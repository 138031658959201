import GroupId from './DefaultGroupId.js';
//2020.12.05
let DefaultEvents = [
  //spot_2020_sim_1209_1211
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2020-12-01 15:55:00',
    RegistrationEnd: '2020-12-11 23:59:00',   //2021.02.01

    Event: 'spot2020_sim',
    EventCode: 'spot_2020_sim_1209_1211',   //unique  //random generated GUID
    EventName: 'SPOT 2020',
    Organizer: 'SIM',
    OrganizerIdentity: 'simeducationgroup',
    OrganizerDisplayName: 'SIM EDUCATION GROUP',
    Date: 'Dec 09~11, 2020',
    DateStart: '2020-12-09',
    DateEnd: '2020-12-11',

    RoomsHeldOnSelfTime: true,
    CertImgUrl: '',
    CertDownloadEnabled: false,

    ZoomInfo: [{
      Active: true,
      RoomId: '852 7884 9700',
      PassCode: 'SPOT2020',
      Url: 'https://us02web.zoom.us/j/85278849700?pwd=VUdaNWFlTS80NTlIVURXZnBLbGZEZz09',
    }],
    FacebookInfo: null,

    // ExtraGradeSetting: 'primary-only',  //2021.02.10
    // Groups: ['Standard 1', 'Standard 2', 'Standard 3', 'Standard 4', 'Standard 5', 'Standard 6'],
    Groups: [{ Id: GroupId[1].Id, Name: GroupId[1].Name }, { Id: GroupId[2].Id, Name: GroupId[2].Name }, { Id: GroupId[3].Id, Name: GroupId[3].Name }, { Id: GroupId[4].Id, Name: GroupId[4].Name }, { Id: GroupId[5].Id, Name: GroupId[5].Name }, { Id: GroupId[6].Id, Name: GroupId[6].Name }],

    QuizRooms: [
      {
        Group: GroupId[1].Name,
        GroupId: GroupId[1].Id,
        Rooms: [
          { Subject: 'Malay', RoomCode: '56945', Date: 'Dec 9, 2020', TimeStart: '7:00 PM', TimeEnd: '8:00 PM' },
          { Subject: 'English', RoomCode: '41132', Date: 'Dec 10, 2020', TimeStart: '7:00 PM', TimeEnd: '8:00 PM' },
          { Subject: 'Chinese', RoomCode: '60052', Date: 'Dec 11, 2020', TimeStart: '7:00 PM', TimeEnd: '8:00 PM' }
        ]
      },
      {
        Group: GroupId[2].Name,
        GroupId: GroupId[2].Id,
        Rooms: [
          { Subject: 'Malay', RoomCode: '30904', Date: 'Dec 9, 2020', TimeStart: '7:00 PM', TimeEnd: '8:00 PM' },
          { Subject: 'English', RoomCode: '90759', Date: 'Dec 10, 2020', TimeStart: '7:00 PM', TimeEnd: '8:00 PM' },
          { Subject: 'Chinese', RoomCode: '82917', Date: 'Dec 11, 2020', TimeStart: '7:00 PM', TimeEnd: '8:00 PM' }
        ]
      },
      {
        Group: GroupId[3].Name,
        GroupId: GroupId[3].Id,
        Rooms: [
          { Subject: 'Malay', RoomCode: '74169', Date: 'Dec 9, 2020', TimeStart: '7:00 PM', TimeEnd: '8:00 PM' },
          { Subject: 'English', RoomCode: '31746', Date: 'Dec 10, 2020', TimeStart: '7:00 PM', TimeEnd: '8:00 PM' },
          { Subject: 'Chinese', RoomCode: '48083', Date: 'Dec 11, 2020', TimeStart: '7:00 PM', TimeEnd: '8:00 PM' }
        ]
      },
      {
        Group: GroupId[4].Name,
        GroupId: GroupId[4].Id,
        Rooms: [
          { Subject: 'Malay', RoomCode: '43438', Date: 'Dec 9, 2020', TimeStart: '8:15 PM', TimeEnd: '9:15 PM' },
          { Subject: 'English', RoomCode: '13614', Date: 'Dec 10, 2020', TimeStart: '8:15 PM', TimeEnd: '9:15 PM' },
          { Subject: 'Chinese', RoomCode: '36735', Date: 'Dec 11, 2020', TimeStart: '8:15 PM', TimeEnd: '9:15 PM' }
        ]
      },
      {
        Group: GroupId[5].Name,
        GroupId: GroupId[5].Id,
        Rooms: [
          { Subject: 'Malay', RoomCode: '83318', Date: 'Dec 9, 2020', TimeStart: '8:15 PM', TimeEnd: '9:15 PM' },
          { Subject: 'English', RoomCode: '80203', Date: 'Dec 10, 2020', TimeStart: '8:15 PM', TimeEnd: '9:15 PM' },
          { Subject: 'Chinese', RoomCode: '46236', Date: 'Dec 11, 2020', TimeStart: '8:15 PM', TimeEnd: '9:15 PM' }
        ]
      },
      {
        Group: GroupId[6].Name,
        GroupId: GroupId[6].Id,
        Rooms: [
          { Subject: 'Malay', RoomCode: '40351', Date: 'Dec 9, 2020', TimeStart: '8:15 PM', TimeEnd: '9:15 PM' },
          { Subject: 'English', RoomCode: '48093', Date: 'Dec 10, 2020', TimeStart: '8:15 PM', TimeEnd: '9:15 PM' },
          { Subject: 'Chinese', RoomCode: '76957', Date: 'Dec 11, 2020', TimeStart: '8:15 PM', TimeEnd: '9:15 PM' }
        ]
      },
    ]
  },

  //abacus_challenge_smartnewton_2021_0206
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2020-12-02 15:55:00',
    RegistrationEnd: '2021-02-05 23:59:00',   //2021.02.01  //2021.02.05

    Event: 'abacus-challenge',
    EventCode: 'abacus_challenge_smartnewton_2021_0206',  //unique
    EventName: 'Abacus Challenge',
    Organizer: 'Smart Newton',
    OrganizerIdentity: 'smartnewton',
    OrganizerDisplayName: 'Smart Newton',
    Date: 'Feb 6, 2021',
    DateStart: '2021-02-06',
    DateEnd: '2021-02-06',
    // ZoomInfo: {
    //   Active: false,
    //   RoomId: 'TBA',
    //   PassCode: 'TBA',
    //   Url: '',
    // },

    RoomsHeldOnSelfTime: true,
    CertImgUrl: '',
    CertDownloadEnabled: false,

    ZoomInfo: null,
    FacebookInfo: null,

    // ExtraGradeSetting: 'other',  //2021.02.10
    // Groups: ['Group A', 'Group B', 'Group C', 'Group D'],
    Groups: [{ Id: GroupId[7].Id, Name: 'Group A' }, { Id: GroupId[8], Name: 'Group B' }, { Id: GroupId[9], Name: 'Group C' }, { Id: GroupId[10], Name: 'Group D' }],

    QuizRooms: [
      {
        Group: GroupId[7].Name,
        GroupId: GroupId[7].Id,
        Rooms: [
          { Subject: 'Abacus Challenge', RoomCode: '59943', Date: 'Feb 6, 2021', TimeStart: '10:00 AM', TimeEnd: '10:30 AM' },
        ]
      },
      {
        Group: GroupId[8].Name,
        GroupId: GroupId[8].Id,
        Rooms: [
          { Subject: 'Abacus Challenge', RoomCode: '71183', Date: 'Feb 6, 2021', TimeStart: '10:30 AM', TimeEnd: '11:00 AM' },
        ]
      },
      {
        Group: GroupId[9].Name,
        GroupId: GroupId[9].Id,
        Rooms: [
          { Subject: 'Abacus Challenge', RoomCode: '60976', Date: 'Feb 6, 2021', TimeStart: '11:00 AM', TimeEnd: '11:30 AM' },
        ]
      },
      {
        Group: GroupId[10].Name,
        GroupId: GroupId[10].Id,
        Rooms: [
          { Subject: 'Abacus Challenge', RoomCode: '60143', Date: 'Feb 6, 2021', TimeStart: '11:30 AM', TimeEnd: '12:00 PM' },
        ]
      },
    ],
  },

  //colin_bm_2021_0130
  //2021.01.14  //new event - Colin's BM 2021 (Colin)
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2021-01-13 15:55:00',
    RegistrationEnd: '2021-01-30 23:59:00',   //2021.02.01

    Event: 'colin_bm_2021',
    EventCode: 'colin_bm_2021_0130',  //unique
    EventName: "Secondary BM",
    Organizer: "The Colin's Learning Centre",
    OrganizerIdentity: 'thecolinslearningcentre',
    OrganizerDisplayName: "The Colin's Learning Centre",

    Date: 'Jan 30, 2021',
    DateStart: '2021-01-30',
    DateEnd: '2021-01-30',

    RoomsHeldOnSelfTime: false,
    CertImgUrl: '',
    CertDownloadEnabled: false,

    ZoomInfo: null,
    FacebookInfo: [{
      Active: true,
      Url: 'https://www.facebook.com/groups/228004045490026/',
    }],

    // ExtraGradeSetting: 'secondary-only',  //2021.02.10
    // Groups: ['Form 1', 'Form 2', 'Form 3', 'Form 4', 'Form 5', 'Form 5 (Batch 2020)'],
    Groups: [{ Id: GroupId[11].Id, Name: GroupId[11].Name }, { Id: GroupId[12].Id, Name: GroupId[12].Name }, { Id: GroupId[13].Id, Name: GroupId[13].Name }, { Id: GroupId[14].Id, Name: GroupId[14].Name }, { Id: GroupId[15].Id, Name: GroupId[15].Name }, { Id: GroupId[17].Id, Name: GroupId[17].Name }],

    QuizRooms: [
      {
        Group: GroupId[11].Name,
        GroupId: GroupId[11].Id,
        Rooms: [
          { Subject: 'Secondary BM', RoomCode: '16434', Date: 'Jan 30, 2021', TimeStart: '02:00 PM', TimeEnd: '03:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[12].Name,
        GroupId: GroupId[12].Id,
        Rooms: [
          { Subject: 'Secondary BM', RoomCode: '47274', Date: 'Jan 30, 2021', TimeStart: '02:00 PM', TimeEnd: '03:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[13].Name,
        GroupId: GroupId[13].Id,
        Rooms: [
          { Subject: 'Secondary BM', RoomCode: '77333', Date: 'Jan 30, 2021', TimeStart: '02:00 PM', TimeEnd: '03:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[14].Name,
        GroupId: GroupId[14].Id,
        Rooms: [
          { Subject: 'Secondary BM', RoomCode: '53598', Date: 'Jan 30, 2021', TimeStart: '02:00 PM', TimeEnd: '03:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[15].Name,
        GroupId: GroupId[15].Id,
        Rooms: [
          { Subject: 'Secondary BM', RoomCode: '22940', Date: 'Jan 30, 2021', TimeStart: '02:00 PM', TimeEnd: '03:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[17].Name,
        GroupId: GroupId[17].Id,
        Rooms: [
          { Subject: 'Secondary BM', RoomCode: '61778', Date: 'Jan 30, 2021', TimeStart: '02:00 PM', TimeEnd: '03:00 PM', TimeLimit: '01:00' },
        ]
      },
    ],
  },

  //ptsmc_primary_test_2021_0306_0307
  //2021.02.10  //new event - Primary Test - Pusat Tuisyen Sri Mulia Cahaya
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2021-02-10 17:05:00',
    RegistrationEnd: '2021-03-07 18:00:00',

    Event: 'ptsmc_primary_test_2021_03',
    EventCode: 'ptsmc_primary_test_2021_0306_0307',  //unique
    EventName: "全马线上考试",  //"Primary Test",
    Organizer: "佳学教育 JiaXue Tuition Center",  //"Pusat Tuisyen Sri Mulia Cahaya",
    OrganizerIdentity: 'jiaxuetuitioncenter',
    OrganizerDisplayName: "佳学教育 JiaXue Tuition Center",
    Date: 'Mar 06~07, 2021',
    DateStart: '2021-03-06',
    DateEnd: '2021-03-07',

    //2021.03.04
    TimeStart: '9:00 AM (6.3.2021)',
    TimeEnd: '6:00 PM (7.3.2021)',

    RoomsHeldOnSelfTime: false,
    CertImgUrl: '',
    CertDownloadEnabled: false,

    ZoomInfo: null,
    FacebookInfo: null,

    // ExtraGradeSetting: 'primary-only',  //2021.02.10
    // Groups: ['Standard 1', 'Standard 2', 'Standard 3', 'Standard 4', 'Standard 5', 'Standard 6'],
    // Groups: [{ Id: 1, Name: 'Standard 1' }, { Id: 2, Name: 'Standard 2' }, { Id: 3, Name: 'Standard 3' }, { Id: 4, Name: 'Standard 4' }, { Id: 5, Name: 'Standard 5' }, { Id: 6, Name: 'Standard 6' }],
    // Groups: [{ Id: GroupId[1], Name: 'Standard 1' }, { Id: GroupId[2], Name: 'Standard 2' }, { Id: GroupId[3], Name: 'Standard 3' }, { Id: GroupId[4], Name: 'Standard 4' }, { Id: GroupId[5], Name: 'Standard 5' }, { Id: GroupId[6], Name: 'Standard 6' }],
    Groups: [{ Id: GroupId[1].Id, Name: GroupId[1].Name }, { Id: GroupId[2].Id, Name: GroupId[2].Name }, { Id: GroupId[3].Id, Name: GroupId[3].Name }, { Id: GroupId[4].Id, Name: GroupId[4].Name }, { Id: GroupId[5].Id, Name: GroupId[5].Name }, { Id: GroupId[6].Id, Name: GroupId[6].Name }],

    QuizRooms: [
      {
        Group: GroupId[1].Name,
        GroupId: GroupId[1].Id,
        Rooms: [
          // { Subject: 'Mathematics', RoomCode: '15063', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Science', RoomCode: '64118', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Melayu', RoomCode: '21023', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Inggeris', RoomCode: '47973', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Cina', RoomCode: '98691', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },

          { Subject: 'Mathematics', RoomCode: '16749', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Science', RoomCode: '31306', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Melayu', RoomCode: '89931', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Inggeris', RoomCode: '30375', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Cina', RoomCode: '17554', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[2].Name,
        GroupId: GroupId[2].Id,
        Rooms: [
          // { Subject: 'Mathematics', RoomCode: '46954', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Science', RoomCode: '44536', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Melayu', RoomCode: '63212', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Inggeris', RoomCode: '43373', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Cina', RoomCode: '54779', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },

          { Subject: 'Mathematics', RoomCode: '67724', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Science', RoomCode: '13513', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Melayu', RoomCode: '12560', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Inggeris', RoomCode: '34850', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Cina', RoomCode: '46526', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[3].Name,
        GroupId: GroupId[3].Id,
        Rooms: [
          // { Subject: 'Mathematics', RoomCode: '98467', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Science', RoomCode: '99255', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Melayu', RoomCode: '33447', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Inggeris', RoomCode: '70203', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Cina', RoomCode: '66073', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },

          { Subject: 'Mathematics', RoomCode: '49210', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Science', RoomCode: '90050', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Melayu', RoomCode: '61471', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Inggeris', RoomCode: '24089', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Cina', RoomCode: '51653', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[4].Name,
        GroupId: GroupId[4].Id,
        Rooms: [
          // { Subject: 'Mathematics', RoomCode: '45350', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Science', RoomCode: '87849', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Melayu', RoomCode: '84344', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Inggeris', RoomCode: '65761', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Cina', RoomCode: '95687', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },

          { Subject: 'Mathematics', RoomCode: '80823', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Science', RoomCode: '72207', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Melayu', RoomCode: '89317', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Inggeris', RoomCode: '49570', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Cina', RoomCode: '76967', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[5].Name,
        GroupId: GroupId[5].Id,
        Rooms: [
          // { Subject: 'Mathematics', RoomCode: '71939', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Science', RoomCode: '62579', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Melayu', RoomCode: '55818', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Inggeris', RoomCode: '21364', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Cina', RoomCode: '98674', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },

          { Subject: 'Mathematics', RoomCode: '91131', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Science', RoomCode: '41690', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Melayu', RoomCode: '62047', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Inggeris', RoomCode: '22557', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Cina', RoomCode: '12249', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[6].Name,
        GroupId: GroupId[6].Id,
        Rooms: [
          // { Subject: 'Mathematics', RoomCode: '99742', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Science', RoomCode: '81315', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Melayu', RoomCode: '61485', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Inggeris', RoomCode: '41910', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },
          // { Subject: 'Bahasa Cina', RoomCode: '53085', Date: 'Mar 6, 2021', TimeStart: '9:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:00' },

          { Subject: 'Mathematics', RoomCode: '80955', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Science', RoomCode: '14039', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Melayu', RoomCode: '80173', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Inggeris', RoomCode: '71072', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
          { Subject: 'Bahasa Cina', RoomCode: '53666', Date: 'Mar 7, 2021', TimeStart: '12:00 AM', TimeEnd: '6:00 PM', TimeLimit: '01:00' },
        ]
      },
    ],
  },

  //spot_2021_sim_0417_1st_sem
  //2021.03.17  //new event - SPOT 2021 First Semester
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2021-03-17 17:55:00',
    RegistrationEnd: '2021-04-17 13:30:00',
    RegistrationEndDisplayName: '2021-04-16 22:00:00',  //2021.04.02

    Event: 'spot_2021_sim_0417_1st_sem',
    EventCode: 'spot_2021_sim_0417_1st_sem',   //unique  //random generated GUID
    EventName: 'SPOT 2021 (1ST QUARTER)',
    Organizer: 'SIM',
    OrganizerIdentity: 'simeducationgroup',
    OrganizerDisplayName: 'SIM EDUCATION GROUP',

    Date: 'Apr 17, 2021',       //Display only.
    DateStart: '2021-04-17',    //used as condition.
    DateEnd: '2021-04-17',      //used as condition.

    //2021.03.04
    TimeStart: '10:00 AM (17.4.2021)',
    TimeEnd: '01:30 PM (17.4.2021)',

    //2021.03.17
    RoomsHeldOnSelfTime: true,

    //2021.04.07
    CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/cert_spot_2021_q1.jpg',
    // CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/spot_2021_sim_0417_1st_sem/cert_spot_2021_q1.jpg',

    CertDownloadEnabled: true,

    ZoomInfo: null,
    FacebookInfo: null,

    // Groups: ['Standard 1', 'Standard 2', 'Standard 3', 'Standard 4', 'Standard 5', 'Standard 6'],
    // Groups: [{ Id: 1, Name: 'Standard 1' }, { Id: 2, Name: 'Standard 2' }, { Id: 3, Name: 'Standard 3' }, { Id: 4, Name: 'Standard 4' }, { Id: 5, Name: 'Standard 5' }, { Id: 6, Name: 'Standard 6' }],
    // Groups: [{ Id: GroupId[1], Name: 'Standard 1' }, { Id: GroupId[2], Name: 'Standard 2' }, { Id: GroupId[3], Name: 'Standard 3' }, { Id: GroupId[4], Name: 'Standard 4' }, { Id: GroupId[5], Name: 'Standard 5' }, { Id: GroupId[6], Name: 'Standard 6' }],
    Groups: [{ Id: GroupId[1].Id, Name: GroupId[1].Name }, { Id: GroupId[2].Id, Name: GroupId[2].Name }, { Id: GroupId[3].Id, Name: GroupId[3].Name }, { Id: GroupId[4].Id, Name: GroupId[4].Name }, { Id: GroupId[5].Id, Name: GroupId[5].Name }, { Id: GroupId[6].Id, Name: GroupId[6].Name }],

    QuizRooms: [
      {
        Group: GroupId[1].Name,
        GroupId: GroupId[1].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '67838', Date: 'Apr 17, 2021', TimeStart: '10:00 AM', TimeEnd: '11:00 AM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[2].Name,
        GroupId: GroupId[2].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '28249', Date: 'Apr 17, 2021', TimeStart: '10:30 AM', TimeEnd: '11:30 AM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[3].Name,
        GroupId: GroupId[3].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '96519', Date: 'Apr 17, 2021', TimeStart: '11:00 AM', TimeEnd: '12:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[4].Name,
        GroupId: GroupId[4].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '83820', Date: 'Apr 17, 2021', TimeStart: '11:30 AM', TimeEnd: '12:30 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[5].Name,
        GroupId: GroupId[5].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '62002', Date: 'Apr 17, 2021', TimeStart: '12:00 PM', TimeEnd: '01:00 PM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[6].Name,
        GroupId: GroupId[6].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '53233', Date: 'Apr 17, 2021', TimeStart: '12:30 PM', TimeEnd: '01:30 PM', TimeLimit: '01:00' },
        ]
      },
    ],
  },

  //ikeyteam_2021_beta_test_event
  //2021.04.03 - Event for testing purpose.
  {
    Active: true,
    IsPublic: false,
    Role: 'Admin',
    UserId: 0,
    CreatedDate: '2021-04-03 11:45:00',

    RegistrationEnd: '2021-12-31 23:59:59',
    RegistrationEndDisplayName: '2021-12-31 23:59:59',  //2021.04.02 - Display only.

    Event: 'ikeyteam_2021_beta_test_event',
    EventCode: 'ikeyteam_2021_beta_test_event',   //unique  //random generated GUID
    EventName: 'iKEY Team Beta Test Event',
    Organizer: 'iKEYTEAM',
    OrganizerIdentity: 'ikeyedutech',
    OrganizerDisplayName: 'iKEY EDUTECH',

    Date: 'Apr 01 ~ Dec 31, 2021',       //Display only.
    DateStart: '2021-10-08',    //used as condition.
    DateEnd: '2021-10-08',      //used as condition.

    TimeStart: '0:00 AM (1.4.2021 ~ 31.12.2021)',  //Display only.
    TimeEnd: '11:59 PM (1.4.2021 ~ 31.12.2021)',    //Display only.

    RoomsHeldOnSelfTime: true,

    CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/cert_sim_spot_ai_2021_essay_3.jpg',
    // CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/ikeyteam_2021_beta_test_event/cert_sim_spot_ai_2021_essay_3.jpg',
    CertDownloadEnabled: true,
    CertSerialPrefix: 'SCWNOV21',   //2021.11.06
    CertLayoutTypeId: 2,            //2021.11.06
    EventSharedReportUrl: '',       //2021.11.08
    CertDownloadDelayed: false,      //2021.11.08

    ZoomInfo: null,
    FacebookInfo: null,

    // Groups: ['Standard 1', 'Standard 2', 'Standard 3', 'Standard 4', 'Standard 5', 'Standard 6'],
    // Groups: [{ Id: 1, Name: 'Standard 1' }, { Id: 2, Name: 'Standard 2' }, { Id: 3, Name: 'Standard 3' }, { Id: 4, Name: 'Standard 4' }, { Id: 5, Name: 'Standard 5' }, { Id: 6, Name: 'Standard 6' }],
    // Groups: [{ Id: GroupId[1], Name: 'Standard 1' }, { Id: GroupId[2], Name: 'Standard 2' }, { Id: GroupId[3], Name: 'Standard 3' }, { Id: GroupId[4], Name: 'Standard 4' }, { Id: GroupId[5], Name: 'Standard 5' }, { Id: GroupId[6], Name: 'Standard 6' }],
    Groups: [{ Id: GroupId[1].Id, Name: GroupId[1].Name }, { Id: GroupId[2].Id, Name: GroupId[2].Name }, { Id: GroupId[3].Id, Name: GroupId[3].Name }, { Id: GroupId[4].Id, Name: GroupId[4].Name }, { Id: GroupId[5].Id, Name: GroupId[5].Name }, { Id: GroupId[6].Id, Name: GroupId[6].Name }],

    QuizRooms: [
      {
        Group: GroupId[1].Name,
        GroupId: GroupId[1].Id,
        Rooms: [
          // { Subject: 'Essay', RoomCode: '93670', Date: '2021-04-06', TimeStart: '00:00 AM', TimeEnd: '11:59 PM', TimeLimit: '06:00' },
          { Subject: 'Essay', RoomCode: '93670', Date: 'Apr 6, 2021', DateStart: '2021-10-08', DateEnd: '2021-10-08', TimeStart: '00:00 AM', TimeEnd: '11:59 PM', TimeLimit: '06:00' },
        ]
      }
    ],
  },

  //4c3fe3deaf78485a839f6ec350a76a20 = JULY ASSESSMENT
  //2021.07.05 - Event for JULY ASSESSMENT
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2021-07-05 15:15:00',

    RegistrationEnd: '2021-07-25 17:00:00',
    RegistrationEndDisplayName: '2021-07-25 17:00:00',  //Display only.

    Event: 'mega_2021_july_assessment_rev',
    EventCode: '4c3fe3deaf78485a839f6ec350a76a20',   //unique  //random generated GUID
    EventName: 'JULY ASSESSMENT',
    Organizer: 'MEGA',
    OrganizerIdentity: 'megaeducationcenter',
    OrganizerDisplayName: 'MEGA EDUCATION CENTER',

    Date: 'July 24~25, 2021',       //Display only.
    DateStart: '2021-07-24',    //used as condition.
    DateEnd: '2021-07-25',      //used as condition.

    TimeStart: '0:00 AM (24.07.2021 ~ 25.07.2021)',  //Display only.
    TimeEnd: '11:59 PM (24.07.2021 ~ 25.07.2021)',    //Display only.

    RoomsHeldOnSelfTime: true,
    CertImgUrl: '',
    CertDownloadEnabled: false,   //2021.07.05
    PrivateDistributeRoomCode: false,  //2021.07.14

    ZoomInfo: null,
    FacebookInfo: null,

    // Groups: [{ Id: 1, Name: 'Standard 1' }, { Id: 2, Name: 'Standard 2' }, { Id: 3, Name: 'Standard 3' }, { Id: 4, Name: 'Standard 4' }, { Id: 5, Name: 'Standard 5' }, { Id: 6, Name: 'Standard 6' }],
    // Groups: [{ Id: GroupId[1], Name: 'Standard 1' }, { Id: GroupId[2], Name: 'Standard 2' }, { Id: GroupId[3], Name: 'Standard 3' }, { Id: GroupId[4], Name: 'Standard 4' }, { Id: GroupId[5], Name: 'Standard 5' }, { Id: GroupId[6], Name: 'Standard 6' }],
    Groups: [{ Id: GroupId[1].Id, Name: GroupId[1].Name }, { Id: GroupId[2].Id, Name: GroupId[2].Name }, { Id: GroupId[3].Id, Name: GroupId[3].Name }, { Id: GroupId[4].Id, Name: GroupId[4].Name }, { Id: GroupId[5].Id, Name: GroupId[5].Name }, { Id: GroupId[6].Id, Name: GroupId[6].Name }],

    QuizRooms: [
      {
        Group: GroupId[1].Name,
        GroupId: GroupId[1].Id,
        Rooms: [
          { Subject: 'Bahasa Melayu', RoomCode: '97778', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Mathematic', RoomCode: '90689', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '03:30 PM', TimeEnd: '09:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Inggeris', RoomCode: '34418', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Cina', RoomCode: '78782', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '03:30 PM', TimeEnd: '05:00 PM', TimeLimit: '01:30' },
          // { Subject: 'Sains', RoomCode: 'TBA', Date: 'TBA', DateStart: '2021-07-24', DateEnd: '2021-07-25', TimeStart: '00:00 AM', TimeEnd: '11:59 PM', TimeLimit: '02:00' },
        ]
      },
      {
        Group: GroupId[2].Name,
        GroupId: GroupId[2].Id,
        Rooms: [
          { Subject: 'Bahasa Melayu', RoomCode: '81700', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Mathematic', RoomCode: '85535', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '03:30 PM', TimeEnd: '09:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Inggeris', RoomCode: '70124', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Cina', RoomCode: '14494', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '03:30 PM', TimeEnd: '05:00 PM', TimeLimit: '01:30' },
          // { Subject: 'Sains', RoomCode: 'TBA', Date: 'TBA', DateStart: '2021-07-24', DateEnd: '2021-07-25', TimeStart: '00:00 AM', TimeEnd: '11:59 PM', TimeLimit: '02:00' },
        ]
      },
      {
        Group: GroupId[3].Name,
        GroupId: GroupId[3].Id,
        Rooms: [
          { Subject: 'Bahasa Melayu', RoomCode: '35880', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Mathematic', RoomCode: '45950', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '03:30 PM', TimeEnd: '09:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Inggeris', RoomCode: '20298', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Cina', RoomCode: '49471', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '03:30 PM', TimeEnd: '05:00 PM', TimeLimit: '01:30' },
          // { Subject: 'Sains', RoomCode: 'TBA', Date: 'TBA', DateStart: '2021-07-24', DateEnd: '2021-07-25', TimeStart: '00:00 AM', TimeEnd: '11:59 PM', TimeLimit: '02:00' },
        ]
      },
      {
        Group: GroupId[4].Name,
        GroupId: GroupId[4].Id,
        Rooms: [
          { Subject: 'Bahasa Melayu', RoomCode: '99785', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Mathematic', RoomCode: '46200', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '03:30 PM', TimeEnd: '09:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Inggeris', RoomCode: '85060', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Cina', RoomCode: '77815', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '03:30 PM', TimeEnd: '05:00 PM', TimeLimit: '01:30' },
          // { Subject: 'Sains', RoomCode: 'TBA', Date: 'TBA', DateStart: '2021-07-24', DateEnd: '2021-07-25', TimeStart: '00:00 AM', TimeEnd: '11:59 PM', TimeLimit: '02:00' },
        ]
      },
      {
        Group: GroupId[5].Name,
        GroupId: GroupId[5].Id,
        Rooms: [
          { Subject: 'Bahasa Melayu', RoomCode: '54137', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Mathematic', RoomCode: '82675', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '03:30 PM', TimeEnd: '09:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Inggeris', RoomCode: '22957', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Cina', RoomCode: '54847', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '03:30 PM', TimeEnd: '05:00 PM', TimeLimit: '01:30' },
          // { Subject: 'Sains', RoomCode: 'TBA', Date: 'TBA', DateStart: '2021-07-24', DateEnd: '2021-10-15', TimeStart: '00:00 AM', TimeEnd: '11:59 PM', TimeLimit: '02:00' },
        ]
      },
      {
        Group: GroupId[6].Name,
        GroupId: GroupId[6].Id,
        Rooms: [
          { Subject: 'Bahasa Melayu', RoomCode: '73411', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Mathematic', RoomCode: '65372', Date: '2021-07-24', DateStart: '2021-07-24', DateEnd: '2021-07-24', TimeStart: '03:30 PM', TimeEnd: '09:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Inggeris', RoomCode: '98882', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '02:00 PM', TimeEnd: '03:30 PM', TimeLimit: '01:30' },
          { Subject: 'Bahasa Cina', RoomCode: '46764', Date: '2021-07-25', DateStart: '2021-07-25', DateEnd: '2021-07-25', TimeStart: '03:30 PM', TimeEnd: '05:00 PM', TimeLimit: '01:30' },
          // { Subject: 'Sains', RoomCode: 'TBA', Date: 'TBA', DateStart: '2021-07-24', DateEnd: '2021-10-15', TimeStart: '00:00 AM', TimeEnd: '11:59 PM', TimeLimit: '02:00' },
        ]
      },
    ],
  },

  //ced0217d39cb49a4bb810e4b1abd04ae
  //2021.07.30 - Event for 'SJKC SPOT 2021' on 2021-08-28
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2021-07-30 13:00:00',
    RegistrationEnd: '2021-08-28 13:59:00',
    RegistrationEndDisplayName: '2021-08-27 23:59:00',

    Event: 'sjk_spot_2021_2nd_quarter',
    EventCode: 'ced0217d39cb49a4bb810e4b1abd04ae',   //unique  //random generated GUID
    EventName: 'SJKC SPOT 2021',  //'SJK SPOT 2021 (2nd Quarter)', //'SPOT 2021 (2nd Quarter)',
    Organizer: 'SIM',
    OrganizerIdentity: 'simeducationgroup',
    OrganizerDisplayName: 'SIM EDUCATION GROUP',

    Date: 'Aug 28, 2021',       //Display only.
    DateStart: '2021-08-28',    //used as condition.
    DateEnd: '2021-08-28',      //used as condition.

    TimeStart: '10:00 AM (28.8.2021)',
    TimeEnd: '02:00 PM (28.8.2021)',

    RoomsHeldOnSelfTime: true,

    CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/cert_sim_spot_sjkc_2021_1.jpg',
    // CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/ced0217d39cb49a4bb810e4b1abd04ae/cert_sim_spot_sjkc_2021_1.jpg',
    CertDownloadEnabled: true,

    ZoomInfo: null,
    FacebookInfo: null,

    Groups: [{ Id: GroupId[1].Id, Name: GroupId[1].Name }, { Id: GroupId[2].Id, Name: GroupId[2].Name }, { Id: GroupId[3].Id, Name: GroupId[3].Name }, { Id: GroupId[4].Id, Name: GroupId[4].Name }, { Id: GroupId[5].Id, Name: GroupId[5].Name }, { Id: GroupId[6].Id, Name: GroupId[6].Name }],

    QuizRooms: [
      {
        Group: GroupId[1].Name,
        GroupId: GroupId[1].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '91901', Date: 'Aug 28, 2021', DateStart: '2021-08-28', DateEnd: '2021-08-28', TimeStart: '10:00 AM', TimeEnd: '11:30 AM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[2].Name,
        GroupId: GroupId[2].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '97945', Date: 'Aug 28, 2021', DateStart: '2021-08-28', DateEnd: '2021-08-28', TimeStart: '10:30 AM', TimeEnd: '12:00 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[3].Name,
        GroupId: GroupId[3].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '29973', Date: 'Aug 28, 2021', DateStart: '2021-08-28', DateEnd: '2021-08-28', TimeStart: '11:00 AM', TimeEnd: '12:30 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[4].Name,
        GroupId: GroupId[4].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '81242', Date: 'Aug 28, 2021', DateStart: '2021-08-28', DateEnd: '2021-08-28', TimeStart: '11:30 AM', TimeEnd: '01:00 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[5].Name,
        GroupId: GroupId[5].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '76187', Date: 'Aug 28, 2021', DateStart: '2021-08-28', DateEnd: '2021-08-28', TimeStart: '12:00 PM', TimeEnd: '01:30 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[6].Name,
        GroupId: GroupId[6].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '36859', Date: 'Aug 28, 2021', DateStart: '2021-08-28', DateEnd: '2021-08-28', TimeStart: '12:30 PM', TimeEnd: '02:00 PM', TimeLimit: '01:30' },
        ]
      },
    ],
  },

  //9ca16982f8b845b8bc2831bb05fe275d
  //2021.08.13 - Event for 'Youth Chinese Test【国际汉语】' on 2021-09-04
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2021-08-13 15:00:00',
    RegistrationEnd: '2021-09-03 23:59:00',
    RegistrationEndDisplayName: '2021-09-03 23:59:00',

    Event: 'yct_hypy_2021_1',
    EventCode: '9ca16982f8b845b8bc2831bb05fe275d',   //unique  //random generated GUID
    EventName: 'Youth Chinese Test【国际汉语】',  //'YCT 国际汉语【自我挑战赛】',
    Organizer: 'SIM',
    OrganizerIdentity: 'simeducationgroup',
    OrganizerDisplayName: 'SIM EDUCATION GROUP',

    Date: 'Sep 4~9, 2021',       //Display only.
    DateStart: '2021-09-04',    //used as condition.
    DateEnd: '2021-09-09',      //used as condition.

    TimeStart: '10:00 AM (4.9.2021)',
    TimeEnd: '02:00 PM (4.9.2021)',

    RoomsHeldOnSelfTime: true,

    CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/cert_sim_yct_test_2021_1.jpg',
    // CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/9ca16982f8b845b8bc2831bb05fe275d/cert_sim_yct_test_2021_1.jpg',
    CertDownloadEnabled: true,

    ZoomInfo: null,
    FacebookInfo: null,

    Groups: [{ Id: GroupId[18].Id, Name: GroupId[18].Name }, { Id: GroupId[19].Id, Name: GroupId[19].Name }, { Id: GroupId[20].Id, Name: GroupId[20].Name }, { Id: GroupId[21].Id, Name: GroupId[21].Name }],

    QuizRooms: [
      {
        Group: GroupId[18].Name,
        GroupId: GroupId[18].Id,
        Remark: GroupId[18].Remark,
        Rooms: [
          { Subject: 'Chinese', RoomCode: '37812', Date: 'Sep 4, 2021', DateStart: '2021-09-04', DateEnd: '2021-09-04', TimeStart: '10:00 AM', TimeEnd: '10:30 AM', TimeLimit: '00:30' },
        ]
      },
      {
        Group: GroupId[19].Name,
        GroupId: GroupId[19].Id,
        Remark: GroupId[19].Remark,
        Rooms: [
          { Subject: 'Chinese', RoomCode: '78820', Date: 'Sep 4, 2021', DateStart: '2021-09-04', DateEnd: '2021-09-04', TimeStart: '10:45 AM', TimeEnd: '11:25 AM', TimeLimit: '00:40' },
        ]
      },
      {
        Group: GroupId[20].Name,
        GroupId: GroupId[20].Id,
        Remark: GroupId[20].Remark,
        Rooms: [
          // { Subject: 'Chinese', RoomCode: '51962', Date: 'Sep 4, 2021', DateStart: '2021-09-04', DateEnd: '2021-09-04', TimeStart: '11:45 AM', TimeEnd: '12:30 PM', TimeLimit: '00:45' },
          // { Subject: 'Chinese', RoomCode: '19900', Date: 'Sep 4, 2021', DateStart: '2021-09-04', DateEnd: '2021-09-04', TimeStart: '02:15 PM', TimeEnd: '03:00 PM', TimeLimit: '00:45' },
          { Subject: 'Chinese', RoomCode: '19900', Date: 'Sep 4, 2021', DateStart: '2021-09-04', DateEnd: '2021-09-09', TimeStart: '10:00 AM', TimeEnd: '11:59 PM', TimeLimit: '00:45' },
        ]
      },
      {
        Group: GroupId[21].Name,
        GroupId: GroupId[21].Id,
        Remark: GroupId[21].Remark,
        Rooms: [
          // { Subject: 'Chinese', RoomCode: '58418', Date: 'Sep 4, 2021', DateStart: '2021-09-04', DateEnd: '2021-09-04', TimeStart: '12:45 PM', TimeEnd: '02:00 PM', TimeLimit: '01:15' },
          // { Subject: 'Chinese', RoomCode: '44782', Date: 'Sep 4, 2021', DateStart: '2021-09-04', DateEnd: '2021-09-04', TimeStart: '03:15 PM', TimeEnd: '04:30 PM', TimeLimit: '01:15' },
          { Subject: 'Chinese', RoomCode: '44782', Date: 'Sep 4, 2021', DateStart: '2021-09-04', DateEnd: '2021-09-09', TimeStart: '10:00 AM', TimeEnd: '11:59 PM', TimeLimit: '01:15' },
        ]
      },
    ],
  },

  //e6c4de5a2b27406eb5d7d6e3db36fd74
  //2021.08.13 - Event for 'SK&SJKT SPOT 2021' on 2021-09-11
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2021-08-13 15:40:00',
    RegistrationEnd: '2021-09-10 23:59:00',
    RegistrationEndDisplayName: '2021-09-10 23:59:00',

    Event: 'sk_spot_2021_1',
    EventCode: 'e6c4de5a2b27406eb5d7d6e3db36fd74',   //unique  //random generated GUID
    EventName: 'SK&SJKT SPOT 2021',  //'SK SPOT 2021',
    Organizer: 'SIM',
    OrganizerIdentity: 'simeducationgroup',
    OrganizerDisplayName: 'SIM EDUCATION GROUP',

    Date: 'Sep 11, 2021',       //Display only.
    DateStart: '2021-09-11',    //used as condition.
    DateEnd: '2021-09-11',      //used as condition.

    TimeStart: '10:00 AM (11.9.2021)',
    TimeEnd: '02:00 PM (11.9.2021)',

    RoomsHeldOnSelfTime: true,

    CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/cert_sk_spot_2021_1.jpg',
    // CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/e6c4de5a2b27406eb5d7d6e3db36fd74/cert_sk_spot_2021_1.jpg',
    CertDownloadEnabled: true,

    ZoomInfo: null,
    FacebookInfo: null,

    Groups: [{ Id: GroupId[1].Id, Name: GroupId[1].Name }, { Id: GroupId[2].Id, Name: GroupId[2].Name }, { Id: GroupId[3].Id, Name: GroupId[3].Name }, { Id: GroupId[4].Id, Name: GroupId[4].Name }, { Id: GroupId[5].Id, Name: GroupId[5].Name }, { Id: GroupId[6].Id, Name: GroupId[6].Name }],

    QuizRooms: [
      {
        Group: GroupId[1].Name,
        GroupId: GroupId[1].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '70743', Date: 'Sep 11, 2021', DateStart: '2021-09-11', DateEnd: '2021-09-11', TimeStart: '10:00 AM', TimeEnd: '11:30 AM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[2].Name,
        GroupId: GroupId[2].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '60068', Date: 'Sep 11, 2021', DateStart: '2021-09-11', DateEnd: '2021-09-11', TimeStart: '10:30 AM', TimeEnd: '12:00 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[3].Name,
        GroupId: GroupId[3].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '83483', Date: 'Sep 11, 2021', DateStart: '2021-09-11', DateEnd: '2021-09-11', TimeStart: '11:00 AM', TimeEnd: '12:30 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[4].Name,
        GroupId: GroupId[4].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '83116', Date: 'Sep 11, 2021', DateStart: '2021-09-11', DateEnd: '2021-09-11', TimeStart: '11:30 AM', TimeEnd: '01:00 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[5].Name,
        GroupId: GroupId[5].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '15122', Date: 'Sep 11, 2021', DateStart: '2021-09-11', DateEnd: '2021-09-11', TimeStart: '12:00 PM', TimeEnd: '01:30 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[6].Name,
        GroupId: GroupId[6].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '92535', Date: 'Sep 11, 2021', DateStart: '2021-09-11', DateEnd: '2021-09-11', TimeStart: '12:30 PM', TimeEnd: '02:00 PM', TimeLimit: '01:30' },
        ]
      },
    ],
  },

  //783f207e4ab84f52af5494eac658bc8a
  //2021.09.18 - Event for 'Little Oppy - Preschool SPOT 2021' on 2021-10-16
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2021-09-18 11:45:00',
    RegistrationEnd: '2021-10-15 23:59:00',
    RegistrationEndDisplayName: '2021-10-15 23:59:00',

    Event: 'little_oppy_preschool_spot_2021_1',
    EventCode: '783f207e4ab84f52af5494eac658bc8a',   //unique  //random generated GUID
    EventName: 'Preschool SPOT 2021',  //'SK SPOT 2021',
    Organizer: 'LITTLEOPPY',
    OrganizerIdentity: 'littleoppy',
    OrganizerDisplayName: 'Little Oppy',

    Date: 'Oct 16, 2021',       //Display only.
    DateStart: '2021-10-16',    //used as condition.
    DateEnd: '2021-10-16',      //used as condition.

    TimeStart: '00:00 AM (16.10.2021)',
    TimeEnd: '23:59 PM (16.10.2021)',

    RoomsHeldOnSelfTime: true,

    CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/cert_littleoppy_spot_2021_1_1.jpg',
    // CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/783f207e4ab84f52af5494eac658bc8a/cert_littleoppy_spot_2021_1_1.jpg',
    CertDownloadEnabled: true,

    ZoomInfo: null,
    FacebookInfo: null,

    Groups: [{ Id: GroupId[22].Id, Name: GroupId[22].Name }, { Id: GroupId[23].Id, Name: GroupId[23].Name }, { Id: GroupId[24].Id, Name: GroupId[24].Name },],

    QuizRooms: [
      {
        Group: GroupId[22].Name,
        GroupId: GroupId[22].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '83804', Date: 'Oct 16, 2021', DateStart: '2021-10-16', DateEnd: '2021-10-16', TimeStart: '10:00 AM', TimeEnd: '11:00 AM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[23].Name,
        GroupId: GroupId[23].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '96719', Date: 'Oct 16, 2021', DateStart: '2021-10-16', DateEnd: '2021-10-16', TimeStart: '10:45 AM', TimeEnd: '11:45 AM', TimeLimit: '01:00' },
        ]
      },
      {
        Group: GroupId[24].Name,
        GroupId: GroupId[24].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '97588', Date: 'Oct 16, 2021', DateStart: '2021-10-16', DateEnd: '2021-10-16', TimeStart: '11:15 AM', TimeEnd: '12:15 PM', TimeLimit: '01:00' },
        ]
      },
    ],
  },

  //8a99d6952ca0470481834023672e8d43
  //2021.11.13 - Event for 'SJKC SPOT 2021 终极场' on 2021-11-13
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2021-10-20 18:45:00',
    RegistrationEnd: '2021-11-12 23:59:00',
    RegistrationEndDisplayName: '2021-11-12 23:59:00',
    RegistrationOverloaded: false,

    Event: 'sjkc_spot_2021_final',
    EventCode: '8a99d6952ca0470481834023672e8d43',   //unique  //random generated GUID
    EventName: 'SJKC SPOT 2021 终极场',
    Organizer: 'SIM',
    OrganizerIdentity: 'simeducationgroup',
    OrganizerDisplayName: 'SIM EDUCATION GROUP',

    Date: 'Nov 13, 2021',       //Display only.
    DateStart: '2021-11-13',    //used as condition.
    DateEnd: '2021-11-13',      //used as condition.

    TimeStart: '00:00 AM (13.11.2021)',
    TimeEnd: '23:59 PM (13.11.2021)',

    RoomsHeldOnSelfTime: true,

    CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/cert_sim_spot_sjkc_2021_final_3.jpg',
    // CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/8a99d6952ca0470481834023672e8d43/cert_sim_spot_sjkc_2021_final_3.jpg',
    CertDownloadEnabled: true,
    CertSerialPrefix: 'SJKCNOV21',  //2021.11.06
    CertLayoutTypeId: 2,            //2021.11.06
    EventSharedReportUrl: '',       //2021.11.08
    CertDownloadDelayed: true,     //2021.11.08

    ZoomInfo: null,
    FacebookInfo: null,

    Groups: [{ Id: GroupId[1].Id, Name: GroupId[1].Name }, { Id: GroupId[2].Id, Name: GroupId[2].Name }, { Id: GroupId[3].Id, Name: GroupId[3].Name }, { Id: GroupId[4].Id, Name: GroupId[4].Name }, { Id: GroupId[5].Id, Name: GroupId[5].Name }, { Id: GroupId[6].Id, Name: GroupId[6].Name }],

    QuizRooms: [
      {
        Group: GroupId[1].Name,
        GroupId: GroupId[1].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '59399', Date: 'Nov 13, 2021', DateStart: '2021-11-13', DateEnd: '2021-11-13', TimeStart: '10:00 AM', TimeEnd: '11:30 AM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[2].Name,
        GroupId: GroupId[2].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '85063', Date: 'Nov 13, 2021', DateStart: '2021-11-13', DateEnd: '2021-11-13', TimeStart: '10:30 AM', TimeEnd: '12:00 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[3].Name,
        GroupId: GroupId[3].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '63014', Date: 'Nov 13, 2021', DateStart: '2021-11-13', DateEnd: '2021-11-13', TimeStart: '11:00 AM', TimeEnd: '12:30 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[4].Name,
        GroupId: GroupId[4].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '55683', Date: 'Nov 13, 2021', DateStart: '2021-11-13', DateEnd: '2021-11-13', TimeStart: '11:30 AM', TimeEnd: '01:00 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[5].Name,
        GroupId: GroupId[5].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '31811', Date: 'Nov 13, 2021', DateStart: '2021-11-13', DateEnd: '2021-11-13', TimeStart: '12:00 PM', TimeEnd: '01:30 PM', TimeLimit: '01:30' },
        ]
      },
      {
        Group: GroupId[6].Name,
        GroupId: GroupId[6].Id,
        Rooms: [
          { Subject: 'MixedQuestions', RoomCode: '65303', Date: 'Nov 13, 2021', DateStart: '2021-11-13', DateEnd: '2021-11-13', TimeStart: '12:30 PM', TimeEnd: '02:00 PM', TimeLimit: '01:30' },
        ]
      },
    ],
  },

  //7565a9729ecb4747b665b38c9652982e
  //2021.11.11 - Event for 'SPOT 2021 AI 智能作文争霸赛' on 2021-11/11~11/13
  {
    Active: true,
    IsPublic: true,
    Role: 'Global',
    UserId: 0,
    CreatedDate: '2021-10-21 18:55:00',
    RegistrationEnd: '2021-11-10 23:59:00',
    RegistrationEndDisplayName: '2021-11-10 23:59:00',
    RegistrationOverloaded: true,

    Event: 'sim_spot_ai_2021_essay_1',
    EventCode: '7565a9729ecb4747b665b38c9652982e',   //unique  //random generated GUID
    EventName: 'SPOT 2021 AI 智能作文争霸赛',
    Organizer: 'SIM',
    OrganizerIdentity: 'simeducationgroup',
    OrganizerDisplayName: 'SIM EDUCATION GROUP',

    Date: 'Nov 11~13, 2021',       //Display only.
    DateStart: '2021-11-11',    //used as condition.
    DateEnd: '2021-11-13',      //used as condition.

    TimeStart: '10:00 AM (11.11.2021)',
    TimeEnd: '23:59 PM (13.11.2021)',

    RoomsHeldOnSelfTime: true,

    CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/cert_sim_spot_ai_2021_essay_3.jpg',
    // CertImgUrl: 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/7565a9729ecb4747b665b38c9652982e/cert_sim_spot_ai_2021_essay_3.jpg',
    CertDownloadEnabled: true,
    CertSerialPrefix: 'SCWNOV21',   //2021.11.06
    CertLayoutTypeId: 2,            //2021.11.06
    EventSharedReportUrl: '',       //2021.11.08
    CertDownloadDelayed: true,      //2021.11.08

    GovRecognitionLetterUrl: '',    //2021.12.09
    GovRecognitionLetterFileId: 0,    //2021.12.09

    ZoomInfo: null,
    FacebookInfo: null,

    Groups: [{ Id: GroupId[4].Id, Name: GroupId[4].Name }, { Id: GroupId[5].Id, Name: GroupId[5].Name }, { Id: GroupId[6].Id, Name: GroupId[6].Name }],

    QuizRooms: [
      {
        Group: GroupId[4].Name,
        GroupId: GroupId[4].Id,
        Rooms: [
          {
            Subject: 'Essay', RoomCode: '40655', Date: 'Nov 11, 2021', DateStart: '2021-11-11', DateEnd: '2021-11-13', TimeStart: '10:00 AM', TimeEnd: '11:59 PM', TimeLimit: '00:00',
            ExtraUrl: 'https://anyflip.com/dnhvk/mfjf/',
          },
        ]
      },
      {
        Group: GroupId[5].Name,
        GroupId: GroupId[5].Id,
        Rooms: [
          {
            Subject: 'Essay', RoomCode: '45371', Date: 'Nov 11, 2021', DateStart: '2021-11-11', DateEnd: '2021-11-13', TimeStart: '10:00 AM', TimeEnd: '11:59 PM', TimeLimit: '00:00',
            ExtraUrl: 'https://anyflip.com/dnhvk/ejqq/',
          },
        ]
      },
      {
        Group: GroupId[6].Name,
        GroupId: GroupId[6].Id,
        Rooms: [
          {
            Subject: 'Essay', RoomCode: '38437', Date: 'Nov 11, 2021', DateStart: '2021-11-11', DateEnd: '2021-11-13', TimeStart: '10:00 AM', TimeEnd: '11:59 PM', TimeLimit: '00:00',
            ExtraUrl: 'https://anyflip.com/dnhvk/gkfq/',
          },
        ]
      },
    ],
  },
];
export default DefaultEvents;