// import { Locale, Lang } from '../Localization/CustomLocalization';

export const isStaging = window.location.href.includes('livequiz-2');
const isLocalHost = window.location.href.includes('localhost');

//Jay added 2021.07.05
//fixed variables.
export const GlobalSetting = {
    //2024.08.15
    RootUrl: isLocalHost ? 'http://localhost:3000/' : 'https://livequiz.ikey.my/',
    // ApiUrl: 'http://localhost:55557/',
    // ApiUrl: 'https://ile-api.ikey.my/',
    // ApiUrl: 'https://ile-api-staging.ikey.my/',
    ApiUrl: isLocalHost ? 'http://localhost:55557/' : (isStaging ? 'https://ile-api-staging.ikey.my/' : 'https://ile-api.ikey.my/'),
}

//for Admin > Quiz Management Menu & navi.
export const Toggle = {
    None: '0',

    ManageEvent: 'manageEvent',
    NewEvent: 'newEvent',
    EditEvent: 'editEvent',

    ManageRoom: 'manageRoom',
    NewRoom: 'newRoom',
    EditRoom: 'editRoom',

    ManageQuestionSet: 'manageQuestionSet',
    NewQuestionSet: 'newQuestionSet',
    EditQuestionSet: 'editQuestionSet',

    ManageQuestion: 'manageQuestion',
    NewQuestion: 'newQuestion',
    EditQuestion: 'editQuestion',

    RoomTypeFile: 'manageRoomTypeFile',
    RoomTypeFileDetail: 'manageRoomTypeFileDetail',

    LiveQuizRankingList: 'liveQuizRankingList',
    EventParticipationReportLite: 'eventParticipationReport',

    //2023.09.11
    ManageStudentProfile: 'manageStudentProfile',
    ManageCustomGroup: 'manageCustomGroup',             //2023.11.28
    ManageStudentReport: 'manageStudentReport',

    //Super admin.
    EventParticipationReport_Admin: '31',
    EventParticipationReportCompleteDetail: '32',
    UserProfileManagement: '40',
    AdminTool: '41',
    ManageAuthor: 'manageAuthor',
    ManageOrganizer: 'manageOrganizer',

    Etc: '100',
    Invalid: '9999',
}

// //2023.09.11
// //for Admin > Organizer Menu & navi.
// export const OMenuToggle = {
//     None: '0',

//     ManageStudentProfile: 'manageStudentProfile',
//     ManageStudentReport: 'manageStudentReport',

//     Invalid: '9999',
// }

//2023.09.11
export const UploadState = {
    None: 'None',
    Converting: 'Converting',
    ConvertFailed: 'ConvertFailed',
    Uploading: 'Uploading',
    UploadFailed: 'UploadFailed',
    Processing: 'Processing',
    Failed: 'Failed',
    Success: 'Success',
    Saving: 'Saving',
    Validation: 'Validation',
};

//2023.09.14
export const CommonState = {
    None: 'None',
    New: 'New',
    View: 'View',
    Edit: 'Edit',
    Processing: 'Processing',
    Failed: 'Failed',
    Success: 'Success',
};

//2023.09.15
export const InputType = {
    None: 'none',
    Text: 'text',
    Number: 'number',
    Checkbox: 'checkbox',
    Select: 'select',
    // SelectWithPreCondition: 'selectWithPreCondition',
    SelectWithInputDetection: 'selectWithInputDetection',
};

//2023.09.21
export const PermissionAccessType = {
    None: '',
    View: 'view',
    Search: 'search',
    Download: 'download',
    Upload: 'upload',
    Create: 'create',
    Update: 'update',
    Delete: 'delete',
}

//2024.01.08
export const SecretKey = {
    Admin: '722B229070E44A6582034C1BAFFF8644',
    Common: '7Np5EkDX4SlgdaVITCh9JQbU',
    // Scheduler: '796Za7RZ4A9EqYjfFCX65NFq',
    // AiStation: '39ffcc60-a593-47eb-8040-c177db9e34e6',
}

export const AtoZ = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
    'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
];