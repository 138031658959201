import React from 'react';
// import moment from 'moment';
import { Lang } from '../Localization/CustomLocalization.js';

export default class LocalizationSwitcher extends React.Component {

    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    SetLocaleSetting(setting) {
        this.props.SetLocaleSetting(setting);
    }

    render() {
        return (
            <table cellPadding="2" className="float-right">
                <tbody>
                    <tr>
                        <td>
                            <button type="button"
                                className={this.props.Locale === Lang.English ? "btn btn-primary btn-block btn-xs" : "btn btn-outline-primary btn-xs"}
                                onClick={() => this.SetLocaleSetting(Lang.English)}
                                style={{
                                    backgroundColor: this.props.Locale === Lang.English ? "#007bff" : "white",
                                    borderColor: "white",
                                }}
                            ><span style={{ color: this.props.Locale === Lang.English ? "white" : "#007bff" }}>EN</span></button>
                        </td>
                        <td>
                            <button type="button"
                                className={this.props.Locale === Lang.Chinese ? "btn btn-primary btn-block btn-xs" : "btn btn-outline-primary btn-xs"}
                                onClick={() => this.SetLocaleSetting(Lang.Chinese)}
                                style={{
                                    backgroundColor: this.props.Locale === Lang.Chinese ? "#007bff" : "white",
                                    borderColor: "white",
                                }}
                            ><span style={{ color: this.props.Locale === Lang.Chinese ? "white" : "#007bff" }}>CN</span></button>
                        </td>
                        <td>
                            <button type="button"
                                className={this.props.Locale === Lang.Malay ? "btn btn-primary btn-block btn-xs" : "btn btn-outline-primary btn-xs"}
                                onClick={() => this.SetLocaleSetting(Lang.Malay)}
                                style={{
                                    backgroundColor: this.props.Locale === Lang.Malay ? "#007bff" : "white",
                                    borderColor: "white",
                                }}
                            ><span style={{ color: this.props.Locale === Lang.Malay ? "white" : "#007bff" }}>BM</span></button>
                        </td>
                    </tr>
                    {/* <tr>
                        <td colSpan={3} align='center' style={{ padding: 0 }}>
                            <span
                                style={{ fontSize: '12px', color: 'white', fontWeight: "normal", }}
                            >Login: {moment().format('lll')}</span>
                        </td>
                    </tr> */}
                </tbody>
            </table>
        )
    }
}