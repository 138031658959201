import React from "react";
import { Redirect } from 'react-router-dom';
import { ProgressBar, Row, Col } from 'react-bootstrap';
// import ReactHTMLTableToExcel from 'react-html-to-excel';
import moment from 'moment';
// import {
//     // osVersion, osName, browserName, fullBrowserVersion
//     browserName
// } from "react-device-detect";
import './LiveQuizAdminPortal.scss';
import { GlobalSetting } from '../../components/GlobalSetting';
import { CheckBoolean, GetPropIds } from '../../components/GlobalFunctions';      //2023.09.11

//Jay created 2022.05.24
export default class LiveQuizEventParticipationCompleteDetailList extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isLoading: false,
        isLoaded: false,

        EventList: [],

        toggleDetailModal: false,
        targetEventIndex: -1,
    });

    componentDidMount = () => {
        if (this.props.user === null) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }
        this.LoadEventParticipationList();
    }

    LoadEventParticipationList = async () => {
        this.setState({
            isLoading: false,
            isLoaded: false,
            EventList: [],
        });

        let { centerUserId, authorId, authorRoleId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log(centerUserId, authorId, authorRoleId);

        //dev - forced as admin.
        if (this.props.isDevMode && this.props.isSuperAdmin) {
            centerUserId = 0;
            authorId = 1;
            authorRoleId = 1;
        }

        let _List = [];
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Event/Statistic/ReportSummary/'
            + centerUserId + '/' + authorId + '/' + authorRoleId,
            // Api/LearningCentre/Event/Statistic/ReportSummary/{centerUserId}/{authorId}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('event statistic \n report list =\n\n' + JSON.stringify(data));

                if (data === undefined || data === null) {
                    if (this.props.isDevMode)
                        console.log('Error \n api \n event \n statistic report \n load list (failed).');
                }
                else {
                    if (data.success) {
                        if (data.data !== undefined && data.data !== null)
                            if (Array(data.data).length > 0)
                                _List = data.data;
                            else
                                if (this.props.isDevMode)
                                    console.log('Event Report list is empty.');
                    }
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error \n api \n event \n statistic report \n load list (failed) \n' + error.message);
            });

        //populate list.
        if (_List.length > 0) {
            let newList = [];
            _List.map((data, key) => {
                const isReportGenerated = data['totalParticipants'] !== null ? Number(data['totalParticipants']) > 0 : false;
                return newList.push({
                    ...data,
                    rowId: key,
                    toggleDetail: false,
                    reportGenerated: isReportGenerated,
                    isLoading: false,
                    isLoaded: false,
                    isUserListLoading: false,
                    isUserListLoaded: false,
                    joinedList: [],
                    otherList: [],
                });
            });
            _List = newList;
        }

        this.setState({
            isLoading: false,
            isLoaded: true,
            EventList: _List,
        });
    }

    ToggleDetail = (index = -1) => {
        if (index < 0)
            return;

        let _List = this.state.EventList;
        _List[index]['toggleDetail'] = !CheckBoolean(_List[index]['toggleDetail']);
        this.setState({
            EventList: _List,
            targetEventIndex: CheckBoolean(_List[index]['toggleDetail']) ? index : -1,
            toggleDetailModal: CheckBoolean(_List[index]['toggleDetail']),
        });
    }

    GenerateDetail = async (index = -1) => {
        if (index < 0)
            return;

        let _EventList = this.state.EventList;
        let _List = _EventList[index];
        _List.isLoading = true;
        _List.isLoaded = false;
        _EventList[index] = _List;
        this.setState({ EventList: _EventList, });

        let { centerUserId, authorId, authorRoleId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log(centerUserId, authorId, authorRoleId, _List['eventCode']);

        //dev - forced as admin.
        if (this.props.isDevMode && this.props.isSuperAdmin) {
            centerUserId = 0;
            authorId = 1;
            authorRoleId = 1;
        }

        let _data = [];

        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Event/Statistic/ReportSummary/DataAnalysis/Run/'
            + centerUserId + '/' + authorId + '/' + _List['eventCode'] + '/' + authorRoleId,
            // Api/LearningCentre/Event/Statistic/ReportSummary/DataAnalysis/Run/{centerUserId}/{authorId}/{eventCode}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('event statistic \n generate detail =\n\n' + JSON.stringify(data));

                if (data === undefined || data === null) {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - event statistic - generate detail (failed)');
                }
                else {
                    if (data.success) {
                        if (data.data !== undefined && data.data !== null)
                            _data = data.data;
                    }
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - event statistic - generate detail (failed)\n' + error.message);
            });

        //done.
        _List.isLoading = false;
        _List.isLoaded = true;
        _List.reportGenerated = _data['totalParticipants'] !== null ? Number(_data['totalParticipants']) > 0 : false;
        _EventList[index] = _List;
        this.setState({ EventList: _EventList, });
    }

    GetUserList = async (index = -1) => {
        if (index < 0)
            return;

        let _EventList = this.state.EventList;
        let _List = _EventList[index];
        _List.isUserListLoading = true;
        _List.isUserListLoaded = false;
        _EventList[index] = _List;
        this.setState({ EventList: _EventList, });

        const { centerUserId, authorId, authorRoleId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log(centerUserId, authorId, authorRoleId, _List['eventCode']);

        let _data = { JoinedList: [], OtherList: [] };

        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Event/Statistic/ReportSummary/UserList/'
            + centerUserId + '/' + authorId + '/' + _List['eventCode'] + '/' + authorRoleId,
            // Api/LearningCentre/Event/Statistic/ReportSummary/UserList/{centerUserId}/{authorId}/{eventCode}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('event statistic \n get user list =\n\n' + JSON.stringify(data));

                if (data === undefined || data === null) {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - event statistic - get user list (failed)');
                }
                else {
                    if (data.success) {
                        if (data.data !== undefined && data.data !== null)
                            _data = data.data;
                    }
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - event statistic - get user list (failed)\n' + error.message);
            });

        //done.
        _List.isUserListLoading = false;
        _List.isUserListLoaded = true;
        _List.joinedList = _data.JoinedList.length > 0 ? _data.JoinedList : [];
        _List.otherList = _data.OtherList.length > 0 ? _data.OtherList : [];
        _EventList[index] = _List;
        this.setState({ EventList: _EventList, });
    }

    TargetListTableComponent = () => {
        return (<table className="table table-bordered tb-col-hover" width='100%'>
            <thead>
                <tr>
                    <td align="center">#</td>
                    <th>Event</th>
                    <th align="center">Total</th>
                    <th align="center">Date (Begin)</th>
                    <th align="center">Date (End)</th>
                    <th align="center">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.EventList.map((data, key) => {
                        // return (<>
                        return (
                            <tr key={'row_' + key}>
                                <td align="center">{key + 1}</td>
                                <td><b>{data['eventName']}</b></td>
                                <td align="center">{data['totalParticipants']}</td>
                                <td align="center">{moment(data['startDate'], 'DD/MM/YYYY').format('DD/MMM/YYYY')}</td>
                                <td align="center">{moment(data['endDate'], 'DD/MM/YYYY').format('DD/MMM/YYYY')}</td>
                                {/* <td align="center">
                                    <button
                                        type="button"
                                        className="btn-link"
                                        onClick={() => {
                                            if (data['reportGenerated'])
                                                this.ShowDetail(key);
                                            else
                                                this.GenerateDetail(key);
                                        }}
                                    >{data['toggleDetail'] ? 'Hide' : 'Show'}</button>
                                </td> */}
                                {
                                    data['reportGenerated'] ?
                                        <>
                                            <td align="center">
                                                <button
                                                    type="button"
                                                    className="btn-link"
                                                    onClick={() => this.ToggleDetail(key)}
                                                >{data['toggleDetail'] ? 'hide' : 'detail'}</button>
                                            </td>
                                            <td align="center">
                                                <button
                                                    type="button"
                                                    className="btn-link"
                                                    onClick={() => this.GetUserList(key)}
                                                >Participants</button>
                                            </td>
                                        </>
                                        :
                                        <>
                                            <td colSpan={2} align="center">
                                                <button
                                                    type="button"
                                                    className="btn-link"
                                                    onClick={() => this.GenerateDetail(key)}
                                                >Generate Report</button>
                                            </td>
                                        </>
                                }
                            </tr>
                        );
                        // {
                        // data['toggleDetail'] === false ? null :
                        //     <tr>
                        //         <td colSpan={6}>
                        //             <table className="table table-bordered" style={{ width: 555, marginBottom: 0, }}>
                        //                 <thead>
                        //                     <tr>
                        //                         <td align="center">Group</td>
                        //                         <td align="center">Total Students</td>
                        //                         <td>Last Update</td>
                        //                     </tr>
                        //                 </thead>
                        //                 <tbody>
                        //                     {data['groups'].map((group, stdIdx) => {
                        //                         return (<tr>
                        //                             <td align="center">{group['Name']}</td>
                        //                             <td align="center">{group['TotalStudents']}</td>
                        //                             <td>{moment(group['LastUpdate'], 'YYYY-MM-DD hh:mm:ss A').add(8, 'hours').format('llll')}</td>
                        //                         </tr>);
                        //                     })}
                        //                 </tbody>
                        //             </table>
                        //         </td>
                        //     </tr>
                        // }
                        // </>);
                    })
                }
            </tbody>
        </table>);
    }

    render = () => {
        if (!this.props.user) {
            return <Redirect link="/" />;   //back to login screen.
        }
        return (<>
            <Row className='rowStyle'>
                <Col>
                    <span style={{ fontSize: 20, fontWeight: 'bold' }}>Event Participation Report (Complete Details)</span>
                </Col>
            </Row>
            <p />
            {this.state.isLoading && !this.state.isLoaded ? <ProgressBar animated now={100} /> : null}
            {!this.state.isLoading && this.state.isLoaded ? this.state.EventList.length > 0 ? this.TargetListTableComponent() : 'List is empty.' : null}
        </>);
    }
}