import React from 'react';
// import { Redirect } from 'react-router-dom';
// import {
//     // osVersion, osName, browserName, fullBrowserVersion
//     browserName
// } from "react-device-detect";
import { Col, Row, Modal, Button, ProgressBar, DropdownButton, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';
import './PageStyle.scss';
import { Locale } from '../../Localization/CustomLocalization.js';
import LoadingIndicator from '../Quiz/LoadingIndicator';
// import LoadingIndicator from '../../Screens/Quiz/LoadingIndicator.js';
import { GlobalSetting, Toggle, PermissionAccessType } from '../../components/GlobalSetting';
import { Delay, DelayUntil, ScrollToElement, GetPropIds, CheckNullValue, CheckObjectNullValue, BlockInvalidPermissionFeatureAccess, CheckObjectBoolean, CheckObjectNumber, CheckObjectStringEmpty, CheckUpdateValueDiffer, CheckBoolean, CapitalizeJsonKeys, FormatList_QuestionSet, PagingComponents } from '../../components/GlobalFunctions';      //2023.09.11

const DataInput = {
    FromDate: 0,
    ToDate: 1,
    FromTime: 2,
    ToTime: 3,
    Group: 4,
    QuestionSetId: 5,
    AccessibleOnEntireDay: 6,
    Date: 7,
    AccessibleOnSingleDayOnly: 8,
    Title: 9,
    UseCustomDuration: 10,
    Remark: 11,
    ExtraUrl: 12,
    Subject: 13,                                //2022.02.14
    RandomQuestionMode: 14,                     //2023.10.06
    RestrictAccessToTimeRangeOnly: 15,          //2023.10.26
    ForceRetrictedAccess: 16,                   //2023.10.26
    QuizEnded: 17,                              //2023.10.30
    EnableStatisticReport: 18,                  //2023.11.03
    ExcludedFromStatisticReport: 19,            //2024.04.18

    SearchQsSet_ByGroup: 51,
    SearchQsSet_BySubject: 52,
    SearchQsSet_MaxQtyShow: 53,
    SearchQsSet_SelectQuestionSetId: 54,

    RoomType: 55,
}

//2021.10.14
let RoomTypeOptions = [
    { value: 0, label: 'Basic Room', name: 'basic', subLabel: 'Basic Room', disabled: false },
    { value: 1, label: 'Document Room (File Upload feature)', name: 'file', subLabel: 'Document Room', disabled: true }
];

//2021.11.08
let FileExtOptions = [
    { value: '.doc', label: 'Word Document (doc)', name: 'doc', content_type: 'application/msword' },
    { value: '.docx', label: 'Word Document (docx)', name: 'docx', content_type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
    { value: '.txt', label: 'Plain text (txt)', name: 'txt', content_type: 'text/plain' },
    { value: '.rtf', label: 'Rich Text Format (rtf)', name: 'rtf', content_type: 'application/rtf' },
    { value: '.pdf', label: 'PDF (pdf)', name: 'pdf', content_type: 'application/pdf' }
];

//2021.06.30
export default class QuizBankManageRoom extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        //New Room.
        this.NR_FromTime = React.createRef();
        this.NR_ToTime = React.createRef();
        this.NR_FromDate = React.createRef();
        this.NR_ToDate = React.createRef();
        this.NR_Date = React.createRef();
        this.NR_CHK_AccessibleOnEntireDay = React.createRef();
        this.NR_CHK_AccessibleOnSingleDayOnly = React.createRef();
        this.NR_CHK_UseCustomDuration = React.createRef();
        this.NR_Duration_Hour = React.createRef();
        this.NR_Duration_Min = React.createRef();
        this.NR_Duration_Sec = React.createRef();
        this.New_RoomData_FileExtOptions_Ref = [];              //2021.11.08
        this.NR_CHK_RandomQuestionMode = React.createRef();                 //2023.10.06
        this.NR_CHK_RestrictAccessToTimeRangeOnly = React.createRef();      //2023.10.26
        this.NR_CHK_ForceRetrictedAccess = React.createRef();               //2023.10.26
        this.NR_CHK_QuizEnded = React.createRef();                          //2023.10.30
        this.NR_CHK_EnableStatisticReport = React.createRef();              //2023.11.03
        this.NR_CHK_ExcludedFromStatisticReport = React.createRef();        //2024.04.18

        //Edit Room.
        this.EDR_FromTime = React.createRef();
        this.EDR_ToTime = React.createRef();
        this.EDR_FromDate = React.createRef();
        this.EDR_ToDate = React.createRef();
        this.EDR_Duration_Hour = React.createRef();
        this.EDR_Duration_Min = React.createRef();
        this.EDR_Duration_Sec = React.createRef();
        this.EDR_CHK_RandomQuestionMode = React.createRef();                //2023.10.06
        this.EDR_CHK_RestrictAccessToTimeRangeOnly = React.createRef();     //2023.10.18
        this.EDR_CHK_ForceRetrictedAccess = React.createRef();              //2023.10.18
        this.EDR_CHK_QuizEnded = React.createRef();                         //2023.10.30
        this.EDR_CHK_EnableStatisticReport = React.createRef();             //2023.11.03
        this.EDR_CHK_ExcludedFromStatisticReport = React.createRef();       //2024.04.18
    }

    getInitState = () => ({

        PA_View: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageRoom, PermissionAccessType.View),
        PA_Search: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageRoom, PermissionAccessType.Search),
        PA_Create: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageRoom, PermissionAccessType.Create),
        PA_Update: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageRoom, PermissionAccessType.Update),
        PA_Delete: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageRoom, PermissionAccessType.Delete),
        PA_Upload: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageRoom, PermissionAccessType.Upload),
        PA_Download: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageRoom, PermissionAccessType.Download),

        EventList: [],
        TargetEventModal: [],
        // TargetRoomModal: [],
        ShowDeleteRoomModal: false,
        ShowCreateRoomModal: false,
        ShowEditRoomModal: false,   //2021.09.30
        ShowSearchQuestionSetModal: false,
        ShowSelectQuestionSetModal: false,

        NewRoom_Title: '',
        NewRoom_Code: 0,
        NewRoom_Code_isValid: false,
        NewRoom_DateStart: '',      //new
        NewRoom_DateEnd: '',        //new
        NewRoom_TimeStart: '',
        NewRoom_TimeEnd: '',
        NewRoom_AccessibleOnEntireDay: false,
        NewRoom_Date: '',
        NewRoom_AccessibleOnSingleDayOnly: false,
        NewRoom_Group: null,    //obj
        NewRoom_Subject: null,    //obj     //2022.02.14

        //2021.09.27
        // NewRoom_QuestionSetId: '',
        // NewRoom_QuestionSetName: '',
        NewRoom_QuestionSet: null,

        //2021.09.29
        NewRoom_UseCustomDuration: false,
        NewRoom_Duration: 0,
        NewRoom_Duration_Hour: 0,
        NewRoom_Duration_Min: 0,
        NewRoom_Duration_Sec: 0,
        NewRoom_EventCode: '',
        NewRoom_Organizer: '',
        NewRoom_SubjectName: '',

        NewRoom_RoomType: 0,            //2021.10.14
        NewRoom_Remark: '',             //2021.11.02
        NewRoom_SupportedDocExt: [],    //2021.11.08
        NewRoom_ExtraUrl: '',             //2021.12.10
        NewRoom_RandomQuestionMode: false,      //2023.10.06
        NewRoom_RestrictAccessToTimeRangeOnly: false,      //2023.10.26
        NewRoom_ForceRetrictedAccess: false,      //2023.10.26
        NewRoom_QuizEnded: false,      //2023.10.30
        NewRoom_EnableStatisticReport: false,      //2023.11.03
        NewRoom_ExcludedFromStatisticReport: false,      //2024.04.18

        // GroupList: [],  //this.props.GroupId,
        // GroupOptions: [],

        // SubjectList: [],
        // SubjectOptions: [],

        IsSearchQsSetSelected: false,
        SearchQsSet_QuestionSet_Selected: null,
        SearchQsSet_ByGroup: null,      //obj
        SearchQsSet_BySubject: null,    //obj
        SearchQsSet_MaxQtyShow: 5,
        SearchQsSet_SelectQuestionSetId: '',
        SearchQsSet_Processing: false,
        QuestionSetList: [],
        QuestionSetList_lastVisible: null,
        QuestionSetList_More_isLoading: false,

        //2021.07.21
        RoomList: [],
        RoomList_lastVisible: null,
        RoomList_More_isLoading: false,
        IsRoomListLoaded: false,
        ListMaxQueryQty: '10',
        hasTotalFileQty: false,     //2021.11.15
        previousRoomListQty: 0,     //2021.11.17

        Fetched_RoomList: [],       //2023.10.18

        //2023.11.22
        PageIndex: 0,
        PageSize: 0,
        TotalRows: 0,

        // //2021.09.27
        // EditRoom_Modal: null,
        // EditRoom_CachedModal: null,

        //2021.10.05
        AllowToCreateRoom: false,
        AllowToSaveEditedRoom: false,
        SelectedRoom: null,
        RoomData: {
            AuthorId: 0,
            CenterUserId: 0,
            Date: '',
            DateEnd: '',
            DateStart: '',
            Duration: 0,
            EventCode: '',
            Organizer: '',
            OrganizerIdentity: '',
            OrganizerId: 0,
            QuestionSetUniqueId: '',
            RoomCode: 0,
            RoomId: '',
            RoomTitle: '',
            RoomType: 0,        //0 = basic, 1 = document
            SubjectName: '',
            Subject: '',
            SubjectId: 0,
            SupportedDocExt: ['.txt', '.rtf', '.doc', '.docx'],     //2021.11.08
            TimeEnd: '',
            TimeStart: '',
            GroupId: 0,
            Grade: 0,
            Remark: '',
            ExtraUrl: '',   //2021.12.10 for Flipbook
            RandomQuestionMode: false,      //2023.10.06
            RestrictAccessToTimeRangeOnly: false,
            ForceRetrictedAccess: false,
            QuizEnded: false,
            EnableStatisticReport: false,
            ExcludedFromStatisticReport: false,
            DemoTestingChecking: false,
        },
        Cached_RoomData: null,
        Fetched_RoomData: null,     //2023.10.18
        EditRoom_QuestionSet: null,
        Cached_EditRoom_QuestionSet: null,
        EditRoom_Group: null,
        Cached_EditRoom_Group: null,
        EditRoom_Subject: null,             //2022.02.14
        Cached_EditRoom_Subject: null,      //2022.02.14
        RoomDataFS: null,

        //2021.10.29
        SelectedRoomTypeForQuery: -1,

        //2021.11.20
        IsChild: false,

        //2022.01.05
        ShowSearchRoomByRoomCodeModal: false,
        SearchRoomByRoomCode_Processing: false,
        SearchRoomByRoomCode_RoomCode: '',
    });

    componentDidMount = async () => {

        //2021.11.20
        if (this.props.IsChild && this.props.IsChild !== undefined) {
            this.setState({ IsChild: true });
            return null;
        }

        if (this.props.user === null) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }
        await this.LoadSavedListMaxQueryQtySetting();     //2021.11.17
        this.setState({
            RoomData: null,
        });
        if (this.state.EventList.length <= 0) {
            var events = this.props.Events;
            if (events !== undefined && events !== null)
                events.sort((a, b) => moment(b.DateStart) - moment(a.DateStart));
            this.setState({ EventList: events });
        }
        // this.PopulateGroupOptions();

        window.scrollTo(0, 0);
        await DelayUntil(() => this.props.GroupIsLoaded === true);
        await DelayUntil(() => this.props.SubjectIsLoaded === true);
        // this.LoadRoomList();
        this.LoadRoomList_ViaApi();
    }

    DoNothing = () => { }

    //format = 'YYYY-MM-DD hh:mm A'
    FormatTime = (_time) => {
        let time = '';
        if (String(_time).includes(' ') || String(_time).length > 8)
            time = moment(_time).format('hh:mm A');
        else
            time = moment(moment().format('YYYY-MM-DD ') + _time).format('hh:mm A');
        return time;
    }

    //format = 'MMM D, YYYY'
    FormatDate = (_data) => {
        if (_data.hasOwnProperty('DateStart')) {
            if (_data.DateStart === _data.DateEnd)
                return (<>{moment(_data.DateStart).format('ll')}</>);
            else
                return (<>{moment(_data.DateStart).format('ll')}<br />~<br />{moment(_data.DateEnd).format('ll')}</>);
        }
        else {
            return (<>{moment(_data.Date).format('ll')}</>);
        }
    }

    //2022.01.05
    GetValue = (_data, _propertyName, _default = '') => {
        if (_data !== null && _data !== undefined) {
            if (_data.hasOwnProperty(_propertyName))
                return String(_data[_propertyName]);
            else
                return _default;
        }
        return '';
    }

    SelectTargetEvent = (_idx) => {
        this.setState({
            TargetEventModal: this.state.EventList[_idx]
        });
    }

    //2021.11.17
    LoadSavedListMaxQueryQtySetting = async () => {
        let _maxQueryQty = localStorage.getItem('ManageRoom_List_MaxQueryQty_' + this.props.user.uid);
        this.setState({
            ListMaxQueryQty: _maxQueryQty !== null ? _maxQueryQty : this.state.ListMaxQueryQty,
        });
    }

    //#region === Room List === start ===//

    //2023.11.22
    LoadRoomList_ViaApi = async () => {
        // let roomList_lastVisible = null;
        let roomList = this.state.RoomList;
        let fetched_roomList = [];  //2023.10.18
        let totalRows = 0;  //2023.11.24

        //2021.11.18
        let _listRoomType = localStorage.getItem('ManageRoom_List_SelectedRoomTypeForQuery');
        if (_listRoomType === null)
            _listRoomType = this.state.SelectedRoomTypeForQuery;
        else
            if (_listRoomType !== this.state.SelectedRoomTypeForQuery)
                _listRoomType = Number(_listRoomType);

        this.setState({
            IsRoomListLoaded: false,
            RoomList: [],
            // RoomList_lastVisible: null,
            Fetched_RoomList: [],   //2023.10.18
            hasTotalFileQty: false,
            SelectedRoomTypeForQuery: _listRoomType,    //2021.11.18
            PageSize: Number(this.state.ListMaxQueryQty) < 5 ? 5 : Number(this.state.ListMaxQueryQty),     //2023.11.24
        });
        await Delay(200);

        const { authorId, organizerId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log('LoadRoomList_ViaApi', authorId, organizerId);

        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Quiz/Room/List/'
            + organizerId + '/'
            + authorId + '/'
            + this.state.SelectedRoomTypeForQuery + '/'
            + this.state.PageIndex + '/'
            + this.state.PageSize,
            // + this.state.ListMaxQueryQty,
            // Api/LearningCentre/Quiz/Room/List/{organizerId}/{authorId}/{roomType}/{pageIndex}/{pageSize}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('LoadRoomList_ViaApi (source)', JSON.stringify(data));
                if (data.success) {
                    roomList = data.data.list;
                    totalRows = CheckObjectNumber(data.data, 'totalRows', roomList.length);     //2023.11.24
                }
                else {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - room list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - room list (failed)\n' + error.message);
            });

        if (roomList.length > 0) {
            // //Finalize list.
            // for (let i = 0; i < roomList.length; i++) {
            //     roomList[i] = CapitalizeJsonKeys(roomList[i]);
            // }
            // console.log('LoadRoomList_ViaApi (CapitalizeJsonKeys)', JSON.stringify(roomList));

            let _List = []
            roomList.map((data, key) => {
                fetched_roomList.push(data);
                return _List.push(this.Populate_RoomData_with_RoomFS(data));
            });
            roomList = _List;
            if (this.props.isDevMode) {
                console.log('LoadRoomList_ViaApi (final)', JSON.stringify(roomList));
                // console.log('LoadRoomList_ViaApi (fetched_roomList)', JSON.stringify(fetched_roomList));
            }
        }
        this.setState({
            TotalRows: totalRows,
            RoomList: roomList,
            // RoomList_lastVisible: roomList_lastVisible,
            IsRoomListLoaded: true,
            Fetched_RoomList: fetched_roomList,
        }, () => {
            if (this.state.SelectedRoomTypeForQuery === 1) {
                //Document Room
                if (this.state.RoomList.length > 0) {
                    this.LoadRoomUploadedFileQty();
                }
            }
        });
    }
    LoadMoreRoomList_ViaApi = () => {
        this.setState({
            // PageIndex: this.state.PageIndex + Number(this.state.ListMaxQueryQty),
            // PageSize: this.state.PageSize + Number(this.state.ListMaxQueryQty),
        }, () => {
            this.LoadRoomList_ViaApi();
        });
    }
    //#region === Paging Components
    //2023.12.07
    CallbackFunctionForPagingComponents_PageSize = (pageSize = 5) => {
        this.setState({
            ListMaxQueryQty: pageSize,
        }, () => {
            localStorage.setItem('ManageRoom_List_MaxQueryQty_' + this.props.user.uid, this.state.ListMaxQueryQty);
            setTimeout(() => {
                this.LoadRoomList_ViaApi();
            }, 500);
        });
    }
    //2023.12.07
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadRoomList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components

    //2021.10.28 revamped. added RoomType 1 (Document Room).
    LoadRoomList = async () => {
        let roomList_lastVisible = null;
        let roomList = [];
        let fetched_roomList = [];  //2023.10.18

        //2021.11.18
        let _listRoomType = localStorage.getItem('ManageRoom_List_SelectedRoomTypeForQuery');
        if (_listRoomType === null)
            _listRoomType = this.state.SelectedRoomTypeForQuery;
        else
            if (_listRoomType !== this.state.SelectedRoomTypeForQuery)
                _listRoomType = Number(_listRoomType);

        this.setState({
            IsRoomListLoaded: false,
            RoomList: [],
            RoomList_lastVisible: null,
            Fetched_RoomList: [],   //2023.10.18
            hasTotalFileQty: false,
            SelectedRoomTypeForQuery: _listRoomType,    //2021.11.18
        });

        //#region old codes
        // if (this.props.isSuperAdmin) {
        //     if (this.props.roomTypeFile === true || this.state.SelectedRoomTypeForQuery > 0) {
        //         //RoomType = 1 (File Upload, Document Room)
        //         await this.props.firestore
        //             .collection("LiveQuiz_UniqueRoomCode")
        //             .where('RoomType', '==', this.state.SelectedRoomTypeForQuery)
        //             .orderBy('Date', 'desc')
        //             .limit(5)
        //             .get()
        //             .then(querySnapshot => {
        //                 let dataArray = [];
        //                 if (querySnapshot !== null) {
        //                     querySnapshot.forEach((doc) => {
        //                         dataArray.push(doc.data());
        //                     });
        //                     if (dataArray.length > 0) {
        //                         roomList = dataArray;
        //                         roomList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        //                         // roomList_lastVisible = dataArray[0];
        //                     }
        //                 }
        //                 // if (this.props.isDevMode)
        //                 //     console.log(JSON.stringify(roomList));
        //             })
        //             .catch(error => {
        //                 if (this.props.isDevMode)
        //                     console.log(error.message);
        //             });
        //     }
        //     else {
        //         await this.props.firestore
        //             .collection("LiveQuiz_UniqueRoomCode")
        //             .orderBy('Date', 'desc')
        //             .limit(5)
        //             .get()
        //             .then(querySnapshot => {
        //                 let dataArray = [];
        //                 if (querySnapshot !== null) {
        //                     querySnapshot.forEach((doc) => {
        //                         dataArray.push(doc.data());
        //                     });
        //                     if (dataArray.length > 0) {
        //                         roomList = dataArray;
        //                         roomList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        //                         // roomList_lastVisible = dataArray[0];
        //                     }
        //                 }
        //                 // if (this.props.isDevMode)
        //                 //     console.log(JSON.stringify(roomList));
        //             })
        //             .catch(error => {
        //                 if (this.props.isDevMode)
        //                     console.log(error.message);
        //             });
        //     }
        // }
        // else {
        //     // console.log('CenterUserId = ' + Number(this.props.user.CenterUserId));
        //     // console.log('AuthorId = ' + Number(this.props.user.AuthorId));

        //     if (this.props.roomTypeFile === true || this.state.SelectedRoomTypeForQuery > 0) {
        //         //RoomType = 1 (File Upload, Document Room)
        //         await this.props.firestore
        //             .collection("LiveQuiz_UniqueRoomCode")
        //             .where('CenterUserId', '==', Number(this.props.user.CenterUserId))
        //             .where('AuthorId', '==', Number(this.props.user.AuthorId))
        //             .where('RoomType', '==', this.state.SelectedRoomTypeForQuery)
        //             .orderBy('Date', 'desc')
        //             .limit(5)
        //             .get()
        //             .then(querySnapshot => {
        //                 let dataArray = [];
        //                 if (querySnapshot !== null) {
        //                     querySnapshot.forEach((doc) => {
        //                         dataArray.push(doc.data());
        //                     });
        //                     if (dataArray.length > 0) {
        //                         roomList = dataArray;
        //                         roomList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        //                         // roomList_lastVisible = dataArray[0];
        //                     }
        //                 }
        //                 // if (this.props.isDevMode)
        //                 //     console.log(JSON.stringify(roomList));
        //             })
        //             .catch(error => {
        //                 if (this.props.isDevMode)
        //                     console.log(error.message);
        //             });
        //     }
        //     else {
        //         //RoomType = 0 (default, Basic Room)
        //         await this.props.firestore
        //             .collection("LiveQuiz_UniqueRoomCode")
        //             .where('CenterUserId', '==', Number(this.props.user.CenterUserId))
        //             .where('AuthorId', '==', Number(this.props.user.AuthorId))
        //             // .where('RoomType', '<', 1)
        //             // .orderBy('RoomType', 'asc')
        //             .orderBy('Date', 'desc')
        //             .limit(5)
        //             .get()
        //             .then(querySnapshot => {
        //                 let dataArray = [];
        //                 if (querySnapshot !== null) {
        //                     querySnapshot.forEach((doc) => {
        //                         dataArray.push(doc.data());
        //                     });
        //                     if (dataArray.length > 0) {
        //                         roomList = dataArray;
        //                         roomList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        //                         // roomList_lastVisible = dataArray[0];
        //                     }
        //                 }
        //                 // if (this.props.isDevMode)
        //                 //     console.log(JSON.stringify(roomList));
        //             })
        //             .catch(error => {
        //                 if (this.props.isDevMode)
        //                     console.log(error.message);
        //             });
        //     }
        // }
        //#endregion old codes

        //2023.09.09
        const { centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log('LoadRoomList', centerUserId, authorId, authorRoleId, organizerId);

        //2021.11.16 - revamped.
        let docRef = this.props.firestore.collection("LiveQuiz_UniqueRoomCode");
        if (this.props.isSuperAdmin) {
            if (this.props.roomTypeFile === true || this.state.SelectedRoomTypeForQuery >= 0) {
                docRef = docRef.where('RoomType', '==', this.state.SelectedRoomTypeForQuery);
            }
            else { }
        }
        else {
            if (this.props.roomTypeFile === true || this.state.SelectedRoomTypeForQuery >= 0) {
                if (organizerId > 0) {
                    //2023.09.09
                    docRef = docRef
                        .where('OrganizerId', '==', organizerId)
                        .where('RoomType', '==', this.state.SelectedRoomTypeForQuery);
                }
                else {
                    docRef = docRef
                        .where('CenterUserId', '==', centerUserId)
                        .where('AuthorId', '==', authorId)
                        .where('RoomType', '==', this.state.SelectedRoomTypeForQuery);
                }
            }
            else {
                if (organizerId > 0) {
                    //2023.09.09
                    docRef = docRef
                        .where('OrganizerId', '==', organizerId);
                }
                else {
                    docRef = docRef
                        .where('CenterUserId', '==', centerUserId)
                        .where('AuthorId', '==', authorId);
                }
            }
        }
        await docRef.orderBy('Date', 'desc')
            .limit(Number(this.state.ListMaxQueryQty))
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                    });
                    if (dataArray.length > 0) {
                        roomList = dataArray;
                        roomList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                        // roomList_lastVisible = dataArray[0];
                    }
                }
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(roomList));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });

        //2022.06.04
        let _List = []
        roomList.map((data, key) => {
            fetched_roomList.push(data);    //2023.10.18
            return _List.push(this.Populate_RoomData_with_RoomFS(data));
        });
        roomList = _List;

        this.setState({
            RoomList: roomList,
            RoomList_lastVisible: roomList_lastVisible,
            IsRoomListLoaded: true,
            Fetched_RoomList: fetched_roomList,     //2023.10.18
        }, () => {
            if (this.state.SelectedRoomTypeForQuery === 1) {
                //Document Room
                if (this.state.RoomList.length > 0) {
                    this.LoadRoomUploadedFileQty();     //2021.11.15
                }
            }
        });
    }
    //2021.10.28 revamped. added RoomType 1 (Document Room).
    LoadMoreRoomList = async () => {

        //2021.11.18
        let _listRoomType = localStorage.getItem('ManageRoom_List_SelectedRoomTypeForQuery');
        if (_listRoomType === null)
            _listRoomType = this.state.SelectedRoomTypeForQuery;
        else
            if (_listRoomType !== this.state.SelectedRoomTypeForQuery)
                _listRoomType = Number(_listRoomType);

        this.setState({
            RoomList_More_isLoading: true,
            IsRoomListLoaded: false,
            previousRoomListQty: this.state.RoomList.length,    //2021.11.17
            SelectedRoomTypeForQuery: _listRoomType,    //2021.11.18
        });
        let more_roomList = [];
        let _roomList = this.state.RoomList;
        let roomList_lastVisible = null;
        let fetched_RoomList = [];     //2023.10.18
        let _fetched_roomList = this.state.Fetched_RoomList;    //2023.10.26

        //#region old codes
        // if (this.props.isSuperAdmin) {
        //     if (this.props.roomTypeFile === true || this.state.SelectedRoomTypeForQuery > 0) {
        //         //RoomType = 1 (File Upload, Document Room)
        //         await this.props.firestore
        //             .collection('LiveQuiz_UniqueRoomCode')
        //             .where('RoomType', '==', this.state.SelectedRoomTypeForQuery)
        //             .orderBy("Date", "desc")
        //             .startAfter(this.state.RoomList_lastVisible)
        //             .limit(Number(this.state.ListMaxQueryQty))
        //             .get()
        //             .then((querySnapshot) => {
        //                 let dataArray = [];
        //                 if (querySnapshot !== null) {
        //                     querySnapshot.forEach((doc) => { dataArray.push(doc.data()); });
        //                     // console.log(JSON.stringify(dataArray));
        //                     if (dataArray.length > 0) {
        //                         roomList = dataArray;
        //                         roomList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        //                         // roomList_lastVisible = dataArray[0];
        //                     }
        //                 }
        //             })
        //             .catch(error => {
        //                 console.log(error.message);
        //             });
        //     }
        //     else {
        //         //RoomType = 0 (default, Basic Room)
        //         await this.props.firestore
        //             .collection('LiveQuiz_UniqueRoomCode')
        //             // .where('RoomType', '<', 1)
        //             // .orderBy('RoomType', 'asc')
        //             .orderBy("Date", "desc")
        //             .startAfter(this.state.RoomList_lastVisible)
        //             .limit(Number(this.state.ListMaxQueryQty))
        //             .get()
        //             .then((querySnapshot) => {
        //                 let dataArray = [];
        //                 if (querySnapshot !== null) {
        //                     querySnapshot.forEach((doc) => { dataArray.push(doc.data()); });
        //                     // console.log(JSON.stringify(dataArray));
        //                     if (dataArray.length > 0) {
        //                         roomList = dataArray;
        //                         roomList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        //                         // roomList_lastVisible = dataArray[0];
        //                     }
        //                 }
        //             })
        //             .catch(error => {
        //                 console.log(error.message);
        //             });
        //     }
        // }
        // else {
        //     if (this.props.roomTypeFile === true || this.state.SelectedRoomTypeForQuery > 0) {
        //         //RoomType = 1 (Upload File, Document Room)
        //         await this.props.firestore
        //             .collection('LiveQuiz_UniqueRoomCode')
        //             .where('CenterUserId', '==', Number(this.props.user.CenterUserId))
        //             .where('AuthorId', '==', Number(this.props.user.AuthorId))
        //             .where('RoomType', '==', this.state.SelectedRoomTypeForQuery)
        //             .orderBy("Date", "desc")
        //             .startAfter(this.state.RoomList_lastVisible)
        //             .limit(Number(this.state.ListMaxQueryQty))
        //             .get()
        //             .then((querySnapshot) => {
        //                 let dataArray = [];
        //                 if (querySnapshot !== null) {
        //                     querySnapshot.forEach((doc) => { dataArray.push(doc.data()); });
        //                     // console.log(JSON.stringify(dataArray));
        //                     if (dataArray.length > 0) {
        //                         roomList = dataArray;
        //                         roomList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        //                         // roomList_lastVisible = dataArray[0];
        //                     }
        //                 }
        //             })
        //             .catch(error => {
        //                 console.log(error.message);
        //             });
        //     }
        //     else {
        //         //RoomType = 0 (default, Basic Room)
        //         await this.props.firestore
        //             .collection('LiveQuiz_UniqueRoomCode')
        //             .where('CenterUserId', '==', Number(this.props.user.CenterUserId))
        //             .where('AuthorId', '==', Number(this.props.user.AuthorId))
        //             // .where('RoomType', '<', 1)
        //             // .orderBy('RoomType', 'asc')
        //             .orderBy("Date", "desc")
        //             .startAfter(this.state.RoomList_lastVisible)
        //             .limit(Number(this.state.ListMaxQueryQty))
        //             .get()
        //             .then((querySnapshot) => {
        //                 let dataArray = [];
        //                 if (querySnapshot !== null) {
        //                     querySnapshot.forEach((doc) => { dataArray.push(doc.data()); });
        //                     // console.log(JSON.stringify(dataArray));
        //                     if (dataArray.length > 0) {
        //                         roomList = dataArray;
        //                         roomList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        //                         // roomList_lastVisible = dataArray[0];
        //                     }
        //                 }
        //             })
        //             .catch(error => {
        //                 console.log(error.message);
        //             });
        //     }
        // }

        // //2021.11.17 - revamped.
        // let docRef = this.props.firestore.collection("LiveQuiz_UniqueRoomCode");
        // if (this.props.isSuperAdmin) {
        //     if (this.props.roomTypeFile === true || this.state.SelectedRoomTypeForQuery >= 0) {
        //         docRef = docRef.where('RoomType', '==', this.state.SelectedRoomTypeForQuery);
        //     }
        //     else { }
        // }
        // else {
        //     if (this.props.roomTypeFile === true || this.state.SelectedRoomTypeForQuery >= 0) {
        //         docRef = docRef
        //             .where('CenterUserId', '==', Number(this.props.user.CenterUserId))
        //             .where('AuthorId', '==', Number(this.props.user.AuthorId))
        //             .where('RoomType', '==', this.state.SelectedRoomTypeForQuery);
        //     }
        //     else {
        //         docRef = docRef
        //             .where('CenterUserId', '==', Number(this.props.user.CenterUserId))
        //             .where('AuthorId', '==', Number(this.props.user.AuthorId));
        //     }
        // }
        //#endregion old codes

        //2023.09.09
        const { centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log('LoadRoomList', centerUserId, authorId, authorRoleId, organizerId);

        //2023.09.09
        let docRef = this.props.firestore.collection("LiveQuiz_UniqueRoomCode");
        if (this.props.isSuperAdmin) {
            if (this.props.roomTypeFile === true || this.state.SelectedRoomTypeForQuery >= 0) {
                docRef = docRef.where('RoomType', '==', this.state.SelectedRoomTypeForQuery);
            }
            else { }
        }
        else {
            if (this.props.roomTypeFile === true || this.state.SelectedRoomTypeForQuery >= 0) {
                if (organizerId > 0) {
                    docRef = docRef
                        .where('OrganizerId', '==', organizerId)
                        .where('RoomType', '==', this.state.SelectedRoomTypeForQuery);
                }
                else {
                    docRef = docRef
                        .where('CenterUserId', '==', centerUserId)
                        .where('AuthorId', '==', authorId)
                        .where('RoomType', '==', this.state.SelectedRoomTypeForQuery);
                }
            }
            else {
                if (organizerId > 0) {
                    docRef = docRef
                        .where('OrganizerId', '==', organizerId);
                }
                else {
                    docRef = docRef
                        .where('CenterUserId', '==', centerUserId)
                        .where('AuthorId', '==', authorId);
                }
            }
        }

        await docRef.orderBy("Date", "desc")
            .startAfter(this.state.RoomList_lastVisible)
            .limit(Number(this.state.ListMaxQueryQty))
            .get()
            .then((querySnapshot) => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => { dataArray.push(doc.data()); });
                    // console.log(JSON.stringify(dataArray));
                    if (dataArray.length > 0) {
                        more_roomList = dataArray;
                        roomList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                        // roomList_lastVisible = dataArray[0];
                    }
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });

        if (more_roomList.length > 0) {

            //2022.06.04
            let _List = []
            more_roomList.map((data, key) => {
                fetched_RoomList.push(data);   //2023.10.18
                return _List.push(this.Populate_RoomData_with_RoomFS(data));
            });
            more_roomList = _List;

            // temp_roomList = this.state.RoomList;
            more_roomList.map((data, key) => {
                let findIndex = _roomList.findIndex(x => String(x.RoomCode) === String(data.RoomCode)); //2022.01.05
                if (findIndex < 0) {
                    _roomList.push(data);
                }
                return null;
            });
            _roomList.sort((a, b) => moment(b.Date) - moment(a.Date));

            //2023.10.26
            fetched_RoomList.map((data, key) => {
                let findIndex = _fetched_roomList.findIndex(x => String(x.RoomCode) === String(data.RoomCode)); //2022.01.05
                if (findIndex < 0) {
                    _fetched_roomList.push(data);
                }
                return null;
            });
            _fetched_roomList.sort((a, b) => moment(b.Date) - moment(a.Date));
        }
        this.setState({
            RoomList: _roomList,
            RoomList_lastVisible: more_roomList.length > 0 ? roomList_lastVisible : this.state.RoomList_lastVisible,
            RoomList_More_isLoading: false,
            IsRoomListLoaded: true,
            Fetched_RoomList: _fetched_roomList,     //2023.10.18   //2023.10.26
        }, () => {
            if (this.state.SelectedRoomTypeForQuery === 1) {
                //Document Room
                if (this.state.RoomList.length > 0) {
                    this.LoadRoomUploadedFileQty();     //2021.11.15
                }
            }
            if (this.state.previousRoomListQty < this.state.RoomList.length)
                ScrollToElement('bottomPage', 500);
        });
    }
    //2021.11.15
    LoadRoomUploadedFileQty = async () => {
        if (this.state.RoomList.length > 0) {
            let _roomList = this.state.RoomList;
            await Promise.all(
                this.state.RoomList.map(async (data, key) => {
                    if (_roomList[key].TotalFile === undefined) {
                        _roomList[key].TotalFile = '0';
                        await fetch(GlobalSetting.ApiUrl
                            + 'Api/LearningCentre/Quiz/Room/File/RequestFileCount/'
                            + (data.hasOwnProperty('RoomCode') ? String(data.RoomCode) : '') + '/'
                            + (data.hasOwnProperty('RoomId') ? String(data.RoomId) : ''),
                            // Api/LearningCentre/Quiz/Room/File/RequestFileCount/{roomcode}/{room_id}
                            {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                            })
                            .then(res => res.json())
                            .then(data => {
                                if (!data.success)
                                    if (this.props.isDevMode)
                                        console.log('Error', 'api - room total file (failed)\n' + JSON.stringify(data));

                                if (data.data !== undefined)
                                    _roomList[key].TotalFile = String(data.data);

                                if (this.props.isDevMode)
                                    console.log(JSON.stringify(data));
                            })
                            .catch(error => {
                                if (this.props.isDevMode)
                                    console.log('Error', 'api - room total file (failed)\n' + error.message);
                            });
                    }
                    return null;
                })
            );
            this.setState({
                RoomList: _roomList,
                hasTotalFileQty: true,
            }, () => {
                // setTimeout(() => {
                //     console.log(JSON.stringify(this.state.RoomList));
                //     this.setState({
                //         // IsRoomListLoaded: true,
                //         hasTotalFileQty: true,
                //     });
                // }, 500);
            });
        }
    }
    RoomListComponents = () => {
        if (this.state.RoomList.length > 0) {
            let listRows = [];
            this.state.RoomList.map((data, key) => {
                return listRows.push(
                    <tr key={'room_' + key}>
                        <td>{key + 1 + this.state.PageIndex}</td>
                        <td>{data.RoomCode}</td>
                        <td align={'left'}>{data.RoomTitle}{
                            data.hasOwnProperty('GroupId') ?
                                data.GroupId > 0 ?
                                    <><br /><span style={{ color: 'gray', fontSize: 12, }}>{this.GetGroupName(data)}</span></>
                                    : null
                                : null
                        }{
                                data.hasOwnProperty('RoomType') ?
                                    data.RoomType === 1 ?
                                        String(data.Remark).length > 0 ?
                                            <>
                                                <br /><span style={{ color: 'gray', fontSize: 12, }}>{'< ' + data.Remark + ' >'}</span>
                                            </>
                                            : null
                                        : null
                                    : null
                            }</td>
                        <td hidden={this.state.hasTotalFileQty === false}>{
                            data.hasOwnProperty('TotalFile') ?
                                String(data.TotalFile)
                                : '0'
                        }</td>
                        <td>{data.SubjectName}</td>
                        <td>{data.TimeStart === '_none' ? '12:00 AM' : this.FormatTime(data.TimeStart)}</td>
                        <td>{data.TimeEnd === '_none' ? '11:59 PM' : this.FormatTime(data.TimeEnd)}</td>
                        <td>{this.FormatDate(data)}</td>
                        <td style={{ padding: 5 }}>
                            {/* <button type="button" className="btn btn-outline-primary"
                                    // onClick={() => this.GotoEditRoom(data)}
                                    onClick={() => this.LoadSelectedRoom(data.RoomCode, data.RoomId)}   //2021.10.05
                                >Edit</button> */}
                            {
                                // //2021.10.29 - revamped
                                // // this.props.roomTypeFile === true
                                // //     ||
                                // //     (data.hasOwnProperty('RoomType') ? (data.RoomType === 1 ? true : false) : false)

                                // //2021.11.01
                                // (data.hasOwnProperty('RoomType') ? (data.RoomType === 1 ? true : false) : false)
                                //     ?
                                //     <button type="button" className="btn btn-outline-primary"
                                //         onClick={() => this.props.TogglePage(this.props.Toggle.RoomTypeFileDetail, data)}
                                //     >Detail</button>
                                //     :
                                //     <button type="button" className="btn btn-outline-primary"
                                //         onClick={() => this.LoadSelectedRoom(data.RoomCode, data.RoomId)}   //2021.10.05
                                //     >Edit</button>
                            }

                            {/* //2021.12.10 - new pattern. */}
                            {
                                this.state.PA_Update === false ? null :
                                    <button type="button" className="btn btn-outline-primary"
                                        onClick={() => this.LoadSelectedRoom(data.RoomCode, data.RoomId)}   //2021.10.05
                                        style={{ width: '100%', }}
                                    >Edit</button>
                            }
                            {
                                this.state.PA_Update === false ? null :
                                    <button type="button" className="btn btn-outline-primary"
                                        hidden={data.hasOwnProperty('RoomType') ? (data.RoomType === 1 ? false : true) : true}
                                        onClick={() => this.props.TogglePage(this.props.Toggle.RoomTypeFileDetail, data)}
                                        style={{ width: '100%', marginTop: 5, }}
                                    >Detail</button>
                            }
                            {
                                this.state.PA_Update === false ? null :
                                    <button type="button" className="btn btn-outline-primary"
                                        onClick={() => this.LoadResultFromSelectedRoom(data.RoomCode, data.RoomId)}   //2023.11.24
                                        style={{ width: '100%', marginTop: 5, }}
                                    >Result</button>
                            }
                        </td>
                    </tr >
                );
            });
            return listRows;
        }
        return this.state.IsRoomListLoaded ?
            <tr><td colSpan='15' align='center'>list is empty</td></tr>
            : <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>;
    }
    //#endregion === Room List === end ===//


    GetRoomDataViaRoomCode = async (_roomCode) => {
        let done = false;
        let _success = false;
        let _roomInfo = null;
        let async_action = async () => {
            await this.props.firestore
                .collection("LiveQuiz_UniqueRoomCode")
                .where('RoomCode', '==', Number(_roomCode))
                .orderBy('DateStart', 'asc')
                .get()
                .then(querySnapshot => {
                    let data = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            data.push(doc.data());
                        });
                        if (data.length > 0 || data.hasOwnProperty('RoomId'))
                            data = data[0];
                        else
                            data = null;
                    }
                    if (data !== null && data.hasOwnProperty('RoomId')) {
                        _roomInfo = data;
                    }
                    if (this.props.isDevMode)
                        console.log("Room Code : " + _roomCode + "\nModal : " + JSON.stringify(_roomInfo));
                    _success = true;
                    done = true;
                })
                .catch(error => {
                    _success = false;
                    done = true;
                    if (this.props.isDevMode)
                        console.log('bad Internet connection - retrying...\n\n' + JSON.stringify(error));
                });
            await DelayUntil(() => done === true);
        }
        do {
            _success = false;
            done = false;
            await async_action();
            // await DelayUntil(() => done === true);
        } while (_success === false);
        return _roomInfo;
    }
    SaveDataInput = (_value, _inputType) => {
        let _selected = null;
        switch (_inputType) {
            default: break;
            case DataInput.Title:
                this.setState({ NewRoom_Title: _value });
                break;
            case DataInput.FromDate:
                this.setState({ NewRoom_DateStart: _value });
                break;
            case DataInput.ToDate:
                this.setState({ NewRoom_DateEnd: _value });
                break;
            case DataInput.FromTime:
                //2021.10.05
                // let timeSet1 = moment(moment().format('YYYY-MM-DD ') + _value + ':00');
                // let startTime1 = moment(moment().format('YYYY-MM-DD ') + '00:00:00');
                // let endTime1 = moment(moment().format('YYYY-MM-DD ') + '23:59:00');
                // _value = timeSet1 >= startTime1 && timeSet1 <= endTime1 ? _value : '00:00';

                this.setState({ NewRoom_TimeStart: _value },
                    () => {
                        this.NR_FromTime.current.value = this.state.NewRoom_TimeStart;
                        this.RecalculateDuration();
                    });
                break;
            case DataInput.ToTime:
                //2021.10.05
                // let timeSet2 = moment(moment().format('YYYY-MM-DD ') + _value + ':00');
                // let startTime2 = moment(moment().format('YYYY-MM-DD ') + '00:00:00');
                // let endTime2 = moment(moment().format('YYYY-MM-DD ') + '23:59:00');
                // _value = timeSet2 >= startTime2 && timeSet2 <= endTime2 ? _value : '23:59';

                this.setState({
                    NewRoom_TimeEnd: _value
                }, () => {
                    this.NR_ToTime.current.value = this.state.NewRoom_TimeEnd;
                    this.RecalculateDuration();
                });
                break;
            case DataInput.AccessibleOnEntireDay:
                this.setState({
                    NewRoom_AccessibleOnEntireDay: CheckBoolean(_value),
                }, () => {
                    if (this.state.NewRoom_AccessibleOnEntireDay) {
                        // this.SaveDataInput('00:00:00', DataInput.FromTime);
                        // this.SaveDataInput('23:59:00', DataInput.ToTime);
                        this.setState({
                            NewRoom_TimeStart: '00:00',
                            NewRoom_TimeEnd: '23:59',
                            NewRoom_Duration: 0,
                        }, () => {
                            this.NR_FromTime.current.value = this.state.NewRoom_TimeStart;
                            this.NR_ToTime.current.value = this.state.NewRoom_TimeEnd;
                            this.RecalculateDuration();
                        });
                    }
                    this.NR_CHK_AccessibleOnEntireDay.current.checked = this.state.NewRoom_AccessibleOnEntireDay;
                });
                //     NewRoom_TimeStart: null, NewRoom_TimeEnd: null
                // }, () => {
                //     this.NR_FromTime.current.value = null;
                //     this.NR_ToTime.current.value = null;
                // });
                break;
            case DataInput.Date:
                this.setState({
                    NewRoom_Date: _value,
                }, () => {
                    if (this.state.NewRoom_AccessibleOnSingleDayOnly) {
                        // this.NR_Date.current.value = this.state.NewRoom_Date;
                        this.setState({
                            NewRoom_DateStart: this.state.NewRoom_Date,
                            NewRoom_DateEnd: this.state.NewRoom_Date,
                        });
                    }
                    this.RecalculateDuration();
                });
                break;
            case DataInput.AccessibleOnSingleDayOnly:
                // if (_value) {
                //     this.NR_FromDate.current.value = '';
                //     this.NR_ToDate.current.value = '';
                // }
                this.setState({
                    NewRoom_AccessibleOnSingleDayOnly: _value, //NewRoom_DateStart: null, NewRoom_DateEnd: null

                    //2021.10.05
                    NewRoom_DateStart: '',
                    NewRoom_DateEnd: '',
                    NewRoom_Date: '',
                }, () => {
                    if (this.state.NewRoom_AccessibleOnSingleDayOnly) {
                        this.NR_Date.current.value = this.state.NewRoom_Date;
                        // this.setState({
                        //     NewRoom_DateStart: this.state.NewRoom_Date,
                        //     NewRoom_DateEnd: this.state.NewRoom_Date,
                        // });
                    }
                    else {
                        this.NR_FromDate.current.value = this.state.NewRoom_DateStart;
                        this.NR_ToDate.current.value = this.state.NewRoom_DateEnd;
                    }
                    this.NR_CHK_AccessibleOnSingleDayOnly.current.checked = this.state.NewRoom_AccessibleOnSingleDayOnly;
                    this.RecalculateDuration();
                });
                break;
            case DataInput.UseCustomDuration:
                this.setState({
                    NewRoom_UseCustomDuration: CheckBoolean(_value),
                }, () => {
                    // if (this.state.NewRoom_UseCustomDuration) {
                    this.setState({
                        NewRoom_Duration_Hour: 0,
                        NewRoom_Duration_Min: 0,
                        NewRoom_Duration_Sec: 0,
                    }, () => {
                        this.NR_CHK_UseCustomDuration.current.checked = this.state.NewRoom_UseCustomDuration;
                        this.RecalculateDuration();
                    });
                    // }
                });
                break;
            case DataInput.Group:
                _selected = null;
                this.props.GroupOptions.map((data, key) => {
                    if (_value.value === data.value && _value.id === data.id)
                        _selected = data;
                    return null;
                });
                this.setState({ NewRoom_Group: _selected });
                break;
            case DataInput.Subject:
                //2022.02.14
                _selected = null;
                this.props.SubjectOptions.map((data, key) => {
                    if (_value.value === data.value && _value.id === data.id)
                        _selected = data;
                    return null;
                });
                this.setState({ NewRoom_Subject: _selected });
                break;
            case DataInput.Remark:
                this.setState({ NewRoom_Remark: _value });
                break;
            case DataInput.RandomQuestionMode:
                //2023.10.06
                this.setState({
                    NewRoom_RandomQuestionMode: CheckBoolean(_value),
                }, () => {
                    this.NR_CHK_RandomQuestionMode.current.checked = this.state.NewRoom_RandomQuestionMode;
                });
                break;
            case DataInput.RestrictAccessToTimeRangeOnly:
                //2023.10.26
                this.setState({
                    NewRoom_RestrictAccessToTimeRangeOnly: CheckBoolean(_value),
                }, () => {
                    this.NR_CHK_RestrictAccessToTimeRangeOnly.current.checked = this.state.NewRoom_RestrictAccessToTimeRangeOnly;
                });
                break;
            case DataInput.ForceRetrictedAccess:
                //2023.10.26
                this.setState({
                    NewRoom_ForceRetrictedAccess: CheckBoolean(_value),
                }, () => {
                    this.NR_CHK_ForceRetrictedAccess.current.checked = this.state.NewRoom_ForceRetrictedAccess;
                });
                break;
            case DataInput.QuizEnded:
                //2023.10.30
                this.setState({
                    NewRoom_QuizEnded: CheckBoolean(_value),
                }, () => {
                    this.NR_CHK_QuizEnded.current.checked = this.state.NewRoom_QuizEnded;
                });
                break;
            case DataInput.EnableStatisticReport:
                //2023.11.03
                this.setState({
                    NewRoom_EnableStatisticReport: CheckBoolean(_value),
                }, () => {
                    this.NR_CHK_EnableStatisticReport.current.checked = this.state.NewRoom_EnableStatisticReport;
                });
                break;
            case DataInput.ExcludedFromStatisticReport:
                //2023.11.03
                this.setState({
                    NewRoom_ExcludedFromStatisticReport: CheckBoolean(_value),
                }, () => {
                    this.NR_CHK_ExcludedFromStatisticReport.current.checked = this.state.NewRoom_ExcludedFromStatisticReport;
                });
                break;

            //Select Question Set - Search By...
            case DataInput.SearchQsSet_ByGroup:
                _selected = null;
                this.props.GroupOptions.map((data, key) => {
                    if (_value.value === data.value && _value.id === data.id)
                        _selected = data;
                    return null;
                });
                this.setState({ SearchQsSet_ByGroup: _selected });
                break
            case DataInput.SearchQsSet_BySubject:
                //2022.02.11
                _selected = null;
                this.props.SubjectOptions.map((data, key) => {
                    if (_value.value === data.value && _value.id === data.id)
                        _selected = data;
                    return null;
                });
                this.setState({ SearchQsSet_BySubject: _selected });
                break
            case DataInput.SearchQsSet_MaxQtyShow:
                this.setState({ SearchQsSet_MaxQtyShow: _value });
                break
        }
        if (this.props.isDevMode)
            setTimeout(() => {
                console.log(
                    "\nRoom Title : " + this.state.NewRoom_Title + "\n" +
                    "Duration : " + this.state.NewRoom_Duration + "\n" +
                    "Accessible Single Date : " + this.state.NewRoom_AccessibleOnSingleDayOnly + "\n" +
                    "Accessible Entire Day : " + this.state.NewRoom_AccessibleOnEntireDay + "\n" +
                    "Date : " + this.state.NewRoom_Date + "\n" +
                    "From Date : " + this.state.NewRoom_DateStart + "\n" +
                    "To Date : " + this.state.NewRoom_DateEnd + "\n" +
                    "From Time : " + this.state.NewRoom_TimeStart + "\n" +
                    "To Time : " + this.state.NewRoom_TimeEnd + "\n" +
                    "Group : " + (this.state.NewRoom_Group !== null ? this.state.NewRoom_Group.label + " (Id: " + this.state.NewRoom_Group.id + ")\n" : "null\n") +
                    "Subject : " + (this.state.NewRoom_Subject !== null ? this.state.NewRoom_Subject.label + " (Id: " + this.state.NewRoom_Subject.id + ")\n" : "null\n") +
                    "Remark : " + this.state.NewRoom_Remark + "\n" +
                    "Search Group : " + (this.state.SearchQsSet_ByGroup !== null ? this.state.SearchQsSet_ByGroup.value + " (Id: " + this.state.SearchQsSet_ByGroup.id + ")\n" : "null\n") +
                    "Search Subject : " + (this.state.SearchQsSet_BySubject !== null ? this.state.SearchQsSet_BySubject.value + " (Id: " + this.state.SearchQsSet_BySubject.id + ")\n" : "null\n") +
                    "Random Question Mode : " + this.state.NewRoom_RandomQuestionMode + "\n" +
                    "Restrict Access To Time Range Only : " + this.state.NewRoom_RestrictAccessToTimeRangeOnly + "\n" +
                    "Force Retricted Access : " + this.state.NewRoom_ForceRetrictedAccess + "\n" +
                    "Quiz Ended : " + this.state.NewRoom_QuizEnded + "\n" +
                    "Enable Statistic Report : " + this.state.NewRoom_EnableStatisticReport + "\n" +
                    "Excluded From Statistic Report : " + this.state.NewRoom_ExcludedFromStatisticReport + "\n"
                );
            }, 0);
    }
    //#region code moved to upper level component.
    // //2021.07.29
    // CheckOnGroupList = async () => {
    //     if (this.state.GroupList.length <= 0) {
    //         let _List = [];
    //         await this.props.firestore
    //             .collection("QuizBank")
    //             .doc('QuizGroup')
    //             .collection('Items')
    //             // .where('CenterUserId', '==', _centerUserId)
    //             // .where('AuthorId', '==', _authorId)
    //             .orderBy('DisplayOrder', 'asc')
    //             // .limit(5)
    //             .get()
    //             .then(querySnapshot => {
    //                 let dataArray = [];
    //                 if (querySnapshot !== null) {
    //                     querySnapshot.forEach((doc) => {
    //                         dataArray.push(doc.data());
    //                     });
    //                     if (dataArray.length > 0) {
    //                         _List = dataArray;
    //                         // _List_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    //                     }
    //                 }
    //                 // if (this.props.isDevMode)
    //                 //     console.log(JSON.stringify(List));
    //             })
    //             .catch(error => {
    //                 console.log(error.message);
    //             });
    //         this.setState({ GroupList: _List, });
    //         await Delay(500);
    //     }
    //     this.PopulateGroupOptions();
    // }

    // //2021.07.29
    // PopulateGroupOptions = () => {
    //     if (this.state.GroupOptions.length <= 0) {
    //         let options = [];
    //         this.state.GroupList.map((data, key) => {
    //             return options.push({ value: data.Name, label: data.Name, id: data.Id });
    //         });
    //         this.setState({ GroupOptions: options });
    //     }
    // }
    //#endregion
    //2021.09.30
    RecalculateDuration = () => {
        this.setState({
            NewRoom_Duration: 0,
        });
        //2021.09.29
        let _maxDuration = (24 * 60 * 60) - 60;
        let _duration = 0;
        if (this.state.NewRoom_UseCustomDuration) {
            _duration = (this.state.NewRoom_Duration_Hour * 3600)
                + (this.state.NewRoom_Duration_Min * 60) + this.state.NewRoom_Duration_Sec;
            const _secs = _duration >= _maxDuration ? 0 : this.state.NewRoom_Duration_Sec;
            _duration = _duration >= _maxDuration ? _maxDuration : _duration;
            this.setState({
                NewRoom_Duration: _duration,
                NewRoom_Duration_Sec: _secs,
                AllowToCreateRoom: _duration <= 0 ? false : true,    //2021.10.05
            }, () => {
                // console.log('Duration = ' + this.state.NewRoom_Duration);
                this.NR_Duration_Hour.current.value = this.state.NewRoom_Duration_Hour;
                this.NR_Duration_Min.current.value = this.state.NewRoom_Duration_Min;
                this.NR_Duration_Sec.current.value = this.state.NewRoom_Duration_Sec;
            });
        }
        else {
            if (this.state.NewRoom_AccessibleOnEntireDay) {
                // _duration = moment(moment().format('2021-09-30 23:59:00'))
                //         .diff(moment(moment().format('2021-09-30 00:00:00')), 'seconds');

                //2021.10.05
                if (moment(this.state.NewRoom_Date).toString().toLowerCase().includes('invalid'))
                    _duration = 0;
                else
                    _duration = moment(moment().format(this.state.NewRoom_Date + ' 23:59:00'))
                        .diff(moment(moment().format(this.state.NewRoom_Date + ' 00:00:00')), 'seconds');
            }
            else {
                const _start = this.state.NewRoom_TimeStart.length > 0 ? '2021-09-30 ' + this.state.NewRoom_TimeStart + ':00' : '';
                const _end = this.state.NewRoom_TimeEnd.length > 0 ? '2021-09-30 ' + this.state.NewRoom_TimeEnd + ':00' : '';
                _duration = _start.length > 0 && _end.length > 0 ? moment(_end).diff(moment(_start), 'seconds') : 0;

                //2021.10.05
                // if (_duration <= 0) {
                //     let startTime = moment(moment().format('YYYY-MM-DD ') + this.state.NewRoom_TimeStart + ':00');
                //     let endTime = moment(moment().format('YYYY-MM-DD ') + this.state.NewRoom_TimeEnd + ':00');
                //     _duration = endTime.diff(startTime, 'seconds');
                //     _duration = _duration < 0 ? 0 : _duration;
                // }
            }
            this.setState({
                NewRoom_Duration: _duration,
                AllowToCreateRoom: _duration <= 0 ? false : true,    //2021.10.05
            });
        }
        // console.log('Duration = ' + _duration);
        // this.NR_Duration_Hour.current.value = this.state.NewRoom_Duration_Hour;
        // this.NR_Duration_Min.current.value = this.state.NewRoom_Duration_Min;
        // this.NR_Duration_Sec.current.value = this.state.NewRoom_Duration_Sec;
    }
    GetDurationText = (_totalSeconds = 0) => {
        var hours = Number((_totalSeconds / 3600).toFixed(3).split('.')[0]);
        var minutes = Number((_totalSeconds / 60).toFixed(3).split('.')[0]);
        var seconds = (_totalSeconds % 60);
        return hours > 0 ?
            hours + ' hr ' + (minutes - (hours * 60)) + ' min ' + seconds + ' sec'
            : minutes + ' min ' + seconds + ' sec';
    }


    //#region === Delete Room === start ===//
    DeleteThisRoom = async () => {
        // this.props.SetAlert('', '<b>Delete Room currently not available.</b>', false);

        if (this.state.SelectedRoom === null || this.state.SelectedRoom === undefined)
            return null;

        this.ToggleDeleteRoomModal();
        this.props.SetLoading('', 'Removing Room <' + this.state.SelectedRoom.RoomCode + '>...', false);
        let success = true;
        let errorMessage = [];

        //Delete from FS.
        await this.props.firestore
            .collection('LiveQuiz_UniqueRoomCode')
            .doc(String(this.state.SelectedRoom.RoomId))
            .delete()
            .catch(error => {
                success = false;
                errorMessage.push(error);
                if (this.props.isDevMode)
                    console.log('remove room FS (error) = ' + error);
            });

        //Delete from RTDB.
        let _date = moment(this.state.SelectedRoom.DateStart + ' 00:00:00').format('YYYYMMDD');
        await this.props.dbLiveQuiz
            .ref('pkquiz/' + _date + '/pkquiz-room-detail/' + String(this.state.SelectedRoom.RoomId))
            .remove()
            .catch((error) => {
                success = false;
                errorMessage.push(error);
                if (this.props.isDevMode)
                    console.log('remove room RTDB (room detail) (error) = ' + error);
            });
        await this.props.dbLiveQuiz
            .ref('pkquiz/' + _date + '/pkquiz-room-code/' + String(this.state.SelectedRoom.RoomCode))
            .remove()
            .catch((error) => {
                success = false;
                errorMessage.push(error);
                if (this.props.isDevMode)
                    console.log('remove room RTDB (room code) (error) = ' + error);
            });
        await this.props.dbLiveQuiz
            .ref('pkquiz/' + _date + '/pkquiz-live/' + String(this.state.SelectedRoom.RoomId))
            .remove()
            .catch((error) => {
                success = false;
                errorMessage.push(error);
                if (this.props.isDevMode)
                    console.log('remove room RTDB (room state) (error) = ' + error);
            });

        //2021.11.17
        //update room records in CMS, MarkedAsDeleted/delete azure files/mappings etc.
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Quiz/Room/Remove/'
            + this.props.user.CenterUserId + '/'
            + this.props.user.AuthorId + '/'
            + String(this.state.SelectedRoom.RoomCode) + '/'
            + String(this.state.SelectedRoom.RoomId),
            // Api/LearningCentre/Quiz/Room/Remove/{centerUserId}/{authorId}/{roomcode}/{room_id}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (!data.success)
                    if (this.props.isDevMode)
                        console.log('Error', 'api - room delete (failed)\n' + JSON.stringify(data));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - room delete (failed)\n' + error.message);
            });

        //done
        if (success) {
            this.ToggleEditRoomModal();
            this.props.SetAlert('', 'Room <' + this.state.SelectedRoom.RoomCode + '> has been removed.', false);
            // this.LoadRoomList();
            this.LoadRoomList_ViaApi();
            this.ResetEditRoomParams();
        }
        else {
            this.props.SetAlert('', 'Failed to remove Room <' + this.state.SelectedRoom.RoomCode + '>.<br /><br />' + errorMessage.join('<br />'), false);
        }
    }
    //2021.10.06
    ResetEditRoomParams = () => {
        this.setState({
            SelectedRoom: null,
            RoomData: null,
            Cached_RoomData: null,
            Fetched_RoomData: null,     //2023.10.18
            EditRoom_Group: null,
            Cached_EditRoom_Group: null,
            EditRoom_QuestionSet: null,
            Cached_EditRoom_QuestionSet: null,
            SearchQsSet_ByGroup: null,  //2021.10.14
            SearchQsSet_BySubject: null,  //2022.02.14
        });
    }
    ToggleDeleteRoomModal = () => {
        this.setState({ ShowDeleteRoomModal: !this.state.ShowDeleteRoomModal });
    }
    //#endregion === Delete Room === end ===//


    //=== Search Room by Room Code === start ===//
    //2022.06.04 - revamped.
    //2022.01.05
    SearchRoomByRoomCode = async () => {

        this.setState({ SearchRoomByRoomCode_Processing: true, });

        let _room = null;
        let _roomList = this.state.RoomList;
        let _findIndex = _roomList.findIndex(x => String(x.RoomCode) === this.state.SearchRoomByRoomCode_RoomCode);
        if (_findIndex > -1) {
            _room = _roomList[_findIndex];
            this.CheckOnSearchRoomResult(_room, _findIndex, this.state.Fetched_RoomList[_findIndex]);
        }
        else {
            let docRef = this.props.firestore.collection('LiveQuiz_UniqueRoomCode');

            const { centerUserId, authorId, organizerId } = GetPropIds(this.props);

            // if (Boolean(this.props.isDevMode) === false) {
            //     docRef = docRef
            //         .where('CenterUserId', '==', Number(this.props.user.CenterUserId))
            //         .where('AuthorId', '==', Number(this.props.user.AuthorId));
            // }
            // await docRef
            //     .where('RoomCode', '==', Number(this.state.SearchRoomByRoomCode_RoomCode))
            //     .limit(1)
            //     .get()
            //     .then(querySnapshot => {
            //         let dataArray = [];
            //         if (querySnapshot !== null) {
            //             querySnapshot.forEach((doc) => {
            //                 dataArray.push(doc.data());
            //             });
            //             _room = dataArray[0];
            //         }
            //         if (this.props.isDevMode)
            //             console.log(JSON.stringify(_room));
            //         const _fetched_RoomData = _room;
            //         _room = this.Populate_RoomData_with_RoomFS(_room);
            //         this.CheckOnSearchRoomResult(_room, _findIndex, _fetched_RoomData);
            //     })
            //     .catch(error => {
            //         if (this.props.isDevMode)
            //             console.log(error.message);
            //     });

            let done = false;

            //2023.12.07 - revamped - search via Api - main.
            await fetch(GlobalSetting.ApiUrl
                + 'Api/LearningCentre/Quiz/Room/Get/{organizerId}/{authorId}/{roomCode}/{roomId}'
                + organizerId + '/'
                + authorId + '/'
                + Number(this.state.SearchRoomByRoomCode_RoomCode) + '/0',
                // Api/LearningCentre/Quiz/Room/Get/{organizerId}/{authorId}/{roomCode}/{roomId}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if (this.props.isDevMode)
                        console.log('SearchRoomByRoomCode (source)', JSON.stringify(data));
                    if (data.success)
                        _room = CapitalizeJsonKeys(data.data);
                    else
                        if (this.props.isDevMode)
                            console.log('Error', 'api - room get (failed)\n' + JSON.stringify(data));
                    done = true;
                })
                .catch(error => {
                    done = true;
                    if (this.props.isDevMode)
                        console.log('Error', 'api - room get (failed)\n' + error.message);
                });
            await DelayUntil(() => done === true);

            //2023.12.08 - search via FS - obsolete, now as backup.
            //2023.11.09 - revamped.
            if (CheckNullValue(_room) === null) {
                await docRef
                    .where('OrganizerId', '==', organizerId)
                    .where('RoomCode', '==', Number(this.state.SearchRoomByRoomCode_RoomCode))
                    .limit(1)
                    .get()
                    .then(querySnapshot => {
                        let dataArray = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                dataArray.push(doc.data());
                                // console.log(doc.id, JSON.stringify(doc.data()));
                            });
                            _room = dataArray[0];
                            if (this.props.isDevMode)
                                console.log('search room', '1', JSON.stringify(_room));
                        }
                        done = true;
                    })
                    .catch(error => {
                        done = true;
                        if (this.props.isDevMode)
                            console.log(error.message);
                    });
                await DelayUntil(() => done === true);
            }

            //2023.12.08 - search via FS - obsolete, now as backup.
            //second try.
            if (CheckNullValue(_room) === null) {
                done = false;
                await docRef
                    .where('CenterUserId', '==', centerUserId)
                    .where('AuthorId', '==', authorId)
                    .where('RoomCode', '==', Number(this.state.SearchRoomByRoomCode_RoomCode))
                    .limit(1)
                    .get()
                    .then(querySnapshot => {
                        let dataArray = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                dataArray.push(doc.data());
                                // console.log(doc.id, JSON.stringify(doc.data()));
                            });
                            _room = dataArray[0];
                            if (this.props.isDevMode)
                                console.log('search room', '2', JSON.stringify(_room));
                        }
                        done = true;
                    })
                    .catch(error => {
                        done = true;
                        if (this.props.isDevMode)
                            console.log(error.message);
                    });
                await DelayUntil(() => done === true);
            }

            //2023.12.08 - search via FS - obsolete, now as backup.
            //last try.
            if (CheckNullValue(_room) === null) {
                done = false;
                await docRef
                    .where('RoomCode', '==', Number(this.state.SearchRoomByRoomCode_RoomCode))
                    .limit(1)
                    .get()
                    .then(querySnapshot => {
                        let dataArray = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                dataArray.push(doc.data());
                                // console.log(doc.id, JSON.stringify(doc.data()));
                            });
                            const t_room = dataArray[0];
                            //check room permission.
                            if (CheckObjectNullValue(t_room, 'OrganizerId') !== null) {
                                _room = CheckObjectNumber(t_room, 'OrganizerId') === organizerId ? t_room : null;
                                if (this.props.isDevMode)
                                    console.log('search room', '3', 'OrganizerId', _room !== null ? 'matched' : 'not match');
                            }
                            if (_room === null) {
                                if (CheckObjectNullValue(t_room, 'CenterUserId') !== null && CheckObjectNullValue(t_room, 'AuthorId') !== null) {
                                    _room = CheckObjectNumber(t_room, 'CenterUserId') === centerUserId && CheckObjectNumber(t_room, 'AuthorId') === authorId ? t_room : null;
                                    if (this.props.isDevMode)
                                        console.log('search room', '3', 'CenterUserId & AuthorId', _room !== null ? 'matched' : 'not match');
                                }
                            }
                            if (this.props.isDevMode)
                                console.log('search room', '3', JSON.stringify(_room));
                        }
                        done = true;
                    })
                    .catch(error => {
                        done = true;
                        if (this.props.isDevMode)
                            console.log(error.message);
                    });
                await DelayUntil(() => done === true);
            }

            if (CheckNullValue(_room) !== null) {
                if (this.props.isDevMode)
                    console.log('raw', JSON.stringify(_room));
                const _fetched_RoomData = _room;
                _room = this.Populate_RoomData_with_RoomFS(_room);
                if (this.props.isDevMode)
                    console.log('populated', JSON.stringify(_room));
                this.CheckOnSearchRoomResult(_room, _findIndex, _fetched_RoomData);
            }
            else {
                this.CheckOnSearchRoomResult(null);
            }
        }
    }
    CheckOnSearchRoomResult = (_room = null, _findIndex = -1, fetched_room = null) => {

        let _roomData = null;
        if (_room !== null) {

            let _roomList = this.state.RoomList;

            const _fetched_RoomData = fetched_room;
            let _fetched_RoomList = this.state.Fetched_RoomList;

            //set room data.
            _roomData = this.Populate_RoomData_with_RoomFS(_room);

            if (this.props.isDevMode)
                console.log(JSON.stringify(_roomData));

            //update room list if not in list.
            if (_findIndex < 0) {
                _roomList.push(_roomData);
                _fetched_RoomList.push(fetched_room);
            }

            //=== Organizer/Identity
            if (
                _roomData.hasOwnProperty('OrganizerIdentity') === false
                || _roomData.hasOwnProperty('OrganizerId') === false
                || _roomData.hasOwnProperty('Organizer') === false
            ) {
                if (this.props.OrganizerInfo !== undefined && this.props.OrganizerInfo !== null) {
                    _roomData.OrganizerIdentity = String(this.props.OrganizerInfo.Identity);    //important.
                    _roomData.OrganizerId = Number(this.props.OrganizerInfo.Id);    //2023.10.06

                    //replace, mostly for display only.
                    if (this.props.OrganizerInfo.DisplayName !== '')
                        _roomData.Organizer = String(this.props.OrganizerInfo.DisplayName);
                    else
                        _roomData.Organizer = String(this.props.OrganizerInfo.Name);
                }
                else {
                    _roomData.Organizer = _roomData.Organizer !== '' ? _roomData.Organizer : '';
                    _roomData.OrganizerIdentity = '';
                    _roomData.OrganizerId = 0;      //2023.10.06
                }
            }

            //=== RoomType (0 = normal, 1 = upload file)
            if (_roomData.hasOwnProperty('RoomType') === false) {
                _roomData.RoomType = 0;
            }
            else {
                switch (_roomData.RoomType) {
                    default: break;
                    case 1: _roomData.QuestionSetUniqueId = ''; break;
                }
            }

            //2023.10.06
            if (_roomData.hasOwnProperty('RandomQuestionMode') === false) {
                _roomData.RandomQuestionMode = false;
            }

            this.setState({
                RoomList: _roomList,    //update or remain.
                Fetched_RoomList: _fetched_RoomList,    //2023.10.18
                SelectedRoom: _room,
                RoomData: _roomData,
                Cached_RoomData: JSON.parse(JSON.stringify(_roomData)),
                Fetched_RoomData: JSON.parse(JSON.stringify(_fetched_RoomData)),    //2023.10.18
                EditRoom_QuestionSet: null,
                Cached_EditRoom_QuestionSet: null,

                ShowSearchRoomByRoomCodeModal: false,
                SearchRoomByRoomCode_Processing: false,
            }, async () => {
                this.props.CloseAlert();
                this.ToggleEditRoomModal();
                await Delay(0);
                this.Recalculate_Duration_EditRoom();
                this.Recalculate_DateTime_EditRoom();

                if (_roomData.hasOwnProperty('QuestionSetUniqueId')) {
                    // this.LoadRoomQuestionSet();
                    this.LoadRoomQuestionSet_ViaApi();      //2023.11.23
                }
            });
        }
        else {
            this.setState({
                ShowSearchRoomByRoomCodeModal: false,
            }, async () => {
                await Delay(200);
                this.setState({
                    SearchRoomByRoomCode_Processing: false,
                    SearchRoomByRoomCode_RoomCode: '',
                }, () => {
                    this.props.SetAlert('', 'Room not found.', false);
                });
            });
        }
    }
    Populate_RoomData_with_RoomFS = (_room) => {
        let _roomData = null;
        if (CheckNullValue(_room) !== null) {

            // console.log('GroupOptions =\n' + JSON.stringify(this.props.GroupOptions));

            let _group = null;
            // let _groupIndex = this.props.GroupOptions.findIndex(x => Number(x.id) === Number(this.GetValue(_room, 'GroupId', '0')));
            let _groupIndex = this.props.GroupOptions.findIndex(x => Number(x.id) === CheckObjectNumber(_room, 'GroupId'));
            if (_groupIndex > -1)
                _group = this.props.GroupOptions[_groupIndex];

            // //2023.10.06
            // let _randomQuestionMode = false;
            // const get_randomQuestionMode = this.GetValue(_room, 'RandomQuestionMode');
            // if (get_randomQuestionMode === '' || get_randomQuestionMode.toLowerCase() === 'false')
            //     _randomQuestionMode = false;
            // else
            //     _randomQuestionMode = true;

            //2023.10.06
            const { centerUserId, authorId, organizerId, organizerDiplayName, organizerIdentity } = GetPropIds(this.props);
            let _Duration = CheckObjectNumber(_room, 'Duration');   //Number(this.GetValue(_room, 'Duration', '0'));
            let _DurationPerQuestion = CheckObjectNumber(_room, 'DurationPerQuestion');   //Number(this.GetValue(_room, 'DurationPerQuestion', '0'));
            //get total question from question set.
            // let _qsArray = [];
            let _QnQty = CheckObjectNumber(_room, 'QnQty');   //Number(this.GetValue(_room, 'QnQty', '0'));
            let _qSet = null;
            if (this.state.ShowCreateRoomModal)
                _qSet = this.state.NewRoom_QuestionSet;
            else
                _qSet = this.state.EditRoom_QuestionSet;
            if (_qSet !== null) {
                if (CheckObjectNullValue(_qSet, 'TotalQuestion') !== null)
                    _QnQty = Number(_qSet.TotalQuestion);
                if (_QnQty > 0) {
                    //Questions array.
                    // [...Array(_QnQty)].map((data, key) => {
                    //     return _qsArray.push(key + 1);
                    // });
                    if (_Duration > 0) {
                        _DurationPerQuestion = Math.round(_Duration / _QnQty);
                    }
                }
            }

            //revamped 2023.10.18
            _roomData = {
                AuthorId: CheckObjectNumber(_room, 'AuthorId', authorId),
                CenterUserId: CheckObjectNumber(_room, 'CenterUserId', centerUserId),
                Date: CheckObjectStringEmpty(_room, 'Date'),
                DateEnd: CheckObjectStringEmpty(_room, 'DateEnd'),
                DateStart: CheckObjectStringEmpty(_room, 'DateStart'),
                Duration: _Duration,                            //2023.10.06
                DurationPerQuestion: _DurationPerQuestion,      //2023.10.06
                EventCode: CheckObjectStringEmpty(_room, 'EventCode'),
                Organizer: CheckObjectStringEmpty(_room, 'Organizer', organizerDiplayName),
                OrganizerId: CheckObjectNumber(_room, 'OrganizerId', organizerId),      //2023.10.06
                OrganizerIdentity: CheckObjectStringEmpty(_room, 'OrganizerIdentity', organizerIdentity),
                QuestionSetUniqueId: CheckObjectStringEmpty(_room, 'QuestionSetUniqueId'),
                RoomCode: CheckObjectNumber(_room, 'RoomCode'),
                RoomId: CheckObjectStringEmpty(_room, 'RoomId'),
                RoomTitle: CheckObjectStringEmpty(_room, 'RoomTitle'),
                RoomType: CheckObjectNumber(_room, 'RoomType'),        //0 = basic, 1 = document
                SubjectName: CheckObjectStringEmpty(_room, 'SubjectName'),
                Subject: CheckObjectStringEmpty(_room, 'SubjectName'),
                SubjectId: CheckObjectNumber(_room, 'SubjectId'),
                SupportedDocExt: CheckObjectNumber(_room, 'RoomType') === 0 ? [] : ['.txt', '.rtf', '.doc', '.docx'],
                TimeEnd: CheckObjectStringEmpty(_room, 'TimeEnd'),
                TimeStart: CheckObjectStringEmpty(_room, 'TimeStart'),
                GroupId: _group !== null ? Number(_group.id) : 0,
                Group: _group,
                Grade: _group !== null ? Number(_group.id) : '',
                Remark: CheckObjectStringEmpty(_room, 'Remark'),
                ExtraUrl: CheckObjectStringEmpty(_room, 'ExtraUrl'),   //2021.12.10 for Flipbook

                //2023.10.06    //2023.10.18
                QnQty: _QnQty,
                // Questions: _qsArray.join(';'),
                RandomQuestionMode: CheckObjectBoolean(_room, 'RandomQuestionMode'),
                RestrictAccessToTimeRangeOnly: CheckObjectBoolean(_room, 'RestrictAccessToTimeRangeOnly'),
                ForceRetrictedAccess: CheckObjectBoolean(_room, 'ForceRetrictedAccess'),
                EnableStatisticReport: CheckObjectBoolean(_room, 'EnableStatisticReport'),      //2023.11.03

                //2024.04.17
                QuizEnded: moment.utc() > moment(CheckObjectStringEmpty(_roomData, 'DateEnd') + ' ' + CheckObjectStringEmpty(_roomData, 'TimeEnd', '23:59:00')).utc(),
                ExcludedFromStatisticReport: CheckObjectBoolean(_room, 'ExcludedFromStatisticReport'),
            };

            //2023.10.30
            // _roomData['QuizEnded'] = CheckObjectBoolean(_room, 'QuizEnded');
            // if (moment() > moment(_roomData['DateEnd'])) {
            //     _roomData['QuizEnded'] = true;
            // }
            // _roomData['QuizEnded'] = moment.utc() > moment(CheckObjectStringEmpty(_roomData, 'DateEnd') + ' ' + CheckObjectStringEmpty(_roomData, 'TimeEnd', '23:59:00')).utc();

            //#region  old code
            // _roomData = {
            //     AuthorId: Number(this.GetValue(_room, 'AuthorId', authorId)),
            //     CenterUserId: Number(this.GetValue(_room, 'CenterUserId', centerUserId)),
            //     Date: this.GetValue(_room, 'Date'),
            //     DateEnd: this.GetValue(_room, 'DateEnd'),
            //     DateStart: this.GetValue(_room, 'DateStart'),
            //     Duration: _Duration,                            //2023.10.06
            //     DurationPerQuestion: _DurationPerQuestion,      //2023.10.06
            //     EventCode: this.GetValue(_room, 'EventCode'),
            //     Organizer: this.GetValue(_room, 'Organizer', organizerDiplayName),
            //     OrganizerId: Number(this.GetValue(_room, 'OrganizerId', organizerId)),      //2023.10.06
            //     OrganizerIdentity: this.GetValue(_room, 'OrganizerIdentity', organizerIdentity),
            //     QuestionSetUniqueId: this.GetValue(_room, 'QuestionSetUniqueId'),
            //     RoomCode: Number(this.GetValue(_room, 'RoomCode', '0')),
            //     RoomId: Number(this.GetValue(_room, 'RoomId', '0')),
            //     RoomTitle: this.GetValue(_room, 'RoomTitle'),
            //     RoomType: Number(this.GetValue(_room, 'RoomType', '0')),        //0 = basic, 1 = document
            //     SubjectName: this.GetValue(_room, 'SubjectName'),
            //     Subject: this.GetValue(_room, 'SubjectName'),
            //     SubjectId: Number(this.GetValue(_room, 'SubjectId', '0')),
            //     SupportedDocExt: Number(this.GetValue(_room, 'RoomType', '0')) === 0 ? [] : ['.txt', '.rtf', '.doc', '.docx'],
            //     TimeEnd: this.GetValue(_room, 'TimeEnd'),
            //     TimeStart: this.GetValue(_room, 'TimeStart'),
            //     GroupId: _group !== null ? Number(_group.id) : 0,
            //     Grade: _group !== null ? Number(_group.id) : '',
            //     Remark: this.GetValue(_room, 'Remark'),
            //     ExtraUrl: this.GetValue(_room, 'ExtraUrl'),   //2021.12.10 for Flipbook

            //     //2023.10.06    //2023.10.18
            //     QnQty: _QnQty,
            //     Questions: _qsArray.join(';'),
            //     RandomQuestionMode: CheckObjectBoolean(_room, 'RandomQuestionMode'),
            //     RestrictAccessToTimeRangeOnly: CheckObjectBoolean(_room, 'RestrictAccessToTimeRangeOnly'),
            //     ForceRetrictedAccess: CheckObjectBoolean(_room, 'ForceRetrictedAccess'),
            // };
            //#endregion

            //SubjectId.
            // if (CheckObjectNumber(_roomData, 'SubjectId') === 0) {
            //     const subjectName = CheckObjectNullValue(_roomData, 'Subject') === null ?
            //         (CheckObjectNullValue(_roomData, 'SubjectName') === null ? '' : CheckObjectStringEmpty(_roomData, 'SubjectName'))
            //         : CheckObjectStringEmpty(_roomData, 'Subject');
            //     if (CheckNullValue(subjectName) !== null) {
            //         let findIndex = this.props.SubjectOptions.findIndex(x => String(x.value) === subjectName);
            //         if (findIndex > -1)
            //             _roomData.SubjectId = Number(this.props.SubjectOptions[findIndex].id);
            //     }
            // }
            if (CheckObjectNumber(_roomData, 'SubjectId') > 0) {
                let findIndex = this.props.SubjectOptions.findIndex(x => Number(x.id) === CheckObjectNumber(_roomData, 'SubjectId'));
                if (findIndex > -1) {
                    _roomData['SubjectName'] = String(this.props.SubjectOptions[findIndex].value);
                    _roomData['Subject'] = String(this.props.SubjectOptions[findIndex].value);
                }
            }
            //RoomType (0 = normal, 1 = upload file)
            switch (_roomData.RoomType) {
                default: break;
                case 1: _roomData.QuestionSetUniqueId = ''; break;
            }

            // //2023.11.22
            // if (_roomData['DateStart'].includes('T00:00:00'))
            //     _roomData['DateStart'] = _roomData['DateStart'].replace('T00:00:00', '');
            // if (_roomData['DateEnd'].includes('T00:00:00'))
            //     _roomData['DateEnd'] = _roomData['DateEnd'].replace('T00:00:00', '');
        }
        return _roomData;
    }
    //=== Search Room by Room Code === end ===//


    //#region === Edit Selected Room === start ===//
    //2021.10.05
    // GotoEditRoom = (room) => {
    //     // this.props.SetAlert('', '<b>Room Edit currently not available.</b>', false);
    //     this.LoadSelectedRoom(room.RoomCode, room.RoomId);
    // }
    ToggleEditRoomModal = () => {
        if (this.state.PA_Update === false)
            return null;

        this.setState({ ShowEditRoomModal: !this.state.ShowEditRoomModal });
    }
    ResetEditedRoomData = () => {
        // this.setState({
        //     RoomData: JSON.parse(JSON.stringify(this.state.Cached_RoomData)),
        //     EditRoom_QuestionSet: JSON.parse(JSON.stringify(this.state.Cached_EditRoom_QuestionSet)),
        //     EditRoom_Group: JSON.parse(JSON.stringify(this.state.Cached_EditRoom_Group)),
        // }, () => {
        //     this.Recalculate_Duration_EditRoom();
        //     this.Recalculate_DateTime_EditRoom();
        // });
        this.ToggleEditRoomModal();
        setTimeout(() => {
            let _room = this.state.SelectedRoom;
            this.LoadSelectedRoom(_room.RoomCode, _room.RoomId);
        }, 500);
    }
    LoadSelectedRoom = async (roomCode, roomId) => {

        if (CheckNullValue(roomCode) === null && CheckNullValue(roomId) === null)
            return null;

        this.ResetEditRoomParams();
        this.props.SetLoading('', 'Loading Room <' + roomCode + '>...', false);

        let _room = null;
        let _roomData = null;
        let _fetched_RoomData = null;
        let _error = '';

        let selectedRoomIndex = this.state.RoomList.findIndex(x => x.RoomCode === roomCode && x.RoomId === roomId);
        if (selectedRoomIndex > -1) {
            _room = this.state.RoomList[selectedRoomIndex];
            _roomData = this.Populate_RoomData_with_RoomFS(_room);
            _fetched_RoomData = this.state.Fetched_RoomList[selectedRoomIndex];
        }
        else {
            // await this.props.dbLiveQuiz
            //     .ref('pkquiz/' + moment(_room.DateStart + ' 00:00:00').format('YYYYMMDD') + '/pkquiz-room-detail/' + roomId)
            //     .once('value', snapshot => {
            //         // handle read data.
            //         if (snapshot.exists()) {
            //             _roomData = snapshot.val();

            //             if (this.props.isDevMode)
            //                 console.log(JSON.stringify(_roomData));
            //         }
            //     })
            //     .catch((error) => {
            //         _error = error;
            //     });

            //2022.06.04
            await this.props.firestore.collection('LiveQuiz_UniqueRoomCode')
                .where('RoomCode', '==', Number(_room.RoomCode))
                .limit(1)
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                        });
                        // _roomData = dataArray[0];
                        _room = dataArray[0];
                    }
                    if (this.props.isDevMode)
                        console.log(JSON.stringify(_roomData));
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log(error.message);
                });

            // if (_roomData === null || _roomData === undefined)
            if (CheckNullValue(_room) !== null) {

                _fetched_RoomData = _room;
                // let _fetched_roomList = this.state.Fetched_RoomList;
                // _fetched_roomList.push(_fetched_RoomData);

                _roomData = this.Populate_RoomData_with_RoomFS(_room);
                // let _roomList = this.state.RoomList;
                // _roomList.push(_roomData);

                // this.setState({
                //     RoomList: _roomList,
                //     Fetched_RoomList: _fetched_roomList,
                // });
            }
        }
        if (_roomData !== null) {

            //#region old code - moved to function 'Populate_RoomData_with_RoomFS'.
            // //2021.10.13 === SubjectId
            // if (_roomData.hasOwnProperty('SubjectId') === false) {
            //     let findIndex = this.props.SubjectOptions.findIndex(x => x.value === String(_roomData.Subject));
            //     if (findIndex > -1)
            //         _roomData.SubjectId = Number(this.props.SubjectOptions[findIndex].id);
            // }

            // //2021.10.13 === Organizer/Identity
            // if (_roomData.hasOwnProperty('OrganizerIdentity') === false) {
            //     if (this.props.OrganizerInfo !== undefined && this.props.OrganizerInfo !== null) {
            //         _roomData.OrganizerIdentity = String(this.props.OrganizerInfo.Identity);    //important.
            //         _roomData.OrganizerId = Number(this.props.OrganizerInfo.Id);    //2023.10.06

            //         //replace, mostly for display only.
            //         if (this.props.OrganizerInfo.DisplayName !== '')
            //             _roomData.Organizer = String(this.props.OrganizerInfo.DisplayName);
            //         else
            //             _roomData.Organizer = String(this.props.OrganizerInfo.Name);
            //     }
            //     else {
            //         _roomData.Organizer = _roomData.Organizer !== '' ? _roomData.Organizer : '';
            //         _roomData.OrganizerIdentity = '';
            //         _roomData.OrganizerId = 0;      //2023.10.06
            //     }
            // }

            // //2021.10.13 === RoomType (0 = normal, 1 = upload file)
            // if (_roomData.hasOwnProperty('RoomType') === false) {
            //     _roomData.RoomType = 0;
            // }
            // else {
            //     switch (_roomData.RoomType) {
            //         default: break;
            //         case 1: _roomData.QuestionSetUniqueId = ''; break;
            //     }
            // }

            // //2023.10.06    //2023.10.18
            // if (_roomData.hasOwnProperty('RandomQuestionMode') === false)
            //     _roomData.RandomQuestionMode = false;
            // if (_roomData.hasOwnProperty('RestrictAccessToTimeRangeOnly') === false)
            //     _roomData.RestrictAccessToTimeRangeOnly = false;
            // if (_roomData.hasOwnProperty('ForceRetrictedAccess') === false)
            //     _roomData.ForceRetrictedAccess = false;
            //#endregion

            let _fetched_roomList = this.state.Fetched_RoomList;
            if (selectedRoomIndex < 0)
                _fetched_roomList.push(_fetched_RoomData);

            let _roomList = this.state.RoomList;
            if (selectedRoomIndex < 0)
                _roomList.push(_roomData);

            console.log('LoadSelectedRoom', JSON.stringify(_roomData));

            this.setState({
                SelectedRoom: _room,
                RoomData: _roomData,
                Cached_RoomData: JSON.parse(JSON.stringify(_roomData)),
                Fetched_RoomData: JSON.parse(JSON.stringify(_fetched_RoomData)),    //2023.10.18
                EditRoom_QuestionSet: null,
                Cached_EditRoom_QuestionSet: null,
                RoomList: _roomList,                    //2023.10.18
                Fetched_RoomList: _fetched_roomList,    //2023.10.18
            }, async () => {
                this.props.CloseAlert();
                this.ToggleEditRoomModal();
                await Delay(0);
                this.Recalculate_Duration_EditRoom();
                this.Recalculate_DateTime_EditRoom();
                this.EDR_CHK_RandomQuestionMode.current.checked = _roomData.RandomQuestionMode;     //2023.10.06
                this.EDR_CHK_RestrictAccessToTimeRangeOnly.current.checked = _roomData.RestrictAccessToTimeRangeOnly;     //2023.10.18
                this.EDR_CHK_ForceRetrictedAccess.current.checked = _roomData.ForceRetrictedAccess;                       //2023.10.18
                this.EDR_CHK_QuizEnded.current.checked = _roomData.QuizEnded;     //2023.10.30
                this.EDR_CHK_EnableStatisticReport.current.checked = _roomData.EnableStatisticReport;     //2023.11.03
                this.EDR_CHK_ExcludedFromStatisticReport.current.checked = _roomData.ExcludedFromStatisticReport;     //2024.04.18

                if (_roomData.hasOwnProperty('QuestionSetUniqueId')) {
                    // this.LoadRoomQuestionSet();
                    this.LoadRoomQuestionSet_ViaApi();      //2023.11.23
                }

                // //2021.10.06
                // if (_roomData.hasOwnProperty('GroupId') === false) {
                //     this.LoadRoomDetail(_roomData, roomCode, roomId);
                // }
            });
        }
        else {
            this.props.SetAlert('', '<b>Unable to load Room <' + roomCode + '>.</b><br /><br /><i>' + _error + '</i>');
        }
        if (_roomData === null) {
            this.props.SetAlert('Error', 'Room not found.');
        }
    }
    LoadRoomQuestionSet_ViaApi = async () => {
        //2023.11.23
        if (this.state.RoomData !== null) {
            if (CheckObjectNullValue(this.state.RoomData, 'QuestionSetUniqueId') !== null) {
                const { authorId, organizerId } = GetPropIds(this.props);
                //Fetch.
                let _questionSet = null;
                await fetch(GlobalSetting.ApiUrl
                    + 'Api/LearningCentre/Quiz/QuestionSet/Get/'
                    + organizerId + '/'
                    + authorId + '/'
                    + CheckObjectStringEmpty(this.state.RoomData, 'QuestionSetUniqueId'),
                    // Api/LearningCentre/Quiz/QuestionSet/Get/{organizerId}/{authorId}/{uniqueId}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (this.props.isDevMode)
                            console.log('LoadRoomQuestionSet_ViaApi (source)', JSON.stringify(data));
                        if (data.success)
                            _questionSet = data.data;
                        else
                            if (this.props.isDevMode)
                                console.log('Error', 'api - question set (failed)\n' + JSON.stringify(data));
                    })
                    .catch(error => {
                        if (this.props.isDevMode)
                            console.log('Error', 'api - question set (failed)\n' + error.message);
                    });

                if (_questionSet !== null) {
                    //Finalize json object.
                    _questionSet = CapitalizeJsonKeys(_questionSet);
                    console.log('LoadRoomQuestionSet_ViaApi (CapitalizeJsonKeys)', JSON.stringify(_questionSet));

                    let _group = null;
                    if (_questionSet.hasOwnProperty('GroupId')) {
                        let _findIndex = this.props.GroupOptions.findIndex(x => x.id === _questionSet.GroupId);
                        if (_findIndex > -1)
                            _group = this.props.GroupOptions[_findIndex];
                    }

                    let _List = [];
                    _List.push(_questionSet);
                    _List = FormatList_QuestionSet(this.props, _List);
                    _questionSet = _List[0];
                    console.log('LoadRoomQuestionSet_ViaApi (final)', JSON.stringify(_questionSet));

                    this.setState({
                        EditRoom_QuestionSet: _questionSet,
                        Cached_EditRoom_QuestionSet: JSON.parse(JSON.stringify(_questionSet)),
                        EditRoom_Group: _group,
                        Cached_EditRoom_Group: JSON.parse(JSON.stringify(_group)),
                    });
                }
            }
        }
    }
    LoadRoomQuestionSet = async () => {
        if (this.state.RoomData !== null) {
            if (this.state.RoomData.QuestionSetUniqueId !== '') {
                let _questionSet = null;
                await this.props.firestore
                    .collection("QuizBank")
                    .doc('QuizQuestionSet')
                    .collection('QuizQuestionSets')
                    // .doc(String(this.state.RoomData.QuestionSetUniqueId))
                    .where('UniqueId', '==', String(this.state.RoomData.QuestionSetUniqueId))
                    .limit(1)
                    .get()
                    .then(querySnapshot => {
                        let dataArray = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                dataArray.push(doc.data());
                            });
                            _questionSet = dataArray[0];
                        }
                        if (this.props.isDevMode)
                            console.log(JSON.stringify(_questionSet));
                    })
                    .catch(error => {
                        if (this.props.isDevMode)
                            console.log(error.message);
                    });

                if (CheckNullValue(_questionSet) !== null) {
                    //2021.10.06
                    let _group = null;
                    if (_questionSet.hasOwnProperty('GroupId')) {
                        let _findIndex = this.props.GroupOptions.findIndex(x => x.id === _questionSet.GroupId);
                        if (_findIndex > -1)
                            _group = this.props.GroupOptions[_findIndex];
                    }

                    //2022.02.14
                    let _List = [];
                    _List.push(_questionSet);
                    _List = FormatList_QuestionSet(this.props, _List);
                    _questionSet = _List[0];

                    this.setState({
                        EditRoom_QuestionSet: _questionSet,
                        Cached_EditRoom_QuestionSet: JSON.parse(JSON.stringify(_questionSet)),

                        //2021.10.06
                        EditRoom_Group: _group,
                        Cached_EditRoom_Group: JSON.parse(JSON.stringify(_group)),
                    });
                }
            }
        }
    }
    GetGroupName = (data) => {
        //2021.11.10
        let _findIndex = -1;
        if (data.hasOwnProperty('GroupId')) {
            _findIndex = this.props.GroupOptions.findIndex(x => Number(x.id) === Number(data.GroupId));
        }
        return _findIndex < 0 ? '' : String(this.props.GroupOptions[_findIndex].label);
    }
    GetPlaceholder_Group = () => {
        let _findIndex = -1;
        if (this.state.ShowEditRoomModal) {
            // if (this.state.RoomData.hasOwnProperty('GroupId')) {
            //     //2021.10.14
            //     _findIndex = this.props.GroupOptions.findIndex(x => Number(x.id) === this.state.RoomData.GroupId);
            // }
            // else {
            //     if (this.state.RoomData.hasOwnProperty('QuestionSetUniqueId')) {
            //         // if (this.state.RoomData.QuestionSetUniqueId === '')
            //         //     // _findIndex = this.props.GroupOptions.findIndex(x => String(x.label).toLowerCase().includes('standard ' + this.state.RoomData.Grade));
            //         //     _findIndex = this.props.GroupOptions.findIndex(x => x.label === ('Standard ' + this.state.RoomData.Grade));
            //         // else
            //         if (this.state.RoomData.hasOwnProperty('GroupId'))
            //             _findIndex = this.props.GroupOptions.findIndex(x => Number(x.id) === this.state.RoomData.GroupId);
            //         else
            //             _findIndex = this.props.GroupOptions.findIndex(x => String(x.label) === ('Standard ' + this.state.RoomData.Grade));
            //     }
            //     else {
            //         _findIndex = this.props.GroupOptions.findIndex(x => String(x.label) === ('Standard ' + this.state.RoomData.Grade));
            //     }
            // }
            if (this.state.RoomData.hasOwnProperty('GroupId'))
                _findIndex = this.props.GroupOptions.findIndex(x => Number(x.id) === CheckObjectNumber(this.state.RoomData, 'GroupId'));

            if (_findIndex > -1) {
                //2021.10.14
                if (this.state.SearchQsSet_ByGroup === null)
                    this.setState({ SearchQsSet_ByGroup: this.props.GroupOptions[_findIndex], });

                return this.props.GroupOptions[_findIndex].label;
            }
        }
        return Locale("grade", this.props.Locale);
    }
    //2022.02.14
    GetPlaceholder_Subject = () => {
        let _findIndex = -1;
        if (this.state.ShowEditRoomModal) {
            if (this.state.RoomData.hasOwnProperty('SubjectId')) {
                _findIndex = this.props.SubjectOptions.findIndex(x => Number(x.id) === Number(this.state.RoomData.SubjectId));
                if (_findIndex > -1) {
                    if (this.state.SearchQsSet_BySubject === null)
                        this.setState({ SearchQsSet_BySubject: this.props.SubjectOptions[_findIndex], });

                    return String(this.props.SubjectOptions[_findIndex].label);
                }
            }
        }
        return Locale("subject", this.props.Locale);
    }
    // //2021.10.08
    // GetSubjectPlaceholder = () => {
    //     let _findIndex = this.props.SubjectList.findIndex(x => x.Name === this.state.NewRoom_SubjectName);
    //     if (_findIndex > -1)
    //         return this.props.SubjectList[_findIndex].Name;
    // }
    // //2021.10.06
    // GetGroupValue = () => {
    //     // if (this.state.RoomDataFS !== null) {
    //     if (this.state.RoomData.QuestionSetUniqueId === '') {
    //         let _findIndex = this.props.GroupOptions.findIndex(x => String(x.label).toLowerCase().includes('standard ' + this.state.RoomData.Grade));
    //         if (_findIndex > -1)
    //             return this.props.GroupOptions[_findIndex].label;
    //     }
    //     // }
    //     return this.state.RoomData.Grade;   //also use as GroupId
    // }
    // //2021.10.06
    // CheckOnGroupState = (_group) => {
    //     if (this.state.RoomData.GroupId !== _group.id) {
    //         let _roomData = this.state.RoomData;
    //         _roomData.GroupId = _group.id;
    //         this.setState({
    //             RoomData: _roomData,
    //             Cached_RoomData: JSON.parse(JSON.stringify(_roomData)),
    //         });
    //     }
    // }
    // //2021.10.06
    // LoadRoomDetail = async (_roomData, _roomCode, _roomId) => {
    //     //e.g. RTDB = pkquiz/{YYYYMMDD}/pkquiz-room-detail/{roomId}
    //     if (this.state.RoomDataFS === null) {
    //         if (_roomData !== null) {
    //             let _roomDataFS = null;
    //             //#region wrong fetch
    //             // let _date = moment(_roomData.DateStart + ' 00:00:00').format('YYYYMMDD');                
    //             // console.log('pkquiz/' + _date + '/pkquiz-room-detail/' + String(_roomData.RoomId));
    //             // await this.props.dbLiveQuiz
    //             //     .ref('pkquiz/' + _date + '/pkquiz-room-detail/' + String(_roomData.RoomId))
    //             //     .once('value', snapshot => {
    //             //         // handle read data.
    //             //         if (snapshot.exists()) {
    //             //             _roomDetail = snapshot.val();

    //             //             if (this.props.isDevMode)
    //             //                 console.log(JSON.stringify(_roomDetail));
    //             //         }
    //             //     })
    //             //     .catch((error) => {
    //             //         console.log('Room Detail (Error) =\n' + error);
    //             //     });
    //             //#endregion
    //             await this.props.firestore
    //                 .collection("LiveQuiz_UniqueRoomCode")
    //                 .where('RoomCode', '==', _roomCode)
    //                 .get()
    //                 .then(querySnapshot => {
    //                     let dataArray = [];
    //                     if (querySnapshot !== null) {
    //                         querySnapshot.forEach((doc) => {
    //                             dataArray.push(doc.data());
    //                         });
    //                         _roomDataFS = dataArray[0];
    //                     }
    //                     if (this.props.isDevMode)
    //                         console.log(JSON.stringify(_roomDataFS));
    //                 })
    //                 .catch(error => {
    //                     console.log(error);
    //                 });
    //             if (_roomDataFS !== null) {
    //                 this.setState({
    //                     RoomDataFS: _roomDataFS,
    //                 });
    //             }
    //         }
    //     }
    // }
    Recalculate_Duration_EditRoom = () => {
        if (this.state.ShowEditRoomModal) {
            let _roomData = this.state.RoomData;

            let _hr = Number(this.EDR_Duration_Hour.current.value);
            let _min = Number(this.EDR_Duration_Min.current.value);
            let _sec = Number(this.EDR_Duration_Sec.current.value);

            let _duration = Number(_roomData.Duration);
            // console.log('hr = ' + _hr + '\nmin = ' + _min + '\nsec = ' + _sec + '\nDuration = ' + _duration);

            if (_hr === 0 && _min === 0 && _sec === 0 && _duration > 0) {
                //Initial.
                _hr = Number((_duration / 3600).toFixed(3).split('.')[0]);
                _min = Number(((_duration - (_hr * 3600)) / 60).toFixed(3).split('.')[0]);
                _sec = _duration - (_hr * 3600) - (_min * 60);
                this.EDR_Duration_Hour.current.value = _hr;
                this.EDR_Duration_Min.current.value = _min;
                this.EDR_Duration_Sec.current.value = _sec;
                // console.log('hr = ' + _hr + '\nmin = ' + _min + '\nsec = ' + _sec);
            }
            else {
                //Edit Room Duration.
                if (_hr >= 6) {
                    _hr = 6;
                    _min = 0;
                    _sec = 0;
                    this.EDR_Duration_Hour.current.value = _hr;
                    this.EDR_Duration_Min.current.value = _min;
                    this.EDR_Duration_Sec.current.value = _sec;
                }
                if (_min > 59) {
                    _min = 59;
                    this.EDR_Duration_Min.current.value = _min;
                }
                if (_sec > 59) {
                    _sec = 59;
                    this.EDR_Duration_Sec.current.value = _sec;
                }
                _duration = (_hr * 3600) + (_min * 60) + _sec;
                _roomData.Duration = _duration;
                this.setState({
                    RoomData: _roomData,
                });
            }
        }
    }
    Recalculate_DateTime_EditRoom = () => {
        if (this.state.ShowEditRoomModal) {
            let _roomData = this.state.RoomData;
            if (_roomData !== null) {
                this.EDR_FromDate.current.value = _roomData.DateStart;
                this.EDR_ToDate.current.value = _roomData.DateEnd;
                this.EDR_FromTime.current.value = _roomData.TimeStart;
                this.EDR_ToTime.current.value = _roomData.TimeEnd;
            }
        }
    }
    //2021.10.06
    SaveEditedRoom = async () => {
        if (this.state.RoomData === null || this.state.PA_Update === false)
            return null;

        this.props.SetLoading('', 'Updating Room <' + this.state.SelectedRoom.RoomCode + '>...', false);
        let success = true;
        let errorMessage = [];

        //data to update.

        //updateData - full revamped 2023.10.18
        let updateData = {};
        const roomData = this.state.RoomData;
        const cached_RoomData = this.state.Cached_RoomData;
        // let CheckUpdateValueDiffer = (updateObj, objA, objB, val) => {
        //     // console.log('CompareObjectValue_NotEqual ', val, objA[val], objB[val], CompareObjectValue_NotEqual(objA, objB, val));
        //     if (CheckNullValue(this.state.Fetched_RoomData, val) === null || CompareObjectValue_NotEqual(objA, objB, val)) {
        //         if (typeof (objA[val]) === 'number')
        //             updateObj[val] = CheckObjectNumber(objA, val);
        //         else if (typeof (objA[val]) === 'boolean')
        //             updateObj[val] = CheckObjectBoolean(objA, val);
        //         else if (typeof (objA[val]) === 'string')
        //             updateObj[val] = CheckObjectStringEmpty(objA, val);
        //         else
        //             updateObj[val] = objA[val];
        //     }
        //     return updateObj;
        // };

        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'RoomTitle',);
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'DateStart');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'DateEnd');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'TimeStart');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'TimeEnd');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'QuestionSetUniqueId');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'Duration');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'GroupId');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'Remark');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'SupportedDocExt');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'ExtraUrl');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'SubjectId');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'SubjectName');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'Organizer');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'OrganizerIdentity');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'OrganizerId');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'RoomType');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'RandomQuestionMode');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'RestrictAccessToTimeRangeOnly');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'ForceRetrictedAccess');
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'QuizEnded');    //2023.10.30
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'EnableStatisticReport');    //2023.11.03
        updateData = CheckUpdateValueDiffer(updateData, roomData, cached_RoomData, 'ExcludedFromStatisticReport');    //2024.04.18

        // console.log('roomData', JSON.stringify(roomData));
        // console.log('cached_RoomData', JSON.stringify(cached_RoomData));
        // console.log('updateData', JSON.stringify(updateData));
        // this.props.CloseAlert();
        // return null;

        //#region not yet simplify.
        // if (CheckNullValue(this.state.Fetched_RoomData, 'RoomTitle') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'RoomTitle'))
        //     updateData['RoomTitle'] = CheckObjectStringEmpty(roomData, 'RoomTitle');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'DateStart') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'DateStart'))
        //     updateData['DateStart'] = CheckObjectStringEmpty(roomData, 'DateStart');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'DateEnd') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'DateEnd'))
        //     updateData['DateEnd'] = CheckObjectStringEmpty(roomData, 'DateEnd');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'TimeStart') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'TimeStart'))
        //     updateData['TimeStart'] = CheckObjectStringEmpty(roomData, 'TimeStart');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'TimeEnd') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'TimeEnd'))
        //     updateData['TimeEnd'] = CheckObjectStringEmpty(roomData, 'TimeEnd');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'QuestionSetUniqueId') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'QuestionSetUniqueId'))
        //     updateData['QuestionSetUniqueId'] = CheckObjectStringEmpty(roomData, 'QuestionSetUniqueId');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'Duration') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'Duration'))
        //     updateData['Duration'] = CheckObjectStringEmpty(roomData, 'Duration');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'GroupId') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'GroupId'))
        //     updateData['GroupId'] = CheckObjectNumber(roomData, 'GroupId');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'Remark') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'Remark'))
        //     updateData['Remark'] = CheckObjectStringEmpty(roomData, 'Remark');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'SupportedDocExt') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'SupportedDocExt'))
        //     updateData['SupportedDocExt'] = CheckObjectStringEmpty(roomData, 'SupportedDocExt');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'ExtraUrl') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'ExtraUrl'))
        //     updateData['ExtraUrl'] = CheckObjectStringEmpty(roomData, 'ExtraUrl');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'SubjectId') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'SubjectId'))
        //     updateData['SubjectId'] = CheckObjectNumber(roomData, 'SubjectId');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'SubjectName') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'SubjectName'))
        //     updateData['SubjectName'] = CheckObjectStringEmpty(roomData, 'SubjectName');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'Organizer') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'Organizer'))
        //     updateData['Organizer'] = CheckObjectStringEmpty(roomData, 'Organizer');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'OrganizerIdentity') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'OrganizerIdentity'))
        //     updateData['OrganizerIdentity'] = CheckObjectStringEmpty(roomData, 'OrganizerIdentity');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'OrganizerId') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'OrganizerId'))
        //     updateData['OrganizerId'] = CheckObjectNumber(roomData, 'OrganizerId');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'RoomType') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'RoomType'))
        //     updateData['RoomType'] = CheckObjectNumber(roomData, 'RoomType');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'RandomQuestionMode') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'RandomQuestionMode'))
        //     updateData['RandomQuestionMode'] = CheckObjectBoolean(roomData, 'RandomQuestionMode');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'RestrictAccessToTimeRangeOnly') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'RestrictAccessToTimeRangeOnly'))
        //     updateData['RestrictAccessToTimeRangeOnly'] = CheckObjectBoolean(roomData, 'RestrictAccessToTimeRangeOnly');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'ForceRetrictedAccess') === null || CompareObjectValue_NotEqual(roomData, cached_RoomData, 'ForceRetrictedAccess'))
        //     updateData['ForceRetrictedAccess'] = CheckObjectBoolean(roomData, 'ForceRetrictedAccess');
        //#endregion

        //#region old codes
        // // let updateData = {
        // //     RoomTitle: String(this.state.RoomData.RoomTitle),
        // //     DateStart: String(this.state.RoomData.DateStart),
        // //     DateEnd: String(this.state.RoomData.DateEnd),
        // //     TimeStart: String(this.state.RoomData.TimeStart),
        // //     TimeEnd: String(this.state.RoomData.TimeEnd),
        // //     QuestionSetUniqueId: String(this.state.RoomData.QuestionSetUniqueId),
        // //     Duration: Number(this.state.RoomData.Duration),
        // //     GroupId: Number(this.state.RoomData.GroupId),
        // // };
        // let updateData = {};
        // if (this.state.RoomData.RoomTitle !== this.state.Cached_RoomData.RoomTitle)
        //     updateData['RoomTitle'] = String(this.state.RoomData.RoomTitle);
        // if (this.state.RoomData.DateStart !== this.state.Cached_RoomData.DateStart)
        //     updateData['DateStart'] = String(this.state.RoomData.DateStart);
        // if (this.state.RoomData.DateEnd !== this.state.Cached_RoomData.DateEnd)
        //     updateData['DateEnd'] = String(this.state.RoomData.DateEnd);
        // if (this.state.RoomData.TimeStart !== this.state.Cached_RoomData.TimeStart)
        //     updateData['TimeStart'] = String(this.state.RoomData.TimeStart);
        // if (this.state.RoomData.TimeEnd !== this.state.Cached_RoomData.TimeEnd)
        //     updateData['TimeEnd'] = String(this.state.RoomData.TimeEnd);
        // if (this.state.RoomData.QuestionSetUniqueId !== this.state.Cached_RoomData.QuestionSetUniqueId)
        //     updateData['QuestionSetUniqueId'] = String(this.state.RoomData.QuestionSetUniqueId);
        // if (this.state.RoomData.Duration !== this.state.Cached_RoomData.Duration)
        //     updateData['Duration'] = Number(this.state.RoomData.Duration);
        // if (this.state.RoomData.GroupId !== this.state.Cached_RoomData.GroupId)
        //     updateData['GroupId'] = Number(this.state.RoomData.GroupId);
        // // if (this.state.RoomData.Subject !== this.state.Cached_RoomData.Subject)
        // //     updateData['SubjectName'] = String(this.state.RoomData.Subject.Name);
        // if (this.state.RoomData.Remark !== this.state.Cached_RoomData.Remark)
        //     updateData['Remark'] = String(this.state.RoomData.Remark);

        // //2021.11.08
        // if (this.state.RoomData.SupportedDocExt !== this.state.Cached_RoomData.SupportedDocExt)
        //     updateData['SupportedDocExt'] = this.state.RoomData.SupportedDocExt;

        // //2021.12.10
        // if (this.state.RoomData.ExtraUrl !== this.state.Cached_RoomData.ExtraUrl)
        //     updateData['ExtraUrl'] = this.state.RoomData.ExtraUrl;

        // //revamped 2023.10.18
        // //2022.06.04
        // //2021.10.13
        // if (CheckNullValue(this.state.Fetched_RoomData, 'SubjectId') === null || this.state.RoomData.SubjectId !== this.state.Cached_RoomData.SubjectId)
        //     updateData['SubjectId'] = CheckObjectNumber(this.state.RoomData, 'SubjectId');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'SubjectName') === null || this.state.RoomData.SubjectName !== this.state.Cached_RoomData.SubjectName)
        //     updateData['SubjectName'] = CheckObjectStringEmpty(this.state.RoomData, 'SubjectName');
        // //#region  old codes
        // // //2021.10.13
        // // // if (
        // // //     (this.state.RoomData.hasOwnProperty('SubjectId') === false || this.state.Cached_RoomData.hasOwnProperty('SubjectId') === false)
        // // //     || (this.state.RoomData.SubjectId !== this.state.Cached_RoomData.SubjectId)
        // // // ) {
        // // //     let subjectIndex = this.props.SubjectOptions.findIndex(x => x.value === String(this.state.RoomData.Subject));
        // // //     if (subjectIndex > -1) {
        // // //         updateData['SubjectId'] = Number(this.props.SubjectOptions[subjectIndex].id);
        // // //     }
        // // // }
        // // if (this.state.RoomData.SubjectId !== this.state.Cached_RoomData.SubjectId
        // //     || this.state.RoomData.hasOwnProperty('SubjectId') === false)
        // //     updateData['SubjectId'] = String(this.state.RoomData.SubjectId);

        // // //2022.06.04
        // // if (this.state.RoomData.SubjectId !== this.state.Cached_RoomData.SubjectId
        // //     || this.state.RoomData.hasOwnProperty('SubjectId') === false
        // //     || this.state.RoomData.hasOwnProperty('SubjectName') === false
        // //     || Number(this.state.RoomData['SubjectId']) === 0
        // //     || String(this.state.RoomData['SubjectName']) === '') {
        // //     // updateData['SubjectId'] = String(this.state.RoomData.SubjectId);
        // //     updateData['SubjectId'] = String(this.state.RoomData.Subject.Id);
        // //     updateData['SubjectName'] = String(this.state.RoomData.Subject.Name);
        // // }
        // //#endregion        

        // //2021.10.13
        // if (CheckNullValue(this.state.Fetched_RoomData, 'Organizer') === null || this.state.RoomData.Organizer !== this.state.Cached_RoomData.Organizer)
        //     updateData['Organizer'] = String(this.state.RoomData.Organizer);
        // if (CheckNullValue(this.state.Fetched_RoomData, 'OrganizerIdentity') === null || this.state.RoomData.OrganizerIdentity !== this.state.Cached_RoomData.OrganizerIdentity)
        //     updateData['OrganizerIdentity'] = String(this.state.RoomData.OrganizerIdentity);
        // if (CheckNullValue(this.state.Fetched_RoomData, 'OrganizerId') === null || this.state.RoomData.OrganizerId !== this.state.Cached_RoomData.OrganizerId)
        //     updateData['OrganizerId'] = Number(this.state.RoomData.OrganizerId);    //2023.10.06

        // //2021.10.13
        // if (CheckNullValue(this.state.Fetched_RoomData, 'RoomType') === null || this.state.RoomData.RoomType !== this.state.Cached_RoomData.RoomType) {
        //     updateData['RoomType'] = this.state.RoomData.RoomType;
        // }

        // //revamped 2023.10.18
        // //2023.10.06
        // if (CheckNullValue(this.state.Fetched_RoomData, 'RandomQuestionMode') === null || this.state.RoomData.RandomQuestionMode !== this.state.Cached_RoomData.RandomQuestionMode)
        //     updateData['RandomQuestionMode'] = CheckObjectBoolean(this.state.RoomData, 'RandomQuestionMode');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'RestrictAccessToTimeRangeOnly') === null || this.state.RoomData.RestrictAccessToTimeRangeOnly !== this.state.Cached_RoomData.RestrictAccessToTimeRangeOnly)
        //     updateData['RestrictAccessToTimeRangeOnly'] = CheckObjectBoolean(this.state.RoomData, 'RestrictAccessToTimeRangeOnly');
        // if (CheckNullValue(this.state.Fetched_RoomData, 'ForceRetrictedAccess') === null || this.state.RoomData.ForceRetrictedAccess !== this.state.Cached_RoomData.ForceRetrictedAccess)
        //     updateData['ForceRetrictedAccess'] = CheckObjectBoolean(this.state.RoomData, 'ForceRetrictedAccess');
        //#endregion

        //save in FS.
        await this.props.firestore
            .collection('LiveQuiz_UniqueRoomCode')
            .doc(String(this.state.SelectedRoom.RoomId))
            .update(updateData)
            .catch(error => {
                success = false;
                errorMessage.push(error);
                if (this.props.isDevMode)
                    console.log('save room FS (error) =\n' + error);
            });

        // //other RTDB properties.
        // if (this.state.RoomData.Grade !== this.state.Cached_RoomData.Grade)
        //     updateData['Grade'] = Number(this.state.RoomData.Grade);

        // //save in RTDB.
        // if (this.state.RoomData.Subject !== this.state.Cached_RoomData.Subject
        //     || this.state.RoomData.hasOwnProperty('Subject') === false
        //     || this.state.RoomData.hasOwnProperty('SubjectName') === true) {
        //     updateData['Subject'] = String(this.state.RoomData.Subject.Name);
        //     if (updateData.hasOwnProperty('SubjectName'))
        //         delete updateData.SubjectName;
        // }


        //2022.06.04 === start ===
        const editRoomQsSet = this.state.EditRoom_QuestionSet;
        const cachedEditRoomQsSet = this.state.Cached_EditRoom_QuestionSet;
        let _qsSet = {};
        if (JSON.stringify(editRoomQsSet) === JSON.stringify(cachedEditRoomQsSet))
            _qsSet = editRoomQsSet;
        else
            _qsSet = cachedEditRoomQsSet;
        if (editRoomQsSet === null && cachedEditRoomQsSet === null)
            _qsSet['TotalQuestion'] = 40;

        // let _qsArray = [];
        // if (Number(this.state.RoomData.RoomType) === 0) {
        //     [...Array(_qsSet.TotalQuestion)].map((data, key) => {
        //         return _qsArray.push(key + 1);
        //     });
        // }

        const dataToSave = {
            AuthorId: Number(this.state.RoomData.AuthorId),
            CenterUserId: Number(this.state.RoomData.CenterUserId),
            DateEnd: String(this.state.RoomData.DateEnd),
            DateStart: String(this.state.RoomData.DateStart),
            Duration: Number(this.state.RoomData.Duration),
            DurationPerQuestion: Math.round(Number(this.state.RoomData.Duration) / Number(_qsSet['TotalQuestion'])),
            EventCode: String(this.state.RoomData.EventCode),
            ExtraUrl: String(this.state.RoomData.ExtraUrl),
            Grade: _qsSet.hasOwnProperty('Group') ? _qsSet.Group.hasOwnProperty('Name') ? String(_qsSet.Group.Name) : '' : '',         //*
            GroupId: Number(this.state.RoomData.GroupId),
            Organizer: String(this.state.RoomData.Organizer),
            OrganizerIdentity: String(this.state.RoomData.OrganizerIdentity),
            OrganizerId: Number(this.state.RoomData.OrganizerId),     //2023.10.06
            QnQty: Number(_qsSet['TotalQuestion']),     //*
            QuestionSetUniqueId: String(this.state.RoomData.QuestionSetUniqueId),
            // Questions: _qsArray.join(';'),
            Remark: String(this.state.RoomData.Remark),
            RoomCode: Number(this.state.RoomData.RoomCode),
            RoomId: Number(this.state.RoomData.RoomId),
            RoomTitle: String(this.state.RoomData.RoomTitle),
            RoomType: Number(this.state.RoomData.RoomType),
            Subject: String(this.state.RoomData.SubjectName),   //*
            SubjectId: Number(this.state.RoomData.SubjectId),
            SubjectName: String(this.state.RoomData.SubjectName),   //2022.06.07
            TimeEnd: String(this.state.RoomData.TimeEnd),
            TimeStart: String(this.state.RoomData.TimeStart),

            //2023.10.06
            RandomQuestionMode: this.state.RoomData.RandomQuestionMode,
            RestrictAccessToTimeRangeOnly: this.state.RoomData.RestrictAccessToTimeRangeOnly,
            ForceRetrictedAccess: this.state.RoomData.ForceRetrictedAccess,

            //2023.10.30
            QuizEnded: this.state.RoomData.QuizEnded,

            //2023.11.03
            EnableStatisticReport: this.state.RoomData.EnableStatisticReport,

            //2024.04.18
            ExcludedFromStatisticReport: this.state.RoomData.ExcludedFromStatisticReport,
        };
        //2022.06.04 === end ===

        //save in RTDB.
        let _date = moment(this.state.SelectedRoom.DateStart + ' 00:00:00').format('YYYYMMDD');
        await this.props.dbLiveQuiz
            .ref('pkquiz/' + _date + '/pkquiz-room-detail/' + String(this.state.SelectedRoom.RoomId))
            .set(dataToSave)
            .catch((error) => {
                success = false;
                errorMessage.push(error);
                if (this.props.isDevMode)
                    console.log('save room RTDB (error) =\n' + error);
            });

        //trigger CMS sync Room details.    //2021.11.10
        await Delay(500);
        if (success)
            await this.TriggerRoomRecordSyncViaApi(String(this.state.SelectedRoom.RoomId), false);

        //done
        if (success) {
            this.setState({
                Cached_RoomData: JSON.parse(JSON.stringify(this.state.RoomData)),
                Cached_EditRoom_Group: JSON.parse(JSON.stringify(this.state.EditRoom_Group)),
                Cached_EditRoom_QuestionSet: JSON.parse(JSON.stringify(this.state.EditRoom_QuestionSet)),
            });
            this.props.SetAlert('', 'Room <' + this.state.SelectedRoom.RoomCode + '> has been updated.', false);
            this.ToggleEditRoomModal();
            // this.LoadRoomList();
            this.LoadRoomList_ViaApi();
            this.ResetEditRoomParams();
            // this.SearchRoomByRoomCode();
        }
        else {
            this.props.SetAlert('', 'Failed to update Room <' + this.state.SelectedRoom.RoomCode + '>.<br /><br />' + errorMessage.join('<br />'), false);
        }
    }
    //#endregion === Edit Selected Room === end ===//


    //#region === Create New Room === start ===//
    ToggleCreateRoomModal = () => {
        if (this.state.PA_Create === false)
            return null;

        this.setState({
            ShowCreateRoomModal: !this.state.ShowCreateRoomModal
        }, () => {
            this.ResetNewRoomData();
            if (this.state.ShowCreateRoomModal) {
                this.GenerateRandomRoomCode();
                // this.CheckOnGroupList();
            }
            else {
                //close modal.
                if (this.state.IsChild)
                    this.props.CancelCreateNewRoom_Callback();
            }
        });
    }
    getRandomIntegerBetweenRange = (min, max) => {
        var x = parseInt(((Math.random() * ((max - min) + 1)) + min), 10);
        return x;
    }
    GenerateRandomRoomCode = async () => {
        this.setState({ NewRoom_Code: 0, NewRoom_Code_isValid: false });
        let _NewRoomCode_isValid = false;
        let _NewRoomCode = 0;
        let _currentLimitCount = 0;
        let _maxLimitCount = 100;   //200 retries = around 1 min or more.

        do {
            if (_currentLimitCount > _maxLimitCount) {
                _NewRoomCode = this.getRandomIntegerBetweenRange(100000, 999999);   //6 digits
            }
            else {
                _NewRoomCode = this.getRandomIntegerBetweenRange(10000, 99999);     //5 digits, default
                _currentLimitCount++;
            }
            _NewRoomCode_isValid = await this.CheckIfRoomCodeIsValid(_NewRoomCode);
            await Delay(300);
        } while (_NewRoomCode_isValid === false);

        if (this.props.isDevMode)
            console.log("Room Code : " + _NewRoomCode + "\nIsValid : " + _NewRoomCode_isValid);
        this.setState({ NewRoom_Code: _NewRoomCode, NewRoom_Code_isValid: _NewRoomCode_isValid });
    }
    CheckIfRoomCodeIsValid = async (_roomCode) => {
        let _RoomCode_isValid = false;

        // //check on loaded rooms first.
        // if (this.state.RoomList.length > 0) {
        //     _RoomCode_isValid = this.state.RoomList.findIndex(x => String(x.RoomCode).trim() === String(_roomCode).trim()) < 0;
        // }
        // else {
        //     _RoomCode_isValid = true;
        // }

        //check via FireStore.
        // if (_RoomCode_isValid) {
        let roomInfo = await this.GetRoomDataViaRoomCode(_roomCode);
        if (roomInfo === null || roomInfo === undefined)
            _RoomCode_isValid = true;
        else
            _RoomCode_isValid = false;
        // }

        return _RoomCode_isValid;
    }
    ResetNewRoomData = () => {
        this.setState({
            NewRoom_Title: '',
            NewRoom_Code: 0,
            NewRoom_Code_isValid: false,
            NewRoom_DateStart: '',      //new
            NewRoom_DateEnd: '',        //new
            NewRoom_TimeStart: '',
            NewRoom_TimeEnd: '',
            NewRoom_AccessibleOnEntireDay: false,
            NewRoom_Date: '',
            NewRoom_AccessibleOnSingleDayOnly: false,
            NewRoom_Group: null,    //obj
            NewRoom_Subject: null,    //obj

            //2021.09.27
            // NewRoom_QuestionSetId: '',
            // NewRoom_QuestionSetName: '',
            NewRoom_QuestionSet: null,
            NewRoom_UseCustomDuration: false,

            NewRoom_RoomType: 0,        //2021.10.14
            NewRoom_RandomQuestionMode: false,      //2023.10.06

            // GroupOptions: [],
            // SubjectOptions: [],

            IsSearchQsSetSelected: false,
            SearchQsSet_QuestionSet_Selected: null,
            SearchQsSet_ByGroup: null,      //obj
            SearchQsSet_BySubject: null,    //obj
            SearchQsSet_MaxQtyShow: 5,
            SearchQsSet_SelectQuestionSetId: '',

            // SearchQsSet_ByGroup: this.GetCachedSearchByGroup(true),

        }, () => {
            // this.NR_Date.current.value = '';     //auto-reset if element is hidded.
            this.NR_FromDate.current.value = '';
            this.NR_ToDate.current.value = '';
            this.NR_FromTime.current.value = '';
            this.NR_ToTime.current.value = '';
            this.NR_CHK_AccessibleOnEntireDay.current.checked = false;
            this.NR_CHK_AccessibleOnSingleDayOnly.current.checked = false;
            this.NR_CHK_RandomQuestionMode.current.checked = false;     //2023.10.06
            this.RecalculateDuration();
        });
    }
    //2021.09.28
    CreateNewRoom = async () => {

        if (this.state.PA_Create === false)
            return null;

        // this.props.SetAlert('', '<b>Create New Room currently not available.</b>', false);
        this.props.SetLoading('', 'Creating Room <' + this.state.NewRoom_Code + '>...', false);

        //room data validation.
        let result = this.RoomDataValidation();
        if (result.Success === false) {
            this.props.SetAlert('Validation Failed', '<b>Unable to create Room <' + this.state.NewRoom_Code + '>.</b><br /><br /><i>' + result.Messages + '</i>');
            return null;
        }

        //backUp set Date.
        if (this.state.NewRoom_DateStart === '' || this.state.NewRoom_DateEnd === '') {
            if (this.state.NewRoom_AccessibleOnSingleDayOnly) {
                this.setState({
                    NewRoom_DateStart: this.state.NewRoom_Date,
                    NewRoom_DateEnd: this.state.NewRoom_Date,
                });
            }
        }

        //current time.
        let _currentRoomId = (new Date()).getTime().toString();
        // let _current = moment();
        // let _currentRoomId = _current.valueOf();
        // let _lastUpdate = _current.format('YYYY-MM-DD HH:mm:ss');
        // let _lastUpdateUtc = _current.utc().format('YYYY-MM-DD HH:mm:ss');

        //insert room details on RTDB.  // YYYYMMDD > pkquiz-room-detail
        //insert room code on RTDB.     // YYYYMMDD > pkquiz-room-code
        //insert room state on RTDB.    // YYYYMMDD > pkquiz-live
        let success = await this.InsertRoomRecordOnRTDB(_currentRoomId);

        //insert room details on FS.
        if (success)
            success = await this.InsertRoomRecordOnFS(_currentRoomId);

        //trigger CMS sync Room details.    //2021.11.10
        await Delay(500);
        if (success)
            await this.TriggerRoomRecordSyncViaApi(_currentRoomId);

        //final.
        if (success) {
            let _roomCode = JSON.parse(JSON.stringify(this.state.NewRoom_Code));    //2021.11.20

            let _title = 'Room <' + this.state.NewRoom_Code + '>';
            let _msg = _title + ' has been created.';
            this.props.SetAlert(_title, _msg);
            if (this.props.isDevMode)
                console.log(_msg);
            this.ResetNewRoomData();
            this.ToggleCreateRoomModal();
            // this.LoadRoomList();

            //2021.11.20 - revamped.
            if (this.state.IsChild === false) {
                // this.LoadRoomList();
                this.LoadRoomList_ViaApi();
            }
            else {
                //for ManageEvent > Create New Room when editing Event.
                this.props.CreateNewRoomIsCompleted_Callback(_roomCode);
            }
        }
    }
    //2021.09.30
    RoomDataValidation = () => {
        let _messages = [];

        //room title.
        if (this.state.NewRoom_Title === '')
            _messages.push("Room's <u><b>Title</b></u> is still empty.");

        //group.
        if (this.state.NewRoom_Group === null)
            _messages.push("Room's <u><b>Group</b></u> is not yet picked.");

        //subject.
        if (this.state.NewRoom_Subject === null || this.state.NewRoom_SubjectName.length <= 0)
            _messages.push("Room's <u><b>Subject</b></u> is not yet picked.");

        //date.
        if (this.state.NewRoom_AccessibleOnSingleDayOnly) {
            if (this.state.NewRoom_Date === '')
                _messages.push("Room's <u><b>Date</b></u> is not yet picked.");
        }
        else {
            //start date.
            if (this.state.NewRoom_DateStart === '')
                _messages.push("Room's <u><b>Date (From)</b></u> is not yet picked.");
            //end date.
            if (this.state.NewRoom_DateEnd === '')
                _messages.push("Room's <u><b>Date (To)</b></u> is not yet picked.");
        }

        //start time.
        if (this.state.NewRoom_TimeStart === '')
            _messages.push("Room's <u><b>Time (From)</b></u> is not yet picked.");

        //end time.
        if (this.state.NewRoom_TimeEnd === '')
            _messages.push("Room's <u><b>Time (To)</b></u> is not yet picked.");

        //duration.
        if (this.state.NewRoom_UseCustomDuration) {
            if (
                this.state.NewRoom_Duration_Hour === 0
                && this.state.NewRoom_Duration_Min === 0
                && this.state.NewRoom_Duration_Sec === 0
            ) {
                _messages.push("Please fill in the appropriate amount for <u><b>custom duration</b></u>'s fields.");
            }
        }

        //assign DateStart & DateEnd.
        if (this.state.NewRoom_Date !== '' && this.state.NewRoom_DateStart === '' && this.state.NewRoom_DateEnd === '') {
            let _date = moment(this.state.NewRoom_Date).format('YYYY-MM-DD');
            this.setState({
                // NewRoom_Date: _date,
                NewRoom_DateStart: _date,
                NewRoom_DateEnd: _date,
            });
        }

        //room type.
        if (Number(this.state.NewRoom_RoomType) === 1) {
            //2021.11.08
            if (this.state.NewRoom_SupportedDocExt.length === 0)
                _messages.push("Room's <u><b>Supported File Format</b></u> is not yet selected.");
        }
        else {
            //question set.
            if (this.state.NewRoom_QuestionSet === null)
                _messages.push("Room's <u><b>Question Set</b></u> is not yet selected.");
        }

        let _msg = '<ul style="list-style-type:disclosure-closed;margin:0px;line-height:2;">';
        _messages.map((data, key) => {
            return _msg += '<li>' + data + '</li>';
        });
        _msg += '</ul>';

        return {
            Success: (_messages.length === 0),
            // Messages: _messages.join('<br />'), 
            Messages: _msg,
        };
    }
    //2021.09.29 - revamped.
    InsertRoomRecordOnRTDB = async (_currentRoomId) => {
        let success = false;
        let db = this.props.dbLiveQuiz;
        // let batch = db.batch();

        //room DateStart.
        let _date = moment(this.state.NewRoom_DateStart + ' 00:00:00').format('YYYYMMDD');

        //RTDB ref.
        // let rootRef = db.ref('pkquiz/' + _date);
        // let roomDetailRef = rootRef.ref('pkquiz-room-detail');
        // let roomCodeRef = rootRef.ref('pkquiz-room-code');
        // let roomStateRef = rootRef.ref('pkquiz-live');
        let roomDetailRef = db.ref('pkquiz/' + _date + '/pkquiz-room-detail/' + _currentRoomId);
        let roomCodeRef = db.ref('pkquiz/' + _date + '/pkquiz-room-code/' + this.state.NewRoom_Code);
        let roomStateRef = db.ref('pkquiz/' + _date + '/pkquiz-live/' + _currentRoomId);

        // //Questions array.
        // let _qsArray = [];
        // if (Number(this.state.NewRoom_RoomType) === 0) {
        //     [...Array(this.state.NewRoom_QuestionSet.TotalQuestion)].map((data, key) => {
        //         return _qsArray.push(key + 1);
        //     });
        // }

        //2021.10.13
        let _subjectId = 0;
        let subjectIndex = this.props.SubjectOptions.findIndex(x => x.value === this.state.NewRoom_SubjectName);
        if (subjectIndex > -1) {
            _subjectId = Number(this.props.SubjectOptions[subjectIndex].id);
        }

        //Room Details.
        let _roomDetails_DataToUpdate = {
            //2021.11.10
            AuthorId: Number(this.props.user.AuthorId),
            CenterUserId: Number(this.props.user.CenterUserId),

            DateStart: this.state.NewRoom_DateStart,
            DateEnd: this.state.NewRoom_DateEnd,
            Duration: this.state.NewRoom_Duration,
            DurationPerQuestion:
                Number(this.state.NewRoom_RoomType) === 0 ?
                    // Number((this.state.NewRoom_Duration / Number(this.state.NewRoom_QuestionSet.TotalQuestion)).toFixed(0))
                    Math.round((this.state.NewRoom_Duration / Number(this.state.NewRoom_QuestionSet.TotalQuestion)))
                    : this.state.NewRoom_Duration,
            EventCode: '',

            Grade: Number(this.state.NewRoom_RoomType) === 0 ? this.state.NewRoom_QuestionSet.GroupId : this.state.NewRoom_Group.id,
            //std 1~6, N2, K1, K2 = ok, others = no dedicated qs set category.

            // Organizer: this.state.NewRoom_Organizer,
            //2021.10.13
            Organizer: this.props.OrganizerInfo === null || this.props.OrganizerInfo === undefined ? ''
                : this.props.OrganizerInfo.DisplayName !== '' ?
                    String(this.props.OrganizerInfo.DisplayName) : String(this.props.OrganizerInfo.Name),
            OrganizerId: this.props.OrganizerInfo === null || this.props.OrganizerInfo === undefined ? ''
                : Number(this.props.OrganizerInfo.OrganizerId),
            OrganizerIdentity: this.props.OrganizerInfo === null || this.props.OrganizerInfo === undefined ? ''
                : String(this.props.OrganizerInfo.Identity),

            QnQty: Number(this.state.NewRoom_RoomType) === 0 ? Number(this.state.NewRoom_QuestionSet.TotalQuestion) : 1,
            // QnSet: '',
            // Questions: _qsArray.join(';'),
            RoomTitle: this.state.NewRoom_Title,

            Subject: this.state.NewRoom_SubjectName,
            SubjectId: Number(_subjectId),      //2021.10.13

            TimeEnd: this.state.NewRoom_TimeEnd,
            TimeStart: this.state.NewRoom_TimeStart,

            //new property. for new portal.
            QuestionSetUniqueId: Number(this.state.NewRoom_RoomType) === 0 ? String(this.state.NewRoom_QuestionSet.UniqueId) : '',
            GroupId: Number(Number(this.state.NewRoom_RoomType) === 0 ? this.state.NewRoom_QuestionSet.GroupId : this.state.NewRoom_Group.id),
            RoomCode: this.state.NewRoom_Code,
            RoomId: Number(_currentRoomId),
            RoomType: Number(this.state.NewRoom_RoomType),  //2021.10.14
            SupportedDocExt: this.state.NewRoom_SupportedDocExt,    //2021.11.08
            Remark: this.state.NewRoom_Remark,  //2021.11.10
            ExtraUrl: this.state.NewRoom_ExtraUrl,  //2021.12.10
            RandomQuestionMode: this.state.NewRoom_RandomQuestionMode,     //2023.10.06
        };
        // batch.set(roomDetailRef, _roomDetails_DataToUpdate);
        success = false;
        await roomDetailRef
            .set(_roomDetails_DataToUpdate)
            .then(() => { success = true; })
            .catch((error) => { if (this.props.isDevMode) { console.log('set room detail (failed) =\n' + error.message); } });

        //Room Code.
        // let _roomCode_DataToUpdate = {};
        // _roomCode_DataToUpdate[this.state.NewRoom_Code] = _currentRoomId;
        // batch.set(roomCodeRef, _roomCode_DataToUpdate);
        if (success) {
            success = false;
            await roomCodeRef
                .set(_currentRoomId)
                .then(() => { success = true; })
                .catch((error) => { if (this.props.isDevMode) { console.log('set room code (failed) =\n' + error.message); } });
        }

        //Room State.
        let _roomState_DataToUpdate = {
            DateStart: this.state.NewRoom_DateStart,
            DateEnd: this.state.NewRoom_DateEnd,
            TimeStart: this.state.NewRoom_TimeStart,
            TimeEnd: this.state.NewRoom_TimeEnd,
            QuizState: 'started',
            RoomId: _currentRoomId,
            QuizStartDT: this.state.NewRoom_DateStart + ' ' + this.state.NewRoom_TimeStart,
            QuizEndDT: this.state.NewRoom_DateEnd + ' ' + this.state.NewRoom_TimeEnd,
        };
        // batch.set(roomStateRef, _roomState_DataToUpdate);
        if (success) {
            success = false;
            await roomStateRef
                .set(_roomState_DataToUpdate)
                .then(() => { success = true; })
                .catch((error) => { if (this.props.isDevMode) { console.log('set room state (failed) =\n' + error.message); } });
        }

        // // Commit the batch
        // batch.commit().then(() => {
        //     if (this.props.isDevMode)
        //         console.log('All room records are saved.');
        // });

        if (success) {
            if (this.props.isDevMode)
                console.log('All room records are saved.');
        }
        else {
            //remove posted records on any failed attempt, as roomId will be renew every time create room is issued.

            //remove room detail.
            await roomDetailRef.remove();

            //remove room code.
            await roomCodeRef.remove();

            //remove room state.
            await roomStateRef.remove();

            if (this.props.isDevMode)
                console.log('(failed) Error occurred. All previously saved room records are Removed.');
        }

        return success;
    }
    //2021.09.29 - revamped.
    InsertRoomRecordOnFS = async (_currentRoomId) => {
        let success = false;

        // //Questions array.
        // let _qsArray = [];
        // if (Number(this.state.NewRoom_RoomType) === 0) {
        //     [...Array(this.state.NewRoom_QuestionSet.TotalQuestion)].map((data, key) => {
        //         return _qsArray.push(key + 1);
        //     });
        // }

        //2021.10.13
        let _subjectId = 0;
        let subjectIndex = this.props.SubjectOptions.findIndex(x => String(x.value) === String(this.state.NewRoom_SubjectName));
        if (subjectIndex > -1) {
            _subjectId = Number(this.props.SubjectOptions[subjectIndex].id);
        }

        let _modal = {
            //2021.11.10
            AuthorId: Number(this.props.user.AuthorId),
            CenterUserId: Number(this.props.user.CenterUserId),

            Date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),   //Created Date.
            DateEnd: this.state.NewRoom_DateEnd,
            DateStart: this.state.NewRoom_DateStart,
            Duration: this.state.NewRoom_Duration,
            EventCode: '',

            // Organizer: this.state.NewRoom_Organizer,
            //2021.11.16
            Organizer: this.props.OrganizerInfo === null || this.props.OrganizerInfo === undefined ? ''
                : this.props.OrganizerInfo.DisplayName !== '' ?
                    String(this.props.OrganizerInfo.DisplayName) : String(this.props.OrganizerInfo.Name),
            OrganizerId: this.props.OrganizerInfo === null || this.props.OrganizerInfo === undefined ? ''
                : Number(this.props.OrganizerInfo.OrganizerId),
            OrganizerIdentity: this.props.OrganizerInfo === null || this.props.OrganizerInfo === undefined ? ''
                : String(this.props.OrganizerInfo.Identity),

            RoomCode: this.state.NewRoom_Code,
            RoomId: Number(_currentRoomId),
            RoomTitle: this.state.NewRoom_Title,

            SubjectName: this.state.NewRoom_SubjectName,
            SubjectId: Number(_subjectId),      //2021.10.13

            TimeStart: this.state.NewRoom_TimeStart,
            TimeEnd: this.state.NewRoom_TimeEnd,

            //new property. for new portal.
            QuestionSetUniqueId: Number(this.state.NewRoom_RoomType) === 0 ? String(this.state.NewRoom_QuestionSet.UniqueId) : '',
            GroupId: Number(Number(this.state.NewRoom_RoomType) === 0 ? this.state.NewRoom_QuestionSet.GroupId : this.state.NewRoom_Group.id),
            RoomType: Number(this.state.NewRoom_RoomType),  //2021.10.14
            SupportedDocExt: this.state.NewRoom_SupportedDocExt,    //2021.11.08
            Remark: this.state.NewRoom_Remark,  //2021.11.10
            ExtraUrl: this.state.NewRoom_ExtraUrl,  //2021.12.10

            //2023.10.06
            RandomQuestionMode: this.state.NewRoom_RandomQuestionMode,
            Subject: this.state.NewRoom_SubjectName,
            DurationPerQuestion:
                Number(this.state.NewRoom_RoomType) === 0 ?
                    // Number((this.state.NewRoom_Duration / Number(this.state.NewRoom_QuestionSet.TotalQuestion)).toFixed(0))
                    Math.round((this.state.NewRoom_Duration / Number(this.state.NewRoom_QuestionSet.TotalQuestion)))
                    : this.state.NewRoom_Duration,
            QnQty: Number(this.state.NewRoom_RoomType) === 0 ? Number(this.state.NewRoom_QuestionSet.TotalQuestion) : 1,
            // Questions: _qsArray.join(';'),
        };
        await this.props.firestore
            .collection("LiveQuiz_UniqueRoomCode")
            .doc(_currentRoomId)
            .set(_modal)
            .then(() => { success = true; })
            .catch((error) => { if (this.props.isDevMode) { console.log('set room (FS) (failed) =\n' + error.message); } });
        return success;
    }
    //2021.11.10
    TriggerRoomRecordSyncViaApi = async (_currentRoomId, _new = true) => {
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Quiz/Room/Sync/'
            + this.props.user.CenterUserId + '/'
            + this.props.user.AuthorId + '/'
            + String(_new ? this.state.NewRoom_Code : this.state.SelectedRoom.RoomCode) + '/'
            + String(_new ? _currentRoomId : this.state.SelectedRoom.RoomId),
            // Api/LearningCentre/Quiz/Room/Sync/{centerUserId}/{authorId}/{roomcode}/{room_id}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (!data.success)
                    if (this.props.isDevMode)
                        console.log('Error', 'api - room sync (' + (_new ? 'new' : 'update') + ') (failed)\n' + JSON.stringify(data));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - room sync (' + (_new ? 'new' : 'update') + ') (failed)\n' + error.message);
            });
    }
    Set_New_RoomData_FileExtOptions_Ref = (ref) => {
        this.New_RoomData_FileExtOptions_Ref.push(ref);
    }
    UpdateStateOnFileExtOptions_NewRoom = (index) => {
        let _supportedFileFormats = this.state.NewRoom_SupportedDocExt;
        this.New_RoomData_FileExtOptions_Ref.map((data, key) => {
            if (index === key) {
                let findIndex = _supportedFileFormats.findIndex(x => x === FileExtOptions[key].value);
                if (findIndex < 0) {
                    _supportedFileFormats.push(FileExtOptions[key].value);
                    data.checked = true;
                }
                else {
                    _supportedFileFormats.splice(findIndex, 1);
                    data.checked = false;
                }
            }
            return null;
        });
        // if (_supportedFileFormats.length > 0) {
        this.setState({ NewRoom_SupportedDocExt: _supportedFileFormats, }, () => {
            if (this.props.isDevMode) {
                // console.log('New_RoomData_FileExtOptions_Ref = \n' + this.New_RoomData_FileExtOptions_Ref.length);
                console.log('NewRoom_SupportedDocExt =\n' + this.state.NewRoom_SupportedDocExt.join(','));
            }
        });
        // }
    }
    //#endregion === Create New Room === end ===//


    //#region === Search Question Set === start ===//
    Toggle_Search_QuestionSetModal = (resetCache = false) => {
        this.setState({
            ShowSearchQuestionSetModal: !this.state.ShowSearchQuestionSetModal,
            NewRoom_QuestionSet: null,
        }, () => {
            if (this.state.ShowSearchQuestionSetModal) {
                // let _group = this.state.SearchQsSet_ByGroup;
                // if (_group === null) {
                //     if (this.state.ShowEditRoomModal)
                //         _group = this.state.EditRoom_Group !== null ? this.state.EditRoom_Group : null;
                //     else
                //         _group = this.state.NewRoom_Group !== null ? this.state.NewRoom_Group : null;
                // }
                //2021.10.14
                let _group = this.GetCachedSearchByGroup(resetCache);
                // console.log('NewRoom_Group =\n' + JSON.stringify(this.state.NewRoom_Group));
                // console.log('_group =\n' + JSON.stringify(_group));

                //2022.02.14
                let _subject = this.GetCachedSearchBySubject(resetCache);

                this.setState({
                    SearchQsSet_ByGroup: _group,
                    SearchQsSet_BySubject: _subject,    //2022.02.14
                    SearchQsSet_MaxQtyShow: 5,
                    SearchQsSet_QuestionSet_Selected: null,
                    IsSearchQsSetSelected: false,
                    QuestionSetList: [],
                    QuestionSetList_lastVisible: null,
                });   //reset
            }
        });
    }
    SearchQuestionSetByConditions_ViaApi = async () => {
        if (this.props.user === null)
            return null;

        this.setState({ SearchQsSet_Processing: true });

        //init.
        let _List = [];
        let totalRows = 0;

        const { authorId, organizerId } = GetPropIds(this.props);

        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Quiz/QuestionSet/List/'
            + organizerId + '/'
            + authorId + '/'
            + CheckObjectNumber(this.state.SearchQsSet_ByGroup, 'id') + '/'
            + CheckObjectNumber(this.state.SearchQsSet_BySubject, 'id') + '/'
            + 0 + '/'
            + this.state.SearchQsSet_MaxQtyShow,
            // Api/LearningCentre/Quiz/QuestionSet/List/{organizerId}/{authorId}/{groupId}/{subjectId}/{pageIndex}/{pageSize}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('SearchQuestionSetByConditions_ViaApi (source)', JSON.stringify(data));
                if (data.success) {
                    _List = data.data.list;
                    totalRows = CheckObjectNumber(data.data, 'totalRows', _List.length);     //2023.11.24
                }
                else {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - qSet list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - qSet list (failed)\n' + error.message);
            });

        if (_List.length > 0) {
            //Finalize list.
            for (let i = 0; i < _List.length; i++) {
                _List[i] = CapitalizeJsonKeys(_List[i]);
            }
            // console.log('SearchQuestionSetByConditions_ViaApi (CapitalizeJsonKeys)', JSON.stringify(_List));
            _List = FormatList_QuestionSet(this.props, _List);
            if (this.props.isDevMode)
                console.log('SearchQuestionSetByConditions_ViaApi (final)', JSON.stringify(_List));
        }
        this.setState({
            SearchQsSet_Processing: false,
            QuestionSetList: _List,
            TotalRows: totalRows,
            // QuestionSetList_lastVisible: _List_lastVisible,
        }, () => {
            this.Toggle_Search_QuestionSetModal();
            this.Toggle_Select_QuestionSetModal();
        });
    }
    SearchQuestionSetByConditions = async () => {
        if (this.props.user === null)
            return null;

        this.setState({ SearchQsSet_Processing: true });

        //init.
        let _List_lastVisible = null;
        let _List = [];

        const { centerUserId, authorId } = GetPropIds(this.props);

        // let _centerUserId = Number(this.props.user.CenterUserId);
        // let _authorId = Number(this.props.user.AuthorId);
        // let _groupId = Number(this.state.SearchQsSet_ByGroup.id);
        // console.log('GroupId = ' + _groupId);

        //2021.11.18 - revamped.
        let docRef = this.props.firestore.collection("QuizBank").doc('QuizQuestionSet').collection('QuizQuestionSets');
        if (this.props.isSuperAdmin) { }
        else {
            docRef = docRef
                .where('CenterUserId', '==', centerUserId)
                .where('AuthorId', '==', authorId);
        }

        //2022.02.09
        if (this.state.SearchQsSet_ByGroup !== null) {
            let _groupId = this.GetValue(this.state.SearchQsSet_ByGroup, 'id', 0);
            if (_groupId > 0)
                docRef = docRef.where('GroupId', '==', Number(_groupId));
        }
        if (this.state.SearchQsSet_BySubject !== null) {
            let _subjectId = this.GetValue(this.state.SearchQsSet_BySubject, 'id', 0);
            if (_subjectId !== '')
                docRef = docRef.where('SubjectId', '==', Number(_subjectId));
        }
        // console.log('_groupId = ' + _groupId + '\n_subjectId = ' + _subjectId);

        //Search Query.
        await docRef
            .orderBy('CreatedOnUtc', 'desc')
            .limit(this.state.SearchQsSet_MaxQtyShow)   //default: 5
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                        // dataArray[dataArray.length - 1].Id = doc.id;    //important
                    });
                    if (dataArray.length > 0) {
                        _List = dataArray;
                        _List_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    }
                }
                // if (this.props.isDevMode)
                //     console.log('qSet list result =\n' + JSON.stringify(_List));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });

        // // 2022.02.10
        // if (_List.length > 0) {
        //     _List.map((data, key) => {
        //         if (data.hasOwnProperty('Subject') === false)
        //             data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value };     //default
        //         else
        //             if (data.Subject['Id'] === 0 || data.Subject['Id'] === undefined)
        //                 data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value };     //default
        //         return null;
        //     });
        // }
        //2022.02.14
        _List = FormatList_QuestionSet(this.props, _List);
        if (this.props.isDevMode)
            console.log('qSet list result =\n' + JSON.stringify(_List));

        this.setState({
            SearchQsSet_Processing: false,
            QuestionSetList: _List,
            QuestionSetList_lastVisible: _List_lastVisible,
        }, () => {
            this.Toggle_Search_QuestionSetModal();
            this.Toggle_Select_QuestionSetModal();
        });
    }
    LoadMoreQuestionSetList = async () => {
        this.setState({ QuestionSetList_More_isLoading: true });
        let _centerUserId = Number(this.props.user.CenterUserId);
        let _authorId = Number(this.props.user.AuthorId);
        let _List = [];
        let _temp_List = this.state.QuestionSetList;
        let _List_lastVisible = null;
        await this.props.firestore
            .collection("QuizBank")
            .doc('QuizQuestionSet')
            .collection('QuizQuestionSets')
            .where('CenterUserId', '==', _centerUserId)
            .where('AuthorId', '==', _authorId)
            .orderBy("CreatedOnUtc", "desc")
            .startAfter(this.state.QuestionSetList_lastVisible)
            .limit(Number(this.state.SearchQsSet_MaxQtyShow))
            .get()
            .then((querySnapshot) => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => { dataArray.push(doc.data()); });
                    // console.log(JSON.stringify(dataArray));
                    if (dataArray.length > 0) {
                        _List = dataArray;
                        _List_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    }
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });
        if (_List.length > 0) {
            // _temp_List = this.state.QuestionSetList;
            _List.map((data, key) => {
                return _temp_List.push(data);
            });
            _temp_List.sort((a, b) => moment(b.CreatedOnUtc) - moment(a.CreatedOnUtc));

            //2022.02.14
            _temp_List = FormatList_QuestionSet(this.props, _temp_List);
        }
        this.setState({
            QuestionSetList: _temp_List,
            QuestionSetList_lastVisible: _List.length > 0 ? _List_lastVisible : this.state.QuestionSetList_lastVisible,
            QuestionSetList_More_isLoading: false
        });
        // ScrollToElement('bottomPage', 500);
    }
    //moved to GlobaleFunctions - 2023.11.27
    // //2022.02.14
    // FormatList_QuestionSet = (_List = []) => {
    //     if (_List.length > 0) {
    //         // let getLabel = (data) => {
    //         //     const _name = CheckObjectStringEmpty(data, 'Name', CheckObjectStringEmpty(data, 'name'));
    //         //     const _bc = CheckObjectStringEmpty(data, 'BC', CheckObjectStringEmpty(data, 'Bc'));
    //         //     const _bm = CheckObjectStringEmpty(data, 'BM', CheckObjectStringEmpty(data, 'Bm'));
    //         //     const _tag = CheckObjectStringEmpty(data, 'TagName', CheckObjectStringEmpty(data, 'tagName'));
    //         //     const _label = _name + ' (' + _bc + ') (' + _bm + ')' + (CheckNullValue(_tag) === null ? '' : ' (' + _tag + ')');
    //         //     return _label;
    //         // }
    //         _List.map((data, key) => {
    //             if (CheckNullValue(data) !== null) {

    //                 //2023.11.23
    //                 if (data.hasOwnProperty('SubjectId')) {
    //                     const findIndex = this.props.SubjectOptions.findIndex(x => Number(x.id) === CheckObjectNumber(data, 'SubjectId'));
    //                     if (findIndex > -1)
    //                         data['Subject'] = { Id: this.props.SubjectOptions[findIndex].id, Name: this.props.SubjectOptions[findIndex].value, Label: this.props.SubjectOptions[findIndex].label };     //default
    //                 }
    //                 if (data.hasOwnProperty('Subject') === false)
    //                     data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value, Label: this.props.SubjectOptions[0].label };     //default
    //                 else
    //                     if (CheckObjectNumber(data.Subject, 'Id') === 0)
    //                         data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value, Label: this.props.SubjectOptions[0].label };     //default

    //                 //2023.11.23
    //                 if (data.hasOwnProperty('GroupId')) {
    //                     const findIndex = this.props.GroupOptions.findIndex(x => Number(x.id) === CheckObjectNumber(data, 'GroupId'));
    //                     if (findIndex > -1)
    //                         data['Group'] = { Id: this.props.GroupOptions[findIndex].id, Name: this.props.GroupOptions[findIndex].value };     //default
    //                 }

    //                 // if (data.Subject.hasOwnProperty('Label') === false) {
    //                 //     let temp = this.props.SubjectList[0];
    //                 //     let _findIndex = this.props.SubjectList.findIndex(x => Number(x['Id']) === Number(data['Subject']['Id']));
    //                 //     if (_findIndex < 0) {
    //                 //         data.Subject['Label'] = temp['Name'] + ' (' + temp['BC'] + ') (' + temp['BM'] + ') (' + temp['TagName'] + ')';
    //                 //     }
    //                 //     else {
    //                 //         temp = this.props.SubjectList[_findIndex];
    //                 //         data.Subject['Label'] = temp['Name'] + ' (' + temp['BC'] + ') (' + temp['BM'] + ') (' + temp['TagName'] + ')';
    //                 //     }
    //                 // }

    //                 // if (data.hasOwnProperty('Subject') === false)
    //                 //     data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value };     //default
    //                 // else
    //                 //     if (data.Subject['Id'] === 0 || data.Subject['Id'] === undefined)
    //                 //         data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value };     //default

    //                 // if (data.Subject.hasOwnProperty('Label') === false) {
    //                 //     let temp = this.props.SubjectList[0];
    //                 //     let _findIndex = this.props.SubjectList.findIndex(x => Number(x['Id']) === Number(data['Subject']['Id']));
    //                 //     if (_findIndex < 0) {
    //                 //         data.Subject['Label'] = temp['Name'] + ' (' + temp['BC'] + ') (' + temp['BM'] + ') (' + temp['TagName'] + ')';
    //                 //     }
    //                 //     else {
    //                 //         temp = this.props.SubjectList[_findIndex];
    //                 //         data.Subject['Label'] = temp['Name'] + ' (' + temp['BC'] + ') (' + temp['BM'] + ') (' + temp['TagName'] + ')';
    //                 //     }
    //                 // }
    //             }
    //             return data;
    //         });
    //     }
    //     return _List;
    // }
    //#endregion === Search Question Set === end ===//

    GetCachedSearchByGroup = (resetCache) => {
        //2021.10.14
        let _group = this.state.SearchQsSet_ByGroup;
        if (resetCache) {
            // if (this.state.ShowEditRoomModal) {
            //     //Edit Room.
            //     if (this.state.RoomData !== null) {
            //         let _findIndex = this.props.GroupOptions.findIndex(x => x.id === this.state.RoomData.GroupId);
            //         if (_findIndex > -1)
            //             _group = this.props.GroupOptions[_findIndex];
            //         else
            //             _group = null;
            //     }
            // }
            // else if (this.state.ShowCreateRoomModal) {
            //     //New Room.
            //     if (this.state.NewRoom_Group !== null) {
            //         let _findIndex = this.props.GroupOptions.findIndex(x => x.id === this.state.NewRoom_Group.id);
            //         if (_findIndex > -1)
            //             _group = this.props.GroupOptions[_findIndex];
            //         else
            //             _group = null;
            //     }
            //     else {
            //         _group = null;
            //     }
            // }
            // else {
            //     _group = null;
            // }

            //2022.02.14 - revamp.
            let _groupId = 0;
            if (this.state.ShowEditRoomModal)
                _groupId = this.state.RoomData.GroupId === undefined ? 0 : this.state.RoomData.GroupId;     //Edit Room.

            else if (this.state.ShowCreateRoomModal)
                _groupId = this.state.NewRoom_Group === null ? 0 : this.state.NewRoom_Group.id;     //New Room.

            if (_groupId > 0) {
                let _findIndex = this.props.GroupOptions.findIndex(x => x.id === _groupId);
                if (_findIndex > -1)
                    _group = this.props.GroupOptions[_findIndex];
                else
                    _group = null;
            }
            else {
                _group = null;
            }
        }
        return _group;
    }

    //2022.02.14
    GetCachedSearchBySubject = (resetCache) => {
        let _subject = this.state.SearchQsSet_BySubject;
        if (resetCache) {
            let _subjectId = 0;
            if (this.state.ShowEditRoomModal)
                _subjectId = this.state.RoomData.SubjectId === undefined ? 0 : this.state.RoomData.SubjectId;     //Edit Room.

            else if (this.state.ShowCreateRoomModal)
                _subjectId = this.state.NewRoom_Subject === null ? 0 : this.state.NewRoom_Subject.id;     //New Room.

            if (_subjectId > 0) {
                let _findIndex = this.props.SubjectOptions.findIndex(x => x.id === _subjectId);
                if (_findIndex > -1)
                    _subject = this.props.SubjectOptions[_findIndex];
                else
                    _subject = null;
            }
            else {
                _subject = null;
            }
        }
        return _subject;
    }

    //#region === Select Question Set === start ===//
    Toggle_Select_QuestionSetModal = (resetCache = false) => {
        //2021.10.14
        let _group = this.GetCachedSearchByGroup(resetCache);

        //2022.02.14
        let _subject = this.GetCachedSearchBySubject(resetCache);

        this.setState({
            ShowSelectQuestionSetModal: !this.state.ShowSelectQuestionSetModal,
            SearchQsSet_ByGroup: _group,
            SearchQsSet_BySubject: _subject,
        });
    }
    GetQuestionSetsResultList = () => {
        let rows = [];
        rows.push(<tr className='hide-row-hover' key={0}>
            <td></td>
            <td>#</td>
            <td align='left'>Name</td>
            <td align='left'>Group</td>
            <td align='left'>Subject</td>
            <td>Total Question</td>
            <td align='left'>Remark</td>
            <td>Created</td>
        </tr>);
        if (this.state.QuestionSetList.length > 0) {
            this.state.QuestionSetList.map((data, key) => {
                return rows.push(<tr onClick={() => this.SelectThisQuestionSet(data)} key={'qSet_' + key}>
                    <td>
                        <input type='radio' value={data.UniqueId} name='QSet'
                            checked={
                                this.state.SearchQsSet_QuestionSet_Selected === null ? false :
                                    this.state.SearchQsSet_QuestionSet_Selected.UniqueId === data.UniqueId
                            }
                        ></input>
                    </td>
                    <td>{key + 1}</td>
                    <td align='left'>{data.Name}</td>
                    <td align='left'>{data.Group.Name}</td>
                    <td align='left'>{data.Subject.Name}</td>
                    <td>{data.TotalQuestion}</td>
                    <td align='left'>{data.Remark}</td>
                    {/* <td>{moment.utc(data.CreatedOnUtc).format('YYYY-MM-DD hh:mm:ss A')}</td> */}
                    <td>{moment.utc(data.CreatedOnUtc).local().format('lll')}</td>
                </tr>);
            });
        }
        else {
            rows.push(<tr key={'qSet_0'}><td colSpan='7' align='center'>list is empty.</td></tr>);
        }
        return rows;
    }
    SelectThisQuestionSet = (data) => {
        this.setState({ SearchQsSet_QuestionSet_Selected: data, IsSearchQsSetSelected: true });
    }
    SearchAgain_SelectQuestionSet = () => {
        this.Toggle_Select_QuestionSetModal();
        setTimeout(() => {
            this.Toggle_Search_QuestionSetModal();
        }, 300);
    }
    Confirm_SelectOnThisQuestionSet = () => {
        // let selectedQuestionSet = this.state.QuestionSetList[this.state.SearchQsSet_QuestionSet_Selected];
        // this.setState({
        //     NewRoom_QuestionSetId: selectedQuestionSet.UniqueId,
        //     NewRoom_QuestionSetName: selectedQuestionSet.Name,
        // });
        // this.Toggle_Select_QuestionSetModal();
        if (this.state.SearchQsSet_QuestionSet_Selected !== null) {

            // //2021.09.28
            // let _group = this.state.NewRoom_Group;
            // if (this.state.NewRoom_Group === null) {
            //     let _qSet = this.state.SearchQsSet_QuestionSet_Selected;
            //     this.props.GroupOptions.map((data, key) => {
            //         if (_qSet.GroupName === data.value && _qSet.GroupId === data.id)
            //             _group = data;
            //         return null;
            //     });
            // }

            // this.setState({
            //     // NewRoom_QuestionSetId: this.state.SearchQsSet_QuestionSet_Selected.UniqueId,
            //     // NewRoom_QuestionSetName: this.state.SearchQsSet_QuestionSet_Selected.Name,
            //     NewRoom_QuestionSet: this.state.SearchQsSet_QuestionSet_Selected,
            //     NewRoom_Group: _group,
            // });

            //2021.10.05
            if (this.state.ShowEditRoomModal) {
                let _roomData = this.state.RoomData;
                _roomData.QuestionSetUniqueId = this.state.SearchQsSet_QuestionSet_Selected.UniqueId;
                this.setState({
                    RoomData: _roomData,
                    // Cached_RoomData: JSON.parse(JSON.stringify(_roomData)),
                    EditRoom_QuestionSet: this.state.SearchQsSet_QuestionSet_Selected,
                    // Cached_EditRoom_QuestionSet: JSON.parse(JSON.stringify(this.state.SearchQsSet_QuestionSet_Selected)),
                    // EditRoom_Group: _group,
                    // Cached_EditRoom_Group: JSON.parse(JSON.stringify(_group)),
                });
            }
            else {
                //2021.09.28
                let _group = this.state.NewRoom_Group;
                if (this.state.NewRoom_Group === null) {
                    let _qSet = this.state.SearchQsSet_QuestionSet_Selected;
                    this.props.GroupOptions.map((data, key) => {
                        if (_qSet.Group.Name === data.value && _qSet.Group.Id === data.id)
                            _group = data;
                        return null;
                    });
                }
                //2022.02.14
                let _subject = this.state.NewRoom_Subject;
                if (this.state.NewRoom_Subject === null) {
                    let _qSet = this.state.SearchQsSet_QuestionSet_Selected;
                    this.props.SubjectOptions.map((data, key) => {
                        if (String(_qSet.Subject.Name) === String(data.value) && Number(_qSet.Subject.Id) === Number(data.id))
                            _subject = data;
                        return null;
                    });
                }
                this.setState({
                    NewRoom_QuestionSet: this.state.SearchQsSet_QuestionSet_Selected,
                    NewRoom_Group: _group,
                    NewRoom_Subject: _subject,
                    NewRoom_SubjectName: _subject === null ? '' : String(_subject.value),
                });
            }

            this.Toggle_Select_QuestionSetModal(true);
        }
    }
    //2021.09.28
    GetGroupLabel = () => {
        // let _label = '';
        // if (this.state.SearchQsSet_QuestionSet_Selected !== null) {
        //     let _qSet = this.state.SearchQsSet_QuestionSet_Selected;
        //     this.props.GroupOptions.map((data, key) => {
        //         if (_qSet.GroupId === data.id)
        //             _label = data.label;
        //         return null;
        //     });
        // }
        // return _label;

        //2021.10.05 - revamped.
        let _label = '';
        let _qSet = null;
        if (this.state.ShowEditRoomModal) {
            if (this.state.EditRoom_QuestionSet !== null) {
                _qSet = this.state.EditRoom_QuestionSet;
            }
        }
        else {
            if (this.state.SearchQsSet_QuestionSet_Selected !== null) {
                _qSet = this.state.SearchQsSet_QuestionSet_Selected;
            }
        }
        if (_qSet !== null) {
            this.props.GroupOptions.map((data, key) => {
                if (_qSet.Group.Id === data.id)
                    _label = data.label;
                return null;
            });
        }
        return _label;
    }
    //#endregion === Select Question Set === end ===//

    //2023.11.24
    LoadResultFromSelectedRoom = async () => {
        this.props.SetAlert('', 'Coming soon...', false);
    }

    render() {
        return (
            <>
                <div hidden={this.props.IsChild && this.props.IsChild !== undefined}>
                    <Row className='rowStyle'>
                        <Col style={{ display: 'flex' }}>
                            <span style={{ fontSize: 20, fontWeight: 'bold', paddingRight: 15, alignSelf: 'center' }}>{
                                this.props.roomTypeFile === false ? 'Manage Rooms' : 'Document Rooms'
                            }</span>
                            <DropdownButton
                                id='search-room-type-dropdown-button'
                                title={
                                    this.state.SelectedRoomTypeForQuery > -1 ?
                                        RoomTypeOptions[this.state.SelectedRoomTypeForQuery].subLabel
                                        : 'All Room Types'
                                }
                                drop='down'
                                onSelect={(option) => this.setState({ SelectedRoomTypeForQuery: Number(option) - 2 }, () => {
                                    localStorage.setItem('ManageRoom_List_SelectedRoomTypeForQuery', this.state.SelectedRoomTypeForQuery);
                                    // this.LoadRoomList();
                                    this.LoadRoomList_ViaApi();
                                    if (this.props.isDevMode)
                                        console.log('item #' + JSON.stringify(option) + ' aka ' + this.state.SelectedRoomTypeForQuery);
                                })}
                                style={{ width: 215 }}
                            >
                                <Dropdown.Item disabled={true}>Select a Room Type</Dropdown.Item>
                                <Dropdown.Item as="button" eventKey={1} value={1}>All Room Types</Dropdown.Item>
                                <Dropdown.Item as="button" eventKey={2} value={2} disabled={RoomTypeOptions[0].disabled}>{RoomTypeOptions[0].subLabel}</Dropdown.Item>
                                <Dropdown.Item as="button" eventKey={3} value={3} disabled={RoomTypeOptions[1].disabled}>{RoomTypeOptions[1].subLabel}</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        <Col className='colBtn'>
                            <button type="button" className="btn btn-outline-info"
                                onClick={() => this.setState({ ShowSearchRoomByRoomCodeModal: true, SearchRoomByRoomCode_Processing: false, SearchRoomByRoomCode_RoomCode: '' })}
                            >Search Room by Room Code</button>
                            {
                                this.state.PA_Create === false ? null :
                                    <>
                                        &nbsp;&nbsp;
                                        <button type="button" className="btn btn-outline-primary"
                                            onClick={this.ToggleCreateRoomModal}
                                            hidden={this.props.roomTypeFile === true}
                                        >New Room</button>
                                    </>
                            }
                        </Col>
                    </Row>
                    <p />

                    <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                        <thead>
                            <tr>
                                <th width='50'>#</th>
                                <th width='100'>Room&nbsp;Code</th>
                                <th style={{ textAlign: 'left' }}>Room&nbsp;Title</th>
                                <th hidden={this.state.hasTotalFileQty === false} width='85'>Total&nbsp;Files</th>
                                <th width='120'>Subject</th>
                                <th width='90'>Time&nbsp;Start</th>
                                <th width='90'>Time&nbsp;End&nbsp;</th>
                                <th width='110'>Date&nbsp;/&nbsp;Period</th>
                                <th width='95'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                // this.props.Events !== null ?
                                //     this.RoomListComponents()
                                //     : <tr><td colSpan='15'></td></tr>

                                //2021.11.17
                                this.state.IsRoomListLoaded ?
                                    this.state.RoomList.length > 0 ?
                                        this.RoomListComponents()
                                        : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                                    : <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>
                            }
                            {
                                this.state.RoomList_More_isLoading ?
                                    <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                                    :
                                    this.state.RoomList.length === 0 ? null :
                                        PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                            }
                        </tbody>
                    </table>

                    <span id={'bottomPage'}>&nbsp;</span>
                </div>

                {/* Room - Delete - Modal */}
                <Modal show={this.state.ShowDeleteRoomModal} onHide={this.ToggleDeleteRoomModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove Room</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span>
                            Room Code: <b>{this.state.SelectedRoom === null ? '' : this.state.SelectedRoom.RoomCode}</b>
                            <br />Are you sure you want to <b>remove</b> this room ?
                            <br /><b><i>The removal is not reversible.</i></b>
                        </span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.ToggleDeleteRoomModal}>Cancel</Button>
                        &nbsp;&nbsp;
                        <Button variant="primary" onClick={() => this.DeleteThisRoom()}>Confirm</Button>
                    </Modal.Footer>
                </Modal>

                {/* Room - Edit Selected - Modal */}
                <Modal size='lg' show={this.state.ShowEditRoomModal} onHide={this.ToggleEditRoomModal}>
                    {
                        this.state.SelectedRoom === null || this.state.RoomData === null ?
                            <Modal.Body>empty room data.</Modal.Body>
                            :
                            <>
                                <Modal.Header closeButton>
                                    <Modal.Title>{'Edit Room <' + this.state.SelectedRoom.RoomCode + '>'}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ width: '100%' }}>
                                        <table className='table tbStyle1' cellPadding='10' cellSpacing='10' border='0' style={{ marginBottom: 0, borderStyle: 'hidden' }}>
                                            <tbody>
                                                <tr>
                                                    <td width='150'>Room Code</td>
                                                    <td>{this.state.SelectedRoom.RoomCode}</td>
                                                </tr>
                                                <tr>
                                                    <td width='150'>Room Title *</td>
                                                    <td>
                                                        <input type="text" style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['RoomTitle'] = String(e.target.value);
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                            defaultValue={this.state.SelectedRoom.RoomTitle}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Group *</td>
                                                    <td>
                                                        <Select
                                                            options={this.props.GroupOptions}
                                                            placeholder={this.GetPlaceholder_Group()}
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    neutral50: 'black',  // placeholder color
                                                                },
                                                            })}
                                                            onChange={(option) => {
                                                                if (this.props.isDevMode)
                                                                    console.log(JSON.stringify(option));

                                                                let _roomData = this.state.RoomData;
                                                                _roomData['GroupId'] = Number(option.id);      //e.g. 25
                                                                _roomData['Grade'] = Number(option.id);        //e.g. 25, not N2

                                                                // //2021.10.06
                                                                // let _group = null;
                                                                // let _findIndex = this.props.GroupOptions.findIndex(x => x.id === option.id);
                                                                // if (_findIndex > -1)
                                                                //     _group = this.props.GroupOptions[_findIndex];

                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                    // EditRoom_Group: _group,
                                                                    EditRoom_Group: option,
                                                                });
                                                            }}
                                                        // defaultValue={this.state.RoomData.Grade}    //also use as GroupId
                                                        // defaultValue={this.GetGroupValue()}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Subject *</td>
                                                    <td>
                                                        <Select
                                                            options={this.props.SubjectOptions}
                                                            placeholder={this.GetPlaceholder_Subject()}
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    neutral50: 'black',  // placeholder color
                                                                },
                                                            })}
                                                            onChange={(option) => {
                                                                if (this.props.isDevMode)
                                                                    console.log(JSON.stringify(option));

                                                                let _roomData = this.state.RoomData;
                                                                _roomData['Subject'] = { Id: Number(option.id), Name: String(option.value), Label: String(option.label) };
                                                                _roomData['SubjectId'] = Number(option.id);
                                                                _roomData['SubjectName'] = String(option.value);

                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                    EditRoom_Subject: option,
                                                                });
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Date *</td>
                                                    <td>
                                                        From <input type='date'
                                                            ref={this.EDR_FromDate}
                                                            onChange={(e) => {
                                                                // this.SaveDataInput(e.currentTarget.value, DataInput.FromDate)
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['DateStart'] = String(e.currentTarget.value);
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                        ></input>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        To <input type='date'
                                                            ref={this.EDR_ToDate}
                                                            onChange={(e) => {
                                                                // this.SaveDataInput(e.currentTarget.value, DataInput.ToDate)
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['DateEnd'] = String(e.currentTarget.value);
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                        ></input>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Time *</td>
                                                    <td>
                                                        From <input type='time'
                                                            ref={this.EDR_FromTime}
                                                            onChange={(e) => {
                                                                // this.SaveDataInput(e.currentTarget.value, DataInput.FromTime)
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['TimeStart'] = String(e.currentTarget.value);
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                        ></input>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        To <input type='time'
                                                            ref={this.EDR_ToTime}
                                                            onChange={(e) => {
                                                                // this.SaveDataInput(e.currentTarget.value, DataInput.ToTime)
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['TimeEnd'] = String(e.currentTarget.value);
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                        ></input>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Duration *</td>
                                                    <td style={{ padding: 0 }}>
                                                        <table cellPadding='0' cellSpacing='0' border='0' width='100%' style={{ borderTop: 0, marginBottom: 0, borderStyle: 'hidden' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div style={{ padding: 5, paddingLeft: 15, paddingRight: 15, border: '1px solid green', borderRadius: 5, width: 'fit-content', textAlign: 'center' }}
                                                                        >{this.GetDurationText(
                                                                            this.state.RoomData === null ? 0 :
                                                                                Number(this.state.RoomData.Duration)
                                                                        ) + ' (max: 6 hours)'}</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan='2' style={{ padding: 0 }}>
                                                                        <table cellPadding='0' cellSpacing='0' border='0' width='60%' style={{ marginBottom: 0, borderStyle: 'hidden' }}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td align='center'>
                                                                                        <input type='number' min={0} max={6} defaultValue={0} ref={this.EDR_Duration_Hour}
                                                                                            onChange={() => this.Recalculate_Duration_EditRoom()}></input> hour
                                                                                    </td>
                                                                                    <td align='center'>
                                                                                        <input type='number' min={0} max={59} defaultValue={0} ref={this.EDR_Duration_Min}
                                                                                            onChange={() => this.Recalculate_Duration_EditRoom()}></input> min
                                                                                    </td>
                                                                                    <td align='center'>
                                                                                        <input type='number' min={0} max={59} defaultValue={0} ref={this.EDR_Duration_Sec}
                                                                                            onChange={() => this.Recalculate_Duration_EditRoom()}></input> sec
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Room Type *</td>
                                                    <td>
                                                        <Select
                                                            options={RoomTypeOptions}
                                                            isOptionDisabled={(option) => option.disabled === true} //2023.10.04
                                                            placeholder={
                                                                this.state.RoomData !== null ?
                                                                    this.state.RoomData.hasOwnProperty('RoomType') ?
                                                                        RoomTypeOptions[this.state.RoomData.RoomType].label
                                                                        : Locale("room-type", this.props.Locale)
                                                                    : Locale("room-type", this.props.Locale)
                                                            }
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    neutral50: 'black',  // placeholder color
                                                                },
                                                            })}
                                                            onChange={(option) => {
                                                                if (this.props.isDevMode)
                                                                    console.log(JSON.stringify(option));

                                                                let _roomData = this.state.RoomData;

                                                                _roomData['RoomType'] = String(option.value);
                                                                if (Number(option.value) === 1)
                                                                    _roomData['QuestionSetUniqueId'] = '';

                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr hidden={
                                                    this.state.RoomData.hasOwnProperty('RoomType') ?
                                                        this.state.RoomData.RoomType === 1
                                                        : true
                                                }>
                                                    <td>Question Set *</td>
                                                    <td style={{ padding: 0 }}>
                                                        <table cellPadding='0' cellSpacing='0' border='0' width='100%' style={{ borderTop: 0, marginBottom: 0, borderStyle: 'hidden' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td valign='middle'>
                                                                        {
                                                                            this.state.RoomData.QuestionSetUniqueId !== '' ?
                                                                                this.state.EditRoom_QuestionSet === null ?
                                                                                    <span style={{ color: 'gray', }}><i>(no question set is selected)</i></span>
                                                                                    :
                                                                                    <>
                                                                                        {this.state.EditRoom_QuestionSet.Name}
                                                                                        <span style={{ fontSize: 12, color: 'gray' }}>
                                                                                            <br />{'Group : ' + this.GetGroupLabel()}
                                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            {'Total Question : ' + this.state.EditRoom_QuestionSet.TotalQuestion}
                                                                                            <br />{'Subject : ' + this.state.EditRoom_QuestionSet['Subject']['Label']}
                                                                                            <br />{this.state.EditRoom_QuestionSet.UniqueId}
                                                                                        </span>
                                                                                    </>
                                                                                :
                                                                                <span style={{ color: 'gray', }}><i>(no question set is selected)</i></span>
                                                                        }
                                                                    </td>
                                                                    <td align='right' style={{ verticalAlign: 'middle' }}>
                                                                        <Button onClick={this.Toggle_Search_QuestionSetModal}>Select Question Set</Button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width='150'>Extra Url (Flipbook)</td>
                                                    <td>
                                                        <input type="text" style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['ExtraUrl'] = String(e.target.value);
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                            defaultValue={CheckObjectStringEmpty(this.state.SelectedRoom, 'ExtraUrl')}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    {
                                                        //2023.10.06
                                                    }
                                                    <td>Random Question Mode</td>
                                                    <td>
                                                        <div
                                                            onClick={(e) => {
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['RandomQuestionMode'] = !this.EDR_CHK_RandomQuestionMode.current.checked;
                                                                this.EDR_CHK_RandomQuestionMode.current.checked = _roomData['RandomQuestionMode'];
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                            style={{ cursor: 'pointer', userSelect: 'none' }}
                                                        >
                                                            <input type='checkbox' ref={this.EDR_CHK_RandomQuestionMode}
                                                                checked={CheckObjectBoolean(this.state.RoomData, 'RandomQuestionMode')}
                                                                readOnly={true}
                                                                style={{ marginBottom: 15, pointerEvents: 'none' }}
                                                            ></input><span
                                                            > enable randomly arrange question when answering quiz.</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    {
                                                        //2023.10.18
                                                    }
                                                    <td>Restrict Access To Time Range Only</td>
                                                    <td>
                                                        <div
                                                            onClick={(e) => {
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['RestrictAccessToTimeRangeOnly'] = !this.EDR_CHK_RestrictAccessToTimeRangeOnly.current.checked;
                                                                this.EDR_CHK_RestrictAccessToTimeRangeOnly.current.checked = _roomData['RestrictAccessToTimeRangeOnly'];
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                            style={{ cursor: 'pointer', userSelect: 'none' }}
                                                        >
                                                            <input type='checkbox' ref={this.EDR_CHK_RestrictAccessToTimeRangeOnly}
                                                                checked={CheckObjectBoolean(this.state.RoomData, 'RestrictAccessToTimeRangeOnly')}
                                                                readOnly={true}
                                                                style={{ marginBottom: 15, pointerEvents: 'none' }}
                                                            ></input><span
                                                            > restrict current quiz room only accessible within specified time period.</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    {
                                                        //2023.10.30
                                                    }
                                                    <td>Set Quiz Ended</td>
                                                    <td>
                                                        <div
                                                            onClick={(e) => {
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['QuizEnded'] = !this.EDR_CHK_QuizEnded.current.checked;
                                                                this.EDR_CHK_QuizEnded.current.checked = CheckObjectBoolean(_roomData, 'QuizEnded');
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                            style={{ cursor: 'pointer', userSelect: 'none' }}
                                                        >
                                                            <input type='checkbox' ref={this.EDR_CHK_QuizEnded}
                                                                checked={CheckObjectBoolean(this.state.RoomData, 'QuizEnded')}
                                                                readOnly={true}
                                                                style={{ marginBottom: 15, pointerEvents: 'none' }}
                                                            ></input><span
                                                            > end this quiz & restrict access of any future entry regardless of End Date.</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    {
                                                        //2023.10.18
                                                    }
                                                    <td>Force Retricted Access</td>
                                                    <td>
                                                        <div
                                                            onClick={(e) => {
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['ForceRetrictedAccess'] = !this.EDR_CHK_ForceRetrictedAccess.current.checked;
                                                                this.EDR_CHK_ForceRetrictedAccess.current.checked = _roomData['ForceRetrictedAccess'];
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                            style={{ cursor: 'pointer', userSelect: 'none' }}
                                                        >
                                                            <input type='checkbox' ref={this.EDR_CHK_ForceRetrictedAccess}
                                                                checked={CheckObjectBoolean(this.state.RoomData, 'ForceRetrictedAccess')}
                                                                readOnly={true}
                                                                style={{ marginBottom: 15, pointerEvents: 'none' }}
                                                            ></input><span
                                                            > restrict access immediately & kick all users back to home screen.</span>
                                                            <br /><span>(For Emergency Purpose)</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr hidden={true}>
                                                    {
                                                        //2023.11.03
                                                    }
                                                    <td>Enable Statistic Report</td>
                                                    <td>
                                                        <div
                                                            onClick={(e) => {
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['EnableStatisticReport'] = !this.EDR_CHK_EnableStatisticReport.current.checked;
                                                                this.EDR_CHK_EnableStatisticReport.current.checked = _roomData['EnableStatisticReport'];
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                            style={{ cursor: 'pointer', userSelect: 'none' }}
                                                        >
                                                            <input type='checkbox' ref={this.EDR_CHK_EnableStatisticReport}
                                                                checked={CheckObjectBoolean(this.state.RoomData, 'EnableStatisticReport')}
                                                                readOnly={true}
                                                                style={{ marginBottom: 15, pointerEvents: 'none' }}
                                                            ></input><span
                                                            > include this room as part of the statistic report.</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    {
                                                        //2024.04.18
                                                    }
                                                    <td>Exclude from Statistic Report</td>
                                                    <td>
                                                        <div
                                                            onClick={(e) => {
                                                                let _roomData = this.state.RoomData;
                                                                _roomData['ExcludedFromStatisticReport'] = !this.EDR_CHK_ExcludedFromStatisticReport.current.checked;
                                                                this.EDR_CHK_ExcludedFromStatisticReport.current.checked = _roomData['ExcludedFromStatisticReport'];
                                                                this.setState({
                                                                    RoomData: _roomData,
                                                                });
                                                            }}
                                                            style={{ cursor: 'pointer', userSelect: 'none' }}
                                                        >
                                                            <input type='checkbox' ref={this.EDR_CHK_ExcludedFromStatisticReport}
                                                                checked={CheckObjectBoolean(this.state.RoomData, 'ExcludedFromStatisticReport')}
                                                                readOnly={true}
                                                                style={{ marginBottom: 15, pointerEvents: 'none' }}
                                                            ></input><span
                                                            > exclude this room from part of the statistic report.</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* <tr hidden={this.props.isDevMode === false}><td colSpan='2'>{JSON.stringify(this.state.RoomData).replaceAll(',', ', ')}</td></tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer style={{ padding: '10px 0px', }}>
                                    <Row style={{ width: '100%', margin: 0, }}>
                                        <Col>
                                            <Button variant="danger" onClick={() => {
                                                this.ToggleDeleteRoomModal();
                                            }}
                                                disabled={this.state.RoomData.hasOwnProperty('QuestionSetUniqueId') ?
                                                    this.state.RoomData.QuestionSetUniqueId === ''
                                                    : true}
                                            >Remove</Button>
                                        </Col>
                                        <Col style={{ textAlign: 'end' }}>
                                            <Button variant="secondary"
                                                onClick={() => {
                                                    // this.ResetEditedRoomData();
                                                    this.ToggleEditRoomModal();
                                                }}
                                            >Cancel</Button>
                                            &nbsp;&nbsp;
                                            <Button variant="secondary"
                                                onClick={() => this.ResetEditedRoomData()}
                                            >Reload</Button>
                                            &nbsp;&nbsp;
                                            <Button variant="primary"
                                                onClick={() => this.SaveEditedRoom()}
                                                // disabled={this.state.AllowToSaveEditedRoom === false}
                                                disabled={JSON.stringify(this.state.RoomData) === JSON.stringify(this.state.Cached_RoomData)}
                                            >Save</Button>
                                        </Col>
                                    </Row>
                                </Modal.Footer>
                            </>
                    }
                </Modal>

                {/* Room - Create New - Modal */}
                <Modal size='lg' show={this.state.ShowCreateRoomModal} onHide={this.ToggleCreateRoomModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create New Room</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ width: '100%' }}>
                            <table className='table tbStyle1' cellPadding='10' cellSpacing='10' border='0' style={{ marginBottom: 0, borderStyle: 'hidden' }}>
                                <tbody>
                                    <tr>
                                        <td width='150'>Room Code</td>
                                        <td>
                                            {
                                                this.state.NewRoom_Code_isValid ?
                                                    <>
                                                        <b>{this.state.NewRoom_Code}</b>
                                                        &nbsp;&nbsp;
                                                        <button type="button" className="btn btn-outline btn1"
                                                            // hidden={this.state.NewRoom_Code === 0 && this.state.NewRoom_Code_isValid === false}
                                                            onClick={() => this.GenerateRandomRoomCode()}
                                                        ><i className="fa fa-refresh icon1"></i></button>
                                                    </>
                                                    : <ProgressBar animated now={100} className='progressbar1' />
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width='150'>Room Title *</td>
                                        <td>
                                            <input type="text" style={{ width: '100%' }}
                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Title)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Group *</td>
                                        <td>
                                            <Select
                                                options={this.props.GroupOptions}
                                                placeholder={
                                                    this.state.NewRoom_Group !== null ?
                                                        this.state.NewRoom_Group.label
                                                        :
                                                        Locale("grade", this.props.Locale)
                                                }
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'black',  // placeholder color
                                                    },
                                                })}
                                                onChange={(option) => this.SaveDataInput(option, DataInput.Group)}
                                                value={this.state.NewRoom_Group !== null ? this.state.NewRoom_Group.value : null}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Subject *</td>
                                        <td>
                                            <Select
                                                options={this.props.SubjectOptions}
                                                placeholder={
                                                    this.state.NewRoom_Subject !== null ?
                                                        this.state.NewRoom_Subject.label
                                                        :
                                                        Locale("subject", this.props.Locale)
                                                }
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'black',  // placeholder color
                                                    },
                                                })}
                                                // onChange={(option) => this.setState({ NewRoom_SubjectName: option.value, })}
                                                onChange={(option) => this.SaveDataInput(option, DataInput.Subject)}
                                                value={this.state.NewRoom_SubjectName.length > 0 ? this.state.NewRoom_SubjectName : null}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Date *</td>
                                        <td>
                                            <div
                                                onClick={() => this.SaveDataInput(!this.NR_CHK_AccessibleOnSingleDayOnly.current.checked, DataInput.AccessibleOnSingleDayOnly)}
                                                style={{ cursor: 'pointer', userSelect: 'none', marginBottom: 10 }}
                                            >
                                                <input type='checkbox'
                                                    ref={this.NR_CHK_AccessibleOnSingleDayOnly}
                                                    checked={this.state.NewRoom_AccessibleOnSingleDayOnly}
                                                    readOnly={true}
                                                    style={{ pointerEvents: 'none' }}
                                                ></input><span
                                                > accessible on single day only.</span>
                                            </div>
                                            {
                                                this.state.NewRoom_AccessibleOnSingleDayOnly ?
                                                    <>
                                                        <div style={{ width: '65%', display: 'inline-block' }}>
                                                            <input type='date' ref={this.NR_Date} onChange={(e) => this.SaveDataInput(e.currentTarget.value, DataInput.Date)}></input>
                                                            &nbsp;&nbsp;
                                                            <button
                                                                type='button'
                                                                className='link-button'
                                                                onClick={() => {
                                                                    var _today = moment().format('YYYY-MM-DD');
                                                                    this.SaveDataInput(_today, DataInput.Date);
                                                                    this.NR_Date.current.value = _today;
                                                                }}>set as today's date</button>
                                                        </div>
                                                        <div style={{ width: '35%', textAlign: 'right', display: 'inline-block' }}>
                                                            <span style={{ color: 'gray' }} hidden={this.state.NewRoom_Date === ''}
                                                            >({moment(this.state.NewRoom_Date).format('LL')})</span>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        From <input type='date' ref={this.NR_FromDate} onChange={(e) => this.SaveDataInput(e.currentTarget.value, DataInput.FromDate)}></input>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        To <input type='date' ref={this.NR_ToDate} onChange={(e) => this.SaveDataInput(e.currentTarget.value, DataInput.ToDate)}></input>
                                                    </>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Time *</td>
                                        <td>
                                            From <input type='time' ref={this.NR_FromTime} onChange={(e) => this.SaveDataInput(e.currentTarget.value, DataInput.FromTime)} disabled={this.state.NewRoom_AccessibleOnEntireDay}></input>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            To <input type='time' ref={this.NR_ToTime} onChange={(e) => this.SaveDataInput(e.currentTarget.value, DataInput.ToTime)} disabled={this.state.NewRoom_AccessibleOnEntireDay}></input>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <div
                                                onClick={() => this.SaveDataInput(!this.NR_CHK_AccessibleOnEntireDay.current.checked, DataInput.AccessibleOnEntireDay)}
                                                style={{ cursor: 'pointer', userSelect: 'none', display: 'initial' }}
                                            >
                                                <input type='checkbox'
                                                    ref={this.NR_CHK_AccessibleOnEntireDay}
                                                    checked={this.state.NewRoom_AccessibleOnEntireDay}
                                                    readOnly={true}
                                                    style={{ pointerEvents: 'none' }}
                                                ></input><span
                                                > accessible on the entire day.</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Duration *</td>
                                        <td style={{ padding: 0 }}>
                                            <table cellPadding='0' cellSpacing='0' border='0' width='100%' style={{ borderTop: 0, marginBottom: 0, borderStyle: 'hidden' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div style={{ padding: 5, border: '1px solid green', borderRadius: 5, width: '100%', textAlign: 'center' }}
                                                            >{this.GetDurationText(this.state.NewRoom_Duration)}</div>
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <div
                                                                onClick={() => this.SaveDataInput(!this.NR_CHK_UseCustomDuration.current.checked, DataInput.UseCustomDuration)}
                                                                style={{ cursor: 'pointer', userSelect: 'none' }}
                                                            >
                                                                <input type='checkbox'
                                                                    ref={this.NR_CHK_UseCustomDuration}
                                                                    checked={this.state.NewRoom_UseCustomDuration}
                                                                    readOnly={true}
                                                                    style={{ pointerEvents: 'none' }}
                                                                ></input><span
                                                                > use custom duration. (max: 6 hours)</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr hidden={this.state.NewRoom_UseCustomDuration === false}>
                                                        <td colSpan='2' style={{ padding: 0 }}>
                                                            {
                                                                this.state.NewRoom_UseCustomDuration === false ? null :
                                                                    <table cellPadding='0' cellSpacing='0' border='0' width='60%' style={{ marginBottom: 0, borderStyle: 'hidden' }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align='center'>
                                                                                    <input type='number' min={0} max={6} defaultValue={0} ref={this.NR_Duration_Hour}
                                                                                        onChange={(e) => {
                                                                                            let _value = Number(e.currentTarget.value);
                                                                                            _value = _value <= 6 ? _value : 6;
                                                                                            this.setState({
                                                                                                NewRoom_Duration_Hour: _value,
                                                                                                NewRoom_Duration_Min: _value === 6 ? 0 : this.state.NewRoom_Duration_Min,
                                                                                                NewRoom_Duration_Sec: _value === 6 ? 0 : this.state.NewRoom_Duration_Sec,
                                                                                            }, () => this.RecalculateDuration())
                                                                                        }}></input> hour
                                                                                </td>
                                                                                <td align='center'>
                                                                                    <input type='number' min={0} max={59} defaultValue={0} ref={this.NR_Duration_Min}
                                                                                        onChange={(e) => {
                                                                                            let _value = Number(e.currentTarget.value);
                                                                                            this.setState({ NewRoom_Duration_Min: _value <= 59 ? _value : 59, }, () => this.RecalculateDuration())
                                                                                        }}></input> min
                                                                                </td>
                                                                                <td align='center'>
                                                                                    <input type='number' min={0} max={59} defaultValue={0} ref={this.NR_Duration_Sec}
                                                                                        onChange={(e) => {
                                                                                            let _value = Number(e.currentTarget.value);
                                                                                            this.setState({ NewRoom_Duration_Sec: _value <= 59 ? _value : 59, }, () => this.RecalculateDuration())
                                                                                        }}></input> sec
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Room Type *</td>
                                        <td>
                                            <Select
                                                options={RoomTypeOptions}
                                                isOptionDisabled={(option) => option.disabled === true} //2023.10.04
                                                placeholder={RoomTypeOptions[Number(this.state.NewRoom_RoomType)].label}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'black',  // placeholder color
                                                    },
                                                })}
                                                onChange={(option) =>
                                                    this.setState({
                                                        NewRoom_RoomType: option.value,
                                                        NewRoom_QuestionSet: option.value === 1 ? null : this.state.NewRoom_QuestionSet,
                                                    })
                                                }
                                            // value={this.state.NewRoom_RoomType}
                                            />
                                        </td>
                                    </tr>
                                    <tr hidden={Number(this.state.NewRoom_RoomType) === 1}>
                                        <td>Question Set *</td>
                                        <td style={{ padding: 0 }}>
                                            <table cellPadding='0' cellSpacing='0' border='0' width='100%' style={{ borderTop: 0, marginBottom: 0, borderStyle: 'hidden' }}>
                                                <tbody>
                                                    <tr>
                                                        <td valign='middle'>
                                                            {
                                                                this.state.NewRoom_QuestionSet !== null ?
                                                                    <>
                                                                        {this.state.NewRoom_QuestionSet.Name}
                                                                        <span style={{ fontSize: 12, color: 'gray' }}>
                                                                            {/* <br />{this.state.NewRoom_QuestionSet.GroupName} */}
                                                                            <br />{'Group : ' + this.GetGroupLabel()}
                                                                            {/* <br />{this.state.NewRoom_Group.label} */}
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            {'Total Question : ' + this.state.NewRoom_QuestionSet.TotalQuestion}
                                                                            <br />{'Subject : ' + this.state.NewRoom_QuestionSet['Subject']['Label']}
                                                                            <br />{this.state.NewRoom_QuestionSet.UniqueId}
                                                                        </span>
                                                                    </>
                                                                    :
                                                                    <span style={{ color: 'lightgray', }}><i>(no question set is selected)</i></span>
                                                            }
                                                        </td>
                                                        <td align='right' style={{ verticalAlign: 'middle' }}>
                                                            <Button onClick={() => this.Toggle_Search_QuestionSetModal(true)}>Select Question Set</Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr hidden={Number(this.state.NewRoom_RoomType) === 0}>
                                        <td width='150'>Remark</td>
                                        <td>
                                            <span hidden={Number(this.state.NewRoom_RoomType) !== 1} style={{ color: 'gray', }}
                                            >this Remark value will be display as <u>{Locale("room-essay-title", this.props.Locale)}</u> in Document Room.</span>
                                            <input type="text" style={{ width: '100%', marginTop: 5, }}
                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Remark)}
                                            />
                                        </td>
                                    </tr>
                                    <tr hidden={Number(this.state.NewRoom_RoomType) === 0}>
                                        <td>Supported File Format(s) *</td>
                                        <td>
                                            {
                                                FileExtOptions.map((data, key) => {
                                                    return <div style={{ width: '100%', cursor: 'pointer' }}
                                                        onClick={() => this.UpdateStateOnFileExtOptions_NewRoom(key)}
                                                        key={'FileExtOptions_' + key}
                                                    >
                                                        <input type='checkbox' ref={this.Set_New_RoomData_FileExtOptions_Ref}
                                                        ></input>&nbsp;&nbsp;<span>{data.label}</span>
                                                    </div>
                                                })
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width='150'>Extra Url (Flipbook)</td>
                                        <td>
                                            <input type="text" style={{ width: '100%' }}
                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.ExtraUrl)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        {
                                            //2023.10.06
                                        }
                                        <td>Random Question Mode</td>
                                        <td>
                                            <div
                                                onClick={() => this.SaveDataInput(!this.NR_CHK_RandomQuestionMode.current.checked, DataInput.RandomQuestionMode)}
                                                style={{ cursor: 'pointer', userSelect: 'none' }}
                                            >
                                                <input type='checkbox' ref={this.NR_CHK_RandomQuestionMode}
                                                    checked={this.state.NewRoom_RandomQuestionMode}
                                                    readOnly={true}
                                                    style={{ marginBottom: 15, pointerEvents: 'none' }}
                                                ></input><span
                                                > enable randomly arrange question when answering quiz.</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr hidden={true}>
                                        {
                                            //2023.10.26
                                        }
                                        <td>Restrict Access To Time Range Only</td>
                                        <td>
                                            <div
                                                onClick={() => this.SaveDataInput(!this.NR_CHK_RestrictAccessToTimeRangeOnly.current.checked, DataInput.RestrictAccessToTimeRangeOnly)}
                                                style={{ cursor: 'pointer', userSelect: 'none' }}
                                            >
                                                <input type='checkbox' ref={this.NR_CHK_RestrictAccessToTimeRangeOnly}
                                                    checked={this.state.NewRoom_RestrictAccessToTimeRangeOnly}
                                                    readOnly={true}
                                                    style={{ marginBottom: 15, pointerEvents: 'none' }}
                                                ></input><span
                                                > restrict current quiz room only accessible within specified time period.</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr hidden={true}>
                                        {
                                            //2023.10.30
                                        }
                                        <td>Set Quiz Ended</td>
                                        <td>
                                            <div
                                                onClick={() => this.SaveDataInput(!this.NR_CHK_QuizEnded.current.checked, DataInput.QuizEnded)}
                                                style={{ cursor: 'pointer', userSelect: 'none' }}
                                            >
                                                <input type='checkbox' ref={this.NR_CHK_QuizEnded}
                                                    checked={this.state.NewRoom_QuizEnded}
                                                    readOnly={true}
                                                    style={{ marginBottom: 15, pointerEvents: 'none' }}
                                                ></input><span
                                                > end this quiz & restrict access of any future entry regardless of End Date.</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr hidden={true}>
                                        {
                                            //2023.10.26
                                        }
                                        <td>Force Retricted Access</td>
                                        <td>
                                            <div
                                                onClick={() => this.SaveDataInput(!this.NR_CHK_ForceRetrictedAccess.current.checked, DataInput.ForceRetrictedAccess)}
                                                style={{ cursor: 'pointer', userSelect: 'none' }}
                                            >
                                                <input type='checkbox' ref={this.NR_CHK_ForceRetrictedAccess}
                                                    checked={this.state.NewRoom_ForceRetrictedAccess}
                                                    readOnly={true}
                                                    style={{ marginBottom: 15, pointerEvents: 'none' }}
                                                ></input><span
                                                > restrict access immediately & kick all users back to home screen.</span>
                                                <br /><span>(For Emergency Purpose)</span>
                                            </div>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        {
                                            //2023.11.03
                                        }
                                        <td>Enable Statistic Report</td>
                                        <td>
                                            <input type='checkbox' ref={this.NR_CHK_EnableStatisticReport}
                                                className='cursor-pointer'
                                                onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.EnableStatisticReport)}
                                                style={{ marginBottom: 15, }}></input><span
                                                    style={{ cursor: 'default' }}
                                                    onClick={() => this.SaveDataInput(!this.NR_CHK_EnableStatisticReport.current.checked, DataInput.EnableStatisticReport)}
                                                > include this room as part of the statistic report.</span>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        {
                                            //2024.04.18
                                        }
                                        <td>Exclude from Statistic Report</td>
                                        <td>
                                            <div
                                                onClick={() => this.SaveDataInput(!this.NR_CHK_ExcludedFromStatisticReport.current.checked, DataInput.ExcludedFromStatisticReport)}
                                                style={{ cursor: 'pointer', userSelect: 'none' }}
                                            >
                                                <input type='checkbox' ref={this.NR_CHK_ExcludedFromStatisticReport}
                                                    checked={this.state.NewRoom_ExcludedFromStatisticReport}
                                                    readOnly={true}
                                                    style={{ marginBottom: 15, pointerEvents: 'none' }}
                                                ></input><span
                                                > exclude this room from part of the statistic report.</span>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.ResetNewRoomData();
                            this.ToggleCreateRoomModal();
                        }}>Cancel</Button>
                        &nbsp;&nbsp;
                        <Button variant="secondary" onClick={() => {
                            this.ResetNewRoomData();
                            this.GenerateRandomRoomCode();
                        }}>Reset</Button>
                        &nbsp;&nbsp;
                        <Button
                            variant={this.state.AllowToCreateRoom ? "primary" : "secondary"}
                            // variant="primary"
                            onClick={() => this.CreateNewRoom()}
                        // disabled={this.state.AllowToCreateRoom === false}
                        >Create</Button>
                    </Modal.Footer>
                </Modal>

                {/* Room - Search Question Set - Modal */}
                <Modal show={this.state.ShowSearchQuestionSetModal}
                    onHide={() => {
                        if (this.state.SearchQsSet_Processing)
                            this.DoNothing();
                        else
                            this.Toggle_Search_QuestionSetModal(true);
                    }
                    }
                    centered>
                    <Modal.Header closeButton={this.state.SearchQsSet_Processing === false}>
                        <Modal.Title>{
                            this.state.SearchQsSet_Processing ? 'Searching...' : 'Search Question Set'
                        }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.SearchQsSet_Processing ?
                                <ProgressBar animated now={100} className='progressbar1' />
                                :
                                <span>
                                    Search for Question Set:<br />
                                    <table width='100%' cellPadding='5' cellSpacing='5' border='0' style={{ borderColor: 'grey', marginBottom: 0, }}>
                                        <tbody>
                                            <tr>
                                                <td width={90}>Group</td>
                                                <td>
                                                    <Select
                                                        options={this.props.GroupOptions}
                                                        placeholder={
                                                            this.state.SearchQsSet_ByGroup !== null ?
                                                                this.state.SearchQsSet_ByGroup.label
                                                                : Locale("not-specify-group", this.props.Locale)
                                                        }
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                neutral50: 'black',  // placeholder color
                                                            },
                                                        })}
                                                        value={this.state.SearchQsSet_ByGroup !== null ? this.state.SearchQsSet_ByGroup.value : null}
                                                        onChange={(option) => this.SaveDataInput(option, DataInput.SearchQsSet_ByGroup)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Subject</td>
                                                <td>
                                                    <Select
                                                        options={this.props.SubjectOptions}
                                                        placeholder={
                                                            this.state.SearchQsSet_BySubject !== null ?
                                                                this.state.SearchQsSet_BySubject.label
                                                                :
                                                                Locale("subject", this.props.Locale)
                                                        }
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                neutral50: 'black',  // placeholder color
                                                            },
                                                        })}
                                                        value={this.state.SearchQsSet_BySubject !== null ? this.state.SearchQsSet_BySubject.value : null}
                                                        onChange={(option) => this.SaveDataInput(option, DataInput.SearchQsSet_BySubject)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Max Result</td>
                                                <td>
                                                    <input type="number" style={{ width: '100%' }}
                                                        defaultValue={this.state.SearchQsSet_MaxQtyShow}
                                                        onChange={(e) => this.SaveDataInput(Number(e.target.value), DataInput.SearchQsSet_MaxQtyShow)}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                        }
                    </Modal.Body>
                    {
                        !this.state.SearchQsSet_Processing ?
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.Toggle_Search_QuestionSetModal(true)}>Cancel</Button>
                                &nbsp;&nbsp;
                                {/* <Button variant="primary" onClick={this.SearchQuestionSetByConditions} disabled={this.state.SearchQsSet_ByGroup === null}>Search</Button> */}
                                <Button variant="primary" onClick={this.SearchQuestionSetByConditions_ViaApi} disabled={this.state.SearchQsSet_ByGroup === null}>Search</Button>
                            </Modal.Footer>
                            : null
                    }
                </Modal>

                {/* Room - Select Question Set - Modal */}
                <Modal size='xl' show={this.state.ShowSelectQuestionSetModal} onHide={() => this.Toggle_Select_QuestionSetModal(true)}
                    centered
                // dialogClassName='alert-dialog-bordered'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Select Question Set</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span>
                            Select a Question Set from list:<br />
                            <table className='table table-hover tbStyle' width='100%' cellPadding='5' cellSpacing='5'
                                style={{
                                    border: '1px solid gray', marginTop: 10, marginBottom: 0,
                                    borderTop: 'inset', borderBottomStyle: 'groove',
                                }}>
                                <tbody>
                                    {this.GetQuestionSetsResultList()}
                                </tbody>
                            </table>
                        </span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.SearchAgain_SelectQuestionSet}>Search Again</Button>
                        &nbsp;&nbsp;
                        <Button variant="secondary" onClick={() => this.Toggle_Select_QuestionSetModal(true)}>Cancel</Button>
                        &nbsp;&nbsp;
                        <Button variant="primary" onClick={this.Confirm_SelectOnThisQuestionSet} disabled={this.state.SearchQsSet_QuestionSet_Selected === null}>Select</Button>
                    </Modal.Footer>
                </Modal>

                {/* Room - Search Room by Room Code - Modal */}
                <Modal show={this.state.ShowSearchRoomByRoomCodeModal}
                    onHide={() => {
                        if (this.state.SearchRoomByRoomCode_Processing)
                            this.DoNothing();
                        else
                            this.setState({ ShowSearchRoomByRoomCodeModal: false, SearchRoomByRoomCode_RoomCode: '', })
                    }}
                    centered >
                    <Modal.Header closeButton={this.state.SearchRoomByRoomCode_Processing === false}>
                        <Modal.Title>{
                            this.state.SearchRoomByRoomCode_Processing ? 'Searching...' : 'Search Room by Room Code'
                        }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.SearchRoomByRoomCode_Processing ?
                                <ProgressBar animated now={100} className='progressbar1' />
                                :
                                <table cellPadding={5} cellSpacing={0} width='100%'>
                                    <tbody>
                                        <tr>
                                            <td align='right'><span>Search by Room Code</span></td>
                                            <td>
                                                <input className='form-control' type="number" style={{ width: '100%' }}
                                                    placeholder='(enter room code here)'
                                                    onChange={(e) => this.setState({ SearchRoomByRoomCode_RoomCode: String(e.target.value) })}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                        }
                    </Modal.Body>
                    {
                        !this.state.SearchRoomByRoomCode_Processing ?
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.setState({ ShowSearchRoomByRoomCodeModal: false, SearchRoomByRoomCode_RoomCode: '', })}>Cancel</Button>
                                &nbsp;&nbsp;
                                <Button variant="primary" onClick={() => this.SearchRoomByRoomCode()} disabled={isNaN(Number(this.state.SearchRoomByRoomCode_RoomCode)) === 'NaN'}>Search</Button>
                            </Modal.Footer>
                            : null
                    }
                </Modal>
            </>
        );
    }
}

