import React from 'react';
import { Redirect } from 'react-router-dom';



export default class AdminHome extends React.Component {
    
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    state = {
        redirect: false,
        redirectLink: '',
    }

    //2021.06.30
    componentDidMount = () => {
        if (this.props.user === null || this.props.isSuperAdmin === false) {
            this.GotoLoginPage();
            return null;
        }

        if (!window.location.href.includes('localhost') || this.props.user.uid !== 'uj4Zk0dQSyNfIIJBqVPfne8y5z32')
            this.GotoQuizManagement();
    }

    GotoPage = (loc) => {
        this.setState({
            redirect: true,
            redirectLink: loc,
        });
    }

    //2021.06.30
    GotoLoginPage = () => {
        this.GotoPage('/login');
    }

    GotoHome = () => {
        this.GotoPage('/home');
    }

    GotoQuizAdminTool = () => {
        this.GotoPage('/admin/quiz/admintool');
    }

    GotoQuizManagement = () => {
        this.GotoPage('/admin/quiz/management');
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            if (this.props.user === null) {
                return <Redirect to={'/login'} />;
            }
            else {
                return (
                    <div style={{
                        // backgroundColor: 'lavender', 
                        backgroundColor: 'transparent',
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 50
                    }}>
                        <div className="container" style={{ width: '550px' }}>
                            <div className="row">
                                <aside className="col-sm-12">
                                    <p style={{
                                        color: 'white', fontSize: 35, textAlign: 'center', fontWeight: 'bold',
                                    }}>Admin</p>
                                    <div className="card">
                                        <article className="card-body">

                                            <button type="button" className="btn btn-primary btn-block"
                                                onClick={this.GotoHome}
                                            > back </button>

                                            <button type="button" className="btn btn-primary btn-block"
                                                onClick={this.GotoQuizAdminTool}
                                                // hidden={this.props.user.uid !== 'uj4Zk0dQSyNfIIJBqVPfne8y5z32'}
                                                hidden={this.props.isSuperAdmin === false}
                                            > Quiz Admin Tool </button>

                                            <button type="button" className="btn btn-primary btn-block"
                                                onClick={this.GotoQuizManagement}
                                                hidden={this.props.isAuthor === false}
                                            > Quiz Management </button>

                                        </article>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}