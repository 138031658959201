
import React from 'react';
// import { Redirect } from 'react-router-dom';
// import { Col, Row } from 'react-bootstrap';
// import moment from 'moment';
import '../../Admin/PageStyle.scss';
import '../../Admin/LiveQuizAdminPortal.scss';
import { Toggle, PermissionAccessType } from '../../../components/GlobalSetting';
import { BlockInvalidPermissionFeatureAccess } from '../../../components/GlobalFunctions';

//2023.09.11
export default class OrganizerMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
    });

    componentDidMount = () => {
    }

    render = () => {
        if (this.props.user !== null) {
            return (
                <>
                    <span>&nbsp;</span>
                    {
                        BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageStudentProfile, PermissionAccessType.View) ? null :
                            <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                id='panel-manage-student-profile'
                                onClick={() => this.props.callback_Toggle_OMenuPage(Toggle.ManageStudentProfile)}
                            >Manage<br />Student Profile</button>
                    }
                    {
                        // //2023.11.28
                        // BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageCustomGroup, PermissionAccessType.View) ? null :
                        //     <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                        //         id='panel-manage-custom-group'
                        //         onClick={() => this.props.callback_Toggle_OMenuPage(Toggle.ManageCustomGroup)}
                        //     >Manage<br />Custom Group</button>
                    }
                    {
                        BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageStudentReport, PermissionAccessType.View) ? null :
                            <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                id='panel-manage-student-report'
                                onClick={() => this.props.callback_Toggle_OMenuPage(Toggle.ManageStudentReport)}
                            >Manage<br />Student Report</button>
                    }
                    {
                        // //2024.01.08
                        // BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageOrganizer, PermissionAccessType.View) ? null :
                        //     <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                        //         id='panel-manage-organizer'
                        //         onClick={() => this.props.callback_Toggle_OMenuPage(Toggle.ManageOrganizer)}
                        //     >Manage<br />Organizer</button>
                    }
                </>
            );
        }
        return (null);
    }
}