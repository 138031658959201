//2021.07.02 === fixed atm.
let GroupId = [
    { Id: 17, Name: 'Other', UniqueId: '25b3e0f20dc2400284f04397d00eb64d', Remark:'' },

    { Id: 1, Name: 'Standard 1', UniqueId: '6d257457eaaa4a8da7df0c1d93cee786', Remark:'' },
    { Id: 2, Name: 'Standard 2', UniqueId: '6ae3bf8c5193483293cee5dc7ef5350a', Remark:'' },
    { Id: 3, Name: 'Standard 3', UniqueId: '98011ae9709b485ebc52d2b70ba4df42', Remark:'' },
    { Id: 4, Name: 'Standard 4', UniqueId: '2e49aa69ae314fd6bc6ab9fc2189eb2c', Remark:'' },
    { Id: 5, Name: 'Standard 5', UniqueId: 'cfdaaafd182549028713f5ded515b15d', Remark:'' },

    { Id: 6, Name: 'Standard 6', UniqueId: '63376a0debc54006a7c19f5e6e508978', Remark:'' },
    { Id: 7, Name: 'Group A', UniqueId: 'e1cf02ed6edb483a8af14a1cb2249c4a', Remark:'' },
    { Id: 8, Name: 'Group B', UniqueId: 'c51d3f855a174a1b85dc29d54a144ce6', Remark:'' },
    { Id: 9, Name: 'Group C', UniqueId: '826d68734dff445d81cbca72001644e1', Remark:'' },
    { Id: 10, Name: 'Group D', UniqueId: '3ebfa943b5f9468ead4fe43c83893ef2', Remark:'' },

    { Id: 11, Name: 'Form 1', UniqueId: '8c4e2af8d9b9427e9c139ea6f548e26c', Remark:'' },
    { Id: 12, Name: 'Form 2', UniqueId: '6826613c9ef3423bbf6b1613c19f5677', Remark:'' },
    { Id: 13, Name: 'Form 3', UniqueId: 'f8bd9bb689304e589ac95103a56b6074', Remark:'' },
    { Id: 14, Name: 'Form 4', UniqueId: 'dbde457d7fac4910b0e14fa71e10cccd', Remark:'' },
    { Id: 15, Name: 'Form 5', UniqueId: 'e2e01b9b32f147c7ae9ae282fc2d88d1', Remark:'' },

    { Id: 18, Name: 'Form 5 (Batch 2020)', UniqueId: 'ad0a004d477a42bea827a4e76676e408', Remark:'' },
    { Id: 16, Name: 'Form 6', UniqueId: '8424771eae7b483e9826ba5a5fdd07c1', Remark:'' },

    { Id: 21, Name: 'Level 1', UniqueId: 'a4b4bebe634d46f09fb8a6d45d224f2f', Remark:'(Std 1-3)' },  //18
    { Id: 22, Name: 'Level 2', UniqueId: '914cb570349441ed93499a4ff0ded79d', Remark:'(Std 1-3)' },  //19
    { Id: 23, Name: 'Level 3', UniqueId: 'fd0ee58512c74c71a211cad686663993', Remark:'(Std 4-6)' },  //20
    { Id: 24, Name: 'Level 4', UniqueId: '91e848d0c47c46df832c0a823bbd291a', Remark:'(Std 4-6)' },  //21

    { Id: 25, Name: 'N2', UniqueId: '66b4509c24ac4d84826d2643c525c148', Remark:'(3-4 years old)' },  //22
    { Id: 26, Name: 'K1', UniqueId: 'ecc8b721e15c4318a0cea3dcae025441', Remark:'(4-5 years old)' },  //23
    { Id: 27, Name: 'K2', UniqueId: 'e001728f2f3f4e34b60615c5017f93fa', Remark:'(5-6 years old)' },  //24
];
export default GroupId;