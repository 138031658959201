import React from 'react';
import { Redirect } from 'react-router-dom';
import { CheckBoolean, CheckNullValue, CheckObjectNullValue, CheckStringEmpty, DelayUntil, GetPropIds } from '../components/GlobalFunctions';
import { Locale } from '../Localization/CustomLocalization';
import { GlobalSetting } from '../components/GlobalSetting';

export default class OrganizerChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        redirect: false,
        redirectLink: '',

        SecretKey: '722B229070E44A6582034C1BAFFF8644',
        CurrentPassword: '',
        NewPassword: '',
        NewPasswordConfirm: '',
        IsProcessing: false,
    });

    componentDidMount = async () => {
        if (this.props.user === null) {
            this.BackToLogin();
            return null;
        }
        window.scrollTo(0, 0);

        // await DelayUntil(() => this.props.user !== null);
        if (this.props.isDevMode)
            console.log('OrganizerChangePassword', 'start');
    }

    BackToLogin = () => {
        // this.setState({
        //     redirectLink: '/',
        //     redirect: true,
        // });
        window.location.href = GlobalSetting.RootUrl;
    }

    CheckInputValidation = () => {

        let errorMessage = [];

        const password = CheckStringEmpty(this.props.password);

        //Current Password.
        if (CheckNullValue(this.state.CurrentPassword) === null)
            errorMessage.push('Please do not leave the <b>Current Password</b> field in empty.');
        else if (this.state.CurrentPassword !== password)
            errorMessage.push("The password provided in the <b>Current Password</b> field is <u>not match with the real current password</u> used to login into this account.");

        //New Password.
        const newPassword = CheckStringEmpty(this.state.NewPassword);
        if (CheckNullValue(newPassword) === null)
            errorMessage.push('Please do not leave the <b>New Password</b> field in empty.');
        else if (newPassword.length < 8)
            errorMessage.push(Locale("change-password-warning-too-short-pass", 'en'));

        //New Password (Confirm).
        const newPasswordConfirm = CheckStringEmpty(this.state.NewPasswordConfirm);
        if (CheckNullValue(newPasswordConfirm) === null)
            errorMessage.push('Please do not leave the <b>New Password (Confirm)</b> field in empty.');
        else if (newPassword.length < 8)
            errorMessage.push('<b>New</b> ' + Locale("change-password-warning-too-short-pass-confirm", 'en'));

        //Comapare New Password & New Password Confirm.
        if (newPassword !== newPasswordConfirm)
            errorMessage.push(Locale("change-password-warning-not-match", 'en'));
        else
            //Comapare Current Password & New Password Confirm.
            if (password === newPassword || password === newPasswordConfirm)
                errorMessage.push(Locale("change-password-warning-current-and-new-are-same", 'en'));

        //Final.
        if (errorMessage.length > 0) {
            // this.props.SetAlert('Invalid Input(s)', errorMessage.join('<br /><br />') + '<hr />Please check your inputs again.');
            errorMessage.push('Please check your inputs again.');
            this.props.SetAlert('Invalid Input(s)', errorMessage.join('<hr />'));
        }
        else {
            return true;
        }
        return false;
    }

    UpdatePassword_ViaApi = async () => {

        const isValid = this.CheckInputValidation();
        if (isValid === false)
            return null;

        this.setState({
            IsProcessing: true,
        });
        this.props.SetLoading('', 'Updating password...', false);

        const { authorId } = GetPropIds(this.props);

        const jsonData = JSON.stringify({
            secret: this.state.SecretKey,
            requestAuthorId: authorId,
            authorId: authorId,
            currentPassword: CheckStringEmpty(this.state.CurrentPassword),
            newPassword: CheckStringEmpty(this.state.NewPasswordConfirm),
        });
        if (this.props.isDevMode)
            console.log('UpdatePassword_ViaApi (json)', jsonData);

        let done = false;
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/User/Author/ChangePassword/Update',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonData,
            })
            .then(res => res.json())
            .then(data => {
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(data));
                if (data.success) {

                    this.props.SetAlert('', 'Author password has been updated.<br />(Redirecting in 3s...)', false);

                    const saveLoginCredential = CheckBoolean(localStorage.getItem('saveLoginCredential'));
                    if (saveLoginCredential) {
                        localStorage.setItem('password', CheckStringEmpty(this.state.NewPasswordConfirm));
                    }

                    setTimeout(() => {
                        this.BackToLogin();
                    }, 3000);
                }
                else {
                    this.props.SetAlert('Update Failed', 'Failed to update password.'
                        + (CheckObjectNullValue(data, 'message') === null ? '' : '<br /><br />Error:<br />' + data.message));
                    if (this.props.isDevMode)
                        console.log('Error', 'api-author-update-password (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                done = true;
                this.props.SetAlert('Api Update Failed', 'Failed to update password.'
                    + (CheckObjectNullValue(error, 'message') === null ? '' : '<br /><br />Error:<br />' + error.message));
                if (this.props.isDevMode)
                    console.log('Error', 'api-author-update-password (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        this.setState({
            IsProcessing: false,
        });
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else if (this.props.user === null) {
            return (<></>);
        }
        else {
            return (
                <div style={{
                    backgroundColor: 'transparent',
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    // paddingTop: 85,
                }}>
                    <div className="container" style={{ maxWidth: '550px', backgroundColor: 'transparent', paddingTop: 15, paddingBottom: 15, borderRadius: 5 }}>
                        <div className="row">
                            <aside className="col-sm-12 font-weight-bold">
                                <p style={{
                                    color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                }}>iKEY Live Quiz<br />Organizer (Author) Portal</p>

                                {/* <button type='button' onClick={this.BackToLogin}
                                >Back to Login</button> */}

                                <div className="card">
                                    <article className="card-body">
                                        <a href="/" className="float-right btn btn-outline-primary">{Locale("back", 'en')}</a>
                                        <h4 className="card-title mb-4 mt-1"><b>{Locale("password-reset", 'en')}</b></h4>

                                        <div
                                            className='form-warning-text'
                                            dangerouslySetInnerHTML={{ __html: Locale("author-change-password-warning-text", 'en') }}
                                            style={{ paddingTop: 0 }}
                                        ></div>

                                        <div className="form-group">
                                            <label>{Locale("label-password-current", 'en')}</label>
                                            <input
                                                name="current-password"
                                                className="form-control"
                                                type="text"
                                                placeholder={CheckStringEmpty(this.state.CurrentPassword, Locale("password-min-req", 'en'))}
                                                onChange={(e) => {
                                                    this.setState({
                                                        CurrentPassword: CheckStringEmpty(e.currentTarget.value),
                                                    });
                                                }}
                                                disabled={this.state.IsProcessing}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label>{Locale("label-password-new", 'en')}</label>
                                            <input
                                                name="new-password"
                                                className="form-control"
                                                type="text"
                                                placeholder={CheckStringEmpty(this.state.NewPassword, Locale("password-min-req", 'en'))}
                                                onChange={(e) => {
                                                    this.setState({
                                                        NewPassword: CheckStringEmpty(e.currentTarget.value),
                                                    });
                                                }}
                                                disabled={this.state.IsProcessing}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label>{Locale("label-password-new-confirm", 'en')}</label>
                                            <input
                                                name="new-password-confirm"
                                                className="form-control"
                                                type="text"
                                                placeholder={CheckStringEmpty(this.state.NewPasswordConfirm, Locale("password-min-req", 'en'))}
                                                onChange={(e) => {
                                                    this.setState({
                                                        NewPasswordConfirm: CheckStringEmpty(e.currentTarget.value),
                                                    });
                                                }}
                                                disabled={this.state.IsProcessing}
                                            />
                                        </div>

                                        <br />

                                        <div className="form-group">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-block"
                                                onClick={this.UpdatePassword_ViaApi}
                                                disabled={this.state.IsProcessing}
                                            >Update Password</button>
                                        </div>

                                        <div className="form-group">
                                            <button
                                                type="button"
                                                className="btn btn-secondary btn-block"
                                                onClick={this.BackToLogin}
                                                disabled={this.state.IsProcessing}
                                            >Back to Login</button>
                                        </div>

                                    </article>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            );
        }
    }
}