import React from 'react';
import { Dropdown, DropdownButton, ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
// import _ from 'lodash';
import QuizBankManageEvent from './QM_ManageEvent';
import QuizBankManageRoom from './QM_ManageRoom';
import QuizBankManageQuestionSet from './QM_ManageQuestionSet';
import QuizBankEditQuestionSet from './QM_EditQuestionSet';
import './PageStyle.scss';
import QuizBankRoomTypeFileUploadDetail from './QM_RoomTypeFileUploadDetail';
import LiveQuizRoomRankingDataBrowsing from './LiveQuizRoomRankingDataBrowsing';
import LiveQuizEventJoinedListBrowsing from './LiveQuizEventJoinedListBrowsing';
import LiveQuizUserProfileManagement from './LiveQuizUserProfileManagement';
import LiveQuizEventParticipationList from './LiveQuizEventParticipationList';  //2022.05.24
import LiveQuizEventParticipationCompleteDetailList from './LiveQuizEventParticipationCompleteDetailList';  //2022.05.31
import QuizAdminTool from './QuizAdminTool';
import AdminManageAuthor from './Admin_ManageAuthor';

import { GlobalSetting, Toggle } from '../../components/GlobalSetting';
import { DelayUntil, BlockInvalidPermissionFeatureAccess, CheckNullValue, CheckObjectNullValue, CheckObjectStringEmpty, CapitalizeJsonKeys, CheckObjectNumber, CheckBoolean } from '../../components/GlobalFunctions';      //2023.09.11

import OrganizerMenu from './Organizer/OrganizerMenu';
import ManageStudentProfile from './Organizer/ManageStudentProfile';
import ManageOrganizer from './Organizer/ManageOrganizer';          //2024.01.08
import ManageStudentReport from './Organizer/ManageStudentReport';

import './PageStyle.scss';

// const Toggle = {
//     None: '0',

//     Event: '1',
//     NewEvent: '2',
//     EditEvent: '3',

//     Room: '4',
//     NewRoom: '5',
//     EditRoom: '6',

//     QuestionSet: '7',
//     NewQuestionSet: '8',
//     EditQuestionSet: '9',

//     Question: '10',
//     NewQuestion: '11',
//     EditQuestion: '12',

//     RoomTypeFile: '13',
//     RoomTypeFileDetail: '14',

//     LiveQuizRankingList: '30',
//     EventParticipationReport_Admin: '31',
//     EventParticipationReportLite: '32',
//     EventParticipationReportCompleteDetail: '33',
//     UserProfileManagement: '40',
//     AdminTool: '41',

//     ManageAuthor: '51',

//     Etc: '100',
//     Invalid: '9999',
// }

const DataInput = {
    None: 0,
    Name: 1,
    Remark: 2,
    TotalQuestion: 3,
    IsPublic: 4,
    IsPrivateGroup: 5,
    Published: 6,
    DisplayOrder: 7,
    Group: 8,
    Subject: 9,
    SearchQsSet_ByGroup: 10,          //standard
    SearchQsSet_BySubject: 11,        //subject
    SearchQsSet_MaxQtyShow: 12,       //max result
    SearchQsSet_ByName: 13,           //name
};

// //2023.09.07
// const SectionAccess = {
//     None: 'None',
//     ManageEvent: 'ManageEvent',
//     ManageRoom: 'ManageRoom',
//     ManageQuestionSet: 'ManageQuestionSet',
//     LiveQuizRankingList: 'LiveQuizRankingList',
//     EventParticipationReport: 'EventParticipationReport',
// };


//2021.06.30
export default class QuizManagement extends React.Component {

    constructor(props) {
        super(props);

        this.EditQuestionSet_Ref = React.createRef();
    }

    state = {
        redirect: false,
        redirectLink: '',

        toggleMenuOption: Toggle.None,

        QuestionSetUniqueId: '',

        //2021.08.02
        GroupList: [],  //this.props.GroupId,
        GroupOptions: [],
        GroupIsLoaded: false,

        //2021.10.04
        QuestionList: null,
        SelectedQuestionIndex: -1,
        ToggleQuestionList: true,

        //2021.10.08
        SubjectList: [],
        SubjectOptions: [],
        SubjectIsLoaded: false,

        //2021.10.13
        OrganizerInfo: null,
        OrganizerInfoIsLoaded: false,
        // OrganizerOptions: [],
        Organizers: [],
        RepresentMultipleOrganizers: false,

        //2021.10.29
        RoomInfo: {
            Date: '',
            DateEnd: '',
            DateStart: '',
            Duration: 0,
            EventCode: '',
            GroupId: 0,
            Organizer: '',
            OrganizerIdentity: '',
            QuestionSetUniqueId: '',
            RoomCode: '',
            RoomId: 0,
            RoomTitle: '',
            RoomType: 0,
            SubjectName: '',
            TimeEnd: '',
            TimeStart: '',
        },

        todayDT: moment().format('YYYYMMDD'),

        SchoolListWithDetails: [],  //2022.06.17
    }

    componentWillUnmount = () => {
        this.props.SetScreen(this.props.Screen.None);   //2022.06.28
    }

    componentDidMount = async () => {
        if (this.props.user === null || this.props.isAuthor === false) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }
        window.scrollTo(0, 0);

        this.props.SetScreen(this.props.Screen.QuizManagementScreen);   //2022.06.27

        // let lastSelectedMenu = localStorage.getItem('lastSelectedMenu_' + this.props.user.uid);
        // if (this.props.isDevMode)
        //     console.log('\nlastSelectedMenu = ' + lastSelectedMenu);
        // // if (lastSelectedMenu === null || lastSelectedMenu === undefined) {
        // //     lastSelectedMenu = Toggle.ManageRoom;
        // // }
        // // else {
        // //     lastSelectedMenu = this.GetLastSelectedMenu(lastSelectedMenu);
        // // }
        // lastSelectedMenu = this.GetLastSelectedMenu(lastSelectedMenu);
        // if (this.props.isDevMode)
        //     console.log('\nlastSelectedMenu (2) = ' + lastSelectedMenu);
        // // this.ToggleList(lastSelectedMenu);

        this.setState({
            RoomInfo: null,
        });
        // await this.CheckOn_GroupList();
        // await this.CheckOn_SubjectList();
        // await this.Fetch_OrganizerInfo();
        // await this.Testing_Participant_CertInfo();

        //2022.06.27
        await this.CheckOn_IsOrganizer();
        if (this.state.OrganizerInfoIsLoaded === false) {
            await this.Fetch_OrganizerInfo();
        }

        //2023.11.23
        await this.CheckOn_GroupList_ViaApi();
        await this.CheckOn_SubjectList_ViaApi();

        //2023.09.07
        await DelayUntil(() => this.props.user !== null && this.state.OrganizerInfo !== null);
        let lastSelectedMenu = localStorage.getItem('lastSelectedMenu_' + this.props.user.uid);
        // if (this.props.isDevMode)
        //     console.log('\nlastSelectedMenu = ' + lastSelectedMenu);
        lastSelectedMenu = this.GetLastSelectedMenu(lastSelectedMenu);
        // if (this.props.isDevMode)
        //     console.log('\nlastSelectedMenu (2) = ' + lastSelectedMenu);
        // this.ToggleList(this.HideSection(lastSelectedMenu) ? Toggle.Invalid : lastSelectedMenu);
        const findIndex = Object.values(Toggle).findIndex(x => x === lastSelectedMenu);
        // console.log(findIndex, JSON.stringify(Object.values(Toggle)));
        if (findIndex > -1) {
            if (lastSelectedMenu === Toggle.ManageStudentProfile || lastSelectedMenu === Toggle.ManageStudentReport)
                this.Callback_Toggle_OMenuPage(this.HideSection(lastSelectedMenu) ? Toggle.ManageRoom : lastSelectedMenu);
            else
                this.ToggleList(this.HideSection(lastSelectedMenu) ? Toggle.ManageRoom : lastSelectedMenu);
        }
        else {
            this.ToggleList(Toggle.ManageRoom);
        }

        //debug.
        if (this.props.isDevMode)
            console.log('QuizManagement (User)\n\n' + JSON.stringify(this.props.user));
    }

    //2022.01.07
    GetLastSelectedMenu = (_menu = Toggle.None) => {
        switch (_menu) {
            default: return _menu;

            case Toggle.NewEvent: return Toggle.ManageEvent;
            case Toggle.EditEvent: return Toggle.ManageEvent;

            case Toggle.NewRoom: return Toggle.ManageRoom;
            case Toggle.EditRoom: return Toggle.ManageRoom;
            case Toggle.RoomTypeFile: return Toggle.ManageRoom;
            case Toggle.RoomTypeFileDetail: return Toggle.ManageRoom;
            case Toggle.None: return Toggle.ManageRoom;

            case Toggle.NewQuestionSet: return Toggle.ManageQuestionSet;
            case Toggle.EditQuestionSet: return Toggle.ManageQuestionSet;

            case Toggle.NewQuestion: return Toggle.ManageQuestion;
            case Toggle.EditQuestion: return Toggle.ManageQuestion;

            // case Toggle.EventParticipationReportLite: return Toggle.None;
            // case Toggle.EventParticipationReport_Admin: return Toggle.LiveQuizRankingList;
            // case Toggle.UserProfileManagement: return Toggle.LiveQuizRankingList;

            // case Toggle.ManageStudentProfile: return Toggle.ManageRoom;
            // case Toggle.ManageStudentReport: return Toggle.ManageRoom;
        }
    }

    DoNothing = () => { }

    //2021.08.02
    //#region === Group Options === start ===//
    CheckOn_GroupList_ViaApi = async () => {
        //2023.11.23
        if (this.state.GroupList.length > 0)
            return null;

        // const { authorId, organizerId } = GetPropIds(this.props);

        let _List = [];
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Quiz/Group/List/'
            + this.state.OrganizerInfo.OrganizerId + '/'
            + this.props.user.AuthorId + '/'
            + 0 + '/'
            + 99999,
            // Api/LearningCentre/Quiz/Group/List/{organizerId}/{authorId}/{pageIndex}/{pageSize}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('CheckOn_GroupList_ViaApi', JSON.stringify(data));
                if (data.success)
                    _List = data.data;
                else
                    if (this.props.isDevMode)
                        console.log('Error', 'api - group list (failed)\n' + JSON.stringify(data));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - group list (failed)\n' + error.message);
            });

        if (_List.length > 0) {
            //Finalize list.
            for (let i = 0; i < _List.length; i++) {
                _List[i] = CapitalizeJsonKeys(_List[i]);
            }
            //Sort.
            _List.sort((a, b) => a.Id - b.Id);
            _List.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
            //Save.
            this.setState({
                GroupList: _List,
            }, () => {
                this.Populate_GroupOptions();
            });
        }
    }
    CheckOn_GroupList = async () => {
        if (this.state.GroupList.length <= 0) {
            let _List = [];
            //Default Groups.
            await this.props.firestore
                .collection("QuizBank")
                .doc('QuizGroup')
                .collection('QuizGroups')
                .where('AuthorId', '==', Number(1))
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                        });
                        if (dataArray.length > 0) {
                            dataArray.map((data, key) => {
                                return _List.push(data);
                            });
                        }
                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log(error.message);
                });
            //Author created Groups.
            await this.props.firestore
                .collection("QuizBank")
                .doc('QuizGroup')
                .collection('QuizGroups')
                .where('AuthorId', '==', this.props.user.AuthorId)
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                        });
                        if (dataArray.length > 0) {
                            dataArray.map((data, key) => {
                                return _List.push(data);
                            });
                        }
                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log(error.message);
                });
            //Sort.
            _List.sort((a, b) => a.Id - b.Id);
            _List.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
            //Save.
            this.setState({
                GroupList: _List,
            }, () => {
                this.Populate_GroupOptions();
            });
            // await Delay(300);
        }
        // this.Populate_GroupOptions();
    }
    Populate_GroupOptions = () => {
        if (this.state.GroupOptions.length <= 0) {
            let options = [];
            this.state.GroupList.map((data, key) => {
                // let _remark = (data.Remark !== undefined ? data.Remark !== '' ? ' ' + data.Remark : '' : '');
                // return options.push({ value: data.Name, label: data.Name + _remark, id: data.Id });
                const _remark = CheckObjectNullValue(data, 'Remark') === null ? '' : ' ' + CheckObjectStringEmpty(data, 'remark');
                const _name = CheckObjectStringEmpty(data, 'Name', CheckObjectStringEmpty(data, 'name'));
                const _id = CheckObjectNumber(data, 'Id', CheckObjectNumber(data, 'id'));
                return options.push({ value: _name, label: _name + _remark, id: _id });
            });
            if (this.props.isDevMode)
                console.log('Populate_GroupOptions', JSON.stringify(options));
            this.setState({ GroupOptions: options, GroupIsLoaded: true, });
        }
    }
    Refresh_GroupOptions = () => {
        this.setState({
            GroupList: [],
            GroupOptions: [],
            GroupIsLoaded: false,
        }, () => {
            // this.CheckOn_GroupList();
            this.CheckOn_GroupList_ViaApi();
        });
    }
    //#endregion === Group Options === end ===//

    //2021.10.08
    //#region === Subjects === start ===//
    CheckOn_SubjectList_ViaApi = async () => {
        //2023.11.23
        if (this.state.SubjectList.length > 0)
            return null;

        // const { authorId, organizerId } = GetPropIds(this.props);

        let _List = [];
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Quiz/Subject/List/'
            // + this.state.OrganizerInfo.OrganizerId + '/'
            + 0 + '/'
            + this.props.user.AuthorId + '/'
            + 0 + '/'
            + 99999,
            // Api/LearningCentre/Quiz/Subject/List/{organizerId}/{authorId}/{pageIndex}/{pageSize}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('CheckOn_SubjectList_ViaApi (source)', JSON.stringify(data));
                if (data.success)
                    _List = data.data;
                else
                    if (this.props.isDevMode)
                        console.log('Error', 'api - subject list (failed)\n' + JSON.stringify(data));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - subject list (failed)\n' + error.message);
            });

        if (_List.length > 0) {
            //Finalize list.
            for (let i = 0; i < _List.length; i++) {
                _List[i] = CapitalizeJsonKeys(_List[i]);
            }
            if (this.props.isDevMode)
                console.log('CheckOn_SubjectList_ViaApi (CapitalizeJsonKeys)', JSON.stringify(_List));
            //Sort.
            _List.sort((a, b) => a.Id - b.Id);
            _List.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
            //Save.
            this.setState({
                SubjectList: _List,
            }, () => {
                this.Populate_SubjectOptions();
            });
        }
    }
    CheckOn_SubjectList = async () => {
        if (this.state.SubjectList.length <= 0) {
            let _List = [];
            //Default Subjects.
            await this.props.firestore
                .collection("QuizBank")
                .doc('QuizSubject')
                .collection('QuizSubjects')
                .where('AuthorId', '==', Number(1))
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                        });
                        if (dataArray.length > 0) {
                            dataArray.map((data, key) => {
                                return _List.push(data);
                            });
                        }
                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log(error.message);
                });
            //Author created Subjects.
            await this.props.firestore
                .collection("QuizBank")
                .doc('QuizSubject')
                .collection('QuizSubjects')
                .where('AuthorId', '==', this.props.user.AuthorId)
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                        });
                        if (dataArray.length > 0) {
                            dataArray.map((data, key) => {
                                return _List.push(data);
                            });
                        }
                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log(error.message);
                });
            //Sort.
            _List.sort((a, b) => a.Id - b.Id);
            _List.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
            // if (this.props.isDevMode)
            //     console.log(JSON.stringify(_List));
            //Save.
            this.setState({
                SubjectList: _List,
            }, () => {
                this.Populate_SubjectOptions();
            });
            // this.Populate_SubjectOptions();
            // this.setState({ SubjectList: _List, }, () => this.Populate_SubjectOptions());
            // await Delay(300);
        }
    }
    Populate_SubjectOptions = () => {
        if (this.state.SubjectOptions.length <= 0) {
            let options = [];
            this.state.SubjectList.map((data, key) => {
                // return options.push({ id: Number(data.Id), value: String(data.Name), label: data.Name + ' (' + data['BC'] + ') (' + data['BM'] + ')' + (data['TagName'] !== '' ? ' (' + data['TagName'] + ')' : '') });
                const _name = CheckObjectStringEmpty(data, 'Name', CheckObjectStringEmpty(data, 'name'));
                const _id = CheckObjectNumber(data, 'Id', CheckObjectNumber(data, 'id'));
                const _bc = CheckObjectStringEmpty(data, 'BC', CheckObjectStringEmpty(data, 'Bc'));
                const _bm = CheckObjectStringEmpty(data, 'BM', CheckObjectStringEmpty(data, 'Bm'));
                const _tag = CheckObjectStringEmpty(data, 'TagName', CheckObjectStringEmpty(data, 'tagName'));
                const _label = _name + ' (' + _bc + ') (' + _bm + ')' + (CheckNullValue(_tag) === null ? '' : ' (' + _tag + ')');
                return options.push({ id: _id, value: _name, label: _label });
            });
            if (this.props.isDevMode)
                console.log('Populate_SubjectOptions', JSON.stringify(options));
            this.setState({ SubjectOptions: options, SubjectIsLoaded: true, });
        }
    }
    Refresh_SubjectOptions = () => {
        this.setState({
            SubjectList: [],
            SubjectOptions: [],
            SubjectIsLoaded: false,
        }, () => {
            // this.CheckOn_SubjectList();
            this.CheckOn_SubjectList_ViaApi();
        });
    }
    //#endregion === Subjects === end ===//

    //2021.11.13
    //#region === Organizers === start ===//
    Fetch_OrganizerInfo = async () => {
        if (this.props.isDevMode)
            console.log('Fetch_OrganizerInfo > user >\n', JSON.stringify(this.props.user));
        //2023.09.26
        if (CheckNullValue(this.props.user.IsOrganizer) !== null) {
            if (CheckNullValue(this.props.user.OrganizerInfoList) !== null) {
                const data = this.props.user.OrganizerInfoList[0];
                if (this.props.isDevMode)
                    console.log('Fetch_OrganizerInfo > data >\n', JSON.stringify(data));
                if (CheckNullValue(data) !== null) {
                    this.setState({
                        OrganizerInfo: {
                            'Name': data.name,
                            'DisplayName': data.displayName,
                            'Id': data.id,
                            'OrganizerId': data.organizerId,
                            'Identity': data.identityGuid,
                            'NationalState': data.nationalState,
                            'DistrictArea': data.districtArea,
                            'SchoolName': data.schoolName,
                            'SchoolCompleteName': data.schoolCompleteName,
                        },
                        OrganizerInfoIsLoaded: true,
                    });
                }
            }
        }

        if (this.state.OrganizerInfo === null) {
            let _data = null;
            await this.props.firestore
                .collection("QuizBank")
                .doc('OrganizerMapping')
                .collection('OrganizerMappings')
                .where('CenterUserId', '==', Number(this.props.user.CenterUserId))
                .where('AuthorId', '==', Number(this.props.user.AuthorId))
                .limit(1)
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                        });
                        if (dataArray.length > 0) {
                            // dataArray.map((data, key) => {
                            //     return _List.push(data);
                            // });
                            _data = dataArray[0];
                        }
                        // console.log('(0) OrganizerInfo =\n' + JSON.stringify(_List));
                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log(error.message);
                });
            //Sort.
            // _List.sort((a, b) => a.Id - b.Id);
            if (this.props.isDevMode)
                console.log('OrganizerInfo =\n' + JSON.stringify(_data));
            //Save.
            this.setState({
                OrganizerInfo: _data,
                OrganizerInfoIsLoaded: true,
            });
            //Check IsOrganizer.    //2022.08.23
            if (this.props.user !== null) {
                this.props.user.IsOrganizer = _data !== null;
                this.props.SetUserDataOnly(this.props.user);
            }
        }
    }
    // Populate_OrganizerOptions = () => {
    //     // if (this.state.SubjectOptions.length <= 0) {
    //     let options = [];
    //     this.state.OrganizerInfo.map((data, key) => {
    //         return options.push({ value: data.Identity, label: (data.DisplayName !== '' ? data.DisplayName : data.Name), id: data.Id });
    //     });
    //     if (this.props.isDevMode)
    //         console.log(JSON.stringify(options));
    //     this.setState({ OrganizerOptions: options });
    //     // }
    // }
    GetAllOrganizers = async () => {
        if (this.state.Organizers.length <= 0) {
            let _List = [];
            await this.props.firestore
                .collection("QuizBank")
                .doc('Organizer')
                .collection('Organizers')
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                            dataArray[dataArray.length - 1].Id = doc.id;
                        });
                        if (dataArray.length > 0) {
                            _List = dataArray;
                            _List.sort((a, b) => a.Id - b.Id);
                            if (this.props.isDevMode)
                                console.log('\nAll Organizers =\n' + JSON.stringify(_List));
                        }

                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log(error.message);
                });
            //Save.
            this.setState({
                Organizers: _List,
            });
        }
    }
    //2022.06.27
    CheckOn_IsOrganizer = async () => {
        // if (this.props.isDevMode)
        //     console.log('CheckOn_IsOrganizer >\n' + JSON.stringify(this.props.user));
        // if (this.props.isSuperAdmin) {
        if (CheckNullValue(this.props.user.IsOrganizer) !== null) {
            if (
                CheckBoolean(this.props.user.IsOrganizer)                //must true.
                && CheckNullValue(this.props.user.OrganizerInfoList) !== null       //must not null.
                && this.props.user.OrganizerInfoList.length > 0     //must at least has 1, or more.
            ) {
                if (this.props.user.OrganizerInfoList.length > 1) {
                    //represent multiple Organizers (rare cases, or never).
                    this.setState({
                        RepresentMultipleOrganizers: true,
                        Organizers: this.props.user.OrganizerInfoList.sort((a, b) => a.Id - b.Id),          //list
                        OrganizerInfo: this.props.user.OrganizerInfoList[0],    //default first item.
                        OrganizerInfoIsLoaded: true,
                    });
                }
                else {
                    //represent single Organizer (most cases).
                    this.setState({
                        RepresentMultipleOrganizers: false,
                        Organizers: [],                                         //remain empty.
                        OrganizerInfo: this.props.user.OrganizerInfoList[0],    //default first item.
                        OrganizerInfoIsLoaded: true,
                    });
                }
                if (this.props.isDevMode)
                    console.log('CheckOn_IsOrganizer > OrganizerInfo >\n' + JSON.stringify(this.state.OrganizerInfo));
            }
            else {
                window.location.reload();
            }
        }
        // }
    }
    //#endregion === Organizers === end ===//

    handleError = (error) => {
        alert(JSON.stringify(error));
    }

    handleGoBack = (loc) => {
        this.setState({
            redirect: true,
            redirectLink: loc,
        });
    }

    GetBtnStyle = (toggle) => {
        return {
            backgroundColor: this.state.toggleMenuOption === toggle ? "#007bff" : "white",
            borderColor: "#007bff",
        }
    }

    GetBtnTextStyle = (toggle) => {
        return { color: this.state.toggleMenuOption === toggle ? "white" : "#007bff" }
    }

    GoBack = () => {
        this.handleGoBack('/');
    }

    RefreshPage = () => {
        this.ToggleList(this.state.toggleMenuOption);
    }

    //left panel menu.
    ToggleList = (setting = '0') => {
        this.setState({
            toggleMenuOption: Toggle.None,
        }, () => {

            this.setState({
                toggleMenuOption: setting,
            }, async () => {
                //2022.01.05
                localStorage.setItem('lastSelectedMenu_' + this.props.user.uid, setting === Toggle.Invalid ? Toggle.ManageRoom : setting);

                let elementId = '';
                switch (setting) {

                    //Author menu.
                    case Toggle.ManageEvent:
                        elementId = 'panel-manage-events';
                        break;
                    case Toggle.ManageRoom:
                        elementId = 'panel-manage-rooms';
                        break;
                    case Toggle.ManageQuestionSet:
                        elementId = 'panel-manage-question-sets';
                        this.setState({ QuestionList: null, });
                        break;
                    case Toggle.ManageQuestion:
                        elementId = 'panel-manage-questions';
                        break;
                    case Toggle.Etc:
                        // elementId = 'panel-manage-events';
                        break;
                    case Toggle.RoomTypeFile:
                        // elementId = 'panel-manage-events';
                        break;

                    //Admin menu.
                    case Toggle.LiveQuizRankingList:
                        elementId = 'panel-live-quiz-ranking-list';
                        this.GetAllOrganizers();
                        //2022.06.17 - dynamic import huge size list.
                        if (CheckNullValue(this.state.SchoolListWithDetails) === null || this.state.SchoolListWithDetails.length === 0) {
                            const _SchoolListWithDetails = await import('../../components/SchoolListWithDetails').then((data) => { return data.default; });
                            this.setState({ SchoolListWithDetails: _SchoolListWithDetails, });
                            // console.log('SchoolListWithDetails', JSON.stringify(_SchoolListWithDetails));
                            // return;
                        }
                        break;
                    case Toggle.EventParticipationReport_Admin:
                        elementId = 'panel-event-participation-report';
                        this.GetAllOrganizers();
                        break;
                    case Toggle.EventParticipationReportLite:
                        elementId = 'panel-event-participation-report-lite';
                        // this.GetAllOrganizers();
                        break;
                    case Toggle.EventParticipationReportCompleteDetail:
                        elementId = 'panel-event-participation-report-complete-detail';
                        break;
                    case Toggle.UserProfileManagement:
                        elementId = 'panel-user-profile-management';
                        this.GetAllOrganizers();
                        break;
                    case Toggle.AdminTool:
                        elementId = 'panel-admin-tool';
                        this.GetAllOrganizers();
                        break;

                    //2023.09.07
                    case Toggle.ManageAuthor:
                        elementId = 'panel-manage-author';
                        break;
                    //2024.01.08
                    case Toggle.ManageOrganizer:
                        elementId = 'panel-manage-organizer';
                        break;

                    default:
                        break;
                }

                this.ToggleSectionButtonStyle(elementId);
            });
        });
    }

    //2023.09.07
    ToggleSectionButtonStyle = (elementId = '') => {
        if (elementId === '')
            return null;

        //2022.05.12
        let panelBtns = document.getElementsByClassName('panel-btn');
        for (var pb = 0; pb < panelBtns.length; pb++) {
            panelBtns[pb].className = panelBtns[pb].className.replace(' active', '');
        }
        if (document.getElementById(elementId) !== null)
            if (document.getElementById(elementId).className !== null)
                if (!document.getElementById(elementId).className.includes('active'))
                    document.getElementById(elementId).className += ' active';
    }

    //for Callback.
    TogglePage = async (page = '0', value = '') => {
        //2021.10.04
        this.Callback_SetQuestionList(null, -1);

        // this.RefreshGroupOptions();
        switch (page) {
            case Toggle.ManageEvent:
                break;
            case Toggle.ManageRoom:
                break;
            case Toggle.EditQuestionSet:
                this.setState({ QuestionSetUniqueId: value });
                break;
            case Toggle.ManageQuestion:
                break;
            case Toggle.Etc:
                break;
            case Toggle.RoomTypeFileDetail:
                this.setState({ RoomInfo: value });
                break;
            case Toggle.LiveQuizRankingList:
                // this.GetAllOrganizers();
                //2022.06.17 - dynamic import huge size list.
                if (CheckNullValue(this.state.SchoolListWithDetails) === null || this.state.SchoolListWithDetails.length === 0) {
                    const _SchoolListWithDetails = await import('../../components/SchoolListWithDetails').then((data) => { return data.default; });
                    this.setState({ SchoolListWithDetails: _SchoolListWithDetails, });
                    // console.log('SchoolListWithDetails', JSON.stringify(_SchoolListWithDetails));
                    // return;
                }
                break;
            // case Toggle.EventParticipationReport_Admin:
            //     this.GetAllOrganizers();
            //     break;
            default:
                break;
        };

        this.setState({
            toggleMenuOption: page,
        }, () => {
            //2022.01.05
            localStorage.setItem('lastSelectedMenu', page);

            //2021.10.04
            this.Callback_SetQuestionList(null, -1);
        });
    }

    //2021.10.04
    Callback_SetQuestionList = (_questionList, _questionIndex) => {
        // if (_questionList === null) {
        //     this.setState({
        //         SelectedQuestionIndex: _questionIndex,
        //     });
        // }
        // else {
        //     this.setState({
        //         QuestionList: _questionList,
        //         SelectedQuestionIndex: _questionIndex,
        //     });
        // }
        this.setState({
            QuestionList: _questionList,
            SelectedQuestionIndex: _questionIndex,
        });
        // console.log(document.getElementById('left-panel-qm').getBoundingClientRect().width);
        if (this.props.isDevMode)
            console.log('QsNo (LPanel) = ' + (_questionList !== undefined ?
                _questionIndex > -1 ?
                    _questionList[_questionIndex].No
                    : '-1 index'
                : 'null list, index = ' + _questionIndex));
    }
    QuestionListUiComponent = () => {
        let _components = [];
        let _columns = [];
        let _maxColPerRow = 5;
        // let _itemCounts = this.state.QuestionList.length;
        if (this.state.QuestionList !== null) {
            if (this.state.QuestionList.length > 0) {
                this.state.QuestionList.map((data, key) => {

                    //2021.09.08
                    //each item.
                    _columns.push(<td
                        key={'qsList' + key}
                        className={this.QsNumStyling(data)}
                        align='center'
                        valign='middle'
                        width={40}
                        onClick={() => this.EditQuestionSet_Ref.current === null ? this.DoNothing() :
                            this.EditQuestionSet_Ref.current.SelectQuestion(data)}
                    // style={this.QsNumStyling(data)}
                    // disabled={this.QsNoDisabledByQuizType(data)}
                    >{data.No}</td>);

                    if (key > 0) {
                        if (data.No % _maxColPerRow === 0) {
                            //items per row.
                            _components.push(<tr key={'qsList' + key}>{_columns}</tr>);
                            _columns = [];
                        }
                        else if (key === this.state.QuestionList.length - 1 && _columns.length < _maxColPerRow) {
                            //put remaining items in a row if not enough _maxColPerRow.
                            _components.push(<tr key={'qsList' + key}>{_columns}</tr>);
                            _columns = [];
                        }
                    }
                    else {
                        if (key === this.state.QuestionList.length - 1 && _columns.length < _maxColPerRow) {
                            //put remaining items in a row if not enough _maxColPerRow.
                            _components.push(<tr key={'qsList' + key}>{_columns}</tr>);
                            _columns = [];
                        }
                    }
                    return null;
                });
            }
        }
        // console.log(JSON.stringify(_components));
        return (_components);
    }
    QsNumStyling = (data) => {
        if (this.state.QuestionList !== null) {
            if (data !== null) {
                let disabled =
                    this.EditQuestionSet_Ref.current === null ? true :
                        this.EditQuestionSet_Ref.current.QsNoDisabledByQuizType(data);
                if (disabled) {
                    //disabled.
                    // return {
                    //     cursor: 'not-allowed',
                    //     color: 'white',
                    //     backgroundColor: 'lightgray',
                    //     fontWeight: 'normal',
                    // };
                    return 'qListItem-disabled';
                }
                else {
                    //2021.09.14
                    let selectedQuestion = this.state.QuestionList[this.state.SelectedQuestionIndex];
                    // if (selectedQuestion === undefined || selectedQuestion !== null)
                    //     selectedQuestion = this.state.SelectedQuestion;

                    if (selectedQuestion !== null && selectedQuestion !== undefined) {
                        if (selectedQuestion.No === data.No) {
                            //selected.
                            // return {
                            //     cursor: 'pointer',
                            //     color: 'white',
                            //     backgroundColor: 'blueviolet',
                            //     fontWeight: 'bold',
                            // }
                            return 'qListItem-selected';
                        }
                        else {
                            //not selected.
                            // return {
                            //     cursor: 'pointer',
                            //     color: 'black',
                            //     backgroundColor: 'white',
                            //     fontWeight: 'normal',
                            // };
                            return 'qListItem-normal';
                        }
                    }
                    else {
                        //null.
                        // return { cursor: 'pointer' };
                        // return {
                        //     cursor: 'pointer',
                        //     color: 'black',
                        //     backgroundColor: 'white',
                        //     fontWeight: 'normal',
                        // };
                        return 'qListItem-normal';
                    }
                }
            }
        }
    }

    // //2021.12.01 - testing
    // Testing_Participant_CertInfo = async () => {
    //     let _List = [];
    //     await this.props.firestore
    //         .collection("LiveQuiz_Certifications")
    //         .doc('Y2RfhtrgDxg1uKBNAxp3mifGQjC3')
    //         .collection('Certificates')
    //         .where('EventCode', '==', 'ikeyteam_2021_beta_test_event')
    //         .orderBy('CreatedOnUtc', 'asc')
    //         .limit(1)
    //         .get()
    //         .then(querySnapshot => {
    //             let dataArray = [];
    //             if (querySnapshot !== null) {
    //                 querySnapshot.forEach((doc) => {
    //                     dataArray.push(doc.data());
    //                 });
    //                 if (dataArray.length > 0) {
    //                     // dataArray.map((data, key) => {
    //                     //     return _List.push(data);
    //                     // });
    //                     _List = dataArray;
    //                 }
    //                 // console.log('(0) OrganizerInfo =\n' + JSON.stringify(_List));
    //             }
    //         })
    //         .catch(error => {
    //             if (this.props.isDevMode)
    //                 console.log(error.message);
    //         });
    //     //Sort.
    //     // _List.sort((a, b) => a.Id - b.Id);
    //     if (this.props.isDevMode)
    //         console.log('\nParticipant CertInfo =\n' + JSON.stringify(_List));
    //     // //Save.
    //     // this.setState({
    //     //     OrganizerInfo: _List,
    //     // });
    // }


    //2022.10.27
    GenerateSchoolListTxt = async () => {
        const _SchoolListWithDetails = await import('../../components/SchoolListWithDetails').then((data) => { return data.default; });
        this.setState({ SchoolListWithDetails: _SchoolListWithDetails, });
        let schoolNames = [];
        for (let k = 0; k < _SchoolListWithDetails.length; k++) {
            let ppds = String(_SchoolListWithDetails[k].PPD).split(' ');
            ppds.shift();
            let ppd = ppds.join(' ');
            let schoolName = String(_SchoolListWithDetails[k].Name)
                + ' - ' + String(_SchoolListWithDetails[k].NationalState)
                + ' - ' + ppd;
            schoolNames.push(schoolName);
            // console.log('#' + (k + 1) + '\n = ' + schoolName);
        }
        let schoolNames_noDupes = [];
        for (let t = 0; t < schoolNames.length; t++) {
            let findIndex = schoolNames_noDupes.findIndex(x => x === schoolNames[t]);
            if (findIndex < 0)
                schoolNames_noDupes.push(schoolNames[t]);
        }
        this.DownloadTxtFile(schoolNames_noDupes, 'SchoolNames_' + moment().format('YYYY-MM-DD_HHmmss'));
    }
    DownloadTxtFile = (dataSet, filename) => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(dataSet)], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = filename + '.txt';
        document.body.appendChild(element);
        element.click();
    };

    //2022.10.27 - Internal use - Admin Tool.
    SyncSchoolListToMainDatabase = async () => {

        //2022.11.01 - disabled to avoid slow build time (25min build time).

        // const _SchoolListWithCompleteDetails = await import('../../components/SchoolListWithCompleteDetails').then((data) => { return data.default; });
        // // console.log(JSON.stringify(_SchoolListWithCompleteDetails));
        // console.log(_SchoolListWithCompleteDetails.length);
        // await fetch(GlobalSetting.ApiUrl + 'Api/LiveQuiz/GovernmentSchool/InsertOrUpdate',
        //     {
        //         method: 'POST',
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(_SchoolListWithCompleteDetails),
        //     })
        //     .then(resp => resp.json())
        //     .then(result => {
        //         console.log(JSON.stringify(result));
        //         if (result.Success)
        //             console.log('api sync school list (Success)');
        //         else
        //             console.log('api sync school list (Error) =\n' + result.Message);
        //     })
        //     .catch(error => {
        //         if (this.state.isDevMode)
        //             console.log('api sync school list error =\n' + error.message);
        //     });

        //done.
    }

    // //2023.09.08
    // CheckPermissionNullValue = (permission, section = '') => {
    //     if (permission === null || section === '')
    //         return true;
    //     if (permission[section] === undefined || permission[section] === null)
    //         return true;
    //     if (permission[section].view === undefined || permission[section].view === null)
    //         return true;
    //     return false;
    // }
    // BlockInvalidPermissionFeatureAccess = (permissions = null, section = '', action = '') => {
    //     // console.log('BlockInvalidPermissionFeatureAccess', Array.isArray(permissions), section, action, JSON.stringify(permissions));
    //     // console.log(permissions === undefined, permissions === null, Array.isArray(permissions), section === '', action === '');
    //     if (permissions === undefined || permissions === null || !Array.isArray(permissions) || section === '' || action === '')
    //         return true;
    //     const entries = Object.values(permissions);
    //     let keyIndex = -1;
    //     for (let i = 0; i < entries.length; i++) {
    //         // console.log(JSON.stringify(Object.keys(entries[i])[0]));
    //         // console.log(JSON.stringify(Object.values(entries[i])[0]));
    //         const _section = Object.keys(entries[i])[0];
    //         // const _entry = Object.values(entries[i])[0];
    //         if (_section === section) {
    //             keyIndex = i;
    //             break;
    //         }
    //     }
    //     // console.log('BlockInvalidPermissionFeatureAccess', keyIndex, JSON.stringify(permissions[keyIndex][section][action]));
    //     if (keyIndex > -1) {
    //         if (permissions[keyIndex] === undefined || permissions[keyIndex] === null)
    //             return true;
    //         if (permissions[keyIndex][section] === undefined || permissions[keyIndex][section] === null)
    //             return true;
    //         if (permissions[keyIndex][section][action] === undefined || permissions[keyIndex][section][action] === null)
    //             return true;
    //         // console.log('BlockInvalidPermissionFeatureAccess', !CheckBoolean(permissions[keyIndex][section][action]), keyIndex, section, action);
    //         return !CheckBoolean(permissions[keyIndex][section][action]);
    //     }
    //     return true;
    // }

    //2023.09.07
    HideSection = (section = Toggle.None) => {
        if (this.props.user === null)
            return null;

        //#region older codes.

        // const viewerAccess = this.props.user === null ? true : (!CheckBoolean(this.props.user.IsViewer) || this.state.OrganizerInfo === null);

        // const organizerAccess = this.props.user === null ? true : (!CheckBoolean(this.props.user.IsOrganizer) || this.state.OrganizerInfo === null);

        // const adminAccess = this.props.user === null || this.props.isSuperAdmin === null ? true : this.props.isSuperAdmin;

        // // let customAccess = this.props.defaultCustomPermissions;
        // // if (this.props.user !== null && this.props.user.CustomPermissions !== null && this.props.user.CustomPermissions !== undefined)
        // const customAccess = this.props.user === null ||
        //     this.props.user.CustomPermissions === undefined || this.props.user.CustomPermissions === null ? null
        //     : this.props.user.CustomPermissions;
        // // if (this.props.isDevMode)
        // //     console.log('HideSection', section, this.BlockInvalidPermissionFeatureAccess(customAccess, 'manageEvent', 'view'));

        // let hideSection = adminAccess ? false : true;
        // if (customAccess !== null) {
        //     switch (section) {

        //         case Toggle.ManageEvent:
        //             // hideSection = (this.BlockInvalidPermissionFeatureAccess(customAccess, 'manageEvent', 'view') ? !organizerAccess
        //             //     : customAccess.manageEvent.view) ? false : true;
        //             hideSection = this.BlockInvalidPermissionFeatureAccess(customAccess, 'manageEvent', 'view');
        //             break;

        //         case Toggle.ManageRoom:
        //             // hideSection = (this.BlockInvalidPermissionFeatureAccess(customAccess, 'manageRoom', 'view') ? !organizerAccess
        //             //     : customAccess.manageRoom.view) ? false : true;
        //             hideSection = this.BlockInvalidPermissionFeatureAccess(customAccess, 'manageRoom', 'view');
        //             break;

        //         case Toggle.ManageQuestionSet:
        //             // hideSection = (this.BlockInvalidPermissionFeatureAccess(customAccess, 'manageQuestionSet', 'view') ? !organizerAccess
        //             //     : customAccess.manageQuestionSet.view) ? false : true;
        //             hideSection = this.BlockInvalidPermissionFeatureAccess(customAccess, 'manageQuestionSet', 'view');
        //             break;

        //         case Toggle.LiveQuizRankingList:
        //             // hideSection = (this.BlockInvalidPermissionFeatureAccess(customAccess, 'liveQuizRankingList', 'view') ? !organizerAccess
        //             //     : customAccess.liveQuizRankingList.view) ? false : true;
        //             hideSection = this.BlockInvalidPermissionFeatureAccess(customAccess, 'liveQuizRankingList', 'view');
        //             break;

        //         case Toggle.EventParticipationReportLite:
        //             // hideSection = (this.BlockInvalidPermissionFeatureAccess(customAccess, 'eventParticipationReport', 'view') ? !organizerAccess
        //             //     : customAccess.eventParticipationReport.view) ? false : true;
        //             hideSection = this.BlockInvalidPermissionFeatureAccess(customAccess, 'eventParticipationReport', 'view');
        //             break;

        //         case Toggle.ManageAuthor:
        //             // hideSection = (this.BlockInvalidPermissionFeatureAccess(customAccess, 'manageAuthor', 'view') ? adminAccess
        //             //     : customAccess.manageAuthor.view) ? false : true;
        //             hideSection = this.BlockInvalidPermissionFeatureAccess(customAccess, 'manageAuthor', 'view');
        //             break;

        //         default: break;
        //     }
        // }

        //#endregion

        //revamped 2023.09.11
        let hideSection = true;
        switch (section) {

            case Toggle.ManageEvent:
                hideSection = BlockInvalidPermissionFeatureAccess(this.props, 'manageEvent', 'view');
                break;

            case Toggle.ManageRoom:
                hideSection = BlockInvalidPermissionFeatureAccess(this.props, 'manageRoom', 'view');
                break;

            case Toggle.ManageQuestionSet:
                hideSection = BlockInvalidPermissionFeatureAccess(this.props, 'manageQuestionSet', 'view');
                break;

            case Toggle.LiveQuizRankingList:
                hideSection = BlockInvalidPermissionFeatureAccess(this.props, 'liveQuizRankingList', 'view');
                break;

            case Toggle.EventParticipationReportLite:
                hideSection = BlockInvalidPermissionFeatureAccess(this.props, 'eventParticipationReport', 'view');
                break;

            case Toggle.ManageAuthor:
                hideSection = BlockInvalidPermissionFeatureAccess(this.props, 'manageAuthor', 'view');
                break;
            case Toggle.ManageOrganizer:
                hideSection = BlockInvalidPermissionFeatureAccess(this.props, 'manageOrganizer', 'view');
                break;

            case Toggle.ManageStudentProfile:
                hideSection = BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageStudentProfile, 'view');
                break;
            case Toggle.ManageStudentReport:
                hideSection = BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageStudentReport, 'view');
                break;

            default: break;
        }
        return hideSection;
    }

    //2023.09.11
    Callback_Toggle_OMenuPage = (menu = Toggle.None) => {
        let elementId = '';
        switch (menu) {
            case Toggle.ManageStudentProfile: elementId = 'panel-manage-student-profile'; break;
            case Toggle.ManageStudentReport: elementId = 'panel-manage-student-report'; break;
            case Toggle.ManageOrganizer: elementId = 'panel-manage-organizer'; break;          //2024.01.08
            default: break;
        }
        this.setState({
            toggleMenuOption: menu,
        }, () => {
            localStorage.setItem('lastSelectedMenu_' + this.props.user.uid, menu === Toggle.None ? Toggle.ManageRoom : menu);
            this.ToggleSectionButtonStyle(elementId);
        });
    }

    //2023.10.20
    // RepairProfiles = async () => {
    //     let profiles = [];
    //     //fetch affected profiles.
    //     await this.props.firestore
    //         .collection("User")
    //         .where('FirebaseUserId', '>=', '')
    //         .limit(5)
    //         .get()
    //         .then(querySnapshot => {
    //             let data = [];
    //             if (querySnapshot !== null) {
    //                 querySnapshot.forEach((doc) => {
    //                     data.push(doc.data());
    //                 });
    //                 profiles = data;
    //             }
    //             // console.log('RepairProfiles (list)', JSON.stringify(data));
    //         })
    //         .catch(async (error) => {
    //             console.log('RepairProfiles (error)', error);
    //         });
    //     console.log('RepairProfiles (list)', JSON.stringify(profiles));
    //     let confirm = window.confirm('continue?');
    //     if (confirm) {
    //         //loop thru all profiles.
    //         for (let p = 0; p < profiles.length; p++) {
    //             let profile_base = profiles[p];

    //             let uid = CheckObjectStringEmpty(profile_base, 'FirebaseUserId');
    //             if (CheckNullValue(uid) === null)
    //                 uid = CheckObjectStringEmpty(profile_base, 'Uid');

    //             console.log('Checking on... (' + profile_base.Name, uid);

    //             if (CheckNullValue(uid) !== null) {

    //                 //fetch profile frm api.
    //                 let fetched_profile = null;
    //                 await fetch(GlobalSetting.ApiUrl
    //                     + 'Api/LearningCentre/User/Profile/Get/' + uid,
    //                     // Api/LearningCentre/User/Profile/Get/{firebaseUserId}
    //                     {
    //                         method: 'GET',
    //                         headers: {
    //                             'Accept': 'application/json',
    //                             'Content-Type': 'application/json',
    //                         },
    //                     })
    //                     .then(res => res.json())
    //                     .then(data => {
    //                         // console.log('res json =', JSON.stringify(data));
    //                         if (data.success) {
    //                             if (CheckObjectNullValue(data, 'data') !== null) {
    //                                 // fetched_profile = data.data;
    //                                 fetched_profile = {};
    //                                 fetched_profile['uid'] = CheckObjectStringEmpty(data.data, 'firebaseUserId');
    //                                 fetched_profile['email'] = CheckObjectStringEmpty(data.data, 'email');
    //                                 fetched_profile['policyTncAgreeDateTime'] = CheckObjectStringEmpty(data.data, 'policyTncAgreeDateTime');
    //                                 fetched_profile['lastUpdatedOnUtc'] = CheckObjectStringEmpty(data.data, 'lastUpdatedOnUtc');
    //                             }
    //                         }
    //                         else {
    //                             if (this.props.isDevMode)
    //                                 console.log('Error', 'api - fetch profile (failed)\n' + JSON.stringify(data));
    //                         }
    //                     })
    //                     .catch(error => {
    //                         if (this.props.isDevMode)
    //                             console.log('Error', 'api - fetch profile (failed)\n' + error.message);
    //                     });
    //                 if (fetched_profile !== null) {
    //                     console.log('fetched_profile', JSON.stringify(fetched_profile));
    //                     //validate.
    //                     let { profile } = Profile_ValidateProperties(profile_base, fetched_profile);
    //                     if (CheckObjectStringEmpty(profile, 'PolicyTncAgreeDateTime').includes('YYYY')) {
    //                         profile['PolicyTncAgreeDateTime'] = moment(fetched_profile.policyTncAgreeDateTime).format('YYYY-MM-DD HH:mm:ss');
    //                     }
    //                     if (CheckObjectNullValue(profile, 'LastUpdate') === null && CheckObjectNullValue(fetched_profile, 'lastUpdatedOnUtc') !== null) {
    //                         profile['LastUpdate'] = moment(fetched_profile.lastUpdatedOnUtc).format('YYYY-MM-DD HH:mm:ss');
    //                     }
    //                     console.log(JSON.stringify(profile));
    //                     // set.
    //                     let save = window.confirm('save profile ?');
    //                     if (save) {
    //                         if (CheckObjectNullValue(profile, 'Uid') !== null) {
    //                             await this.props.firestore
    //                                 .collection("User")
    //                                 .doc(CheckObjectStringEmpty(profile, 'Uid'))
    //                                 .set(profile)
    //                                 .then(() => {
    //                                     console.log('set profile (done) (' + profile['Uid'] + ' | ' + profile['Name'] + ')');
    //                                 })
    //                                 .catch((error) => {
    //                                     console.log('set profile (failed) (' + profile['Uid'] + ' | ' + profile['Name'] + ') ' + error);
    //                                 });
    //                         }
    //                     }
    //                 }
    //             }
    //             //done 1 profile.
    //             let next = window.confirm('next?');
    //             if (next === false)
    //                 break;
    //         }
    //         console.log('RepairProfiles', 'completed');
    //     }
    //     else {
    //         console.log('RepairProfiles', 'cancelled');
    //     }
    // }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <div style={{
                    // backgroundColor: 'lavender',
                    backgroundColor: 'transparent',
                    // height: '100%',
                    // width: '100%',
                    // position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 15
                }}>
                    <div
                    // className="container"
                    >

                        <div className="row">
                            <div className="col-12">
                                <p style={{
                                    color: 'white', fontSize: 25, textAlign: 'left', fontWeight: 'bold', paddingLeft: 0
                                }}>Quiz Management Portal{this.props.user === null ? '' : ' - ' + this.props.user.email}{this.props.isSuperAdmin ? ' - (Admin)' : ' - (Author)'}</p>
                            </div>
                        </div>

                        <div className="row">

                            {
                                //Menu - left panel - start.
                            }
                            <div className="col-2" id='left-panel-qm'>

                                <div className="card">
                                    <article className="card-body">

                                        {
                                            this.state.toggleMenuOption === Toggle.Invalid ? null :
                                                <>
                                                    <button type="button" className="btn btn-outline-secondary btn-block"
                                                        onClick={this.RefreshPage}
                                                    ><i className="fa fa-refresh icon1"></i></button>
                                                    {
                                                        this.state.OrganizerInfoIsLoaded ?
                                                            //2022.06.27
                                                            this.state.RepresentMultipleOrganizers ?
                                                                <DropdownButton
                                                                    id='organizers-dropdown-button'
                                                                    title={
                                                                        this.state.OrganizerInfo !== null ? this.state.OrganizerInfo.DisplayName : '- Select Organizer -'
                                                                        // this.state.OrganizerInfo.DisplayName
                                                                    }
                                                                    drop='down'
                                                                    onSelect={(option) => {
                                                                        this.setState({
                                                                            OrganizerInfo: option < 0 ? null : this.state.Organizers[Number(option)],
                                                                        }, () => {
                                                                            this.RefreshPage();
                                                                            if (this.props.isDevMode)
                                                                                console.log('organizer Id: ' + this.state.OrganizerInfo?.Id
                                                                                    + '\n\n' + JSON.stringify(this.state.OrganizerInfo)
                                                                                    + '\n\n' + JSON.stringify(this.state.Organizers));
                                                                        });
                                                                    }}
                                                                    style={{ marginTop: 10 }}
                                                                    className='select-organizer-btn'
                                                                >
                                                                    <Dropdown.Item eventKey={-1} value={-1}>- Select Organizer -</Dropdown.Item>
                                                                    {
                                                                        this.state.Organizers.map((data, key) => {
                                                                            return (<Dropdown.Item as="button" eventKey={key} value={key} key={'o_' + key}>{data.DisplayName}</Dropdown.Item>);
                                                                        })
                                                                    }
                                                                </DropdownButton>
                                                                :
                                                                this.state.OrganizerInfo === null ? null :
                                                                    <button type="button" className="btn btn-primary btn-block panel-btn"
                                                                        id='btn-organizer-info'
                                                                        onClick={() => {
                                                                            const info = this.state.OrganizerInfo;
                                                                            const tableHtml = '<table width="100%"><tbody>'
                                                                                + '<tr><th>Display Name</th><td> : </td><td>' + info.DisplayName + '<td></tr>'
                                                                                + '<tr><th>Identity</th><td> : </td><td>' + info.Identity + '<td></tr>'
                                                                                + '<tr><th>Email</th><td> : </td><td>' + this.props.user.email + '<td></tr>'
                                                                                + '</tbody></table>';
                                                                            this.props.SetAlert('Organizer Info', tableHtml, true);
                                                                        }}
                                                                        hidden={!this.props.isDevMode}
                                                                    >Organizer Info</button>
                                                            :
                                                            <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10, }} />
                                                    }
                                                    <span>&nbsp;</span>
                                                </>
                                        }

                                        {
                                            this.HideSection(Toggle.ManageEvent) ? null :
                                                <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                                    id='panel-manage-events'
                                                    onClick={() => this.ToggleList(Toggle.ManageEvent)}
                                                // hidden={(this.props.user === null ? true : !CheckBoolean(this.props.user.IsOrganizer)) || this.state.OrganizerInfo === null}
                                                >Manage Events</button>
                                        }

                                        {
                                            this.HideSection(Toggle.ManageRoom) ? null :
                                                <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                                    id='panel-manage-rooms'
                                                    onClick={() => this.ToggleList(Toggle.ManageRoom)}
                                                >Manage Rooms</button>
                                        }

                                        <span>&nbsp;</span>

                                        {
                                            this.HideSection(Toggle.ManageQuestionSet) ? null :
                                                <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                                    id='panel-manage-question-sets'
                                                    onClick={() => this.ToggleList(Toggle.ManageQuestionSet)}
                                                >Manage<br />Question Sets</button>
                                        }

                                        {
                                            // this.HideSection(Toggle.ManageQuestion) ? null :
                                            //     <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                            //         id='panel-manage-questions'
                                            //         onClick={() => this.ToggleList(Toggle.ManageQuestion)}
                                            //         hidden
                                            //     >Manage Questions</button>
                                        }

                                        <span>&nbsp;</span>

                                        {
                                            this.HideSection(Toggle.LiveQuizRankingList) ? null :
                                                <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                                    id='panel-live-quiz-ranking-list'
                                                    onClick={() => this.ToggleList(Toggle.LiveQuizRankingList)}
                                                // hidden={this.props.user === null ? true : !CheckBoolean(this.props.user.IsOrganizer)}
                                                // hidden={this.props.isSuperAdmin || this.props.isDevMode ? false : this.props.user === null || this.state.OrganizerInfo === null ? true : !CheckBoolean(this.props.user.IsOrganizer)}
                                                >Live Quiz<br />Ranking List</button>
                                        }

                                        {
                                            this.HideSection(Toggle.EventParticipationReportLite) ? null :
                                                <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                                    //2022.05.24
                                                    id='panel-event-participation-report-lite'
                                                    onClick={() => this.ToggleList(Toggle.EventParticipationReportLite)}
                                                // hidden={this.props.user === null ? true : !CheckBoolean(this.props.user.IsOrganizer)}
                                                // hidden={this.props.isSuperAdmin || this.props.isDevMode ? false : this.props.user === null ? true : !CheckBoolean(this.props.user.IsOrganizer)}
                                                // style={this.GetBtnStyle(Toggle.EventParticipationReportLite)}
                                                >Event Participation Report</button>
                                        }

                                        {
                                            <OrganizerMenu
                                                user={this.props.user}
                                                isSuperAdmin={this.props.isSuperAdmin}
                                                callback_Toggle_OMenuPage={this.Callback_Toggle_OMenuPage}
                                            />
                                        }

                                        {
                                            this.HideSection(Toggle.ManageAuthor) ? null :
                                                <>
                                                    <span>&nbsp;</span>
                                                    <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                                        id='panel-manage-author'
                                                        onClick={() => this.ToggleList(Toggle.ManageAuthor)}
                                                    >Manage Author</button>
                                                    <span>&nbsp;</span>
                                                </>
                                        }

                                        {
                                            //2024.01.08
                                            this.HideSection(Toggle.ManageOrganizer) ? null :
                                                <>
                                                    <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                                        id='panel-manage-organizer'
                                                        onClick={() => this.ToggleList(Toggle.ManageOrganizer)}
                                                    >Manage Organizer</button>
                                                    <span>&nbsp;</span>
                                                </>
                                        }

                                        {
                                            //admin special purpose tools - under development/tweaking.
                                            this.props.isDevMode || this.props.isSuperAdmin ?
                                                <>
                                                    <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                                        //2022.05.31
                                                        id='panel-event-participation-report-complete-detail'
                                                        onClick={() => this.ToggleList(Toggle.EventParticipationReportCompleteDetail)}
                                                    // hidden={!this.props.isDevMode || !this.props.isSuperAdmin}
                                                    >Event Participation Report (Complete)</button>

                                                    <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                                        id='panel-event-participation-report'
                                                        onClick={() => this.ToggleList(Toggle.EventParticipationReport_Admin)}
                                                    // hidden={!this.props.isDevMode || !this.props.isSuperAdmin}
                                                    >Event Participation Report (Admin)</button>

                                                    <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                                        id='panel-user-profile-management'
                                                        onClick={() => this.ToggleList(Toggle.UserProfileManagement)}
                                                    // hidden={!this.props.isDevMode || !this.props.isSuperAdmin}
                                                    >User Profile Management</button>

                                                    <button type="button" className="btn btn-outline-primary btn-block panel-btn"
                                                        id='panel-admin-tool'
                                                        onClick={() => this.ToggleList(Toggle.AdminTool)}
                                                        hidden={!this.props.isSuperAdmin}
                                                    // hidden={!this.props.isDevMode || !this.props.isSuperAdmin}
                                                    >Admin Tool</button>
                                                </>
                                                : null
                                        }

                                        {/* <span>&nbsp;</span> */}

                                        {/* <button type="button" className="btn btn-outline-primary btn-block"
                                            onClick={() => this.ToggleList(Toggle.Etc)}
                                            style={this.GetBtnStyle(Toggle.Etc)}
                                            hidden
                                        ><span style={this.GetBtnTextStyle(Toggle.Etc)}>Etc</span></button> */}

                                        {/* <span>&nbsp;</span> */}

                                        {/* <button type="button" className="btn btn-outline-primary btn-block"
                                            onClick={() => this.ToggleList(Toggle.RoomTypeFile)}
                                            style={this.GetBtnStyle(Toggle.RoomTypeFile)}
                                        // hidden
                                        ><span style={this.GetBtnTextStyle(Toggle.RoomTypeFile)}>Manage<br />Document Rooms</span></button> */}

                                        {
                                            // //2023.10.20
                                            // this.props.user === null || this.props.user.email !== 'testaccount@ikeyedutech.com.my' ? null :
                                            //     <>
                                            //         <span>&nbsp;</span>
                                            //         <button type='button' className='btn btn-outline-primary btn-block panel-btn'
                                            //             // style={{ float: 'right', position: 'sticky', zIndex: 999 }}
                                            //             onClick={this.RepairProfiles}>Repair Profiles</button>
                                            //     </>
                                        }

                                    </article>
                                </div>

                                <br />

                                {/* <div className="card">
                                    <article className="card-body">

                                        <p><a href='javascript:void(0);' onClick={() => alert('test')}>shortcut 1</a></p>
                                        <p><a href='javascript:void(0);' onClick={() => alert('test')}>shortcut 1</a></p>
                                        <p><a href='javascript:void(0);' onClick={() => alert('test')}>shortcut 1</a></p>

                                    </article>
                                </div> */}

                                {
                                    //2021.10.04
                                    //Callback Question List - floating div at bottom left corner.
                                    this.state.toggleMenuOption === Toggle.EditQuestionSet ?
                                        this.state.QuestionList !== null ?
                                            <>
                                                <div className='floating-modal-qList' style={{
                                                    width: document.getElementById('left-panel-qm').getBoundingClientRect().width - 30,
                                                }}>
                                                    <table id='LP_QsList' className='table table-bordered tb-no-padding' style={{ marginTop: 10, border: '2px solid orange' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan='5' style={{ backgroundColor: 'white', height: 30, }}>
                                                                    &nbsp;&nbsp;<span style={{ fontSize: 14, fontWeight: 'bold', color: 'blue', }}>
                                                                        Question List
                                                                    </span>
                                                                    <button
                                                                        className='btn-link'
                                                                        type='button'
                                                                        style={{ width: 53 }}
                                                                        onClick={() => this.setState({ ToggleQuestionList: !this.state.ToggleQuestionList })}
                                                                    >{this.state.ToggleQuestionList ? 'hide' : 'show'}</button>
                                                                </td>
                                                            </tr>
                                                            {this.state.ToggleQuestionList ? this.QuestionListUiComponent() : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                            : null
                                        : null
                                }

                            </div>
                            {
                                //Menu - left panel - end.
                            }

                            {
                                //Content - right panel - start.
                            }
                            <div className="col-10" style={{ paddingLeft: 0, }}>

                                <div className="card">
                                    {
                                        this.state.OrganizerInfoIsLoaded === false ?
                                            <article className="card-body">
                                                <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10, }} />
                                            </article>
                                            :
                                            <article className="card-body">
                                                {
                                                    // this.state.OrganizerInfo === null ?
                                                    //     null
                                                    //     :
                                                    this.state.toggleMenuOption === Toggle.ManageEvent ?
                                                        <QuizBankManageEvent
                                                            user={this.props.user}
                                                            isSuperAdmin={this.props.isSuperAdmin}
                                                            isAuthor={this.props.isAuthor}
                                                            isFromParentApp={this.props.isFromParentApp}

                                                            Locale={this.props.Locale}
                                                            SetLocaleSetting={this.props.SetLocaleSetting}

                                                            firestore={this.props.firestore}     //Everything
                                                            dbLiveQuiz={this.props.dbLiveQuiz}
                                                            dbCommon={this.props.dbCommon}
                                                            // dbQuestion={this.props.dbQuestion}
                                                            dbLogs={this.props.dbLogs}
                                                            dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                            dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                                                            SetLoading={this.props.SetLoading}
                                                            SetAlert={this.props.SetAlert}
                                                            SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                            CloseAlert={this.props.CloseAlert}
                                                            SetLog={this.props.SetLog}

                                                            Events={this.props.Events}
                                                            isDevMode={this.props.isDevMode}

                                                            //2021.07.27
                                                            TogglePage={this.TogglePage}
                                                            Toggle={Toggle}

                                                            //2021.08.02
                                                            GroupOptions={this.state.GroupOptions}
                                                            GroupIsLoaded={this.state.GroupIsLoaded}        //2022.06.04
                                                            RefreshGroupOptions={this.Refresh_GroupOptions}

                                                            //2021.10.08
                                                            SubjectList={this.state.SubjectList}
                                                            SubjectOptions={this.state.SubjectOptions}
                                                            SubjectIsLoaded={this.state.SubjectIsLoaded}    //2022.02.10

                                                            //2021.10.13
                                                            OrganizerInfo={this.state.OrganizerInfo}
                                                            OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                            Organizers={this.state.Organizers}
                                                            // OrganizerOptions={this.state.OrganizerOptions}

                                                            //2021.10.23
                                                            credential={this.props.credential}  //aka Firebase Login Credential
                                                        />
                                                        : null
                                                }

                                                {
                                                    this.state.OrganizerInfo === null ?
                                                        null
                                                        :
                                                        this.state.toggleMenuOption === Toggle.ManageRoom ||
                                                            this.state.toggleMenuOption === Toggle.RoomTypeFile ?
                                                            <QuizBankManageRoom
                                                                user={this.props.user}
                                                                isSuperAdmin={this.props.isSuperAdmin}
                                                                isAuthor={this.props.isAuthor}
                                                                isFromParentApp={this.props.isFromParentApp}

                                                                Locale={this.props.Locale}
                                                                SetLocaleSetting={this.props.SetLocaleSetting}

                                                                firestore={this.props.firestore}     //Everything
                                                                dbLiveQuiz={this.props.dbLiveQuiz}
                                                                dbCommon={this.props.dbCommon}
                                                                // dbQuestion={this.props.dbQuestion}
                                                                dbLogs={this.props.dbLogs}
                                                                dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                                dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                                                                SetLoading={this.props.SetLoading}
                                                                SetAlert={this.props.SetAlert}
                                                                // SetAlertWithComponent={this.props.SetAlertWithComponent}
                                                                CloseAlert={this.props.CloseAlert}
                                                                SetLog={this.props.SetLog}

                                                                Events={this.props.Events}
                                                                CheckIfRoomBelongsToEvent={this.props.CheckIfRoomBelongsToEvent}
                                                                isDevMode={this.props.isDevMode}

                                                                // GroupId={this.props.GroupId}
                                                                // SubjectId={this.props.SubjectId}

                                                                // props={this.props}

                                                                //2021.07.27
                                                                TogglePage={this.TogglePage}
                                                                Toggle={Toggle}

                                                                //2021.08.02
                                                                GroupOptions={this.state.GroupOptions}
                                                                GroupIsLoaded={this.state.GroupIsLoaded}        //2022.06.04
                                                                RefreshGroupOptions={this.Refresh_GroupOptions}

                                                                //2021.09.24
                                                                GroupList={this.state.GroupList}

                                                                //2021.10.08
                                                                SubjectList={this.state.SubjectList}
                                                                SubjectOptions={this.state.SubjectOptions}
                                                                SubjectIsLoaded={this.state.SubjectIsLoaded}    //2022.02.10

                                                                //2021.10.13
                                                                OrganizerInfo={this.state.OrganizerInfo}
                                                                OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                                Organizers={this.state.Organizers}

                                                                //2021.10.23
                                                                credential={this.props.credential}  //aka Firebase Login Credential

                                                                //2021.10.27
                                                                roomTypeFile={this.state.toggleMenuOption === Toggle.RoomTypeFile}
                                                            />
                                                            : null
                                                }

                                                {
                                                    this.state.toggleMenuOption === Toggle.RoomTypeFileDetail ?
                                                        <QuizBankRoomTypeFileUploadDetail
                                                            user={this.props.user}
                                                            isSuperAdmin={this.props.isSuperAdmin}
                                                            isAuthor={this.props.isAuthor}
                                                            isFromParentApp={this.props.isFromParentApp}

                                                            Locale={this.props.Locale}
                                                            SetLocaleSetting={this.props.SetLocaleSetting}

                                                            firestore={this.props.firestore}     //Everything
                                                            firebase={this.props.firebase}       //Login, path, etc.
                                                            dbLiveQuiz={this.props.dbLiveQuiz}
                                                            dbCommon={this.props.dbCommon}
                                                            // dbQuestion={this.props.dbQuestion}
                                                            dbLogs={this.props.dbLogs}
                                                            dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                            dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                                                            SetLoading={this.props.SetLoading}
                                                            SetAlert={this.props.SetAlert}
                                                            SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                            CloseAlert={this.props.CloseAlert}
                                                            SetLog={this.props.SetLog}

                                                            Events={this.props.Events}
                                                            CheckIfRoomBelongsToEvent={this.props.CheckIfRoomBelongsToEvent}
                                                            isDevMode={this.props.isDevMode}

                                                            TogglePage={this.TogglePage}
                                                            Toggle={Toggle}

                                                            //Data
                                                            RoomInfo={this.state.RoomInfo}
                                                            GroupList={this.state.GroupList}
                                                            GroupOptions={this.state.GroupOptions}
                                                            GroupIsLoaded={this.state.GroupIsLoaded}        //2022.06.04
                                                            SubjectList={this.state.SubjectList}
                                                            SubjectOptions={this.state.SubjectOptions}
                                                            SubjectIsLoaded={this.state.SubjectIsLoaded}    //2022.02.10

                                                            OrganizerInfo={this.state.OrganizerInfo}
                                                            OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                            Organizers={this.state.Organizers}

                                                            //2021.11.12
                                                            SpecialPageMode={false}
                                                        />
                                                        : null
                                                }

                                                {
                                                    this.state.OrganizerInfo === null ?
                                                        null
                                                        :
                                                        this.state.toggleMenuOption === Toggle.ManageQuestionSet ?
                                                            <QuizBankManageQuestionSet
                                                                user={this.props.user}
                                                                isSuperAdmin={this.props.isSuperAdmin}
                                                                isAuthor={this.props.isAuthor}
                                                                // isFromParentApp={this.props.isFromParentApp}

                                                                Locale={this.props.Locale}
                                                                SetLocaleSetting={this.props.SetLocaleSetting}

                                                                firestore={this.props.firestore}     //Everything
                                                                firebase={this.props.firebase}       //Login, path, etc.
                                                                dbLiveQuiz={this.props.dbLiveQuiz}
                                                                dbCommon={this.props.dbCommon}
                                                                // dbQuestion={this.props.dbQuestion}
                                                                dbLogs={this.props.dbLogs}
                                                                dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                                // dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12
                                                                dbQuestionSet={this.props.dbQuestionSet}    //2023.09.09

                                                                SetLoading={this.props.SetLoading}
                                                                SetAlert={this.props.SetAlert}
                                                                // SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                                CloseAlert={this.props.CloseAlert}
                                                                SetLog={this.props.SetLog}

                                                                Events={this.props.Events}
                                                                isDevMode={this.props.isDevMode}

                                                                // GroupId={this.props.GroupId}
                                                                // SubjectId={this.props.SubjectId}

                                                                //2021.07.27
                                                                TogglePage={this.TogglePage}
                                                                Toggle={Toggle}

                                                                //2021.08.02
                                                                // GroupList={this.state.GroupList}
                                                                GroupOptions={this.state.GroupOptions}
                                                                GroupIsLoaded={this.state.GroupIsLoaded}        //2022.06.04
                                                                RefreshGroupOptions={this.Refresh_GroupOptions}
                                                                DataInput={DataInput}

                                                                //2021.10.08
                                                                SubjectList={this.state.SubjectList}
                                                                SubjectOptions={this.state.SubjectOptions}
                                                                SubjectIsLoaded={this.state.SubjectIsLoaded}    //2022.02.10

                                                                //2021.10.13
                                                                OrganizerInfo={this.state.OrganizerInfo}
                                                                OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                                Organizers={this.state.Organizers}

                                                                //2021.10.23
                                                                credential={this.props.credential}  //aka Firebase Login Credential
                                                            />
                                                            : null
                                                }

                                                {
                                                    this.state.OrganizerInfo === null ?
                                                        null
                                                        :
                                                        this.state.toggleMenuOption === Toggle.EditQuestionSet ?
                                                            <QuizBankEditQuestionSet
                                                                // props={this.props}
                                                                ref={this.EditQuestionSet_Ref}
                                                                user={this.props.user}
                                                                isSuperAdmin={this.props.isSuperAdmin}
                                                                isAuthor={this.props.isAuthor}
                                                                // isFromParentApp={this.props.isFromParentApp}

                                                                Locale={this.props.Locale}
                                                                SetLocaleSetting={this.props.SetLocaleSetting}

                                                                firestore={this.props.firestore}     //Everything
                                                                firebase={this.props.firebase}       //Login, path, etc.
                                                                dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                                // dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                                                                // SetAlert={this.props.SetAlert}
                                                                Feedback={this.props.Feedback}
                                                                SetLoading={this.props.SetLoading}
                                                                SetAlert={this.props.SetAlert}
                                                                SetAlertConfirm={this.props.SetAlertConfirm}
                                                                SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                                CloseAlert={this.props.CloseAlert}

                                                                // Events={this.props.Events}
                                                                isDevMode={this.props.isDevMode}

                                                                TogglePage={this.TogglePage}
                                                                Toggle={Toggle}

                                                                //2021.08.02
                                                                GroupOptions={this.state.GroupOptions}
                                                                GroupIsLoaded={this.state.GroupIsLoaded}        //2022.06.04
                                                                RefreshGroupOptions={this.Refresh_GroupOptions}
                                                                QuestionSetUniqueId={this.state.QuestionSetUniqueId}
                                                                DataInput={DataInput}

                                                                //2021.10.04
                                                                Callback_SetQuestionList={this.Callback_SetQuestionList}

                                                                //2021.10.08
                                                                SubjectList={this.state.SubjectList}
                                                                SubjectOptions={this.state.SubjectOptions}
                                                                SubjectIsLoaded={this.state.SubjectIsLoaded}    //2022.02.10

                                                                //2021.10.13
                                                                OrganizerInfo={this.state.OrganizerInfo}
                                                                OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                                Organizers={this.state.Organizers}

                                                                //2021.10.23
                                                                credential={this.props.credential}  //aka Firebase Login Credential
                                                            />
                                                            : null
                                                }

                                                {
                                                    this.state.toggleMenuOption === Toggle.List ?
                                                        <div style={{ border: '1px solid gray', width: '100%', padding: 10 }} >

                                                            {
                                                                this.state.isFireStoreQuizExists ?
                                                                    <>
                                                                        {
                                                                            this.state.quizListInFireStore.map((data, key) => {
                                                                                return (
                                                                                    JSON.stringify(data)
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                    :
                                                                    <span>list of quiz created.</span>
                                                            }
                                                        </div>
                                                        : null
                                                }

                                                {
                                                    this.state.toggleMenuOption === Toggle.ManageQuestion ?
                                                        <div style={{ border: '1px solid gray', width: '100%', padding: 10 }} >
                                                            list of questions created.
                                                        </div>
                                                        : null
                                                }

                                                {
                                                    // this.state.toggleMenuOption === Toggle.Etc ?
                                                    //     <>
                                                    //         <div style={{ border: '1px solid gray', width: '100%', padding: 10 }} >Etc</div>

                                                    //         <br />Buttons

                                                    //         <br />
                                                    //         <button type="button" className="btn btn-primary"
                                                    //             onClick={() => this.RandomSetEvent()}
                                                    //             disabled={this.state.actionInProgress}
                                                    //         >Random Set Event to FS</button>

                                                    //     </>
                                                    //     : null
                                                }

                                                {
                                                    this.state.OrganizerInfo === null ?
                                                        null
                                                        :
                                                        this.state.toggleMenuOption === Toggle.LiveQuizRankingList ?
                                                            <LiveQuizRoomRankingDataBrowsing
                                                                key={'K_LiveQuizRankingList'}
                                                                user={this.props.user}
                                                                isSuperAdmin={this.props.isSuperAdmin}
                                                                isAuthor={this.props.isAuthor}
                                                                // isFromParentApp={this.props.isFromParentApp}
                                                                isDevMode={this.props.isDevMode}

                                                                Locale={this.props.Locale}
                                                                SetLocaleSetting={this.props.SetLocaleSetting}

                                                                dbFireStore={this.props.firestore}     //Everything
                                                                firebase={this.props.firebase}       //Login, path, etc.
                                                                db={this.props.dbLiveQuiz}
                                                                dbCom={this.props.dbCommon}
                                                                dbQuestion={this.props.dbQuestion}
                                                                dbLog={this.props.dbLogs}
                                                                dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                                // dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                                                                SetLoading={this.props.SetLoading}
                                                                SetAlert={this.props.SetAlert}
                                                                // SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                                CloseAlert={this.props.CloseAlert}
                                                                SetLog={this.props.SetLog}

                                                                // GroupId={this.props.GroupId}
                                                                // SubjectId={this.props.SubjectId}

                                                                TogglePage={this.TogglePage}
                                                                Toggle={Toggle}

                                                                // GroupList={this.state.GroupList}
                                                                GroupOptions={this.state.GroupOptions}
                                                                GroupIsLoaded={this.state.GroupIsLoaded}        //2022.06.04
                                                                RefreshGroupOptions={this.Refresh_GroupOptions}
                                                                DataInput={DataInput}

                                                                SubjectList={this.state.SubjectList}
                                                                SubjectOptions={this.state.SubjectOptions}
                                                                SubjectIsLoaded={this.state.SubjectIsLoaded}    //2022.02.10

                                                                OrganizerInfo={this.state.OrganizerInfo}
                                                                OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                                Organizers={this.state.Organizers}
                                                                // OrganizerOptions={this.state.OrganizerOptions}
                                                                credential={this.props.credential}  //aka Firebase Login Credential

                                                                today={this.state.todayDT}

                                                                schoolListWithDetails={this.state.SchoolListWithDetails}    //2022.06.17 - dynamic import.
                                                            />
                                                            : null
                                                }

                                                {
                                                    this.state.OrganizerInfo === null ?
                                                        null
                                                        :
                                                        this.state.toggleMenuOption === Toggle.EventParticipationReport_Admin ?
                                                            <LiveQuizEventJoinedListBrowsing
                                                                key={'K_EventParticipationReport_Admin'}
                                                                user={this.props.user}
                                                                isSuperAdmin={this.props.isSuperAdmin}
                                                                isAuthor={this.props.isAuthor}
                                                                // isFromParentApp={this.props.isFromParentApp}
                                                                isDevMode={this.props.isDevMode}

                                                                Locale={this.props.Locale}
                                                                SetLocaleSetting={this.props.SetLocaleSetting}

                                                                dbFireStore={this.props.firestore}     //Everything
                                                                firebase={this.props.firebase}       //Login, path, etc.
                                                                db={this.props.dbLiveQuiz}
                                                                dbCom={this.props.dbCommon}
                                                                // dbQuestion={this.props.dbQuestion}
                                                                dbLog={this.props.dbLogs}
                                                                dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                                // dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                                                                SetLoading={this.props.SetLoading}
                                                                SetAlert={this.props.SetAlert}
                                                                // SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                                CloseAlert={this.props.CloseAlert}
                                                                SetLog={this.props.SetLog}

                                                                // GroupId={this.props.GroupId}
                                                                // SubjectId={this.props.SubjectId}

                                                                TogglePage={this.TogglePage}
                                                                Toggle={Toggle}

                                                                // GroupList={this.state.GroupList}
                                                                GroupOptions={this.state.GroupOptions}
                                                                GroupIsLoaded={this.state.GroupIsLoaded}        //2022.06.04
                                                                RefreshGroupOptions={this.Refresh_GroupOptions}
                                                                DataInput={DataInput}

                                                                SubjectList={this.state.SubjectList}
                                                                SubjectOptions={this.state.SubjectOptions}
                                                                SubjectIsLoaded={this.state.SubjectIsLoaded}    //2022.02.10

                                                                OrganizerInfo={this.state.OrganizerInfo}
                                                                OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                                Organizers={this.state.Organizers}
                                                                // OrganizerOptions={this.state.OrganizerOptions}
                                                                credential={this.props.credential}  //aka Firebase Login Credential

                                                                today={this.state.todayDT}
                                                            />
                                                            : null
                                                }

                                                {
                                                    this.state.OrganizerInfo === null ?
                                                        null
                                                        :
                                                        //2022.05.24
                                                        this.state.toggleMenuOption === Toggle.EventParticipationReportLite ?
                                                            <LiveQuizEventParticipationList
                                                                key={'K_EventParticipationReportLite'}
                                                                user={this.props.user}
                                                                isSuperAdmin={this.props.isSuperAdmin}
                                                                isAuthor={this.props.isAuthor}
                                                                // isFromParentApp={this.props.isFromParentApp}
                                                                isDevMode={this.props.isDevMode}

                                                                Locale={this.props.Locale}
                                                                SetLocaleSetting={this.props.SetLocaleSetting}

                                                                // dbFireStore={this.props.firestore}     //Everything
                                                                // firebase={this.props.firebase}       //Login, path, etc.
                                                                // db={this.props.dbLiveQuiz}
                                                                // dbCom={this.props.dbCommon}
                                                                // dbQuestion={this.props.dbQuestion}
                                                                // dbLog={this.props.dbLogs}
                                                                // dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                                // dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                                                                SetLoading={this.props.SetLoading}
                                                                SetAlert={this.props.SetAlert}
                                                                // SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                                CloseAlert={this.props.CloseAlert}
                                                                SetLog={this.props.SetLog}

                                                                // GroupId={this.props.GroupId}
                                                                // SubjectId={this.props.SubjectId}

                                                                TogglePage={this.TogglePage}
                                                                Toggle={Toggle}

                                                                // GroupList={this.state.GroupList}
                                                                GroupOptions={this.state.GroupOptions}
                                                                GroupIsLoaded={this.state.GroupIsLoaded}        //2022.06.04
                                                                RefreshGroupOptions={this.Refresh_GroupOptions}
                                                                DataInput={DataInput}

                                                                SubjectList={this.state.SubjectList}
                                                                SubjectOptions={this.state.SubjectOptions}
                                                                SubjectIsLoaded={this.state.SubjectIsLoaded}    //2022.02.10

                                                                OrganizerInfo={this.state.OrganizerInfo}
                                                                OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                                Organizers={this.state.Organizers}
                                                                // OrganizerOptions={this.state.OrganizerOptions}
                                                                credential={this.props.credential}  //aka Firebase Login Credential

                                                                today={this.state.todayDT}
                                                            />
                                                            : null
                                                }

                                                {
                                                    this.state.OrganizerInfo === null ?
                                                        null
                                                        :
                                                        //2022.05.31
                                                        this.state.toggleMenuOption === Toggle.EventParticipationReportCompleteDetail ?
                                                            <LiveQuizEventParticipationCompleteDetailList
                                                                key={'K_EventParticipationReportCompleteDetail'}
                                                                user={this.props.user}
                                                                isSuperAdmin={this.props.isSuperAdmin}
                                                                isAuthor={this.props.isAuthor}
                                                                // isFromParentApp={this.props.isFromParentApp}
                                                                isDevMode={this.props.isDevMode}

                                                                Locale={this.props.Locale}
                                                                SetLocaleSetting={this.props.SetLocaleSetting}

                                                                // dbFireStore={this.props.firestore}     //Everything
                                                                // firebase={this.props.firebase}       //Login, path, etc.
                                                                // db={this.props.dbLiveQuiz}
                                                                // dbCom={this.props.dbCommon}
                                                                // dbQuestion={this.props.dbQuestion}
                                                                // dbLog={this.props.dbLogs}
                                                                // dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                                // dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                                                                SetLoading={this.props.SetLoading}
                                                                SetAlert={this.props.SetAlert}
                                                                // SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                                CloseAlert={this.props.CloseAlert}
                                                                SetLog={this.props.SetLog}

                                                                // GroupId={this.props.GroupId}
                                                                // SubjectId={this.props.SubjectId}

                                                                TogglePage={this.TogglePage}
                                                                Toggle={Toggle}

                                                                // GroupList={this.state.GroupList}
                                                                GroupOptions={this.state.GroupOptions}
                                                                GroupIsLoaded={this.state.GroupIsLoaded}        //2022.06.04
                                                                RefreshGroupOptions={this.Refresh_GroupOptions}
                                                                DataInput={DataInput}

                                                                SubjectList={this.state.SubjectList}
                                                                SubjectOptions={this.state.SubjectOptions}
                                                                SubjectIsLoaded={this.state.SubjectIsLoaded}    //2022.02.10

                                                                OrganizerInfo={this.state.OrganizerInfo}
                                                                OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                                Organizers={this.state.Organizers}
                                                                // OrganizerOptions={this.state.OrganizerOptions}
                                                                credential={this.props.credential}  //aka Firebase Login Credential

                                                                today={this.state.todayDT}
                                                            />
                                                            : null
                                                }

                                                {
                                                    this.state.OrganizerInfo === null ?
                                                        null
                                                        :
                                                        this.state.toggleMenuOption === Toggle.UserProfileManagement ?
                                                            <LiveQuizUserProfileManagement
                                                                key={'K_UserProfileManagement'}
                                                                user={this.props.user}
                                                                isSuperAdmin={this.props.isSuperAdmin}
                                                                isAuthor={this.props.isAuthor}
                                                                // isFromParentApp={this.props.isFromParentApp}
                                                                isDevMode={this.props.isDevMode}

                                                                Locale={this.props.Locale}
                                                                SetLocaleSetting={this.props.SetLocaleSetting}

                                                                dbFireStore={this.props.firestore}     //Everything
                                                                firebase={this.props.firebase}       //Login, path, etc.
                                                                db={this.props.dbLiveQuiz}
                                                                dbCom={this.props.dbCommon}
                                                                // dbQuestion={this.props.dbQuestion}
                                                                dbLog={this.props.dbLogs}
                                                                dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                                // dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                                                                SetLoading={this.props.SetLoading}
                                                                SetAlert={this.props.SetAlert}
                                                                // SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                                CloseAlert={this.props.CloseAlert}
                                                                SetLog={this.props.SetLog}

                                                                // GroupId={this.props.GroupId}
                                                                // SubjectId={this.props.SubjectId}

                                                                TogglePage={this.TogglePage}
                                                                Toggle={Toggle}

                                                                // GroupList={this.state.GroupList}
                                                                GroupOptions={this.state.GroupOptions}
                                                                GroupIsLoaded={this.state.GroupIsLoaded}        //2022.06.04
                                                                RefreshGroupOptions={this.Refresh_GroupOptions}
                                                                DataInput={DataInput}

                                                                SubjectList={this.state.SubjectList}
                                                                SubjectOptions={this.state.SubjectOptions}
                                                                SubjectIsLoaded={this.state.SubjectIsLoaded}    //2022.02.10

                                                                OrganizerInfo={this.state.OrganizerInfo}
                                                                OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                                Organizers={this.state.Organizers}
                                                                // OrganizerOptions={this.state.OrganizerOptions}
                                                                credential={this.props.credential}  //aka Firebase Login Credential
                                                                profile={this.props.profile}

                                                                today={this.state.todayDT}
                                                            />
                                                            : null
                                                }

                                                {
                                                    this.props.isSuperAdmin === false ?
                                                        null
                                                        :
                                                        this.state.toggleMenuOption === Toggle.AdminTool ?
                                                            <QuizAdminTool
                                                                key={'K_AdminTool'}
                                                                user={this.props.user}
                                                                isSuperAdmin={this.props.isSuperAdmin}
                                                                isAuthor={this.props.isAuthor}
                                                                // isFromParentApp={this.props.isFromParentApp}
                                                                isDevMode={this.props.isDevMode}

                                                                Locale={this.props.Locale}
                                                                SetLocaleSetting={this.props.SetLocaleSetting}

                                                                firestore={this.props.firestore}     //Everything
                                                                firebase={this.props.firebase}       //Login, path, etc.
                                                                dbLiveQuiz={this.props.dbLiveQuiz}
                                                                dbCommon={this.props.dbCommon}
                                                                // dbQuestion={this.props.dbQuestion}
                                                                dbLogs={this.props.dbLogs}
                                                                dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                                // dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                                                                SetLoading={this.props.SetLoading}
                                                                SetAlert={this.props.SetAlert}
                                                                // SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                                CloseAlert={this.props.CloseAlert}
                                                                SetLog={this.props.SetLog}

                                                                // GroupId={this.props.GroupId}
                                                                // SubjectId={this.props.SubjectId}

                                                                TogglePage={this.TogglePage}
                                                                Toggle={Toggle}

                                                                // GroupList={this.state.GroupList}
                                                                GroupOptions={this.state.GroupOptions}
                                                                GroupIsLoaded={this.state.GroupIsLoaded}        //2022.06.04
                                                                RefreshGroupOptions={this.Refresh_GroupOptions}
                                                                DataInput={DataInput}

                                                                SubjectList={this.state.SubjectList}
                                                                SubjectOptions={this.state.SubjectOptions}
                                                                SubjectIsLoaded={this.state.SubjectIsLoaded}    //2022.02.10

                                                                OrganizerInfo={this.state.OrganizerInfo}
                                                                OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                                Organizers={this.state.Organizers}
                                                                // OrganizerOptions={this.state.OrganizerOptions}
                                                                credential={this.props.credential}  //aka Firebase Login Credential
                                                                profile={this.props.profile}

                                                                today={this.state.todayDT}

                                                                Events={this.props.Events}
                                                            />
                                                            : null
                                                }

                                                {
                                                    this.state.toggleMenuOption === Toggle.ManageStudentProfile ?
                                                        <ManageStudentProfile
                                                            key={'K_ManageStudentProfile'}

                                                            user={this.props.user}
                                                            isSuperAdmin={this.props.isSuperAdmin}
                                                            isAuthor={this.props.isAuthor}
                                                            isDevMode={this.props.isDevMode}

                                                            Locale={this.props.Locale}
                                                            SetLocaleSetting={this.props.SetLocaleSetting}

                                                            SetLoading={this.props.SetLoading}
                                                            SetAlert={this.props.SetAlert}
                                                            CloseAlert={this.props.CloseAlert}
                                                            SetLog={this.props.SetLog}

                                                            OrganizerInfo={this.state.OrganizerInfo}
                                                            OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                            Organizers={this.state.Organizers}
                                                        />
                                                        : null
                                                }

                                                {
                                                    this.state.toggleMenuOption === Toggle.ManageStudentReport ?
                                                        <ManageStudentReport
                                                            key={'K_ManageStudentReport'}

                                                            user={this.props.user}
                                                            isSuperAdmin={this.props.isSuperAdmin}
                                                            isAuthor={this.props.isAuthor}
                                                            isDevMode={this.props.isDevMode}

                                                            Locale={this.props.Locale}
                                                            SetLocaleSetting={this.props.SetLocaleSetting}

                                                            SetLoading={this.props.SetLoading}
                                                            SetAlert={this.props.SetAlert}
                                                            CloseAlert={this.props.CloseAlert}
                                                            SetLog={this.props.SetLog}

                                                            OrganizerInfo={this.state.OrganizerInfo}
                                                            OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                            Organizers={this.state.Organizers}
                                                        />
                                                        : null
                                                }

                                                {
                                                    this.props.isSuperAdmin === false ?
                                                        null
                                                        :
                                                        this.state.toggleMenuOption === Toggle.ManageAuthor ?
                                                            <AdminManageAuthor
                                                                key={'K_Admin_ManageAuthor'}
                                                                user={this.props.user}
                                                                isSuperAdmin={this.props.isSuperAdmin}
                                                                isAuthor={this.props.isAuthor}
                                                                // isFromParentApp={this.props.isFromParentApp}
                                                                isDevMode={this.props.isDevMode}

                                                                Locale={this.props.Locale}
                                                                SetLocaleSetting={this.props.SetLocaleSetting}

                                                                SetLoading={this.props.SetLoading}
                                                                SetAlert={this.props.SetAlert}
                                                                // SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                                CloseAlert={this.props.CloseAlert}
                                                                SetLog={this.props.SetLog}

                                                                defaultCustomPermissionModel_allowed={this.props.defaultCustomPermissionModel_allowed}
                                                                defaultCustomPermissionModel_restricted={this.props.defaultCustomPermissionModel_restricted}
                                                                defaultCustomPermissions={this.props.defaultCustomPermissions}

                                                                OrganizerInfo={this.state.OrganizerInfo}
                                                                OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                                Organizers={this.state.Organizers}
                                                            />
                                                            : null
                                                }

                                                {
                                                    this.props.isSuperAdmin === false ?
                                                        null
                                                        :
                                                        this.state.toggleMenuOption === Toggle.ManageOrganizer ?
                                                            <ManageOrganizer
                                                                key={'K_ManageOrganizer'}     //2024.01.08

                                                                user={this.props.user}
                                                                isSuperAdmin={this.props.isSuperAdmin}
                                                                isAuthor={this.props.isAuthor}
                                                                isDevMode={this.props.isDevMode}

                                                                Locale={this.props.Locale}
                                                                SetLocaleSetting={this.props.SetLocaleSetting}

                                                                SetLoading={this.props.SetLoading}
                                                                SetAlert={this.props.SetAlert}
                                                                CloseAlert={this.props.CloseAlert}
                                                                SetLog={this.props.SetLog}

                                                                OrganizerInfo={this.state.OrganizerInfo}
                                                                OrganizerInfoIsLoaded={this.state.OrganizerInfoIsLoaded}
                                                                Organizers={this.state.Organizers}
                                                            />
                                                            : null
                                                }

                                                {
                                                    this.state.toggleMenuOption === Toggle.Invalid ?
                                                        <>Invalid Access Permission.</>
                                                        : null
                                                }
                                            </article>
                                    }
                                </div>

                            </div>
                            {
                                //Content - right panel - end.
                            }

                        </div>
                    </div>

                    <div style={{ height: '200px', width: '100%' }}>
                        <span>&nbsp;</span>
                    </div>
                    {/* <button type='button' onClick={this.GenerateSchoolListTxt}>generate school list txt</button> */}
                    {/* <button type='button' onClick={this.SyncSchoolListToMainDatabase}>sync school list to main database</button> */}
                </div>
            );
        }
    }
}