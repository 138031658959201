import React from 'react';
import { Redirect } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import './PageStyle.scss';
// import { Locale, Lang } from '../../Localization/CustomLocalization.js';
import QuizBankRoomTypeFileUploadDetail from './QM_RoomTypeFileUploadDetail';
import LoadingIndicator from '../Quiz/LoadingIndicator';
import { GlobalSetting } from '../../components/GlobalSetting';
// import { Delay, DelayUntil, ScrollToElement, GetPropIds } from '../../components/GlobalFunctions';      //2023.09.11

//2021.11.12
export default class QuizBankListAndEditDocRoomForSpecialWebPortal extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        // this.RoomDetailRef = React.createRef();
    }

    getInitState = () => ({
        redirect: false,
        redirectLink: '',
        // isDevMode: false,

        RoomList: [],
        IsRoomListLoaded: false,
        hasTotalFileQty: false,     //2021.11.15

        SelectedRoom: null,

        GroupList: [],
        GroupOptions: [],
    });

    componentDidMount = () => {
        let cookie = localStorage.getItem('special-page-mode');
        if (cookie === null) {
            this.BackToLogin();
        }
        else {
            if (cookie !== 'a048ba0f-d147-490d-9113-7e1b6eb76de7')
                this.BackToLogin();
            else
                if (this.props.isDevMode === false)
                    localStorage.removeItem('special-page-mode');
        }
        this.LoadRelatedRoomsOnly();
        this.CheckOn_GroupList();
        this.props.LoadAllEvents();
    }

    Delay = ms => new Promise(res => setTimeout(res, ms));

    DoNothing = () => { }

    //format = 'YYYY-MM-DD hh:mm A'
    FormatTime = (_time) => {
        let time = '';
        if (String(_time).includes(' ') || String(_time).length > 8)
            time = moment(_time).format('hh:mm A');
        else
            time = moment(moment().format('YYYY-MM-DD ') + _time).format('hh:mm A');
        return time;
    }

    //format = 'MMM D, YYYY'
    FormatDate = (_data) => {
        if (_data.hasOwnProperty('DateStart')) {
            if (_data.DateStart === _data.DateEnd)
                return (<>{moment(_data.DateStart).format('ll')}</>);
            else
                return (<>{moment(_data.DateStart).format('ll')}<br />~<br />{moment(_data.DateEnd).format('ll')}</>);
        }
        else {
            return (<>{moment(_data.Date).format('ll')}</>);
        }
    }

    BackToLogin = () => {
        this.setState({
            redirectLink: '/',
            redirect: true,
        });
        return null;
    }

    LoadRelatedRoomsOnly = async () => {
        let roomList = [];
        this.setState({ IsRoomListLoaded: false, });
        //RoomType = 1 (File Upload, Document Room)
        await this.props.firestore
            .collection("LiveQuiz_UniqueRoomCode")
            .where('CenterUserId', '==', Number(24856532))
            .where('AuthorId', '==', Number(6))
            .where('RoomType', '==', Number(1))
            .orderBy('Date', 'desc')
            .limit(5)
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                    });
                    if (dataArray.length > 0) {
                        roomList = dataArray;
                        // roomList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                        // roomList_lastVisible = dataArray[0];
                    }
                }
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(roomList));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });
        this.setState({
            RoomList: roomList,
            // RoomList_lastVisible: roomList_lastVisible,
            IsRoomListLoaded: true,
        }, () => {
            this.LoadRoomUploadedFileQty();     //2021.11.15
        });
    }

    RoomListComponents = () => {
        let listRows = [];
        if (this.state.IsRoomListLoaded) {
            if (this.state.RoomList.length > 0) {
                this.state.RoomList.map((data, key) => {
                    return listRows.push(
                        <tr style={
                            this.state.SelectedRoom === null ? {}
                                : this.state.SelectedRoom.RoomCode === data.RoomCode ? { backgroundColor: 'lavender' }
                                    : {}
                        }>
                            <td>{key + 1}</td>
                            <td>{data.RoomCode}</td>
                            <td align={'left'}>{data.RoomTitle}{
                                data.hasOwnProperty('GroupId') ?
                                    data.GroupId > 0 ?
                                        <><br /><span style={{ color: 'gray', fontSize: 12, }}>{this.GetGroupName(data)}</span></>
                                        : null
                                    : null
                            }{
                                    data.hasOwnProperty('RoomType') ?
                                        data.RoomType === 1 ?
                                            String(data.Remark).length > 0 ?
                                                <>
                                                    <br /><span style={{ color: 'gray', fontSize: 12, }}>{'< ' + data.Remark + ' >'}</span>
                                                </>
                                                : null
                                            : null
                                        : null
                                }</td>
                            <td hidden={this.state.hasTotalFileQty === false}>{
                                data.hasOwnProperty('TotalFile') ?
                                    String(data.TotalFile)
                                    : '0'
                            }</td>
                            <td>{data.SubjectName}</td>
                            <td>{data.TimeStart === '_none' ? '12:00 AM' : this.FormatTime(data.TimeStart)}</td>
                            <td>{data.TimeEnd === '_none' ? '11:59 PM' : this.FormatTime(data.TimeEnd)}</td>
                            <td>{this.FormatDate(data)}</td>
                            <td>
                                <button type="button" className="btn btn-outline-primary"
                                    onClick={() => this.LoadSelectedRoom(data)}
                                >Detail</button>
                            </td>
                        </tr >
                    );
                });
            }
            else {
                listRows.push(<tr><td colSpan='15'>list is empty</td></tr>);
            }
        }
        else {
            listRows.push(<tr><td colSpan='15'><LoadingIndicator /></td></tr>);
        }
        return listRows;
    }

    LoadSelectedRoom = async (room = null) => {
        if (room !== null) {
            if (this.state.SelectedRoom === null) {
                this.setState({
                    SelectedRoom: room,
                });
            }
            else {
                this.setState({
                    SelectedRoom: null,
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            SelectedRoom: room,
                        }, () => {
                            // setTimeout(() => {
                            //     // this.RoomDetailRef.current.EditRoomSettings();
                            //     this.RoomDetailRef.EditRoomSettings();
                            // }, 2000);
                        });
                    }, 500);
                });
            }
        }
    }

    CheckOn_GroupList = async () => {
        if (this.state.GroupList.length <= 0) {
            let _List = [];
            //Default Groups.
            await this.props.firestore
                .collection("QuizBank")
                .doc('QuizGroup')
                .collection('QuizGroups')
                .where('AuthorId', '==', Number(1))
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                        });
                        if (dataArray.length > 0) {
                            dataArray.map((data, key) => {
                                return _List.push(data);
                            });
                        }
                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log(error.message);
                });
            //Author created Groups.
            await this.props.firestore
                .collection("QuizBank")
                .doc('QuizGroup')
                .collection('QuizGroups')
                .where('AuthorId', '==', Number(6))
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                        });
                        if (dataArray.length > 0) {
                            dataArray.map((data, key) => {
                                return _List.push(data);
                            });
                        }
                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log(error.message);
                });
            //Sort.
            _List.sort((a, b) => a.Id - b.Id);
            _List.sort((a, b) => moment(a.DisplayOrder) - moment(b.DisplayOrder));
            //Save.
            this.setState({ GroupList: _List, });
            // await Delay(300);
        }
        this.Populate_GroupOptions();
    }
    Populate_GroupOptions = () => {
        if (this.state.GroupOptions.length <= 0) {
            let options = [];
            this.state.GroupList.map((data, key) => {
                let _remark = (data.Remark !== undefined ? data.Remark !== '' ? ' ' + data.Remark : '' : '');
                return options.push({ value: data.Name, label: data.Name + _remark, id: data.Id });
            });
            this.setState({ GroupOptions: options });
        }
    }
    GetGroupName = (data) => {
        //2021.11.10
        let _findIndex = -1;
        if (data.hasOwnProperty('GroupId')) {
            _findIndex = this.state.GroupOptions.findIndex(x => x.id === data.GroupId);
        }
        return _findIndex < 0 ? '' : this.state.GroupOptions[_findIndex].label;
    }

    //2021.11.15
    LoadRoomUploadedFileQty = async () => {
        if (this.state.RoomList.length > 0) {
            let _roomList = this.state.RoomList;
            await Promise.all(
                this.state.RoomList.map(async (data, key) => {
                    if (_roomList[key].TotalFile === undefined) {
                        _roomList[key].TotalFile = '0';
                        await fetch(GlobalSetting.ApiUrl
                            + 'Api/LearningCentre/Quiz/Room/File/RequestFileCount/'
                            + (data.hasOwnProperty('RoomCode') ? String(data.RoomCode) : '') + '/'
                            + (data.hasOwnProperty('RoomId') ? String(data.RoomId) : ''),
                            // Api/LearningCentre/Quiz/Room/File/RequestFileCount/{roomcode}/{room_id}
                            {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                            })
                            .then(res => res.json())
                            .then(data => {
                                if (!data.success)
                                    if (this.props.isDevMode)
                                        console.log('Error', 'api - room total file (failed)\n' + JSON.stringify(data));

                                if (data.data !== undefined)
                                    _roomList[key].TotalFile = String(data.data);

                                if (this.props.isDevMode)
                                    console.log(JSON.stringify(data));
                            })
                            .catch(error => {
                                if (this.props.isDevMode)
                                    console.log('Error', 'api - room total file (failed)\n' + error.message);
                            });
                    }
                    return null;
                })
            );
            this.setState({
                RoomList: _roomList,
                hasTotalFileQty: true,
            }, () => {
                // setTimeout(() => {
                //     console.log(JSON.stringify(this.state.RoomList));
                //     this.setState({
                //         // IsRoomListLoaded: true,
                //         hasTotalFileQty: true,
                //     });
                // }, 500);
            });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                this.state.IsRoomListLoaded === false ?
                    <ProgressBar animated now={100} className='progressbar1' />
                    :
                    <div style={{
                        // backgroundColor: 'lavender',
                        backgroundColor: 'transparent',
                        // height: '100%',
                        // width: '100%',
                        // position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 15
                    }}>
                        <div className="card">
                            <article className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <span style={{ fontSize: 20, fontWeight: 'bold' }}>Rooms</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                                            <thead>
                                                <tr>
                                                    <td>#</td>
                                                    <td>Room&nbsp;Code</td>
                                                    <td>Room&nbsp;Title</td>
                                                    <td hidden={this.state.hasTotalFileQty === false}>Total&nbsp;Files</td>
                                                    <td>Subject</td>
                                                    <td>Time&nbsp;Start</td>
                                                    <td>Time&nbsp;End&nbsp; </td>
                                                    <td>Date&nbsp;/&nbsp;Period</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.Events !== null ?
                                                        this.RoomListComponents()
                                                        : <tr><td colSpan='15'></td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            this.state.SelectedRoom !== null ?
                                                <QuizBankRoomTypeFileUploadDetail

                                                    // ref={this.RoomDetailRef}
                                                    // ref={RoomDetailRef => { this.RoomDetailRef = RoomDetailRef; }}

                                                    user={this.props.user}
                                                    isSuperAdmin={this.props.isSuperAdmin}
                                                    isAuthor={this.props.isAuthor}

                                                    Locale={this.props.Locale}
                                                    SetLocaleSetting={this.props.SetLocaleSetting}

                                                    firestore={this.props.firestore}     //Everything
                                                    firebase={this.props.firebase}       //Login, path, etc.
                                                    dbLiveQuiz={this.props.dbLiveQuiz}
                                                    dbCommon={this.props.dbCommon}
                                                    // dbQuestion={this.props.dbQuestion}
                                                    dbLogs={this.props.dbLogs}
                                                    dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                                                    dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                                                    SetLoading={this.props.SetLoading}
                                                    SetAlert={this.props.SetAlert}
                                                    SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                    CloseAlert={this.props.CloseAlert}
                                                    SetLog={this.props.SetLog}

                                                    Events={this.props.Events}
                                                    isDevMode={this.props.isDevMode}

                                                    // TogglePage={this.TogglePage}
                                                    // Toggle={Toggle}

                                                    //Data
                                                    RoomInfo={this.state.SelectedRoom}
                                                    GroupList={this.state.GroupList}
                                                    GroupOptions={this.state.GroupOptions}
                                                    // SubjectList={this.state.SubjectList}
                                                    // SubjectOptions={this.state.SubjectOptions}
                                                    // OrganizerInfo={this.state.OrganizerInfo}

                                                    //2021.11.12
                                                    SpecialPageMode={true}
                                                    CheckIfRoomBelongsToEvent={this.props.CheckIfRoomBelongsToEvent}
                                                />
                                                : null
                                        }
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div >
            );
        }
    }
}