import React from 'react';
import { Redirect } from 'react-router-dom';
// import {
//     // osVersion, osName, browserName, fullBrowserVersion
//     browserName
// } from "react-device-detect";
import { Col, Row, Modal, Button, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';
import * as XLSX from "xlsx";
import './PageStyle.scss';
import { Locale } from '../../Localization/CustomLocalization.js';
import { GlobalSetting, Toggle, PermissionAccessType } from '../../components/GlobalSetting';
import { Delay, DelayUntil, ScrollToElement, GetPropIds, BlockInvalidPermissionFeatureAccess, CheckNullValue, CheckObjectStringEmpty, CheckObjectNullValue, CheckObjectNumber, CapitalizeJsonKeys, FormatList_QuestionSet, CheckStringEmpty, PagingComponents } from '../../components/GlobalFunctions';      //2023.09.11
import LoadingIndicator from '../Quiz/LoadingIndicator';


// let DataInput = {
//     None: 0,
//     Name: 1,
//     Remark: 2,
//     TotalQuestion: 3,
//     IsPublic: 4,
//     IsPrivateGroup: 5,
//     Published: 6,
//     DisplayOrder: 7,
//     Group: 8,
//     Subject: 9,
// };
let DataInput = {};

let UploadState = {
    None: 0,
    Converting: 1,
    ConvertFailed: 2,
    Uploading: 3,
    UploadFailed: 4,
    Processing: 5,
    Failed: 6,
    Success: 7,
    Saving: 8,
    Validation: 9,
};

//2021.06.30
export default class QuizBankManageQuestionSet extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
        this.NQSet_Remark = React.createRef();
    }

    getInitState = () => ({
        redirect: false,
        redirectLink: '',

        PA_View: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageQuestionSet, PermissionAccessType.View),
        PA_Search: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageQuestionSet, PermissionAccessType.Search),
        PA_Create: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageQuestionSet, PermissionAccessType.Create),
        PA_Update: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageQuestionSet, PermissionAccessType.Update),
        PA_Delete: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageQuestionSet, PermissionAccessType.Delete),
        PA_Upload: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageQuestionSet, PermissionAccessType.Upload),
        PA_Download: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageQuestionSet, PermissionAccessType.Download),

        EventList: [],
        TargetEventModal: [],

        QuestionSetList: [],
        QuestionSetList_lastVisible: null,
        QuestionSetList_FetchDone: false,
        QuestionSetList_More_isLoading: false,
        ListMaxQueryQty: '5',     //2021.11.17
        previousQSetListQty: 0,             //2021.11.17

        //2023.11.24
        PageIndex: 0,
        PageSize: 0,
        TotalRows: 0,

        TargetQuestionSetModal: [],
        Show_Delete_QuestionSetModal: false,
        Show_Create_QuestionSetModal: false,
        Show_Upload_QuestionSetModal: false,
        Show_ProcessUploaded_QuestionSetModal: false,

        uploadedFile: null,
        // fileToJson: null,        
        // fileConvertToJsonSuccess: null,
        // jsonForFireStore: null,
        // uploadToFireStoreSuccess: null,
        // questionSetModal: null,

        //2021.07.06
        NewQSet_IsPublic: false,
        NewQSet_IsPrivateGroup: false,
        NewQSet_Published: true,
        NewQSet_Name: '',
        NewQSet_Remark: '',
        NewQSet_TotalQuestion: 0,
        NewQSet_DisplayOrder: 0,
        NewQSet_Group: null,

        // //2021.07.23
        // NewQSet_SubjectId: 0,
        // NewQSet_SubjectName: '',
        NewQSet_Subject: null,      //2022.02.10

        //2021.07.12
        FileToJson_Questions: null,
        // FileToJson_FillInTheBlanks: null,
        FileToJson_Comprehension: null,
        FileToJson_FillInTheBlanks: null,

        //2021.07.24
        UploadStatus: UploadState.None,
        NewQSet_UniqueId: '',

        //2021.07.29
        IsUploadConditionsFullfilled: false,
        // GroupOptions: [],
        // GroupList: [],  //this.props.GroupId,
        // GroupList: [
        //     { Id: 1, Name: 'Standard 1' }, { Id: 2, Name: 'Standard 2' }, { Id: 3, Name: 'Standard 3' },
        //     { Id: 4, Name: 'Standard 4' }, { Id: 5, Name: 'Standard 5' }, { Id: 6, Name: 'Standard 6' },
        //     { Id: 7, Name: 'Group A' }, { Id: 8, Name: 'Group B' }, { Id: 9, Name: 'Group C' }, { Id: 10, Name: 'Group D' },
        //     { Id: 11, Name: 'Form 1' }, { Id: 12, Name: 'Form 2' }, { Id: 13, Name: 'Form 3' },
        //     { Id: 14, Name: 'Form 4' }, { Id: 15, Name: 'Form 5' }, { Id: 15, Name: 'Form 6' },
        //     { Id: 16, Name: 'Form 5 (Batch 2020)' }, { Id: 17, Name: 'Other' }
        // ],

        //2021.10.05
        UploadStatusText: '',

        //2022.02.11
        ShowSearchQuestionSetModal: false,
        SearchQsSet_Processing: false,
        SearchQsSet_ByGroup: null,          //standard
        SearchQsSet_BySubject: null,        //subject
        SearchQsSet_MaxQtyShow: 5,          //max result
        IsSearchQsSetConditionsValid: false,
        SearchQsSet_Result_List: [],
        SearchQsSet_Result_List_lastVisible: null,
        //Select from Search Result.
        ShowSelectQuestionSetModal: false,
        IsSearchQsSetSelected: false,
        SearchQsSet_QuestionSet_Selected: null,
        SearchQsSet_ByName: '',
    });

    componentDidMount = async () => {
        DataInput = this.props.DataInput;

        if (this.props.user === null) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }

        await this.LoadSavedListMaxQueryQtySetting();     //2021.11.17

        if (this.state.EventList.length <= 0) {
            var events = this.props.Events;
            events.sort((a, b) => moment(b.DateStart) - moment(a.DateStart));
            this.setState({ EventList: events });
        }
        // await Delay(500);
        await DelayUntil(() => this.props.SubjectIsLoaded === true);

        // await this.LoadQuestionSetList();
        await this.LoadQuestionSetList_ViaApi();
    }

    componentWillUnmount = async () => {
        await this.Remove_ListenToUploadStatus();
    }

    DoNothing = () => { }

    //2022.12.06
    ConvertKeysToCapital = (obj) => {
        //Temporary object for storing traverse results
        var tmpObj = {};
        //Process array objects
        if (Array.isArray(obj)) {
            var arr = [];
            //traverse through array content
            for (var i in obj) {
                if (obj[i] !== null && typeof (obj[i]) == "object") {
                    arr.push(this.ConvertKeysToCapital(obj[i]));
                } else {
                    arr.push(obj[i]);
                }
            }
            tmpObj = arr;
        }
        //IF not array, process other objects
        else {
            for (var ii in obj) {
                var j = this.CapitalizeFirstLetter(ii);
                if (obj[ii] !== null && typeof (obj[ii]) == "object") {
                    tmpObj[j] = this.ConvertKeysToCapital(obj[ii]);
                } else {
                    //If not array or object, assume that we have string, etc. value
                    tmpObj[j] = obj[ii];
                }
            }
        }
        return tmpObj;
    }
    CapitalizeFirstLetter = (text = '') => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    GetValue = (_data, _propertyName, _default = '') => {
        if (_data !== null && _data !== undefined) {
            if (_data.hasOwnProperty(_propertyName))
                return String(_data[_propertyName]);
            else
                return _default;
        }
        return '';
    }

    SelectTargetEvent = (_idx) => {
        this.setState({
            TargetEventModal: this.state.EventList[_idx]
        });
    }

    //2021.11.17
    LoadSavedListMaxQueryQtySetting = async () => {
        let _maxQueryQty = localStorage.getItem('ManageQuestionSet_List_MaxQueryQty_' + this.props.user.uid);
        this.setState({
            ListMaxQueryQty: _maxQueryQty !== null ? _maxQueryQty : this.state.ListMaxQueryQty,
        });
    }

    //2023.09.09
    GetPropIds = () => {
        let centerUserId = 0;
        let authorId = 0;
        let authorRoleId = 0;
        let organizerId = 0;
        if (this.props.user.CenterUserId !== null)
            centerUserId = Number(this.props.user.CenterUserId);
        if (this.props.user.AuthorId !== null)
            authorId = Number(this.props.user.AuthorId);
        if (this.props.user.AuthorRoleId !== null)
            authorRoleId = Number(this.props.user.AuthorRoleId);
        if (this.props.OrganizerInfoIsLoaded === true)
            if (this.props.OrganizerInfo !== null)
                if (this.props.OrganizerInfo.OrganizerId !== null)
                    organizerId = Number(this.props.OrganizerInfo.OrganizerId);
        return { centerUserId, authorId, authorRoleId, organizerId };
    }

    //=== Question Set === Delete === start ===//
    DeleteThisQuestionSet = async () => {
        this.props.SetAlert('', '<b>Delete Question Set currently not available.</b>', false);
    }
    ToggleDeleteQuestionSetModal = () => {
        if (this.state.PA_Delete === false)
            return null;
        this.setState({ Show_Delete_QuestionSetModal: !this.state.Show_Delete_QuestionSetModal });
    }
    //=== Question Set === Delete === end ===//


    //=== Question Set === Create / New === start ===//
    ToggleCreateQuestionSetModal = () => {
        if (this.state.PA_Create === false)
            return null;
        this.setState({ Show_Create_QuestionSetModal: !this.state.Show_Create_QuestionSetModal }, () => {
            if (this.state.Show_Create_QuestionSetModal) {
                this.ToggleUploadQuestionSetModal();
                // this.ResetQuestionSetModal();
            }
        });
    }
    //Reset.
    ResetCreateQuestionSetModal = () => {
        this.ToggleCreateQuestionSetModal();
        setTimeout(() => {
            this.ToggleCreateQuestionSetModal();
        }, 300);
    }
    CreateQuestionSetTemplate = () => {
        if (this.state.PA_Upload === false)
            return null;

        this.setState({
            // NewQSet_UniqueId: '',
            UploadStatus: UploadState.Converting,
        });
        this.ToggleProcessUploadedQuestionSetModal();

        //Creating Template based on settings.
        let _qsModal = [];
        for (var i = 0; i < this.state.NewQSet_TotalQuestion; i++) {
            _qsModal.push({
                QuizType: 'Objective',
                // No: (i + 1).toString(),
                Content: 'content',
                Answer: 'A',
                // Selection: 'A:-;B:-;C:-;D:-;',
                Tags: '',
                Hints: '',
                PictureUrl: '',
                A: '-',
                B: '-',
                C: '-',
                D: '-',
            });
        }

        //Continue.
        this.setState({
            FileToJson_Questions: _qsModal,
            FileToJson_Comprehension: null,
            FileToJson_FillInTheBlanks: null,
            UploadStatus: UploadState.Saving,
        }, () => {
            this.PopulatingJsonModalForFireStore();
            if (this.props.isDevMode)
                console.log(JSON.stringify(_qsModal));
        });
    }
    //=== Question Set === Create / New === end ===//


    ResetQuestionSetModal = () => {
        this.setState({
            NewQSet_IsPublic: false,
            NewQSet_IsPrivateGroup: false,
            NewQSet_Published: true,
            NewQSet_Name: '',
            NewQSet_Remark: '',
            NewQSet_TotalQuestion: 0,
            NewQSet_DisplayOrder: 0,
            NewQSet_Group: null,

            // //2021.07.23
            // NewQSet_SubjectId: 0,
            // NewQSet_SubjectName: '',
            NewQSet_Subject: null,      //2022.02.10

            uploadedFile: null,
            // fileToJson: null,        
            // fileConvertToJsonSuccess: null,
            // jsonForFireStore: null,
            // uploadToFireStoreSuccess: null,
            // questionSetModal: null,

            UploadStatus: UploadState.None,
            NewQSet_UniqueId: '',
            IsUploadConditionsFullfilled: false,
        });
    }

    // //2023.08.30
    // CheckNullValue = (val) => {
    //     if (val === '' || val === null || val === undefined || String(val) === 'null' || String(val) === 'undefined')
    //         return '';
    //     return val;
    // }

    //=== Question Set === Upload === start ===//
    ToggleUploadQuestionSetModal = () => {
        if (this.state.PA_Upload === false)
            return null;
        this.setState({
            Show_Upload_QuestionSetModal: !this.state.Show_Upload_QuestionSetModal
        }, async () => {
            if (this.state.Show_Upload_QuestionSetModal) {
                this.ResetQuestionSetModal();
            }
            else {
                if (this.state.Show_Create_QuestionSetModal) {
                    // await Delay(500);
                    this.setState({ Show_Create_QuestionSetModal: !this.state.Show_Create_QuestionSetModal });
                }
            }
        });
    }
    //Reset.
    ResetUploadQuestionSetModal = () => {
        this.ToggleUploadQuestionSetModal();
        setTimeout(() => {
            this.ToggleUploadQuestionSetModal();
        }, 300);
    }
    // On file select (from the pop up)
    onUploadFileChange = (event) => {
        if (this.state.PA_Upload === false)
            return null;
        // Update the state
        this.setState({ uploadedFile: event.target.files[0] }, () => this.CheckOnUploadConditions());
    };
    // UploadNewQuestionSet = () => {
    //     this.props.SetAlert('', '<b>Upload New Question Set currently not available.</b>', false);
    // }
    //2021.07.29
    CheckOnUploadConditions = () => {
        let _fullfilled = false;
        if (this.state.Show_Create_QuestionSetModal) {
            //Create New.
            if (
                Number(this.state.NewQSet_TotalQuestion) > 0
                && this.state.NewQSet_Name !== ''
                && this.state.NewQSet_Group !== null
                && this.state.NewQSet_Subject !== null      //2022.02.10
            ) {
                _fullfilled = true;
            }
        }
        else {
            //Create & Upload.
            if (
                Number(this.state.NewQSet_TotalQuestion) > 0
                && this.state.NewQSet_Name !== ''
                && this.state.NewQSet_Group !== null
                && this.state.NewQSet_Subject !== null      //2022.02.10
                && this.state.uploadedFile !== null
            ) {
                _fullfilled = true;
            }
        }
        this.setState({ IsUploadConditionsFullfilled: _fullfilled });
        // console.log(
        //     'TotalQuestion set = ' + String(Number(this.state.NewQSet_TotalQuestion) > 0) +
        //     '\nName set = ' + String(this.state.NewQSet_Name !== '') +
        //     '\nGroup set = ' + String(this.state.NewQSet_Group !== null) +
        //     '\nFile set = ' + String(this.state.uploadedFile !== null) +
        //     '\nFullfilled = ' + String(_fullfilled)
        // );
    }
    //=== Question Set === Upload === end ===//


    //2021.07.06
    SaveDataInput = async (_value, _inputType) => {
        let _selected = null;
        switch (_inputType) {
            default: break;
            case DataInput.Name:
                this.setState({ NewQSet_Name: _value });
                break;
            case DataInput.Remark:
                if (String(_value).length <= 250)
                    this.setState({ NewQSet_Remark: _value });
                else
                    this.NQSet_Remark.current.value = this.state.NewQSet_Remark;
                break;
            case DataInput.TotalQuestion:
                this.setState({ NewQSet_TotalQuestion: _value });
                break;
            case DataInput.DisplayOrder:
                this.setState({ DisplayOrder: _value });
                break;
            case DataInput.IsPublic:
                this.setState({ NewQSet_IsPublic: _value });
                break;
            case DataInput.IsPrivateGroup:
                this.setState({ NewQSet_IsPrivateGroup: _value });
                break;
            case DataInput.Published:
                this.setState({ NewQSet_Published: _value });
                break;
            case DataInput.Group:
                _selected = null;
                this.props.GroupOptions.map((data, key) => {
                    if (_value.value === data.value && _value.id === data.id)
                        _selected = data;
                    return null;
                });
                this.setState({ NewQSet_Group: _selected });
                break;
            case DataInput.Subject:
                // this.setState({ NewQSet_SubjectId: _value, NewQSet_SubjectName: '' });
                //2022.02.10
                _selected = null;
                this.props.SubjectOptions.map((data, key) => {
                    if (_value.value === data.value && _value.id === data.id)
                        _selected = data;
                    return null;
                });
                this.setState({ NewQSet_Subject: _selected });
                break;

            //2022.02.11
            case DataInput.SearchQsSet_ByGroup:
                _selected = null;
                this.props.GroupOptions.map((data, key) => {
                    if (_value.value === data.value && _value.id === data.id)
                        _selected = data;
                    return null;
                });
                // this.setState({ SearchQsSet_ByGroup: _selected });
                this.setState({ SearchQsSet_ByGroup: _selected }, () => this.Check_SearchQsSetCondition());
                break;
            case DataInput.SearchQsSet_BySubject:
                _selected = null;
                this.props.SubjectOptions.map((data, key) => {
                    if (_value.value === data.value && _value.id === data.id)
                        _selected = data;
                    return null;
                });
                // this.setState({ SearchQsSet_BySubject: _selected });
                this.setState({ SearchQsSet_BySubject: _selected }, () => this.Check_SearchQsSetCondition());
                break;
            case DataInput.SearchQsSet_MaxQtyShow:
                // this.setState({ SearchQsSet_MaxQtyShow: Number(_value) });
                this.setState({ SearchQsSet_MaxQtyShow: Number(_value) }, () => this.Check_SearchQsSetCondition());
                break;
            case DataInput.SearchQsSet_ByName:
                //2022.12.05
                if (_value === null || _value === undefined || _value === '')
                    _value = '';
                this.setState({ SearchQsSet_ByName: _value }, () => this.Check_SearchQsSetCondition());
                break;
        }
        // if (this.props.isDevMode)
        //     setTimeout(() => {
        //         console.log(
        //             "\nName : " + this.state.NewQSet_Name + "\n" +
        //             "Remark : " + this.state.NewQSet_Remark + "\n" +
        //             "Total Question : " + this.state.NewQSet_TotalQuestion + "\n" +
        //             "Display Order : " + this.state.NewQSet_DisplayOrder + "\n" +
        //             "Is Public : " + this.state.NewQSet_IsPublic + "\n" +
        //             "Is Private Group : " + this.state.NewQSet_IsPrivateGroup + "\n" +
        //             "Published : " + this.state.NewQSet_Published + "\n" +
        //             "Group : " + this.state.NewQSet_Group.label + " (" + this.state.NewQSet_Group.id + ") " + "\n"
        //         );
        //     }, 0);

        await Delay(500);
        this.CheckOnUploadConditions();
    }


    //#region disabled scripts.
    // //2021.07.09
    // getGroupOptions = () => {
    //     let _options = [];
    //     this.state.GroupList.map((data, key) => {
    //         return _options.push({ value: data.Name, label: data.Name, id: data.Id });
    //     });
    //     return _options;
    // }

    // //2021.07.29
    // CheckOnGroupList = async () => {
    //     if (this.state.GroupList.length <= 0) {
    //         let _List = [];
    //         await this.props.firestore
    //             .collection("QuizBank")
    //             .doc('QuizGroup')
    //             .collection('Items')
    //             // .where('CenterUserId', '==', _centerUserId)
    //             // .where('AuthorId', '==', _authorId)
    //             .orderBy('DisplayOrder', 'asc')
    //             // .limit(5)
    //             .get()
    //             .then(querySnapshot => {
    //                 let dataArray = [];
    //                 if (querySnapshot !== null) {
    //                     querySnapshot.forEach((doc) => {
    //                         dataArray.push(doc.data());
    //                     });
    //                     if (dataArray.length > 0) {
    //                         _List = dataArray;
    //                         // _List_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    //                         _List.sort((a, b) => moment(a.Id) - moment(b.Id));
    //                         _List.sort((a, b) => moment(a.DisplayOrder) - moment(b.DisplayOrder));
    //                     }
    //                 }
    //                 // if (this.props.isDevMode)
    //                 //     console.log(JSON.stringify(List));
    //             })
    //             .catch(error => {
    //                 console.log(JSON.stringify(error));
    //             });
    //         this.setState({ GroupList: _List, });
    //         await Delay(500);
    //     }
    //     this.PopulateGroupOptions();
    // }

    // //2021.07.29
    // PopulateGroupOptions = () => {
    //     if (this.state.GroupOptions.length <= 0) {
    //         let options = [];
    //         this.state.GroupList.map((data, key) => {
    //             return options.push({ value: data.Name, label: data.Name, id: data.Id });
    //         });
    //         this.setState({ GroupOptions: options });
    //     }
    // }
    //#endregion


    //=== Question Set === Process Uploaded === start ===//
    ToggleProcessUploadedQuestionSetModal = () => {
        this.setState({ Show_ProcessUploaded_QuestionSetModal: !this.state.Show_ProcessUploaded_QuestionSetModal });
    }
    ProcessQuestionSetModal = () => {

        if (this.state.uploadedFile === null || this.state.uploadedFile === undefined) {
            this.props.SetAlert('Upload Failed', 'Please select a question set before upload.', true);
            return null;
        }

        this.setState({
            // fileConvertToJsonSuccess: null,
            // uploadToFireStoreSuccess: null,
            NewQSet_UniqueId: '',
            // UploadStatus: UploadState.Converting,
            UploadStatus: UploadState.Validation,
        });
        this.ToggleProcessUploadedQuestionSetModal();
        let jsonModal_Questions = null;
        let jsonModal_Comprehension = null;
        let jsonModal_FillInTheBlanks = null;
        let reader = new FileReader();
        reader.onload = (event) => {
            /* Parse data */
            let bstr = event.target.result;
            let wb = XLSX.read(bstr, { type: "binary" });

            //=== All Questions === start ===//
            /* Get first worksheet */
            // let jsonModal_Questions = null;
            let wsname = wb.SheetNames[0];
            // console.log(wsname);
            if (wsname === 'Questions') {
                let ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                let jsonData = XLSX.utils.sheet_to_json(ws);
                let jsonStrings = JSON.stringify(jsonData);
                // jsonStrings = jsonStrings.replace(new RegExp(/\\r\\n/, 'g'), '<br/>');
                // jsonStrings = jsonStrings.replace(new RegExp(/\\r\\n/, 'g'), '\\n');
                jsonStrings = jsonStrings.replaceAll('\\r\\n', '<br/>');
                // if (this.props.isDevMode)
                //     console.log('Questions =\n' + jsonStrings);
                // jsonModal_Questions = JSON.parse(jsonStrings, (key, value) => { return value !== undefined && value !== null && value !== '' ? value : null; });
                jsonModal_Questions = JSON.parse(jsonStrings, (key, value) => { return CheckNullValue(value) !== null ? value : null; });    //2023.08.30
                if (this.props.isDevMode) {
                    // console.log('Questions =\n' + JSON.stringify(jsonModal_Questions));
                    console.log('Total Questions = ' + jsonModal_Questions.length);
                }
            }
            //=== All Questions === end ===//

            //2021.07.12
            //=== Comprehension, 2nd sheet === start ===//
            // let jsonModal_Comprehension = null;
            let wsname_comp = wb.SheetNames[1];
            // console.log(wsname_comp);
            if (wsname_comp === 'Comprehension') {
                let ws_comp = wb.Sheets[wsname_comp];
                /* Convert array of arrays */
                let jsonData_comp = XLSX.utils.sheet_to_json(ws_comp);
                let jsonStrings_comp = JSON.stringify(jsonData_comp);
                // jsonStrings_comp = jsonStrings_comp.replace(new RegExp(/\\r\\n/, 'g'), '<br/>');
                // jsonStrings_comp = jsonStrings_comp.replace(new RegExp(/\\r\\n/, 'g'), '\\n');
                jsonStrings_comp = jsonStrings_comp.replaceAll('\\r\\n', '<br/>');
                // if (this.props.isDevMode)
                //     console.log('Comprehension =\n' + jsonStrings_comp);
                // jsonModal_Comprehension = JSON.parse(jsonStrings_comp, (key, value) => { return value !== undefined && value !== null && value !== '' ? value : null });
                jsonModal_Comprehension = JSON.parse(jsonStrings_comp, (key, value) => { return CheckNullValue(value) !== null ? value : null });    //2023.08.30
                // if (this.props.isDevMode)
                //     console.log('Comprehension =\n' + JSON.stringify(jsonModal_Comprehension));
            }
            //=== Comprehension, 2nd sheet === end ===//

            //2021.07.21
            //=== FillInTheBlanks, 3rd sheet === start ===//
            // let jsonModal_FillInTheBlanks = null;
            let wsname_fitb = wb.SheetNames[2];
            // console.log(wsname_fitb);
            if (wsname_fitb === 'FillInTheBlanks') {
                let ws_fitb = wb.Sheets[wsname_fitb];
                /* Convert array of arrays */
                let jsonData_fitb = XLSX.utils.sheet_to_json(ws_fitb);
                let jsonStrings_fitb = JSON.stringify(jsonData_fitb);
                // jsonStrings_fitb = jsonStrings_fitb.replace(new RegExp(/\\r\\n/, 'g'), '<br/>');
                // jsonStrings_fitb = jsonStrings_fitb.replace(new RegExp(/\\r\\n/, 'g'), '\\n');
                jsonStrings_fitb = jsonStrings_fitb.replaceAll('\\r\\n', '<br/>');
                // if (this.props.isDevMode)
                //     console.log('FillInTheBlanks =\n' + jsonStrings_fitb);
                // jsonModal_FillInTheBlanks = JSON.parse(jsonStrings_fitb, (key, value) => { return value !== undefined && value !== null && value !== '' ? value : null });
                jsonModal_FillInTheBlanks = JSON.parse(jsonStrings_fitb, (key, value) => { return CheckNullValue(value) !== null ? value : null });  //2023.08.30
                // if (this.props.isDevMode)
                //     console.log('FillInTheBlanks =\n' + JSON.stringify(jsonModal_FillInTheBlanks));
            }
            //=== FillInTheBlanks, 3rd sheet === end ===//

            //2021.09.28
            // setTimeout(() => {
            //     console.log(JSON.stringify(jsonModal_Questions));
            //     console.log(JSON.stringify(jsonModal_Comprehension));
            //     console.log(JSON.stringify(jsonModal_FillInTheBlanks));
            //     this.QuestionSetValidation(jsonModal_Questions, jsonModal_Comprehension, jsonModal_FillInTheBlanks);
            // }, 1000);
            // let result = this.QuestionSetValidation(jsonModal_Questions, jsonModal_Comprehension, jsonModal_FillInTheBlanks);
            // if (result.IsValid === false) {
            //     jsonModal_Questions = null;
            //     jsonModal_Comprehension = null;
            //     jsonModal_FillInTheBlanks = null;
            //     this.props.SetAlert('Conversion Error', result.Messages.join('<br />'));
            //     this.ToggleProcessUploadedQuestionSetModal();
            // }
            // return null;

            // setTimeout(() => {
            //     if (jsonModal_Questions === null) {
            //         this.setState({
            //             //  fileConvertToJsonSuccess: false 
            //             UploadStatus: UploadState.ConvertFailed
            //         });
            //     }
            //     else {
            //         this.setState({
            //             FileToJson_Questions: jsonModal_Questions,
            //             FileToJson_Comprehension: jsonModal_Comprehension,
            //             FileToJson_FillInTheBlanks: jsonModal_FillInTheBlanks,
            //             // fileConvertToJsonSuccess: true
            //             // UploadStatus: UploadState.Uploading,
            //         }, async () => {
            //             await this.QuestionSetValidation(
            //                 this.state.FileToJson_Questions,
            //                 this.state.FileToJson_Comprehension,
            //                 this.state.FileToJson_FillInTheBlanks
            //             );

            //             // this.PopulatingJsonModalForFireStore();

            //             // if (this.props.isDevMode)
            //             //     console.log(JSON.stringify(this.state.FileToJson_Questions));
            //         });
            //     }
            // }, 1000);
        };
        reader.readAsBinaryString(this.state.uploadedFile);

        //2021.09.29 - moved to outer event func.
        setTimeout(() => {
            if (jsonModal_Questions === null) {
                this.setState({
                    //  fileConvertToJsonSuccess: false 
                    UploadStatus: UploadState.ConvertFailed
                });
            }
            else {
                this.setState({
                    FileToJson_Questions: jsonModal_Questions,
                    // FileToJson_Comprehension: jsonModal_Comprehension,
                    // FileToJson_FillInTheBlanks: jsonModal_FillInTheBlanks,
                    FileToJson_Comprehension: jsonModal_Comprehension === null ? []
                        : jsonModal_Comprehension.length <= 0 ? []
                            : jsonModal_Comprehension[0].Id === null ? [] : jsonModal_Comprehension,
                    FileToJson_FillInTheBlanks: jsonModal_FillInTheBlanks === null ? []
                        : jsonModal_FillInTheBlanks.length <= 0 ? []
                            : jsonModal_FillInTheBlanks[0].Id === null ? [] : jsonModal_FillInTheBlanks,
                    // fileConvertToJsonSuccess: true
                    // UploadStatus: UploadState.Uploading,
                }, () => {
                    this.QuestionSetValidation(
                        this.state.FileToJson_Questions,
                        this.state.FileToJson_Comprehension,
                        this.state.FileToJson_FillInTheBlanks
                    );

                    // this.PopulatingJsonModalForFireStore();

                    // if (this.props.isDevMode)
                    //     console.log(JSON.stringify(this.state.FileToJson_Questions));
                });
            }
        }, 1000);
    }
    //2021.09.28
    QuestionSetValidation = (questions, comprehension, fillInTheBlanks) => {
        // this.setState({
        //     UploadStatus: UploadState.Validation,
        // });

        let _isValid = true;
        let _messages = [];

        let _hasComprehension = false;
        let _hasFillInTheBlanks = false;

        let _comprehensions = [];
        let _fillInTheBlanks = [];

        if (this.props.isDevMode) {
            // console.log(this.state.uploadedFile !== null);
            console.log('\nquestions =\n' + JSON.stringify(questions));
            console.log('\ncomprehension =\n' + JSON.stringify(comprehension));
            console.log('\nfillInTheBlanks =\n' + JSON.stringify(fillInTheBlanks));
        }

        //2021.09.30
        let _ignoreChecking = false;
        // let _temp = [];
        // let _start_QsNo = 0;
        // let _end_QsNo = 0;

        if (questions !== null) {
            //validating <Questions>.
            questions.map((data, key) => {
                //re-init.
                _ignoreChecking = false;
                // _temp = [];
                // _start_QsNo = 0;
                // _end_QsNo = 0;

                //found <Comprehension>.
                if (data.QuizType === 'Comprehension') {      //  'Comprehension;13,16;4;Content_13_16;'
                    _comprehensions.push(key + 1);
                    if (_hasComprehension === false)
                        _hasComprehension = true;
                }
                //found <FillInTheBlanks>.
                if (data.QuizType === 'FillInTheBlanks') {      //  'FillInTheBlanks;17,20;4;Content_17_20;'
                    _fillInTheBlanks.push(key + 1);
                    if (_hasFillInTheBlanks === false)
                        _hasFillInTheBlanks = true;

                    // if (data.hasOwnProperty('SpecialMode')) {
                    //     _temp = String(data.SpecialMode).split(';');
                    //     _start_QsNo = Number(_temp[1].split(',')[0]);
                    //     _end_QsNo = Number(_temp[1].split(',')[1]);
                    //     if (data.Id > _start_QsNo && data.Id <= _end_QsNo) {
                    //         _ignoreChecking = true;
                    //     }
                    // }

                    if (fillInTheBlanks.length > 0) {
                        fillInTheBlanks.map((setData, setKey) => {
                            if (_ignoreChecking === false)
                                if (setData.Start >= data.Id || setData.End <= data.Id)
                                    _ignoreChecking = true;
                            return null;
                        });
                    }
                }

                //for QuizType = Objective/Comprehension/Subjective.
                if (_ignoreChecking === false) {
                    //check Content.
                    if (data.Content === undefined) {
                        _isValid = false;
                        _messages.push('undefined content for qs #' + (key + 1) + '.');
                    }
                    //check Answer.
                    if (data.Answer === undefined) {
                        _isValid = false;
                        _messages.push('undefined answer for qs #' + (key + 1) + '.');
                    }
                    //loop & check answer options' content for reserved symbol. (e.g. ":" & ";")
                    [
                        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
                        'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
                        'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
                    ].map((opt, key) => {
                        // if (CheckObjectNullValue(data, 'opt') !== null) {
                        if (data[opt] !== undefined) {
                            let text = String(data[opt]);
                            if (text.includes(':') || text.includes(';')) {
                                data[opt] = text.replaceAll(':', '：').replaceAll(';', '；');   //2024.01.31
                                // _isValid = false;
                                // _messages.push('<b>invalid symbol found in answer option &lt;' + opt + '&gt; for qs #' + (key + 1) + '.</b>');
                            }
                        }
                        return null;
                    });
                }
                return null;
            });

            if (_isValid) {
                //validating <Comprehension>.
                if (_hasComprehension) {
                    if (comprehension !== null) {
                        if (comprehension.length > 0) {
                            comprehension.map((data, key) => {
                                if (comprehension.Passage === undefined) {
                                    _isValid = false;
                                    _messages.push('undefined passage in comprehension modal.');
                                }
                                if (_comprehensions.findIndex(x => x === data.Start) < 0) {
                                    _isValid = false;
                                    _messages.push('Qs-No-Start &lt;' + data.Start + '&gt; is not found in comprehension modal.');
                                }
                                if (_comprehensions.findIndex(x => x === data.End) < 0) {
                                    _isValid = false;
                                    _messages.push('Qs-No-End &lt;' + data.End + '&gt; is not found in comprehension modal.');
                                }
                                return null;
                            });
                        }
                    }
                    else {
                        _isValid = false;
                        _messages.push('invalid comprehensions array conversion.');
                    }
                }
                //validating <FillInTheBlanks>.
                if (_hasFillInTheBlanks) {
                    if (fillInTheBlanks !== null) {
                        if (fillInTheBlanks.length > 0) {
                            fillInTheBlanks.map((data, key) => {
                                if (_fillInTheBlanks.findIndex(x => x === data.Start) < 0) {
                                    _isValid = false;
                                    _messages.push('Qs-No-Start &lt;' + data.Start + '&gt; is not found in fillInTheBlanks modal.');
                                }
                                if (_fillInTheBlanks.findIndex(x => x === data.End) < 0) {
                                    _isValid = false;
                                    _messages.push('Qs-No-End &lt;' + data.End + '&gt; is not found in fillInTheBlanks modal.');
                                }
                                return null;
                            });
                        }
                    }
                    else {
                        _isValid = false;
                        _messages.push('invalid fillInTheBlanks array conversion.');
                    }
                }
            }

            // if (this.props.isDevMode)
            //     console.log('isValid = ' + _isValid + '\nmessages = ' + _messages.join('\n'));
        }
        else {
            _isValid = false;
            _messages.push('invalid questions array conversion.');
        }
        // return { IsValid: _isValid, Messages: _messages };
        // _isValid = false;    //testing false case.
        if (_isValid === false) {
            this.setState({
                //  fileConvertToJsonSuccess: false 
                UploadStatus: UploadState.ConvertFailed
            });
            this.props.SetAlert('File Validation Failed', _messages.join('<br />'));
            this.ToggleProcessUploadedQuestionSetModal();
        }
        else {
            this.PopulatingJsonModalForFireStore();

            if (this.props.isDevMode)
                console.log('file conversion & validation success.\nisValid = ' + _isValid + '\nerror messages = ' + _messages.join('\n'));
        }
    }
    // ConvertToJson = (csv) => {
    //     var lines = csv.split("\n");
    //     var result = [];
    //     var headers = lines[0].split(",");
    //     for (var i = 1; i < lines.length; i++) {
    //         var obj = {};
    //         var currentline = lines[i].split(",");
    //         for (var j = 0; j < headers.length; j++) {
    //             obj[headers[j]] = currentline[j];
    //         }
    //         result.push(obj);
    //     }
    //     return result; //JavaScript object
    //     // return JSON.stringify(result); //JSON
    // }
    PopulatingJsonModalForFireStore = () => {
        // this.ListeningToCreationEventOfTheQuestionSet();   //start listening.
        // this.setState({
        //     // fileConvertToJsonSuccess: true
        //     UploadStatus: UploadState.Converting,
        // });
        let qsModal = [];
        //Convert to Keyed Json format.
        this.state.FileToJson_Questions.map((data, key) => {
            let _No = key + 1;
            qsModal.push({
                No: _No,
                // // Content: data.hasOwnProperty('Content') ? data.Content : '',
                // Tags: data.hasOwnProperty('Tags') ? CheckObjectStringEmpty(data, 'Tags') : '',
                // // Hints: data.hasOwnProperty('Hints') ? data.Hints : '',
                // // Answer: data.hasOwnProperty('Answer') ? data.Answer : '',
                // // PictureUrl: data.hasOwnProperty('PictureUrl') ? data.PictureUrl : '',
                // QuizType: data.hasOwnProperty('QuizType') ? data.QuizType : 'Objective',
                // // Selection: this.PopulateSelections(data),
                // // SpecialMode: data.hasOwnProperty('SpecialMode') ? data.SpecialMode : null,

                //2023.10.19
                Tags: CheckObjectStringEmpty(data, 'Tags'),
                QuizType: CheckObjectStringEmpty(data, 'QuizType'),
            });
            if (data.hasOwnProperty('QuizType')) {
                if (data.QuizType === 'Objective' || data.QuizType === 'Comprehension') {
                    // qsModal[key].Content = data.hasOwnProperty('Content') ? data.Content : '';
                    // qsModal[key].Hints = data.hasOwnProperty('Hints') ? CheckObjectStringEmpty(data, 'Hints') : '';
                    // qsModal[key].Answer = data.hasOwnProperty('Answer') ? data.Answer : '';
                    // qsModal[key].PictureUrl = data.hasOwnProperty('PictureUrl') ? data.PictureUrl : '';
                    // qsModal[key].Selection = this.PopulateSelections(data);

                    //2023.10.19
                    qsModal[key].Content = CheckObjectStringEmpty(data, 'Content');
                    qsModal[key].Hints = CheckObjectStringEmpty(data, 'Hints');
                    qsModal[key].Answer = CheckObjectStringEmpty(data, 'Answer');
                    qsModal[key].PictureUrl = CheckObjectStringEmpty(data, 'PictureUrl');
                    qsModal[key].Selection = this.PopulateSelections(data);

                    if (data.QuizType === 'Comprehension') {
                        let _index = this.state.FileToJson_Comprehension.findIndex(x => _No >= x.Start && _No <= x.End);
                        if (_index > -1) {
                            let com = this.state.FileToJson_Comprehension[_index];
                            qsModal[key].SpecialMode = 'Comprehension;' + com.Start + ',' + com.End + ';' + (com.End - com.Start + 1) + ';Content_' + com.Start + '_' + com.End + ';';
                        }
                    }
                }
                else if (data.QuizType === 'FillInTheBlanks') {
                    let _index = this.state.FileToJson_FillInTheBlanks.findIndex(x => _No >= x.Start && _No <= x.End);
                    if (_index > -1) {
                        let com = this.state.FileToJson_FillInTheBlanks[_index];
                        if (com.Start === _No) {
                            qsModal[key].Content = data.hasOwnProperty('Content') ? data.Content : '';
                            qsModal[key].Hints = data.hasOwnProperty('Hints') ? CheckObjectStringEmpty(data, 'Hints') : '';
                            qsModal[key].Answer = data.hasOwnProperty('Answer') ? data.Answer : '';
                            qsModal[key].PictureUrl = data.hasOwnProperty('PictureUrl') ? data.PictureUrl : '';
                            qsModal[key].Selection = this.PopulateSelections(data);
                        }
                        qsModal[key].SpecialMode = 'FillInTheBlanks;' + com.Start + ',' + com.End + ';' + (com.End - com.Start + 1) + ';Content_' + com.Start + '_' + com.End + ';';
                    }
                }
            }
            return null;
        });
        this.SendQuestionSetModalToCMS(qsModal);
        // this.props.SetAlert('', JSON.stringify(qsModal), false);
        // console.log(JSON.stringify(qsModal));
    }
    PopulateSelections = (array) => {
        let _selections = '';
        [
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
            'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
            'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
        ].map((data, key) => {
            //2023.10.19
            if (CheckObjectNullValue(array, data) !== null)
                _selections += data + ':' + CheckObjectStringEmpty(array, data) + ';';
            // if (array.hasOwnProperty(data))
            //     if (array[data] !== undefined || array[data] !== null)
            //         _selections += data + ':' + array[data] + ';';
            return null;
        });
        return _selections;
    }
    SendQuestionSetModalToCMS = async (qsModal) => {
        // // console.log(JSON.stringify(qsModal));
        // this.props.SetAlert('', JSON.stringify(qsModal), false);
        // // console.log(JSON.stringify(qsModal));
        // return null;
        this.setState({
            UploadStatus: UploadState.Uploading,
            UploadStatusText: '<span>File Conversion & Validation Success.<br /><br />Now Uploading & Saving...</span>',
        }, async () => {
            //2021.10.05 - set UploadStatus.
            if (this.props.user !== null) {
                await this.props.dbQuizBank
                    .ref(this.props.user.uid + '/UploadStatus')
                    .set(this.state.UploadStatusText);
                await this.Remove_ListenToUploadStatus();
                this.ListenToUploadStatus();
            }
        });
        await Delay(1500);

        //2023.09.09
        const { uid, centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log('SendQuestionSetModalToCMS', centerUserId, authorId, authorRoleId, organizerId);

        let isUploadDone = false;
        let errorMessage = '';
        let apiResponse = null;

        let jsonModel = {
            OrganizerId: organizerId,       //2023.09.09
            CenterUserId: centerUserId,     //this.props.user.CenterUserId,
            AuthorId: authorId,             //this.props.user.AuthorId,
            AuthorRoleId: authorRoleId,     //this.props.user.AuthorRoleId,    //1 = admin, 4 = center, 11 = Author
            FirebaseUserId: uid,

            Name: this.state.NewQSet_Name,
            Remark: this.state.NewQSet_Remark,
            // TotalQuestion: this.state.NewQSet_TotalQuestion,
            TotalQuestion: qsModal.length > this.state.NewQSet_TotalQuestion ? qsModal.length : Number(this.state.NewQSet_TotalQuestion),   //2021.09.29
            GroupId: this.state.NewQSet_Group.id,   //small letter id. key from GroupOptions
            GroupName: this.state.NewQSet_Group.value,   //small letter value.

            //2022.02.10
            SubjectId: this.state.NewQSet_Subject !== null ? this.state.NewQSet_Subject.id : 0,
            SubjectName: this.state.NewQSet_Subject !== null ? this.state.NewQSet_Subject.value : '',

            IsPublic: this.state.NewQSet_IsPublic,
            IsPrivateGroup: this.state.NewQSet_IsPrivateGroup,
            DisplayOrder: this.state.NewQSet_DisplayOrder,
            Published: this.state.NewQSet_Published,

            // JsonModel: JSON.stringify(qsModal),
            // ComprehensionModel: this.state.FileToJson_Comprehension !== null ? JSON.stringify(this.state.FileToJson_Comprehension) : '',
            // FillInTheBlanksModel: this.state.FileToJson_FillInTheBlanks !== null ? JSON.stringify(this.state.FileToJson_FillInTheBlanks) : '',
            QuestionModel: qsModal,
            ComprehensionModel: this.state.FileToJson_Comprehension,
            FillInTheBlanksModel: this.state.FileToJson_FillInTheBlanks,
        };
        if (this.props.isDevMode)
            console.log('jsonModel =\n' + JSON.stringify(jsonModel));
        // return null;

        let done = false;
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/QuestionSet/Upload',
            {
                method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                // mode: 'cors',                            // no-cors, *cors, same-origin
                // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin',                 // include, *same-origin, omit
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // redirect: 'follow',                         // manual, *follow, error
                // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(jsonModel), // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then(data => {
                isUploadDone = data.success;
                if (data.success)
                    apiResponse = data;
                else
                    errorMessage = 'api upload - question set - failed.\n' + JSON.stringify(data);
                done = true;
            })
            .catch(error => {
                errorMessage = 'Error : ' + error.message;  //JSON.stringify(error);
                done = true;
            });
        await DelayUntil(() => done === true);

        await Delay(1000);
        if (isUploadDone === false) {
            this.setState({ UploadStatus: UploadState.Failed, UploadStatusText: errorMessage, });
        }
        else {
            this.setState({
                UploadStatus: UploadState.Success,
                NewQSet_UniqueId: apiResponse.data,
            }, async () => {
                // //2021.10.05
                // await this.Remove_ListenToUploadStatus();
            });
        }
        await this.Remove_ListenToUploadStatus();
        if (this.props.isDevMode)
            console.log(errorMessage.length > 0 ? errorMessage : JSON.stringify(apiResponse));
    }
    UploadStatusMessage = () => {
        if (this.state.UploadStatus !== UploadState.None)
            switch (this.state.UploadStatus) {
                // case UploadState.Converting:
                //     return (<ProgressBar animated now={100} className='progressbar1' />);
                case UploadState.Validation:
                    return (<>
                        <span>Processing File Conversion & Validation...</span>
                        <br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                    </>);
                case UploadState.Uploading:
                    return (<>
                        {/* <span>File Conversion & Validation Success.<br /><br />Now Uploading & Saving...</span> */}
                        <div dangerouslySetInnerHTML={{ __html: this.state.UploadStatusText }} />
                        {/* <br /> */}
                        <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                    </>);
                case UploadState.Saving:
                    return (<>
                        <span>Now Saving...</span>
                        <br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                    </>);
                case UploadState.ConvertFailed:
                    return (<span>File Conversion & Validation Failed.</span>);
                case UploadState.Success:
                    return (<>
                        {/* Upload Success. */}
                        <div dangerouslySetInnerHTML={{ __html: this.state.UploadStatusText }} />
                        {/* <br /> */}
                        <span style={{ fontSize: 12, color: 'gray' }}>{this.state.NewQSet_UniqueId}</span>
                    </>);
                case UploadState.Failed:
                    return (<span>Upload Failed.<br />{this.state.UploadStatusText}</span>);
                default:
                    return null;
            }
        return null;
    }
    ReloadQuestionSetList = () => {
        this.setState({
            QuestionSetList: [],
            QuestionSetList_FetchDone: false,
        }, () => {
            this.ToggleProcessUploadedQuestionSetModal();   //close ui.
            this.ToggleUploadQuestionSetModal();            //close ui.
            // this.LoadQuestionSetList();                     //reload list.
            this.LoadQuestionSetList_ViaApi();
        });
    }
    //2021.10.05
    ListenToUploadStatus = () => {
        if (this.props.user !== null) {
            this.props.dbQuizBank
                .ref(this.props.user.uid + '/UploadStatus')
                .on('value', snapshot => {
                    // handle read data.
                    if (snapshot.exists()) {
                        let data = snapshot.val();
                        if (data !== '' && data !== undefined && data !== null) {
                            this.setState({ UploadStatusText: String(data), });
                        }
                        if (this.props.isDevMode)
                            console.log('Upload Status =\n' + String(data));
                    }
                });
        }
    }
    //2021.10.05
    Remove_ListenToUploadStatus = async () => {
        if (this.props.user !== null) {
            //Off listener.
            await this.props.dbQuizBank
                .ref(this.props.user.uid + '/UploadStatus')
                .off();
            //Remove status text.
            await this.props.dbQuizBank
                .ref(this.props.user.uid + '/UploadStatus')
                .remove();
        }
    }
    //entry point appeared after upload is success.
    GotoEditQuestionSetPage = (_UniqueId) => {
        this.props.TogglePage(this.props.Toggle.EditQuestionSet, _UniqueId);
    }
    //=== Question Set === Process Uploaded === end ===//


    //=== Question Set === Load List === start ===//
    LoadQuestionSetList_ViaApi = async () => {
        //2023.11.24
        if (this.props.user === null)
            return null;

        this.setState({
            TotalRows: 0,
            QuestionSetList: [],
            QuestionSetList_FetchDone: false,
            PageSize: Number(this.state.ListMaxQueryQty) < 5 ? 5 : Number(this.state.ListMaxQueryQty),     //2023.11.24
        });

        let _List = [];
        let totalRows = 0;

        const { authorId, organizerId } = GetPropIds(this.props);

        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Quiz/QuestionSet/List/'
            + organizerId + '/'
            + authorId + '/'
            + 0 + '/'
            + 0 + '/'
            + this.state.PageIndex + '/'
            + this.state.PageSize,
            // Api/LearningCentre/Quiz/QuestionSet/List/{organizerId}/{authorId}/{groupId}/{subjectId}/{pageIndex}/{pageSize}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('LoadQuestionSetList_ViaApi (source)', JSON.stringify(data));
                if (data.success) {
                    _List = data.data.list;
                    totalRows = CheckObjectNumber(data.data, 'totalRows', _List.length);     //2023.11.24
                }
                else {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - qSet list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - qSet list (failed)\n' + error.message);
            });

        if (_List.length > 0) {
            //Finalize list.
            for (let i = 0; i < _List.length; i++) {
                _List[i] = CapitalizeJsonKeys(_List[i]);
            }
            // console.log('LoadQuestionSetList_ViaApi (CapitalizeJsonKeys)', JSON.stringify(_List));
            _List = FormatList_QuestionSet(this.props, _List);
            if (this.props.isDevMode)
                console.log('LoadQuestionSetList_ViaApi (final)', JSON.stringify(_List));
        }

        this.setState({
            TotalRows: totalRows,
            QuestionSetList: _List,
            // QuestionSetList_lastVisible: _List_lastVisible,
            QuestionSetList_FetchDone: true,
        });
    }
    // //moved to GlobaleFunctions - 2023.11.27
    // FormatList_QuestionSet = (_List = []) => {
    //     //2023.11.24
    //     if (_List.length > 0) {
    //         _List.map((data, key) => {
    //             if (CheckNullValue(data) !== null) {

    //                 //2023.11.23
    //                 if (data.hasOwnProperty('SubjectId')) {
    //                     const findIndex = this.props.SubjectOptions.findIndex(x => Number(x.id) === CheckObjectNumber(data, 'SubjectId'));
    //                     if (findIndex > -1)
    //                         data['Subject'] = { Id: this.props.SubjectOptions[findIndex].id, Name: this.props.SubjectOptions[findIndex].value, Label: this.props.SubjectOptions[findIndex].label };     //default
    //                 }
    //                 if (data.hasOwnProperty('Subject') === false)
    //                     data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value, Label: this.props.SubjectOptions[0].label };     //default
    //                 else
    //                     if (CheckObjectNumber(data.Subject, 'Id') === 0)
    //                         data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value, Label: this.props.SubjectOptions[0].label };     //default

    //                 //2023.11.23
    //                 if (data.hasOwnProperty('GroupId')) {
    //                     const findIndex = this.props.GroupOptions.findIndex(x => Number(x.id) === CheckObjectNumber(data, 'GroupId'));
    //                     if (findIndex > -1)
    //                         data['Group'] = { Id: this.props.GroupOptions[findIndex].id, Name: this.props.GroupOptions[findIndex].value };     //default
    //                 }
    //             }
    //             return data;
    //         });
    //     }
    //     return _List;
    // }
    //#region === Paging Components
    //2023.12.07
    CallbackFunctionForPagingComponents_PageSize = (pageSize = 5) => {
        this.setState({
            ListMaxQueryQty: pageSize,
        }, () => {
            localStorage.setItem('ManageQuestionSet_List_MaxQueryQty_' + this.props.user.uid, this.state.ListMaxQueryQty);
            setTimeout(() => {
                this.LoadQuestionSetList_ViaApi();
            }, 500);
        });
    }
    //2023.12.07
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadQuestionSetList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components

    LoadQuestionSetList = async () => {
        if (this.props.user === null)
            return null;

        // let _centerUserId = Number(this.props.user.CenterUserId);
        // let _authorId = Number(this.props.user.AuthorId);
        // console.log('CenterUserId : ' + _centerUserId);
        // console.log('AuthorId : ' + _authorId);

        // console.log(JSON.stringify(this.props.user));

        // let _current = moment().format('YYYY-MM-DD hh:mm:ss A');
        // console.log(_current);

        let _List_lastVisible = null;
        let _List = [];

        //#region old codes
        // await this.props.firestore
        //     .collection("QuizBank")
        //     .doc('QuizQuestionSet')
        //     .collection('QuizQuestionSets')
        //     .where('CenterUserId', '==', _centerUserId)
        //     .where('AuthorId', '==', _authorId)
        //     // .where('CreatedOnUtc', '>', _current)
        //     .orderBy('CreatedOnUtc', 'desc')
        //     .limit(Number(this.state.ListMaxQueryQty))
        //     .get()
        //     .then(querySnapshot => {
        //         let dataArray = [];
        //         if (querySnapshot !== null) {
        //             querySnapshot.forEach((doc) => {
        //                 dataArray.push(doc.data());
        //             });
        //             if (dataArray.length > 0) {
        //                 _List = dataArray;
        //                 _List_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        //             }
        //         }
        //         // if (this.props.isDevMode)
        //         //     console.log(JSON.stringify(List));
        //     })
        //     .catch(error => {
        //         if (this.props.isDevMode)
        //             console.log(error.message);
        //     });
        //#endregion old codes

        //2023.09.09
        // const organizerId = 0;
        const { centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log('LoadQuestionSetList', centerUserId, authorId, authorRoleId, organizerId);

        //2021.11.17 - revamped.
        let docRef = this.props.firestore.collection("QuizBank").doc('QuizQuestionSet').collection('QuizQuestionSets');
        if (this.props.isSuperAdmin) {
            //do nothing, retrive all question sets.
        }
        else {
            if (organizerId > 0) {
                //2023.09.09
                docRef = docRef
                    .where('OrganizerId', '==', organizerId)
            }
            else {
                docRef = docRef
                    .where('CenterUserId', '==', centerUserId)
                    .where('AuthorId', '==', authorId);
                // .where('CreatedOnUtc', '>', _current)
            }
        }
        await docRef.orderBy('CreatedOnUtc', 'desc')
            .limit(Number(this.state.ListMaxQueryQty))
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                    });
                    if (dataArray.length > 0) {
                        _List = dataArray;
                        _List_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    }
                }
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(List));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });

        //2022.02.11
        if (_List.length > 0) {
            _List.map((data, key) => {
                if (data.hasOwnProperty('Subject') === false)
                    data['Subject'] = { Id: 0, Name: '-' };     //default
                else
                    if (Number(data.Subject['Id']) === 0 || data.Subject['Id'] === undefined)
                        data['Subject'] = { Id: 0, Name: '-' };     //default
                return null;
            });
        }

        this.setState({
            QuestionSetList: _List,
            QuestionSetList_lastVisible: _List_lastVisible,
            QuestionSetList_FetchDone: true,
        });
    }
    QuestionSetItemComponents = () => {
        if (this.state.QuestionSetList.length > 0) {
            let listItems = [];
            this.state.QuestionSetList.map((data, key) => {
                return listItems.push(<tr key={'qsList' + key}>
                    <td>{key + 1 + this.state.PageIndex}</td>
                    <td align='left'>
                        <button
                            className='btn btn-primary'
                            onClick={() => this.GotoEditQuestionSetPage(data.UniqueId)}
                            style={{ textAlign: 'justify' }}
                        >{data.Name}</button>
                    </td>
                    <td>{data.hasOwnProperty('Group') ? (data.Group !== null ? data.Group.Name : '-') : '-'}</td>
                    <td>{data.hasOwnProperty('Subject') ? (data.Subject !== null ? data.Subject.Name : '-') : '-'}</td>
                    <td>{data.TotalQuestion}</td>
                    {/* <td>{data.IsPublic ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}</td>
                    <td>{data.Published ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}</td> */}
                    {/* <td>{String(data.Remark).length > 10 ? String(data.Remark).substring(0, 10) + '...' : data.Remark}</td> */}
                    <td>{
                        String(data.Remark).length > 0 ?
                            <button
                                className='btn btn-secondary'
                                onClick={() => this.props.SetAlert(data.Name, '<b><u>Remark :</u></b><br /><br /><span style="padding:20px">' + data.Remark + '</span>')}
                            >View</button>
                            : 'N/A'
                    }</td>
                    <td>{
                        moment.utc(data.CreatedOnUtc).local().format('lll')
                        // String(moment(data.CreatedOnUtc) > moment())
                    }</td>
                </tr>);
            });
            return listItems;
        }
        return this.state.QuestionSetList_FetchDone ?
            <tr><td colSpan='15' align='center'>list is empty</td></tr>
            : <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>;
    }
    LoadMoreQuestionSetList = async () => {
        this.setState({
            QuestionSetList_More_isLoading: true,
            QuestionSetList_FetchDone: false,
            previousQSetListQty: this.state.QuestionSetList.length,     //2021.11.17
        });
        let _centerUserId = Number(this.props.user.CenterUserId);
        let _authorId = Number(this.props.user.AuthorId);
        let _List = [];
        let _temp_List = this.state.QuestionSetList;
        let _List_lastVisible = null;

        //#region old codes
        // await this.props.firestore
        //     .collection("QuizBank")
        //     .doc('QuizQuestionSet')
        //     .collection('QuizQuestionSets')
        //     .where('CenterUserId', '==', _centerUserId)
        //     .where('AuthorId', '==', _authorId)
        //     .orderBy("CreatedOnUtc", "desc")
        //     .startAfter(this.state.QuestionSetList_lastVisible)
        //     .limit(Number(this.state.ListMaxQueryQty))
        //     .get()
        //     .then((querySnapshot) => {
        //         let dataArray = [];
        //         if (querySnapshot !== null) {
        //             querySnapshot.forEach((doc) => { dataArray.push(doc.data()); });
        //             // console.log(JSON.stringify(dataArray));
        //             if (dataArray.length > 0) {
        //                 _List = dataArray;
        //                 _List_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        //             }
        //         }
        //     })
        //     .catch(error => {
        //         if (this.props.isDevMode)
        //             console.log(error.message);
        //     });
        //#endregion old codes

        //2023.09.09
        const { centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log('LoadMoreQuestionSetList', centerUserId, authorId, authorRoleId, organizerId);

        //2021.11.17 - revamped.
        let docRef = this.props.firestore.collection("QuizBank").doc('QuizQuestionSet').collection('QuizQuestionSets');
        if (this.props.isSuperAdmin) {
            //do nothing, retrive all question sets.
        }
        else {
            if (organizerId > 0) {
                //2023.09.09
                docRef = docRef
                    .where('OrganizerId', '==', organizerId)
            }
            else {
                docRef = docRef
                    .where('CenterUserId', '==', _centerUserId)
                    .where('AuthorId', '==', _authorId);
            }
        }
        await docRef.orderBy("CreatedOnUtc", "desc")
            .startAfter(this.state.QuestionSetList_lastVisible)
            .limit(Number(this.state.ListMaxQueryQty))
            .get()
            .then((querySnapshot) => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => { dataArray.push(doc.data()); });
                    // console.log(JSON.stringify(dataArray));
                    if (dataArray.length > 0) {
                        _List = dataArray;
                        _List_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    }
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });


        if (_List.length > 0) {
            // _temp_List = this.state.QuestionSetList;
            _List.map((data, key) => {
                return _temp_List.push(data);
            });
            _temp_List.sort((a, b) => moment(b.CreatedOnUtc) - moment(a.CreatedOnUtc));
        }
        this.setState({
            QuestionSetList: _temp_List,
            QuestionSetList_lastVisible: _List.length > 0 ? _List_lastVisible : this.state.QuestionSetList_lastVisible,
            QuestionSetList_More_isLoading: false,
            QuestionSetList_FetchDone: true,
        }, () => {
            if (this.state.previousQSetListQty < this.state.QuestionSetList.length)
                ScrollToElement('bottomPage', 500);
        });
    }
    //=== Question Set === Load List === end ===//


    //2022.02.11
    //=== Question Set === Search === start ===//
    Toggle_Search_QuestionSetModal = (resetCache = true) => {
        if (this.state.PA_Search === false)
            return null;
        this.setState({
            ShowSearchQuestionSetModal: !this.state.ShowSearchQuestionSetModal,
        }, () => {
            if (this.state.ShowSearchQuestionSetModal) {

                //reset
                this.setState({
                    SearchQsSet_Processing: false,
                    // SearchQsSet_ByGroup: null,              //standard
                    // SearchQsSet_BySubject: null,            //subject
                    // SearchQsSet_MaxQtyShow: 5,              //max result
                    IsSearchQsSetConditionsValid: false,
                    IsSearchConditionsSelected: false,
                    SearchQsSet_Result_List: [],
                    SearchQsSet_Result_List_lastVisible: null,
                    //Select from Search Result.
                    ShowSelectQuestionSetModal: false,
                    IsSearchQsSetSelected: false,
                    SearchQsSet_QuestionSet_Selected: null,
                });
            }

            //reset by condition.
            if (resetCache) {
                this.setState({
                    SearchQsSet_ByGroup: null,              //standard
                    SearchQsSet_BySubject: null,            //subject
                    SearchQsSet_MaxQtyShow: 5,              //max result
                    SearchQsSet_ByName: '',
                });
            }

            //check.
            this.Check_SearchQsSetCondition();
        });
    }
    Check_SearchQsSetCondition = () => {
        this.setState({
            IsSearchQsSetConditionsValid:
                // this.state.SearchQsSet_ByGroup === null
                //     || this.state.SearchQsSet_BySubject === null
                //     || 
                Number(this.state.SearchQsSet_MaxQtyShow) <= 0
                    ? false : true,
        });
    }
    SearchQuestionSetByConditions = async () => {
        if (this.props.user === null)
            return null;

        if (this.state.PA_Search === false)
            return null;

        this.setState({ SearchQsSet_Processing: true });

        // //init.
        // let _List_lastVisible = null;
        // let _List = [];

        // let _centerUserId = Number(this.props.user.CenterUserId);
        // let _authorId = Number(this.props.user.AuthorId);

        //query.
        let { _List, _List_lastVisible } = await this.SearchViaApi();
        // let { _List, _List_lastVisible } = await this.SearchViaApi(_centerUserId, _authorId);
        // let { _List, _List_lastVisible } = this.state.SearchQsSet_ByName === null
        //     || this.state.SearchQsSet_ByName === undefined
        //     || this.state.SearchQsSet_ByName === '' ?
        //     await this.SearchViaFirebase(_centerUserId, _authorId)
        //     :
        //     await this.SearchViaApi(_centerUserId, _authorId);

        //2022.02.14
        _List = this.FormatList_Subject(_List);
        if (this.props.isDevMode)
            console.log('qSet list result =\n' + JSON.stringify(_List));

        this.setState({
            SearchQsSet_Processing: false,
            SearchQsSet_Result_List: _List,
            SearchQsSet_Result_List_lastVisible: _List_lastVisible,
        }, () => {
            this.Toggle_Search_QuestionSetModal(false);
            this.Toggle_Select_QuestionSetModal();
        });
    }
    SearchViaFirebase = async (_centerUserId, _authorId) => {
        if (this.state.PA_Search === false)
            return null;

        let done = false;
        let _List_lastVisible = null;
        let _List = [];

        let docRef = this.props.firestore.collection("QuizBank").doc('QuizQuestionSet').collection('QuizQuestionSets');
        if (this.props.isSuperAdmin) { }
        else {
            docRef = docRef
                .where('CenterUserId', '==', _centerUserId)
                .where('AuthorId', '==', _authorId);
        }

        if (this.state.SearchQsSet_ByGroup !== null) {
            let _groupId = this.GetValue(this.state.SearchQsSet_ByGroup, 'id', 0);
            if (_groupId > 0)
                docRef = docRef.where('GroupId', '==', Number(_groupId));
        }
        if (this.state.SearchQsSet_BySubject !== null) {
            let _subjectId = this.GetValue(this.state.SearchQsSet_BySubject, 'id', 0);
            if (_subjectId !== '')
                docRef = docRef.where('SubjectId', '==', Number(_subjectId));
        }
        // console.log('_groupId = ' + _groupId + '\n_subjectId = ' + _subjectId);

        //Search Query.
        await docRef
            .orderBy('CreatedOnUtc', 'desc')
            .limit(this.state.SearchQsSet_MaxQtyShow)   //default: 5
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                        // dataArray[dataArray.length - 1].Id = doc.id;    //important
                    });
                    if (dataArray.length > 0) {
                        _List = dataArray;
                        _List_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    }
                }
                // if (this.props.isDevMode)
                //     console.log('qSet list result =\n' + JSON.stringify(_List));
                done = true;
            })
            .catch(error => {
                done = true;
                if (this.props.isDevMode)
                    console.log(error.message);
            });

        // //2022.02.11
        // if (_List.length > 0) {
        //     _List.map((data, key) => {
        //         if (data.hasOwnProperty('Subject') === false)
        //             data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value };     //default
        //         else
        //             if (data.Subject['Id'] === 0 || data.Subject['Id'] === undefined)
        //                 data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value };     //default
        //         return null;
        //     });
        // }
        await DelayUntil(() => done === true);

        return { _List, _List_lastVisible };
    }
    SearchViaApi = async () => {
        if (this.state.PA_Search === false)
            return null;

        //2022.12.05
        let done = false;
        let errorMessage = '';
        let _List_lastVisible = null;
        let _List = [];

        const { centerUserId, authorId, organizerId } = GetPropIds(this.props);

        // const params = 'organizerId=' + organizerId
        //     + '&centerUserId=' + centerUserId
        //     + '&authorId=' + authorId
        //     + '&name=' + this.state.SearchQsSet_ByName
        //     + '&groupId=' + (this.state.SearchQsSet_ByGroup === null ? 0 : Number(this.state.SearchQsSet_ByGroup.id))
        //     + '&subjectId=' + (this.state.SearchQsSet_BySubject === null ? 0 : Number(this.state.SearchQsSet_BySubject.id))
        //     + '&maxQty=' + this.state.SearchQsSet_MaxQtyShow;

        // const params = organizerId + '/' + centerUserId + '/' + authorId + '/'
        //     + this.state.SearchQsSet_ByName + '/'
        //     + CheckObjectNumber(this.state.SearchQsSet_ByGroup, 'id') + '/'
        //     + CheckObjectNumber(this.state.SearchQsSet_BySubject, 'id') + '/'
        //     + this.state.SearchQsSet_MaxQtyShow;

        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/QuestionSet/SearchByName/'
            + organizerId + '/' + centerUserId + '/' + authorId + '/'
            + CheckObjectNumber(this.state.SearchQsSet_ByGroup, 'id') + '/'
            + CheckObjectNumber(this.state.SearchQsSet_BySubject, 'id') + '/'
            + this.state.SearchQsSet_MaxQtyShow + '/'
            + CheckNullValue(this.state.SearchQsSet_ByName),
            // Api/LearningCentre/QuizBank/QuestionSet/SearchByName/{organizerId}/{centerUserId}/{authorId}/{name}/{groupId}/{subjectId}/{maxQty}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    _List = data.data;
                    _List_lastVisible = _List[_List.length - 1];
                }
                else {
                    errorMessage = 'api search - question set - failed.\n' + JSON.stringify(data);
                }
                done = true;
            })
            .catch(error => {
                errorMessage = 'Error : ' + error.message;  //JSON.stringify(error);
                done = true;
            });

        await DelayUntil(() => done === true);
        if (errorMessage !== '')
            if (this.props.isDevMode)
                console.log('SearchViaApi', errorMessage);

        if (_List.length > 0) {
            _List = this.ConvertKeysToCapital(_List);
        }
        return { _List, _List_lastVisible };
    }
    Toggle_Select_QuestionSetModal = () => {
        this.setState({
            ShowSelectQuestionSetModal: !this.state.ShowSelectQuestionSetModal,
        }, () => {
            if (this.state.ShowSelectQuestionSetModal === false) {
                this.setState({
                    //reset
                    SearchQsSet_ByGroup: null,              //standard
                    SearchQsSet_BySubject: null,            //subject
                    SearchQsSet_MaxQtyShow: 5,              //max result
                    SearchQsSet_ByName: '',

                    SearchQsSet_Processing: false,
                    IsSearchQsSetConditionsValid: false,
                    IsSearchConditionsSelected: false,
                    SearchQsSet_Result_List: [],
                    SearchQsSet_Result_List_lastVisible: null,
                    //Select from Search Result.
                    ShowSelectQuestionSetModal: false,
                    IsSearchQsSetSelected: false,
                    SearchQsSet_QuestionSet_Selected: null,
                });
            }
        });
    }
    GetQuestionSetsResultList = () => {
        let rows = [];
        rows.push(<tr className='hide-row-hover' key={0}>
            <td width='50'></td>
            <td width='50'>#</td>
            <td align='left'>Name</td>
            <td align='left'>Group</td>
            <td align='left'>Subject</td>
            <td width='135'>Total Question</td>
            <td>Remark</td>
            <td width='190'>Created Date</td>
        </tr>);
        if (this.state.SearchQsSet_Result_List.length > 0) {
            this.state.SearchQsSet_Result_List.map((data, key) => {
                return rows.push(<tr onClick={() => this.SelectThisQuestionSet(data)} key={key + 1}>
                    <td>
                        <input type='radio' value={data.UniqueId} name='QSet'
                            checked={
                                this.state.SearchQsSet_QuestionSet_Selected === null ? false :
                                    this.state.SearchQsSet_QuestionSet_Selected.UniqueId === data.UniqueId
                            }
                        ></input>
                    </td>
                    <td>{key + 1}</td>
                    <td align='left'>{data.Name}</td>
                    <td align='left'>{data.Group.Name}</td>
                    <td align='left'>{data.Subject.Name}</td>
                    <td>{data.TotalQuestion}</td>
                    <td align='left'>{data.Remark}</td>
                    {/* <td>{moment.utc(data.CreatedOnUtc).format('YYYY-MM-DD hh:mm:ss A')}</td> */}
                    <td>{moment.utc(data.CreatedOnUtc).local().format('lll')}</td>
                </tr>);
            });
        }
        else {
            rows.push(<tr key={1}><td colSpan='8' align='center'>list is empty.</td></tr>);
        }
        return rows;
    }
    SelectThisQuestionSet = (data) => {
        this.setState({ SearchQsSet_QuestionSet_Selected: data, IsSearchQsSetSelected: true });
        if (this.props.isDevMode)
            console.log('\nSelectThisQuestionSet = \n' + JSON.stringify(data));
    }
    SearchAgain_SelectQuestionSet = () => {
        this.Toggle_Select_QuestionSetModal();
        setTimeout(() => {
            this.Toggle_Search_QuestionSetModal(false);
        }, 300);
    }
    Confirm_SelectOnThisQuestionSet = () => {
        if (this.state.SearchQsSet_QuestionSet_Selected !== null) {
            if (this.state.SearchQsSet_QuestionSet_Selected.UniqueId !== undefined)
                this.GotoEditQuestionSetPage(this.state.SearchQsSet_QuestionSet_Selected.UniqueId)
            else
                this.props.SetAlert('Invalid Operation', 'Question Set not found.');
        }
    }
    //2022.02.14
    FormatList_Subject = (_List = []) => {
        if (_List.length > 0) {
            _List.map((data, key) => {
                if (data.hasOwnProperty('Subject') === false)
                    data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value };     //default
                else
                    if (data.Subject['Id'] === 0 || data.Subject['Id'] === undefined)
                        data['Subject'] = { Id: this.props.SubjectOptions[0].id, Name: this.props.SubjectOptions[0].value };     //default

                // if (data.Subject.hasOwnProperty('Label') === false) {
                //     let temp = this.props.SubjectList[0];
                //     let _findIndex = this.props.SubjectList.findIndex(x => Number(x['Id']) === Number(data['Id']));
                //     if (_findIndex < 0) {
                //         data.Subject['Label'] = temp['Name'] + ' (' + temp['BC'] + ') (' + temp['BM'] + ') (' + temp['TagName'] + ')';
                //     }
                //     else {
                //         temp = this.props.SubjectList[_findIndex];
                //         data.Subject['Label'] = temp['Name'] + ' (' + temp['BC'] + ') (' + temp['BM'] + ') (' + temp['TagName'] + ')';
                //     }
                // }
                return null;
            });
        }
        return _List;
    }
    //=== Question Set === Search === end ===//

    //2023.09.09 - admin use only.
    LoadAllAndUpdateField = async () => {

        if (this.props.user === null || this.props.user.isAdmin === null || this.props.user.isAdmin === false || this.props.isDevMode === false)
            return null;

        this.props.SetLoading('Processing', 'Fetching all question sets...');
        let questionSets = [];
        let done = false;

        //FS - fetch.
        let docRef = this.props.firestore.collection("QuizBank").doc('QuizQuestionSet').collection('QuizQuestionSets');
        await docRef.get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                    });
                    if (dataArray.length > 0)
                        questionSets = dataArray;
                }
                done = true;
            })
            .catch(error => {
                done = true;
                if (this.props.isDevMode)
                    console.log(error.message);
            });
        await DelayUntil(() => done === true);
        console.log('\nTotal QSet: ' + questionSets.length);

        //proceed.
        if (questionSets.length > 0) {
            let msg = 'Updating all question sets on FS & RTDB...<br />UniqueId: ';

            for (let i = 0; i < questionSets.length; i++) {
                if (String(questionSets[i].UniqueId) === '00e8929e8d8c474b96fa73e8077d6cb9') {
                    this.props.SetLoading('Processing', msg + questionSets[i].UniqueId);
                    console.log('\nProcessing...' + questionSets[i].UniqueId);

                    const organizerId = questionSets[i].OrganizerId === null || Number(questionSets[i].OrganizerId) <= 0 ? 1 : Number(questionSets[i].OrganizerId);
                    if (questionSets[i].OrganizerId === null || Number(questionSets[i].OrganizerId) <= 0) {
                        //FS - update.
                        await docRef.doc(String(questionSets[i].UniqueId))
                            .update({
                                OrganizerId: organizerId,
                            })
                            .catch((error) => {
                                console.log("(FS) QSet:\n" + questionSets[i].UniqueId + "\nError: " + error);
                            });

                        //RTDB.
                        let update = {};
                        update['/' + String(questionSets[i].UniqueId) + '/Settings/OrganizerId'] = organizerId;
                        await this.props.dbQuestionSet.ref().update(update).catch((error) => {
                            console.log("(RTDB) QSet:\n" + questionSets[i].UniqueId + "\nError: " + error);
                        });;
                    }
                    break;
                }
            }
        }
        else {
            this.props.SetAlert('Process done', 'No question set found.');
        }
    }


    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <>
                    <Row className='rowStyle'>
                        <Col>
                            <span style={{ fontSize: 20, fontWeight: 'bold' }}>Manage Question Sets</span>
                        </Col>
                        <Col className='colBtn'>
                            {/* <button type="button" className="btn btn-outline"
                            // onClick={this.ToggleUploadQuestionSetModal}
                            ><i className="fa fa-info-circle" style={{ fontSize: "24px", color: "green", paddingTop: 2 }}></i></button> */}

                            <button type="button" className="btn btn-outline-info"
                                onClick={() => this.setState({ ShowSearchQuestionSetModal: true, SearchQsSet_Processing: false, SearchQuestionSet_Result: null, })}
                            >Search Question Set</button>
                            {
                                this.state.PA_Create === false ? null :
                                    <>
                                        &nbsp;&nbsp;
                                        <button type="button" className="btn btn-outline-primary"
                                            onClick={this.ToggleCreateQuestionSetModal}
                                        >Create</button>
                                    </>
                            }
                            {
                                this.state.PA_Upload === false ? null :
                                    <>
                                        &nbsp;&nbsp;
                                        <button type="button" className="btn btn-outline-primary"
                                            onClick={this.ToggleUploadQuestionSetModal}
                                        >Upload Question Set</button>
                                    </>
                            }
                        </Col>
                    </Row>
                    {
                        //Admin private function.
                        this.props.user !== null && this.props.isSuperAdmin ?
                            <Row>
                                <Col>
                                    <button type="button" className="btn btn-outline-primary"
                                        onClick={this.LoadAllAndUpdateField}
                                    >LoadAllAndUpdateField</button>
                                    &nbsp;&nbsp;
                                </Col>
                            </Row>
                            : null
                    }
                    <p />

                    <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                        <thead>
                            <tr>
                                <th width='50'>#</th>
                                {/* <th>Question Set Code</th> */}
                                <th style={{ textAlign: 'left' }}>Name</th>
                                <th width='135'>Group</th>
                                <th width='135'>Subject</th>
                                <th width='90'>Questions</th>
                                {/* <th width='65'>Public</th>
                                <th width='90'>Published</th> */}
                                <th width='85'>Remark</th>
                                <th width='170'>Created Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.Events !== null ?
                                    this.QuestionSetItemComponents()
                                    : <tr><td colSpan='15'></td></tr>
                            }
                            {
                                this.state.QuestionSetList_More_isLoading ?
                                    <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                                    :
                                    this.state.QuestionSetList.length === 0 ? null :
                                        PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                            }
                        </tbody>
                    </table>

                    <span id={'bottomPage'}>&nbsp;</span>








                    {/* QuestionSet - Delete - Modal */}
                    <Modal show={this.state.Show_Delete_QuestionSetModal} onHide={this.ToggleDeleteQuestionSetModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Question Set</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <span>
                                Question Set Code: <b>{this.state.TargetQuestionSetModal['QuestionSetCode']}</b>
                                <br />Are you sure you want to <b>delete</b> this question set ?
                                <br />The deletion is not reversible.
                            </span>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.ToggleDeleteQuestionSetModal}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={this.DeleteThisQuestionSet}>Confirm</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* QuestionSet - Create / Upload - Modal */}
                    <Modal size='lg' show={this.state.Show_Upload_QuestionSetModal} onHide={this.ToggleUploadQuestionSetModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Create {this.state.Show_Create_QuestionSetModal ? 'New' : '& Upload'} Question Set</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table width='100%' cellPadding='5'>
                                <tbody>
                                    <tr>
                                        <td colSpan='3'>
                                            <table cellPadding='5' width='100%' border='0'>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Name *</td>
                                                        <td style={{ width: '50%' }}>
                                                            <input type="text" style={{ width: '100%' }}
                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Name)}
                                                            />
                                                        </td>
                                                        <td style={{ width: '35%', verticalAlign: 'top', paddingRight: '30px' }} rowSpan='2'>
                                                            <table cellPadding='5' width='100%' border='0'>
                                                                <tbody>
                                                                    <tr hidden>
                                                                        <td style={{ width: '50%', textAlign: 'right' }}>Is Public</td>
                                                                        <td style={{ width: '25%' }}>
                                                                            <input type="checkbox"
                                                                                checked={this.state.NewQSet_IsPublic}
                                                                                onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPublic)}
                                                                            />&nbsp;
                                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to show this Question Set to the public or hide it from all, only author or admin(s) who belongs to the same Center are allow to edit.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                    </tr>
                                                                    <tr hidden>
                                                                        <td style={{ width: '50%', textAlign: 'right' }}>Is Private Group</td>
                                                                        <td style={{ width: '25%' }}>
                                                                            <input type="checkbox"
                                                                                checked={this.state.NewQSet_IsPrivateGroup}
                                                                                onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPrivateGroup)}
                                                                            />&nbsp;
                                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to continue to customize private group in next step.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                    </tr>
                                                                    <tr hidden>
                                                                        <td style={{ width: '50%', textAlign: 'right' }}>Published</td>
                                                                        <td style={{ width: '25%', }}>
                                                                            <input type="checkbox"
                                                                                checked={this.state.NewQSet_Published}
                                                                                onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.Published)}
                                                                            />&nbsp;
                                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to publish this Question Set. Controls the accessability of this Question Set.<p style={{ textAlign: 'right' }}>(default: checked)</p></div></Tooltip>}>
                                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: '50%', textAlign: 'right' }}>Display Order</td>
                                                                        <td style={{ width: '25%' }}>
                                                                            <input type="number" style={{ width: '100%' }} placeholder={0}
                                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.DisplayOrder)} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: '50%', textAlign: 'right' }}>Total Question *</td>
                                                                        <td style={{ width: '25%' }}>
                                                                            <input type="number" style={{ width: '100%' }} placeholder={0}
                                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.TotalQuestion)} />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right', verticalAlign: 'top', }}>Remark</td>
                                                        <td style={{ width: '35%' }}>
                                                            <textarea
                                                                ref={this.NQSet_Remark}
                                                                rows="4" cols="50"
                                                                placeholder={'...remark...'}
                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Remark)}></textarea>
                                                            <Row>
                                                                <Col style={{ fontWeight: 'bold', fontSize: '13px', color: 'red' }}>{this.state.NewQSet_Remark.length >= 250 ? '(Max charactors reached.)' : ''}</Col>
                                                                <Col style={{ textAlign: 'right', fontSize: '12px', color: 'gray' }}>{'(' + this.state.NewQSet_Remark.length + '/250 charactors remaining.)'}</Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Group *</td>
                                                        <td style={{ width: '35%' }}>
                                                            <Select
                                                                options={this.props.GroupOptions}
                                                                placeholder={
                                                                    this.state.NewQSet_Group !== null ?
                                                                        this.state.NewQSet_Group.Name
                                                                        : Locale("not-specify-group", this.props.Locale)
                                                                }
                                                                value={this.state.NewQSet_Group !== null ? this.state.NewQSet_Group.Name : null}
                                                                onChange={(option) => this.SaveDataInput(option, DataInput.Group)} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Subject *</td>
                                                        <td style={{ width: '35%' }}>
                                                            <Select
                                                                options={this.props.SubjectOptions}
                                                                placeholder={
                                                                    this.state.NewQSet_Subject !== null ?
                                                                        this.state.NewQSet_Subject.label
                                                                        : Locale("not-specify-group", this.props.Locale)
                                                                }
                                                                value={this.state.NewQSet_Subject !== null ? this.state.NewQSet_Subject.value : null}
                                                                onChange={(option) => this.SaveDataInput(option, DataInput.Subject)} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    {
                                        this.state.Show_Create_QuestionSetModal === false ?
                                            <>
                                                <tr><td colSpan='3'><hr /></td></tr>
                                                <tr>
                                                    <td colSpan='3'>
                                                        {/* <a href='' onClick={(e) => {
                                                            e.preventDefault();
                                                            window.open('https://quiz.ikeyedutech.com.my', '_new');
                                                        }}><b>Download & use this sample file</b></a> for upload purpose. */}
                                                        <button
                                                            className='link-button'
                                                            onClick={() => {
                                                                let link40 = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_" + 40 + ".xlsx', '_new')";
                                                                let link100 = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_" + 100 + ".xlsx', '_new')";
                                                                let linkSample = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_SAMPLE.xlsx', '_new')";
                                                                let htmlText = '<table cellpadding="5" width="100%" border="1" style="text-align:center;"><thead><tr><th>Question Amount</th><th>Spreadsheet Template</th></tr></thead><tbody>';
                                                                htmlText += '<tr><td>40</td><td><button class="link-button" onClick="' + link40 + '">download</button></td></tr>';
                                                                htmlText += '<tr><td>100</td><td><button class="link-button" onClick="' + link100 + '">download</button></td></tr>';
                                                                htmlText += '</tbody></table><br />';
                                                                htmlText += '<table cellpadding="5" width="100%" border="1" style="text-align:center;"><thead><tr><th>Spreadsheet Implementation Sample</th></tr></thead><tbody>';
                                                                htmlText += '<tr><td><button class="link-button" onClick="' + linkSample + '">download sample file</button></td></tr>';
                                                                htmlText += '</tbody></table>';
                                                                htmlText += '<br /><ul style="list-style-type: circle"><li>for other question amounts, please download any file, then fill-in or remove the remaining questions if needed.</li></ul>'
                                                                this.props.SetAlert('Question Sets Template', htmlText);
                                                            }}
                                                        >Download & use the provided spreadsheet template file</button> for upload purpose.
                                                        <p>
                                                            Using other files with different column name or format may cause errors on data verification on the questions, or may further create unforseen bugs that may greatly affecting the usability or accessability of the quiz system on your account.
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <input type="file" onChange={this.onUploadFileChange} style={{ width: '100%' }}
                                                            disabled={this.state.PA_Upload === false} />*
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>&nbsp;
                                                        <span>Continue to upload this file ?</span>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </>
                                            : null
                                    }
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.ToggleUploadQuestionSetModal}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button variant="secondary" onClick={this.ResetUploadQuestionSetModal}>Reset</Button>
                            &nbsp;&nbsp;
                            {/* <Button variant="primary" onClick={this.UploadNewQuestionSet}>Upload</Button> */}
                            <Button
                                variant="primary"
                                onClick={() => this.state.Show_Create_QuestionSetModal ? this.CreateQuestionSetTemplate() : this.ProcessQuestionSetModal()}
                                disabled={this.state.IsUploadConditionsFullfilled === false || this.state.PA_Upload === false || this.state.PA_Create === false}
                            >{this.state.Show_Create_QuestionSetModal ? 'Create' : 'Upload'}</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* QuestionSet - Process Uploaded - Modal */}
                    <Modal show={this.state.Show_ProcessUploaded_QuestionSetModal}
                        onHide={
                            this.state.UploadStatus === UploadState.ConvertFailed ||
                                this.state.UploadStatus === UploadState.Success ||
                                this.state.UploadStatus === UploadState.Failed
                                ? this.ToggleProcessUploadedQuestionSetModal : this.DoNothing
                        }
                        centered
                        backdrop='static'
                        keyboard='false'
                        dialogClassName='alert-dialog-bordered'
                    >
                        <Modal.Header
                        // closeButton={
                        //     this.state.UploadStatus === UploadState.Success ||
                        //     this.state.UploadStatus === UploadState.Failed
                        // }
                        >
                            <Modal.Title style={{ fontSize: 20 }}>{
                                this.state.UploadStatus === UploadState.ConvertFailed ||
                                    this.state.UploadStatus === UploadState.Success ||
                                    this.state.UploadStatus === UploadState.Failed
                                    ? 'Result' : 'Processing...'
                            }</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.UploadStatusMessage()}
                        </Modal.Body>
                        <Modal.Footer
                            hidden={
                                this.state.UploadStatus === UploadState.ConvertFailed ||
                                    this.state.UploadStatus === UploadState.Success ||
                                    this.state.UploadStatus === UploadState.Failed ? false : true
                            }>
                            {/* <Button variant="secondary" onClick={this.ToggleProcessUploadedQuestionSetModal}>Close</Button> */}
                            {/* &nbsp;&nbsp;
                        <Button variant="primary" onClick={this.ToggleProcessUploadedQuestionSetModal}>Confirm</Button> */}
                            {/* {
                                this.state.UploadStatus === UploadState.ConvertFailed ||
                                    this.state.UploadStatus === UploadState.Success ||
                                    this.state.UploadStatus === UploadState.Failed
                                    ?
                                    <> */}
                            <Button variant="secondary" onClick={this.ReloadQuestionSetList}>Close</Button>&nbsp;&nbsp;
                            <Button
                                variant="primary"
                                onClick={() => this.GotoEditQuestionSetPage(this.state.NewQSet_UniqueId)}
                                disabled={this.state.NewQSet_UniqueId === ''}
                                hidden={
                                    this.state.UploadStatus === UploadState.ConvertFailed ||
                                        this.state.UploadStatus === UploadState.Failed ? true : false
                                }
                            >View & Edit</Button>
                            {/* </>
                                    : null
                            } */}
                        </Modal.Footer>
                    </Modal>

                    {/* QuestionSet - Search Question Set - Modal */}
                    <Modal show={this.state.ShowSearchQuestionSetModal}
                        onHide={() => {
                            if (this.state.SearchQsSet_Processing)
                                this.DoNothing();
                            else
                                this.Toggle_Search_QuestionSetModal();
                        }}
                        centered>
                        <Modal.Header closeButton={this.state.SearchQsSet_Processing === false}>
                            <Modal.Title>{
                                this.state.SearchQsSet_Processing ? 'Searching...' : 'Search Question Set'
                            }</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                this.state.SearchQsSet_Processing ?
                                    <ProgressBar animated now={100} className='progressbar1' />
                                    :
                                    <span>
                                        Search for Question Set:<br />
                                        <table width='100%' cellPadding='5' cellSpacing='5' border='0' style={{ borderColor: 'grey', marginBottom: 0, }}>
                                            <tbody>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>
                                                        <input type="text" className='form-control' style={{ width: '100%' }}
                                                            defaultValue={this.state.SearchQsSet_ByName}
                                                            onChange={(e) => this.SaveDataInput(e.target.value, DataInput.SearchQsSet_ByName)}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Group</td>
                                                    <td>
                                                        <Select
                                                            options={this.props.GroupOptions}
                                                            placeholder={
                                                                this.state.SearchQsSet_ByGroup !== null ?
                                                                    this.state.SearchQsSet_ByGroup.label
                                                                    : Locale("not-specify-group", this.props.Locale)
                                                            }
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    neutral50: 'black',  // placeholder color
                                                                },
                                                            })}
                                                            value={this.state.SearchQsSet_ByGroup !== null ? this.state.SearchQsSet_ByGroup.value : null}
                                                            onChange={(option) => this.SaveDataInput(option, DataInput.SearchQsSet_ByGroup)}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Subject</td>
                                                    <td>
                                                        <Select
                                                            options={this.props.SubjectOptions}
                                                            placeholder={
                                                                this.state.SearchQsSet_BySubject !== null ?
                                                                    this.state.SearchQsSet_BySubject.label
                                                                    :
                                                                    Locale("subject", this.props.Locale)
                                                            }
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    neutral50: 'black',  // placeholder color
                                                                },
                                                            })}
                                                            value={this.state.SearchQsSet_BySubject !== null ? this.state.SearchQsSet_BySubject.value : null}
                                                            onChange={(option) => this.SaveDataInput(option, DataInput.SearchQsSet_BySubject)}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Max Result</td>
                                                    <td>
                                                        <input type="number" style={{ width: '100%' }}
                                                            defaultValue={this.state.SearchQsSet_MaxQtyShow}
                                                            onChange={(e) => this.SaveDataInput(Number(e.target.value), DataInput.SearchQsSet_MaxQtyShow)}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </span>
                            }
                        </Modal.Body>
                        {
                            !this.state.SearchQsSet_Processing ?
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.Toggle_Search_QuestionSetModal()}>Cancel</Button>
                                    &nbsp;&nbsp;
                                    <Button variant="primary" onClick={this.SearchQuestionSetByConditions} disabled={this.state.IsSearchQsSetConditionsValid === false}>Search</Button>
                                </Modal.Footer>
                                : null
                        }
                    </Modal>

                    {/* QuestionSet - Select Question Set - Modal */}
                    <Modal size='xl' show={this.state.ShowSelectQuestionSetModal} onHide={() => this.Toggle_Select_QuestionSetModal()}
                        centered
                    // dialogClassName='alert-dialog-bordered'
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Select Question Set</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <span>
                                Select a Question Set from list:<br />
                                <table className='table table-hover tbStyle' width='100%' cellPadding='5' cellSpacing='5'
                                    style={{
                                        border: '1px solid gray', marginTop: 10, marginBottom: 0,
                                        borderTop: 'inset', borderBottomStyle: 'groove',
                                    }}>
                                    <tbody>
                                        {this.GetQuestionSetsResultList()}
                                    </tbody>
                                </table>
                            </span>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.SearchAgain_SelectQuestionSet}>Search Again</Button>
                            &nbsp;&nbsp;
                            <Button variant="secondary" onClick={() => this.Toggle_Select_QuestionSetModal()}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={this.Confirm_SelectOnThisQuestionSet} disabled={this.state.SearchQsSet_QuestionSet_Selected === null}>Select</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            );
        }
    }
}

