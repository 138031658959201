
import React from 'react';
import moment from 'moment';

// import LoadingIndicator from '../Quiz/LoadingIndicator.js';

//2020.12.14
let Center = {
    SIM: 'SIM',
    SmartNewton: 'SmartNewton',
};

//2020.12.08
export default class RegistrationInfo extends React.Component {

    state = {

        lastUpdate: '',
        regData: [],
        fetchRegDataList: [],
        gradeStudentQty: [],
        stateStudentQty: [],

        isDataPopulationInProgress: false,
        isDataPopulationDone: false,

        count_totalRegistration: 0,
        count_totalCenterStudent: 0,
        count_totalOtherStudent: 0,

        //2020.12.14
        centerCollection: '',
        centerName: '',
        isDataLoaded: false,
        groupStudentQty: [],
    }

    componentDidMount = async () => {

        //2020.12.14
        await this.SetCenterCollection(Center.SIM);

        // await this.Fetch_Saved_RegistrationDataInfo_FromFireStore();

    }

    //2020.12.14
    SetCenterCollection = async (option) => {
        let _centerCollection = '';
        let _centerName = '';
        switch (option) {
            default:
                break;
            case Center.SIM:
                _centerCollection = 'RegistrationData';
                _centerName = 'SIM';
                break;
            case Center.SmartNewton:
                _centerCollection = 'SmartNewton_RegistrationData';
                _centerName = 'Smart Newton';
                break;
        }
        if (_centerCollection !== '') {
            this.setState({
                centerCollection: _centerCollection,
                centerName: _centerName,
                lastUpdate: '',
                count_totalRegistration: 0,
                count_totalCenterStudent: 0,
                count_totalOtherStudent: 0,
                regData: [],
                fetchRegDataList: [],
                gradeStudentQty: [],
                stateStudentQty: [],
                isDataPopulationInProgress: false,
                isDataPopulationDone: false,
                isDataLoaded: false,
                groupStudentQty: [],
            }, async () => {
                await this.Fetch_Saved_RegistrationDataInfo_FromFireStore();
            });
        }
    }

    FetchRegistrationData = async () => {
        if (this.state.centerCollection !== '') {
            let _regData = [];
            this.setState({
                isDataPopulationInProgress: true,
            });
            await this.props.firestore
                .collection(this.state.centerCollection)
                // .where('Uid', '==', 'aXdIcYL8BMTFbzPpOHcnduP3MVz1')
                .orderBy("Name")
                // .limit(3)
                .get()
                .then((querySnapshot) => {

                    _regData = querySnapshot.docs.map(doc => doc.data());

                    // let testData = '';
                    // _regData.map((data, key) => {
                    //     return testData += JSON.stringify(data) + "<br /><br />";
                    // });
                    // this.props.SetAlert('Test Data (3)', testData);

                    //Populating Registration Data.
                    this.setState({
                        fetchRegDataList: _regData,
                    }, () => {
                        this.PopulatingRegistrationData();
                    });
                })
                .catch(async (error) => {
                    this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
                    this.setState({
                        isDataPopulationInProgress: false,
                    });
                });
        }
    }

    PopulatingRegistrationData = () => {

        let _count_totalRegistration = this.state.fetchRegDataList.length;
        let _count_totalCenterStudent = 0;
        let _count_totalOtherStudent = 0;
        let _gradeStudentQty = [];
        let _stateStudentQty = [];
        let _groupStudentQty = [];  //2020.12.14

        this.state.fetchRegDataList.map((data, key) => {

            let isCenterStudent = String(data.IsCenterStudent).toLowerCase() === 'yes' ? true : false;
            if (isCenterStudent && data.Center === this.state.centerName)
                _count_totalCenterStudent += 1;
            else
                _count_totalOtherStudent += 1;

            //Update Grade array.
            let grade_idx = _gradeStudentQty.findIndex(x => x.Name === data.Grade);
            if (grade_idx > -1)
                _gradeStudentQty[grade_idx].Qty += 1;
            else
                _gradeStudentQty.push({ Name: data.Grade, Qty: 1 });

            //Update State array.
            let state_idx = _stateStudentQty.findIndex(x => x.Name === data.NationalState);
            if (state_idx > -1)
                _stateStudentQty[state_idx].Qty += 1;
            else
                _stateStudentQty.push({ Name: data.NationalState, Qty: 1 });

            //Update Student Group array.   //2020.12.14
            if (data.Group !== undefined && data.Group.length > 0) {
                let group_idx = _groupStudentQty.findIndex(x => x.Name === data.Group);
                if (group_idx > -1)
                    _groupStudentQty[group_idx].Qty += 1;
                else
                    _groupStudentQty.push({ Name: data.Group, Qty: 1 });
            }

            return null;
        });

        //Sort by Name.
        _gradeStudentQty.sort(function (a, b) {
            if (a.Name > b.Name) {
                return 1;
            }
            if (a.Name < b.Name) {
                return -1;
            }
            return 0;
        });

        //Sort by Name.
        _stateStudentQty.sort(function (a, b) {
            if (a.Name > b.Name) {
                return 1;
            }
            if (a.Name < b.Name) {
                return -1;
            }
            return 0;
        });

        //Sort by Name.
        _groupStudentQty.sort(function (a, b) {
            if (a.Name > b.Name) {
                return 1;
            }
            if (a.Name < b.Name) {
                return -1;
            }
            return 0;
        });

        this.setState({
            lastUpdate: moment().format('lll'),
            count_totalRegistration: _count_totalRegistration,
            count_totalCenterStudent: _count_totalCenterStudent,
            count_totalOtherStudent: _count_totalOtherStudent,
            gradeStudentQty: _gradeStudentQty,
            stateStudentQty: _stateStudentQty,
            isDataPopulationDone: true,
            isDataPopulationInProgress: false,
            isDataLoaded: true,
            groupStudentQty: _groupStudentQty,
        }, async () => {

            //save generated results.
            await this.SaveDataInFireStore();
        });
    }

    SaveDataInFireStore = async () => {
        if (this.state.centerCollection !== '') {
            let _modalToSave = {
                Counter: this.state.centerCollection,
                LastUpdate: this.state.lastUpdate,
                TotalRegistration: this.state.count_totalRegistration,
                CenterStudentQty: this.state.count_totalCenterStudent,
                OtherStudentQty: this.state.count_totalOtherStudent,
                Grade: JSON.parse(JSON.stringify(this.state.gradeStudentQty)),
                State: JSON.parse(JSON.stringify(this.state.stateStudentQty)),
                Group: this.state.groupStudentQty.length > 0 ? JSON.parse(JSON.stringify(this.state.groupStudentQty)) : null,   //2020.12.14
            };

            // this.props.SetAlert('Modal to Save', JSON.stringify(_modalToSave));
            // return null;

            await this.props.firestore.collection("_Counters")
                .doc(this.state.centerCollection)
                .set(_modalToSave)
                .then(() => {
                    //do nothing atm.
                })
                .catch(async (error) => {
                    this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
                });
        }
    }

    Fetch_Saved_RegistrationDataInfo_FromFireStore = async () => {
        if (this.state.centerCollection !== '') {
            this.setState({
                isDataPopulationInProgress: true,
            });

            await this.props.firestore.collection("_Counters")
                // .doc('RegistrationData')
                .where('Counter', '==', this.state.centerCollection)
                .get()
                .then((querySnapshot) => {
                    let _regData = querySnapshot.docs.map(doc => doc.data());
                    _regData = _regData[0];
                    // let _regData = querySnapshot.data();
                    // this.props.SetAlert('Saved Data', JSON.stringify(_regData));
                    // this.props.SetAlert('Saved Data', Number(_regData[0]['TotalRegistration']));

                    this.setState({
                        lastUpdate: _regData['LastUpdate'],
                        count_totalRegistration: _regData['TotalRegistration'],
                        count_totalCenterStudent: _regData['CenterStudentQty'],
                        count_totalOtherStudent: _regData['OtherStudentQty'],
                        gradeStudentQty: _regData['Grade'],
                        stateStudentQty: _regData['State'],
                        isDataPopulationInProgress: false,
                        isDataPopulationDone: true,

                        //2020.12.14
                        isDataLoaded: true,
                        groupStudentQty: _regData['Group'] !== null ? _regData['Group'] : [],
                    });

                })
                .catch(async (error) => {
                    this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
                });

            this.setState({
                isDataPopulationInProgress: false,
            });
        }
    }

    //2020.12.14
    CheckBeforeFetchData = async () => {
        if (this.state.isDataLoaded === false) {
            await this.Fetch_Saved_RegistrationDataInfo_FromFireStore();
        }
        else {
            await this.FetchRegistrationData();
        }
    }

    render = () => {
        return (
            <>
                <div style={{ border: '1px solid gray', width: '100%', height: 'auto', padding: 10, }} >
                    <div className='row' style={{ paddingBottom: 10 }}>
                        <div className='col'>
                            <b>Registration Information Details</b>
                        </div>
                    </div>

                    <div className='row'>
                        {
                            Object.values(Center).map((data, key) => {
                                return (
                                    <div className='col-1'>
                                        <button type="button" class="btn btn-primary"
                                            onClick={async () => await this.SetCenterCollection(data)}
                                            disabled={this.state.isDataPopulationInProgress}
                                        >{data}</button>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>

                <span>&nbsp;</span>

                <div style={{ border: '1px solid gray', width: '100%', height: '60px', padding: 10, }} >
                    <div style={{ float: 'left', paddingTop: 5, color: 'blue' }}><b>{this.state.centerName} - {this.state.centerCollection}</b></div>
                    <div style={{ float: 'right' }}>
                        {this.state.lastUpdate}&nbsp;&nbsp;
                        <button type="button" class="btn btn-primary"
                            onClick={this.CheckBeforeFetchData}
                            // disabled={this.state.isDataPopulationInProgress}
                            disabled
                        >{
                                this.state.isDataPopulationInProgress ?
                                    "processing"
                                    :
                                    this.state.isDataPopulationDone ? "Refresh" : "Load"
                            }</button>
                    </div>
                    &nbsp;
                </div>

                <span>&nbsp;</span>

                <div style={{ border: '1px solid gray', width: '100%', padding: 10 }} >
                    <table width='100%' cellPadding='5'>
                        <tr>
                            <td>Total Registration</td>
                            <td>:</td>
                            <td>{this.state.count_totalRegistration}</td>
                        </tr>

                        <tr>
                            <td>Student from <b>{this.state.centerName}</b></td>
                            <td>:</td>
                            <td>{this.state.count_totalCenterStudent}</td>
                        </tr>

                        <tr>
                            <td>Other Student</td>
                            <td>:</td>
                            <td>{this.state.count_totalOtherStudent}</td>
                        </tr>

                        {
                            this.state.groupStudentQty.length > 0 ?
                                <>
                                    <tr><td colSpan='3'><hr /></td></tr>
                                    {
                                        this.state.groupStudentQty.map((data, key) => {
                                            return (
                                                <tr>
                                                    <td>{data.Name.length > 0 ? data.Name : 'Unknown'}</td>
                                                    <td>:</td>
                                                    <td>{data.Qty}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </>
                                : null
                        }

                        {
                            this.state.gradeStudentQty.length > 0 ?
                                <>
                                    <tr><td colSpan='3'><hr /></td></tr>
                                    {
                                        this.state.gradeStudentQty.map((data, key) => {
                                            return (
                                                <tr>
                                                    <td>{data.Name.length > 0 ? data.Name : 'Unknown'}</td>
                                                    <td>:</td>
                                                    <td>{data.Qty}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </>
                                : null
                        }

                        {
                            this.state.stateStudentQty.length > 0 ?
                                <>
                                    <tr><td colSpan='3'><hr /></td></tr>
                                    {
                                        this.state.stateStudentQty.map((data, key) => {
                                            return (
                                                <tr>
                                                    <td>{data.Name.length > 0 ? data.Name : 'Unknown'}</td>
                                                    <td>:</td>
                                                    <td>{data.Qty}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </>
                                : null
                        }

                    </table>
                </div>






            </>
        );
    }
}