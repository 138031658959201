import React from 'react';
// import { Spinner } from 'react-bootstrap';
// import _ from 'lodash';

import { Locale } from '../../Localization/CustomLocalization.js';

import './css/QuestionCSS.css';

// import AppCtx, { SetContext } from '../../AppContext';

import { CheckBoolean, CheckObjectNullValue, CheckObjectStringEmpty, Delay, DelayUntil } from '../../components/GlobalFunctions';      //2023.09.11

export default class Question extends React.Component {

    state = {
        ToggleNext: false,
        ToggleSkip: true,
        // Answers: [],
        Selections: [],
        PictureURL: "",
        // isPictureLoaded: false,
        imageBlob: null,
        showResultIcon: null,
        toggleShowArticle: false,
        // subjectLocale: Lang.English,
        // subjectLocale: this.GetLocale(),

        //2021.01.18
        hasHints: false,
        hasInstruction: false,

        //2024.05.23
        submittingAnswer: false,
    }

    componentDidMount = () => {
        // let answers = Object.assign("", this.props.data.Selection);
        // alert(this.props.data.Selection);

        const question = this.props.data;
        const Selections = CheckObjectStringEmpty(question, 'Selection').split(';')
            .filter((el) => { return (el.split(':')[1] !== ''); });

        this.setState({
            // Answers: this.props.data.Selection != null ? this.props.data.Selection.split(';') : [],
            // Answers: this.props.data.Selection.split(';'),
            // Answers: answers.split(';'),
            // Answers: JSON.stringify(this.props.data.Selection).split(';'),
            // Answers: typeof this.props.data.Selection === 'string' ? this.props.data.Selection.split(';') : [],

            // subjectLocale: this.GetLocale(),

            PictureURL: CheckObjectStringEmpty(question, 'PictureUrl'),
            // showResultIcon: this.props.ASelectId !== "-1",
            Selections: Selections,
            toggleShowArticle: Number(this.props.id) + 1 === Number(this.props.qsStart) ? true : false,

            //2021.01.18
            hasHints: CheckObjectNullValue(question, 'Hints') !== null,
            hasInstruction: CheckObjectNullValue(question, 'Instruction') !== null,

        }, () => {
            // this.getPicture(this.state.PictureUrl);

            // setTimeout(() => {
            //     this.setState({
            //         // showResultIcon: this.props.ASelectId !== "-1",
            //         showResultIcon: this.props.showResultIcon,
            //     });
            // }, 1000);
        });
    }

    // GetLocale = () => {
    //     let qsLocale = Lang.English;

    //     if (String(this.props.data.Subject) === 'Chinese')
    //         qsLocale = Lang.Chinese;
    //     else if (String(this.props.data.Subject) === 'Malay')
    //         qsLocale = Lang.Malay;
    //     // else
    //     //     if (this.props.Locale !== Lang.English)
    //     //         qsLocale = this.props.Locale;

    //     return qsLocale;
    // }

    handleClickAnswer = async (event) => {
        const keyId = Number(event.target.id);
        const answer = String(event.target.value);
        const qsAnswer = CheckObjectStringEmpty(this.props.data, 'Answer');
        const result = qsAnswer === '' ? false : answer === qsAnswer;
        // alert(result + " " + answer + " " + this.props.id);
        this.setState({
            ToggleNext: true,
            toggleShowArticle: false,
            // showResultIcon: true,        //dun show correct/wrong immediately.

            // }, async () => {

            //     //2022.06.16 - revamped.

            //     //Show Loading.
            //     this.props.SetLoading('', 'saving answer...', false);
            //     SetContext('IsSavingAnswer', true);

            //     //set answer.
            //     this.props.setAnswer(result, Number(this.props.id), keyId);

            //     //wait until save is finished.
            //     await DelayUntil(() => AppCtx.IsSavingAnswer === false);

            //     //Close loading.
            //     this.props.CloseAlert();

            //     //jump to next qs.
            //     this.props.nextQs(Number(this.props.id) + 1, null);
            // });

            //2024.05.23
            submittingAnswer: true,     //disable buttons.
        });
        // alert(this.props.id);
        const saveDone = await this.props.setAnswer(result, Number(this.props.id), keyId);
        await DelayUntil(() => saveDone === true);
        await Delay(500);
        this.props.CloseAlert();    //2023.11.11
        // let modeOffset = 1;
        // if (this.props.SpecialMode === "Comprehension")
        //     modeOffset = 0;
        this.props.nextQs(Number(this.props.id) + 1, null);
        this.setState({ submittingAnswer: false, });    //reset state.
    }

    convertTagOfColorAndNextline = (_content, extra) => {

        let content = String(_content);

        if (content.length > 0) {

            // if (content.includes('color')) {
            //     if (content.includes('green'))
            //         content = content.replaceAll('<color=green>', '<span style="color:' + String(extra) + 'green;font-weight:bold;">&nbsp;');
            //     else if (content.includes('blue'))
            //         content = content.replaceAll('<color=blue>', '<span style="color:blue;font-weight:bold;">&nbsp;');
            //     else if (content.includes('red'))
            //         content = content.replaceAll('<color=red>', '<span style="color:red;font-weight:bold;">&nbsp;');

            //     content = content.replaceAll('</color>', '&nbsp;</span>');
            // }

            // if (content.includes('\n')) {
            //     content = content.replaceAll('\n', '<br />');
            // }

            if (content.includes('color')) {
                if (content.includes('green'))
                    content = content.replace(new RegExp('<color=green>', 'g'), '<span style="color:' + String(extra) + 'green;font-weight:bold;">&nbsp;');
                else if (content.includes('blue'))
                    content = content.replace(new RegExp('<color=blue>', 'g'), '<span style="color:blue;font-weight:bold;">&nbsp;');
                else if (content.includes('red'))
                    content = content.replace(new RegExp('<color=red>', 'g'), '<span style="color:red;font-weight:bold;">&nbsp;');

                content = content.replace(new RegExp('</color>', 'g'), '&nbsp;</span>');
            }

            if (content.includes('\n')) {
                content = content.replace(new RegExp('\\n', 'g'), '<br />');
            }

            //added on 2020-11-20
            if (content.includes('\t')) {
                content = String(content).replace(new RegExp('\\t', 'g'), '&#9;');
            }

            //2020.11.24
            if (content.includes('<b>')) {
                content = String(content).replace(new RegExp('<b>', 'g'), '<span style="font-weight:bold;">');
                content = String(content).replace(new RegExp('</b>', 'g'), '</span>');
            }

            //2021.08.25
            if (content.includes('<i>')) {
                content = String(content).replace(new RegExp('<i>', 'g'), '<span style="font-style:italic;">');
                content = String(content).replace(new RegExp('</i>', 'g'), '</span>');
            }

            //2020.11.25
            if (content.includes('<tab/>')) {
                content = String(content).replace(new RegExp('<tab/>', 'g'), '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
            }

            //2021.03.25
            if (content.includes('<img>')) {
                content = String(content).replace(new RegExp('<img>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
                content = String(content).replace(new RegExp('</img>', 'g'), '.jpg" alt="" width="100%">');
            }

            //2021.07.12
            if (content.includes('<jpg>')) {
                content = String(content).replace(new RegExp('<jpg>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
                content = String(content).replace(new RegExp('</jpg>', 'g'), '.jpg" alt="" width="100%">');
            }
            if (content.includes('<png>')) {
                content = String(content).replace(new RegExp('<png>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
                content = String(content).replace(new RegExp('</png>', 'g'), '.png" alt="" width="100%">');
            }

        }

        return content;
    }

    getQuestionTitle = (specialMode) => {
        // let title = "<h5><b>" + Locale("question", this.props.UiLocale) + " " + this.props.qsStart + " " + Locale("quiz-qs-comp-to", this.props.UiLocale) + " " + this.props.qsEnd + "</b></h5>";
        let title = "<h5><b>" + Locale("question", this.props.UiLocale) + " " + (Number(this.props.id) + 1) + "</b></h5>";

        //2021.04.02 - disabled by request from May.
        // if (String(specialMode) === 'Comprehension')
        //     title += "<h6>(" + Locale("comprehension", this.props.UiLocale) + ")</h6>";

        // return { __html: title };
        return title;
    }

    getQuestionInnerTitle = () => {
        // let title = Locale("quiz-qs-comprehension", this.props.Locale) + " " + this.props.qsStart + Locale("quiz-qs-comp-to", this.props.Locale) + this.props.qsEnd + Locale("quiz-qs-comp-end", this.props.Locale);
        // return { __html: title };

        // return "<p>" + Locale("quiz-qs-comprehension", this.props.UiLocale) + this.props.qsStart + Locale("quiz-qs-comp-to", this.props.UiLocale) + this.props.qsEnd + Locale("quiz-qs-comp-end", this.props.UiLocale) + "</p>";

        //2021.04.03 - simplified by request from May.
        return "<p>" + Locale("comprehension", this.props.UiLocale) + ' (' + Locale("question", this.props.UiLocale) + this.props.qsStart + Locale("quiz-qs-comp-to", this.props.UiLocale) + this.props.qsEnd + ")</p>";
    }

    getExtraContent = () => {
        // var content = String(this.props.ExtraContent);

        // var content = "<p>" + this.convertTagOfColorAndNextline(String(this.props.ExtraContent), '') + "</p>";
        // return { __html: content }

        return "<p>" + this.convertTagOfColorAndNextline(String(this.props.ExtraContent), '') + "</p><br/>";
    }

    getContent = () => {
        // var temp = String(this.props.data.Content);

        // var content = "";

        // if (temp.includes('color')) {
        //     if (temp.includes('green'))
        //         content = temp.replaceAll('<color=green>', '<span style="color:lightgreen;font-weight:bold;">&nbsp;');
        //     else if (temp.includes('blue'))
        //         content = temp.replaceAll('<color=blue>', '<span style="color:blue;font-weight:bold;">&nbsp;');
        //     else if (temp.includes('red'))
        //         content = temp.replaceAll('<color=red>', '<span style="color:red;font-weight:bold;">&nbsp;');

        //     content = content.replaceAll('</color>', '&nbsp;</span>');
        // }
        // else {
        //     content = temp;
        // }

        // if (content.includes('\n')) {
        //     content = content.replaceAll('\n', '<br />');
        // }

        var content = this.convertTagOfColorAndNextline(String(this.props.data.Content), 'light');
        return { __html: content }

        // return this.convertTagOfColorAndNextline(String(this.props.data.Content), 'light');
    }

    getPicture = (pictureUrl) => {
        let _pictureUrl = String(pictureUrl);

        if (_pictureUrl.length > 0)
            if (_pictureUrl === 'undefined')
                return '';
            else
                return 'https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + _pictureUrl + '.jpg';
        else
            return '';

        // // let imageBlob;
        // if (pictureUrl.length > 0)
        //     this.setState({
        //         imageBlob: null,
        //     }, async () => {
        //         await fetch('https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + pictureUrl + ".jpg")
        //             .then(response => response.blob())
        //             .then(images => {
        //                 // imageBlob = response;
        //                 this.setState({
        //                     imageBlob: URL.createObjectURL(images),
        //                 });
        //                 // alert(response);
        //             })
        //             .catch(error => {
        //                 alert("Unable to load picture.\n\n" + error.code + "\n\n" + error.message);
        //             });
        //     });

        // // return imageBlob;
    }

    getAnswerTextOrPictureURL = (data) => {
        let answer = String(data).split(':')[1];

        let content = '';

        if (answer !== null && answer !== undefined && answer.includes('_pk_')) {
            // content = <img src={this.getPicture(answer)} alt="Picture" width={'80%'} />;
            content = "<img src='" + this.getPicture(answer) + "' alt='' width='80%' />";
            // content = "<img src='" + this.getPicture(answer) + "' alt='' max-width='535' />";
        }
        else {

            // if (content.includes('color')) {
            //     if (content.includes('green'))
            //         content = content.replaceAll('<color=green>', '<span style="color:green;font-weight:bold;">&nbsp;');
            //     else if (content.includes('blue'))
            //         content = content.replaceAll('<color=blue>', '<span style="color:blue;font-weight:bold;">&nbsp;');
            //     else if (content.includes('red'))
            //         content = content.replaceAll('<color=red>', '<span style="color:red;font-weight:bold;">&nbsp;');

            //     content = content.replaceAll('</color>', '&nbsp;</span>');
            // }

            // if (content.includes('\n')) {
            //     content = content.replaceAll('\n', '<br />');
            // }

            content = "<span>" + this.convertTagOfColorAndNextline(answer, '') + "</span>";

            // return <span>{content}</span>;
        }

        return content;
    }

    getSelectedAnswerInText = () => {
        // alert(this.props.ASelectId);s
        let answer = '';
        if (this.state.Selections.length > 0)
            if (String(this.props.ASelectId) !== '-1' || Number(this.props.ASelectId) >= 0) {
                answer = String(this.state.Selections[Number(this.props.ASelectId)]).split(':')[0];
            }
        return answer;
    }

    //2021.01.18
    getHints = () => {
        return '<p> Hints: ' + this.convertTagOfColorAndNextline(String(this.props.data.Hints), 'light') + '</p>';
    }

    //2021.01.18
    getInstruction = () => {
        return '<p>' + this.convertTagOfColorAndNextline(String(this.props.data.Instruction), 'light') + '</p>';
    }

    render = () => {
        return (
            <>
                <div className="row" style={{ paddingBottom: 5, }}>
                    <div className="col col-sm-5">
                        {/* <h5><b>{Locale("question", this.props.UiLocale) + " " + (this.props.id + 1)}</b></h5> */}
                        {/* <h5>{"Question " + (this.props.id + 1)} {String(this.props.InActive)} {String(this.props.HasAnswer)}</h5> */}

                        <div dangerouslySetInnerHTML={{ __html: this.getQuestionTitle(this.props.SpecialMode) }} />
                        {/* <p>This is sub title</p> */}
                    </div>
                    <div className="col col-sm-7 text-right">
                        {
                            CheckBoolean(this.props.InActive) && String(this.props.ASelectId) === "-1" ?
                                // this.props.ASelectId
                                <>
                                    <span>{Locale("quiz-times-up", this.props.UiLocale)}</span>&nbsp;
                                    <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                </>
                                // this.props.data.Answer === this.getSelectedAnswerInText() ?
                                //     <i className="fa fa-check" style={{ fontSize: "24px", color: "blue" }}></i>
                                //     :
                                //     <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>s                                
                                :
                                CheckBoolean(this.props.ShowResultIcon) ?
                                    String(this.props.data.Answer) === this.getSelectedAnswerInText() ?
                                        <i className="fa fa-check" style={{ fontSize: "24px", color: "green" }}></i>
                                        :
                                        <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                    : null
                        }
                    </div>
                </div>

                <div className="questionsBox">

                    {
                        // String(this.props.SpecialMode) !== '' && 
                        String(this.props.SpecialMode) === 'Comprehension' ?
                            <>
                                <div className="row">
                                    <div className="col col-sm-12">
                                        {/* <p>{Locale("quiz-qs-comprehension", this.props.Locale) + " " + this.props.qsStart + Locale("quiz-qs-comp-to", this.props.Locale) + this.props.qsEnd + Locale("quiz-qs-comp-end", this.props.Locale)}</p> */}
                                        <div dangerouslySetInnerHTML={{ __html: this.getQuestionInnerTitle() }} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-sm-12">
                                        <button
                                            className="btn btn-primary btn-block" id="showArticle"
                                            onClick={() => {
                                                this.setState({ toggleShowArticle: !this.state.toggleShowArticle });
                                            }}
                                        >{this.state.toggleShowArticle ? Locale("hide-article", this.props.UiLocale) : Locale("show-article", this.props.UiLocale)}</button>
                                        <span>&nbsp;</span>
                                    </div>
                                </div>
                                {
                                    this.state.toggleShowArticle ?
                                        <div className="row">
                                            <div className="col col-sm-12">
                                                <div dangerouslySetInnerHTML={{ __html: this.getExtraContent() }} />
                                            </div>
                                        </div>
                                        : null
                                }
                            </>
                            : null
                    }

                    <div className="questions">
                        {
                            //2021.01.18
                            this.state.hasInstruction ?
                                <div dangerouslySetInnerHTML={{ __html: this.getInstruction() }} />
                                : null
                        }

                        {/* <div dangerouslySetInnerHTML={{ __html: this.getContent() }} /> */}
                        <div dangerouslySetInnerHTML={this.getContent()} />
                        {/* <p>this is content</p> */}

                        {
                            //2021.01.18
                            this.state.hasHints ?
                                <div dangerouslySetInnerHTML={{ __html: this.getHints() }} />
                                : null
                        }
                    </div>

                    {
                        this.state.PictureURL.length > 0 ?
                            <div className="questionPicture">
                                <img src={this.getPicture(this.state.PictureURL)} alt={''} width={'100%'} />
                                {/* {
                                    this.state.imageBlob != null ?
                                        <img src={this.state.imageBlob} alt="Picture" width={'100%'} />
                                        :
                                        <Spinner animation="border" variant="primary" />
                                } */}
                            </div>
                            : null
                    }

                    <ul className={CheckBoolean(this.props.InActive) || this.state.submittingAnswer ? "answerList-inactive" : "answerList"}>
                        {
                            this.state.Selections.map((data, key) => {
                                // this.state.Answers.map((data, key) => {
                                if (String(data).length > 0) {
                                    return (
                                        <li key={'A_' + key}>
                                            <label>
                                                <div className="row">
                                                    <div className="col-1 ans-col-1">
                                                        <input
                                                            type="radio"
                                                            className="radio"
                                                            name={"Q" + this.props.id + "_ag"}
                                                            value={String(data).split(":")[0]}
                                                            // id={"Q" + this.props.id + "_" + key}
                                                            id={key}
                                                            onClick={this.handleClickAnswer}
                                                            checked={key === Number(this.props.ASelectId)}
                                                            // onChange
                                                            // disabled={!this.props.Enabled}
                                                            // disabled={this.props.InActive || this.props.HasAnswer}
                                                            disabled={CheckBoolean(this.props.InActive) || this.state.submittingAnswer}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                    <div className="col-1 textBold ans-col-2-cols">{String(data).split(":")[0]}</div>
                                                    <div className={(window.innerWidth < 576 ? "col-7" : "col-9") + " ans-col-2-cols"}>
                                                        <div dangerouslySetInnerHTML={{ __html: this.getAnswerTextOrPictureURL(data) }} />
                                                    </div>
                                                    {/* <div className="col col-sm-2 ans-col-2-cols">
                                                                {
                                                                    //show this answer is correct or wrong.
                                                                    this.state.showResultIcon ?
                                                                        String(data).split(":")[0] == this.getSelectedAnswerInText() ?
                                                                            data.split(":")[0] == this.props.data.Answer ?
                                                                                // <>✅</>
                                                                                <i className="fa fa-check" style={{ fontSize: "24px", color: "blue" }}></i>
                                                                                :
                                                                                // <>❎</>
                                                                                <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                                                            : null
                                                                        :
                                                                        null
                                                                }
                                                            </div> */}
                                                    <div className="col-1 ans-col-2-cols">
                                                        {
                                                            //show the correct answer if answered wrong.
                                                            this.props.ShowResultIcon ?
                                                                String(data).split(':')[0] === String(this.props.data.Answer) ?
                                                                    <i className="fa fa-star" style={{ fontSize: "20px", color: "blue" }}></i>
                                                                    :
                                                                    <span>&nbsp;</span>
                                                                :
                                                                <span>&nbsp;</span>
                                                        }
                                                    </div>
                                                </div>
                                            </label>
                                        </li>
                                    );
                                }
                                return null;
                            })
                        }
                    </ul>

                    <div className="questionsRow">
                        <div className="row">
                            <div className="col col-sm-3">
                                <button
                                    className="btn btn-secondary btn-block" id="skipquestions"
                                    onClick={() => {
                                        this.setState({
                                            toggleShowArticle: false
                                        }, () => {
                                            this.props.nextQs(Number(this.props.id) + 1, null);
                                        });
                                    }}
                                    hidden={Number(this.props.total) > Number(this.props.id) + 1 ? !this.state.ToggleSkip : true}
                                    style={{ fontSize: window.innerWidth < 576 ? 14 : 16, }}
                                >{Locale("next", this.props.UiLocale)}</button>
                            </div>
                            <div className="col col-sm-6 text-center" style={{ padding: 0, fontSize: window.innerWidth < 576 ? 14 : 16, }}>
                                <span>{Number(this.props.id) + 1} / {this.props.total}</span>
                            </div>
                            <div className="col col-sm-3">
                                {/* <button
                                    className="btn btn-primary btn-block" id="nextquestions"
                                    onClick={() => {
                                        this.props.nextQs(this.props.id + 1, null);
                                        this.setState({ ToggleSkip: false, ToggleNext: false, });
                                    }}
                                    hidden={this.props.total > this.props.id + 1 ? !this.state.ToggleNext : true}
                                >Next</button> */}
                                <a href="#quiz-pos-q-result" hidden={!this.props.ShowResultBtn}>
                                    <button
                                        className="btn btn-primary btn-block"
                                        style={{ fontSize: window.innerWidth < 576 ? 14 : 16, }}
                                    >{Locale("goto-result", this.props.UiLocale)}</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <span id={"quiz-pos-q-" + (this.props.id + 1)} >&nbsp;</span> */}
            </>
        );
    }
}