import React from 'react';
// import LoadingIndicator from './LoadingIndicator.js';
// import { Spinner } from 'react-bootstrap';
// import _ from 'lodash';

import { Locale } from '../../Localization/CustomLocalization.js';

import './css/QuestionCSS.css';
import { CheckBoolean } from '../../components/GlobalFunctions.js';

// let node = null;

export default class QuestionSubjective extends React.Component {

    constructor(props) {
        super(props);

        this.keyPress = this.keyPress.bind(this);   //2020.12.14
    }

    state = {
        ToggleNext: false,
        ToggleSkip: true,
        PictureURL: '',
        imageBlob: null,
        showResultIcon: null,

        //2020.12.01
        givenAnswer: '',
        hasHints: false,

        //2021.01.18
        hasInstruction: false,

        //2021.04.28
        ansInPairsState: '',
        ansInPairsStatePrev: '',
        Content: '',
        // refreshContent: false,

        //2021.04.30
        emptyAnswers: '',
        answerSets: [],
    }

    componentDidMount = () => {

        this.setState({

            Content: String(this.props.data.Content),
            PictureURL: String(this.props.data.PictureUrl),
            hasHints: String(this.props.data.Hints).length > 0 ? true : false,
            // givenAnswer: String(this.props.ASelectId) !== '-1' ? (String(this.props.ASelectId).includes('|') ? String(this.props.ASelectId) : '') : '',
            givenAnswer: String(this.props.ASelectId) !== '-1' && String(this.props.ASelectId) !== undefined ? String(this.props.ASelectId) : '',
            // givenAnswer: JSON.stringify(this.props.data.ASelectId),

            //2021.01.18
            hasInstruction: this.props.data.hasOwnProperty('Instruction') ?
                String(this.props.data.Instruction).length > 0 ? true : false
                : false,

            // });
        }, () => {
            // this.getPicture(this.state.PictureUrl);

            // setTimeout(() => {
            //     this.setState({
            //         // showResultIcon: this.props.ASelectId !== "-1",
            //         showResultIcon: this.props.showResultIcon,
            //     });
            // }, 1000);

            if (this.state.ansInPairsState.length <= 0 && this.state.emptyAnswers === '') {

                let Ans_Set = String(this.props.data.Answer).split(';');
                let Ans_Pair = Ans_Set[0].split('|');

                let emptyAnswers = (new Array(Ans_Pair.length).fill("")).join("|");
                let givenAnswers = this.state.givenAnswer !== '-1' ? (this.state.givenAnswer !== emptyAnswers ? this.state.givenAnswer : emptyAnswers) : emptyAnswers;
                // let givenAnswers = this.state.givenAnswer;
                let pairs = this.state.ansInPairsState;
                let pairsPrev = this.state.ansInPairsStatePrev;
                // pairs.push({ QsId: this.props.id, Answers: givenAnswers });
                // pairsPrev.push({ QsId: this.props.id, Answers: givenAnswers });
                pairs = givenAnswers;
                pairsPrev = givenAnswers;
                this.setState({
                    emptyAnswers: emptyAnswers,
                    answerSets: Ans_Set,
                    // givenAnswer: String(this.props.ASelectId) !== '-1' ? String(this.props.ASelectId) : emptyAnswers,
                    ansInPairsState: pairs,
                    ansInPairsStatePrev: pairsPrev,
                }, () => {
                    if (this.props.isDevMode)
                        console.log('first run:\ngiven answers = ' + this.state.givenAnswer + '\nanswers = ' + givenAnswers + '\nanswersPrev = ' + givenAnswers);
                    this.getContent();
                });
                // console.log('first run:\ngiven answers = ' + this.state.givenAnswer + '\nanswers = ' + givenAnswers + '\nanswersPrev = ' + givenAnswers);
                // Ans_Pair.map((data, key) => {
                //     return answersValue.push(emptyAnswers);
                // });
            }

            setTimeout(() => {
                this.getContent();
            }, 2000);

        });

        // node = document.getElementsByClassName("input")[0];
        // node.addEventListener("keyup", this.DetectEnterKey);
    }

    componentWillUnmount = () => {
        // node.removeEventListener("keyup")
    }

    // DetectEnterKey = (e) => {
    //     if (e.key === "Enter") {
    //         // Do work
    //         alert(e.key);
    //     }
    // }

    keyPress = (e) => {
        // alert(e.keyCode + "\n" + e.key);
        if (e.keyCode === 13 || e.key === 'Enter') {
            this.handleConfirmAnswer();
            // if (this.props.nextQsData !== null)
            //     this.SetGivenAnswerOfPairs();
            // else
            //     this.handleConfirmAnswer();
        }
    }

    SetGivenAnswer = (event) => {
        let _answer = String(event.target.value);
        //2021.04.28 - allow spaces.
        // _answer = _answer.replace(new RegExp(' ', 'g'), '');    //remove spaces.
        this.setState({
            givenAnswer: _answer,
        });
    }

    //2021.04.21
    IsAnsInManyPairs = () => {
        return String(this.props.data.Answer).includes('|');
    }

    //2021.04.21
    GetAnsInManyPairsComponent = () => {
        let components = [];

        let Ans_Set = String(this.props.data.Answer).split(';');
        let Ans_Pair = Ans_Set[0].split('|');

        //2021.04.28
        // let answersValue = [];
        // if (this.state.ansInPairsState.length > 0) {
        //     let ansInPairsIndex = this.state.ansInPairsState.findIndex(x => x.QsId === this.props.id);
        //     if (ansInPairsIndex > -1) {
        //         let pairs = String(this.state.ansInPairsState[ansInPairsIndex]);
        //         this.setState({
        //             ansInPairsState: pairs,
        //         });
        //     }
        // }
        // else {

        // if (this.state.ansInPairsState.length <= 0 && this.state.emptyAnswers === '') {
        //     let emptyAnswers = (new Array(Ans_Pair.length).fill("")).join("|");
        //     let givenAnswers = this.state.givenAnswer.length > 0 ? (this.state.givenAnswer !== emptyAnswers ? this.state.givenAnswer : emptyAnswers) : emptyAnswers;
        //     // let givenAnswers = this.state.givenAnswer;
        //     let pairs = this.state.ansInPairsState;
        //     let pairsPrev = this.state.ansInPairsStatePrev;
        //     // pairs.push({ QsId: this.props.id, Answers: givenAnswers });
        //     // pairsPrev.push({ QsId: this.props.id, Answers: givenAnswers });
        //     pairs = givenAnswers;
        //     pairsPrev = givenAnswers;
        //     this.setState({
        //         emptyAnswers: emptyAnswers,
        //         // givenAnswer: String(this.props.ASelectId) !== '-1' ? String(this.props.ASelectId) : emptyAnswers,
        //         ansInPairsState: pairs,
        //         ansInPairsStatePrev: pairsPrev,
        //     });
        //     console.log('first run:\ngiven answers = ' + this.state.givenAnswer + '\nanswers = ' + givenAnswers + '\nanswersPrev = ' + givenAnswers);
        //     // Ans_Pair.map((data, key) => {
        //     //     return answersValue.push(emptyAnswers);
        //     // });
        // }

        let contentComponent = [];
        for (let p = 0; p < Ans_Pair.length; p++) {
            let _value = '';
            if (this.state.givenAnswer !== '-1')
                _value = String(this.state.givenAnswer).split('|')[p];

            contentComponent.push(<input
                type="text"
                className="form-control"
                id={"Q_" + this.props.id + "_sub_answer_" + p}
                name={"Q_" + this.props.id + "_sub_answer_" + p}
                placeholder={Locale("enter-answer-here", this.props.Locale)}
                value={_value}
                onChange={(event) => this.SetGivenAnswerOfPairs(event.target.value, p)}
                disabled={CheckBoolean(this.props.InActive)}
                autoFocus={true}
                onKeyDown={this.keyPress}
            />);
            if (p < Ans_Pair.length - 1)
                components.push(<br />);
        }
        components.push(<div id={"Q_" + this.props.id + "_sub_answer"}>{contentComponent}</div>);

        return components;
    }

    //2021.04.21
    SetGivenAnswerOfPairs = (_value, _idx) => {
        let _valueOfPairs = '';
        let _pairs = [];
        // if (this.state.givenAnswer.length > 0) {
        _pairs = String(this.state.givenAnswer).split('|');
        _pairs[_idx] = _value;
        _valueOfPairs = _pairs.join('|');
        // }
        if (this.props.isDevMode)
            console.log('answer = ' + _value + " | " + _valueOfPairs + ' = ' + _idx);
        this.setState({
            givenAnswer: _valueOfPairs,
        }, () => {
            //2021.04.28
            this.UpdateAnsInPairs(_valueOfPairs);
        });
    }

    //2021.04.28
    UpdateAnsInPairs = (_valueOfPairs) => {
        //for refresh given answers in Ui.
        this.setState({
            ansInPairsState: _valueOfPairs,
        });

        //Update Qs Ui.
        setTimeout(() => {
            this.getContent();
        }, 0);
    }

    handleConfirmAnswer = () => {

        let keyId = this.state.givenAnswer;
        let result = this.state.givenAnswer === String(this.props.data.Answer);
        // alert(result + " " + answer + " " + this.props.id);
        let temp = this.state.givenAnswer;

        //2021.04.21 - to support multiple answer. === start
        let answer = String(this.props.data.Answer);
        if (answer.includes(';')) {
            let answers = answer.split(';');
            result = false;
            // answers.map((data, key) => {
            //     if (data !== '')
            //         if (this.state.givenAnswer === data)
            //             result = true;
            //     return null;
            // });

            for (let set = 0; set < answers.length; set++) {
                // if (answers[set].includes('|')) {
                //     //1 set with more than 1 answer, 2 or more.
                //     let ansInSet = answers[set].split('|');
                //     let ansCorrect = new Array(ansInSet.length).fill(false);
                //     for (let ans = 0; ans < ansInSet.length; ans++) {
                //         if (this.state.givenAnswer === answers[set])
                //             result = true;
                //     }
                // }
                // else {
                //     //1 set with 1 answer.
                //     if (this.state.givenAnswer === answers[set])
                //         result = true;
                // }

                if (this.props.isDevMode)
                    console.log('givenAnswer = ' + this.state.givenAnswer
                        + '\nanswers set = ' + answers[set]);

                if (answers[set] !== '')
                    if (this.state.givenAnswer === answers[set]) {
                        result = true;
                        temp = answers[set];
                        break;
                    }
            }
        }
        //2021.04.21 - to support multiple answer or pairs of answer. === end


        this.setState({
            ToggleNext: true,
            // showResultIcon: true,        //dun show correct/wrong immediately.
        }, async () => {
            //2021.04.28
            if (this.IsAnsInManyPairs())
                this.UpdateAnsInPairs(temp);

            // alert(this.props.id);
            await this.props.setAnswer(result, Number(this.props.id), keyId);

            this.props.nextQs(Number(this.props.id) + 1, this.getNextQsId());
        });
    }

    //2020.12.14
    getNextQsId = () => {
        let _id = Number(this.props.id) + 1;

        // alert((Number(this.props.id) + 1) + "\n" + (_id == Number(this.props.id) + 1) + "\n\n"
        //     + this.props.qsEnd + "\n" + (_id == this.props.qsEnd));

        // alert(_id + "\n" + (_id >= this.props.qsTotal));

        if (_id >= this.props.qsTotal) {
            _id = null;
        }
        else {
            _id = "Q_" + (Number(this.props.id) + 1) + "_sub_answer";
        }

        return _id;
    }

    convertTagOfColorAndNextline = (_content, extra, _isContent) => {

        let content = String(_content);

        if (content.length > 0) {

            if (content.includes('color')) {
                if (content.includes('green'))
                    content = content.replace(new RegExp('<color=green>', 'g'), '<span style="color:' + String(extra) + 'green;font-weight:bold;">&nbsp;');
                else if (content.includes('blue'))
                    content = content.replace(new RegExp('<color=blue>', 'g'), '<span style="color:blue;font-weight:bold;">&nbsp;');
                else if (content.includes('red'))
                    content = content.replace(new RegExp('<color=red>', 'g'), '<span style="color:red;font-weight:bold;">&nbsp;');

                content = content.replace(new RegExp('</color>', 'g'), '&nbsp;</span>');
            }

            if (content.includes('\n')) {
                content = content.replace(new RegExp('\\n', 'g'), '<br />');
            }

            //added on 2020-11-20
            if (content.includes('\t')) {
                content = String(content).replace(new RegExp('\\t', 'g'), '&#9;');
            }

            //2020.11.24
            if (content.includes('<b>')) {
                content = String(content).replace(new RegExp('<b>', 'g'), '<span style="font-weight:bold;">');
                content = String(content).replace(new RegExp('</b>', 'g'), '</span>');
            }

            //2021.08.25
            if (content.includes('<i>')) {
                content = String(content).replace(new RegExp('<i>', 'g'), '<span style="font-style:italic;">');
                content = String(content).replace(new RegExp('</i>', 'g'), '</span>');
            }

            //2020.11.25
            if (content.includes('<tab/>')) {
                content = String(content).replace(new RegExp('<tab/>', 'g'), '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
            }

            //2020.12.18
            if (content.includes('<subjective-instruction>')) {
                let instructionText = Locale("subjective-instruction", this.props.UiLocale);
                content = String(content).replace(new RegExp('<subjective-instruction>', 'g'), instructionText);
            }

            //2021.03.25
            if (content.includes('<img>')) {
                content = String(content).replace(new RegExp('<img>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
                content = String(content).replace(new RegExp('</img>', 'g'), '.jpg" alt="" width="100%">');
            }

            //2021.07.12
            if (content.includes('<jpg>')) {
                content = String(content).replace(new RegExp('<jpg>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
                content = String(content).replace(new RegExp('</jpg>', 'g'), '.jpg" alt="" width="100%">');
            }
            if (content.includes('<png>')) {
                content = String(content).replace(new RegExp('<png>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
                content = String(content).replace(new RegExp('</png>', 'g'), '.png" alt="" width="100%">');
            }

            //2021.04.28
            if (_isContent)
                if (this.IsAnsInManyPairs()) {
                    if (this.state.ansInPairsState !== this.state.emptyAnswers) {

                        // let Ans_Set = String(this.props.data.Answer).split(';');
                        // let Ans_Pair = Ans_Set[0].split('|');
                        // let emptyAnswers = (new Array(Ans_Pair.length).fill(""));

                        let answers = this.state.ansInPairsState !== this.state.emptyAnswers ? this.state.ansInPairsState.split("|") : this.state.emptyAnswers;
                        let answersPrev = this.state.ansInPairsStatePrev !== this.state.emptyAnswers ? this.state.ansInPairsStatePrev.split("|") : this.state.emptyAnswers;
                        if (answers !== answersPrev) {
                            if (this.props.isDevMode)
                                console.log('answers = ' + answers + '\nanswersPrev = ' + answersPrev);
                            for (let key = 0; key < answers.length; key++) {
                                if (content.includes("<input id='" + key + "' disabled='disabled'>")) {
                                    content = String(content).replace(
                                        new RegExp("<input id='" + key + "' disabled='disabled'>", 'g'),
                                        "<input id='" + key + "' value='" + answers[key] + "' disabled='disabled'>"
                                    );
                                }
                                else if (content.includes("<input id='" + key + "' value='" + answersPrev[key] + "' disabled='disabled'>")) {
                                    content = String(content).replace(
                                        new RegExp("<input id='" + key + "' value='" + answersPrev[key] + "' disabled='disabled'>", 'g'),
                                        "<input id='" + key + "' value='" + answers[key] + "' disabled='disabled'>"
                                    );
                                }
                            }
                            // setTimeout(() => {
                            if (this.state.ansInPairsState !== this.state.ansInPairsStatePrev) {
                                this.setState({
                                    ansInPairsStatePrev: this.state.ansInPairsState,
                                });
                                if (this.props.isDevMode)
                                    console.log('update ui\n'
                                        + 'answers = ' + this.state.ansInPairsState + '\n'
                                        + 'answersPrev = ' + this.state.ansInPairsStatePrev);
                            }
                            // }, 200);
                            if (this.props.isDevMode)
                                console.log(content);
                        }
                    }
                }
        }

        //2021.04.28
        // if (_isContent)
        //     this.setState({
        //         Content: content,
        //     });
        // if (content !== this.state.Content) {
        //     this.setState({
        //         Content: content,
        //     });
        //     // }, () => {
        //     //     this.setState({ refreshContent: false });
        //     // });
        //     console.log(this.state.Content);
        // }

        return content;
    }

    getQuestionTitle = (specialMode) => {
        let title = "<h5><b>" + Locale("question", this.props.UiLocale) + " " + (Number(this.props.id) + 1) + "</b></h5>";
        if (String(specialMode) === 'Subjective')
            title += "<h6>(" + Locale("subjective", this.props.UiLocale) + ")</h6>";
        return title;
    }

    getContent = () => {
        // return this.convertTagOfColorAndNextline(String(this.state.Content), 'light', true);
        let content = this.convertTagOfColorAndNextline(String(this.state.Content), 'light', true);
        this.setState({
            Content: content,
        });
    }

    //2021.01.18
    getHints = () => {
        // return '<p> Hints: ' + (String(this.props.data.Hints).length > 0 ? String(this.props.data.Hints) : '') + '</p>';
        return '<p> Hints: ' + this.convertTagOfColorAndNextline(String(this.props.data.Hints), 'light', false) + '</p>';
    }

    //2021.01.18
    getInstruction = () => {
        return '<p>' + this.convertTagOfColorAndNextline(String(this.props.data.Instruction), 'light', false) + '</p>';
    }

    getPicture = (pictureUrl) => {
        let _pictureUrl = String(pictureUrl);

        if (_pictureUrl.length > 0)
            if (_pictureUrl === 'undefined')
                return '';
            else if (_pictureUrl.includes('http'))
                return pictureUrl;
            else
                return 'https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + _pictureUrl + '.jpg';
        else
            return '';

        // // let imageBlob;
        // if (pictureUrl.length > 0)
        //     this.setState({
        //         imageBlob: null,
        //     }, async () => {
        //         await fetch('https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + pictureUrl + ".jpg")
        //             .then(response => response.blob())
        //             .then(images => {
        //                 // imageBlob = response;
        //                 this.setState({
        //                     imageBlob: URL.createObjectURL(images),
        //                 });
        //                 // alert(response);
        //             })
        //             .catch(error => {
        //                 alert("Unable to load picture.\n\n" + error.code + "\n\n" + error.message);
        //             });
        //     });

        // // return imageBlob;
    }

    render = () => {
        return (
            <>
                <div className="row" style={{ paddingBottom: 5, }}>
                    <div className="col col-sm-5">
                        <div dangerouslySetInnerHTML={{ __html: this.getQuestionTitle(this.props.SpecialMode) }} />
                    </div>
                    <div className="col col-sm-7 text-right">
                        {
                            CheckBoolean(this.props.InActive) && String(this.props.ASelectId) === "-1" ?
                                <>
                                    <span>{Locale("quiz-times-up", this.props.UiLocale)}</span>&nbsp;
                                    <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                </>
                                :
                                CheckBoolean(this.props.ShowResultIcon) ?
                                    String(this.props.data.Answer).includes(';') || String(this.props.data.Answer).includes('|') ?
                                        String(this.props.ASelectId) === '' ?
                                            <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                            :
                                            String(this.props.data.Answer).split(';').findIndex(x => x === String(this.props.ASelectId)) > -1 ?
                                                <i className="fa fa-check" style={{ fontSize: "24px", color: "green" }}></i>
                                                :
                                                <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                        :
                                        String(this.props.data.Answer) === String(this.props.ASelectId) ?
                                            <i className="fa fa-check" style={{ fontSize: "24px", color: "green" }}></i>
                                            :
                                            <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                    : null
                        }
                    </div>
                </div>

                <div className="questionsBox">

                    {
                        // String(this.props.SpecialMode) === 'Comprehension' ?
                        //     <>
                        //         <div className="row">
                        //             <div className="col col-sm-12">
                        //                 <div dangerouslySetInnerHTML={{ __html: this.getQuestionInnerTitle() }} />
                        //             </div>
                        //         </div>
                        //         <div className="row">
                        //             <div className="col col-sm-12">
                        //                 <button
                        //                     className="btn btn-primary btn-block" id="showArticle"
                        //                     onClick={() => {
                        //                         this.setState({ toggleShowArticle: !this.state.toggleShowArticle });
                        //                     }}
                        //                 >{this.state.toggleShowArticle ? Locale("hide-article", this.props.Locale) : Locale("show-article", this.props.Locale)}</button>
                        //                 <span>&nbsp;</span>
                        //             </div>
                        //         </div>
                        //         {
                        //             this.state.toggleShowArticle ?
                        //                 <div className="row">
                        //                     <div className="col col-sm-12">
                        //                         <div dangerouslySetInnerHTML={{ __html: this.getExtraContent() }} />
                        //                     </div>
                        //                 </div>
                        //                 : null
                        //         }
                        //     </>
                        //     : null
                    }

                    <div className="questions">
                        {
                            //2021.01.18
                            this.state.hasInstruction ?
                                <div dangerouslySetInnerHTML={{ __html: this.getInstruction() }} />
                                : null
                        }

                        {/* {
                            this.state.refreshContent ?
                                this.RefreshContent()
                                :
                                <div dangerouslySetInnerHTML={{ __html: this.getContent() }} />
                        } */}
                        {/* <div dangerouslySetInnerHTML={{ __html: this.getContent() }} /> */}
                        <div dangerouslySetInnerHTML={{ __html: this.state.Content }} />


                        {/* <p>this is content</p> */}

                        {
                            this.state.hasHints ?
                                <div dangerouslySetInnerHTML={{ __html: this.getHints() }} />
                                : null
                        }
                    </div>

                    {
                        this.state.PictureURL.length > 0 ?
                            <div className="questionPicture" style={{ textAlign: 'center' }}>
                                <img
                                    src={this.getPicture(this.state.PictureURL)}
                                    alt={''}
                                    width={'70%'}
                                />
                                {/* {
                                    this.state.imageBlob != null ?
                                        <img src={this.state.imageBlob} alt="Picture" width={'100%'} />
                                        :
                                        <Spinner animation="border" variant="primary" />
                                } */}
                            </div>
                            : null
                    }

                    <ul className="answerList-1">
                        <li key={'Answer'}>
                            <label>
                                <div className="row centered">
                                    {
                                        // this.props.ShowResultIcon ?
                                        //     null
                                        //     :
                                        //     <div className="col-1 ans-col-2-cols">&nbsp;</div>
                                    }
                                    <div className="col-1 ans-col-2-cols">&nbsp;</div>

                                    <div
                                        className="col-10 ans-col-1"
                                    // className={this.props.ShowResultIcon ? "col-10 ans-col-1" : "col-11 ans-col-1"}
                                    >
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            id={"Q_" + this.props.id + "_sub_answer"}
                                            name={"Q_" + this.props.id + "_sub_answer"}
                                            placeholder={Locale("enter-answer-here", this.props.Locale)}
                                            value={this.state.givenAnswer}
                                            onChange={this.SetGivenAnswer}
                                            disabled={CheckBoolean(this.props.InActive)}

                                            //2020.12.14
                                            autoFocus={true}
                                            onKeyDown={this.keyPress}
                                        /> */}
                                        {
                                            this.IsAnsInManyPairs() ?
                                                this.GetAnsInManyPairsComponent()   //2021.04.21
                                                :
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={"Q_" + this.props.id + "_sub_answer"}
                                                    name={"Q_" + this.props.id + "_sub_answer"}
                                                    placeholder={Locale("enter-answer-here", this.props.Locale)}
                                                    value={this.state.givenAnswer}
                                                    onChange={this.SetGivenAnswer}
                                                    disabled={CheckBoolean(this.props.InActive)}

                                                    //2020.12.14
                                                    autoFocus={true}
                                                    onKeyDown={this.keyPress}
                                                />
                                        }
                                        {
                                            CheckBoolean(this.props.InActive) ?
                                                null
                                                :
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-block"
                                                    onClick={() => this.handleConfirmAnswer()}
                                                    disabled={CheckBoolean(this.props.InActive)}
                                                    style={{ marginTop: 5 }}
                                                >{Locale("confirm", this.props.UiLocale)}</button>
                                        }
                                    </div>

                                    {/* <div className="col-2 ans-col-2-cols"> */}
                                    {
                                        //show the correct answer if answered wrong.
                                        // this.props.ShowResultIcon ?
                                        //     String(this.props.ASelectId) === String(this.props.data.Answer) ?
                                        //         <div className="col-2 ans-col-2-cols">
                                        //             <i className="fa fa-star" style={{ fontSize: "20px", color: "blue" }}></i>
                                        //         </div>
                                        //         :
                                        //         <span>&nbsp;</span>
                                        //     :
                                        //     <span>&nbsp;</span>
                                    }
                                    {/* </div> */}
                                </div>
                            </label>
                        </li>
                    </ul>

                    {
                        this.props.ShowResultIcon ?
                            <>
                                <div className="row custyle">
                                    <table className="table table-striped custab">
                                        <thead>
                                            <tr>
                                                {
                                                    this.IsAnsInManyPairs() || String(this.props.data.Answer).split(';').length > 1 ?
                                                        //2021.04.30
                                                        <>
                                                            <th width="20%">#</th>
                                                            <th width="40%">{Locale("your-answer", this.props.UiLocale)}</th>
                                                            <th width="40%">{Locale("result", this.props.UiLocale)}</th>
                                                        </>
                                                        :
                                                        <>
                                                            <th width="15%">#</th>
                                                            <th width="35%">{Locale("your-answer", this.props.UiLocale)}</th>
                                                            <th width="15%">{Locale("result", this.props.UiLocale)}</th>
                                                            <th width="35%">{Locale("correct-answer", this.props.UiLocale)}</th>
                                                        </>
                                                }
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr key={'PA_Result_' + this.props.id}>
                                                <td>{Number(this.props.qsStart)}</td>
                                                <td>
                                                    <div dangerouslySetInnerHTML={{
                                                        __html:
                                                            this.state.givenAnswer !== "-1" ?
                                                                this.state.givenAnswer.includes('|') ?
                                                                    // this.state.givenAnswer.split('|').map((data, key) => {
                                                                    //     return (data)
                                                                    // })
                                                                    // this.state.givenAnswer.replace(new RegExp('|', 'g'), '<br />')
                                                                    this.state.givenAnswer.split('|').join('<br /><br />')
                                                                    :
                                                                    this.state.givenAnswer !== undefined ?
                                                                        this.state.givenAnswer === "" ?
                                                                            Locale("quiz-ans-empty", this.props.UiLocale)
                                                                            :
                                                                            this.state.givenAnswer
                                                                        :
                                                                        Locale("times-up", this.props.UiLocale)
                                                                : Locale("times-up", this.props.UiLocale)
                                                    }}></div>
                                                </td>
                                                <td>
                                                    {
                                                        String(this.props.data.Answer).includes('|') || String(this.props.data.Answer).includes(';') ?
                                                            String(this.props.ASelectId) === '' ?
                                                                <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                                                :
                                                                String(this.props.data.Answer).split(';').findIndex(x => x === String(this.props.ASelectId)) > -1 ?
                                                                    <i className="fa fa-check" style={{ fontSize: "24px", color: "green" }}></i>
                                                                    :
                                                                    <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                                            :
                                                            String(this.props.data.Answer) === String(this.props.ASelectId) ?
                                                                <i className="fa fa-check" style={{ fontSize: "24px", color: "green" }}></i>
                                                                :
                                                                <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                                    }
                                                </td>
                                                {
                                                    this.IsAnsInManyPairs() ?
                                                        null
                                                        :
                                                        String(this.props.data.Answer).split(';').length > 1 ?
                                                            null
                                                            : <td>{String(this.props.data.Answer)}</td>
                                                }
                                            </tr>

                                            {
                                                this.IsAnsInManyPairs() ?
                                                    //2021.04.30
                                                    <tr>
                                                        <td colSpan={3}>
                                                            {/* <table className="table table-striped custab"> */}
                                                            {/* <table border={0} width={'100%'}> */}
                                                            <table className="table custab">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan={String(this.state.answerSets[0]).split('|').length + 1}
                                                                            align={'center'} style={{ backgroundColor: 'white' }}
                                                                        >{Locale("label-quiz-sub-possible-answers-combination", this.props.UiLocale)}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.answerSets.map((data, key) => {
                                                                            let ansPair = String(data).split('|');
                                                                            let colWidth = Math.floor(90 / ansPair.length);
                                                                            if (data !== '')
                                                                                return (<tr
                                                                                    style={this.state.givenAnswer === data ? { fontWeight: 'bold', color: 'blueviolet' } : {}}
                                                                                // style={{ fontWeight: 'bold' }}
                                                                                >
                                                                                    <td width="10%">{key + 1}</td>
                                                                                    {
                                                                                        ansPair.map((pairValue, pairKey) => {
                                                                                            // return <td width={colWidth + "%"}
                                                                                            //     style={{ fontWeight: this.state.givenAnswer === ansPair ? 'bold' : 'normal' }}
                                                                                            // >{pairValue}</td>

                                                                                            // if (this.state.givenAnswer === pairValue)
                                                                                            //     return <td width={colWidth + "%"}><b>{pairValue}</b></td>
                                                                                            // else
                                                                                            return <td width={colWidth + "%"}>{pairValue}</td>
                                                                                        })
                                                                                    }
                                                                                </tr>);
                                                                            else
                                                                                return null;
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    :
                                                    String(this.props.data.Answer).split(';').length > 1 ?
                                                        <tr>
                                                            <td colSpan={3}>
                                                                {/* <table border={0} width={'100%'}> */}
                                                                <table className="table custab">
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan={2} align={'center'} style={{ backgroundColor: 'white' }}
                                                                            >{Locale("label-quiz-sub-possible-answers", this.props.UiLocale)}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            String(this.props.data.Answer).split(';').map((data, key) => {
                                                                                if (data !== '')
                                                                                    return (<tr
                                                                                        style={this.state.givenAnswer === data ? { fontWeight: 'bold', color: 'blueviolet' } : {}}
                                                                                    >
                                                                                        <td width="10%">{key + 1}</td>
                                                                                        <td width="90%">{data}</td>
                                                                                    </tr>);
                                                                                else
                                                                                    return null;
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        : null
                                            }
                                        </tbody>

                                    </table>
                                </div>
                            </>
                            : null
                    }

                    <div className="questionsRow">
                        <div className="row">
                            <div className="col col-sm-3">
                                <button
                                    className="btn btn-secondary btn-block" id="skipquestions"
                                    onClick={() => this.props.nextQs(Number(this.props.id) + 1, this.getNextQsId())}
                                    hidden={Number(this.props.total) > Number(this.props.id) + 1 ? !this.state.ToggleSkip : true}
                                >{Locale("next", this.props.UiLocale)}</button>
                            </div>
                            <div className="col col-sm-6 text-center">
                                <span>{Number(this.props.id) + 1} / {this.props.total}</span>
                            </div>
                            <div className="col col-sm-3">
                                <a href="#quiz-pos-q-result" hidden={!this.props.ShowResultBtn}>
                                    <button className="btn btn-primary btn-block" >{Locale("goto-result", this.props.UiLocale)}</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <span id={"quiz-pos-q-" + (this.props.id + 1)} >&nbsp;</span> */}
            </>
        );
    }
}