import React from 'react';
import moment from 'moment';

let SearchBy = {
    None: 0,
    LastUpdate: 1,
};

//2020.12.11
export default class QuizHistoryMonitor extends React.Component {

    state = {

        target_Date: 'Invalid date',
        isDataPopulationInProgress: false,
        target_SearchBy_Mode: SearchBy.None,
        target_SearchBy_Keyword: '',
        targets: [],

    }

    componentDidMount = async () => {



    }

    SetTargetDate = (event) => {
        this.setState({
            target_Date: event.target.value,
        });
    }

    SearchQuizHistoryListByCondition = async (mode) => {

        let _mode = '';
        let _keyword = '';
        switch (mode) {
            case SearchBy.LastUpdate:
                _mode = 'LastUpdate';
                _keyword = this.state.target_Date;
                break;
            default:
                break;
        }

        if (_keyword.length <= 0) {
            this.setState({
                isDataPopulationInProgress: false,
            });
            return null;
        }

        this.setState({
            isDataPopulationInProgress: true,
            target_SearchBy_Mode: _mode,
            target_SearchBy_Keyword: _keyword,
            targets: [],
        });
        // let errorMessage = '';
        let _targets = [];

        await this.props.firestore
            .collection('LiveQuizHistory')
            .where(_mode, '==', _keyword)
            .get()
            .then((querySnapshot) => {
                let _regData = querySnapshot.docs.map(doc => doc.data());
                if (_regData.length > 0) {
                    _regData.map((data, key) => {
                        _targets.push(data);
                        return null;
                    });
                }
            })
            .catch(async (error) => {
                // errorMessage += "Search QuizHistoryList FS" + "<br />" + error.code + "<br />" + error.message + "<br /><br />";
            });

        if (_targets.length > 0) {
            _targets.map((data, key) => {
                _targets.fixed = false;
                return null;
            });
        }

        this.setState({
            targets: _targets,
            isDataPopulationInProgress: false,
        });
    }

    FixAllQuizHistoryByFetchedList = async () => {
        let _targets = this.state.targets;
        // let errorMessage = '';
        if (_targets.length > 0) {
            _targets.map(async (data, key) => {
                // if (key <= 0) {
                await this.props.firestore
                    .collection('LiveQuizHistory')
                    .doc(data.Uid)
                    .set({
                        Uid: data.Uid,
                        LastUpdate: moment().format('YYYY-MM-DD HH:mm:ss')
                    })
                    .then(() => {
                        _targets[key].fixed = true;
                        this.setState({
                            targets: _targets,
                        })
                    })
                    .catch(async (error) => {
                        // errorMessage += "Search QuizHistoryList FS" + "<br />" + error.code + "<br />" + error.message + "<br /><br />";
                    });
                // }
            });
            this.setState({
                targets: _targets,
            });
        }
    }

    render = () => {
        return (
            <>
                <div style={{ border: '1px solid gray', width: '100%', height: '60px', padding: 10, }} >
                    <div className='row'>
                        <div className='col-3'>
                            <div style={{ paddingTop: 5 }}><b>Search By Date</b></div>
                        </div>

                        <div className='col-5'>
                            <div style={{ paddingTop: 5 }}>
                                <input
                                    type='text'
                                    onChange={this.SetTargetDate}
                                    value={this.state.target_Date}
                                    // maxLength={40}
                                    size={20}
                                />
                            </div>
                        </div>

                        <div className='col-4'>
                            <div className='row'>
                                <div className='col-1'></div>

                                <div className='col-3'>
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => this.SearchQuizHistoryListByCondition(SearchBy.LastUpdate)}
                                        disabled={this.state.isDataPopulationInProgress}
                                    >Search</button>
                                </div>

                                <div className='col-1'></div>

                                <div className='col-6'>
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => this.FixAllQuizHistoryByFetchedList(SearchBy.LastUpdate)}
                                        disabled={this.state.targets.length <= 0}
                                    >Fix All</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    &nbsp;
                </div>

                <span>&nbsp;</span>

                {
                    this.state.targets.length > 0 ?
                        <>
                            <div style={{ border: '1px solid gray', width: '100%', height: 'auto', padding: 10, }} >
                                <table width='100%' cellPadding='5'>
                                    <tbody>
                                        {
                                            this.state.targets.map((data, key) => {
                                                return (
                                                    <tr>
                                                        <td>{data.Name}</td>
                                                        <td>{data.Uid}</td>
                                                        <td>
                                                            {
                                                                data.fixed ?
                                                                    'fixed'
                                                                    :
                                                                    data.LastUpdate
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <span>&nbsp;</span>
                        </>
                        : null
                }
            </>
        );
    }
}