import React from 'react';
import { Redirect } from 'react-router-dom';

export default class BlankScreen extends React.Component {
    // componentDidMount() {
    //     alert("this is blank page.");
    // }

    handleLogout = async () => {
        await this.props.logout();
    }

    render() {
        if (!this.props.user) {
            return <Redirect link="/login" />;   //back to login screen.
        }
        else {
            return (
                // <Redirect to="/" />

                // <button type="button" className="btn btn-primary btn-block"
                //     onClick={()=>this.handleLogout()}
                // > Sign Out / Logout  </button>

                <div style={{
                    backgroundColor: 'pink', height: '100%', width: '100%',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 50
                }}>
                    <div className="container" style={{ width: '550px' }}>
                        <div className="row">
                            <aside className="col-sm-12">
                                <p style={{
                                    color: 'black', fontSize: 35, textAlign: 'center', fontWeight: 'bold',
                                }}>iKEY Kidz (WEB) logged in</p>
                                <div className="card">
                                    <article className="card-body">

                                        <button type="button" className="btn btn-primary btn-block"
                                            onClick={this.handleLogout}
                                        > Sign Out / Logout  </button>

                                    </article>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            );
        }
    }
}