import React from "react";
import { Redirect } from 'react-router-dom';
import { ProgressBar, Row, Col, Modal, Button } from 'react-bootstrap';
// import ReactHTMLTableToExcel from 'react-html-to-excel';
import moment from 'moment';
// import {
//     // osVersion, osName, browserName, fullBrowserVersion
//     browserName
// } from "react-device-detect";
import './LiveQuizAdminPortal.scss';
import { GlobalSetting } from '../../components/GlobalSetting';
import { CheckBoolean, Delay, GetPropIds } from '../../components/GlobalFunctions';      //2023.09.11

//Jay created 2022.05.24
export default class LiveQuizEventParticipationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isLoading: false,
        isLoaded: false,

        TargetList: [],

        event_selected_organizer: '- select an organizer -',
        event_organizers_dd_buttons: [],

        Event_Participants_Detail_Table: '',
        ShowPopupModal: false,
        PopupEventIdx: -1,
        PopupGroupIdx: -1,
        PopupModalTitle: '',
        ShowPopupListModal: false,
        PopupListTitle: '',
        PopupList: [],
    });

    componentDidMount = () => {
        if (this.props.user === null) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }
        this.LoadEventParticipationList();
    }

    LoadEventParticipationList = async () => {
        this.setState({
            isLoading: false,
            isLoaded: false,
            TargetList: [],
        });

        const { centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log(centerUserId, authorId, authorRoleId, organizerId);

        let _List = [];
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Event/Participation/List/'
            + centerUserId + '/' + authorId + '/' + organizerId + '/' + authorRoleId,
            // Api/LearningCentre/Event/Participation/List/{centerUserId}/{authorId}/{organizerId}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log(JSON.stringify(data));
                // success = data.success;
                if (data.success) {
                    if (data.data !== undefined && data.data !== null)
                        if (Array(data.data).length > 0)
                            _List = data.data;
                        else
                            if (this.props.isDevMode)
                                console.log('Event Participation List is empty.');
                }
                else {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - event - participation - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api -  event - participation - load list (failed)\n' + error.message);
            });

        //populate list.
        if (_List.length > 0) {
            let newList = [];
            _List.map((data, key) => {
                return newList.push({
                    ...data,
                    rowId: key,
                    showDetail: false,
                    total: 0,
                    /*
                    EventName, EventCode,
                    DateStart, DateEnd,
                    Groups: { Id, Name, TotalStudents }
                     */
                });
            });
            newList.map((data, key) => {
                return data.groups = data.groups.sort((x, y) => x.Id - y.Id);    //sort asc by Group Name
            });
            newList.map((data, key) => {
                if (data['groups'] !== null) {
                    let total = 0;
                    data['groups'].map((group, idx) => {
                        group.ParticipantsDetail = { fileName: '', fileExt: '', url: '' };     //2022.10.03
                        return total += Number(group['TotalStudents']);
                    });
                    data['total'] = total;
                }
                return null;
            });
            _List = newList;
            _List.sort((x, y) => y.id - x.id);  //sort desc by event id.
        }

        this.setState({
            isLoading: false,
            isLoaded: true,
            TargetList: _List,
        });
    }

    ShowDetail = (index) => {
        let _List = this.state.TargetList;
        _List.map((data, key) => {
            if (key === index)
                data['showDetail'] = !data['showDetail'];
            return null;
        })
        this.setState({
            TargetList: _List,
        });
    }

    //2022.10.01
    GetEventTotalStudents = (groups) => {
        if (groups === null)
            return '-';

        let total = 0;
        groups.map((group, key) => {
            return total += Number(group['TotalStudents']);
        });
        return total;
    }
    GetEventTotalCerts = (groups) => {
        if (groups === null)
            return '-';

        let total = 0;
        groups.map((group, key) => {
            return total += Number(group['TotalCerts']);
        });
        return total;
    }
    HandleRequestForParticipantsDetails = async (event_idx = -1, group_idx = -1) => {
        this.props.SetLoading('', 'processing request...', true);

        let event = null;
        if (event_idx > -1)
            event = this.state.TargetList[event_idx];

        let group = null;
        if (group_idx > -1)
            group = event.groups[group_idx];

        if (this.props.isDevMode) {
            console.log(JSON.stringify(event));
            console.log(JSON.stringify(group));
        }

        if (event_idx < 0 || event === null || group_idx < 0 || group === null) {
            this.props.SetAlert('Request Failed', 'Invalid request parameters.', true);
            return null;
        }

        const { centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(this.props);
        let url = GlobalSetting.ApiUrl
            + 'Api/LiveQuiz/Event/ParticipantDetail/Report/Request/'
            + centerUserId + '/' + authorId + '/' + organizerId + '/' + authorRoleId + '/'
            + String(event.eventCode) + '/'
            + String(group.Id);
        if (this.props.isDevMode)
            console.log('api = ' + url);
        // return null;

        let success = false;
        let resjData = {};
        // let list = [];
        // let fileGuid = '';
        // let fileName = '';
        // let fileBytes = null;
        await fetch(url,
            // Api/LiveQuiz/Event/ParticipantDetail/Report/Request/{centerUserId}/{authorId}/{organizerId}/{authorRoleId}/{eventCode}/{groupId}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(resj => {
                if (this.props.isDevMode)
                    console.log('res json =\n' + JSON.stringify(resj));
                if (CheckBoolean(resj.success) && String(resj.success).toLowerCase() === 'true') {
                    success = resj.success;
                    // fileGuid = resj.fileGuid;
                    // fileName = resj.data.fileName;
                    // fileBytes = resj.data.fileBytes;
                    // list = resj.data;
                    // resjData = new {
                    //     fileName: resj.data.fileName, fileExt: resj.data.fileExt, eventCode: resj.data.eventCode,
                    //     group: resj.data.groupName, folder: resj.data.folder, container: resj.data.container,
                    // };
                    resjData = resj.data;
                    if (this.props.isDevMode)
                        console.log(JSON.stringify(resjData));
                }
                else {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - req participant detail (failed)\n' + JSON.stringify(resj));
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - req participant detail (failed)\n' + error.message);
            });

        if (success) {
            // this.PopulateTableContent(list, 'event-participants-detail-table_hidden');

            // this.props.SetAlert('Success', 'The requested info has been sent.<br />Please check your email later.', false);

            //download file.
            // this.saveByteArray(fileName, fileBytes, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            // this.saveByteArray(fileName, fileBytes, 'application/vnd.ms-excel');
            // let a = document.createElement('a');
            // // a.href = GlobalSetting.ApiUrl + 'Api/LiveQuiz/Download/' + fileGuid + '/' + fileName;
            // a.href = fileBytes;
            // a.download = fileName;
            // document.body.appendChild(a);
            // a.click();
            // setTimeout(function () {
            //     window.URL.revokeObjectURL(url);
            //     document.body.removeChild(a);
            // }, 100);

            // //download file.
            // url = 'https://ikeynew.blob.core.windows.net/' + resjData.container + '/' + resjData.folder + '/' + resjData.eventCode + '/' + resjData.group + '/' + resjData.fileName + resjData.fileExt;
            // let a = document.createElement('a');
            // a.href = url;
            // a.download = resjData.fileName + resjData.fileExt;
            // // a.target = '_blank';
            // document.body.appendChild(a);
            // a.click();
            // await Delay(200);
            // window.URL.revokeObjectURL(url);
            // document.body.removeChild(a);

            //save link to event.
            url = 'https://ikeynew.blob.core.windows.net/' + resjData.container + '/' + resjData.folder + '/' + resjData.eventCode + '/' + resjData.fileName + resjData.fileExt;
            // url = 'https://ikeynew.blob.core.windows.net/' + resjData.container + '/' + resjData.folder + '/' + resjData.eventCode + '/' + resjData.group + '/' + resjData.fileName + resjData.fileExt;
            group.ParticipantsDetail = { fileName: resjData.fileName, fileExt: resjData.fileExt, url: url };
            event.groups[group_idx] = group;
            let events = this.state.TargetList;
            events[event_idx] = event;
            this.setState({
                PopupEventIdx: event_idx,
                PopupGroupIdx: group_idx,
                PopupModalTitle: event.eventName + ' (' + group.Name + ')',
                TargetList: events,
                ShowPopupModal: true,

                ShowPopupListModal: false,
                PopupListTitle: event.eventName + ' (' + group.Name + ')',
                PopupList: resjData.list !== undefined ? resjData.list : [],
            });
            this.props.CloseAlert();
        }
        else {
            this.props.SetAlert('Failed', 'The request is failed.', false);
        }
    }

    //2022.11.01
    GetTotalValue = (groups, valueName) => {
        if (groups === null || valueName === null)
            return '-';

        let total = 0;
        groups.map((group, key) => {
            if (group[valueName] !== undefined)
                total += Number(group[valueName]);
            return null;
        });
        return total;
    }

    DownloadParticipantsDetail = async () => {
        const event_idx = this.state.PopupEventIdx;
        const group_idx = this.state.PopupGroupIdx;
        const event = this.state.TargetList[event_idx];
        const pdetail = event.groups[group_idx].ParticipantsDetail;
        if (this.props.isDevMode)
            console.log('DownloadParticipantsDetail', JSON.stringify(pdetail));

        //download file.
        const url = pdetail.url;
        let a = document.createElement('a');
        a.href = url;
        a.download = pdetail.fileName + pdetail.fileExt;
        // a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        await Delay(200);
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    RequestSendToEmail = async () => {
        const event_idx = this.state.PopupEventIdx;
        const group_idx = this.state.PopupGroupIdx;
        const event = this.state.TargetList[event_idx];
        const pdetail = event.groups[group_idx].ParticipantsDetail;
        if (this.props.isDevMode)
            console.log(JSON.stringify(pdetail));

        //modal
        let emailModal = {
            fileName: String(pdetail.fileName + pdetail.fileExt),
            fileUrl: String(pdetail.url),
            eventName: String(event.eventName),
            groupName: String(event.groups[group_idx].Name),
            email: String(this.props.user.email),
            name: String(this.props.user.email),
            // email: 'jaychb@gmail.com',
        };
        if (this.props.isDevMode)
            console.log(JSON.stringify(emailModal));
        // return null;

        let message = '';
        await fetch('https://ikey-general-task.azurewebsites.net/api/LiveQuizParticipantDetailDownloadLink?code=RQoWx-EfqjAMd917lM-MfqvA9XhF5n8u5qan3lrWw8itAzFuaDAS1w==',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailModal), // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then(data => {
                message = 'api - send welcome email (' + (data.success ? 'success' : 'failed') + '). ' + JSON.stringify(data);
                if (data.success)
                    this.props.SetAlert('Success', 'The requested file has been sent.<br />Please check your email later.', false);
                else
                    this.props.SetAlert('Failed', 'Failed to send file to your email address.', false);
            })
            .catch(error => {
                message = 'api welcome email failed catch. ' + error.message;
                this.props.SetAlert('Failed', 'Failed to send file to your email address.<br/><br/>' + error.message, false);
            });
        if (this.props.isDevMode)
            console.log(message);
    }

    // DownloadButton = (event, group) => {
    //     return (<ReactHTMLTableToExcel
    //         className="buttonStyleDownloadExcel"
    //         table="event-participants-detail-table_hidden"
    //         filename={String(event.eventName).replace(' ', '-') + '_Participants-Detail_' + String(group.Name).replace(' ', '-') + '.xlsx'}
    //         sheet="Sheet"
    //         buttonText="save file"
    //     />);
    // }

    ShowPopupListTableModal = () => {
        this.PopulateTableContent(this.state.PopupList);
        this.setState({
            ShowPopupListModal: true,
        });
    }
    // ClosePopupListModal = () => {
    //     this.setState({
    //         ShowPopupListModal: false,
    //         PopupListTitle: '',
    //         PopupList: [],
    //     });
    // }

    PopulateTableContent = (list = []) => {
        let _htmlStrings = "<table class='table table-bordered tb-row-hover' padding={10} width='100%'>"
            + "<tr>"
            + "<td>No</td>"
            + "<td>Student Name</td>"
            + "<td>Guardian</td>"
            + "<td>Email Address</td>"
            + "<td>Contact Number</td>"
            + "<td>Organizer's Student</td>"
            + "</tr>";
        if (list.length > 0) {
            list.map((data, key) => {
                return _htmlStrings += "<tr>"
                    + "<td>" + data.no + "</td>"
                    + "<td>" + data.studentName + "</td>"
                    + "<td>" + data.guardian + "</td>"
                    + "<td>" + data.email + "</td>"
                    + "<td>" + data.contactNumber + "</td>"
                    + (data.isOrganizerStudent === undefined ?
                        "<td>-</td>"
                        :
                        "<td>" + (CheckBoolean(data.isOrganizerStudent) ? 'YES' : 'NO') + "</td>")
                    + "</tr>";
            });
        }
        else {
            _htmlStrings += "<tr><td colSpan='6' align='center'>- list is empty -</td></tr>";
        }
        _htmlStrings += "</table>";
        this.setState({ Event_Participants_Detail_Table: _htmlStrings, });
    }

    // saveByteArray = (reportName, byte, contentType) => {
    //     var blob = new Blob([byte], { type: contentType });
    //     var link = document.createElement('a');
    //     link.href = window.URL.createObjectURL(blob);
    //     var fileName = reportName;
    //     link.download = fileName;
    //     link.click();
    // };
    // bin2String = (array) => {
    //     let result = "";
    //     for (let i = 0; i < array.length; i++) {
    //         result += String.fromCharCode(parseInt(array[i], 2));
    //     }
    //     return result;
    // }
    // string2Bin = (str) => {
    //     let result = [];
    //     for (let i = 0; i < str.length; i++) {
    //         result.push(str.charCodeAt(i).toString(2));
    //     }
    //     return result;
    // }

    TargetListTableComponent = () => {
        return (<table className="table table-bordered tb-row-hover" width='100%'>
            <thead>
                <tr>
                    <th style={{ textAlign: 'center' }}>#</th>
                    <th>Event</th>
                    <th style={{ textAlign: 'center' }}>Total</th>
                    <th>Date (Start)</th>
                    <th>Date (End)</th>
                    <th style={{ textAlign: 'center' }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.TargetList.map((data, list_key) => {
                        return (<>
                            <tr key={'row_' + list_key} id={'row_' + list_key} onClick={() => this.ShowDetail(list_key)} style={{ cursor: 'pointer' }}>
                                <td align="center">{list_key + 1}</td>
                                <td><b>{data['eventName']}</b></td>
                                <td align="center">{data['total']}</td>
                                <td>{moment(data['dateStart']).format('lll')}</td>
                                <td>{moment(data['dateEnd']).format('lll')}</td>
                                <td align="center">
                                    <button
                                        type="button"
                                        className="btn-link"
                                        // onClick={() => this.ShowDetail(key)}
                                        onClick={() => { }}
                                    >{data['showDetail'] ? 'Hide' : 'Show'}</button>
                                </td>
                            </tr>
                            {
                                data['showDetail'] === false ? null :
                                    <tr key={'row_detail_' + list_key} id={'row_detail_' + list_key} style={{ backgroundColor: "transparent" }}>
                                        <td colSpan={6}>
                                            <table className="table table-bordered" style={{ marginBottom: 0, }}>
                                                <thead>
                                                    <tr className="hover">
                                                        <td align="center" valign="center" width={150}>Group</td>
                                                        <td align="center" valign="center" width={125}>Total<br />Students</td>
                                                        <td align="center" valign="center" width={125}>Total Certs<br />Generated</td>
                                                        <td align="center" valign="center" width={125}>Organizer's<br />Students</td>
                                                        <td valign="center">Last Update</td>
                                                        <td valign="center" width={255}>Participants' Details</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data['groups'].map((group, stdIdx) => {
                                                        return (<tr key={'row_detail_' + list_key + '_group_' + stdIdx}>
                                                            <td align="center">{group['Name']}</td>
                                                            <td align="center">{group['TotalStudents']}</td>
                                                            <td align="center">{group['TotalCerts']}</td>
                                                            <td align="center">{group['TotalOrganizerStudents']}</td>
                                                            <td>{moment(group['LastUpdate'], 'YYYY-MM-DD hh:mm:ss A').add('hours', 8).format('llll')}</td>
                                                            <td align="center"><button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => this.HandleRequestForParticipantsDetails(list_key, stdIdx)}
                                                            >Request Participants Detail</button></td>
                                                        </tr>);
                                                    })}
                                                    <tr>
                                                        <td></td>
                                                        {/* <td align="center">{this.GetEventTotalStudents(data['groups'])}</td> */}
                                                        {/* <td align="center">{this.GetEventTotalCerts(data['groups'])}</td> */}
                                                        <td align="center">{this.GetTotalValue(data['groups'], 'TotalStudents')}</td>
                                                        <td align="center">{this.GetTotalValue(data['groups'], 'TotalCerts')}</td>
                                                        <td align="center">{this.GetTotalValue(data['groups'], 'TotalOrganizerStudents')}</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                            }
                        </>);
                    })
                }
            </tbody>
        </table>);
    }

    //2022.10.01 === start ===
    // GetOrganizerDisplayName = (organizerId) => {
    //     if (this.props.Organizers !== null) {
    //         const organizer = this.props.Organizers.filter(x => x.OrganizerId === organizerId);
    //         if (organizer !== null)
    //             return organizer.DisplayName;
    //     }
    //     return this.state.event_selected_organizer;
    // }
    // OrganizerListDropdownButtonComponent = () => {
    //     if (this.state.event_organizers_dd_buttons.length > 0)
    //         return this.state.event_organizers_dd_buttons;

    //     if (this.props.Organizers !== null) {
    //         if (this.props.Organizers.length > 0) {
    //             let _organizerButtons = [];
    //             this.props.Organizers.map((data, key) => {
    //                 return _organizerButtons.push(<Dropdown.Item as="button" eventKey={data.OrganizerId} value={data.OrganizerId}>{data.DisplayName}</Dropdown.Item>);
    //             });
    //             this.setState({ event_organizers_dd_buttons: _organizerButtons });
    //             return _organizerButtons;
    //         }
    //     }
    //     return null;
    // }
    // handleSelectOrganizer = (option) => {
    //     this.setState({
    //         event_selected_organizer: option,
    //     }, () => {
    //         console.log(option);
    //         if (this.props.isDevMode)
    //             console.log('Selected Organizer = ' + this.GetOrganizerDisplayName(this.state.event_selected_organizer));
    //     });
    // }
    //2022.10.01 === end ===

    render = () => {
        if (!this.props.user) {
            return <Redirect link="/" />;   //back to login screen.
        }
        return (<>
            <Row className='rowStyle'>
                <Col>
                    <span style={{ fontSize: 20, fontWeight: 'bold' }}>Event Participation Report</span>
                </Col>
                {/* <Col hidden={this.props.isSuperAdmin === false}>
                    <DropdownButton
                        id="event-organizers-dropdown-button"
                        title={this.GetOrganizerDisplayName(this.state.event_selected_organizer)}
                        drop="down"
                        onSelect={this.handleSelectOrganizer}
                    >
                        {this.OrganizerListDropdownButtonComponent()}
                    </DropdownButton>
                </Col> */}
            </Row>
            <p />
            {this.state.isLoading && !this.state.isLoaded ? <ProgressBar animated now={100} /> : null}
            {!this.state.isLoading && this.state.isLoaded ? this.state.TargetList.length > 0 ? this.TargetListTableComponent() : 'List is empty.' : <ProgressBar animated now={100} />}
            {/* <div
                id='event-participants-detail-table_hidden'
                hidden={true}
                dangerouslySetInnerHTML={{ __html: this.state.Event_Participants_Detail_Table }}
            /> */}
            {/* 2022.10.03 */}
            <Modal show={this.state.ShowPopupModal}
                onHide={() => this.setState({ PopupEventIdx: -1, PopupGroupIdx: -1, ShowPopupModal: false })}
                size={'lg'}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="steps-top">{this.state.PopupModalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col style={{ textAlign: 'center', }}><b>Success</b><br />The requested participants detail has been generated.</Col>
                    </Row>
                    <Row style={{ padding: 15, }}>
                        <Col style={{ textAlign: 'right', }}>
                            <button type="button" className="btn btn-primary" onClick={this.DownloadParticipantsDetail} style={{ width: 155, height: 50, }} >Download Excel</button>
                        </Col>
                        <Col style={{ textAlign: 'center', }}>
                            <button type="button" className="btn btn-primary" onClick={this.RequestSendToEmail} style={{ width: 155, height: 50, }} >Send to Email</button>
                        </Col>
                        <Col>
                            <button type="button" className="btn btn-primary" onClick={this.ShowPopupListTableModal} style={{ width: 155, height: 50, }} >View List</button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => this.setState({ PopupEventIdx: -1, PopupGroupIdx: -1, ShowPopupModal: false })}
                    >Close</Button>
                </Modal.Footer>
            </Modal>
            {/* 2022.10.05 */}
            <Modal show={this.state.ShowPopupListModal}
                onHide={() => this.setState({ ShowPopupListModal: false })}
                size={'xl'}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="steps-top">{this.state.PopupListTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Row>
                        <Col style={{ textAlign: 'center', }}>{this.state.Event_Participants_Detail_Table}</Col>
                    </Row> */}
                    <div dangerouslySetInnerHTML={{ __html: this.state.Event_Participants_Detail_Table }}></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => this.setState({ ShowPopupListModal: false })}
                    >Close</Button>
                </Modal.Footer>
            </Modal>
        </>);
    }
}