import React from 'react';
// import moment from 'moment';

let SearchBy = {
    None: 0,
    Phone: 1,
    Email: 2,
    Name: 3,
};

//2020.12.14
let Center = {
    SIM: 'SIM',
    SmartNewton: 'SmartNewton',
    Colin: 'COLIN',
    PTSMC: 'PTSMC', //2021.03.03
    SIM_SPOT2021: 'SIM_SPOT2021_S1',    //2021.03.17
    LittleOppy: 'LITTLEOPPY',   //2021.09.18
};

//2020.12.10
export default class ProfileRemoval extends React.Component {
    state = {

        target_Email: '',
        target_PhoneNo: '0123456789',
        target_Name: '',    //2020.12.14   

        target_SearchBy_Mode: SearchBy.None,
        target_SearchBy_Keyword: '',
        targets: [],

        target_Uid: '',
        isDataPopulationInProgress: false,

        is_Profile_RTDB_Exists: false,
        is_Profile_FS_Exists: false,
        is_Logs_RTDB_Exists: false,
        is_RegData_FS_Exists: false,
        confirmed_Uid: '',

        //2020.12.14
        centerCollection: '',
        centerName: '',
        isDataLoaded: false,

        //2021.01.14
        is_EventJoinedList_FS_Exists: false,
        eventCode: '',

        //2021.03.19
        centerSelected: Center.SIM,
    }

    componentDidMount = async () => {

        //2020.12.14
        await this.SetCenterCollection(Center.SIM);

    }

    //2021.01.14
    ResetData = () => {
        this.setState({
            is_Profile_RTDB_Exists: false,
            is_Profile_FS_Exists: false,
            is_Logs_RTDB_Exists: false,
            is_RegData_FS_Exists: false,
            is_EventJoinedList_FS_Exists: false,
            isDataPopulationInProgress: false,
            confirmed_Uid: '',
        });
    }

    //2020.12.14
    SetCenterCollection = async (option) => {
        let _centerCollection = '';
        let _centerName = '';
        let _eventCode = '';
        switch (option) {
            default:
                break;
            case Center.SIM:
                // _centerCollection = 'RegistrationData';
                _centerCollection = 'SIM';
                _centerName = 'SIM';
                _eventCode = 'spot_2020_sim_1209_1211';
                break;
            case Center.SmartNewton:
                // _centerCollection = 'SmartNewton_RegistrationData';
                _centerCollection = 'SMARTNEWTON';
                _centerName = 'Smart Newton';
                _eventCode = 'abacus_challenge_smartnewton_2021_0206';
                break;
            case Center.Colin:
                // _centerCollection = 'RegistrationData';
                _centerCollection = 'COLIN';
                _centerName = 'COLIN';
                _eventCode = 'spot_2021_bm_colin_2021_0131';
                break;
            case Center.PTSMC:
                // _centerCollection = 'RegistrationData';
                _centerCollection = 'PTSMC';
                _centerName = 'PTSMC';
                _eventCode = 'ptsmc_primary_test_2021_0306_0307';
                break;
            case Center.SIM_SPOT2021:
                // _centerCollection = 'RegistrationData';
                _centerCollection = 'SIM';
                _centerName = 'SIM';
                _eventCode = 'spot_2021_sim_0417_1st_sem';
                break;
            case Center.LittleOppy:
                // _centerCollection = 'RegistrationData';
                _centerCollection = 'LITTLEOPPY';
                _centerName = 'LITTLE OPPY';
                _eventCode = '783f207e4ab84f52af5494eac658bc8a';
                break;
        }
        if (_centerCollection !== '') {
            this.setState({
                target_Email: '',
                target_PhoneNo: '0123456789',
                target_SearchBy_Mode: SearchBy.None,
                target_SearchBy_Keyword: '',
                targets: [],

                target_Uid: '',

                // isDataPopulationInProgress: false,

                // is_Profile_RTDB_Exists: false,
                // is_Profile_FS_Exists: false,
                // is_Logs_RTDB_Exists: false,
                // is_RegData_FS_Exists: false,

                // confirmed_Uid: '',

                //2020.12.14
                centerCollection: _centerCollection,
                centerName: _centerName,
                isDataLoaded: false,
                eventCode: _eventCode,

                //2021.03.19
                centerSelected: option,
            });
            this.ResetData();
        }
    }

    SetTargetPhoneNo = (event) => {
        this.setState({
            target_PhoneNo: event.target.value,
        });
    }

    SetTargetEmail = (event) => {
        this.setState({
            target_Email: String(event.target.value).toLowerCase().replace(new RegExp(' ', 'g'), ''),
        });
    }

    SetTargetName = (event) => {
        this.setState({
            target_Name: event.target.value,
        });
    }

    LoadThisUserData = (_key) => {
        this.setState({
            target_Uid: this.state.targets[_key].Uid,
        }, async () => {
            await this.Fetch_Data_Existence();
        });
    }

    OpenUserProfile = (_key) => {
        let _profileData = '<table >';
        for (let [key, value] of Object.entries(this.state.targets[_key])) {
            _profileData += '<tr><td>' + key + '</td><td>&nbsp; : &nbsp;</td><td>' + value + '</td></tr>';
        }
        _profileData += '</table>';
        this.props.SetAlert('User Profile Data', _profileData);
    }

    SearchRegisteredUserByCondition = async (mode) => {

        let _mode = '';
        let _keyword = '';
        switch (mode) {
            case SearchBy.Phone:
                _mode = 'Contact';
                _keyword = this.state.target_PhoneNo;
                break;
            case SearchBy.Email:
                _mode = 'Email';
                _keyword = this.state.target_Email;
                break;
            case SearchBy.Name:
                _mode = 'Name';
                _keyword = this.state.target_Name;
                break;
            default:
                break;
        }

        if (_keyword.length <= 0) {
            this.setState({
                isDataPopulationInProgress: false,
            });
            return null;
        }

        this.setState({
            isDataPopulationInProgress: true,
            target_SearchBy_Mode: _mode,
            target_SearchBy_Keyword: _keyword,
            targets: [],
        });
        // let errorMessage = '';
        let _targets = [];

        await this.props.firestore
            // .collection('RegistrationData')
            .collection(this.state.centerCollection)
            .where(_mode, '==', _keyword)
            .get()
            .then((querySnapshot) => {
                let _regData = querySnapshot.docs.map(doc => doc.data());
                if (_regData.length > 0) {
                    _regData.map((data, key) => {
                        _targets.push(data);
                        return null;
                    });
                }
            })
            .catch(async (error) => {
                // errorMessage += "Search " + this.state.centerCollection + " FS" + "<br />" + error.code + "<br />" + error.message + "<br /><br />";
            });

        this.setState({
            targets: _targets,
            isDataPopulationInProgress: false,
        });
    }

    //2020.12.14
    SearchUserProfileByCondition = async (mode) => {

        let _mode = '';
        let _keyword = '';
        switch (mode) {
            case SearchBy.Phone:
                _mode = 'Contact';
                _keyword = this.state.target_PhoneNo;
                break;
            case SearchBy.Email:
                _mode = 'Email';
                _keyword = this.state.target_Email;
                break;
            case SearchBy.Name:
                _mode = 'Name';
                _keyword = this.state.target_Name;
                break;
            default:
                break;
        }

        if (_keyword.length <= 0) {
            this.setState({
                isDataPopulationInProgress: false,
            });
            return null;
        }

        this.setState({
            isDataPopulationInProgress: true,
            target_SearchBy_Mode: _mode,
            target_SearchBy_Keyword: _keyword,
            targets: [],
        });
        // let errorMessage = '';
        let _targets = [];

        await this.props.firestore
            .collection('User')
            .where(_mode, '==', _keyword)
            .get()
            .then((querySnapshot) => {
                let _regData = querySnapshot.docs.map(doc => doc.data());
                if (_regData.length > 0) {
                    _regData.map((data, key) => {
                        _targets.push(data);
                        return null;
                    });
                    // alert(JSON.stringify(_targets));
                }
            })
            .catch(async (error) => {
                // errorMessage += "Search User Profile FS" + "<br />" + error.code + "<br />" + error.message + "<br /><br />";
            });

        this.setState({
            targets: _targets,
            isDataPopulationInProgress: false,
        });
    }

    Fetch_Data_Existence = async () => {

        // alert(this.state.target_Uid);
        // return null;

        if (this.state.target_Uid.length <= 0) {
            // this.setState({
            //     is_Profile_RTDB_Exists: false,
            //     is_Profile_FS_Exists: false,
            //     is_Logs_RTDB_Exists: false,
            //     is_RegData_FS_Exists: false,
            //     isDataPopulationInProgress: false,
            //     confirmed_Uid: '',
            // });
            this.ResetData();
            return null;
        }

        // this.setState({
        //     is_Profile_RTDB_Exists: false,
        //     is_Profile_FS_Exists: false,
        //     is_Logs_RTDB_Exists: false,
        //     is_RegData_FS_Exists: false,
        //     isDataPopulationInProgress: true,
        //     confirmed_Uid: '',
        // });
        this.ResetData();

        // let _is_Profile_RTDB_Exists = false;
        // let _is_Profile_FS_Exists = false;
        // let _is_Logs_RTDB_Exists = false;
        // let _is_RegData_FS_Exists = false;
        let errorMessage = '';
        let email = '';

        //Profile RTDB
        await this.props.dbCommon
            .ref('Common/' + this.state.target_Uid)
            .once('value', snapshot => {
                let data = snapshot.val();
                if (snapshot.val() !== null) {
                    // _is_Profile_RTDB_Exists = data.hasOwnProperty('_0LastUpdate');
                    this.setState({ is_Profile_RTDB_Exists: data.hasOwnProperty('_0LastUpdate') });
                    email = data._7Email;
                }
            })
            .catch(async (error) => {
                errorMessage += "Profile RTDB<br />" + error.code + "<br />" + error.message + "<br /><br />";
            });


        //Profile FS
        if (email.length > 0) {
            await this.props.firestore
                .collection('User')
                // .doc(this.state.target_Uid)
                // .where('Uid', '==', this.state.target_Uid)
                .where('Email', '==', email)
                .get()
                .then((querySnapshot) => {
                    let _regData = querySnapshot.docs.map(doc => doc.data());
                    // _regData = _regData[0];
                    // alert(JSON.stringify(_regData));
                    // if (Array(_regData).hasOwnProperty('LastUpdate'))
                    //     _is_Profile_FS_Exists = true;
                    if (_regData.LastUpdate !== null)
                        // _is_Profile_FS_Exists = true;
                        this.setState({ is_Profile_FS_Exists: true });
                })
                .catch(async (error) => {
                    // errorMessage += "Profile FS" + "<br />" + error.code + "<br />" + error.message + "<br /><br />";
                });
        }


        //Logs RTDB
        await this.props.dbLogs
            .ref(this.state.target_Uid)
            .once('value', snapshot => {
                // _is_Logs_RTDB_Exists = snapshot.exists();
                this.setState({ is_Logs_RTDB_Exists: snapshot.exists() });
            })
            .catch(async (error) => {
                errorMessage += "Logs RTDB<br />" + error.code + "<br />" + error.message + "<br /><br />";
            });


        //RegistrationData
        await this.props.firestore
            .collection('LiveQuiz_RegData')
            .doc(this.state.centerCollection)
            .collection('List')
            // .collection('RegistrationData')
            // .doc(this.state.target_Uid)
            .where('Uid', '==', this.state.target_Uid)
            .get()
            .then((querySnapshot) => {
                let _regData = querySnapshot.docs.map(doc => doc.data());
                _regData = _regData[0];
                // alert(JSON.stringify(_regData));
                if (_regData !== undefined) {
                    if (_regData.hasOwnProperty('CreatedDate'))
                        // _is_RegData_FS_Exists = true;
                        this.setState({ is_RegData_FS_Exists: true });
                }
            })
            .catch(async (error) => {
                errorMessage += this.state.centerCollection + "<br />" + error.code + "<br />" + error.message + "<br /><br />";
            });

        //Event Joined List - new location      //2021.01.14
        // let _is_EventJoinedList_FS_Exists = false;
        // alert(this.state.eventCode);
        await this.props.firestore
            .collection('LiveQuizEventJoinedList')
            .doc(this.state.eventCode)
            .collection('List')
            .where('Uid', '==', this.state.target_Uid)
            .get()
            .then((querySnapshot) => {
                let _regData = querySnapshot.docs.map(doc => doc.data());
                _regData = _regData[0];
                if (_regData !== undefined) {
                    if (_regData.hasOwnProperty('Date'))
                        // _is_EventJoinedList_FS_Exists = true;
                        this.setState({ is_EventJoinedList_FS_Exists: true });
                }
            })
            .catch(async (error) => {
                errorMessage += this.state.eventCode + "<br />" + error.code + "<br />" + error.message + "<br /><br />";
            });

        this.setState({
            // is_Profile_RTDB_Exists: _is_Profile_RTDB_Exists,
            // is_Profile_FS_Exists: _is_Profile_FS_Exists,
            // is_Logs_RTDB_Exists: _is_Logs_RTDB_Exists,
            // is_RegData_FS_Exists: _is_RegData_FS_Exists,

            // is_EventJoinedList_FS_Exists: _is_EventJoinedList_FS_Exists,

            isDataPopulationInProgress: false,

            // confirmed_Uid: _is_Profile_RTDB_Exists && _is_Profile_FS_Exists && _is_Logs_RTDB_Exists && _is_RegData_FS_Exists ? this.state.target_Uid : '',

            //2021.01.14
            confirmed_Uid: this.state.target_Uid,
        }, async () => {
            if (errorMessage.length > 0) {
                this.props.SetAlert("Error", errorMessage);
            }
            else {
                if (this.state.confirmed_Uid.length <= 0) {
                    this.props.SetAlert("Result", "Data are incomplete for this Uid.");
                    await this.SearchRegisteredUserByCondition(this.state.target_SearchBy_Mode);
                }
            }
        });
    }

    SetTargetUid = (event) => {
        this.setState({
            target_Uid: event.target.value,
        });
    }

    RemoveAllRelatedData = async () => {

        if (this.state.confirmed_Uid.length <= 0) {
            // this.setState({
            //     is_Profile_RTDB_Exists: false,
            //     is_Profile_FS_Exists: false,
            //     is_Logs_RTDB_Exists: false,
            //     is_RegData_FS_Exists: false,
            //     isDataPopulationInProgress: false,
            //     confirmed_Uid: '',
            // });
            this.ResetData();
            return null;
        }

        this.setState({
            isDataPopulationInProgress: true,
        });

        let errorMessage = '';

        //Profile RTDB
        if (this.state.is_Profile_RTDB_Exists) {
            await this.props.dbCommon
                .ref('Common/' + this.state.confirmed_Uid)
                .remove()
                .then(() => {
                    this.setState({ is_Profile_RTDB_Exists: false });
                })
                .catch(async (error) => {
                    errorMessage += "Profile RTDB<br />" + error.code + "<br />" + error.message + "<br /><br />";
                });
        }

        //Profile FS
        if (this.state.is_Profile_FS_Exists) {
            await this.props.firestore
                .collection('User')
                .doc(this.state.confirmed_Uid)
                .delete()
                .then(() => {
                    this.setState({ is_Profile_FS_Exists: false });
                })
                .catch(async (error) => {
                    errorMessage += "Profile FS<br />" + error.code + "<br />" + error.message + "<br /><br />";
                });
        }

        //Logs RTDB
        if (this.state.is_Logs_RTDB_Exists) {
            await this.props.dbLogs
                .ref(this.state.confirmed_Uid)
                .remove()
                .then(() => {
                    this.setState({ is_Logs_RTDB_Exists: false });
                })
                .catch(async (error) => {
                    errorMessage += "Logs RTDB<br />" + error.code + "<br />" + error.message + "<br /><br />";
                });
        }

        // //Registration Data FS
        // if (this.state.is_RegData_FS_Exists) {
        //     await this.props.firestore
        //         // .collection('RegistrationData')
        //         .collection(this.state.centerCollection)
        //         .doc(this.state.confirmed_Uid)
        //         .delete()
        //         .catch(async (error) => {
        //             errorMessage += this.state.centerCollection + " FS<br />" + error.code + "<br />" + error.message + "<br /><br />";
        //         });
        // }

        //Registration Data FS - new location.      //2021.01.14
        if (this.state.is_RegData_FS_Exists) {
            await this.props.firestore
                .collection('LiveQuiz_RegData')
                .doc(this.state.centerCollection)
                .collection('List')
                .doc(this.state.confirmed_Uid)
                .delete()
                .then(() => {
                    this.setState({ is_RegData_FS_Exists: false });
                })
                .catch(async (error) => {
                    errorMessage += this.state.centerCollection + " FS<br />" + error.code + "<br />" + error.message + "<br /><br />";
                });
        }

        //Event Joined List - new location      //2021.01.14
        if (this.state.is_EventJoinedList_FS_Exists) {
            await this.props.firestore
                .collection('LiveQuizEventJoinedList')
                .doc(this.state.eventCode)
                .collection('List')
                .doc(this.state.confirmed_Uid)
                .delete()
                .then(() => {
                    this.setState({ is_EventJoinedList_FS_Exists: false });
                })
                .catch(async (error) => {
                    errorMessage += this.state.centerCollection + " FS<br />" + error.code + "<br />" + error.message + "<br /><br />";
                });
        }


        this.setState({
            isDataPopulationInProgress: false,
        }, async () => {
            if (errorMessage.length > 0)
                this.props.SetAlert("Error", errorMessage);
            else
                await this.Fetch_Data_Existence();
        });
    }

    SearchAndAddEventCodeProperty = async () => {
        let _eventCode = 'abacus_challenge_2021_0206';
        let _arrayList = [];
        await this.props.firestore
            .collection(this.state.centerCollection)
            .get()
            .then((querySnapshot) => {
                let _regData = querySnapshot.docs.map(doc => doc.data());
                // this.props.SetAlert("Error", JSON.stringify(_regData));
                // navigator.clipboard.writeText(JSON.stringify(_regData));
                // alert("models in clipboard");
                _arrayList = _regData;
            })
            .catch(async (error) => {
                alert(this.state.centerCollection + " (FS)\n\nError:" + error.code + "\nMsg:" + error.message);
            });

        _arrayList.map(async (data, key) => {
            // if (data.Uid === 't7PmqT0p1gRZREJiXATSY6fh0zF2') {
            let _modal = data;
            _modal.EventCode = _eventCode;
            await this.props.firestore
                .collection(this.state.centerCollection)
                .doc(data.Uid)
                .set(_modal)
                // .then(() => {
                //     alert("done");
                // })
                .catch((error) => {
                    alert(this.state.centerCollection + " (FS)\n\n" + data.Name + "\n\nError:" + error.code + "\nMsg:" + error.message);
                });
            // }
            return null;
        });
    }

    render = () => {
        return (
            <>
                <div style={{ border: '1px solid gray', width: '100%', height: 'auto', padding: 10, }} >

                    <div className='row' style={{ paddingBottom: 10 }}>
                        <div className='col'>
                            {localStorage.getItem('email')}
                            <br />
                            {localStorage.getItem('password')}
                        </div>
                    </div>

                    <div className='row' style={{ paddingBottom: 10 }}>
                        <div className='col'>
                            <b>Profile Search / Removal : <span style={{ color: 'blue' }}>{this.state.centerName} - {this.state.centerCollection} - {this.state.centerSelected}</span></b>
                        </div>
                    </div>

                    <div className='row'>
                        {
                            Object.values(Center).map((data, key) => {
                                return (
                                    <div className='col'>
                                        <button type="button" class="btn btn-primary"
                                            onClick={async () => await this.SetCenterCollection(data)}
                                            disabled={this.state.isDataPopulationInProgress}
                                        >{data}</button>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>

                <span>&nbsp;</span>

                {/* <button type="button" className="btn btn-primary"
                    onClick={() => this.SearchAndAddEventCodeProperty()}
                >Temp - Add new property (Event Code) and value (abacus_challenge_2021_0206)</button> */}

                <div style={{ border: '1px solid gray', width: '100%', height: '60px', padding: 10, }} >
                    <div className='row'>
                        <div className='col-3'>
                            <div style={{ paddingTop: 5 }}><b>Search By Phone</b></div>
                        </div>

                        <div className='col-4'>
                            <div style={{ paddingTop: 5 }}>
                                <input
                                    type='text'
                                    onChange={this.SetTargetPhoneNo}
                                    value={this.state.target_PhoneNo}
                                    // maxLength={40}
                                    size={25}
                                />
                            </div>
                        </div>

                        <div className='col-5'>
                            <div className='row'>
                                <div className='col-1'></div>

                                <div className='col-3'>
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => this.SearchRegisteredUserByCondition(SearchBy.Phone)}
                                        disabled={this.state.isDataPopulationInProgress}
                                    >Search</button>
                                </div>

                            </div>
                        </div>
                    </div>
                    &nbsp;
                </div>

                <span>&nbsp;</span>

                <div style={{ border: '1px solid gray', width: '100%', height: '60px', padding: 10, }} >
                    <div className='row'>
                        <div className='col-3'>
                            <div style={{ paddingTop: 5 }}><b>Search By Email</b></div>
                        </div>

                        <div className='col-4'>
                            <div style={{ paddingTop: 5 }}>
                                <input
                                    type='text'
                                    onChange={this.SetTargetEmail}
                                    value={this.state.target_Email}
                                    // maxLength={40}
                                    size={25}
                                />
                            </div>
                        </div>

                        <div className='col-5'>
                            <div className='row'>
                                <div className='col-1'></div>

                                <div className='col-4'>
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => this.SearchRegisteredUserByCondition(SearchBy.Email)}
                                        disabled={this.state.isDataPopulationInProgress}
                                    >Search</button>
                                </div>

                                <div className='col-6'>
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => this.SearchUserProfileByCondition(SearchBy.Email)}
                                        disabled={this.state.isDataPopulationInProgress}
                                    >Search User</button>
                                </div>

                            </div>
                        </div>
                    </div>
                    &nbsp;
                </div>

                <span>&nbsp;</span>

                <div style={{ border: '1px solid gray', width: '100%', height: '60px', padding: 10, }} >
                    <div className='row'>
                        <div className='col-3'>
                            <div style={{ paddingTop: 5 }}><b>Search By Name</b></div>
                        </div>

                        <div className='col-4'>
                            <div style={{ paddingTop: 5 }}>
                                <input
                                    type='text'
                                    onChange={this.SetTargetName}
                                    value={this.state.target_Name}
                                    // maxLength={40}
                                    size={25}
                                />
                            </div>
                        </div>

                        <div className='col-5'>
                            <div className='row'>
                                <div className='col-1'></div>

                                <div className='col-4'>
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => this.SearchRegisteredUserByCondition(SearchBy.Name)}
                                        disabled={this.state.isDataPopulationInProgress}
                                    >Search</button>
                                </div>

                                <div className='col-6'>
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => this.SearchUserProfileByCondition(SearchBy.Name)}
                                        disabled={this.state.isDataPopulationInProgress}
                                    >Search User</button>
                                </div>

                            </div>
                        </div>
                    </div>
                    &nbsp;
                </div>

                <span>&nbsp;</span>

                {
                    this.state.targets.length > 0 ?
                        <>
                            <div style={{ border: '1px solid gray', width: '100%', height: 'auto', padding: 10, }} >
                                <table width='100%' cellPadding='5'>
                                    <tbody>
                                        {
                                            this.state.targets.map((data, key) => {
                                                return (
                                                    <tr>
                                                        <td>{data.Name}</td>
                                                        <td>{data.Uid}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={() => this.LoadThisUserData(key)}
                                                                disabled={this.state.isDataPopulationInProgress}
                                                            >Load this User</button>
                                                            &nbsp;
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={() => this.OpenUserProfile(key)}
                                                                disabled={this.state.isDataPopulationInProgress}
                                                            >See Profile</button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <span>&nbsp;</span>
                        </>
                        : null
                }

                <div style={{ border: '1px solid gray', width: '100%', height: 'auto', padding: 10, }} >
                    <div className='row'>
                        <div className='col-3'>
                            <div style={{ paddingTop: 5 }}><b>Profile Removal</b></div>
                        </div>

                        <div className='col-4'>
                            <div style={{ paddingTop: 5 }}>
                                <input
                                    type='text'
                                    onChange={this.SetTargetUid}
                                    value={this.state.target_Uid}
                                    maxLength={40}
                                    size={25}
                                />
                            </div>
                        </div>

                        <div className='col-5'>
                            <div className='row'>
                                <div className='col-1'></div>

                                <div className='col-3'>
                                    <button type="button" className="btn btn-secondary"
                                        onClick={() => this.ResetData()
                                            // this.setState({
                                            //     target_Uid: '',
                                            //     is_Profile_RTDB_Exists: false,
                                            //     is_Profile_FS_Exists: false,
                                            //     is_Logs_RTDB_Exists: false,
                                            //     is_RegData_FS_Exists: false,
                                            //     confirmed_Uid: '',
                                            // })
                                        }
                                        disabled={this.state.isDataPopulationInProgress}
                                    >Clear</button>
                                </div>

                                <div className='col-3'>
                                    <button type="button" className="btn btn-primary"
                                        onClick={this.Fetch_Data_Existence}
                                        disabled={this.state.isDataPopulationInProgress || this.state.target_Uid.length <= 0}
                                    >Load</button>
                                </div>

                                <div className='col-3'>
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => {
                                            navigator.clipboard.writeText(this.state.target_Uid);
                                            this.props.SetAlert("Copied", this.state.target_Uid);
                                        }}
                                        disabled={this.state.isDataPopulationInProgress || this.state.target_Uid.length <= 0}
                                    >Copy</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <span>&nbsp;</span>

                <div style={{ border: '1px solid gray', width: '100%', padding: 10 }} >
                    <table width='100%' cellPadding='5'>
                        <tbody>
                            <tr>
                                <td>Profile RTDB</td>
                                <td>:</td>
                                <td>{this.state.is_Profile_RTDB_Exists ? 'has data' : 'n/a'}</td>
                            </tr>

                            <tr>
                                <td>Profile FS</td>
                                <td>:</td>
                                <td>{this.state.is_Profile_FS_Exists ? 'has data' : 'n/a'}</td>
                            </tr>

                            <tr>
                                <td>Logs</td>
                                <td>:</td>
                                <td>{this.state.is_Logs_RTDB_Exists ? 'has data' : 'n/a'}</td>
                            </tr>

                            <tr>
                                <td>Registration Data</td>
                                <td>:</td>
                                <td>{this.state.is_RegData_FS_Exists ? 'has data' : 'n/a'}</td>
                            </tr>

                            <tr>
                                <td>Event Join List</td>
                                <td>:</td>
                                <td>{this.state.is_EventJoinedList_FS_Exists ? 'has data' : 'n/a'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                {
                    this.state.confirmed_Uid.length > 0 ?
                        <>
                            <span>&nbsp;</span>
                            <div style={{ border: '1px solid gray', width: '100%', padding: 10 }} >
                                {this.state.confirmed_Uid}
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-primary"
                                    onClick={this.RemoveAllRelatedData}
                                    disabled={this.state.isDataPopulationInProgress}
                                >{this.state.isDataPopulationInProgress ? "processing" : "Remove all related data"}</button>

                            </div>
                        </>
                        : null
                }
            </>
        );
    }
}