import { PDFDocument, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit'
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Row, ProgressBar, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import './PageStyle.scss';
import { Locale } from '../../Localization/CustomLocalization.js';
import _ from 'lodash';
import { GlobalSetting } from '../../components/GlobalSetting';
import { CheckBoolean, Delay } from '../../components/GlobalFunctions';      //2023.09.11
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactHTMLTableToExcel from 'react-html-to-excel';

//2021.10.29
export default class QuizBankRoomTypeFileUploadDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        this.Edit_ParticipantScore_Ref = [];
    }

    getInitState = () => ({
        redirect: false,
        redirectLink: '',

        //Data.
        RoomInfo: {
            Date: '',
            DateEnd: '',
            DateStart: '',
            Duration: 0,
            EventCode: '',
            GroupId: 0,
            Organizer: '',
            OrganizerIdentity: '',
            QuestionSetUniqueId: '',
            RoomCode: '',
            RoomId: 0,
            RoomTitle: '',
            RoomType: 0,
            SubjectName: '',
            TimeEnd: '',
            TimeStart: '',
        },
        ParticipantDataList: [{
            Uid: '',
            Participant: '',
            UploadedFileName: '',
            FileUploadedDate: '',
            Score: 0,
            ScoreIssuedDate: '',

            //2021.11.12
            Profile: {
                Race: '',
                Grade: '',
                Uid: '',
                Contact: '',
                Email: '',
                Score: 0,
                Gender: '',
                ICNo: '',
                Guardian: '',
                Below13: true,
                DistrictArea: '',
                Mode: 'Student',
                NationalState: '',
                PolicyTncAgree: true,
                PolicyTncAgreeDateTime: '',
                LastUpdate: '',
                Name: '',
                Center: '',
                School: '',
            },
            CertSN: '',
            CertData: {
                CertSN: '',
            }
        }],

        IsLoadingParticipantListing: false,

        DummyTextResponse: '',
        HasTextResponse: false,

        RoomStatistic: {
            TotalParticipants: 0,
            TotalScoresIssued: 0,
            TotalSubmittedFiles: 0,
        },

        EventModal: null,
        ParticipantCerts: [],

        //2021.11.16
        hiddenStudentListTableForXLS: null,
        hiddenStudentListTableForXLS_filename: '',

        //2021.12.14
        Cert_bgImgBytes: null,
        AllowDownloadPdfOnWeb: true,
        ToggleCertBatchDownloadUi: false,
        ParticipantProfilePullingDone: false,
    });

    componentDidMount = () => {
        if (this.props.SpecialPageMode === false) {
            if (this.props.user === null) {
                this.setState({
                    redirectLink: '/',
                    redirect: true,
                });
                return null;
            }
        }
        this.setState({
            RoomInfo: null,
            ParticipantDataList: [],
        }, () => {
            this.setState({
                RoomInfo: this.props.RoomInfo,
            }, () => {
                this.LoadParticipantListing();
            });
        });
    }

    componentWillUnmount = () => {
        this.Edit_ParticipantScore_Ref = [];
    }

    ReadValue = (state, field) => {
        switch (field) {
            default: return state === null ? '' : (state[field] === undefined ? '' : state[field]);
            case 'Group':
                if (state === null)
                    return '';
                let _id = state['GroupId'];
                let _groupName = '';
                this.props.GroupOptions.map((data, key) => {
                    if (_id === data.id)
                        _groupName = data.label;
                    return null;
                });
                return _groupName;
            case 'DateLength':
                if (state === null)
                    return '';
                let _dateLength = '';
                let _startDate = state['DateStart'];
                let _endDate = state['DateEnd'];
                if (_startDate === _endDate)
                    _dateLength = moment(_startDate).format('ll');
                else
                    // _dateLength = moment(_startDate).format('mmm d ~ ' + moment(_endDate).date + ', YYYY');
                    _dateLength = moment(_startDate).format('MMM D') + ' ~ ' + moment(_endDate).format('MMM D') + moment(_endDate).format(', YYYY')
                return _dateLength;
            case 'TimeLength':
                if (state === null)
                    return '';
                return state['TimeStart'] + ' ~ ' + state['TimeEnd'];
        }
    }

    SetParticipantScoreCallbackRef = (ref, key) => {
        let index = this.Edit_ParticipantScore_Ref.findIndex(x => x !== null ? x.id === 'Input_Score_' + String(key) : false);
        if (index < 0)
            this.Edit_ParticipantScore_Ref.push(ref);
    }

    LoadParticipantListing = async () => {
        this.setState({ IsLoadingParticipantListing: true, });

        let _List = [];
        let _path = 'pkquiz/' + moment(this.state.RoomInfo.DateStart).format('YYYYMMDD') + '/pkquiz-ranking-live/' + String(this.state.RoomInfo.RoomId);

        if (this.props.isDevMode)
            console.log('path = ' + _path);

        //Get Participant Listing from RTDB.
        await this.props.dbLiveQuiz
            .ref(_path)
            .once('value', snapshot => {
                // handle read data.
                if (snapshot.exists()) {
                    let dataValues = _(snapshot.val()).values().value();
                    _List = dataValues;
                    _List.sort((a, b) => moment(a.FileUploadedDate) - moment(b.FileUploadedDate));

                    //remove null or empty item.
                    if (snapshot.val().length > 1)
                        _List = _List.filter((el) => { return (el !== null && el !== undefined); });

                    //2021.11.12
                    _List.map((data, key) => { data.Profile = null; return null; });

                    if (this.props.isDevMode)
                        console.log(JSON.stringify(_List));
                }
            });

        this.setState({
            ParticipantDataList: _List,
            // IsLoadingParticipantListing: false,
            ParticipantProfilePullingDone: false,
        }, async () => {
            this.Populate_DocumentRoomStatistic();

            // //Init Score value to input field.
            // if (_List.length > 0) {
            //     _List.map((data, key) => {
            //         return this.Edit_ParticipantScore_Ref[key].value = data.Score;
            //     });
            // }

            await this.PullProfileDataOfEachParticipant();

            //2021.11.16
            this.PopulateHiddenStudentListTableForXLS();
        });
    }

    UpdateParticipantScoreViaAPI = async (score = 0, key = -1) => {
        // this.props.SetAlert('', 'fake update score (' + score + ')...', false);
        if (key < 0)
            this.props.SetAlert('Error', 'Invalid Participant(key).');

        let participant = this.state.ParticipantDataList[key];
        if (participant === undefined)
            this.props.SetAlert('Error', 'Invalid Participant.');

        //Start updating.
        this.props.SetAlertWithProgressBar('Updating', 'saving score record for participant <' + participant.Participant + '>', true);

        let useApiUpdateMethod = true;
        if (useApiUpdateMethod) {
            let isUpdateSuccess = false;
            if (this.state.RoomInfo !== undefined && this.state.RoomInfo !== null) {
                //Update Score via APi.
                await fetch(GlobalSetting.ApiUrl
                    + 'Api/LearningCentre/Quiz/Room/File/UpdateScore/'
                    + this.state.RoomInfo.RoomCode + '/'
                    + this.state.RoomInfo.RoomId + '/'
                    + participant.Uid + '/'
                    + score,
                    // Api/LearningCentre/Quiz/Room/File/UpdateScore/{roomcode}/{roomid}/{uid}/{score}
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (!data.success)
                            this.props.SetAlert('Error', 'api - score update (failed)\n' + JSON.stringify(data));
                        else
                            isUpdateSuccess = true;
                    })
                    .catch(error => {
                        this.props.SetAlert('Error', error.message);
                    });
            }
            else {
                this.props.SetAlert('Error', 'Invalid Room.');
            }

            //update ui cache.
            if (isUpdateSuccess) {
                await this.UpdateParticipantCachedData(key);
                // this.props.CloseAlert();
            }
        }
        else {
            //Firebase RTDB - direct update method.

            let updates = {};
            let currentDateTime = moment.utc().format('YYYY-MM-DD HH:mm:ss');

            let participantPath = '/pkquiz-personal-data/' + String(participant.Uid) + '/' + String(this.state.RoomInfo.RoomId);
            updates[participantPath + '/Score'] = score;
            updates[participantPath + '/ScoreIssuedDate'] = currentDateTime;

            let listingPath = '/pkquiz-ranking-live/' + String(this.state.RoomInfo.RoomId) + '/' + String(participant.Uid);
            updates[listingPath + '/Score'] = score;
            updates[listingPath + '/ScoreIssuedDate'] = currentDateTime;

            let _path = 'pkquiz/' + moment(this.state.RoomInfo.DateStart).format('YYYYMMDD');
            await this.props.dbLiveQuiz.ref(_path).update(updates);

            this.props.CloseAlert();
        }
        // this.props.CloseAlert();
    }

    UpdateParticipantCachedData = async (index) => {
        if (index > -1) {
            let participants = this.state.ParticipantDataList;

            let _data = '';
            let _path = 'pkquiz/'
                + moment(this.state.RoomInfo.DateStart).format('YYYYMMDD')
                + '/pkquiz-personal-data/' + participants[index].Uid + '/'
                + String(this.state.RoomInfo.RoomId) + '/ScoreIssuedDate';

            if (this.props.isDevMode)
                console.log('path = ' + _path);

            //Get Participant Listing from RTDB.
            await this.props.dbLiveQuiz
                .ref(_path)
                .once('value', snapshot => {
                    // handle read data.
                    if (snapshot.exists()) {
                        // let dataValues = _(snapshot.val()).values().value();
                        // _data = dataValues;

                        // //remove null or empty item.
                        // if (snapshot.val().length > 1)
                        //     _data = _data.filter((el) => { return (el !== null && el !== undefined); });

                        // if (this.props.isDevMode)
                        //     console.log(JSON.stringify(_data));

                        _data = snapshot.val();
                    }
                });

            if (_data.length > 0) {
                participants[index].ScoreIssuedDate = _data;
                this.setState({
                    ParticipantDataList: participants,
                }, () => {
                    this.Populate_DocumentRoomStatistic();
                    this.UpdateSerialCounterForParticipantAfterScoreIssued(index);
                });
            }
        }
    }

    //2021.11.12
    UpdateSerialCounterForParticipantAfterScoreIssued = async (index) => {

        let _timeStampId = '';

        //check if this room belongs to any event.
        let isEventQuiz = { Result: false, EventCode: '', EventModal: null };
        isEventQuiz = this.props.CheckIfRoomBelongsToEvent(this.state.roomCode);
        if (this.props.isDevMode)
            console.log(JSON.stringify(isEventQuiz));

        //Get event.
        if (isEventQuiz.Result === false)
            await this.GetEventModal();
        else
            this.setState({ EventModal: isEventQuiz.EventModal, });

        if (this.state.EventModal !== null) {
            let eventCode = String(this.state.EventModal.EventCode);
            let participant = this.state.ParticipantDataList[index];
            let loc = eventCode; //+ '/' + String(this.state.RoomInfo.RoomId);

            //Get Counter. Check if value exist.
            let isSerialCounterExist = false;
            let serialCounterModal = {
                CertSerialPrefix: '',
                EventCode: '',
                Counter: 0,
                LastUpdate: '',
                // RoomId: 0,
            };
            await this.props.dbLQ_SN_Counter.ref(loc).once('value').then(snapshot => {
                if (snapshot.exists()) {
                    isSerialCounterExist = true;
                    serialCounterModal = snapshot.val();
                }
            }).catch(error => { console.log('SN Counter (Fetch) Error = ' + error); });

            //Generate a new SN for this cert.
            serialCounterModal.Counter = serialCounterModal.Counter + 1;

            //Fill the returned SN into this cert info.
            let fullTextCounter = '';
            if (String(serialCounterModal.Counter).length < 6) {
                let loopCount = 6 - String(serialCounterModal.Counter).length;
                for (var i = 0; i < loopCount; i++)
                    fullTextCounter += '0';
                fullTextCounter += String(serialCounterModal.Counter);
            }
            else {
                fullTextCounter = String(serialCounterModal.Counter);
            }
            let serialNumberText = this.state.EventModal.CertSerialPrefix + fullTextCounter;
            if (this.props.isDevMode)
                console.log('serialNumberText = ' + serialNumberText);

            let canUpdateSerialCounter = false;

            //Get Cert info.
            let certInfoModal = await this.GetCertInfo(participant.Uid, eventCode);
            let _current = moment.utc();
            if (certInfoModal !== null) {
                //cert exist.
                let updateModal = {};

                //CertSN.
                if (certInfoModal.hasOwnProperty('CertSN') === false) {
                    updateModal['CertSN'] = serialNumberText;
                }
                else {
                    //only those without SN will be updated.
                    if (certInfoModal.CertSN === '') {
                        updateModal['CertSN'] = serialNumberText;
                        updateModal['CertIssuedDate'] = _current.format('YYYY-MM-DD HH:mm:ss');     //2021.11.27
                        canUpdateSerialCounter = true;
                    }
                }

                //School Name.
                if (certInfoModal.hasOwnProperty('SchoolName') === false) {
                    updateModal['SchoolName'] = participant.Profile.School;
                }
                else {
                    if (certInfoModal.SchoolName === '') {
                        updateModal['SchoolName'] = participant.Profile.School;
                    }
                }

                //update cert record.
                if (updateModal.hasOwnProperty('CertSN') || updateModal.hasOwnProperty('SchoolName')) {
                    await this.props.firestore
                        .collection('LiveQuiz_Certifications')
                        .doc(participant.Uid)
                        .collection('Certificates')
                        .doc(String(certInfoModal.Id))
                        .update(updateModal);
                }
                _timeStampId = String(certInfoModal.Id);
            }
            else {
                //new cert record.
                if (this.state.ParticipantCerts.length === 0) {
                    await this.props.firestore
                        .collection('LiveQuiz_Certifications')
                        .doc(participant.Uid)
                        .set({
                            Counter: 1,
                            LastUpdatedOnUtc: _current.format('YYYY-MM-DD HH:mm:ss'),
                            Uid: participant.Uid,
                        });
                }
                else {
                    await this.props.firestore
                        .collection('LiveQuiz_Certifications')
                        .doc(participant.Uid)
                        .update({
                            Counter: this.state.ParticipantCerts.length + 1,
                            LastUpdatedOnUtc: _current.format('YYYY-MM-DD HH:mm:ss'),
                        });
                }
                _timeStampId = _current.valueOf().toString();
                await this.props.firestore
                    .collection('LiveQuiz_Certifications')
                    .doc(participant.Uid)
                    .collection('Certificates')
                    .doc(_timeStampId)
                    .set({
                        CertSN: serialNumberText,
                        CertDownloadEnabled: CheckBoolean(this.state.EventModal.CertDownloadEnabled),
                        CreatedOnUtc: _current.format('YYYY-MM-DD HH:mm:ss'),
                        EventCode: eventCode,
                        Participant: participant.Participant,
                        SchoolName: participant.Profile.School,
                        CertIssuedDate: _current.format('YYYY-MM-DD HH:mm:ss'),     //2021.11.27
                    });
                canUpdateSerialCounter = true;
            }
            if (this.props.isDevMode)
                console.log(certInfoModal.Id + ' = ' + JSON.stringify(certInfoModal));

            //Personal result update modal.
            let personalResultModal = {
                FileUploadedDate: participant.FileUploadedDate,
                Participant: participant.Participant,
                Score: participant.Score,
                ScoreIssuedDate: participant.ScoreIssuedDate,
                Uid: participant.Uid,
                UploadedFileName: participant.UploadedFileName,

                SchoolName: participant.Profile.School,
                CertSN: canUpdateSerialCounter ?
                    serialNumberText
                    : participant.hasOwnProperty('CertSN') ?
                        participant.CertSN !== '' ?
                            participant.CertSN
                            : ''
                        : '',
            };
            //Update Participant personal record.
            await this.props.dbLiveQuiz
                .ref('pkquiz/'
                    + moment(this.state.RoomInfo.DateStart).format('YYYYMMDD')
                    + '/pkquiz-personal-data/' + participant.Uid + '/'
                    + String(this.state.RoomInfo.RoomId))
                .set(personalResultModal);
            //Update Participant result listing.
            await this.props.dbLiveQuiz
                .ref('pkquiz/'
                    + moment(this.state.RoomInfo.DateStart).format('YYYYMMDD')
                    + '/pkquiz-ranking-live/' + String(this.state.RoomInfo.RoomId) + '/'
                    + participant.Uid)
                .set(personalResultModal);

            //#region revamped 2021.11.12
            // //Get & Update Participant personal record.
            // let personalResult_path = 'pkquiz/'
            //     + moment(this.state.RoomInfo.DateStart).format('YYYYMMDD')
            //     + '/pkquiz-personal-data/' + participant.Uid + '/'
            //     + String(this.state.RoomInfo.RoomId);
            // let personalResult = await this.GetParticipantDataRTDB(personalResult_path);
            // if (personalResult !== null) {
            //     await this.props.dbLiveQuiz
            //         .ref(personalResult_path)
            //         .set(personalResultModal);
            // }

            // //Get & Update Participant result listing.
            // let resultListing_path = 'pkquiz/'
            //     + moment(this.state.RoomInfo.DateStart).format('YYYYMMDD')
            //     + '/pkquiz-ranking-live/' + String(this.state.RoomInfo.RoomId) + '/'
            //     + participant.Uid;
            // let resultListing = await this.GetParticipantDataRTDB(resultListing_path);
            // if (resultListing !== null) {
            //     await this.props.dbLiveQuiz
            //         .ref(resultListing_path)
            //         .set(personalResultModal);
            // }
            //#endregion

            //Update SN counter in RTDB. Last step.
            if (isSerialCounterExist) {
                if (canUpdateSerialCounter) {
                    let updates = {};
                    updates['Counter'] = serialCounterModal.Counter;
                    updates['LastUpdate'] = _current.format('YYYY-MM-DD HH:mm:ss.sss');
                    await this.props.dbLQ_SN_Counter.ref(loc).update(updates);
                }
            }
            else {
                //first time only.
                // if (canUpdateSerialCounter) {
                serialCounterModal = {
                    CertSerialPrefix: String(this.state.EventModal.CertSerialPrefix),
                    EventCode: eventCode,
                    Counter: 1,
                    LastUpdate: _current.format('YYYY-MM-DD HH:mm:ss.sss'),
                    // RoomId: this.state.RoomInfo.RoomId,
                };
                await this.props.dbLQ_SN_Counter.ref(loc).set(serialCounterModal);
                // }
            }
            //done.

            //2021.11.29
            //Trigger Api to sync to db for Participant's Serial Number.
            if (_timeStampId.length > 0)
                await this.Trigger_ToSync_Event_Cert_ParticipantSerialNumber(participant.Uid, _timeStampId);
        }
        this.props.CloseAlert();
    }

    //2021.11.29
    Trigger_ToSync_Event_Cert_ParticipantSerialNumber = async (_uid = '', _timeStampId = '') => {
        //Trigger Sync to db via API.
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Event/Certificate/Participant/SerialNumber/Sync/'
            + String(this.state.EventModal.EventCode) + '/'
            + _uid + '/'
            + _timeStampId,
            // Api/LearningCentre/Event/Certificate/Participant/SerialNumber/Sync/{eventcode}/{uid}/{timestampid}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (!data.success)
                    if (this.props.isDevMode)
                        console.log('Error', 'api - event cert user sn sync (failed)\n' + JSON.stringify(data));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - event cert user sn sync (failed)\n' + error.message);
            });
    }

    //2021.11.12
    GetEventModal = async () => {
        let _eventModal = null;
        await this.props.firestore
            .collection('LiveQuiz_UpcomingEvents')
            .where('EventCode', '==', this.state.RoomInfo.EventCode)
            .limit(1)
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                    });
                    if (dataArray.length > 0) {
                        _eventModal = dataArray[0];
                    }
                }
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(_eventModal));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });
        this.setState({
            EventModal: _eventModal,
        });
    }

    //2021.11.12
    GetCertInfo = async (uid, eventCode) => {
        let certInfoModal = null;
        let certs = [];
        this.setState({ ParticipantCerts: certs, });
        await this.props.firestore
            .collection('LiveQuiz_Certifications')
            .doc(String(uid))
            .collection('Certificates')
            // .where('EventCode', '==', String(eventCode))
            // .limit(1)
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                        // console.log(doc.id);
                        dataArray[dataArray.length - 1].Id = doc.id;    //important
                    });
                    if (dataArray.length > 0) {
                        certs = dataArray;
                        let findIndex = dataArray.findIndex(x => x.EventCode === eventCode);
                        if (findIndex > -1) {
                            certInfoModal = dataArray[findIndex];
                        }
                    }
                }
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(_certInfoModal));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });
        this.setState({ ParticipantCerts: certs, });
        return certInfoModal;
    }

    //#region codes simplified - 2021.11.12
    // //2021.11.12
    // GetPersonalResult = async (uid) => {
    //     let result = null;
    //     let _path = 'pkquiz/'
    //         + moment(this.state.RoomInfo.DateStart).format('YYYYMMDD')
    //         + '/pkquiz-personal-data/' + String(uid) + '/'
    //         + String(this.state.RoomInfo.RoomId);
    //     await this.props.dbLiveQuiz
    //         .ref(_path)
    //         .once('value', snapshot => {
    //             if (snapshot.exists()) {
    //                 result = snapshot.val();
    //             }
    //         });
    //     return result;
    // }

    // //2021.11.12
    // GetResultListing = async (uid) => {
    //     let result = null;
    //     let _path = 'pkquiz/'
    //         + moment(this.state.RoomInfo.DateStart).format('YYYYMMDD')
    //         + '/pkquiz-ranking-live/' + String(this.state.RoomInfo.RoomId) + '/'
    //         + String(uid);
    //     await this.props.dbLiveQuiz
    //         .ref(_path)
    //         .once('value', snapshot => {
    //             if (snapshot.exists()) {
    //                 result = snapshot.val();
    //             }
    //         });
    //     return result;
    // }
    //#endregion

    //2021.11.12
    GetParticipantDataRTDB = async (path) => {
        let result = null;
        await this.props.dbLiveQuiz
            .ref(path)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    result = snapshot.val();
                }
            });
        return result;
    }

    DownloadFile = async (mode = '', fileName = '') => {
        if (mode === 'single') {
            if (
                fileName !== '' &&
                (fileName.includes('.txt') || fileName.includes('.doc') || fileName.includes('.docx') || fileName.includes('.rtf'))
            ) {
                // this.props.SetAlert('', 'fake downloading single file...', false);
                if (fileName.includes('.txt') === false) {
                    window.open('https://ikeynew.blob.core.windows.net/ikeyquiz/' + String(this.state.RoomInfo.RoomId) + '/' + fileName, '_new');
                }
                else {
                    this.setState({ HasTextResponse: false });
                    this.props.SetLoading('', 'downloading file...', false);
                    await Delay(1000);

                    let path = 'https://ikeynew.blob.core.windows.net/ikeyquiz/' + String(this.state.RoomInfo.RoomId) + '/' + fileName;

                    let getText = () => {
                        // read text from URL location
                        var request = new XMLHttpRequest();
                        request.open('GET', path, true);
                        request.send(null);
                        request.onreadystatechange = async () => {
                            if (request.readyState === 4 && request.status === 200) {
                                var type = request.getResponseHeader('Content-Type');
                                // console.log('response = ' + request.response);
                                // console.log('responseText = ' + request.responseText);
                                // console.log('responseType = ' + request.responseType);
                                // console.log('responseURL = ' + request.responseURL);
                                // console.log('responseXML = ' + request.responseXML);

                                // if (type.indexOf("text") !== 1) {
                                if (type.includes("text")) {
                                    let responseText = request.responseText;
                                    this.setState({ HasTextResponse: true });

                                    //trigger download.
                                    var element = document.createElement('a');
                                    element.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(responseText);
                                    element.target = '_blank';
                                    element.download = fileName;
                                    element.style.display = 'none';
                                    document.body.appendChild(element);
                                    element.click();
                                    document.body.removeChild(element);

                                    await Delay(200);
                                    this.props.CloseAlert();
                                }
                            }
                            else {
                                await Delay(500);
                                if (this.state.HasTextResponse === false) {
                                    this.props.SetAlert('Error', 'Unable to download file.<br />File not found.');
                                }
                            }
                        }
                    };
                    getText();
                    //done.
                }
            }
            else {
                this.props.SetAlert('Error', 'Unable to download file.<br />Filename is invalid.');
            }
        }
        else {
            //download all files in a container folder, seperated by RoomId.
            this.props.SetLoading('', 'downloading file...', false);
            await Delay(1000);

            // let isRequestSuccess = false;
            // let errorMessage = '';

            //Request zip file download url for all uploaded files via APi.
            await fetch(GlobalSetting.ApiUrl
                + 'Api/LearningCentre/Quiz/Room/File/RequestDownloadAll/'
                + this.state.RoomInfo.RoomCode + '/'
                + this.state.RoomInfo.RoomId,
                // Api/LearningCentre/Quiz/Room/File/RequestDownloadAll/{roomcode}/{room_id}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if (!data.success) {
                        this.props.SetAlert('Error', 'Unable to download file.<br /><br />' + data.message);
                    }
                    else if (data.success) {
                        // isRequestSuccess = true;

                        if (this.props.isDevMode) {
                            console.log(JSON.stringify(data));
                            if (data.hasOwnProperty('data')) {
                                var blobs = JSON.parse(data.data);
                                blobs.map((data, key) => {
                                    if (this.props.isDevMode)
                                        console.log(key + ' : ' + decodeURI(data));
                                    return null;
                                });
                            }
                        }

                        //trigger download file.
                        // window.open('https://ikeynew.blob.core.windows.net/ikeyquiz/' + String(this.state.RoomInfo.RoomId) + '/' + fileName, '_new');
                        window.open(String(data.url), '_new');

                        this.props.CloseAlert();
                    }
                    else {
                        this.props.SetAlert('Error', 'api - get file dl url (failed)<br /><br />' + JSON.stringify(data));
                    }
                })
                .catch(error => {
                    this.props.SetAlert('Error', error.message);
                });
        }
    }

    Populate_DocumentRoomStatistic = () => {
        let _statistic = this.state.RoomStatistic;
        if (this.state.ParticipantDataList.length > 0) {
            let _counter_scoreIssued = 0;
            let _counter_fileUploaded = 0;
            this.state.ParticipantDataList.map((data, key) => {
                if (data.ScoreIssuedDate.length > 0 && data.Score > 0)
                    _counter_scoreIssued += 1;
                if (data.FileUploadedDate.length > 0 && data.UploadedFileName.length > 0)
                    _counter_fileUploaded += 1;
                return null;
            });
            _statistic.TotalScoresIssued = _counter_scoreIssued;
            _statistic.TotalSubmittedFiles = _counter_fileUploaded;
            _statistic.TotalParticipants = this.state.ParticipantDataList.length;
        }
        this.setState({
            RoomStatistic: _statistic,
        }, async () => {
            // await this.props.dbLiveQuiz
            //     .ref('pkquiz/' + moment(this.state.RoomInfo.DateStart).format('YYYYMMDD') + '/pkquiz-room-file-statistic/' + String(this.state.RoomInfo.RoomId))
            //     .set(this.state.RoomStatistic);
        });
    }

    //2021.11.12
    PullProfileDataOfEachParticipant = async () => {
        if (this.state.ParticipantDataList.length > 0) {
            let participantData = this.state.ParticipantDataList;
            await Promise.all(
                participantData.map(async (data, key) => {
                    let canFetchProfile = true;
                    // //2021.11.16 - disable checking as now require other info from Profile.
                    // if (data.hasOwnProperty('SchoolName')) {
                    //     if (String(data.SchoolName) !== '') {
                    //         //Bypass fetching Profile to lessen resource usage & time.
                    //         participantData[key].Profile = { School: String(data.SchoolName) };
                    //         // console.log(data.Participant);
                    //         canFetchProfile = false;
                    //     }
                    // }

                    //Get Profile.
                    if (canFetchProfile) {
                        // participantData[key].Profile = null;
                        await this.props.firestore
                            .collection("User")
                            .doc(data.Uid)
                            .get()
                            .then(doc => {
                                if (doc.exists) {
                                    if (doc.data().hasOwnProperty('School'))
                                        participantData[key].Profile = doc.data();
                                }
                                if (this.props.isDevMode)
                                    console.log(JSON.stringify(doc.data()));
                            })
                            .catch(error => {
                                if (this.props.isDevMode)
                                    console.log(error.message);
                            });
                        // if (participantData[key].Profile === null) {
                        //     participantData[key].Profile = { School: '- school name is not available -' };
                        // }
                    }
                    return null;
                })
            );
            this.setState({
                ParticipantDataList: participantData,
                IsLoadingParticipantListing: false,
                ParticipantProfilePullingDone: true,    //2021.12.15
            }, () => {
                //Init Score value to input field.
                if (this.state.ParticipantDataList.length > 0) {
                    this.state.ParticipantDataList.map((data, key) => {
                        return this.Edit_ParticipantScore_Ref[key].value = data.Score;
                    });
                }
            });
        }
    }

    //2021.11.16
    PopulateHiddenStudentListTableForXLS = () => {
        let htmlProps = '<table id="table-to-xls" hidden><tbody>';
        htmlProps += '<tr><td colspan="7">&nbsp;</td></tr>';
        htmlProps += '<tr><td></td><td>Room Code</td><td colspan="5" align="left">' + this.state.RoomInfo.RoomCode + '</td></tr>';
        htmlProps += '<tr><td></td><td>Room Title</td><td colspan="5" align="left">' + this.state.RoomInfo.RoomTitle + '</td></tr>';
        htmlProps += '<tr><td></td><td>Total Participants</td><td colspan="5" align="left">' + this.state.RoomStatistic.TotalParticipants + '</td></tr>';
        htmlProps += '<tr><td></td><td>Total Submitted Files</td><td colspan="5" align="left">' + this.state.RoomStatistic.TotalSubmittedFiles + '</td></tr>';
        htmlProps += '<tr><td></td><td>Total Scores Issued</td><td colspan="5" align="left">' + this.state.RoomStatistic.TotalScoresIssued + '</td></tr>';
        htmlProps += '<tr><td></td><td>Essay Title</td><td colspan="5" align="left">' + this.ReadValue(this.state.RoomInfo, 'Remark') + '</td></tr>';
        htmlProps += '<tr><td></td><td>Group</td><td colspan="5" align="left">' + this.ReadValue(this.state.RoomInfo, 'Group') + '</td></tr>';
        htmlProps += '<tr><td></td><td>Subject</td><td colspan="5" align="left">' + this.ReadValue(this.state.RoomInfo, 'SubjectName') + '</td></tr>';
        htmlProps += '<tr><td></td><td>Date</td><td colspan="5" align="left">' + this.ReadValue(this.state.RoomInfo, 'DateLength') + '</td></tr>';
        htmlProps += '<tr><td></td><td>Length</td><td colspan="5" align="left">' + this.ReadValue(this.state.RoomInfo, 'TimeLength') + '</td></tr>';
        htmlProps += '<tr><td colspan="7">&nbsp;</td></tr>';
        htmlProps += '<tr>';
        htmlProps += '<th>#</th><th>Student Name</th><th>Filename</th>';
        htmlProps += '<th>Gender</th><th>Race</th><th>School</th><th>State</th>';
        htmlProps += '</tr>';
        if (this.state.ParticipantDataList.length > 0) {
            this.state.ParticipantDataList.map((data, key) => {
                htmlProps += '<tr height="15px">';
                htmlProps += '<td>' + (key + 1) + '</td>';
                htmlProps += '<td>' + data.Participant + '</td>';
                htmlProps += '<td>' + data.UploadedFileName + '</td>';
                htmlProps += '<td>' + (data.Profile === null ? '' : data.Profile.Gender) + '</td>';
                htmlProps += '<td>' + (data.Profile === null ? '' : data.Profile.Race) + '</td>';
                htmlProps += '<td>' + (data.Profile === null ? '' : data.Profile.School) + '</td>';
                htmlProps += '<td>' + (data.Profile === null ? '' : data.Profile.NationalState) + '</td>';
                htmlProps += '</tr>';
                return null;
            });
        }
        htmlProps += '</tbody></table>';
        this.setState({
            hiddenStudentListTableForXLS: htmlProps,
            hiddenStudentListTableForXLS_filename: this.state.RoomInfo.RoomCode + '_StudentList_' + moment.utc().local().format('YYYY-MM-DD_HHmm'),
        });
    }

    // EditRoomSettings = () => {
    //     this.props.SetAlert('', 'fake edit room setting...', false);
    // }


    //#region //=== Batch Download Certs === start ===//
    ToggleCertBatchDownloadUi = () => {
        this.setState({ ToggleCertBatchDownloadUi: !this.state.ToggleCertBatchDownloadUi, });
    }
    //2021.12.15
    CertBatchDownloadComponentUi = () => {
        let _components = null;
        if (this.state.ToggleCertBatchDownloadUi) {
            let _certArray = [];
            this.state.ParticipantDataList.map((data, key) => {
                if (data.hasOwnProperty('Score') && data.hasOwnProperty('ScoreIssuedDate')) {
                    // if (data.CertSN !== null && data.CertSN.includes(this.state.EventModal.CertSerialPrefix))
                    if (data.Score >= 0 && data.ScoreIssuedDate !== '')
                        _certArray.push({ Index: key, Uid: data.Uid, CertSN: data.CertSN, Name: data.Profile.Name, School: data.Profile.School });
                }
                return null;
            });
            let _mainButton = <Button
                variant="primary"
                onClick={() => this.BatchDownloadParticipantCerts(_certArray)}
            >Download All (<b><u>{_certArray.length}</u></b>/{this.state.ParticipantDataList.length})<br />(for Participants with Score issued.)</Button>;
            let _gridButtons = [];
            let _dividerCounter = 15;
            let _loopCount = 0;
            _certArray.map((data, key) => {
                if (key % _dividerCounter === 0) {
                    // if (((key % _dividerCounter) * _dividerCounter) + _dividerCounter <= _certArray.length) {
                    if ((_loopCount + 1) * _dividerCounter <= _certArray.length) {
                        _loopCount++;
                        _gridButtons.push(<div class="grid-item-2-col"><Button variant="primary"
                            onClick={() => this.BatchDownloadParticipantCerts(_certArray, key + 1, key + _dividerCounter)}
                        >Part {_loopCount} ({key + 1}~{key + _dividerCounter})</Button></div>);
                    }
                }
                return null;
            });
            if ((_loopCount + 1) * _dividerCounter > _certArray.length) {
                let startNo = (_loopCount * _dividerCounter) + 1;
                _gridButtons.push(<div class="grid-item-2-col"><Button variant="primary"
                    onClick={() => this.BatchDownloadParticipantCerts(_certArray, startNo, _certArray.length)}
                >Part {_loopCount + 1} ({startNo}~{_certArray.length})</Button></div>);
            }
            _components =
                <Modal show={this.state.ToggleCertBatchDownloadUi} onHide={this.ToggleCertBatchDownloadUi} centered>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Certs Batch Download</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center', alignSelf: 'center', }}>
                        {_mainButton}
                        <br />
                        <br />
                        <div class="grid-container-2-col">
                            {_gridButtons}
                        </div>
                        <br />*only participants with score issued will receive certificate.
                        <br />*or participant insisted to download cert without waiting for score to be issued.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.ToggleCertBatchDownloadUi}
                        >Close</Button>
                    </Modal.Footer>
                </Modal>;
        }
        return _components;
    }
    //2021.12.14
    BatchDownloadParticipantCerts = async (_certArray = [], _start = 0, _end = 0) => {
        if (this.props.isDevMode)
            console.log('Downloading <' + _start + '> ~ <' + _end + '>');

        // let debugList = [];
        // _certArray.map(async (data, key) => {
        //     if (
        //         (_start === 0 && _end === 0)
        //         || (key + 1 >= _start && key + 1 <= _end)
        //     ) {
        //         debugList.push('CertSN = ' + data.CertSN + '\nName = ' + data.Name + '\nSchool = ' + String(data.School).substring(0, 10) + '...');
        //     }
        // })
        // console.log('list (' + debugList.length + ') =\n\n' + debugList.join('\n\n'));
        // return null;

        if (_certArray.length > 0) {
            await this.GetEventModal();
            await Delay(500);
            this.props.SetLoading('e-Certificate of Participation', 'Downloading PDF file...', false);
            await Delay(1000);
            await Promise.all(
                _certArray.map(async (data, key) => {
                    if (
                        (_start === 0 && _end === 0)
                        || (key + 1 >= _start && key + 1 <= _end)
                    ) {
                        let _certSN = String(data.CertSN);
                        if (_certSN === '') {
                            await this.FetchCertData(data.Uid, this.state.EventModal.EventCode);
                            let findIndex = this.state.ParticipantDataList.findIndex(x => x.Uid === data.Uid);
                            if (findIndex > -1) {
                                _certSN = this.state.ParticipantDataList[findIndex].CertSN;
                            }
                        }
                        await this.GenerateAndDownloadCertInPDF(_certSN, data.Name, data.School);
                        await Delay(500);
                    }
                })
            );
            //show success ui.
            this.props.SetAlert(
                Locale("label-pdf-download-success", this.props.Locale),
                Locale("notice-pdf-download-success", this.props.Locale)
            );
        }
    }
    //2021.12.15
    FetchCertData = async (_Uid = '', _EventCode = '') => {
        if (_Uid !== '' && _EventCode !== '') {
            let _certData = null;
            await this.props.firestore
                .collection("LiveQuiz_Certifications")
                .doc(_Uid)
                .collection('Certificates')
                .where('EventCode', '==', _EventCode)
                .orderBy('CreatedOnUtc', 'asc')
                .limit(1)
                .get()
                .then(querySnapshot => {
                    let data = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            data.push(doc.data());
                        });
                        if (data.length > 0)
                            data = data[0];
                        else
                            data = null;
                    }
                    if (data !== null && data.hasOwnProperty('EventCode')) {
                        _certData = data;
                    }
                    if (this.props.isDevMode)
                        console.log("\nCertData =\n" + JSON.stringify(_certData));
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log('bad Internet connection.\n\n' + error.message);
                });
            if (_certData !== null) {
                let findIndex = this.state.ParticipantDataList.findIndex(x => x.Uid === _Uid);
                if (findIndex > -1) {
                    let _List = this.state.ParticipantDataList;
                    _List[findIndex].CertData = _certData;
                    _List[findIndex].CertSN = String(_certData.CertSN);
                    this.setState({ ParticipantDataList: _List, });
                }
            }
        }
    }
    //2021.12.14 - originally DownloadCertPDF copied from ParticipatedEventList with some modifications.
    GenerateAndDownloadCertInPDF = async (_certSN = '', _name = '', _school = '') => {

        let event = this.state.EventModal;
        if (event.hasOwnProperty('CertLayoutTypeId')) {
            if (event.hasOwnProperty('CertDownloadDelayed') === false)
                event.CertDownloadDelayed = false;
        }
        else {
            event.CertLayoutTypeId = 1;
        }

        //show processing notice.
        // this.props.SetLoading('e-Certificate of Participation', 'Processing PDF file...', true);

        //Create Pdf object.
        let pdfDoc = await PDFDocument.create();

        //Add a blank page to the document.
        let page = pdfDoc.addPage();

        let defaultCertImgUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/cert_spot_2021_q1.jpg';  //final

        //Set Student Name.
        // let studentName = String(this.state.Participated_Event_List[idx].Participant);
        //2021.04.07 - follow Profile name.
        let studentName = String(_name).toUpperCase();
        let studentNameForFile = studentName.replace(new RegExp(' ', 'g'), '-');

        //get event details.
        let eventName = String(event.EventName).replace(new RegExp(' ', 'g'), '-');
        let eventDate = event.DateStart === event.DateEnd ?
            moment(event.DateStart + ' 00:00:00').format('YYYY_MMDD')
            : moment(event.DateStart + ' 00:00:00').format('YYYY')
            + '_' + moment(event.DateStart + ' 00:00:00').format('MMDD')
            + '-' + moment(event.DateEnd + ' 00:00:00').format('MMDD');

        //assign cert bg image url.
        // let bgUrl = event.hasOwnProperty('CertImgUrl') ? event.CertImgUrl : defaultCertImgUrl;

        //2021.07.05
        let bgUrl = defaultCertImgUrl;
        if (event.hasOwnProperty('CertImgUrl')) {
            if (String(event.CertImgUrl) !== '' || String(event.CertImgUrl).length > 0) {
                bgUrl = String(event.CertImgUrl);
            }
        }
        if (this.props.isDevMode)
            console.log('\nbgUrl = ' + bgUrl);

        //Fetch image file.
        let bgImgBytes = this.state.Cert_bgImgBytes;
        if (bgImgBytes === null) {
            bgImgBytes = await fetch(bgUrl, {
                method: 'GET',
                // mode: 'no-cors',
                headers: { 'Access-Control-Allow-Origin': false }
            }, true).then(res => res.arrayBuffer()).catch(error => { return null; });
            // console.log('bgImgBytes = ' + bgImgBytes.byteLength + ' | ' + bgImgBytes);

            if (bgImgBytes !== null)
                this.setState({ Cert_bgImgBytes: bgImgBytes, });
        }

        // console.log(bgImgBytes.byteLength);  //failed = 215.
        // if (bgImgBytes.byteLength < 500) {
        if (bgImgBytes === null || (bgImgBytes.hasOwnProperty('byteLength') ? bgImgBytes.byteLength < 500 : false)) {
            this.props.SetAlert(
                Locale("label-pdf-download-failed", this.props.Locale),
                Locale("notice-pdf-download-failed", this.props.Locale)
            );
            return null;
        }

        //Add picture.
        // let bgImg = await pdfDoc.embedJpg('data:image/jpeg;base64,' + bgImgBase64);
        let bgImg = await pdfDoc.embedJpg(bgImgBytes);
        if (this.props.isDevMode)
            console.log('\nbgImg = w (' + bgImg.width + ') h (' + bgImg.height + ')');

        let bgImgDims = event.CertLayoutTypeId === 2 ? bgImg.scale(0.24) : bgImg.scale(0.25);
        if (this.props.isDevMode)
            console.log('\nbgImgDims = w (' + bgImgDims.width + ') h (' + bgImgDims.height + ')');

        //Set Bg picture.
        page.drawImage(bgImg, {
            x: page.getWidth() / 2 - bgImgDims.width / 2,
            y: page.getHeight() / 2 - bgImgDims.height / 2,
            width: bgImgDims.width,
            height: bgImgDims.height,
        });

        // let regexJapanese = /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/;

        let regexChinese = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;

        let hasChineseCharactors = regexChinese.test(studentName);
        // console.log('hasChineseCharactors = ' + hasChineseCharactors);
        // return null;

        //2021.11.08 - revamped.
        if (event.CertLayoutTypeId === 2) {
            //event.CertLayoutTypeId === 2 > Student Name, School Name, & Serial Number.

            //set font.
            let studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
            let serialNumberFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

            if (hasChineseCharactors) {
                //register 'fontkit' instance
                pdfDoc.registerFontkit(fontkit);

                //set custom font.
                let fontUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/helpfile/NotoSansSC-Bold.otf';    //SC support chinese.
                let fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
                let customFont = await pdfDoc.embedFont(fontBytes);

                //Text (1) Student Name.
                let studentName_fontSize = 28;  //charactors width are small even with long name, so fixed at 28.
                page.drawText(studentName, {
                    x: (page.getWidth() / 2) - (customFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (customFont.heightAtSize(studentName_fontSize) / 2) + 50 + 34,
                    size: studentName_fontSize,
                    font: customFont,
                });
            }
            else {
                //2021.04.14
                let isLongName = studentName.length > 29;
                if (isLongName) {
                    let nameArray = studentName.split(' ');
                    let nextLineIndex = Math.round(nameArray.length / 2) - 1;
                    let newNameFormat = '';
                    nameArray.map((data, key) => {
                        if (key === nextLineIndex)
                            newNameFormat += data + '<>';
                        else if (key === nameArray.length)
                            newNameFormat += data;
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                    studentName = newNameFormat;
                }
                // let posHeight = isLongName ? 75 : 50;

                // //set font.
                // let studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

                //non-chinese-names = alphanumeric charactors = aka English & etc.
                let studentName_fontSize = isLongName ? 22 : 28;

                //draw name.
                //Text (1) Student Name.
                if (isLongName) {
                    let firstLine = studentName.split('<>')[0];
                    page.drawText(firstLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(firstLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 75 + 19,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                    let secondLine = studentName.split('<>')[1];
                    page.drawText(secondLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(secondLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 19,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
                else {
                    page.drawText(studentName, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 25,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
            }

            //Text (2) School Name.
            let schoolName = _school;
            let schoolName_isLongName = schoolName.length > 29;
            let schoolName_fontSize = schoolName_isLongName ? 18 : 24;  //smaller size than student name.
            if (schoolName_isLongName) {
                let nameArray = schoolName.split(' ');
                let nextLineIndex = Math.round(nameArray.length / 2) - 1;
                let newNameFormat = '';
                nameArray.map((data, key) => {
                    if (key === nextLineIndex)
                        newNameFormat += data + '<>';
                    else if (key === nameArray.length)
                        newNameFormat += data;
                    else
                        newNameFormat += data + ' ';
                    return null;
                });
                schoolName = newNameFormat;
                let firstLine = schoolName.split('<>')[0];
                page.drawText(firstLine, {
                    x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(firstLine, schoolName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + 20 + 5,
                    size: schoolName_fontSize,
                    font: studentNameFont,
                });
                let secondLine = schoolName.split('<>')[1];
                page.drawText(secondLine, {
                    x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(secondLine, schoolName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + 5,
                    size: schoolName_fontSize,
                    font: studentNameFont,
                });
            }
            else {
                page.drawText(schoolName, {
                    x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(schoolName, schoolName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + 20,
                    size: schoolName_fontSize,
                    font: studentNameFont,
                });
            }

            //Text (3) Serial Number.
            let serialNumber_fontSize = 14;
            let serialNumber = _certSN;
            page.drawText(serialNumber, {
                x: (page.getWidth() / 2) - (serialNumberFont.widthOfTextAtSize(serialNumber, serialNumber_fontSize) / 2) + 167, //177,
                y: (page.getHeight() / 2) - (serialNumberFont.heightAtSize(serialNumber_fontSize) / 2) - 317,
                size: serialNumber_fontSize,
                font: serialNumberFont,
            });
        }
        else {
            //event.CertLayoutTypeId === 1 > default layout. Student Name only.
            if (hasChineseCharactors) {
                //register 'fontkit' instance
                pdfDoc.registerFontkit(fontkit);

                //set custom font.
                // let fontUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/helpfile/NotoSans-Bold.ttf';   //not support chinese.
                let fontUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/helpfile/NotoSansSC-Bold.otf';    //SC support chinese.
                let fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
                let customFont = await pdfDoc.embedFont(fontBytes);

                let studentName_fontSize = 28;  //charactors width are small even with long name, so fixed at 28.

                page.drawText(studentName, {
                    x: (page.getWidth() / 2) - (customFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (customFont.heightAtSize(studentName_fontSize) / 2) + 50,
                    size: studentName_fontSize,
                    font: customFont,
                });
            }
            else {
                // studentName = 'PUTERI DAMIA AMANI BINTI MOHD KHAIRUL RAIS'; //43 chars
                // studentName = 'MUHAMMAD AIMAN HAKIM BIN REZA ROSDEE';   //37 chars
                // studentName = 'Ernest Tan Wui Shean';   //21 chars

                //2021.04.14
                let isLongName = studentName.length > 29;
                if (isLongName) {
                    let nameArray = studentName.split(' ');
                    let nextLineIndex = Math.round(nameArray.length / 2) - 1;
                    let newNameFormat = '';
                    nameArray.map((data, key) => {
                        if (key === nextLineIndex)
                            newNameFormat += data + '<>';
                        else if (key === nameArray.length)
                            newNameFormat += data;
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                    studentName = newNameFormat;
                }
                // let posHeight = isLongName ? 75 : 50;

                //set font.
                let studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

                //non-chinese-names = alphanumeric charactors = aka English & etc.
                let studentName_fontSize = isLongName ? 22 : 28;

                //draw name.
                if (isLongName) {
                    let firstLine = studentName.split('<>')[0];
                    page.drawText(firstLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(firstLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 75,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                    let secondLine = studentName.split('<>')[1];
                    page.drawText(secondLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(secondLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
                else {
                    page.drawText(studentName, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
            }
        }

        // //Serialize the PDFDocument to bytes (a Uint8Array)
        // let pdfBytes = await pdfDoc.save();

        // //Reduce buffer.
        // let arrayBytes = new Uint8Array(pdfBytes);
        // let bgImgBase64 = btoa(arrayBytes.reduce((data, byte) => {
        //     return data + String.fromCharCode(byte);
        // }, ''));

        //2021.04.23 - new built-in function.
        let bgImgBase64 = await pdfDoc.saveAsBase64();
        let bgImgBase64Uri = await pdfDoc.saveAsBase64({ dataUri: true });
        //this method greatly shorten the steps to get the needed final format === 'data:application/pdf;base64,' + bgImgBase64

        // console.log(bgImgBase64);

        //2021.04.23 - backup pdf base64 data uri.
        this.setState({ Base64PdfUri: bgImgBase64 });

        //show download notice.
        await Delay(1000);
        // this.props.SetLoading('e-Certificate of Participation', 'Downloading PDF file...', false);
        // await Delay(1000);

        //filename
        let _filename = eventDate + '_' + eventName + '_' + studentNameForFile; // + '.pdf';
        if (!this.props.isFromParentApp && this.state.AllowDownloadPdfOnWeb)
            _filename += '.pdf';

        //Trigger the browser to download the PDF document.
        await this.SaveFile(_filename, bgImgBase64Uri, event.EventCode);
    }
    //2021.12.14 - originally DownloadCertPDF copied from ParticipatedEventList with some modifications.
    SaveFile = async (filename, data, eventCode) => {

        // create link element
        let a = document.createElement('a');
        let url = data;

        // initialize 
        a.href = url;
        a.download = filename;

        // append element to the body, 
        // a must, due to Firefox
        document.body.appendChild(a);

        // trigger download
        a.click();

        // delay a bit deletion of the element
        setTimeout(function () {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 100);

        var havePopupBlockers = ('' + window.open).indexOf('[native code]') === -1;
        if (havePopupBlockers) {
            // this.props.SetAlert('Download Failed', 'Download has been blocked by your browser.<br />Please whitelist this website & try again.');
            this.props.CloseAlert();
            this.setState({ ShowPopupErrorModal: true });
            return null;
        }

        // //save counter of downloads on FS.
        // await this.SaveDownloadCounters(true, eventCode);  //2021.04.22

        // //show success ui.
        // this.props.SetAlert(
        //     Locale("label-pdf-download-success", this.props.Locale),
        //     Locale("notice-pdf-download-success", this.props.Locale)
        // );
    }
    //#endregion    //=== Batch Download Certs === end ===//


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <>
                    <Row className='rowStyle'>
                        <Col>
                            <span style={{ fontSize: 20, fontWeight: 'bold' }}>Document Room (Detail Information)</span>
                        </Col>
                        <Col className='colBtn' hidden={this.props.SpecialPageMode}>
                            <button type="button" className="btn btn-outline-primary"
                                onClick={() => { this.props.TogglePage(this.props.Toggle.ManageRoom) }}
                            >back</button>
                        </Col>
                    </Row>
                    {/* <Row className='rowStyle'>
                        <Col>
                            {JSON.stringify(this.props.RoomInfo)}
                        </Col>
                    </Row> */}
                    <p />
                    {/* <div style={{ border: '1px solid black', borderRadius: 10, padding: 10, borderColor: 'rgb(0, 123, 255)', textAlign: 'center', }}> */}
                    <Row style={{ alignItems: 'center' }}>
                        <Col style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div style={{ width: '49%', border: '1px solid black', borderRadius: 10, padding: 10, borderColor: 'rgb(0, 123, 255)', textAlign: 'center', }}>
                                <table className='tbStyle_DocRoomTable1' cellPadding='2' cellSpacing='0' border='0' width='97%'>
                                    <tbody>
                                        <tr><td className='title'>Room Title</td><td>:</td><td className='value'>{this.ReadValue(this.state.RoomInfo, 'RoomTitle')}</td></tr>
                                        <tr><td className='title'>Total Participants</td><td>:</td><td className='value'>{this.ReadValue(this.state.RoomStatistic, 'TotalParticipants')}</td></tr>
                                        <tr><td className='title'>Total Submitted Files</td><td>:</td><td className='value'>{this.ReadValue(this.state.RoomStatistic, 'TotalSubmittedFiles')}</td></tr>
                                        <tr><td className='title'>Total Scores Issued</td><td>:</td><td className='value'>{this.ReadValue(this.state.RoomStatistic, 'TotalScoresIssued')}</td></tr>
                                        <tr>
                                            <td colSpan='3' align='center' style={{ verticalAlign: 'bottom', height: 80 }}>
                                                {/* <div hidden={this.props.isAuthor === false}>
                                                    <button type='button' className='btn btn-primary'
                                                        onClick={this.EditRoomSettings}
                                                    >Edit Room Settings</button>&nbsp;&nbsp;&nbsp;&nbsp;</div> */}
                                                <button type='button' className='btn btn-primary'
                                                    onClick={this.ToggleCertBatchDownloadUi}
                                                    disabled={this.state.hiddenStudentListTableForXLS === null && this.state.ParticipantProfilePullingDone === false}
                                                >Download All Certs</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                {
                                                    this.state.hiddenStudentListTableForXLS === null ?
                                                        <button type='button' className='btn btn-primary' disabled>Download List (XLS)</button>
                                                        :
                                                        <ReactHTMLTableToExcel
                                                            id="download-excel-button"
                                                            className="btn btn-primary"
                                                            table="table-to-xls"
                                                            filename={this.state.hiddenStudentListTableForXLS_filename}
                                                            sheet="tablexls"
                                                            buttonText="Download List (XLS)"
                                                        />
                                                }&nbsp;&nbsp;&nbsp;&nbsp;
                                                <button type='button' className='btn btn-primary'
                                                    onClick={this.DownloadFile}
                                                >Download All Files</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ width: '49%', border: '1px solid black', borderRadius: 10, padding: 10, borderColor: 'rgb(0, 123, 255)', textAlign: 'center', }}>
                                <table className='tbStyle_DocRoomTable1' cellPadding='2' cellSpacing='0' border='0' width='100%'>
                                    <tbody>
                                        <tr><td className='title'>Room Code</td><td>:</td><td className='value'>{this.ReadValue(this.state.RoomInfo, 'RoomCode')}</td></tr>
                                        <tr><td className='title'>Essay Title</td><td>:</td><td className='value'>{this.ReadValue(this.state.RoomInfo, 'Remark')}</td></tr>
                                        <tr><td className='title'>Group</td><td>:</td><td className='value'>{this.ReadValue(this.state.RoomInfo, 'Group')}</td></tr>
                                        <tr><td className='title'>Subject</td><td>:</td><td className='value'>{this.ReadValue(this.state.RoomInfo, 'SubjectName')}</td></tr>
                                        <tr><td className='title'>Date</td><td>:</td><td className='value'>{this.ReadValue(this.state.RoomInfo, 'DateLength')}</td></tr>
                                        <tr><td className='title'>Length</td><td>:</td><td className='value'>{this.ReadValue(this.state.RoomInfo, 'TimeLength')}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row style={{ alignItems: 'center', }}>
                            <Col><button type='button' className='btn btn-primary'>Download All Files</button></Col>
                        </Row> */}
                    {/* </div> */}
                    <p />
                    <div dangerouslySetInnerHTML={{ __html: this.state.hiddenStudentListTableForXLS }}></div>
                    {/* <table id="table-to-xls" hidden><tbody>{this.state.hiddenStudentListTableForXLS}</tbody></table> */}
                    <table className='table table-hover table-bordered tbStyle_DocRoomTable2' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                        <thead>
                            <tr>
                                <th style={{ width: 55, }}>#</th>
                                <th className='student'>Student Name</th>
                                <th style={{ width: 75, }}>G/R</th>
                                <th style={{ width: 155, }}>Score</th>
                                <th style={{ width: 75, }}>File</th>
                                <th style={{ width: 115, }}>Upload Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.IsLoadingParticipantListing ?
                                    <tr><td colSpan='6'><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                                    :
                                    this.state.ParticipantDataList.length <= 0 ?
                                        <tr><td colSpan='6'>list is empty</td></tr>
                                        :
                                        this.state.ParticipantDataList.map((data, key) => {
                                            // console.log(JSON.stringify(data));
                                            return (<tr style={data.ScoreIssuedDate === '' ? {} : { backgroundColor: 'cyan' }}>
                                                <td>{key + 1}</td>
                                                <td className='student'>
                                                    {data.Participant}<br />
                                                    <span style={{ color: 'gray', fontSize: 12, }}>{data.Uid}<br />{data.UploadedFileName}<br />{
                                                        data.Profile === null ?
                                                            '- school name is not available -'
                                                            : data.Profile.School === undefined ?
                                                                '- school name is not available -'
                                                                : data.Profile.School.length === 0 ?
                                                                    '- school name is not available -'
                                                                    : data.Profile.School
                                                    }</span>
                                                </td>
                                                <td>{
                                                    data.Profile === null ? '- N/A -' :
                                                        <span style={{ color: 'gray', fontSize: 12, }}>
                                                            {data.Profile.Gender.length > 0 ? data.Profile.Gender : '-'}
                                                            <br />
                                                            {data.Profile.Race.length > 0 ? data.Profile.Race : '-'}
                                                        </span>
                                                }</td>
                                                <td>
                                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingBottom: 2, }}>
                                                        <input
                                                            type='number'
                                                            id={'Input_Score_' + String(key)}
                                                            ref={ref => this.SetParticipantScoreCallbackRef(ref, key)}
                                                            value={data.Score}
                                                            maxLength={3}
                                                            style={{
                                                                fontSize: 20,
                                                                maxWidth: 55,
                                                                textAlign: 'center',
                                                            }}
                                                            onChange={(e) => {
                                                                // console.log(e + ' | ' + e.currentTarget.value);
                                                                let _List = this.state.ParticipantDataList;
                                                                if (Number(e.currentTarget.value) <= 100)
                                                                    _List[key].Score = Number(e.currentTarget.value);
                                                                else
                                                                    _List[key].Score = 100;

                                                                // this.Edit_ParticipantScore_Ref.map((data1, key1) => {
                                                                //     if (data1 !== null)
                                                                //         if (data1.id === 'Input_Score_' + String(key)) {
                                                                //             data1.value = Number(_List[key].Score);
                                                                //             // console.log(key1);
                                                                //         }
                                                                // });
                                                                this.Edit_ParticipantScore_Ref[key].value = _List[key].Score;

                                                                e.currentTarget.value = Number(_List[key].Score);
                                                                this.setState({ ParticipantDataList: _List, });

                                                                // console.log(this.Edit_ParticipantScore_Ref[key].value);
                                                                // console.log(key);
                                                            }}
                                                        ></input>&nbsp;
                                                        <button type='button' className='btn btn-primary'
                                                            onClick={() => this.UpdateParticipantScoreViaAPI(this.Edit_ParticipantScore_Ref[key].value, key)}
                                                        >Save</button>
                                                    </div>
                                                    <span style={{ color: 'grey', fontSize: 12, }}>{
                                                        data.ScoreIssuedDate === "" ?
                                                            // Locale("not-yet-specify-result", this.props.Locale)
                                                            '-not-yet-issue-score-'
                                                            :
                                                            moment.utc(data.ScoreIssuedDate).local().format('lll')}</span>
                                                </td>
                                                <td>
                                                    <button type='button' className='btn btn-primary'
                                                        onClick={() => this.DownloadFile('single', data.UploadedFileName)}
                                                    >DL</button>
                                                </td>
                                                <td>{moment.utc(data.FileUploadedDate).local().format('lll')}</td>
                                            </tr>);
                                        })
                            }
                        </tbody>
                    </table>

                    <span id={'bottomPage'}>&nbsp;</span>

                    {this.CertBatchDownloadComponentUi()}
                </>
            );
        }
    }
}

// export const EditRoomSettings = () => {
//     this.props.SetAlert('', 'fake edit room setting...', false);
// }