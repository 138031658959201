// QuizDataBrowsing.js      //2020.06.09

import React from 'react';
import { Redirect } from 'react-router-dom';
import { Picker, Switch } from 'react-native';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// import quizBg2 from './html/images/page-bg.png';
import './LiveQuizAdminPortal.scss';
// import ReactHTMLTableToExcel from 'react-html-to-excel';
// import {
//     // osVersion, osName, browserName, fullBrowserVersion
//     browserName
// } from "react-device-detect";
import { GlobalSetting } from '../../components/GlobalSetting';
import { CheckNullValue, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, DelayUntil, GradeOptions, ScrollToElement, Profile_ValidateProperties, CapitalizeJsonKeys } from '../../components/GlobalFunctions';      //2023.09.11

// import { SchoolListWithDetails } from '../../components/SchoolListWithDetails';

import * as alasql from 'alasql';
import * as XLSX from 'xlsx';
import { Lang } from '../../Localization/CustomLocalization';

alasql.setXLSX(XLSX);

// let rankingNodes;
// let dbPathRef, rooomDetail_PathRef, common_PathRef;
// let textValues;
// let _QsUiTopPos = '0px';

//RFC 5322 compliant regex 
// const emailRegex = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

//RFC 5322 compliant regex 
// const uidRegex = /^[a-zA-Z0-9]*$/gm;
// const uidRegex = /^(\d|\w)+$/;

let ProgressionStateText = '';

const AtoZ = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
    'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
];

export default class LiveQuizRoomRankingDataBrowsing extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        versionDivisionDate: 20200630,
        versionDivision: '2.2.3.1(68)',
        IsButtonEnabled: true,
        selectedDate: new Date(),
        liveQuizDate: '',
        Rankings: [],
        isloaded: false,
        temp: '',

        RoomCodeArrays: [],
        showRoomDetailsList: false,
        showRoomDetail: false,
        RoomDetailArrays: [],
        RoomDetailUiComponent: '',

        IsRoomListLoaded: false,
        IsRoomDetailsLoaded: false,
        IsShowRoomDetails: false,
        selectedRoomId: -1,
        selectedRoomIndex: -1,
        prevSelectedRoomId: -1,
        selectedRoomCode: -1,

        isShowJoinedPlayers: false,
        isJoinedPlayersLoaded: false,
        JoinedPlayerArrays: [],
        JoinedPlayerEmails: [],
        // JoinedPlayersList: [],

        singleQuestionDetailArray: [],
        singleQuestionDetailHtml: '',
        isShowQuestionDetailHtml: false,

        // showRankingList: false,
        isShowRankingList: false,
        isRankingListLoaded: false,
        RankingListArrays: [],

        IsShowFinalRankingList: false,
        IsFinalRankingListLoaded: false,
        FinalRankingList: [],
        // isAlmostDone: false,
        // dummy: false,
        dupeQtyForFRList: 0,

        // isShowLogDataDetail: false,
        logDataDetails: '',
        ToggleLogDataDetail: false,       //2021.12.28
        Hidden_Ranking_List_With_Results: '',
        // RankingListResults: [],
        resultDataDetails: '',
        ToggleResultDetails: false,       //2021.12.29
        selectedQuestionId: -1,
        scrollPosition: 0,

        //added 2020.11.13
        ToggleAutoRefresh: false,
        autoRefreshTimerValue: 20,
        autoRefreshElapsedTime: 0,

        //2021.03.01
        Hidden_RoomList_table: '',

        //2021.07.29
        Hidden_RoomList_TimeTable: '',

        //2022.06.13
        Hidden_School_Statistic_Report: '',

        //2021.09.13
        QuizQuestions: [],
        SelectedAnswers: [],
        ReCalculatedResults: [],
        ProgressionState: 'processing...',

        //2021.12.30
        RoomList: [],

        //2023.09.26
        rankingListRoomInfoForXLSX: [],
        rankingListParticipantsForXLSX: [],
        rankingListParticipantResultsForXLSX_fileName: '',

        //2023.12.20
        questionSetModal: null,
    });

    //#region utilities
    //2021.09.10
    Delay = ms => new Promise(res => setTimeout(res, ms));

    //2021.09.13
    DelayUntil = (conditionFunction) => {
        const poll = resolve => {
            if (conditionFunction()) resolve();
            else setTimeout(_ => poll(resolve), 500);
        }
        return new Promise(poll);
    }

    //===== Auto Refresh === //added 2020.11.13
    ToggleAutoRefresh = () => {
        this.setState({
            ToggleAutoRefresh: !this.state.ToggleAutoRefresh,
            autoRefreshElapsedTime: 0,
        });
    }
    SetAutoRefreshTimerValue = (value) => {
        this.setState({
            autoRefreshTimerValue: Number(value),
            autoRefreshElapsedTime: 0,
        },
            () => {
                // alert(this.state.autoRefreshTimerValue);
            }
        );
    }
    CheckOnAutoRefreshTimer = () => {
        if (this.state.autoRefreshElapsedTime >= this.state.autoRefreshTimerValue) {
            this.setState({
                autoRefreshElapsedTime: 0,
            }, () => {
                this.SelectRoom(this.state.selectedRoomId);
            });
        }
    }
    //======= ends.
    //#endregion

    //#region RTDB paths.
    checkTestPhasePath = (_date) => {
        // return (this.props.isTest ? 'pkquiz/' + _date + '/testPhase/' : 'pkquiz/' + _date + '/');
        return 'pkquiz/' + _date;
    }

    roomDetailPath = (_date) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-room-detail');
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-room-detail');
    }

    roomDetailPathByRoomId = (_roomId) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-room-detail');
        return this.props.db.ref('pkquiz/' + this.state.liveQuizDate + '/pkquiz-room-detail/' + _roomId);
    }

    roomCodePath = (_date) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-room-code');
    }

    roomCodePathByRoomCode = (_roomCode) => {
        return this.props.db.ref('pkquiz/' + this.state.liveQuizDate + '/pkquiz-room-code/' + _roomCode);
    }

    joinedPlayersPath = (_date) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + this.state.selectedRoomId);
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + this.state.selectedRoomId);
    }

    joinedPlayersPathV2 = (_date, _roomId) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + this.state.selectedRoomId);
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + _roomId);
    }

    liveRankingPath = (_date) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + this.state.selectedRoomId);
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + this.state.selectedRoomId);
    }

    rankingListPath = (_date) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking/' + this.state.selectedRoomId);
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking/' + this.state.selectedRoomId);
    }

    liveQuizRoomStatePath = (_roomIndex) => {
        // return this.props.db.ref('pkquiz/' + this.state.liveQuizDate + '/pkquiz-live/' + this.state.RoomCodeArrays[_roomIndex].RoomId);
        return this.props.db.ref('pkquiz/' + this.state.liveQuizDate + '/pkquiz-live/' + _roomIndex);
    }

    roomTitlePath = (_roomIndex) => {
        return this.props.db.ref("pkquiz-questions/"
            + this.state.RoomDetailArrays[_roomIndex].Subject + "/"
            + this.state.RoomDetailArrays[_roomIndex].Grade + "/"
            + this.state.RoomDetailArrays[_roomIndex].QnSet + "/"
            + "RoomTitle"
        );
    }

    userEmailPath = (_uid) => {
        return this.props.dbCom.ref("Common/" + _uid + "/_7Email");
    }

    userNamePath = (_uid) => {
        return this.props.dbCom.ref("Common/" + _uid + "/_1Name");
    }

    //2021.01.30
    userProfilePath = (_uid) => {
        return this.props.dbCom.ref("Common/" + _uid);
    }

    questionPath = (_roomIndex, _qsId) => {
        let path = "LiveQuiz/"
            + this.state.RoomDetailArrays[_roomIndex].Subject + "/"
            + this.state.RoomDetailArrays[_roomIndex].Grade + "/"
            + this.state.RoomDetailArrays[_roomIndex].QnSet + "/"
            + _qsId;
        // window.alert(path);
        return this.props.dbQuestion.ref(path);
    }

    //2021.09.13
    questionSetPath = (_roomIndex) => {
        let path = "LiveQuiz/"
            + this.state.RoomDetailArrays[_roomIndex].Subject + "/"
            + this.state.RoomDetailArrays[_roomIndex].Grade + "/"
            + this.state.RoomDetailArrays[_roomIndex].QnSet;
        // window.alert(path);
        return this.props.dbQuestion.ref(path);
    }

    //2022.02.09
    questionSetPathV2 = (_questionSetUniqueId) => {
        let path = _questionSetUniqueId + "/Settings";
        // window.alert(path);
        return this.props.dbQuizBank.ref(path);
    }

    //2021.09.30
    getRoomQuestionPath_QuestionSet_V1 = (questionSetUniqueId) => {
        let path = questionSetUniqueId + "/Questions";
        return this.props.dbQuizBank.ref(path);
    }

    //2023.10.26
    personalResultRootPath = (_date, _roomId, _uid) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId);
    }

    //2021.09.13
    personalASelectsPath = (_date, _roomId, _uid) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '/ASelects');
    }

    //2020.07.15
    logsPath = (_uid, _date = null) => {
        // let date = moment.unix(this.state.selectedRoomId / 1000);   //.format("YYYY-MM-DD HH:mm:ss"); //room id = ticks
        // alert(date.format("YYYY-MM-DD HH:mm:ss"));

        let date = moment(this.state.liveQuizDate);
        if (_date !== null) {
            date = moment(String(_date));
        }
        let path = _uid + "/" + date.format("YYYY/M/D");
        // alert(path);
        // console.log('logsPath', _uid, _date);
        return this.props.dbLog.ref(path);
    }

    //2020.11.13
    personalQResultPath = (_date, _roomId, _uid) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '/QResults');
    }

    //2020.11.11
    personalScoreRecordPath = (_date, _roomId, _uid) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '/ScoreRecord');
    }

    //2020.07.17
    personalResultPath = (_date, _roomId, _uid) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '/QResults');

        // let path = this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '/QResults');
        // alert(path);
        // return path;
    }

    //2020.07.17
    personalResult_OldPath = (_date, _roomId, _uid) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '_Results');
    }
    //#endregion

    componentDidMount = async () => {
        if (this.props.user === null) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }
        // await Delay(1000);
        this.onCalenderChange(new Date());

        //Timer
        await Delay(1000);
        this.myInterval = setInterval(() => {
            if (uiModeState === UiMode.ShowFinalRankingList
                && this.state.ToggleAutoRefresh
                && this.state.selectedRoomId > 0
                && this.state.IsFinalRankingListLoaded
            ) {
                this.setState({
                    autoRefreshElapsedTime: this.state.autoRefreshElapsedTime + 1,
                }, () => {
                    this.CheckOnAutoRefreshTimer();
                });
            }
        }, 1000);

        // console.log('schoolListWithDetails', JSON.stringify(this.props.schoolListWithDetails));
    }

    componentDidUpdate = () => {
        //
    }

    componentWillUnmount = () => {
        //
    }

    GetValue = (state, name, defaultValue) => {
        if (state === null || state[name] === null)
            return defaultValue;

        return state !== null ? state[name] : defaultValue;
    }

    onCalenderChange = (value) => {
        // let dateString = moment(value).format('YYYYMMDD');
        // alert(dateString);
        this.setState({
            IsButtonEnabled: false,
            selectedDate: value,
            liveQuizDate: moment(value).format('YYYYMMDD'),
            RoomList: [],
            RoomDetailArrays: [],
            RoomCodeArrays: [],
            selectedRoomIndex: -1,
        }, () => {
            this.LoadRoomListAndDetails(UiMode.ShowRoomList);
            // this.LoadRoomListAndDetails();
        });
    }

    LoadRoomListAndDetails = (_uiMode) => {
        this.SwitchUi(_uiMode);

        if (this.state.RoomDetailArrays.length > 0) {
            this.setState({
                IsButtonEnabled: true,
                IsRoomListLoaded: true,
                // selectedRoomIndex: -1,
            });
        }
        else {
            // let date = moment(this.state.selectedDate).format('YYYYMMDD');
            // this.roomDetailPath(this.state.liveQuizDate).once('value', snapshot => {
            //     this.processRoomDetailsData(snapshot.val());
            //     // if (snapshot.val() != null) {
            //     //     this.processRoomDetailsData(snapshot.val());
            //     // }
            //     // else {
            //     //     this.setState({
            //     //         IsRoomListLoaded: true,
            //     //         RoomDetailUiComponent: <tr><td>No room is created for this day.</td></tr>,
            //     //     });
            //     // }
            // });

            // this.setState({
            //     IsButtonEnabled: false,
            // }, async () => {
            //     await this.roomCodePath(this.state.liveQuizDate).once('value', snapshot => {
            //         this.processRoomCodeData(snapshot.val());
            //     });
            //     await this.roomDetailPath(this.state.liveQuizDate).once('value', snapshot => {
            //         this.processRoomDetailsData(snapshot.val());
            //     });
            // });

            //2021.12.30
            this.setState({
                IsButtonEnabled: false,
            }, async () => {
                await this.LoadRoomListFromFS();
            });
        }
    }

    //2021.12.30
    LoadRoomListFromFS = async () => {

        //init.
        this.setState({
            IsButtonEnabled: false,
            IsRoomListLoaded: false,
            RoomList: [],
        });
        let _List = [];

        if (this.props.isDevMode)
            console.log('query =\n' + this.props.user.CenterUserId + '\n' + this.props.user.AuthorId + '\n' + this.state.liveQuizDate);

        //fetch.
        let docRef = this.props.dbFireStore.collection("LiveQuiz_UniqueRoomCode");
        // if (this.props.isSuperAdmin || this.props.isDevMode) {
        //     // docRef = docRef.where('RoomType', '==', -1)
        //     docRef = docRef.where('DateStart', '==', moment(this.state.selectedDate).format('YYYY-MM-DD'));
        // }
        // else {
        docRef = docRef
            .where('OrganizerId', '==', Number(this.props.OrganizerInfo.OrganizerId))
            // .where('CenterUserId', '==', Number(this.props.user.CenterUserId))
            // .where('AuthorId', '==', Number(this.props.user.AuthorId))
            // .where('RoomType', '!=', 1)
            .where('DateStart', '==', moment(this.state.selectedDate).format('YYYY-MM-DD'));
        // }
        await docRef.get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                    });
                    if (dataArray.length > 0) {
                        _List = dataArray;
                    }
                }
                if (this.props.isDevMode)
                    console.log(JSON.stringify(_List));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });

        //process.
        this.setState({
            // IsButtonEnabled: true,
            // IsRoomListLoaded: true,
            RoomList: _List,
        }, async () => {
            await this.PopulateRoomCodeData();
            await this.PopulateRoomDetailArrayData();
            this.setState({
                IsButtonEnabled: true,
                IsRoomListLoaded: true,
            });
        });
    }
    PopulateRoomCodeData = async () => {
        if (this.state.RoomList.length > 0) {
            let _array = [];
            this.state.RoomList.map((data, key) => {
                let canSave = true;
                if (data.hasOwnProperty('RoomType'))    //filter out other rooms except Basic Room.
                    canSave = data.RoomType < 1;
                if (canSave) {
                    return _array.push({
                        RoomCode: Number(data.RoomCode),
                        RoomId: Number(data.RoomId),
                    });
                }
                return null;
            });
            this.setState({
                RoomCodeArrays: _array,
            });
        }
    }
    PopulateRoomDetailArrayData = async () => {
        if (this.state.RoomList.length > 0) {
            if (this.state.RoomCodeArrays.length > 0) {
                let _RoomDetailArrays = [];
                await Promise.all(
                    this.state.RoomCodeArrays.map(async (data, key) => {
                        const _index = this.state.RoomList.findIndex(x => x.RoomCode === data.RoomCode && x.RoomId === data.RoomId);
                        if (_index > -1) {
                            const _roomData = this.state.RoomList[_index];
                            let _roomDetails = null;
                            await this.roomDetailPathByRoomId(String(data.RoomId)).once('value', snapshot => {
                                if (snapshot.exists())
                                    _roomDetails = snapshot.val();
                            });

                            //moved to SelectRoom.
                            // //2022.02.09
                            // let _QuestionSetUniqueId = this.GetValue(_roomDetails, 'QuestionSetUniqueId', '');
                            // let _QuestionSetDetails = null;
                            // if (_QuestionSetUniqueId !== '') {
                            //     await this.questionSetPathV2(_QuestionSetUniqueId).once('value', snapshot => {
                            //         if (snapshot.exists())
                            //             _QuestionSetDetails = snapshot.val();
                            //     });
                            // }

                            if (_roomDetails !== null) {
                                let _room = {

                                    //revamped 2023.11.03
                                    RoomCode: CheckObjectStringEmpty(_roomData, 'RoomCode'),
                                    RoomId: CheckObjectStringEmpty(_roomData, 'RoomId'),
                                    RoomTitle: CheckObjectStringEmpty(_roomData, 'RoomTitle'),

                                    GroupId: CheckObjectNumber(_roomData, 'GroupId'),
                                    Subject: CheckObjectStringEmpty(_roomData, 'SubjectName'),
                                    SubjectId: CheckObjectNumber(_roomData, 'SubjectId'),

                                    QnQty: CheckObjectNumber(_roomDetails, 'QnQty'),
                                    QnSet: CheckObjectNumber(_roomDetails, 'QnSet'),
                                    Questions: CheckObjectStringEmpty(_roomDetails, 'Questions'),
                                    Duration: CheckObjectNumber(_roomDetails, 'Duration'),
                                    DurationPerQuestion: CheckObjectNumber(_roomDetails, 'DurationPerQuestion'),

                                    DateStart: CheckObjectStringEmpty(_roomData, 'DateStart'),
                                    DateEnd: CheckObjectStringEmpty(_roomData, 'DateEnd'),
                                    TimeStart: CheckObjectStringEmpty(_roomData, 'TimeStart'),
                                    TimeEnd: CheckObjectStringEmpty(_roomData, 'TimeEnd'),
                                    StartTime: '',
                                    EndTime: '',

                                    QuestionSetUniqueId: CheckObjectStringEmpty(_roomData, 'QuestionSetUniqueId'),
                                    QuestionSetDetails: null,

                                    //legacy data.
                                    Grade: CheckObjectNumber(_roomDetails, 'Grade'),

                                    //#region old codes
                                    /*
                                    RoomCode: this.GetValue(_roomDetails, 'RoomCode', ''),
                                    RoomId: this.GetValue(_roomDetails, 'RoomId', ''),
                                    Duration: this.GetValue(_roomDetails, 'Duration', 0),
                                    DurationPerQuestion: this.GetValue(_roomDetails, 'DurationPerQuestion', 0),
                                    Grade: this.GetValue(_roomDetails, 'Grade', 0),
                                    QnQty: this.GetValue(_roomDetails, 'QnQty', 0),
                                    QnSet: this.GetValue(_roomDetails, 'QnSet', 0),
                                    Questions: this.GetValue(_roomDetails, 'Questions', ''),
                                    Subject: this.GetValue(_roomDetails, 'Subject', ''),

                                    RoomTitle: this.GetValue(_roomDetails, 'RoomTitle', ''),

                                    StartTime: '',
                                    EndTime: '',

                                    DateStart: this.GetValue(_roomDetails, 'DateStart', ''),
                                    DateEnd: this.GetValue(_roomDetails, 'DateEnd', ''),
                                    TimeStart: this.GetValue(_roomDetails, 'TimeStart', ''),
                                    TimeEnd: this.GetValue(_roomDetails, 'TimeEnd', ''),

                                    // QuestionSetUniqueId: this.GetValue(_roomDetails, 'QuestionSetUniqueId', ''),
                                    GroupId: this.GetValue(_roomDetails, 'GroupId', 0),

                                    //2022.02.09
                                    QuestionSetUniqueId: this.GetValue(_roomDetails, 'QuestionSetUniqueId', ''),
                                    QuestionSetDetails: null,
                                    */
                                    //#endregion
                                };
                                if (_room.DateStart !== '' && _room.TimeStart !== '')
                                    _room.StartTime = _room.DateStart + ' ' + _room.TimeStart;
                                if (_room.DateEnd !== '' && _room.TimeEnd !== '')
                                    _room.EndTime = _room.DateEnd + ' ' + _room.TimeEnd;
                                _RoomDetailArrays.push(_room);
                            }
                        }
                    })
                );
                this.setState({
                    RoomDetailArrays: _RoomDetailArrays,
                    IsRoomListLoaded: true,
                    IsButtonEnabled: true,
                }, async () => {
                    await this.SetRoomListTable();
                    await this.PopulateRoomDetailInTimeTableForm();
                });
            }
        }
    }

    //#region old codes - processRoomCodeData / processRoomDetailsData
    // processRoomCodeData = (dataSnapVal) => {
    //     let dataKeys = _(dataSnapVal).keys().value();       //key = room code
    //     let dataValues = _(dataSnapVal).values().value();   //values = room id

    //     dataKeys.map(async (data, key) => {
    //         return this.state.RoomCodeArrays.push({
    //             RoomCode: data,
    //             RoomId: dataValues[key],
    //         })
    //     });

    //     this.setState({
    //         RoomCodeArrays: this.state.RoomCodeArrays,
    //     });
    // }

    // processRoomDetailsData = async (dataSnapVal) => {
    //     // if (dataSnapVal != null)
    //     //     alert(JSON.stringify(dataSnapVal));

    //     let dataKeys = _(dataSnapVal).keys().value();       //key = room id
    //     let dataValues = _(dataSnapVal).values().value();   //values = room details

    //     this.setState({
    //         RoomDetailArrays: [],
    //         RoomDetailUiComponent: '',
    //     });

    //     // console.log(JSON.stringify(dataSnapVal));

    //     let _RoomDetailArrays = this.state.RoomDetailArrays;

    //     dataKeys.map((data, key) => {
    //         // let _roomCode = this.state.RoomDetailArrays.find(({ RoomId }) => RoomId === data).RoomCode;

    //         // console.log(JSON.stringify(data) + '\n\n' + key + '\n\n' + JSON.stringify(this.state.RoomCodeArrays));

    //         // //2020.12.11
    //         // let _roomCode = this.state.RoomCodeArrays.find(x => x.RoomId === data).RoomCode;

    //         //2021.07.23
    //         let _index = this.state.RoomCodeArrays.findIndex(x => x.RoomId === data);

    //         // console.log(_index + '\n\n' + JSON.stringify(this.state.RoomCodeArrays[_index]));

    //         if (_index > -1) {
    //             let _roomCode = this.state.RoomCodeArrays[_index].RoomCode;

    //             _RoomDetailArrays.push({
    //                 // RoomCode: _roomCode,
    //                 // RoomId: data.toString(),
    //                 // Duration: _(dataValues[key]).values().value()[0],
    //                 // DurationPerQuestion: _(dataValues[key]).values().value()[1],
    //                 // Grade: _(dataValues[key]).values().value()[2],
    //                 // QnQty: _(dataValues[key]).values().value()[3],
    //                 // QnSet: _(dataValues[key]).values().value()[4],
    //                 // Questions: _(dataValues[key]).values().value()[5],
    //                 // Subject: _(dataValues[key]).values().value()[6],

    //                 RoomCode: _roomCode,
    //                 RoomId: data.toString(),
    //                 Duration: dataValues[key]['Duration'],
    //                 DurationPerQuestion: dataValues[key]['DurationPerQuestion'],
    //                 Grade: dataValues[key]['Grade'],
    //                 QnQty: dataValues[key]['QnQty'],
    //                 QnSet: dataValues[key]['QnSet'],
    //                 Questions: dataValues[key]['Questions'],
    //                 Subject: dataValues[key]['Subject'],

    //                 RoomTitle: dataValues[key]['RoomTitle'],

    //                 //2020.12.11
    //                 StartTime: '',
    //                 EndTime: '',
    //                 // CustomDuration: '',

    //                 //2021.07.23
    //                 DateStart: dataValues[key].hasOwnProperty('DateStart') ? dataValues[key]['DateStart'] : '',
    //                 DateEnd: dataValues[key].hasOwnProperty('DateEnd') ? dataValues[key]['DateEnd'] : '',
    //                 TimeStart: dataValues[key].hasOwnProperty('TimeStart') ? dataValues[key]['TimeStart'] : '',
    //                 TimeEnd: dataValues[key].hasOwnProperty('TimeEnd') ? dataValues[key]['TimeEnd'] : '',

    //                 //2021.09.30
    //                 QuestionSetUniqueId: dataValues[key].hasOwnProperty('QuestionSetUniqueId') ? dataValues[key]['QuestionSetUniqueId'] : '',

    //                 //2021.12.28
    //                 GroupId: dataValues[key]['GroupId'],
    //             });
    //         }
    //         // return _RoomDetailArrays.push(null);
    //         return null;
    //     });
    //     // window.alert(details);

    //     //Get RoomTitle for each Room.
    //     Promise.all(
    //         dataKeys.map(async (data, key) => {
    //             // for (var key = 0; key < dataKeys.length; key++) {
    //             if (_RoomDetailArrays[key] !== undefined) {
    //                 // console.log(JSON.stringify(_RoomDetailArrays[key]));

    //                 let grade = '';
    //                 if (_RoomDetailArrays[key].Grade === 'L')
    //                     grade = 'Lower Primary';
    //                 else if (_RoomDetailArrays[key].Grade === 'U')
    //                     grade = 'Upper Primary';
    //                 else
    //                     grade = 'Standard ' + _RoomDetailArrays[key].Grade;

    //                 if (_RoomDetailArrays[key].RoomTitle === '') {
    //                     await this.roomTitlePath(key)
    //                         .once('value', snapshot => {
    //                             let title = snapshot.val() !== null ? snapshot.val().toString()
    //                                 : grade + ' ' + _RoomDetailArrays[key].Subject;
    //                             return _RoomDetailArrays[key].RoomTitle = title;  //Current Format
    //                         });
    //                 }

    //                 //2021.07.23
    //                 if (_RoomDetailArrays[key].DateStart !== '' && _RoomDetailArrays[key].TimeStart !== '') {
    //                     //2021.07.23
    //                     _RoomDetailArrays[key].StartTime = _RoomDetailArrays[key].DateStart + ' ' + _RoomDetailArrays[key].TimeStart;
    //                     _RoomDetailArrays[key].EndTime = _RoomDetailArrays[key].DateEnd + ' ' + _RoomDetailArrays[key].TimeEnd;
    //                 }
    //                 else {
    //                     //2020.12.11
    //                     let _roomId = this.state.RoomCodeArrays.find(x => x.RoomCode === _RoomDetailArrays[key].RoomCode).RoomId;
    //                     await this.liveQuizRoomStatePath(_roomId)
    //                         .once('value', snapshot => {
    //                             let roomState = snapshot.val();
    //                             _RoomDetailArrays[key].StartTime = roomState['QuizStartDT'];
    //                             _RoomDetailArrays[key].EndTime = roomState['QuizEndDT'];
    //                             // _RoomDetailArrays[key].CustomDuration = '';

    //                             // _RoomDetailArrays[key].RoomCode = _roomId;
    //                             // _RoomDetailArrays[key].RoomCode = 'pkquiz/' + this.state.liveQuizDate + '/pkquiz-live/' + this.state.RoomCodeArrays[key].RoomId
    //                             return null;
    //                         });
    //                 }
    //             }
    //             // }
    //             return null;
    //         })
    //     )
    //         .then(() => {
    //             this.setState({
    //                 RoomDetailArrays: _RoomDetailArrays,
    //                 IsRoomListLoaded: true,
    //                 IsButtonEnabled: true,
    //             }, () => {
    //                 this.SetRoomListTable();
    //                 this.PopulateRoomDetailInTimeTableForm();
    //             });
    //         });
    //     // .then(async () => {
    //     //     await this.LoadQuizQuestions();
    //     // });
    //     // this.setState({
    //     //     RoomDetailArrays: this.state.RoomDetailArrays,
    //     //     IsRoomListLoaded: true,
    //     // });
    // }
    //#endregion

    //2021.07.29
    PopulateRoomDetailInTimeTableForm = async () => {
        if (this.state.RoomDetailArrays.length > 0) {

            //revamped 2023.10.27
            let groups = [];
            this.state.RoomDetailArrays.map((data, key) => {
                let findGroupIndex = groups.findIndex(x => x.GroupId === data.GroupId);
                if (findGroupIndex < 0) {
                    const group = this.GetGroup(data);
                    groups.push({
                        GroupId: CheckObjectNumber(data, 'GroupId'),
                        GroupName: group === null ? '-' : CheckObjectStringEmpty(group, 'value'),
                        Rooms: [],
                    });
                    findGroupIndex = groups.length - 1;
                }
                let group = groups[findGroupIndex];
                const findRoomIndex = group.Rooms.findIndex(x => x.RoomCode === data.RoomCode);
                if (findRoomIndex < 0) {
                    group.Rooms.push({
                        RoomCode: CheckObjectStringEmpty(data, 'RoomCode'),
                        RoomId: CheckObjectStringEmpty(data, 'RoomId'),
                        RoomTitle: CheckObjectStringEmpty(data, 'RoomTitle'),
                        DateStart: CheckObjectStringEmpty(data, 'DateStart'),
                        DateEnd: CheckObjectStringEmpty(data, 'DateEnd'),
                        TimeStart: CheckObjectStringEmpty(data, 'TimeStart'),
                        TimeEnd: CheckObjectStringEmpty(data, 'TimeEnd'),
                        QnQty: CheckObjectStringEmpty(data, 'QnQty'),
                        Subject: CheckObjectStringEmpty(data, 'Subject'),
                        Duration: CheckObjectStringEmpty(data, 'Duration'),
                    });
                }
                groups[findGroupIndex] = group;
                return null;
            });

            //populating html.
            var htmls = '<table id="Room-List-TimeTable" cellSpacing="0" cellPadding="10" border="1" style="border-style:hidden;font-weight:bold;"><tbody>';
            for (let g = 0; g < groups.length; g++) {
                const group = groups[g];
                htmls += '<tr style="background-color: yellow;height: 35;">';
                htmls += '<td>Group</td>';
                htmls += '<td>Room Code</td>';
                htmls += '<td>Subject</td>';
                htmls += '<td>Time</td>';
                htmls += '<td>Room Title</td>';
                htmls += '<td>Total Question</td>';
                htmls += '<td>Duration</td>';
                htmls += '</tr>';

                for (let r = 0; r < group.Rooms.length; r++) {
                    const room = group.Rooms[r];
                    htmls += '<tr>';
                    if (r === 0) {
                        htmls += '<td rowspan="' + group.Rooms.length + '" valign="top">' + CheckObjectStringEmpty(group, 'GroupName') + '</td>';
                    }
                    htmls += '<td>' + CheckObjectStringEmpty(room, 'RoomCode') + '</td>';
                    htmls += '<td>' + CheckObjectStringEmpty(room, 'Subject') + '</td>';
                    htmls += '<td>' + this.GetRoomTime(room) + '</td>';
                    htmls += '<td>' + CheckObjectStringEmpty(room, 'RoomTitle') + '</td>';
                    htmls += '<td>' + CheckObjectStringEmpty(room, 'QnQty') + '</td>';
                    htmls += '<td>' + this.GetDurationInText(CheckObjectStringEmpty(room, 'Duration')) + '</td>';
                    htmls += '</tr>';
                }

                htmls += '<tr><td colspan="5" style="border:none;">&nbsp;</td></tr>';
            }
            htmls += "</tbody></table>";


            //#region old codes
            // let subjects = [];
            // let currentSubject = -1;

            // let grades = [];
            // let currentGrade = -1;

            // let timeStartGroups = [];
            // let currentTimeStart = -1;

            // let timeEndGroups = [];
            // let currentTimeEnd = -1;

            // let dateStartGroups = [];
            // let currentDateStart = -1;

            // let dateEndGroups = [];
            // let currentDateEnd = -1;

            // this.state.RoomDetailArrays.map((data, key) => {
            //     currentSubject = subjects.findIndex(x => x === data.Subject);
            //     if (currentSubject < 0) {
            //         subjects.push(data.Subject);
            //     }
            //     currentGrade = grades.findIndex(x => x === data.Grade);
            //     if (currentGrade < 0) {
            //         grades.push(data.Grade);
            //     }
            //     currentTimeStart = timeStartGroups.findIndex(x => x === data.TimeStart);
            //     if (currentTimeStart < 0) {
            //         timeStartGroups.push(data.TimeStart);
            //     }
            //     currentTimeEnd = timeEndGroups.findIndex(x => x === data.TimeEnd);
            //     if (currentTimeEnd < 0) {
            //         timeEndGroups.push(data.TimeEnd);
            //     }
            //     currentDateStart = dateStartGroups.findIndex(x => x === data.DateStart);
            //     if (currentDateStart < 0) {
            //         dateStartGroups.push(data.DateStart);
            //     }
            //     currentDateEnd = dateEndGroups.findIndex(x => x === data.DateEnd);
            //     if (currentDateEnd < 0) {
            //         dateEndGroups.push(data.DateEnd);
            //     }
            //     return null;
            // });
            // if (this.props.isDevMode) {
            //     console.log('Subject: ' + subjects.join(', '));
            //     console.log('Grade: ' + grades.join(', '));
            //     console.log('Time Start: ' + timeStartGroups.join(', '));
            //     console.log('Time End: ' + timeEndGroups.join(', '));
            //     console.log('Date Start: ' + dateStartGroups.join(', '));
            //     console.log('Date End: ' + dateEndGroups.join(', '));
            // }

            // //revamped 2023.10.27
            // var htmls = "<table id='Room-List-TimeTable' cellSpacing='0' cellPadding='0' border='1'><tbody>";
            // for (let i = 0; i < grades.length; i++) {

            //     htmls += "<tr style='background-color: yellow;height: 35;'>";
            //     htmls += "<td>" + this.GetGroupName(String(grades[i])) + "</td>";
            //     for (let j = 0; j < timeStartGroups.length; j++) {
            //         let _index = this.state.RoomDetailArrays.findIndex(x => x.Grade === grades[i] && x.TimeStart === timeStartGroups[j]);
            //         if (_index > -1) {
            //             htmls += "<td>" + this.GetRoomTime(this.state.RoomDetailArrays[_index]) + "</td>";
            //             break;
            //         }
            //     }
            //     htmls += "</tr>";

            //     let _roomTitleHtml = '';
            //     for (let m = 0; m < subjects.length; m++) {
            //         for (let p = 0; p < dateStartGroups.length; p++) {
            //             // htmls += "<tr style='height: 35'>";
            //             for (let k = 0; k < timeStartGroups.length; k++) {
            //                 let _index = -1;
            //                 _index = this.state.RoomDetailArrays.findIndex(x =>
            //                     x.Grade === grades[i]
            //                     && x.Subject === subjects[m]
            //                     && x.DateStart === dateStartGroups[p]
            //                     && x.TimeStart === timeStartGroups[k]);
            //                 if (_index > -1) {
            //                     htmls += "<tr style='height: 35'>";
            //                     htmls += "<td>" + this.GetRoomDate(this.state.RoomDetailArrays[_index]) + "</td>";
            //                     htmls += "<td>" + this.GetSubjectText(_index) + "</td>";
            //                     htmls += "</tr>";
            //                     _roomTitleHtml += "<tr style='height: 35'><td>" + this.state.RoomDetailArrays[_index].RoomTitle + "</td></tr>";
            //                 }
            //             }
            //             // htmls += "</tr>";
            //         }
            //     }
            //     htmls += "</tbody></table></td><td width='50'></td><td>";
            //     htmls += "<table id='roomTitle_" + i + "' cellSpacing='0' cellPadding='5' border='1' style='font-weight: bold;font-weight: bold;border: 0px solid black;'>";
            //     htmls += "<tbody><tr style='background-color: yellow;height: 35;'><td>Room Title</td></tr>";
            //     htmls += _roomTitleHtml + "</tbody></table>";
            //     htmls += "<tr><td colspan='3'>&nbsp;</td></tr>";
            // }
            // htmls += "</tbody></table>";
            //#endregion

            //#region old codes
            // var htmls = "<table id='Room-List-TimeTable' cellSpacing='0' cellPadding='0' border='0'><tbody>";
            // for (let i = 0; i < grades.length; i++) {

            //     htmls += "<tr><td><table id='" + i + "' cellSpacing='0' cellPadding='5' border='1' style='font-weight: bold;'>";
            //     htmls += "<tbody><tr style='background-color: yellow;height: 35;'>";
            //     htmls += "<td>" + this.GetGroupName(String(grades[i])) + "</td>";
            //     for (let j = 0; j < timeStartGroups.length; j++) {
            //         let _index = this.state.RoomDetailArrays.findIndex(x => x.Grade === grades[i] && x.TimeStart === timeStartGroups[j]);
            //         if (_index > -1) {
            //             htmls += "<td>" + this.GetRoomTime(this.state.RoomDetailArrays[_index]) + "</td>";
            //         }
            //     }
            //     htmls += "</tr>";

            //     let _roomTitleHtml = '';
            //     for (let m = 0; m < subjects.length; m++) {
            //         for (let p = 0; p < dateStartGroups.length; p++) {
            //             // htmls += "<tr style='height: 35'>";
            //             for (let k = 0; k < timeStartGroups.length; k++) {
            //                 let _index = -1;
            //                 _index = this.state.RoomDetailArrays.findIndex(x =>
            //                     x.Grade === grades[i]
            //                     && x.Subject === subjects[m]
            //                     && x.DateStart === dateStartGroups[p]
            //                     && x.TimeStart === timeStartGroups[k]);
            //                 if (_index > -1) {
            //                     htmls += "<tr style='height: 35'>";
            //                     htmls += "<td>" + this.GetRoomDate(this.state.RoomDetailArrays[_index]) + "</td>";
            //                     htmls += "<td>" + this.GetSubjectText(_index) + "</td>";
            //                     htmls += "</tr>";
            //                     _roomTitleHtml += "<tr style='height: 35'><td>" + this.state.RoomDetailArrays[_index].RoomTitle + "</td></tr>";
            //                 }
            //             }
            //             // htmls += "</tr>";
            //         }
            //     }
            //     htmls += "</tbody></table></td><td width='50'></td><td>";
            //     htmls += "<table id='roomTitle_" + i + "' cellSpacing='0' cellPadding='5' border='1' style='font-weight: bold;font-weight: bold;border: 0px solid black;'>";
            //     htmls += "<tbody><tr style='background-color: yellow;height: 35;'><td>Room Title</td></tr>";
            //     htmls += _roomTitleHtml + "</tbody></table>";
            //     htmls += "<tr><td colspan='3'>&nbsp;</td></tr>";
            // }
            // htmls += "</tbody></table>";

            // //2021.04.06 === fixing Copy/Paste feature on browser, where right now permission is needed to success === start
            // const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };
            // const permissionStatus = await navigator.permissions.query(queryOpts);
            // // Will be 'granted', 'denied' or 'prompt':
            // // console.log(permissionStatus.state);
            // if (navigator.clipboard !== undefined) {//Chrome
            //     await navigator.clipboard.writeText(htmls).then(function () {
            //         console.log('Copied to clipboard');
            //     }, function (err) {
            //         console.error('Could not copy text: ', err);
            //     });
            // }
            // else if (window.clipboardData) { // Internet Explorer
            //     window.clipboardData.setData("Text", htmls);
            // }
            // //2021.04.06 === end
            //#endregion

            this.setState({
                Hidden_RoomList_TimeTable: htmls,
            });
        }
    }

    //2021.07.29
    PreviewRoomListTimeTable = () => {
        var newWindow = window.open();
        newWindow.document.body.innerHTML = "<html><head></head><body>" + this.state.Hidden_RoomList_TimeTable + "</body></html>";
    }

    //2021.07.29
    GetGroupName = (groupNumber = '') => {
        switch (groupNumber) {
            default: return '';
            case '1': return '一年级';
            case '2': return '二年级';
            case '3': return '三年级';
            case '4': return '四年级';
            case '5': return '五年级';
            case '6': return '六年级';
        }
    }

    //2021.12.28
    GetGroup = (roomDetail = null) => {
        if (roomDetail !== null) {
            let _group = null;
            if (roomDetail.hasOwnProperty('GroupId')) {
                let _groupIndex = this.props.GroupOptions.findIndex(x => Number(x.id) === Number(roomDetail.GroupId));
                if (_groupIndex > -1)
                    _group = this.props.GroupOptions[_groupIndex];
            }
            return _group;
        }
        return null;
    }

    //2021.07.29
    GetRoomTime = (data) => {
        let today = moment().format('YYYY-MM-DD ');
        let startTime = data.TimeStart;
        let endTime = data.TimeEnd;
        if (startTime !== undefined && endTime !== undefined)
            return moment(today + startTime).format('hh:mm A') + ' - ' + moment(today + endTime).format('hh:mm A');
        else
            return 'invalid time format';
    }

    //2021.07.29
    GetRoomDate = (data) => {
        let day = moment(data.DateStart).day();
        let dayChinese = '';
        switch (day) {
            default: dayChinese = ''; break;
            case 0: dayChinese = '星期日'; break;
            case 1: dayChinese = '星期一'; break;
            case 2: dayChinese = '星期二'; break;
            case 3: dayChinese = '星期三'; break;
            case 4: dayChinese = '星期四'; break;
            case 5: dayChinese = '星期五'; break;
            case 6: dayChinese = '星期六'; break;
        }
        return dayChinese + ' (' + moment(data.DateStart).format('Do)');
    }

    //2021.07.29
    GetSubjectText = (_index) => {
        let roomCode = this.state.RoomDetailArrays[_index].RoomCode;
        let subject = String(this.state.RoomDetailArrays[_index].Subject).toLowerCase();
        switch (subject) {
            default: return 'N/A (' + roomCode + ')';
            case 'science': return '科学 (' + roomCode + ')';
            case 'chinese': return '华语 (' + roomCode + ')';
            case 'english': return '英语 (' + roomCode + ')';
            case 'malay': return '国语 (' + roomCode + ')';
            case 'mathematics': return '数学 (' + roomCode + ')';
            case 'mixedquestions': return '多科目 (' + roomCode + ')';
        }
    }

    //2021.07.29
    GetNameOfRoomListTimeTable = () => {
        return "RoomList_TimeTable_" + this.state.liveQuizDate;
    }

    SelectRoom = (_roomId) => {
        // window.alert(_roomId);
        // this.state.JoinedPlayerEmails = [];
        // this.state.JoinedPlayerArrays = [];
        // this.state.RankingListArrays = [];
        // this.state.FinalRankingList = [];
        this.setState({
            IsButtonEnabled: false,
            // JoinedPlayerEmails: this.state.JoinedPlayerEmails,
            // JoinedPlayerArrays: this.state.JoinedPlayerArrays,
            // RankingListArrays: this.state.RankingListArrays,
            // FinalRankingList: this.state.FinalRankingList,
            // JoinedPlayerEmails: [],
            // JoinedPlayerArrays: [],
            // RankingListArrays: [],
            // FinalRankingList: [],

            autoRefreshElapsedTime: 0,
        });
        // }, () => {

        let _roomIndex = 0;
        let _roomCode = 0;
        switch (uiModeState) {
            case UiMode.ShowRoomList:
                this.setState({
                    RoomDetailUiComponent: '',
                    IsRoomDetailsLoaded: false,
                    IsShowRoomDetails: false,
                }, async () => {
                    if (this.state.RoomDetailArrays.length > 0) {
                        // let _roomIndex = 0;
                        // let _roomCode = 0;
                        if (_roomId > 0) {
                            // this.state.RoomDetailArrays.map((data, index) => {
                            //     if (data.RoomId === _roomId) {
                            //         _roomIndex = index;
                            //         _roomCode = data.RoomCode;
                            //     }
                            //     return null;
                            // }),

                            //2022.02.09 - revamped.
                            let _QuestionSetDetails = null;
                            let _RoomDetailArrays = this.state.RoomDetailArrays;
                            await Promise.all(
                                this.state.RoomDetailArrays.map(async (data, index) => {
                                    if (data.RoomId === _roomId) {
                                        _roomIndex = index;
                                        _roomCode = data.RoomCode;

                                        let _QuestionSetUniqueId = this.GetValue(data, 'QuestionSetUniqueId', '');
                                        _QuestionSetDetails = this.GetValue(data, 'QuestionSetDetails', null);
                                        if (_QuestionSetUniqueId !== '' && _QuestionSetDetails === null) {
                                            await this.questionSetPathV2(_QuestionSetUniqueId).once('value', snapshot => {
                                                if (snapshot.exists())
                                                    _QuestionSetDetails = snapshot.val();
                                            });
                                        }
                                    }
                                    return null;
                                }),
                            );
                            if (this.GetValue(_RoomDetailArrays[_roomIndex], 'QuestionSetDetails', null) === null && _QuestionSetDetails !== null) {
                                _RoomDetailArrays[_roomIndex].QuestionSetDetails = _QuestionSetDetails;
                                this.setState({ RoomDetailArrays: _RoomDetailArrays });
                                await Delay(200);
                            }
                        }
                        this.processRoomDetailUi(_roomIndex, _roomId, _roomCode);
                    }
                });
                break;
            // case UiMode.ShowJoinedPlayers:
            //     this.setState({
            //         isShowJoinedPlayers: true,
            //         isJoinedPlayersLoaded: false,
            //         // JoinedPlayerArrays: [],
            //         // JoinedPlayerEmails: [],
            //     }, () => {
            //         if (this.state.RoomDetailArrays.length > 0) {
            //             // let _roomIndex = 0;
            //             if (_roomId > 0) {
            //                 this.state.RoomDetailArrays.map((data, index) => {
            //                     if (data.RoomId === _roomId) {
            //                         _roomIndex = index;
            //                         _roomCode = data.RoomCode;
            //                     }
            //                     return null;
            //                 });
            //             }
            //             this.fetchJoinedPlayerList(_roomIndex, _roomId, _roomCode);
            //         }
            //     });
            //     break;
            // case UiMode.ShowRankingList:
            //     this.setState({
            //         isShowRankingList: true,
            //         isRankingListLoaded: false,
            //         // JoinedPlayerEmails: [],
            //         // JoinedPlayerArrays: [],
            //         // RankingListArrays: [],
            //         // FinalRankingList: [],
            //     }, () => {
            //         if (this.state.RoomDetailArrays.length > 0) {
            //             // let _roomIndex = 0;
            //             if (_roomId > 0) {
            //                 this.state.RoomDetailArrays.map((data, index) => {
            //                     if (data.RoomId === _roomId) {
            //                         _roomIndex = index;
            //                         _roomCode = data.RoomCode;
            //                     }
            //                     return null;
            //                 });
            //             }
            //             this.fetchRankingList(_roomIndex, _roomId, _roomCode);
            //         }
            //     });
            //     break;
            case UiMode.ShowFinalRankingList:
                this.setState({
                    IsShowFinalRankingList: true,
                    IsFinalRankingListLoaded: false,
                    JoinedPlayerEmails: [],
                    JoinedPlayerArrays: [],
                    RankingListArrays: [],
                    FinalRankingList: [],
                    Hidden_Ranking_List_With_Results: '',
                    // RankingListResults: [],
                }, () => {
                    if (this.state.RoomDetailArrays.length > 0) {
                        // let _roomIndex = 0;
                        if (_roomId > 0) {
                            this.state.RoomDetailArrays.map((data, index) => {
                                if (data.RoomId === _roomId) {
                                    _roomIndex = index;
                                    _roomCode = data.RoomCode;
                                }
                                return null;
                            });
                        }
                        this.fetchFinalRankingList(_roomIndex, _roomId, _roomCode);
                    }
                });
                break;
            default:
                break;
        }
        // });
    }

    processRoomDetailUi = (_index, _roomId, _roomCode) => {
        this.setState({
            selectedRoomCode: _roomCode,
            selectedRoomId: _roomId,
            selectedRoomIndex: _index,
            prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
            IsRoomDetailsLoaded: true,
            RoomDetailUiComponent: '',
            singleQuestionDetailArray: [],
            isShowQuestionDetailHtml: false,
        }, () => {

            let roomDetail = this.state.RoomDetailArrays[_index];
            if (this.props.isDevMode)
                console.log(JSON.stringify(roomDetail));

            //Draw Ui.
            let _selectedQuestionsUi = "<table border={1} borderColor='white' bgcolor={'#2e2e2e'}><tr>";

            //2020.07.16
            if (roomDetail.Grade === 'L' || roomDetail.Grade === 'U') {
                _selectedQuestionsUi += "<td colspan='10' align='center' style='color:blue;'>These questions are not available to view online at the moment.</td></tr><tr>";
            }

            roomDetail.Questions.split(';')
                .map((qs, key) => {
                    if (qs.length > 0) {
                        // {
                        _selectedQuestionsUi += "<td width=50 align='center' id='" + qs + "'>"
                            + "<a href='#" + qs + "' id='" + qs + "' onclick='return false;'>&nbsp;"
                            + qs
                            + "&nbsp;</a></td>";
                        if (key > 0 && (key + 1) % 10 === 0) {
                            _selectedQuestionsUi += "</tr><tr>";
                        }
                        // }
                    }
                    return null;
                })
            _selectedQuestionsUi += "</tr></table>";

            //2021.12.28
            let _group = this.GetGroup(roomDetail);

            let _roomDetails_temp = <tbody>
                <tr><td colSpan={2} style={{ color: 'black', fontWeight: 'bold' }}>Room Details&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ fontSize: 16 }}><u><a href='#top'>back to top</a></u></span></td></tr>
                <tr><td>Room Code </td><td>: {roomDetail.RoomCode} </td></tr>
                <tr><td>Room Id </td><td>: {roomDetail.RoomId} </td></tr>
                <tr><td>Title </td><td>: {roomDetail.RoomTitle} </td></tr>
                <tr><td>Subject </td><td><div dangerouslySetInnerHTML={{ __html: this.GetSubjectName(_index) }}></div></td></tr>
                {/* <tr><td>Group </td><td>: {roomDetail.Grade} </td></tr> */}
                <tr><td>Group </td><td>: {_group !== null ? _group.label : roomDetail.Grade} </td></tr>
                <tr><td>Total Duration </td><td>: {this.GetDurationInText(roomDetail.Duration)} ({roomDetail.Duration} sec) </td></tr>
                <tr><td>Duration Per Question &nbsp;</td><td>: {roomDetail.DurationPerQuestion} sec</td></tr>
                <tr><td>Question Set </td><td>: {roomDetail.QuestionSetDetails === null ? '' : <>{this.GetValue(roomDetail.QuestionSetDetails, 'Name', '')} <span style={{ fontSize: 16, color: 'gray' }}>({roomDetail.QuestionSetUniqueId})</span></>}</td></tr>
                <tr><td>Total Question </td><td>: {roomDetail.QnQty} </td></tr>
                {/* <tr><td>Selected Questions </td><td>: {roomDetail.Questions.split(';').map(qs => <p>{qs}</p>)} </td></tr> */}
                {/* <tr><td>Selected Questions </td><td>: {roomDetail.Questions.replace(/;/g, '\n')} </td></tr> */}

                <tr><td></td></tr>
                <tr><td>Start Time </td><td>: {
                    roomDetail.StartTime === '_none' ?
                        <font color='blue'>Quiz is available for today ({moment(this.state.selectedDate).format('ll')}) until 12 AM.</font>
                        :
                        <>{roomDetail.StartTime} <font color='blue'>({moment(roomDetail.StartTime).format('lll')})</font></>
                }</td></tr>
                <tr><td>End Time </td><td>: {
                    roomDetail.EndTime === '_none' ?
                        <font color='blue'>Quiz is available for today ({moment(this.state.selectedDate).format('ll')}) until 12 AM.</font>
                        :
                        <>{roomDetail.EndTime} <font color='blue'>({moment(roomDetail.EndTime).format('lll')})</font></>
                }</td></tr>
                <tr><td></td></tr>

                <tr>
                    <td valign={'top'}>Questions </td>
                    <td>
                        <div
                            onClick={this.fetchQuestion}
                            dangerouslySetInnerHTML={{ __html: _selectedQuestionsUi }}
                        />
                    </td>
                </tr>

                <tr>
                    <td colSpan='2' valign='bottom' height='75'>
                        <button
                            className='btn btn-secondary'
                            disabled={moment() > moment(this.state.liveQuizDate).add(1, 'day')}
                            onClick={() => this.RemoveSelectedRoom(roomDetail.RoomCode, roomDetail.RoomId)}
                        >Remove this Room ({roomDetail.RoomCode + '/' + roomDetail.RoomId}) {
                                moment() > moment(this.state.liveQuizDate).add(1, 'day') ?
                                    "(disabled after 24hrs on current Quiz's Date)"
                                    : ''
                            }</button>
                    </td>
                </tr>
            </tbody>;

            //Show Ui.
            // _QsUiTopPos = '0px';
            this.setState({
                // QsUiTopPos: '0px',
                RoomDetailUiComponent: _roomDetails_temp,
                IsShowRoomDetails: true,
                IsButtonEnabled: true,
                // });
            }, async () => {
                // await this.LoadQuizQuestions();

                // //2022.06.17
                // if (this.state.RoomDetailArrays.length > 8) {
                //     //2021.12.28
                //     ScrollToElement('RoomDetailTitle');
                // }
            });
            // window.alert(this.state.selectedRoomId);
        });
    }

    RemoveSelectedRoom = async (_roomCode, _roomId) => {
        let confirmRemoveRoom = window.confirm('Are you sure you really want to remove this room ?\nOnce confirmed, this action is not reversible.\nClick confirm to continue, or Cancel to skip this action.');
        if (confirmRemoveRoom) {
            let _securityCode = window.prompt('Enter Admin Code to continue', '');
            if (_securityCode === '8271653') {
                alert('Admin Code validated, proceed to remove selected Room.');

                //Remove record on FireStore > LiveQuiz_UniqueRoomCode
                await this.props.dbFireStore
                    .collection("LiveQuiz_UniqueRoomCode")
                    .doc(_roomId)
                    .delete()
                    .then(() => {
                        if (this.props.isDevMode)
                            console.log('Room (' + _roomCode + '/' + _roomId + ') has been removed from FS.');
                    })
                    .catch((error) => {
                        if (this.props.isDevMode)
                            console.log('Room (' + _roomCode + '/' + _roomId + ') removal action encountered error.\n' + error);
                    });

                //Remove records on RTDB > https://ikeyedutech-student-5ea3c-pkquiz.firebaseio.com/pkquiz/{quizDate} 
                //3 location > pkquiz-room-detail (roomid) , pkquiz-room-code (roomcode) , pkquiz-live (roomid)

                //pkquiz-room-detail
                await this.roomDetailPathByRoomId(_roomId)
                    .remove()
                    .then(() => {
                        if (this.props.isDevMode)
                            console.log('Room (' + _roomCode + '/' + _roomId + ') has been removed from RTDB (pkquiz-room-detail).');
                    })
                    .catch((error) => {
                        if (this.props.isDevMode)
                            console.log('Room (' + _roomCode + '/' + _roomId + ') removal action encountered error. (pkquiz-room-detail)\n' + error);
                    });

                //pkquiz-room-code
                await this.roomCodePathByRoomCode(_roomCode)
                    .remove()
                    .then(() => {
                        if (this.props.isDevMode)
                            console.log('Room (' + _roomCode + '/' + _roomId + ') has been removed from RTDB (pkquiz-room-code).');
                    })
                    .catch((error) => {
                        if (this.props.isDevMode)
                            console.log('Room (' + _roomCode + '/' + _roomId + ') removal action encountered error. (pkquiz-room-code)\n' + error);
                    });

                //pkquiz-live
                await this.liveQuizRoomStatePath(_roomId)
                    .remove()
                    .then(() => {
                        if (this.props.isDevMode)
                            console.log('Room (' + _roomCode + '/' + _roomId + ') has been removed from RTDB (pkquiz-live).');
                    })
                    .catch((error) => {
                        if (this.props.isDevMode)
                            console.log('Room (' + _roomCode + '/' + _roomId + ') removal action encountered error. (pkquiz-live)\n' + error);
                    });

                //reload Room List & Ui.
                this.onCalenderChange(this.state.selectedDate);
            }
            else {
                alert('Invalid Admin Code.');
            }
        }
    }

    GetSubjectName = (_index) => {
        let textToReturn = ": " + this.state.RoomDetailArrays[_index].Subject;
        if (String(this.state.RoomDetailArrays[_index].Subject).toUpperCase() === "SEC_BM_COLIN") {
            textToReturn += " (aka <span style='color:blue;'>Colin's BM</span>)";
        }
        return textToReturn;
    }

    GetDurationInText = (_totalSeconds) => {
        var minutes = (_totalSeconds / 60).toFixed(3).split('.')[0];
        var seconds = (_totalSeconds % 60).toString();
        return minutes + ' min ' + seconds + ' sec';
    }

    fetchQuestion = (e) => {
        // window.alert(e);
        let _id = e.target.id;
        // window.alert(_id);

        if (_id != null && _id > 0) {
            this.setState({
                singleQuestionDetailArray: [],
                singleQuestionDetailHtml: '',
                isShowQuestionDetailHtml: false,
            }, () => {
                // this.questionPath(this.state.selectedRoomIndex, _id)
                //     .once('value', snapshot => {
                //         this.processSingleQuestionUi(snapshot.val(), _id);
                //     });

                let isFound = false;
                let roomDetails = this.state.RoomDetailArrays[this.state.selectedRoomIndex];
                if (roomDetails.hasOwnProperty('QuestionSetUniqueId')) {
                    this.props.dbQuizBank.ref(String(roomDetails.QuestionSetUniqueId) + '/Questions/' + _id)
                        .once('value', snapshot => {
                            if (this.props.isDevMode)
                                console.log(_id + '\n' + JSON.stringify(snapshot.val()));
                            isFound = snapshot.exists();
                            if (isFound)
                                this.processSingleQuestionUi(snapshot.val(), _id);
                        });
                }
                else {
                    this.questionPath(this.state.selectedRoomIndex, _id)
                        .once('value', snapshot => {
                            if (this.props.isDevMode)
                                console.log(_id + '\n' + JSON.stringify(snapshot.val()));
                            isFound = snapshot.exists();
                            if (isFound)
                                this.processSingleQuestionUi(snapshot.val(), _id);
                        });
                }
            });
        }
    }

    processSingleQuestionUi = (dataSnapVal, _id) => {
        if (dataSnapVal == null) {
            window.alert('This question is not available to view online at the moment.');
            return;
        }

        let dataKeys = _(dataSnapVal).keys().value();       //key
        let dataValues = _(dataSnapVal).values().value();   //values

        // window.alert(dataKeys[0].toString() + " : " + dataValues[0].toString());
        // window.alert(dataKeys[0] + " : " + _(dataValues[0]).value());

        let _singleQuestionDetailArray = [];
        dataKeys.map((data, key) => {
            // this.state.singleQuestionDetailArray[data] = _(dataValues[key]).value();
            // window.alert(data + " : " + this.state.singleQuestionDetailArray[data]);
            return _singleQuestionDetailArray[data] = _(dataValues[key]).value();
        });

        this.setState({
            // singleQuestionDetailArray: this.state.singleQuestionDetailArray,
            singleQuestionDetailArray: _singleQuestionDetailArray,
        }, () => {
            let formatedQuestionUi = "<table style='text-align: left;'>";
            formatedQuestionUi += "<tr><td colspan='3'><b><u><font color='blue' size=5>Question #" + _id + "</font></u> ("
                + String(this.state.singleQuestionDetailArray['QuizType']) + ")</b></td></tr>";
            // formatedQuestionUi += "<tr><td>Question </td><td>&nbsp; : &nbsp;</td><td>" + this.state.singleQuestionDetailArray['Content'] + "</td></tr>";

            //2020.12.31
            if (
                String(this.state.singleQuestionDetailArray['Content']).replace(' ', '').length > 0
                && String(this.state.singleQuestionDetailArray['Content']) !== "<subjective-instruction>"
            ) {
                formatedQuestionUi += "<tr><td valign='top'>Question </td><td valign='top'>&nbsp;:&nbsp;</td><td valign='top'>" + this.state.singleQuestionDetailArray['Content'] + "</td></tr>";
            }

            // formatedQuestion += "<tr><td>Answer </td><td> : " + this.state.singleQuestionDetailArray['Answer'] + "</td></tr>";
            // formatedQuestion += "<tr><td valign='top' colspan='3'>Options </td></tr><tr><td colspan='2'></td><td>";
            if (this.state.singleQuestionDetailArray['PictureUrl'].length > 0) {
                formatedQuestionUi += "<tr><td valign='top'>Picture</td><td valign='top'>&nbsp;:&nbsp;</td>";
                formatedQuestionUi += "<td valign='top' width='100%'><img width='375px' src='"
                    + "https://ikeynew.blob.core.windows.net/ikeykidz/JPG/"
                    + this.state.singleQuestionDetailArray['PictureUrl'] + ".jpg' alt='' /></td></tr>";
            }
            else {
                formatedQuestionUi += "<tr><td colspan='3' height=25></td></tr>";
            }

            //2020.12.31
            if (this.state.singleQuestionDetailArray['Selection'].length <= 0) {
                formatedQuestionUi += "<tr><td valign='top'>Answer</td><td valign='top'>&nbsp;:&nbsp;</td><td>";
                formatedQuestionUi += this.state.singleQuestionDetailArray['Answer'];
            }
            else {
                formatedQuestionUi += "<tr><td valign='top' colspan='2'>Options </td><td valign='top'>";
                formatedQuestionUi += "<table>";
                let answerOptions = this.state.singleQuestionDetailArray['Selection'].split(';');
                answerOptions.map((data, key) => {
                    if (data.length > 0) {
                        formatedQuestionUi +=
                            data.split(':')[1] === '' ? '' :
                                "<tr><td valign='top'>"
                                + (
                                    this.state.singleQuestionDetailArray['Answer'] === data.split(':')[0] ?
                                        "<b><u><font color='blue'>" + data.split(':')[0] + "</font></u></b>"
                                        :
                                        data.split(':')[0]
                                )
                                +
                                (
                                    data.split(':')[1].includes('_pk_') ?
                                        "&nbsp;&nbsp;<img src='https://ikeynew.blob.core.windows.net/ikeykidz/JPG/" + data.split(':')[1] + ".jpg' alt='' width='70%' />"
                                        :
                                        "</td><td valign='top'>&nbsp;:&nbsp;</td><td valign='top'>" + data.split(':')[1] + "</td></tr>"
                                );
                    }
                    return null;
                });
                formatedQuestionUi += "</table>";
            }
            formatedQuestionUi += "</td></tr>";
            // formatedQuestionUi += "<tr><td align='right' colspan='3'><button id='closeQsDetailUi' class='buttonStyleCloseUi'>Close</button></td></tr>";
            // formatedQuestionUi += "<tr><td align='right' colspan='3'><Button id='closeQsDetailUi' variant='contained' color='primary'>Close</Button></td></tr>";
            formatedQuestionUi += "</table>"
            // window.alert(formatedQuestion);

            // _QsUiTopPos = '200px';   
            this.setState({
                // QsUiTopPos: '200px',
                singleQuestionDetailHtml: formatedQuestionUi,
                isShowQuestionDetailHtml: true,
            });
        });
    }

    // closeQsDetailUi = (e) => {
    //     let _id = e.target.id;
    //     if (_id != null && _id === 'closeQsDetailUi') {
    //         this.setState({
    //             singleQuestionDetailArray: [],
    //             singleQuestionDetailHtml: '',
    //             isShowQuestionDetailHtml: false,
    //         });
    //     }
    // }

    fetchJoinedPlayerList = (_roomIndex, _roomId, _roomCode) => {
        // let date = moment(this.state.selectedDate).format('YYYYMMDD');
        this.setState({
            selectedRoomCode: _roomCode,
            selectedRoomId: _roomId,
            selectedRoomIndex: _roomIndex,
            prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
        }, async () => {
            await this.joinedPlayersPathV2(this.state.liveQuizDate, _roomId).once('value', async (snapshot) => {
                await this.processJoinedPlayersData(snapshot.val());
            });
        });
    }

    processJoinedPlayersData = async (dataSnapVal) => {
        let dataKeys = _(dataSnapVal).keys().value();       //key = uid
        let dataValues = _(dataSnapVal).values().value();   //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"

        // window.alert(_(dataValues[0]).values().value()[0]); //correct

        this.setState({
            JoinedPlayerArrays: [],
            JoinedPlayerEmails: [],
        });

        // await Promise.all(
        // this.state.liveQuizDate > 20200607 ?
        dataKeys.map(async (data, key) => {
            // if (data.indexOf('%') < 0) {                        
            //     await this.userEmailPath(data).once('value', snapshot => {
            //         return this.state.JoinedPlayerEmails.push(snapshot.val());  //Current Format
            //     });
            // }
            // else {
            //     return this.state.JoinedPlayerEmails.push('N/A');
            // }
            // await this.userEmailPath(data).once('value', snapshot => {
            //     return this.state.JoinedPlayerEmails.push(snapshot.val());  //Current Format
            // });

            let dataSet = dataValues[key].split(';');

            let _uid = data.length >= 28 && data.indexOf(' ') === -1 ? data : dataSet[2];
            let _email = '';
            // let uid = uidRegex.test(data) ? data : dataValues[key].split(';')[2].toString();
            // let _uid = data.length >= 28 && data.indexOf(' ') === -1 ? data : dataValues[key].split(';')[2].toString();

            await this.userEmailPath(_uid).once('value', snapshot => {
                // return this.state.JoinedPlayerEmails.push(snapshot.val());  //Current Format
                return _email = snapshot.val();
            });

            //2020.12.09
            let _name = dataSet[2];
            if (_name.length <= 0) {
                await this.userNamePath(_uid).once('value', snapshot => {
                    // return this.state.JoinedPlayerEmails.push(snapshot.val());
                    return _name = snapshot.val();
                });
                // alert(_name);
            }

            //2020.12.12
            if (_name.length <= 0) {
                await this.props.dbFireStore.collection("User")
                    .doc(_uid)
                    .get()
                    .then((querySnapshot) => {
                        let data = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                data.push(doc.data());
                            });
                        }
                        if (data.length > 0) {
                            _name = data[0]['Name'];
                        }
                    });
                // alert(_name);
            }

            // if (_email === 'yaphs3989@gmail.com') {
            //     alert(_name + "\n\n" + _email);
            // }
            alert(_name);

            let _emails = this.state.JoinedPlayerEmails;

            if (_emails.findIndex(element => element.email === _email) < 0) {
                _emails.push(
                    {
                        uid: _uid,
                        email: _email,
                        pname: _name.length > 0 ? _name : _email.split('@')[0],    //2020.12.09
                    }
                );
            }
            this.setState({
                JoinedPlayerEmails: _emails,
            });
        });
        // :
        // dataValues.map(async (data, key) => {
        //     // if (data.split(';')[2].indexOf('%') < 0) {
        //     //     await this.userEmailPath(data.split(';')[2]).once('value', snapshot => {
        //     //         return this.state.JoinedPlayerEmails.push(snapshot.val());
        //     //     });
        //     // }
        //     // else {                        
        //     //     return this.state.JoinedPlayerEmails.push('N/A');
        //     // }
        //     let _email = '';
        //     let _uid = data.split(';')[2];
        //     await this.userEmailPath(_uid).once('value', snapshot => {
        //         // return this.state.JoinedPlayerEmails.push(snapshot.val());
        //         return _email = snapshot.val();
        //     });

        //     //2020.12.09
        //     let _name = '';
        //     await this.userNamePath(_uid).once('value', snapshot => {
        //         // return this.state.JoinedPlayerEmails.push(snapshot.val());
        //         return _name = snapshot.val();
        //     });

        //     //2020.12.12
        //     if (_name.length <= 0) {
        //         await this.props.dbFireStore.collection("User")
        //             .where('Uid', '==', _uid)
        //             .get()
        //             .then((querySnapshot) => {
        //                 let data = [];
        //                 if (querySnapshot !== null) {
        //                     querySnapshot.forEach((doc) => {
        //                         data.push(doc.data());
        //                     });
        //                 }
        //                 if (data.length > 0) {
        //                     _name = data[0]['Name'];
        //                 }
        //             });
        //     }


        //     if (this.state.JoinedPlayerEmails.findIndex(element => element.email === _email) < 0) {
        //         this.state.JoinedPlayerEmails.push(
        //             {
        //                 uid: _uid,
        //                 email: _email,
        //                 pname: _name.length > 0 ? _name : _email.split('@')[0],    //2020.12.09
        //             }
        //         );
        //     }
        //     this.setState({
        //         JoinedPlayerEmails: this.state.JoinedPlayerEmails,
        //     });
        // })
        // );
        // // .then(() => {
        // //     this.setState({
        // //         JoinedPlayerEmails: this.state.JoinedPlayerEmails,
        // //     }, () => {
        // //         // window.alert(this.state.JoinedPlayerEmails.length);
        // //     });
        // // })
        // .then(() => {
        //     dataKeys.map((data, key) => {
        //         let dataVal = _(dataValues[key]).value().split(';');
        //         let _isVerNew = data.length >= 28 && data.indexOf(' ') === -1 ? true : false;
        //         if (this.state.liveQuizDate > 20200607) {   //  (/^\s*$/.test(dataVal[2])) = contains any space
        //             // if (this.state.liveQuizDate > 20200607 || !(/^\s*$/.test(dataVal[2]))) {
        //             let _pname = data.length >= 28 && data.indexOf(' ') === -1 ?
        //                 decodeURI(dataVal[2])
        //                 : decodeURI(data);

        //             let _uid = data.length >= 28 && data.indexOf(' ') === -1 ?
        //                 data
        //                 : dataValues[key].split(';')[2].toString();

        //             //2020.12.09
        //             if (_pname.length <= 0) {
        //                 _pname = this.state.JoinedPlayerEmails.find(x => x.uid === _uid).pname;
        //             }

        //             return this.state.JoinedPlayerArrays.push({
        //                 // PlayerName: !(/^\s*$/.test(decodeURI(dataVal[2]))) ? decodeURI(dataVal[2]) : data,
        //                 // PlayerName: data.length >= 28 && data.indexOf(' ') === -1 ? decodeURI(dataVal[2]) : data,
        //                 PlayerName: _pname,
        //                 Scores: dataVal[0],
        //                 TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
        //                 IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
        //                 Uid: _uid,
        //                 // Email: this.state.JoinedPlayerEmails[key + 1],
        //                 Email: this.state.JoinedPlayerEmails.find(x => x.uid === _uid).email,
        //                 Version: _isVerNew,
        //             });
        //         }
        //         else {

        //             //2020.12.09
        //             let _pname = decodeURI(data);
        //             if (_pname.length <= 0) {
        //                 _pname = this.state.JoinedPlayerEmails.find(x => x.uid === dataVal[2]).pname;
        //             }

        //             return this.state.JoinedPlayerArrays.push({
        //                 // PlayerName: decodeURI(data),
        //                 PlayerName: _pname,
        //                 Scores: dataVal[0],
        //                 TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
        //                 IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
        //                 Uid: dataVal[2],
        //                 // Email: this.state.JoinedPlayerEmails[key + 1],
        //                 Email: this.state.JoinedPlayerEmails.find(x => x.uid === dataVal[2]).email,
        //                 Version: _isVerNew,
        //             });
        //         }
        //     });
        // })
        // .then(() => {
        //     //Sort List according to Name in ASC
        //     this.state.JoinedPlayerArrays.sort(function (a, b) {
        //         if (a.PlayerName < b.PlayerName) { return -1; }
        //         if (a.PlayerName > b.PlayerName) { return 1; }
        //         return 0;
        //     });

        //     //Show Ui & Joined Players List
        //     this.setState({
        //         JoinedPlayerArrays: this.state.JoinedPlayerArrays,
        //         isJoinedPlayersLoaded: true,
        //         IsButtonEnabled: true,
        //     });
        // });

        dataKeys.map((data, key) => {
            let dataVal = _(dataValues[key]).value().split(';');
            let _isVerNew = data.length >= 28 && data.indexOf(' ') === -1 ? true : false;
            if (this.state.liveQuizDate > 20200607) {   //  (/^\s*$/.test(dataVal[2])) = contains any space
                // if (this.state.liveQuizDate > 20200607 || !(/^\s*$/.test(dataVal[2]))) {
                let _pname = data.length >= 28 && data.indexOf(' ') === -1 ?
                    decodeURI(dataVal[2])
                    : decodeURI(data);

                let _uid = data.length >= 28 && data.indexOf(' ') === -1 ?
                    data
                    : dataValues[key].split(';')[2].toString();

                //2020.12.09
                if (_pname.length <= 0) {
                    _pname = this.state.JoinedPlayerEmails.find(x => x.uid === _uid).pname;
                }

                return this.state.JoinedPlayerArrays.push({
                    // PlayerName: !(/^\s*$/.test(decodeURI(dataVal[2]))) ? decodeURI(dataVal[2]) : data,
                    // PlayerName: data.length >= 28 && data.indexOf(' ') === -1 ? decodeURI(dataVal[2]) : data,
                    PlayerName: _pname,
                    Scores: dataVal[0],
                    TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                    IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
                    Uid: _uid,
                    // Email: this.state.JoinedPlayerEmails[key + 1],
                    Email: this.state.JoinedPlayerEmails.find(x => x.uid === _uid).email,
                    Version: _isVerNew,
                });
            }
            else {

                //2020.12.09
                let _pname = decodeURI(data);
                if (_pname.length <= 0) {
                    _pname = this.state.JoinedPlayerEmails.find(x => x.uid === dataVal[2]).pname;
                }

                return this.state.JoinedPlayerArrays.push({
                    // PlayerName: decodeURI(data),
                    PlayerName: _pname,
                    Scores: dataVal[0],
                    TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                    IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
                    Uid: dataVal[2],
                    // Email: this.state.JoinedPlayerEmails[key + 1],
                    Email: this.state.JoinedPlayerEmails.find(x => x.uid === dataVal[2]).email,
                    Version: _isVerNew,
                });
            }
        });

        //Sort List according to Name in ASC
        this.state.JoinedPlayerArrays.sort(function (a, b) {
            if (a.PlayerName < b.PlayerName) { return -1; }
            if (a.PlayerName > b.PlayerName) { return 1; }
            return 0;
        });

        //Show Ui & Joined Players List
        this.setState({
            JoinedPlayerArrays: this.state.JoinedPlayerArrays,
            isJoinedPlayersLoaded: true,
            IsButtonEnabled: true,
        });
    }

    // async fetchEmail(_uid) {
    //     this.userEmailPath(_uid).once('value', snapshot => {
    //         // window.alert(snapshot.val());
    //         return snapshot.val();
    //     });
    // }

    // //2022.06.13 - greatly simplify logics.
    // fetchFinalRankingList_V2 = async (_roomIndex, _roomId, _roomCode) => {
    //     ProgressionStateText = 'Downloading data...';
    //     this.setState({
    //         selectedRoomCode: _roomCode,
    //         selectedRoomId: _roomId,
    //         selectedRoomIndex: _roomIndex,
    //         prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
    //         JoinedPlayerEmails: [],
    //         JoinedPlayerArrays: [],
    //         RankingListArrays: [],
    //         FinalRankingList: [],
    //     });

    //     //Get Room's Players.
    //     let _snapShot = [];
    //     this.props.db.ref('pkquiz/' + this.state.liveQuizDate + '/pkquiz-ranking-live/' + _roomId)
    //         .once('value', snapshot => {
    //             if (snapshot.exists()) {
    //                 _snapShot = snapshot.val();
    //                 if (this.props.isDevMode)
    //                     console.log(JSON.stringify(_snapShot));
    //             }
    //         });
    //     await this.processRankingListDataV4(_snapShot);

    //     let date = moment(this.state.selectedRoomId);
    //     let _FinalRankingList = [];


    // }

    //2023.11.09 - current actively using.
    //New created 2020.07.01
    fetchFinalRankingList = (_roomIndex, _roomId, _roomCode) => {
        ProgressionStateText = 'Downloading data...';
        this.setState({
            selectedRoomCode: _roomCode,
            selectedRoomId: _roomId,
            selectedRoomIndex: _roomIndex,
            prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
            JoinedPlayerEmails: [],
            JoinedPlayerArrays: [],
            RankingListArrays: [],
            FinalRankingList: [],
            // });
        }, async () => {
            //         this.fetchFinalRankingList_Stage1(_roomIndex, _roomId, _roomCode);
            //     });
            // }

            // fetchFinalRankingList_Stage1 = async (_roomIndex, _roomId, _roomCode) => {

            // let _FinalRankingList = [];

            // this.setState({
            //     prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
            //     // });
            // }, async () => {
            //     this.setState({
            //         selectedRoomId: _roomId,
            //         selectedRoomIndex: _roomIndex,
            //         // prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
            //         // JoinedPlayerEmails: [],
            //         // JoinedPlayerArrays: [],
            //         // RankingListArrays: [],
            //         // FinalRankingList: [],
            //     });
            // });
            // }, async () => {

            // if (
            //     this.state.JoinedPlayerEmails.length > 0
            //     || this.state.JoinedPlayerArrays.length > 0
            //     || this.state.RankingListArrays.length > 0
            //     || this.state.FinalRankingList.length > 0
            // ) {
            //     setTimeout(() => {
            //         this.setState({
            //             JoinedPlayerEmails: [],
            //             JoinedPlayerArrays: [],
            //             RankingListArrays: [],
            //             FinalRankingList: [],
            //         });
            //     }, 0);
            // }
            // setTimeout(() => {
            //     
            // }, 500);

            // this.setState({
            //     dummy: _roomId,
            // });

            //Get Joined Players arrays.
            let _snapShot = [];
            await this.joinedPlayersPathV2(this.state.liveQuizDate, _roomId).once('value', snapshot => {
                if (snapshot.exists()) {
                    // // _snapShot = Object.assign({}, snapshot.val());
                    // let _dataString = JSON.stringify(snapshot.val());
                    // // console.log(_dataString);
                    // _dataString = _dataString.replace(new RegExp('{', 'g'), '');
                    // _dataString = _dataString.replace(new RegExp('}', 'g'), '');
                    // _dataString = _dataString.replace(new RegExp('"', 'g'), '');
                    // let _sourceArray = _dataString.split(',');
                    // let _dataArray = [];
                    // for (var k = 0; k < _sourceArray.length; k++) {
                    //     let splits = _sourceArray[k].split(':');
                    //     _dataArray.push({ Uid: splits[0], Value: splits[1] });
                    // }
                    // _snapShot = _dataArray;

                    // console.log(JSON.stringify(snapshot.val()));

                    //2022.06.13
                    let _dataArray = [];
                    let _dataSnapShot = Object.entries(snapshot.val());
                    // console.log(JSON.stringify(_dataSnapShot));
                    for (let k = 0; k < _dataSnapShot.length; k++) {
                        const value = String(_dataSnapShot[k]);
                        const splitIndex = value.indexOf(',');
                        _dataArray.push({ Uid: value.slice(0, splitIndex), Value: value.slice(splitIndex + 1, value.length - 1) })
                    }
                    _snapShot = _dataArray;

                    if (this.props.isDevMode)
                        console.log(JSON.stringify(_dataArray));

                    // let _source = snapshot.val();
                    // let _array = [];
                    // _source.map((data, key) => {
                    //     _dataArray.push({ Uid: , Value: splits[1] });
                    // });
                }
            });
            // console.log(JSON.stringify(_snapShot));
            await this.processJoinedPlayersDataV3(_snapShot);
            // console.log(JSON.stringify(this.state.JoinedPlayerArrays));

            if (this.state.liveQuizDate <= this.state.versionDivisionDate) {
                //Get Ranking List arrays.
                await this.rankingListPath(this.state.liveQuizDate).once('value', snapshot => {
                    _snapShot = snapshot.val();
                });
                await this.processRankingListDataV3(_snapShot);
                // console.log(_snapShot.length);
            }

            // let roomInfo = await this.GetRoomInfo();

            //#region older pattern.
            //Combine both list.
            // () => {
            // let combinedList = [];
            // let date = moment.unix(this.state.selectedRoomId / 1000);
            let date = moment(this.state.selectedRoomId);
            let _FinalRankingList = this.state.FinalRankingList;
            Promise.all(
                this.state.JoinedPlayerArrays.map(async (data, key1) => {
                    //2020.07.27
                    let isLogsExisted = false;
                    let actualTimeConsumed = 0;
                    let quizStartTimeTicks = 0;
                    let isQuizStartTimeGet = false;
                    let quizCompletedTimeTicks = 0;
                    let isQuizCompletedTimeGet = false;
                    let score = 0;

                    //2023.07.04 - get room access date (FS - LiveQuizHistory/Uid)
                    let _roomEnterHistoryDate_Moment_UTC = null;
                    let _roomEnterHistoryDate_Moment = null;
                    let _roomEnterHistoryDate = null;
                    let _roomEnterHistory = await this.getRoomEnterHistory(data.Uid, this.state.selectedRoomCode);
                    if (_roomEnterHistory !== null) {
                        if (_roomEnterHistory.hasOwnProperty('Date')) {
                            _roomEnterHistoryDate = String(_roomEnterHistory.Date);
                            _roomEnterHistoryDate_Moment = moment(String(_roomEnterHistory.Date));

                            //2023.11.09
                            if (_roomEnterHistory.hasOwnProperty('IsDoneOnUtc')) {
                                _roomEnterHistoryDate_Moment_UTC = moment(String(_roomEnterHistory.IsDoneOnUtc));

                                const hours_diff = _roomEnterHistoryDate_Moment.diff(_roomEnterHistoryDate_Moment_UTC, 'hours');
                                if (hours_diff > 7 || hours_diff < 0)
                                    _roomEnterHistoryDate = _roomEnterHistoryDate_Moment.format('YYYY-MM-DD HH:mm:ss');
                                else if (hours_diff === 7)
                                    _roomEnterHistoryDate = _roomEnterHistoryDate_Moment_UTC.format('YYYY-MM-DD HH:mm:ss');
                            }
                            if (_roomEnterHistory.hasOwnProperty('LastEnteredTimeZone')) {
                                let tz = Number(String(_roomEnterHistory['LastEnteredTimeZone']).split(':')[0]);
                                tz = String(_roomEnterHistory['LastEnteredTimeZone']).includes('-') ? tz : -tz;
                                _roomEnterHistoryDate = _roomEnterHistoryDate_Moment.add(tz, 'hours').format('YYYY-MM-DD HH:mm:ss');
                                if (this.props.isDevMode)
                                    console.warn('email: ' + data.Uid + ' tz: ' + tz);
                            }

                            // //2023.09.26
                            // if (_roomEnterHistory.hasOwnProperty('LastEnteredDateTimeUtc')) {
                            //     _roomEnterHistoryDate_Moment_UTC = moment(String(_roomEnterHistory.LastEnteredDateTimeUtc));

                            //     const hours_diff = _roomEnterHistoryDate_Moment.diff(_roomEnterHistoryDate_Moment_UTC, 'hours');
                            //     if (hours_diff > 7 || hours_diff < 0)
                            //         _roomEnterHistoryDate = _roomEnterHistoryDate_Moment.format('YYYY-MM-DD HH:mm:ss');
                            //     else if (hours_diff === 7)
                            //         _roomEnterHistoryDate = _roomEnterHistoryDate_Moment_UTC.format('YYYY-MM-DD HH:mm:ss');
                            // }
                            // if (_roomEnterHistory.hasOwnProperty('LastEnteredTimeZone')) {
                            //     let tz = Number(String(_roomEnterHistory['LastEnteredTimeZone']).split(':')[0]);
                            //     tz = String(_roomEnterHistory['LastEnteredTimeZone']).includes('-') ? tz : -tz;
                            //     _roomEnterHistoryDate = _roomEnterHistoryDate_Moment.add(tz, 'hours').format('YYYY-MM-DD HH:mm:ss');
                            //     if (this.props.isDevMode)
                            //         console.warn('email: ' + data.Uid + ' tz: ' + tz);
                            // }
                        }
                        if (this.state.isDevMode)
                            console.log('RoomEnterHistory (' + data.Uid + ')', JSON.stringify(_roomEnterHistory));
                    }

                    //2023.08.18 - add Finish Timestamp
                    let _finishTimestamp = '-';

                    //edited on 2020.11.11
                    // personalScoreRecordPath(_date, _roomId, _uid)
                    let theDate = moment(this.state.selectedDate);
                    if (theDate > moment('20201019', 'YYYYMMDD')) {
                        isLogsExisted = true;
                        await this.personalScoreRecordPath(theDate.format('YYYYMMDD'), this.state.selectedRoomId, String(data.Uid))
                            .once('value', snapshot => {
                                // if (data.Uid === 'McTqmyNEnyUmeQwbV2QfXz0bhdH2')
                                //     window.alert(JSON.stringify(snapshot.val()));
                                if (snapshot.exists()) {
                                    let splitData = snapshot.val().split(';');
                                    actualTimeConsumed = Number(splitData[1]);
                                    score = Number(splitData[0]);
                                }
                            });

                        // if (data.Uid === 'VIZUL0z28rTwLupGn2CYjkoWnwo2') {
                        //     console.log('Score = ' + score + '\nTime Consumed = ' + actualTimeConsumed);
                        // }

                        // if (data.Uid === 'McTqmyNEnyUmeQwbV2QfXz0bhdH2') {
                        //     window.alert('Score = ' + score
                        //         + '\nTime Consumed = ' + actualTimeConsumed
                        //         + '\nTimestamp = ' + _finishTimestamp
                        //         + '\nscore > 100.00 = ' + String(score > 100.00) + ' = ' + score
                        //         + '\nscore with "." = ' + String(String(score).includes('.') === false) + ' = ' + score
                        //         + '\nactualTimeConsumed <= 0 = ' + String(actualTimeConsumed <= 0) + ' = ' + actualTimeConsumed
                        //     );
                        // }

                        //added 2020.11.13  //to counter user still using app.
                        // if (score > 100.00 || String(score).includes('.00') === false || String(score).includes('.50') === false) {
                        // if ((score > 0.0 && score < 100.0) || String(score).includes('.') === false || actualTimeConsumed <= 0) {

                        // if (data.Uid === 'McTqmyNEnyUmeQwbV2QfXz0bhdH2') {
                        //     window.alert('Score = ' + score
                        //      + '\nTime Consumed = ' + actualTimeConsumed
                        //      + '\nTimestamp = ' + _finishTimestamp
                        //      + '\nscore > 100.00 = ' + String(score > 100.00)
                        //      + '\nscore with "." = ' + String(String(score).includes('.') === false)
                        //      + '\nactualTimeConsumed <= 0 = ' + String(actualTimeConsumed <= 0)
                        //      );
                        // }

                        //find the correct actual time consumed.
                        await this.logsPath(data.Uid, _roomEnterHistoryDate).once('value', snapshot => {
                            if (snapshot.exists()) {
                                isLogsExisted = true;

                                let dataKeys = _(snapshot.val()).keys().value();       //key = time
                                let dataValues = _(snapshot.val()).values().value();   //values = log message

                                //start time if existed.
                                dataKeys.map((data, key) => {
                                    //2023.07.04 - revamped.
                                    if (!isQuizStartTimeGet && String(dataValues[key]).includes(this.state.selectedRoomCode)) {
                                        if (String(dataValues[key]).includes('Quiz Started')) {
                                            isQuizStartTimeGet = true;
                                            quizStartTimeTicks = this.GetConvertedTime(data, date);
                                        }
                                    }
                                    return null;
                                });

                                //start time if none existed but 'Entered'.
                                if (!isQuizStartTimeGet) {
                                    dataKeys.map((data, key) => {
                                        //2023.07.04 - revamped.
                                        if (String(dataValues[key]).includes(this.state.selectedRoomCode)) {
                                            if (String(dataValues[key]).includes('Entered')) {
                                                isQuizStartTimeGet = true;
                                                quizStartTimeTicks = this.GetConvertedTime(data, date);
                                            }
                                        }
                                        return null;
                                    });
                                }

                                //end time if existed.
                                dataKeys.map((data, key) => {
                                    //2023.07.04 - revamped.
                                    if (!isQuizCompletedTimeGet && String(dataValues[key]).includes(this.state.selectedRoomCode)) {
                                        if (String(dataValues[key]).includes('Quiz Completed')) {
                                            isQuizCompletedTimeGet = true;
                                            quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                            //2023.08.18 - add Finish Timestamp
                                            _finishTimestamp = this.GetConvertedTime(data, _roomEnterHistoryDate_Moment, true);
                                        }
                                        else if (String(dataValues[key]).includes('ASelects')) {
                                            isQuizCompletedTimeGet = true;
                                            quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                            //2023.08.18 - add Finish Timestamp
                                            _finishTimestamp = this.GetConvertedTime(data, _roomEnterHistoryDate_Moment, true);
                                        }
                                        else if (String(dataValues[key]).includes('QResults')) {
                                            isQuizCompletedTimeGet = true;
                                            quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                            //2023.08.18 - add Finish Timestamp
                                            _finishTimestamp = this.GetConvertedTime(data, _roomEnterHistoryDate_Moment, true);
                                        }
                                    }
                                    return null;
                                });

                                //end time if none existed but 'Exit'.
                                if (!isQuizCompletedTimeGet) {
                                    dataKeys.map((data, key) => {
                                        //2023.07.04 - revamped.
                                        if (String(dataValues[key]).includes(this.state.selectedRoomCode)) {
                                            if (String(dataValues[key]).includes('Exit')) {
                                                quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                                //2023.08.18 - add Finish Timestamp
                                                _finishTimestamp = this.GetConvertedTime(data, _roomEnterHistoryDate_Moment, true);
                                            }
                                        }
                                        return null;
                                    });
                                    isQuizCompletedTimeGet = quizCompletedTimeTicks > 0;
                                }

                                if (quizStartTimeTicks > 0 && quizCompletedTimeTicks > 0) {
                                    actualTimeConsumed = (quizCompletedTimeTicks - quizStartTimeTicks) / 1000;
                                    // alert(quizCompletedTimeTicks + " | " + quizStartTimeTicks + " | " + actualTimeConsumed);
                                }
                            }
                            // else {
                            //     if (data.Uid === 'McTqmyNEnyUmeQwbV2QfXz0bhdH2') {
                            //         window.alert('Score = ' + score
                            //          + '\nTime Consumed = ' + actualTimeConsumed
                            //          + '\nTimestamp = ' + _finishTimestamp
                            //          + '\nRoom Enter History Date = ' + _roomEnterHistoryDate);
                            //     }
                            // }
                        });

                        //find the actual score received.
                        await this.personalQResultPath(theDate.format('YYYYMMDD'), this.state.selectedRoomId, data.Uid)
                            .once('value', snapshot => {
                                if (snapshot.exists()) {
                                    let splitData = String(snapshot.val()).split(':');
                                    let correct = 0;
                                    if (splitData.length > 0) {
                                        splitData.map((data, key) => {
                                            if (data === '1')
                                                correct++;
                                            return null;
                                        });
                                        score = (correct / splitData.length) * 100;
                                    }
                                }
                            });

                        // data.TimeElapsed = actualTimeConsumed;
                        data.Scores = score.toFixed(2);

                        //2022.06.13
                        if (actualTimeConsumed > 0)
                            data.TimeElapsed = actualTimeConsumed;
                        // }

                        // if (data.Uid === 'VIZUL0z28rTwLupGn2CYjkoWnwo2') {
                        //     console.log('Score = ' + score + '\nTime Consumed = ' + actualTimeConsumed);

                        //     // if (actualTimeConsumed < 0)
                        // }

                        // if (data.Uid === 'McTqmyNEnyUmeQwbV2QfXz0bhdH2') {
                        //     window.alert('Score = ' + score
                        //         + '\nTime Consumed = ' + actualTimeConsumed
                        //         + '\nTimestamp = ' + _finishTimestamp);
                        // }
                    }
                    else {
                        await this.logsPath(data.Uid, _roomEnterHistoryDate).once('value', snapshot => {
                            if (snapshot.exists()) {
                                isLogsExisted = true;

                                let dataKeys = _(snapshot.val()).keys().value();       //key = time
                                let dataValues = _(snapshot.val()).values().value();   //values = log message

                                dataKeys.map((data, key) => {
                                    if (dataValues[key].toString().includes('Quiz Started')
                                        && dataValues[key].toString().includes(this.state.selectedRoomCode)
                                        && !isQuizStartTimeGet) {
                                        isQuizStartTimeGet = true;
                                        // let hr = data.substring(0, 2);
                                        // let min = data.substring(2, 4);
                                        // let sec = data.substring(4, 6);
                                        // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
                                        // quizStartTimeTicks = moment(dateTime).add(8, 'hours').format('x');
                                        quizStartTimeTicks = this.GetConvertedTime(data, date);
                                    }
                                    // if (dataValues[key].toString().includes('Exit')
                                    //     && dataValues[key].toString().includes(this.state.selectedRoomCode)
                                    //     && isQuizStartTimeGet
                                    //     && !isQuizCompletedTimeGet) {
                                    //     // let hr = data.substring(0, 2);
                                    //     // let min = data.substring(2, 4);
                                    //     // let sec = data.substring(4, 6);
                                    //     // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
                                    //     // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
                                    //     quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                    // }
                                    if (dataValues[key].toString().includes('Quiz Completed')
                                        && dataValues[key].toString().includes(this.state.selectedRoomCode)
                                        && !isQuizCompletedTimeGet) {
                                        isQuizCompletedTimeGet = true;
                                        // let hr = data.substring(0, 2);
                                        // let min = data.substring(2, 4);
                                        // let sec = data.substring(4, 6);
                                        // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
                                        // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
                                        quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                        //2023.08.18 - add Finish Timestamp
                                        _finishTimestamp = this.GetConvertedTime(data, _roomEnterHistoryDate_Moment, true);
                                    }
                                    if (dataValues[key].toString().includes('Exit')
                                        && dataValues[key].toString().includes(this.state.selectedRoomCode)
                                        && isQuizStartTimeGet
                                        && !isQuizCompletedTimeGet) {
                                        // let hr = data.substring(0, 2);
                                        // let min = data.substring(2, 4);
                                        // let sec = data.substring(4, 6);
                                        // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
                                        // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
                                        quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                        //2023.08.18 - add Finish Timestamp
                                        _finishTimestamp = this.GetConvertedTime(data, _roomEnterHistoryDate_Moment, true);
                                    }
                                    return null;
                                });

                                // if (quizStartTimeTicks == 0) {

                                // }

                                if (quizStartTimeTicks > 0 && quizCompletedTimeTicks > 0) {
                                    actualTimeConsumed = (quizCompletedTimeTicks - quizStartTimeTicks) / 1000;
                                    // alert(quizCompletedTimeTicks + " | " + quizStartTimeTicks + " | " + actualTimeConsumed);
                                }
                            }
                        });
                    }

                    if (_finishTimestamp === '-' && _roomEnterHistoryDate_Moment !== null && _roomEnterHistoryDate_Moment_UTC !== null) {
                        // _finishTimestamp = this.GetConvertedTime(_roomEnterHistoryDate_Moment_UTC.format('HHmmss'), _roomEnterHistoryDate_Moment, true);
                        _finishTimestamp = this.GetConvertedTime(_roomEnterHistoryDate_Moment_UTC.format('HHmmss'),
                            _roomEnterHistoryDate_Moment_UTC, true);    //2023.10.04
                    }

                    // let _FinalRankingList = this.state.FinalRankingList;
                    _FinalRankingList.push({
                        PlayerName: data.PlayerName,
                        Scores: data.Scores,
                        TimeElapsed: actualTimeConsumed > 0 ? actualTimeConsumed : data.TimeElapsed,
                        ActualTimeElapsed: data.TimeElapsed,
                        // TimeElapsed: data.TimeElapsed,
                        // ActualTimeElapsed: actualTimeConsumed > 0 ? actualTimeConsumed : data.TimeElapsed,
                        FinishTimestamp: _finishTimestamp,      //2023.08.18 - add Finish Timestamp
                        IsCompleted: data.IsCompleted,
                        Uid: data.Uid,
                        Email: '',
                        Version: isLogsExisted, //data.Version,
                        Duplicated: false,
                        Qty: 0,
                        // Rank: 0,
                        CorrectQty: 0,
                        WrongQty: 0,
                        IsWeb: false,
                        RoomEnterHistory: _roomEnterHistory,
                    });
                    // this.setState({ FinalRankingList: _FinalRankingList });
                    return null;
                })
            )
                .then(() => {

                    // let _FinalRankingList = this.state.FinalRankingList;
                    if (this.state.liveQuizDate <= this.state.versionDivisionDate) {
                        Promise.all(
                            this.state.RankingListArrays.map(async (data1, key1) => {
                                let pickedData;
                                _FinalRankingList.map((data2, key2) => {
                                    // _FinalRankingList = this.state.FinalRankingList;
                                    if (data1.Uid === data2.Uid) {
                                        // let isDuped = false;
                                        if (parseInt(data1.Scores) > parseInt(data2.Scores)) {  //Score 1 higher.
                                            pickedData = data1;
                                        }
                                        else if (parseInt(data1.Scores) < parseInt(data2.Scores)) { //Score 1 lower.
                                            pickedData = data2;
                                        }
                                        else {  //Same Score.
                                            if (parseFloat(data1.TimeElapsed) <= parseFloat(data2.TimeElapsed)) {    //TimeElapsed 1 lesser, or =Zero.
                                                pickedData = data1;
                                            }
                                            else if (parseFloat(data1.TimeElapsed) > parseFloat(data2.TimeElapsed)) {    //TimeElapsed 1 more.
                                                pickedData = data2;
                                            }
                                            else {
                                                pickedData = data1; //default if no matches.
                                                // isDuped = true;
                                            }
                                        }
                                        _FinalRankingList[key2].Scores = pickedData.Scores;
                                        _FinalRankingList[key2].TimeElapsed = pickedData.TimeElapsed;
                                        // this.state.FinalRankingList[key2].Duplicated = isDuped;
                                        _FinalRankingList[key2].Qty += 1;
                                        // this.setState({ FinalRankingList: _FinalRankingList });
                                    }
                                    return null;
                                });
                                // _FinalRankingList = this.state.FinalRankingList;
                                if (pickedData == null) {
                                    // combinedList.push({
                                    //     PlayerName: pickedData.PlayerName,
                                    //     Scores: pickedData.Scores,
                                    //     TimeElapsed: pickedData.TimeElapsed,
                                    //     IsCompleted: pickedData.IsCompleted,
                                    //     Uid: pickedData.Uid,
                                    //     Email: '',
                                    // });

                                    //2023.07.04 - get room access date (FS - LiveQuizHistory/Uid)
                                    // let _roomEnterHistoryDate = null;
                                    let _roomEnterHistory = await this.getRoomEnterHistory(this.state.RankingListArrays[key1].Uid, this.state.selectedRoomCode);
                                    if (_roomEnterHistory !== null) {
                                        // if (_roomEnterHistory.hasOwnProperty('Date'))
                                        //     _roomEnterHistoryDate = String(_roomEnterHistory.Date);

                                        if (this.state.isDevMode)
                                            console.log('RoomEnterHistory (' + this.state.RankingListArrays[key1].Uid + ')', JSON.stringify(_roomEnterHistory));
                                    }

                                    _FinalRankingList.push({
                                        PlayerName: this.state.RankingListArrays[key1].PlayerName,
                                        Scores: this.state.RankingListArrays[key1].Scores,
                                        TimeElapsed: this.state.RankingListArrays[key1].TimeElapsed,
                                        ActualTimeElapsed: this.state.RankingListArrays[key1].ActualTimeElapsed,    //new 2020-10-19
                                        IsCompleted: this.state.RankingListArrays[key1].IsCompleted,
                                        Uid: this.state.RankingListArrays[key1].Uid,
                                        Email: '',
                                        Version: this.state.RankingListArrays[key1].Version,
                                        Duplicated: false,
                                        Qty: 1,
                                        // Rank: 0,
                                        CorrectQty: 0,
                                        WrongQty: 0,
                                        IsWeb: false,
                                        RoomEnterHistory: _roomEnterHistory,
                                    });
                                }
                                // this.setState({
                                //     FinalRankingList: _FinalRankingList,
                                //     // FinalRankingList: combinedList,
                                // });
                                return null;
                            })
                        );
                    }
                    // console.log('line = 1824');
                    this.setState({
                        IsButtonEnabled: false,
                        FinalRankingList: _FinalRankingList,
                        // FinalRankingList: combinedList,
                        dupeQtyForFRList: 0,
                    }, async () => {

                        //Find Email & fill-in.
                        // () => {
                        let _dupeQty = 0;
                        let _FinalRankingList = this.state.FinalRankingList;
                        await Promise.all(
                            _FinalRankingList.map(async (data, key) => {
                                if (_FinalRankingList[key].Qty > 1) {
                                    _FinalRankingList[key].Duplicated = true;
                                    _dupeQty += 1;
                                }
                                let _email = '';
                                await this.userEmailPath(data.Uid).once('value', snapshot => {
                                    return _email = snapshot.val();
                                });
                                // if (this.state.JoinedPlayerEmails.findIndex(element => element.uid === data.Uid) < 0) {
                                //     this.state.JoinedPlayerEmails.push(
                                //         {
                                //             uid: data.Uid,
                                //             email: _email,
                                //         }
                                //     );
                                // }
                                // this.setState({
                                //     JoinedPlayerEmails: this.state.JoinedPlayerEmails,
                                // });
                                _FinalRankingList[key].Email = _email;

                                //2023.07.04 - get room access date (FS - LiveQuizHistory/Uid)
                                if (_FinalRankingList[key].RoomEnterHistory === null) {
                                    _FinalRankingList[key].RoomEnterHistory = await this.getRoomEnterHistory(data.Uid, this.state.selectedRoomCode);
                                }
                                // if (_FinalRankingList[key].RoomEnterHistory !== null)
                                if (this.state.isDevMode)
                                    console.log('RoomEnterHistory (' + _email + ')', JSON.stringify(_FinalRankingList[key].RoomEnterHistory));

                                this.setState({
                                    FinalRankingList: _FinalRankingList,
                                });
                                return null;
                            })
                        );

                        // this.setState({
                        //     FinalRankingList: this.state.FinalRankingList,
                        // });
                        // this.setState({
                        //     FinalRankingList: this.state.FinalRankingList,
                        //     // });
                        // }, () => {

                        // const _finalRankingList = this.state.FinalRankingList.filter((val, id, array) => {
                        //     return array.indexOf(val) == id;
                        // });
                        // window.alert(_finalRankingList.length);
                        this.setState({
                            // FinalRankingList: _finalRankingList,
                            // FinalRankingList: this.state.FinalRankingList,
                            FinalRankingList: _FinalRankingList,
                            // isAlmostDone: true,
                            // ProgressionState: 'Almost done... just a little bit more...<br></br>Sorting list right now...',
                            dupeQtyForFRList: _dupeQty,
                        }, async () => {
                            ProgressionStateText = 'Almost done... just a little bit more...<br>please wait patiently...';
                            await this.processHiddenRankingListWithResults();
                            // await this.processHiddenRankingListWithResults_XLSX();  //2023.10.26
                            await this.processHiddenSchoolStatisticReport();
                            // console.log('line = 1898');
                        });

                        //#region simplified, can remove if no use.
                        // //2021.09.10
                        // await Delay(1000);
                        // for (var s = 0; s < this.state.FinalRankingList.length; s++) {
                        //     if (Number(this.state.FinalRankingList[s].TimeElapsed) < 0) {
                        //         //
                        //     }
                        // }

                        // // let _delay_ms = this.state.FinalRankingList.length * 35;
                        // // _delay_ms = _delay_ms > 10000 ? 10000 : _delay_ms;
                        // let _delay_ms = 2000;
                        // setTimeout(async () => {

                        //     // //Sort List according to Scores & TimeElapsed in ASC
                        //     // this.state.FinalRankingList.sort(function (a, b) {
                        //     //     if (parseInt(a.Scores) > parseInt(b.Scores)) {
                        //     //         return -1;
                        //     //     }
                        //     //     if (parseInt(a.Scores) < parseInt(b.Scores)) {
                        //     //         return 1;
                        //     //     }
                        //     //     if (parseFloat(a.TimeElapsed) < parseFloat(b.TimeElapsed)) {
                        //     //         return -1;
                        //     //     }
                        //     //     if (parseFloat(a.TimeElapsed) > parseFloat(b.TimeElapsed)) {
                        //     //         return 1;
                        //     //     }
                        //     //     // if (a.PlayerName < b.PlayerName) { return -1; }
                        //     //     // if (a.PlayerName > b.PlayerName) { return 1; }
                        //     //     return 0;
                        //     // });

                        //     // //Set Rank.
                        //     // this.state.FinalRankingList.map((data, key) => {
                        //     //     this.state.FinalRankingList[key].Rank = key + 1;
                        //     // });

                        //     // //Sort by Rank again.
                        //     // this.state.FinalRankingList.sort(function (a, b) {
                        //     //     if (parseInt(a.Rank) < parseInt(b.Rank)) {
                        //     //         return -1;
                        //     //     }
                        //     //     if (parseInt(a.Rank) > parseInt(b.Rank)) {
                        //     //         return 1;
                        //     //     }
                        //     //     if (parseFloat(a.TimeElapsed) < parseFloat(b.TimeElapsed)) {
                        //     //         return -1;
                        //     //     }
                        //     //     if (parseFloat(a.TimeElapsed) > parseFloat(b.TimeElapsed)) {
                        //     //         return 1;
                        //     //     }
                        //     //     return 0;
                        //     // });

                        //     //Save state & show results in Ui.
                        //     // this.setState({
                        //     //     FinalRankingList: this.state.FinalRankingList,
                        //     //     // IsFinalRankingListLoaded: true,
                        //     //     // IsButtonEnabled: true,
                        //     //     // isAlmostDone: false,
                        //     // }, async () => {
                        //     //     await this.processHiddenRankingListWithResults();
                        //     // });

                        //     await this.processHiddenRankingListWithResults();

                        // }, _delay_ms);
                        // );
                        // });
                        // });
                        //#endregion
                    });
                });
            //#endregion older logic

            //#region part of new pattern logic, but too slow, do not use, can remove.
            //     await this.fetchFinalRankingList_Stage2(_roomIndex, _roomId, _roomCode);
            // }, async () => {
            //     // }

            //     // fetchFinalRankingList_Stage2 = async (_roomIndex, _roomId, _roomCode) => {
            //     //Combine both list.
            //     //1st stage.
            //     let date = moment(this.state.selectedRoomId);
            //     let _FinalRankingList = [];
            //     for (var join = 0; join < this.state.JoinedPlayerArrays.length; join++) {
            //         let data = this.state.JoinedPlayerArrays[join];
            //         //2020.07.27
            //         let isLogsExisted = false;
            //         let actualTimeConsumed = 0;
            //         let quizStartTimeTicks = 0;
            //         let isQuizStartTimeGet = false;
            //         let quizCompletedTimeTicks = 0;
            //         let isQuizCompletedTimeGet = false;
            //         let score = 0;

            //         //edited on 2020.11.11
            //         // personalScoreRecordPath(_date, _roomId, _uid)
            //         let theDate = moment(this.state.selectedDate);
            //         if (theDate > moment('20201019', 'YYYYMMDD')) {
            //             isLogsExisted = true;
            //             await this.personalScoreRecordPath(theDate.format('YYYYMMDD'), this.state.selectedRoomId, data.Uid).once('value', snapshot => {
            //                 if (snapshot.exists()) {
            //                     let splitData = String(snapshot.val()).split(';');
            //                     actualTimeConsumed = Number(splitData[1]);
            //                     score = Number(splitData[0]);
            //                 }
            //             });

            //             // if (data.Uid === 'VIZUL0z28rTwLupGn2CYjkoWnwo2') {
            //             //     console.log('Score = ' + score + '\nTime Consumed = ' + actualTimeConsumed);
            //             // }

            //             //added 2020.11.13  //to counter user still using app.
            //             // if (score > 100.00 || String(score).includes('.00') === false || String(score).includes('.50') === false) {
            //             if (score > 100.00 || String(score).includes('.') === false || actualTimeConsumed <= 0) {

            //                 //find the correct actual time consumed.
            //                 await this.logsPath(data.Uid).once('value', snapshot => {
            //                     if (snapshot.exists()) {
            //                         isLogsExisted = true;

            //                         let dataKeys = _(snapshot.val()).keys().value();       //key = time
            //                         let dataValues = _(snapshot.val()).values().value();   //values = log message

            //                         dataKeys.map((data, key) => {
            //                             if (dataValues[key].toString().includes('Quiz Started')
            //                                 && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                                 && !isQuizStartTimeGet) {
            //                                 isQuizStartTimeGet = true;
            //                                 quizStartTimeTicks = this.GetConvertedTime(data, date);
            //                             }
            //                             if (dataValues[key].toString().includes('Quiz Completed')
            //                                 && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                                 && !isQuizCompletedTimeGet) {
            //                                 isQuizCompletedTimeGet = true;
            //                                 quizCompletedTimeTicks = this.GetConvertedTime(data, date);
            //                             }
            //                             if (dataValues[key].toString().includes('Exit')
            //                                 && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                                 && isQuizStartTimeGet
            //                                 && !isQuizCompletedTimeGet) {
            //                                 quizCompletedTimeTicks = this.GetConvertedTime(data, date);
            //                             }
            //                             return null;
            //                         });

            //                         if (quizStartTimeTicks > 0 && quizCompletedTimeTicks > 0) {
            //                             actualTimeConsumed = (quizCompletedTimeTicks - quizStartTimeTicks) / 1000;
            //                             // alert(quizCompletedTimeTicks + " | " + quizStartTimeTicks + " | " + actualTimeConsumed);
            //                         }
            //                     }
            //                 });

            //                 //find the actual score received.
            //                 await this.personalQResultPath(theDate.format('YYYYMMDD'), this.state.selectedRoomId, data.Uid)
            //                     .once('value', snapshot => {
            //                         if (snapshot.exists()) {
            //                             let splitData = String(snapshot.val()).split(':');
            //                             let correct = 0;
            //                             if (splitData.length > 0) {
            //                                 splitData.map((data, key) => {
            //                                     if (data === '1')
            //                                         correct++;
            //                                     return null;
            //                                 });
            //                                 score = (correct / splitData.length) * 100;
            //                             }
            //                         }
            //                     });

            //                 data.TimeElapsed = actualTimeConsumed;
            //                 data.Scores = score.toFixed(2);
            //             }

            //             // if (data.Uid === 'VIZUL0z28rTwLupGn2CYjkoWnwo2') {
            //             //     console.log('Score = ' + score + '\nTime Consumed = ' + actualTimeConsumed);

            //             //     // if (actualTimeConsumed < 0)
            //             // }
            //         }
            //         else {
            //             await this.logsPath(data.Uid).once('value', snapshot => {
            //                 if (snapshot.exists()) {
            //                     isLogsExisted = true;

            //                     let dataKeys = _(snapshot.val()).keys().value();       //key = time
            //                     let dataValues = _(snapshot.val()).values().value();   //values = log message

            //                     // dataKeys.map((data, key) => {
            //                     for (var key = 0; key < dataKeys.length; key++) {
            //                         if (dataValues[key].toString().includes('Quiz Started')
            //                             && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                             && !isQuizStartTimeGet) {
            //                             isQuizStartTimeGet = true;
            //                             // let hr = data.substring(0, 2);
            //                             // let min = data.substring(2, 4);
            //                             // let sec = data.substring(4, 6);
            //                             // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
            //                             // quizStartTimeTicks = moment(dateTime).add(8, 'hours').format('x');
            //                             quizStartTimeTicks = this.GetConvertedTime(dataKeys[key], date);
            //                         }
            //                         // if (dataValues[key].toString().includes('Exit')
            //                         //     && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                         //     && isQuizStartTimeGet
            //                         //     && !isQuizCompletedTimeGet) {
            //                         //     // let hr = data.substring(0, 2);
            //                         //     // let min = data.substring(2, 4);
            //                         //     // let sec = data.substring(4, 6);
            //                         //     // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
            //                         //     // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
            //                         //     quizCompletedTimeTicks = this.GetConvertedTime(data, date);
            //                         // }
            //                         if (dataValues[key].toString().includes('Quiz Completed')
            //                             && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                             && !isQuizCompletedTimeGet) {
            //                             isQuizCompletedTimeGet = true;
            //                             // let hr = data.substring(0, 2);
            //                             // let min = data.substring(2, 4);
            //                             // let sec = data.substring(4, 6);
            //                             // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
            //                             // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
            //                             quizCompletedTimeTicks = this.GetConvertedTime(dataKeys[key], date);
            //                         }
            //                         if (dataValues[key].toString().includes('Exit')
            //                             && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                             && isQuizStartTimeGet
            //                             && !isQuizCompletedTimeGet) {
            //                             // let hr = data.substring(0, 2);
            //                             // let min = data.substring(2, 4);
            //                             // let sec = data.substring(4, 6);
            //                             // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
            //                             // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
            //                             quizCompletedTimeTicks = this.GetConvertedTime(dataKeys[key], date);
            //                         }
            //                         // return null;
            //                         // });
            //                     }

            //                     // if (quizStartTimeTicks == 0) {

            //                     // }

            //                     if (quizStartTimeTicks > 0 && quizCompletedTimeTicks > 0) {
            //                         actualTimeConsumed = (quizCompletedTimeTicks - quizStartTimeTicks) / 1000;
            //                         // alert(quizCompletedTimeTicks + " | " + quizStartTimeTicks + " | " + actualTimeConsumed);
            //                     }
            //                 }
            //             });
            //         }

            //         _FinalRankingList.push({
            //             PlayerName: data.PlayerName,
            //             Scores: data.Scores,
            //             TimeElapsed: actualTimeConsumed > 0 ? actualTimeConsumed : data.TimeElapsed,
            //             ActualTimeElapsed: data.TimeElapsed,
            //             // TimeElapsed: data.TimeElapsed,
            //             // ActualTimeElapsed: actualTimeConsumed > 0 ? actualTimeConsumed : data.TimeElapsed,
            //             IsCompleted: data.IsCompleted,
            //             Uid: data.Uid,
            //             Email: '',
            //             Version: isLogsExisted, //data.Version,
            //             Duplicated: false,
            //             Qty: 0,
            //             // Rank: 0,
            //             CorrectQty: 0,
            //             WrongQty: 0,
            //             IsWeb: false,
            //         });
            //         // this.setState({ FinalRankingList: _FinalRankingList });
            //     }

            //     //2nd stage.
            //     //Find Email & fill-in.
            //     this.setState({
            //         IsButtonEnabled: false,
            //         dupeQtyForFRList: 0,
            //     });

            //     let _dupeQty = 0;
            //     for (var key = 0; key < _FinalRankingList.length; key++) {
            //         if (_FinalRankingList[key].Qty > 1) {
            //             _FinalRankingList[key].Duplicated = true;
            //             _dupeQty += 1;
            //         }
            //         let _email = '';
            //         await this.userEmailPath(_FinalRankingList[key].Uid).once('value', snapshot => {
            //             return _email = snapshot.val();
            //         });
            //         _FinalRankingList[key].Email = _email;
            //     }

            //     this.setState({
            //         FinalRankingList: _FinalRankingList,
            //         isAlmostDone: true,
            //         dupeQtyForFRList: _dupeQty,
            //     });
            //     await Delay(1000);
            //     await this.processHiddenRankingListWithResults();
            //#endregion
        });
    }

    getRoomResult

    getRoomEnterHistory = async (_uid, _roomCode) => {
        if (this.state.isDevMode)
            console.log('getRoomEnterHistory', _uid, _roomCode);
        let _room = null;
        await this.props.dbFireStore
            .collection('LiveQuizHistory')
            .doc(String(_uid))
            .collection('List')
            .where('RoomCode', '==', String(_roomCode))
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                    });
                    if (dataArray.length > 0) {
                        _room = dataArray[0];
                        // if (_room.hasOwnProperty('RoomType') === false)
                        //   _room.RoomType = 0;
                    }
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log(error.message);
            });

        if (_room !== null) {
            if (this.state.isDevMode)
                console.log('getRoomEnterHistory \n Fetch \n ' + JSON.stringify(_room));

            // if (_room.hasOwnProperty('Date'))
            //     return _room;
        }
        return _room;
    }

    GetConvertedTime = (_time, _date, _dateTimeString = false) => {
        let hr = _time.substring(0, 2);
        let min = _time.substring(2, 4);
        let sec = _time.substring(4, 6);
        let dateTime = _date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
        if (_dateTimeString)
            return moment(dateTime).add(8, 'hours').format('YYYY-MM-DD HH:mm:ss');
        else
            return Number(moment(dateTime).add(8, 'hours').format('x'));
    }


    processJoinedPlayersDataV3 = async (dataSnapVal) => {
        // let dataKeys = _(dataSnapVal).keys().value();       //key = uid
        // let dataValues = _(dataSnapVal).values().value();   //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"

        this.setState({
            JoinedPlayerArrays: [],
        });
        // await Delay(500);
        let _JoinedPlayerArrays = [];   //this.state.JoinedPlayerArrays;

        // await Promise.all(
        // dataKeys.map(async (data_Uid, key) => {
        for (var key = 0; key < dataSnapVal.length; key++) {
            let data_Uid = CheckStringEmpty(dataSnapVal[key].Uid);
            // let dataVal = _(dataValues[key]).value().split(';');
            let dataVal = dataSnapVal[key].Value.split(';');
            let _isVerNew = data_Uid.length >= 28 && data_Uid.indexOf(' ') === -1 ? true : false;
            if (this.state.liveQuizDate > 20200607) {
                let _pname = data_Uid.length >= 28 && data_Uid.indexOf(' ') === -1 ?
                    decodeURI(dataVal[2])
                    : decodeURI(data_Uid);
                let _uid = data_Uid.length >= 28 && data_Uid.indexOf(' ') === -1 ?
                    data_Uid
                    : dataVal[2].toString();

                //2020.12.09
                // let _name = dataSet[2];
                if (CheckNullValue(_pname) === null) {
                    await this.userNamePath(_uid).once('value', snapshot => {
                        // return this.state.JoinedPlayerEmails.push(snapshot.val());
                        return _pname = snapshot.val();
                    });
                    // alert(_name);
                }

                //2020.12.12
                let _email = '';
                if (CheckNullValue(_pname) === null) {
                    await this.props.dbFireStore.collection("User")
                        .where('Uid', '==', _uid)
                        .get()
                        .then((querySnapshot) => {
                            let data = [];
                            if (querySnapshot !== null) {
                                querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                });
                            }
                            if (data.length > 0) {
                                _pname = data[0]['Name'];
                                _email = data[0]['Email'];
                            }
                        });
                    // alert(_name);
                }

                _JoinedPlayerArrays.push({
                    PlayerName: _pname,
                    Scores: dataVal[0],
                    TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                    IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
                    Uid: _uid,
                    Email: _email,
                    Version: _isVerNew,
                });
                // return null;
            }
            else {
                _JoinedPlayerArrays.push({
                    PlayerName: decodeURI(data_Uid),
                    Scores: dataVal[0],
                    TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                    IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
                    Uid: dataVal[2],
                    Email: '',
                    Version: _isVerNew,
                });
            }
            // return null;
        }
        //     })
        // );

        this.setState({
            JoinedPlayerArrays: _JoinedPlayerArrays,
        }, () => {
            // console.log(JSON.stringify(this.state.JoinedPlayerArrays));
        });
    }

    //2022.06.13
    processRankingListDataV4 = async (dataSnapVal) => {
        let dataKeys = _(dataSnapVal).keys().value();       //key = uid
        let dataValues = _(dataSnapVal).values().value();   //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"

        let _List = [];
        this.setState({
            JoinedPlayerArrays: [],
        });

        // dataKeys.map((data, key) => {
        for (var key = 0; key < dataKeys.length; key++) {
            let _uid = String(dataKeys[key]);
            let dataVals = String(dataValues[key]).split(';');
            //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"
            let _isVerNew = dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ? false : true;
            return _List.push({
                PlayerName: decodeURI(dataVals[2]),
                Scores: Number(dataVals[0]),
                TimeElapsed: Number(parseFloat(dataVals[1]).toFixed(3)),
                // IsCompleted: '',
                Uid: _uid,
                Email: '',
                Version: _isVerNew,
            });
        }

        //Show Ui & Joined Players List
        this.setState({
            JoinedPlayerArrays: _List,
            // IsButtonEnabled: true,
        });
    }

    processRankingListDataV3 = async (dataSnapVal) => {
        let dataKeys = _(dataSnapVal).keys().value();       //key = uid
        let dataValues = _(dataSnapVal).values().value();   //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"

        this.setState({
            RankingListArrays: [],
        });

        // dataKeys.map((data, key) => {
        //     let dataVal = dataValues[key].split(';');
        //     if (this.state.liveQuizDate > 20200607) {   //  (/^\s*$/.test(dataVal[2])) = contains any space
        //         // if (this.state.liveQuizDate > 20200607 || !(/^\s*$/.test(dataVal[2]))) {
        //         let _pname = data.length >= 28 && data.indexOf(' ') === -1 ?
        //             decodeURI(dataVal[2])
        //             : decodeURI(data);
        //         let _uid = data.length >= 28 && data.indexOf(' ') === -1 ?
        //             data
        //             : dataValues[key].split(';')[2].toString();
        //         return this.state.RankingListArrays.push({
        //             PlayerName: _pname,
        //             Scores: dataVal[0],
        //             TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
        //             Uid: _uid,
        //             Email: '',
        //         });
        //     }
        //     else {
        //         return this.state.RankingListArrays.push({
        //             PlayerName: decodeURI(data),
        //             Scores: dataVal[0],
        //             TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
        //             Uid: dataVal[2],
        //             Email: '',
        //         });
        //     }
        // });

        // dataKeys.map((data, key) => {
        for (var key = 0; key < dataKeys.length; key++) {
            let data = dataKeys[key];
            if (data <= 0)
                return null;
            // if (data > 0) {
            let dataVals = _(dataValues[parseInt(data)]).value().split(';');
            let _uid = dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ?
                dataVals[0]
                : dataVals[3];
            let _isVerNew = dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ? false : true;
            return this.state.RankingListArrays.push({
                // PlayerName: !(/^\s*$/.test(decodeURI(dataVal[2]))) ? decodeURI(dataVal[2]) : data,
                PlayerName: dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ? decodeURI(dataVals[3]) : decodeURI(dataVals[0]),
                Scores: dataVals[1],
                TimeElapsed: parseFloat(dataVals[2]).toFixed(1),
                // IsCompleted: '',
                Uid: _uid,
                Email: '',
                Version: _isVerNew,
            });
        }
        // }
        // });

        //Show Ui & Joined Players List
        this.setState({
            RankingListArrays: this.state.RankingListArrays,
            IsButtonEnabled: true,
        });
    }

    fetchRankingList = (_roomIndex, _roomId, _roomCode) => {
        // let date = moment(this.state.selectedDate).format('YYYYMMDD');
        this.setState({
            selectedRoomCode: _roomCode,
            selectedRoomId: _roomId,
            selectedRoomIndex: _roomIndex,
            prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
        }, () => {
            this.rankingListPath(this.state.liveQuizDate).once('value', snapshot => {
                this.processRankingListData(snapshot.val());
            });
            // this.joinedPlayersPath(this.state.liveQuizDate).once('value', snapshot => {
            //     // this.processRankingListData(snapshot.val());
            //     this.processRankingListDataV2(snapshot.val());
            // });  //2020.06.30
        });
    }

    processRankingListDataV2 = async (dataSnapVal) => {
        let dataKeys = _(dataSnapVal).keys().value();       //key = uid
        let dataValues = _(dataSnapVal).values().value();   //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"

        // window.alert(_(dataValues[0]).values().value()[0]); //correct

        this.setState({
            RankingListArrays: [],
            JoinedPlayerEmails: [],
        });

        Promise.all(
            this.state.liveQuizDate > 20200607 ?
                dataKeys.map(async (data, key) => {
                    let _email = '';
                    // let uid = uidRegex.test(data) ? data : dataValues[key].split(';')[2].toString();
                    let _uid = data.length >= 28 && data.indexOf(' ') === -1 ? data : dataValues[key].split(';')[2].toString();
                    await this.userEmailPath(_uid).once('value', snapshot => {
                        // return this.state.JoinedPlayerEmails.push(snapshot.val());  //Current Format
                        return _email = snapshot.val();
                    });
                    if (this.state.JoinedPlayerEmails.findIndex(element => element.uid === _uid) < 0) {
                        this.state.JoinedPlayerEmails.push(
                            {
                                uid: _uid,
                                email: _email,
                            }
                        );
                    }
                    this.setState({
                        JoinedPlayerEmails: this.state.JoinedPlayerEmails,
                    });
                })
                :
                dataValues.map(async (data, key) => {
                    let _email = '';
                    let _uid = data.split(';')[2];
                    await this.userEmailPath(_uid).once('value', snapshot => {
                        // return this.state.JoinedPlayerEmails.push(snapshot.val());
                        return _email = snapshot.val();
                    });
                    if (this.state.JoinedPlayerEmails.findIndex(element => element.email === _email) < 0) {
                        this.state.JoinedPlayerEmails.push(
                            {
                                uid: _uid,
                                email: _email,
                            }
                        );
                    }
                    this.setState({
                        JoinedPlayerEmails: this.state.JoinedPlayerEmails,
                    });
                })
        )
            // .then(() => {
            //     this.setState({
            //         JoinedPlayerEmails: this.state.JoinedPlayerEmails,
            //     }, () => {
            //         // window.alert(this.state.JoinedPlayerEmails.length);
            //     });
            // })
            .then(() => {
                dataKeys.map((data, key) => {
                    let dataVal = _(dataValues[key]).value().split(';');
                    let _isVerNew = data.length >= 28 && data.indexOf(' ') === -1 ? false : true;
                    if (this.state.liveQuizDate > 20200607) {   //  (/^\s*$/.test(dataVal[2])) = contains any space
                        // if (this.state.liveQuizDate > 20200607 || !(/^\s*$/.test(dataVal[2]))) {
                        let _pname = data.length >= 28 && data.indexOf(' ') === -1 ?
                            decodeURI(dataVal[2])
                            : decodeURI(data);
                        let _uid = data.length >= 28 && data.indexOf(' ') === -1 ?
                            data
                            : dataValues[key].split(';')[2].toString();
                        return this.state.RankingListArrays.push({
                            PlayerName: _pname,
                            Scores: dataVal[0],
                            TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                            Uid: _uid,
                            Email: this.state.JoinedPlayerEmails.find(x => x.uid === _uid).email,
                            Version: _isVerNew,
                        });
                    }
                    else {
                        return this.state.RankingListArrays.push({
                            PlayerName: decodeURI(data),
                            Scores: dataVal[0],
                            TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                            Uid: dataVal[2],
                            Email: this.state.JoinedPlayerEmails.find(x => x.uid === dataVal[2]).email,
                            Version: _isVerNew,
                        });
                    }
                });
            })
            .then(() => {
                //Sort List according to Scores & TimeElapsed in ASC
                this.state.RankingListArrays.sort(function (a, b) {
                    if (parseInt(a.Scores) > parseInt(b.Scores)) {
                        return -1;
                    }
                    if (parseInt(a.Scores) < parseInt(b.Scores)) {
                        return 1;
                    }
                    if (parseFloat(a.TimeElapsed) < parseFloat(b.TimeElapsed)) {
                        return -1;
                    }
                    if (parseFloat(a.TimeElapsed) > parseFloat(b.TimeElapsed)) {
                        return 1;
                    }
                    return 0;
                });

                //Show Ui & Joined Players List
                this.setState({
                    RankingListArrays: this.state.RankingListArrays,
                    isRankingListLoaded: true,
                    IsButtonEnabled: true,
                });
            });
    }

    processRankingListData = async (dataSnapVal) => {
        let dataKeys = _(dataSnapVal).keys().value();       //key = uid
        let dataValues = _(dataSnapVal).values().value();   //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"

        // window.alert(_(dataValues[0]).values().value()[0]); //correct

        this.setState({
            // JoinedPlayerArrays: [],
            JoinedPlayerEmails: [],
            RankingListArrays: [],
            dupeQtyForFRList: 0,
            // });
        }, () => {
            let _dupeQty = 0;
            // let _TempRankingListArrays = [];
            Promise.all(
                dataKeys.map(async (data, key) => {
                    if (data <= 0)
                        return null;

                    // if (data > 0) {
                    let dataVal = dataValues[key].split(';');
                    // let dataVal = _(dataValues[key]).value().split(';');
                    let _email = '';
                    let _uid = dataVal[3].length >= 28 && dataVal[3].indexOf(' ') === -1 ? dataVal[3] : dataVal[0];
                    // let _pname = dataVal[3].length >= 28 && dataVal[3].indexOf(' ') === -1 ? dataVal[0] : dataVal[3];
                    await this.userEmailPath(_uid).once('value', snapshot => {
                        // window.alert(dataVal[3] + " / " + snapshot.val());
                        // return this.state.JoinedPlayerEmails.push(snapshot.val());  //Current Format
                        return _email = snapshot.val();
                    });
                    if (this.state.JoinedPlayerEmails.findIndex(element => element.uid === _uid) < 0) {
                        this.state.JoinedPlayerEmails.push(
                            {
                                uid: _uid,
                                email: _email,
                            }
                        );
                    }
                    // else {
                    //     this.state.JoinedPlayerEmails.push('email not found');
                    // }
                    this.setState({
                        JoinedPlayerEmails: this.state.JoinedPlayerEmails,
                    });
                })
            )
                // .then(() => {
                //     // window.alert(this.state.JoinedPlayerEmails.toString());
                //     // window.alert(this.state.JoinedPlayerEmails.length);
                //     this.setState({
                //         JoinedPlayerEmails: this.state.JoinedPlayerEmails,
                //     }, () => {
                //         // window.alert(this.state.JoinedPlayerEmails.toString());
                //         // window.alert(this.state.JoinedPlayerEmails.length);
                //     });
                // })
                .then(() => {
                    // window.alert(this.state.JoinedPlayerEmails.toString());
                    dataKeys.map((data, key) => {
                        if (data <= 0)
                            return null;
                        // if (data > 0) {
                        let dataVals = _(dataValues[parseInt(data)]).value().split(';');
                        let _uid = dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ?
                            dataVals[0]
                            : dataVals[3];
                        let _isVerNew = dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ? false : true;
                        // window.alert(dataVals.toString());
                        // PlayerName: !(/^\s*$/.test(decodeURI(dataVal[2]))) ? decodeURI(dataVal[2]) : data,
                        // return this.state.RankingListArrays.push({
                        return this.state.RankingListArrays.push({
                            PlayerName: dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ? decodeURI(dataVals[3]) : decodeURI(dataVals[0]),
                            Scores: dataVals[1],
                            TimeElapsed: parseFloat(dataVals[2]).toFixed(1),
                            // IsCompleted: '', //dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
                            // Uid: dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ? dataVals[0] : dataVals[3],
                            Uid: _uid,
                            // Email: data + ' ' + key,
                            // Email: dataVals[0] + ' - ' + this.state.JoinedPlayerEmails[key - 1],
                            // Email: this.state.JoinedPlayerEmails[key - 1],
                            Email: this.state.JoinedPlayerEmails.find(x => x.uid === _uid).email,
                            // Email: '',
                            Version: _isVerNew,
                            Duplicated: false,
                            Qty: 0,
                        });
                        // }
                    });
                    // window.alert(this.state.RankingListArrays.toString());
                })
                // .then(() => {
                //     // let pickedData;
                //     this.state.RankingListArrays.map((data, key) => {

                //         let index = _TempRankingListArrays.find(({ Uid }) => Uid === data.Uid);
                //         if (index < 0) {  //Not Exist
                //             _TempRankingListArrays.push({
                //                 PlayerName: data.PlayerName,
                //                 Scores: data.Scores,
                //                 TimeElapsed: data.TimeElapsed,
                //                 Uid: data.Uid,
                //                 Email: data.Email,
                //                 Version: data.Version,
                //                 Duplicated: data.Duplicated,
                //                 Qty: data.Qty,
                //             })
                //         }
                //         else {
                //             _TempRankingListArrays.push({
                //                 PlayerName: data.PlayerName,
                //                 Scores: data.Scores,
                //                 TimeElapsed: data.TimeElapsed,
                //                 Uid: data.Uid,
                //                 Email: data.Email,
                //                 Version: data.Version,
                //                 Duplicated: data.Duplicated,
                //                 Qty: data.Qty,
                //             })
                //         }

                //         // let index = _TempRankingListArrays.findIndex(element => element.Uid === data.Uid);
                //         // if (index < 0)  //Not Exist
                //         // {
                //         //     _TempRankingListArrays.push({
                //         //         PlayerName: data.PlayerName,
                //         //         Scores: data.Scores,
                //         //         TimeElapsed: data.TimeElapsed,
                //         //         Uid: data.Uid,
                //         //         Email: data.Email,
                //         //         Version: data.Version,
                //         //         Duplicated: data.Duplicated,
                //         //         Qty: data.Qty,
                //         //     })
                //         // }
                //         // else {
                //         //     this.state.RankingListArrays[key].Qty += 1;
                //         //     // if (this.state.RankingListArrays[key].Uid == _TempRankingListArrays[index].Uid) {
                //         //     //     // let isDuped = false;
                //         //     //     if (parseInt(this.state.RankingListArrays[key].Scores) > parseInt(_TempRankingListArrays[index].Scores)) {  //Score 1 higher.
                //         //     //         pickedData = this.state.RankingListArrays[key];
                //         //     //     }
                //         //     //     else if (parseInt(this.state.RankingListArrays[key].Scores) < parseInt(_TempRankingListArrays[index].Scores)) { //Score 1 lower.
                //         //     //         pickedData = _TempRankingListArrays[index];
                //         //     //     }
                //         //     //     else {  //Same Score.
                //         //     //         if (parseFloat(this.state.RankingListArrays[key].TimeElapsed) <= parseFloat(_TempRankingListArrays[index].TimeElapsed)) {    //TimeElapsed 1 lesser, or =Zero.
                //         //     //             pickedData = this.state.RankingListArrays[key];
                //         //     //         }
                //         //     //         else if (parseFloat(this.state.RankingListArrays[key].TimeElapsed) > parseFloat(_TempRankingListArrays[index].TimeElapsed)) {    //TimeElapsed 1 more.
                //         //     //             pickedData = _TempRankingListArrays[index];
                //         //     //         }
                //         //     //         else {
                //         //     //             pickedData = this.state.RankingListArrays[key]; //default if no matches.
                //         //     //             // isDuped = true;
                //         //     //         }
                //         //     //     }
                //         //     //     this.state.RankingListArrays[key].Scores = pickedData.Scores;
                //         //     //     this.state.RankingListArrays[key].TimeElapsed = pickedData.TimeElapsed;
                //         //     //     // this.state.FinalRankingList[key2].Duplicated = isDuped;
                //         //     //     this.state.RankingListArrays[key].Qty += 1;
                //         //     // }
                //         // }    
                //     });

                //     _TempRankingListArrays.map((data, key) => {
                //         if (data.Qty > 1) {
                //             this.state.RankingListArrays[key].Duplicated = true;
                //         }
                //     });
                // })
                .then(() => {
                    // //Sort List according to Name in ASC
                    // this.state.JoinedPlayerArrays.sort(function (a, b) {
                    //     if (a.PlayerName < b.PlayerName) { return -1; }
                    //     if (a.PlayerName > b.PlayerName) { return 1; }
                    //     return 0;
                    // });

                    //Show Ui & Joined Players List
                    this.setState({
                        RankingListArrays: this.state.RankingListArrays,
                        // RankingListArrays: _TempRankingListArrays,
                        isRankingListLoaded: true,
                        IsButtonEnabled: true,
                        dupeQtyForFRList: _dupeQty,
                    });
                });
        });
    }

    LoadRoomList = (_uiMode) => {
        if (this.state.RoomDetailArrays.length <= 0) {
            this.LoadRoomListAndDetails(_uiMode);
        }
        else {
            this.SwitchUi(_uiMode);
            this.setState({
                IsRoomListLoaded: true,
            });
            ScrollToElement('ranking-list-table');
        }
    }

    // LoadRoomListAndJoinedPlayers() {
    //     if (this.state.RoomDetailArrays.length <= 0) {
    //         this.LoadRoomListAndDetails(UiMode.ShowJoinedPlayers);
    //     }
    //     else {
    //         this.SwitchUi(UiMode.ShowJoinedPlayers);
    //         this.setState({
    //             IsRoomListLoaded: true,
    //         });
    //     }
    // }

    // LoadRoomListForRankingList() {
    //     if (this.state.RoomDetailArrays.length <= 0) {
    //         this.LoadRoomListAndDetails(UiMode.ShowRankingList);
    //     }
    //     else {
    //         this.SwitchUi(UiMode.ShowRankingList);
    //         this.setState({
    //             IsRoomListLoaded: true,
    //         });
    //     }
    // }

    // LoadRoomListForFinalRankingList() {
    //     if (this.state.RoomDetailArrays.length <= 0) {
    //         this.LoadRoomListAndDetails(UiMode.ShowFinalRankingList);
    //     }
    //     else {
    //         this.SwitchUi(UiMode.ShowFinalRankingList);
    //         this.setState({
    //             IsRoomListLoaded: true,
    //         });
    //     }
    // }

    SwitchUi = (_uiMode) => {
        uiModeState = _uiMode;
        this.setState({
            // selectedRoomId: -1,
            // selectedRoomIndex: -1,
            showRoomDetailsList: false,
            showRoomDetail: false,
            IsRoomListLoaded: false,
            IsRoomDetailsLoaded: false,
            IsShowRoomDetails: false,
            isShowJoinedPlayers: false,
            isJoinedPlayersLoaded: false,
            // showRankingList: false,
            isShowRankingList: false,
            // showRoomDetailsList: true,
            IsShowFinalRankingList: false,
            isAlmostDone: false,

            // JoinedPlayerEmails: [],
            // JoinedPlayerArrays: [],
            // RankingListArrays: [],
            // FinalRankingList: [],

            logDataDetails: '',
            resultDataDetails: '',
        }, () => {
            switch (_uiMode) {
                case UiMode.Begin:
                    break;
                // case UiMode.ShowRoomList:
                //     this.setState({
                //         showRoomDetailsList: true,
                //     });
                //     break;
                // case UiMode.ShowJoinedPlayers:
                //     if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0)
                //         this.SelectRoom(this.state.prevSelectedRoomId);
                //     // this.setState({
                //     //     isShowJoinedPlayers: true,
                //     // });
                //     break;
                // case UiMode.ShowRankingList:
                //     if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0)
                //         this.SelectRoom(this.state.prevSelectedRoomId);
                //     // this.setState({
                //     //     isShowRankingList: true,
                //     // });
                //     break;
                // case UiMode.ShowFinalRankingList:
                //     // if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0)
                //     //     this.SelectRoom(this.state.prevSelectedRoomId);
                //     this.setState({
                //         // IsShowFinalRankingList: true,
                //         showRoomDetailsList: true,
                //         JoinedPlayerArrays: [],
                //         RankingListArrays: [],
                //         FinalRankingList: [],
                //     }, () => {
                //         if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0)
                //             this.SelectRoom(this.state.prevSelectedRoomId);
                //     });
                //     break;
                default:
                    this.setState({
                        showRoomDetailsList: true,
                    });
                    // this.setState({
                    //     showRoomDetailsList: true,
                    // }, () => {
                    //     if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0) {
                    //         this.SelectRoom(this.state.prevSelectedRoomId);
                    //     }
                    // });
                    break;
            }
            if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0) {
                this.SelectRoom(this.state.prevSelectedRoomId);
            }
        });
    }

    downloadFile = () => {
        let myData;
        let lastNotice;
        let _filename = this.state.liveQuizDate + '_';
        let roomDetails = this.state.RoomDetailArrays[this.state.selectedRoomIndex];
        if (uiModeState === UiMode.ShowJoinedPlayers) {
            _filename += roomDetails.RoomTitle.replace(' ', '-') + '_' + roomDetails.RoomId + '_Joined-Player-List';
            myData = this.state.JoinedPlayerArrays;
            lastNotice = 'Joined Player List for <' + roomDetails.RoomTitle + '> has been downloaded.';
        }
        else if (uiModeState === UiMode.ShowRankingList) {
            _filename += roomDetails.RoomTitle.replace(' ', '-') + '_' + roomDetails.RoomId + '_Player-Ranking-List';
            myData = this.state.RankingListArrays;
            lastNotice = 'Player Ranking List for <' + roomDetails.RoomTitle + '> has been downloaded.';
        }
        else if (uiModeState === UiMode.ShowFinalRankingList) {
            _filename += roomDetails.RoomTitle.replace(' ', '-') + '_' + roomDetails.RoomId + '_Player-Final-Ranking-List';
            myData = this.state.FinalRankingList;
            lastNotice = 'Final Ranking List for <' + roomDetails.RoomTitle + '> has been downloaded.';
        }
        const fileName = _filename;
        const json = JSON.stringify(myData);
        const blob = new Blob([json], { type: 'application/json' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setTimeout(() => {
            window.alert(lastNotice);
        }, 1000);
    }

    // exportToJson() {
    //     let objectData;
    //     if (uiModeState === UiMode.ShowJoinedPlayers) {
    //         objectData = this.state.JoinedPlayerArrays;
    //     }
    //     else if (uiModeState === UiMode.ShowRankingList) {
    //         objectData = this.state.RankingListArrays;
    //     }

    //     let filename = "export.json";
    //     let contentType = "application/json;charset=utf-8;";
    //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //         var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], { type: contentType });
    //         navigator.msSaveOrOpenBlob(blob, filename);
    //     }
    //     else {
    //         var a = document.createElement('a');
    //         a.download = filename;
    //         a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData));
    //         a.target = '_blank';
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //     }
    // }

    // downloadFile1() {
    //     let objectData;
    //     if (uiModeState === UiMode.ShowJoinedPlayers) {
    //         objectData = this.state.JoinedPlayerArrays;
    //     }
    //     else if (uiModeState === UiMode.ShowRankingList) {
    //         objectData = this.state.RankingListArrays;
    //     }

    //     let link = document.createElement("a");
    //     link.download = 'fileName.json';
    //     link.href = `data:application/octet-stream,${objectData}`;
    //     return link;
    // }

    PrintDiv = (_name = '') => {
        window.alert("Best View in Mode: Landscape, A3 size paper");

        let objectData;
        if (uiModeState === UiMode.ShowJoinedPlayers) {
            objectData = 'Joined-Player-List';
        }
        else if (uiModeState === UiMode.ShowRankingList) {
            objectData = 'Ranking-List';
        }
        else if (uiModeState === UiMode.ShowFinalRankingList) {
            // objectData = 'Final-Ranking-List';
            objectData = 'Ranking-List-with-Quiz-Result_hidden';

            //2022.06.13
            if (_name !== null && _name.length > 0) {
                objectData = _name + '_hidden';
            }
        }

        var mywindow = window.open('', 'PRINT', 'height=700,width=1000');

        mywindow.document.write('<html><head><title>' + this.getNameOfReport() + '</title>');
        mywindow.document.write('</head><body >');
        mywindow.document.write('<h1>' + document.title + '</h1>');
        mywindow.document.write(document.getElementById(objectData).innerHTML.replace('blue', 'black'));
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }

    //2020.07.15
    OpenLogs = async (_uid, _pname, _rank, _email, _roomEnterHistory = null) => {
        // alert(_uid);
        // window.open('https://www.google.com/');

        this.setState({
            logDataDetails: '',
            scrollPosition: window.pageYOffset,
        });

        // let date = moment.unix(this.state.selectedRoomId / 1000);   //.format("YYYY-MM-DD HH:mm:ss"); //room id = ticks
        // alert(date.format("YYYY-MM-DD HH:mm:ss") + " / " + new Date(this.state.selectedRoomId / 10000).toString());
        // alert(date.format("YYYY-MM-DD HH:mm:ss"));
        // alert(date.format("YYYY-MM-DD HH:mm:ss") + "\n\n" 
        // + date.format("YYYY") + "|" + date.format("M") + "|" + date.format("D") 
        // + "|=|" + date.format("hh:mm:ss A"));

        //2023.07.04
        let _date = null;
        if (_roomEnterHistory !== null) {
            // if (_roomEnterHistory.hasOwnProperty('LastEnteredDateTimeUtc')) {       //2023.09.26
            //     const _roomEnterHistoryDate_Moment_UTC = moment(String(_roomEnterHistory.LastEnteredDateTimeUtc));
            //     _date = _roomEnterHistoryDate_Moment_UTC.format('YYYY-MM-DD HH:mm:ss');
            // }
            // if (_roomEnterHistory.hasOwnProperty('Date')) {
            //     _date = String(_roomEnterHistory.Date);
            // }

            //2023.09.26
            if (_roomEnterHistory.hasOwnProperty('Date')) {
                let _roomEnterHistoryDate = String(_roomEnterHistory.Date);
                let _roomEnterHistoryDate_Moment = moment(String(_roomEnterHistory.Date));
                // console.warn('uid: ' + _uid + ', date: ' + _roomEnterHistoryDate);
                if (_roomEnterHistory.hasOwnProperty('LastEnteredDateTimeUtc')) {
                    const _roomEnterHistoryDate_Moment_UTC = moment(String(_roomEnterHistory.LastEnteredDateTimeUtc));
                    const hours_diff = _roomEnterHistoryDate_Moment.diff(_roomEnterHistoryDate_Moment_UTC, 'hours');
                    if (hours_diff > 7 || hours_diff < 0)
                        _roomEnterHistoryDate = _roomEnterHistoryDate_Moment.format('YYYY-MM-DD HH:mm:ss');
                    else if (hours_diff === 7)
                        _roomEnterHistoryDate = _roomEnterHistoryDate_Moment_UTC.format('YYYY-MM-DD HH:mm:ss');
                    // console.warn('uid: ' + _uid + ', date (last enter): ' + _roomEnterHistoryDate_Moment_UTC.format('YYYY-MM-DD HH:mm:ss'));
                    // console.warn('uid: ' + _uid + ', diff: ' + _roomEnterHistoryDate_Moment.diff(_roomEnterHistoryDate_Moment_UTC, 'hours'));
                }
                if (_roomEnterHistory.hasOwnProperty('LastEnteredTimeZone')) {
                    let tz = Number(String(_roomEnterHistory['LastEnteredTimeZone']).split(':')[0]);
                    tz = String(_roomEnterHistory['LastEnteredTimeZone']).includes('-') ? tz : -tz;
                    _roomEnterHistoryDate = _roomEnterHistoryDate_Moment.add(tz, 'hours').format('YYYY-MM-DD HH:mm:ss');
                }
                _date = _roomEnterHistoryDate;
                // console.warn('uid: ' + _uid + ', date: ' + _date);
            }
        }

        if (_date !== null) {
            await this.logsPath(_uid, _date).once('value', snapshot => {
                this.processLogsData(snapshot.val(), _uid, _pname, _rank, _email, _date);
            });
        }
    }

    //2020.07.15
    processLogsData = async (dataSnapVal, _uid, _pname, _rank, _email, _date = null) => {

        let date = moment(this.state.selectedDate); //room id = ticks
        if (_date !== null) {
            date = moment(String(_date));
        }
        let dataKeys = _(dataSnapVal).keys().value();       //key = time
        let dataValues = _(dataSnapVal).values().value();   //values = log message
        let _logDataHtml = '';

        _logDataHtml += "<table cellpadding='15' cellspacing='0' border='1' width='765px'><tr><td colspan='2'>";
        _logDataHtml += "<table width='100%' style='font-weight: bold;'><tr><td align='left' style='width:33%'>("
            + _rank + ") "
            + _pname + "<br />" + _email + "<br />" + _uid + "</td>"
            + "<td align='center' style='width:40%'>Room Code<br />" + this.state.selectedRoomCode + "</td>"
            + "<td align='right'>" + date.format("LL") + "<br />(" + date.format("YYYY-MM-DD") + ")</td></tr></table>";
        _logDataHtml += "</td></tr><tr><th width='100px'>Time</th><th width='665px' align='left'>Log</th></tr>";

        let _logArray = [];
        dataKeys.map(async (data, key) => {
            _logArray.push({ Time: data, Log: dataValues[key] });
        });
        _logArray.sort(function (a, b) {
            if (moment(a.Time) > moment(b.Time)) {
                return 1;
            }
            if (moment(a.Time) < moment(b.Time)) {
                return -1;
            }
            return 0;
        });
        _logArray.map(async (data, key) => {
            let hr = String(data.Time).substring(0, 2);
            let min = String(data.Time).substring(2, 4);
            let sec = String(data.Time).substring(4, 6);

            let dateTime = moment(this.state.selectedDate).format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
            let logText = String(_logArray[key].Log).toString().replace(this.state.selectedRoomCode, "<font color='blue'><u>" + this.state.selectedRoomCode + "</u></font>");

            _logDataHtml += "<tr><td>" + moment(dateTime).add(8, 'hours').format("hh:mm:ss A")
                + "</td><td width='665px' align='left'>"
                + logText.match(new RegExp('.{1,' + 85 + '}', 'g')).join('<br />')
                + "</td></tr>";

            return null;
        });
        _logDataHtml += "</table>";

        let rankingData = this.state.FinalRankingList;
        rankingData[_rank - 1].IsWeb = String(_logDataHtml).toLowerCase().includes('platform');
        _logDataHtml = _logDataHtml.replace(new RegExp('Platform', 'g'), "<font color='blue'>Platform</font>");

        this.setState({
            logDataDetails: _logDataHtml,
            FinalRankingList: rankingData,
            ToggleLogDataDetail: true,
        });
    }

    //2020.07.16 - Ranking-List-with-Quiz-Result
    getNameOfReport = (_name = '', _rankingList = true) => {

        //2022.06.13
        let _reportName = 'Ranking-List-with-Quiz-Result';
        if (_name !== null && _name.length > 0) {
            _reportName = _name;
        }

        if (_rankingList === false) {
            return _reportName + "_(" + moment().format('YYYY-MM-DD_HHmm') + ")";
        }

        //2023.11.03
        const groupId = CheckObjectNumber(this.state.RoomDetailArrays[this.state.selectedRoomIndex], 'GroupId');
        const groupOptions = GradeOptions(Lang.English);
        const groupIndex = groupOptions.findIndex(x => x.id === groupId);
        const groupName = groupIndex < 0 ? '' : groupOptions[groupIndex].value;

        return _reportName
            + "_" + groupName.replace(' ', '-') + "_"
            + String(this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject).replace(' ', '-') + "_"
            + this.state.selectedRoomCode + "_" + this.state.selectedRoomId + "_("
            + moment().format('YYYY-MM-DD_HHmm') + ")";
    }

    //2021.03.08
    getOrganizer = (name, subject) => {
        name = String(name).toLowerCase();
        subject = String(subject).toLowerCase();
        let organizer = '';
        if (name.includes('全马线上考试'))
            organizer = '佳学教育 JiaXue Tuition Center';
        else if (subject.includes('colin'))
            organizer = "The Colin's Learning Centre";
        else if (subject.includes('abacus'))
            organizer = 'Smart Newton';
        else
            organizer = 'SIM';
        return organizer;
    }

    //2020.07.17 - Hidden Ranking List with Results - Ui
    processHiddenRankingListWithResults = async () => {

        let _space_1 = 17;  //11 + 2 + 2 + 1;  //6;
        let _space_2 = 14;  //8 + 2 + 2 + 1;   //3;

        let _qsQty = this.state.RoomDetailArrays[this.state.selectedRoomIndex].QnQty;
        let _htmlStrings = "<table id='Ranking-List-with-Quiz-Result' padding={'10'}>";


        _htmlStrings += "<tr height='30'><td colspan='" + (_space_1 + _qsQty) + "'></td></tr>";
        _htmlStrings += "<tr><td colspan='" + (_space_1 + _qsQty) + "'><b>" + this.getNameOfReport('Ranking-List-with-Quiz-Result') + "</b></td></tr>";
        _htmlStrings += "<tr height='30'><td colspan='" + (_space_1 + _qsQty) + "'></td></tr>";

        _htmlStrings += "<tr><td></td><td>Date</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + moment(this.state.selectedDate).format('dddd, MMMM Do, YYYY')
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Quiz</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>Live Quiz</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Room Title</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + String(this.state.RoomDetailArrays[this.state.selectedRoomIndex].RoomTitle)
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Room Code</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + String(this.state.selectedRoomCode)
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Room Id</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + String(this.state.selectedRoomId)
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Subject</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + String(this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject)
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Grade</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "' align='left'>"
            + "<b>" + String(this.state.RoomDetailArrays[this.state.selectedRoomIndex].Grade)
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Question Quantity</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + String(_qsQty)
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Questions</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + String(this.state.RoomDetailArrays[this.state.selectedRoomIndex].Questions).replace(/;/g, ' | ')
            + "</b></td></tr>";
        _htmlStrings += "<tr height='30'><td colspan='" + (_space_1 + _qsQty) + "'></td></tr>";


        // _htmlStrings += "<tr>"
        //     + "<td align='right'><b>Rank</b></td><td><b>Name</b></td><td><b>Scores</b></td>"
        //     + "<td><b>Time&nbsp;Consumed</b></td><td><b>Email</b></td><td><b>Result</b></td>";
        _htmlStrings += "<tr>"
            + "<td align='right'><b>Rank</b></td> <td><b>Name</b></td> <td><b>Result</b></td>"
            // + "<td><b>Time&nbsp;Consumed</b></td> <td><b>Scores</b></td> <td><b>Email</b></td>"

            // //2021.11.16
            // + "<td><b>Time&nbsp;Consumed</b></td> <td><b>Scores</b></td> <td><b>Classroom</b></td>"
            // + "<td><b>Gender</b></td> <td><b>Race</b></td> <td><b>Email</b></td>";

            //2023.08.18 - add Finish Timestamp
            + "<td><b>Time&nbsp;Consumed</b></td> <td><b>Scores</b></td> <td><b>Finish Timestamp</b></td> <td><b>Classroom</b></td>"
            + "<td><b>Gender</b></td> <td><b>Race</b></td> <td><b>Email</b></td>";

        //2021.04.20
        _htmlStrings += "<td><b>Contact</b></td>";

        // //2020.12.11
        // _htmlStrings += "<td><b>SIM</b></td> <td><b>State</b></td> <td><b>School</b></td>";
        // console.log('line = 3132');

        //2021.03.08
        _htmlStrings += "<td><b>"
            + this.getOrganizer(
                this.state.RoomDetailArrays[this.state.selectedRoomIndex].RoomTitle,
                this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject
            )
            + "</b></td> <td><b>State</b></td> <td><b>School</b></td>"
            + "<td><b>School Code</b></td> <td><b>District Area</b></td>"   //2022.11.04
            ;

        // console.log('line = 3142');

        //2020.12.14
        // _htmlStrings += "<td><b>Guardian</b></td> <td><b>Contact</b></td>";

        //2021.04.20
        _htmlStrings += "<td><b>Guardian</b></td>";

        for (var i = 1; i < _qsQty + 1; i++) {
            _htmlStrings += "<td align='center'><b>Q" + i + "</b></td>";
        }
        _htmlStrings += "</tr>";

        let _UpdatedFinalRankingList = [];
        // let _RankingListResults = [];
        let _date = moment(this.state.selectedDate).format('YYYYMMDD');
        // console.log('line = 3158');

        //2021.09.13 - Load quiz questions.
        await this.LoadQuizQuestions();
        await DelayUntil(() => this.state.QuizQuestions.length > 0);
        // await Delay(1000);
        let roomData = this.state.RoomDetailArrays[this.state.selectedRoomIndex];

        //2023.11.27
        if (CheckObjectNullValue(roomData, 'Questions') === null) {
            if (Number(_qsQty) > 0) {
                let txt = [];
                for (let q = 0; q < Number(_qsQty); q++) {
                    txt.push(q + 1);
                }
                roomData['Questions'] = txt.join(';');
            }
        }

        let pickedQs = roomData.Questions.split(';');
        // pickedQs = pickedQs.filter((el) => { return (el !== null); });
        // console.log('line = 3175');

        //2022.11.04
        // const _SchoolListWithDetails = await import('../../components/SchoolListWithDetails').then((data) => { return data.default; });
        // let _SchoolListWithDetails = [];
        // const _SchoolListWithDetails = this.props.schoolListWithDetails;

        await Promise.all(
            //Loop ranking list.
            this.state.FinalRankingList.map(async (data, key) => {

                // for (let kkk = 0; kkk < this.state.FinalRankingList.length; kkk++) {

                // let data = this.state.FinalRankingList[kkk];

                // for (var num = 0; num < this.state.FinalRankingList.length; num++) {
                //     let data = this.state.FinalRankingList[num];
                //     let key = num;

                // //2021.09.13 - progression state.
                // if (key % 100 === 0) {
                //     ProgressionStateText = 'Almost done...(' + Math.round(((key + 1) / this.state.FinalRankingList.length) * 100)
                //         + '%) (' + (key + 1) + '/' + this.state.FinalRankingList.length + ')';
                //     // console.log(ProgressionStateText);
                //     this.forceUpdate();
                //     await Delay(500);
                // }
                // // this.setState({
                // //     ProgressionState: 'Almost done...(' + Math.round(((key + 1) / this.state.FinalRankingList.length) * 100)
                // //         + '%) (' + (key + 1) + '/' + this.state.FinalRankingList.length + ')',
                // // });

                let _uid = CheckObjectStringEmpty(data, 'Uid');
                // let _results = '';
                let _resultArrayInBit = [];
                // let hasResult = false;
                let _correctQty = 0;
                // let isFetchNewPathDone = false;
                let _resultArray = [];

                // alert(this.state.selectedDate + "|" + _roomCode + "|" + _uid);

                //#region quiz result - no need as new method is used, get result by ASelects.
                // await this.personalResultPath(_date, this.state.selectedRoomId, _uid).once('value', snapshot => {
                //     _results = snapshot.val();
                //     // alert(_date + "|" + this.state.selectedRoomCode + "|" + _uid + "|" + _results);
                //     // if (snapshot.exists())
                //     isFetchNewPathDone = true;
                // });

                // if (!isFetchNewPathDone) {
                //     await this.personalResult_OldPath(_date, this.state.selectedRoomId, _uid).once('value', snapshot => {
                //         // if (snapshot.exists())
                //         _results = snapshot.val();
                //         // alert(data.PlayerName + "|" + _date + "|" + this.state.selectedRoomCode + "|" + _uid + "|" + _results);
                //     });
                // }

                // if (_results != null && _results.length > 0) {
                //     // hasResult = true;
                //     _resultArrayInBit = _results.split(':');
                // }
                // else {
                //     // _resultArrayInBit = new Array(this.state.QuizQuestions.length).fill('0');
                //     _resultArrayInBit = new Array(roomData.QnQty).fill('0');
                // }
                //#endregion
                _resultArrayInBit = new Array(roomData.QnQty).fill('0');

                //#region new method for calculating result. 2021.09.13
                // let selects = '';
                let selectedAnswers = [];
                let feteched_selectedAnswers = '';
                // let retryAgain = false;
                // do {
                //     retryAgain = false;
                await this.personalASelectsPath(_date, this.state.selectedRoomId, _uid)
                    .once('value', snapshot => {
                        // if (snapshot.exists())
                        // selectedAnswers = String(snapshot.val()).split(':');
                        feteched_selectedAnswers = String(snapshot.val());
                    });

                //2023.11.16
                //avoid ASelects in wrong format, shd b in raw, but in (Q:A;)*n form
                let p_ASelects = CheckStringEmpty(feteched_selectedAnswers);
                if (p_ASelects.includes(':') && p_ASelects.includes(';')) {
                    let p_ASelects_array = [];
                    const _tmp_p_ASelects_array = p_ASelects.split(';');
                    for (let p1 = 0; p1 < _tmp_p_ASelects_array.length; p1++) {
                        const aSelect = _tmp_p_ASelects_array[p1].split(':');
                        //aSelect[0] = Question No.
                        //aSelect[1] = Answer.
                        const aSelect_AnswerIndex = AtoZ.findIndex(x => x === aSelect[1]);
                        p_ASelects_array.push(aSelect_AnswerIndex.toString());
                        // if (aSelect_AnswerIndex > -1)
                        //     p_ASelects_array.push(aSelect_AnswerIndex.toString());
                        // else
                        //     p_ASelects_array.push('-1');
                    }
                    p_ASelects = p_ASelects_array.join(':');
                }
                selectedAnswers = p_ASelects.split(':');
                // console.log(_uid, p_ASelects);

                // //2021.11.15 - debugging.
                // if (data.Uid === 'pR6r0ysJvcZeWavyZ0uLzo35pE63') {
                //     let selectedAnswers_text = 'selectedAnswers_text\n';
                //     selectedAnswers.map((d, k) => {
                //         return selectedAnswers_text += (k + 1) + ' = ' + d + ',\n';
                //     });
                //     console.log(selectedAnswers_text);
                // }

                // selectedAnswers = selects.split(':');
                // } while (retryAgain);
                if (selectedAnswers.length > 0) {
                    for (var q = 0; q < _resultArrayInBit.length; q++) {
                        // let _finalResult = null;
                        let _selectedAnswer = '-1';
                        let _answer = '';
                        // let question = this.state.QuizQuestions[q];
                        let question = this.state.QuizQuestions[Number(pickedQs[q]) - 1];
                        // console.log(JSON.stringify(question));
                        if (question !== undefined) {
                            if (question.hasOwnProperty('SpecialMode')) {
                                if (question.SpecialMode.includes('FillInTheBlanks')) {
                                    let settings = String(question.SpecialMode).split(';');
                                    let startNo = Number(settings[1].split(',')[0]) - 1;
                                    // let endNo = Number(settings[1].split(',')[1]) - 1;
                                    // let index = key - startNo;
                                    let index = q - startNo;
                                    _answer = String(this.state.QuizQuestions[startNo].Answer).split(';')[index];
                                    // _finalResult = answer === String(String(this.state.QuizQuestions[startNo].Selection).split(';')[selectedAnswers[q]]).split(':')[0];
                                    _selectedAnswer = String(String(this.state.QuizQuestions[startNo].Selection).split(';')[Number(selectedAnswers[q])]).split(':')[0];

                                    // //2021.11.15 - debugging.
                                    // if (data.Uid === 'pR6r0ysJvcZeWavyZ0uLzo35pE63') {
                                    //     if (q > 30 && q <= 34)
                                    //         console.log(
                                    //             '(#' + (q + 1) + ')\nAns = ' + _answer + '\nSelected = ' + _selectedAnswer
                                    //             + '\nCorrect = ' + (_answer === _selectedAnswer ? 'yes' : 'no')
                                    //             + '\nkey = ' + key
                                    //             + '\nstartNo = ' + startNo
                                    //             + '\nindex = ' + index
                                    //         );
                                    // }
                                }
                                else {
                                    //Comprehension or Subjective.
                                    _answer = question.Answer;
                                    _selectedAnswer = String(String(question.Selection).split(';')[Number(selectedAnswers[q])]).split(':')[0];
                                }
                            }
                            else {
                                //normal question.
                                _answer = question.Answer;
                                _selectedAnswer = String(String(question.Selection).split(';')[Number(selectedAnswers[q])]).split(':')[0];
                            }
                            if (_answer === _selectedAnswer) {
                                _resultArrayInBit[q] = '1';
                            }
                            else {
                                _resultArrayInBit[q] = '0';
                            }
                            // console.log((q + 1) + ' = ' + _resultArrayInBit[q]);
                        }
                    }
                }
                //#endregion


                // let _rank = key.toString().includes('*') ? key.toString() : (key + 1).toString();

                // _htmlStrings += "<tr><td valign='top'>" + data.Rank + "</td><td valign='top'>" + data.PlayerName
                //     + "</td><td valign='top'>" + data.Scores + "</td><td valign='top'>"
                //     + this.convertTime(data.TimeElapsed) + " (" + data.TimeElapsed + ") " + "</td><td valign='top'>"
                //     + data.Email + "</td>";

                // if (hasResult) {
                for (var i = 0; i < _qsQty; i++) {
                    if (_resultArrayInBit[i] === '1')
                        _correctQty += 1;

                    let _result = _resultArrayInBit[i] === '1' ? '✔' : '❌';
                    // let _result = _resultArrayInBit[i] == '1' ? 1 : 0;
                    _resultArray.push({ result: _result });
                }
                // }
                // _htmlStrings += "<td valign='top'>" + _correctQty + "/" + _qsQty + " (Wrong:&nbsp;" + (_qsQty - _correctQty) + ")</td>";
                // for (var i = 0; i < _qsQty; i++) {
                //     let _result = (hasResult ? (_resultArrayInBit[i] == '1' ? '✔' : '❌') : '-');
                //     _htmlStrings += "<td align='center' valign='top'>" + _result + "</td>";

                //     //2020.07.18
                //     _resultArray.push({ _result });
                // }

                // _htmlStrings += "</tr>";

                //2020.07.18    //Storing results data in array for future reference.
                // _RankingListResults.push({
                //     // Rank: data.Rank,
                //     // PlayerName: data.PlayerName,
                //     // Scores: data.Scores,
                //     // TimeElapsed: data.TimeElapsed,
                //     // Email: data.Email,
                //     Uid: _uid,
                //     Results: _resultArray,
                // });


                //2020.12.11    //fetch data from FS > RegistrationData
                // let fetch_FS_RegData_success = false;
                // let _nationalState = '';
                // let _school = '';
                // let _isSimStudent = '';

                //2021.01.30
                let fetched_profile = null;
                // let _subject = String(this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject).toLowerCase();
                // let _targetRegData = 'SIM';
                // if (_subject.includes('colin'))
                //     _targetRegData = 'COLIN';
                // else if (_subject.includes('abacus'))
                //     _targetRegData = 'SMARTNEWTON';

                // //2020.12.14
                // let _guardian = '';
                // let _contact = '';

                // //2021.11.16
                // let _gender = '';
                // let _race = '';

                //2022.11.04
                // let _districtArea = '-';

                // //2023.06.14
                // let _classroom = '';

                // // await this.props.dbFireStore.collection("RegistrationData")
                // let fetch_FS_RegData_success = false;
                // await this.props.dbFireStore
                //     // .collection("LiveQuiz_RegData/" + _targetRegData + "/List")
                //     .collection("User")     //2022.06.13
                //     // .collection("LiveQuiz_NewUser_RegData")
                //     .where('Uid', '==', _uid)
                //     .get()
                //     .then((querySnapshot) => {
                //         let data = [];
                //         if (querySnapshot !== null) {
                //             querySnapshot.forEach((doc) => {
                //                 data.push(doc.data());
                //             });
                //         }
                //         if (data.length > 0) {

                //             // _nationalState = data[0]['NationalState'];
                //             // _school = data[0]['School'];
                //             // // _isSimStudent = data[0]['IsCenterStudent'];

                //             // //2020.12.14
                //             // _guardian = data[0]['Guardian'];
                //             // _contact = data[0]['Contact'];

                //             // //2021.11.16
                //             // _gender = data[0]['Gender'];
                //             // _race = data[0]['Race'];

                //             // //2022.11.04
                //             // _districtArea = data[0]['DistrictArea'];

                //             // //2023.06.14
                //             // _classroom = data[0]['Classroom'];

                //             //2022.06.13
                //             fetched_profile = data[0];

                //             fetch_FS_RegData_success = true;
                //         }
                //         // alert(JSON.stringify(data));
                //     });

                //2024.01.17
                let fetch_FS_RegData_success = false;
                await fetch(GlobalSetting.ApiUrl
                    + 'Api/LearningCentre/User/Profile/Get/' + _uid + '/' + Number(this.props.OrganizerInfo.OrganizerId),
                    // Api/LearningCentre/User/Profile/Get/{firebaseUserId}/{organizerId}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        // if (this.props.isDevMode)
                        //     console.log('CheckProfile_ViaApi (json)', JSON.stringify(data));
                        if (data.success) {
                            if (CheckObjectNullValue(data, 'data') !== null) {
                                fetched_profile = data.data;
                                fetch_FS_RegData_success = true;
                            }
                        }
                        else {
                            if (this.props.isDevMode)
                                console.log('Error', 'api - fetch profile (failed)\n' + JSON.stringify(data));
                        }
                    })
                    .catch(error => {
                        if (this.props.isDevMode)
                            console.log('Error', 'api - fetch profile (failed)\n' + error.message);
                    });

                //2022.11.04
                let _isSimStudent = 'No';
                if (fetch_FS_RegData_success) {
                    await this.props.dbFireStore
                        .collection("LiveQuiz_NewUser_RegData")
                        .doc(_uid)
                        .get()
                        .then((doc) => {
                            if (doc.exists) {
                                // console.log(JSON.stringify(doc.data()));
                                // if (doc.data()['IsCenterStudent'] !== undefined)
                                if (CheckObjectNullValue(doc.data(), 'IsCenterStudent') !== null)
                                    _isSimStudent = String(doc.data()['IsCenterStudent']).toLowerCase() === 'yes' ? 'Yes' : 'No';
                            }
                        });
                }

                //#region old codes
                // //2021.01.30 - get from Profile (RTDB) if not found.
                // if (_contact.length <= 0) {
                //     if (fetched_profile === null) {
                //         await this.userProfilePath(data.Uid).once('value', snapshot => {
                //             return fetched_profile = snapshot.val();
                //         });
                //     }
                //     // alert(JSON.stringify(fetched_profile));
                //     if (fetched_profile !== null) {
                //         _nationalState = fetched_profile._13NationalState;
                //         _school = fetched_profile._10School;
                //         // _isSimStudent = _targetRegData === 'SIM' ? true : false;
                //         _guardian = fetched_profile._9Guardian;
                //         _contact = fetched_profile._8Contact;
                //         _classroom = fetched_profile._19Classroom;
                //         fetch_FS_RegData_success = true;
                //     }
                // }

                // //2022.11.04
                // let _schoolCode = '-';
                // if (_school !== 'OTHER' && _school !== '' && _school !== undefined && _school !== null) {
                //     let findSchoolDetailIndex = _school.includes(' - ') ?
                //         _SchoolListWithDetails.findIndex(x =>
                //             _school.includes(String(x.Name))
                //             && _school.includes(String(x.NationalState)) && _school.includes(this.GetDistrict(String(x.PPD)))
                //         )
                //         :
                //         _SchoolListWithDetails.findIndex(x => _school.includes(String(x.Name)));

                //     if (findSchoolDetailIndex > -1) {
                //         _districtArea = String(_SchoolListWithDetails[findSchoolDetailIndex].PPD);
                //         _schoolCode = String(_SchoolListWithDetails[findSchoolDetailIndex].Kod);
                //     }

                //     // let _schoolName = _school.includes(' - ') ? _school.split(' - ')[0] : _school;
                //     // let findIdx = _SchoolListWithDetails.findIndex(x => String(x.schoolName).includes(_schoolName));
                //     // if (findIdx < 0) {
                //     //     let govSchoolDetails = await this.GetGovSchoolDetail(_schoolName, fetched_profile);
                //     //     if (govSchoolDetails !== null && govSchoolDetails !== undefined) {
                //     //         let checkIdx = _SchoolListWithDetails.findIndex(x => Number(x.id) === Number(govSchoolDetails.id));
                //     //         if (checkIdx < 0)
                //     //             _SchoolListWithDetails.push(govSchoolDetails);

                //     //         _districtArea = govSchoolDetails['ppd'] !== undefined ? String(govSchoolDetails['ppd']) : '';
                //     //         _schoolCode = govSchoolDetails['schoolCode'] !== undefined ? String(govSchoolDetails['schoolCode']) : '';
                //     //     }
                //     // }
                //     // else {
                //     //     _districtArea = String(_SchoolListWithDetails[findIdx].ppd);
                //     //     _schoolCode = String(_SchoolListWithDetails[findIdx].schoolCode);
                //     // }
                // }

                // //2023.10.26
                // let _schoolCode = '-';
                // _school = CheckObjectStringEmpty(fetched_profile, 'School');
                // if (_school !== 'OTHER' && _school !== '') {
                //     let findSchoolDetailIndex = _school.includes(' - ') ?
                //         _SchoolListWithDetails.findIndex(x =>
                //             _school.includes(CheckObjectStringEmpty(x, 'Name'))
                //             && _school.includes(CheckObjectStringEmpty(x, 'NationalState'))
                //             && _school.includes(this.GetDistrict(CheckObjectStringEmpty(x, 'PPD')))
                //         )
                //         :
                //         _SchoolListWithDetails.findIndex(x => _school.includes(CheckObjectStringEmpty(x, 'Name')));

                //     if (findSchoolDetailIndex > -1) {
                //         _districtArea = CheckObjectStringEmpty(_SchoolListWithDetails[findSchoolDetailIndex], 'PPD');
                //         _schoolCode = CheckObjectStringEmpty(_SchoolListWithDetails[findSchoolDetailIndex], 'Kod');
                //     }
                // }

                // //2020.07.18
                // _UpdatedFinalRankingList.push({
                //     PlayerName: String(data.PlayerName),
                //     Scores: Number(data.Scores),
                //     TimeElapsed: Number(data.TimeElapsed),
                //     ActualTimeElapsed: Number(data.ActualTimeElapsed),  //new 2020.10.19
                //     FinishTimestamp: String(data.FinishTimestamp),      //2023.08.18 - add Finish Timestamp
                //     IsCompleted: String(data.IsCompleted),
                //     Uid: String(data.Uid),
                //     Email: String(data.Email),
                //     Version: Boolean(data.Version),
                //     Duplicated: Boolean(data.Duplicated),
                //     Qty: Number(data.Qty),
                //     CorrectQty: _correctQty,
                //     WrongQty: _qsQty - _correctQty,
                //     Results: _resultArray,
                //     SelectedAnswers: selectedAnswers,  //2021.12.30

                //     //2020.12.11
                //     IsSimStudent: fetch_FS_RegData_success ? _isSimStudent : '-',
                //     State: fetch_FS_RegData_success ? _nationalState : '-',
                //     School: fetch_FS_RegData_success ? (_school === undefined || _school === '' ? '-' : _school) : '-',

                //     // //2022.11.04
                //     // SchoolCode: fetch_FS_RegData_success ? String(_schoolCode,) : '-',
                //     // DistrictArea: fetch_FS_RegData_success ? String(_districtArea) : '-',

                //     //2023.10.26
                //     SchoolCode: _schoolCode,
                //     DistrictArea: _districtArea,

                //     //2020.12.14
                //     Guardian: fetch_FS_RegData_success ? (_guardian === undefined ? '' : (_guardian.length > 0 ? _guardian : '-')) : '-',
                //     Contact: fetch_FS_RegData_success ? (_contact === undefined ? '' : (_contact.length > 0 ? _contact : '-')) : '-',

                //     //2021.11.16
                //     Gender: fetch_FS_RegData_success ? (_gender === undefined ? '' : (_gender.length > 0 ? _gender : '-')) : '-',
                //     Race: fetch_FS_RegData_success ? (_race === undefined ? '' : (_race.length > 0 ? _race : '-')) : '-',

                //     //2022.06.13
                //     Profile: fetched_profile,

                //     //2023.06.14
                //     Classroom: fetch_FS_RegData_success ? _classroom === undefined ? '-' : _classroom : '-',

                //     //2023.07.04
                //     RoomEnterHistory: data.RoomEnterHistory,
                // });
                //#endregion

                //2023.10.27
                const { school, schoolCode, districtArea } = this.FindSchoolCodeAndDistrictArea(fetched_profile);

                _UpdatedFinalRankingList.push({
                    Uid: _uid,
                    PlayerName: CheckObjectStringEmpty(fetched_profile, 'Name', CheckObjectStringEmpty(data, 'PlayerName')),
                    // Scores: CheckObjectNumber(data, 'Scores'),
                    Scores: Number(((100 / _qsQty) * _correctQty).toFixed(2)),    //2023.12.20 - emergency fix during Annual Leave.
                    TimeElapsed: CheckObjectNumber(data, 'TimeElapsed'),
                    ActualTimeElapsed: CheckObjectNumber(data, 'ActualTimeElapsed'),
                    FinishTimestamp: CheckObjectStringEmpty(data, 'FinishTimestamp'),
                    IsCompleted: CheckObjectBoolean(data, 'IsCompleted'),
                    // Uid: CheckObjectStringEmpty(data, 'Uid'),
                    Email: CheckObjectStringEmpty(fetched_profile, 'Email', CheckObjectStringEmpty(data, 'Email')),
                    Version: CheckObjectBoolean(data, 'Version'),
                    Duplicated: CheckObjectBoolean(data, 'Duplicated'),
                    Qty: CheckObjectNumber(data, 'Qty'),
                    CorrectQty: _correctQty,
                    WrongQty: _qsQty - _correctQty,
                    Results: _resultArray,
                    SelectedAnswers: selectedAnswers,
                    IsSimStudent: _isSimStudent,
                    School: school,
                    SchoolCode: schoolCode,
                    DistrictArea: districtArea,
                    Guardian: CheckObjectStringEmpty(fetched_profile, 'Guardian', '-'),
                    Contact: CheckObjectStringEmpty(fetched_profile, 'Contact', '-'),
                    Gender: CheckObjectStringEmpty(fetched_profile, 'Gender', '-'),
                    Race: CheckObjectStringEmpty(fetched_profile, 'Race', '-'),
                    State: CheckObjectStringEmpty(fetched_profile, 'NationalState', '-'),
                    Classroom: CheckObjectStringEmpty(fetched_profile, 'Classroom', '-'),
                    RoomEnterHistory: data.RoomEnterHistory,
                    Profile: fetched_profile,
                });

                return null;
                // return key;
                // }
                // }
            })
        );
        // .then((key) => {
        //     //2021.09.13 - progression state.
        //     ProgressionStateText = 'Almost done...(' + Math.round(((key + 1) / this.state.FinalRankingList.length) * 100)
        //         + '%) (' + (key + 1) + '/' + this.state.FinalRankingList.length + ')';
        // });
        // console.log(JSON.stringify(_UpdatedFinalRankingList));

        // //2021.09.13 - recount score/result
        // _UpdatedFinalRankingList = await this.ReCheckAndReCalculateScoreResult(_UpdatedFinalRankingList);

        //2021.09.13 - progression state.
        ProgressionStateText = 'Almost done...Sorting List...';

        //Sort List according to Scores & TimeElapsed in ASC
        _UpdatedFinalRankingList.sort(function (a, b) {
            if (parseInt(a.CorrectQty) > parseInt(b.CorrectQty)) {
                return -1;
            }
            if (parseInt(a.CorrectQty) < parseInt(b.CorrectQty)) {
                return 1;
            }
            if (parseFloat(a.TimeElapsed) < parseFloat(b.TimeElapsed)) {
                return -1;
            }
            if (parseFloat(a.TimeElapsed) > parseFloat(b.TimeElapsed)) {
                return 1;
            }
            if (a.PlayerName < b.PlayerName) { return -1; }
            if (a.PlayerName > b.PlayerName) { return 1; }
            return 0;
        });

        // return _htmlStrings;
        //     })
        // )
        //     .then(() => {
        _UpdatedFinalRankingList.map((data, key) => {
            // for (var kkk = 0; kkk < _UpdatedFinalRankingList.length; kkk++) {
            //     let data = _UpdatedFinalRankingList[kkk];
            //     let key = kkk;
            // _htmlStrings += "<tr><td valign='top'>" + (key + 1) + "</td><td valign='top'>" + data.PlayerName
            //     + "</td><td valign='top'>" + data.Scores + "</td><td valign='top'>"
            //     + this.convertTime(data.TimeElapsed) + " (" + data.TimeElapsed + ") " + "</td><td valign='top'>"
            //     + data.Email + "</td>";
            _htmlStrings += "<tr><td valign='top' style='background-color: " + (data.Version ? "transparent" : "lightgray") + "'>" + (key + 1) + "</td>";
            _htmlStrings += "<td valign='top'>" + data.PlayerName + "</td>";

            let _qsQty = data.CorrectQty + data.WrongQty;
            _htmlStrings += "<td valign='top'>" + data.CorrectQty + "/" + _qsQty + " (Wrong:&nbsp;" + data.WrongQty + ")</td>";

            _htmlStrings += "<td valign='top'>" + this.convertTime(data.TimeElapsed) + " (" + data.TimeElapsed + ") </td>";
            // _htmlStrings += "<td valign='top'>" + this.convertTime(data.ActualTimeElapsed) + " (" + data.ActualTimeElapsed + ") " + "</td>";

            _htmlStrings += "<td valign='top'>" + CheckObjectNumber(data, 'Scores').toFixed(2) + "</td>";

            //2023.08.18 - add Finish Timestamp
            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'FinishTimestamp') + "</td>";

            //2023.06.14
            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'Classroom') + "</td>";

            //2021.11.16
            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'Gender') + "</td>";
            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'Race') + "</td>";

            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'Email') + "</td>";

            //2021.04.20
            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'Contact') + "</td>";

            //2020.12.11
            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'IsSimStudent') + "</td>";
            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'State') + "</td>";
            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'School') + "</td>";

            //2022.11.04
            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'SchoolCode') + "</td>";
            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'DistrictArea') + "</td>";

            //2020.12.14
            _htmlStrings += "<td valign='top'>" + CheckObjectStringEmpty(data, 'Guardian') + "</td>";
            // _htmlStrings += "<td valign='top'>" + data.Contact + "</td>";    //2021.04.20 moved to center after Email.

            // let targetPlayerResults = this.state.RankingListResults.find(rlr => rlr.Uid === data.Uid).Results;
            for (var i = 0; i < _qsQty; i++) {
                const _result = (data.Results.length > 0 ? String(data.Results[i].result) : '-');
                _htmlStrings += "<td align='center' valign='top'>" + _result + "</td>";
            }

            _htmlStrings += "</tr>";
            // }
            return null;
        });
        // })
        // .then(() => {
        _htmlStrings += "</table>";

        this.setState({
            FinalRankingList: _UpdatedFinalRankingList,
            // RankingListResults: _RankingListResults,
            Hidden_Ranking_List_With_Results: _htmlStrings,
            IsFinalRankingListLoaded: true,
            IsButtonEnabled: true,
            isAlmostDone: false,
        }, () => {
            // ScrollToElement('ranking-list-table');
        });
        // });

        // _htmlStrings += "</table>";

        // this.setState({
        //     Hidden_Ranking_List_With_Results: _htmlStrings,
        // });
        // );
        // }
    }

    //2023.10.27
    FindSchoolCodeAndDistrictArea = (profile) => {
        let schoolCode = '-';
        let districtArea = '-';
        const _SchoolListWithDetails = this.props.schoolListWithDetails;
        const school = CheckObjectStringEmpty(profile, 'School', '-');
        let _schoolSplits = school.includes('-') ? school.split('-') : null;
        if (school !== '' && school !== '-' && school !== 'OTHER') {
            let findSchoolDetailIndex = -1;
            if (_schoolSplits !== null) {
                for (let i = 0; i < _schoolSplits.length; i++) {
                    _schoolSplits[i] = _schoolSplits[i].trim();
                }
                switch (_schoolSplits.length) {
                    case 3:
                        findSchoolDetailIndex = _SchoolListWithDetails.findIndex(x =>
                            school.includes(x.Name)
                            && school.includes(x.NationalState)
                            && school.includes(this.GetDistrict(x.PPD))
                        );
                        if (findSchoolDetailIndex < 0) {
                            findSchoolDetailIndex = _SchoolListWithDetails.findIndex(x =>
                                school.includes(x.Name)
                                && school.includes(x.NationalState)
                            );
                        }
                        if (findSchoolDetailIndex < 0) {
                            findSchoolDetailIndex = _SchoolListWithDetails.findIndex(x =>
                                school.includes(x.Name)
                                && school.includes(this.GetDistrict(x.PPD))
                            );
                        }
                        if (findSchoolDetailIndex < 0) {
                            findSchoolDetailIndex = _SchoolListWithDetails.findIndex(x =>
                                school.includes(x.Name)
                            );
                        }
                        break;
                    case 2:
                        findSchoolDetailIndex = _SchoolListWithDetails.findIndex(x =>
                            school.includes(x.Name)
                            && school.includes(x.NationalState)
                        );
                        if (findSchoolDetailIndex < 0) {
                            findSchoolDetailIndex = _SchoolListWithDetails.findIndex(x =>
                                school.includes(x.Name)
                                && school.includes(this.GetDistrict(x.PPD))
                            );
                        }
                        if (findSchoolDetailIndex < 0) {
                            findSchoolDetailIndex = _SchoolListWithDetails.findIndex(x =>
                                school.includes(x.Name)
                            );
                        }
                        break;
                    default:
                        findSchoolDetailIndex = _SchoolListWithDetails.findIndex(x => school.includes(x.Name));
                        break;
                }
            }
            else {
                findSchoolDetailIndex = _SchoolListWithDetails.findIndex(x => school.includes(x.Name));
            }
            if (findSchoolDetailIndex > -1) {
                districtArea = CheckObjectStringEmpty(_SchoolListWithDetails[findSchoolDetailIndex], 'PPD');
                schoolCode = CheckObjectStringEmpty(_SchoolListWithDetails[findSchoolDetailIndex], 'Kod');
            }
        }
        return { school, schoolCode, districtArea };
    }

    //2023.10.26 - simplest method.
    DownloadReportAsXLSX = (tableId = '', reportName = '', _rankingList = true) => {
        if (tableId === '')
            return null;

        const table = document.getElementById(tableId);
        if (table !== null) {
            let wb = XLSX.utils.table_to_book(table, { raw: true });

            // //columns custom formating.
            // switch (reportName) {
            //     case 'Ranking-List-with-Quiz-Result':
            //         wb['A2'].z = '';
            //         delete wb['A2'].w;
            //         XLSX.utils.format_cell(ws['A2']);
            //         break;
            //     case 'School-Statistic-Report':
            //         break;
            //     default: break;
            // }

            const filename = this.getNameOfReport(reportName, _rankingList);
            XLSX.writeFile(wb, filename + ".xlsx");
        }
    }

    //2023.10.25+
    //2023.09.26
    processHiddenRankingListWithResults_XLSX = async () => {

        this.setState({
            rankingListRoomInfoForXLSX: [],
            rankingListParticipantsForXLSX: [],
            rankingListParticipantResultsForXLSX_fileName: '',

            // FinalRankingList: _UpdatedFinalRankingList,
            // Hidden_Ranking_List_With_Results: '',
            IsFinalRankingListLoaded: false,
            IsButtonEnabled: false,
            isAlmostDone: false,
        }, () => {
            // ScrollToElement('ranking-list-table');
        });

        let page1 = [];     //room info.
        let page2 = [];     //participants.
        let _UpdatedFinalRankingList = [];

        let _qsQty = this.state.RoomDetailArrays[this.state.selectedRoomIndex].QnQty;

        const filename = this.getNameOfReport();
        // 'Ranking-List-with-Quiz-Result_Std-6_Malay_38408_1692589109789_(2023-10-26_1357)';

        //#region page 1 - basic room info.

        // page1.push({ 'Report': filename });
        // page1.push({ 'Date': moment(this.state.selectedDate).format('dddd, MMMM Do, YYYY') });
        // page1.push({ 'Quiz': 'Live Quiz' });
        // page1.push({ 'Room': this.state.selectedRoomCode });
        // page1.push({ 'Room Id': this.state.selectedRoomId });
        // page1.push({ 'Subject': this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject });
        // page1.push({ 'Grade': this.state.RoomDetailArrays[this.state.selectedRoomIndex].Grade });
        // page1.push({ 'Question Quantity': _qsQty });
        // page1.push({ 'Questions': this.state.RoomDetailArrays[this.state.selectedRoomIndex].Questions.replace(/;/g, ' | ') });

        page1.push({ 'Info': 'Report', 'Detail': filename });
        page1.push({ 'Info': 'Date', 'Detail': moment(this.state.selectedDate).format('dddd, MMMM Do, YYYY') });
        page1.push({ 'Info': 'Quiz', 'Detail': 'Live Quiz' });
        page1.push({ 'Info': 'Room', 'Detail': String(this.state.selectedRoomCode) });
        page1.push({ 'Info': 'Room Id', 'Detail': String(this.state.selectedRoomId) });
        page1.push({ 'Info': 'Subject', 'Detail': String(this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject) });
        page1.push({ 'Info': 'Grade', 'Detail': String(this.state.RoomDetailArrays[this.state.selectedRoomIndex].Grade) });
        page1.push({ 'Info': 'Question Quantity', 'Detail': String(_qsQty) });
        page1.push({ 'Info': 'Questions', 'Detail': this.state.RoomDetailArrays[this.state.selectedRoomIndex].Questions.replace(/;/g, ' | ') });

        //#endregion page 1 - basic room info.

        //#region page 2 - ranking list.

        let _date = moment(this.state.selectedDate).format('YYYYMMDD');
        await this.LoadQuizQuestions();
        await DelayUntil(() => this.state.QuizQuestions.length > 0);
        let roomData = this.state.RoomDetailArrays[this.state.selectedRoomIndex];

        //2023.11.27
        if (CheckObjectNullValue(roomData, 'Questions') === null) {
            if (Number(_qsQty) > 0) {
                let txt = [];
                for (let q = 0; q < Number(_qsQty); q++) {
                    txt.push(q + 1);
                }
                roomData['Questions'] = txt.join(';');
            }
        }

        const pickedQs = roomData.Questions.split(';');
        // const _SchoolListWithDetails = this.props.schoolListWithDetails;

        // for (let k = 0; k < this.state.FinalRankingList.length; k++) {
        // let data = this.state.FinalRankingList[k];
        await Promise.all(
            //Loop ranking list.
            this.state.FinalRankingList.map(async (data, key) => {
                const _uid = CheckObjectStringEmpty(data, 'Uid');
                if (_uid !== '') {
                    let _resultArrayInBit = new Array(roomData.QnQty).fill('0');
                    let _correctQty = 0;
                    let _resultArray = [];

                    //#region new method for calculating result.
                    let selectedAnswers = [];
                    let randomQuestions = [];
                    //2023.10.26
                    await this.personalResultRootPath(_date, this.state.selectedRoomId, _uid)
                        .once('value', snapshot => {
                            if (snapshot.exists()) {
                                let resultData = snapshot.val();

                                //2023.11.16
                                //avoid ASelects in wrong format, shd b in raw, but in (Q:A;)*n form
                                let p_ASelects = CheckObjectStringEmpty(resultData, 'ASelects');
                                if (p_ASelects.includes(':') && p_ASelects.includes(';')) {
                                    let p_ASelects_array = [];
                                    const _tmp_p_ASelects_array = p_ASelects.split(';');
                                    for (let p1 = 0; p1 < _tmp_p_ASelects_array.length; p1++) {
                                        const aSelect = _tmp_p_ASelects_array[p1].split(':');
                                        //aSelect[0] = Question No.
                                        //aSelect[1] = Answer.
                                        const aSelect_AnswerIndex = AtoZ.findIndex(x => x === aSelect[1]);
                                        p_ASelects_array.push(aSelect_AnswerIndex.toString());
                                        // if (aSelect_AnswerIndex > -1)
                                        //     p_ASelects_array.push(aSelect_AnswerIndex);
                                        // else
                                        //     p_ASelects_array.push('-1');
                                    }
                                    p_ASelects = p_ASelects_array.join(':');
                                }
                                // console.log(_uid, p_ASelects);

                                selectedAnswers = p_ASelects.split(':');
                                if (CheckObjectBoolean(roomData, 'RandomQuestionMode'))
                                    randomQuestions = CheckObjectStringEmpty(resultData, 'Questions').split(':');
                            }
                        });
                    if (selectedAnswers.length > 0) {
                        for (let q = 0; q < _resultArrayInBit.length; q++) {

                            //2023.10.26
                            let qIndex = q;
                            if (randomQuestions.length > 0) {
                                const randQuestion = String(randomQuestions[q]);
                                const findQsIndex = this.state.QuizQuestions.findIndex(x => String(x.No) === randQuestion);
                                if (findQsIndex > -1)
                                    qIndex = findQsIndex;
                            }

                            // let _finalResult = null;
                            let _selectedAnswer = '-1';
                            let _answer = '';
                            // let question = this.state.QuizQuestions[qIndex];
                            let question = this.state.QuizQuestions[Number(pickedQs[qIndex]) - 1];
                            // console.log(JSON.stringify(question));
                            if (CheckNullValue(question) !== null) {
                                if (question.hasOwnProperty('SpecialMode')) {
                                    if (question.SpecialMode.includes('FillInTheBlanks')) {
                                        let settings = String(question.SpecialMode).split(';');
                                        let startNo = Number(settings[1].split(',')[0]) - 1;
                                        let index = qIndex - startNo;
                                        _answer = String(this.state.QuizQuestions[startNo].Answer).split(';')[index];
                                        _selectedAnswer = String(String(this.state.QuizQuestions[startNo].Selection).split(';')[Number(selectedAnswers[qIndex])]).split(':')[0];
                                    }
                                    else {
                                        //Comprehension or Subjective.
                                        _answer = String(question.Answer);
                                        _selectedAnswer = String(String(question.Selection).split(';')[Number(selectedAnswers[qIndex])]).split(':')[0];
                                    }
                                }
                                else {
                                    //normal question.
                                    _answer = String(question.Answer);
                                    _selectedAnswer = String(String(question.Selection).split(';')[Number(selectedAnswers[qIndex])]).split(':')[0];
                                }
                                if (_answer === _selectedAnswer) {
                                    _resultArrayInBit[qIndex] = 1;
                                }
                                else {
                                    _resultArrayInBit[qIndex] = 0;
                                }
                                // console.log((q + 1) + ' = ' + _resultArrayInBit[qIndex]);
                            }
                        }
                    }
                    //#endregion

                    //populate result.
                    for (let i = 0; i < _qsQty; i++) {
                        if (_resultArrayInBit[i] === 1)
                            _correctQty += 1;

                        let _result = _resultArrayInBit[i] === 1 ? '✔' : '❌';
                        _resultArray.push({ result: _result });
                    }

                    let fetch_FS_RegData_done = false;
                    let fetched_profile = null;
                    await this.props.dbFireStore
                        .collection("User")
                        .where('Uid', '==', _uid)
                        .get()
                        .then((querySnapshot) => {
                            fetched_profile = [];
                            if (querySnapshot !== null) {
                                querySnapshot.forEach((doc) => {
                                    fetched_profile.push(doc.data());
                                });
                            }
                            if (fetched_profile.length > 0) {
                                fetched_profile = fetched_profile[0];
                                let { profile } = Profile_ValidateProperties({ uid: _uid, email: CheckObjectStringEmpty(data, 'Email') }, fetched_profile);
                                fetched_profile = profile;
                            }
                            fetch_FS_RegData_done = true;
                            console.log(JSON.stringify(fetched_profile));
                        })
                        .catch((e) => {
                            fetch_FS_RegData_done = true;
                            console.log('\nfetch User (' + _uid + ') (Failed).');
                        });
                    await DelayUntil(() => fetch_FS_RegData_done === true);

                    let _isSimStudent = 'No';
                    await this.props.dbFireStore
                        .collection("LiveQuiz_NewUser_RegData")
                        .doc(_uid)
                        .get()
                        .then((doc) => {
                            if (doc.exists) {
                                const docData = doc.data();
                                if (this.props.isDevMode)
                                    console.log('LiveQuiz_NewUser_RegData', JSON.stringify(docData));
                                if (CheckObjectNullValue(docData, 'IsCenterStudent') !== null)
                                    _isSimStudent = CheckObjectStringEmpty(docData, 'IsCenterStudent').toLowerCase() === 'yes' ? 'Yes' : 'No';
                            }
                        });

                    // let _schoolCode = '-';
                    // let _districtArea = '';
                    // const _school = CheckObjectStringEmpty(fetched_profile, 'School');
                    // if (_school !== 'OTHER' && _school !== '') {
                    //     let findSchoolDetailIndex = _school.includes(' - ') ?
                    //         _SchoolListWithDetails.findIndex(x =>
                    //             _school.includes(CheckObjectStringEmpty(x, 'Name'))
                    //             && _school.includes(CheckObjectStringEmpty(x, 'NationalState'))
                    //             && _school.includes(this.GetDistrict(CheckObjectStringEmpty(x, 'PPD')))
                    //         )
                    //         :
                    //         _SchoolListWithDetails.findIndex(x => _school.includes(CheckObjectStringEmpty(x, 'Name')));

                    //     if (findSchoolDetailIndex > -1) {
                    //         _districtArea = CheckObjectStringEmpty(_SchoolListWithDetails[findSchoolDetailIndex], 'PPD');
                    //         _schoolCode = CheckObjectStringEmpty(_SchoolListWithDetails[findSchoolDetailIndex], 'Kod');
                    //     }
                    // }

                    //2023.10.27
                    const { school, schoolCode, districtArea } = this.FindSchoolCodeAndDistrictArea(fetched_profile);

                    _UpdatedFinalRankingList.push({
                        PlayerName: CheckObjectStringEmpty(data, 'PlayerName'),
                        Scores: CheckObjectNumber(data, 'Scores'),
                        TimeElapsed: CheckObjectNumber(data, 'TimeElapsed'),
                        ActualTimeElapsed: CheckObjectNumber(data, 'ActualTimeElapsed'),
                        FinishTimestamp: CheckObjectStringEmpty(data, 'FinishTimestamp'),
                        IsCompleted: CheckObjectBoolean(data, 'IsCompleted'),
                        Uid: CheckObjectStringEmpty(data, 'Uid'),
                        Email: CheckObjectStringEmpty(data, 'Email'),
                        Version: CheckObjectBoolean(data, 'Version'),
                        Duplicated: CheckObjectBoolean(data, 'Duplicated'),
                        Qty: CheckObjectNumber(data, 'Qty'),
                        CorrectQty: _correctQty,
                        WrongQty: _qsQty - _correctQty,
                        Results: _resultArray,
                        SelectedAnswers: selectedAnswers,
                        IsSimStudent: _isSimStudent,
                        School: school,
                        SchoolCode: schoolCode,
                        DistrictArea: districtArea,
                        Guardian: CheckObjectNullValue(fetched_profile, 'Guardian') === null ? '-' : CheckObjectStringEmpty(fetched_profile, 'Guardian'),
                        Contact: CheckObjectNullValue(fetched_profile, 'Contact') === null ? '-' : CheckObjectStringEmpty(fetched_profile, 'Contact'),
                        Gender: CheckObjectNullValue(fetched_profile, 'Gender') === null ? '-' : CheckObjectStringEmpty(fetched_profile, 'Gender'),
                        Race: CheckObjectNullValue(fetched_profile, 'Race') === null ? '-' : CheckObjectStringEmpty(fetched_profile, 'Race'),
                        State: CheckObjectNullValue(fetched_profile, 'NationalState') === null ? '-' : CheckObjectStringEmpty(fetched_profile, 'NationalState'),
                        Classroom: CheckObjectNullValue(fetched_profile, 'Classroom') === null ? '-' : CheckObjectStringEmpty(fetched_profile, 'Classroom'),
                        RoomEnterHistory: data.RoomEnterHistory,
                        Profile: fetched_profile,
                    });

                    // page2.push({
                    //     'Rank': 0,
                    //     'Name': CheckObjectNullValue(data, 'PlayerName') === null ? CheckObjectStringEmpty(fetched_profile, 'Name') : CheckObjectStringEmpty(data, 'PlayerName'),
                    //     'Result': _correctQty + '/' + _qsQty + ' (Wrong:&nbsp;' + (_qsQty - _correctQty) + ')',
                    //     'Time Consumed': this.convertTime(CheckObjectNumber(data, 'TimeElapsed')) + ' (' + CheckObjectNumber(data, 'TimeElapsed') + ')',
                    //     'Scores': CheckObjectNumber(data, 'Scores').toFixed(2),
                    //     'Finish Timestamp': CheckObjectStringEmpty(data, 'FinishTimestamp'),
                    //     'Classroom': CheckObjectStringEmpty(data, 'Classroom'),
                    //     'Gender': CheckObjectStringEmpty(data, 'Gender'),
                    //     'Race': CheckObjectStringEmpty(data, 'Race'),
                    //     'Email': CheckObjectStringEmpty(data, 'Email'),
                    //     'Contact': CheckObjectStringEmpty(data, 'Contact'),
                    //     'SIM': CheckObjectBoolean(data, 'IsSimStudent'),
                    //     'State': CheckObjectStringEmpty(data, 'State'),
                    //     'School': CheckObjectStringEmpty(data, 'School'),
                    //     'School Code': _schoolCode,
                    //     'District Area': _districtArea,
                    //     'Guardian': CheckObjectStringEmpty(data, 'Guardian'),
                    // });
                    // let page2_tmp = page2[page2.length - 1];
                    // for (let i = 0; i < _qsQty; i++) {
                    //     page2_tmp['Q' + (i + 1)] = _resultArray[i].result;
                    // }
                    // page2[page2.length - 1] = page2_tmp;
                }
                return null;
            })
        );

        //sort.
        _UpdatedFinalRankingList.sort(function (a, b) {
            if (parseInt(a.CorrectQty) > parseInt(b.CorrectQty)) {
                return -1;
            }
            if (parseInt(a.CorrectQty) < parseInt(b.CorrectQty)) {
                return 1;
            }
            if (parseFloat(a.TimeElapsed) < parseFloat(b.TimeElapsed)) {
                return -1;
            }
            if (parseFloat(a.TimeElapsed) > parseFloat(b.TimeElapsed)) {
                return 1;
            }
            if (a.Name < b.Name) { return -1; }
            if (a.Name > b.Name) { return 1; }
            return 0;
        });

        //populate final result.
        _UpdatedFinalRankingList.map((data, key) => {
            const _correctQty = CheckObjectNumber(data, 'CorrectQty');
            page2.push({
                'Rank': key + 1,
                'Name': CheckObjectNullValue(data, 'PlayerName'),
                'Result': _correctQty + '/' + _qsQty + ' (Wrong: ' + (_qsQty - _correctQty) + ')',
                'Time Consumed': this.convertTime(CheckObjectNumber(data, 'TimeElapsed')) + ' (' + CheckObjectNumber(data, 'TimeElapsed') + ')',
                'Scores': CheckObjectNumber(data, 'Scores').toFixed(2),
                'Finish Timestamp': CheckObjectStringEmpty(data, 'FinishTimestamp'),
                'Classroom': CheckObjectStringEmpty(data, 'Classroom'),
                'Gender': CheckObjectStringEmpty(data, 'Gender'),
                'Race': CheckObjectStringEmpty(data, 'Race'),
                'Email': CheckObjectStringEmpty(data, 'Email'),
                'Contact': CheckObjectStringEmpty(data, 'Contact'),
                'SIM': CheckObjectStringEmpty(data, 'IsSimStudent'),
                'State': CheckObjectStringEmpty(data, 'State'),
                'School': CheckObjectStringEmpty(data, 'School'),
                'School Code': CheckObjectStringEmpty(data, 'SchoolCode'),
                'District Area': CheckObjectStringEmpty(data, 'DistrictArea'),
                'Guardian': CheckObjectStringEmpty(data, 'Guardian'),
            });
            let page2_tmp = page2[page2.length - 1];
            for (let i = 0; i < _qsQty; i++) {
                const resultArray = CheckObjectNullValue(data, 'Results');
                page2_tmp['Q' + (i + 1)] = resultArray === null ? '-' : resultArray[i].result;
            }
            page2[page2.length - 1] = page2_tmp;
            return null;
        });
        // //assign Rank #.
        // for (let p = 0; p < page2.length; p++) {
        //     page2[p]['Rank'] = p + 1;
        // }

        //#endregion page 2 - ranking list.

        this.setState({
            rankingListRoomInfoForXLSX: page1,
            rankingListParticipantsForXLSX: page2,
            rankingListParticipantResultsForXLSX_fileName: filename,

            FinalRankingList: _UpdatedFinalRankingList,
            // Hidden_Ranking_List_With_Results: '',
            IsFinalRankingListLoaded: true,
            IsButtonEnabled: true,
            isAlmostDone: false,
        }, () => {
            // ScrollToElement('ranking-list-table');
        });
    }

    //2023.09.26
    DownloadRankingListReportAsXLSX = async () => {
        // let style = {
        //     headers: true,
        //     column: { style: { Font: { Bold: "1" } } },
        //     rows: { 1: { style: { Font: { Color: "#FF0077" } } } },
        //     cells: {
        //         1: {
        //             1: {
        //                 style: { Font: { Color: "#00FFFF" } }
        //             }
        //         }
        //     }
        // };
        var opts = [
            // column: {
            //     style: {
            //         width: { FitContent: '1' },
            //     }
            // },
            {
                sheetid: 'Room Info',
                header: true,
                column: {
                    style: {
                        Width: '100px'
                    }
                }
            },
            {
                sheetid: 'Participants',
                header: false
            }
        ];
        alasql('SELECT INTO XLSX("' + this.state.rankingListParticipantResultsForXLSX_fileName + '.xlsx",?) FROM ?',
            [
                opts, [
                    this.state.rankingListRoomInfoForXLSX,
                    this.state.rankingListParticipantsForXLSX
                ]
            ]);
    }

    //2022.11.07
    GetDistrict = (ppd = '') => {
        let splits = CheckStringEmpty(ppd).split(' ');
        splits.shift();
        return splits.join(' ');
    };

    //2022.11.04
    GetGovSchoolDetail = async (_SchoolName = '', _profile = null) => {
        if (_SchoolName === '')  // || _CountryState === undefined || _StateArea === undefined)
            return null;

        let _CountryState = '';
        let _StateArea = '';
        if (String(_SchoolName).includes(' - ')) {
            let splits = String(_SchoolName).split(' - ');
            if (splits[1] !== undefined)
                _CountryState = splits[1];
            if (splits[2] !== undefined)
                _StateArea = splits[2];
        }

        if (_StateArea === '') {
            if (_profile !== null) {
                if (_profile['DistrictArea'] !== undefined) {
                    let temp = String(_profile['DistrictArea']).split(' ');
                    temp.shift();
                    _StateArea = temp.join(' ');
                }
                if (_profile['CountryState'] !== undefined) {
                    _CountryState = String(_profile['CountryState']);
                }
            }
        }

        await fetch(GlobalSetting.ApiUrl + 'Api/LiveQuiz/GovernmentSchool/SearchByParams',
            // Api/LiveQuiz/GovernmentSchool/SearchByParams
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    SchoolName: _SchoolName,
                    CountryState: _CountryState,
                    StateArea: _StateArea
                })
            })
            .then(res => res.json())
            .then(data => {
                // success = data.success;
                if (this.props.isDevMode)
                    if (data.success)
                        console.log('api - participation - sync (success)\n' + JSON.stringify(data));
                    else
                        console.log('Error', 'api - participation - sync (failed)\n' + JSON.stringify(data));
                if (data.success) {
                    return JSON.parse(JSON.stringify(data.data));
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - participation - sync (failed)\n' + error.message);
                return null;
            });
    }

    //2022.06.13 - Hidden School Statistic Report - Ui
    processHiddenSchoolStatisticReport = async () => {
        const _List = this.state.FinalRankingList;

        let _Schools = [];
        _List.forEach((item, index, arr) => {

            // if (item.hasOwnProperty('Profile')) {
            //     if (item.Profile !== null) {
            //         if (item.Profile.hasOwnProperty('School')) {
            //             if (item.Profile.School !== null) {
            //                 const stu_school = String(item.Profile.School);

            //             }
            //         }
            //     }
            // }

            //2023.10.26
            let school = null;
            let schoolName = CheckObjectStringEmpty(item, 'School');

            // let schoolName = '';
            // const schoolNameText = String(item.School);
            // if (
            //     item === null || item === undefined
            //     || item.School === null || item.School === undefined
            //     || schoolNameText.toLowerCase() === 'null' || schoolNameText.toLowerCase() === 'undefined'
            //     || schoolNameText.length === 0 || schoolNameText === '-'
            // ) {
            //     schoolName = '';
            // }
            // else {
            //     schoolName = schoolNameText;
            // }

            //2022.11.07
            // if (schoolName.length === 0 || schoolName === undefined || schoolName === '')
            if (schoolName === '' || schoolName === '-')
                schoolName = 'OTHER';

            if (schoolName !== '') {
                let schoolIndex = schoolName.length > 0 ? _Schools.findIndex(x => String(x.Name).toLowerCase() === schoolName.toLowerCase()) : -1;
                if (schoolIndex < 0) {
                    _Schools.push({
                        Kod: '',                    //gov
                        Name: schoolName,           //temp, gov
                        PPD: '',                    //gov
                        District: '',               //gov
                        Gender_Male: 0,
                        Gender_Female: 0,
                        Gender_Other: 0,
                        Race_Malay: 0,
                        Race_Chinese: 0,
                        Race_Indian: 0,
                        Race_Other: 0,
                        Join_Student: 0,
                        Base_Student: 0,            //gov
                        Base_Teacher: 0,            //gov
                    });
                    schoolIndex = _Schools.length - 1;
                    school = _Schools[schoolIndex];
                }
                else {
                    school = _Schools[schoolIndex];
                }

                if (school !== null) {
                    // //School details from gov list.
                    // if (String(school['Kod']).length <= 0) {
                    //     const SchoolListWithDetails = this.props.schoolListWithDetails;
                    //     if (SchoolListWithDetails.length > 0) {
                    //         // const school_list_index = SchoolListWithDetails.findIndex(x => String(x.Name).toLowerCase() === String(school.Name).toLowerCase());

                    //         //2022.11.07
                    //         let _school = String(school.Name);
                    //         if (_school.length === 0 || _school === undefined || _school === '')
                    //             _school = 'OTHER';
                    //         const school_list_index = _school.includes(' - ') ?
                    //             SchoolListWithDetails.findIndex(x =>
                    //                 _school.includes(String(x.Name))
                    //                 && _school.includes(String(x.NationalState)) && _school.includes(this.GetDistrict(String(x.PPD)))
                    //             )
                    //             :
                    //             SchoolListWithDetails.findIndex(x => _school.includes(String(x.Name)));

                    //         if (school_list_index > -1) {
                    //             school['Kod'] = SchoolListWithDetails[school_list_index].Kod;
                    //             school['Name'] = SchoolListWithDetails[school_list_index].Name;
                    //             school['PPD'] = SchoolListWithDetails[school_list_index].PPD;
                    //             school['District'] = SchoolListWithDetails[school_list_index].NationalState;
                    //             school['Base_Student'] = SchoolListWithDetails[school_list_index].Student;
                    //             school['Base_Teacher'] = SchoolListWithDetails[school_list_index].Teacher;
                    //         }
                    //     }
                    // }

                    //Join.
                    school['Join_Student'] = Number(school['Join_Student']) + 1;

                    //Race.
                    const _race = String(item.Race).toLowerCase();
                    switch (_race) {
                        case 'chinese': school['Race_Chinese'] = Number(school['Race_Chinese']) + 1; break;
                        case 'malay': school['Race_Malay'] = Number(school['Race_Malay']) + 1; break;
                        case 'indian': school['Race_Indian'] = Number(school['Race_Indian']) + 1; break;
                        default: school['Race_Other'] = Number(school['Race_Other']) + 1; break;
                    }

                    //Gender
                    const _gender = String(item.Gender).toLowerCase();
                    const isMale = _gender.includes('male') ? (_gender === 'male' ? true : false) : null;
                    if (isMale === null) {
                        school['Gender_Other'] = Number(school['Gender_Other']) + 1;
                    }
                    else {
                        if (isMale)
                            school['Gender_Male'] = Number(school['Gender_Male']) + 1;
                        else
                            school['Gender_Female'] = Number(school['Gender_Female']) + 1;
                    }

                    //update to array.
                    _Schools[schoolIndex] = school;
                }
            }
        });

        //sort asc.
        // _Schools.sort((a, b) => String(a.Kod) - String(b.Kod));
        _Schools.sort(function (a, b) {
            if (String(a.Name) > String(b.Name)) {
                return 1;
            }
            if (String(a.Name) < String(b.Name)) {
                return -1;
            }
            return 0;
        });
        await Delay(500);

        //push Other to last item.
        const otherIndex = _Schools.findIndex(x => String(x.Name).toLowerCase() === 'other');
        if (otherIndex > -1) {
            const popped = _Schools.splice(otherIndex, 1)[0];
            _Schools.push(popped);
        }
        await Delay(500);

        //School details from gov list.
        _Schools.forEach((item, index, arr) => {
            let school = item;  // _Schools[index];
            // if (String(school['Kod']).length <= 0) {
            const SchoolListWithDetails = this.props.schoolListWithDetails;
            if (SchoolListWithDetails.length > 0) {
                // const school_list_index = SchoolListWithDetails.findIndex(x => String(x.Name).toLowerCase() === String(school.Name).toLowerCase());

                // //2022.11.07
                // let _school = String(school.Name);
                // if (_school.length === 0 || _school === undefined || _school === '')
                //     _school = 'OTHER';

                const _school = CheckObjectStringEmpty(school, 'Name');
                const school_list_index = _school.includes(' - ') ?
                    SchoolListWithDetails.findIndex(x =>
                        _school.includes(String(x.Name))
                        && _school.includes(String(x.NationalState))
                        && _school.includes(this.GetDistrict(String(x.PPD)))
                    )
                    :
                    SchoolListWithDetails.findIndex(x => _school.includes(String(x.Name)));

                if (school_list_index > -1) {
                    school['Kod'] = SchoolListWithDetails[school_list_index].Kod;
                    school['Name'] = SchoolListWithDetails[school_list_index].Name;
                    school['PPD'] = SchoolListWithDetails[school_list_index].PPD;
                    school['District'] = SchoolListWithDetails[school_list_index].NationalState;
                    school['Base_Student'] = SchoolListWithDetails[school_list_index].Student;
                    school['Base_Teacher'] = SchoolListWithDetails[school_list_index].Teacher;
                }
            }
            // }
            _Schools[index] = school;
        });


        //html.
        let _htmlStrings = "<table id='School-Statistic-Report' padding='10'><thead>";
        _htmlStrings += "<tr><th rowspan='2'>BIL</th> <th rowspan='2'>KODSEKOLAH</th> <th rowspan='2'>SEKOLAH</th> <th rowspan='2'>PPD</th> <th rowspan='2'>DAERAH/NEGERI/NEGARA</th>";
        _htmlStrings += "<th colspan='3'>JANTINA</th>";
        _htmlStrings += "<th colspan='4'>BANGSA</th>";
        _htmlStrings += "<th colspan='3'>JUMLAH</th>";
        _htmlStrings += "</tr><tr>";
        _htmlStrings += "<th>L</th> <th>P</th> <th>T/A</th> <th>MELAYU</th> <th>CINA</th> <th>INDIA</th> <th>LAIN-LAIN</th>";
        _htmlStrings += "<th>PENYERTA</th> <th>MURID</th> <th>GURU</th>";
        _htmlStrings += "</thead><tbody>";

        let total_gender_male = 0;
        let total_gender_female = 0;
        let total_gender_other = 0;
        let total_race_chinese = 0;
        let total_race_malay = 0;
        let total_race_indian = 0;
        let total_race_other = 0;
        let total_joined = 0;
        let total_student = 0;
        let total_teacher = 0;

        _Schools.forEach((school, index, arr) => {
            _htmlStrings += "<tr height='25'>";
            _htmlStrings += "<td>" + String(index + 1) + "</td>";
            _htmlStrings += "<td>" + String(school['Kod']) + "</td>";
            _htmlStrings += "<td>" + String(school['Name']) + "</td>";
            _htmlStrings += "<td>" + String(school['PPD']) + "</td>";
            _htmlStrings += "<td>" + String(school['District']) + "</td>";
            _htmlStrings += "<td>" + String(school['Gender_Male']) + "</td>";
            _htmlStrings += "<td>" + String(school['Gender_Female']) + "</td>";
            _htmlStrings += "<td>" + String(school['Gender_Other']) + "</td>";
            _htmlStrings += "<td>" + String(school['Race_Malay']) + "</td>";
            _htmlStrings += "<td>" + String(school['Race_Chinese']) + "</td>";
            _htmlStrings += "<td>" + String(school['Race_Indian']) + "</td>";
            _htmlStrings += "<td>" + String(school['Race_Other']) + "</td>";
            _htmlStrings += "<td>" + String(school['Join_Student']) + "</td>";
            _htmlStrings += "<td>" + String(school['Base_Student']) + "</td>";
            _htmlStrings += "<td>" + String(school['Base_Teacher']) + "</td>";
            _htmlStrings += "</tr>";

            total_gender_male += Number(school['Gender_Male']);
            total_gender_female += Number(school['Gender_Female']);
            total_gender_other += Number(school['Gender_Other']);
            total_race_chinese += Number(school['Race_Chinese']);
            total_race_malay += Number(school['Race_Malay']);
            total_race_indian += Number(school['Race_Indian']);
            total_race_other += Number(school['Race_Other']);
            total_joined += Number(school['Join_Student']);
            total_student += Number(school['Base_Student']);
            total_teacher += Number(school['Base_Teacher']);
        });
        await Delay(500);

        _htmlStrings += "<tr height='25'><td colspan='5' align='center'><b>JUMLAH</b></td><td><b>" + total_gender_male + "</b></td><td><b>" + total_gender_female + "</b></td><td><b>" + total_gender_other + "</b></td>";
        _htmlStrings += "<td><b>" + total_race_malay + "</b></td><td><b>" + total_race_chinese + "</b></td><td><b>" + total_race_indian + "</b></td><td><b>" + total_race_other + "</b></td>";
        _htmlStrings += "<td><b>" + total_joined + "</b></td><td><b>" + total_student + "</b></td><td><b>" + total_teacher + "</b></td>";
        _htmlStrings += "</tr></tbody></table>";

        this.setState({
            Hidden_School_Statistic_Report: _htmlStrings,
        });
    }

    convertTime = (_timeInSec, _backUpTimeInSec = 0) => {
        // let minutes = (_timeInSec / 60).toFixed(3).split('.')[0];
        // let seconds = (_timeInSec % 60).toFixed(0);

        //2022.06.13
        if (_timeInSec === null && _timeInSec === undefined) {
            if (_backUpTimeInSec > 0)
                _timeInSec = _backUpTimeInSec;
        }
        else {
            if (Number(_timeInSec) <= 0 && _backUpTimeInSec > 0)
                _timeInSec = _backUpTimeInSec;
        }
        let hours = (Number(_timeInSec) / 3600).toFixed(3).split('.')[0];
        let secondsAfterHours = hours > 0 ? Number(_timeInSec) - (Number(hours) * 3600) : Number(_timeInSec);
        let minutes = (secondsAfterHours / 60).toFixed(3).split('.')[0];
        let seconds = (secondsAfterHours % 60).toFixed(0);

        return (minutes + ' m ' + (seconds.length < 2 ? '0' + seconds : seconds) + ' s');
    }

    //2020.07.18
    OpenResults = (_uid, _pname) => {
        this.setState({
            resultDataDetails: '',
            scrollPosition: window.pageYOffset,
        });

        let date = moment(this.state.selectedDate); //room id = ticks
        let _resultDataHtml = '';
        let _qsResults = '';
        let _correctQty = 0;

        let targetPlayerResults = this.state.FinalRankingList.find(x => String(x.Uid) === String(_uid));
        if (targetPlayerResults.Results.length > 0) {
            targetPlayerResults.Results.map((data, key) => {
                // _qsResults += "<tr><td align='right'><b>Q " + (key + 1) + "</b></td><td align='left'>"
                //     + (data.result === '✔' ? data.result : data.result) + "</td></tr>";

                //2022.06.17
                let _pickedAnswer = AtoZ[Number(targetPlayerResults.SelectedAnswers[key])];
                if (_pickedAnswer === null || _pickedAnswer === undefined)
                    _pickedAnswer = '-';

                //2021.12.30
                _qsResults += "<tr><td align='right'>"
                    + '(' + this.state.QuizQuestions[key].Answer + ') '
                    + "<b><a href='#" + (key + 1) + "' id='" + (key + 1) + "' onclick='return false;'>Q "
                    + (key + 1) + "</a></b></td><td align='left'>"
                    + (data.result === '✔' ? data.result : data.result)
                    // + ' (' + AtoZ[Number(targetPlayerResults.SelectedAnswers[key])] + ')'
                    + ' (' + _pickedAnswer + ')'
                    + "</td></tr>";

                if (data.result === '✔')
                    _correctQty += 1;
                return null;
            });
        }

        _resultDataHtml += "<table cellpadding='15' border='1' width='100%'><tr><td colspan='3' style='padding: 0px 10px 10px 10px;'>";
        _resultDataHtml += "<table width='100%' border='0'><tr>"
            + "<td align='center' colspan='2' height='45' style='color:blue;font-weight:bold;'>" + _pname + "</td></tr><tr>"
            + "<td align='left' width='50%'>Room Code: " + this.state.selectedRoomCode + "</td>"
            + "<td align='right' width='50%' style='font-size: 15px;'>" + date.format("LL") + " (" + date.format("YYYY-MM-DD") + ")</td></tr><tr>"
            + "<td align='left'>Standard " + this.state.RoomDetailArrays[this.state.selectedRoomIndex].Grade + "</td>"
            + "<td align='right'>" + this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject + "</td></tr>"
            + "<tr><td align='center' colspan='2'><b>" + this.state.RoomDetailArrays[this.state.selectedRoomIndex].RoomTitle + "</b></td></tr>"
            // + "<tr><td align='center' colspan='2'><b>Total (" + this.state.RoomDetailArrays[this.state.selectedRoomIndex].QnQty + ")</b></td></tr>"
            // + "<tr><td align='right' style='color:magenta;font-weight:bold;'>Correct (" + _correctQty + ")&nbsp;</td>"
            // + "<td align='left' style='color:red;font-weight:bold;'>&nbsp;Wrong (" + (targetPlayerResults.length - _correctQty) + ")</td></tr>"
            + "<tr><td colspan='2' align='center' style='font-weight:bold;'><span style='color:magenta'>Correct (" + _correctQty + ")</span>&nbsp;&nbsp;&nbsp;"
            + "<span style='color:red'>Wrong (" + (this.state.RoomDetailArrays[this.state.selectedRoomIndex].QnQty - _correctQty) + ")</span>&nbsp;&nbsp;&nbsp;"
            + "Total (" + this.state.RoomDetailArrays[this.state.selectedRoomIndex].QnQty + ")</b></td></tr>"
            + "</table>";
        _resultDataHtml += "</td></tr><tr><td align='right' width='50%'>Question</td>"
            + "<td width='50%' align='left'>Result</td></tr>";
        _resultDataHtml += _qsResults + "</table>";

        this.setState({
            resultDataDetails: _resultDataHtml,
            ToggleResultDetails: true,
        });
    }

    // //2021.01.29
    // BackToMenu = () => {
    //     // this.props.SetStage(this.props.StageMode.Begin);
    //     sessionStorage.setItem('redirectRequired', this.props.StageMode.Begin);
    //     window.location.reload();
    // }

    //2021.03.01
    // SaveRoomListToFile = () => {
    SetRoomListTable = async () => {
        let roomArrays = this.state.RoomDetailArrays;
        if (roomArrays.length > 0) {

            var htmls = "<table id='Room-List-table' padding={'10'}><tr>"
                + "<td>#</td><td>Room Code</td><td>Subject</td>"
                + "<td>Grade</td><td>Room</td><td>Total Question</td>"
                + "<td>Duration</td>"
                + "</tr>";
            roomArrays.map((data, key) => {
                if (data !== null)
                    htmls += "<tr>"
                        + "<td>" + (key + 1) + "</td><td>" + data.RoomCode + "</td><td>" + data.Subject + "</td>"
                        + "<td>" + data.Grade + "</td><td>" + data.RoomTitle + "</td><td>" + data.QnQty + "</td>"
                        + "<td>" + this.GetDurationInText(data.Duration) + "</td>"
                        + "</tr>";
                return null;
            });
            htmls += "</table>";

            this.setState({
                Hidden_RoomList_table: htmls,
            });

        }
        else {
            // alert('There is no room info to be save.');
        }
    }

    //2021.03.01
    getNameOfRoomList = () => {
        return "RoomList_" + this.state.liveQuizDate;
    }

    //2021.09.13 - Load quiz questions.
    LoadQuizQuestions = async () => {
        if (this.state.RoomDetailArrays.length <= 0 || this.state.selectedRoomIndex < 0)
            return null;

        let roomDetail = this.state.RoomDetailArrays[this.state.selectedRoomIndex];

        let _questions = [];
        let retryAgain = false;
        let retryCounter = 0;

        do {
            retryAgain = false;
            
            //2021.09.30 - ravamped.
            if (CheckObjectNullValue(roomDetail, 'QuestionSetUniqueId') !== null) {

                // //fetch from new RTDB location.
                // await this.getRoomQuestionPath_QuestionSet_V1(roomDetail.QuestionSetUniqueId)
                //     .once('value', snapshot => {
                //         if (snapshot.exists()) {
                //             // let questions = snapshot.val();
                //             let questions = _(snapshot.val()).values().value();
                //             // console.log(questions.length + '\n\n' + JSON.stringify(questions));

                //             if (questions.hasOwnProperty('ExtraContent')) {
                //                 delete questions.ExtraContent;
                //             }
                //             questions = questions.filter((el) => { return (el !== null && el !== undefined); });    //remove null or empty item.

                //             _questions = questions;
                //             // console.log(questions.length + '\n\n' + JSON.stringify(questions));
                //         }
                //     })
                //     .catch(() => {
                //         retryAgain = true;
                //     });

                //2023.12.20 - emergency fix during Annual Leave - revamped to fetch root via api === start ===//
                let questionSetRoot = null;
                let success = false;
                let errorMessage = '';
                let done = false;
                await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/QuestionSet/Root/Get/'
                    + 0 + '/'
                    + 0 + '/'
                    + CheckObjectStringEmpty(roomDetail, 'QuestionSetUniqueId'),
                    // Api/LearningCentre/Quiz/QuestionSet/Root/Get/{organizerId}/{authorId}/{uniqueId}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (this.props.isDevMode)
                            console.log('LoadQuizQuestions (api)', JSON.stringify(data));
                        success = data.success;
                        if (success)
                            questionSetRoot = data.data;
                        else
                            errorMessage = 'api-get / qSet (failed)\n' + JSON.stringify(data);
                        done = true;
                    })
                    .catch(error => {
                        errorMessage = 'api-get / qSet (failed)\n' + error.message;
                        done = true;
                    });
                await DelayUntil(() => done === true);
                if (errorMessage !== '') {
                    retryAgain = true;
                    if (this.props.isDevMode)
                        console.log('LoadQuizQuestions (api) (error)', errorMessage);
                }
                if (questionSetRoot !== null) {
                    if (CheckObjectNullValue(questionSetRoot, 'questions') !== null)
                        _questions = CapitalizeJsonKeys(questionSetRoot.questions);
                    else if (CheckObjectNullValue(questionSetRoot, 'Questions') !== null)
                        _questions = CapitalizeJsonKeys(questionSetRoot.Questions);

                    let settings = null;
                    if (CheckObjectNullValue(questionSetRoot, 'settings') !== null)
                        settings = CapitalizeJsonKeys(questionSetRoot.settings);
                    else if (CheckObjectNullValue(questionSetRoot, 'Settings') !== null)
                        settings = CapitalizeJsonKeys(questionSetRoot.Settings);
                    if (settings !== null) {
                        roomDetail.QnQty = CheckObjectNumber(settings, 'TotalQuestion', roomDetail.QnQty);
                        let roomDetailArrays = this.state.RoomDetailArrays;
                        roomDetailArrays[this.state.selectedRoomIndex] = roomDetail;
                        this.setState({
                            questionSetModal: settings,
                            RoomDetailArrays: roomDetailArrays,
                        });
                    }
                }
            }
            else {
                //2021.09.30 === older method/questions fetch location before 2021.09.30
                let tmp_questions = [];
                let retry = false;
                await this.questionSetPath(this.state.selectedRoomIndex)
                    .once('value', snapshot => {
                        if (snapshot.exists()) {
                            // let questions = snapshot.val();
                            let questions = _(snapshot.val()).values().value();
                            // console.log(questions.length + '\n\n' + JSON.stringify(questions));

                            // let countIsQs = 0;
                            // // let countIsNotQs = 0;
                            // questions.map((data, key) => {
                            //     if (typeof (data) === 'object')
                            //         countIsQs++;
                            //     // if (typeof (data) === 'string')
                            //     //     countIsNotQs++;
                            // });
                            // // console.log('qs = ' + countIsQs);   // + '\ntext = ' + countIsNotQs);

                            questions = questions.filter((el) => { return (typeof (el) === 'object'); });

                            // if (countIsQs === this.state.RoomDetailArrays[this.state.selectedRoomIndex].QnQty) {
                            tmp_questions = questions;
                            // console.log(questions.length + '\n\n' + JSON.stringify(questions));
                            // }
                        }
                    })
                    .catch(() => {
                        retry = true;
                    });
                _questions = tmp_questions;
                retryAgain = retry;
            }

            retryCounter++;
            await Delay(1000);

        } while (retryAgain && retryCounter < 5);

        this.setState({
            QuizQuestions: _questions,
        }, () => {
            // console.log(JSON.stringify(this.state.QuizQuestions));
        });
    }

    // //2021.09.13 - recount score/result
    // ReCheckAndReCalculateScoreResult = async (_UpdatedFinalRankingList) => {
    //     //Load Quiz Questions.
    //     await this.LoadQuizQuestions();

    //     //ReCalculate.
    //     for (var i = 0; i < _UpdatedFinalRankingList.length; i++) {

    //     }

    //     return _UpdatedFinalRankingList;
    // }

    // chunkSubstr = (str, size) => {
    //     const numChunks = Math.ceil(str.length / size)
    //     const chunks = new Array(numChunks)

    //     for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    //         chunks[i] = str.substr(o, size)
    //     }

    //     return chunks
    // }

    //2022.06.13
    GetRoomInfo = async (_roomCode) => {
        let roomInfo = null;
        await this.props.dbFireStore
            .collection("LiveQuiz_UniqueRoomCode")
            .where('RoomCode', '==', Number(_roomCode))
            .limit(1)
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                        dataArray[dataArray.length - 1].Id = doc.id;    //important
                    });
                    if (dataArray.length > 0) {
                        roomInfo = dataArray;
                    }
                }
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(roomInfo));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('load room info (error) =\n' + error);
            });
        return roomInfo;
    }

    render = () => {
        if (!this.props.user) {
            return <Redirect link="/login" />;   //back to login screen.
        }
        else {
            return (
                <>
                    <div style={styleJSX.contentStyle} id='top'>
                        <table width='100%' border={0}>
                            <tbody>
                                {/* <tr>
                            <td valign='middle'><div style={{ textAlign: 'center', fontSize: 20, }}>Calender</div></td>
                            <td>&nbsp;&nbsp;</td>
                            <td valign='middle'><font size={5}>Live Quiz : Data Browsing : Simple Ui</font>
                            </td>
                        </tr> */}
                                <tr>
                                    <td valign='top' width='350px'>
                                        <table width='100%' border='0' cellSpacing='0' cellPadding='5'>
                                            <tbody>
                                                <tr>
                                                    <td valign='middle'>
                                                        <div style={{
                                                            textAlign: 'center', fontSize: 20, color: 'white', backgroundColor: 'cornflowerblue',
                                                        }}>Calender</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Calendar
                                                            locale="en-US"
                                                            showNeighboringMonth={false}

                                                            onChange={this.onCalenderChange}
                                                            value={this.state.selectedDate}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr><td>&nbsp;</td></tr>
                                                {
                                                    this.state.RoomDetailArrays.length > 0 ?
                                                        <>
                                                            <tr>
                                                                <td align="right" width='350'>
                                                                    {/* <ReactHTMLTableToExcel
                                                                        //2021.03.01
                                                                        className="buttonStyleDownloadExcel"
                                                                        table="Room-List-table"
                                                                        filename={this.getNameOfRoomList()}
                                                                        sheet="Sheet"
                                                                        buttonText="Save Rooms List (XLS)" /> */}
                                                                    <button
                                                                        className="buttonStyleDownloadExcel"
                                                                        type='button'
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => this.DownloadReportAsXLSX('Room-List-table', 'RoomList_' + this.state.liveQuizDate, false)}
                                                                        disabled={this.state.Hidden_RoomList_table.length === 0}
                                                                    >Save Rooms List (XLSX)</button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {/* <ReactHTMLTableToExcel
                                                                        //2021.07.29
                                                                        className="buttonStyleDownloadExcel"
                                                                        table="Room-List-TimeTable"
                                                                        filename={this.GetNameOfRoomListTimeTable()}
                                                                        sheet="Sheet"
                                                                        buttonText="Save Rooms' TimeTable (XLS)" /> */}
                                                                    <button
                                                                        className="buttonStyleDownloadExcel"
                                                                        type='button'
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => this.DownloadReportAsXLSX('Room-List-TimeTable', 'RoomList_TimeTable_' + this.state.liveQuizDate, false)}
                                                                        disabled={this.state.Hidden_RoomList_TimeTable.length === 0}
                                                                    >Save Rooms' TimeTable (XLSX)</button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <button
                                                                        className='btn btn-secondary'
                                                                        style={{ width: '100%' }}
                                                                        onClick={this.PreviewRoomListTimeTable}
                                                                    >Preview Rooms' TimeTable (HTML)</button>
                                                                </td>
                                                            </tr>
                                                            <tr hidden={true}>
                                                                {/* <tr> */}
                                                                <td>
                                                                    <div
                                                                        id='Room-List-table-hidden'
                                                                        hidden={true}
                                                                        dangerouslySetInnerHTML={{ __html: this.state.Hidden_RoomList_table }}
                                                                    />
                                                                    <div
                                                                        id='Room-List-TimeTable-hidden'
                                                                        hidden={true}
                                                                        dangerouslySetInnerHTML={{ __html: this.state.Hidden_RoomList_TimeTable }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    this.state.RoomDetailArrays.length > 0 && this.state.selectedRoomIndex > -1
                                                        && this.state.FinalRankingList.length > 0
                                                        && (this.state.isJoinedPlayersLoaded || this.state.isRankingListLoaded || this.state.IsFinalRankingListLoaded)
                                                        ?
                                                        uiModeState !== UiMode.Begin && uiModeState !== UiMode.ShowRoomList ?
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        <div>
                                                                            {/* <ReactHTMLTableToExcel
                                                                                //2020.07.16
                                                                                className="buttonStyleDownloadExcel"
                                                                                table="Ranking-List-with-Quiz-Result"
                                                                                filename={this.getNameOfReport()}
                                                                                sheet="Sheet"
                                                                                buttonText="Save Room's Ranking List Report (XLS)"
                                                                            />
                                                                            <br /> */}
                                                                            <button
                                                                                className="buttonStyleDownloadExcel"
                                                                                type='button'
                                                                                style={{ width: '100%' }}
                                                                                // onClick={this.DownloadRankingListReportAsXLSX}
                                                                                onClick={() => this.DownloadReportAsXLSX('Ranking-List-with-Quiz-Result_hidden', 'Ranking-List-with-Quiz-Result')}
                                                                                // disabled={this.state.rankingListParticipantResultsForXLSX_fileName === ''}
                                                                                disabled={this.state.Hidden_Ranking_List_With_Results.length === 0}
                                                                            >Save Room's Ranking List Report (XLSX)</button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <button
                                                                            className='btn btn-secondary'
                                                                            style={{ width: '100%' }}
                                                                            onClick={this.PrintDiv}
                                                                            disabled={this.state.Hidden_Ranking_List_With_Results.length === 0}
                                                                        >Print Room's Ranking List</button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div>
                                                                            {/* <ReactHTMLTableToExcel
                                                                                //2020.07.16
                                                                                className="buttonStyleDownloadExcel"
                                                                                table="School-Statistic-Report"
                                                                                filename={this.getNameOfReport('School-Statistic-Report')}
                                                                                sheet="Sheet"
                                                                                buttonText="Save Room's School Statistic Report (XLS)"
                                                                                disabled={this.state.Hidden_School_Statistic_Report.length === 0}
                                                                            /> */}
                                                                            <button
                                                                                className="buttonStyleDownloadExcel"
                                                                                type='button'
                                                                                style={{ width: '100%' }}
                                                                                // onClick={this.DownloadRankingListReportAsXLSX}
                                                                                onClick={() => this.DownloadReportAsXLSX('School-Statistic-Report_hidden', 'School-Statistic-Report')}
                                                                                disabled={this.state.Hidden_School_Statistic_Report.length === 0}
                                                                            >Save Room's School Statistic Report (XLSX)</button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <button
                                                                            className='btn btn-secondary'
                                                                            style={{ width: '100%' }}
                                                                            onClick={() => this.PrintDiv('School-Statistic-Report')}
                                                                            disabled={this.state.Hidden_School_Statistic_Report.length === 0}
                                                                        >Print Room's School Statistic Report</button>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td valign='top'>
                                        <table style={styleJSX.tableStyle}>
                                            <tbody>
                                                <tr>
                                                    <td valign='middle'>
                                                        <span style={{ fontSize: 24 }}>Live Quiz : Data Browsing : Simple Ui</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styleJSX.tableTD}>
                                                        <span style={{ marginBottom: 10, }}>DATE [ {this.state.selectedDate.toLocaleDateString()} ]</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styleJSX.tableTD}>
                                                        <Button
                                                            variant='contained' color='primary'
                                                            disabled={!this.state.IsButtonEnabled}
                                                            style={uiModeState === UiMode.ShowRoomList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                            onClick={() => this.LoadRoomListAndDetails(UiMode.ShowRoomList)}
                                                        >Rooms Detail</Button>
                                                        &nbsp;&nbsp;
                                                        <Button
                                                            variant='contained' color='primary'
                                                            disabled={!this.state.IsButtonEnabled}
                                                            style={uiModeState === UiMode.ShowFinalRankingList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                            // onClick={() => this.LoadRoomListForFinalRankingList()}
                                                            onClick={() => this.LoadRoomList(UiMode.ShowFinalRankingList)}
                                                        >Ranking List</Button>
                                                        {/* &nbsp;&nbsp; */}
                                                    </td>
                                                </tr>
                                                {
                                                    // uiModeState === UiMode.ShowRoomList || uiModeState === UiMode.ShowJoinedPlayers || uiModeState === UiMode.ShowRankingList ?
                                                    uiModeState !== UiMode.Begin ?
                                                        //Rooms' List.
                                                        this.state.IsRoomListLoaded ?
                                                            <>
                                                                <tr><td style={styleJSX.tableTD}>Room List</td></tr>
                                                                <tr>
                                                                    <td style={styleJSX.tableTD}>
                                                                        {
                                                                            this.state.RoomDetailArrays.length > 0 ?
                                                                                this.state.RoomDetailArrays.map((data, index) => {
                                                                                    if (data !== null) {
                                                                                        let _group = this.GetGroup(data);
                                                                                        return (
                                                                                            <>
                                                                                                <Button
                                                                                                    variant='contained' color='primary'
                                                                                                    disabled={!this.state.IsButtonEnabled}
                                                                                                    style={
                                                                                                        index === this.state.selectedRoomIndex ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal
                                                                                                    }
                                                                                                    onClick={() => this.SelectRoom(data.RoomId)}
                                                                                                    id={data.RoomId}
                                                                                                >{data.RoomTitle}<br /><b>{data.RoomCode}</b><i>{_group !== null ? <><br />{_group.label}</> : <></>}</i></Button>&nbsp;&nbsp;
                                                                                                {
                                                                                                    ((index + 1) % 4) === 0 ?
                                                                                                        <><p height={25}></p></>
                                                                                                        : null
                                                                                                }
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return null;
                                                                                    }
                                                                                })
                                                                                :
                                                                                // <font style={styleJSX.tableTDwhite}>No Room is created for this day.</font>
                                                                                <div style={{ border: '1px solid gray', width: '100%', padding: 10 }} >No Room is created for this day.</div>
                                                                        }
                                                                        &nbsp;
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    //Room's Details.
                                                                    this.state.IsShowRoomDetails ?
                                                                        this.state.IsRoomDetailsLoaded ?
                                                                            <>
                                                                                <tr><td id='RoomDetailTitle'><hr /></td></tr>
                                                                                <tr>
                                                                                    <td style={styleJSX.tableTDwhite}>
                                                                                        <table width='100%'>
                                                                                            {this.state.RoomDetailUiComponent}
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                            :
                                                                            null
                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    //#region not using atm.

                                                                    // //Joined Players List.
                                                                    // this.state.isShowJoinedPlayers ?
                                                                    //     this.state.isJoinedPlayersLoaded ?
                                                                    //         <tr>
                                                                    //             <td style={styleJSX.tableTDwhite}>
                                                                    //                 <div id='Joined-Player-List'>
                                                                    //                     <table>
                                                                    //                         <tr>
                                                                    //                             <td colSpan='11' style={{ color: 'blue', }}>
                                                                    //                                 <table width={'100%'} border={0}>
                                                                    //                                     <tr>
                                                                    //                                         <td valign={'middle'}>
                                                                    //                                             <div>
                                                                    //                                                 Joined Player List
                                                                    //                                                 <font size={3} color={'#ffffff'} > (Total Student: {this.state.JoinedPlayerArrays.length} persons)</font>
                                                                    //                                             </div>
                                                                    //                                         </td>
                                                                    //                                         <td align={'right'} valign={'middle'}>
                                                                    //                                             <div style={{
                                                                    //                                                 width: 185, height: 25, backgroundColor: '#123456',
                                                                    //                                                 fontSize: 12, color: '#ffffff',
                                                                    //                                                 textAlign: 'left', padding: 5,
                                                                    //                                             }}
                                                                    //                                             >* Student with older app version</div>
                                                                    //                                         </td>
                                                                    //                                     </tr>
                                                                    //                                 </table>
                                                                    //                             </td>
                                                                    //                         </tr>
                                                                    //                         <tr style={{ color: 'white', fontWeight: 'normal', backgroundColor: 'magenta' }}>
                                                                    //                             <td valign='bottom' style={{ paddingLeft: 15, }}>Rank</td>
                                                                    //                             <td width={50}>&nbsp;</td>
                                                                    //                             <td valign='bottom'>Name</td>
                                                                    //                             <td width={50}>&nbsp;</td>
                                                                    //                             <td valign='bottom'>Scores</td>
                                                                    //                             <td width={50}>&nbsp;</td>
                                                                    //                             <td valign='bottom'>Time<br />Consumed</td>
                                                                    //                             <td width={50}>&nbsp;</td>
                                                                    //                             <td valign='bottom'>Is Quiz<br />Completed</td>
                                                                    //                             <td width={50}>&nbsp;</td>
                                                                    //                             <td valign='bottom'>Email</td>
                                                                    //                         </tr>
                                                                    //                         {
                                                                    //                             this.state.JoinedPlayerArrays.map((data, index) => {
                                                                    //                                 return (
                                                                    //                                     <tr style={{ backgroundColor: (data.Version ? 'transparent' : '#123456') }}>
                                                                    //                                         <td valign='top' style={{ paddingLeft: 15, }}>{index + 1}{data.Version ? '' : ' *'}</td>
                                                                    //                                         <td>&nbsp;</td>
                                                                    //                                         <td valign='top'>{data.PlayerName}</td>
                                                                    //                                         <td>&nbsp;</td>
                                                                    //                                         <td valign='top'>{data.Scores}</td>
                                                                    //                                         <td>&nbsp;</td>
                                                                    //                                         <td valign='top'>{data.TimeElapsed}</td>
                                                                    //                                         <td>&nbsp;</td>
                                                                    //                                         <td valign='top'>{data.IsCompleted === 'YES' ? data.IsCompleted : <font color={'magenta'}>{data.IsCompleted}</font>} </td>
                                                                    //                                         <td>&nbsp;</td>
                                                                    //                                         <td valign='top'>{data.Email}</td>
                                                                    //                                         {/* <td>
                                                                    //                                     <button
                                                                    //                                         // style={styleJSX.buttonStyleNormal}
                                                                    //                                         onClick={() => this.fetchEmail(data.Uid)}
                                                                    //                                     >Email</button>
                                                                    //                                 </td> */}
                                                                    //                                     </tr>
                                                                    //                                 )
                                                                    //                             })
                                                                    //                         }
                                                                    //                     </table>
                                                                    //                 </div>
                                                                    //             </td>
                                                                    //         </tr>
                                                                    //         :
                                                                    //         // null
                                                                    //         <>
                                                                    //             <tr>
                                                                    //                 <td height={70}>
                                                                    //                     <ActivityIndicator size="large" color="#fff" />
                                                                    //                 </td>
                                                                    //             </tr>
                                                                    //         </>
                                                                    //     :
                                                                    //     null
                                                                    // }

                                                                    // {
                                                                    // //Ranking List.
                                                                    // this.state.isShowRankingList ?
                                                                    //     this.state.isRankingListLoaded ?
                                                                    //         <tr>
                                                                    //             <td style={styleJSX.tableTDwhite}>
                                                                    //                 <div id='Ranking-List'>
                                                                    //                     <table>
                                                                    //                         <tr>
                                                                    //                             <td colSpan='11' style={{ color: 'blue' }}>
                                                                    //                                 {/* Ranking List */}
                                                                    //                                 <table width={'100%'} border={0}>
                                                                    //                                     <tr>
                                                                    //                                         <td valign={'middle'}>
                                                                    //                                             {/* Ranking List */}
                                                                    //                                             <div>
                                                                    //                                                 Ranking List
                                                                    //                                                 <font size={3} color={'#ffffff'} > (Total Student: {this.state.RankingListArrays.length} persons)</font>
                                                                    //                                             </div>
                                                                    //                                         </td>
                                                                    //                                         <td align={'right'} valign={'middle'}>
                                                                    //                                             <div style={{
                                                                    //                                                 width: 185, height: 25, backgroundColor: '#123456',
                                                                    //                                                 fontSize: 12, color: '#ffffff',
                                                                    //                                                 textAlign: 'left', padding: 5,
                                                                    //                                             }}
                                                                    //                                             >* Student with older app version</div>
                                                                    //                                         </td>
                                                                    //                                     </tr>
                                                                    //                                 </table>
                                                                    //                             </td>
                                                                    //                         </tr>
                                                                    //                         <tr style={{ color: 'white', fontWeight: 'normal', backgroundColor: 'magenta' }}>
                                                                    //                             <td valign='bottom' style={{ paddingLeft: 15, }}>Rank</td>
                                                                    //                             <td width={50}>&nbsp;</td>
                                                                    //                             <td valign='bottom'>Name</td>
                                                                    //                             <td width={50}>&nbsp;</td>
                                                                    //                             <td valign='bottom'>Scores</td>
                                                                    //                             <td width={50}>&nbsp;</td>
                                                                    //                             <td valign='bottom'>Time<br />Consumed</td>
                                                                    //                             {/* <td width={50}>&nbsp;</td>
                                                                    //                         <td valign='bottom'>Is Quiz<br />Completed</td> */}
                                                                    //                             <td width={50}>&nbsp;</td>
                                                                    //                             <td valign='bottom'>Email</td>
                                                                    //                             {/* <td width={50}>&nbsp;</td>
                                                                    //                                 <td valign='bottom'>Duplicated<br></br>Record ({this.state.dupeQtyForFRList})</td> */}
                                                                    //                         </tr>
                                                                    //                         {
                                                                    //                             this.state.RankingListArrays.map((data, index) => {
                                                                    //                                 return (
                                                                    //                                     <tr style={{ backgroundColor: (data.Version ? 'transparent' : '#123456') }}>
                                                                    //                                         <td valign='top' style={{ paddingLeft: 15, }}>{index + 1}{data.Version ? '' : ' *'}</td>
                                                                    //                                         <td>&nbsp;</td>
                                                                    //                                         <td valign='top'>{data.PlayerName}</td>
                                                                    //                                         <td>&nbsp;</td>
                                                                    //                                         <td valign='top'>{data.Scores}</td>
                                                                    //                                         <td>&nbsp;</td>
                                                                    //                                         <td valign='top'>{data.TimeElapsed}</td>
                                                                    //                                         {/* <td>&nbsp;</td>
                                                                    //                                     <td>{data.IsCompleted === 'YES' ? data.IsCompleted : <font color={'gray'}>{data.IsCompleted}</font>} </td> */}
                                                                    //                                         <td>&nbsp;</td>
                                                                    //                                         <td valign='top'>{data.Email}</td>
                                                                    //                                         {/* <td>&nbsp;</td>
                                                                    //                                             <td valign='top' align='center'
                                                                    //                                                 style={{ backgroundColor: (data.Version ? data.Duplicated ? 'gray' : 'transparent' : '#123456') }}
                                                                    //                                             >{data.Duplicated ? '☆' : ''}</td> */}
                                                                    //                                         {/* <td>
                                                                    //                                     <button
                                                                    //                                         // style={styleJSX.buttonStyleNormal}
                                                                    //                                         onClick={() => this.fetchEmail(data.Uid)}
                                                                    //                                     >Email</button>
                                                                    //                                 </td> */}
                                                                    //                                     </tr>
                                                                    //                                 )
                                                                    //                             })
                                                                    //                         }
                                                                    //                     </table>
                                                                    //                 </div>
                                                                    //             </td>
                                                                    //         </tr>
                                                                    //         :
                                                                    //         // null
                                                                    //         <>
                                                                    //             <tr>
                                                                    //                 <td height={70}>
                                                                    //                     <ActivityIndicator size="large" color="#fff" />
                                                                    //                 </td>
                                                                    //             </tr>
                                                                    //         </>
                                                                    //     :
                                                                    //     null

                                                                    //#endregion
                                                                }
                                                            </>
                                                            :
                                                            <tr>
                                                                <td style={styleJSX.tableTD}>
                                                                    {/* <ActivityIndicator size="large" color="#fff" /> */}
                                                                    <ProgressBar animated now={100} />
                                                                </td>
                                                            </tr>
                                                        :
                                                        null
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr><td colSpan='3'><hr /></td></tr>
                                <tr>
                                    <td colSpan='3'>
                                        <table style={styleJSX.tableStyleList} id='ranking-list-table'>
                                            <tbody>
                                                {
                                                    //Final Ranking List.
                                                    this.state.RoomDetailArrays.length > 0 && this.state.IsShowFinalRankingList ?
                                                        this.state.IsFinalRankingListLoaded ?
                                                            <>
                                                                <tr>
                                                                    <td style={styleJSX.tableTD}>
                                                                        <div className='row' style={{ maxWidth: 400, alignItems: 'center', marginTop: 0 }}>
                                                                            <div className='col-4' style={{ fontSize: 18, padding: 0, paddingLeft: 18 }}>Auto Refresh</div>
                                                                            <div className='col-4' style={{ padding: 0 }}>
                                                                                <Picker
                                                                                    selectedValue={this.state.autoRefreshTimerValue}
                                                                                    style={styleJSX.pickerStyleAutoRefresh}
                                                                                    onValueChange={(itemValue, itemIndex) =>
                                                                                        this.SetAutoRefreshTimerValue(itemValue)
                                                                                    }>
                                                                                    {
                                                                                        [15, 20, 25, 30, 40, 50, 60].map((data, key) => {
                                                                                            return (
                                                                                                <Picker.Item label={data + ' seconds'} value={data} />
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Picker>
                                                                            </div>
                                                                            <div className='col-1' style={{ fontSize: 18, padding: 0 }}>({this.state.autoRefreshTimerValue - this.state.autoRefreshElapsedTime})</div>
                                                                            <div className='col-2' style={{ paddingLeft: 10 }}>
                                                                                <Switch
                                                                                    onValueChange={this.ToggleAutoRefresh}
                                                                                    value={this.state.ToggleAutoRefresh}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={styleJSX.tableTDwhite}>
                                                                        <div id='Final-Ranking-List'>
                                                                            <table width='100%' border={0}>
                                                                                <tbody>
                                                                                    {/* id='Ranking-List-with-Quiz-Result' */}
                                                                                    <tr>
                                                                                        <td colSpan='15'>
                                                                                            {/* Final Ranking List */}
                                                                                            <div>
                                                                                                Ranking List
                                                                                                <font size={3} > (Total Participants: {this.state.FinalRankingList.length} persons)</font>
                                                                                                <a href="#Final-Ranking-List"><font size={1} color={'blue'} > <u><i>focus table</i></u></font></a>
                                                                                            </div>
                                                                                            {/* <table width='100%' border={0} >
                                                                                <tr>
                                                                                    <td valign={'middle'}>
                                                                                        <div>
                                                                                            Final Ranking List
                                                                                            <font size={3} > (Total Student: {this.state.FinalRankingList.length} persons)</font>
                                                                                            <a href="#Final-Ranking-List"><font size={1} color={'blue'} > <u><i>focus table</i></u></font></a>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td align={'right'} valign={'middle'}>
                                                                                        <div style={{
                                                                                            width: 265, height: 25, backgroundColor: '#123456',
                                                                                            fontSize: 12, color: 'yellow',
                                                                                            textAlign: 'left', padding: 5,
                                                                                        }}
                                                                                        >* Student with older app version than {this.state.versionDivision}</div>
                                                                                        {
                                                                                            this.state.liveQuizDate <= this.state.versionDivisionDate ?
                                                                                                <div style={{
                                                                                                    width: 185, height: 25, backgroundColor: 'gray',
                                                                                                    fontSize: 12, color: '#ffffff',
                                                                                                    textAlign: 'left', padding: 5,
                                                                                                }}
                                                                                                >* Student with duplicated Uid</div>
                                                                                                : null
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            </table> */}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr style={{ color: 'white', fontWeight: 'normal', backgroundColor: 'magenta' }}>
                                                                                        <td valign='bottom' align='center' width={75}>Rank</td>
                                                                                        <td valign='bottom'>Name</td>
                                                                                        <td valign='bottom' width={135}>Result</td>
                                                                                        <td valign='bottom' width={190}>Time Consumed</td>
                                                                                        <td valign='bottom' align='center' width={70}>Scores</td>
                                                                                        <td valign='bottom'>Email</td>
                                                                                        {
                                                                                            this.state.liveQuizDate > this.state.versionDivisionDate ?
                                                                                                <td valign='bottom' align='center' width={85}>Logs</td>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            this.state.liveQuizDate > this.state.versionDivisionDate ?
                                                                                                <td valign='bottom' align='center' width={85}>Results</td>
                                                                                                : null
                                                                                        }
                                                                                    </tr>
                                                                                    {
                                                                                        this.state.FinalRankingList.length > 0 ?
                                                                                            this.state.FinalRankingList.map((data, index) => {
                                                                                                return (
                                                                                                    <tr style={{ backgroundColor: (data.Version ? 'transparent' : '#123456') }}>
                                                                                                        <td valign='top' align='center'>{index + 1}{data.Version ? '' : ' *'}</td>
                                                                                                        <td valign='top'>{data.PlayerName}</td>
                                                                                                        <td valign='top'>{data.CorrectQty}/{this.state.RoomDetailArrays[this.state.selectedRoomIndex].QnQty}&nbsp;(<font color='red'><b>{data.WrongQty}</b></font>)</td>
                                                                                                        <td valign='top'>{String(this.convertTime(data.TimeElapsed, Number(data.ActualTimeElapsed)) + " (" + data.TimeElapsed + ") (" + data.ActualTimeElapsed + ")")}</td>
                                                                                                        <td valign='top' align='center'>{Number(data.Scores)}</td>
                                                                                                        <td valign='top'>{String(data.Email).length > 20 ? String(data.Email).substring(0, 20) + '...' : data.Email}</td>
                                                                                                        {
                                                                                                            this.state.liveQuizDate > this.state.versionDivisionDate ?
                                                                                                                <td valign='top' align='center'>
                                                                                                                    <button
                                                                                                                        className='btn btn-info'
                                                                                                                        style={{ width: 55 }}
                                                                                                                        // style={styleJSX.buttonStyleLog}
                                                                                                                        onClick={() => this.OpenLogs(data.Uid, data.PlayerName, index + 1, data.Email, data.RoomEnterHistory)}
                                                                                                                        disabled={this.state.IsFinalRankingListLoaded === false}
                                                                                                                    >...</button>
                                                                                                                </td>
                                                                                                                : null
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.liveQuizDate > this.state.versionDivisionDate ?
                                                                                                                <td valign='top' align='center'>
                                                                                                                    <button
                                                                                                                        className='btn btn-info'
                                                                                                                        style={{ width: 55 }}
                                                                                                                        // style={styleJSX.buttonStyleLog}
                                                                                                                        onClick={() => this.OpenResults(data.Uid, data.PlayerName)}
                                                                                                                        disabled={this.state.IsFinalRankingListLoaded === false}
                                                                                                                    >...</button>
                                                                                                                </td>
                                                                                                                : null
                                                                                                        }
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <tr><td colSpan={8} align='center'>list is empty</td></tr>
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            <div
                                                                                id='Ranking-List-with-Quiz-Result_hidden'
                                                                                hidden={true}
                                                                                dangerouslySetInnerHTML={{ __html: this.state.Hidden_Ranking_List_With_Results }}
                                                                            />
                                                                            <div
                                                                                id='School-Statistic-Report_hidden'
                                                                                hidden={true}
                                                                                dangerouslySetInnerHTML={{ __html: this.state.Hidden_School_Statistic_Report }}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            :
                                                            <tr>
                                                                <td height={70}>
                                                                    <ProgressBar animated now={100} />
                                                                    <div style={{ fontSize: 20, color: 'blue', width: '100%', textAlign: 'center', marginTop: 25, }}><div dangerouslySetInnerHTML={{ __html: ProgressionStateText }} /></div>
                                                                </td>
                                                            </tr>
                                                        :
                                                        null
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <p>End of list</p> */}
                    </div>

                    <Modal show={this.state.isShowQuestionDetailHtml} onHide={() => this.setState({ isShowQuestionDetailHtml: false })} centered>
                        {/* <Modal.Header>
                        <Modal.Title>Question content</Modal.Title>
                    </Modal.Header> */}
                        <Modal.Body style={{ textAlign: 'center', alignSelf: 'center', width: '100%' }}>
                            <div
                                // style={styleJSX.qsDetailUi}
                                dangerouslySetInnerHTML={{ __html: this.state.singleQuestionDetailHtml }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => this.setState({ isShowQuestionDetailHtml: false })}
                            >Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size='lg' show={this.state.ToggleLogDataDetail} onHide={() => this.setState({ ToggleLogDataDetail: false })} centered>
                        <Modal.Header>
                            <Modal.Title>Logs</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center', alignSelf: 'center', width: '100%' }}>
                            <div width={765}
                                dangerouslySetInnerHTML={{ __html: this.state.logDataDetails }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => this.setState({ ToggleLogDataDetail: false })}
                            >Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.ToggleResultDetails} onHide={() => this.setState({ ToggleResultDetails: false })} centered>
                        <Modal.Header>
                            <Modal.Title>Results</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center', alignSelf: 'center', width: '100%' }}>
                            <div width={765}
                                onClick={this.fetchQuestion}
                                dangerouslySetInnerHTML={{ __html: this.state.resultDataDetails }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => this.setState({ ToggleResultDetails: false })}
                            >Close</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
        }
    }
}

const styleJSX = {
    tableStyle: {
        // color: 'blue',
        fontSize: 20,
        // width: 1000,
        // backgroundColor: '#2e2e2e',
        // backgroundColor: 'rgba(255, 255, 255, 0.25)',
        // padding: 50,
        // opacity: 0.5,
    },
    tableStyleList: {
        fontSize: 20,
        backgroundColor: 'white',
        color: 'black',
        width: '100%',
    },
    tableTD: {
        // padding: 10,
        // paddingLeft: 10,
        // paddingRight: 10,
        paddingBottom: 10,
    },
    // tableTDwhite: {
    //     // padding: 10,
    //     paddingLeft: 10,
    //     paddingRight: 10,
    //     // color: 'white',
    // },
    contentStyle: {
        // backgroundColor: '#000',
        // backgroundColor: 'transparent',
        // backgroundColor: 'black',
        // width: '100vh',
        // height: '2000vh',
        // margin: 50,
    },
    buttonStyleLog: {
        width: 80, //185,
        height: 25,
        borderRadius: 5,    //15,
        fontSize: 15,
    },
    buttonStyleNormal: {
        width: 185,
        // height: 65,
        // height: 85,
        borderRadius: 5,    //15,
        fontSize: 15,
        backgroundColor: '#007bff',
        color: 'white',
        // border: '1px solid black',
    },
    buttonStyleSelected: {
        width: 185,
        // height: 65,
        // height: 85,
        borderRadius: 5,    //15,
        fontSize: 15,
        backgroundColor: 'red',
        color: 'white',
    },
    qsDetailUi: {
        position: 'absolute',
        zIndex: '10',
        // color: 'blue',
        fontSize: 20,
        width: '100%',
        // height: '100%',
        // backgroundColor: '#5d5d5d',
        backgroundColor: '#282c34',
        padding: 50,
        // justifyContent: 'center',
        // alignItems: 'center',
        // top: _QsUiTopPos,
    },
    qsDetailTable: {
        width: '100%',
    },
    logsDetailUi: {
        position: 'absolute',
        zIndex: '10',
        fontSize: 20,
        width: '100%',
        // height: '100%',
        // backgroundColor: 'transparent',    
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: 50,
    },
    pickerStyle: {
        height: 30,
        width: 200,
        fontSize: 18,
        fontWeight: 'bold',
        // alignSelf: 'flex-start',
    },
    pickerStyleAutoRefresh: {
        height: 30,
        width: 120,
        fontSize: 18,
        fontWeight: 'bold',
        // alignSelf: 'flex-start',
    },
}

const UiMode = {
    Begin: 'begin',
    ShowRoomList: 'showRoomDetailsList',
    ShowJoinedPlayers: 'showJoinedPlayers',
    ShowRankingList: 'showRankingList',
    ShowFinalRankingList: 'showFinalRankingList'
}
let uiModeState = UiMode.Begin;