import React from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Row, ProgressBar } from 'react-bootstrap';
import moment from 'moment';
// import {
//     // osVersion, osName, browserName, fullBrowserVersion
//     browserName
// } from "react-device-detect";
import './PageStyle.scss';
// import { Locale, Lang } from '../../Localization/CustomLocalization.js';
import { GlobalSetting } from '../../components/GlobalSetting';
import { CheckBoolean, CheckNullValue, Delay, DelayUntil, ScrollToElement } from '../../components/GlobalFunctions';      //2023.09.11
// import { _ProfileUid_TempList } from './TempList';
import { _TempList } from './TempList';

//2022.11.03
const Toggle = {
    None: '0',
    MappingUserToEvent: '1',
    UpdateUserSchoolName: '2',
}

//2022.01.10
export default class LiveQuizUserProfileManagement extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        redirect: false,
        redirectLink: '',

        //2022.11.03
        ToggleUi: Toggle.None,
        IsProcessingUpdate: false,
        ForceStop: false,

        DataList: [],
        ProfileList_lastVisible: null,

        IsProcessingSyncProfile: false,
        ProgressionStatusText: '',
        IsSyncCompleted: false,

        IsProfileListLoading: false,
        IsProfileListLoaded: false,

        //2022.01.14
        EventList: [],
        IsEventListLoading: false,
        IsEventListLoaded: false,
        SelectedEvent: null,
        OrganizerMappingInfo: null,

        //2022.02.14
        SyncStatusMessage: '',  //temp use for sync MyGuruDetails.
    });

    componentDidMount = async () => {
        if (this.props.user === null) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }
        await DelayUntil(() => this.props.OrganizerInfoIsLoaded === true);
        if (this.props.isDevMode)
            console.log('OrganizerInfo is loaded.');
        // this.LoadProfileListFromBase();
        // this.LoadAllEvents();
    }


    //#region Utilities


    //#endregion

    ToggleUiSection = (option) => {
        this.setState({ ToggleUi: option }, () => {
            switch (this.state.ToggleUi) {
                case Toggle.MappingUserToEvent: this.LoadAllEvents(); break;
                default: break;
            }
        });
    }


    //#region one time use functions

    //#region //for first profile data migration.
    // SyncAllUserProfile = async () => {
    //     this.setState({
    //         IsProcessingSyncProfile: true,
    //         ProgressionStatusText: 'fetching profile(s)...',
    //         DataList: [],
    //         IsSyncCompleted: false,
    //     });
    //     await Delay(2000);

    //     //init.
    //     let continueLoop = true;

    //     //loop.
    //     do {
    //         //init.
    //         continueLoop = true;
    //         let _List = [];

    //         //lastVisible.
    //         let _last = this.state.ProfileList_lastVisible;
    //         // if (_last === null || _last === undefined) {
    //         //     let _lastLS = localStorage.getItem('ProfileList_lastVisible');
    //         //     _last = _lastLS === null || _lastLS === undefined ? null : JSON.parse(_lastLS);
    //         // }

    //         //fetch all User profile(s).
    //         let docRef = this.props.dbFireStore.collection("User").orderBy('Uid', 'asc');
    //         if (_last !== null && _last !== undefined) {
    //             docRef = docRef.startAfter(_last);
    //         }
    //         await docRef
    //             .limit(10)
    //             .get()
    //             .then(querySnapshot => {
    //                 let dataArray = [];
    //                 if (querySnapshot !== null) {
    //                     querySnapshot.forEach((doc) => {
    //                         dataArray.push(doc.data());
    //                         // dataArray[dataArray.length - 1].Id = doc.id;    //Uid
    //                     });
    //                     if (dataArray.length > 0) {
    //                         _List = dataArray;
    //                         _last = querySnapshot.docs[querySnapshot.docs.length - 1];
    //                     }
    //                 }
    //                 if (this.props.isDevMode)
    //                     console.log(JSON.stringify(_List));
    //             })
    //             .catch(error => {
    //                 if (this.props.isDevMode)
    //                     console.log(error.message);
    //             });

    //         this.setState({
    //             ProfileList_lastVisible: _last,
    //         });
    //         // localStorage.setItem('ProfileList_lastVisible', JSON.stringify(_last));
    //         console.log('\nProfileList_lastVisible = ' + String(_List[_List.length - 1]['Uid']));

    //         continueLoop = _List.length > 0;     //loop toggle.

    //         //trigger Sync with Api.
    //         if (_List.length > 0) {
    //             console.log('\nTotal Profiles (fetched) = ' + _List.length);
    //             let _success = false;

    //             for (let i = 0; i < _List.length; i++) {
    //                 let success = false;
    //                 await fetch(GlobalSetting.ApiUrl
    //                     + 'Api/LearningCentre/User/Profile/Sync/'
    //                     + String(_List[i]['Uid']) + '/null/null/null/null/null',
    //                     // Api/LearningCentre/User/Profile/Sync/{firebaseUserId}/{organizerIdentity}/{eventCode}/{registrationDate}/{centerUserId}/{authorId}
    //                     {
    //                         method: 'GET',
    //                         headers: {
    //                             'Accept': 'application/json',
    //                             'Content-Type': 'application/json',
    //                         },
    //                     })
    //                     .then(res => res.json())
    //                     .then(data => {
    //                         success = data.success;
    //                         if (!data.success)
    //                             if (this.props.isDevMode)
    //                                 console.log('Error', 'api - profile - sync (failed)\n' + JSON.stringify(data));
    //                     })
    //                     .catch(error => {
    //                         if (this.props.isDevMode)
    //                             console.log('Error', 'api - profile - sync (failed)\n' + error.message);
    //                     });

    //                 if (success) {
    //                     //update list.
    //                     let _profileList = this.state.DataList;
    //                     let findIndex = _profileList.findIndex(x => String(x.Uid) === String(_List[i]['Uid']));
    //                     if (findIndex < 0)
    //                         _profileList.push(_List[i]);

    //                     //save state.
    //                     this.setState({
    //                         DataList: _profileList,
    //                         ProgressionStatusText: "(" + (i + 1) + "/" + _List.length + ") Sync'ed. | " + String(_List[i]['Name']) + " | Uid = " + String(_List[i]['Uid']),
    //                     });
    //                     _success = success;
    //                     ScrollToElement('bottomPage');
    //                 }
    //             }
    //             continueLoop = _success;     //loop toggle.
    //         }
    //     } while (continueLoop);

    //     await Delay(2000);
    //     this.setState({
    //         // DataList: _List,
    //         ProgressionStatusText: '\nTotal Profiles (saved) = ' + this.state.DataList.length,
    //         IsProcessingSyncProfile: false,
    //         IsSyncCompleted: true,
    //     });
    // }
    //#endregion

    //#region  //for re-sync left over/bugged profile(s) during first data migration.
    // SyncSomeUserProfile = async () => {
    //     this.setState({
    //         IsProcessingSyncProfile: true,
    //         ProgressionStatusText: 're-fetch 240 items',
    //         DataList: [],
    //         IsSyncCompleted: false,
    //     });
    //     await Delay(2000);

    //     let _List = _ProfileUid_TempList;

    //     //trigger Sync with Api.
    //     if (_List.length > 0) {
    //         console.log('\nTotal Profiles (fetched) = ' + _List.length);
    //         let _success = false;

    //         for (let i = 0; i < _List.length; i++) {
    //             let success = false;
    //             await fetch(GlobalSetting.ApiUrl
    //                 + 'Api/LearningCentre/User/Profile/Sync/'
    //                 + String(_List[i]) + '/null/null/null/null/null',
    //                 // Api/LearningCentre/User/Profile/Sync/{firebaseUserId}/{organizerIdentity}/{eventCode}/{registrationDate}/{centerUserId}/{authorId}
    //                 {
    //                     method: 'GET',
    //                     headers: {
    //                         'Accept': 'application/json',
    //                         'Content-Type': 'application/json',
    //                     },
    //                 })
    //                 .then(res => res.json())
    //                 .then(data => {
    //                     success = data.success;
    //                     if (!data.success)
    //                         if (this.props.isDevMode)
    //                             console.log('Error', 'api - profile - sync (failed)\n' + JSON.stringify(data));
    //                 })
    //                 .catch(error => {
    //                     if (this.props.isDevMode)
    //                         console.log('Error', 'api - profile - sync (failed)\n' + error.message);
    //                 });

    //             if (success) {
    //                 //update list.
    //                 let _profileList = this.state.DataList;
    //                 let findIndex = _profileList.findIndex(x => String(x.Uid) === String(_List[i]));
    //                 if (findIndex < 0)
    //                     _profileList.push(_List[i]);

    //                 //save state.
    //                 this.setState({
    //                     DataList: _profileList,
    //                     ProgressionStatusText: "(" + (i + 1) + "/" + _List.length + ") Sync'ed. | Uid = " + String(_List[i]),
    //                 });
    //                 _success = success;
    //                 ScrollToElement('bottomPage');
    //             }
    //         }
    //     }

    //     await Delay(2000);
    //     this.setState({
    //         // DataList: _List,
    //         ProgressionStatusText: '\nTotal Profiles (saved) = ' + this.state.DataList.length,
    //         IsProcessingSyncProfile: false,
    //         IsSyncCompleted: true,
    //     });
    // }
    //#endregion

    //2022.01.14
    //#region //for sync profile to event for statistic on registration by event
    SyncMapping_ProfileToEvent = async () => {
        this.setState({
            ProgressionStatusText: 'fetching profile(s)...',
            DataList: [],
            IsProcessingSyncProfile: true,
            IsSyncCompleted: false,
            ProfileList_lastVisible: null,
        });
        await Delay(500);
        await this.GetOrganizerMapping();
        await Delay(500);

        //init.
        let continueLoop = true;

        //loop.
        do {
            //init.
            continueLoop = true;
            let _List = [];

            //lastVisible.
            let _last = this.state.ProfileList_lastVisible;

            //get by target collection.
            let _target = '';
            let _findIndex = this.props.Organizers.findIndex(x => Number(x.Id) === Number(this.state.SelectedEvent.OrganizerId));
            if (_findIndex > -1) {
                let _temp = this.props.Organizers[_findIndex]['Name'];
                _target = _temp !== undefined && _temp !== '' ? String(_temp) : '';
                if (this.props.isDevMode)
                    console.log(JSON.stringify(this.props.Organizers[_findIndex]));
            }
            if (this.props.isDevMode)
                console.log('_findIndex = ' + _findIndex);

            //fetch all User profile(s).
            if (_target !== '') {
                let docRef = this.props.dbFireStore.collection('LiveQuiz_RegData').doc(_target).collection('List')
                    .where('EventCode', '==', String(this.state.SelectedEvent['EventCode']))
                    .orderBy('Uid', 'asc');
                if (_last !== null && _last !== undefined) {
                    docRef = docRef.startAfter(_last);
                }
                await docRef
                    .limit(10)
                    .get()
                    .then(querySnapshot => {
                        let dataArray = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                dataArray.push(doc.data());
                                // dataArray[dataArray.length - 1].Id = doc.id;    //Uid
                            });
                            if (dataArray.length > 0) {
                                _List = dataArray;
                                _last = querySnapshot.docs[querySnapshot.docs.length - 1];
                            }
                        }
                        if (this.props.isDevMode)
                            console.log(JSON.stringify(_List));
                    })
                    .catch(error => {
                        if (this.props.isDevMode)
                            console.log(error.message);
                    });

                this.setState({
                    ProfileList_lastVisible: _last,
                });
                // localStorage.setItem('ProfileList_lastVisible', JSON.stringify(_last));
                if (this.props.isDevMode)
                    console.log('\nProfileList_lastVisible = ' + String(_List[_List.length - 1]['Uid']));
            }
            continueLoop = _List.length > 0;     //loop toggle.

            //trigger Sync with Api.
            if (_List.length > 0) {
                if (this.props.isDevMode)
                    console.log('\nTotal Profiles (fetched) = ' + _List.length);
                let _success = false;

                for (let i = 0; i < _List.length; i++) {
                    let success = false;

                    let _regDate = _List[i]['CreatedDate'];
                    if (_regDate === undefined || _regDate === null)
                        _regDate = _List[i]['PolicyTncAgreeDateTime'];
                    if (_regDate === undefined || _regDate === null)
                        _regDate = '';
                    else
                        // _regDate = moment(String(_regDate), 'YYYY-MM-DD HH:mm:ss').add(-8, 'hours').format('YYYY-MM-DD HH:mm:ss.sss');
                        _regDate = moment(String(_regDate), 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss.sss');     //2022.02.08

                    //trigger sync to db. //2022.02.08 revamped - with body model.
                    await fetch(GlobalSetting.ApiUrl
                        + 'Api/LearningCentre/User/Registration/Profile/Sync',
                        // + 'Api/LearningCentre/User/Profile/Sync',
                        // Api/LearningCentre/User/Profile/Sync/{model}
                        {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                FirebaseUserId: String(_List[i]['Uid']),
                                OrganizerIdentity: _findIndex > -1 ? String(this.props.Organizers[_findIndex]['Identity']) : '',
                                EventCode: String(this.state.SelectedEvent['EventCode']),
                                CenterUserId: this.state.OrganizerMappingInfo === null ? '' : String(this.state.OrganizerMappingInfo['CenterUserId']),
                                AuthorId: this.state.OrganizerMappingInfo === null ? '' : String(this.state.OrganizerMappingInfo['AuthorId']),
                                RegistrationDate: _regDate,
                                Email: String(_List[i]['Email']),    //2024.05.06
                            })
                        })
                        .then(res => res.json())
                        .then(data => {
                            success = data.success;
                            if (!data.success)
                                if (this.props.isDevMode)
                                    console.log('Error', 'api - profile - sync (failed)\n' + JSON.stringify(data));
                        })
                        .catch(error => {
                            if (this.props.isDevMode)
                                console.log('Error', 'api - profile - sync (failed)\n' + error.message);
                        });

                    //#region older code.
                    // //trigger sync to db.
                    // await fetch(GlobalSetting.ApiUrl
                    //     + 'Api/LearningCentre/User/Profile/Sync/'
                    //     + String(_List[i]['Uid']) + '/'
                    //     + (_findIndex > -1 ? String(this.props.Organizers[_findIndex]['Identity']) : 'null') + '/'
                    //     // + '/null/'
                    //     + String(this.state.SelectedEvent['EventCode']) + '/' + _regDate
                    //     + (this.state.OrganizerMappingInfo === null ? '/null/null'
                    //         : '/' + String(this.state.OrganizerMappingInfo['CenterUserId']) + '/' + String(this.state.OrganizerMappingInfo['AuthorId'])),
                    //     // Api/LearningCentre/User/Profile/Sync/{firebaseUserId}/{organizerIdentity}/{eventCode}/{registrationDate}/{centerUserId}/{authorId}
                    //     {
                    //         method: 'GET',
                    //         headers: {
                    //             'Accept': 'application/json',
                    //             'Content-Type': 'application/json',
                    //         },
                    //     })
                    //     .then(res => res.json())
                    //     .then(data => {
                    //         success = data.success;
                    //         if (!data.success)
                    //             if (this.props.isDevMode)
                    //                 console.log('Error', 'api - profile - sync (failed)\n' + JSON.stringify(data));
                    //     })
                    //     .catch(error => {
                    //         if (this.props.isDevMode)
                    //             console.log('Error', 'api - profile - sync (failed)\n' + error.message);
                    //     });
                    //#endregion older code.

                    if (success) {
                        let item = _List[i];
                        //update list.
                        let _profileList = this.state.DataList;
                        let findIndex = _profileList.findIndex(x => String(x.Uid) === String(item['Uid']));
                        if (findIndex < 0)
                            _profileList.push(item[i]);

                        //save state.
                        this.setState({
                            DataList: _profileList,
                            ProgressionStatusText: "(" + (i + 1) + "/" + _List.length + ") Sync'ed. | " + String(item['Name']) + " | Uid = " + String(item['Uid']),
                        });
                        _success = success;
                        ScrollToElement('bottomPage');
                    }
                }
                continueLoop = _success;     //loop toggle.
            }
        } while (continueLoop);

        await Delay(2000);
        this.setState({
            // DataList: _List,
            ProgressionStatusText: "\nTotal Profiles (sync'ed) = " + this.state.DataList.length,
            IsProcessingSyncProfile: false,
            IsSyncCompleted: true,
        });
    }

    GetOrganizerMapping = async () => {
        let _data = this.state.OrganizerMappingInfo;
        if (_data === null || _data === undefined) {
            if (this.state.SelectedEvent !== null && this.state.SelectedEvent !== undefined) {
                await this.props.dbFireStore
                    .collection('QuizBank').doc('OrganizerMapping').collection('OrganizerMappings')
                    .where('OrganizerId', '==', Number(this.state.SelectedEvent['OrganizerId']))
                    // .orderBy('Id', 'asc')
                    .get()
                    .then(querySnapshot => {
                        let dataArray = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                dataArray.push(doc.data());
                                // dataArray[dataArray.length - 1].Id = doc.id;    //Uid
                            });
                            if (dataArray.length > 0) {
                                _data = dataArray[0];
                            }
                        }
                        if (this.props.isDevMode)
                            console.log(JSON.stringify(_data));
                    })
                    .catch(error => {
                        if (this.props.isDevMode)
                            console.log(error.message);
                    });
                this.setState({
                    OrganizerMappingInfo: _data,
                });
            }
        }
        return _data;
    }
    //#endregion

    //#endregion


    //#region  === MappingUserToEvent ===

    LoadProfileListFromBase = async () => {
        this.setState({
            DataList: [],
            IsProfileListLoading: true,
            IsProfileListLoaded: false,
        });

        const url = GlobalSetting.ApiUrl
            + 'Api/LearningCentre/User/Profile/List/'
            + Number(this.props.user.CenterUserId) + '/' + Number(this.props.user.AuthorId) + '/'
            + (this.props.OrganizerInfo !== null && this.props.OrganizerInfo !== undefined ?
                this.props.OrganizerInfo.hasOwnProperty('OrganizerId') ?
                    Number(this.props.OrganizerInfo.OrganizerId)
                    : 0
                : 0);

        if (this.props.isDevMode)
            console.log(url);

        // let success = false;
        let _List = [];
        await fetch(url,
            // Api/LearningCentre/User/Profile/List/{centerUserId}/{authorId}/{organizerId}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                // success = data.success;
                if (data.success) {
                    if (data.data !== undefined)
                        if (Array(data.data.data).length > 0)
                            _List = data.data.data;
                        else
                            if (this.props.isDevMode)
                                console.log('Profile List is empty.');
                }
                else {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - profile - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - profile - load list (failed)\n' + error.message);
            });

        this.setState({
            DataList: _List,
            IsProfileListLoading: false,
            IsProfileListLoaded: true,
        });
    }

    //2022.01.14
    LoadAllEvents = async () => {
        this.setState({
            ProgressionStatusText: 'fetching event(s)...',
            EventList: [],
            SelectedEvent: null,
            IsEventListLoading: true,
            IsEventListLoaded: false,
        });
        await Delay(2000);

        let _List = [];
        await this.props.dbFireStore
            .collection("LiveQuiz_UpcomingEvents")
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                        dataArray[dataArray.length - 1].Id = doc.id;    //timestampId
                    });
                    if (dataArray.length > 0) {
                        _List = dataArray;
                        // _last = querySnapshot.docs[querySnapshot.docs.length - 1];
                    }
                }
                if (this.props.isDevMode)
                    console.log(JSON.stringify(_List));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });

        this.setState({
            EventList: _List,
            IsEventListLoading: false,
            IsEventListLoaded: true,
            ProgressionStatusText: '',
        });
    }

    //2022.01.14
    SelectEvent = (_event) => {
        this.setState({
            SelectedEvent: _event,
        }, () => this.SyncMapping_ProfileToEvent());
    }

    //#endregion


    //2022.02.14
    //temp use for sync MyGuruDetails.
    SyncToFS_MyGuruDetails = async () => {
        if (_TempList.length > 0) {
            this.setState({ SyncStatusMessage: 'sync in progress...' });
            for (let i = 0; i < _TempList.length; i++) {
                await this.props.dbFireStore
                    .collection('MyGuruDetails')
                    .doc(moment.utc().valueOf().toString())
                    .set(_TempList[i])
                    .then(() => {
                        this.setState({ SyncStatusMessage: 'sync is done. = ' + _TempList[i].Code });
                    })
                    .catch((error) => {
                        this.setState({ SyncStatusMessage: 'sync is failed.\n\n' + JSON.stringify(error) });
                    });
            }
        }
    }


    //#region  === UpdateUserSchoolName ===

    BeginUpdateProcess_SchoolName = async () => {

        this.setState({
            IsSyncCompleted: false,
            IsProcessingUpdate: true,
            ProgressionStatusText: '...retrieving profile list',
            DataList: [],
            IsProcessingSyncProfile: true,
            ProfileList_lastVisible: null,
        });

        //init.
        let continueLoop = true;

        await this.GetOrganizerMapping();
        await Delay(1000);

        const _SchoolListWithDetails = await import('../../components/SchoolListWithDetails').then((data) => { return data.default; });
        // let _SchoolListWithDetails = [];

        // import { NationalState, SchoolList, District } from '../components/NationalQuizEventSignUpSettings.js';
        const { SchoolList } = await import('../../components/NationalQuizEventSignUpSettings.js');    //.then((data) => { return data.default; });
        await Delay(1000);
        this.DebugLog("SchoolList = " + SchoolList.length);

        //loop.
        do {
            this.setState({ IsProfileListLoaded: false, DataList: [], });

            //init.
            continueLoop = true;
            let _List = [];
            let _last = this.state.ProfileList_lastVisible;

            //fetch profiles in range.
            let docRef = this.props.dbFireStore.collection('User')
                // .where('Uid', '>', '4T2bmLrAqtRANtbK8LQK9jzApos1')
                .where('Uid', '>', 'IiaV9dYvGZT9pcW9VlzHHXK0Kut2')
                .orderBy('Uid', 'asc');
            if (_last !== null && _last !== undefined) {
                docRef = docRef.startAfter(_last);
            }
            await docRef
                .limit(5)
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                            // dataArray[dataArray.length - 1].Id = doc.id;    //Uid
                        });
                        if (dataArray.length > 0) {
                            _List = dataArray;
                            _last = querySnapshot.docs[querySnapshot.docs.length - 1];
                        }
                    }
                    if (this.props.isDevMode)
                        console.log(JSON.stringify(_List));
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log(error.message);
                });

            this.setState({
                ProfileList_lastVisible: _last,
            });
            if (this.props.isDevMode)
                console.log('\nProfileList_lastVisible = ' + String(_List[_List.length - 1]['Uid']));
            continueLoop = _List.length > 0;     //loop toggle.

            //trigger profile update & sync.
            if (_List.length > 0) {
                this.setState({ IsProfileListLoaded: true, DataList: _List, });

                let _success = false;

                //2022.11.04
                let getDistrict = (ppd) => {
                    let splits = ppd.split(' ');
                    splits.shift();
                    return splits.join(' ');
                };

                for (let i = 0; i < _List.length; i++) {

                    let item = _List[i];

                    //find profile school name.
                    const schoolIdx = SchoolList.findIndex(x => x.includes(String(item['School'])));
                    if (schoolIdx > -1) {

                        //compare.
                        if (String(item['School']) !== String(SchoolList[schoolIdx])) {

                            //update profile school name.
                            item['School'] = String(SchoolList[schoolIdx]);

                            //update district area.
                            if (String(item['School']) !== 'OTHER') {
                                if (String(item['DistrictArea']).length === 0 || String(item['DistrictArea']).includes('PPD') === false) {
                                    // let txt = String(item['School']).split(' - ');
                                    // if (txt[2] !== undefined)
                                    //     item['DistrictArea'] = 'PPD ' + String(txt[2]);

                                    let findSchoolDetailIndex = _SchoolListWithDetails.findIndex(x =>
                                        String(item['School']).includes(x.Name)
                                        &&
                                        (
                                            String(item['School']).includes(' - ') ?
                                                String(item['School']).includes(x.NationalState) && String(item['School']).includes(getDistrict(x.PPD))
                                                : true
                                        )
                                    );
                                    if (findSchoolDetailIndex > -1) {
                                        item['DistrictArea'] = String(_SchoolListWithDetails[findSchoolDetailIndex].PPD);
                                    }

                                    // let _schoolName = String(item['School']).includes(' - ') ? String(item['School']).split(' - ')[0] : String(item['School']);
                                    // let findIdx = _SchoolListWithDetails.findIndex(x => String(x.schoolName).includes(_schoolName));
                                    // if (findIdx < 0) {
                                    //     let govSchoolDetails = await this.GetGovSchoolDetail(_schoolName, item);
                                    //     if (govSchoolDetails !== null && govSchoolDetails !== undefined) {
                                    //         let checkIdx = _SchoolListWithDetails.findIndex(x => Number(x.id) === Number(govSchoolDetails.id));
                                    //         if (checkIdx < 0)
                                    //             _SchoolListWithDetails.push(govSchoolDetails);

                                    //         item['DistrictArea'] = govSchoolDetails['ppd'] !== undefined ? String(govSchoolDetails['ppd']) : '';
                                    //     }
                                    // }
                                    // else {
                                    //     item['DistrictArea'] = String(_SchoolListWithDetails[findIdx].ppd);
                                    // }
                                }
                            }
                            if (String(item['DistrictArea']) === 'undefined')
                                item['DistrictArea'] = '';

                            //log.
                            this.DebugLog(' updating | ' + item['Uid'] + ' | ' + item['School'] + ' | ' + item['DistrictArea']);

                            //fs sync.
                            this.props.dbFireStore.collection('User').doc(String(item['Uid'])).update({
                                School: String(item['School']),
                                DistrictArea: String(item['DistrictArea'])
                            });

                            //api sync.
                            let success = false;
                            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Registration/Profile/Sync',
                                {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        FirebaseUserId: String(item['Uid']),
                                        OrganizerIdentity: '',
                                        EventCode: '',
                                        CenterUserId: 0,
                                        AuthorId: 0,
                                        RegistrationDate: '',
                                        Email: String(item['Email']),    //2024.05.06
                                    })
                                })
                                .then(res => res.json())
                                .then(data => {
                                    success = data.success;
                                    if (!data.success)
                                        if (this.props.isDevMode)
                                            console.log('Error', 'api - profile - sync (failed)\n' + JSON.stringify(data));
                                })
                                .catch(error => {
                                    if (this.props.isDevMode)
                                        console.log('Error', 'api - profile - sync (failed)\n' + error.message);
                                });

                            //ui.
                            if (success) {

                                //update list.
                                let _profileList = this.state.DataList;
                                let findIndex = _profileList.findIndex(x => String(x.Uid) === String(item['Uid']));
                                if (findIndex < 0)
                                    _profileList.push(item);

                                //save state.
                                this.setState({
                                    DataList: _profileList,
                                    // ProgressionStatusText: "(" + (i + 1) + "/" + _List.length + ") Sync'ed. | " + String(item['Name']) + " | Uid = " + String(item['Uid']),
                                });
                                _success = success;
                                // ScrollToElement('bottomPage');
                            }
                        }
                        else {
                            _success = true;
                        }
                    }
                    else {
                        this.DebugLog('school index not found. | ' + item['Uid'] + ' | ' + item['School']);
                    }

                    //done 1 profile.
                    item['Updated'] = true;
                    _List[i] = item;
                    this.setState({ DataList: _List, });

                    await Delay(1000);
                }
                continueLoop = _success;
            }

            if (this.state.ForceStop)
                continueLoop = false;

        } while (continueLoop);

        await Delay(2000);

        //done.
        this.setState({
            ProgressionStatusText: "\nTotal Profiles (updated) = " + this.state.DataList.length,
            IsProcessingSyncProfile: false,
            IsSyncCompleted: true,
            IsProcessingUpdate: false,
            ForceStop: false,
        });
    }

    //2022.11.04
    GetGovSchoolDetail = async (_SchoolName = '', _profile = null) => {
        if (_SchoolName === '')  // || _CountryState === undefined || _StateArea === undefined)
            return null;

        let _CountryState = '';
        let _StateArea = '';
        if (String(_SchoolName).includes(' - ')) {
            let splits = String(_SchoolName).split(' - ');
            if (splits[1] !== undefined)
                _CountryState = splits[1];
            if (splits[2] !== undefined)
                _StateArea = splits[2];
        }

        if (_StateArea === '') {
            if (_profile !== null) {
                if (_profile['DistrictArea'] !== undefined) {
                    let temp = String(_profile['DistrictArea']).split(' ');
                    temp.shift();
                    _StateArea = temp.join(' ');
                }
                if (_profile['CountryState'] !== undefined) {
                    _CountryState = String(_profile['CountryState']);
                }
            }
        }

        await fetch(GlobalSetting.ApiUrl + 'Api/LiveQuiz/GovernmentSchool/SearchByParams',
            // Api/LiveQuiz/GovernmentSchool/SearchByParams
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    SchoolName: _SchoolName,
                    CountryState: _CountryState,
                    StateArea: _StateArea
                })
            })
            .then(res => res.json())
            .then(data => {
                // success = data.success;
                if (this.props.isDevMode)
                    if (data.success)
                        console.log('api - participation - sync (success)\n' + JSON.stringify(data));
                    else
                        console.log('Error', 'api - participation - sync (failed)\n' + JSON.stringify(data));
                if (data.success) {
                    return JSON.parse(JSON.stringify(data.data));
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - participation - sync (failed)\n' + error.message);
                return null;
            });
    }

    DebugLog = (msg) => {
        console.log('\n' + msg);
        this.setState({ ProgressionStatusText: msg, });
    }

    LoadProfileListFromFireStore = async () => {

    }

    SyncToDB = async (profile) => {
        if (CheckNullValue(profile) === null)
            return false;

        let success = false;
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Registration/Profile/Sync',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    FirebaseUserId: String(profile['Uid']),
                    OrganizerIdentity: '',
                    EventCode: '',
                    CenterUserId: this.state.OrganizerMappingInfo === null ? '' : String(this.state.OrganizerMappingInfo['CenterUserId']),
                    AuthorId: this.state.OrganizerMappingInfo === null ? '' : String(this.state.OrganizerMappingInfo['AuthorId']),
                    RegistrationDate: '',
                    Email: String(profile['Email']),    //2024.05.06
                })
            })
            .then(res => res.json())
            .then(data => {
                success = data.success;
                if (!data.success)
                    if (this.props.isDevMode)
                        console.log('Error', 'api - profile - sync (failed)\n' + JSON.stringify(data));
                return success;
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - profile - sync (failed)\n' + error.message);
                return false;
            });
    }

    //#endregion



    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <>
                    <Row className='rowStyle'>
                        <Col>
                            <span style={{ fontSize: 20, fontWeight: 'bold' }}>User Profile Management</span>
                        </Col>
                        {/* <Col className='colBtn'>
                            <button type="button" className="btn btn-outline-primary"
                                onClick={this.CreateNewEvent}
                            >New Event</button>
                        </Col> */}
                    </Row>
                    <Row className='rowStyle'>
                        <Col>
                            <br />
                            <Row style={{ display: 'inline-flex' }}>
                                <Col>
                                    <button
                                        className='btn btn-primary'
                                        style={{ width: 200, height: 75, }}
                                        onClick={() => this.ToggleUiSection(Toggle.MappingUserToEvent)}
                                        disabled={this.state.ToggleUi !== Toggle.None}
                                    ><b>Mapping Profile To Event</b></button>
                                </Col>
                                <Col>
                                    <button
                                        className='btn btn-primary'
                                        style={{ width: 200, height: 75, }}
                                        onClick={() => this.ToggleUiSection(Toggle.UpdateUserSchoolName)}
                                        disabled={this.state.ToggleUi !== Toggle.None}
                                    ><b>Update Profile School Name</b></button>
                                </Col>
                            </Row>
                            <br />
                            {
                                this.state.ToggleUi === Toggle.None ? null
                                    :
                                    <div style={{ display: 'flex', marginTop: 20, }}>{
                                        this.state.ToggleUi === Toggle.MappingUserToEvent ? <h1>Mapping User To Event</h1>
                                            : this.state.ToggleUi === Toggle.UpdateUserSchoolName ? <h1>Update User School Name</h1>
                                                : null
                                    }&nbsp;&nbsp;<button className='btn btn-primary' onClick={() => this.setState({ ToggleUi: Toggle.None })} >back</button>{
                                            this.state.IsProcessingUpdate ? <>
                                                &nbsp;&nbsp;<button className='btn btn-primary' onClick={() => this.setState({ ForceStop: true })} >force stop</button>
                                            </> : null}
                                    </div>
                            }
                            <br />
                            {
                                this.state.ToggleUi !== Toggle.UpdateUserSchoolName ? null
                                    :
                                    <>
                                        <button
                                            className='btn btn-primary'
                                            style={{ width: 200, height: 75, }}
                                            onClick={() => this.BeginUpdateProcess_SchoolName()}
                                            disabled={this.state.IsProcessingUpdate}
                                        >begin update process</button>
                                    </>
                            }
                            {
                                // this.state.ProgressionStatusText.length > 0 ? <><br /><br />{this.state.ProgressionStatusText}</> : null
                            }
                            {
                                this.state.ToggleUi !== Toggle.MappingUserToEvent ? null
                                    :
                                    this.state.SelectedEvent !== null ?
                                        <>
                                            Selected Event:<br />
                                            <span style={{ fontWeight: 'bold', }}>{this.state.SelectedEvent['EventName']}</span><br />
                                            <span style={{ color: 'gray', fontSize: 12, }}>{this.state.SelectedEvent['EventCode']}</span>
                                        </>
                                        :
                                        this.state.IsEventListLoaded ?
                                            this.state.EventList.length <= 0 ?
                                                'event list is empty.'
                                                :
                                                this.state.EventList.map((data, key) => {
                                                    // let _findIndex = this.props.Organizers.findIndex(x => Number(x.Id) === Number(data['OrganizerId']));
                                                    // let _organizer = undefined;
                                                    // if (_findIndex > -1)
                                                    //     _organizer = this.props.Organizers[_findIndex];
                                                    return <>
                                                        <button
                                                            className='btn btn-primary'
                                                            style={{ width: '100%', marginBottom: 5, }}
                                                            onClick={() => this.SelectEvent(data)}
                                                        ><b>{data['EventName']}</b> ({data['EventCode']}) ({data['Organizer']}) ({data['OrganizerIdentity']})
                                                            {/* ({_organizer === undefined ? '' : _organizer['Name'] + ' | ' + _organizer['Identity'] + ' | ' + _organizer['Id']}) */}
                                                        </button>
                                                        <br />
                                                    </>;
                                                })
                                            :
                                            this.state.IsEventListLoading ?
                                                <>
                                                    Loading Event list...
                                                    <br /><br />
                                                    <ProgressBar animated now={100} />
                                                </>
                                                : null
                            }
                            {
                                this.state.IsProfileListLoaded ?
                                    this.state.DataList.length <= 0 ?
                                        <><br /><br />'profile list is empty.'</>
                                        :
                                        <>
                                            <br /><br />
                                            {/* {JSON.stringify(this.state.DataList)} */}
                                            <table className='table tb-no-border' width={'100%'}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        {
                                                            this.state.ToggleUi === Toggle.MappingUserToEvent ?
                                                                <>
                                                                    <th>Race</th>
                                                                    <th>Gender</th>
                                                                    <th>Registration Date</th>
                                                                </>
                                                                :
                                                                this.state.ToggleUi === Toggle.UpdateUserSchoolName ?
                                                                    <>
                                                                        <th>Updated</th>
                                                                    </>
                                                                    : null
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.DataList.map((data, key) => {
                                                            return <tr>
                                                                <td>{key + 1}</td>
                                                                <td>{data['Name']}</td>
                                                                <td>{data['Email']}</td>
                                                                {
                                                                    this.state.ToggleUi === Toggle.MappingUserToEvent ?
                                                                        <>
                                                                            <td>{data['Race']}</td>
                                                                            <td>{data['Gender']}</td>
                                                                            <td>{data['CreatedDate']}</td>
                                                                        </>
                                                                        :
                                                                        this.state.ToggleUi === Toggle.UpdateUserSchoolName ?
                                                                            <>
                                                                                <td>{data['Updated'] !== undefined && CheckBoolean(data['Updated']) ? '✅' : '...'}</td>
                                                                            </>
                                                                            : null
                                                                }
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </>
                                    :
                                    this.state.IsProfileListLoading ?
                                        <>
                                            <br /><br />
                                            Loading Profile list...
                                            <br /><br />
                                            <ProgressBar animated now={100} />
                                        </>
                                        : null
                            }
                            {
                                this.state.IsProcessingSyncProfile ?
                                    <>
                                        <br />
                                        <ProgressBar animated now={100} />
                                        <br />
                                        {this.state.ProgressionStatusText}
                                    </>
                                    : null
                            }
                        </Col>
                    </Row >
                    <div id='bottomPage'></div>

                    {/* <hr />
                    <hr />
                    <hr />
                    <hr />
                    <Row className='rowStyle'>
                        <Col className='colBtn'>
                            <button type="button" className="btn btn-outline-primary"
                                onClick={this.SyncToFS_MyGuruDetails}
                            >SyncToFS = MyGuruDetails</button>
                        </Col>
                    </Row>
                    <Row className='rowStyle'>
                        <Col>{this.state.SyncStatusMessage}</Col>
                    </Row> */}

                </>
            );
        }
    }
}