//2021.07.23 === fixed atm.
let SubjectId = [
    { Id: 1, Name: 'English', BC: '英语', BM: 'Bahasa Inggeris', UniqueId: '004dd73ecf1d40ada1ccc76f2fd38a67', Tag: 'BI' },
    { Id: 2, Name: 'Malay', BC: '国语', BM: 'Bahasa Melayu', UniqueId: 'a16999cbec60400f9332b092f46b17de', Tag: 'BM' },
    { Id: 3, Name: 'Chinese', BC: '华语', BM: 'Bahasa Cina', UniqueId: 'e884efce2e28497bbf6d53af96bea4b4', Tag: 'BC' },
    { Id: 4, Name: 'Science', BC: '科学', BM: 'Sains', UniqueId: '7245a6ea4bc34da684811f8d1d6c09d8', Tag: 'SN' },
    { Id: 5, Name: 'Mathematics', BC: '数学', BM: 'Matematik', UniqueId: '3919de29ac404c9bb939e8afb08d9eb3', Tag: 'MM' },
    { Id: 6, Name: 'ChinesePinYin', BC: '汉语拼音', BM: 'HanYuPinYin', UniqueId: '1cc2682acbe74bea90a930a95bcbe162', Tag: 'HYPY' },
    { Id: 7, Name: 'MixedQuestions', BC: '混合题目', BM: 'Soalan Campuran', UniqueId: 'd3bb7d936ccc4a989f8ff58456aa9971', Tag: '' },
];
export default SubjectId;