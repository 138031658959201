import React from 'react';
import { Redirect } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';
// import _ from 'lodash';
import 'firebase/database';

// import {
//     // osVersion, osName, browserName, fullBrowserVersion
//     browserName
// } from "react-device-detect";

// import LoadingIndicator from './LoadingIndicator.js';
import { Locale, Lang } from '../../Localization/CustomLocalization.js';
import './css/StripedTableCSS.css';
import LocalizationSwitcher from '../../Localization/LocalizationSwitcher.js';
import { GlobalSetting } from '../../components/GlobalSetting';
import { Delay, DelayUntil } from '../../components/GlobalFunctions';      //2023.09.11

let UploadState = {
    None: 0,
    Browse: 1,
    Validation: 2,
    Uploading: 3,
    Failed: 4,
    Success: 5,
};

export default class QuizRoomTypeFileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        redirect: false,
        redirectLink: '',
        isDevMode: false,

        todayDT: '',
        roomCode: '',
        roomId: '',
        roomTitle: '',

        isRoomInfoLoading: false,
        isRoomInfoLoaded: false,
        roomInfoModal: {                    //from FS.
            //revamped values.
            Date: '',
            DateEnd: '',
            DateStart: '',
            Duration: 0,
            EventCode: '',
            Organizer: '',
            OrganizerIdentity: '',
            QuestionSetUniqueId: '',
            RoomCode: 0,
            RoomId: '',
            RoomTitle: '',
            RoomType: 0,        //0 = basic, 1 = document
            SubjectName: '',
            Subject: '',
            SubjectId: 0,
            SupportedDocExt: ['.txt', '.rtf', '.doc', '.docx'],     //2021.11.08
            TimeEnd: '',
            TimeStart: '',
            GroupId: 0,
            Grade: 0,
            Remark: '',
            ExtraUrl: '',   //2021.12.10
        },
        isRoomDetailLoading: false,
        isRoomDetailLoaded: false,
        roomDetailModal: {                      //from RTDB.
            //old values. keep first.
            Subject: '',
            Grade: '',
            QnQty: 0,
            QnSet: 0,
            // DurationPerQuestion: 0,
            // Questions: '',
        },
        group: '',

        liveQuizStartTime: '',
        liveQuizEndTime: '',

        roomState: null,
        isQuizStarted: false,
        isQuizEnded: false,     //2021.11.15

        subjectLocale: Lang.English,

        FileUploadState: UploadState.None,
        FileToUpload: null,
        UploadErrorMessage: [],

        personalResult: {
            Participant: '',
            UploadedFileName: '',
            FileUploadedDate: '',
            Score: 0,
        },
        hasRoomResult: false,

        eventData: {
            EventCode: '',
            EventSharedReportUrl: ''
        },
    });

    componentDidMount = () => {

        if (this.props.user === null) {
            this.GotoPage('/');
            return null;
        }

        this.setState({

            roomCode: String(this.props.RoomCode),
            roomId: String(this.props.RoomId),

            isRoomInfoLoading: true,
            roomInfoModal: null,
            isRoomInfoLoaded: false,

            isRoomDetailLoading: false,
            roomDetailModal: null,
            isRoomDetailLoaded: false,

            subjectLocale: this.GetLocale(),

            personalResult: null,
            eventData: null,

        }, async () => {
            if (this.props.isDevMode)
                console.log('viewHistoryQuiz = ' + String(this.props.viewHistoryQuiz) + '\ntodayDT = ' + this.state.todayDT);

            if (this.props.user != null) {

                await this.GetRoomInfo();

                //2021.11.08
                this.GetEventData();

                let todayDate = '';
                if (String(this.props.RoomDate) !== '') {
                    todayDate = moment(String(this.props.RoomDate)).format('YYYYMMDD');
                }
                else {
                    if (this.props.viewHistoryQuiz !== null) {
                        todayDate = String(this.props.viewHistoryQuiz);
                    }
                    else {
                        if (this.state.roomInfoModal.hasOwnProperty('DateStart'))
                            todayDate = moment(this.state.roomInfoModal.DateStart).format('YYYYMMDD');
                        else
                            todayDate = moment().format('YYYYMMDD');
                    }
                }

                //2021.11.15
                let _isQuizEnded = false;
                if (this.state.roomInfoModal !== null) {
                    let _dateEnd = moment().add(-1, 'day').format('YYYY-MM-DD');
                    let _timeEnd = '23:59:00';

                    if (this.state.roomInfoModal.hasOwnProperty('DateEnd'))
                        _dateEnd = moment(this.state.roomInfoModal.DateEnd).format('YYYY-MM-DD');

                    if (this.state.roomInfoModal.hasOwnProperty('TimeEnd'))
                        _timeEnd = moment(_dateEnd + ' ' + this.state.roomInfoModal.TimeEnd).format('HH:mm:ss');

                    _isQuizEnded = moment() > moment(_dateEnd + ' ' + _timeEnd);
                }

                this.setState({
                    todayDT: todayDate,
                    // todayDT: String(this.props.RoomDate) !== '' ?
                    //     moment(String(this.props.RoomDate)).format('YYYYMMDD')
                    //     :
                    //     this.props.viewHistoryQuiz !== null ? String(this.props.viewHistoryQuiz) : todayDate,

                    isQuizEnded: _isQuizEnded,  //2021.11.15

                }, () => {
                    // this.ListenToRoomState();

                    //2021.10.27
                    this.CheckRoomResult();

                    //2021.11.02
                    this.GetGroup();
                    // this.GetRoomDetail();
                });
            }
            else {
                //back to login.
                this.GotoPage('/login');
            }
        });
    }

    componentWillUnmount = async () => {
        await this.RemoveListenToRoomState();
    }

    //#region common methods.

    Delay = ms => new Promise(res => setTimeout(res, ms));

    DelayUntil = (conditionFunction) => {
        const poll = resolve => {
            if (conditionFunction()) resolve();
            else setTimeout(_ => poll(resolve), 500);
        }
        return new Promise(poll);
    }

    GotoPage = (path) => { this.setState({ redirectLink: path, redirect: true, }); }

    GetValue = (state, name) => {
        if (state === null || state[name] === null)
            return '';

        return state !== null ? state[name] : '';
    }

    GetLocale = () => {

        let qsLocale = Lang.English;

        if (this.state.roomInfoModal !== undefined && this.state.roomInfoModal !== null) {
            if (String(this.state.roomInfoModal.Subject) === 'Chinese')
                qsLocale = Lang.Chinese;
            else if (String(this.state.roomInfoModal.Subject) === 'Malay')
                qsLocale = Lang.Malay;
            // else
            //     if (this.state.subjectLocale !== Lang.English)
            //         qsLocale = this.state.subjectLocale;
        }

        return qsLocale;
    }

    FormatedDuration = (_totalSeconds) => {
        // let min = Locale("time-min", this.props.Locale);
        // let sec = Locale("time-sec", this.props.Locale);

        // let totalSeconds = Number(_totalSeconds);

        // var minutes = (totalSeconds / 60).toFixed(3).split('.')[0];
        // var seconds = (totalSeconds % 60).toFixed(1);
        // return minutes + ' ' + min + ' ' + seconds + ' ' + sec;

        //2022.06.07
        let hr = Locale("time-hour-full", this.props.Locale);
        let hrs = Locale("time-hour-full-s", this.props.Locale);
        let min = Locale("time-min", this.props.Locale);
        let sec = Locale("time-sec", this.props.Locale);

        let totalSeconds = Number(_totalSeconds);

        let hours = (totalSeconds / 3600).toFixed(3).split('.')[0];
        let minutes = ((totalSeconds - (hours * 3600)) / 60).toFixed(3).split('.')[0];
        let seconds = (totalSeconds % 60).toFixed(3).split('.')[0];
        return (hours > 0 ? hours + ' ' + (hours > 1 ? hrs : hr) + ' ' : '') + minutes + ' ' + min + ' ' + seconds + ' ' + sec;
    }

    //10 alphanumeric letters
    RandomId = () => {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 10; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    //#endregion

    //2021.11.08
    GetEventData = () => {
        // let eventCode = this.props.eventCode;
        let eventCode = this.state.roomInfoModal.EventCode;
        if (eventCode !== '') {
            if (this.props.Events !== null) {
                let index = this.props.Events.findIndex(x => x.EventCode === eventCode);
                if (index > -1)
                    this.setState({ eventData: this.props.Events[index], });
            }
        }
        // console.log('GetEventData = \n' + JSON.stringify(this.props.Events));
    }

    GetRoomInfo = async () => {
        let _data = null;
        let isRetryNeeded = false;
        //2021.11.23 - ravamped.
        let loopAction = async () => {
            await this.props.firestore
                .collection("LiveQuiz_UniqueRoomCode")
                .where('RoomCode', '==', Number(this.state.roomCode))
                .get()
                .then(querySnapshot => {
                    // isEmailNotExist = !querySnapshot.exists;
                    let data = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            data.push(doc.data());
                        });
                        if (data.length > 0)
                            data = data[0];
                        else
                            data = null;
                    }
                    if (data.hasOwnProperty('RoomId'))
                        _data = data;
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log('load room info (error)\n' + error);
                    isRetryNeeded = true;
                });
        }
        do {
            isRetryNeeded = false;
            //Search Room in new FS location.
            await loopAction();
        } while (isRetryNeeded);
        if (_data !== null) {
            this.setState({
                roomInfoModal: _data,
                liveQuizStartTime: _data.hasOwnProperty('DateStart') && _data.hasOwnProperty('TimeStart') ? _data.DateStart + ' ' + _data.TimeStart + ':00' : '',
                liveQuizEndTime: _data.hasOwnProperty('DateEnd') && _data.hasOwnProperty('TimeEnd') ? _data.DateEnd + ' ' + _data.TimeEnd + ':00' : '',

                isRoomInfoLoaded: true,
                isRoomInfoLoading: false,
                FileUploadState: UploadState.Browse,
            });
        }
    }

    GetRoomDetail = async () => {
        let _data = null;
        let isRetryNeeded = false;
        //2021.11.23 - revamped.
        let loopAction = async () => {
            await this.props.dbLiveQuiz
                .ref('pkquiz/' + this.state.todayDT + '/pkquiz-room-detail/' + this.state.roomId)
                .once('value')
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        _data = snapshot.val();
                        if (this.props.isDevMode)
                            console.log(JSON.stringify(_data));
                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log('load room detail (error)\n' + error);
                    isRetryNeeded = true;
                });
        }
        do {
            isRetryNeeded = false;
            //Search Room in RTDB.
            await loopAction();
        } while (isRetryNeeded);
        if (_data !== null) {
            this.setState({ roomDetailModal: _data, isRoomDetailLoaded: true, isRoomDetailLoading: false, });
        }
    }

    GetGroup = async () => {
        let _group = [];
        await this.props.firestore
            .collection('QuizBank')
            .doc('QuizGroup')
            .collection('QuizGroups')
            .where('Id', '==', this.state.roomInfoModal.GroupId)
            .get()
            .then(querySnapshot => {
                if (querySnapshot !== null) {
                    let data = [];
                    querySnapshot.forEach((doc) => {
                        data.push(doc.data());
                    });
                    _group = data[0];
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('load group (error)\n' + error);
            });
        this.setState({ group: _group, });
    }

    //2021.10.27
    CheckRoomResult = async () => {
        let result = await this.GetPersonalResult();
        if (result) {
            this.setState({ hasRoomResult: true, isQuizStarted: true, }, () => {
                // this.props.SaveRoomInLocalHistoryList_V2({
                //     Title: this.state.roomInfoModal.RoomTitle + ' (' + this.state.roomInfoModal.Remark + ')',
                //     RoomCode: this.state.roomInfoModal.RoomCode,
                //     RoomId: this.state.roomInfoModal.RoomId,
                //     Date: moment().format("YYYY-MM-DD HH:mm:ss"),
                //     Score: this.state.personalResult.Score,
                // });
                let index = this.props.historyList.findIndex(el => el.RoomCode === this.state.roomInfoModal.RoomCode && el.RoomId === this.state.roomInfoModal.RoomId);
                if (index > -1) {
                    this.props.SaveRoomInLocalHistoryList_V2({
                        Title: this.state.roomInfoModal.RoomTitle + ' (' + this.state.roomInfoModal.Remark + ')',
                        RoomCode: String(this.state.roomInfoModal.RoomCode),
                        RoomId: String(this.state.roomInfoModal.RoomId),
                        Date: this.state.roomInfoModal.Date,
                        Score: this.state.personalResult.Score,
                        RoomType: Number(1),
                    });
                }

                this.SetParticipationOnEvent();
            });
        }
        else {
            this.ListenToRoomState();
        }
    }

    //2021.10.27
    GetPersonalResult = async () => {   //path = pkquiz/pkquiz-personal-data/uid
        let _data = null;
        let isRetryNeeded = false;
        //2021.11.23 - revamped.
        let loopAction = async () => {
            await this.props.dbLiveQuiz
                .ref('pkquiz/' + this.state.todayDT + '/pkquiz-personal-data/' + String(this.props.user.uid) + '/' + String(this.props.RoomId))
                .once('value')
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        _data = snapshot.val();
                        if (this.props.isDevMode)
                            console.log('personal result = ' + JSON.stringify(_data));
                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log('load personal result (error)\n' + error);
                    isRetryNeeded = true;
                });
        }
        do {
            isRetryNeeded = false;
            //Search Room in RTDB.
            await loopAction();
        } while (isRetryNeeded);
        if (_data !== null) {
            this.setState({
                personalResult: _data,
                FileUploadState: _data['ScoreIssuedDate'] === '' || _data['ScoreIssuedDate'] === undefined ?
                    UploadState.Browse : UploadState.None,
            });
            await this.CheckExtraProperties();    //2021.12.06
            return true;
        }
        return false;
    }

    //2021.12.06 - Fill CertSN & SchoolName if not found.
    CheckExtraProperties = async () => {
        let _personalResult = this.state.personalResult;
        if (_personalResult !== null) {
            let _modalToUpdate = {};
            if (_personalResult.hasOwnProperty('SchoolName') === false
                || _personalResult['SchoolName'] === undefined || _personalResult['SchoolName'] === '') {
                _personalResult.SchoolName = String(this.props.profile.School);     //for webUi.
                _modalToUpdate.SchoolName = String(this.props.profile.School);      //for RTDB.
            }

            let checkOnCertSN = false;
            if (_personalResult.hasOwnProperty('CertSN') === false)
                checkOnCertSN = true;
            else
                if (_personalResult.CertSN === '' || _personalResult.CertSN === undefined)
                    checkOnCertSN = true;

            if (checkOnCertSN) {
                //Get CertInfo.
                let _FS = null;
                await this.props.firestore
                    .collection("LiveQuiz_Certifications")
                    .doc(String(this.props.user.uid))
                    .collection('Certificates')
                    .where('EventCode', '==', this.state.eventData.EventCode)
                    .orderBy('CreatedOnUtc', 'asc')
                    .limit(1)
                    .get()
                    .then(querySnapshot => {
                        let dataArray = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                dataArray.push(doc.data());
                                dataArray[dataArray.length - 1].Id = doc.id;    //important
                            });
                            if (dataArray.length > 0) {
                                _FS = dataArray[0];
                            }
                            // console.log('(0) CertInfo =\n' + JSON.stringify(_List));
                        }
                    })
                    .catch(error => {
                        if (this.props.isDevMode)
                            console.log(error.message);
                    });
                if (_FS !== null) {
                    let updateCertInfo = false;
                    if (_FS.hasOwnProperty('CertSN')) {
                        if (_FS.CertSN !== _personalResult.CertSN) {
                            _personalResult.CertSN = String(_FS.CertSN);
                            _modalToUpdate.CertSN = String(_FS.CertSN);
                            updateCertInfo = true;
                        }
                    }
                    // else {
                    //     _personalResult.CertSN = String(_FS.CertSN);
                    //     _modalToUpdate.CertSN = String(_FS.CertSN);
                    //     updateCertInfo = true;
                    // }

                    //update CertInfo.
                    if (updateCertInfo) {
                        await this.props.dbLiveQuiz
                            .ref('pkquiz/' + this.state.todayDT + '/pkquiz-personal-data/' + String(this.props.user.uid) + '/' + String(this.props.RoomId))
                            .update(_modalToUpdate)
                            .catch(error => { if (this.props.isDevMode) { console.log('update cert info (failed).\n' + error); } });

                        //update local data.
                        this.setState({ personalResult: _personalResult });
                    }
                    //done.
                }
            }
            if (this.props.isDevMode)
                console.log('\npersonal result\nextra properties = checked.');
        }
    }

    ListenToRoomState = async () => {
        if (this.state.hasRoomResult === false) {
            let index = this.props.historyList.findIndex(el => el.RoomCode === this.state.roomInfoModal.RoomCode && el.RoomId === this.state.roomInfoModal.RoomId);
            if (index < 0) {
                this.props.SaveRoomInLocalHistoryList_V2({
                    Title: this.state.roomInfoModal.RoomTitle + ' (' + this.state.roomInfoModal.Remark + ')',
                    RoomCode: String(this.state.roomInfoModal.RoomCode),
                    RoomId: String(this.state.roomInfoModal.RoomId),
                    Date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    Score: 0,
                    RoomType: Number(1),
                });
            }
        }
        if (this.state.isQuizStarted === false) {
            do {
                this.props.dbLiveQuiz
                    .ref('pkquiz/' + this.state.todayDT + '/pkquiz-live/' + this.state.roomId)
                    .once('value', snapshot => {
                        if (snapshot.exists()) {
                            let _state = snapshot.val();
                            this.setState({
                                roomState: _state,
                                // isQuizStarted: _state.hasOwnProperty('QuizState') ?
                                //     String(_state['QuizState']).toLowerCase() === 'started'
                                //     : false,
                                isQuizStarted: moment() >= moment(moment(_state['DateStart']).format('YYYY-MM-DD ') + String(_state['TimeStart']) + ':00'),
                            });
                        }
                    });
                await Delay(1500);
            } while (this.state.isQuizStarted === false);
        }
        // .catch(error => {
        //     if (this.props.isDevMode)
        //         console.log('listen room state (error)\n' + error);
        // });
    }

    RemoveListenToRoomState = async () => {
        await this.props.dbLiveQuiz
            .ref('pkquiz/' + this.state.todayDT + '/pkquiz-live/' + this.state.roomId)
            .off();
    }

    FileUploadComponentUi = () => {
        //0 = browse file, 1 = wrong file format, 2 = uploading, 3 = upload failed, 4 = upload success.
        switch (this.state.FileUploadState) {
            default: return null;
            case UploadState.Browse:
                return (<>
                    <span>Browse for a file to upload </span>
                    <span style={{ color: 'gray', paddingBottom: 10, }}>(supported file format: <b><i>{this.GetSupportedFileExt_Label()}</i></b> )</span><p />
                    <input
                        type="file"
                        name="file"
                        accept={this.GetSupportedFileExt_Accept()}
                        onChange={this.onUploadFileChange}
                        style={{ width: '100%' }}
                    />
                </>);
            case UploadState.Validation:
                return (<>
                    <span>Validating file...</span>
                    <ProgressBar animated now={100} style={{ marginTop: 7, }} />
                </>);
            case UploadState.Uploading:
                return (<>
                    <span>Uploading file...</span>
                    <ProgressBar animated now={100} style={{ marginTop: 7, }} />
                </>);
            case UploadState.Failed:
                return (<>
                    {this.state.UploadErrorMessage.map((data, key) => { return <>{data}<br /></>; })}
                    <button type='button btn-primary'
                        onClick={() => this.setState({ FileToUpload: null, FileUploadState: UploadState.Browse, UploadErrorMessage: [], })}>Retry</button>
                </>);
            case UploadState.Success:
                return (<>
                    <span>Updating data...</span>
                    <ProgressBar animated now={100} style={{ marginTop: 7, }} />
                </>);
        }
    }

    onUploadFileChange = (event) => {
        // {

        //     lastModified: 1588350162449
        //     lastModifiedDate: Fri May 01 2020 17:22:42 GMT+0100 (British Summer Time) {} // Date object
        //     name: "Pluralsight_logo.png"
        //     size: 68317
        //     type: "image/png"
        //     webkitRelativePath: ""
        //     __proto__: File
        // }
        this.setState({
            FileToUpload: event === null ? null : event.target.files[0],
            FileUploadState: UploadState.Validation,
        }, () =>
            setTimeout(() => {
                this.ValidateFileFormat();
            }, 1000)
        );
    };

    //2021.11.08
    GetFileExt = (fileName) => {
        if (fileName.includes('.txt')) {
            return '.txt';
        }
        else if (fileName.includes('.rtf')) {
            return '.rtf';
        }
        else if (fileName.includes('.docx')) {
            return '.docx';
        }
        else if (fileName.includes('.doc')) {
            return '.doc';
        }
        return '';
    }

    //2021.11.08
    GetFileType = (fileName) => {
        if (fileName.includes('.txt')) {
            return 'text/plain';
        }
        else if (fileName.includes('.rtf')) {
            return 'application/rtf';
        }
        else if (fileName.includes('.docx')) {
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }
        else if (fileName.includes('.doc')) {
            return 'application/msword';
        }
        return '';
    }

    //2021.11.08
    GetSupportedFileExt_Label = () => {
        if (this.state.roomInfoModal.hasOwnProperty('SupportedDocExt')) {
            if (this.state.roomInfoModal.SupportedDocExt.length > 0) {
                let fileExts = '';
                this.state.roomInfoModal.SupportedDocExt.map((data, key) => {
                    fileExts += data;
                    if (key < this.state.roomInfoModal.SupportedDocExt.length - 1)
                        fileExts += ', ';
                    return null;
                });
                if (fileExts.length > 0)
                    return fileExts;
            }
        }
        return '.txt, .rtf, .doc, .docx, .pdf';
    }

    //2021.11.08
    GetSupportedFileExt_Accept = () => {
        if (this.state.roomInfoModal.hasOwnProperty('SupportedDocExt')) {
            if (this.state.roomInfoModal.SupportedDocExt.length > 0) {
                let fileExts = '';
                this.state.roomInfoModal.SupportedDocExt.map((data, key) => {
                    if (data.includes('.txt')) {
                        fileExts += 'text/plain';
                    }
                    else if (data.includes('.rtf')) {
                        fileExts += 'application/rtf';
                    }
                    else if (data.includes('.docx')) {
                        fileExts += 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                    }
                    else if (data.includes('.doc')) {
                        fileExts += 'application/msword';
                    }
                    else if (data.includes('.pdf')) {
                        fileExts += 'application/pdf';
                    }
                    if (key < this.state.roomInfoModal.SupportedDocExt.length - 1)
                        fileExts += ',';
                    return null;
                });
                if (fileExts.length > 0)
                    return fileExts;
            }
        }
        return 'text/plain,application/rtf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }

    ValidateFileFormat = () => {
        let fileExt = '';
        let fileName = String(this.state.FileToUpload.name).toLowerCase();
        let fileType = String(this.state.FileToUpload.type).toLowerCase();
        // let isValid = fileType.includes('doc') || fileType.includes('txt') || fileType.includes('pdf');
        let uploadErrorMessage = [];

        if (this.props.isDevMode) {
            console.log('file type = ' + fileType);
            console.log('file name = ' + fileName);
        }

        //check file type.
        // 'text/plain,application/rtf,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        // let isValid = true;
        // if (fileName.includes('.txt')) {
        //     fileExt = '.txt';
        //     fileType = 'text/plain';
        // }
        // else if (fileName.includes('.rtf')) {
        //     fileExt = '.rtf';
        //     fileType = 'application/rtf';
        // }
        // else if (fileName.includes('.docx')) {
        //     fileExt = '.docx';
        //     fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        // }
        // else if (fileName.includes('.doc')) {
        //     fileExt = '.doc';
        //     fileType = 'application/msword';
        // }
        // else {
        //     isValid = false;
        // }

        //check file type. - 2021.11.08 - revamped.
        let isValid = true;
        fileExt = this.GetFileExt(fileName);
        fileType = this.GetFileType(fileName);
        if (fileExt === '' || fileType === '') {
            isValid = false;
        }

        //result
        if (isValid) {
            //proceed.
            this.setState({
                FileUploadState: UploadState.Uploading,
            }, () => {
                this.UploadFileToServerViaAPi(fileExt, fileType);
            });
        }
        else {
            //wrong file type.
            if (fileType === '') {
                let splits = fileName.split('.');
                // fileExt = '.' + splits[splits.length - 1];
                fileType = splits[splits.length - 1].toUpperCase();
            }
            uploadErrorMessage.push(<span>Current attached File format ({fileType}) is not allow.<br />Please select another file with appropriate file format.</span>);

            this.setState({
                FileUploadState: UploadState.Failed,
                FileToUpload: null,
                UploadErrorMessage: uploadErrorMessage,
            });
        }
    }

    UploadFileToServerViaAPi = async (fileExt, fileType) => {
        let file = this.state.FileToUpload;
        // let fileType = String(this.state.FileToUpload.type).toLowerCase();
        // fileType = fileType.includes('/') ? fileType.split('/')[1] : fileType;
        // let fileExt = String(this.state.FileToUpload.value).split('.')[1].toLowerCase();

        if (file !== null && fileType !== '') {
            let isUploadDone = false;
            let errorMessage = '';
            let apiResponse = null;

            //filename.
            // let studentName = this.props.profile !== null ?
            //     '_' + String(this.props.profile.Name).replaceAll(' ', '-').toLowerCase()
            //     : '';
            // let fileName = this.state.roomCode + studentName + '_' + String(this.props.email).toLowerCase();
            let fileName = this.state.roomCode + '_'
                // + String(this.props.profile.Name).replaceAll(' ', '-').replaceAll('/', '-').toLowerCase()
                + String(this.props.profile.Name)
                    // eslint-disable-next-line
                    .replaceAll(/[$-/:-?{-~!"^_`\[\]\@\#\s]/g, '-')     //to replace all possible symbols & spaces to -.
                    .toLowerCase()
                + '_' + this.RandomId();

            //Convert File to Base64.
            let file_base64 = '';
            // if (this.state.FileToUpload !== null) {
            // let reader = new FileReader();
            // reader.onload = (evt) => {
            //     // let binaryString = evt.target.result;
            //     // file_base64 = btoa(binaryString);

            //     // file_base64 = evt.target.result

            //     // let binaryString = reader.result;
            //     // file_base64 = btoa(binaryString);

            //     // console.log('evt.target.result = ' + evt.target.result);
            //     // console.log('reader.result = ' + reader.result);

            //     // var array = new Uint32Array(this.state.FileToUpload); // read the actual file contents
            //     // file_base64 = String.fromCharCode.apply(null, array);

            //     file_base64 = evt.target.result;
            // };
            // reader.readAsDataURL(this.state.FileToUpload);
            // }
            // console.log('file_base64 = ' + file_base64.length);

            file_base64 = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    resolve(event.target.result);
                };
                reader.onerror = (err) => {
                    reject(err);
                };
                reader.readAsDataURL(this.state.FileToUpload);
            });
            file_base64 = file_base64.split(',')[1];

            // const formData = new FormData();
            // formData.append('File', this.state.FileToUpload);
            // file_base64 = btoa(this.state.FileToUpload);
            if (this.props.isDevMode)
                console.log('file_base64 = ' + file_base64.length);
            if (file_base64.length <= 0) {
                this.setState({ FileUploadState: UploadState.Failed, UploadErrorMessage: ['file size = ' + file_base64.length], });
                return null;
            }

            //modal.
            let jsonModel = {
                // CenterUserId: this.props.isAuthor ? Number(this.props.user.CenterUserId) : '',
                // AuthorId: this.props.isAuthor ? Number(this.props.user.AuthorId) : '',
                // AuthorRoleId: this.props.isAuthor ? Number(this.props.user.AuthorRoleId) : '',    //1 = admin, 4 = center, 11 = Author
                FirebaseUserId: String(this.props.user.uid),

                RoomTimestampId: String(this.props.RoomId),
                RoomCode: String(this.props.RoomCode),
                RoomInfo: JSON.stringify(this.state.roomInfoModal),

                UserName: this.props.profile !== undefined && this.props.profile !== null ? (this.props.profile.hasOwnProperty('Name') ? String(this.props.profile.Name) : '') : '',
                UserEmail: this.props.email !== undefined && this.props.email !== '' ? String(this.props.email) : '',

                FileName: fileName.toLowerCase(),
                FileType: fileType.toLowerCase(),
                FileExt: fileExt.toLowerCase(),
                FileBase64: file_base64,

                UploadFileDate: moment.utc().format('YYYY-MM-DD HH:mm:ss.sss'),
                RoomResultPath: 'pkquiz/' + this.state.todayDT + '/pkquiz-personal-data/' + String(this.props.user.uid) + '/' + String(this.props.RoomId),
                RoomStudentListingPath: 'pkquiz/' + this.state.todayDT + '/pkquiz-ranking-live/' + String(this.props.RoomId) + '/' + String(this.props.user.uid),

                //2021.11.13
                // CertSN: '',
                SchoolName: this.props.profile.hasOwnProperty('School') ?
                    String(this.props.profile.School).length > 0 ? String(this.props.profile.School) : ''
                    : '',
            };

            if (this.props.isDevMode)
                console.log(JSON.stringify(jsonModel));

            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/File/Upload',
                {
                    method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                    // mode: 'cors',                            // no-cors, *cors, same-origin
                    // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: 'same-origin',                 // include, *same-origin, omit
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    // redirect: 'follow',                         // manual, *follow, error
                    // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(jsonModel), // body data type must match "Content-Type" header
                })
                .then(res => res.json())
                .then(data => {
                    isUploadDone = data.success;
                    if (data.success)
                        apiResponse = data;
                    else
                        errorMessage = 'api - room - file upload (failed)\n' + JSON.stringify(data);
                })
                .catch(error => {
                    errorMessage = 'Error : ' + error.message;
                });

            await Delay(1000);
            if (isUploadDone === false) {
                this.setState({ FileUploadState: UploadState.Failed, UploadErrorMessage: [errorMessage] });
            }
            else {
                this.setState({
                    FileUploadState: UploadState.Success, FileToUpload: null,
                }, () => {
                    this.CheckRoomResult();
                    this.props.SetAlert('', Locale("file-upload-success", this.props.Locale), false);
                });
            }
            if (this.props.isDevMode)
                console.log(errorMessage.length > 0 ? errorMessage : JSON.stringify(apiResponse));
        }
    }

    //2021.04.06
    SetParticipationOnEvent = async () => {

        // if (this.state.isAllAnswered === false)
        //     return null;

        //check if this room belongs to any event.
        let isEventQuiz = { Result: false, EventCode: '', EventModal: null };
        isEventQuiz = this.props.CheckIfRoomBelongsToEvent(this.state.roomCode);
        if (this.props.isDevMode)
            console.log(JSON.stringify(isEventQuiz));
        // console.log('SetParticipationOnEvent > CheckIfRoomBelongsToEvent = ' + isEventQuiz.Result + ' | ' + isEventQuiz.EventCode);

        if (isEventQuiz.Result) {
            //2021.09.14
            if (moment() <= moment(isEventQuiz.EventModal.DateStart).add(7, 'days')) {

                //2021.09.08 - add retry.
                let retryAgain = false;
                // do {
                //     retryAgain = false;

                //check if collection/doc exits.
                let isEventExists = false;
                let isCounterExists = false;
                let counter = 0;

                let done = false;

                //check on counter record, to know whether its fresh or existed.
                let async_action_1 = async () => {
                    await this.props.firestore
                        .collection('LiveQuiz_Certifications')
                        .where('Uid', '==', String(this.props.user.uid))
                        .get()
                        .then((querySnapshot) => {
                            let data = [];
                            if (querySnapshot !== null) {
                                data = querySnapshot.docs.map(doc => doc.data())[0];
                            }
                            if (data !== undefined && data.hasOwnProperty('Counter')) {
                                isCounterExists = true;
                                counter = Number(data.Counter);
                            }
                            done = true;
                            if (this.state.isDevMode)
                                console.log('Counter (' + (isCounterExists ? 'exist' : 'not-exist') + ') =\n' + JSON.stringify(data) + ' | ' + counter);
                        })
                        .catch((error) => {
                            retryAgain = true;
                            done = true;
                        });
                    await DelayUntil(() => done === true);
                }
                do {
                    retryAgain = false;
                    done = false;
                    await async_action_1();
                    // await DelayUntil(() => done === true);
                } while (retryAgain);

                //check for certificate of current event.
                let async_action_2 = async () => {
                    await this.props.firestore
                        .collection('LiveQuiz_Certifications')
                        .doc(String(this.props.user.uid))
                        .collection('Certificates')
                        .where('EventCode', '==', isEventQuiz.EventCode)
                        .get()
                        .then((querySnapshot) => {
                            let data = [];
                            if (querySnapshot !== null) {
                                data = querySnapshot.docs.map(doc => doc.data())[0];
                            }
                            if (data !== undefined && data.hasOwnProperty('EventCode')) {
                                isEventExists = true;
                            }
                            done = true;
                            if (this.state.isDevMode)
                                console.log('Event (' + (isEventExists ? 'exist, new cert record not needed' : 'not-exist') + ') =\n' + JSON.stringify(data));
                        })
                        .catch((error) => {
                            retryAgain = true;
                            done = true;
                        });
                    await DelayUntil(() => done === true);
                }
                do {
                    retryAgain = false;
                    done = false;
                    await async_action_2();
                    // await DelayUntil(() => done === true);
                } while (retryAgain);

                //if no record found.
                if (isCounterExists === false) {
                    //set initial search target.
                    let async_action_3 = async () => {
                        await this.props.firestore
                            .collection('LiveQuiz_Certifications')
                            .doc(String(this.props.user.uid))
                            .set({ Uid: String(this.props.user.uid), Counter: 1, LastUpdatedOnUtc: moment().utc().format('YYYY-MM-DD HH:mm:ss') })
                            .then(() => {
                                done = true;
                            })
                            .catch((error) => {
                                retryAgain = true;
                                done = true;
                            });
                        await DelayUntil(() => done === true);
                    }
                    do {
                        retryAgain = false;
                        done = false;
                        await async_action_3();
                        // await DelayUntil(() => done === true);
                    } while (retryAgain);
                    if (this.state.isDevMode)
                        console.log('new record created. | Counter = 1');
                }
                else if (isCounterExists && !isEventExists) {
                    //update counter
                    let newCounter = counter + 1;
                    let async_action_4 = async () => {
                        await this.props.firestore
                            .collection('LiveQuiz_Certifications')
                            .doc(String(this.props.user.uid))
                            .update({ Counter: newCounter, LastUpdatedOnUtc: moment().utc().format('YYYY-MM-DD HH:mm:ss') })
                            .then(() => {
                                done = true;
                            })
                            .catch((error) => {
                                retryAgain = true;
                                done = true;
                            });
                        await DelayUntil(() => done === true);
                    }
                    do {
                        retryAgain = false;
                        done = false;
                        await async_action_4();
                        // await DelayUntil(() => done === true);
                    } while (retryAgain);
                    if (this.state.isDevMode)
                        console.log('certification counter updated.\nCounter = ' + newCounter);
                }

                //set certificates collection.
                if (isEventExists === false) {

                    //#region changed plan - only will set serial when set score. 2021.11.12
                    // //2021.11.08 === generate serial number.
                    // if (isEventQuiz.EventModal.hasOwnProperty('CertSerialPrefix')) {
                    //     if (String(isEventQuiz.EventModal.CertSerialPrefix).length > 0) {

                    //         //2021.11.12 - revamped.
                    //         // let eventCode = String(isEventQuiz.EventModal.EventCode);
                    //         let eventCode = '883f207e4ab84f52af5494eac658bc8a';

                    //         //Check if value exist.
                    //         let isSerialCounterExist = false;
                    //         let serialCounterModal = {
                    //             CertSerialPrefix: '',
                    //             EventCode: '',
                    //             Counter: 0,
                    //         };
                    //         await this.props.dbLQ_SN_Counter.ref(eventCode).then(snapshot => {
                    //             if (snapshot.exists) {
                    //                 isSerialCounterExist = true;
                    //                 serialCounterModal = snapshot.data();
                    //             }
                    //         }).catch(error => { console.log('SN Counter (Fetch) Error = ' + error) });

                    //         //Generate a new SN for this cert.
                    //         if (isSerialCounterExist) {
                    //             let updates = {};
                    //             updates['Counter'] = this.props.dbLQ_SN_Counter.FieldValue.increment(1);
                    //             serialCounterModal.Counter = updates['Counter'];
                    //             await this.props.dbLQ_SN_Counter.ref(eventCode).update(updates);
                    //         }
                    //         else {
                    //             serialCounterModal = {
                    //                 CertSerialPrefix: String(isEventQuiz.EventModal.CertSerialPrefix),
                    //                 EventCode: eventCode,
                    //                 Counter: 1,
                    //             };
                    //             await this.props.dbLQ_SN_Counter.ref(eventCode).set(serialCounterModal);
                    //         }

                    //         //Fill the returned SN into this cert info.
                    //         let fullTextCounter = '';
                    //         if (String(serialCounterModal.Counter).length < 6) {
                    //             let loopCount = 6 - String(serialCounterModal.Counter).length;
                    //             for (var i = 0; i < loopCount; i++)
                    //                 fullTextCounter += '0';
                    //             fullTextCounter += String(serialCounterModal.Counter);
                    //         }
                    //         else {
                    //             fullTextCounter = String(serialCounterModal.Counter);
                    //         }
                    //         isEventQuiz.EventModal.CertSN = String(isEventQuiz.EventModal.CertSerialPrefix) + fullTextCounter;
                    //     }
                    // }
                    //#endregion

                    //original codes.
                    let _studentName = String(this.props.profile.Name);
                    let _current = moment.utc();    //2021.12.17
                    let async_action_5 = async () => {
                        await this.props.firestore
                            .collection('LiveQuiz_Certifications')
                            .doc(String(this.props.user.uid))
                            .collection('Certificates')
                            .doc(_current.valueOf().toString())
                            .set({
                                CertDownloadEnabled: isEventQuiz.EventModal.CertDownloadEnabled,    //2021.07.05
                                Participant: _studentName,
                                EventCode: String(isEventQuiz.EventCode),
                                CreatedOnUtc: _current.format('YYYY-MM-DD HH:mm:ss'),        //YYYY-MM-DD HH:mm:ss.SSS

                                //2021.11.08 === add serial number & school name.
                                CertSN: '', //isEventQuiz.EventModal.CertSN === undefined ? '' : String(isEventQuiz.EventModal.CertSN),
                                SchoolName: this.props.profile.hasOwnProperty('School') ?
                                    String(this.props.profile.School).length > 0 ? String(this.props.profile.School) : ''
                                    : '',
                            })
                            .then(() => {
                                done = true;
                            })
                            .catch((error) => {
                                retryAgain = true;
                                done = true;
                            });
                        await DelayUntil(() => done === true);
                    }
                    do {
                        retryAgain = false;
                        done = false;
                        await async_action_5();
                        // await DelayUntil(() => done === true);
                    } while (retryAgain);
                    if (this.state.isDevMode)
                        console.log('<' + _studentName + '> new certificate is added.');
                }

                // } while (retryAgain);
            }
        }
    }

    //2021.12.06
    //(previously named as) EventSharedReportUrlComponent
    EventRoomExtraUrlComponent = () => {
        let _component = <i>{Locale("room-essay-report-url-pending", this.props.Locale)}</i>;
        if (this.state.personalResult.hasOwnProperty('CertSN'))
            if (this.state.personalResult.CertSN !== '')
                // if (this.state.eventData !== null)
                //     if (this.state.eventData.hasOwnProperty('EventSharedReportUrl'))
                //         if (this.state.eventData.EventSharedReportUrl.length > 0)
                //             _component = <a href={String(this.state.eventData.EventSharedReportUrl)} target='_blank' rel='noopener noreferrer'><i>{Locale("click-here-link", this.props.Locale)}</i></a>
                //2021.12.10
                if (this.state.eventData !== null)
                    if (this.state.eventData.hasOwnProperty('CertDownloadDelayed'))
                        if (this.state.eventData.CertDownloadDelayed === false)
                            if (this.state.roomInfoModal !== null)
                                if (this.state.roomInfoModal.hasOwnProperty('ExtraUrl'))
                                    if (this.state.roomInfoModal.ExtraUrl !== '')
                                        _component = <a href={String(this.state.roomInfoModal.ExtraUrl)} target='_blank' rel='noopener noreferrer'><i>{Locale("participant-submitted-entries", this.props.Locale)}</i></a>

        return _component;
    }
    EventRoomSerialNumberComponent = () => {
        let _component = <>{Locale("pending", this.props.Locale)}</>;
        if (this.state.eventData !== null)
            if (this.state.eventData.hasOwnProperty('CertDownloadDelayed'))
                if (this.state.eventData.CertDownloadDelayed === false)
                    if (this.state.personalResult !== null)
                        if (this.state.personalResult.hasOwnProperty('CertSN'))
                            _component = <b>{String(this.state.personalResult.CertSN)}</b>;
        return _component
    }

    render() {
        if (!this.props.user) {
            return <Redirect link="/login" />;   //back to login screen.
        }
        else if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <>
                    <div style={{
                        backgroundColor: 'transparent',
                        height: !this.state.isRoomInfoLoaded ? '100%' : 'auto',
                        width: '100%',
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 0,
                        // paddingTop: 25,
                        paddingTop: window.innerWidth < 576 ? 0 : 25,   //2020.12.29
                    }}>
                        <div className="container" style={{
                            maxWidth: '650px',
                            backgroundColor: 'lavender',
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 5
                        }}>
                            <div className="row" key='quizRoomTypeUploadFile'>
                                <aside className="col-sm-12">
                                    <div className="card" style={{ backgroundColor: '#007bff' }}>
                                        <article className="card-body text-center">

                                            <span style={{
                                                color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                                float: "left"
                                            }}>{"Live Quiz (" + this.state.roomCode + ") "}</span>

                                            <LocalizationSwitcher
                                                Locale={this.props.Locale}
                                                SetLocaleSetting={this.props.SetLocaleSetting}
                                            />

                                        </article>
                                    </div>

                                    <span>&nbsp;</span>

                                    {
                                        this.state.isRoomInfoLoaded ?
                                            <>
                                                <div className="card">
                                                    <article className="card-body">

                                                        <div className="row custyle">
                                                            <table className="table table-striped custab table-result">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>{Locale("room-code", this.props.Locale)} {this.state.roomCode}</th>
                                                                        <td>{this.state.roomInfoModal.RoomTitle}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{Locale("subject", this.props.Locale)}</th>
                                                                        <td>{this.state.roomInfoModal.SubjectName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{String(Locale("label-grade", this.props.Locale)).replace(new RegExp(' ', 'g'), '')}</th>
                                                                        <td>{this.state.group['Name']}</td>
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <th>{Locale("no-of-qs", this.props.Locale)}</th>
                                                                        <td>{this.state.roomDetailModal === null ? '' : this.state.roomDetailModal.QnQty}</td>
                                                                    </tr> */}
                                                                    <tr>
                                                                        <th>{Locale("room-essay-title", this.props.Locale)}</th>
                                                                        <td>{this.state.roomInfoModal.Remark}</td>
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <th>{Locale("total-duration", this.props.Locale)}</th>
                                                                        <td><span className="blink" style={{ fontWeight: 'bold', color: 'red' }}>{this.FormatedDuration(this.state.roomInfoModal.Duration)}</span></td>
                                                                    </tr> */}
                                                                    <tr>
                                                                        <th>{Locale("held-date", this.props.Locale)}</th>
                                                                        <td>{
                                                                            // moment(this.state.todayDT).format("ll")
                                                                            //2021.07.19
                                                                            this.state.roomInfoModal.hasOwnProperty('DateStart') ?
                                                                                this.state.roomInfoModal.DateStart === '' ?
                                                                                    moment(this.state.roomInfoModal.Date).format("ll")     //2021.09.14
                                                                                    :
                                                                                    this.state.roomInfoModal.DateStart === this.state.roomInfoModal.DateEnd ?
                                                                                        moment(this.state.roomInfoModal.DateStart).format("ll")
                                                                                        : moment(this.state.roomInfoModal.DateStart).format("ll") + ' ~ ' + moment(this.state.roomInfoModal.DateEnd).format("ll")
                                                                                : moment(this.state.todayDT).format("ll")
                                                                            // this.state.roomInfoModal.Date     //2021.09.14
                                                                        }</td>
                                                                    </tr>
                                                                    {
                                                                        this.state.liveQuizStartTime !== '' && this.state.liveQuizStartTime !== '_none' ?
                                                                            <tr>
                                                                                <th>{Locale("start-time", this.props.Locale)}</th>
                                                                                <td>{moment(this.state.liveQuizStartTime).format("LT")}</td>
                                                                            </tr>
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.state.liveQuizEndTime !== '' && this.state.liveQuizEndTime !== '_none' ?
                                                                            <tr>
                                                                                <th>{Locale("end-time", this.props.Locale)}</th>
                                                                                <td>{moment(this.state.liveQuizEndTime).format("LT")}</td>
                                                                            </tr>
                                                                            : null
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </article>
                                                </div>

                                                <span>&nbsp;</span>

                                                <div hidden={!this.state.hasRoomResult}>
                                                    <div className="card">
                                                        <article className="card-body">
                                                            {
                                                                this.state.personalResult === null ? null :
                                                                    // <table className='table ' cellPadding='0' cellSpacing='' width='100%' border='0' style={{ borderTop: 0 }}>
                                                                    <table className='table tbStyle1' cellPadding='10' cellSpacing='10' border='0'
                                                                        style={{ marginBottom: 0, borderStyle: 'hidden', fontWeight: 'bold', }}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{Locale("your-name", this.props.Locale)}</td>
                                                                                <td>{this.state.personalResult['Participant']}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{Locale("file-uploaded", this.props.Locale)}</td>
                                                                                <td>{this.state.personalResult['UploadedFileName']}  <button className='btn btn-primary'
                                                                                    style={{ padding: '0px 10px 0px 10px' }}
                                                                                    onClick={() => window.open('https://ikeynew.blob.core.windows.net/ikeyquiz/' + this.state.roomId + '/' + this.state.personalResult['UploadedFileName'], '_blank')}
                                                                                ><i className='fa fa-cloud-download'></i></button></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{Locale("file-upload-date", this.props.Locale)}</td>
                                                                                <td><i>{moment.utc(this.state.personalResult['FileUploadedDate']).local().format('lll')}</i></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{Locale("quiz-score", this.props.Locale)}</td>
                                                                                <td>{this.state.personalResult['Score'] + '%'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{Locale("score-issue-date", this.props.Locale)}</td>
                                                                                <td><i>{
                                                                                    this.state.personalResult['ScoreIssuedDate'] === ''
                                                                                        || this.state.personalResult['ScoreIssuedDate'] === undefined ?
                                                                                        Locale("not-yet-specify-result", this.props.Locale)
                                                                                        :
                                                                                        moment.utc(this.state.personalResult['ScoreIssuedDate']).local().format('lll')
                                                                                }</i></td>
                                                                            </tr>
                                                                            <tr hidden={
                                                                                false
                                                                                // this.state.eventData !== null ?
                                                                                //     this.state.eventData.hasOwnProperty('EventSharedReportUrl') ?
                                                                                //         String(this.state.eventData.EventSharedReportUrl).length > 0 ?
                                                                                //             false
                                                                                //             : true
                                                                                //         : true
                                                                                //     : true
                                                                            }>
                                                                                <td>{Locale("room-essay-report-label", this.props.Locale)}</td>
                                                                                {/* <td>{
                                                                                    this.state.eventData !== null ?
                                                                                        this.state.eventData.hasOwnProperty('EventSharedReportUrl') ?
                                                                                            this.state.eventData.EventSharedReportUrl.length > 0 ?
                                                                                                <a href={String(this.state.eventData.EventSharedReportUrl)} target='_blank' rel='noopener noreferrer'><i>{Locale("click-here-link", this.props.Locale)}</i></a>
                                                                                                : <i>{Locale("room-essay-report-url-pending", this.props.Locale)}</i>
                                                                                            : <i>{Locale("room-essay-report-url-pending", this.props.Locale)}</i>
                                                                                        : <i>{Locale("room-essay-report-url-pending", this.props.Locale)}</i>
                                                                                }</td> */}
                                                                                <td>{this.EventRoomExtraUrlComponent()}</td>
                                                                            </tr>
                                                                            <tr hidden={
                                                                                this.state.personalResult !== null ?
                                                                                    this.state.personalResult.hasOwnProperty('CertSN') ?
                                                                                        this.state.personalResult.CertSN !== undefined || this.state.personalResult.CertSN !== '' ?
                                                                                            false
                                                                                            : true
                                                                                        : true
                                                                                    : true
                                                                            }>
                                                                                <td>{Locale("room-essay-cert-sn-label", this.props.Locale)}</td>
                                                                                <td><i>{this.EventRoomSerialNumberComponent()
                                                                                    // this.state.eventData !== null ?
                                                                                    //     this.state.eventData.hasOwnProperty('CertDownloadDelayed') ?
                                                                                    //         this.state.eventData.CertDownloadDelayed === false ?
                                                                                    //             this.state.personalResult !== null ?
                                                                                    //                 this.state.personalResult.hasOwnProperty('CertSN') ?
                                                                                    //                     String(this.state.personalResult.CertSN)
                                                                                    //                     : ''
                                                                                    //                 : ''
                                                                                    //             : ''
                                                                                    //         : ''
                                                                                    //     : ''
                                                                                }</i></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                            }
                                                        </article>
                                                    </div>
                                                    <span>&nbsp;</span>
                                                </div>

                                                <div hidden={
                                                    this.state.hasRoomResult === false ? false
                                                        : this.state.personalResult['ScoreIssuedDate'] === ''
                                                            || this.state.personalResult['ScoreIssuedDate'] === undefined ? false : true
                                                }>
                                                    <div className="card">
                                                        <article className="card-body">
                                                            {
                                                                this.state.isQuizEnded === false ?
                                                                    this.state.isQuizStarted === false ?
                                                                        <div dangerouslySetInnerHTML={{ __html: Locale("waiting-to-start", this.props.Locale) }} />
                                                                        :
                                                                        this.FileUploadComponentUi()
                                                                    :
                                                                    Locale("room-essay-upload-ended", this.props.Locale)
                                                            }
                                                        </article>
                                                    </div>
                                                    <span>&nbsp;</span>
                                                </div>
                                            </>
                                            : null
                                    }

                                    {
                                        this.state.isRoomInfoLoading || this.state.isRoomDetailLoading ?
                                            <>
                                                <div className="card">
                                                    <article className="card-body">
                                                        <ProgressBar animated now={100} />
                                                    </article>
                                                </div>
                                                <span>&nbsp;</span>
                                            </>
                                            : null
                                    }

                                    <div className="card">
                                        <article className="card-body">
                                            <button type="button" className="btn btn-primary btn-block"
                                                onClick={() => this.GotoPage('/home')}
                                            >{Locale("back-to-home", this.props.Locale)}</button>
                                        </article>
                                    </div>

                                </aside>
                            </div>
                        </div>

                        <div style={{ height: '200px', width: '100%' }}>
                            <span>&nbsp;</span>
                        </div>
                    </div>
                </>
            );
        }
    }
}