//Listing last update from Gov site: 2022 June.
// https://www.moe.gov.my/en/muat-turun/laporan-dan-statistik/senarai-sekolah

//2022.10.05
//2022.06.13
const SchoolListWithDetails = [
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0001",
    "Name": "SEKOLAH KEBANGSAAN TOH TANDEWA SAKTI",
    "Student": 461,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0002",
    "Name": "SEKOLAH KEBANGSAAN PENDITA ZA'BA",
    "Student": 430,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0003",
    "Name": "SEKOLAH KEBANGSAAN BANIR",
    "Student": 39,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0004",
    "Name": "SEKOLAH KEBANGSAAN TEMOH",
    "Student": 110,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0005",
    "Name": "SEKOLAH KEBANGSAAN CHENDERIANG",
    "Student": 170,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0006",
    "Name": "SEKOLAH KEBANGSAAN BIDOR",
    "Student": 267,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0007",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG POH",
    "Student": 100,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0008",
    "Name": "SEKOLAH KEBANGSAAN BATU TIGA",
    "Student": 196,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0009",
    "Name": "SEKOLAH KEBANGSAAN BATU MELINTANG",
    "Student": 135,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0010",
    "Name": "SEKOLAH KEBANGSAAN HAJI HASAN",
    "Student": 257,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0011",
    "Name": "SEKOLAH KEBANGSAAN SRI KINJANG",
    "Student": 179,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0012",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG PAHANG",
    "Student": 156,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0013",
    "Name": "SEKOLAH KEBANGSAAN BATU TUJUH",
    "Student": 495,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0014",
    "Name": "SEKOLAH KEBANGSAAN JERAM MENGKUANG",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0015",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LESONG",
    "Student": 276,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0016",
    "Name": "SEKOLAH KEBANGSAAN AYER KUNING",
    "Student": 187,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0017",
    "Name": "SEKOLAH KEBANGSAAN BATU MASJID",
    "Student": 74,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0018",
    "Name": "SEKOLAH KEBANGSAAN SUNGKAI",
    "Student": 485,
    "Teacher": 47
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0019",
    "Name": "SEKOLAH KEBANGSAAN PERLOK",
    "Student": 169,
    "Teacher": 20
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0020",
    "Name": "SEKOLAH KEBANGSAAN BIKAM",
    "Student": 73,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0024",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT SULAIMAN",
    "Student": 147,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0031",
    "Name": "SEKOLAH KEBANGSAAN FELDA SUNGAI KELAH",
    "Student": 146,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0034",
    "Name": "SEKOLAH KEBANGSAAN BATU 14",
    "Student": 328,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0035",
    "Name": "SEKOLAH KEBANGSAAN BESOUT 3",
    "Student": 274,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0110",
    "Name": "SEKOLAH KEBANGSAAN TROLAK UTARA",
    "Student": 328,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0118",
    "Name": "SEKOLAH KEBANGSAAN TROLAK SELATAN",
    "Student": 371,
    "Teacher": 35
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0120",
    "Name": "SEKOLAH KEBANGSAAN SERI BESOUT",
    "Student": 439,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0125",
    "Name": "SEKOLAH KEBANGSAAN POS GEDONG",
    "Student": 87,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0126",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SENTA",
    "Student": 94,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0127",
    "Name": "SEKOLAH KEBANGSAAN POS MUSUH LZ",
    "Student": 54,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0128",
    "Name": "SEKOLAH KEBANGSAAN JERNANG",
    "Student": 171,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0129",
    "Name": "SEKOLAH KEBANGSAAN TAPAH",
    "Student": 102,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABA0130",
    "Name": "SEKOLAH KEBANGSAAN BIDOR 2",
    "Student": 588,
    "Teacher": 52
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1001",
    "Name": "SEKOLAH KEBANGSAAN DENDANG",
    "Student": 94,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1002",
    "Name": "SEKOLAH KEBANGSAAN BERUAS",
    "Student": 237,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1003",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG KOTA",
    "Student": 113,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1004",
    "Name": "SEKOLAH KEBANGSAAN GELONG GAJAH",
    "Student": 248,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1005",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT CHERMIN",
    "Student": 96,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1006",
    "Name": "SEKOLAH KEBANGSAAN AYER TAWAR",
    "Student": 313,
    "Teacher": 36
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1007",
    "Name": "SEKOLAH KEBANGSAAN PANCHOR",
    "Student": 145,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1008",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN BAHARU",
    "Student": 333,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1009",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BATU",
    "Student": 355,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1010",
    "Name": "SEKOLAH KEBANGSAAN LADANG HUNTLY",
    "Student": 86,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1011",
    "Name": "SEKOLAH KEBANGSAAN SEGARI",
    "Student": 114,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1012",
    "Name": "SEKOLAH KEBANGSAAN NAKHODA MUHAMMAD TAIB",
    "Student": 218,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1013",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RAMAI",
    "Student": 41,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1014",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI WANGI",
    "Student": 420,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1015",
    "Name": "SEKOLAH KEBANGSAAN SERI SELAMAT",
    "Student": 428,
    "Teacher": 41
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1016",
    "Name": "SEKOLAH KEBANGSAAN SITIAWAN",
    "Student": 411,
    "Teacher": 37
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1017",
    "Name": "SEKOLAH KEBANGSAAN LEKIR",
    "Student": 448,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1018",
    "Name": "SEKOLAH KEBANGSAAN MUHAMMAD SAMAN",
    "Student": 366,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1019",
    "Name": "SEKOLAH KEBANGSAAN BATU SEPULUH",
    "Student": 511,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1020",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TIRAM",
    "Student": 261,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1021",
    "Name": "SEKOLAH KEBANGSAAN DATO' ISHAK",
    "Student": 410,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1022",
    "Name": "SEKOLAH KEBANGSAAN LUMUT",
    "Student": 119,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1023",
    "Name": "SEKOLAH KEBANGSAAN SERI PANGKOR",
    "Student": 462,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1024",
    "Name": "SEKOLAH KEBANGSAAN TELAGA NENAS",
    "Student": 72,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1025",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BAHARU",
    "Student": 134,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1026",
    "Name": "SEKOLAH KEBANGSAAN KAYAN",
    "Student": 109,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1027",
    "Name": "SEKOLAH KEBANGSAAN PANGKALAN TLDM",
    "Student": 1156,
    "Teacher": 91
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1028",
    "Name": "SEKOLAH KEBANGSAAN SERI MANJUNG",
    "Student": 1462,
    "Teacher": 111
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1029",
    "Name": "SEKOLAH KEBANGSAAN PANTAI REMIS",
    "Student": 285,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1030",
    "Name": "SEKOLAH KEBANGSAAN GUGUSAN LEKIR",
    "Student": 178,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1031",
    "Name": "SEKOLAH KEBANGSAAN PANGKALAN TLDM II",
    "Student": 706,
    "Teacher": 62
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1032",
    "Name": "SEKOLAH KEBANGSAAN KPG. DATO' SERI KAMARUDDIN",
    "Student": 929,
    "Teacher": 78
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1033",
    "Name": "SEKOLAH KEBANGSAAN SERI SITIAWAN",
    "Student": 792,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1034",
    "Name": "SEKOLAH KEBANGSAAN SERI BAYU",
    "Student": 1018,
    "Teacher": 86
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABA1035",
    "Name": "SEKOLAH KEBANGSAAN SERI SAMUDERA",
    "Student": 617,
    "Teacher": 43
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2001",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RAYA",
    "Student": 467,
    "Teacher": 36
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2002",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RAPAT",
    "Student": 544,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2003",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ROKAM",
    "Student": 780,
    "Teacher": 77
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2004",
    "Name": "SEKOLAH KEBANGSAAN TAMBUN",
    "Student": 657,
    "Teacher": 63
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2005",
    "Name": "SEKOLAH KEBANGSAAN TANJONG RAMBUTAN",
    "Student": 664,
    "Teacher": 54
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2006",
    "Name": "SEKOLAH KEBANGSAAN TASEK",
    "Student": 219,
    "Teacher": 23
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2007",
    "Name": "SEKOLAH KEBANGSAAN CHEPOR",
    "Student": 874,
    "Teacher": 67
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2008",
    "Name": "SEKOLAH KEBANGSAAN HAJI MAHMUD",
    "Student": 926,
    "Teacher": 68
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2009",
    "Name": "SEKOLAH KEBANGSAAN SYED IDRUS",
    "Student": 191,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2010",
    "Name": "SEKOLAH KEBANGSAAN DATO' PANGLIMA KINTA",
    "Student": 246,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2011",
    "Name": "SEKOLAH KEBANGSAAN JALAN PANGLIMA BUKIT GANTANG",
    "Student": 580,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2012",
    "Name": "SEKOLAH KEBANGSAAN SERI KEPAYANG",
    "Student": 167,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2014",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG PASIR PUTEH",
    "Student": 778,
    "Teacher": 53
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2015",
    "Name": "SEKOLAH KEBANGSAAN KUALA PARI",
    "Student": 337,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2016",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN PEGOH",
    "Student": 925,
    "Teacher": 72
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA2017",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN BAHARU",
    "Student": 364,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA2030",
    "Name": "SEKOLAH KEBANGSAAN TOH INDERA WANGSA AHMAD",
    "Student": 764,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA2031",
    "Name": "SEKOLAH KEBANGSAAN SIPUTEH",
    "Student": 702,
    "Teacher": 52
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA2032",
    "Name": "SEKOLAH KEBANGSAAN TRONOH",
    "Student": 269,
    "Teacher": 20
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA2033",
    "Name": "SEKOLAH KEBANGSAAN KG. SERI RAHMAT",
    "Student": 89,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA2034",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG TUALANG",
    "Student": 104,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA2035",
    "Name": "SEKOLAH KEBANGSAAN AYER PAPAN",
    "Student": 74,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA2036",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT TIN",
    "Student": 120,
    "Teacher": 23
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA2038",
    "Name": "SEKOLAH KEBANGSAAN BAKAP",
    "Student": 321,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2039",
    "Name": "SEKOLAH KEBANGSAAN PASUKAN POLIS HUTAN",
    "Student": 868,
    "Teacher": 74
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA2040",
    "Name": "SEKOLAH KEBANGSAAN AYER DENAK",
    "Student": 95,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2041",
    "Name": "SEKOLAH KEBANGSAAN MANJOI (SATU)",
    "Student": 423,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2042",
    "Name": "SEKOLAH KEBANGSAAN GURU KALGIDHAR",
    "Student": 84,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2043",
    "Name": "SEKOLAH KEBANGSAAN MANJOI (DUA)",
    "Student": 274,
    "Teacher": 34
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2044",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS IPOH",
    "Student": 55,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2045",
    "Name": "SEKOLAH KEBANGSAAN JELAPANG",
    "Student": 1018,
    "Teacher": 81
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2046",
    "Name": "SEKOLAH KEBANGSAAN DATO' AHMAD SAID TAMBAHAN",
    "Student": 647,
    "Teacher": 51
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2047",
    "Name": "SEKOLAH KEBANGSAAN POS RAYA",
    "Student": 260,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2049",
    "Name": "SEKOLAH KEBANGSAAN SERI TANJUNG",
    "Student": 176,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2050",
    "Name": "SEKOLAH KEBANGSAAN SERI AMPANG",
    "Student": 856,
    "Teacher": 59
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2051",
    "Name": "SEKOLAH KEBANGSAAN JALAN PEGOH",
    "Student": 847,
    "Teacher": 63
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2052",
    "Name": "SEKOLAH KEBANGSAAN SERI KELEBANG",
    "Student": 846,
    "Teacher": 60
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2053",
    "Name": "SEKOLAH KEBANGSAAN PERPADUAN",
    "Student": 786,
    "Teacher": 63
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2055",
    "Name": "SEKOLAH KEBANGSAAN RAJA CHULAN",
    "Student": 457,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2056",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BERSATU",
    "Student": 715,
    "Teacher": 53
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2057",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN",
    "Student": 1201,
    "Teacher": 78
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2058",
    "Name": "SEKOLAH KEBANGSAAN JATI",
    "Student": 676,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2059",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU PUTERA",
    "Student": 807,
    "Teacher": 56
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2060",
    "Name": "SEKOLAH KEBANGSAAN SILIBIN",
    "Student": 407,
    "Teacher": 40
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2061",
    "Name": "SEKOLAH KEBANGSAAN TASEK DERMAWAN",
    "Student": 392,
    "Teacher": 37
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2062",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG PULAI",
    "Student": 460,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2063",
    "Name": "SEKOLAH KEBANGSAAN RAPAT JAYA",
    "Student": 506,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2064",
    "Name": "SEKOLAH KEBANGSAAN PINJI",
    "Student": 380,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2065",
    "Name": "SEKOLAH KEBANGSAAN JELAPANG JAYA",
    "Student": 161,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2066",
    "Name": "SEKOLAH KEBANGSAAN BUNTONG",
    "Student": 248,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA2067",
    "Name": "SEKOLAH KEBANGSAAN SERI JAYA",
    "Student": 420,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2068",
    "Name": "SEKOLAH KEBANGSAAN RAPAT SETIA",
    "Student": 440,
    "Teacher": 37
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2069",
    "Name": "SEKOLAH KEBANGSAAN WIRA JAYA",
    "Student": 231,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2070",
    "Name": "SEKOLAH KEBANGSAAN SERI MUTIARA",
    "Student": 282,
    "Teacher": 42
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2071",
    "Name": "SEKOLAH KEBANGSAAN TASIK DAMAI",
    "Student": 967,
    "Teacher": 79
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2072",
    "Name": "SEKOLAH KEBANGSAAN PAKATAN JAYA",
    "Student": 1102,
    "Teacher": 70
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2073",
    "Name": "SEKOLAH KEBANGSAAN KELEBANG JAYA",
    "Student": 1389,
    "Teacher": 81
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2074",
    "Name": "SEKOLAH KEBANGSAAN TANAH HITAM",
    "Student": 616,
    "Teacher": 42
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABA2075",
    "Name": "SEKOLAH KEBANGSAAN MERU RAYA",
    "Student": 831,
    "Teacher": 62
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3001",
    "Name": "SEKOLAH KEBANGSAAN ALOR PONGSU",
    "Student": 527,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3002",
    "Name": "SEKOLAH KEBANGSAAN BAGAN SERAI",
    "Student": 379,
    "Teacher": 52
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3003",
    "Name": "SEKOLAH KEBANGSAAN BUKIT MERAH",
    "Student": 331,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3004",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT LOBAK",
    "Student": 173,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3005",
    "Name": "SEKOLAH KEBANGSAAN DATO' MAS'UD",
    "Student": 99,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3006",
    "Name": "SEKOLAH KEBANGSAAN KUALA KURAU",
    "Student": 369,
    "Teacher": 50
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3007",
    "Name": "SEKOLAH KEBANGSAAN MATANG GERDU",
    "Student": 793,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3008",
    "Name": "SEKOLAH KEBANGSAAN MATANG JELUTONG",
    "Student": 131,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3009",
    "Name": "SEKOLAH KEBANGSAAN PARIT HAJI TAIB",
    "Student": 173,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3010",
    "Name": "SEKOLAH KEBANGSAAN PARIT HAJI AMAN",
    "Student": 196,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3011",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BOGAK",
    "Student": 153,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3012",
    "Name": "SEKOLAH KEBANGSAAN SRI SIAKAP",
    "Student": 295,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3013",
    "Name": "SEKOLAH KEBANGSAAN TEBUK PANCUR",
    "Student": 174,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3014",
    "Name": "SEKOLAH KEBANGSAAN TELUK MEDAN",
    "Student": 261,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3015",
    "Name": "SEKOLAH KEBANGSAAN TELOK PIAL",
    "Student": 135,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3016",
    "Name": "SEKOLAH KEBANGSAAN JALAN BAHARU",
    "Student": 399,
    "Teacher": 42
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3017",
    "Name": "SEKOLAH KEBANGSAAN HAJI RAMLI",
    "Student": 173,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3018",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG KEDAH",
    "Student": 199,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3019",
    "Name": "SEKOLAH KEBANGSAAN SRI KERIAN",
    "Student": 278,
    "Teacher": 40
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3020",
    "Name": "SEKOLAH KEBANGSAAN PARIT HJ WAHAB",
    "Student": 207,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3021",
    "Name": "SEKOLAH KEBANGSAAN PARIT TOK NGAH",
    "Student": 117,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3022",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BAHARU",
    "Student": 163,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3023",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BURONG",
    "Student": 157,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3024",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI STAR",
    "Student": 200,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3025",
    "Name": "SEKOLAH KEBANGSAAN TANAH KEBUN",
    "Student": 450,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3026",
    "Name": "SEKOLAH KEBANGSAAN TANJONG PIANDANG",
    "Student": 517,
    "Teacher": 62
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3027",
    "Name": "SEKOLAH KEBANGSAAN TITI SERONG",
    "Student": 527,
    "Teacher": 51
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3028",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI MEGAT ARIS",
    "Student": 375,
    "Teacher": 40
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3029",
    "Name": "SEKOLAH KEBANGSAAN GUNONG SEMANGGOL",
    "Student": 284,
    "Teacher": 35
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3030",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG DEW",
    "Student": 115,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3031",
    "Name": "SEKOLAH KEBANGSAAN DATO' ALAUDIN",
    "Student": 344,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3032",
    "Name": "SEKOLAH KEBANGSAAN SAMA GAGAH",
    "Student": 116,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3033",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KEPAR",
    "Student": 299,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3034",
    "Name": "SEKOLAH KEBANGSAAN SERI PINANG",
    "Student": 133,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3035",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG TUA",
    "Student": 118,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3036",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG SELAMAT",
    "Student": 66,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3037",
    "Name": "SEKOLAH KEBANGSAAN HAJI DAHLAN",
    "Student": 148,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3038",
    "Name": "SEKOLAH KEBANGSAAN KUALA GULA",
    "Student": 93,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3039",
    "Name": "SEKOLAH KEBANGSAAN KUALA KURAU (BARU)",
    "Student": 206,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3040",
    "Name": "SEKOLAH KEBANGSAAN BARU PARIT BUNTAR",
    "Student": 398,
    "Teacher": 37
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABA3041",
    "Name": "SEKOLAH KEBANGSAAN WAWASAN",
    "Student": 192,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4001",
    "Name": "SEKOLAH KEBANGSAAN SAYONG",
    "Student": 215,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4002",
    "Name": "SEKOLAH KEBANGSAAN KOTA LAMA KANAN",
    "Student": 244,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4003",
    "Name": "SEKOLAH KEBANGSAAN PADANG AMPANG",
    "Student": 134,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4004",
    "Name": "SEKOLAH KEBANGSAAN BELURU",
    "Student": 136,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4005",
    "Name": "SEKOLAH KEBANGSAAN BENDANG KERING",
    "Student": 128,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4006",
    "Name": "SEKOLAH KEBANGSAAN MENORA",
    "Student": 78,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4007",
    "Name": "SEKOLAH KEBANGSAAN BEKOR",
    "Student": 160,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4008",
    "Name": "SEKOLAH KEBANGSAAN KUALA KENAS",
    "Student": 14,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4009",
    "Name": "SEKOLAH KEBANGSAAN SENGGANG",
    "Student": 117,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4010",
    "Name": "SEKOLAH KEBANGSAAN RAMBAI TUJUH",
    "Student": 37,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4011",
    "Name": "SEKOLAH KEBANGSAAN SULTAN IDRIS II",
    "Student": 515,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4012",
    "Name": "SEKOLAH KEBANGSAAN RAJA PEREMPUAN MUZWIN",
    "Student": 739,
    "Teacher": 54
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4013",
    "Name": "SEKOLAH KEBANGSAAN TANAH MERAH",
    "Student": 66,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4014",
    "Name": "SEKOLAH KEBANGSAAN SYEIKH MOHD. IDRIS AL-MARBAWI",
    "Student": 276,
    "Teacher": 40
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4015",
    "Name": "SEKOLAH KEBANGSAAN KATI",
    "Student": 247,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4016",
    "Name": "SEKOLAH KEBANGSAAN CHEH",
    "Student": 89,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4017",
    "Name": "SEKOLAH KEBANGSAAN SAUK",
    "Student": 239,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4018",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ABDUL AZIZ",
    "Student": 131,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4019",
    "Name": "SEKOLAH KEBANGSAAN ULU KENAS",
    "Student": 83,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4020",
    "Name": "SEKOLAH KEBANGSAAN LEMPOR",
    "Student": 94,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4021",
    "Name": "SEKOLAH KEBANGSAAN JELIANG",
    "Student": 118,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4022",
    "Name": "SEKOLAH KEBANGSAAN ARA PANJANG",
    "Student": 42,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4023",
    "Name": "SEKOLAH KEBANGSAAN MANONG",
    "Student": 209,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4024",
    "Name": "SEKOLAH KEBANGSAAN ULU PIOL",
    "Student": 53,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4025",
    "Name": "SEKOLAH KEBANGSAAN TALANG",
    "Student": 547,
    "Teacher": 47
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4026",
    "Name": "SEKOLAH KEBANGSAAN PADANG ASAM",
    "Student": 112,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4027",
    "Name": "SEKOLAH KEBANGSAAN TUN DR ISMAIL",
    "Student": 322,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4028",
    "Name": "SEKOLAH KEBANGSAAN PAYA SALAK",
    "Student": 220,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4029",
    "Name": "SEKOLAH KEBANGSAAN LANEH",
    "Student": 126,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4030",
    "Name": "SEKOLAH KEBANGSAAN PEREMPUAN",
    "Student": 197,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4031",
    "Name": "SEKOLAH KEBANGSAAN KARAI",
    "Student": 136,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4032",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SIPUT (U)",
    "Student": 662,
    "Teacher": 50
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4033",
    "Name": "SEKOLAH KEBANGSAAN TROSOR",
    "Student": 123,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4034",
    "Name": "SEKOLAH KEBANGSAAN LASAH",
    "Student": 175,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4035",
    "Name": "SEKOLAH KEBANGSAAN RKT LASAH",
    "Student": 193,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4036",
    "Name": "SEKOLAH KEBANGSAAN JAWANG",
    "Student": 44,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4037",
    "Name": "SEKOLAH KEBANGSAAN PERIANG",
    "Student": 56,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4038",
    "Name": "SEKOLAH KEBANGSAAN TEMIN",
    "Student": 240,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4039",
    "Name": "SEKOLAH KEBANGSAAN JALONG",
    "Student": 145,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4040",
    "Name": "SEKOLAH KEBANGSAAN TEMONG",
    "Student": 120,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4041",
    "Name": "SEKOLAH KEBANGSAAN CHEGAR GALAH",
    "Student": 67,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4042",
    "Name": "SEKOLAH KEBANGSAAN LINTANG",
    "Student": 55,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4043",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG MAAMOR",
    "Student": 112,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4044",
    "Name": "SEKOLAH KEBANGSAAN ULU RIBU",
    "Student": 45,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4045",
    "Name": "SEKOLAH KEBANGSAAN SEROLI",
    "Student": 73,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4046",
    "Name": "SEKOLAH KEBANGSAAN JENALIK",
    "Student": 75,
    "Teacher": 20
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4047",
    "Name": "SEKOLAH KEBANGSAAN CHENEIN",
    "Student": 156,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4048",
    "Name": "SEKOLAH KEBANGSAAN PERLOP 1",
    "Student": 63,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4049",
    "Name": "SEKOLAH KEBANGSAAN POS POI",
    "Student": 146,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4050",
    "Name": "SEKOLAH KEBANGSAAN POS PERWOR",
    "Student": 140,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4051",
    "Name": "SEKOLAH KEBANGSAAN POS LEGAP",
    "Student": 302,
    "Teacher": 36
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4052",
    "Name": "SEKOLAH KEBANGSAAN POS PIAH",
    "Student": 136,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4053",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG KENANG",
    "Student": 281,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4054",
    "Name": "SEKOLAH KEBANGSAAN POS KUALA MU",
    "Student": 111,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4055",
    "Name": "SEKOLAH KEBANGSAAN POS SUNGAI PELANTOK",
    "Student": 42,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4056",
    "Name": "SEKOLAH KEBANGSAAN RAJA MUDA MUSA",
    "Student": 609,
    "Teacher": 56
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4057",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG MUHIBAH",
    "Student": 445,
    "Teacher": 48
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABA4058",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG NASIB",
    "Student": 262,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5001",
    "Name": "SEKOLAH KEBANGSAAN KUMPULAN GANDA",
    "Student": 24,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5002",
    "Name": "SEKOLAH KEBANGSAAN SULTAN IDRIS 2",
    "Student": 175,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5003",
    "Name": "SEKOLAH KEBANGSAAN SERI INTAN",
    "Student": 128,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5004",
    "Name": "SEKOLAH KEBANGSAAN SELABAK",
    "Student": 275,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5005",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT JONG",
    "Student": 157,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5006",
    "Name": "SEKOLAH KEBANGSAAN AYER HITAM",
    "Student": 180,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5007",
    "Name": "SEKOLAH KEBANGSAAN TEBOK BANJAR",
    "Student": 148,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5008",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DURIAN",
    "Student": 61,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5009",
    "Name": "SEKOLAH KEBANGSAAN DATO' LAKSAMANA RAJA MAHKOTA",
    "Student": 446,
    "Teacher": 53
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5012",
    "Name": "SEKOLAH KEBANGSAAN TELUK BIRAH",
    "Student": 51,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5014",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RUSA",
    "Student": 76,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5015",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI JEJAWI",
    "Student": 344,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5060",
    "Name": "SEKOLAH KEBANGSAAN DATO' YAHYA SUBBAN",
    "Student": 592,
    "Teacher": 50
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5061",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TUNGKU",
    "Student": 203,
    "Teacher": 20
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5062",
    "Name": "SEKOLAH KEBANGSAAN CHIKUS",
    "Student": 165,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5063",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG",
    "Student": 147,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5064",
    "Name": "SEKOLAH KEBANGSAAN SG LAMPAM",
    "Student": 195,
    "Teacher": 20
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5065",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TUKANG SIDIN",
    "Student": 90,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5066",
    "Name": "SEKOLAH KEBANGSAAN SERI LANGKAP",
    "Student": 456,
    "Teacher": 51
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5067",
    "Name": "SEKOLAH KEBANGSAAN PEKAN RABU",
    "Student": 192,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5068",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG PELANDOK",
    "Student": 71,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5069",
    "Name": "SEKOLAH KEBANGSAAN DEGONG",
    "Student": 181,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5070",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN ARA",
    "Student": 127,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5071",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KERAWAI",
    "Student": 106,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5072",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BAHARU REDANG PONGGOR",
    "Student": 94,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5073",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BAHAGIA",
    "Student": 675,
    "Teacher": 58
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5074",
    "Name": "SEKOLAH KEBANGSAAN SERI SETIA",
    "Student": 185,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5075",
    "Name": "SEKOLAH KEBANGSAAN RAJA MUDA MUSA",
    "Student": 329,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5077",
    "Name": "SEKOLAH KEBANGSAAN PERWIRA",
    "Student": 443,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABA5078",
    "Name": "SEKOLAH KEBANGSAAN ST.ANTHONY",
    "Student": 132,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6001",
    "Name": "SEKOLAH KEBANGSAAN LONG JAAFAR",
    "Student": 532,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6002",
    "Name": "SEKOLAH KEBANGSAAN ASAM KUMBANG",
    "Student": 327,
    "Teacher": 35
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6003",
    "Name": "SEKOLAH KEBANGSAAN LAKSAMANA",
    "Student": 142,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6004",
    "Name": "SEKOLAH KEBANGSAAN MATANG",
    "Student": 186,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6005",
    "Name": "SEKOLAH KEBANGSAAN NGAH IBRAHIM",
    "Student": 111,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6006",
    "Name": "SEKOLAH KEBANGSAAN MATANG GELUGUR",
    "Student": 113,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6007",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG",
    "Student": 806,
    "Teacher": 64
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6008",
    "Name": "SEKOLAH KEBANGSAAN SULTAN YUSSUF AMDR",
    "Student": 206,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6009",
    "Name": "SEKOLAH KEBANGSAAN TAIPING",
    "Student": 437,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6010",
    "Name": "SEKOLAH KEBANGSAAN SERI AMAN",
    "Student": 371,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6011",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT LARUT",
    "Student": 404,
    "Teacher": 40
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6012",
    "Name": "SEKOLAH KEBANGSAAN BENDANG SIAM",
    "Student": 237,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6013",
    "Name": "SEKOLAH KEBANGSAAN TOH JOHAN",
    "Student": 319,
    "Teacher": 36
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6014",
    "Name": "SEKOLAH KEBANGSAAN TEMELOK",
    "Student": 123,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6015",
    "Name": "SEKOLAH KEBANGSAAN PADANG GAJAH",
    "Student": 92,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6016",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TINGGI",
    "Student": 293,
    "Teacher": 36
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6017",
    "Name": "SEKOLAH KEBANGSAAN BATU HAMPAR",
    "Student": 182,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6018",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT JERING",
    "Student": 457,
    "Teacher": 41
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6019",
    "Name": "SEKOLAH KEBANGSAAN JELUTONG",
    "Student": 122,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6020",
    "Name": "SEKOLAH KEBANGSAAN DATO' PANGLIMA BKT GANTANG",
    "Student": 167,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6021",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT IBUL",
    "Student": 303,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6022",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ABDULLAH",
    "Student": 258,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6023",
    "Name": "SEKOLAH KEBANGSAAN TOH SAJAK",
    "Student": 301,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6024",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG PERAK",
    "Student": 321,
    "Teacher": 37
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6025",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG GUDANG",
    "Student": 123,
    "Teacher": 23
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6026",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG REPOH",
    "Student": 188,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6027",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG YAMAN",
    "Student": 70,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6028",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG RELANG",
    "Student": 59,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6029",
    "Name": "SEKOLAH KEBANGSAAN JELAI",
    "Student": 207,
    "Teacher": 23
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6030",
    "Name": "SEKOLAH KEBANGSAAN PANTAI BESAR",
    "Student": 137,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6031",
    "Name": "SEKOLAH KEBANGSAAN PONDOK TANJONG",
    "Student": 45,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6032",
    "Name": "SEKOLAH KEBANGSAAN ULU SEPETANG",
    "Student": 176,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6033",
    "Name": "SEKOLAH KEBANGSAAN HAJI WAN JAAFAR",
    "Student": 181,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6034",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI JERNIH",
    "Student": 125,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6035",
    "Name": "SEKOLAH KEBANGSAAN SRI KURAU",
    "Student": 158,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6036",
    "Name": "SEKOLAH KEBANGSAAN SRI SELAMA",
    "Student": 423,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6037",
    "Name": "SEKOLAH KEBANGSAAN TEBING TINGGI",
    "Student": 146,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6038",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DENDANG",
    "Student": 129,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6039",
    "Name": "SEKOLAH KEBANGSAAN BAGAN BAHARU",
    "Student": 229,
    "Teacher": 23
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6040",
    "Name": "SEKOLAH KEBANGSAAN RANTAU PANJANG",
    "Student": 164,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6041",
    "Name": "SEKOLAH KEBANGSAAN ULU MENGKUANG",
    "Student": 113,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6042",
    "Name": "SEKOLAH KEBANGSAAN BANGGOL JAS",
    "Student": 273,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6043",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BAYOR",
    "Student": 257,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6044",
    "Name": "SEKOLAH KEBANGSAAN TOH RAKNA SAKTI",
    "Student": 249,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6045",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SIPUTEH",
    "Student": 214,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6046",
    "Name": "SEKOLAH KEBANGSAAN SULTAN IDRIS II",
    "Student": 212,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6047",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BERTAM",
    "Student": 130,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6049",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ARA",
    "Student": 116,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6050",
    "Name": "SEKOLAH KEBANGSAAN (RTBK) PONDOK TANJONG",
    "Student": 89,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6051",
    "Name": "SEKOLAH KEBANGSAAN KAMUNTING",
    "Student": 732,
    "Teacher": 55
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6052",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS TAIPING",
    "Student": 20,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6053",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN AUR",
    "Student": 656,
    "Teacher": 54
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6054",
    "Name": "SEKOLAH KEBANGSAAN AYER PUTEH",
    "Student": 310,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6055",
    "Name": "SEKOLAH KEBANGSAAN BUKIT JANA",
    "Student": 662,
    "Teacher": 69
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6056",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG JAMBU",
    "Student": 716,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6057",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG RAJA",
    "Student": 291,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6058",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PANGLIMA",
    "Student": 299,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6059",
    "Name": "SEKOLAH KEBANGSAAN TAMAN JANA",
    "Student": 984,
    "Teacher": 66
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6060",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BOYAN",
    "Student": 432,
    "Teacher": 50
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABA6061",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PALMA",
    "Student": 768,
    "Teacher": 63
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7001",
    "Name": "SEKOLAH KEBANGSAAN KEROH",
    "Student": 543,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7002",
    "Name": "SEKOLAH KEBANGSAAN KUAK HULU",
    "Student": 176,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7003",
    "Name": "SEKOLAH KEBANGSAAN KUAK LUAR",
    "Student": 476,
    "Teacher": 34
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7004",
    "Name": "SEKOLAH KEBANGSAAN BEKUAI",
    "Student": 119,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7005",
    "Name": "SEKOLAH KEBANGSAAN TASEK",
    "Student": 68,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7006",
    "Name": "SEKOLAH KEBANGSAAN KLIAN INTAN",
    "Student": 122,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7007",
    "Name": "SEKOLAH KEBANGSAAN AYER PANAS",
    "Student": 178,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7008",
    "Name": "SEKOLAH KEBANGSAAN LENGGONG",
    "Student": 234,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7009",
    "Name": "SEKOLAH KEBANGSAAN GELOK",
    "Student": 104,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7010",
    "Name": "SEKOLAH KEBANGSAAN SUMPITAN",
    "Student": 104,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7011",
    "Name": "SEKOLAH KEBANGSAAN BUKIT SAPI",
    "Student": 54,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7012",
    "Name": "SEKOLAH KEBANGSAAN TEMELONG",
    "Student": 50,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7013",
    "Name": "SEKOLAH KEBANGSAAN CHEPOR",
    "Student": 55,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7014",
    "Name": "SEKOLAH KEBANGSAAN BANGGOL BELIMBING",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7015",
    "Name": "SEKOLAH KEBANGSAAN LUBOK KAWAH",
    "Student": 123,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7016",
    "Name": "SEKOLAH KEBANGSAAN LUAT",
    "Student": 66,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7017",
    "Name": "SEKOLAH KEBANGSAAN BENG",
    "Student": 40,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7018",
    "Name": "SEKOLAH KEBANGSAAN RABAN",
    "Student": 203,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7019",
    "Name": "SEKOLAH KEBANGSAAN SRI ADIKA RAJA",
    "Student": 941,
    "Teacher": 73
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7020",
    "Name": "SEKOLAH KEBANGSAAN TAN SRI GHAZALI JAWI",
    "Student": 325,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7021",
    "Name": "SEKOLAH KEBANGSAAN SERI TAWAI",
    "Student": 107,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7022",
    "Name": "SEKOLAH KEBANGSAAN KENAYAT",
    "Student": 103,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7023",
    "Name": "SEKOLAH KEBANGSAAN KENERING",
    "Student": 340,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7024",
    "Name": "SEKOLAH KEBANGSAAN KERUNAI",
    "Student": 428,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7025",
    "Name": "SEKOLAH KEBANGSAAN PLANG",
    "Student": 192,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7026",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG LALANG",
    "Student": 161,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7027",
    "Name": "SEKOLAH KEBANGSAAN RKT BERSIA",
    "Student": 273,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7028",
    "Name": "SEKOLAH KEBANGSAAN BASIA LAMA",
    "Student": 333,
    "Teacher": 34
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7029",
    "Name": "SEKOLAH KEBANGSAAN BONGOR",
    "Student": 161,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7030",
    "Name": "SEKOLAH KEBANGSAAN GANDA TEMENGOR",
    "Student": 151,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7031",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PAPULUT",
    "Student": 164,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7032",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LAWIN UTARA",
    "Student": 84,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7033",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LAWIN SELATAN",
    "Student": 125,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7034",
    "Name": "SEKOLAH KEBANGSAAN PAHIT",
    "Student": 271,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7035",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LEPANG NENERING",
    "Student": 87,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7036",
    "Name": "SEKOLAH KEBANGSAAN RPS POS KEMAR",
    "Student": 966,
    "Teacher": 56
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7037",
    "Name": "SEKOLAH KEBANGSAAN RPS BANUN",
    "Student": 316,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7038",
    "Name": "SEKOLAH KEBANGSAAN RPS DALA",
    "Student": 204,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7039",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TIANG",
    "Student": 149,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABA7040",
    "Name": "SEKOLAH KEBANGSAAN BATU 4",
    "Student": 552,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8201",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI GALAH",
    "Student": 241,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8202",
    "Name": "SEKOLAH KEBANGSAAN BALUN BIDAI",
    "Student": 130,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8203",
    "Name": "SEKOLAH KEBANGSAAN TEPUS",
    "Student": 20,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8204",
    "Name": "SEKOLAH KEBANGSAAN TANJONG BELANJA",
    "Student": 134,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8205",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG TIGA",
    "Student": 93,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8206",
    "Name": "SEKOLAH KEBANGSAAN BELANJA",
    "Student": 87,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8207",
    "Name": "SEKOLAH KEBANGSAAN PASIR GAJAH",
    "Student": 110,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8208",
    "Name": "SEKOLAH KEBANGSAAN LAYANG-LAYANG KIRI",
    "Student": 160,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8209",
    "Name": "SEKOLAH KEBANGSAAN PULAU PADANG",
    "Student": 24,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8210",
    "Name": "SEKOLAH KEBANGSAAN BOTA KIRI",
    "Student": 187,
    "Teacher": 23
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8211",
    "Name": "SEKOLAH KEBANGSAAN SERIKAYA",
    "Student": 116,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8212",
    "Name": "SEKOLAH KEBANGSAAN BAKONG",
    "Student": 79,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8213",
    "Name": "SEKOLAH KEBANGSAAN LAMBOR KIRI",
    "Student": 97,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8214",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT BANJAR",
    "Student": 31,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8215",
    "Name": "SEKOLAH KEBANGSAAN TELUK KEPAYANG",
    "Student": 103,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8216",
    "Name": "SEKOLAH KEBANGSAAN PARIT",
    "Student": 124,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8217",
    "Name": "SEKOLAH KEBANGSAAN LENGKUAS",
    "Student": 7,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8218",
    "Name": "SEKOLAH KEBANGSAAN TELOK PERANG",
    "Student": 26,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8219",
    "Name": "SEKOLAH KEBANGSAAN CHOPIN",
    "Student": 45,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8220",
    "Name": "SEKOLAH KEBANGSAAN BUKIT CHUPAK",
    "Student": 27,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8221",
    "Name": "SEKOLAH KEBANGSAAN LAYANG-LAYANG KANAN",
    "Student": 102,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8222",
    "Name": "SEKOLAH KEBANGSAAN CIKGU MIOR SHAHARUDDIN",
    "Student": 28,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8223",
    "Name": "SEKOLAH KEBANGSAAN BOTA KANAN",
    "Student": 314,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8224",
    "Name": "SEKOLAH KEBANGSAAN PADANG CHANGKAT",
    "Student": 84,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8225",
    "Name": "SEKOLAH KEBANGSAAN LAMBOR KANAN",
    "Student": 150,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8226",
    "Name": "SEKOLAH KEBANGSAAN TELOK BAKONG",
    "Student": 135,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8227",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RANGGAM",
    "Student": 131,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8228",
    "Name": "SEKOLAH KEBANGSAAN DATO' SAGOR",
    "Student": 529,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8229",
    "Name": "SEKOLAH KEBANGSAAN SELAT PULAU",
    "Student": 92,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8230",
    "Name": "SEKOLAH KEBANGSAAN TANJONG BIDARA",
    "Student": 35,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8231",
    "Name": "SEKOLAH KEBANGSAAN RAJA CHULAN",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8232",
    "Name": "SEKOLAH KEBANGSAAN PULAU TIGA KIRI",
    "Student": 72,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8233",
    "Name": "SEKOLAH KEBANGSAAN TOH PADUKA RAJA",
    "Student": 47,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8234",
    "Name": "SEKOLAH KEBANGSAAN TOK PELITA",
    "Student": 90,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8235",
    "Name": "SEKOLAH KEBANGSAAN PULAU JUAR",
    "Student": 64,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8236",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TUA",
    "Student": 9,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8237",
    "Name": "SEKOLAH KEBANGSAAN PASIR PANJANG ULU",
    "Student": 24,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8238",
    "Name": "SEKOLAH KEBANGSAAN SUAK PADI",
    "Student": 124,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8239",
    "Name": "SEKOLAH KEBANGSAAN PASIR KUBU",
    "Student": 69,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8241",
    "Name": "SEKOLAH KEBANGSAAN BANDAR",
    "Student": 17,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8242",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BESAR",
    "Student": 65,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8243",
    "Name": "SEKOLAH KEBANGSAAN KOTA SETIA",
    "Student": 167,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8244",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG PERAK",
    "Student": 546,
    "Teacher": 48
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8245",
    "Name": "SEKOLAH KEBANGSAAN SRI CHANGKAT",
    "Student": 70,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8246",
    "Name": "SEKOLAH KEBANGSAAN SERI CEMPAKA",
    "Student": 411,
    "Teacher": 41
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8247",
    "Name": "SEKOLAH KEBANGSAAN CHENDERONG KELUBI",
    "Student": 147,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8248",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PERAH",
    "Student": 61,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8249",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT LADA 2",
    "Student": 494,
    "Teacher": 42
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8250",
    "Name": "SEKOLAH KEBANGSAAN TITI GANTONG",
    "Student": 539,
    "Teacher": 48
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8251",
    "Name": "SEKOLAH KEBANGSAAN SERI ISKANDAR",
    "Student": 1066,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8252",
    "Name": "SEKOLAH KEBANGSAAN FELCRA NASARUDDIN",
    "Student": 254,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8253",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT LADA 3",
    "Student": 181,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABA8254",
    "Name": "SEKOLAH KEBANGSAAN ISKANDAR PERDANA",
    "Student": 1168,
    "Teacher": 85
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9001",
    "Name": "SEKOLAH KEBANGSAAN GOPENG",
    "Student": 584,
    "Teacher": 50
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9002",
    "Name": "SEKOLAH KEBANGSAAN GUNUNG PANJANG",
    "Student": 172,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9003",
    "Name": "SEKOLAH KEBANGSAAN KOTA BAHARU",
    "Student": 48,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9004",
    "Name": "SEKOLAH KEBANGSAAN TUALANG SEKAH",
    "Student": 349,
    "Teacher": 41
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9005",
    "Name": "SEKOLAH KEBANGSAAN MALIM NAWAR",
    "Student": 123,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9006",
    "Name": "SEKOLAH KEBANGSAAN KUALA DIPANG",
    "Student": 186,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9007",
    "Name": "SEKOLAH KEBANGSAAN SAHOM",
    "Student": 153,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9008",
    "Name": "SEKOLAH KEBANGSAAN KAMPAR",
    "Student": 359,
    "Teacher": 36
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9009",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PEKAN",
    "Student": 90,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9010",
    "Name": "SEKOLAH KEBANGSAAN AIR HITAM LABU",
    "Student": 151,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9011",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ITEK",
    "Student": 159,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9012",
    "Name": "SEKOLAH KEBANGSAAN ULU GERUNTUM",
    "Student": 135,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9014",
    "Name": "SEKOLAH KEBANGSAAN POS DIPANG",
    "Student": 101,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9015",
    "Name": "SEKOLAH KEBANGSAAN SENTOSA",
    "Student": 535,
    "Teacher": 48
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABA9016",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT TUALANG",
    "Student": 49,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA001",
    "Name": "SEKOLAH KEBANGSAAN AMINUDIN BAKI",
    "Student": 320,
    "Teacher": 40
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA002",
    "Name": "SEKOLAH KEBANGSAAN BALUN",
    "Student": 99,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA003",
    "Name": "SEKOLAH KEBANGSAAN DATUK NAN KAYA",
    "Student": 70,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA004",
    "Name": "SEKOLAH KEBANGSAAN ULU SLIM",
    "Student": 83,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA005",
    "Name": "SEKOLAH KEBANGSAAN KUALA SLIM",
    "Student": 152,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA006",
    "Name": "SEKOLAH KEBANGSAAN TANJONG MALIM",
    "Student": 949,
    "Teacher": 74
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA007",
    "Name": "SEKOLAH KEBANGSAAN BEHRANG",
    "Student": 183,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA008",
    "Name": "SEKOLAH KEBANGSAAN SLIM VILLAGE",
    "Student": 303,
    "Teacher": 35
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA009",
    "Name": "SEKOLAH KEBANGSAAN DATO KAMARUDDIN",
    "Student": 150,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA010",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BEHRANG",
    "Student": 334,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA011",
    "Name": "SEKOLAH KEBANGSAAN POS BERSIH",
    "Student": 140,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA012",
    "Name": "SEKOLAH KEBANGSAAN POS TENAU",
    "Student": 49,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA013",
    "Name": "SEKOLAH KEBANGSAAN PROTON CITY",
    "Student": 1050,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA014",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BEHRANG 2020",
    "Student": 602,
    "Teacher": 47
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA015",
    "Name": "SEKOLAH KEBANGSAAN TROLAK TIMUR",
    "Student": 191,
    "Teacher": 20
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA016",
    "Name": "SEKOLAH KEBANGSAAN BESOUT 5",
    "Student": 72,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABAA017",
    "Name": "SEKOLAH KEBANGSAAN BESOUT 4",
    "Student": 135,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB001",
    "Name": "SEKOLAH KEBANGSAAN TAPAK SEMENANG",
    "Student": 22,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB002",
    "Name": "SEKOLAH KEBANGSAAN SERI BARU",
    "Student": 91,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB003",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BETUL",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB004",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BULOH",
    "Student": 145,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB005",
    "Name": "SEKOLAH KEBANGSAAN BAGAN PASIR",
    "Student": 147,
    "Teacher": 20
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB006",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SUMUN",
    "Student": 66,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB007",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PULAU",
    "Student": 70,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB008",
    "Name": "SEKOLAH KEBANGSAAN TANJONG BAYAN",
    "Student": 206,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB009",
    "Name": "SEKOLAH KEBANGSAAN HUTAN MELINTANG",
    "Student": 210,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB010",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BAHARU",
    "Student": 115,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB011",
    "Name": "SEKOLAH KEBANGSAAN TANAH LALANG",
    "Student": 74,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB012",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DULANG DALAM",
    "Student": 60,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB013",
    "Name": "SEKOLAH KEBANGSAAN TEBING REBAK",
    "Student": 7,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB014",
    "Name": "SEKOLAH KEBANGSAAN TELUK BULOH",
    "Student": 209,
    "Teacher": 23
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB015",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KELI",
    "Student": 56,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB016",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SAMAK",
    "Student": 192,
    "Teacher": 23
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB017",
    "Name": "SEKOLAH KEBANGSAAN ULU BERNAM",
    "Student": 71,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB018",
    "Name": "SEKOLAH KEBANGSAAN BAGAN DATOH",
    "Student": 161,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB019",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI NIPAH",
    "Student": 57,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB020",
    "Name": "SEKOLAH KEBANGSAAN MATANG KUNDA",
    "Student": 27,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB021",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PERGAM",
    "Student": 33,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB022",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG TIGA",
    "Student": 99,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB023",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BALAI",
    "Student": 73,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB024",
    "Name": "SEKOLAH KEBANGSAAN RUNGKUP",
    "Student": 79,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB025",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TIANG BAROH",
    "Student": 74,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB026",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TIANG DARAT",
    "Student": 148,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB027",
    "Name": "SEKOLAH KEBANGSAAN SELEKOH",
    "Student": 106,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB028",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BATANG",
    "Student": 65,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB029",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI HAJI MUHAMMAD",
    "Student": 38,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABAB030",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG EMPAT",
    "Student": 392,
    "Teacher": 35
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABB0045",
    "Name": "SEKOLAH KEBANGSAAN SATU",
    "Student": 392,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABB0046",
    "Name": "SEKOLAH KEBANGSAAN DATUK KELANA",
    "Student": 441,
    "Teacher": 50
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABB0047",
    "Name": "SEKOLAH KEBANGSAAN SERI BIDOR",
    "Student": 374,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABB1039",
    "Name": "SEKOLAH KEBANGSAAN METHODIST (ACS)",
    "Student": 850,
    "Teacher": 68
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABB1040",
    "Name": "SEKOLAH KEBANGSAAN METHODIST (ACS)",
    "Student": 338,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABB1041",
    "Name": "SEKOLAH KEBANGSAAN METHODIST",
    "Student": 379,
    "Teacher": 37
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABB1042",
    "Name": "SEKOLAH KEBANGSAAN CONVENT",
    "Student": 394,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABB1043",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG EMPAT",
    "Student": 375,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABB1044",
    "Name": "SEKOLAH KEBANGSAAN ST. FRANCIS",
    "Student": 240,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABB1045",
    "Name": "SEKOLAH KEBANGSAAN GANGGA",
    "Student": 146,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2075",
    "Name": "SEKOLAH KEBANGSAAN SRI KINTA",
    "Student": 382,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2076",
    "Name": "SEKOLAH KEBANGSAAN CATOR AVENUE",
    "Student": 726,
    "Teacher": 60
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2077",
    "Name": "SEKOLAH KEBANGSAAN RAJA DIHILIR EKRAM",
    "Student": 166,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2078",
    "Name": "SEKOLAH KEBANGSAAN METHODIST (ACS)",
    "Student": 529,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2079",
    "Name": "SEKOLAH KEBANGSAAN HO SENG ONG METHODIST",
    "Student": 236,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2080",
    "Name": "SEKOLAH KEBANGSAAN ST. MICHAEL",
    "Student": 847,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2082",
    "Name": "SEKOLAH KEBANGSAAN LA SALLE",
    "Student": 395,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2083",
    "Name": "SEKOLAH KEBANGSAAN RAJA PEREMPUAN",
    "Student": 252,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2084",
    "Name": "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST",
    "Student": 661,
    "Teacher": 48
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2085",
    "Name": "SEKOLAH KEBANGSAAN CONVENT",
    "Student": 368,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2086",
    "Name": "SEKOLAH KEBANGSAAN MARIAN CONVENT",
    "Student": 615,
    "Teacher": 51
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2088",
    "Name": "SEKOLAH KEBANGSAAN TARCISIAN CONVENT",
    "Student": 806,
    "Teacher": 56
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2089",
    "Name": "SEKOLAH KEBANGSAAN CORONATION PARK",
    "Student": 123,
    "Teacher": 37
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABB2090",
    "Name": "SEKOLAH KEBANGSAAN SULTAN YUSSUF",
    "Student": 1073,
    "Teacher": 100
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABB2091",
    "Name": "SEKOLAH KEBANGSAAN ST. BERNADETTE'S CONVENT",
    "Student": 883,
    "Teacher": 56
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2092",
    "Name": "SEKOLAH KEBANGSAAN PUSING",
    "Student": 443,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABB2093",
    "Name": "SEKOLAH KEBANGSAAN SERI TRONOH",
    "Student": 310,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABB2098",
    "Name": "SEKOLAH KEBANGSAAN ST. PAUL",
    "Student": 144,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABB2099",
    "Name": "SEKOLAH KEBANGSAAN METHODIST",
    "Student": 311,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABB3046",
    "Name": "SEKOLAH KEBANGSAAN METHODIST PARIT BUNTAR",
    "Student": 393,
    "Teacher": 42
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABB3047",
    "Name": "SEKOLAH KEBANGSAAN JALAN MATANG BULUH",
    "Student": 658,
    "Teacher": 56
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABB4086",
    "Name": "SEKOLAH KEBANGSAAN CLIFFORD",
    "Student": 199,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABB4087",
    "Name": "SEKOLAH KEBANGSAAN DATIN KHADIJAH",
    "Student": 263,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABB4088",
    "Name": "SEKOLAH KEBANGSAAN METHODIST",
    "Student": 381,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABB5075",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ABDUL AZIZ",
    "Student": 588,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABB5076",
    "Name": "SEKOLAH KEBANGSAAN HORLEY METHODIST",
    "Student": 113,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABB5077",
    "Name": "SEKOLAH KEBANGSAAN ST ANTHONY",
    "Student": 236,
    "Teacher": 20
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABB5078",
    "Name": "SEKOLAH KEBANGSAAN CONVENT",
    "Student": 340,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABB5079",
    "Name": "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST",
    "Student": 76,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABB6062",
    "Name": "SEKOLAH KEBANGSAAN KING EDWARD VII (1)",
    "Student": 426,
    "Teacher": 47
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABB6063",
    "Name": "SEKOLAH KEBANGSAAN KING EDWARD VII (2)",
    "Student": 174,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABB6064",
    "Name": "SEKOLAH KEBANGSAAN ST GEORGE 1",
    "Student": 335,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABB6066",
    "Name": "SEKOLAH KEBANGSAAN (P) TREACHER METHODIST",
    "Student": 262,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABB6067",
    "Name": "SEKOLAH KEBANGSAAN KLIAN PAUH",
    "Student": 271,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABB6068",
    "Name": "SEKOLAH KEBANGSAAN CONVENT KOTA",
    "Student": 444,
    "Teacher": 46
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABB6069",
    "Name": "SEKOLAH KEBANGSAAN CONVENT AULONG",
    "Student": 102,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABB6070",
    "Name": "SEKOLAH KEBANGSAAN ALL SAINTS'",
    "Student": 327,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABB6071",
    "Name": "SEKOLAH KEBANGSAAN SELAMA",
    "Student": 319,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABB6072",
    "Name": "SEKOLAH KEBANGSAAN BATU KURAU",
    "Student": 531,
    "Teacher": 55
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABB6073",
    "Name": "SEKOLAH KEBANGSAAN METHODIST",
    "Student": 103,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABB7034",
    "Name": "SEKOLAH KEBANGSAAN MAHKOTA SARI",
    "Student": 555,
    "Teacher": 47
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABB7035",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BALAI",
    "Student": 161,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABB8301",
    "Name": "SEKOLAH KEBANGSAAN ISKANDAR SHAH",
    "Student": 292,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABB9001",
    "Name": "SEKOLAH KEBANGSAAN GOPENG",
    "Student": 363,
    "Teacher": 40
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABB9002",
    "Name": "SEKOLAH KEBANGSAAN METHODIST (ACS)",
    "Student": 326,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABB9003",
    "Name": "SEKOLAH KEBANGSAAN DE LA SALLE",
    "Student": 255,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABB9004",
    "Name": "SEKOLAH KEBANGSAAN METHODIST",
    "Student": 117,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABBA001",
    "Name": "SEKOLAH KEBANGSAAN SLIM RIVER",
    "Student": 495,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABBA002",
    "Name": "SEKOLAH KEBANGSAAN METHODIST",
    "Student": 391,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABBB001",
    "Name": "SEKOLAH KEBANGSAAN KHIR JOHARI",
    "Student": 195,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHENG LOK",
    "Student": 79,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA MIN",
    "Student": 57,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI KROH",
    "Student": 28,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHENDERIANG",
    "Student": 79,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHEUNG WA",
    "Student": 110,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT PAGAR",
    "Student": 42,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEKAN GETAH",
    "Student": 10,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHUI CHEN",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPONG PAHANG",
    "Student": 66,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA LIAN",
    "Student": 11,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG HUA (1)",
    "Student": 205,
    "Teacher": 23
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG HUA (2)",
    "Student": 352,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0062",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PIN MIN",
    "Student": 73,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG COLDSTREAM",
    "Student": 110,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0064",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TANAH MAS",
    "Student": 24,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEKAN PASIR",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA BIKAM",
    "Student": 76,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABC0067",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI MENG",
    "Student": 96,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA LIAN (1)",
    "Student": 139,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA LIAN (2)",
    "Student": 79,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ENG LING",
    "Student": 139,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PING MIN",
    "Student": 145,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUR SENG",
    "Student": 70,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHENG",
    "Student": 632,
    "Teacher": 46
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) UK ING",
    "Student": 559,
    "Teacher": 37
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHINESE NATIONAL",
    "Student": 455,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) UK DIH",
    "Student": 269,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIEN HUA",
    "Student": 114,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEKAN GURNEY",
    "Student": 200,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG LIMA",
    "Student": 46,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KOK MING",
    "Student": 80,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AYER TAWAR",
    "Student": 298,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1062",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MIN TE",
    "Student": 474,
    "Teacher": 41
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG JERING",
    "Student": 138,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1064",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI MIN",
    "Student": 33,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPONG MERBAU",
    "Student": 67,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHUEN HEAN",
    "Student": 165,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1067",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI BATU",
    "Student": 166,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1068",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI MIN",
    "Student": 55,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1069",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TIT BIN",
    "Student": 692,
    "Teacher": 52
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1070",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHING",
    "Student": 74,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABC1071",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YEN MIN",
    "Student": 64,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2100",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHOY",
    "Student": 1547,
    "Teacher": 91
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PERAK",
    "Student": 112,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAM TET",
    "Student": 935,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AVE MARIA CONVENT",
    "Student": 1261,
    "Teacher": 84
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUH HUA",
    "Student": 481,
    "Teacher": 36
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2105",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POI LAM",
    "Student": 1277,
    "Teacher": 76
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MIN TET",
    "Student": 123,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2107",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SHAN",
    "Student": 425,
    "Teacher": 36
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2108",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAM CHAI",
    "Student": 762,
    "Teacher": 53
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2109",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PASIR PINJI (1)",
    "Student": 143,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2110",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PASIR PINJI (2)",
    "Student": 478,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2111",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) GUNTONG",
    "Student": 89,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2112",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WAN HWA (1)",
    "Student": 1306,
    "Teacher": 80
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2113",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WAN HWA (2)",
    "Student": 468,
    "Teacher": 36
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2114",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG TACK",
    "Student": 612,
    "Teacher": 42
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2115",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BERCHAM",
    "Student": 1498,
    "Teacher": 107
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2116",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MIN SIN",
    "Student": 306,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2117",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HING HWA",
    "Student": 84,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2118",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAT CHOI",
    "Student": 130,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2119",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHANGKAT KINDING",
    "Student": 29,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2120",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST MICHAEL & ALL ANGELS",
    "Student": 137,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2121",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG TAWAS",
    "Student": 159,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2122",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHENG",
    "Student": 256,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2123",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA KUANG",
    "Student": 199,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2124",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TANAH HITAM",
    "Student": 80,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2125",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA",
    "Student": 131,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2126",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JELAPANG",
    "Student": 210,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2127",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT MERAH",
    "Student": 227,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2128",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LAHAT",
    "Student": 53,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2129",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAPAN",
    "Student": 51,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2130",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YIT CHEE",
    "Student": 158,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2131",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) GUNONG HIJAU",
    "Student": 377,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC2132",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIPUTEH",
    "Student": 52,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC2133",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SUN",
    "Student": 91,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC2134",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG BALI",
    "Student": 120,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC2135",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG NALLA",
    "Student": 83,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC2136",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK KWAN",
    "Student": 391,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC2137",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BEMBAN",
    "Student": 127,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC2138",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) THUNG HON",
    "Student": 89,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC2139",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) THUNG HON",
    "Student": 115,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC2140",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI DURIAN",
    "Student": 61,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC2141",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPONG TIMAH",
    "Student": 73,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2142",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) GUNONG RAPAT",
    "Student": 733,
    "Teacher": 54
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2143",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHUI YING",
    "Student": 237,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABC2158",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BANDAR SERI BOTANI",
    "Student": 1241,
    "Teacher": 64
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABC3048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HWA",
    "Student": 661,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABC3049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BIN SIN",
    "Student": 22,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABC3050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAM HUA",
    "Student": 470,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABC3051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WAI SIN",
    "Student": 17,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABC3052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU CHAI",
    "Student": 356,
    "Teacher": 34
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABC3053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HWA",
    "Student": 101,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABC3054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG WAH",
    "Student": 64,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABC3055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TONG WAH",
    "Student": 323,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABC3056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ALOR PONGSU",
    "Student": 38,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABC3058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AIK HWA",
    "Student": 23,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4091",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAUK",
    "Student": 43,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4092",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LIMAN",
    "Student": 83,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4093",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI CHEE",
    "Student": 51,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4094",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHIU MIN",
    "Student": 55,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4095",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TSUNG WAH",
    "Student": 511,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4096",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JERLUN",
    "Student": 78,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4097",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MANONG",
    "Student": 44,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4100",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SEY WAH",
    "Student": 46,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SALAK BAHRU",
    "Student": 65,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HING WA",
    "Student": 40,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI BULOH",
    "Student": 116,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SHING CHUNG",
    "Student": 477,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4105",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG JALONG",
    "Student": 479,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LINTANG",
    "Student": 25,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4107",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LASAH",
    "Student": 46,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABC4108",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) RIMBA PANJANG",
    "Student": 142,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABC5081",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WAH KEOW",
    "Student": 332,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABC5082",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PELAWAN",
    "Student": 65,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABC5083",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BATU DUABELAS",
    "Student": 72,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABC5084",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUI CHAK",
    "Student": 155,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABC5085",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAN MIN 1",
    "Student": 309,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABC5086",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAN MIN 2",
    "Student": 575,
    "Teacher": 41
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABC5087",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHOOI YONG",
    "Student": 134,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABC5088",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG MIN",
    "Student": 636,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABC5089",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 40,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6074",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HUA LIAN (1)",
    "Student": 519,
    "Teacher": 37
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6075",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HUA LIAN (2)",
    "Student": 535,
    "Teacher": 32
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6076",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HUA LIAN (3)",
    "Student": 619,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6077",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POKOK ASSAM",
    "Student": 777,
    "Teacher": 51
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6078",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AULONG",
    "Student": 437,
    "Teacher": 40
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6079",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHUI CHOI",
    "Student": 255,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6080",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN",
    "Student": 299,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6082",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KWONG MAN",
    "Student": 81,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6083",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHOOI WAH",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6084",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHEA WAH",
    "Student": 87,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6085",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POAY ENG",
    "Student": 229,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6086",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POAY CHEE",
    "Student": 1,
    "Teacher": 2
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6087",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHUEN",
    "Student": 23,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6088",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIU SIN",
    "Student": 36,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6089",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NGAI SENG",
    "Student": 5,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6091",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI ROTAN",
    "Student": 5,
    "Teacher": 5
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6092",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAY HWA",
    "Student": 71,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6093",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HUA",
    "Student": 35,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6094",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AIK HUA",
    "Student": 7,
    "Teacher": 4
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6095",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI SHENG (1)",
    "Student": 81,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6096",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI SHENG (2)",
    "Student": 22,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6097",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) REDANG PANJANG",
    "Student": 18,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABC6098",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI TERAP",
    "Student": 28,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABC7036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) EOK KWAN",
    "Student": 183,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABC7037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG LI",
    "Student": 35,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABC7038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG. BAHARU BATU DUA",
    "Student": 129,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABC7039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA RUI",
    "Student": 80,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABC7040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG WA",
    "Student": 210,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABC7041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AYER KALA",
    "Student": 60,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABC7042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SELAT PAGAR",
    "Student": 10,
    "Teacher": 5
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABC7043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PADANG GRUS",
    "Student": 37,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABC7044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YEONG HWA",
    "Student": 73,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABC7045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KOTA TAMPAN",
    "Student": 13,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABC7046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAY BENG",
    "Student": 20,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABC8401",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 32,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NEW KOPISAN",
    "Student": 305,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MAN MING",
    "Student": 163,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LAWAN KUDA BARU",
    "Student": 201,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JERAM",
    "Student": 107,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YING SING",
    "Student": 241,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN",
    "Student": 40,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI YUAN",
    "Student": 390,
    "Teacher": 34
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MAMBANG DIAWAN",
    "Student": 201,
    "Teacher": 20
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN",
    "Student": 30,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9010",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPAR GIRLS",
    "Student": 224,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUAH",
    "Student": 461,
    "Teacher": 36
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABC9012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU YING",
    "Student": 76,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABCA001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HO PIN",
    "Student": 22,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABCA002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHIN MIN",
    "Student": 61,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABCA003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HUA",
    "Student": 136,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABCA004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BEHRANG ULU",
    "Student": 45,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABCA005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SIN",
    "Student": 404,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABCB001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YEONG SENG",
    "Student": 179,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABCB002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POOI SENG",
    "Student": 95,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABCB003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POOI AING",
    "Student": 36,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABCB004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEOW MIN",
    "Student": 287,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABCB005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN",
    "Student": 123,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABCB006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG SAN",
    "Student": 36,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABCB007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA NAN",
    "Student": 12,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABCB008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HUA HSIA",
    "Student": 51,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABCB009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YEE HWA",
    "Student": 11,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABCB010",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG TIGA",
    "Student": 30,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABCB011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BAGAN PASIR LAUT",
    "Student": 57,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0073",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAPAH",
    "Student": 99,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0074",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KHIR JOHARI",
    "Student": 57,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0075",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN",
    "Student": 154,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0076",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BHARATHY",
    "Student": 21,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0077",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGKAI",
    "Student": 86,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0081",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TONG WAH",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0082",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BIDOR TAHAN",
    "Student": 65,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0083",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BIKAM",
    "Student": 24,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0084",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI KRUIT",
    "Student": 14,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0086",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGKAI",
    "Student": 20,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0089",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KELAPA BALI",
    "Student": 8,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "ABD0106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BANOPDANE",
    "Student": 61,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1073",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MAHA GANESA VIDDYASALAI",
    "Student": 394,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1075",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PANGKOR",
    "Student": 72,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1077",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PENGKALAN BARU",
    "Student": 83,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1078",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG HUNTLY",
    "Student": 38,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1079",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SOGOMANA",
    "Student": 61,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1082",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG AYER TAWAR",
    "Student": 41,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1083",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMPONG TUN SAMBANTHAN",
    "Student": 36,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1084",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CASHWOOD",
    "Student": 39,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1085",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMPUNG COLUMBIA",
    "Student": 52,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1086",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG WALBROOK",
    "Student": 102,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1087",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG WANGI II",
    "Student": 115,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1089",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MUKIM PUNDUT",
    "Student": 176,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1091",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMPUNG KAYAN",
    "Student": 14,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1092",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) AYER TAWAR",
    "Student": 331,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "ABD1093",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BERUAS",
    "Student": 48,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2158",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TANJONG RAMBUTAN",
    "Student": 248,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2159",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KERAJAAN",
    "Student": 276,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2160",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) ST PHILOMENA CONVENT",
    "Student": 358,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2161",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PERAK SANGEETHA SABAH",
    "Student": 179,
    "Teacher": 18
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2163",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) METHODIST",
    "Student": 112,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2164",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) CHETTIARS",
    "Student": 386,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2166",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KG.SIMEE",
    "Student": 208,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2167",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) GUNONG RAPAT",
    "Student": 115,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2168",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MENGLEMBU",
    "Student": 295,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABD2169",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) CHANGKAT",
    "Student": 273,
    "Teacher": 27
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABD2170",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TRONOH",
    "Student": 63,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2173",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHEMOR",
    "Student": 105,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2174",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHANGKAT KINDING",
    "Student": 42,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2175",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KLEBANG",
    "Student": 478,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2176",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG STRATHISLA",
    "Student": 42,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABD2178",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KINTA VALLEY",
    "Student": 67,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "ABD2189",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN DESA PINJI",
    "Student": 205,
    "Teacher": 20
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SELINSING",
    "Student": 22,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG YAM SENG",
    "Student": 33,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUALA KURAU",
    "Student": 29,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SIMPANG LIMA",
    "Student": 54,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BAGAN SERAI",
    "Student": 163,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3062",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SAINT MARY'S",
    "Student": 181,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3064",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI BOGAK",
    "Student": 6,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GULA",
    "Student": 25,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3067",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHERSONESE",
    "Student": 34,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3068",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JIN SENG",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3069",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SOON LEE",
    "Student": 62,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3070",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) ARUMUGAM PILLAI",
    "Student": 18,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3071",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KALUMPONG",
    "Student": 19,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ABD3072",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GEDONG",
    "Student": 22,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4109",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) GANDHI MEMORIAL",
    "Student": 100,
    "Teacher": 17
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4110",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MAHATHMA GANDHI KALASALAI",
    "Student": 402,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4111",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI BIONG",
    "Student": 9,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4112",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KATI",
    "Student": 27,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4113",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GAPIS",
    "Student": 37,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4114",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PERAK RIVER VALLEY",
    "Student": 16,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4115",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) ENGGOR",
    "Student": 21,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4116",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHANGKAT SALAK",
    "Student": 54,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4117",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN",
    "Student": 40,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4118",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ELPHIL",
    "Student": 28,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4119",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI REYLA",
    "Student": 36,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "ABD4120",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG DOVENBY",
    "Student": 147,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) THIRUVALLUVAR",
    "Student": 92,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SITHAMBARAM PILLAY",
    "Student": 181,
    "Teacher": 21
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SELABA",
    "Student": 69,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5107",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) DATO SITHAMBARAM PILLAY",
    "Student": 8,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5108",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUSSEX",
    "Student": 60,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5109",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) NATESA PILLAY",
    "Student": 61,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5111",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI TIMAH",
    "Student": null,
    "Teacher": null
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5112",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SABRANG",
    "Student": 6,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5113",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATAK RABIT",
    "Student": 156,
    "Teacher": 13
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5114",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NOVA SCOTIA 1",
    "Student": 37,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5115",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NOVA SCOTIA (2)",
    "Student": 61,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "ABD5117",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG RUBANA 1",
    "Student": 15,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6099",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMUNTING",
    "Student": 143,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) YMHA",
    "Student": 87,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) ST TERESA'S CONVENT",
    "Student": 359,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) ULU SEPETANG",
    "Student": 18,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SELAMA",
    "Student": 116,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PONDOK TANJUNG",
    "Student": 8,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6107",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG HOLYROOD",
    "Student": 76,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6108",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MALAYA",
    "Student": 9,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6110",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SIN WAH",
    "Student": 112,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6112",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LAUDERDALE",
    "Student": 99,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6113",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MATANG",
    "Student": 9,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6114",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KG JEBONG LAMA",
    "Student": 28,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6115",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMPONG BARU BATU MATANG",
    "Student": 25,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6116",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GETAH TAIPING",
    "Student": 73,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6117",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ALLAGAR",
    "Student": 14,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6118",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TEMERLOH",
    "Student": 21,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "ABD6120",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG STOUGHTON",
    "Student": 16,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABD7047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KERUH",
    "Student": 43,
    "Teacher": 14
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABD7048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) GERIK",
    "Student": 19,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "ABD7049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KOTA LIMA",
    "Student": 11,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABD8451",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GLENEALY",
    "Student": 22,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABD8452",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SERAPOH",
    "Student": 6,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ABD8453",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BULOH AKAR",
    "Student": 17,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABD9001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) GOPENG",
    "Student": 92,
    "Teacher": 16
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABD9003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KOTA BAHROE",
    "Student": 47,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABD9004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MAMBANG DIAWAN",
    "Student": 58,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABD9005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMPAR",
    "Student": 165,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABD9006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KAMPAR",
    "Student": 36,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ABD9007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) METHODIST",
    "Student": 25,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABDA001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SLIM RIVER",
    "Student": 155,
    "Teacher": 26
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABDA002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SLIM VILLAGE",
    "Student": 31,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABDA003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAN SRI DATO' MANICKAVASAGAM",
    "Student": 199,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABDA004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TROLAK",
    "Student": 18,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABDA006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CLUNY",
    "Student": 16,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABDA007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KATOYANG",
    "Student": 126,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ABDA008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BEHRANG RIVER",
    "Student": 23,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BARATHI",
    "Student": 247,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JENDARATA 1",
    "Student": 73,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JENDARATA BHG 2",
    "Student": 23,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JENDARATA BHG 3",
    "Student": 42,
    "Teacher": 9
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TELUK BULOH",
    "Student": 113,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JENDARATA BAHAGIAN ALPHA BERNAM",
    "Student": 21,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG FLEMINGTON",
    "Student": 26,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TELUK BHARU",
    "Student": 14,
    "Teacher": 8
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KUALA BERNAM",
    "Student": 21,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB010",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL & TELUGU) BAGAN DATOH",
    "Student": 26,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG STRATHMASHIE",
    "Student": 12,
    "Teacher": 6
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NEW COCONUT",
    "Student": 23,
    "Teacher": 7
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB013",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ULU BERNAM 2",
    "Student": 16,
    "Teacher": 12
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI SAMAK",
    "Student": 27,
    "Teacher": 11
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KAMATCHY",
    "Student": 101,
    "Teacher": 10
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "ABDB016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN",
    "Student": 60,
    "Teacher": 15
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ACT3001",
    "Name": "SEKOLAH RENDAH ISLAM AL-EHYA ASSHARIFF",
    "Student": 248,
    "Teacher": 23
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ACT3002",
    "Name": "SRA AR-RIDHWANIAH",
    "Student": 370,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEA0033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HAMID KHAN",
    "Student": 554,
    "Teacher": 58
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEA0035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TROLAK SELATAN",
    "Student": 456,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEA0037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) BESOUT",
    "Student": 488,
    "Teacher": 60
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEA0038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI KERUIT",
    "Student": 289,
    "Teacher": 41
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEA0039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AIR KUNING",
    "Student": 658,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEA0040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHENDERIANG",
    "Student": 502,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEA0041",
    "Name": "KOLEJ TINGKATAN ENAM TAPAH",
    "Student": 125,
    "Teacher": 43
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEA0042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BIDOR",
    "Student": 646,
    "Teacher": 62
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEA0043",
    "Name": "SEKOLAH MENENGAH SAINS TAPAH",
    "Student": 846,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEA1109",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI MANJUNG",
    "Student": 1196,
    "Teacher": 106
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEA1110",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AHMAD BOESTAMAM",
    "Student": 1097,
    "Teacher": 108
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEA1111",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANGKALAN TLDM",
    "Student": 769,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEA1112",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU SEPULUH",
    "Student": 943,
    "Teacher": 88
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEA1113",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANTAI REMIS",
    "Student": 758,
    "Teacher": 76
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEA1114",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI SAMUDERA",
    "Student": 1107,
    "Teacher": 95
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEA1115",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHANGKAT BERUAS",
    "Student": 299,
    "Teacher": 41
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEA1116",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KG. DATO' SERI KAMARUDDIN",
    "Student": 729,
    "Teacher": 62
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA CHULAN",
    "Student": 768,
    "Teacher": 82
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN PASIR PUTEH",
    "Student": 828,
    "Teacher": 73
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUNUNG RAPAT",
    "Student": 728,
    "Teacher": 72
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA PERMAISURI BAINUN",
    "Student": 499,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2046",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' HJ. MOHD TAIB",
    "Student": 1168,
    "Teacher": 99
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2047",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI KELEDANG",
    "Student": 1553,
    "Teacher": 109
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2048",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KG. DATO' AHMAD SAID",
    "Student": 654,
    "Teacher": 66
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2049",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KG PASIR PUTEH",
    "Student": 1067,
    "Teacher": 97
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2050",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI AMPANG",
    "Student": 1069,
    "Teacher": 104
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2051",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN TASEK",
    "Student": 1141,
    "Teacher": 117
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIMPANG PULAI",
    "Student": 1096,
    "Teacher": 86
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LAHAT",
    "Student": 455,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TASEK DAMAI",
    "Student": 1709,
    "Teacher": 133
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJONG RAMBUTAN",
    "Student": 683,
    "Teacher": 54
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JELAPANG JAYA",
    "Student": 764,
    "Teacher": 77
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN",
    "Student": 1044,
    "Teacher": 78
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BERCHAM",
    "Student": 840,
    "Teacher": 76
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUSING",
    "Student": 854,
    "Teacher": 69
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUNTONG",
    "Student": 703,
    "Teacher": 61
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN WIRA JAYA",
    "Student": 508,
    "Teacher": 56
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JATI",
    "Student": 959,
    "Teacher": 74
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2063",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PINJI",
    "Student": 553,
    "Teacher": 51
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAPAT SETIA",
    "Student": 588,
    "Teacher": 69
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2066",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMBUN",
    "Student": 1122,
    "Teacher": 97
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2067",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU PUTRA",
    "Student": 798,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEA2302",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ULU KINTA",
    "Student": 1047,
    "Teacher": 102
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEA3038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANGLIMA BUKIT GANTANG",
    "Student": 1021,
    "Teacher": 120
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEA3039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI PERAK",
    "Student": 1077,
    "Teacher": 105
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEA3040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALANG ISKANDAR",
    "Student": 629,
    "Teacher": 121
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEA3041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALOR PONGSU",
    "Student": 525,
    "Teacher": 56
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEA3086",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA LOPE NOR RASHID",
    "Student": 755,
    "Teacher": 81
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEA3087",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA KURAU",
    "Student": 436,
    "Teacher": 62
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEA3088",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ABU BAKAR AL-BAQIR",
    "Student": 856,
    "Teacher": 95
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEA3089",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUNUNG SEMANGGOL",
    "Student": 638,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEA3090",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEKAN BARU",
    "Student": 358,
    "Teacher": 54
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEA3091",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SELINSING",
    "Student": 278,
    "Teacher": 42
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEA4082",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT MERCHU",
    "Student": 797,
    "Teacher": 70
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEA4083",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEMENGGONG",
    "Student": 410,
    "Teacher": 55
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEA4085",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TOH MUDA ABDUL AZIZ SUNGAI SIPUT (U)",
    "Student": 717,
    "Teacher": 95
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEA4086",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIMPANG BELURU",
    "Student": 702,
    "Teacher": 78
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEA4087",
    "Name": "SEKOLAH SENI MALAYSIA PERAK",
    "Student": 272,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEA4131",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RLKT LASAH",
    "Student": 337,
    "Teacher": 47
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEA4141",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SAYONG",
    "Student": 376,
    "Teacher": 46
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEA4142",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAWONG",
    "Student": 539,
    "Teacher": 53
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEA5072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI MANIK",
    "Student": 319,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEA5073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PERAK",
    "Student": 661,
    "Teacher": 84
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEA5074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDULLAH",
    "Student": 975,
    "Teacher": 102
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEA5078",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ABDUL RAHMAN TALIB",
    "Student": 388,
    "Teacher": 48
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEA5079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI SETIA",
    "Student": 204,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEA5080",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI KANDI",
    "Student": 358,
    "Teacher": 50
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEA5084",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG BAHAGIA",
    "Student": 520,
    "Teacher": 42
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6048",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DOKTOR BURHANUDDIN",
    "Student": 897,
    "Teacher": 121
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6049",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI BAYOR",
    "Student": 750,
    "Teacher": 76
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6050",
    "Name": "SEKOLAH MENENGAH SAINS RAJA TUN AZLAN SHAH",
    "Student": 592,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6051",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIMPANG",
    "Student": 949,
    "Teacher": 105
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMUNTING",
    "Student": 980,
    "Teacher": 108
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' WAN AHMAD RASDI",
    "Student": 394,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JELAI",
    "Student": 471,
    "Teacher": 55
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN TASIK",
    "Student": 468,
    "Teacher": 54
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT JANA",
    "Student": 982,
    "Teacher": 91
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG JAMBU",
    "Student": 691,
    "Teacher": 56
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN AUR",
    "Student": 584,
    "Teacher": 61
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN PANGLIMA",
    "Student": 404,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUBU GAJAH",
    "Student": 160,
    "Teacher": 24
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MATANG",
    "Student": 539,
    "Teacher": 52
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KLIAN PAUH",
    "Student": 504,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6063",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TOH JOHAN",
    "Student": 238,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6158",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN REDANG PANJANG",
    "Student": 475,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6159",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPONG PERAK",
    "Student": 426,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEA6160",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMUNTING BAKTI",
    "Student": 439,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEA7001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAN SRI ABDUL AZIZ",
    "Student": 268,
    "Teacher": 34
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEA7002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BERSIA (FELDA)",
    "Student": 427,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEA7003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN AZLAN SHAH",
    "Student": 273,
    "Teacher": 36
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEA7004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO SERI WAN MOHAMED",
    "Student": 401,
    "Teacher": 47
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEA7005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KENERING",
    "Student": 356,
    "Teacher": 35
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEA7006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GERIK",
    "Student": 645,
    "Teacher": 73
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEA7007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN HULU",
    "Student": 547,
    "Teacher": 59
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEA7008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU 4",
    "Student": 693,
    "Teacher": 69
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEA7009",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BUDIMAN",
    "Student": 186,
    "Teacher": 46
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEA8001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN MUHAMMAD SHAH",
    "Student": 466,
    "Teacher": 51
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEA8002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN MUZAFAR SHAH 1",
    "Student": 232,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEA8003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL JALIL SHAH",
    "Student": 648,
    "Teacher": 83
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEA8004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LAYANG-LAYANG KIRI",
    "Student": 228,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEA8005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHANGKAT LADA",
    "Student": 481,
    "Teacher": 59
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEA8006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LAMBOR KIRI",
    "Student": 433,
    "Teacher": 43
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEA8007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RANGGAM",
    "Student": 295,
    "Teacher": 41
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEA8008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI ISKANDAR",
    "Student": 1242,
    "Teacher": 96
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEA8009",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI LONDANG",
    "Student": 343,
    "Teacher": 35
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEA9001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI KAMPAR",
    "Student": 602,
    "Teacher": 84
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEA9002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SENTOSA",
    "Student": 554,
    "Teacher": 60
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEA9003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI TEJA",
    "Student": 450,
    "Teacher": 56
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "AEAA001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KHIR JOHARI",
    "Student": 779,
    "Teacher": 76
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "AEAA002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SLIM",
    "Student": 595,
    "Teacher": 64
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "AEAA003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BEHRANG 2020",
    "Student": 606,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "AEAA004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PROTON CITY",
    "Student": 557,
    "Teacher": 60
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "AEAB001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ULU BERNAM",
    "Student": 118,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "AEAB002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HUTAN MELINTANG",
    "Student": 1197,
    "Teacher": 114
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "AEAB003",
    "Name": "SEKOLAH MENENGAH SAINS TELOK INTAN",
    "Student": 720,
    "Teacher": 59
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "AEAB004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PERKASA",
    "Student": 398,
    "Teacher": 47
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "AEAB005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RUNGKUP",
    "Student": 222,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "AEAB006",
    "Name": "SEKOLAH MENENGAH SAINS BAGAN DATOH",
    "Student": 754,
    "Teacher": 59
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEB0035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUYONG ADIL",
    "Student": 576,
    "Teacher": 55
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEB0036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI TAPAH",
    "Student": 849,
    "Teacher": 77
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEB0038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHOONG HUA",
    "Student": 772,
    "Teacher": 66
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEB1027",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT",
    "Student": 343,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEB1031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NAN HWA",
    "Student": 1842,
    "Teacher": 137
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEB1033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DINDINGS",
    "Student": 734,
    "Teacher": 59
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEB1034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AYER TAWAR",
    "Student": 1011,
    "Teacher": 75
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEB1036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST",
    "Student": 580,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ANDERSON",
    "Student": 1006,
    "Teacher": 119
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. MICHAEL",
    "Student": 1226,
    "Teacher": 133
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST (ACS)",
    "Student": 932,
    "Teacher": 117
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2046",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA PEREMPUAN",
    "Student": 682,
    "Teacher": 92
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2047",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN METHODIST",
    "Student": 1043,
    "Teacher": 82
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2048",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT",
    "Student": 745,
    "Teacher": 66
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2049",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TARCISIAN CONVENT",
    "Student": 805,
    "Teacher": 63
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2050",
    "Name": "SEKOLAH TUANKU ABDUL RAHMAN",
    "Student": 665,
    "Teacher": 63
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2051",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN PERAK",
    "Student": 1075,
    "Teacher": 83
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AVE MARIA CONVENT",
    "Student": 1477,
    "Teacher": 109
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SAM TET",
    "Student": 1774,
    "Teacher": 145
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN POI LAM",
    "Student": 1713,
    "Teacher": 116
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN YUK CHOY",
    "Student": 1272,
    "Teacher": 99
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI INTAN",
    "Student": 311,
    "Teacher": 35
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI PUTERI",
    "Student": 479,
    "Teacher": 43
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PARI",
    "Student": 315,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2059",
    "Name": "KOLEJ TINGKATAN ENAM SERI PUTERA",
    "Student": 94,
    "Teacher": 38
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DR MEGAT KHAS",
    "Student": 498,
    "Teacher": 47
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2061",
    "Name": "KOLEJ TINGKATAN ENAM SERI IPOH",
    "Student": 85,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEB2062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MENGLEMBU",
    "Student": 1000,
    "Teacher": 101
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEB2063",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN YUSSUF",
    "Student": 1266,
    "Teacher": 151
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEB2064",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. BERNADETTE'S CONVENT",
    "Student": 810,
    "Teacher": 64
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEB2065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN YUK KWAN",
    "Student": 576,
    "Teacher": 58
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEB3041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST",
    "Student": 447,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEB3042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KRIAN",
    "Student": 1793,
    "Teacher": 128
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEB3043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI KURAU",
    "Student": 832,
    "Teacher": 83
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEB3044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAGAN SERAI",
    "Student": 1003,
    "Teacher": 103
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEB4072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA MUDA MUSA",
    "Student": 547,
    "Teacher": 88
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEB4073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TSUNG WAH",
    "Student": 752,
    "Teacher": 82
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEB4074",
    "Name": "KOLEJ MELAYU KUALA KANGSAR",
    "Student": 676,
    "Teacher": 77
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEB4075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST",
    "Student": 658,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEB4076",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SHING CHUNG",
    "Student": 1230,
    "Teacher": 105
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEB4077",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK HJ ABD WAHAB",
    "Student": 762,
    "Teacher": 74
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEB5165",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL AZIZ",
    "Student": 829,
    "Teacher": 100
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEB5166",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA MUDA MUSA",
    "Student": 436,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEB5167",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. ANTHONY",
    "Student": 363,
    "Teacher": 64
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEB5168",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HORLEY METHODIST",
    "Student": 709,
    "Teacher": 88
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEB5169",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT",
    "Student": 712,
    "Teacher": 62
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEB5170",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SAN MIN",
    "Student": 1314,
    "Teacher": 105
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEB6051",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. GEORGE",
    "Student": 594,
    "Teacher": 81
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEB6052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) TREACHER METHODIST",
    "Student": 580,
    "Teacher": 54
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEB6053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT",
    "Student": 555,
    "Teacher": 48
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEB6054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HUA LIAN",
    "Student": 1639,
    "Teacher": 135
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEB6055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI KOTA",
    "Student": 300,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEB6056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DARUL RIDWAN",
    "Student": 525,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEB9001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPAR",
    "Student": 367,
    "Teacher": 37
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEB9002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEI YUAN",
    "Student": 1175,
    "Teacher": 83
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "AEBA001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST",
    "Student": 724,
    "Teacher": 78
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "AEBA002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KATHOLIK",
    "Student": 493,
    "Teacher": 43
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEE0037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' PANGLIMA PERANG KIRI",
    "Student": 302,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEE0039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SYEIKH ABDUL GHANI",
    "Student": 727,
    "Teacher": 76
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AEE0040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGKAI",
    "Student": 512,
    "Teacher": 67
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEE1026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST (ACS)",
    "Student": 1555,
    "Teacher": 116
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEE1030",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TOK PERDANA",
    "Student": 676,
    "Teacher": 71
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEE1032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA SHAHRIMAN",
    "Student": 429,
    "Teacher": 58
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEE1036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AMBROSE",
    "Student": 738,
    "Teacher": 101
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEE1037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANGKOR",
    "Student": 524,
    "Teacher": 63
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AEE1038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' IDRIS",
    "Student": 578,
    "Teacher": 62
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEE2066",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TOH INDERA WANGSA AHMAD",
    "Student": 1344,
    "Teacher": 97
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AEE2071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AMINUDDIN BAKI",
    "Student": 1246,
    "Teacher": 102
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEE2072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' BENDAHARA C M YUSUF",
    "Student": 623,
    "Teacher": 59
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEE2073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TRONOH",
    "Student": 681,
    "Teacher": 63
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AEE3045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUDZAFFAR SHAH",
    "Student": 686,
    "Teacher": 78
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEE4070",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CLIFFORD",
    "Student": 707,
    "Teacher": 94
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEE4071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA PEREMPUAN KELSOM",
    "Student": 727,
    "Teacher": 67
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEE4079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN TAJUL ARIFFIN",
    "Student": 425,
    "Teacher": 62
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AEE4081",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN PERAK",
    "Student": 504,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AEE5171",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' SAGOR",
    "Student": 809,
    "Teacher": 85
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEE6050",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KING EDWARD VII",
    "Student": 853,
    "Teacher": 116
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEE6058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO KAMARUDDIN",
    "Student": 791,
    "Teacher": 86
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEE6059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' HJ HUSSEIN",
    "Student": 983,
    "Teacher": 105
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEE6060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAT BENG",
    "Student": 566,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AEE6061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU MENTERI",
    "Student": 546,
    "Teacher": 67
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEE7031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO AHMAD",
    "Student": 509,
    "Teacher": 63
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEE7032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN IDRIS SHAH II",
    "Student": 836,
    "Teacher": 76
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AEE7033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN SABAN",
    "Student": 737,
    "Teacher": 85
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEE8701",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ISKANDAR SHAH",
    "Student": 499,
    "Teacher": 75
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEE8702",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' ABDUL RAHMAN YAAKUB",
    "Student": 714,
    "Teacher": 65
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AEE8703",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' SERI MAHARAJA LELA",
    "Student": 774,
    "Teacher": 76
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEE9001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN IDRIS SHAH",
    "Student": 1158,
    "Teacher": 90
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEE9003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MALIM NAWAR",
    "Student": 646,
    "Teacher": 64
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "AEE9004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST (ACS)",
    "Student": 674,
    "Teacher": 53
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "AEEA001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO ZULKIFLI MUHAMMAD",
    "Student": 775,
    "Teacher": 84
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "AEEB001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KHIR JOHARI",
    "Student": 782,
    "Teacher": 93
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "AEEB002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN ABD RAZAK",
    "Student": 512,
    "Teacher": 71
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "AEEB003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI MUARA",
    "Student": 229,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AFA2001",
    "Name": "MAAHAD TAHFIZ AL-QURAN & AKADEMIK BAKIP (MATAQAB)",
    "Student": 66,
    "Teacher": 25
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AFA2002",
    "Name": "SEKOLAH IZZUDDIN SHAH",
    "Student": 490,
    "Teacher": 44
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AFA2003",
    "Name": "SEKOLAH RAJA PEREMPUAN TAAYAH",
    "Student": 590,
    "Teacher": 39
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AFA8001",
    "Name": "SEKOLAH MENENGAH RAJA DR. NAZRIN SHAH",
    "Student": 469,
    "Teacher": 43
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BATANG PADANG",
    "Kod": "AFT0001",
    "Name": "SMA DAERAH BATANG PADANG",
    "Student": 300,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AFT1001",
    "Name": "MAAHAD ISLAHIAH ADDINIAH",
    "Student": 282,
    "Teacher": 35
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AFT2001",
    "Name": "MAAHAD AL-IMAM ASY SYAFIEE",
    "Student": 240,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AFT2002",
    "Name": "SABK MAAHAD AL-UMMAH",
    "Student": 503,
    "Teacher": 66
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AFT3001",
    "Name": "SEKOLAH MENENGAH AGAMA AL-HIDAYAH",
    "Student": 298,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AFT3003",
    "Name": "SMA SHAMSUL MAARIF AL WATANIAH",
    "Student": 172,
    "Teacher": 22
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AFT3004",
    "Name": "SEKOLAH MENENGAH AGAMA AL FALAH",
    "Student": 510,
    "Teacher": 46
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AFT3005",
    "Name": "MAAHAD AL EHYA ASSHARIFF",
    "Student": 360,
    "Teacher": 42
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AFT3006",
    "Name": "MA'AHAD AR-RIDHWAN",
    "Student": 275,
    "Teacher": 37
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AFT4001",
    "Name": "SEKOLAH MENENGAH AGAMA AD-DINIAH AL-ISLAMIAH",
    "Student": 264,
    "Teacher": 35
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AFT4002",
    "Name": "SEKOLAH MENENGAH AGAMA MAAHAD AL-MIZAN",
    "Student": 615,
    "Teacher": 57
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AFT4003",
    "Name": "SMA MAAHAD NURUL FADZLIAH",
    "Student": 206,
    "Teacher": 29
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AFT4004",
    "Name": "MAAHAD AL YAHYAWIAH",
    "Student": 254,
    "Teacher": 34
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AFT4005",
    "Name": "MADRASAH IDRISIAH",
    "Student": 434,
    "Teacher": 48
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AFT6001",
    "Name": "SEKOLAH MENENGAH ADDINIAH",
    "Student": 276,
    "Teacher": 40
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AFT6002",
    "Name": "MAAHAD AL MAARIF AL ISLAMIAH BATU 9 PERAK",
    "Student": 155,
    "Teacher": 23
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AFT6003",
    "Name": "SABK MAAHAD AL-KHAIR LIL BANAT",
    "Student": 463,
    "Teacher": 45
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AFT6004",
    "Name": "MAAHAD AL TARBIAH AL ISLAMIAH",
    "Student": 603,
    "Teacher": 49
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AFT6005",
    "Name": "SABK SYUBBANIAH",
    "Student": 200,
    "Teacher": 31
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AFT7001",
    "Name": "SMA TAN SRI GHAZALI JAWI",
    "Student": 242,
    "Teacher": 33
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AFT8001",
    "Name": "SMA MAAHAD AL AZIZ",
    "Student": 138,
    "Teacher": 19
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AFT8002",
    "Name": "MAAHAD EHYAK DINIAH ISLAMIAH",
    "Student": 236,
    "Teacher": 28
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD BAGAN DATUK",
    "Kod": "AFTB001",
    "Name": "SMA AL-ULUM AL-SYAR'IYYAH",
    "Student": 291,
    "Teacher": 30
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MANJUNG",
    "Kod": "AHA1002",
    "Name": "KOLEJ VOKASIONAL SERI MANJUNG",
    "Student": 505,
    "Teacher": 92
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AHA2001",
    "Name": "KOLEJ VOKASIONAL LEBUH CATOR",
    "Student": 602,
    "Teacher": 97
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA UTARA",
    "Kod": "AHA2002",
    "Name": "KOLEJ VOKASIONAL IPOH",
    "Student": 373,
    "Teacher": 62
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "AHA3002",
    "Name": "KOLEJ VOKASIONAL KERIAN",
    "Student": 478,
    "Teacher": 100
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KUALA KANGSAR",
    "Kod": "AHA4002",
    "Name": "KOLEJ VOKASIONAL KUALA KANGSAR",
    "Student": 774,
    "Teacher": 133
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HILIR PERAK",
    "Kod": "AHA5001",
    "Name": "KOLEJ VOKASIONAL (PERTANIAN) TELUK INTAN",
    "Student": 698,
    "Teacher": 131
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD HULU PERAK",
    "Kod": "AHA7001",
    "Name": "KOLEJ VOKASIONAL GERIK",
    "Student": 535,
    "Teacher": 113
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "AHA8002",
    "Name": "KOLEJ VOKASIONAL SERI ISKANDAR",
    "Student": 759,
    "Teacher": 129
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "AHAA001",
    "Name": "KOLEJ VOKASIONAL SLIM RIVER",
    "Student": 862,
    "Teacher": 126
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD LARUT/MATANG/SELAMA",
    "Kod": "AKE6159",
    "Name": "KOLEJ VOKASIONAL TAIPING",
    "Student": 787,
    "Teacher": 124
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KRIAN",
    "Kod": "ARA3001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA KERIAN",
    "Student": 676,
    "Teacher": 115
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD PERAK TENGAH",
    "Kod": "ARA8001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SULTAN AZLAN SHAH",
    "Student": 633,
    "Teacher": 56
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD KINTA SELATAN",
    "Kod": "ARA9001",
    "Name": "SEKOLAH BERASRAMA PENUH INTEGRASI GOPENG",
    "Student": 597,
    "Teacher": 54
  },
  {
    "NationalState": "PERAK",
    "PPD": "PPD MUALLIM",
    "Kod": "ARAA001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SLIM RIVER",
    "Student": 681,
    "Teacher": 57
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0001",
    "Name": "SEKOLAH KEBANGSAAN KLANG",
    "Student": 596,
    "Teacher": 53
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0002",
    "Name": "SEKOLAH KEBANGSAAN TELOK GADONG",
    "Student": 1188,
    "Teacher": 84
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0003",
    "Name": "SEKOLAH KEBANGSAAN PELABUHAN KELANG",
    "Student": 701,
    "Teacher": 48
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0004",
    "Name": "SEKOLAH KEBANGSAAN TELOK MENEGON",
    "Student": 1389,
    "Teacher": 76
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0005",
    "Name": "SEKOLAH KEBANGSAAN BUKIT NAGA",
    "Student": 2016,
    "Teacher": 110
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0006",
    "Name": "SEKOLAH KEBANGSAAN JALAN KEBUN",
    "Student": 2474,
    "Teacher": 129
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0007",
    "Name": "SEKOLAH KEBANGSAAN BATU BELAH",
    "Student": 1658,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0008",
    "Name": "SEKOLAH KEBANGSAAN RANTAU PANJANG",
    "Student": 2910,
    "Teacher": 159
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0009",
    "Name": "SEKOLAH KEBANGSAAN SEMENTA",
    "Student": 1939,
    "Teacher": 111
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0010",
    "Name": "SEKOLAH KEBANGSAAN KAPAR",
    "Student": 594,
    "Teacher": 43
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0011",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KAPAR",
    "Student": 2190,
    "Teacher": 111
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0012",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BINJAI",
    "Student": 3296,
    "Teacher": 177
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0013",
    "Name": "SEKOLAH KEBANGSAAN PULAU INDAH",
    "Student": 2064,
    "Teacher": 111
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0014",
    "Name": "SEKOLAH KEBANGSAAN TOK MUDA",
    "Student": 533,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0015",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KUDA",
    "Student": 620,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0016",
    "Name": "SEKOLAH KEBANGSAAN KG RAJA UDA",
    "Student": 757,
    "Teacher": 53
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0017",
    "Name": "SEKOLAH KEBANGSAAN PANDAMARAN JAYA",
    "Student": 1018,
    "Teacher": 62
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0018",
    "Name": "SEKOLAH KEBANGSAAN KG JOHAN SETIA",
    "Student": 1609,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0019",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SERDANG",
    "Student": 643,
    "Teacher": 41
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0021",
    "Name": "SEKOLAH KEBANGSAAN KG PENDAMAR",
    "Student": 1010,
    "Teacher": 63
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0022",
    "Name": "SEKOLAH KEBANGSAAN TELOK GONG",
    "Student": 1314,
    "Teacher": 82
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0023",
    "Name": "SEKOLAH KEBANGSAAN TAMAN KLANG JAYA",
    "Student": 1070,
    "Teacher": 78
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA0024",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SRI MUDA",
    "Student": 880,
    "Teacher": 61
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0025",
    "Name": "SEKOLAH KEBANGSAAN TELOK PULAI",
    "Student": 1155,
    "Teacher": 77
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0026",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI UDANG",
    "Student": 1794,
    "Teacher": 104
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0028",
    "Name": "SEKOLAH KEBANGSAAN PELABUHAN UTARA",
    "Student": 1294,
    "Teacher": 77
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0029",
    "Name": "SEKOLAH KEBANGSAAN ABDUL SAMAT",
    "Student": 772,
    "Teacher": 69
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0030",
    "Name": "SEKOLAH KEBANGSAAN MERU",
    "Student": 1945,
    "Teacher": 116
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0031",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG IDAMAN",
    "Student": 1401,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0032",
    "Name": "SEKOLAH KEBANGSAAN TAMAN KLANG UTAMA",
    "Student": 871,
    "Teacher": 77
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0033",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG JAWA",
    "Student": 741,
    "Teacher": 51
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0034",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SRI ANDALAS",
    "Student": 1874,
    "Teacher": 105
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0035",
    "Name": "SEKOLAH KEBANGSAAN TAMAN GEMBIRA",
    "Student": 515,
    "Teacher": 36
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0036",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TINGGI",
    "Student": 1243,
    "Teacher": 73
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0037",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KEMUNING 2",
    "Student": 1839,
    "Teacher": 101
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0038",
    "Name": "SEKOLAH KEBANGSAAN MERU (2)",
    "Student": 1398,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0039",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KAPAR INDAH",
    "Student": 1051,
    "Teacher": 73
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0040",
    "Name": "SEKOLAH KEBANGSAAN KOTA KEMUNING",
    "Student": 1472,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0041",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG JAWA 2",
    "Student": 1225,
    "Teacher": 85
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0042",
    "Name": "SEKOLAH KEBANGSAAN PULAU INDAH (2)",
    "Student": 693,
    "Teacher": 49
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0043",
    "Name": "SEKOLAH KEBANGSAAN BUKIT RIMAU",
    "Student": 2073,
    "Teacher": 124
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA0044",
    "Name": "SEKOLAH KEBANGSAAN BATU UNJUR",
    "Student": 1375,
    "Teacher": 101
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1002",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BUAYA",
    "Student": 545,
    "Teacher": 42
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1003",
    "Name": "SEKOLAH KEBANGSAAN BANDAR",
    "Student": 319,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1004",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI MANGGIS",
    "Student": 423,
    "Teacher": 31
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1005",
    "Name": "SEKOLAH KEBANGSAAN OLAK LEMPIT",
    "Student": 1145,
    "Teacher": 79
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1006",
    "Name": "SEKOLAH KEBANGSAAN LABOHAN DAGANG",
    "Student": 679,
    "Teacher": 44
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1007",
    "Name": "SEKOLAH KEBANGSAAN BKT CHANGGANG",
    "Student": 1299,
    "Teacher": 82
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1008",
    "Name": "SEKOLAH KEBANGSAAN JENJAROM",
    "Student": 1698,
    "Teacher": 96
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1009",
    "Name": "SEKOLAH KEBANGSAAN TELOK PANGLIMA GARANG",
    "Student": 1210,
    "Teacher": 85
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1010",
    "Name": "SEKOLAH KEBANGSAAN KEBUN BAHARU",
    "Student": 2469,
    "Teacher": 135
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1011",
    "Name": "SEKOLAH KEBANGSAAN SIJANGKANG",
    "Student": 1693,
    "Teacher": 93
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1012",
    "Name": "SEKOLAH KEBANGSAAN SG BUMBUN (A)",
    "Student": 172,
    "Teacher": 13
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1013",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI JUDAH",
    "Student": 116,
    "Teacher": 15
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1014",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TADOM",
    "Student": 71,
    "Teacher": 13
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1015",
    "Name": "SEKOLAH KEBANGSAAN SERI CHEDING",
    "Student": 360,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1016",
    "Name": "SEKOLAH KEBANGSAAN (ASLI) BKT CHEDING",
    "Student": 134,
    "Teacher": 20
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1023",
    "Name": "SEKOLAH KEBANGSAAN TG SEPAT",
    "Student": 497,
    "Teacher": 34
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1024",
    "Name": "SEKOLAH KEBANGSAAN BATU LAUT",
    "Student": 382,
    "Teacher": 36
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1025",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG ENDAH",
    "Student": 271,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1026",
    "Name": "SEKOLAH KEBANGSAAN MORIB",
    "Student": 282,
    "Teacher": 28
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1027",
    "Name": "SEKOLAH KEBANGSAAN KANCHONG DARAT",
    "Student": 1179,
    "Teacher": 70
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1028",
    "Name": "SEKOLAH KEBANGSAAN SG LANG",
    "Student": 559,
    "Teacher": 39
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1029",
    "Name": "SEKOLAH KEBANGSAAN SERI LANANG",
    "Student": 328,
    "Teacher": 33
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1030",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG PASIR",
    "Student": 183,
    "Teacher": 17
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1031",
    "Name": "SEKOLAH KEBANGSAAN SG KELAMBU",
    "Student": 262,
    "Teacher": 24
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1032",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG MEDAN",
    "Student": 847,
    "Teacher": 58
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1033",
    "Name": "SEKOLAH KEBANGSAAN JALAN TANJUNG",
    "Student": 867,
    "Teacher": 56
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1034",
    "Name": "SEKOLAH KEBANGSAAN KANCHONG TENGAH",
    "Student": 154,
    "Teacher": 17
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1035",
    "Name": "SEKOLAH KEBANGSAAN SIJANGKANG JAYA",
    "Student": 779,
    "Teacher": 58
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1036",
    "Name": "SEKOLAH KEBANGSAAN RTB BUKIT CHANGGANG",
    "Student": 1384,
    "Teacher": 87
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1037",
    "Name": "SEKOLAH KEBANGSAAN JUGRA",
    "Student": 866,
    "Teacher": 68
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1038",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BANTING",
    "Student": 716,
    "Teacher": 55
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA1039",
    "Name": "SEKOLAH KEBANGSAAN (ASLI) BUKIT KEMANDOL",
    "Student": 248,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3001",
    "Name": "SEKOLAH KEBANGSAAN ASSAM JAWA",
    "Student": 717,
    "Teacher": 47
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3002",
    "Name": "SEKOLAH KEBANGSAAN BKT BADONG",
    "Student": 965,
    "Teacher": 60
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3003",
    "Name": "SEKOLAH KEBANGSAAN BKT KERAYONG",
    "Student": 436,
    "Teacher": 32
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3004",
    "Name": "SEKOLAH KEBANGSAAN DATO' MAHARAJA LELA",
    "Student": 1562,
    "Teacher": 98
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3005",
    "Name": "SEKOLAH KEBANGSAAN TAMBAK JAWA",
    "Student": 314,
    "Teacher": 31
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3006",
    "Name": "SEKOLAH KEBANGSAAN JERAM",
    "Student": 333,
    "Teacher": 30
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3007",
    "Name": "SEKOLAH KEBANGSAAN JERAM BT 20",
    "Student": 579,
    "Teacher": 67
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3008",
    "Name": "SEKOLAH KEBANGSAAN JAYA SETIA",
    "Student": 266,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3009",
    "Name": "SEKOLAH KEBANGSAAN PASANGAN",
    "Student": 174,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3010",
    "Name": "SEKOLAH KEBANGSAAN RANTAU PANJANG",
    "Student": 305,
    "Teacher": 33
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3011",
    "Name": "SEKOLAH KEBANGSAAN IJOK",
    "Student": 554,
    "Teacher": 41
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3012",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG KERAMAT",
    "Student": 342,
    "Teacher": 32
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3013",
    "Name": "SEKOLAH KEBANGSAAN BKT BELIMBING",
    "Student": 219,
    "Teacher": 21
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3014",
    "Name": "SEKOLAH KEBANGSAAN KUALA SELANGOR",
    "Student": 309,
    "Teacher": 31
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3015",
    "Name": "SEKOLAH KEBANGSAAN TANJONG KARANG",
    "Student": 318,
    "Teacher": 32
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3016",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BURONG",
    "Student": 256,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3017",
    "Name": "SEKOLAH KEBANGSAAN SRI TIRAM",
    "Student": 270,
    "Teacher": 30
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3018",
    "Name": "SEKOLAH KEBANGSAAN DATO' MANAN",
    "Student": 596,
    "Teacher": 46
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3019",
    "Name": "SEKOLAH KEBANGSAAN SERI GAMBUT",
    "Student": 153,
    "Teacher": 17
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3020",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SIREH",
    "Student": 520,
    "Teacher": 49
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3021",
    "Name": "SEKOLAH KEBANGSAAN PARIT EMPAT",
    "Student": 183,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3022",
    "Name": "SEKOLAH KEBANGSAAN PARIT MAHANG",
    "Student": 570,
    "Teacher": 38
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3024",
    "Name": "SEKOLAH KEBANGSAAN JALAN RAJA MUSA",
    "Student": 146,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3025",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TERAP",
    "Student": 444,
    "Teacher": 38
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3026",
    "Name": "SEKOLAH KEBANGSAAN PARIT SERONG",
    "Student": 185,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3028",
    "Name": "SEKOLAH KEBANGSAAN SAWAH SEMPADAN",
    "Student": 227,
    "Teacher": 23
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3029",
    "Name": "SEKOLAH KEBANGSAAN BERJAYA",
    "Student": 240,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBA3030",
    "Name": "SEKOLAH KEBANGSAAN BUKIT CERAKA",
    "Student": 271,
    "Teacher": 24
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3031",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU",
    "Student": 1241,
    "Teacher": 78
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3032",
    "Name": "SEKOLAH KEBANGSAAN BESTARI JAYA",
    "Student": 366,
    "Teacher": 39
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3033",
    "Name": "SEKOLAH KEBANGSAAN PUNCAK ALAM",
    "Student": 1245,
    "Teacher": 75
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3034",
    "Name": "SEKOLAH KEBANGSAAN SAUJANA UTAMA IJOK",
    "Student": 3357,
    "Teacher": 174
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3035",
    "Name": "SEKOLAH KEBANGSAAN PUNCAK ALAM 2",
    "Student": 2667,
    "Teacher": 137
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3036",
    "Name": "SEKOLAH KEBANGSAAN SERI PRISTANA",
    "Student": 2311,
    "Teacher": 128
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3037",
    "Name": "SEKOLAH KEBANGSAAN PUNCAK ALAM 3",
    "Student": 1925,
    "Teacher": 105
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBA3038",
    "Name": "SEKOLAH KEBANGSAAN DESA AMAN",
    "Student": 1661,
    "Teacher": 92
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4001",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SERAI",
    "Student": 691,
    "Teacher": 54
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4002",
    "Name": "SEKOLAH KEBANGSAAN JALAN ENAM",
    "Student": 1801,
    "Teacher": 118
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4003",
    "Name": "SEKOLAH KEBANGSAAN AMPANG CAMPURAN",
    "Student": 1232,
    "Teacher": 87
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4004",
    "Name": "SEKOLAH KEBANGSAAN SERI SEKAMAT",
    "Student": 1341,
    "Teacher": 96
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4005",
    "Name": "SEKOLAH KEBANGSAAN BATU SEMBILAN",
    "Student": 759,
    "Teacher": 47
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4006",
    "Name": "SEKOLAH KEBANGSAAN TUN ABD AZIZ MAJID",
    "Student": 1317,
    "Teacher": 65
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4007",
    "Name": "SEKOLAH KEBANGSAAN SG TEKALI",
    "Student": 280,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4008",
    "Name": "SEKOLAH KEBANGSAAN KUALA POMSON",
    "Student": 115,
    "Teacher": 17
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4009",
    "Name": "SEKOLAH KEBANGSAAN DUSUN TUA",
    "Student": 770,
    "Teacher": 54
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4010",
    "Name": "SEKOLAH KEBANGSAAN LUBOK KELUBI",
    "Student": 451,
    "Teacher": 35
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4011",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LUI",
    "Student": 333,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4012",
    "Name": "SEKOLAH KEBANGSAAN LEFTENAN ADNAN",
    "Student": 1313,
    "Teacher": 74
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4013",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RAMAL DALAM",
    "Student": 1195,
    "Teacher": 72
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4014",
    "Name": "SEKOLAH KEBANGSAAN TAMAN NIRWANA",
    "Student": 1505,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4015",
    "Name": "SEKOLAH KEBANGSAAN KAJANG",
    "Student": 847,
    "Teacher": 52
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4016",
    "Name": "SEKOLAH KEBANGSAAN BERANANG",
    "Student": 925,
    "Teacher": 51
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4017",
    "Name": "SEKOLAH KEBANGSAAN KG KUALA PAJAM",
    "Student": 222,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4018",
    "Name": "SEKOLAH KEBANGSAAN BANGI",
    "Student": 1430,
    "Teacher": 82
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4019",
    "Name": "SEKOLAH KEBANGSAAN KG CHERAS BAHARU",
    "Student": 686,
    "Teacher": 48
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4020",
    "Name": "SEKOLAH KEBANGSAAN LEMBAH JAYA",
    "Student": 1339,
    "Teacher": 77
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4021",
    "Name": "SEKOLAH KEBANGSAAN ULU SEMENYIH",
    "Student": 689,
    "Teacher": 47
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4022",
    "Name": "SEKOLAH KEBANGSAAN KG RINCING",
    "Student": 726,
    "Teacher": 41
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4023",
    "Name": "SEKOLAH KEBANGSAAN RINCHING HILIR",
    "Student": 806,
    "Teacher": 42
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4024",
    "Name": "SEKOLAH KEBANGSAAN AMPANG",
    "Student": 1222,
    "Teacher": 75
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4025",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU BANGI",
    "Student": 1623,
    "Teacher": 99
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4026",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TASIK",
    "Student": 1607,
    "Teacher": 96
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4027",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KANTAN",
    "Student": 976,
    "Teacher": 83
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4028",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MUDA",
    "Student": 1111,
    "Teacher": 63
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4029",
    "Name": "SEKOLAH KEBANGSAAN TAMAN CUEPACS",
    "Student": 1134,
    "Teacher": 79
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4030",
    "Name": "SEKOLAH KEBANGSAAN TAMAN KOSAS",
    "Student": 1455,
    "Teacher": 85
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4031",
    "Name": "SEKOLAH KEBANGSAAN TAMING JAYA",
    "Student": 2529,
    "Teacher": 144
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4032",
    "Name": "SEKOLAH KEBANGSAAN JALAN 3",
    "Student": 1833,
    "Teacher": 105
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4033",
    "Name": "SEKOLAH KEBANGSAAN PANDAN INDAH",
    "Student": 1778,
    "Teacher": 108
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4034",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TUN HUSSEIN ONN",
    "Student": 2038,
    "Teacher": 138
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4035",
    "Name": "SEKOLAH KEBANGSAAN PANDAN JAYA",
    "Student": 732,
    "Teacher": 48
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4036",
    "Name": "SEKOLAH KEBANGSAAN SAUJANA IMPIAN",
    "Student": 1116,
    "Teacher": 66
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4037",
    "Name": "SEKOLAH KEBANGSAAN KAJANG UTAMA",
    "Student": 1601,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4038",
    "Name": "SEKOLAH KEBANGSAAN TAMAN RAKAN",
    "Student": 1438,
    "Teacher": 99
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4039",
    "Name": "SEKOLAH KEBANGSAAN SRI JELOK",
    "Student": 949,
    "Teacher": 63
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4040",
    "Name": "SEKOLAH KEBANGSAAN CHERAS INDAH",
    "Student": 454,
    "Teacher": 47
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4041",
    "Name": "SEKOLAH KEBANGSAAN BUKIT RAYA",
    "Student": 716,
    "Teacher": 59
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4042",
    "Name": "SEKOLAH KEBANGSAAN TASEK PERMAI",
    "Student": 344,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4043",
    "Name": "SEKOLAH KEBANGSAAN DESA BAIDURI",
    "Student": 1350,
    "Teacher": 81
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4044",
    "Name": "SEKOLAH KEBANGSAAN TAMAN JASMIN",
    "Student": 1465,
    "Teacher": 96
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4045",
    "Name": "SEKOLAH KEBANGSAAN JALAN EMPAT",
    "Student": 2416,
    "Teacher": 131
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4046",
    "Name": "SEKOLAH KEBANGSAAN KANTAN PERMAI",
    "Student": 2134,
    "Teacher": 114
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4047",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 7",
    "Student": 2805,
    "Teacher": 156
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4048",
    "Name": "SEKOLAH KEBANGSAAN PANDAN PERDANA",
    "Student": 294,
    "Teacher": 24
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4049",
    "Name": "SEKOLAH KEBANGSAAN SAUJANA IMPIAN (2)",
    "Student": 1151,
    "Teacher": 70
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4050",
    "Name": "SEKOLAH KEBANGSAAN CHERAS JAYA",
    "Student": 1250,
    "Teacher": 81
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4051",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TASIK KESUMA",
    "Student": 2744,
    "Teacher": 125
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4052",
    "Name": "SEKOLAH KEBANGSAAN BANDAR RINCHING",
    "Student": 2671,
    "Teacher": 132
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4053",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TEKNOLOGI KAJANG",
    "Student": 1927,
    "Teacher": 109
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4054",
    "Name": "SEKOLAH KEBANGSAAN DUSUN NANDING",
    "Student": 680,
    "Teacher": 43
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4055",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TUN HUSSEIN ONN 2",
    "Student": 1949,
    "Teacher": 130
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4056",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUKIT TERATAI",
    "Student": 978,
    "Teacher": 56
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4057",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PUTERI",
    "Student": 478,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4058",
    "Name": "SEKOLAH KEBANGSAAN BANDAR SERI PUTRA",
    "Student": 2667,
    "Teacher": 146
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4060",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BUKIT MAHKOTA",
    "Student": 1810,
    "Teacher": 105
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBA4061",
    "Name": "SEKOLAH KEBANGSAAN BANDAR SUNWAY SEMENYIH",
    "Student": 832,
    "Teacher": 49
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5002",
    "Name": "SEKOLAH KEBANGSAAN BATANG KALI",
    "Student": 857,
    "Teacher": 67
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5003",
    "Name": "SEKOLAH KEBANGSAAN RASA",
    "Student": 543,
    "Teacher": 45
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5004",
    "Name": "SEKOLAH KEBANGSAAN SG CHOH",
    "Student": 483,
    "Teacher": 36
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5005",
    "Name": "SEKOLAH KEBANGSAAN SG SELISEK",
    "Student": 146,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5006",
    "Name": "SEKOLAH KEBANGSAAN ULU YAM BHARU",
    "Student": 850,
    "Teacher": 56
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5007",
    "Name": "SEKOLAH KEBANGSAAN ULU YAM LAMA",
    "Student": 135,
    "Teacher": 12
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5008",
    "Name": "SEKOLAH KEBANGSAAN HULU BERNAM",
    "Student": 640,
    "Teacher": 53
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5009",
    "Name": "SEKOLAH KEBANGSAAN KUALA KUBU BHARU",
    "Student": 325,
    "Teacher": 42
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5010",
    "Name": "SEKOLAH KEBANGSAAN KERLING",
    "Student": 187,
    "Teacher": 20
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5011",
    "Name": "SEKOLAH KEBANGSAAN KALUMPANG",
    "Student": 524,
    "Teacher": 42
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5012",
    "Name": "SEKOLAH KEBANGSAAN KG KUANTAN",
    "Student": 268,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5013",
    "Name": "SEKOLAH KEBANGSAAN GEDANGSA",
    "Student": 170,
    "Teacher": 20
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5014",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BUAYA",
    "Student": 350,
    "Teacher": 31
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5015",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SOEHARTO",
    "Student": 220,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5016",
    "Name": "SEKOLAH KEBANGSAAN TUN ABDUL RAZAK",
    "Student": 152,
    "Teacher": 21
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5018",
    "Name": "SEKOLAH KEBANGSAAN GESIR TENGAH",
    "Student": 131,
    "Teacher": 17
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5020",
    "Name": "SEKOLAH KEBANGSAAN SG TENGI",
    "Student": 298,
    "Teacher": 33
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5021",
    "Name": "SEKOLAH KEBANGSAAN SRI KELEDANG",
    "Student": 59,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5022",
    "Name": "SEKOLAH KEBANGSAAN SERENDAH",
    "Student": 1185,
    "Teacher": 85
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5023",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BERUNTUNG",
    "Student": 1525,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5024",
    "Name": "SEKOLAH KEBANGSAAN AMPANG PECAH",
    "Student": 504,
    "Teacher": 48
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5025",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU BATANG KALI",
    "Student": 1403,
    "Teacher": 90
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5026",
    "Name": "SEKOLAH KEBANGSAAN BANDAR SUNGAI BUAYA",
    "Student": 1016,
    "Teacher": 68
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5027",
    "Name": "SEKOLAH KEBANGSAAN SERI FAJAR",
    "Student": 69,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5028",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUNGA RAYA (1)",
    "Student": 1566,
    "Teacher": 102
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5029",
    "Name": "SEKOLAH KEBANGSAAN LEMBAH BERINGIN",
    "Student": 138,
    "Teacher": 20
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5030",
    "Name": "SEKOLAH KEBANGSAAN BUKIT SENTOSA",
    "Student": 2798,
    "Teacher": 141
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5031",
    "Name": "SEKOLAH KEBANGSAAN ANTARA GAPI",
    "Student": 1094,
    "Teacher": 71
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5032",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BERUNTUNG 2",
    "Student": 1799,
    "Teacher": 110
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBA5033",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUNGA RAYA 2",
    "Student": 1213,
    "Teacher": 80
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6001",
    "Name": "SEKOLAH KEBANGSAAN SERI MAKMUR",
    "Student": 850,
    "Teacher": 73
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6002",
    "Name": "SEKOLAH KEBANGSAAN SG HJ DORANI",
    "Student": 222,
    "Teacher": 23
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6003",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG LIMA",
    "Student": 374,
    "Teacher": 35
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6004",
    "Name": "SEKOLAH KEBANGSAAN PANCHANG PEDENA",
    "Student": 201,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6005",
    "Name": "SEKOLAH KEBANGSAAN SG LIMAU",
    "Student": 420,
    "Teacher": 33
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6006",
    "Name": "SEKOLAH KEBANGSAAN PARIT 4",
    "Student": 127,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6007",
    "Name": "SEKOLAH KEBANGSAAN PARIT 6",
    "Student": 254,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6008",
    "Name": "SEKOLAH KEBANGSAAN SERI BAHAGIA",
    "Student": 226,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6009",
    "Name": "SEKOLAH KEBANGSAAN TOK KHALIFAH",
    "Student": 219,
    "Teacher": 22
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6010",
    "Name": "SEKOLAH KEBANGSAAN BAGAN TERAP",
    "Student": 207,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6011",
    "Name": "SEKOLAH KEBANGSAAN PARIT 13",
    "Student": 70,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6012",
    "Name": "SEKOLAH KEBANGSAAN LOBOK PUSING",
    "Student": 57,
    "Teacher": 13
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6013",
    "Name": "SEKOLAH KEBANGSAAN DOKTOR ABDUL LATIFF",
    "Student": 393,
    "Teacher": 35
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6014",
    "Name": "SEKOLAH KEBANGSAAN BATU 38",
    "Student": 423,
    "Teacher": 44
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6015",
    "Name": "SEKOLAH KEBANGSAAN KG BAHARU",
    "Student": 29,
    "Teacher": 11
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6016",
    "Name": "SEKOLAH KEBANGSAAN TUN DOKTOR ISMAIL",
    "Student": 221,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6017",
    "Name": "SEKOLAH KEBANGSAAN SEKENDI",
    "Student": 121,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6018",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG 4 BNO",
    "Student": 132,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6019",
    "Name": "SEKOLAH KEBANGSAAN PARIT BAHARU",
    "Student": 332,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6020",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TENGAR",
    "Student": 111,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6021",
    "Name": "SEKOLAH KEBANGSAAN SERI MAWAR",
    "Student": 182,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6022",
    "Name": "SEKOLAH KEBANGSAAN TEBOK JAWA",
    "Student": 129,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6023",
    "Name": "SEKOLAH KEBANGSAAN SAPINTAS",
    "Student": 158,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6024",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BANTING",
    "Student": 126,
    "Teacher": 21
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6025",
    "Name": "SEKOLAH KEBANGSAAN TANJONG JAWA",
    "Student": 14,
    "Teacher": 7
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6026",
    "Name": "SEKOLAH KEBANGSAAN TELUK RU",
    "Student": 108,
    "Teacher": 23
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6027",
    "Name": "SEKOLAH KEBANGSAAN SERI CEMPAKA",
    "Student": 121,
    "Teacher": 14
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6028",
    "Name": "SEKOLAH KEBANGSAAN SG LEMAN",
    "Student": 301,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6029",
    "Name": "SEKOLAH KEBANGSAAN PARIT 9",
    "Student": 199,
    "Teacher": 22
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6030",
    "Name": "SEKOLAH KEBANGSAAN PASIR PANJANG",
    "Student": 370,
    "Teacher": 31
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6031",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI NIBONG",
    "Student": 216,
    "Teacher": 20
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBA6032",
    "Name": "SEKOLAH KEBANGSAAN BINJAI JAYA",
    "Student": 480,
    "Teacher": 56
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7101",
    "Name": "SEKOLAH KEBANGSAAN SELAYANG BARU (2)",
    "Student": 971,
    "Teacher": 61
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7102",
    "Name": "SEKOLAH KEBANGSAAN (2) TAMAN SELAYANG",
    "Student": 954,
    "Teacher": 65
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7103",
    "Name": "SEKOLAH KEBANGSAAN (2) TAMAN KERAMAT",
    "Student": 588,
    "Teacher": 49
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7201",
    "Name": "SEKOLAH KEBANGSAAN HULU KELANG",
    "Student": 810,
    "Teacher": 49
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7202",
    "Name": "SEKOLAH KEBANGSAAN KLANG GATE",
    "Student": 352,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7203",
    "Name": "SEKOLAH KEBANGSAAN GOMBAK SETIA",
    "Student": 1472,
    "Teacher": 89
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7204",
    "Name": "SEKOLAH KEBANGSAAN GOMBAK UTARA",
    "Student": 1083,
    "Teacher": 63
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7205",
    "Name": "SEKOLAH KEBANGSAAN SG TUA BAHARU",
    "Student": 1712,
    "Teacher": 93
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7206",
    "Name": "SEKOLAH KEBANGSAAN KEPONG",
    "Student": 426,
    "Teacher": 33
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7207",
    "Name": "SEKOLAH KEBANGSAAN RAWANG",
    "Student": 249,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7208",
    "Name": "SEKOLAH KEBANGSAAN KUANG",
    "Student": 1890,
    "Teacher": 110
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7209",
    "Name": "SEKOLAH KEBANGSAAN SG SERAI",
    "Student": 457,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7210",
    "Name": "SEKOLAH KEBANGSAAN SG PELONG",
    "Student": 1775,
    "Teacher": 101
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7211",
    "Name": "SEKOLAH KEBANGSAAN TAMAN KERAMAT (1)",
    "Student": 442,
    "Teacher": 32
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7212",
    "Name": "SEKOLAH KEBANGSAAN SG BULUH",
    "Student": 1638,
    "Teacher": 113
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7213",
    "Name": "SEKOLAH KEBANGSAAN TAMAN EHSAN",
    "Student": 781,
    "Teacher": 62
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7214",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MELAWATI",
    "Student": 1497,
    "Teacher": 85
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7215",
    "Name": "SEKOLAH KEBANGSAAN (1) TAMAN SELAYANG",
    "Student": 851,
    "Teacher": 67
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7216",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SERI GOMBAK",
    "Student": 2005,
    "Teacher": 130
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7217",
    "Name": "SEKOLAH KEBANGSAAN LEMBAH KERAMAT",
    "Student": 1326,
    "Teacher": 75
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7219",
    "Name": "SEKOLAH KEBANGSAAN SELAYANG JAYA",
    "Student": 729,
    "Teacher": 58
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7220",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU SELAYANG",
    "Student": 925,
    "Teacher": 59
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7221",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SELASIH",
    "Student": 1271,
    "Teacher": 78
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7222",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PERMATA",
    "Student": 680,
    "Teacher": 55
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7224",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MELAWATI 2",
    "Student": 1879,
    "Teacher": 131
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7225",
    "Name": "SEKOLAH KEBANGSAAN SELAYANG UTAMA",
    "Student": 809,
    "Teacher": 51
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7226",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KERTAS",
    "Student": 969,
    "Teacher": 65
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7227",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SAMUDRA",
    "Student": 787,
    "Teacher": 52
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7228",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PUSU",
    "Student": 1646,
    "Teacher": 96
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7229",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SETIA",
    "Student": 666,
    "Teacher": 43
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7230",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DESA",
    "Student": 1861,
    "Teacher": 103
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7231",
    "Name": "SEKOLAH KEBANGSAAN BUKIT RAWANG JAYA",
    "Student": 751,
    "Teacher": 47
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7232",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUKIT INDAH",
    "Student": 1726,
    "Teacher": 100
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7233",
    "Name": "SEKOLAH KEBANGSAAN BUKIT RAHMAN PUTRA",
    "Student": 1458,
    "Teacher": 98
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7234",
    "Name": "SEKOLAH KEBANGSAAN SERI KUNDANG",
    "Student": 2256,
    "Teacher": 115
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7235",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SERI GOMBAK 2",
    "Student": 1946,
    "Teacher": 121
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7236",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DESA 2",
    "Student": 1724,
    "Teacher": 117
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7237",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU RAWANG",
    "Student": 659,
    "Teacher": 55
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7238",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TASIK PUTERI",
    "Student": 2237,
    "Teacher": 135
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7239",
    "Name": "SEKOLAH KEBANGSAAN DESA AMANPURI",
    "Student": 366,
    "Teacher": 28
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7240",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PRIMA SELAYANG",
    "Student": 670,
    "Teacher": 42
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7241",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU SELAYANG (2)",
    "Student": 944,
    "Teacher": 59
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7242",
    "Name": "SEKOLAH KEBANGSAAN TASIK PUTERI 2",
    "Student": 1783,
    "Teacher": 102
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA7243",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TUN TEJA",
    "Student": 1821,
    "Teacher": 110
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8101",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DATO HARUN (2)",
    "Student": 1031,
    "Teacher": 58
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8102",
    "Name": "SEKOLAH KEBANGSAAN KELANA JAYA (2)",
    "Student": 261,
    "Teacher": 20
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8103",
    "Name": "SEKOLAH KEBANGSAAN DAMANSARA JAYA (2)",
    "Student": 136,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8104",
    "Name": "SEKOLAH KEBANGSAAN SS 19 SUBANG JAYA",
    "Student": 255,
    "Teacher": 32
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8105",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 24",
    "Student": 1992,
    "Teacher": 111
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8106",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MEGAH",
    "Student": 970,
    "Teacher": 51
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8201",
    "Name": "SEKOLAH KEBANGSAAN PETALING JAYA",
    "Student": 660,
    "Teacher": 39
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8202",
    "Name": "SEKOLAH KEBANGSAAN SUBANG",
    "Student": 1702,
    "Teacher": 99
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8203",
    "Name": "SEKOLAH KEBANGSAAN PADANG JAWA",
    "Student": 1286,
    "Teacher": 78
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8204",
    "Name": "SEKOLAH KEBANGSAAN PUCHONG",
    "Student": 673,
    "Teacher": 45
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8205",
    "Name": "SEKOLAH KEBANGSAAN SERDANG",
    "Student": 994,
    "Teacher": 77
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8206",
    "Name": "SEKOLAH KEBANGSAAN BUKIT LANJAN",
    "Student": 176,
    "Teacher": 20
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA8207",
    "Name": "SEKOLAH KEBANGSAAN MERBAU SEMPAK",
    "Student": 1862,
    "Teacher": 110
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8208",
    "Name": "SEKOLAH KEBANGSAAN DAMANSARA UTAMA",
    "Student": 677,
    "Teacher": 42
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8209",
    "Name": "SEKOLAH KEBANGSAAN SRI DAMAI",
    "Student": 222,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8210",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DATO' HARUN (SATU)",
    "Student": 983,
    "Teacher": 56
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8211",
    "Name": "SEKOLAH KEBANGSAAN KELANA JAYA (1)",
    "Student": 676,
    "Teacher": 45
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8212",
    "Name": "SEKOLAH KEBANGSAAN SUBANG JAYA",
    "Student": 845,
    "Teacher": 58
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8213",
    "Name": "SEKOLAH KEBANGSAAN RAJA MUDA",
    "Student": 1365,
    "Teacher": 100
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8214",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SEA",
    "Student": 138,
    "Teacher": 21
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8215",
    "Name": "SEKOLAH KEBANGSAAN DAMANSARA JAYA (1)",
    "Student": 278,
    "Teacher": 28
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8216",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN ENAM",
    "Student": 1345,
    "Teacher": 78
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8217",
    "Name": "SEKOLAH KEBANGSAAN SRI SUBANG JAYA",
    "Student": 398,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8218",
    "Name": "SEKOLAH KEBANGSAAN SRI SERDANG",
    "Student": 1891,
    "Teacher": 100
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8219",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 17 SHAH ALAM",
    "Student": 1353,
    "Teacher": 82
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8220",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MEDAN",
    "Student": 1952,
    "Teacher": 123
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8221",
    "Name": "SEKOLAH KEBANGSAAN SRI KELANA",
    "Student": 795,
    "Teacher": 64
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8222",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 19",
    "Student": 1494,
    "Teacher": 97
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8223",
    "Name": "SEKOLAH KEBANGSAAN HICOM",
    "Student": 1287,
    "Teacher": 79
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8224",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 18",
    "Student": 1425,
    "Teacher": 81
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8225",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 20",
    "Student": 2029,
    "Teacher": 127
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBA8226",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU SUNGAI BULOH",
    "Student": 1873,
    "Teacher": 111
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8227",
    "Name": "SEKOLAH KEBANGSAAN SEAFIELD",
    "Student": 657,
    "Teacher": 44
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8228",
    "Name": "SEKOLAH KEBANGSAAN TAMAN UNIVERSITI",
    "Student": 2233,
    "Teacher": 108
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8229",
    "Name": "SEKOLAH KEBANGSAAN PUCHONG JAYA",
    "Student": 378,
    "Teacher": 31
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8230",
    "Name": "SEKOLAH KEBANGSAAN TAMAN ALAM MEGAH",
    "Student": 1653,
    "Teacher": 88
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8231",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SRI MUDA 2",
    "Student": 1197,
    "Teacher": 81
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8232",
    "Name": "SEKOLAH KEBANGSAAN PERDANA JAYA SS19",
    "Student": 429,
    "Teacher": 41
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8233",
    "Name": "SEKOLAH KEBANGSAAN TUDM SUBANG",
    "Student": 1311,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8234",
    "Name": "SEKOLAH KEBANGSAAN BANDAR SUNWAY",
    "Student": 856,
    "Teacher": 59
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8235",
    "Name": "SEKOLAH KEBANGSAAN PUCHONG PERDANA",
    "Student": 2155,
    "Teacher": 118
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8236",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 7 KOTA DAMANSARA",
    "Student": 895,
    "Teacher": 62
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8237",
    "Name": "SEKOLAH KEBANGSAAN SERI SELANGOR",
    "Student": 1104,
    "Teacher": 67
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8238",
    "Name": "SEKOLAH KEBANGSAAN BANDAR ANGGERIK",
    "Student": 1814,
    "Teacher": 90
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8239",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KUCHAI",
    "Student": 584,
    "Teacher": 39
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8240",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TUN DR ISMAIL JAYA",
    "Student": 1530,
    "Teacher": 80
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8241",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU SRI DAMANSARA",
    "Student": 398,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8242",
    "Name": "SEKOLAH KEBANGSAAN ALAM MEGAH DUA",
    "Student": 859,
    "Teacher": 58
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8243",
    "Name": "SEKOLAH KEBANGSAAN SEAFIELD 3",
    "Student": 944,
    "Teacher": 59
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8244",
    "Name": "SEKOLAH KEBANGSAAN BANDAR UTAMA DAMANSARA",
    "Student": 141,
    "Teacher": 21
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8245",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS SELANGOR",
    "Student": 95,
    "Teacher": 42
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8246",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 9 SHAH ALAM",
    "Student": 1239,
    "Teacher": 76
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8247",
    "Name": "SEKOLAH KEBANGSAAN USJ 2",
    "Student": 594,
    "Teacher": 58
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8248",
    "Name": "SEKOLAH KEBANGSAAN BANDAR UTAMA DAMANSARA 2",
    "Student": 394,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8249",
    "Name": "SEKOLAH KEBANGSAAN PUCHONG UTAMA",
    "Student": 1633,
    "Teacher": 98
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8250",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 6 KOTA DAMANSARA",
    "Student": 1392,
    "Teacher": 87
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8251",
    "Name": "SEKOLAH KEBANGSAAN USJ 20",
    "Student": 964,
    "Teacher": 59
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8252",
    "Name": "SEKOLAH KEBANGSAAN USJ 12",
    "Student": 1005,
    "Teacher": 63
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8253",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU SRI DAMANSARA 2",
    "Student": 475,
    "Teacher": 38
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8254",
    "Name": "SEKOLAH KEBANGSAAN PUCHONG UTAMA 2",
    "Student": 1841,
    "Teacher": 102
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8255",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 1 BANDAR KINRARA",
    "Student": 1761,
    "Teacher": 101
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8256",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 13",
    "Student": 1459,
    "Teacher": 91
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8257",
    "Name": "SEKOLAH KEBANGSAAN PUCHONG INDAH",
    "Student": 2486,
    "Teacher": 133
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8258",
    "Name": "SEKOLAH KEBANGSAAN PUSAT BANDAR PUCHONG (1)",
    "Student": 487,
    "Teacher": 39
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8259",
    "Name": "SEKOLAH KEBANGSAAN BANDAR SRI DAMANSARA 3",
    "Student": 559,
    "Teacher": 38
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8260",
    "Name": "SEKOLAH KEBANGSAAN BANDAR UTAMA DAMANSARA (4)",
    "Student": 840,
    "Teacher": 54
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8261",
    "Name": "SEKOLAH KEBANGSAAN ALAM MEGAH (3)",
    "Student": 1169,
    "Teacher": 83
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8262",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 2 BANDAR KINRARA",
    "Student": 1267,
    "Teacher": 73
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8263",
    "Name": "SEKOLAH KEBANGSAAN TROPICANA",
    "Student": 615,
    "Teacher": 36
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8264",
    "Name": "SEKOLAH KEBANGSAAN BUKIT JELUTONG",
    "Student": 2070,
    "Teacher": 119
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8265",
    "Name": "SEKOLAH KEBANGSAAN BANDAR SRI DAMANSARA 1",
    "Student": 1024,
    "Teacher": 67
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8266",
    "Name": "SEKOLAH KEBANGSAAN DATO' ONN JAAFAR",
    "Student": 663,
    "Teacher": 45
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8267",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 27(1)",
    "Student": 1181,
    "Teacher": 70
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8268",
    "Name": "SEKOLAH KEBANGSAAN SUBANG BESTARI",
    "Student": 2396,
    "Teacher": 143
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8269",
    "Name": "SEKOLAH KEBANGSAAN PUSAT BANDAR PUCHONG 2",
    "Student": 755,
    "Teacher": 51
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8270",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 9 KOTA DAMANSARA",
    "Student": 900,
    "Teacher": 52
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8271",
    "Name": "SEKOLAH KEBANGSAAN DAMANSARA DAMAI 1",
    "Student": 1688,
    "Teacher": 104
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8272",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 7",
    "Student": 1666,
    "Teacher": 92
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8273",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 27 (2)",
    "Student": 985,
    "Teacher": 63
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8274",
    "Name": "SEKOLAH KEBANGSAAN PUCHONG JAYA 2",
    "Student": 419,
    "Teacher": 33
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8275",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUKIT SUBANG",
    "Student": 1609,
    "Teacher": 96
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8276",
    "Name": "SEKOLAH KEBANGSAAN LEMBAH SUBANG",
    "Student": 1084,
    "Teacher": 64
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8277",
    "Name": "SEKOLAH KEBANGSAAN SEKSYEN 11 KOTA DAMANSARA",
    "Student": 896,
    "Teacher": 64
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8279",
    "Name": "SEKOLAH KEBANGSAAN PUCHONG UTAMA 3",
    "Student": 810,
    "Teacher": 65
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8280",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SUNGAI BESI INDAH",
    "Student": 1321,
    "Teacher": 82
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8281",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG LINDUNGAN",
    "Student": 1123,
    "Teacher": 61
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8282",
    "Name": "SEKOLAH KEBANGSAAN JALAN U3",
    "Student": 1807,
    "Teacher": 103
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8283",
    "Name": "SEKOLAH KEBANGSAAN SETIA ALAM",
    "Student": 2302,
    "Teacher": 128
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8284",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DESAMINIUM",
    "Student": 2237,
    "Teacher": 141
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBA8285",
    "Name": "SEKOLAH KEBANGSAAN DAMANSARA DAMAI 2",
    "Student": 1309,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8286",
    "Name": "SEKOLAH KEBANGSAAN SAUJANA PUCHONG",
    "Student": 704,
    "Teacher": 45
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8287",
    "Name": "SEKOLAH KEBANGSAAN SUBANG BESTARI DUA",
    "Student": 1691,
    "Teacher": 96
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8288",
    "Name": "SEKOLAH KEBANGSAAN PUNCAK PERDANA",
    "Student": 904,
    "Teacher": 56
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBA8289",
    "Name": "SEKOLAH KEBANGSAAN PUTRA HEIGHTS 2",
    "Student": 1199,
    "Teacher": 69
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9201",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RAWANG",
    "Student": 632,
    "Teacher": 44
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9202",
    "Name": "SEKOLAH KEBANGSAAN BKT BANGKONG",
    "Student": 144,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9203",
    "Name": "SEKOLAH KEBANGSAAN SG PELEK",
    "Student": 269,
    "Teacher": 30
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9204",
    "Name": "SEKOLAH KEBANGSAAN SALAK",
    "Student": 1220,
    "Teacher": 84
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9205",
    "Name": "SEKOLAH KEBANGSAAN KG BAHARU LANJUT",
    "Student": 1023,
    "Teacher": 60
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBA9207",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BUSUT ASLI",
    "Student": 47,
    "Teacher": 12
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9208",
    "Name": "SEKOLAH KEBANGSAAN JENDERAM",
    "Student": 298,
    "Teacher": 28
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9209",
    "Name": "SEKOLAH KEBANGSAAN JENDERAM HILIR",
    "Student": 707,
    "Teacher": 44
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9210",
    "Name": "SEKOLAH KEBANGSAAN DENGKIL",
    "Student": 1430,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9211",
    "Name": "SEKOLAH KEBANGSAAN SG MERAB LUAR",
    "Student": 1005,
    "Teacher": 64
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9212",
    "Name": "SEKOLAH KEBANGSAAN DESA PUTRA",
    "Student": 589,
    "Teacher": 41
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9213",
    "Name": "SEKOLAH KEBANGSAAN DATO' ABU BAKAR BAGINDA",
    "Student": 949,
    "Teacher": 63
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9214",
    "Name": "SEKOLAH KEBANGSAAN PULAU MERANTI",
    "Student": 766,
    "Teacher": 53
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9215",
    "Name": "SEKOLAH KEBANGSAAN SG MELUT (ASLI)",
    "Student": 77,
    "Teacher": 11
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9216",
    "Name": "SEKOLAH KEBANGSAAN BKT TAMPOI (ASLI)",
    "Student": 120,
    "Teacher": 17
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9217",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU SALAK TINGGI",
    "Student": 1210,
    "Teacher": 85
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9220",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SEROJA",
    "Student": 1607,
    "Teacher": 91
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9221",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PANTAI SEPANG PUTRA",
    "Student": 525,
    "Teacher": 50
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9224",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PUTRA PERDANA",
    "Student": 2195,
    "Teacher": 138
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9225",
    "Name": "SEKOLAH KEBANGSAAN KOTA WARISAN",
    "Student": 1922,
    "Teacher": 118
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9226",
    "Name": "SEKOLAH KEBANGSAAN CYBERJAYA",
    "Student": 2423,
    "Teacher": 122
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9227",
    "Name": "SEKOLAH KEBANGSAAN KLIA",
    "Student": 1345,
    "Teacher": 78
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9228",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PUTRA PERDANA 2",
    "Student": 2159,
    "Teacher": 111
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBA9229",
    "Name": "SEKOLAH KEBANGSAAN DESA PINGGIRAN PUTRA",
    "Student": 1028,
    "Teacher": 72
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0024",
    "Name": "SEKOLAH KEBANGSAAN METHODIST ACS",
    "Student": 667,
    "Teacher": 48
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0025",
    "Name": "SEKOLAH KEBANGSAAN (1) JALAN BATU TIGA",
    "Student": 380,
    "Teacher": 28
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0027",
    "Name": "SEKOLAH KEBANGSAAN CONVENT (1)",
    "Student": 376,
    "Teacher": 30
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0028",
    "Name": "SEKOLAH KEBANGSAAN CONVENT (2)",
    "Student": 341,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0029",
    "Name": "SEKOLAH KEBANGSAAN LA SALLE",
    "Student": 408,
    "Teacher": 28
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0030",
    "Name": "SEKOLAH KEBANGSAAN (1) JALAN MERU",
    "Student": 218,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0031",
    "Name": "SEKOLAH KEBANGSAAN (2) JALAN MERU",
    "Student": 121,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0032",
    "Name": "SEKOLAH KEBANGSAAN METHODIST",
    "Student": 891,
    "Teacher": 56
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0033",
    "Name": "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST",
    "Student": 842,
    "Teacher": 52
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0034",
    "Name": "SEKOLAH KEBANGSAAN TENGKU BENDAHARA AZMAN (1)",
    "Student": 291,
    "Teacher": 38
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0035",
    "Name": "SEKOLAH KEBANGSAAN TENGKU BENDAHARA AZMAN (2)",
    "Student": 189,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0036",
    "Name": "SEKOLAH KEBANGSAAN PEREMPUAN BUKIT KUDA",
    "Student": 280,
    "Teacher": 42
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0037",
    "Name": "SEKOLAH KEBANGSAAN (1) SIMPANG LIMA",
    "Student": 358,
    "Teacher": 31
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0038",
    "Name": "SEKOLAH KEBANGSAAN (2) SIMPANG LIMA",
    "Student": 215,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBB0039",
    "Name": "SEKOLAH KEBANGSAAN ST ANNE'S CONVENT",
    "Student": 421,
    "Teacher": 32
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBB1031",
    "Name": "SEKOLAH KEBANGSAAN METHODIST",
    "Student": 324,
    "Teacher": 30
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBB1032",
    "Name": "SEKOLAH KEBANGSAAN SRI LANGAT",
    "Student": 1113,
    "Teacher": 88
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBB3030",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ABDUL AZIZ",
    "Student": 195,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBB3033",
    "Name": "SEKOLAH KEBANGSAAN BATU 9 JALAN BOMBA",
    "Student": 826,
    "Teacher": 65
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBB4028",
    "Name": "SEKOLAH KEBANGSAAN CONVENT",
    "Student": 873,
    "Teacher": 51
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBB4029",
    "Name": "SEKOLAH KEBANGSAAN JALAN BUKIT SATU",
    "Student": 222,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBB4030",
    "Name": "SEKOLAH KEBANGSAAN (2) JALAN BUKIT",
    "Student": 157,
    "Teacher": 20
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBB4031",
    "Name": "SEKOLAH KEBANGSAAN JALAN SEMENYIH '1'",
    "Student": 280,
    "Teacher": 31
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBB4032",
    "Name": "SEKOLAH KEBANGSAAN JALAN SEMENYIH '2'",
    "Student": 374,
    "Teacher": 40
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBB4033",
    "Name": "SEKOLAH KEBANGSAAN SEMENYIH",
    "Student": 1955,
    "Teacher": 105
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBB5021",
    "Name": "SEKOLAH KEBANGSAAN KUALA KUBU BARU SATU",
    "Student": 343,
    "Teacher": 31
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBB5023",
    "Name": "SEKOLAH KEBANGSAAN KUALA KUBU BHARU DUA",
    "Student": 284,
    "Teacher": 28
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBB6028",
    "Name": "SEKOLAH KEBANGSAAN KHIR JOHARI",
    "Student": 231,
    "Teacher": 31
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBB6029",
    "Name": "SEKOLAH KEBANGSAAN SERI UTAMA",
    "Student": 458,
    "Teacher": 49
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBB6030",
    "Name": "SEKOLAH KEBANGSAAN SERI SEKINCHAN",
    "Student": 331,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBB7301",
    "Name": "SEKOLAH KEBANGSAAN (1) GOMBAK",
    "Student": 815,
    "Teacher": 52
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBB7302",
    "Name": "SEKOLAH KEBANGSAAN (2) GOMBAK",
    "Student": 612,
    "Teacher": 41
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBB7303",
    "Name": "SEKOLAH KEBANGSAAN (1) KUALA AMPANG",
    "Student": 380,
    "Teacher": 28
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBB7304",
    "Name": "SEKOLAH KEBANGSAAN (2) KUALA AMPANG",
    "Student": 194,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBB7305",
    "Name": "SEKOLAH KEBANGSAAN (1) SELAYANG BARU",
    "Student": 1278,
    "Teacher": 78
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBB7306",
    "Name": "SEKOLAH KEBANGSAAN SINARAN BUDI",
    "Student": 522,
    "Teacher": 43
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBB7307",
    "Name": "SEKOLAH KEBANGSAAN BATU ARANG",
    "Student": 505,
    "Teacher": 33
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8301",
    "Name": "SEKOLAH KEBANGSAAN ASSUNTA SATU",
    "Student": 592,
    "Teacher": 40
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8302",
    "Name": "SEKOLAH KEBANGSAAN ASSUNTA (2)",
    "Student": 549,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8303",
    "Name": "SEKOLAH KEBANGSAAN (L) BUKIT BINTANG (1)",
    "Student": 156,
    "Teacher": 17
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8305",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG TUNKU",
    "Student": 1220,
    "Teacher": 78
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8306",
    "Name": "SEKOLAH KEBANGSAAN LA SALLE PJ",
    "Student": 462,
    "Teacher": 38
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8307",
    "Name": "SEKOLAH KEBANGSAAN METHODIST PJ",
    "Student": 401,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8308",
    "Name": "SEKOLAH KEBANGSAAN (1) PETALING JAYA",
    "Student": 446,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8309",
    "Name": "SEKOLAH KEBANGSAAN (2) PETALING JAYA",
    "Student": 217,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8310",
    "Name": "SEKOLAH KEBANGSAAN JALAN SELANGOR (SATU)",
    "Student": 161,
    "Teacher": 17
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8311",
    "Name": "SEKOLAH KEBANGSAAN (2) JALAN SELANGOR",
    "Student": 112,
    "Teacher": 15
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8312",
    "Name": "SEKOLAH KEBANGSAAN SATU SULTAN ALAM SHAH",
    "Student": 416,
    "Teacher": 38
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8313",
    "Name": "SEKOLAH KEBANGSAAN (2) SULTAN ALAM SHAH",
    "Student": 215,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8314",
    "Name": "SEKOLAH KEBANGSAAN SRI PETALING",
    "Student": 1000,
    "Teacher": 61
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBB8315",
    "Name": "SEKOLAH KEBANGSAAN SHAH ALAM",
    "Student": 749,
    "Teacher": 56
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBB8316",
    "Name": "SEKOLAH KEBANGSAAN SUNGEI WAY",
    "Student": 399,
    "Teacher": 35
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBB9301",
    "Name": "SEKOLAH KEBANGSAAN METHODIST",
    "Student": 269,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEREMPUAN",
    "Student": 1453,
    "Teacher": 68
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUEN MIN",
    "Student": 621,
    "Teacher": 36
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA",
    "Student": 2182,
    "Teacher": 100
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HIN HUA",
    "Student": 2532,
    "Teacher": 104
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA LIEN",
    "Student": 142,
    "Teacher": 14
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KENG CHEE",
    "Student": 75,
    "Teacher": 12
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHE BENG",
    "Student": 1747,
    "Teacher": 75
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KONG HOE",
    "Student": 1156,
    "Teacher": 52
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LEE MIN",
    "Student": 110,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PANDAMARAN 'A'",
    "Student": 919,
    "Teacher": 48
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PANDAMARAN 'B'",
    "Student": 1887,
    "Teacher": 93
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PIN HWA (1)",
    "Student": 2664,
    "Teacher": 104
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PUI YING",
    "Student": 1296,
    "Teacher": 65
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN BIN",
    "Student": 45,
    "Teacher": 11
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SOO JIN",
    "Student": 689,
    "Teacher": 45
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TIONG HUA KOK BIN",
    "Student": 474,
    "Teacher": 33
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TSHING NIAN",
    "Student": 1182,
    "Teacher": 55
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WU TECK",
    "Student": 1405,
    "Teacher": 62
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YING WAH",
    "Student": 277,
    "Teacher": 23
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PIN HWA (2)",
    "Student": 2133,
    "Teacher": 100
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBC0060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAMAN RASHNA",
    "Student": 1330,
    "Teacher": 71
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBC1035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG HUA",
    "Student": 895,
    "Teacher": 51
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBC1036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JENJAROM",
    "Student": 1572,
    "Teacher": 78
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBC1037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAH WAH",
    "Student": 542,
    "Teacher": 30
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBC1038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PENG MING",
    "Student": 318,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBC1040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG MORIB",
    "Student": 132,
    "Teacher": 17
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBC1041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TANJONG SEPAT",
    "Student": 536,
    "Teacher": 39
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBC1043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TIONG NAM",
    "Student": 173,
    "Teacher": 14
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBC1044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT FRASER",
    "Student": 237,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AIK THEE",
    "Student": 314,
    "Teacher": 24
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BAGAN PASIR",
    "Student": 69,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG WAH",
    "Student": 239,
    "Teacher": 21
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) IJOK",
    "Student": 346,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI TEE",
    "Student": 218,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LIAT CHOON",
    "Student": 77,
    "Teacher": 24
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MING TEE",
    "Student": 107,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PUI CHI",
    "Student": 69,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PUI TEH",
    "Student": 42,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TANJONG KARANG",
    "Student": 274,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YIT KHWAN",
    "Student": 79,
    "Teacher": 15
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBC3047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHIH",
    "Student": 107,
    "Teacher": 15
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BALAKONG",
    "Student": 788,
    "Teacher": 51
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BATU 9 CHERAS",
    "Student": 1361,
    "Teacher": 76
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BATU 11",
    "Student": 2428,
    "Teacher": 120
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHOON HWA",
    "Student": 54,
    "Teacher": 9
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU SEMENYIH",
    "Student": 731,
    "Teacher": 45
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MING",
    "Student": 1398,
    "Teacher": 68
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI CHUA",
    "Student": 1475,
    "Teacher": 74
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TON FAH",
    "Student": 189,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BATU 14",
    "Student": 172,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE MIN",
    "Student": 204,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU HUA",
    "Student": 2717,
    "Teacher": 107
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU AMPANG",
    "Student": 1140,
    "Teacher": 61
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ON PONG",
    "Student": 1275,
    "Teacher": 73
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CONNAUGHT (2)",
    "Student": 2529,
    "Teacher": 110
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ON PONG 2",
    "Student": 1923,
    "Teacher": 99
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BANDAR SUNGAI LONG",
    "Student": 2442,
    "Teacher": 102
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBC4051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BANDAR KAJANG 2",
    "Student": 180,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBC5023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BATANG KALI",
    "Student": 425,
    "Teacher": 32
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBC5024",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BKT TANGGA",
    "Student": 343,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBC5027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG CHEE",
    "Student": 132,
    "Teacher": 15
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBC5029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KALUMPANG",
    "Student": 110,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBC5030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG GURNEY",
    "Student": 195,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBC5031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KERLING",
    "Student": 71,
    "Teacher": 13
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBC5032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHING MING",
    "Student": 261,
    "Teacher": 24
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBC5035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) RASA",
    "Student": 207,
    "Teacher": 20
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBC5037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SERENDAH",
    "Student": 283,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBC6030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEONG HUA",
    "Student": 50,
    "Teacher": 11
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBC6031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HAI PING",
    "Student": 72,
    "Teacher": 11
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBC6032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LUM HUA",
    "Student": 210,
    "Teacher": 21
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBC6033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MOON TECK",
    "Student": 22,
    "Teacher": 8
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBC6034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHOOI TEE",
    "Student": 71,
    "Teacher": 11
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBC6035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POAY CHNEH",
    "Student": 43,
    "Teacher": 11
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBC6036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN",
    "Student": 670,
    "Teacher": 38
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBC6037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHOOI MIN",
    "Student": 8,
    "Teacher": 7
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBC6038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE KUAN",
    "Student": 797,
    "Teacher": 47
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBC6039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KIAN SIT",
    "Student": 66,
    "Teacher": 11
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBC7401",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHAP KHUAN",
    "Student": 204,
    "Teacher": 21
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBC7402",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHEOW BIN",
    "Student": 1616,
    "Teacher": 85
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBC7403",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUANG",
    "Student": 633,
    "Teacher": 38
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBC7404",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUNDANG",
    "Student": 560,
    "Teacher": 36
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBC7405",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAN YUK",
    "Student": 1550,
    "Teacher": 90
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBC7406",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SELAYANG BARU",
    "Student": 1248,
    "Teacher": 73
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBC7407",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) DESA JAYA",
    "Student": 2210,
    "Teacher": 114
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBC7408",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KOTA EMERALD",
    "Student": 1160,
    "Teacher": 58
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBC8401",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEN MOH",
    "Student": 844,
    "Teacher": 51
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBC8402",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) DAMANSARA",
    "Student": 1745,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8403",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG MAN",
    "Student": 679,
    "Teacher": 43
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8404",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HAN MING",
    "Student": 3285,
    "Teacher": 135
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8405",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SERDANG BARU (1)",
    "Student": 1937,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8406",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SERDANG BARU (2)",
    "Student": 2610,
    "Teacher": 123
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8407",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUBANG",
    "Student": 1926,
    "Teacher": 89
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBC8408",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI WAY",
    "Student": 528,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBC8409",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI BULOH",
    "Student": 1007,
    "Teacher": 61
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8410",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YAK CHEE",
    "Student": 2944,
    "Teacher": 137
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBC8411",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHAI",
    "Student": 3073,
    "Teacher": 142
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBC8412",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHYUN",
    "Student": 632,
    "Teacher": 42
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBC8413",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PUAY CHAI",
    "Student": 1774,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8414",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LICK HUNG",
    "Student": 1971,
    "Teacher": 97
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBC8415",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PUAY CHAI 2",
    "Student": 2407,
    "Teacher": 104
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8416",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TUN TAN CHENG LOCK",
    "Student": 820,
    "Teacher": 42
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8417",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE WEN",
    "Student": 1632,
    "Teacher": 81
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBC8418",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) DESA JAYA 2",
    "Student": 2045,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8419",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SHIN CHENG (HARCROFT)",
    "Student": 2028,
    "Teacher": 87
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBC8420",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA DAMANSARA",
    "Student": 357,
    "Teacher": 28
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8421",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TUN TAN SIEW SIN",
    "Student": 1277,
    "Teacher": 63
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8422",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHENG CHEE",
    "Student": 1469,
    "Teacher": 68
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBC8423",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT SERDANG SERI KEMBANGAN",
    "Student": 1162,
    "Teacher": 52
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBC9401",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIO CHIAO",
    "Student": 102,
    "Teacher": 14
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBC9402",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) DENGKIL",
    "Student": 624,
    "Teacher": 36
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBC9403",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SEPANG",
    "Student": 130,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBC9404",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MING",
    "Student": 2336,
    "Teacher": 93
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBC9405",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TCHE MIN",
    "Student": 209,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBC9406",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WAH LIAN",
    "Student": 356,
    "Teacher": 30
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBC9407",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) UNION",
    "Student": 647,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATU AMPAT",
    "Student": 325,
    "Teacher": 33
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BRAFFERTON",
    "Student": 36,
    "Teacher": 9
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0062",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT RAJAH",
    "Student": 145,
    "Teacher": 12
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0072",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG EMERALD",
    "Student": 582,
    "Teacher": 36
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0074",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG HIGHLANDS",
    "Student": 1083,
    "Teacher": 57
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0075",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JALAN ACOB",
    "Student": 119,
    "Teacher": 14
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0076",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN TEPI SUNGAI",
    "Student": 98,
    "Teacher": 14
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0077",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PERSIARAN RAJA MUDA MUSA",
    "Student": 491,
    "Teacher": 36
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0078",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN MERU",
    "Student": 406,
    "Teacher": 34
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0079",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) METHODIST",
    "Student": 641,
    "Teacher": 43
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0084",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SIMPANG LIMA",
    "Student": 1706,
    "Teacher": 90
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0092",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG VALLAMBROSA",
    "Student": 435,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD0093",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN SENTOSA",
    "Student": 694,
    "Teacher": 38
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SG MANGGIS",
    "Student": 194,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG GADONG",
    "Student": 65,
    "Teacher": 14
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG JUGRA",
    "Student": 140,
    "Teacher": 13
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JENJAROM",
    "Student": 255,
    "Teacher": 22
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SIMPANG MORIB",
    "Student": 133,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI SEDU",
    "Student": 37,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI BUAYA",
    "Student": 84,
    "Teacher": 12
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1062",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TELOK PANGLIMA GARANG",
    "Student": 436,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PUSAT TELOK DATOK",
    "Student": 484,
    "Teacher": 38
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TUMBUK",
    "Student": 80,
    "Teacher": 12
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PULAU CAREY SELATAN",
    "Student": 26,
    "Teacher": 9
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1067",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PULAU CAREY BARAT",
    "Student": 93,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BBD1068",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PULAU CAREY TIMUR",
    "Student": 34,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BESTARI JAYA",
    "Student": 477,
    "Teacher": 34
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BRAUNSTON",
    "Student": 78,
    "Teacher": 14
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BUKIT CHERAKA",
    "Student": 122,
    "Teacher": 15
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BKT IJOK",
    "Student": null,
    "Teacher": null
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG COALFIELDS",
    "Student": 152,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) GHANDIJI",
    "Student": 21,
    "Teacher": 9
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG HOPEFUL",
    "Student": 16,
    "Teacher": 7
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KG BARU",
    "Student": 20,
    "Teacher": 8
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI TERAP",
    "Student": 40,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KUALA SELANGOR",
    "Student": 56,
    "Teacher": 11
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3064",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG RAJA MUSA",
    "Student": 36,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BUKIT ROTAN BARU",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG RIVERSIDE",
    "Student": 50,
    "Teacher": 11
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3068",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SELANGOR RIVER",
    "Student": 89,
    "Teacher": 12
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3069",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG BULOH",
    "Student": 24,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3071",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG RAMBAI",
    "Student": 36,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3072",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TUAN MEE",
    "Student": 70,
    "Teacher": 11
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD3073",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) VAGEESAR",
    "Student": 338,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBD4051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANGI",
    "Student": 77,
    "Teacher": 15
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBD4053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG DOMINION",
    "Student": 18,
    "Teacher": 8
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBD4055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAJANG",
    "Student": 1039,
    "Teacher": 65
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBD4057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG RINCHING",
    "Student": 386,
    "Teacher": 36
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBD4060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SEMENYIH",
    "Student": 393,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBD4063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG WEST COUNTRY 'TIMUR'",
    "Student": 358,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBD4064",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) AMPANG",
    "Student": 398,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BBD4065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR MAHKOTA CHERAS",
    "Student": 460,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBD5041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATANG KALI",
    "Student": 261,
    "Teacher": 24
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBD5043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG CHANGKAT ASA",
    "Student": 33,
    "Teacher": 8
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBD5044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG ESCOT",
    "Student": 37,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBD5045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUALA KUBU BHARU",
    "Student": 207,
    "Teacher": 20
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBD5047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KERLING",
    "Student": 73,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBD5048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KALUMPANG",
    "Student": 71,
    "Teacher": 12
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBD5051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG LIMA BELAS",
    "Student": 6,
    "Teacher": 7
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD5052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MINYAK",
    "Student": null,
    "Teacher": null
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD5055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG NIGEL GARDNER",
    "Student": 42,
    "Teacher": 12
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBD5058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG CHOH",
    "Student": 417,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BBD5059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BUKIT BERUNTUNG",
    "Student": 305,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD5061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MARY",
    "Student": 18,
    "Teacher": 7
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BBD5062",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG TINGGI",
    "Student": 39,
    "Teacher": 14
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBD6039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI BERNAM",
    "Student": 18,
    "Teacher": 8
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BBD6040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SABAK BERNAM",
    "Student": 101,
    "Teacher": 16
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBD7451",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BATU CAVES",
    "Student": 949,
    "Teacher": 70
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBD7452",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BATU ARANG",
    "Student": 266,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBD7453",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUANG",
    "Student": 177,
    "Teacher": 15
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBD7454",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BUKIT DARAH",
    "Student": 137,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBD7455",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) RAWANG",
    "Student": 1062,
    "Teacher": 61
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBD7456",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN MELAWATI",
    "Student": 223,
    "Teacher": 18
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8451",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) CASTLEFIELD",
    "Student": 685,
    "Teacher": 41
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBD8452",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG EFFINGHAM",
    "Student": 283,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8453",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) F E S SERDANG",
    "Student": 540,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8454",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PUCHONG",
    "Student": 722,
    "Teacher": 47
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8455",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KINRARA",
    "Student": 592,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBD8456",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) RRI SUNGAI BULOH",
    "Student": 410,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BBD8457",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SARASWATHY",
    "Student": 406,
    "Teacher": 35
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBD8458",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) VIVEKANANDA",
    "Student": 573,
    "Teacher": 44
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8459",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) HICOM",
    "Student": 135,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8461",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG EBOR",
    "Student": 112,
    "Teacher": 12
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8462",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG GLENMARIE",
    "Student": 84,
    "Teacher": 13
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8463",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MIDLANDS",
    "Student": 159,
    "Teacher": 17
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BBD8464",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NORTH HUMMOCK",
    "Student": 480,
    "Teacher": 28
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8466",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG RASAK",
    "Student": 110,
    "Teacher": 13
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BBD8467",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SEAPORT",
    "Student": 309,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8468",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SG RENGGAM",
    "Student": 721,
    "Teacher": 49
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8469",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SEAFIELD",
    "Student": 94,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BBD8470",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN",
    "Student": 600,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBD9452",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG AMPAR TENANG",
    "Student": 48,
    "Teacher": 10
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBD9453",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUTE",
    "Student": 25,
    "Teacher": 7
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBD9454",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR BARU SALAK TINGGI",
    "Student": 202,
    "Teacher": 23
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBD9455",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) DENGKIL",
    "Student": 238,
    "Teacher": 25
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBD9457",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN PERMATA",
    "Student": 207,
    "Teacher": 20
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBD9458",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SEPANG",
    "Student": 187,
    "Teacher": 19
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBD9460",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TELUK MERBAU",
    "Student": 293,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BBD9461",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG WEST COUNTRY 'BARAT'",
    "Student": 121,
    "Teacher": 12
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0091",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA MAHADI",
    "Student": 2160,
    "Teacher": 121
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0092",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA LUMU",
    "Student": 945,
    "Teacher": 73
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU AMPUAN RAHIMAH",
    "Student": 1304,
    "Teacher": 91
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0094",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU AMPUAN JEMAAH",
    "Student": 2030,
    "Teacher": 130
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0095",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU IDRIS SHAH",
    "Student": 1924,
    "Teacher": 125
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0096",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL SAMAD",
    "Student": 884,
    "Teacher": 70
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0097",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PULAU INDAH",
    "Student": 1531,
    "Teacher": 96
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0099",
    "Name": "KOLEJ TINGKATAN ENAM SRI ISTANA",
    "Student": 495,
    "Teacher": 116
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RANTAU PANJANG",
    "Student": 2769,
    "Teacher": 164
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) KAPAR",
    "Student": 1078,
    "Teacher": 81
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENDAMARAN JAYA",
    "Student": 1585,
    "Teacher": 100
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELOK GADONG",
    "Student": 2336,
    "Teacher": 156
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI ANDALAS",
    "Student": 1913,
    "Teacher": 124
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0105",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN KEBUN",
    "Student": 3423,
    "Teacher": 173
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0106",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG JAWA",
    "Student": 2004,
    "Teacher": 136
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0107",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN KLANG UTAMA",
    "Student": 1518,
    "Teacher": 115
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0108",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOTA KEMUNING",
    "Student": 3583,
    "Teacher": 176
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0109",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SULTAN SULEIMAN",
    "Student": 937,
    "Teacher": 69
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0110",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI KAPAR INDAH",
    "Student": 2727,
    "Teacher": 177
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0111",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SHAHBANDARAYA",
    "Student": 1729,
    "Teacher": 110
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0112",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU UNJUR",
    "Student": 1807,
    "Teacher": 124
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0113",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT TINGGI KLANG",
    "Student": 1415,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEA0114",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT KAPAR",
    "Student": 1269,
    "Teacher": 74
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEA1064",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JENJAROM",
    "Student": 1824,
    "Teacher": 123
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEA1065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JUGRA",
    "Student": 1671,
    "Teacher": 128
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEA1066",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELOK DATOK",
    "Student": 524,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEA1068",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU LAUT",
    "Student": 591,
    "Teacher": 52
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEA1069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELOK PANGLIMA GARANG",
    "Student": 2885,
    "Teacher": 186
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEA1070",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT CHANGGANG",
    "Student": 2101,
    "Teacher": 136
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEA1071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANTING",
    "Student": 1251,
    "Teacher": 105
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEA1072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIJANGKANG JAYA",
    "Student": 2289,
    "Teacher": 149
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEA1073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BANTING",
    "Student": 1914,
    "Teacher": 116
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEA1074",
    "Name": "SEKOLAH MENENGAH SAINS BANTING",
    "Student": 881,
    "Teacher": 69
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEA1075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR SAUJANA PUTRA",
    "Student": 1022,
    "Teacher": 49
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI TANJUNG",
    "Student": 1279,
    "Teacher": 95
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO HARUN",
    "Student": 1318,
    "Teacher": 85
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3077",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA MUDA MUSA",
    "Student": 1416,
    "Teacher": 112
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3078",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JERAM",
    "Student": 1266,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SG. BURONG",
    "Student": 514,
    "Teacher": 44
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3080",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TIRAM JAYA",
    "Student": 556,
    "Teacher": 50
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3081",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RANTAU PANJANG",
    "Student": 318,
    "Teacher": 29
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3082",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN PERMATANG",
    "Student": 932,
    "Teacher": 79
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3083",
    "Name": "SEKOLAH MENENGAH SAINS KUALA SELANGOR",
    "Student": 742,
    "Teacher": 64
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3084",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SAUJANA UTAMA",
    "Student": 3675,
    "Teacher": 226
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3085",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUNCAK ALAM",
    "Student": 2155,
    "Teacher": 145
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3086",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA SELANGOR",
    "Student": 1191,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3087",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT KUCHING TENGAH",
    "Student": 1375,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEA3088",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUNCAK ALAM 3",
    "Student": 1495,
    "Teacher": 93
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4601",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN TASIK",
    "Student": 1497,
    "Teacher": 136
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4603",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU BANGI",
    "Student": 2039,
    "Teacher": 114
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4604",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL AZIZ SHAH",
    "Student": 1895,
    "Teacher": 130
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4605",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KHIR JOHARI",
    "Student": 917,
    "Teacher": 66
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4606",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ABDUL JALIL",
    "Student": 2136,
    "Teacher": 136
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4608",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ENGKU HUSAIN",
    "Student": 3102,
    "Teacher": 166
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4609",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN KOSAS",
    "Student": 1715,
    "Teacher": 120
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4610",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SERAYA",
    "Student": 1771,
    "Teacher": 111
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4611",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERIMBUN",
    "Student": 1792,
    "Teacher": 129
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4612",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TASEK PERMAI",
    "Student": 564,
    "Teacher": 48
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4613",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN REKO",
    "Student": 1660,
    "Teacher": 143
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4614",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN TIGA",
    "Student": 1877,
    "Teacher": 128
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4615",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANDAN JAYA",
    "Student": 1462,
    "Teacher": 107
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4616",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANDAN INDAH",
    "Student": 1736,
    "Teacher": 114
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4617",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHERAS PERDANA",
    "Student": 1409,
    "Teacher": 103
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4618",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' AHMAD RAZALI",
    "Student": 856,
    "Teacher": 57
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA4619",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT INDAH",
    "Student": 1236,
    "Teacher": 81
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4620",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHERAS JAYA",
    "Student": 2415,
    "Teacher": 144
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4621",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SAUJANA IMPIAN",
    "Student": 2797,
    "Teacher": 155
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4622",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU AMPANG",
    "Student": 1580,
    "Teacher": 108
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4623",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SUNGAI LONG",
    "Student": 2714,
    "Teacher": 159
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4624",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN EMPAT",
    "Student": 1482,
    "Teacher": 97
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4625",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAJANG UTAMA",
    "Student": 1375,
    "Teacher": 112
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4626",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN JASMIN 2",
    "Student": 2476,
    "Teacher": 145
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4627",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DUSUN NANDING",
    "Student": 1606,
    "Teacher": 117
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4628",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR TASIK KESUMA",
    "Student": 2117,
    "Teacher": 126
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4629",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR DAMAI PERDANA",
    "Student": 1766,
    "Teacher": 115
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4630",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR TUN HUSSEIN ONN 2",
    "Student": 2150,
    "Teacher": 160
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4631",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANDAN MEWAH",
    "Student": 1432,
    "Teacher": 114
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4632",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR RINCHING",
    "Student": 2549,
    "Teacher": 154
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4633",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR SERI PUTRA",
    "Student": 3314,
    "Teacher": 177
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4634",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DESA SERDANG",
    "Student": 1138,
    "Teacher": 76
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEA4635",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RAMAL",
    "Student": 1333,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA KUBU BHARU",
    "Student": 767,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPONG SOEHARTO",
    "Student": 313,
    "Teacher": 43
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5063",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI CHOH",
    "Student": 767,
    "Teacher": 81
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5064",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KALUMPANG",
    "Student": 477,
    "Teacher": 51
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GEDANGSA",
    "Student": 189,
    "Teacher": 24
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5066",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT SENTOSA",
    "Student": 2285,
    "Teacher": 133
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5067",
    "Name": "SEKOLAH MENENGAH SAINS HULU SELANGOR",
    "Student": 762,
    "Teacher": 63
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5068",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AMPANG PECAH",
    "Student": 901,
    "Teacher": 72
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU BATANG KALI",
    "Student": 1770,
    "Teacher": 113
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5070",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR SUNGAI BUAYA",
    "Student": 1038,
    "Teacher": 71
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN BUNGA RAYA (1)",
    "Student": 1916,
    "Teacher": 131
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERENDAH",
    "Student": 1653,
    "Teacher": 109
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEA5073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT SENTOSA 2",
    "Student": 1794,
    "Teacher": 114
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BEA6041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN UNGKU AZIZ",
    "Student": 1492,
    "Teacher": 127
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BEA6042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUNSHI ABDULLAH",
    "Student": 718,
    "Teacher": 78
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BEA6043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI BESAR",
    "Student": 1395,
    "Teacher": 129
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BEA6044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAGAN TERAP",
    "Student": 638,
    "Teacher": 73
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BEA6601",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO MUSTAFFA",
    "Student": 799,
    "Teacher": 71
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7601",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GOMBAK SETIA",
    "Student": 2286,
    "Teacher": 163
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7602",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SELAYANG",
    "Student": 1160,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7603",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LEMBAH KERAMAT",
    "Student": 1228,
    "Teacher": 87
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7604",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN EHSAN",
    "Student": 1656,
    "Teacher": 139
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7605",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAWANG",
    "Student": 1362,
    "Teacher": 104
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7606",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN KERAMAT",
    "Student": 665,
    "Teacher": 58
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7607",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI GOMBAK",
    "Student": 1962,
    "Teacher": 121
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7608",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HILLCREST",
    "Student": 1796,
    "Teacher": 112
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7609",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN IDEAL HEIGHTS",
    "Student": 1535,
    "Teacher": 104
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7610",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN MELAWATI",
    "Student": 2003,
    "Teacher": 134
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7612",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI SELAYANG",
    "Student": 1482,
    "Teacher": 109
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7613",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI KERTAS",
    "Student": 1247,
    "Teacher": 84
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7614",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI KERAMAT",
    "Student": 822,
    "Teacher": 66
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7615",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DARUL EHSAN",
    "Student": 2099,
    "Teacher": 143
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7616",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PUSU",
    "Student": 1934,
    "Teacher": 125
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7617",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA",
    "Student": 1693,
    "Teacher": 111
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7618",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR TASIK PUTERI",
    "Student": 2496,
    "Teacher": 165
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7619",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT RAHMAN PUTRA",
    "Student": 1969,
    "Teacher": 136
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7620",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI KUNDANG",
    "Student": 1450,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7621",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA 2",
    "Student": 2317,
    "Teacher": 152
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7622",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIERRAMAS",
    "Student": 1145,
    "Teacher": 89
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA7623",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN PERAK",
    "Student": 1292,
    "Teacher": 79
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8601",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUCHONG",
    "Student": 1737,
    "Teacher": 108
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8602",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI PERMATA",
    "Student": 1126,
    "Teacher": 77
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8603",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN SALAHUDDIN ABDUL AZIZ SHAH",
    "Student": 1001,
    "Teacher": 70
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8604",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUBANG JAYA",
    "Student": 884,
    "Teacher": 66
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA8605",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT GADING",
    "Student": 1731,
    "Teacher": 133
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8606",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI SERDANG",
    "Student": 1685,
    "Teacher": 135
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8607",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN DATO' HARUN",
    "Student": 1437,
    "Teacher": 111
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8608",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DAMANSARA JAYA",
    "Student": 1096,
    "Teacher": 64
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8609",
    "Name": "KOLEJ TINGKATAN ENAM PUCHONG",
    "Student": 217,
    "Teacher": 49
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8610",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 16",
    "Student": 1481,
    "Teacher": 106
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8611",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DAMANSARA UTAMA",
    "Student": 985,
    "Teacher": 60
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8612",
    "Name": "SEKOLAH MENENGAH PENDIDIKAN KHAS VOKASIONAL SHAH ALAM",
    "Student": 201,
    "Teacher": 93
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8613",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN MEDAN",
    "Student": 1254,
    "Teacher": 96
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8614",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUBANG UTAMA",
    "Student": 1031,
    "Teacher": 69
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8615",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KELANA JAYA",
    "Student": 683,
    "Teacher": 68
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8616",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN SEBELAS",
    "Student": 304,
    "Teacher": 47
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8617",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 18",
    "Student": 1104,
    "Teacher": 77
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8618",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEAFIELD",
    "Student": 1222,
    "Teacher": 94
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8619",
    "Name": "KOLEJ TINGKATAN ENAM SHAH ALAM",
    "Student": 280,
    "Teacher": 76
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEA8620",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SUNGAI BULOH",
    "Student": 2273,
    "Teacher": 151
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8621",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 19",
    "Student": 2586,
    "Teacher": 176
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8622",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALAM MEGAH",
    "Student": 1167,
    "Teacher": 85
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8623",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SRI MUDA",
    "Student": 1980,
    "Teacher": 115
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8624",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI UTAMA",
    "Student": 277,
    "Teacher": 37
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8625",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SS 17",
    "Student": 555,
    "Teacher": 56
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8626",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR SUNWAY",
    "Student": 922,
    "Teacher": 71
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8627",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI INDAH",
    "Student": 985,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8628",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUCHONG PERDANA",
    "Student": 2180,
    "Teacher": 142
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8629",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 9",
    "Student": 1870,
    "Teacher": 129
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8630",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUBANG",
    "Student": 2858,
    "Teacher": 164
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8631",
    "Name": "KOLEJ TINGKATAN ENAM BANDAR UTAMA",
    "Student": 219,
    "Teacher": 56
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8632",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN USJ 4",
    "Student": 996,
    "Teacher": 82
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8633",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN USJ 13",
    "Student": 1752,
    "Teacher": 104
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8634",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN USJ 8",
    "Student": 1006,
    "Teacher": 78
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8635",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN USJ 12",
    "Student": 1452,
    "Teacher": 101
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8636",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUCHONG UTAMA (1)",
    "Student": 2821,
    "Teacher": 172
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8637",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TTDI JAYA",
    "Student": 1803,
    "Teacher": 130
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8638",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 10 KOTA DAMANSARA",
    "Student": 1570,
    "Teacher": 110
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8639",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 1 BANDAR KINRARA",
    "Student": 1002,
    "Teacher": 65
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8640",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TROPICANA",
    "Student": 1019,
    "Teacher": 66
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8641",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR SRI DAMANSARA 2",
    "Student": 1172,
    "Teacher": 80
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8642",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN USJ 23",
    "Student": 930,
    "Teacher": 68
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8643",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR UTAMA DAMANSARA 3",
    "Student": 1097,
    "Teacher": 66
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8644",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR UTAMA DAMANSARA (4)",
    "Student": 1228,
    "Teacher": 87
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8645",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 3 BANDAR KINRARA",
    "Student": 1599,
    "Teacher": 111
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8647",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 4 BANDAR KINRARA",
    "Student": 2080,
    "Teacher": 121
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8648",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 7",
    "Student": 2072,
    "Teacher": 136
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8649",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUSAT BANDAR PUCHONG 1",
    "Student": 2584,
    "Teacher": 152
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8650",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT JELUTONG",
    "Student": 1636,
    "Teacher": 106
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8652",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DAMANSARA DAMAI 1",
    "Student": 1840,
    "Teacher": 127
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8653",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALAM MEGAH 2",
    "Student": 1229,
    "Teacher": 77
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8654",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 24 (2)",
    "Student": 1725,
    "Teacher": 117
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8655",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 4 KOTA DAMANSARA",
    "Student": 969,
    "Teacher": 90
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8656",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUCHONG JAYA (A)",
    "Student": 931,
    "Teacher": 71
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8657",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUCHONG JAYA (B)",
    "Student": 1222,
    "Teacher": 72
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8658",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR UTAMA DAMANSARA (2)",
    "Student": 803,
    "Teacher": 61
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8659",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR SRI DAMANSARA 1",
    "Student": 1324,
    "Teacher": 103
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8660",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 27",
    "Student": 1210,
    "Teacher": 85
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8661",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LEMBAH SUBANG",
    "Student": 723,
    "Teacher": 50
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8662",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUBANG BESTARI",
    "Student": 2815,
    "Teacher": 165
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8663",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUCHONG PERMAI",
    "Student": 2084,
    "Teacher": 133
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8664",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESAMINIUM",
    "Student": 1733,
    "Teacher": 103
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8665",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SETIA ALAM",
    "Student": 1505,
    "Teacher": 99
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8666",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 8 KOTA DAMANSARA",
    "Student": 936,
    "Teacher": 74
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8668",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SHAH ALAM",
    "Student": 1337,
    "Teacher": 86
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEA8669",
    "Name": "KOLEJ TINGKATAN ENAM PETALING JAYA",
    "Student": 247,
    "Teacher": 66
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEA8670",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUNCAK JALIL",
    "Student": 1174,
    "Teacher": 76
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BEA9601",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RAWANG",
    "Student": 521,
    "Teacher": 48
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BEA9602",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI SEPANG",
    "Student": 2266,
    "Teacher": 131
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BEA9604",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SALAK TINGGI",
    "Student": 2330,
    "Teacher": 166
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BEA9607",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PUTERI",
    "Student": 887,
    "Teacher": 71
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BEA9609",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CYBERJAYA",
    "Student": 1501,
    "Teacher": 101
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BEA9610",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANTAI SEPANG PUTRA",
    "Student": 503,
    "Teacher": 51
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BEA9611",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRA PERDANA",
    "Student": 3233,
    "Teacher": 188
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BEA9612",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' ABU BAKAR BAGINDA",
    "Student": 1521,
    "Teacher": 115
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0099",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST (ACS)",
    "Student": 1119,
    "Teacher": 75
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA",
    "Student": 1660,
    "Teacher": 82
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT",
    "Student": 1056,
    "Teacher": 72
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI KLANG",
    "Student": 1221,
    "Teacher": 133
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KWANG HUA",
    "Student": 3185,
    "Teacher": 167
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LA SALLE",
    "Student": 651,
    "Teacher": 46
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0105",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) METHODIST",
    "Student": 1297,
    "Teacher": 87
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0106",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) BUKIT KUDA",
    "Student": 789,
    "Teacher": 66
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0108",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO HAMZAH",
    "Student": 857,
    "Teacher": 67
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0109",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) RAJA ZARINA",
    "Student": 1576,
    "Teacher": 109
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0110",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MERU",
    "Student": 3959,
    "Teacher": 256
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BEB0111",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PULAU KETAM",
    "Student": 281,
    "Teacher": 34
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEB1069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST",
    "Student": 1229,
    "Teacher": 70
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEB1070",
    "Name": "KOLEJ VOKASIONAL SULTAN ABDUL SAMAD",
    "Student": 592,
    "Teacher": 107
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEB1072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJUNG SEPAT",
    "Student": 743,
    "Teacher": 60
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEB1073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI MANGGIS",
    "Student": 843,
    "Teacher": 81
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BEB1074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PULAU CAREY",
    "Student": 286,
    "Teacher": 26
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEB3078",
    "Name": "KOLEJ TINGKATAN ENAM SULTAN ABDUL AZIZ",
    "Student": 268,
    "Teacher": 80
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEB3079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN SULAIMAN SHAH",
    "Student": 743,
    "Teacher": 65
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BEB3080",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI DESA",
    "Student": 745,
    "Teacher": 75
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEB4068",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT",
    "Student": 749,
    "Teacher": 60
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEB4069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI KAJANG",
    "Student": 1036,
    "Teacher": 116
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEB4070",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN YU HUA",
    "Student": 3082,
    "Teacher": 170
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BEB4071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN BUKIT",
    "Student": 1619,
    "Teacher": 109
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEB5065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SYED MASHOR",
    "Student": 1660,
    "Teacher": 105
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BEB5066",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' HJ KAMARUDDIN",
    "Student": 676,
    "Teacher": 60
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BEB6044",
    "Name": "KOLEJ TINGKATAN ENAM TUNKU ABDUL RAHMAN PUTRA",
    "Student": 356,
    "Teacher": 111
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BEB6046",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BEDENA",
    "Student": 747,
    "Teacher": 77
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BEB6047",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN YOKE KUAN",
    "Student": 1231,
    "Teacher": 88
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEB7651",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEPONG",
    "Student": 1995,
    "Teacher": 119
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEB7652",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HULU KELANG",
    "Student": 336,
    "Teacher": 50
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEB7653",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SELAYANG BHARU",
    "Student": 1823,
    "Teacher": 130
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEB7654",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI GARING",
    "Student": 1794,
    "Teacher": 143
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BEB7655",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUANKU ABDUL RAHMAN",
    "Student": 690,
    "Teacher": 54
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEB8651",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ASSUNTA",
    "Student": 1287,
    "Teacher": 95
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEB8652",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (L) BUKIT BINTANG",
    "Student": 402,
    "Teacher": 43
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEB8653",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KATHOLIK",
    "Student": 2863,
    "Teacher": 153
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEB8654",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LA SALLE PJ",
    "Student": 850,
    "Teacher": 72
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEB8655",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) SRI AMAN",
    "Student": 835,
    "Teacher": 62
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEB8656",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SEA",
    "Student": 1426,
    "Teacher": 92
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEB8657",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL SAMAD",
    "Student": 1283,
    "Teacher": 103
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING UTAMA",
    "Kod": "BEB8658",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) TMN PETALING",
    "Student": 458,
    "Teacher": 49
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BEB8659",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI KEMBANGAN",
    "Student": 3449,
    "Teacher": 178
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BEB9651",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PELEK",
    "Student": 1177,
    "Teacher": 97
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BEB9701",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DENGKIL",
    "Student": 1856,
    "Teacher": 119
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BFT0001",
    "Name": "SAMT SULTAN HISAMUDDIN",
    "Student": 894,
    "Teacher": 82
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BFT0002",
    "Name": "SAM HISAMUDDIN",
    "Student": 744,
    "Teacher": 58
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BFT0003",
    "Name": "SAM NURUL IMAN",
    "Student": 660,
    "Teacher": 48
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BFT1001",
    "Name": "SAM UNWANUS SAADAH",
    "Student": 664,
    "Teacher": 56
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA LANGAT",
    "Kod": "BFT1002",
    "Name": "SAMT TENGKU AMPUAN RAHIMAH",
    "Student": 989,
    "Teacher": 91
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BFT3001",
    "Name": "SAM JERAM",
    "Student": 804,
    "Teacher": 63
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BFT3002",
    "Name": "SAM TANJONG KARANG",
    "Student": 757,
    "Teacher": 60
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BFT4001",
    "Name": "SAM HULU LANGAT",
    "Student": 801,
    "Teacher": 57
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BFT5001",
    "Name": "SAM SUNGAI SELISEK",
    "Student": 238,
    "Teacher": 27
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU SELANGOR",
    "Kod": "BFT5002",
    "Name": "SAMT KUALA KUBU BHARU",
    "Student": 697,
    "Teacher": 70
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BFT6001",
    "Name": "SAM PARIT BARU",
    "Student": 293,
    "Teacher": 39
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BFT6002",
    "Name": "SAM BAGAN TERAP",
    "Student": 347,
    "Teacher": 41
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BFT6003",
    "Name": "SAM SUNGAI HAJI DORANI",
    "Student": 296,
    "Teacher": 44
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BFT6004",
    "Name": "SAM PASIR PANJANG",
    "Student": 320,
    "Teacher": 41
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BFT6005",
    "Name": "SAM MUHAMMADIAH",
    "Student": 536,
    "Teacher": 69
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BFT6006",
    "Name": "SAM TENGKU AMPUAN JEMAAH",
    "Student": 440,
    "Teacher": 42
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BFT6007",
    "Name": "SAMT SULTAN SALAHUDDIN ABD AZIZ SHAH",
    "Student": 660,
    "Teacher": 71
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BFT7001",
    "Name": "SAM RAWANG",
    "Student": 682,
    "Teacher": 53
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BFT8001",
    "Name": "SAM PAYA JARAS",
    "Student": 743,
    "Teacher": 57
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BFT8002",
    "Name": "SAM BESTARI",
    "Student": 584,
    "Teacher": 65
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BFT8003",
    "Name": "SAMT TENGKU AMPUAN JEMAAH",
    "Student": 780,
    "Teacher": 59
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BFT9001",
    "Name": "SAM SUNGAI MERAB LUAR",
    "Student": 572,
    "Teacher": 48
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BFT9002",
    "Name": "SAM BANDAR BARU SALAK TINGGI",
    "Student": 907,
    "Teacher": 70
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BHA3001",
    "Name": "KOLEJ VOKASIONAL KUALA SELANGOR",
    "Student": 490,
    "Teacher": 96
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BHA7001",
    "Name": "KOLEJ VOKASIONAL GOMBAK",
    "Student": 548,
    "Teacher": 84
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BHA8002",
    "Name": "KOLEJ VOKASIONAL SUNGAI BULOH",
    "Student": 938,
    "Teacher": 140
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD PETALING PERDANA",
    "Kod": "BHA8004",
    "Name": "KOLEJ VOKASIONAL SHAH ALAM",
    "Student": 576,
    "Teacher": 100
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SEPANG",
    "Kod": "BHA9001",
    "Name": "KOLEJ VOKASIONAL SEPANG",
    "Student": 749,
    "Teacher": 114
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BKA4001",
    "Name": "KOLEJ VOKASIONAL KAJANG",
    "Student": 708,
    "Teacher": 101
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BKE0001",
    "Name": "KOLEJ VOKASIONAL KLANG",
    "Student": 875,
    "Teacher": 133
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KLANG",
    "Kod": "BRA0001",
    "Name": "KOLEJ ISLAM SULTAN ALAM SHAH",
    "Student": 768,
    "Teacher": 79
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD KUALA SELANGOR",
    "Kod": "BRA3001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA KUALA SELANGOR",
    "Student": 635,
    "Teacher": 57
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BRA4001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA MAAHAD HAMIDIAH",
    "Student": 884,
    "Teacher": 72
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD HULU LANGAT",
    "Kod": "BRA4003",
    "Name": "SEKOLAH MENENGAH AGAMA PERSEKUTUAN KAJANG",
    "Student": 746,
    "Teacher": 61
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BRA6001",
    "Name": "SEKOLAH BERASRAMA PENUH INTEGRASI SABAK BERNAM",
    "Student": 586,
    "Teacher": 50
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD SABAK BERNAM",
    "Kod": "BRA6002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN RAHAH",
    "Student": 606,
    "Teacher": 44
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BRA7001",
    "Name": "SEKOLAH BERASRAMA PENUH INTEGRASI RAWANG",
    "Student": 582,
    "Teacher": 110
  },
  {
    "NationalState": "SELANGOR",
    "PPD": "PPD GOMBAK",
    "Kod": "BRA7002",
    "Name": "SEKOLAH BERASRAMA PENUH INTEGRASI GOMBAK",
    "Student": 617,
    "Teacher": 58
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0001",
    "Name": "SEKOLAH KEBANGSAAN FELDA LURAH BILUT",
    "Student": 301,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0002",
    "Name": "SEKOLAH KEBANGSAAN LEBU",
    "Student": 211,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0003",
    "Name": "SEKOLAH KEBANGSAAN SRI LAYANG",
    "Student": 166,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0004",
    "Name": "SEKOLAH KEBANGSAAN TUANKU FATIMAH",
    "Student": 666,
    "Teacher": 50
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0005",
    "Name": "SEKOLAH KEBANGSAAN FELDA KG. SERTIK",
    "Student": 340,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0006",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DUA",
    "Student": 130,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0007",
    "Name": "SEKOLAH KEBANGSAAN KARAK",
    "Student": 610,
    "Teacher": 49
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0008",
    "Name": "SEKOLAH KEBANGSAAN JAMBU RIAS",
    "Student": 160,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0009",
    "Name": "SEKOLAH KEBANGSAAN PELANGAI",
    "Student": 119,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0010",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG PELANGAI",
    "Student": 184,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0011",
    "Name": "SEKOLAH KEBANGSAAN KG SHAFIE",
    "Student": 69,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0013",
    "Name": "SEKOLAH KEBANGSAAN JANDA BAIK",
    "Student": 409,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0015",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) MEMPAGA 1",
    "Student": 292,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0016",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KEMASUL",
    "Student": 227,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0017",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) MEMPAGA 2",
    "Student": 608,
    "Teacher": 51
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0018",
    "Name": "SEKOLAH KEBANGSAAN LENTANG",
    "Student": 96,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0019",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PIATU",
    "Student": 656,
    "Teacher": 45
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0020",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) CHEMOMOI",
    "Student": 348,
    "Teacher": 33
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBA0021",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SG KEMAHAL",
    "Student": 153,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBA1001",
    "Name": "SEKOLAH KEBANGSAAN TANAH RATA",
    "Student": 542,
    "Teacher": 45
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBA1002",
    "Name": "SEKOLAH KEBANGSAAN TELANOK (JHEOA)",
    "Student": 279,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBA1003",
    "Name": "SEKOLAH KEBANGSAAN LEMOI (JHEOA)",
    "Student": 110,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBA1004",
    "Name": "SEKOLAH KEBANGSAAN MENSON (JHEOA)",
    "Student": 249,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBA1005",
    "Name": "SEKOLAH KEBANGSAAN RPS TERISU (JHEOA)",
    "Student": 109,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBA1006",
    "Name": "SEKOLAH KEBANGSAAN BRINCHANG",
    "Student": 130,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBA1007",
    "Name": "SEKOLAH KEBANGSAAN RINGLET",
    "Student": 211,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBA1008",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG RAJA",
    "Student": 233,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2001",
    "Name": "SEKOLAH KEBANGSAAN BANDAR JERANTUT",
    "Student": 537,
    "Teacher": 52
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2002",
    "Name": "SEKOLAH KEBANGSAAN KG BAHARU",
    "Student": 72,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2003",
    "Name": "SEKOLAH KEBANGSAAN DAMAK",
    "Student": 335,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2004",
    "Name": "SEKOLAH KEBANGSAAN PAWANG NONG",
    "Student": 198,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2005",
    "Name": "SEKOLAH KEBANGSAAN PEDAH",
    "Student": 271,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2006",
    "Name": "SEKOLAH KEBANGSAAN TANAH ROM",
    "Student": 70,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2007",
    "Name": "SEKOLAH KEBANGSAAN TEBING TINGGI",
    "Student": 117,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2008",
    "Name": "SEKOLAH KEBANGSAAN BUKIT DINDING",
    "Student": 164,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2009",
    "Name": "SEKOLAH KEBANGSAAN GINTONG",
    "Student": 19,
    "Teacher": 9
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2010",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 24",
    "Student": 147,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2011",
    "Name": "SEKOLAH KEBANGSAAN DURIAN HIJAU",
    "Student": 48,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2012",
    "Name": "SEKOLAH KEBANGSAAN BUKIT NIKMAT",
    "Student": 82,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2013",
    "Name": "SEKOLAH KEBANGSAAN PULAU TAWAR",
    "Student": 119,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2014",
    "Name": "SEKOLAH KEBANGSAAN PERIAN",
    "Student": 88,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2015",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SG TEKAM",
    "Student": 162,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2016",
    "Name": "SEKOLAH KEBANGSAAN PULAU MANSOK",
    "Student": 118,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2017",
    "Name": "SEKOLAH KEBANGSAAN TEH",
    "Student": 216,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2018",
    "Name": "SEKOLAH KEBANGSAAN KUALA TEMBELING",
    "Student": 115,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2019",
    "Name": "SEKOLAH KEBANGSAAN PASIR DURIAN",
    "Student": 52,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2020",
    "Name": "SEKOLAH KEBANGSAAN CHEBONG",
    "Student": 102,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2021",
    "Name": "SEKOLAH KEBANGSAAN MERTING",
    "Student": 106,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2022",
    "Name": "SEKOLAH KEBANGSAAN KUALA TAHAN",
    "Student": 317,
    "Teacher": 36
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2023",
    "Name": "SEKOLAH KEBANGSAAN KG PAGI",
    "Student": 38,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2024",
    "Name": "SEKOLAH KEBANGSAAN KUALA SAT",
    "Student": 55,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2025",
    "Name": "SEKOLAH KEBANGSAAN ULU TEMBELING",
    "Student": 76,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2026",
    "Name": "SEKOLAH KEBANGSAAN BUKIT MAT DALING",
    "Student": 54,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2027",
    "Name": "SEKOLAH KEBANGSAAN GUSAI",
    "Student": 18,
    "Teacher": 8
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2039",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PADANG PIOL",
    "Student": 472,
    "Teacher": 44
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2040",
    "Name": "SEKOLAH KEBANGSAAN LKTP TEKAM UTARA",
    "Student": 255,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2042",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KOTA GELANGGI 1",
    "Student": 290,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2044",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SG RETANG",
    "Student": 195,
    "Teacher": 23
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2045",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KOTA GELANGGI 2",
    "Student": 375,
    "Teacher": 36
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2046",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) KOTA GELANGGI 3",
    "Student": 177,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2047",
    "Name": "SEKOLAH KEBANGSAAN P. PENYELIDIKAN PERTANIAN TUN RAZAK",
    "Student": 320,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2048",
    "Name": "SEKOLAH KEBANGSAAN BATU BALAI",
    "Student": 175,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2049",
    "Name": "SEKOLAH KEBANGSAAN SG. KIOL (JHEOA)",
    "Student": 88,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2050",
    "Name": "SEKOLAH KEBANGSAAN TEMIN",
    "Student": 699,
    "Teacher": 49
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2051",
    "Name": "SEKOLAH KEBANGSAAN LKTP JENGKA 25",
    "Student": 74,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2052",
    "Name": "SEKOLAH KEBANGSAAN JERANTUT JAYA",
    "Student": 785,
    "Teacher": 59
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2053",
    "Name": "SEKOLAH KEBANGSAAN INDERAPURA",
    "Student": 214,
    "Teacher": 24
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA2054",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LEPAR UTARA 8",
    "Student": 181,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3001",
    "Name": "SEKOLAH KEBANGSAAN MELA",
    "Student": 94,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3002",
    "Name": "SEKOLAH KEBANGSAAN KUALA TELANG",
    "Student": 47,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3003",
    "Name": "SEKOLAH KEBANGSAAN BATU YON",
    "Student": 162,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3004",
    "Name": "SEKOLAH KEBANGSAAN KG KELEDEK",
    "Student": 144,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3005",
    "Name": "SEKOLAH KEBANGSAAN AUR GADING",
    "Student": 39,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3006",
    "Name": "SEKOLAH KEBANGSAAN MERAPOH",
    "Student": 292,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3007",
    "Name": "SEKOLAH KEBANGSAAN PENJOM",
    "Student": 241,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3008",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KECAU 1",
    "Student": 324,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3009",
    "Name": "SEKOLAH KEBANGSAAN BENTA",
    "Student": 243,
    "Teacher": 33
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3010",
    "Name": "SEKOLAH KEBANGSAAN BUDU",
    "Student": 33,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3011",
    "Name": "SEKOLAH KEBANGSAAN CHAT",
    "Student": 113,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3012",
    "Name": "SEKOLAH KEBANGSAAN KEMAHANG",
    "Student": 46,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3013",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG BESAR",
    "Student": 104,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3014",
    "Name": "SEKOLAH KEBANGSAAN JERANSANG",
    "Student": 134,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3016",
    "Name": "SEKOLAH KEBANGSAAN PAGAR SASAK",
    "Student": 140,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3017",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG GAHAI",
    "Student": 78,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3018",
    "Name": "SEKOLAH KEBANGSAAN KUALA MEDANG",
    "Student": 160,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3019",
    "Name": "SEKOLAH KEBANGSAAN WAN IBRAHIM",
    "Student": 691,
    "Teacher": 62
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3020",
    "Name": "SEKOLAH KEBANGSAAN RELONG",
    "Student": 137,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3021",
    "Name": "SEKOLAH KEBANGSAAN BERCHANG",
    "Student": 109,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3022",
    "Name": "SEKOLAH KEBANGSAAN LUBOK KULIT",
    "Student": 166,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3023",
    "Name": "SEKOLAH KEBANGSAAN PADANG TENGKU",
    "Student": 291,
    "Teacher": 38
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3024",
    "Name": "SEKOLAH KEBANGSAAN KECHOR TUI",
    "Student": 117,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3025",
    "Name": "SEKOLAH KEBANGSAAN GUA",
    "Student": 158,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3026",
    "Name": "SEKOLAH KEBANGSAAN KUALA LANAR",
    "Student": 102,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3028",
    "Name": "SEKOLAH KEBANGSAAN JERAM BUNGOR",
    "Student": 33,
    "Teacher": 8
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3029",
    "Name": "SEKOLAH KEBANGSAAN JERAM LANDAK",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3030",
    "Name": "SEKOLAH KEBANGSAAN KUALA KENONG",
    "Student": 9,
    "Teacher": 7
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3032",
    "Name": "SEKOLAH KEBANGSAAN KERAMBIT",
    "Student": 165,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3052",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SG KOYAN",
    "Student": 628,
    "Teacher": 54
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3053",
    "Name": "SEKOLAH KEBANGSAAN CHEGAR PERAH",
    "Student": 84,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3054",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) CHEGAR PERAH",
    "Student": 147,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3055",
    "Name": "SEKOLAH KEBANGSAAN BATU 13",
    "Student": 48,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3056",
    "Name": "SEKOLAH KEBANGSAAN KUALA KOYAN (JHEOA)",
    "Student": 161,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3057",
    "Name": "SEKOLAH KEBANGSAAN TITOM (JHEOA)",
    "Student": 138,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3058",
    "Name": "SEKOLAH KEBANGSAAN LENJANG(JHEOA)",
    "Student": 363,
    "Teacher": 26
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3059",
    "Name": "SEKOLAH KEBANGSAAN RPS BETAU (JHEOA)",
    "Student": 604,
    "Teacher": 42
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3060",
    "Name": "SEKOLAH KEBANGSAAN SENDERUT(JHEOA)",
    "Student": 370,
    "Teacher": 26
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3061",
    "Name": "SEKOLAH KEBANGSAAN LANAI (JHEOA)",
    "Student": 179,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3062",
    "Name": "SEKOLAH KEBANGSAAN BATU 9",
    "Student": 27,
    "Teacher": 7
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3063",
    "Name": "SEKOLAH KEBANGSAAN TEMPOYANG",
    "Student": 190,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3064",
    "Name": "SEKOLAH KEBANGSAAN TELUK GUNUNG",
    "Student": 245,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBA3065",
    "Name": "SEKOLAH KEBANGSAAN LIMAU PURUT",
    "Student": 99,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4001",
    "Name": "SEKOLAH KEBANGSAAN TERUNTUM",
    "Student": 966,
    "Teacher": 67
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4002",
    "Name": "SEKOLAH KEBANGSAAN GALING",
    "Student": 371,
    "Teacher": 40
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4003",
    "Name": "SEKOLAH KEBANGSAAN BESERAH",
    "Student": 793,
    "Teacher": 57
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4004",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KARANG",
    "Student": 1728,
    "Teacher": 115
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4005",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ULAR",
    "Student": 557,
    "Teacher": 46
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4006",
    "Name": "SEKOLAH KEBANGSAAN BALOK",
    "Student": 829,
    "Teacher": 61
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4007",
    "Name": "SEKOLAH KEBANGSAAN CHERATING",
    "Student": 686,
    "Teacher": 49
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4008",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG PADANG",
    "Student": 903,
    "Teacher": 58
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4009",
    "Name": "SEKOLAH KEBANGSAAN PANCHING",
    "Student": 279,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4010",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LEMBING",
    "Student": 119,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4011",
    "Name": "SEKOLAH KEBANGSAAN BATU TIGA",
    "Student": 346,
    "Teacher": 43
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4012",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG BADAK",
    "Student": 818,
    "Teacher": 54
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4013",
    "Name": "SEKOLAH KEBANGSAAN FAKEH ABD. SAMAD",
    "Student": 1119,
    "Teacher": 76
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4014",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG LUMPUR",
    "Student": 533,
    "Teacher": 41
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4015",
    "Name": "SEKOLAH KEBANGSAAN KEMPADANG",
    "Student": 1547,
    "Teacher": 102
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4016",
    "Name": "SEKOLAH KEBANGSAAN KUALA PENOR",
    "Student": 247,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4017",
    "Name": "SEKOLAH KEBANGSAAN CHEROK PALOH",
    "Student": 134,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA4018",
    "Name": "SEKOLAH KEBANGSAAN UBAI",
    "Student": 145,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4019",
    "Name": "SEKOLAH KEBANGSAAN JAYA GADING",
    "Student": 1213,
    "Teacher": 85
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4020",
    "Name": "SEKOLAH KEBANGSAAN NADAK",
    "Student": 116,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4021",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) BUKIT GOH",
    "Student": 713,
    "Teacher": 55
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4022",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) BUKIT SAGU 1",
    "Student": 287,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4023",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LEPAR HILIR 2",
    "Student": 258,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBA4024",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LEPAR UTARA 1",
    "Student": 412,
    "Teacher": 35
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4065",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SUNGAI PANCHING UTARA",
    "Student": 249,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4066",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SG PANCHING SELATAN",
    "Student": 240,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4067",
    "Name": "SEKOLAH KEBANGSAAN JABOR",
    "Student": 68,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4072",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) BT KUANTAN",
    "Student": 227,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4075",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SG PANCHING TIMUR",
    "Student": 306,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4076",
    "Name": "SEKOLAH KEBANGSAAN BUKIT SEKILAU",
    "Student": 729,
    "Teacher": 66
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4077",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KUIN",
    "Student": 143,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4078",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) LEPAR HILIR 1",
    "Student": 726,
    "Teacher": 55
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4079",
    "Name": "SEKOLAH KEBANGSAAN TANAH PUTIH BARU",
    "Student": 402,
    "Teacher": 47
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4080",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LEPAR HILIR 3",
    "Student": 237,
    "Teacher": 26
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4081",
    "Name": "SEKOLAH KEBANGSAAN GAMBANG",
    "Student": 662,
    "Teacher": 42
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4082",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KUANTAN",
    "Student": 85,
    "Teacher": 33
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4083",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) BUKIT SAGU 2",
    "Student": 202,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4084",
    "Name": "SEKOLAH KEBANGSAAN CENDERAWASIH",
    "Student": 924,
    "Teacher": 72
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4085",
    "Name": "SEKOLAH KEBANGSAAN INDERA MAHKOTA",
    "Student": 656,
    "Teacher": 55
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4086",
    "Name": "SEKOLAH KEBANGSAAN SEMAMBU",
    "Student": 496,
    "Teacher": 47
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4087",
    "Name": "SEKOLAH KEBANGSAAN SG. MAS (JHEOA)",
    "Student": 85,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4088",
    "Name": "SEKOLAH KEBANGSAAN BALOK BARU",
    "Student": 660,
    "Teacher": 47
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4089",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ISAP",
    "Student": 1133,
    "Teacher": 74
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4090",
    "Name": "SEKOLAH KEBANGSAAN WIRA",
    "Student": 1091,
    "Teacher": 93
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4091",
    "Name": "SEKOLAH KEBANGSAAN PANDAN",
    "Student": 1295,
    "Teacher": 80
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4092",
    "Name": "SEKOLAH KEBANGSAAN TOK SERA",
    "Student": 818,
    "Teacher": 55
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4093",
    "Name": "SEKOLAH KEBANGSAAN BUKIT RANGIN",
    "Student": 658,
    "Teacher": 43
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4094",
    "Name": "SEKOLAH KEBANGSAAN INDERA MAHKOTA UTAMA",
    "Student": 983,
    "Teacher": 62
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4095",
    "Name": "SEKOLAH KEBANGSAAN SERI MAHKOTA",
    "Student": 506,
    "Teacher": 39
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4096",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TALAM",
    "Student": 1085,
    "Teacher": 73
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4097",
    "Name": "SEKOLAH KEBANGSAAN AIR PUTIH",
    "Student": 437,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4098",
    "Name": "SEKOLAH KEBANGSAAN PELABUHAN",
    "Student": 858,
    "Teacher": 57
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4099",
    "Name": "SEKOLAH KEBANGSAAN MAT KILAU",
    "Student": 929,
    "Teacher": 59
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4100",
    "Name": "SEKOLAH KEBANGSAAN RTP BUKIT GOH",
    "Student": 943,
    "Teacher": 61
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4101",
    "Name": "SEKOLAH KEBANGSAAN BALOK MAKMUR",
    "Student": 619,
    "Teacher": 49
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4102",
    "Name": "SEKOLAH KEBANGSAAN BUKIT SETONGKOL",
    "Student": 820,
    "Teacher": 49
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4103",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BAGING",
    "Student": 302,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4104",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SOI",
    "Student": 1405,
    "Teacher": 82
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4105",
    "Name": "SEKOLAH KEBANGSAAN TUNKU AZIZAH",
    "Student": 929,
    "Teacher": 68
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4106",
    "Name": "SEKOLAH KEBANGSAAN BUNUT RENDANG",
    "Student": 877,
    "Teacher": 67
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4107",
    "Name": "SEKOLAH KEBANGSAAN PELINDUNG",
    "Student": 980,
    "Teacher": 62
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4108",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BELUKAR",
    "Student": 723,
    "Teacher": 51
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4109",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ISAP MURNI",
    "Student": 691,
    "Teacher": 43
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4110",
    "Name": "SEKOLAH KEBANGSAAN PADANG GARUDA",
    "Student": 875,
    "Teacher": 55
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4112",
    "Name": "SEKOLAH KEBANGSAAN PAYA BUNGOR",
    "Student": 249,
    "Teacher": 24
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4113",
    "Name": "SEKOLAH KEBANGSAAN SERI MELATI",
    "Student": 1038,
    "Teacher": 69
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBA4114",
    "Name": "SEKOLAH KEBANGSAAN CHENGAL LEMPONG",
    "Student": 675,
    "Teacher": 49
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5010",
    "Name": "SEKOLAH KEBANGSAAN BELIMBING",
    "Student": 15,
    "Teacher": 8
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5011",
    "Name": "SEKOLAH KEBANGSAAN SERI TERENTANG",
    "Student": 419,
    "Teacher": 39
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5012",
    "Name": "SEKOLAH KEBANGSAAN NENASI",
    "Student": 301,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5013",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG BATU",
    "Student": 351,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5014",
    "Name": "SEKOLAH KEBANGSAAN SRI MAULANA",
    "Student": 401,
    "Teacher": 38
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5015",
    "Name": "SEKOLAH KEBANGSAAN SINAR MUTIARA",
    "Student": 351,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5016",
    "Name": "SEKOLAH KEBANGSAAN INDERA SHAHBANDAR",
    "Student": 578,
    "Teacher": 53
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5017",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) CHINI TIMUR 1",
    "Student": 314,
    "Teacher": 26
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5018",
    "Name": "SEKOLAH KEBANGSAAN LANGGAR",
    "Student": 113,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5019",
    "Name": "SEKOLAH KEBANGSAAN PAHANG TUA",
    "Student": 113,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5020",
    "Name": "SEKOLAH KEBANGSAAN PULAU RUSA",
    "Student": 49,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5021",
    "Name": "SEKOLAH KEBANGSAAN GANCHONG",
    "Student": 98,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5022",
    "Name": "SEKOLAH KEBANGSAAN PULAU MANIS",
    "Student": 39,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5023",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG KELEDANG",
    "Student": 116,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5024",
    "Name": "SEKOLAH KEBANGSAAN SERI BIRAM",
    "Student": 780,
    "Teacher": 70
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5025",
    "Name": "SEKOLAH KEBANGSAAN PASIR PANJANG",
    "Student": 163,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5026",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG AGAS",
    "Student": 283,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5027",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG MEDANG",
    "Student": 160,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5028",
    "Name": "SEKOLAH KEBANGSAAN KG ACHEH",
    "Student": 72,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5029",
    "Name": "SEKOLAH KEBANGSAAN TEMAI",
    "Student": 125,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5030",
    "Name": "SEKOLAH KEBANGSAAN PADANG RUMBIA",
    "Student": 47,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5031",
    "Name": "SEKOLAH KEBANGSAAN SERAMBI",
    "Student": 58,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5032",
    "Name": "SEKOLAH KEBANGSAAN PALOH HINAI",
    "Student": 247,
    "Teacher": 33
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5033",
    "Name": "SEKOLAH KEBANGSAAN PELAK",
    "Student": 71,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5034",
    "Name": "SEKOLAH KEBANGSAAN MAMBANG",
    "Student": 249,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5035",
    "Name": "SEKOLAH KEBANGSAAN KINCHIR",
    "Student": 112,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5036",
    "Name": "SEKOLAH KEBANGSAAN TANAH PUTEH",
    "Student": 304,
    "Teacher": 39
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5037",
    "Name": "SEKOLAH KEBANGSAAN SERANDU",
    "Student": 243,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5044",
    "Name": "SEKOLAH KEBANGSAAN MERCHONG JAYA",
    "Student": 624,
    "Teacher": 42
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5072",
    "Name": "SEKOLAH KEBANGSAAN SERI MAKMUR",
    "Student": 159,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5074",
    "Name": "SEKOLAH KEBANGSAAN LEPAR",
    "Student": 376,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5078",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) CHINI 1 & 4",
    "Student": 602,
    "Teacher": 46
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5079",
    "Name": "SEKOLAH KEBANGSAAN KOTA PERDANA",
    "Student": 168,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5080",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) CHINI 2",
    "Student": 365,
    "Teacher": 35
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5081",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) CHINI 3 & 5",
    "Student": 497,
    "Teacher": 38
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5082",
    "Name": "SEKOLAH KEBANGSAAN RUNCHANG",
    "Student": 305,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5084",
    "Name": "SEKOLAH KEBANGSAAN PERAMU JAYA",
    "Student": 685,
    "Teacher": 54
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5085",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) CHINI TIMUR 2",
    "Student": 377,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5086",
    "Name": "SEKOLAH KEBANGSAAN TASIK CINI PEKAN (JHEOA)",
    "Student": 94,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5087",
    "Name": "SEKOLAH KEBANGSAAN BANDAR 2",
    "Student": 359,
    "Teacher": 42
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5088",
    "Name": "SEKOLAH KEBANGSAAN PEKAN JAYA",
    "Student": 500,
    "Teacher": 43
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5089",
    "Name": "SEKOLAH KEBANGSAAN PULAU SERAI",
    "Student": 250,
    "Teacher": 24
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBA5090",
    "Name": "SEKOLAH KEBANGSAAN SIMPAI",
    "Student": 200,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6001",
    "Name": "SEKOLAH KEBANGSAAN BANDAR RAUB",
    "Student": 337,
    "Teacher": 38
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6002",
    "Name": "SEKOLAH KEBANGSAAN ULU GALI",
    "Student": 342,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6003",
    "Name": "SEKOLAH KEBANGSAAN GALI",
    "Student": 115,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6005",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG PUTUS",
    "Student": 137,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6006",
    "Name": "SEKOLAH KEBANGSAAN PAMAH KULAT",
    "Student": 157,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6007",
    "Name": "SEKOLAH KEBANGSAAN ULU DONG",
    "Student": 63,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6008",
    "Name": "SEKOLAH KEBANGSAAN MUHAMMAD JABAR",
    "Student": 313,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6009",
    "Name": "SEKOLAH KEBANGSAAN TEMAU",
    "Student": 44,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6010",
    "Name": "SEKOLAH KEBANGSAAN KUALA DONG",
    "Student": 44,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6011",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG RAJA",
    "Student": 39,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6012",
    "Name": "SEKOLAH KEBANGSAAN CHENUA",
    "Student": 73,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6013",
    "Name": "SEKOLAH KEBANGSAAN SEGA",
    "Student": 121,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6014",
    "Name": "SEKOLAH KEBANGSAAN KUALA ATOK",
    "Student": 93,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6015",
    "Name": "SEKOLAH KEBANGSAAN ULU ATOK",
    "Student": 75,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6016",
    "Name": "SEKOLAH KEBANGSAAN SEMANTAN ULU",
    "Student": 19,
    "Teacher": 8
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6017",
    "Name": "SEKOLAH KEBANGSAAN KUNDANG PATAH",
    "Student": 87,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6018",
    "Name": "SEKOLAH KEBANGSAAN BATU TALAM",
    "Student": 194,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6019",
    "Name": "SEKOLAH KEBANGSAAN TERSANG",
    "Student": 70,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6020",
    "Name": "SEKOLAH KEBANGSAAN ULU SUNGAI",
    "Student": 113,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6021",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LEMBAH KLAU",
    "Student": 245,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6022",
    "Name": "SEKOLAH KEBANGSAAN LKTP TERSANG SATU",
    "Student": 307,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6023",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KRAU 1",
    "Student": 107,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6024",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) TERSANG 3",
    "Student": 337,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6025",
    "Name": "SEKOLAH KEBANGSAAN BUKIT FRASER",
    "Student": 39,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6026",
    "Name": "SEKOLAH KEBANGSAAN SATAK",
    "Student": 230,
    "Teacher": 23
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6027",
    "Name": "SEKOLAH KEBANGSAAN RAUB INDAH",
    "Student": 185,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBA6028",
    "Name": "SEKOLAH KEBANGSAAN RAUB JAYA",
    "Student": 366,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7008",
    "Name": "SEKOLAH KEBANGSAAN BATU KAPOR",
    "Student": 633,
    "Teacher": 52
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7010",
    "Name": "SEKOLAH KEBANGSAAN RANTAU PANJANG",
    "Student": 189,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7011",
    "Name": "SEKOLAH KEBANGSAAN LUBOK TERUA",
    "Student": 177,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7012",
    "Name": "SEKOLAH KEBANGSAAN BOLOK",
    "Student": 189,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7013",
    "Name": "SEKOLAH KEBANGSAAN KUALA KAUNG",
    "Student": 65,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7014",
    "Name": "SEKOLAH KEBANGSAAN PAYA ARA",
    "Student": 224,
    "Teacher": 23
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7015",
    "Name": "SEKOLAH KEBANGSAAN SANGGANG",
    "Student": 93,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7016",
    "Name": "SEKOLAH KEBANGSAAN SANGGANG SEBERANG",
    "Student": 82,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7017",
    "Name": "SEKOLAH KEBANGSAAN BINTANG",
    "Student": 71,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7018",
    "Name": "SEKOLAH KEBANGSAAN TELOK SENTANG",
    "Student": 264,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7019",
    "Name": "SEKOLAH KEBANGSAAN PAYA TARAM",
    "Student": 176,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7020",
    "Name": "SEKOLAH KEBANGSAAN KERDAU",
    "Student": 161,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7021",
    "Name": "SEKOLAH KEBANGSAAN LIPAT KAJANG",
    "Student": 48,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7022",
    "Name": "SEKOLAH KEBANGSAAN KUALA TEKAL",
    "Student": 100,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7023",
    "Name": "SEKOLAH KEBANGSAAN PULAU PASIR MANDI",
    "Student": 46,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7024",
    "Name": "SEKOLAH KEBANGSAAN BATU SAWAR",
    "Student": 41,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7025",
    "Name": "SEKOLAH KEBANGSAAN JENDERAK",
    "Student": 97,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7026",
    "Name": "SEKOLAH KEBANGSAAN GUNONG SENYUM",
    "Student": 108,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7027",
    "Name": "SEKOLAH KEBANGSAAN KUALA KRAU",
    "Student": 265,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7028",
    "Name": "SEKOLAH KEBANGSAAN PAYA LUAS",
    "Student": 118,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7029",
    "Name": "SEKOLAH KEBANGSAAN KUALA MAI BHARU",
    "Student": 270,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7030",
    "Name": "SEKOLAH KEBANGSAAN BANGAU",
    "Student": 188,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7031",
    "Name": "SEKOLAH KEBANGSAAN BANDAR MENTAKAB",
    "Student": 240,
    "Teacher": 35
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7032",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TEMERLOH",
    "Student": 764,
    "Teacher": 78
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7033",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG LALANG",
    "Student": 511,
    "Teacher": 41
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7034",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG TENGAH",
    "Student": 282,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7035",
    "Name": "SEKOLAH KEBANGSAAN BELENGU",
    "Student": 83,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7037",
    "Name": "SEKOLAH KEBANGSAAN LEBAK",
    "Student": 108,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7046",
    "Name": "SEKOLAH KEBANGSAAN PAYA PULAI",
    "Student": 1082,
    "Teacher": 78
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7056",
    "Name": "SEKOLAH KEBANGSAAN TELOK IRA",
    "Student": 169,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7059",
    "Name": "SEKOLAH KEBANGSAAN DESA BAKTI",
    "Student": 416,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7060",
    "Name": "SEKOLAH KEBANGSAAN SERI SONGSANG",
    "Student": 353,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7122",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENDERAK UTARA",
    "Student": 186,
    "Teacher": 23
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7140",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENDERAK SELATAN",
    "Student": 198,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7141",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) BUKIT DAMAR",
    "Student": 252,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7144",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 23",
    "Student": 182,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7151",
    "Name": "SEKOLAH KEBANGSAAN PENDERAS(JHEOA)",
    "Student": 375,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7152",
    "Name": "SEKOLAH KEBANGSAAN SERI TUALANG",
    "Student": 277,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7153",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI GAU",
    "Student": 552,
    "Teacher": 39
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7154",
    "Name": "SEKOLAH KEBANGSAAN TEMERLOH JAYA",
    "Student": 813,
    "Teacher": 58
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7155",
    "Name": "SEKOLAH KEBANGSAAN MENTAKAB ( CHATIN )",
    "Student": 399,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7156",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG BATU",
    "Student": 298,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7157",
    "Name": "SEKOLAH KEBANGSAAN BUKIT CERMIN",
    "Student": 261,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7158",
    "Name": "SEKOLAH KEBANGSAAN LANCHANG",
    "Student": 204,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7159",
    "Name": "SEKOLAH KEBANGSAAN MENTAKAB JAYA",
    "Student": 314,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBA7160",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LAKUM",
    "Student": 265,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8001",
    "Name": "SEKOLAH KEBANGSAAN ROMPIN",
    "Student": 698,
    "Teacher": 52
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8002",
    "Name": "SEKOLAH KEBANGSAAN PONTIAN",
    "Student": 386,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8003",
    "Name": "SEKOLAH KEBANGSAAN TANJONG GEMOK",
    "Student": 515,
    "Teacher": 50
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8004",
    "Name": "SEKOLAH KEBANGSAAN PIANGGU",
    "Student": 371,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8005",
    "Name": "SEKOLAH KEBANGSAAN SARANG TIONG",
    "Student": 119,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8006",
    "Name": "SEKOLAH KEBANGSAAN LEBAN CHONDONG",
    "Student": 537,
    "Teacher": 45
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8007",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG AUR",
    "Student": 99,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8008",
    "Name": "SEKOLAH KEBANGSAAN BUKIT IBAM",
    "Student": 343,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8009",
    "Name": "SEKOLAH KEBANGSAAN TEKEK",
    "Student": 203,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8010",
    "Name": "SEKOLAH KEBANGSAAN PERANTAU DAMAI",
    "Student": 229,
    "Teacher": 24
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8011",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SELENDANG 01",
    "Student": 204,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8012",
    "Name": "SEKOLAH KEBANGSAAN KEDAIK",
    "Student": 527,
    "Teacher": 43
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8013",
    "Name": "SEKOLAH KEBANGSAAN BT SEROK",
    "Student": 190,
    "Teacher": 23
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8014",
    "Name": "SEKOLAH KEBANGSAAN LADANG KOTA BAHAGIA",
    "Student": 206,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8015",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KERATONG 1",
    "Student": 407,
    "Teacher": 42
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8018",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) KERATONG 4",
    "Student": 436,
    "Teacher": 33
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8020",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KERATONG 2",
    "Student": 227,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8021",
    "Name": "SEKOLAH KEBANGSAAN LKTP SELANCAR 1",
    "Student": 306,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8022",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KERATONG 6",
    "Student": 182,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8023",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) KERATONG 3",
    "Student": 438,
    "Teacher": 35
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8024",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) KERATONG 8",
    "Student": 178,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8025",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PUTERI",
    "Student": 543,
    "Teacher": 43
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8026",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) SELANCAR 3",
    "Student": 151,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8027",
    "Name": "SEKOLAH KEBANGSAAN MELATI",
    "Student": 219,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8028",
    "Name": "SEKOLAH KEBANGSAAN BULUH NIPIS",
    "Student": 91,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8029",
    "Name": "SEKOLAH KEBANGSAAN DENAI",
    "Student": 69,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8030",
    "Name": "SEKOLAH KEBANGSAAN SG. MOK (JHEOA)",
    "Student": 108,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8031",
    "Name": "SEKOLAH KEBANGSAAN MUKUT",
    "Student": 21,
    "Teacher": 8
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8032",
    "Name": "SEKOLAH KEBANGSAAN JUARA",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8033",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TUN RAZAK",
    "Student": 176,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8034",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) KERATONG 7",
    "Student": 193,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8035",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG KOLAM",
    "Student": 562,
    "Teacher": 51
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8036",
    "Name": "SEKOLAH KEBANGSAAN MUADZAM JAYA",
    "Student": 716,
    "Teacher": 53
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8037",
    "Name": "SEKOLAH KEBANGSAAN KERATONG 10",
    "Student": 207,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8038",
    "Name": "SEKOLAH KEBANGSAAN JANGLAU",
    "Student": 270,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8039",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) SELENDANG 4",
    "Student": 140,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8040",
    "Name": "SEKOLAH KEBANGSAAN BUKIT RIDAN",
    "Student": 650,
    "Teacher": 51
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8041",
    "Name": "SEKOLAH KEBANGSAAN CHENDERAWASIH",
    "Student": 88,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8042",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SELANCAR 2",
    "Student": 356,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBA8043",
    "Name": "SEKOLAH KEBANGSAAN MUADZAM SHAH",
    "Student": 414,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9001",
    "Name": "SEKOLAH KEBANGSAAN MARAN",
    "Student": 592,
    "Teacher": 56
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9002",
    "Name": "SEKOLAH KEBANGSAAN ULU LUIT",
    "Student": 298,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9003",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) KG NEW ZEALAND",
    "Student": 222,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9004",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) BT TAJAU",
    "Student": 146,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9005",
    "Name": "SEKOLAH KEBANGSAAN LKTP ULU JEMPOL",
    "Student": 267,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9006",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 1",
    "Student": 244,
    "Teacher": 26
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9007",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 2",
    "Student": 294,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9008",
    "Name": "SEKOLAH KEBANGSAAN KUALA SENTOL",
    "Student": 129,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9009",
    "Name": "SEKOLAH KEBANGSAAN SENGGORA",
    "Student": 75,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9010",
    "Name": "SEKOLAH KEBANGSAAN KUALA WAU",
    "Student": 69,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9011",
    "Name": "SEKOLAH KEBANGSAAN SERENGKAM",
    "Student": 84,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9012",
    "Name": "SEKOLAH KEBANGSAAN PAYA PASIR",
    "Student": 125,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9013",
    "Name": "SEKOLAH KEBANGSAAN PESAGI",
    "Student": 52,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9040",
    "Name": "SEKOLAH KEBANGSAAN CHENOR",
    "Student": 131,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9041",
    "Name": "SEKOLAH KEBANGSAAN KERTAU",
    "Student": 79,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9042",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG PERANG",
    "Student": 121,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9043",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 15",
    "Student": 241,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9044",
    "Name": "SEKOLAH KEBANGSAAN BUKIT SEGUMPAL",
    "Student": 100,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9045",
    "Name": "SEKOLAH KEBANGSAAN BUKIT LADA",
    "Student": 128,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9046",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BOTA",
    "Student": 279,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9047",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 21",
    "Student": 241,
    "Teacher": 26
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9048",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) KG AWAH",
    "Student": 194,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9049",
    "Name": "SEKOLAH KEBANGSAAN SEKARA",
    "Student": 98,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9055",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SG NEREK",
    "Student": 157,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9105",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 3",
    "Student": 242,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9106",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 4",
    "Student": 186,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9110",
    "Name": "SEKOLAH KEBANGSAAN LKTP JENGKA 5",
    "Student": 147,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9111",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 6",
    "Student": 206,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9112",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) JENGKA 7",
    "Student": 166,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9113",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 8",
    "Student": 229,
    "Teacher": 23
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9117",
    "Name": "SEKOLAH KEBANGSAAN BANDAR PUSAT",
    "Student": 624,
    "Teacher": 56
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9118",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 9",
    "Student": 197,
    "Teacher": 24
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9119",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 10",
    "Student": 199,
    "Teacher": 24
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9120",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 11",
    "Student": 270,
    "Teacher": 26
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9124",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) JENGKA 12",
    "Student": 227,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9125",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) JENGKA 14",
    "Student": 294,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9132",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 13",
    "Student": 164,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9136",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 16",
    "Student": 223,
    "Teacher": 23
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9137",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 17",
    "Student": 232,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9138",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 18",
    "Student": 310,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9139",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 19",
    "Student": 321,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9142",
    "Name": "SEKOLAH KEBANGSAAN SENTOSA",
    "Student": 97,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9143",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 20",
    "Student": 200,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9145",
    "Name": "SEKOLAH KEBANGSAAN JENGKA BATU 13",
    "Student": 119,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9149",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) JENGKA 22",
    "Student": 147,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9150",
    "Name": "SEKOLAH KEBANGSAAN JENGKA PUSAT 2",
    "Student": 422,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9151",
    "Name": "SEKOLAH KEBANGSAAN SERI KERAMAT",
    "Student": 224,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9152",
    "Name": "SEKOLAH KEBANGSAAN CHEDONG JAYA",
    "Student": 141,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBA9153",
    "Name": "SEKOLAH KEBANGSAAN DESA JAYA",
    "Student": 527,
    "Teacher": 39
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA001",
    "Name": "SEKOLAH KEBANGSAAN BUKIT GEMURUH (JHEOA)",
    "Student": 90,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA002",
    "Name": "SEKOLAH KEBANGSAAN ISKANDAR",
    "Student": 201,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA009",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) TEMBANGAU 1",
    "Student": 265,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA010",
    "Name": "SEKOLAH KEBANGSAAN LKTP TEMBANGAU 3",
    "Student": 99,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA011",
    "Name": "SEKOLAH KEBANGSAAN LKTP TEMBANGAU 6",
    "Student": 98,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA036",
    "Name": "SEKOLAH KEBANGSAAN DURIAN TAWAR",
    "Student": 137,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA038",
    "Name": "SEKOLAH KEBANGSAAN KERAYONG",
    "Student": 589,
    "Teacher": 46
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA039",
    "Name": "SEKOLAH KEBANGSAAN KUALA TRIANG",
    "Student": 130,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA040",
    "Name": "SEKOLAH KEBANGSAAN BATU PAPAN",
    "Student": 76,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA041",
    "Name": "SEKOLAH KEBANGSAAN KUALA BERA",
    "Student": 58,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA042",
    "Name": "SEKOLAH KEBANGSAAN BT ROK",
    "Student": 124,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA045",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) TRIANG 3",
    "Student": 295,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA057",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) BT MENDI",
    "Student": 281,
    "Teacher": 33
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA058",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) BT PUCHONG",
    "Student": 215,
    "Teacher": 26
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA109",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) BT KEPAYANG",
    "Student": 189,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA121",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) MAYAM",
    "Student": 191,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA123",
    "Name": "SEKOLAH KEBANGSAAN BOHOR BAHARU",
    "Student": 83,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA130",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PURUN",
    "Student": 344,
    "Teacher": 38
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA135",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) TEMENTI",
    "Student": 313,
    "Teacher": 39
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA145",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SEBERTAK",
    "Student": 314,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA146",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) RENTAM",
    "Student": 205,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA147",
    "Name": "SEKOLAH KEBANGSAAN KEMAYAN",
    "Student": 244,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA148",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KUMAI",
    "Student": 188,
    "Teacher": 18
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA149",
    "Name": "SEKOLAH KEBANGSAAN LKTP TRIANG 2",
    "Student": 150,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA150",
    "Name": "SEKOLAH KEBANGSAAN SRI BUNTAR",
    "Student": 402,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA151",
    "Name": "SEKOLAH KEBANGSAAN MENGKUANG",
    "Student": 66,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA152",
    "Name": "SEKOLAH KEBANGSAAN KERAYONG JAYA",
    "Student": 761,
    "Teacher": 60
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA153",
    "Name": "SEKOLAH KEBANGSAAN BATU BOR",
    "Student": 38,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA154",
    "Name": "SEKOLAH KEBANGSAAN GUAI",
    "Student": 64,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBAA155",
    "Name": "SEKOLAH KEBANGSAAN CHARUK PUTING",
    "Student": 200,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBB0015",
    "Name": "SEKOLAH KEBANGSAAN SULAIMAN",
    "Student": 811,
    "Teacher": 63
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBB0054",
    "Name": "SEKOLAH KEBANGSAAN SG MARONG",
    "Student": 318,
    "Teacher": 43
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBB1002",
    "Name": "SEKOLAH KEBANGSAAN CONVENT",
    "Student": 291,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBB2031",
    "Name": "SEKOLAH KEBANGSAAN JERANTUT",
    "Student": 437,
    "Teacher": 40
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBB3036",
    "Name": "SEKOLAH KEBANGSAAN CLIFFORD",
    "Student": 514,
    "Teacher": 45
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBB4026",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ABDULLAH",
    "Student": 748,
    "Teacher": 53
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBB4027",
    "Name": "SEKOLAH KEBANGSAAN ST THOMAS",
    "Student": 350,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBB4028",
    "Name": "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST",
    "Student": 732,
    "Teacher": 53
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBB4029",
    "Name": "SEKOLAH KEBANGSAAN ASSUNTA CONVENT",
    "Student": 478,
    "Teacher": 39
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBB4030",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN TENTERA",
    "Student": 909,
    "Teacher": 84
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBB5061",
    "Name": "SEKOLAH KEBANGSAAN SULTAN AHMAD",
    "Student": 648,
    "Teacher": 45
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBB6024",
    "Name": "SEKOLAH KEBANGSAAN MAHMUD",
    "Student": 413,
    "Teacher": 51
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBB6025",
    "Name": "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST",
    "Student": 331,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBB7066",
    "Name": "SEKOLAH KEBANGSAAN JALAN BAHAGIA",
    "Student": 793,
    "Teacher": 56
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBB7067",
    "Name": "SEKOLAH KEBANGSAAN ABU BAKAR",
    "Student": 601,
    "Teacher": 45
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBC0023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI MUN PAGI",
    "Student": 296,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBC0024",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI MUN REPAS",
    "Student": 90,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBC0025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI MUN CHAMANG",
    "Student": 146,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBC0027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KETARI",
    "Student": 322,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBC0028",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PERTING",
    "Student": 260,
    "Teacher": 24
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBC0029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI DUA",
    "Student": 40,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBC0030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KARAK",
    "Student": 278,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBC0033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TELEMONG",
    "Student": 121,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBC0034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MANCHIS",
    "Student": 35,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBC0035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT TINGGI",
    "Student": 155,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBC0036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LURAH BILUT",
    "Student": 53,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBC1004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CAMERON",
    "Student": 167,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBC1005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TANAH RATA",
    "Student": 69,
    "Teacher": 9
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBC1006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BRINCHANG",
    "Student": 143,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBC1007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG RAJA",
    "Student": 269,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBC1008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BERTAM VALLEY",
    "Student": 206,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBC1010",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TRINGKAP",
    "Student": 116,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBC1011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEA FARM",
    "Student": 50,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBC2033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SG JAN",
    "Student": 684,
    "Teacher": 47
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBC2034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BATU BALAI",
    "Student": 55,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBC2035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JERANSONG",
    "Student": 89,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBC2036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) DAMAK",
    "Student": 40,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBC3040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MELA",
    "Student": 11,
    "Teacher": 7
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBC3041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 247,
    "Teacher": 24
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBC3042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PADANG TENGKU",
    "Student": 39,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBC3043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JERKOH",
    "Student": 94,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBC3044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PENJOM",
    "Student": 56,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBC3045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BENTA",
    "Student": 104,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SEMAMBU",
    "Student": 536,
    "Teacher": 33
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHING",
    "Student": 705,
    "Teacher": 51
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUANG HWA",
    "Student": 839,
    "Teacher": 55
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHAI",
    "Student": 1333,
    "Teacher": 75
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) GAMBANG",
    "Student": 139,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE SHIAN",
    "Student": 220,
    "Teacher": 26
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN",
    "Student": 548,
    "Teacher": 44
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LEMBING",
    "Student": 65,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PANCHING",
    "Student": 36,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POOI MING",
    "Student": 188,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAMAN TAS",
    "Student": 370,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POAY CHAI",
    "Student": null,
    "Teacher": null
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBC4053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHING 2",
    "Student": 486,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBC5064",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEKAN",
    "Student": 220,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBC5065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEE WHA",
    "Student": 131,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CBC5066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE HWA",
    "Student": 28,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBC6031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHING",
    "Student": 294,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBC6032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SEMPALIT",
    "Student": 342,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBC6033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI RUAN",
    "Student": 367,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBC6034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUH HWA",
    "Student": 126,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBC6035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SG LUI",
    "Student": 210,
    "Teacher": 22
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBC6036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEROH",
    "Student": 99,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBC6037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TRAS",
    "Student": 81,
    "Teacher": 13
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBC6038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SG CHETANG",
    "Student": 41,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBC6039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SANG LEE",
    "Student": 77,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBC6040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SG KLAU",
    "Student": 158,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBC7074",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHEE CHEE",
    "Student": 510,
    "Teacher": 37
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBC7075",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MENTAKAB (1)",
    "Student": 629,
    "Teacher": 43
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBC7076",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MENTAKAB (2)",
    "Student": 570,
    "Teacher": 35
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBC7077",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LANCHANG",
    "Student": 68,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBC7078",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YEOW CHENG LUAN",
    "Student": 147,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBC7085",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KERDAU",
    "Student": 63,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBC7087",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA KRAU",
    "Student": 93,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBC7088",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI KAWANG",
    "Student": 10,
    "Teacher": 8
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CBC8016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ROMPIN",
    "Student": 243,
    "Teacher": 24
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBC9063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI MIN",
    "Student": 110,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBC9068",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MARAN",
    "Student": 112,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBCA079",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TRIANG (1)",
    "Student": 508,
    "Teacher": 41
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBCA080",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TRIANG (2)",
    "Student": 472,
    "Teacher": 37
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBCA082",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KERAYONG",
    "Student": 354,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBCA083",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MENGKUANG",
    "Student": 108,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBCA084",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MENGKARAK",
    "Student": 128,
    "Teacher": 17
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBCA086",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEMASUL",
    "Student": 8,
    "Teacher": 7
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBCA088",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MENTERI",
    "Student": 42,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBD0038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BENTONG",
    "Student": 125,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBD0039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SRI TELEMONG",
    "Student": 35,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBD0040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG RENJOK",
    "Student": 36,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBD0041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KARAK",
    "Student": 250,
    "Teacher": 24
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBD0042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LURAH BILUT",
    "Student": 28,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBD1012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TANAH RATA",
    "Student": 46,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBD1013",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BOH (1)",
    "Student": 4,
    "Teacher": 5
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBD1014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BOH (2)",
    "Student": 36,
    "Teacher": 8
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBD1015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG PALAS",
    "Student": 18,
    "Teacher": 9
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBD1016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BLUE VALLEY",
    "Student": 38,
    "Teacher": 9
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBD1017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SHUM YIP LEONG",
    "Student": 9,
    "Teacher": 5
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBD1019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) RINGLET",
    "Student": 68,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBD1020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUALA TERLA",
    "Student": 53,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CBD2037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JERANTUT",
    "Student": 139,
    "Teacher": 15
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBD3047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUALA LIPIS",
    "Student": 45,
    "Teacher": 9
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBD3048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUDU",
    "Student": 5,
    "Teacher": 4
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBD3049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BENTA",
    "Student": 41,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CBD3050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SELBORNE",
    "Student": 27,
    "Teacher": 9
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBD4051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR INDERA MAHKOTA",
    "Student": 304,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBD4052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JERAM",
    "Student": 45,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CBD4053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KUALA REMAN",
    "Student": 37,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBD6041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) RAUB",
    "Student": 156,
    "Teacher": 19
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBD6042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHEROH",
    "Student": 32,
    "Teacher": 9
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBD6043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BUKIT FRASER",
    "Student": 24,
    "Teacher": 7
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CBD6044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GALI",
    "Student": 11,
    "Teacher": 5
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBD7089",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MENTAKAB",
    "Student": 333,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBD7090",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG EDENSOR",
    "Student": 37,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBD7091",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MENTAKAB",
    "Student": 80,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBD7092",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG TEKAL",
    "Student": 44,
    "Teacher": 12
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBD7093",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BEE YONG",
    "Student": 14,
    "Teacher": 8
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBD7094",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SEMANTAN",
    "Student": 55,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBD7095",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG YEOW CHENG LUAN",
    "Student": 61,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBD7096",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG LANCHANG",
    "Student": 29,
    "Teacher": 9
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CBD7097",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI KAWANG",
    "Student": 30,
    "Teacher": 8
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBDA097",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MENTERI",
    "Student": 120,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBDA098",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KARMEN",
    "Student": 21,
    "Teacher": 8
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBDA099",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KEMAYAN",
    "Student": 95,
    "Teacher": 11
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CBK0026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SG PENJURING",
    "Student": 52,
    "Teacher": 10
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CBK1009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA TERLA",
    "Student": 111,
    "Teacher": 14
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CBK9073",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JERIK",
    "Student": 114,
    "Teacher": 20
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CBKA081",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEMAYAN",
    "Student": 303,
    "Teacher": 26
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CCA8001",
    "Name": "SEKOLAH RENDAH AGAMA ISLAHIAH",
    "Student": 268,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CCT7001",
    "Name": "SRA ISLAM AT TAKWA",
    "Student": 395,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEA0014",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BENTONG",
    "Student": 473,
    "Teacher": 53
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEA0015",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (LKTP) KG SERTIK",
    "Student": 492,
    "Teacher": 52
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEA0053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) LURAH BILUT",
    "Student": 273,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEA0055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PELANGAI",
    "Student": 195,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEA0056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BENTONG",
    "Student": 436,
    "Teacher": 60
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEA0057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT TINGGI",
    "Student": 238,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEA0058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA REPAS",
    "Student": 293,
    "Teacher": 42
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEA0059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KARAK SETIA",
    "Student": 186,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEA0060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) CHEMOMOI",
    "Student": 357,
    "Teacher": 35
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEA0061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI LAYANG",
    "Student": 84,
    "Teacher": 16
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CEA1001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG RAJA",
    "Student": 518,
    "Teacher": 53
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CEA1002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RINGLET",
    "Student": 589,
    "Teacher": 58
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2029",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PULAU TAWAR",
    "Student": 290,
    "Teacher": 37
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2030",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA TEMBELING",
    "Student": 92,
    "Teacher": 23
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG PIOL",
    "Student": 273,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2046",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG SAUJANA",
    "Student": 792,
    "Teacher": 85
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2047",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DAMAK",
    "Student": 434,
    "Teacher": 52
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2048",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEMIN",
    "Student": 585,
    "Teacher": 81
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2049",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOTA GELANGGI 2",
    "Student": 318,
    "Teacher": 42
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2050",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JUBLI PERAK SULTAN HAJI AHMAD SHAH",
    "Student": 232,
    "Teacher": 38
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2051",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN P. PENYELIDIKAN PERTANIAN TUN RAZAK",
    "Student": 575,
    "Teacher": 62
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RETANG",
    "Student": 207,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN INDERAPURA",
    "Student": 469,
    "Teacher": 52
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA2054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JENGKA 24",
    "Student": 98,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEA3034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ORANG KAYA HAJI",
    "Student": 366,
    "Teacher": 49
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEA3035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG TENGKU",
    "Student": 665,
    "Teacher": 66
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEA3036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BENTA",
    "Student": 308,
    "Teacher": 40
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEA3055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) SG KOYAN",
    "Student": 768,
    "Teacher": 71
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEA3056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MERAPOH",
    "Student": 365,
    "Teacher": 54
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEA3057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI LIPIS",
    "Student": 139,
    "Teacher": 54
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEA3058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KERAMBIT",
    "Student": 212,
    "Teacher": 33
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEA3059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA LANAR",
    "Student": 384,
    "Teacher": 44
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4022",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ABDUL RAHMAN TALIB",
    "Student": 806,
    "Teacher": 102
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALOR AKAR",
    "Student": 685,
    "Teacher": 69
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4024",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BESERAH",
    "Student": 1026,
    "Teacher": 97
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAYA BESAR",
    "Student": 846,
    "Teacher": 81
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT GOH",
    "Student": 1030,
    "Teacher": 81
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PANCHING",
    "Student": 494,
    "Teacher": 43
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJONG LUMPUR",
    "Student": 1077,
    "Teacher": 80
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TG PANGLIMA PERANG TG MUHAMMAD",
    "Student": 1281,
    "Teacher": 114
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4076",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LEPAR HILIR",
    "Student": 530,
    "Teacher": 45
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEA4077",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LEPAR UTARA",
    "Student": 292,
    "Teacher": 44
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4078",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANDAN",
    "Student": 883,
    "Teacher": 71
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TOK SERA",
    "Student": 455,
    "Teacher": 70
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4080",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN INDERA MAHKOTA 2",
    "Student": 864,
    "Teacher": 62
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4081",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT RANGIN",
    "Student": 812,
    "Teacher": 72
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4082",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUDANG RASAU",
    "Student": 768,
    "Teacher": 73
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4083",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PELABUHAN",
    "Student": 1072,
    "Teacher": 82
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4084",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEMAMBU",
    "Student": 618,
    "Teacher": 67
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4085",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI DAMAI",
    "Student": 864,
    "Teacher": 67
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4086",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTANAH HAJJAH KALSOM",
    "Student": 592,
    "Teacher": 60
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4087",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ISAP",
    "Student": 753,
    "Teacher": 65
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4088",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ASTANA",
    "Student": 201,
    "Teacher": 64
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4089",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI SOI",
    "Student": 979,
    "Teacher": 74
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4090",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CENDERAWASIH",
    "Student": 1276,
    "Teacher": 84
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4091",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI BAGING",
    "Student": 863,
    "Teacher": 67
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4092",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAT KILAU",
    "Student": 1275,
    "Teacher": 82
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELUK CHEMPEDAK",
    "Student": 377,
    "Teacher": 40
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4094",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PELINDUNG",
    "Student": 781,
    "Teacher": 62
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4095",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GAMBANG",
    "Student": 349,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4096",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG GARUDA",
    "Student": 419,
    "Teacher": 66
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4097",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT SAGU",
    "Student": 301,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4098",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ISAP MURNI",
    "Student": 616,
    "Teacher": 60
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4099",
    "Name": "SEKOLAH SUKAN MALAYSIA PAHANG",
    "Student": 344,
    "Teacher": 76
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEA4100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHENGAL LEMPONG",
    "Student": 724,
    "Teacher": 48
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' MAHMUD MAT",
    "Student": 827,
    "Teacher": 74
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU ABDULLAH",
    "Student": 934,
    "Teacher": 108
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PALOH HINAI",
    "Student": 549,
    "Teacher": 57
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (LKTP) CHINI",
    "Student": 428,
    "Teacher": 58
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NENASI",
    "Student": 614,
    "Teacher": 53
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5072",
    "Name": "SEKOLAH SULTAN HAJI AHMAD SHAH",
    "Student": 596,
    "Teacher": 63
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHINI TIMUR",
    "Student": 290,
    "Teacher": 36
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PEKAN",
    "Student": 551,
    "Teacher": 55
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN INDERA SHAHBANDAR",
    "Student": 438,
    "Teacher": 47
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5076",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEKAN",
    "Student": 576,
    "Teacher": 57
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5077",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN UBAI",
    "Student": 280,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5078",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LEPAR",
    "Student": 515,
    "Teacher": 42
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERAMU JAYA",
    "Student": 459,
    "Teacher": 47
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEA5080",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHINI 2",
    "Student": 405,
    "Teacher": 36
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEA6021",
    "Name": "SEKOLAH MENENGAH SAINS TENGKU ABDULLAH",
    "Student": 620,
    "Teacher": 56
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEA6022",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU KUDIN",
    "Student": 621,
    "Teacher": 77
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEA6023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DONG",
    "Student": 479,
    "Teacher": 60
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEA6024",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LKTP TERSANG",
    "Student": 331,
    "Teacher": 61
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEA6025",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI RAUB",
    "Student": 497,
    "Teacher": 58
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEA6026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GALI",
    "Student": 228,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEA6027",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEGA",
    "Student": 191,
    "Teacher": 23
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KERDAU",
    "Student": 524,
    "Teacher": 42
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7063",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEMERLOH",
    "Student": 748,
    "Teacher": 107
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7064",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA KRAU",
    "Student": 1142,
    "Teacher": 88
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK BAHAMAN",
    "Student": 405,
    "Teacher": 62
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7066",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI SEMANTAN",
    "Student": 717,
    "Teacher": 67
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7067",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI TUALANG",
    "Student": 599,
    "Teacher": 68
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7068",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT DAMAR",
    "Student": 354,
    "Teacher": 41
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEMERLOH JAYA",
    "Student": 623,
    "Teacher": 80
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7070",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAYA PULAI",
    "Student": 462,
    "Teacher": 49
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELUK SENTANG",
    "Student": 305,
    "Teacher": 39
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT CERMIN",
    "Student": 309,
    "Teacher": 41
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LANCHANG",
    "Student": 453,
    "Teacher": 51
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEA7160",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEBERANG TEMERLOH",
    "Student": 770,
    "Teacher": 60
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ROMPIN",
    "Student": 674,
    "Teacher": 84
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8018",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KERATONG",
    "Student": 581,
    "Teacher": 56
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8019",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJUNG GEMOK",
    "Student": 892,
    "Teacher": 82
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8020",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERWIRA JAYA",
    "Student": 246,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8021",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERANTAU DAMAI",
    "Student": 513,
    "Teacher": 54
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8022",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEKEK",
    "Student": 157,
    "Teacher": 24
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHANIS",
    "Student": 285,
    "Teacher": 40
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8024",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR TUN RAZAK",
    "Student": 575,
    "Teacher": 55
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8025",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI ROMPIN",
    "Student": 316,
    "Teacher": 53
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUADZAM JAYA",
    "Student": 979,
    "Teacher": 75
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8027",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SELANCAR",
    "Student": 218,
    "Teacher": 37
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8028",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PUTERI",
    "Student": 301,
    "Teacher": 39
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8029",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SELENDANG",
    "Student": 176,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8030",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PONTIAN JAYA",
    "Student": 265,
    "Teacher": 41
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ROMPIN PERMAI",
    "Student": 358,
    "Teacher": 33
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CEA8032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUADZAM SHAH",
    "Student": 547,
    "Teacher": 82
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MARAN",
    "Student": 592,
    "Teacher": 75
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9060",
    "Name": "SEKOLAH MENENGAH TENGKU AMPUAN AFZAN",
    "Student": 646,
    "Teacher": 71
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9114",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 2",
    "Student": 690,
    "Teacher": 90
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9116",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (LKTP) KAMPUNG AWAH",
    "Student": 265,
    "Teacher": 34
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9133",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 6",
    "Student": 338,
    "Teacher": 38
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9157",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JENGKA PUSAT",
    "Student": 682,
    "Teacher": 81
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9158",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 10",
    "Student": 232,
    "Teacher": 27
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9160",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 18",
    "Student": 467,
    "Teacher": 43
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9161",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 21",
    "Student": 186,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9162",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JENGKA PUSAT 2",
    "Student": 517,
    "Teacher": 47
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9163",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI JENGKA",
    "Student": 453,
    "Teacher": 58
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9164",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MARAN 2",
    "Student": 259,
    "Teacher": 31
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9165",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 16",
    "Student": 451,
    "Teacher": 56
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9166",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JENGKA 12",
    "Student": 392,
    "Teacher": 36
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9167",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI JAYA",
    "Student": 212,
    "Teacher": 25
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9168",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DESA JAYA",
    "Student": 306,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CEA9169",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CEDUNG JAYA",
    "Student": 299,
    "Teacher": 33
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CEAA061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BERA",
    "Student": 424,
    "Teacher": 43
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CEAA062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MENGKARAK",
    "Student": 719,
    "Teacher": 89
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CEAA115",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT MENDI",
    "Student": 379,
    "Teacher": 38
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CEAA156",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) PURUN",
    "Student": 547,
    "Teacher": 68
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CEAA159",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEMAYAN",
    "Student": 453,
    "Teacher": 49
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CEAA161",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR KERAYONG",
    "Student": 760,
    "Teacher": 61
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CEAA162",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEMBANGAU",
    "Student": 404,
    "Teacher": 47
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CEAA163",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BERA",
    "Student": 503,
    "Teacher": 51
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CEAA164",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TRIANG 3",
    "Student": 202,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEB0017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELEMONG",
    "Student": 128,
    "Teacher": 21
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEB0019",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KETARI",
    "Student": 702,
    "Teacher": 84
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEB0020",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KATHOLIK",
    "Student": 465,
    "Teacher": 41
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEB0021",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KHAI MUN",
    "Student": 608,
    "Teacher": 52
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD CAMERON HIGHLANDS",
    "Kod": "CEB1003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN AHMAD SHAH",
    "Student": 918,
    "Teacher": 94
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEB3038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SETIA WANGSA",
    "Student": 281,
    "Teacher": 36
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEB3039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA",
    "Student": 264,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEB4032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABU BAKAR",
    "Student": 1001,
    "Teacher": 108
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEB4033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST THOMAS",
    "Student": 180,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEB4034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) METHODIST",
    "Student": 447,
    "Teacher": 41
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEB4035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AIR PUTIH",
    "Student": 1573,
    "Teacher": 101
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEB4036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANAH PUTEH",
    "Student": 1814,
    "Teacher": 124
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEB4040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI MAHKOTA",
    "Student": 1066,
    "Teacher": 84
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEB4069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU AFZAN",
    "Student": 516,
    "Teacher": 60
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEB6026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAHMUD",
    "Student": 588,
    "Teacher": 97
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEB6027",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO SHAHBANDAR HUSSAIN",
    "Student": 366,
    "Teacher": 48
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEB6028",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) METHODIST",
    "Student": 191,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEB6029",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG CHING",
    "Student": 806,
    "Teacher": 71
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD RAUB",
    "Kod": "CEB6030",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RUAN",
    "Student": 478,
    "Teacher": 47
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEB7068",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ABU BAKAR",
    "Student": 1035,
    "Teacher": 95
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEB7069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BAHAGIA",
    "Student": 392,
    "Teacher": 39
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEB7071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HWA LIAN",
    "Student": 1364,
    "Teacher": 94
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BERA",
    "Kod": "CEBA072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TRIANG",
    "Student": 1336,
    "Teacher": 100
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEE0016",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KARAK",
    "Student": 831,
    "Teacher": 74
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CEE0018",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULAIMAN",
    "Student": 565,
    "Teacher": 79
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CEE2032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JERANTUT",
    "Student": 1194,
    "Teacher": 109
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEE3033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANGLIMA GARANG ABDUL SAMAD",
    "Student": 203,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CEE3037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CLIFFORD",
    "Student": 740,
    "Teacher": 86
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEE4039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI LEMBING",
    "Student": 445,
    "Teacher": 49
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CEE4070",
    "Name": "SEKOLAH MENENGAH SAINS SULTAN HAJI AHMAD SHAH",
    "Student": 695,
    "Teacher": 62
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CEE5062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN AHMAD",
    "Student": 533,
    "Teacher": 72
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CEE7070",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MENTAKAB",
    "Student": 739,
    "Teacher": 95
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CFT2001",
    "Name": "SMA KOTA GELANGGI 3",
    "Student": 85,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CFT2002",
    "Name": "SEKOLAH AGAMA MAAHAD TAHFIZ WA TA'LIMIL AL-QURAN",
    "Student": 86,
    "Teacher": 29
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CFT3001",
    "Name": "SMA AL-ITTIHADIAH",
    "Student": 129,
    "Teacher": 30
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CFT4001",
    "Name": "SEKOLAH MENENGAH AGAMA AL MAARIF",
    "Student": 352,
    "Teacher": 40
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CFT4002",
    "Name": "MAAHAD TAHFIZ DARUL ULUM AL-BASRIYAH",
    "Student": 144,
    "Teacher": 36
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CFT7001",
    "Name": "MADRASAH TARBIAH ISLAMIAH",
    "Student": 202,
    "Teacher": 32
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CFT8001",
    "Name": "SMA AL-HASANAH",
    "Student": 257,
    "Teacher": 28
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CFT9001",
    "Name": "SMA AL MAHMUDI",
    "Student": 279,
    "Teacher": 38
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CHA0002",
    "Name": "KOLEJ VOKASIONAL TENGKU AMPUAN AFZAN",
    "Student": 781,
    "Teacher": 104
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CHA3002",
    "Name": "KOLEJ VOKASIONAL SULTAN HAJI AHMAD SHAH AL-MUSTAIN BILLAH",
    "Student": 475,
    "Teacher": 97
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CHA4001",
    "Name": "KOLEJ VOKASIONAL KUANTAN",
    "Student": 781,
    "Teacher": 117
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CHA4002",
    "Name": "SEKOLAH MENENGAH PENDIDIKAN KHAS VOKASIONAL KUANTAN",
    "Student": 214,
    "Teacher": 94
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CHA7001",
    "Name": "KOLEJ VOKASIONAL PUTERI",
    "Student": 204,
    "Teacher": 47
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CHA7002",
    "Name": "KOLEJ VOKASIONAL TEMERLOH",
    "Student": 644,
    "Teacher": 104
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CHA8001",
    "Name": "KOLEJ VOKASIONAL SULTAN AHMAD SHAH",
    "Student": 575,
    "Teacher": 116
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CHA8002",
    "Name": "KOLEJ VOKASIONAL MUADZAM SHAH",
    "Student": 438,
    "Teacher": 65
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD MARAN",
    "Kod": "CHA9001",
    "Name": "KOLEJ VOKASIONAL (PERTANIAN) CHENOR",
    "Student": 549,
    "Teacher": 137
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CKE4037",
    "Name": "SEKOLAH MENENGAH TEKNIK KUANTAN",
    "Student": 598,
    "Teacher": 58
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD BENTONG",
    "Kod": "CRA0001",
    "Name": "SEKOLAH MENENGAH AGAMA PERSEKUTUAN BENTONG",
    "Student": 772,
    "Teacher": 137
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD JERANTUT",
    "Kod": "CRA2001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA TENGKU AMPUAN HAJJAH AFZAN PAHANG",
    "Student": 682,
    "Teacher": 69
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD LIPIS",
    "Kod": "CRA3001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA KUALA LIPIS",
    "Student": 546,
    "Teacher": 49
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD KUANTAN",
    "Kod": "CRA4001",
    "Name": "SEKOLAH BERASRAMA PENUH INTEGRASI KUANTAN",
    "Student": 563,
    "Teacher": 58
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD PEKAN",
    "Kod": "CRA5001",
    "Name": "SEKOLAH BERASRAMA PENUH INTEGRASI TUN ABDUL RAZAK",
    "Student": 500,
    "Teacher": 52
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD TEMERLOH",
    "Kod": "CRA7001",
    "Name": "SEKOLAH BERASRAMA PENUH INTEGRASI TEMERLOH",
    "Student": 573,
    "Teacher": 52
  },
  {
    "NationalState": "PAHANG",
    "PPD": "PPD ROMPIN",
    "Kod": "CRA8001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA PAHANG",
    "Student": 537,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0001",
    "Name": "SEKOLAH KEBANGSAAN ALOR BAKAT",
    "Student": 251,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0002",
    "Name": "SEKOLAH KEBANGSAAN BACHOK",
    "Student": 460,
    "Teacher": 48
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0003",
    "Name": "SEKOLAH KEBANGSAAN BADAK",
    "Student": 560,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0004",
    "Name": "SEKOLAH KEBANGSAAN BAKONG",
    "Student": 205,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0005",
    "Name": "SEKOLAH KEBANGSAAN BEKELAM",
    "Student": 209,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0006",
    "Name": "SEKOLAH KEBANGSAAN SRI KEMUNTING",
    "Student": 223,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0007",
    "Name": "SEKOLAH KEBANGSAAN BERIS KUBOR BESAR",
    "Student": 505,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0008",
    "Name": "SEKOLAH KEBANGSAAN BERIS PANCHOR",
    "Student": 698,
    "Teacher": 54
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0009",
    "Name": "SEKOLAH KEBANGSAAN BUKIT MARAK",
    "Student": 607,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0010",
    "Name": "SEKOLAH KEBANGSAAN CHANTUM",
    "Student": 228,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0011",
    "Name": "SEKOLAH KEBANGSAAN GUNONG",
    "Student": 622,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0012",
    "Name": "SEKOLAH KEBANGSAAN JELAWAT",
    "Student": 755,
    "Teacher": 60
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0013",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG CHAP",
    "Student": 363,
    "Teacher": 36
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0014",
    "Name": "SEKOLAH KEBANGSAAN KANDIS",
    "Student": 888,
    "Teacher": 69
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0015",
    "Name": "SEKOLAH KEBANGSAAN KETING",
    "Student": 243,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0016",
    "Name": "SEKOLAH KEBANGSAAN KOLAM",
    "Student": 217,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0017",
    "Name": "SEKOLAH KEBANGSAAN KUBANG GOLOK",
    "Student": 534,
    "Teacher": 44
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0018",
    "Name": "SEKOLAH KEBANGSAAN KUBANG TELAGA",
    "Student": 143,
    "Teacher": 19
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0019",
    "Name": "SEKOLAH KEBANGSAAN KUCHELONG",
    "Student": 240,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0020",
    "Name": "SEKOLAH KEBANGSAAN PAK BADOL",
    "Student": 487,
    "Teacher": 46
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0021",
    "Name": "SEKOLAH KEBANGSAAN PA' PURA",
    "Student": 558,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0022",
    "Name": "SEKOLAH KEBANGSAAN PANTAI SENAK",
    "Student": 635,
    "Teacher": 55
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0023",
    "Name": "SEKOLAH KEBANGSAAN PAUH SEMBILAN",
    "Student": 706,
    "Teacher": 66
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0024",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN CHENGAL",
    "Student": 312,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0025",
    "Name": "SEKOLAH KEBANGSAAN PERUPOK",
    "Student": 401,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0026",
    "Name": "SEKOLAH KEBANGSAAN SENENG",
    "Student": 445,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0027",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DUA",
    "Student": 317,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0028",
    "Name": "SEKOLAH KEBANGSAAN TANJONG JENERA",
    "Student": 182,
    "Teacher": 21
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0029",
    "Name": "SEKOLAH KEBANGSAAN TAWANG",
    "Student": 833,
    "Teacher": 70
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0030",
    "Name": "SEKOLAH KEBANGSAAN TELAGA ARA",
    "Student": 253,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0031",
    "Name": "SEKOLAH KEBANGSAAN BERIS LALANG",
    "Student": 586,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0032",
    "Name": "SEKOLAH KEBANGSAAN TANGOK",
    "Student": 719,
    "Teacher": 54
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBA0033",
    "Name": "SEKOLAH KEBANGSAAN JELAWAT 2",
    "Student": 384,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1040",
    "Name": "SEKOLAH KEBANGSAAN ABDUL HADI",
    "Student": 244,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1041",
    "Name": "SEKOLAH KEBANGSAAN KEM",
    "Student": 671,
    "Teacher": 57
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1042",
    "Name": "SEKOLAH KEBANGSAAN BANGGOL SAMAN",
    "Student": 496,
    "Teacher": 41
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1043",
    "Name": "SEKOLAH KEBANGSAAN BANGGU",
    "Student": 661,
    "Teacher": 51
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1044",
    "Name": "SEKOLAH KEBANGSAAN BECHAH KERANJI",
    "Student": 279,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1045",
    "Name": "SEKOLAH KEBANGSAAN BETA HULU",
    "Student": 284,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1046",
    "Name": "SEKOLAH KEBANGSAAN BULOH POH",
    "Student": 125,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1047",
    "Name": "SEKOLAH KEBANGSAAN BUNUT PAYONG",
    "Student": 354,
    "Teacher": 37
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1048",
    "Name": "SEKOLAH KEBANGSAAN CHE DERIS",
    "Student": 643,
    "Teacher": 54
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1049",
    "Name": "SEKOLAH KEBANGSAAN CHE LATIFF",
    "Student": 626,
    "Teacher": 49
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1050",
    "Name": "SEKOLAH KEBANGSAAN DEMIT",
    "Student": 616,
    "Teacher": 49
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1051",
    "Name": "SEKOLAH KEBANGSAAN DEWAN BETA",
    "Student": 595,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1052",
    "Name": "SEKOLAH KEBANGSAAN GONDANG",
    "Student": 124,
    "Teacher": 20
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1053",
    "Name": "SEKOLAH KEBANGSAAN GONG DERMIN",
    "Student": 821,
    "Teacher": 55
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1054",
    "Name": "SEKOLAH KEBANGSAAN KADOK",
    "Student": 526,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1055",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG CHENGAL",
    "Student": 435,
    "Teacher": 40
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1056",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG KELING",
    "Student": 399,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1057",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SIREH",
    "Student": 218,
    "Teacher": 40
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1058",
    "Name": "SEKOLAH KEBANGSAAN KEDAI BULOH 1",
    "Student": 810,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1059",
    "Name": "SEKOLAH KEBANGSAAN KOTA JEMBAL",
    "Student": 809,
    "Teacher": 58
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1060",
    "Name": "SEKOLAH KEBANGSAAN KEDAI PIAH",
    "Student": 410,
    "Teacher": 41
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1061",
    "Name": "SEKOLAH KEBANGSAAN KEMUMIN",
    "Student": 375,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1062",
    "Name": "SEKOLAH KEBANGSAAN KETEREH",
    "Student": 687,
    "Teacher": 55
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1064",
    "Name": "SEKOLAH KEBANGSAAN LONG GAFAR",
    "Student": 979,
    "Teacher": 71
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1065",
    "Name": "SEKOLAH KEBANGSAAN KUBANG KERIAN (1)",
    "Student": 1266,
    "Teacher": 101
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1066",
    "Name": "SEKOLAH KEBANGSAAN KUBANG KIAT",
    "Student": 329,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1067",
    "Name": "SEKOLAH KEBANGSAAN KOK LANAS",
    "Student": 1156,
    "Teacher": 87
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1068",
    "Name": "SEKOLAH KEBANGSAAN KOTA",
    "Student": 483,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1069",
    "Name": "SEKOLAH KEBANGSAAN LANGGAR",
    "Student": 495,
    "Teacher": 46
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1070",
    "Name": "SEKOLAH KEBANGSAAN LUNDANG",
    "Student": 321,
    "Teacher": 40
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1071",
    "Name": "SEKOLAH KEBANGSAAN MELOR",
    "Student": 364,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1072",
    "Name": "SEKOLAH KEBANGSAAN MENTERA",
    "Student": 256,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1073",
    "Name": "SEKOLAH KEBANGSAAN MENTUAN",
    "Student": 974,
    "Teacher": 69
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1074",
    "Name": "SEKOLAH KEBANGSAAN MERBAU",
    "Student": 329,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1075",
    "Name": "SEKOLAH KEBANGSAAN MULONG",
    "Student": 756,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1076",
    "Name": "SEKOLAH KEBANGSAAN PADANG GARONG 2",
    "Student": 387,
    "Teacher": 48
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1077",
    "Name": "SEKOLAH KEBANGSAAN RAJA ABDULLAH",
    "Student": 264,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1078",
    "Name": "SEKOLAH KEBANGSAAN PADANG KALA",
    "Student": 495,
    "Teacher": 58
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1079",
    "Name": "SEKOLAH KEBANGSAAN PADANG MOKAN",
    "Student": 521,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1080",
    "Name": "SEKOLAH KEBANGSAAN PALOH PINTU GANG",
    "Student": 591,
    "Teacher": 50
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1081",
    "Name": "SEKOLAH KEBANGSAAN PANGKAL KALONG",
    "Student": 688,
    "Teacher": 48
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1082",
    "Name": "SEKOLAH KEBANGSAAN PANJI",
    "Student": 533,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1083",
    "Name": "SEKOLAH KEBANGSAAN PARANG PUTTING",
    "Student": 487,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1084",
    "Name": "SEKOLAH KEBANGSAAN PASIR HOR",
    "Student": 500,
    "Teacher": 49
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1085",
    "Name": "SEKOLAH KEBANGSAAN PAUH LIMA",
    "Student": 436,
    "Teacher": 48
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1086",
    "Name": "SEKOLAH KEBANGSAAN SRI BEMBAN",
    "Student": 444,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1087",
    "Name": "SEKOLAH KEBANGSAAN PENAMBANG",
    "Student": 434,
    "Teacher": 41
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1088",
    "Name": "SEKOLAH KEBANGSAAN PENDEK",
    "Student": 275,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1089",
    "Name": "SEKOLAH KEBANGSAAN DATU' HASHIM",
    "Student": 1294,
    "Teacher": 96
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1090",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN KUBOR SALOR",
    "Student": 137,
    "Teacher": 21
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1091",
    "Name": "SEKOLAH KEBANGSAAN PERINGAT",
    "Student": 363,
    "Teacher": 41
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1092",
    "Name": "SEKOLAH KEBANGSAAN PEROL",
    "Student": 414,
    "Teacher": 42
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1093",
    "Name": "SEKOLAH KEBANGSAAN PULAU GAJAH",
    "Student": 167,
    "Teacher": 22
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1094",
    "Name": "SEKOLAH KEBANGSAAN PULAU KUNDOR",
    "Student": 245,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1095",
    "Name": "SEKOLAH KEBANGSAAN SERI CHEMPAKA",
    "Student": 817,
    "Teacher": 65
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1096",
    "Name": "SEKOLAH KEBANGSAAN SABAK",
    "Student": 357,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1097",
    "Name": "SEKOLAH KEBANGSAAN SALOR",
    "Student": 492,
    "Teacher": 44
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1098",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG PASIR MAS",
    "Student": 249,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1099",
    "Name": "SEKOLAH KEBANGSAAN SEMUT API",
    "Student": 303,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1100",
    "Name": "SEKOLAH KEBANGSAAN SERIBONG",
    "Student": 269,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1101",
    "Name": "SEKOLAH KEBANGSAAN SERING",
    "Student": 845,
    "Teacher": 60
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1102",
    "Name": "SEKOLAH KEBANGSAAN TAPANG",
    "Student": 701,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1103",
    "Name": "SEKOLAH KEBANGSAAN TEGAYONG",
    "Student": 244,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1104",
    "Name": "SEKOLAH KEBANGSAAN TIONG",
    "Student": 424,
    "Teacher": 36
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1105",
    "Name": "SEKOLAH KEBANGSAAN WAKAF STAN",
    "Student": 298,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1432",
    "Name": "SEKOLAH KEBANGSAAN CHICHA MENYABONG",
    "Student": 337,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1433",
    "Name": "SEKOLAH KEBANGSAAN KOR",
    "Student": 517,
    "Teacher": 42
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1434",
    "Name": "SEKOLAH KEBANGSAAN DESA PAHLAWAN",
    "Student": 739,
    "Teacher": 72
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1435",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN CHEPA",
    "Student": 720,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1436",
    "Name": "SEKOLAH KEBANGSAAN KUBANG KERIAN (2)",
    "Student": 724,
    "Teacher": 49
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1438",
    "Name": "SEKOLAH KEBANGSAAN PADANG GARONG 1",
    "Student": 785,
    "Teacher": 54
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1439",
    "Name": "SEKOLAH KEBANGSAAN DATO' HASHIM (2)",
    "Student": 1035,
    "Teacher": 68
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1440",
    "Name": "SEKOLAH KEBANGSAAN KEDAI BULOH (2)",
    "Student": 402,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1441",
    "Name": "SEKOLAH KEBANGSAAN ISMAIL PETRA (2)",
    "Student": 311,
    "Teacher": 35
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1442",
    "Name": "SEKOLAH KEBANGSAAN MULONG (2)",
    "Student": 584,
    "Teacher": 50
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1443",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ISMAIL (4)",
    "Student": 581,
    "Teacher": 44
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1444",
    "Name": "SEKOLAH KEBANGSAAN KUBANG KERIAN 3",
    "Student": 1573,
    "Teacher": 110
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1445",
    "Name": "SEKOLAH KEBANGSAAN TANJONG MAS",
    "Student": 555,
    "Teacher": 58
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBA1446",
    "Name": "SEKOLAH KEBANGSAAN DEMIT (2)",
    "Student": 561,
    "Teacher": 51
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2162",
    "Name": "SEKOLAH KEBANGSAAN AYER MERAH",
    "Student": 274,
    "Teacher": 34
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2163",
    "Name": "SEKOLAH KEBANGSAAN BANDAR",
    "Student": 153,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2164",
    "Name": "SEKOLAH KEBANGSAAN BELUKAR",
    "Student": 442,
    "Teacher": 40
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2165",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TIU",
    "Student": 237,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2166",
    "Name": "SEKOLAH KEBANGSAAN DEWAN BESAR",
    "Student": 347,
    "Teacher": 37
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2167",
    "Name": "SEKOLAH KEBANGSAAN JAMBU LAWAR",
    "Student": 315,
    "Teacher": 35
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2168",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG PEK",
    "Student": 589,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2169",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG KERILLA",
    "Student": 166,
    "Teacher": 23
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2170",
    "Name": "SEKOLAH KEBANGSAAN KELAWEH",
    "Student": 186,
    "Teacher": 26
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2171",
    "Name": "SEKOLAH KEBANGSAAN LABOK",
    "Student": 462,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2172",
    "Name": "SEKOLAH KEBANGSAAN MACHANG (1)",
    "Student": 682,
    "Teacher": 57
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2173",
    "Name": "SEKOLAH KEBANGSAAN MATA AYER",
    "Student": 119,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2174",
    "Name": "SEKOLAH KEBANGSAAN PAK ROMAN",
    "Student": 88,
    "Teacher": 17
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2175",
    "Name": "SEKOLAH KEBANGSAAN PALOH RAWA",
    "Student": 325,
    "Teacher": 33
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2176",
    "Name": "SEKOLAH KEBANGSAAN PANGKAL JENEREH",
    "Student": 272,
    "Teacher": 33
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2177",
    "Name": "SEKOLAH KEBANGSAAN PANGKAL MAK WAN",
    "Student": 181,
    "Teacher": 25
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2178",
    "Name": "SEKOLAH KEBANGSAAN PANGKAL MELERET",
    "Student": 361,
    "Teacher": 42
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2179",
    "Name": "SEKOLAH KEBANGSAAN PANGKAL NERING",
    "Student": 189,
    "Teacher": 25
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2180",
    "Name": "SEKOLAH KEBANGSAAN PULAI CHONDONG",
    "Student": 633,
    "Teacher": 61
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2181",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BAGAN",
    "Student": 101,
    "Teacher": 13
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2182",
    "Name": "SEKOLAH KEBANGSAAN TEMANGAN",
    "Student": 267,
    "Teacher": 32
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2183",
    "Name": "SEKOLAH KEBANGSAAN PANGKAL GONG",
    "Student": 215,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2184",
    "Name": "SEKOLAH KEBANGSAAN HAMZAH (2)",
    "Student": 611,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2185",
    "Name": "SEKOLAH KEBANGSAAN MACHANG (2)",
    "Student": 392,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBA2186",
    "Name": "SEKOLAH KEBANGSAAN TOK BOK",
    "Student": 490,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3193",
    "Name": "SEKOLAH KEBANGSAAN BAKONG",
    "Student": 154,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3194",
    "Name": "SEKOLAH KEBANGSAAN BANGGOL CHICHA",
    "Student": 386,
    "Teacher": 41
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3195",
    "Name": "SEKOLAH KEBANGSAAN BANGGOL PETAI",
    "Student": 80,
    "Teacher": 16
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3196",
    "Name": "SEKOLAH KEBANGSAAN BAROH PIAL",
    "Student": 197,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3197",
    "Name": "SEKOLAH KEBANGSAAN BAYU LALANG",
    "Student": 145,
    "Teacher": 20
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3198",
    "Name": "SEKOLAH KEBANGSAAN BECHAH DURIAN",
    "Student": 251,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3199",
    "Name": "SEKOLAH KEBANGSAAN BENDANG PAUH",
    "Student": 262,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3200",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PERAH",
    "Student": 197,
    "Teacher": 23
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3201",
    "Name": "SEKOLAH KEBANGSAAN BUNUT SUSU",
    "Student": 514,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3202",
    "Name": "SEKOLAH KEBANGSAAN CHABANG TIGA CHETOK",
    "Student": 88,
    "Teacher": 17
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3203",
    "Name": "SEKOLAH KEBANGSAAN CHETOK",
    "Student": 283,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3204",
    "Name": "SEKOLAH KEBANGSAAN CHICHA TINGGI",
    "Student": 198,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3205",
    "Name": "SEKOLAH KEBANGSAAN GELANG MAS",
    "Student": 337,
    "Teacher": 33
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3206",
    "Name": "SEKOLAH KEBANGSAAN GUAL PERIOK",
    "Student": 364,
    "Teacher": 36
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3207",
    "Name": "SEKOLAH KEBANGSAAN GUAL SITOK",
    "Student": 258,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3208",
    "Name": "SEKOLAH KEBANGSAAN GUAL TO' DEH",
    "Student": 177,
    "Teacher": 20
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3209",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BARU",
    "Student": 299,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3210",
    "Name": "SEKOLAH KEBANGSAAN KANGKONG",
    "Student": 343,
    "Teacher": 33
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3211",
    "Name": "SEKOLAH KEBANGSAAN KEDAI TANJONG",
    "Student": 629,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3212",
    "Name": "SEKOLAH KEBANGSAAN KEDONDONG",
    "Student": 290,
    "Teacher": 37
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3213",
    "Name": "SEKOLAH KEBANGSAAN KELAR",
    "Student": 227,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3214",
    "Name": "SEKOLAH KEBANGSAAN BECHAH KELUBI",
    "Student": 260,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3215",
    "Name": "SEKOLAH KEBANGSAAN KEPAS",
    "Student": 148,
    "Teacher": 22
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3216",
    "Name": "SEKOLAH KEBANGSAAN KOK PAUH",
    "Student": 200,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3217",
    "Name": "SEKOLAH KEBANGSAAN KUBANG CHENOK",
    "Student": 282,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3218",
    "Name": "SEKOLAH KEBANGSAAN KUBANG KUAU",
    "Student": 518,
    "Teacher": 43
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3219",
    "Name": "SEKOLAH KEBANGSAAN LATI",
    "Student": 832,
    "Teacher": 62
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3220",
    "Name": "SEKOLAH KEBANGSAAN LEMAL",
    "Student": 486,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3221",
    "Name": "SEKOLAH KEBANGSAAN LUBOK SETOL",
    "Student": 241,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3222",
    "Name": "SEKOLAH KEBANGSAAN MEKASAR",
    "Student": 530,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3223",
    "Name": "SEKOLAH KEBANGSAAN MERANTI",
    "Student": 676,
    "Teacher": 67
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3224",
    "Name": "SEKOLAH KEBANGSAAN PANGKAL KALA",
    "Student": 188,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3225",
    "Name": "SEKOLAH KEBANGSAAN DATO' ABDUL HAMID (1)",
    "Student": 307,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3226",
    "Name": "SEKOLAH KEBANGSAAN PENGGU",
    "Student": 415,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3227",
    "Name": "SEKOLAH KEBANGSAAN RANTAU PANJANG",
    "Student": 339,
    "Teacher": 42
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3228",
    "Name": "SEKOLAH KEBANGSAAN TANJONG BUNGA",
    "Student": 152,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3229",
    "Name": "SEKOLAH KEBANGSAAN TANJONG CHENAK",
    "Student": 209,
    "Teacher": 32
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3230",
    "Name": "SEKOLAH KEBANGSAAN OTHMAN TALIB (1)",
    "Student": 619,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3231",
    "Name": "SEKOLAH KEBANGSAAN SRI KIAMBANG",
    "Student": 1153,
    "Teacher": 89
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3232",
    "Name": "SEKOLAH KEBANGSAAN TOK SANGKUT",
    "Student": 403,
    "Teacher": 49
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3233",
    "Name": "SEKOLAH KEBANGSAAN TO' UBAN",
    "Student": 360,
    "Teacher": 35
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3235",
    "Name": "SEKOLAH KEBANGSAAN RAHMAT",
    "Student": 126,
    "Teacher": 16
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3236",
    "Name": "SEKOLAH KEBANGSAAN DATO' ABDUL HAMID (2)",
    "Student": 318,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3237",
    "Name": "SEKOLAH KEBANGSAAN OTHMAN TALIB (2)",
    "Student": 662,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3238",
    "Name": "SEKOLAH KEBANGSAAN SULTAN IBRAHIM (3)",
    "Student": 350,
    "Teacher": 40
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3239",
    "Name": "SEKOLAH KEBANGSAAN SULTAN IBRAHIM (4)",
    "Student": 292,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3240",
    "Name": "SEKOLAH KEBANGSAAN SRI RANTAU PANJANG (2)",
    "Student": 189,
    "Teacher": 23
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3437",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KELANTAN",
    "Student": 79,
    "Teacher": 37
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3438",
    "Name": "SEKOLAH KEBANGSAAN GUAL TINGGI",
    "Student": 694,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBA3439",
    "Name": "SEKOLAH KEBANGSAAN BUKIT JARUM",
    "Student": 236,
    "Teacher": 33
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4251",
    "Name": "SEKOLAH KEBANGSAAN BANGGOL PA' ESAH",
    "Student": 241,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4252",
    "Name": "SEKOLAH KEBANGSAAN BUKIT ABAL",
    "Student": 408,
    "Teacher": 42
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4253",
    "Name": "SEKOLAH KEBANGSAAN BUKIT AWANG",
    "Student": 146,
    "Teacher": 20
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4254",
    "Name": "SEKOLAH KEBANGSAAN BUKIT JAWA",
    "Student": 951,
    "Teacher": 76
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4255",
    "Name": "SEKOLAH KEBANGSAAN BUKIT MERBAU",
    "Student": 179,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4256",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TANAH",
    "Student": 120,
    "Teacher": 14
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4257",
    "Name": "SEKOLAH KEBANGSAAN CHANGGAI",
    "Student": 208,
    "Teacher": 24
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4258",
    "Name": "SEKOLAH KEBANGSAAN CHERANG RUKU",
    "Student": 758,
    "Teacher": 67
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4259",
    "Name": "SEKOLAH KEBANGSAAN DANAN",
    "Student": 201,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4260",
    "Name": "SEKOLAH KEBANGSAAN DALAM RU",
    "Student": 429,
    "Teacher": 43
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4261",
    "Name": "SEKOLAH KEBANGSAAN GAAL",
    "Student": 523,
    "Teacher": 50
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4262",
    "Name": "SEKOLAH KEBANGSAAN GONG GARU",
    "Student": 163,
    "Teacher": 23
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4263",
    "Name": "SEKOLAH KEBANGSAAN GONG MANAK",
    "Student": 118,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4264",
    "Name": "SEKOLAH KEBANGSAAN JELOR",
    "Student": 246,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4265",
    "Name": "SEKOLAH KEBANGSAAN JERAM",
    "Student": 346,
    "Teacher": 33
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4266",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BERANGAN",
    "Student": 276,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4267",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG KANDIS",
    "Student": 141,
    "Teacher": 21
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4268",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG RAJA",
    "Student": 218,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4269",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG TASEK",
    "Student": 118,
    "Teacher": 17
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4270",
    "Name": "SEKOLAH KEBANGSAAN KULIM",
    "Student": 294,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4271",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG NARA",
    "Student": 322,
    "Teacher": 35
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4272",
    "Name": "SEKOLAH KEBANGSAAN PADANG PAK AMAT",
    "Student": 650,
    "Teacher": 55
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4273",
    "Name": "SEKOLAH KEBANGSAAN PANGGONG",
    "Student": 433,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4274",
    "Name": "SEKOLAH KEBANGSAAN PASIR PUTEH (L)",
    "Student": 155,
    "Teacher": 19
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4275",
    "Name": "SEKOLAH KEBANGSAAN PASIR PUTEH (P)",
    "Student": 267,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4276",
    "Name": "SEKOLAH KEBANGSAAN SELIGI",
    "Student": 264,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4277",
    "Name": "SEKOLAH KEBANGSAAN KG SEPULAU",
    "Student": 213,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4278",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PETAI",
    "Student": 411,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4279",
    "Name": "SEKOLAH KEBANGSAAN TASEK PAUH",
    "Student": 212,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4280",
    "Name": "SEKOLAH KEBANGSAAN TELOSAN",
    "Student": 244,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4281",
    "Name": "SEKOLAH KEBANGSAAN TO'BALI",
    "Student": 602,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4282",
    "Name": "SEKOLAH KEBANGSAAN TUALANG TINGGI",
    "Student": 465,
    "Teacher": 48
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4283",
    "Name": "SEKOLAH KEBANGSAAN WAKAF RAJA",
    "Student": 379,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4284",
    "Name": "SEKOLAH KEBANGSAAN BUKIT JAWA (2)",
    "Student": 454,
    "Teacher": 41
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4285",
    "Name": "SEKOLAH KEBANGSAAN KAMIL (3)",
    "Student": 259,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBA4286",
    "Name": "SEKOLAH KEBANGSAAN SERI AMAN",
    "Student": 333,
    "Teacher": 33
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5290",
    "Name": "SEKOLAH KEBANGSAAN LADANG KERILLA",
    "Student": 91,
    "Teacher": 14
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5291",
    "Name": "SEKOLAH KEBANGSAAN ALOR PASIR",
    "Student": 281,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5293",
    "Name": "SEKOLAH KEBANGSAAN BATANG MERBAU",
    "Student": 501,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5294",
    "Name": "SEKOLAH KEBANGSAAN BATU GAJAH",
    "Student": 565,
    "Teacher": 57
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5296",
    "Name": "SEKOLAH KEBANGSAAN BECHAH LAUT",
    "Student": 188,
    "Teacher": 24
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5297",
    "Name": "SEKOLAH KEBANGSAAN BELIMBING",
    "Student": 574,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5298",
    "Name": "SEKOLAH KEBANGSAAN BENDANG NYIOR",
    "Student": 342,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5299",
    "Name": "SEKOLAH KEBANGSAAN BUKIT DURIAN",
    "Student": 217,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5300",
    "Name": "SEKOLAH KEBANGSAAN BUKIT GADING",
    "Student": 374,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5301",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PANAU",
    "Student": 691,
    "Teacher": 64
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5302",
    "Name": "SEKOLAH KEBANGSAAN NIK DAUD",
    "Student": 483,
    "Teacher": 43
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5304",
    "Name": "SEKOLAH KEBANGSAAN GUAL JEDOK",
    "Student": 364,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5305",
    "Name": "SEKOLAH KEBANGSAAN IPOH",
    "Student": 669,
    "Teacher": 65
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5308",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG PANJANG",
    "Student": 181,
    "Teacher": 20
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5309",
    "Name": "SEKOLAH KEBANGSAAN SRI KELEWEK",
    "Student": 555,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5310",
    "Name": "SEKOLAH KEBANGSAAN KUALA TIGA",
    "Student": 150,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5311",
    "Name": "SEKOLAH KEBANGSAAN KULIM",
    "Student": 264,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5312",
    "Name": "SEKOLAH KEBANGSAAN LALANG PEPUYU",
    "Student": 171,
    "Teacher": 24
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5313",
    "Name": "SEKOLAH KEBANGSAAN LAWANG",
    "Student": 336,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5316",
    "Name": "SEKOLAH KEBANGSAAN SOKOR",
    "Student": 146,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5317",
    "Name": "SEKOLAH KEBANGSAAN TANAH MERAH (1)",
    "Student": 739,
    "Teacher": 71
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5318",
    "Name": "SEKOLAH KEBANGSAAN TEBING TINGGI",
    "Student": 149,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5319",
    "Name": "SEKOLAH KEBANGSAAN TOK CHE DOL",
    "Student": 188,
    "Teacher": 25
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5320",
    "Name": "SEKOLAH KEBANGSAAN ULU KUSIAL",
    "Student": 167,
    "Teacher": 25
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5321",
    "Name": "SEKOLAH KEBANGSAAN KEMAHANG 1",
    "Student": 262,
    "Teacher": 26
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5322",
    "Name": "SEKOLAH KEBANGSAAN AYER CHANAL",
    "Student": 169,
    "Teacher": 22
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5323",
    "Name": "SEKOLAH KEBANGSAAN PERALLA",
    "Student": 214,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5324",
    "Name": "SEKOLAH KEBANGSAAN SRI SURIA (2)",
    "Student": 646,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5325",
    "Name": "SEKOLAH KEBANGSAAN KEMAHANG 3",
    "Student": 240,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5326",
    "Name": "SEKOLAH KEBANGSAAN KEMAHANG 2",
    "Student": 250,
    "Teacher": 36
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5327",
    "Name": "SEKOLAH KEBANGSAAN TANAH MERAH (2)",
    "Student": 613,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBA5328",
    "Name": "SEKOLAH KEBANGSAAN SRI SURIA (3)",
    "Student": 690,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6327",
    "Name": "SEKOLAH KEBANGSAAN BENDANG PA' YONG",
    "Student": 437,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6328",
    "Name": "SEKOLAH KEBANGSAAN BERANGAN (1)",
    "Student": 781,
    "Teacher": 57
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6329",
    "Name": "SEKOLAH KEBANGSAAN BUNOHAN",
    "Student": 535,
    "Teacher": 48
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6330",
    "Name": "SEKOLAH KEBANGSAAN CHABANG EMPAT",
    "Student": 636,
    "Teacher": 67
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6331",
    "Name": "SEKOLAH KEBANGSAAN CHENDERONG BATU",
    "Student": 513,
    "Teacher": 44
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6332",
    "Name": "SEKOLAH KEBANGSAAN GETING (1)",
    "Student": 787,
    "Teacher": 74
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6333",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG DELIMA",
    "Student": 358,
    "Teacher": 33
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6334",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG LAUT",
    "Student": 499,
    "Teacher": 54
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6335",
    "Name": "SEKOLAH KEBANGSAAN KEBAKAT JAYA",
    "Student": 517,
    "Teacher": 42
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6336",
    "Name": "SEKOLAH KEBANGSAAN KOK KELI",
    "Student": 315,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6337",
    "Name": "SEKOLAH KEBANGSAAN KOK PASIR",
    "Student": 426,
    "Teacher": 36
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6338",
    "Name": "SEKOLAH KEBANGSAAN KUBANG BATANG",
    "Student": 618,
    "Teacher": 56
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6339",
    "Name": "SEKOLAH KEBANGSAAN KUTAN",
    "Student": 528,
    "Teacher": 49
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6340",
    "Name": "SEKOLAH KEBANGSAAN MORAK",
    "Student": 558,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6341",
    "Name": "SEKOLAH KEBANGSAAN PADANG MANDOL",
    "Student": 300,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6342",
    "Name": "SEKOLAH KEBANGSAAN PADANG POHON TANJUNG",
    "Student": 565,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6343",
    "Name": "SEKOLAH KEBANGSAAN PALEKBANG",
    "Student": 267,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6344",
    "Name": "SEKOLAH KEBANGSAAN PASIR PEKAN",
    "Student": 798,
    "Teacher": 61
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6345",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN KUBOR (1)",
    "Student": 716,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6346",
    "Name": "SEKOLAH KEBANGSAAN PULAU BELURU",
    "Student": 257,
    "Teacher": 35
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6347",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PINANG",
    "Student": 390,
    "Teacher": 41
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6348",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TAPANG",
    "Student": 383,
    "Teacher": 36
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6349",
    "Name": "SEKOLAH KEBANGSAAN TELUK JERING",
    "Student": 346,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6350",
    "Name": "SEKOLAH KEBANGSAAN KELABORAN",
    "Student": 456,
    "Teacher": 43
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6351",
    "Name": "SEKOLAH KEBANGSAAN TUMPAT",
    "Student": 351,
    "Teacher": 42
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6352",
    "Name": "SEKOLAH KEBANGSAAN WAKAF BHARU",
    "Student": 709,
    "Teacher": 55
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6353",
    "Name": "SEKOLAH KEBANGSAAN SIMPANGAN",
    "Student": 240,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6354",
    "Name": "SEKOLAH KEBANGSAAN SRI NETING",
    "Student": 629,
    "Teacher": 50
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6355",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN KUBOR (2)",
    "Student": 370,
    "Teacher": 42
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6356",
    "Name": "SEKOLAH KEBANGSAAN BERANGAN (2)",
    "Student": 269,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBA6357",
    "Name": "SEKOLAH KEBANGSAAN SRI TUMPAT (2)",
    "Student": 394,
    "Teacher": 32
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7361",
    "Name": "SEKOLAH KEBANGSAAN LADANG TAKU",
    "Student": 49,
    "Teacher": 13
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7362",
    "Name": "SEKOLAH KEBANGSAAN SLOW TEMIANG",
    "Student": 141,
    "Teacher": 21
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7363",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SOK",
    "Student": 204,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7364",
    "Name": "SEKOLAH KEBANGSAAN LELA JASA",
    "Student": 237,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7365",
    "Name": "SEKOLAH KEBANGSAAN BANGGOL GUCHIL",
    "Student": 776,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7366",
    "Name": "SEKOLAH KEBANGSAAN BATU JONG",
    "Student": 257,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7367",
    "Name": "SEKOLAH KEBANGSAAN BATU MENGKEBANG",
    "Student": 563,
    "Teacher": 49
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7368",
    "Name": "SEKOLAH KEBANGSAAN LATA REK",
    "Student": 378,
    "Teacher": 43
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7369",
    "Name": "SEKOLAH KEBANGSAAN MANEK URAI BARU",
    "Student": 410,
    "Teacher": 44
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7370",
    "Name": "SEKOLAH KEBANGSAAN CHENULANG",
    "Student": 423,
    "Teacher": 42
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7371",
    "Name": "SEKOLAH KEBANGSAAN DABONG",
    "Student": 288,
    "Teacher": 36
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7372",
    "Name": "SEKOLAH KEBANGSAAN PASIR KELANG",
    "Student": 140,
    "Teacher": 22
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7375",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BEDAL",
    "Student": 387,
    "Teacher": 40
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7376",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG KARANGAN",
    "Student": 105,
    "Teacher": 17
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7378",
    "Name": "SEKOLAH KEBANGSAAN KEMUBU",
    "Student": 141,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7381",
    "Name": "SEKOLAH KEBANGSAAN KUALA GERIS",
    "Student": 250,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7382",
    "Name": "SEKOLAH KEBANGSAAN KUALA KRAI",
    "Student": 307,
    "Teacher": 37
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7383",
    "Name": "SEKOLAH KEBANGSAAN KUALA NAL",
    "Student": 103,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7386",
    "Name": "SEKOLAH KEBANGSAAN LALOH",
    "Student": 371,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7390",
    "Name": "SEKOLAH KEBANGSAAN MANEK URAI",
    "Student": 286,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7391",
    "Name": "SEKOLAH KEBANGSAAN PAHI",
    "Student": 441,
    "Teacher": 51
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7392",
    "Name": "SEKOLAH KEBANGSAAN PASIR GAJAH",
    "Student": 304,
    "Teacher": 37
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7393",
    "Name": "SEKOLAH KEBANGSAAN PEMBERIAN",
    "Student": 123,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7394",
    "Name": "SEKOLAH KEBANGSAAN PERIA",
    "Student": 261,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7397",
    "Name": "SEKOLAH KEBANGSAAN TELKONG",
    "Student": 355,
    "Teacher": 37
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7398",
    "Name": "SEKOLAH KEBANGSAAN TEMALIR",
    "Student": 299,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7414",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG TENGAH",
    "Student": 431,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7426",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI EMBAK",
    "Student": 436,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7427",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PAS",
    "Student": 316,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7428",
    "Name": "SEKOLAH KEBANGSAAN CHUCHOH PUTERI",
    "Student": 361,
    "Teacher": 35
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7429",
    "Name": "SEKOLAH KEBANGSAAN BAHAGIA",
    "Student": 112,
    "Teacher": 21
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7430",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI MENGKUANG",
    "Student": 154,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7431",
    "Name": "SEKOLAH KEBANGSAAN SG SAM",
    "Student": 212,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7432",
    "Name": "SEKOLAH KEBANGSAAN MEMPELAM JELAWANG",
    "Student": 337,
    "Teacher": 33
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7433",
    "Name": "SEKOLAH KEBANGSAAN SULTAN YAHYA PETRA (2)",
    "Student": 388,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7434",
    "Name": "SEKOLAH KEBANGSAAN BIAK",
    "Student": 89,
    "Teacher": 13
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7435",
    "Name": "SEKOLAH KEBANGSAAN SERI MAHLIGAI",
    "Student": 185,
    "Teacher": 19
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBA7436",
    "Name": "SEKOLAH KEBANGSAAN BANDAR KUALA KRAI",
    "Student": 556,
    "Teacher": 40
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8001",
    "Name": "SEKOLAH KEBANGSAAN BERTAM",
    "Student": 186,
    "Teacher": 26
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8002",
    "Name": "SEKOLAH KEBANGSAAN GUA MUSANG",
    "Student": 1007,
    "Teacher": 74
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8003",
    "Name": "SEKOLAH KEBANGSAAN JERAM TEKOH",
    "Student": 555,
    "Teacher": 41
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8004",
    "Name": "SEKOLAH KEBANGSAAN LIMAU KASTURI 1",
    "Student": 268,
    "Teacher": 32
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8005",
    "Name": "SEKOLAH KEBANGSAAN PALOH 1 & 2",
    "Student": 500,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8006",
    "Name": "SEKOLAH KEBANGSAAN RENOK BARU",
    "Student": 547,
    "Teacher": 46
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8007",
    "Name": "SEKOLAH KEBANGSAAN STAR",
    "Student": 161,
    "Teacher": 19
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8008",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TERAH",
    "Student": 263,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8009",
    "Name": "SEKOLAH KEBANGSAAN CHALIL",
    "Student": 139,
    "Teacher": 19
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8010",
    "Name": "SEKOLAH KEBANGSAAN JEREK",
    "Student": 386,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8011",
    "Name": "SEKOLAH KEBANGSAAN LEBIR",
    "Student": 142,
    "Teacher": 17
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8012",
    "Name": "SEKOLAH KEBANGSAAN PALOH TIGA",
    "Student": 261,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8013",
    "Name": "SEKOLAH KEBANGSAAN PASIR TUMBUH",
    "Student": 47,
    "Teacher": 12
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8014",
    "Name": "SEKOLAH KEBANGSAAN SRI CHIKU",
    "Student": 424,
    "Teacher": 36
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8016",
    "Name": "SEKOLAH KEBANGSAAN PERASU",
    "Student": 195,
    "Teacher": 23
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8017",
    "Name": "SEKOLAH KEBANGSAAN CIKU TIGA",
    "Student": 748,
    "Teacher": 63
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8018",
    "Name": "SEKOLAH KEBANGSAAN ARING",
    "Student": 548,
    "Teacher": 41
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8019",
    "Name": "SEKOLAH KEBANGSAAN KUALA SUNGAI",
    "Student": 88,
    "Teacher": 14
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8020",
    "Name": "SEKOLAH KEBANGSAAN CHIKU 7",
    "Student": 174,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8021",
    "Name": "SEKOLAH KEBANGSAAN LIMAU KASTURI 2",
    "Student": 109,
    "Teacher": 17
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8022",
    "Name": "SEKOLAH KEBANGSAAN SRI WANGI",
    "Student": 978,
    "Teacher": 77
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8023",
    "Name": "SEKOLAH KEBANGSAAN SRI CHIKU (2)",
    "Student": 304,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8024",
    "Name": "SEKOLAH KEBANGSAAN TENGKU MUHAMMAD FAKHRY PETRA",
    "Student": 637,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8026",
    "Name": "SEKOLAH KEBANGSAAN TOHOI",
    "Student": 235,
    "Teacher": 21
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8027",
    "Name": "SEKOLAH KEBANGSAAN PULAT",
    "Student": 54,
    "Teacher": 13
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8028",
    "Name": "SEKOLAH KEBANGSAAN SRI PERMAI (JHEOA)",
    "Student": 191,
    "Teacher": 26
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8029",
    "Name": "SEKOLAH KEBANGSAAN KUALA LAH",
    "Student": 100,
    "Teacher": 13
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8033",
    "Name": "SEKOLAH KEBANGSAAN BIHAI",
    "Student": 185,
    "Teacher": 16
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8034",
    "Name": "SEKOLAH KEBANGSAAN MERANTO",
    "Student": 124,
    "Teacher": 16
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8035",
    "Name": "SEKOLAH KEBANGSAAN LEPAN JAYA",
    "Student": 181,
    "Teacher": 19
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8036",
    "Name": "SEKOLAH KEBANGSAAN SRI WANGI (2)",
    "Student": 901,
    "Teacher": 60
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBA8037",
    "Name": "SEKOLAH KEBANGSAAN PASIR LINGGI",
    "Student": 89,
    "Teacher": 14
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9001",
    "Name": "SEKOLAH KEBANGSAAN AYER LANAS",
    "Student": 479,
    "Teacher": 50
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9002",
    "Name": "SEKOLAH KEBANGSAAN BATU MELINTANG",
    "Student": 460,
    "Teacher": 48
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9003",
    "Name": "SEKOLAH KEBANGSAAN BUKIT JERING",
    "Student": 426,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9004",
    "Name": "SEKOLAH KEBANGSAAN GEMANG",
    "Student": 459,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9005",
    "Name": "SEKOLAH KEBANGSAAN JELI (1)",
    "Student": 807,
    "Teacher": 63
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9006",
    "Name": "SEKOLAH KEBANGSAAN KALAI",
    "Student": 204,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9007",
    "Name": "SEKOLAH KEBANGSAAN KUALA BALAH",
    "Student": 414,
    "Teacher": 42
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9008",
    "Name": "SEKOLAH KEBANGSAAN KUBOR DATU",
    "Student": 161,
    "Teacher": 20
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9009",
    "Name": "SEKOLAH KEBANGSAAN LEGEH",
    "Student": 385,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9010",
    "Name": "SEKOLAH KEBANGSAAN LUBOK BONGOR",
    "Student": 196,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9011",
    "Name": "SEKOLAH KEBANGSAAN PENDOK",
    "Student": 164,
    "Teacher": 20
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9012",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LONG",
    "Student": 177,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9013",
    "Name": "SEKOLAH KEBANGSAAN JELI (2)",
    "Student": 544,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9014",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RUAL",
    "Student": 104,
    "Teacher": 20
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DBA9015",
    "Name": "SEKOLAH KEBANGSAAN PASIR DUSUN",
    "Student": 206,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBAA001",
    "Name": "SEKOLAH KEBANGSAAN KUALA BETIS",
    "Student": 774,
    "Teacher": 56
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBAA002",
    "Name": "SEKOLAH KEBANGSAAN BALAR",
    "Student": 288,
    "Teacher": 24
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBAA003",
    "Name": "SEKOLAH KEBANGSAAN HENDROP",
    "Student": 293,
    "Teacher": 21
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBAA004",
    "Name": "SEKOLAH KEBANGSAAN POS BROOKE",
    "Student": 295,
    "Teacher": 32
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBAA005",
    "Name": "SEKOLAH KEBANGSAAN BLAU",
    "Student": 130,
    "Teacher": 14
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DBB0031",
    "Name": "SEKOLAH KEBANGSAAN SERI KEMUDI",
    "Student": 852,
    "Teacher": 68
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1106",
    "Name": "SEKOLAH KEBANGSAAN ISLAH",
    "Student": 1320,
    "Teacher": 91
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1107",
    "Name": "SEKOLAH KEBANGSAAN ISMAIL PETRA (1)",
    "Student": 559,
    "Teacher": 46
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1108",
    "Name": "SEKOLAH KEBANGSAAN RAJA BAHAR",
    "Student": 542,
    "Teacher": 51
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1109",
    "Name": "SEKOLAH KEBANGSAAN SERI KETEREH",
    "Student": 1010,
    "Teacher": 81
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1110",
    "Name": "SEKOLAH KEBANGSAAN SERI KOTA",
    "Student": 722,
    "Teacher": 65
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1111",
    "Name": "SEKOLAH KEBANGSAAN SRI MELOR",
    "Student": 841,
    "Teacher": 66
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1112",
    "Name": "SEKOLAH KEBANGSAAN TENGKU INDERA PETRA",
    "Student": 404,
    "Teacher": 50
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1114",
    "Name": "SEKOLAH KEBANGSAAN REDANG",
    "Student": 366,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1115",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ISMAIL (1)",
    "Student": 583,
    "Teacher": 48
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1116",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ISMAIL (2)",
    "Student": 372,
    "Teacher": 33
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1117",
    "Name": "SEKOLAH KEBANGSAAN ZAINAB (1)",
    "Student": 812,
    "Teacher": 56
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1118",
    "Name": "SEKOLAH KEBANGSAAN ZAINAB 2",
    "Student": 631,
    "Teacher": 50
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBB1412",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ISMAIL (3)",
    "Student": 369,
    "Teacher": 41
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBB2183",
    "Name": "SEKOLAH KEBANGSAAN HAMZAH (1)",
    "Student": 889,
    "Teacher": 80
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBB3234",
    "Name": "SEKOLAH KEBANGSAAN SRI RANTAU PANJANG (1)",
    "Student": 474,
    "Teacher": 54
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBB3235",
    "Name": "SEKOLAH KEBANGSAAN SULTAN IBRAHIM (1)",
    "Student": 1107,
    "Teacher": 79
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBB3236",
    "Name": "SEKOLAH KEBANGSAAN SULTAN IBRAHIM (2)",
    "Student": 620,
    "Teacher": 68
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBB4284",
    "Name": "SEKOLAH KEBANGSAAN KAMIL (1)",
    "Student": 891,
    "Teacher": 69
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBB4413",
    "Name": "SEKOLAH KEBANGSAAN KAMIL (2)",
    "Student": 819,
    "Teacher": 75
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBB5321",
    "Name": "SEKOLAH KEBANGSAAN SRI SURIA (1)",
    "Student": 1027,
    "Teacher": 70
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBB6353",
    "Name": "SEKOLAH KEBANGSAAN SERI KAMPONG LAUT",
    "Student": 225,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBB6354",
    "Name": "SEKOLAH KEBANGSAAN TUMPAT (1)",
    "Student": 710,
    "Teacher": 66
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBB6355",
    "Name": "SEKOLAH KEBANGSAAN SERI WAKAF BAHARU",
    "Student": 823,
    "Teacher": 66
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBB7399",
    "Name": "SEKOLAH KEBANGSAAN SULTAN YAHYA PETRA(1)",
    "Student": 612,
    "Teacher": 62
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBC1119",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHENG",
    "Student": 698,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBC1120",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 1168,
    "Teacher": 65
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DBC1121",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEIR CHIH",
    "Student": 520,
    "Teacher": 46
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBC2184",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 72,
    "Teacher": 12
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBC2185",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA",
    "Student": 123,
    "Teacher": 18
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DBC2186",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POEY SIT",
    "Student": 213,
    "Teacher": 24
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBC3237",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAY BOON",
    "Student": 162,
    "Teacher": 22
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBC3238",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOK ENG",
    "Student": 311,
    "Teacher": 26
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DBC3239",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POY HWA",
    "Student": 85,
    "Teacher": 13
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DBC4285",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAI CHIH",
    "Student": 194,
    "Teacher": 20
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DBC5322",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHENG",
    "Student": 507,
    "Teacher": 39
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DBC6356",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK TZE",
    "Student": 455,
    "Teacher": 40
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBC7401",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHAI",
    "Student": 256,
    "Teacher": 26
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBC8001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) GUA MUSANG",
    "Student": 425,
    "Teacher": 36
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DBC8002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG PULAI",
    "Student": 113,
    "Teacher": 12
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DBD7404",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PASIR GAJAH",
    "Student": 36,
    "Teacher": 7
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DCT1001",
    "Name": "SEKOLAH RENDAH AGAMA TENGKU AMALIN A'ISHAH PUTRI",
    "Student": 370,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BERIS PANCHOR",
    "Student": 1110,
    "Teacher": 92
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NIPAH",
    "Student": 439,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BADAK",
    "Student": 822,
    "Teacher": 87
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LONG YUNUS",
    "Student": 975,
    "Teacher": 103
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTERI SAADONG",
    "Student": 862,
    "Teacher": 68
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JELAWAT",
    "Student": 836,
    "Teacher": 76
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAK BADOL",
    "Student": 711,
    "Teacher": 65
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI GUNUNG",
    "Student": 875,
    "Teacher": 99
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KANDIS",
    "Student": 818,
    "Teacher": 64
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' PERDANA",
    "Student": 659,
    "Teacher": 72
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUBANG TELAGA",
    "Student": 444,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TERATAK PULAI",
    "Student": 344,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUBANG GOLOK",
    "Student": 398,
    "Teacher": 34
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEA0044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BERIS PANCHOR 2",
    "Student": 650,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1122",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEDAI BULOH",
    "Student": 1130,
    "Teacher": 93
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1123",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ISMAIL PETRA",
    "Student": 843,
    "Teacher": 104
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1124",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KADOK",
    "Student": 527,
    "Teacher": 49
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1125",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA SAKTI",
    "Student": 783,
    "Teacher": 85
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1126",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' AHMAD MAHER",
    "Student": 961,
    "Teacher": 94
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1128",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LONG GAFAR",
    "Student": 785,
    "Teacher": 76
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1129",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUBANG KERIAN",
    "Student": 1183,
    "Teacher": 114
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1131",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MULONG",
    "Student": 1075,
    "Teacher": 111
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1132",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANGKAL KALONG",
    "Student": 583,
    "Teacher": 46
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1134",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTERA",
    "Student": 439,
    "Teacher": 57
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1135",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTERI",
    "Student": 521,
    "Teacher": 72
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1136",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SALOR",
    "Student": 491,
    "Teacher": 62
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1137",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANJI",
    "Student": 611,
    "Teacher": 78
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1138",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DEWAN BETA",
    "Student": 598,
    "Teacher": 62
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1139",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PINTU GENG",
    "Student": 497,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1140",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEMUMIN",
    "Student": 505,
    "Teacher": 50
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1141",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUBANG KERIAN 2",
    "Student": 759,
    "Teacher": 82
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1142",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOK LANAS",
    "Student": 940,
    "Teacher": 77
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1143",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJUNG MAS",
    "Student": 632,
    "Teacher": 70
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1144",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ZAINAB 2",
    "Student": 501,
    "Teacher": 67
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1145",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENAMBANG",
    "Student": 499,
    "Teacher": 50
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1146",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN CHEPA 2",
    "Student": 941,
    "Teacher": 83
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1147",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERING",
    "Student": 539,
    "Teacher": 51
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1148",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KG CHENGAL",
    "Student": 707,
    "Teacher": 72
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1149",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJONG CHAT",
    "Student": 542,
    "Teacher": 54
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1150",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG KALA",
    "Student": 450,
    "Teacher": 50
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1151",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG ENGGANG",
    "Student": 485,
    "Teacher": 51
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1152",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BADANG",
    "Student": 796,
    "Teacher": 64
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1153",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUBANG KERIAN 3",
    "Student": 625,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1154",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LONG GHAFAR 2",
    "Student": 614,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEA1155",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANCHOR PERDANA",
    "Student": 506,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DEA2187",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANGKAL MELERET",
    "Student": 503,
    "Teacher": 56
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DEA2188",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI INTAN",
    "Student": 728,
    "Teacher": 80
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DEA2189",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MACHANG",
    "Student": 1230,
    "Teacher": 128
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DEA2190",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ABDUL SAMAD",
    "Student": 654,
    "Teacher": 85
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DEA2191",
    "Name": "SEKOLAH MENENGAH SAINS MACHANG",
    "Student": 578,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DEA2192",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEMANGAN",
    "Student": 287,
    "Teacher": 37
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DEA2193",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HAMZAH (2)",
    "Student": 810,
    "Teacher": 65
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DEA2194",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR MACHANG",
    "Student": 344,
    "Teacher": 43
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3238",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHETOK",
    "Student": 580,
    "Teacher": 66
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3239",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUNUT SUSU",
    "Student": 612,
    "Teacher": 55
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3240",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KANGKONG",
    "Student": 553,
    "Teacher": 82
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3241",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU PANGLIMA RAJA",
    "Student": 915,
    "Teacher": 100
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3242",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MERANTI",
    "Student": 757,
    "Teacher": 73
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3243",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PASIR MAS",
    "Student": 579,
    "Teacher": 86
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3245",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENDONG",
    "Student": 763,
    "Teacher": 89
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3246",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TO' UBAN",
    "Student": 645,
    "Teacher": 67
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3422",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI KIAMBANG",
    "Student": 822,
    "Teacher": 80
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3425",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN IBRAHIM (2)",
    "Student": 763,
    "Teacher": 90
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3426",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUAL PERIOK",
    "Student": 408,
    "Teacher": 48
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3427",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEDONDONG",
    "Student": 391,
    "Teacher": 46
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3428",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUBANG BEMBAN",
    "Student": 811,
    "Teacher": 80
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3429",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAROH PIAL",
    "Student": 546,
    "Teacher": 60
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3430",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG DANGAR",
    "Student": 383,
    "Teacher": 43
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEA3431",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJONG BUNGA",
    "Student": 391,
    "Teacher": 43
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4286",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT JAWA",
    "Student": 939,
    "Teacher": 119
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4287",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TOK JANGGUT",
    "Student": 854,
    "Teacher": 88
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4288",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (AGAMA) DATO' ISMAIL",
    "Student": 574,
    "Teacher": 56
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4291",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GAAL",
    "Student": 563,
    "Teacher": 72
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4292",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JERAM",
    "Student": 484,
    "Teacher": 67
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4293",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI MAHARAJA",
    "Student": 463,
    "Teacher": 48
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4294",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHERANG RUKU",
    "Student": 1229,
    "Teacher": 133
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4295",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG PAK AMAT",
    "Student": 507,
    "Teacher": 60
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4296",
    "Name": "SEKOLAH MENENGAH SAINS PASIR PUTEH",
    "Student": 574,
    "Teacher": 65
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4297",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI AMAN",
    "Student": 936,
    "Teacher": 100
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4298",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SELISING",
    "Student": 477,
    "Teacher": 43
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEA4299",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PETAI",
    "Student": 301,
    "Teacher": 31
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DEA5325",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN IPOH",
    "Student": 828,
    "Teacher": 75
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DEA5416",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAN SRI MOHAMED YAACOB",
    "Student": 980,
    "Teacher": 114
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DEA5417",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANAH MERAH (1)",
    "Student": 984,
    "Teacher": 114
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DEA5418",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LADANG KERILLA",
    "Student": 371,
    "Teacher": 37
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DEA5419",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALOR PASIR",
    "Student": 733,
    "Teacher": 68
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DEA5420",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEMAHANG",
    "Student": 325,
    "Teacher": 53
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DEA5421",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANAH MERAH (2)",
    "Student": 784,
    "Teacher": 108
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DEA5422",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT BUNGA",
    "Student": 441,
    "Teacher": 49
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DEA5423",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' MAHMUD PADUKA RAJA (2)",
    "Student": 937,
    "Teacher": 85
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DEA5424",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BELIMBING",
    "Student": 558,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DEA6357",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHABANG EMPAT",
    "Student": 1234,
    "Teacher": 131
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DEA6358",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO BIJI WANGSA",
    "Student": 889,
    "Teacher": 91
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DEA6359",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPONG LAUT",
    "Student": 953,
    "Teacher": 100
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DEA6360",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAHMUD MAHYIDIN",
    "Student": 809,
    "Teacher": 93
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DEA6361",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PINANG",
    "Student": 525,
    "Teacher": 57
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DEA6362",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN WAKAF BHARU",
    "Student": 1485,
    "Teacher": 137
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DEA6363",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GETING",
    "Student": 1125,
    "Teacher": 93
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DEA6364",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BERANGAN",
    "Student": 693,
    "Teacher": 66
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DEA6365",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUTAN",
    "Student": 321,
    "Teacher": 37
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DEA7405",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEROH",
    "Student": 745,
    "Teacher": 77
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DEA7407",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA KRAI",
    "Student": 859,
    "Teacher": 105
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DEA7408",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MANEK URAI",
    "Student": 504,
    "Teacher": 48
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DEA7418",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DABONG",
    "Student": 833,
    "Teacher": 83
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DEA7419",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAHI",
    "Student": 525,
    "Teacher": 75
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DEA7420",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LALOH",
    "Student": 836,
    "Teacher": 83
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DEA7421",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MENGKEBANG",
    "Student": 677,
    "Teacher": 72
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DEA7422",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN YAHYA PETRA 2",
    "Student": 619,
    "Teacher": 86
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DEA7423",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR KUALA KRAI",
    "Student": 489,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DEA8001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU INDRA PETRA",
    "Student": 1148,
    "Teacher": 122
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DEA8002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR CHIKU",
    "Student": 910,
    "Teacher": 101
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DEA8003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PALOH",
    "Student": 588,
    "Teacher": 59
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DEA8004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ASAP",
    "Student": 970,
    "Teacher": 66
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DEA8005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU BENDAHARA",
    "Student": 679,
    "Teacher": 68
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DEA8006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU INDRA PETRA 2",
    "Student": 1074,
    "Teacher": 79
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DEA8007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHIKU 2",
    "Student": 624,
    "Teacher": 57
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DEA8008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI WANGI",
    "Student": 405,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DEA8009",
    "Name": "SEKOLAH MENENGAH SAINS GUA MUSANG",
    "Student": 654,
    "Teacher": 55
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD GUA MUSANG",
    "Kod": "DEA8010",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANGGUNG JAYA",
    "Student": 615,
    "Teacher": 38
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DEA9001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AYER LANAS",
    "Student": 814,
    "Teacher": 103
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DEA9002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JELI",
    "Student": 973,
    "Teacher": 84
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DEA9003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA BALAH",
    "Student": 429,
    "Teacher": 47
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DEA9004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU MELINTANG",
    "Student": 402,
    "Teacher": 45
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DEA9005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JELI 2",
    "Student": 145,
    "Teacher": 25
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD JELI",
    "Kod": "DEA9006",
    "Name": "SEKOLAH MENENGAH SAINS JELI",
    "Student": 667,
    "Teacher": 61
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEB1137",
    "Name": "MAKTAB SULTAN ISMAIL",
    "Student": 808,
    "Teacher": 93
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEB1138",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG CHENG",
    "Student": 785,
    "Teacher": 62
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEB1139",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA",
    "Student": 1165,
    "Teacher": 100
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEB1142",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ZAINAB (1)",
    "Student": 865,
    "Teacher": 91
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DEE0039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BACHOK",
    "Student": 698,
    "Teacher": 90
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEE1126",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KETEREH",
    "Student": 839,
    "Teacher": 110
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEE1127",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOTA",
    "Student": 1009,
    "Teacher": 110
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEE1130",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MELOR",
    "Student": 791,
    "Teacher": 92
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEE1133",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN CHEPA",
    "Student": 1118,
    "Teacher": 103
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEE1140",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOTA BHARU",
    "Student": 716,
    "Teacher": 88
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEE1141",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ISMAIL",
    "Student": 1097,
    "Teacher": 95
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DEE1415",
    "Name": "SEKOLAH MENENGAH SAINS TENGKU MUHAMMAD FARIS PETRA",
    "Student": 707,
    "Teacher": 64
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DEE2191",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HAMZAH",
    "Student": 1112,
    "Teacher": 132
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEE3244",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RANTAU PANJANG",
    "Student": 1051,
    "Teacher": 106
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DEE3247",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN IBRAHIM (1)",
    "Student": 1312,
    "Teacher": 122
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DEE4289",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMIL",
    "Student": 940,
    "Teacher": 91
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DEE5324",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' MAHMUD PADUKA RAJA (1)",
    "Student": 1461,
    "Teacher": 139
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DEE6362",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUMPAT",
    "Student": 1258,
    "Teacher": 132
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DEE7409",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN YAHYA PETRA 1",
    "Student": 1130,
    "Teacher": 118
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DFT0001",
    "Name": "SMU(A) DARUL ULUM ALMUHAMMADIAH (SABK)",
    "Student": 282,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DFT0002",
    "Name": "SEKOLAH MENENGAH UGAMA (ARAB) DARUL IMAN",
    "Student": 294,
    "Teacher": 30
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DFT1001",
    "Name": "SEKOLAH MENENGAH AGAMA TENGKU AMALIN A'ISHAH PUTRI",
    "Student": 483,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DFT1002",
    "Name": "SEKOLAH MENENGAH UGAMA (ARAB) AL-YUNUSIAH",
    "Student": 218,
    "Teacher": 34
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DFT1003",
    "Name": "SMU(A) AL-KAUTHAR",
    "Student": 312,
    "Teacher": 52
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DFT3001",
    "Name": "SMU(A)AL-FALAH SIRAM",
    "Student": 311,
    "Teacher": 40
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DFT3002",
    "Name": "SEKOLAH MENENGAH UGAMA (ARAB) AL-ULUM",
    "Student": 245,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DFT3003",
    "Name": "SEKOLAH MENENGAH UGAMA (ARAB) AL-BALAGHUL MUBIN",
    "Student": 48,
    "Teacher": 24
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DFT3004",
    "Name": "SMU(A) DINIAH",
    "Student": 128,
    "Teacher": 26
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DFT3005",
    "Name": "SMU(A)MARDZIAH JABO",
    "Student": 213,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DFT4001",
    "Name": "SMU(A) NURUL ITTIFAK",
    "Student": 151,
    "Teacher": 28
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DFT4002",
    "Name": "SMU (A) TARBIAH ISLAMIAH",
    "Student": 300,
    "Teacher": 35
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DFT4003",
    "Name": "SMU(A) ITTIFAKIAH",
    "Student": 98,
    "Teacher": 20
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DFT5001",
    "Name": "SMU (ARAB) AL-FITRAH",
    "Student": 76,
    "Teacher": 25
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DFT5002",
    "Name": "SMU (ARAB) DARUL ULUM",
    "Student": 112,
    "Teacher": 21
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DFT5003",
    "Name": "SMU (ARAB) TARBIAH ISLAMIAH",
    "Student": 178,
    "Teacher": 23
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DFT5004",
    "Name": "SMU (ARAB) ARABIAH",
    "Student": 208,
    "Teacher": 21
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DFT6001",
    "Name": "SEKOLAH MENENGAH UGAMA (ARAB) MARDZIAH",
    "Student": 110,
    "Teacher": 19
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DFT6002",
    "Name": "SMU (A) NURUL HUDA KAJANG SEBIDANG",
    "Student": 326,
    "Teacher": 29
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DFT7001",
    "Name": "SMU(A) DARUS SALAM",
    "Student": 240,
    "Teacher": 27
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DFT7002",
    "Name": "SMU (A) SAADATUL QURA",
    "Student": 88,
    "Teacher": 19
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DHA0001",
    "Name": "KOLEJ VOKASIONAL BACHOK",
    "Student": 363,
    "Teacher": 71
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DHA1001",
    "Name": "KOLEJ VOKASIONAL PENGKALAN CHEPA",
    "Student": 614,
    "Teacher": 111
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DHA1002",
    "Name": "KOLEJ VOKASIONAL KOTA BHARU",
    "Student": null,
    "Teacher": 2
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DHA3002",
    "Name": "KOLEJ VOKASIONAL PASIR MAS",
    "Student": 432,
    "Teacher": 99
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR PUTIH",
    "Kod": "DHA4001",
    "Name": "KOLEJ VOKASIONAL PASIR PUTEH",
    "Student": 358,
    "Teacher": 56
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TANAH MERAH",
    "Kod": "DHA5002",
    "Name": "KOLEJ VOKASIONAL TANAH MERAH",
    "Student": 1166,
    "Teacher": 159
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KUALA KRAI",
    "Kod": "DHA7002",
    "Name": "KOLEJ VOKASIONAL KUALA KRAI",
    "Student": 718,
    "Teacher": 130
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD BACHOK",
    "Kod": "DRA0001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (AGAMA) TOK BACHOK",
    "Student": 737,
    "Teacher": 140
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DRA1009",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA MELOR",
    "Student": 884,
    "Teacher": 68
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD KOTA BHARU",
    "Kod": "DRA1010",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA NAIM LIL-BANAT",
    "Student": 927,
    "Teacher": 85
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD MACHANG",
    "Kod": "DRA2001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA WATANIAH",
    "Student": 834,
    "Teacher": 70
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD PASIR MAS",
    "Kod": "DRA3001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (A) LATI",
    "Student": 748,
    "Teacher": 58
  },
  {
    "NationalState": "KELANTAN",
    "PPD": "PPD TUMPAT",
    "Kod": "DRA6001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (AGAMA) FALAHIAH",
    "Student": 917,
    "Teacher": 83
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0001",
    "Name": "SEKOLAH KEBANGSAAN LUBOK",
    "Student": 99,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0002",
    "Name": "SEKOLAH KEBANGSAAN SERI MAAMOR",
    "Student": 125,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0003",
    "Name": "SEKOLAH KEBANGSAAN SERI PENGKALAN",
    "Student": 98,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0004",
    "Name": "SEKOLAH KEBANGSAAN SERI UTAMA",
    "Student": 329,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0005",
    "Name": "SEKOLAH KEBANGSAAN BAGAN",
    "Student": 130,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0006",
    "Name": "SEKOLAH KEBANGSAAN SERI SEKAWAN DESA",
    "Student": 107,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0007",
    "Name": "SEKOLAH KEBANGSAAN GAMBUT",
    "Student": 98,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0008",
    "Name": "SEKOLAH KEBANGSAAN SG KAJANG",
    "Student": 210,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0009",
    "Name": "SEKOLAH KEBANGSAAN PESERAI",
    "Student": 591,
    "Teacher": 51
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0010",
    "Name": "SEKOLAH KEBANGSAAN SERI CHANTEK",
    "Student": 205,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0011",
    "Name": "SEKOLAH KEBANGSAAN SERI MAIMON",
    "Student": 360,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0012",
    "Name": "SEKOLAH KEBANGSAAN SERI LAKSANA",
    "Student": 499,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0013",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG JAWA",
    "Student": 98,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0014",
    "Name": "SEKOLAH KEBANGSAAN SERI PDG SARI",
    "Student": 78,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0016",
    "Name": "SEKOLAH KEBANGSAAN SERI COMEL",
    "Student": 146,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0017",
    "Name": "SEKOLAH KEBANGSAAN SERI TANJONG",
    "Student": 84,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0018",
    "Name": "SEKOLAH KEBANGSAAN SERI JASA SEPAKAT",
    "Student": 57,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0019",
    "Name": "SEKOLAH KEBANGSAAN PENGHULU SALLEH",
    "Student": 346,
    "Teacher": 38
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0020",
    "Name": "SEKOLAH KEBANGSAAN SERI SEPARAP",
    "Student": 246,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0021",
    "Name": "SEKOLAH KEBANGSAAN PARIT JU",
    "Student": 51,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0022",
    "Name": "SEKOLAH KEBANGSAAN BANDAR",
    "Student": 505,
    "Teacher": 53
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0024",
    "Name": "SEKOLAH KEBANGSAAN TEMENGGONG IBRAHIM PENGGARAM",
    "Student": 449,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0026",
    "Name": "SEKOLAH KEBANGSAAN BUKIT SOGA",
    "Student": 585,
    "Teacher": 48
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0027",
    "Name": "SEKOLAH KEBANGSAAN SERI BEROLEH",
    "Student": 678,
    "Teacher": 51
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0028",
    "Name": "SEKOLAH KEBANGSAAN SERI GADING",
    "Student": 555,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0029",
    "Name": "SEKOLAH KEBANGSAAN PARIT LAPIS",
    "Student": 263,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0031",
    "Name": "SEKOLAH KEBANGSAAN PARIT BILAL",
    "Student": 641,
    "Teacher": 55
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0032",
    "Name": "SEKOLAH KEBANGSAAN SERI TELOK",
    "Student": 454,
    "Teacher": 39
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0033",
    "Name": "SEKOLAH KEBANGSAAN TENAGA SEPAKAT",
    "Student": 130,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0034",
    "Name": "SEKOLAH KEBANGSAAN SERI BENGKAL",
    "Student": 101,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0035",
    "Name": "SEKOLAH KEBANGSAAN BINDU",
    "Student": 110,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0036",
    "Name": "SEKOLAH KEBANGSAAN BUKIT RAHMAT",
    "Student": 369,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0037",
    "Name": "SEKOLAH KEBANGSAAN SERI BINJAI",
    "Student": 648,
    "Teacher": 45
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0038",
    "Name": "SEKOLAH KEBANGSAAN TG SEMBERONG",
    "Student": 28,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0039",
    "Name": "SEKOLAH KEBANGSAAN SERI MENDAPAT",
    "Student": 97,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0040",
    "Name": "SEKOLAH KEBANGSAAN SERI NASIB BAIK",
    "Student": 17,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0041",
    "Name": "SEKOLAH KEBANGSAAN SERI MANGGIS",
    "Student": 76,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0042",
    "Name": "SEKOLAH KEBANGSAAN SERI IDAMAN",
    "Student": 90,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0043",
    "Name": "SEKOLAH KEBANGSAAN SERI BUNUT",
    "Student": 105,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0044",
    "Name": "SEKOLAH KEBANGSAAN PINTAS PUDING",
    "Student": 714,
    "Teacher": 52
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0045",
    "Name": "SEKOLAH KEBANGSAAN PINTAS RAYA",
    "Student": 549,
    "Teacher": 49
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0046",
    "Name": "SEKOLAH KEBANGSAAN SERI SABAK UNI",
    "Student": 496,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0047",
    "Name": "SEKOLAH KEBANGSAAN SETIA JAYA",
    "Student": 148,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0048",
    "Name": "SEKOLAH KEBANGSAAN SERI AMAN",
    "Student": 257,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0049",
    "Name": "SEKOLAH KEBANGSAAN JELOTONG",
    "Student": 365,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0050",
    "Name": "SEKOLAH KEBANGSAAN SERI KEMAJUAN",
    "Student": 198,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0051",
    "Name": "SEKOLAH KEBANGSAAN PARIT BINGAN",
    "Student": 136,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0052",
    "Name": "SEKOLAH KEBANGSAAN SERI PANDAN",
    "Student": 156,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0053",
    "Name": "SEKOLAH KEBANGSAAN SERI PULEH",
    "Student": 313,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0054",
    "Name": "SEKOLAH KEBANGSAAN MINYAK BEKU",
    "Student": 106,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0055",
    "Name": "SEKOLAH KEBANGSAAN SG SULOH",
    "Student": 128,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0056",
    "Name": "SEKOLAH KEBANGSAAN SERI BANANG",
    "Student": 96,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0057",
    "Name": "SEKOLAH KEBANGSAAN SERI MOLEK",
    "Student": 136,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0058",
    "Name": "SEKOLAH KEBANGSAAN KORIS",
    "Student": 189,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0059",
    "Name": "SEKOLAH KEBANGSAAN SENGGARANG",
    "Student": 576,
    "Teacher": 49
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0060",
    "Name": "SEKOLAH KEBANGSAAN KG BAHRU",
    "Student": 191,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0061",
    "Name": "SEKOLAH KEBANGSAAN PARIT KEMANG",
    "Student": 290,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0062",
    "Name": "SEKOLAH KEBANGSAAN SERI REJO SARI",
    "Student": 275,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0063",
    "Name": "SEKOLAH KEBANGSAAN PARIT KADIR",
    "Student": 138,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0064",
    "Name": "SEKOLAH KEBANGSAAN SERI TIMBUL",
    "Student": 173,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0065",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TONGKANG",
    "Student": 127,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0066",
    "Name": "SEKOLAH KEBANGSAAN SERI SAMPURNA",
    "Student": 134,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0067",
    "Name": "SEKOLAH KEBANGSAAN SERI PERPAT",
    "Student": 118,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0068",
    "Name": "SEKOLAH KEBANGSAAN SERI LADANG",
    "Student": 136,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0069",
    "Name": "SEKOLAH KEBANGSAAN RENGIT",
    "Student": 250,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0070",
    "Name": "SEKOLAH KEBANGSAAN SERI MERLONG",
    "Student": 292,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0071",
    "Name": "SEKOLAH KEBANGSAAN MUTIARA",
    "Student": 93,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0072",
    "Name": "SEKOLAH KEBANGSAAN SRI BELAHAN",
    "Student": 141,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0073",
    "Name": "SEKOLAH KEBANGSAAN PARIT SULONG",
    "Student": 475,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0074",
    "Name": "SEKOLAH KEBANGSAAN SERI MEDAN",
    "Student": 398,
    "Teacher": 34
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0075",
    "Name": "SEKOLAH KEBANGSAAN SERI PASIR",
    "Student": 295,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0076",
    "Name": "SEKOLAH KEBANGSAAN TENAGA SETIA",
    "Student": 215,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0077",
    "Name": "SEKOLAH KEBANGSAAN SERI DAYONG",
    "Student": 206,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0078",
    "Name": "SEKOLAH KEBANGSAAN SERI TIGA SERANGKAI",
    "Student": 222,
    "Teacher": 23
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0079",
    "Name": "SEKOLAH KEBANGSAAN SERI SEJATI",
    "Student": 147,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0080",
    "Name": "SEKOLAH KEBANGSAAN SERI PEMATANG RENGAS",
    "Student": 136,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0081",
    "Name": "SEKOLAH KEBANGSAAN AIR HITAM",
    "Student": 573,
    "Teacher": 49
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0082",
    "Name": "SEKOLAH KEBANGSAAN SERI YONG PENG",
    "Student": 193,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0083",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KUARI",
    "Student": 149,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0084",
    "Name": "SEKOLAH KEBANGSAAN SERI TENGAH",
    "Student": 103,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0085",
    "Name": "SEKOLAH KEBANGSAAN SERI BANDAN",
    "Student": 196,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0086",
    "Name": "SEKOLAH KEBANGSAAN KOTA DALAM",
    "Student": 355,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0087",
    "Name": "SEKOLAH KEBANGSAAN SERI PASIR PUTEH",
    "Student": 57,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0088",
    "Name": "SEKOLAH KEBANGSAAN SERI PAYA",
    "Student": 170,
    "Teacher": 21
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0089",
    "Name": "SEKOLAH KEBANGSAAN LADANG YONG PENG",
    "Student": 109,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0090",
    "Name": "SEKOLAH KEBANGSAAN SEPAKAT JAYA",
    "Student": 91,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0091",
    "Name": "SEKOLAH KEBANGSAAN SERI BERTAM",
    "Student": 249,
    "Teacher": 34
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0092",
    "Name": "SEKOLAH KEBANGSAAN SERI BULAN",
    "Student": 115,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0093",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS BATU PAHAT",
    "Student": 50,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0094",
    "Name": "SEKOLAH KEBANGSAAN SERI MULIA",
    "Student": 108,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0095",
    "Name": "SEKOLAH KEBANGSAAN SEJAGONG",
    "Student": 121,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0096",
    "Name": "SEKOLAH KEBANGSAAN AIR PUTIH",
    "Student": 73,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0097",
    "Name": "SEKOLAH KEBANGSAAN SETIA BUDI",
    "Student": 87,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0098",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SERI KOTA",
    "Student": 342,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0099",
    "Name": "SEKOLAH KEBANGSAAN BANANG JAYA",
    "Student": 527,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBA0100",
    "Name": "SEKOLAH KEBANGSAAN PARIT RAJA",
    "Student": 510,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1001",
    "Name": "SEKOLAH KEBANGSAAN AYER MOLEK",
    "Student": 224,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1003",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN RINTING",
    "Student": 758,
    "Teacher": 57
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1004",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG PUTERI",
    "Student": 653,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1008",
    "Name": "SEKOLAH KEBANGSAAN (P) BANDAR TINGGI",
    "Student": 171,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1010",
    "Name": "SEKOLAH KEBANGSAAN MOHD KHIR JOHARI",
    "Student": 1178,
    "Teacher": 77
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1011",
    "Name": "SEKOLAH KEBANGSAAN ANGKATAN TENTERA",
    "Student": 381,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1012",
    "Name": "SEKOLAH KEBANGSAAN MAJIDI BARU",
    "Student": 1044,
    "Teacher": 71
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1013",
    "Name": "SEKOLAH KEBANGSAAN SENIBONG",
    "Student": 41,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1014",
    "Name": "SEKOLAH KEBANGSAAN KOPOK",
    "Student": 1101,
    "Teacher": 73
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1015",
    "Name": "SEKOLAH KEBANGSAAN CAHAYA BARU",
    "Student": 928,
    "Teacher": 59
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1016",
    "Name": "SEKOLAH KEBANGSAAN MASAI",
    "Student": 674,
    "Teacher": 49
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1017",
    "Name": "SEKOLAH KEBANGSAAN PASIR PUTEH",
    "Student": 694,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1018",
    "Name": "SEKOLAH KEBANGSAAN KONGKONG LAUT",
    "Student": 136,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1019",
    "Name": "SEKOLAH KEBANGSAAN PASIR GUDANG (1)",
    "Student": 671,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1020",
    "Name": "SEKOLAH KEBANGSAAN TANJONG LANGSAT",
    "Student": 85,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1021",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TIRAM",
    "Student": 1399,
    "Teacher": 84
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1023",
    "Name": "SEKOLAH KEBANGSAAN ULU TIRAM",
    "Student": 1043,
    "Teacher": 75
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1024",
    "Name": "SEKOLAH KEBANGSAAN KG. MAJU JAYA",
    "Student": 897,
    "Teacher": 58
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1026",
    "Name": "SEKOLAH KEBANGSAAN TEBRAU BAKAR BATU",
    "Student": 680,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1027",
    "Name": "SEKOLAH KEBANGSAAN (FELDA ) ULU TEBRAU",
    "Student": 517,
    "Teacher": 45
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1028",
    "Name": "SEKOLAH KEBANGSAAN KANGKA TEBRAU",
    "Student": 550,
    "Teacher": 49
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1029",
    "Name": "SEKOLAH KEBANGSAAN GELANG PATAH",
    "Student": 752,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1030",
    "Name": "SEKOLAH KEBANGSAAN SEKUDAI BT 10",
    "Student": 366,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1031",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI MELAYU",
    "Student": 93,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1032",
    "Name": "SEKOLAH KEBANGSAAN SIKIJANG",
    "Student": 77,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1033",
    "Name": "SEKOLAH KEBANGSAAN LADANG PENDAS",
    "Student": 85,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1034",
    "Name": "SEKOLAH KEBANGSAAN PENDAS LAUT",
    "Student": 91,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1035",
    "Name": "SEKOLAH KEBANGSAAN KG PASIR",
    "Student": 676,
    "Teacher": 51
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1036",
    "Name": "SEKOLAH KEBANGSAAN SRI SKUDAI",
    "Student": 851,
    "Teacher": 61
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1037",
    "Name": "SEKOLAH KEBANGSAAN SERI KENANGA",
    "Student": 780,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1038",
    "Name": "SEKOLAH KEBANGSAAN PASIR GUDANG (2)",
    "Student": 317,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1039",
    "Name": "SEKOLAH KEBANGSAAN PASIR GUDANG 3",
    "Student": 878,
    "Teacher": 63
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1045",
    "Name": "SEKOLAH KEBANGSAAN TIRAM DUKU",
    "Student": 222,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1046",
    "Name": "SEKOLAH KEBANGSAAN TANJONG ADANG",
    "Student": 123,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1047",
    "Name": "SEKOLAH KEBANGSAAN MORNI POK",
    "Student": 279,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1048",
    "Name": "SEKOLAH KEBANGSAAN TG KUPANG",
    "Student": 127,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1049",
    "Name": "SEKOLAH KEBANGSAAN KEMPAS",
    "Student": 1301,
    "Teacher": 85
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1050",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DANGA",
    "Student": 194,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1051",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG PULAI",
    "Student": 388,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1054",
    "Name": "SEKOLAH KEBANGSAAN SRI TEBRAU",
    "Student": 781,
    "Teacher": 57
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1055",
    "Name": "SEKOLAH KEBANGSAAN KOMPLEKS UDA",
    "Student": 1046,
    "Teacher": 73
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1056",
    "Name": "SEKOLAH KEBANGSAAN SRI AMAR",
    "Student": 674,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1057",
    "Name": "SEKOLAH KEBANGSAAN NONG CHIK",
    "Student": 620,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1059",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PELANGI",
    "Student": 409,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1060",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TUN AMINAH",
    "Student": 446,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1061",
    "Name": "SEKOLAH KEBANGSAAN SERI MELATI",
    "Student": 672,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1062",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS JOHOR BAHRU",
    "Student": 79,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1063",
    "Name": "SEKOLAH KEBANGSAAN PERMAS JAYA 1",
    "Student": 841,
    "Teacher": 55
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1064",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PERLING",
    "Student": 945,
    "Teacher": 74
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1065",
    "Name": "SEKOLAH KEBANGSAAN BANDAR UDA 2",
    "Student": 1141,
    "Teacher": 65
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1066",
    "Name": "SEKOLAH KEBANGSAAN TAMAN RINTING 1",
    "Student": 807,
    "Teacher": 54
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1067",
    "Name": "SEKOLAH KEBANGSAAN TAMAN JOHOR JAYA 1",
    "Student": 319,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1068",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SURIA",
    "Student": 904,
    "Teacher": 65
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1069",
    "Name": "SEKOLAH KEBANGSAAN SERI PERLING 2",
    "Student": 1104,
    "Teacher": 62
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1070",
    "Name": "SEKOLAH KEBANGSAAN PERUMAHAN TAMPOI 2",
    "Student": 821,
    "Teacher": 55
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1071",
    "Name": "SEKOLAH KEBANGSAAN TAMAN UNIVERSITI 2",
    "Student": 532,
    "Teacher": 45
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1072",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SELESA JAYA",
    "Student": 440,
    "Teacher": 38
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1073",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PERMAS JAYA 2",
    "Student": 430,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1074",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TUN AMINAH 2",
    "Student": 513,
    "Teacher": 37
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1075",
    "Name": "SEKOLAH KEBANGSAAN TAMAN JOHOR JAYA 2",
    "Student": 507,
    "Teacher": 48
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1076",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DAMAI JAYA",
    "Student": 785,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1077",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DAYA",
    "Student": 698,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1078",
    "Name": "SEKOLAH KEBANGSAAN TAMAN UNIVERSITI 1",
    "Student": 918,
    "Teacher": 68
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1079",
    "Name": "SEKOLAH KEBANGSAAN DESA CEMERLANG",
    "Student": 599,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1080",
    "Name": "SEKOLAH KEBANGSAAN TAMAN RINTING 2",
    "Student": 598,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1081",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DESA JAYA",
    "Student": 759,
    "Teacher": 51
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1082",
    "Name": "SEKOLAH KEBANGSAAN TAMAN CEMPAKA",
    "Student": 677,
    "Teacher": 53
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1083",
    "Name": "SEKOLAH KEBANGSAAN PASIR GUDANG 4",
    "Student": 767,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1084",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SKUDAI BARU",
    "Student": 345,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1085",
    "Name": "SEKOLAH KEBANGSAAN SERI KOTA PUTERI",
    "Student": 531,
    "Teacher": 39
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1086",
    "Name": "SEKOLAH KEBANGSAAN KOMPLEKS SULTAN ABU BAKAR",
    "Student": 514,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1087",
    "Name": "SEKOLAH KEBANGSAAN PERIGI ACHEH",
    "Student": 77,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1088",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MOLEK",
    "Student": 1764,
    "Teacher": 103
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1089",
    "Name": "SEKOLAH KEBANGSAAN TMN JOHOR JAYA 3",
    "Student": 325,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1090",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PUTERI WANGSA",
    "Student": 1198,
    "Teacher": 78
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1091",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MEGAH RIA",
    "Student": 309,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1092",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PERMAS JAYA 3",
    "Student": 343,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1093",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MUTIARA RINI",
    "Student": 919,
    "Teacher": 58
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1094",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUKIT KEMPAS",
    "Student": 317,
    "Teacher": 37
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1095",
    "Name": "SEKOLAH KEBANGSAAN TAMAN ANGGERIK",
    "Student": 376,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1096",
    "Name": "SEKOLAH KEBANGSAAN TAMAN UNIVERSITI 4",
    "Student": 850,
    "Teacher": 66
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1097",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DAYA 3",
    "Student": 1557,
    "Teacher": 105
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1099",
    "Name": "SEKOLAH KEBANGSAAN TAMAN JOHOR JAYA 5",
    "Student": 304,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1100",
    "Name": "SEKOLAH KEBANGSAAN TAMAN UNIVERSITI 3",
    "Student": 463,
    "Teacher": 35
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1101",
    "Name": "SEKOLAH KEBANGSAAN PERMAS JAYA 5",
    "Student": 189,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1102",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUKIT MEWAH",
    "Student": 502,
    "Teacher": 38
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1103",
    "Name": "SEKOLAH KEBANGSAAN BANDAR SERI ALAM",
    "Student": 2002,
    "Teacher": 117
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1104",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DAYA 2",
    "Student": 1037,
    "Teacher": 64
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1105",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PASIR PUTIH",
    "Student": 1143,
    "Teacher": 67
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1106",
    "Name": "SEKOLAH KEBANGSAAN SERI ALAM 2",
    "Student": 603,
    "Teacher": 53
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1107",
    "Name": "SEKOLAH KEBANGSAAN TAMAN CENDANA",
    "Student": 987,
    "Teacher": 74
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1108",
    "Name": "SEKOLAH KEBANGSAAN SERI KOTA PUTERI 4",
    "Student": 304,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1109",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SUTERA",
    "Student": 298,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1110",
    "Name": "SEKOLAH KEBANGSAAN PERMAS JAYA 4",
    "Student": 218,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1111",
    "Name": "SEKOLAH KEBANGSAAN TAMAN NUSA PERINTIS",
    "Student": 1000,
    "Teacher": 60
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1112",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DESA SKUDAI",
    "Student": 487,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1113",
    "Name": "SEKOLAH KEBANGSAAN TAMAN IMPIAN EMAS",
    "Student": 971,
    "Teacher": 66
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1114",
    "Name": "SEKOLAH KEBANGSAAN TAMAN RINTING 3",
    "Student": 769,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1115",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUKIT INDAH",
    "Student": 638,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1116",
    "Name": "SEKOLAH KEBANGSAAN BUKIT MUTIARA",
    "Student": 1075,
    "Teacher": 64
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1117",
    "Name": "SEKOLAH KEBANGSAAN KOTA MASAI",
    "Student": 2558,
    "Teacher": 154
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1118",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SKUDAI BARU 2",
    "Student": 404,
    "Teacher": 38
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1119",
    "Name": "SEKOLAH KEBANGSAAN TASEK UTARA",
    "Student": 689,
    "Teacher": 54
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1120",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PERLING 3",
    "Student": 689,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1121",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUKIT TIRAM",
    "Student": 1534,
    "Teacher": 89
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1122",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MOUNT AUSTIN",
    "Student": 788,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1123",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TAMPOI UTAMA",
    "Student": 533,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1124",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PELANGI INDAH",
    "Student": 915,
    "Teacher": 63
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1125",
    "Name": "SEKOLAH KEBANGSAAN TG PUTERI RESORT",
    "Student": 1029,
    "Teacher": 70
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1126",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MUTIARA RINI 2",
    "Student": 797,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1127",
    "Name": "SEKOLAH KEBANGSAAN NUSANTARA",
    "Student": 1488,
    "Teacher": 92
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1128",
    "Name": "SEKOLAH KEBANGSAAN KOTA MASAI 2",
    "Student": 2072,
    "Teacher": 130
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1129",
    "Name": "SEKOLAH KEBANGSAAN TAMAN NUSA DAMAI",
    "Student": 1683,
    "Teacher": 102
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1130",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DAMANSARA ALIFF",
    "Student": 1060,
    "Teacher": 60
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1131",
    "Name": "SEKOLAH KEBANGSAAN TAMAN CAHAYA MASAI",
    "Student": 1251,
    "Teacher": 76
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1132",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUKIT DAHLIA",
    "Student": 1478,
    "Teacher": 84
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBA1133",
    "Name": "SEKOLAH KEBANGSAAN MEDINI",
    "Student": 770,
    "Teacher": 48
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA1134",
    "Name": "SEKOLAH KEBANGSAAN KANGKAR PULAI",
    "Student": 1659,
    "Teacher": 92
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA1135",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SRI PULAI",
    "Student": 1261,
    "Teacher": 85
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA1136",
    "Name": "SEKOLAH KEBANGSAAN KANGKAR PULAI 2",
    "Student": 1964,
    "Teacher": 123
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA1137",
    "Name": "SEKOLAH KEBANGSAAN SRI PULAI PERDANA",
    "Student": 1206,
    "Teacher": 71
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1138",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SCIENTEX",
    "Student": 2682,
    "Teacher": 133
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBA1139",
    "Name": "SEKOLAH KEBANGSAAN KOTA MASAI 3",
    "Student": 1415,
    "Teacher": 85
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2002",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) ULU PENGGELI",
    "Student": 179,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2003",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG RENGAM",
    "Student": 1010,
    "Teacher": 68
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2009",
    "Name": "SEKOLAH KEBANGSAAN SERI MACAP",
    "Student": 337,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2012",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PALOH",
    "Student": 106,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2014",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KENANGAN",
    "Student": 97,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2015",
    "Name": "SEKOLAH KEBANGSAAN SERI KENCANA",
    "Student": 211,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2018",
    "Name": "SEKOLAH KEBANGSAAN (L) BANDAR KLUANG",
    "Student": 354,
    "Teacher": 38
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2019",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG MELAYU",
    "Student": 860,
    "Teacher": 58
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2020",
    "Name": "SEKOLAH KEBANGSAAN KAHANG",
    "Student": 270,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2021",
    "Name": "SEKOLAH KEBANGSAAN BANDAR RENGAM",
    "Student": 95,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2022",
    "Name": "SEKOLAH KEBANGSAAN (P) BANDAR KLUANG",
    "Student": 384,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2023",
    "Name": "SEKOLAH KEBANGSAAN SERI LALANG",
    "Student": 292,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2024",
    "Name": "SEKOLAH KEBANGSAAN SERI KG TENGAH",
    "Student": 765,
    "Teacher": 60
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2025",
    "Name": "SEKOLAH KEBANGSAAN SERI KG RENGAM",
    "Student": 60,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2026",
    "Name": "SEKOLAH KEBANGSAAN PEKAN LAYANG-LAYANG",
    "Student": 350,
    "Teacher": 34
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2027",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG CHAMEK",
    "Student": 160,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2028",
    "Name": "SEKOLAH KEBANGSAAN LADANG PAMOL",
    "Student": 192,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2029",
    "Name": "SEKOLAH KEBANGSAAN LADANG BUKIT BENUT",
    "Student": 50,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2030",
    "Name": "SEKOLAH KEBANGSAAN LADANG TUN DR ISMAIL",
    "Student": 16,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2031",
    "Name": "SEKOLAH KEBANGSAAN LADANG ULU REMIS",
    "Student": 85,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2032",
    "Name": "SEKOLAH KEBANGSAAN SULTAN SIR IBRAHIM",
    "Student": 130,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2033",
    "Name": "SEKOLAH KEBANGSAAN BANDAR PALOH",
    "Student": 106,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2034",
    "Name": "SEKOLAH KEBANGSAAN LKTP AYER HITAM",
    "Student": 238,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2035",
    "Name": "SEKOLAH KEBANGSAAN KG MELAYU NIYOR",
    "Student": 116,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2036",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KAHANG TIMUR",
    "Student": 366,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2037",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LINAU",
    "Student": 182,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2038",
    "Name": "SEKOLAH KEBANGSAAN KG DATO IBRAHIM MAJID",
    "Student": 514,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2039",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KAHANG BARAT",
    "Student": 238,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2040",
    "Name": "SEKOLAH KEBANGSAAN LADANG TEREH",
    "Student": 99,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2041",
    "Name": "SEKOLAH KEBANGSAAN LKTP BELITONG",
    "Student": 310,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2042",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LAYANG-LAYANG",
    "Student": 113,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2043",
    "Name": "SEKOLAH KEBANGSAAN LKTP ULU DENGAR",
    "Student": 149,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2044",
    "Name": "SEKOLAH KEBANGSAAN SERI INTAN",
    "Student": 1048,
    "Teacher": 74
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2045",
    "Name": "SEKOLAH KEBANGSAAN DATO' ONN JAAFAR",
    "Student": 842,
    "Teacher": 68
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2046",
    "Name": "SEKOLAH KEBANGSAAN SERI SEDOHOK",
    "Student": 80,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2047",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN TEREH",
    "Student": 47,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2048",
    "Name": "SEKOLAH KEBANGSAAN DATO' SYED ZAIN ALSHAHAB",
    "Student": 683,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2050",
    "Name": "SEKOLAH KEBANGSAAN ABDUL RAHMAN YASSIN",
    "Student": 1265,
    "Teacher": 87
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2051",
    "Name": "SEKOLAH KEBANGSAAN SERI MAJU JAYA",
    "Student": 178,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2052",
    "Name": "SEKOLAH KEBANGSAAN LADANG MUTIARA",
    "Student": 87,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2053",
    "Name": "SEKOLAH KEBANGSAAN BANDAR T6",
    "Student": 1440,
    "Teacher": 95
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2054",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TONGKAT",
    "Student": 268,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA2055",
    "Name": "SEKOLAH KEBANGSAAN SERI TAMAN KLUANG BARAT",
    "Student": 906,
    "Teacher": 62
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3001",
    "Name": "SEKOLAH KEBANGSAAN BANDAR",
    "Student": 493,
    "Teacher": 39
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3002",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) BUKIT WAHA",
    "Student": 274,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3003",
    "Name": "SEKOLAH KEBANGSAAN BUKIT LINTANG",
    "Student": 537,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3004",
    "Name": "SEKOLAH KEBANGSAAN SEDILI BESAR",
    "Student": 119,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3005",
    "Name": "SEKOLAH KEBANGSAAN MAWAI",
    "Student": 209,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3006",
    "Name": "SEKOLAH KEBANGSAAN JOHOR KAMPONG",
    "Student": 28,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3007",
    "Name": "SEKOLAH KEBANGSAAN PANCHOR",
    "Student": 62,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3008",
    "Name": "SEKOLAH KEBANGSAAN SEDILI KECIL",
    "Student": 124,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3009",
    "Name": "SEKOLAH KEBANGSAAN SEMPANG",
    "Student": 31,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3010",
    "Name": "SEKOLAH KEBANGSAAN TELOK HUTAN LESONG",
    "Student": 17,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3012",
    "Name": "SEKOLAH KEBANGSAAN SERI SETIA JAYA",
    "Student": 540,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3013",
    "Name": "SEKOLAH KEBANGSAAN NAM HENG",
    "Student": 95,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3014",
    "Name": "SEKOLAH KEBANGSAAN SEMANGAR",
    "Student": 154,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3015",
    "Name": "SEKOLAH KEBANGSAAN TELOK SENGAT",
    "Student": 75,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3016",
    "Name": "SEKOLAH KEBANGSAAN PENGERANG",
    "Student": 75,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3017",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KAPAL",
    "Student": 371,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3018",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RENGIT",
    "Student": 148,
    "Teacher": 21
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3019",
    "Name": "SEKOLAH KEBANGSAAN TELOK RAMUNIA",
    "Student": 225,
    "Teacher": 23
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3020",
    "Name": "SEKOLAH KEBANGSAAN LEPAU",
    "Student": 19,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3021",
    "Name": "SEKOLAH KEBANGSAAN KG.BARU PASIR GOGOK",
    "Student": 76,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3022",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG SURAT",
    "Student": 27,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3023",
    "Name": "SEKOLAH KEBANGSAAN TANJONG BUAI",
    "Student": 24,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3024",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PAPAN",
    "Student": 59,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3025",
    "Name": "SEKOLAH KEBANGSAAN TUN DR SYED NASIR",
    "Student": 546,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3026",
    "Name": "SEKOLAH KEBANGSAAN BANDAR PENAWAR",
    "Student": 808,
    "Teacher": 52
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3030",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) AIR TAWAR 1",
    "Student": 339,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3031",
    "Name": "SEKOLAH KEBANGSAAN DATUK ISMAIL SA'ADON",
    "Student": 465,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3032",
    "Name": "SEKOLAH KEBANGSAAN MUZAFFAR SYAH",
    "Student": 331,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3033",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) AIR TAWAR 4",
    "Student": 321,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3034",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) AIR TAWAR 5",
    "Student": 334,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3035",
    "Name": "SEKOLAH KEBANGSAAN SERI AMAN",
    "Student": 227,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3036",
    "Name": "SEKOLAH KEBANGSAAN BUKIT MAHKOTA",
    "Student": 327,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3038",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SEMENCHU",
    "Student": 375,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3040",
    "Name": "SEKOLAH KEBANGSAAN LKTP SUNGAI MAS",
    "Student": 581,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3041",
    "Name": "SEKOLAH KEBANGSAAN BANDAR MAS",
    "Student": 541,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3042",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TELOR",
    "Student": 191,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3044",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) ADELA",
    "Student": 425,
    "Teacher": 39
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3046",
    "Name": "SEKOLAH KEBANGSAAN LKTP KLEDANG",
    "Student": 597,
    "Teacher": 45
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3047",
    "Name": "SEKOLAH KEBANGSAAN BANDAR EASTER",
    "Student": 481,
    "Teacher": 38
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3048",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LINGGIU",
    "Student": 233,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3049",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LOK HENG",
    "Student": 343,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3050",
    "Name": "SEKOLAH KEBANGSAAN FELCRA SUNGAI ARA",
    "Student": 118,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3051",
    "Name": "SEKOLAH KEBANGSAAN GEMBUT",
    "Student": 257,
    "Teacher": 23
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3052",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LOK HENG SELATAN",
    "Student": 419,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3053",
    "Name": "SEKOLAH KEBANGSAAN PUNGGAI",
    "Student": 120,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3054",
    "Name": "SEKOLAH KEBANGSAAN TUNJUK LAUT",
    "Student": 111,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3055",
    "Name": "SEKOLAH KEBANGSAAN LKTP TENGGAROH SELATAN",
    "Student": 64,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3056",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG LAYAU",
    "Student": 83,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3057",
    "Name": "SEKOLAH KEBANGSAAN DATUK USMAN AWANG",
    "Student": 513,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3058",
    "Name": "SEKOLAH KEBANGSAAN SRI PERANI",
    "Student": 77,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3059",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SENING",
    "Student": 279,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3060",
    "Name": "SEKOLAH KEBANGSAAN PETRI JAYA",
    "Student": 91,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3061",
    "Name": "SEKOLAH KEBANGSAAN TAMAN KOTA JAYA",
    "Student": 909,
    "Teacher": 70
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3062",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG PENGELIH",
    "Student": 89,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3063",
    "Name": "SEKOLAH KEBANGSAAN BANDAR PENAWAR 2",
    "Student": 829,
    "Teacher": 56
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBA3064",
    "Name": "SEKOLAH KEBANGSAAN SERI SAUJANA",
    "Student": 1247,
    "Teacher": 85
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA3065",
    "Name": "SEKOLAH KEBANGSAAN FELDA PENGELI TIMUR",
    "Student": 430,
    "Teacher": 37
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA3066",
    "Name": "SEKOLAH KEBANGSAAN FELDA SUNGAI SIBOL",
    "Student": 451,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA3067",
    "Name": "SEKOLAH KEBANGSAAN SAYONG PINANG",
    "Student": 94,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA3068",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TENGGARA 2",
    "Student": 776,
    "Teacher": 56
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA3069",
    "Name": "SEKOLAH KEBANGSAAN LKTP PASIR RAJA",
    "Student": 188,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA3070",
    "Name": "SEKOLAH KEBANGSAAN LKTP SUNGAI SAYONG",
    "Student": 244,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA3071",
    "Name": "SEKOLAH KEBANGSAAN LKTP BUKIT BESAR",
    "Student": 249,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA3072",
    "Name": "SEKOLAH KEBANGSAAN LKTP BUKIT RAMUN",
    "Student": 209,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA3073",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TENGGARA 1",
    "Student": 624,
    "Teacher": 53
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4001",
    "Name": "SEKOLAH KEBANGSAAN BANDAR MERSING",
    "Student": 554,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4002",
    "Name": "SEKOLAH KEBANGSAAN LKTP NITAR 1",
    "Student": 209,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4003",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 2",
    "Student": 215,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4004",
    "Name": "SEKOLAH KEBANGSAAN SERI PANTAI",
    "Student": 455,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4005",
    "Name": "SEKOLAH KEBANGSAAN SAWAH DATO'",
    "Student": 409,
    "Teacher": 38
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4006",
    "Name": "SEKOLAH KEBANGSAAN TENGLU",
    "Student": 210,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4007",
    "Name": "SEKOLAH KEBANGSAAN AIR PAPAN",
    "Student": 76,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4008",
    "Name": "SEKOLAH KEBANGSAAN TERIANG",
    "Student": 165,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4009",
    "Name": "SEKOLAH KEBANGSAAN LEMBAGA ENDAU",
    "Student": 91,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4011",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG RESANG",
    "Student": 42,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4012",
    "Name": "SEKOLAH KEBANGSAAN BANDAR ENDAU",
    "Student": 345,
    "Teacher": 35
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4013",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 4",
    "Student": 158,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4014",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 6",
    "Student": 161,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4015",
    "Name": "SEKOLAH KEBANGSAAN PUSAT AIR TAWAR",
    "Student": 317,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4016",
    "Name": "SEKOLAH KEBANGSAAN TELOK LIPAT",
    "Student": 341,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4017",
    "Name": "SEKOLAH KEBANGSAAN PULAU AUR",
    "Student": 11,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4018",
    "Name": "SEKOLAH KEBANGSAAN PULAU PEMANGGIL",
    "Student": 1,
    "Teacher": 3
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4019",
    "Name": "SEKOLAH KEBANGSAAN PULAU SIBU",
    "Student": 12,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4021",
    "Name": "SEKOLAH KEBANGSAAN PULAU TINGGI",
    "Student": 18,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4022",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 1",
    "Student": 241,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4023",
    "Name": "SEKOLAH KEBANGSAAN PENYABONG",
    "Student": 101,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4024",
    "Name": "SEKOLAH KEBANGSAAN (LKTP) NITAR 2",
    "Student": 241,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4025",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 5",
    "Student": 258,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4026",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 3",
    "Student": 247,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4027",
    "Name": "SEKOLAH KEBANGSAAN MERSING KANAN",
    "Student": 417,
    "Teacher": 37
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4028",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 7",
    "Student": 59,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4029",
    "Name": "SEKOLAH KEBANGSAAN TANAH ABANG",
    "Student": 111,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4030",
    "Name": "SEKOLAH KEBANGSAAN PETA",
    "Student": 38,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4031",
    "Name": "SEKOLAH KEBANGSAAN LABUNG",
    "Student": 60,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBA4032",
    "Name": "SEKOLAH KEBANGSAAN KEM ISKANDAR",
    "Student": 345,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBA4033",
    "Name": "SEKOLAH KEBANGSAAN PUNAN",
    "Student": 44,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5001",
    "Name": "SEKOLAH KEBANGSAAN BUKIT NANING",
    "Student": 203,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5002",
    "Name": "SEKOLAH KEBANGSAAN AYER HITAM BATU 18",
    "Student": 233,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5004",
    "Name": "SEKOLAH KEBANGSAAN BANDAR MAHARANI",
    "Student": 526,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5006",
    "Name": "SEKOLAH KEBANGSAAN BAKRI BATU 2",
    "Student": 712,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5007",
    "Name": "SEKOLAH KEBANGSAAN BAKRI BATU 5",
    "Student": 613,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5013",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KEPONG",
    "Student": 147,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5018",
    "Name": "SEKOLAH KEBANGSAAN DUMPAR",
    "Student": 258,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5020",
    "Name": "SEKOLAH KEBANGSAAN GOMBANG",
    "Student": 30,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5022",
    "Name": "SEKOLAH KEBANGSAAN JALAN YUSUF",
    "Student": 114,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5023",
    "Name": "SEKOLAH KEBANGSAAN JORAK",
    "Student": 144,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5024",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BAHRU",
    "Student": 106,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5028",
    "Name": "SEKOLAH KEBANGSAAN LKTP BATU 27",
    "Student": 113,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5029",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG RAJA",
    "Student": 149,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5030",
    "Name": "SEKOLAH KEBANGSAAN LENGA",
    "Student": 84,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5031",
    "Name": "SEKOLAH KEBANGSAAN LIANG BATU",
    "Student": 45,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5032",
    "Name": "SEKOLAH KEBANGSAAN PARIT KADZI",
    "Student": 390,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5033",
    "Name": "SEKOLAH KEBANGSAAN PARIT KEROMA",
    "Student": 103,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5034",
    "Name": "SEKOLAH KEBANGSAAN PARIT KEROMA DARAT",
    "Student": 281,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5035",
    "Name": "SEKOLAH KEBANGSAAN PARIT RAJA",
    "Student": 399,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5036",
    "Name": "SEKOLAH KEBANGSAAN PARIT PINANG SERIBU",
    "Student": 143,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5037",
    "Name": "SEKOLAH KEBANGSAAN PARIT SETONGKAT",
    "Student": 800,
    "Teacher": 63
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5040",
    "Name": "SEKOLAH KEBANGSAAN PARIT BAKAR TENGAH",
    "Student": 241,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5041",
    "Name": "SEKOLAH KEBANGSAAN PARIT BAKAR DARAT",
    "Student": 98,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5042",
    "Name": "SEKOLAH KEBANGSAAN PARIT ZAIN",
    "Student": 457,
    "Teacher": 36
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5043",
    "Name": "SEKOLAH KEBANGSAAN PARIT JAWA",
    "Student": 443,
    "Teacher": 39
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5044",
    "Name": "SEKOLAH KEBANGSAAN PARIT SAMSU",
    "Student": 145,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5046",
    "Name": "SEKOLAH KEBANGSAAN PARIT PECHAH",
    "Student": 172,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5047",
    "Name": "SEKOLAH KEBANGSAAN PARIT NAWI",
    "Student": 141,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5048",
    "Name": "SEKOLAH KEBANGSAAN PARIT LATIFF",
    "Student": 173,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5051",
    "Name": "SEKOLAH KEBANGSAAN PANCHOR",
    "Student": 316,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5052",
    "Name": "SEKOLAH KEBANGSAAN PANJANG SARI",
    "Student": 185,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5054",
    "Name": "SEKOLAH KEBANGSAAN PAYA REDAN",
    "Student": 209,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5055",
    "Name": "SEKOLAH KEBANGSAAN PAYA PANJANG",
    "Student": 147,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5056",
    "Name": "SEKOLAH KEBANGSAAN PEKAN PAGOH",
    "Student": 294,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5057",
    "Name": "SEKOLAH KEBANGSAAN PEKAN BAHARU",
    "Student": 319,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5060",
    "Name": "SEKOLAH KEBANGSAAN PARIT BULAT",
    "Student": 85,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5062",
    "Name": "SEKOLAH KEBANGSAAN RENCHONG",
    "Student": 43,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5067",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG JERAM",
    "Student": 337,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5069",
    "Name": "SEKOLAH KEBANGSAAN SERI JONG",
    "Student": 412,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5070",
    "Name": "SEKOLAH KEBANGSAAN SERI BUKIT BATU",
    "Student": 165,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5071",
    "Name": "SEKOLAH KEBANGSAAN PARIT NO. 5",
    "Student": 321,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5072",
    "Name": "SEKOLAH KEBANGSAAN SERI MENANTI",
    "Student": 134,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5074",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG 4",
    "Student": 59,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5083",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RAYA",
    "Student": 187,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5084",
    "Name": "SEKOLAH KEBANGSAAN ORANGKAYA ALI",
    "Student": 323,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5085",
    "Name": "SEKOLAH KEBANGSAAN SG BALANG DARAT",
    "Student": 118,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5087",
    "Name": "SEKOLAH KEBANGSAAN SEMASEH",
    "Student": 86,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5090",
    "Name": "SEKOLAH KEBANGSAAN TEMIANG",
    "Student": 335,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5093",
    "Name": "SEKOLAH KEBANGSAAN TANJONG TOHOR",
    "Student": 53,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5094",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS MUAR",
    "Student": 34,
    "Teacher": 23
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5095",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ABONG",
    "Student": 558,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBA5096",
    "Name": "SEKOLAH KEBANGSAAN FELCRA BUKIT KEPONG",
    "Student": 185,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6001",
    "Name": "SEKOLAH KEBANGSAAN RIMBA TERJUN",
    "Student": 225,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6002",
    "Name": "SEKOLAH KEBANGSAAN PARIT RAMBAI",
    "Student": 117,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6003",
    "Name": "SEKOLAH KEBANGSAAN PARIT SULAIMAN",
    "Student": 70,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6004",
    "Name": "SEKOLAH KEBANGSAAN RAMBAH",
    "Student": 170,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6005",
    "Name": "SEKOLAH KEBANGSAAN SERI BUNIAN",
    "Student": 277,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6006",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BUNYI",
    "Student": 578,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6007",
    "Name": "SEKOLAH KEBANGSAAN TELOK KERANG",
    "Student": 183,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6009",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BAHARU",
    "Student": 57,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6010",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BOH",
    "Student": 80,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6011",
    "Name": "SEKOLAH KEBANGSAAN BELOKOK",
    "Student": 59,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6012",
    "Name": "SEKOLAH KEBANGSAAN SERKAT",
    "Student": 182,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6013",
    "Name": "SEKOLAH KEBANGSAAN PERMAS KELAPA",
    "Student": 99,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6014",
    "Name": "SEKOLAH KEBANGSAAN SERI SINARAN CHOKOH",
    "Student": 93,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6015",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DURIAN",
    "Student": 230,
    "Teacher": 23
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6016",
    "Name": "SEKOLAH KEBANGSAAN JERAM BATU",
    "Student": 151,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6017",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN RAJA",
    "Student": 225,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6018",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG SAWAH",
    "Student": 353,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6019",
    "Name": "SEKOLAH KEBANGSAAN PEKAN NANAS",
    "Student": 643,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6020",
    "Name": "SEKOLAH KEBANGSAAN PENEROK",
    "Student": 124,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6021",
    "Name": "SEKOLAH KEBANGSAAN BANDAR PONTIAN",
    "Student": 918,
    "Teacher": 69
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6022",
    "Name": "SEKOLAH KEBANGSAAN PARIT KUDUS",
    "Student": 224,
    "Teacher": 23
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6023",
    "Name": "SEKOLAH KEBANGSAAN PARIT PATAH",
    "Student": 200,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6024",
    "Name": "SEKOLAH KEBANGSAAN TG AYER HITAM",
    "Student": 93,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6025",
    "Name": "SEKOLAH KEBANGSAAN PARIT SELANGOR",
    "Student": 148,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6026",
    "Name": "SEKOLAH KEBANGSAAN PARIT HJ ADNAN",
    "Student": 125,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6027",
    "Name": "SEKOLAH KEBANGSAAN SERI BUKIT PANJANG",
    "Student": 99,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6028",
    "Name": "SEKOLAH KEBANGSAAN PONTIAN BESAR",
    "Student": 313,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6029",
    "Name": "SEKOLAH KEBANGSAAN MELAYU RAYA",
    "Student": 380,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6030",
    "Name": "SEKOLAH KEBANGSAAN API API",
    "Student": 361,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6031",
    "Name": "SEKOLAH KEBANGSAAN KAYU ARA PASONG",
    "Student": 328,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6032",
    "Name": "SEKOLAH KEBANGSAAN PARIT SAPRAN",
    "Student": 143,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6033",
    "Name": "SEKOLAH KEBANGSAAN PULAI SEBATANG",
    "Student": 166,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6034",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG JAWA",
    "Student": 91,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6035",
    "Name": "SEKOLAH KEBANGSAAN AYER BALOI",
    "Student": 437,
    "Teacher": 39
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6036",
    "Name": "SEKOLAH KEBANGSAAN SERI PULAI",
    "Student": 123,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6037",
    "Name": "SEKOLAH KEBANGSAAN SANGLANG",
    "Student": 149,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6038",
    "Name": "SEKOLAH KEBANGSAAN SERI SEKAWAN DESA",
    "Student": 165,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6039",
    "Name": "SEKOLAH KEBANGSAAN SERI ANOM",
    "Student": 125,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6040",
    "Name": "SEKOLAH KEBANGSAAN JAYA MULIA",
    "Student": 52,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6041",
    "Name": "SEKOLAH KEBANGSAAN BENUT",
    "Student": 356,
    "Teacher": 39
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6042",
    "Name": "SEKOLAH KEBANGSAAN SERI SINARAN",
    "Student": 108,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6043",
    "Name": "SEKOLAH KEBANGSAAN BUGIS BENUT",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6044",
    "Name": "SEKOLAH KEBANGSAAN SERI SEMANGAT",
    "Student": 55,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6045",
    "Name": "SEKOLAH KEBANGSAAN SERI JAYA",
    "Student": 114,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6046",
    "Name": "SEKOLAH KEBANGSAAN SERI SETIA",
    "Student": 92,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6047",
    "Name": "SEKOLAH KEBANGSAAN SERI BAHAGIA",
    "Student": 180,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6048",
    "Name": "SEKOLAH KEBANGSAAN SERI KEMBAR",
    "Student": 158,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6049",
    "Name": "SEKOLAH KEBANGSAAN SERI AL ULUM",
    "Student": 144,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6050",
    "Name": "SEKOLAH KEBANGSAAN SERI SENANG HATI",
    "Student": 142,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6051",
    "Name": "SEKOLAH KEBANGSAAN PARIT MARKOM",
    "Student": 309,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBA6052",
    "Name": "SEKOLAH KEBANGSAAN BATU 24",
    "Student": 523,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7001",
    "Name": "SEKOLAH KEBANGSAAN TEMENGGONG ABDUL RAHMAN",
    "Student": 547,
    "Teacher": 49
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7003",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG TENGAH",
    "Student": 260,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7004",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG JAWA",
    "Student": 521,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7005",
    "Name": "SEKOLAH KEBANGSAAN LKTP MEDOI",
    "Student": 131,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7006",
    "Name": "SEKOLAH KEBANGSAAN DATO SERI MAHARAJA LELA",
    "Student": 233,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7007",
    "Name": "SEKOLAH KEBANGSAAN GELANG CHINCHIN",
    "Student": 137,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7008",
    "Name": "SEKOLAH KEBANGSAAN BULOH KASAP",
    "Student": 518,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7009",
    "Name": "SEKOLAH KEBANGSAAN KUALA PAYA",
    "Student": 103,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7010",
    "Name": "SEKOLAH KEBANGSAAN SPANG LOI",
    "Student": 62,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7011",
    "Name": "SEKOLAH KEBANGSAAN SEPINANG",
    "Student": 119,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7012",
    "Name": "SEKOLAH KEBANGSAAN BATU ANAM",
    "Student": 517,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7013",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG AWAT",
    "Student": 66,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7014",
    "Name": "SEKOLAH KEBANGSAAN MENSUDUT PINDAH",
    "Student": 32,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7015",
    "Name": "SEKOLAH KEBANGSAAN TAMBANG",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7016",
    "Name": "SEKOLAH KEBANGSAAN GEMEREH",
    "Student": 402,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7017",
    "Name": "SEKOLAH KEBANGSAAN JEMENTAH",
    "Student": 328,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7018",
    "Name": "SEKOLAH KEBANGSAAN LADANG WELCH",
    "Student": 73,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7019",
    "Name": "SEKOLAH KEBANGSAAN TEBING TINGGI",
    "Student": 37,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7020",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TUNGGAL",
    "Student": 68,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7021",
    "Name": "SEKOLAH KEBANGSAAN PAYA JAKAS",
    "Student": 145,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7022",
    "Name": "SEKOLAH KEBANGSAAN POGOH",
    "Student": 240,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7023",
    "Name": "SEKOLAH KEBANGSAAN MELAYU RAYA",
    "Student": 151,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7024",
    "Name": "SEKOLAH KEBANGSAAN LABIS",
    "Student": 257,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7025",
    "Name": "SEKOLAH KEBANGSAAN PEKAN AIR PANAS",
    "Student": 85,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7026",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG TENANG",
    "Student": 70,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7027",
    "Name": "SEKOLAH KEBANGSAAN SAWAH BAHRU",
    "Student": 46,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7028",
    "Name": "SEKOLAH KEBANGSAAN LKTP TENANG",
    "Student": 151,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7029",
    "Name": "SEKOLAH KEBANGSAAN SERI BALI",
    "Student": 260,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7030",
    "Name": "SEKOLAH KEBANGSAAN SERI BEKOK",
    "Student": 139,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7031",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) CHEMPLAK",
    "Student": 226,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7032",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KEMELAH",
    "Student": 200,
    "Teacher": 21
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7033",
    "Name": "SEKOLAH KEBANGSAAN MENSUDUT LAMA",
    "Student": 38,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7034",
    "Name": "SEKOLAH KEBANGSAAN BALAI BADANG",
    "Student": 79,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7035",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) REDONG",
    "Student": 317,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7036",
    "Name": "SEKOLAH KEBANGSAAN LKTP PEMANIS 1",
    "Student": 282,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7037",
    "Name": "SEKOLAH KEBANGSAAN TENANG STESEN",
    "Student": 147,
    "Teacher": 23
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7038",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PEMANIS 2",
    "Student": 226,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7039",
    "Name": "SEKOLAH KEBANGSAAN LKTP PALONG TIMUR",
    "Student": 314,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7040",
    "Name": "SEKOLAH KEBANGSAAN LKTP MAOKIL 1",
    "Student": 201,
    "Teacher": 21
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7041",
    "Name": "SEKOLAH KEBANGSAAN DESA TEMU JODOH",
    "Student": 272,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7042",
    "Name": "SEKOLAH KEBANGSAAN SEGAMAT KECIL",
    "Student": 78,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7043",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG KUDUNG",
    "Student": 124,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7044",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG PAYA BESAR",
    "Student": 113,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7045",
    "Name": "SEKOLAH KEBANGSAAN LKTP PALONG TIMUR 2",
    "Student": 124,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7046",
    "Name": "SEKOLAH KEBANGSAAN LKTP MAOKIL 2",
    "Student": 162,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7047",
    "Name": "SEKOLAH KEBANGSAAN SEGAMAT BARU",
    "Student": 611,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7048",
    "Name": "SEKOLAH KEBANGSAAN KEMEDAK",
    "Student": 169,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBA7049",
    "Name": "SEKOLAH KEBANGSAAN BANDAR PUTRA",
    "Student": 857,
    "Teacher": 70
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8001",
    "Name": "SEKOLAH KEBANGSAAN SINAR BAHAGIA",
    "Student": 499,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8003",
    "Name": "SEKOLAH KEBANGSAAN KULAI",
    "Student": 727,
    "Teacher": 61
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8004",
    "Name": "SEKOLAH KEBANGSAAN AYER MANIS",
    "Student": 317,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8005",
    "Name": "SEKOLAH KEBANGSAAN LKTP BUKIT BATU",
    "Student": 298,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8006",
    "Name": "SEKOLAH KEBANGSAAN LKTP BUKIT PERMAI",
    "Student": 111,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8008",
    "Name": "SEKOLAH KEBANGSAAN SEDENAK",
    "Student": 240,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8009",
    "Name": "SEKOLAH KEBANGSAAN POLIS KEM",
    "Student": 1321,
    "Teacher": 78
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8011",
    "Name": "SEKOLAH KEBANGSAAN SENAI",
    "Student": 739,
    "Teacher": 52
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8020",
    "Name": "SEKOLAH KEBANGSAAN FELDA INAS",
    "Student": 297,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8021",
    "Name": "SEKOLAH KEBANGSAAN MURNI JAYA",
    "Student": 135,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8025",
    "Name": "SEKOLAH KEBANGSAAN SRI GUNUNG PULAI",
    "Student": 99,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8026",
    "Name": "SEKOLAH KEBANGSAAN TAMAN KOTA KULAI",
    "Student": 1203,
    "Teacher": 82
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8027",
    "Name": "SEKOLAH KEBANGSAAN SENAI UTAMA",
    "Student": 1082,
    "Teacher": 69
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8028",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MUHIBBAH",
    "Student": 630,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8029",
    "Name": "SEKOLAH KEBANGSAAN TAMAN KOTA KULAI 2",
    "Student": 1082,
    "Teacher": 74
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8031",
    "Name": "SEKOLAH KEBANGSAAN TAMAN INDAHPURA 1",
    "Student": 481,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8032",
    "Name": "SEKOLAH KEBANGSAAN TAMAN INDAHPURA 2",
    "Student": 468,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8033",
    "Name": "SEKOLAH KEBANGSAAN BANDAR PUTRA",
    "Student": 1827,
    "Teacher": 118
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8034",
    "Name": "SEKOLAH KEBANGSAAN PUTRA UTAMA",
    "Student": 1382,
    "Teacher": 93
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBA8036",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PULAI EMAS",
    "Student": null,
    "Teacher": null
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9001",
    "Name": "SEKOLAH KEBANGSAAN SEROM 8",
    "Student": 107,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9002",
    "Name": "SEKOLAH KEBANGSAAN SAGIL KAMPONG",
    "Student": 361,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9003",
    "Name": "SEKOLAH KEBANGSAAN SAWAH RING",
    "Student": 277,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9004",
    "Name": "SEKOLAH KEBANGSAAN SERI MA'AMOR",
    "Student": 211,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9005",
    "Name": "SEKOLAH KEBANGSAAN SEROM 3",
    "Student": 293,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9006",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG 5 PEKAN",
    "Student": 142,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9007",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG 5 DARAT",
    "Student": 119,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9008",
    "Name": "SEKOLAH KEBANGSAAN SEROM 4",
    "Student": 54,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9009",
    "Name": "SEKOLAH KEBANGSAAN SRI TANGKAK",
    "Student": 544,
    "Teacher": 37
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9010",
    "Name": "SEKOLAH KEBANGSAAN SEROM 6",
    "Student": 81,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9011",
    "Name": "SEKOLAH KEBANGSAAN PENCHU",
    "Student": 106,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9012",
    "Name": "SEKOLAH KEBANGSAAN SENGKANG",
    "Student": 197,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9013",
    "Name": "SEKOLAH KEBANGSAAN SIALANG",
    "Student": 131,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9014",
    "Name": "SEKOLAH KEBANGSAAN SERI JAYA",
    "Student": 147,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9015",
    "Name": "SEKOLAH KEBANGSAAN TANJONG GADING",
    "Student": 247,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9016",
    "Name": "SEKOLAH KEBANGSAAN TELOK RIMBA",
    "Student": 121,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9017",
    "Name": "SEKOLAH KEBANGSAAN ABDULLAH",
    "Student": 310,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9018",
    "Name": "SEKOLAH KEBANGSAAN LEDANG",
    "Student": 489,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9019",
    "Name": "SEKOLAH KEBANGSAAN SEROM 5",
    "Student": 84,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9020",
    "Name": "SEKOLAH KEBANGSAAN KESANG",
    "Student": 216,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9021",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TANGKAK",
    "Student": 353,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9022",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KANGKAR",
    "Student": 127,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9023",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BANJAR",
    "Student": 100,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9024",
    "Name": "SEKOLAH KEBANGSAAN BUKIT GAMBIR",
    "Student": 577,
    "Teacher": 45
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9025",
    "Name": "SEKOLAH KEBANGSAAN BELEMANG",
    "Student": 31,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9026",
    "Name": "SEKOLAH KEBANGSAAN BEKOH",
    "Student": 106,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9027",
    "Name": "SEKOLAH KEBANGSAAN BUKIT RAHMAT",
    "Student": 156,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9028",
    "Name": "SEKOLAH KEBANGSAAN RKT SRI LEDANG",
    "Student": 132,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9029",
    "Name": "SEKOLAH KEBANGSAAN PUTERI LEDANG",
    "Student": 92,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9030",
    "Name": "SEKOLAH KEBANGSAAN GERSEK",
    "Student": 131,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9031",
    "Name": "SEKOLAH KEBANGSAAN RAWANG",
    "Student": 154,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9032",
    "Name": "SEKOLAH KEBANGSAAN KESANG TASEK",
    "Student": 99,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9033",
    "Name": "SEKOLAH KEBANGSAAN KUNDANG ULU",
    "Student": 95,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9034",
    "Name": "SEKOLAH KEBANGSAAN PARIT BUNGA",
    "Student": 820,
    "Teacher": 61
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9035",
    "Name": "SEKOLAH KEBANGSAAN PARIT KASAN",
    "Student": 212,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9036",
    "Name": "SEKOLAH KEBANGSAAN PANTAI LAYANG",
    "Student": 85,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9037",
    "Name": "SEKOLAH KEBANGSAAN PAYA MAS",
    "Student": 389,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9038",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN BESAR",
    "Student": 52,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBA9039",
    "Name": "SEKOLAH KEBANGSAAN DURIAN CHONDONG",
    "Student": 54,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBB0022",
    "Name": "SEKOLAH KEBANGSAAN SRI RENGIT",
    "Student": 355,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBB0023",
    "Name": "SEKOLAH KEBANGSAAN LIMPOON 1",
    "Student": 333,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBB0024",
    "Name": "SEKOLAH KEBANGSAAN SERI PUTERI",
    "Student": 518,
    "Teacher": 39
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBB0025",
    "Name": "SEKOLAH KEBANGSAAN TENGKU MARIAM",
    "Student": 502,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBB0027",
    "Name": "SEKOLAH KEBANGSAAN CONVENT",
    "Student": 313,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBB0028",
    "Name": "SEKOLAH KEBANGSAAN MONTFORT",
    "Student": 390,
    "Teacher": 34
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBB0029",
    "Name": "SEKOLAH KEBANGSAAN PESERIAN",
    "Student": 157,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBB0048",
    "Name": "SEKOLAH KEBANGSAAN YONG PENG",
    "Student": 283,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBB1001",
    "Name": "SEKOLAH KEBANGSAAN TEMENGGONG ABDUL RAHMAN 1",
    "Student": 622,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBB1002",
    "Name": "SEKOLAH KEBANGSAAN TEMENGGONG ABDUL RAHMAN 2",
    "Student": 382,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBB1003",
    "Name": "SEKOLAH KEBANGSAAN LARKIN 1",
    "Student": 386,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBB1004",
    "Name": "SEKOLAH KEBANGSAAN LARKIN 2",
    "Student": 261,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBB1005",
    "Name": "SEKOLAH KEBANGSAAN ST. JOSEPH",
    "Student": 498,
    "Teacher": 35
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBB1006",
    "Name": "SEKOLAH KEBANGSAAN NGEE HENG",
    "Student": 221,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBB1008",
    "Name": "SEKOLAH KEBANGSAAN (P) SULTAN IBRAHIM",
    "Student": 806,
    "Teacher": 53
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBB1009",
    "Name": "SEKOLAH KEBANGSAAN (P) JLN. YAHYA AWAL",
    "Student": 303,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBB1011",
    "Name": "SEKOLAH KEBANGSAAN INFANT JESUS CONVENT",
    "Student": 804,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBB2047",
    "Name": "SEKOLAH KEBANGSAAN PALOH (2)",
    "Student": 176,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBB2049",
    "Name": "SEKOLAH KEBANGSAAN TUNKU MAHMOOD 1",
    "Student": 757,
    "Teacher": 52
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBB2050",
    "Name": "SEKOLAH KEBANGSAAN TUNKU MAHMOOD 2",
    "Student": 627,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBB2051",
    "Name": "SEKOLAH KEBANGSAAN CANOSSIAN CONVENT",
    "Student": 366,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBB2052",
    "Name": "SEKOLAH KEBANGSAAN BATU 3",
    "Student": 710,
    "Teacher": 53
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBB2053",
    "Name": "SEKOLAH KEBANGSAAN JUBLI INTAN",
    "Student": 108,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBB3013",
    "Name": "SEKOLAH KEBANGSAAN LAKSAMANA",
    "Student": 544,
    "Teacher": 52
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBB4014",
    "Name": "SEKOLAH KEBANGSAAN SRI MERSING",
    "Student": 751,
    "Teacher": 59
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBB5030",
    "Name": "SEKOLAH KEBANGSAAN ISMAIL SATU",
    "Student": 193,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBB5031",
    "Name": "SEKOLAH KEBANGSAAN ISMAIL (2)",
    "Student": 185,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBB5032",
    "Name": "SEKOLAH KEBANGSAAN ST ANDREW",
    "Student": 94,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBB5033",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ABU BAKAR (1)",
    "Student": 196,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBB5034",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ABU BAKAR 2",
    "Student": 89,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBB5035",
    "Name": "SEKOLAH KEBANGSAAN CONVENT",
    "Student": 415,
    "Teacher": 35
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBB5036",
    "Name": "SEKOLAH KEBANGSAAN SRI JAMIL",
    "Student": 139,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBB5037",
    "Name": "SEKOLAH KEBANGSAAN SRI BUKIT PASIR",
    "Student": 489,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBB5038",
    "Name": "SEKOLAH KEBANGSAAN KOTA RAJA",
    "Student": 323,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBB6017",
    "Name": "SEKOLAH KEBANGSAAN ANDEK MORI",
    "Student": 200,
    "Teacher": 21
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBB6018",
    "Name": "SEKOLAH KEBANGSAAN TENGKU MAHMOOD ISKANDAR 1",
    "Student": 394,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBB6019",
    "Name": "SEKOLAH KEBANGSAAN TENGKU MAHMOOD ISKANDAR 2",
    "Student": 244,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBB6021",
    "Name": "SEKOLAH KEBANGSAAN SRI BENUT",
    "Student": 281,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBB7043",
    "Name": "SEKOLAH KEBANGSAAN BUKIT HAMPAR",
    "Student": 265,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBB7044",
    "Name": "SEKOLAH KEBANGSAAN DATUK WAN IDRIS",
    "Student": 182,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBB7045",
    "Name": "SEKOLAH KEBANGSAAN CANOSSIAN CONVENT",
    "Student": 178,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBB7046",
    "Name": "SEKOLAH KEBANGSAAN SRI LABIS",
    "Student": 372,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBB8001",
    "Name": "SEKOLAH KEBANGSAAN KULAI 1",
    "Student": 648,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AI CHUN 1",
    "Student": 369,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AI CHUN 2",
    "Student": 595,
    "Teacher": 37
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BULOH",
    "Student": 149,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG SIU 1",
    "Student": 288,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG SIU 2",
    "Student": 463,
    "Teacher": 39
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHERN HWA BUKIT PASIR",
    "Student": 568,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA SRI GADING",
    "Student": 115,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA SEGENTING",
    "Student": 77,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA SG.AYAM",
    "Student": 21,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0010",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA SENGGARANG",
    "Student": 167,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA PT KADIR",
    "Student": 76,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA SRI MEDAN",
    "Student": 57,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0013",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA RENGIT",
    "Student": 325,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA KANGKAR SENANGGAR",
    "Student": 53,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHENG",
    "Student": 56,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HUA MIN",
    "Student": 1023,
    "Teacher": 60
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA JIN",
    "Student": 332,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA NAN",
    "Student": 279,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KONG NAN",
    "Student": 237,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KANGKAR BAHRU",
    "Student": 86,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LAM LEE",
    "Student": 60,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LI CHUN",
    "Student": 68,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MALAYAN",
    "Student": 218,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PANCHOR",
    "Student": 176,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHUN",
    "Student": 54,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PING MING",
    "Student": 63,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0028",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HWA",
    "Student": 805,
    "Teacher": 48
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HWA",
    "Student": 25,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SULONG",
    "Student": 135,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TONGKANG",
    "Student": 434,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG CHENG",
    "Student": 67,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YANI",
    "Student": 37,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YEONG CHANG",
    "Student": 310,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YONG PENG 1",
    "Student": 653,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YONG PENG 2",
    "Student": 522,
    "Teacher": 37
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBC0037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YORK CHAI",
    "Student": 10,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) FOON YEW 1",
    "Student": 2027,
    "Teacher": 105
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) FOON YEW 2",
    "Student": 1391,
    "Teacher": 77
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) FOON YEW 3",
    "Student": 664,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) FOON YEW 4",
    "Student": 1221,
    "Teacher": 62
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) FOON YEW 5",
    "Student": 3570,
    "Teacher": 142
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUO KUANG",
    "Student": 1730,
    "Teacher": 96
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST JOSEPH",
    "Student": 212,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1013",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAMPOI",
    "Student": 530,
    "Teacher": 38
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MING TERK",
    "Student": 1153,
    "Teacher": 60
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA",
    "Student": 2190,
    "Teacher": 115
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PING MING",
    "Student": 2009,
    "Teacher": 103
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PU SZE",
    "Student": 2125,
    "Teacher": 111
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BAN FOO",
    "Student": 366,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIEN CHI",
    "Student": 1340,
    "Teacher": 74
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE TONG",
    "Student": 1369,
    "Teacher": 75
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MASAI",
    "Student": 718,
    "Teacher": 56
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PANDAN",
    "Student": 2632,
    "Teacher": 128
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TIRAM",
    "Student": 2401,
    "Teacher": 127
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEMPAS BARU",
    "Student": 921,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUO KUANG 2",
    "Student": 3203,
    "Teacher": 154
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAM HENG BARU",
    "Student": 1325,
    "Teacher": 68
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JOHOR JAYA",
    "Student": 2014,
    "Teacher": 110
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHIH",
    "Student": 1819,
    "Teacher": 82
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC1041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WOON HWA",
    "Student": 919,
    "Teacher": 48
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) THORBURN",
    "Student": 1453,
    "Teacher": 73
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) THAI HONG",
    "Student": 689,
    "Teacher": 36
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAI TZE",
    "Student": 827,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MING CHIH",
    "Student": 1359,
    "Teacher": 66
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC1046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHING YEH",
    "Student": 305,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC1047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PULAI",
    "Student": 549,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA 2",
    "Student": 1100,
    "Teacher": 60
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBC1049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LADANG GRISEK",
    "Student": 686,
    "Teacher": 35
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBC1050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEAH FAH",
    "Student": 297,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SENTRAL PALOH",
    "Student": 46,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAN BEE",
    "Student": 70,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAU MIN",
    "Student": 529,
    "Teacher": 35
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN CHIANG",
    "Student": 46,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG ENG",
    "Student": 406,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA I",
    "Student": 277,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA 2",
    "Student": 482,
    "Teacher": 35
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2010",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA 3",
    "Student": 547,
    "Teacher": 37
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG. GAJAH",
    "Student": 60,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAHANG",
    "Student": 333,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LAYANG",
    "Student": 211,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LIT TERK",
    "Student": 80,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MACHAP",
    "Student": 75,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PALOH",
    "Student": 261,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PA YAI",
    "Student": 851,
    "Teacher": 55
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PING MING",
    "Student": 781,
    "Teacher": 49
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAYONG",
    "Student": 34,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2024",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SOUTH MALAYA",
    "Student": 122,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TUAN POON",
    "Student": 485,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU MING",
    "Student": 35,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBC2030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SRI LALANG",
    "Student": 193,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBC3005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) GOGOK",
    "Student": 25,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBC3006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MAWAI",
    "Student": 379,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBC3007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NEW KOTA",
    "Student": 478,
    "Teacher": 34
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBC3008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAN YA",
    "Student": 22,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBC3012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA",
    "Student": 218,
    "Teacher": 22
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBC3014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEAY MIN",
    "Student": 5,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBC3015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAI SIN",
    "Student": 94,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBC3018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU HWA",
    "Student": 119,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBC3019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOK POON",
    "Student": 151,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBC4001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAO CHING",
    "Student": 325,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBC4002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG HUBONG",
    "Student": 64,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBC4003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JEMALUANG",
    "Student": 52,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBC4004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAI CHEE",
    "Student": 282,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AI HWA",
    "Student": 37,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AIK MING",
    "Student": 115,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BOON LIM",
    "Student": 33,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI SIN",
    "Student": 146,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN KWANG WAHYU",
    "Student": 283,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN TERH",
    "Student": 71,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 1 A",
    "Student": 122,
    "Teacher": 23
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5013",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 1 B",
    "Student": 174,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 2A",
    "Student": 91,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 2B",
    "Student": 180,
    "Teacher": 21
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA KETIGA",
    "Student": 647,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA PRESBYTERIAN",
    "Student": 552,
    "Teacher": 35
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 61,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SUN",
    "Student": 50,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HAU LIM",
    "Student": 42,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LIMBONG",
    "Student": 50,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA MING",
    "Student": 57,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5028",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEPONG",
    "Student": 72,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KIM KEE",
    "Student": 30,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LENGA",
    "Student": 50,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHAI",
    "Student": 224,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI YANG",
    "Student": 764,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PENGKALAN BUKIT",
    "Student": 73,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PU NAN",
    "Student": 649,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) RENCHONG",
    "Student": 29,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAN CHAI",
    "Student": 107,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SING HWA",
    "Student": 573,
    "Teacher": 36
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MING",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SOON CHENG",
    "Student": 723,
    "Teacher": 45
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SOON MONG",
    "Student": 139,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WEE SIN",
    "Student": 33,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YIH CHIAU",
    "Student": 31,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOK ENG",
    "Student": 82,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU ENG",
    "Student": 156,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU JERN",
    "Student": 332,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU MING",
    "Student": 32,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBC5056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHAI",
    "Student": 13,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BIN CHONG",
    "Student": 464,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEOW MIN",
    "Student": 436,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI CHIH",
    "Student": 19,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI MANG",
    "Student": 37,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIEN HWA",
    "Student": 29,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 42,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) GAU SAN",
    "Student": 169,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEN BOON",
    "Student": 99,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LEE MING",
    "Student": 19,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6010",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YORK",
    "Student": 127,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YU 1",
    "Student": 88,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YU(2)",
    "Student": 21,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6013",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YU 3",
    "Student": 21,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YU 4",
    "Student": 17,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YU 6",
    "Student": 39,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAN MAH",
    "Student": 37,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHIAO",
    "Student": 48,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHUN 1",
    "Student": 209,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHUN (2)",
    "Student": 144,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA",
    "Student": 63,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIU WOON",
    "Student": 28,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAH TONG",
    "Student": 346,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU MING",
    "Student": 35,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6024",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU MING 1",
    "Student": 496,
    "Teacher": 35
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBC6025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU MING 2",
    "Student": 468,
    "Teacher": 34
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AI CHUN",
    "Student": 90,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BEKOK",
    "Student": 155,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA NAN",
    "Student": 87,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHA'AH",
    "Student": 315,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CENTRAL SITE",
    "Student": 4,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAH KANG",
    "Student": 137,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JEMENTAH 1",
    "Student": 322,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JABI",
    "Student": 375,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JAGOH",
    "Student": 162,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7010",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LI CHI",
    "Student": 620,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KASAP",
    "Student": 256,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KARAS",
    "Student": 111,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LABIS",
    "Student": 538,
    "Teacher": 36
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SEG HWA",
    "Student": 453,
    "Teacher": 34
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT SIPUT",
    "Student": 546,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG TENGAH",
    "Student": 184,
    "Teacher": 21
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TENANG",
    "Student": 30,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAMBANG",
    "Student": 18,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TUA OOH",
    "Student": 62,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBC7022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JEMENTAH (2)",
    "Student": 228,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BATU",
    "Student": 141,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BEMBAN",
    "Student": 118,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAWIT",
    "Student": 643,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SEDENAK",
    "Student": 192,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SENGKANG",
    "Student": 182,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KULAI 1",
    "Student": 1027,
    "Teacher": 67
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8010",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KULAI 2",
    "Student": 1012,
    "Teacher": 64
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SALENG",
    "Student": 543,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8013",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SEELONG",
    "Student": 174,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SENAI",
    "Student": 711,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KULAI BESAR",
    "Student": 1429,
    "Teacher": 71
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBC8016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHENG",
    "Student": 991,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BEKOH",
    "Student": 38,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAN KUO",
    "Student": 668,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI MING 1",
    "Student": 564,
    "Teacher": 39
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG YU",
    "Student": 44,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN JU",
    "Student": 66,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 122,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 147,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 21,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 55,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KOK BENG",
    "Student": 31,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI MING (2)",
    "Student": 706,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9013",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAN HWA",
    "Student": 150,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI ENG",
    "Student": 116,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA",
    "Student": 315,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PUI NAN",
    "Student": 44,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAN YU",
    "Student": 70,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE CHUIN",
    "Student": 85,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LIAN HWA",
    "Student": 27,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEBUN BAHRU",
    "Student": 39,
    "Teacher": 12
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBC9021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAY HIAN",
    "Student": 36,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBD0050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SRI GADING",
    "Student": 18,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBD0051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SERI PELANGI",
    "Student": 82,
    "Teacher": 13
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JBD0058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG YONG PENG",
    "Student": 129,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBD1001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN YAHYA AWAL",
    "Student": 759,
    "Teacher": 49
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBD1004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TEBRAU",
    "Student": 390,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBD1005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MOUNT AUSTIN",
    "Student": 445,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBD1006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PERMAS JAYA",
    "Student": 300,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBD1007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG PLENTONG",
    "Student": 27,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBD1008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MASAI",
    "Student": 926,
    "Teacher": 59
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBD1009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PASIR GUDANG",
    "Student": 251,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBD1010",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) DESA CEMERLANG",
    "Student": 348,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBD1011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ULU TIRAM",
    "Student": 331,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBD1013",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MADOS",
    "Student": 14,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBD1022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG RINI",
    "Student": 683,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBD1025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) GELANG PATAH",
    "Student": 191,
    "Teacher": 20
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBD1026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN TUN AMINAH",
    "Student": 1866,
    "Teacher": 98
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBD1027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KANGKAR PULAI",
    "Student": 661,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JBD1028",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT SERAMPANG",
    "Student": 440,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JBD1029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR SERI ALAM",
    "Student": null,
    "Teacher": null
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LAYANG",
    "Student": 7,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ULU REMIS",
    "Student": 30,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TUN DR ISMAIL",
    "Student": 33,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SEMBRONG",
    "Student": 19,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN BUKIT RENGAM",
    "Student": 49,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SIMPANG RENGAM",
    "Student": 22,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SOUTHERN MALAY",
    "Student": 27,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT BENUT",
    "Student": 16,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LAMBAK",
    "Student": 62,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ELAEIS",
    "Student": 23,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN HAJI MANAN",
    "Student": 521,
    "Teacher": 37
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MENGKIBOL",
    "Student": 109,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PAMOL",
    "Student": 58,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAHANG BATU 24",
    "Student": 26,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NIYOR",
    "Student": 37,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) CEP.NIYOR KLUANG",
    "Student": 23,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JBD2053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN SETESYEN PALOH",
    "Student": 145,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBD3014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG REM",
    "Student": 43,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBD3015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN TAJUL",
    "Student": 135,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBD3016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PASAK",
    "Student": 19,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBD3017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PELEPAH",
    "Student": 48,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBD3018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG. NAM HENG",
    "Student": 32,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBD3019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG TELUK SENGAT",
    "Student": 11,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JBD3020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SUNGAI PAPAN",
    "Student": 16,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JBD4021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MERSING",
    "Student": 20,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBD5080",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN KHALIDI",
    "Student": 108,
    "Teacher": 15
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBD5084",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LANADRON",
    "Student": 69,
    "Teacher": 23
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBD5087",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BAN HENG",
    "Student": 12,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JBD5088",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TEMIANG RENCHONG",
    "Student": 68,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JBD6027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN PARIT IBRAHIM",
    "Student": 26,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BEKOK",
    "Student": 22,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LABIS",
    "Student": 112,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG VOULES",
    "Student": 73,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SEGAMAT",
    "Student": 48,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7067",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR SEGAMAT",
    "Student": 105,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7068",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG MUAR",
    "Student": 51,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7069",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG SENARUT",
    "Student": 12,
    "Teacher": 8
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7070",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BATU ANAM",
    "Student": 78,
    "Teacher": 16
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7071",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GOMALI",
    "Student": 9,
    "Teacher": 6
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7072",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG FORTROSE",
    "Student": 37,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7073",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NAGAPPA",
    "Student": 38,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JBD7074",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) CANTUMAN CHAAH",
    "Student": 211,
    "Teacher": 23
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBD8004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KULAI BESAR",
    "Student": 725,
    "Teacher": 56
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBD8005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KELAN",
    "Student": 220,
    "Teacher": 21
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBD8006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KULAI OIL PALM",
    "Student": 127,
    "Teacher": 14
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JBD8007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SEDENAK",
    "Student": 50,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBD9001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN SIALANG",
    "Student": 172,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBD9002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BEKOH",
    "Student": 24,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBD9003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NORDANAL",
    "Student": 27,
    "Teacher": 7
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBD9005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SAGIL",
    "Student": 43,
    "Teacher": 11
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBD9006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TANAH MERAH",
    "Student": 73,
    "Teacher": 10
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JBD9007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TANGKAH",
    "Student": 19,
    "Teacher": 9
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JCB1001",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS PRINCESS ELIZABETH",
    "Student": 31,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JCT0001",
    "Name": "SEKOLAH RENDAH AGAMA BERSEPADU BATU PAHAT",
    "Student": 439,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JCT1001",
    "Name": "SEKOLAH RENDAH AGAMA BERSEPADU JOHOR BAHRU",
    "Student": 465,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JCT2001",
    "Name": "SEKOLAH RENDAH AGAMA BERSEPADU KLUANG",
    "Student": 438,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JCT3001",
    "Name": "SEKOLAH RENDAH AGAMA BERSEPADU KOTA TINGGI",
    "Student": 411,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JCT4001",
    "Name": "SEKOLAH RENDAH AGAMA BERSEPADU MERSING",
    "Student": 392,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JCT5001",
    "Name": "SEKOLAH RENDAH AGAMA BERSEPADU MUAR",
    "Student": 428,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JCT6001",
    "Name": "SEKOLAH RENDAH AGAMA BERSEPADU PONTIAN",
    "Student": 408,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JCT7001",
    "Name": "SEKOLAH RENDAH AGAMA BERSEPADU SEGAMAT",
    "Student": 424,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0010",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' BENTARA LUAR",
    "Student": 1453,
    "Teacher": 117
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN AMINAH",
    "Student": 750,
    "Teacher": 64
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0012",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU MAHMOOD ISKANDAR",
    "Student": 646,
    "Teacher": 59
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0013",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SENGGARANG",
    "Student": 1076,
    "Teacher": 98
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0014",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI MEDAN",
    "Student": 1096,
    "Teacher": 105
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0015",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO SULAIMAN",
    "Student": 1009,
    "Teacher": 100
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0016",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO ONN",
    "Student": 1311,
    "Teacher": 117
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERMATA JAYA",
    "Student": 632,
    "Teacher": 53
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0018",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI GADING",
    "Student": 1565,
    "Teacher": 120
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0019",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATIN ONN JAFFAR",
    "Student": 1414,
    "Teacher": 118
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0020",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO SETH",
    "Student": 1132,
    "Teacher": 94
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0022",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGHULU SAAT",
    "Student": 838,
    "Teacher": 63
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANANG JAYA",
    "Student": 785,
    "Teacher": 62
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0024",
    "Name": "SEKOLAH MENENGAH SAINS BATU PAHAT",
    "Student": 818,
    "Teacher": 69
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEA0025",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SURIA PERDANA",
    "Student": 668,
    "Teacher": 52
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ISMAIL",
    "Student": 931,
    "Teacher": 90
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TASEK UTARA",
    "Student": 719,
    "Teacher": 76
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN SYED NASIR ISMAIL",
    "Student": 1666,
    "Teacher": 125
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI TEBRAU",
    "Student": 1011,
    "Teacher": 73
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI RAHMAT",
    "Student": 1650,
    "Teacher": 110
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SKUDAI",
    "Student": 1330,
    "Teacher": 111
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PASIR GUDANG",
    "Student": 1713,
    "Teacher": 127
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) ULU TEBRAU",
    "Student": 454,
    "Teacher": 38
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJUNG ADANG",
    "Student": 224,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN PELANGI",
    "Student": 610,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN JOHOR JAYA (1)",
    "Student": 1078,
    "Teacher": 109
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1046",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN TUN AMINAH",
    "Student": 1360,
    "Teacher": 83
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1047",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PASIR GUDANG 2",
    "Student": 784,
    "Teacher": 94
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1048",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU UDA",
    "Student": 1420,
    "Teacher": 118
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1049",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN UNIVERSITI",
    "Student": 1905,
    "Teacher": 148
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1050",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PERLING",
    "Student": 1850,
    "Teacher": 123
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1051",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN JOHOR JAYA 2",
    "Student": 1118,
    "Teacher": 105
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERMAS JAYA",
    "Student": 1259,
    "Teacher": 94
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN DAYA",
    "Student": 1180,
    "Teacher": 109
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DAMAI JAYA",
    "Student": 1375,
    "Teacher": 87
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAN SRI MOHAMED RAHMAT",
    "Student": 1711,
    "Teacher": 112
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS SULTAN ABU BAKAR",
    "Student": 459,
    "Teacher": 36
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA JAYA",
    "Student": 999,
    "Teacher": 77
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' USMAN AWANG",
    "Student": 1640,
    "Teacher": 113
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUTIARA RINI",
    "Student": 1839,
    "Teacher": 132
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI KOTA PUTERI",
    "Student": 899,
    "Teacher": 68
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN UNIVERSITI 2",
    "Student": 967,
    "Teacher": 90
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SELESA JAYA",
    "Student": 1048,
    "Teacher": 73
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1063",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR SERI ALAM",
    "Student": 1664,
    "Teacher": 123
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1064",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN RINTING 2",
    "Student": 1157,
    "Teacher": 78
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DESA CEMERLANG",
    "Student": 1026,
    "Teacher": 75
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1066",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PASIR PUTIH",
    "Student": 1953,
    "Teacher": 135
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1067",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN MOLEK",
    "Student": 1624,
    "Teacher": 124
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1068",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN MOUNT AUSTIN",
    "Student": 1664,
    "Teacher": 107
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI ALAM 2",
    "Student": 1256,
    "Teacher": 91
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1070",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTERI WANGSA",
    "Student": 1972,
    "Teacher": 129
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI KOTA PUTERI 2",
    "Student": 488,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN IMPIAN EMAS",
    "Student": 1537,
    "Teacher": 103
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN MEGAH RIA",
    "Student": 747,
    "Teacher": 59
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN DAYA 2",
    "Student": 1756,
    "Teacher": 115
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PASIR GUDANG 3",
    "Student": 984,
    "Teacher": 82
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1076",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERMAS JAYA 2",
    "Student": 993,
    "Teacher": 72
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1077",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA SKUDAI",
    "Student": 1036,
    "Teacher": 85
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1078",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOTA MASAI",
    "Student": 2284,
    "Teacher": 166
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TASEK UTARA 2",
    "Student": 862,
    "Teacher": 77
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1080",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA TEBRAU",
    "Student": 819,
    "Teacher": 69
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1081",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN PELANGI INDAH",
    "Student": 2070,
    "Teacher": 135
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1082",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN FATIMAH HASHIM",
    "Student": 1842,
    "Teacher": 120
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1083",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERMAS JAYA 3",
    "Student": 479,
    "Teacher": 45
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1084",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SELESA JAYA 2",
    "Student": 1039,
    "Teacher": 71
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1085",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN MUTIARA RINI 2",
    "Student": 1635,
    "Teacher": 105
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1086",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN NUSAJAYA",
    "Student": 1749,
    "Teacher": 96
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1087",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN BUKIT INDAH",
    "Student": 2120,
    "Teacher": 126
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1088",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SUTERA",
    "Student": 889,
    "Teacher": 84
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1089",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TG PUTERI RESORT",
    "Student": 1119,
    "Teacher": 83
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1090",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MOHD KHALID",
    "Student": 561,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1091",
    "Name": "SEKOLAH SENI MALAYSIA JOHOR",
    "Student": 329,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1092",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOTA MASAI 2",
    "Student": 3045,
    "Teacher": 180
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN NUSA DAMAI",
    "Student": 1727,
    "Teacher": 104
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1094",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI TIRAM",
    "Student": 1032,
    "Teacher": 71
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1095",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN DAMANSARA ALIFF",
    "Student": 800,
    "Teacher": 56
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1096",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR UDA UTAMA",
    "Student": 1390,
    "Teacher": 86
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1097",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAJIDI BARU 2",
    "Student": 1165,
    "Teacher": 90
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEA1098",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MEDINI",
    "Student": 639,
    "Teacher": 55
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA1099",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PULAI PERDANA",
    "Student": 1739,
    "Teacher": 116
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA1100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KANGKAR PULAI",
    "Student": 3037,
    "Teacher": 203
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEA1101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SCIENTEX",
    "Student": 2036,
    "Teacher": 120
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2029",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LAYANG-LAYANG",
    "Student": 664,
    "Teacher": 55
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2030",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JLN MENGKIBOL",
    "Student": 1068,
    "Teacher": 80
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN HUSSEIN ONN",
    "Student": 316,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAHANG",
    "Student": 504,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL JALIL",
    "Student": 1185,
    "Teacher": 133
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) KAHANG TIMUR",
    "Student": 429,
    "Teacher": 42
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LKTP BELITONG",
    "Student": 369,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN KLUANG BARAT",
    "Student": 560,
    "Teacher": 76
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIMPANG RENGAM",
    "Student": 1147,
    "Teacher": 99
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI INTAN",
    "Student": 697,
    "Teacher": 62
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR T6",
    "Student": 818,
    "Teacher": 66
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN KOTA TINGGI",
    "Student": 977,
    "Teacher": 78
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI LALANG",
    "Student": 665,
    "Teacher": 59
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SERI KLUANG",
    "Student": 736,
    "Teacher": 62
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI SEMBRONG",
    "Student": 269,
    "Teacher": 24
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI KOTA PALOH",
    "Student": 208,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' IBRAHIM MAJID",
    "Student": 371,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2046",
    "Name": "SEKOLAH MENENGAH SAINS SEMBRONG",
    "Student": 815,
    "Teacher": 71
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2047",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PERDANA",
    "Student": 881,
    "Teacher": 71
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEA2048",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI MACHAP",
    "Student": 525,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR EASTER",
    "Student": 475,
    "Teacher": 49
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN HABAB",
    "Student": 853,
    "Teacher": 87
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR KOTA TINGGI",
    "Student": 893,
    "Teacher": 85
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAN SRI JAAFAR ALBAR",
    "Student": 711,
    "Teacher": 64
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AIR TAWAR",
    "Student": 978,
    "Teacher": 74
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR MAS",
    "Student": 738,
    "Teacher": 72
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ADELA",
    "Student": 815,
    "Teacher": 72
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3046",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEMENCHU",
    "Student": 714,
    "Teacher": 62
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3047",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LOKMAN HAKIM",
    "Student": 469,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3048",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LINGGIU",
    "Student": 249,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3049",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR PENAWAR",
    "Student": 684,
    "Teacher": 56
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3050",
    "Name": "SEKOLAH MENENGAH SAINS KOTA TINGGI",
    "Student": 723,
    "Teacher": 60
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3051",
    "Name": "SEKOLAH SUKAN TUNKU MAHKOTA ISMAIL",
    "Student": 313,
    "Teacher": 104
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI AMAN",
    "Student": 523,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN KOTA JAYA",
    "Student": 762,
    "Teacher": 69
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJUNG PENGELIH",
    "Student": 74,
    "Teacher": 17
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SERI SAUJANA",
    "Student": 1004,
    "Teacher": 83
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA3056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ALAUDDIN",
    "Student": 610,
    "Teacher": 54
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA3057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR TENGGARA",
    "Student": 683,
    "Teacher": 77
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA3058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR TENGGARA 2",
    "Student": 591,
    "Teacher": 52
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA3059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PINANG",
    "Student": 903,
    "Teacher": 62
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEA3060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGERANG UTAMA",
    "Student": 830,
    "Teacher": 71
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JEA4034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) TENGGAROH",
    "Student": 695,
    "Teacher": 67
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JEA4035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MERSING",
    "Student": 963,
    "Teacher": 82
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JEA4036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NITAR",
    "Student": 336,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JEA4037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAN SRI ABDUL KADIR",
    "Student": 193,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JEA4038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ANJUNG BATU",
    "Student": 755,
    "Teacher": 58
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEA5021",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI MENANTI",
    "Student": 440,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEA5022",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' SRI AMAR DI RAJA",
    "Student": 910,
    "Teacher": 115
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEA5023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN PERAK",
    "Student": 1205,
    "Teacher": 108
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEA5024",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA MUDA",
    "Student": 724,
    "Teacher": 69
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEA5028",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT PASIR",
    "Student": 910,
    "Teacher": 74
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEA5032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEKAN BARU MUAR",
    "Student": 965,
    "Teacher": 102
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEA5035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN DR ISMAIL (STUDI)",
    "Student": 1261,
    "Teacher": 100
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEA5036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT NANING",
    "Student": 377,
    "Teacher": 36
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEA5037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR MAHARANI",
    "Student": 527,
    "Teacher": 57
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEA5039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ABONG",
    "Student": 1327,
    "Teacher": 105
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEA5041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN FELCRA BUKIT KEPONG",
    "Student": 349,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEA6005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI TANJUNG",
    "Student": 867,
    "Teacher": 78
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEA6006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO` PENGGAWA BARAT",
    "Student": 944,
    "Teacher": 83
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEA6007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI KUKUP",
    "Student": 858,
    "Teacher": 83
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEA6008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AYER BALOI",
    "Student": 904,
    "Teacher": 79
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEA6009",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PARIT BETAK",
    "Student": 639,
    "Teacher": 57
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEA6010",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' MOHD. YUNOS SULAIMAN",
    "Student": 1300,
    "Teacher": 114
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEA6011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAYU ARA PASONG",
    "Student": 503,
    "Teacher": 48
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEA6012",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAN SRI OSMAN MOHD SA'AT",
    "Student": 715,
    "Teacher": 63
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEA6013",
    "Name": "KOLEJ TINGKATAN ENAM PONTIAN",
    "Student": 175,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BULUH KASAP",
    "Student": 749,
    "Teacher": 71
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' BENTARA DALAM",
    "Student": 830,
    "Teacher": 84
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENANG STESEN",
    "Student": 568,
    "Teacher": 51
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BEKOK",
    "Student": 366,
    "Teacher": 34
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PALONG TIMUR",
    "Student": 325,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LKTP PEMANIS",
    "Student": 271,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (LKTP) MAOKIL",
    "Student": 185,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GEMEREH",
    "Student": 307,
    "Teacher": 33
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMARUL ARIFFIN",
    "Student": 323,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI JEMENTAH",
    "Student": 214,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUTRA",
    "Student": 810,
    "Teacher": 66
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI KENANGAN",
    "Student": 512,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEA7043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BALI",
    "Student": 435,
    "Teacher": 41
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA8001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SENAI",
    "Student": 1691,
    "Teacher": 125
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA8002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABDUL RAHMAN PUTRA",
    "Student": 1263,
    "Teacher": 129
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA8005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KELAPA SAWIT",
    "Student": 1416,
    "Teacher": 111
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA8006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KULAI BESAR",
    "Student": 711,
    "Teacher": 70
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA8007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN KOTA KULAI",
    "Student": 1066,
    "Teacher": 74
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA8011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN PUTRI",
    "Student": 1256,
    "Teacher": 99
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA8012",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUTRA",
    "Student": 1855,
    "Teacher": 136
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA8013",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN INDAHPURA 1",
    "Student": 1194,
    "Teacher": 92
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA8014",
    "Name": "SEKOLAH MENENGAH PENDIDIKAN KHAS VOKASIONAL INDAHPURA",
    "Student": 208,
    "Teacher": 97
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA8015",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT BATU",
    "Student": 591,
    "Teacher": 48
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEA8016",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUTRA 2",
    "Student": 1061,
    "Teacher": 71
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU MAHMUD ISKANDAR",
    "Student": 965,
    "Teacher": 101
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU TEMENGGONG AHMAD",
    "Student": 302,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN MAMAT",
    "Student": 1071,
    "Teacher": 92
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9004",
    "Name": "SEKOLAH MENENGAH SAINS MUAR",
    "Student": 737,
    "Teacher": 61
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PARIT BUNGA",
    "Student": 537,
    "Teacher": 63
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SAGIL TANGKAK",
    "Student": 564,
    "Teacher": 54
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI TANGKAK",
    "Student": 713,
    "Teacher": 64
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PESISIRAN PERDANA",
    "Student": 403,
    "Teacher": 36
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9009",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LEDANG",
    "Student": 1168,
    "Teacher": 106
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9010",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT GAMBIR",
    "Student": 1443,
    "Teacher": 117
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN SRI LANANG",
    "Student": 208,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JEA9012",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEI HWA",
    "Student": 1056,
    "Teacher": 92
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEB0015",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI BATU PAHAT",
    "Student": 713,
    "Teacher": 79
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEB0016",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) TEMENGGONG IBRAHIM",
    "Student": 853,
    "Teacher": 75
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEB0017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT",
    "Student": 697,
    "Teacher": 55
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEB0018",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUNSHI SULAIMAN",
    "Student": 1117,
    "Teacher": 102
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEB0019",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' SYED ESA",
    "Student": 1489,
    "Teacher": 121
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEB1001",
    "Name": "MAKTAB SULTAN ABU BAKAR",
    "Student": 847,
    "Teacher": 108
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEB1002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO JAAFAR",
    "Student": 571,
    "Teacher": 72
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEB1003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AMINUDDIN BAKI",
    "Student": 834,
    "Teacher": 81
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEB1004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SAINT JOSEPH",
    "Student": 433,
    "Teacher": 36
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEB1005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) SULTAN IBRAHIM",
    "Student": 820,
    "Teacher": 60
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEB1006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN INFANT JESUS CONVENT",
    "Student": 813,
    "Teacher": 55
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEB1007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTANAH ENGKU TUN AMINAH",
    "Student": 638,
    "Teacher": 51
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEB1009",
    "Name": "SEKOLAH TUN FATIMAH",
    "Student": 734,
    "Teacher": 59
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEB2037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI KLUANG",
    "Student": 865,
    "Teacher": 114
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEB2038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU ARIS BENDAHARA",
    "Student": 1386,
    "Teacher": 97
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEB2039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CANOSSIAN CONVENT",
    "Student": 793,
    "Teacher": 56
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JEB3043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LAKSAMANA",
    "Student": 1524,
    "Teacher": 126
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JEB4044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI MERSING",
    "Student": 561,
    "Teacher": 69
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JEB4045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAI CHEE",
    "Student": 247,
    "Teacher": 26
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEB5021",
    "Name": "SEKOLAH TINGGI MUAR",
    "Student": 679,
    "Teacher": 89
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEB5022",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST ANDREW",
    "Student": 629,
    "Teacher": 49
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEB5024",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI MUAR",
    "Student": 320,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEB5025",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (PEREMPUAN) SULTAN ABU BAKAR",
    "Student": 838,
    "Teacher": 70
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEB5026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT",
    "Student": 758,
    "Teacher": 70
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEB6012",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI PERHENTIAN",
    "Student": 834,
    "Teacher": 70
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEB6014",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO` ALI HJ AHMAD",
    "Student": 1568,
    "Teacher": 118
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEB7029",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI SEGAMAT",
    "Student": 884,
    "Teacher": 101
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEB7030",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEG HWA",
    "Student": 1574,
    "Teacher": 110
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEB7031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CANOSSIAN CONVENT",
    "Student": 290,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEB7032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LABIS",
    "Student": 437,
    "Teacher": 60
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEB7034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADUKA TUAN",
    "Student": 1119,
    "Teacher": 95
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEB8001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUNSHI ABDULLAH",
    "Student": 524,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEE0018",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN SARDON",
    "Student": 622,
    "Teacher": 69
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEE0019",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN ISMAIL",
    "Student": 1510,
    "Teacher": 136
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEE0020",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEMERAH",
    "Student": 672,
    "Teacher": 73
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEE0034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK MENTERI",
    "Student": 859,
    "Teacher": 84
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEE0040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN YONG PENG",
    "Student": 1403,
    "Teacher": 101
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JEE0041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU PUTRA",
    "Student": 1476,
    "Teacher": 117
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEE1004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GELANG PATAH",
    "Student": 1461,
    "Teacher": 109
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEE1005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO PENGGAWA TIMUR",
    "Student": 956,
    "Teacher": 91
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JEE1008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO ABDUL RAHMAN YASSIN",
    "Student": 1038,
    "Teacher": 80
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JEE1011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ULU TIRAM",
    "Student": 1967,
    "Teacher": 144
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEE2042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO HJ HASSAN YUNOS",
    "Student": 353,
    "Teacher": 59
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEE2043",
    "Name": "SEKOLAH MENENGAH SAINS JOHOR",
    "Student": 570,
    "Teacher": 54
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEE2044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PALOH",
    "Student": 629,
    "Teacher": 56
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JEE2045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' ABD RAHMAN ANDAK",
    "Student": 884,
    "Teacher": 73
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JEE4036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN UNGKU HUSIN",
    "Student": 1199,
    "Teacher": 107
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEE5023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU MAHKOTA",
    "Student": 1108,
    "Teacher": 99
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEE5027",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ALAUDDIN RIAYAT SHAH 1",
    "Student": 1351,
    "Teacher": 104
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEE5030",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LENGA",
    "Student": 301,
    "Teacher": 34
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JEE5031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN SULAIMAN NINAM SHAH",
    "Student": 411,
    "Teacher": 51
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEE6009",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELOK KERANG",
    "Student": 525,
    "Teacher": 53
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEE6011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEKAN NENAS",
    "Student": 888,
    "Teacher": 66
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JEE6017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BENUT",
    "Student": 831,
    "Teacher": 78
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEE7033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUNSHI IBRAHIM",
    "Student": 799,
    "Teacher": 78
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEE7035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' AHMAD ARSHAD",
    "Student": 465,
    "Teacher": 43
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEE7036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JEMENTAH",
    "Student": 570,
    "Teacher": 44
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JEE7037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHAAH",
    "Student": 476,
    "Teacher": 50
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JEE8001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN IBRAHIM",
    "Student": 1622,
    "Teacher": 146
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JFT0001",
    "Name": "SEKOLAH MENENGAH AGAMA PARIT RAJA",
    "Student": 519,
    "Teacher": 47
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JFT0002",
    "Name": "SEKOLAH MENENGAH AGAMA JAWAHIR AL-ULUM",
    "Student": 249,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JFT0003",
    "Name": "SEKOLAH MENENGAH AGAMA PARIT SULONG",
    "Student": 231,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JFT0004",
    "Name": "SEKOLAH MENENGAH AGAMA ATTARBIAH AL ISLAMIAH",
    "Student": 292,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JFT1001",
    "Name": "MADRASAH ALATTAS ALARABIAH JOHOR",
    "Student": 273,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JFT1002",
    "Name": "SEKOLAH MENENGAH AGAMA AL QURAN WADDIN",
    "Student": 322,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JFT2001",
    "Name": "SEKOLAH MENENGAH AGAMA ARABIAH KLUANG",
    "Student": 323,
    "Teacher": 31
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JFT3001",
    "Name": "SEKOLAH MENENGAH AGAMA KOTA TINGGI",
    "Student": 366,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KULAI",
    "Kod": "JFT3002",
    "Name": "SEKOLAH MENENGAH AGAMA BANDAR TENGGARA",
    "Student": 314,
    "Teacher": 39
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JFT4001",
    "Name": "SEKOLAH MENENGAH AGAMA AL-KHAIRIAH AL-ISLAMIAH",
    "Student": 341,
    "Teacher": 35
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JFT5001",
    "Name": "SEKOLAH MENENGAH AGAMA AD-DINIAH",
    "Student": 135,
    "Teacher": 19
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JFT5006",
    "Name": "SEKOLAH MENENGAH AGAMA AT-TANWIRIAH",
    "Student": 254,
    "Teacher": 28
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JFT6001",
    "Name": "SEKOLAH MENENGAH AGAMA (ARAB) AN-NUR",
    "Student": 370,
    "Teacher": 37
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JFT6002",
    "Name": "SEKOLAH MAAHAD PONTIAN",
    "Student": 597,
    "Teacher": 52
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JFT6003",
    "Name": "SEKOLAH MENENGAH AGAMA BUGISIAH",
    "Student": 418,
    "Teacher": 49
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JFT7001",
    "Name": "SEKOLAH MENENGAH AGAMA AL-KHAIRIAH SEGAMAT",
    "Student": 278,
    "Teacher": 29
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JFT9001",
    "Name": "SEKOLAH MENENGAH AGAMA LUGHATIL ARABIAH",
    "Student": 152,
    "Teacher": 27
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JFT9002",
    "Name": "SEKOLAH MENENGAH AGAMA LUGHATUL QURAN",
    "Student": 494,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JFT9003",
    "Name": "SEKOLAH MENENGAH AGAMA SHAMSUDDINIAH",
    "Student": 294,
    "Teacher": 40
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD TANGKAK",
    "Kod": "JFT9004",
    "Name": "SEKOLAH MENENGAH AGAMA DARUL ULUM",
    "Student": 101,
    "Teacher": 18
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JHA0002",
    "Name": "KOLEJ VOKASIONAL BATU PAHAT",
    "Student": 822,
    "Teacher": 124
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD BATU PAHAT",
    "Kod": "JHA0003",
    "Name": "KOLEJ VOKASIONAL TUN HUSSEIN ONN",
    "Student": 127,
    "Teacher": 25
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JHA1001",
    "Name": "KOLEJ VOKASIONAL TANJUNG PUTERI",
    "Student": 527,
    "Teacher": 79
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JHA1002",
    "Name": "KOLEJ VOKASIONAL (ERT) AZIZAH",
    "Student": 604,
    "Teacher": 99
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JHA1003",
    "Name": "KOLEJ VOKASIONAL PERDAGANGAN",
    "Student": 475,
    "Teacher": 75
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JHA1004",
    "Name": "KOLEJ VOKASIONAL PASIR GUDANG",
    "Student": 121,
    "Teacher": 30
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KLUANG",
    "Kod": "JHA2001",
    "Name": "KOLEJ VOKASIONAL KLUANG",
    "Student": 950,
    "Teacher": 140
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JHA3002",
    "Name": "KOLEJ VOKASIONAL KOTA TINGGI",
    "Student": 710,
    "Teacher": 111
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JHA5001",
    "Name": "KOLEJ VOKASIONAL MUAR",
    "Student": 803,
    "Teacher": 139
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JHA5002",
    "Name": "KOLEJ VOKASIONAL PAGOH",
    "Student": 150,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PONTIAN",
    "Kod": "JHA6001",
    "Name": "KOLEJ VOKASIONAL TANJUNG PIAI",
    "Student": 207,
    "Teacher": 38
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JHA7002",
    "Name": "KOLEJ VOKASIONAL SEGAMAT",
    "Student": 654,
    "Teacher": 104
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD JOHOR BAHRU",
    "Kod": "JKE1001",
    "Name": "SEKOLAH MENENGAH TEKNIK JOHOR BAHRU",
    "Student": 594,
    "Teacher": 46
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD PASIR GUDANG",
    "Kod": "JRA1001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA JOHOR BAHRU",
    "Student": 674,
    "Teacher": 54
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD KOTA TINGGI",
    "Kod": "JRA3001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA BANDAR PENAWAR",
    "Student": 594,
    "Teacher": 59
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MERSING",
    "Kod": "JRA4001",
    "Name": "SEKOLAH MENENGAH SAINS SULTAN ISKANDAR",
    "Student": 546,
    "Teacher": 52
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JRA5036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA MAAHAD MUAR",
    "Student": 802,
    "Teacher": 147
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JRA5037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA PAGOH",
    "Student": 703,
    "Teacher": 51
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD MUAR",
    "Kod": "JRA5038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA HAJI MOHD YASSIN",
    "Student": 461,
    "Teacher": 32
  },
  {
    "NationalState": "JOHOR",
    "PPD": "PPD SEGAMAT",
    "Kod": "JRA7001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SEGAMAT",
    "Student": 585,
    "Teacher": 57
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0001",
    "Name": "SEKOLAH KEBANGSAAN MOHD ARIFF ABDULLAH",
    "Student": 305,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0002",
    "Name": "SEKOLAH KEBANGSAAN TELOI KANAN",
    "Student": 258,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0003",
    "Name": "SEKOLAH KEBANGSAAN TEMBAK",
    "Student": 415,
    "Teacher": 39
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0004",
    "Name": "SEKOLAH KEBANGSAAN BADANG",
    "Student": 201,
    "Teacher": 21
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0005",
    "Name": "SEKOLAH KEBANGSAAN ASAM JAWA/CAROK KELIAN",
    "Student": 368,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0006",
    "Name": "SEKOLAH KEBANGSAAN SERI INAS",
    "Student": 229,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0007",
    "Name": "SEKOLAH KEBANGSAAN PENGHULU ABU BAKAR",
    "Student": 367,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0008",
    "Name": "SEKOLAH KEBANGSAAN BALING",
    "Student": 663,
    "Teacher": 62
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0009",
    "Name": "SEKOLAH KEBANGSAAN DALAM WANG",
    "Student": 191,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0010",
    "Name": "SEKOLAH KEBANGSAAN TUNKU PUAN HABSAH",
    "Student": 155,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0011",
    "Name": "SEKOLAH KEBANGSAAN IBOI",
    "Student": 288,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0012",
    "Name": "SEKOLAH KEBANGSAAN SYED SHEH",
    "Student": 447,
    "Teacher": 42
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0013",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SADEK",
    "Student": 212,
    "Teacher": 23
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0014",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG PADANG",
    "Student": 170,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0015",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SERA",
    "Student": 155,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0016",
    "Name": "SEKOLAH KEBANGSAAN KUALA KUPANG",
    "Student": 510,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0017",
    "Name": "SEKOLAH KEBANGSAAN KUALA PEGANG",
    "Student": 698,
    "Teacher": 62
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0018",
    "Name": "SEKOLAH KEBANGSAAN KUALA KUANG",
    "Student": 244,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0019",
    "Name": "SEKOLAH KEBANGSAAN KUALA MERAH",
    "Student": 300,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0020",
    "Name": "SEKOLAH KEBANGSAAN KUALA SAMAK",
    "Student": 192,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0021",
    "Name": "SEKOLAH KEBANGSAAN LANAI",
    "Student": 656,
    "Teacher": 53
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0022",
    "Name": "SEKOLAH KEBANGSAAN PULAI",
    "Student": 500,
    "Teacher": 38
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0023",
    "Name": "SEKOLAH KEBANGSAAN SIONG",
    "Student": 423,
    "Teacher": 46
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0024",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG PARI",
    "Student": 222,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0025",
    "Name": "SEKOLAH KEBANGSAAN TAWAR",
    "Student": 291,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0026",
    "Name": "SEKOLAH KEBANGSAAN TIAK",
    "Student": 186,
    "Teacher": 22
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0027",
    "Name": "SEKOLAH KEBANGSAAN TITI GANTONG",
    "Student": 225,
    "Teacher": 22
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0028",
    "Name": "SEKOLAH KEBANGSAAN RAMBONG PULAI",
    "Student": 64,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0029",
    "Name": "SEKOLAH KEBANGSAAN SERI BAYU",
    "Student": 176,
    "Teacher": 20
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0030",
    "Name": "SEKOLAH KEBANGSAAN KUALA KETIL",
    "Student": 913,
    "Teacher": 66
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0031",
    "Name": "SEKOLAH KEBANGSAAN SERI JEMERLI",
    "Student": 125,
    "Teacher": 14
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0032",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SELARONG",
    "Student": 289,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0062",
    "Name": "SEKOLAH KEBANGSAAN TELOK TEDURI",
    "Student": 132,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0063",
    "Name": "SEKOLAH KEBANGSAAN MALAU",
    "Student": 143,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0065",
    "Name": "SEKOLAH KEBANGSAAN PAYA BESAR",
    "Student": 244,
    "Teacher": 23
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0066",
    "Name": "SEKOLAH KEBANGSAAN BINJUL",
    "Student": 154,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0067",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BANDAR",
    "Student": 280,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0068",
    "Name": "SEKOLAH KEBANGSAAN ULU TAWAR",
    "Student": 246,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0069",
    "Name": "SEKOLAH KEBANGSAAN ULU BAKAI",
    "Student": 114,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0070",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG KETIL",
    "Student": 201,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0071",
    "Name": "SEKOLAH KEBANGSAAN BINJUL DALAM",
    "Student": 190,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBA0073",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG KEDA",
    "Student": 351,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1001",
    "Name": "SEKOLAH KEBANGSAAN AYER PUTEH",
    "Student": 113,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1002",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BAHARU",
    "Student": 445,
    "Teacher": 43
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1004",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SALLEH",
    "Student": 173,
    "Teacher": 22
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1005",
    "Name": "SEKOLAH KEBANGSAAN LUBOK BUNTAR",
    "Student": 315,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1006",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG KERAT TELUNJUK",
    "Student": 180,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1007",
    "Name": "SEKOLAH KEBANGSAAN RELAU",
    "Student": 299,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1008",
    "Name": "SEKOLAH KEBANGSAAN SELAMA",
    "Student": 207,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1009",
    "Name": "SEKOLAH KEBANGSAAN DATO' HAJI ZAINUDDIN",
    "Student": 445,
    "Teacher": 46
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1010",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BATU",
    "Student": 190,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1011",
    "Name": "SEKOLAH KEBANGSAAN SG KECHIL ILIR",
    "Student": 313,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1012",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KECHIL ULU",
    "Student": 228,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1013",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TAKA",
    "Student": 127,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA1014",
    "Name": "SEKOLAH KEBANGSAAN SG TENGAS",
    "Student": 181,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2007",
    "Name": "SEKOLAH KEBANGSAAN ALOR JANGGUS",
    "Student": 613,
    "Teacher": 50
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2008",
    "Name": "SEKOLAH KEBANGSAAN SERI AMAR DIRAJA",
    "Student": 328,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2010",
    "Name": "SEKOLAH KEBANGSAAN GUNONG",
    "Student": 154,
    "Teacher": 24
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2011",
    "Name": "SEKOLAH KEBANGSAAN KOTA RENTANG",
    "Student": 125,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2012",
    "Name": "SEKOLAH KEBANGSAAN WAN SULAIMAN SIDIQ",
    "Student": 243,
    "Teacher": 26
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2013",
    "Name": "SEKOLAH KEBANGSAAN HJ ABDULLAH SADUN",
    "Student": 205,
    "Teacher": 22
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2014",
    "Name": "SEKOLAH KEBANGSAAN DATO' SHAARI",
    "Student": 600,
    "Teacher": 51
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2015",
    "Name": "SEKOLAH KEBANGSAAN LENGKUAS",
    "Student": 112,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2016",
    "Name": "SEKOLAH KEBANGSAAN MERGONG",
    "Student": 1086,
    "Teacher": 78
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2017",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG GUNUNG",
    "Student": 188,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2018",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG TIGA",
    "Student": 171,
    "Teacher": 23
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2019",
    "Name": "SEKOLAH KEBANGSAAN SUKA MENANTI",
    "Student": 391,
    "Teacher": 39
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2020",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BARU",
    "Student": 266,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2021",
    "Name": "SEKOLAH KEBANGSAAN TITI GAJAH",
    "Student": 400,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2022",
    "Name": "SEKOLAH KEBANGSAAN KUBANG TEDUH",
    "Student": 123,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2023",
    "Name": "SEKOLAH KEBANGSAAN ALOR MELINTANG",
    "Student": 128,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2024",
    "Name": "SEKOLAH KEBANGSAAN ALOR MENGKUDU",
    "Student": 443,
    "Teacher": 35
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2025",
    "Name": "SEKOLAH KEBANGSAAN TUNKU RAUDZAH",
    "Student": 429,
    "Teacher": 38
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2026",
    "Name": "SEKOLAH KEBANGSAAN HUTAN KAMPONG",
    "Student": 328,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2028",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG GELAM",
    "Student": 209,
    "Teacher": 21
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2029",
    "Name": "SEKOLAH KEBANGSAAN GERIGIS",
    "Student": 161,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2030",
    "Name": "SEKOLAH KEBANGSAAN SRI TAMAN",
    "Student": 205,
    "Teacher": 21
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2031",
    "Name": "SEKOLAH KEBANGSAAN KUALA KEDAH",
    "Student": 423,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2032",
    "Name": "SEKOLAH KEBANGSAAN LANGGAR",
    "Student": 559,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2033",
    "Name": "SEKOLAH KEBANGSAAN HAJI ABU BAKAR",
    "Student": 323,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2036",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG PERAK BARU",
    "Student": 179,
    "Teacher": 20
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2037",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG PERAK",
    "Student": 154,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2038",
    "Name": "SEKOLAH KEBANGSAAN HAJI ISMAIL",
    "Student": 308,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2039",
    "Name": "SEKOLAH KEBANGSAAN JALAN PEGAWAI",
    "Student": 902,
    "Teacher": 65
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2040",
    "Name": "SEKOLAH KEBANGSAAN SG KOROK LAMA",
    "Student": 396,
    "Teacher": 45
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2041",
    "Name": "SEKOLAH KEBANGSAAN HJ IDRIS TAJAR",
    "Student": 300,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2042",
    "Name": "SEKOLAH KEBANGSAAN DATO' WAN MOHD SAMAN",
    "Student": 647,
    "Teacher": 58
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2045",
    "Name": "SEKOLAH KEBANGSAAN TELOK KECHAI",
    "Student": 457,
    "Teacher": 39
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2049",
    "Name": "SEKOLAH KEBANGSAAN BULUH LIMA",
    "Student": 265,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2055",
    "Name": "SEKOLAH KEBANGSAAN KANGKONG",
    "Student": 280,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2058",
    "Name": "SEKOLAH KEBANGSAAN LAHAR BUDI",
    "Student": 93,
    "Teacher": 13
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2061",
    "Name": "SEKOLAH KEBANGSAAN HJ HASSAN ITAM",
    "Student": 665,
    "Teacher": 63
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2064",
    "Name": "SEKOLAH KEBANGSAAN TEBENGAU",
    "Student": 367,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2065",
    "Name": "SEKOLAH KEBANGSAAN HAJI WAHAB",
    "Student": 114,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2066",
    "Name": "SEKOLAH KEBANGSAAN DARUL HIKMAH",
    "Student": 190,
    "Teacher": 22
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2073",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG JAWA",
    "Student": 166,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2074",
    "Name": "SEKOLAH KEBANGSAAN SRI MAHAWANGSA",
    "Student": 188,
    "Teacher": 20
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2084",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PINANG",
    "Student": 473,
    "Teacher": 42
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2085",
    "Name": "SEKOLAH KEBANGSAAN TAMAN UDA",
    "Student": 1042,
    "Teacher": 73
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2087",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS ALOR SETAR",
    "Student": 77,
    "Teacher": 36
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2088",
    "Name": "SEKOLAH KEBANGSAAN TAMAN RAKYAT",
    "Student": 933,
    "Teacher": 69
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2089",
    "Name": "SEKOLAH KEBANGSAAN SERI PANTAI",
    "Student": 416,
    "Teacher": 39
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2090",
    "Name": "SEKOLAH KEBANGSAAN SERI PERDANA",
    "Student": 777,
    "Teacher": 58
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2091",
    "Name": "SEKOLAH KEBANGSAAN PEREMBA",
    "Student": 601,
    "Teacher": 58
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2092",
    "Name": "SEKOLAH KEBANGSAAN JALAN DATUK KUMBAR",
    "Student": 540,
    "Teacher": 57
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2093",
    "Name": "SEKOLAH KEBANGSAAN KEBUN PINANG",
    "Student": 334,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2094",
    "Name": "SEKOLAH KEBANGSAAN TAMAN AMAN",
    "Student": 512,
    "Teacher": 50
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2095",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BERSATU",
    "Student": 636,
    "Teacher": 60
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2096",
    "Name": "SEKOLAH KEBANGSAAN HAJI SALLEH MASRI",
    "Student": 497,
    "Teacher": 43
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBA2098",
    "Name": "SEKOLAH KEBANGSAAN TAMAN AWANA",
    "Student": 675,
    "Teacher": 57
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3001",
    "Name": "SEKOLAH KEBANGSAAN GURUN (PUSAT)",
    "Student": 494,
    "Teacher": 48
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3002",
    "Name": "SEKOLAH KEBANGSAAN JENIANG (PUSAT)",
    "Student": 713,
    "Teacher": 61
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3003",
    "Name": "SEKOLAH KEBANGSAAN BATU HAMPAR",
    "Student": 271,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3004",
    "Name": "SEKOLAH KEBANGSAAN SERI WANGSA",
    "Student": 184,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3005",
    "Name": "SEKOLAH KEBANGSAAN SULTAN MUDZAFFAR SHAH",
    "Student": 977,
    "Teacher": 72
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3006",
    "Name": "SEKOLAH KEBANGSAAN AMAN JAYA",
    "Student": 283,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3007",
    "Name": "SEKOLAH KEBANGSAAN BKT SELAMBAU",
    "Student": 529,
    "Teacher": 50
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3008",
    "Name": "SEKOLAH KEBANGSAAN BEDONG",
    "Student": 478,
    "Teacher": 48
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3009",
    "Name": "SEKOLAH KEBANGSAAN LADANG HARVARD",
    "Student": 68,
    "Teacher": 12
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3010",
    "Name": "SEKOLAH KEBANGSAAN DATARAN MUDA",
    "Student": 160,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3011",
    "Name": "SEKOLAH KEBANGSAAN SRI JERAI",
    "Student": 541,
    "Teacher": 45
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3012",
    "Name": "SEKOLAH KEBANGSAAN PANTAI PRAI",
    "Student": 184,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3013",
    "Name": "SEKOLAH KEBANGSAAN HJ. OMAR TAHIR",
    "Student": 357,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3014",
    "Name": "SEKOLAH KEBANGSAAN PATANI PARA",
    "Student": 99,
    "Teacher": 12
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3015",
    "Name": "SEKOLAH KEBANGSAAN SEMELING",
    "Student": 824,
    "Teacher": 56
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3016",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LALANG",
    "Student": 760,
    "Teacher": 53
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3017",
    "Name": "SEKOLAH KEBANGSAAN SUNGKAP PARA",
    "Student": 59,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3018",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TOK PAWANG",
    "Student": 90,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3019",
    "Name": "SEKOLAH KEBANGSAAN TANJONG DAWAI",
    "Student": 254,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3020",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KECHIL",
    "Student": 262,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3021",
    "Name": "SEKOLAH KEBANGSAAN DARUL AMAN",
    "Student": 294,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3022",
    "Name": "SEKOLAH KEBANGSAAN BUKIT MERIAM",
    "Student": 112,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3023",
    "Name": "SEKOLAH KEBANGSAAN HAJI SULAIMAN",
    "Student": 99,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3024",
    "Name": "SEKOLAH KEBANGSAAN SRI GEDONG",
    "Student": 1052,
    "Teacher": 73
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3025",
    "Name": "SEKOLAH KEBANGSAAN KOTA KUALA MUDA",
    "Student": 423,
    "Teacher": 41
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3027",
    "Name": "SEKOLAH KEBANGSAAN SERI KUALA",
    "Student": 245,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3028",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG KUALA SIN",
    "Student": 145,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3029",
    "Name": "SEKOLAH KEBANGSAAN SERI PINANG",
    "Student": 1072,
    "Teacher": 64
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3030",
    "Name": "SEKOLAH KEBANGSAAN PINANG TUNGGAL",
    "Student": 832,
    "Teacher": 55
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3031",
    "Name": "SEKOLAH KEBANGSAAN RANTAU PANJANG",
    "Student": 191,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3032",
    "Name": "SEKOLAH KEBANGSAAN SIDAM KIRI",
    "Student": 593,
    "Teacher": 47
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3033",
    "Name": "SEKOLAH KEBANGSAAN SIMPOR",
    "Student": 267,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3034",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PASIR",
    "Student": 685,
    "Teacher": 50
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3035",
    "Name": "SEKOLAH KEBANGSAAN KHIR JOHARI",
    "Student": 403,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3036",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PETANI",
    "Student": 678,
    "Teacher": 59
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3037",
    "Name": "SEKOLAH KEBANGSAAN TIKAM BATU",
    "Student": 691,
    "Teacher": 55
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3038",
    "Name": "SEKOLAH KEBANGSAAN BATU EMPAT",
    "Student": 1180,
    "Teacher": 81
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3039",
    "Name": "SEKOLAH KEBANGSAAN BERAPIT",
    "Student": 438,
    "Teacher": 49
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3040",
    "Name": "SEKOLAH KEBANGSAAN KEM LAPANGAN TERBANG",
    "Student": 393,
    "Teacher": 34
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3041",
    "Name": "SEKOLAH KEBANGSAAN PETANI JAYA",
    "Student": 399,
    "Teacher": 41
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3042",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS SUNGAI PETANI",
    "Student": 87,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3043",
    "Name": "SEKOLAH KEBANGSAAN TUNKU ISMAIL",
    "Student": 536,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3044",
    "Name": "SEKOLAH KEBANGSAAN TAMAN RIA",
    "Student": 1390,
    "Teacher": 101
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3045",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LAYAR",
    "Student": 591,
    "Teacher": 56
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3046",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG PASIR",
    "Student": 367,
    "Teacher": 37
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3047",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU SUNGAI LALANG",
    "Student": 1238,
    "Teacher": 90
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3048",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SERI WANG",
    "Student": 1109,
    "Teacher": 72
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3049",
    "Name": "SEKOLAH KEBANGSAAN TASEK APONG",
    "Student": 866,
    "Teacher": 58
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3050",
    "Name": "SEKOLAH KEBANGSAAN BAKAR ARANG",
    "Student": 615,
    "Teacher": 55
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3051",
    "Name": "SEKOLAH KEBANGSAAN TAMAN INTAN",
    "Student": 221,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3052",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PASIR KECHIL",
    "Student": 1407,
    "Teacher": 84
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3053",
    "Name": "SEKOLAH KEBANGSAAN TELUK WANG",
    "Student": 440,
    "Teacher": 39
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3054",
    "Name": "SEKOLAH KEBANGSAAN AMBANGAN HEIGHTS",
    "Student": 1276,
    "Teacher": 95
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3055",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KIARA",
    "Student": 1497,
    "Teacher": 113
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3056",
    "Name": "SEKOLAH KEBANGSAAN BANDAR PUTERI JAYA",
    "Student": 2505,
    "Teacher": 138
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA3057",
    "Name": "SEKOLAH KEBANGSAAN BANDAR LAGUNA MERBOK",
    "Student": 933,
    "Teacher": 64
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4001",
    "Name": "SEKOLAH KEBANGSAAN AYER HITAM",
    "Student": 766,
    "Teacher": 64
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4002",
    "Name": "SEKOLAH KEBANGSAAN HAJI WAN YAHYA",
    "Student": 248,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4003",
    "Name": "SEKOLAH KEBANGSAAN BATU 8 CHANGLUN",
    "Student": 320,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4004",
    "Name": "SEKOLAH KEBANGSAAN DATO' SYED NAHAR",
    "Student": 244,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4005",
    "Name": "SEKOLAH KEBANGSAAN BINJAL",
    "Student": 430,
    "Teacher": 35
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4006",
    "Name": "SEKOLAH KEBANGSAAN GUAR NAPAI",
    "Student": 107,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4007",
    "Name": "SEKOLAH KEBANGSAAN DATO' WAN KEMARA",
    "Student": 1273,
    "Teacher": 92
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4008",
    "Name": "SEKOLAH KEBANGSAAN GELONG",
    "Student": 213,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4009",
    "Name": "SEKOLAH KEBANGSAAN JITRA",
    "Student": 522,
    "Teacher": 48
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4010",
    "Name": "SEKOLAH KEBANGSAAN KODIANG",
    "Student": 498,
    "Teacher": 51
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4011",
    "Name": "SEKOLAH KEBANGSAAN DATO' SRI SYED AHMAD",
    "Student": 217,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4012",
    "Name": "SEKOLAH KEBANGSAAN HOSBA",
    "Student": 420,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4013",
    "Name": "SEKOLAH KEBANGSAAN MEGAT DEWA",
    "Student": 158,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4014",
    "Name": "SEKOLAH KEBANGSAAN MANGGOL BONGOR",
    "Student": 191,
    "Teacher": 20
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4015",
    "Name": "SEKOLAH KEBANGSAAN MATANG PAKU",
    "Student": 181,
    "Teacher": 23
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4016",
    "Name": "SEKOLAH KEBANGSAAN PADANG PERAHU",
    "Student": 226,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4017",
    "Name": "SEKOLAH KEBANGSAAN TUNKU LAKSAMANA",
    "Student": 241,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4018",
    "Name": "SEKOLAH KEBANGSAAN PIDA TIGA",
    "Student": 202,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4019",
    "Name": "SEKOLAH KEBANGSAAN HAKIM TEH",
    "Student": 201,
    "Teacher": 23
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4020",
    "Name": "SEKOLAH KEBANGSAAN SANGLANG",
    "Student": 223,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4021",
    "Name": "SEKOLAH KEBANGSAAN SIPUTEH",
    "Student": 581,
    "Teacher": 51
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4022",
    "Name": "SEKOLAH KEBANGSAAN TOK KEPAK",
    "Student": 171,
    "Teacher": 20
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4023",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BUKIT KAYU HITAM",
    "Student": 618,
    "Teacher": 43
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4024",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LAKA SELATAN",
    "Student": 275,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4025",
    "Name": "SEKOLAH KEBANGSAAN ANAK-ANAK ANGKATAN TENTERA",
    "Student": 262,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4026",
    "Name": "SEKOLAH KEBANGSAAN JERLUN",
    "Student": 362,
    "Teacher": 35
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4027",
    "Name": "SEKOLAH KEBANGSAAN SERI BANAI",
    "Student": 242,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4028",
    "Name": "SEKOLAH KEBANGSAAN PENGHULU HJ DARUS",
    "Student": 220,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4029",
    "Name": "SEKOLAH KEBANGSAAN MALAU",
    "Student": 478,
    "Teacher": 35
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4030",
    "Name": "SEKOLAH KEBANGSAAN TELOK MALEK",
    "Student": 105,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4031",
    "Name": "SEKOLAH KEBANGSAAN KEPALA BATAS",
    "Student": 575,
    "Teacher": 43
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4032",
    "Name": "SEKOLAH KEBANGSAAN SERI MUDA",
    "Student": 546,
    "Teacher": 57
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4033",
    "Name": "SEKOLAH KEBANGSAAN JITRA 2",
    "Student": 342,
    "Teacher": 34
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4034",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU DARULAMAN",
    "Student": 1088,
    "Teacher": 75
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4056",
    "Name": "SEKOLAH KEBANGSAAN PULAU CHAPA",
    "Student": 127,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4057",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) BKT TANGGA",
    "Student": 286,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4058",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TINGGI",
    "Student": 543,
    "Teacher": 47
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4059",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU SINTOK",
    "Student": 1164,
    "Teacher": 80
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4060",
    "Name": "SEKOLAH KEBANGSAAN PULAU NYIOR",
    "Student": 273,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4061",
    "Name": "SEKOLAH KEBANGSAAN JITRA 3",
    "Student": 588,
    "Teacher": 48
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4062",
    "Name": "SEKOLAH KEBANGSAAN PADANG PEKAN",
    "Student": 558,
    "Teacher": 50
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4063",
    "Name": "SEKOLAH KEBANGSAAN PUTAT",
    "Student": 289,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4064",
    "Name": "SEKOLAH KEBANGSAAN PAYA KEMUNTING",
    "Student": 606,
    "Teacher": 55
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBA4065",
    "Name": "SEKOLAH KEBANGSAAN DARULAMAN HEIGHTS",
    "Student": 1081,
    "Teacher": 85
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5001",
    "Name": "SEKOLAH KEBANGSAAN BUKIT SELARONG",
    "Student": 161,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5002",
    "Name": "SEKOLAH KEBANGSAAN LDG BUKIT SIDIM",
    "Student": 80,
    "Teacher": 13
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5003",
    "Name": "SEKOLAH KEBANGSAAN LADANG DUBLIN",
    "Student": 118,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5004",
    "Name": "SEKOLAH KEBANGSAAN GUAR LOBAK",
    "Student": 1247,
    "Teacher": 91
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5005",
    "Name": "SEKOLAH KEBANGSAAN JUNJONG",
    "Student": 247,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5006",
    "Name": "SEKOLAH KEBANGSAAN KELADI",
    "Student": 565,
    "Teacher": 41
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5007",
    "Name": "SEKOLAH KEBANGSAAN KULIM",
    "Student": 395,
    "Teacher": 35
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5008",
    "Name": "SEKOLAH KEBANGSAAN SRI KULIM",
    "Student": 534,
    "Teacher": 52
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5009",
    "Name": "SEKOLAH KEBANGSAAN LABU BESAR",
    "Student": 475,
    "Teacher": 41
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5010",
    "Name": "SEKOLAH KEBANGSAAN SRI LIMAU",
    "Student": 531,
    "Teacher": 46
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5011",
    "Name": "SEKOLAH KEBANGSAAN LUNAS JAYA",
    "Student": 786,
    "Teacher": 66
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5012",
    "Name": "SEKOLAH KEBANGSAAN MERBAU PULAS",
    "Student": 308,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5013",
    "Name": "SEKOLAH KEBANGSAAN MAHANG",
    "Student": 286,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5014",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KARANGAN",
    "Student": 753,
    "Teacher": 60
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5015",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG TOK DIK",
    "Student": 1009,
    "Teacher": 84
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5016",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KOB",
    "Student": 768,
    "Teacher": 65
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5017",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SELUANG",
    "Student": 854,
    "Teacher": 59
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5018",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ULAR",
    "Student": 1009,
    "Teacher": 72
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5019",
    "Name": "SEKOLAH KEBANGSAAN SERI LINDUNGAN RAJA",
    "Student": 414,
    "Teacher": 43
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5020",
    "Name": "SEKOLAH KEBANGSAAN TERAP",
    "Student": 284,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5021",
    "Name": "SEKOLAH KEBANGSAAN GUNUNG BONGSU",
    "Student": 133,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5022",
    "Name": "SEKOLAH KEBANGSAAN PAGAR MUSIH",
    "Student": 256,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5023",
    "Name": "SEKOLAH KEBANGSAAN KULIM BANDAR",
    "Student": 168,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5024",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG TIONG",
    "Student": 229,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5025",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SELASIH",
    "Student": 924,
    "Teacher": 70
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5026",
    "Name": "SEKOLAH KEBANGSAAN TAMAN KENARI",
    "Student": 838,
    "Teacher": 65
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5027",
    "Name": "SEKOLAH KEBANGSAAN TAMAN RIA",
    "Student": 1419,
    "Teacher": 101
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5028",
    "Name": "SEKOLAH KEBANGSAAN JALAN PAYA BESAR",
    "Student": 758,
    "Teacher": 57
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5029",
    "Name": "SEKOLAH KEBANGSAAN TAMAN HI-TECH",
    "Student": 1045,
    "Teacher": 70
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5030",
    "Name": "SEKOLAH KEBANGSAAN AIR MERAH",
    "Student": 961,
    "Teacher": 72
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5031",
    "Name": "SEKOLAH KEBANGSAAN TAMAN JELUTONG",
    "Student": 578,
    "Teacher": 42
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5032",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MUTIARA",
    "Student": 990,
    "Teacher": 74
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBA5033",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MAHSURI",
    "Student": 920,
    "Teacher": 66
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6001",
    "Name": "SEKOLAH KEBANGSAAN LUBUK CHEMPEDAK",
    "Student": 62,
    "Teacher": 13
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6002",
    "Name": "SEKOLAH KEBANGSAAN BAYAS",
    "Student": 638,
    "Teacher": 49
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6003",
    "Name": "SEKOLAH KEBANGSAAN EWA",
    "Student": 365,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6004",
    "Name": "SEKOLAH KEBANGSAAN KEDAWANG",
    "Student": 776,
    "Teacher": 56
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6006",
    "Name": "SEKOLAH KEBANGSAAN SERI NEGERI",
    "Student": 1054,
    "Teacher": 76
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6007",
    "Name": "SEKOLAH KEBANGSAAN KUALA TERIANG",
    "Student": 623,
    "Teacher": 45
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6008",
    "Name": "SEKOLAH KEBANGSAAN PADANG MATSIRAT",
    "Student": 884,
    "Teacher": 72
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6009",
    "Name": "SEKOLAH KEBANGSAAN PULAU TUBA",
    "Student": 123,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6010",
    "Name": "SEKOLAH KEBANGSAAN SERI LAGENDA",
    "Student": 333,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6011",
    "Name": "SEKOLAH KEBANGSAAN TEMONYONG",
    "Student": 598,
    "Teacher": 52
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6012",
    "Name": "SEKOLAH KEBANGSAAN ULU MELAKA",
    "Student": 867,
    "Teacher": 65
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6013",
    "Name": "SEKOLAH KEBANGSAAN LANGKAWI",
    "Student": 597,
    "Teacher": 50
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6020",
    "Name": "SEKOLAH KEBANGSAAN SLT BAGAN NYIOR",
    "Student": 56,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6021",
    "Name": "SEKOLAH KEBANGSAAN NYIOR CHABANG",
    "Student": 358,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6022",
    "Name": "SEKOLAH KEBANGSAAN KILIM",
    "Student": 237,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBA6023",
    "Name": "SEKOLAH KEBANGSAAN SG MENGHULU",
    "Student": 574,
    "Teacher": 43
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7001",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TEMBAGA",
    "Student": 312,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7002",
    "Name": "SEKOLAH KEBANGSAAN KUALA NERANG",
    "Student": 543,
    "Teacher": 58
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7003",
    "Name": "SEKOLAH KEBANGSAAN KURONG HITAM",
    "Student": 283,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7004",
    "Name": "SEKOLAH KEBANGSAAN TOH PUAN SHARIFAH HANIFAH",
    "Student": 175,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7005",
    "Name": "SEKOLAH KEBANGSAAN NAKA",
    "Student": 545,
    "Teacher": 49
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7006",
    "Name": "SEKOLAH KEBANGSAAN PANGLIMA AWANG",
    "Student": 263,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7007",
    "Name": "SEKOLAH KEBANGSAAN NAMI",
    "Student": 203,
    "Teacher": 26
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7008",
    "Name": "SEKOLAH KEBANGSAAN PADANG SANAI",
    "Student": 217,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7009",
    "Name": "SEKOLAH KEBANGSAAN PADANG TERAP",
    "Student": 490,
    "Teacher": 46
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7010",
    "Name": "SEKOLAH KEBANGSAAN TANDOP BESAR",
    "Student": 445,
    "Teacher": 42
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7011",
    "Name": "SEKOLAH KEBANGSAAN DATIN FATIMAH",
    "Student": 217,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7012",
    "Name": "SEKOLAH KEBANGSAAN PEDU",
    "Student": 470,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7013",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BELUKAR",
    "Student": 400,
    "Teacher": 34
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7014",
    "Name": "SEKOLAH KEBANGSAAN TUALAK",
    "Student": 295,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7015",
    "Name": "SEKOLAH KEBANGSAAN LUBUK MERBAU",
    "Student": 487,
    "Teacher": 47
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7016",
    "Name": "SEKOLAH KEBANGSAAN PERIK",
    "Student": 153,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7018",
    "Name": "SEKOLAH KEBANGSAAN LAMDIN",
    "Student": 110,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7019",
    "Name": "SEKOLAH KEBANGSAAN KUBANG PALAS",
    "Student": 142,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBA7020",
    "Name": "SEKOLAH KEBANGSAAN SERI BAKTI",
    "Student": 161,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8001",
    "Name": "SEKOLAH KEBANGSAAN BATU LIMA",
    "Student": 278,
    "Teacher": 34
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8002",
    "Name": "SEKOLAH KEBANGSAAN CHEPIR",
    "Student": 434,
    "Teacher": 45
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8003",
    "Name": "SEKOLAH KEBANGSAAN GULAU",
    "Student": 333,
    "Teacher": 34
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8004",
    "Name": "SEKOLAH KEBANGSAAN HUJONG BANDAR",
    "Student": 174,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8005",
    "Name": "SEKOLAH KEBANGSAAN JENERI",
    "Student": 628,
    "Teacher": 56
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8006",
    "Name": "SEKOLAH KEBANGSAAN KG BETONG",
    "Student": 274,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8007",
    "Name": "SEKOLAH KEBANGSAAN HJ HUSSAIN",
    "Student": 293,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8008",
    "Name": "SEKOLAH KEBANGSAAN KG KOTA BUKIT",
    "Student": 158,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8009",
    "Name": "SEKOLAH KEBANGSAAN SERI AMPANG MUDA",
    "Student": 318,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8010",
    "Name": "SEKOLAH KEBANGSAAN CHEMARA",
    "Student": 114,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8011",
    "Name": "SEKOLAH KEBANGSAAN KALAI",
    "Student": 135,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8012",
    "Name": "SEKOLAH KEBANGSAAN PAYA TERENDAM",
    "Student": 252,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8013",
    "Name": "SEKOLAH KEBANGSAAN PADANG CHICHAK",
    "Student": 188,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8014",
    "Name": "SEKOLAH KEBANGSAAN SIK",
    "Student": 666,
    "Teacher": 62
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8015",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU BERIS JAYA",
    "Student": 293,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8016",
    "Name": "SEKOLAH KEBANGSAAN TELOI TUA",
    "Student": 351,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8017",
    "Name": "SEKOLAH KEBANGSAAN DANGLAU",
    "Student": 111,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8018",
    "Name": "SEKOLAH KEBANGSAAN SIK DALAM",
    "Student": 203,
    "Teacher": 23
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8019",
    "Name": "SEKOLAH KEBANGSAAN KOTA AUR",
    "Student": 104,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8020",
    "Name": "SEKOLAH KEBANGSAAN BATU 8",
    "Student": 224,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBA8021",
    "Name": "SEKOLAH KEBANGSAAN TELOI TIMUR",
    "Student": 333,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9001",
    "Name": "SEKOLAH KEBANGSAAN GUAR CHEMPEDAK",
    "Student": 630,
    "Teacher": 53
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9002",
    "Name": "SEKOLAH KEBANGSAAN PADANG LUMAT",
    "Student": 314,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9003",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KERING",
    "Student": 122,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9004",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LIMAU",
    "Student": 398,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9005",
    "Name": "SEKOLAH KEBANGSAAN HAJI KASSIM JASIN",
    "Student": 132,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9006",
    "Name": "SEKOLAH KEBANGSAAN ULU SEDAKA",
    "Student": 270,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9007",
    "Name": "SEKOLAH KEBANGSAAN DULANG",
    "Student": 265,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9008",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG PAUH",
    "Student": 180,
    "Teacher": 20
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9009",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG SETAR",
    "Student": 87,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9010",
    "Name": "SEKOLAH KEBANGSAAN LANGKASUKA",
    "Student": 345,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9011",
    "Name": "SEKOLAH KEBANGSAAN TOK MAT SALLEH",
    "Student": 94,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9012",
    "Name": "SEKOLAH KEBANGSAAN TEROI",
    "Student": 316,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9013",
    "Name": "SEKOLAH KEBANGSAAN YAN KECHIL",
    "Student": 457,
    "Teacher": 39
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9014",
    "Name": "SEKOLAH KEBANGSAAN HJ NYAK GAM",
    "Student": 320,
    "Teacher": 41
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9016",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BESAR",
    "Student": 392,
    "Teacher": 38
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9017",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG BULOH",
    "Student": 165,
    "Teacher": 20
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9018",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DEDAP",
    "Student": 153,
    "Teacher": 21
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9019",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG TIGA SG DAUN",
    "Student": 156,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9020",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DAUN TENGAH",
    "Student": 122,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9021",
    "Name": "SEKOLAH KEBANGSAAN SINGKIR",
    "Student": 311,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBA9022",
    "Name": "SEKOLAH KEBANGSAAN HAJI HUSSIN DOL",
    "Student": 823,
    "Teacher": 55
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA004",
    "Name": "SEKOLAH KEBANGSAAN KUBOR PANJANG",
    "Student": 534,
    "Teacher": 48
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA005",
    "Name": "SEKOLAH KEBANGSAAN PADANG DURIAN",
    "Student": 210,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA006",
    "Name": "SEKOLAH KEBANGSAAN POKOK ASAM",
    "Student": 287,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA035",
    "Name": "SEKOLAH KEBANGSAAN PAYA RAWA",
    "Student": 298,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA046",
    "Name": "SEKOLAH KEBANGSAAN PENGHULU JUSOH",
    "Student": 366,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA048",
    "Name": "SEKOLAH KEBANGSAAN ALOR BESAR",
    "Student": 101,
    "Teacher": 14
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA050",
    "Name": "SEKOLAH KEBANGSAAN BKT CHORAS",
    "Student": 350,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA051",
    "Name": "SEKOLAH KEBANGSAAN BKT RAYA DALAM",
    "Student": 255,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA052",
    "Name": "SEKOLAH KEBANGSAAN HAJI ABDUL RAHMAN",
    "Student": 335,
    "Teacher": 41
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA053",
    "Name": "SEKOLAH KEBANGSAAN CHAROK KUDONG",
    "Student": 194,
    "Teacher": 22
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA054",
    "Name": "SEKOLAH KEBANGSAAN GUAR KEPAYANG",
    "Student": 222,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA056",
    "Name": "SEKOLAH KEBANGSAAN KG RAMBAI",
    "Student": 164,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA057",
    "Name": "SEKOLAH KEBANGSAAN KG CHEGAR",
    "Student": 278,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA059",
    "Name": "SEKOLAH KEBANGSAAN PENDANG",
    "Student": 903,
    "Teacher": 77
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA060",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BARU",
    "Student": 376,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA062",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TIANG",
    "Student": 465,
    "Teacher": 45
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA063",
    "Name": "SEKOLAH KEBANGSAAN TANAH MERAH",
    "Student": 340,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA067",
    "Name": "SEKOLAH KEBANGSAAN AYER PUTEH",
    "Student": 438,
    "Teacher": 38
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA068",
    "Name": "SEKOLAH KEBANGSAAN HJ MOHAMAD ARIFF",
    "Student": 364,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA069",
    "Name": "SEKOLAH KEBANGSAAN BUKIT JENUN",
    "Student": 485,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA070",
    "Name": "SEKOLAH KEBANGSAAN BUKIT JAMBUL",
    "Student": 216,
    "Teacher": 26
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA071",
    "Name": "SEKOLAH KEBANGSAAN BUKIT GENTING",
    "Student": 137,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA075",
    "Name": "SEKOLAH KEBANGSAAN KUALA RIMAU",
    "Student": 120,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA076",
    "Name": "SEKOLAH KEBANGSAAN POKOK TAI",
    "Student": 377,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA077",
    "Name": "SEKOLAH KEBANGSAAN HAJI MAT DAHAN",
    "Student": 140,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA078",
    "Name": "SEKOLAH KEBANGSAAN SYED IBRAHIM",
    "Student": 467,
    "Teacher": 46
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA083",
    "Name": "SEKOLAH KEBANGSAAN BENDANG RAJA",
    "Student": 161,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA158",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG PULAI TOKAI",
    "Student": 132,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA159",
    "Name": "SEKOLAH KEBANGSAAN PAYA MENGKUANG",
    "Student": 249,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA160",
    "Name": "SEKOLAH KEBANGSAAN HUJUNG KETON",
    "Student": 158,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA161",
    "Name": "SEKOLAH KEBANGSAAN PAYA MAK INSUN",
    "Student": 375,
    "Teacher": 37
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA162",
    "Name": "SEKOLAH KEBANGSAAN TUNKU INTAN SAFINAZ",
    "Student": 531,
    "Teacher": 42
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBAA163",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SIPUT",
    "Student": 91,
    "Teacher": 14
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB001",
    "Name": "SEKOLAH KEBANGSAAN WAN ABDUL SAMAD",
    "Student": 717,
    "Teacher": 51
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB002",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BUKIT",
    "Student": 264,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB003",
    "Name": "SEKOLAH KEBANGSAAN KUALA LANJUT",
    "Student": 228,
    "Teacher": 24
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB004",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TAMPOI",
    "Student": 241,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB005",
    "Name": "SEKOLAH KEBANGSAAN JABI",
    "Student": 273,
    "Teacher": 35
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB006",
    "Name": "SEKOLAH KEBANGSAAN NAWA",
    "Student": 208,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB007",
    "Name": "SEKOLAH KEBANGSAAN TELAGA MAS",
    "Student": 219,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB008",
    "Name": "SEKOLAH KEBANGSAAN TELUK JAMAT",
    "Student": 186,
    "Teacher": 20
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB009",
    "Name": "SEKOLAH KEBANGSAAN TUALANG",
    "Student": 385,
    "Teacher": 34
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB010",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PAYONG",
    "Student": 287,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB011",
    "Name": "SEKOLAH KEBANGSAAN BUKIT HIJAU",
    "Student": 165,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB012",
    "Name": "SEKOLAH KEBANGSAAN KG PAYA",
    "Student": 117,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB013",
    "Name": "SEKOLAH KEBANGSAAN KUBANG LERET",
    "Student": 104,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB014",
    "Name": "SEKOLAH KEBANGSAAN POKOK SENA",
    "Student": 650,
    "Teacher": 51
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBAB015",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PAK KIAU",
    "Student": 377,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBB0033",
    "Name": "SEKOLAH KEBANGSAAN BATU PEKAKA",
    "Student": 427,
    "Teacher": 46
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBB0034",
    "Name": "SEKOLAH KEBANGSAAN TUNKU PUTERA",
    "Student": 467,
    "Teacher": 42
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBB1015",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG PASIR",
    "Student": 209,
    "Teacher": 26
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBB1016",
    "Name": "SEKOLAH KEBANGSAAN JALAN SELAMA",
    "Student": 176,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBB2079",
    "Name": "SEKOLAH KEBANGSAAN SRI GUNONG",
    "Student": 313,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBB2080",
    "Name": "SEKOLAH KEBANGSAAN BOHOR",
    "Student": 207,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBB2081",
    "Name": "SEKOLAH KEBANGSAAN ISKANDAR",
    "Student": 482,
    "Teacher": 49
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBB2082",
    "Name": "SEKOLAH KEBANGSAAN HJ MOHD SHARIFF",
    "Student": 286,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBB2083",
    "Name": "SEKOLAH KEBANGSAAN TUNKU ABDUL HALIM",
    "Student": 167,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBB2084",
    "Name": "SEKOLAH KEBANGSAAN ALOR SETAR",
    "Student": 118,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBB2085",
    "Name": "SEKOLAH KEBANGSAAN SULTANAH ASMA",
    "Student": 540,
    "Teacher": 47
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBB2086",
    "Name": "SEKOLAH KEBANGSAAN CONVENT",
    "Student": 427,
    "Teacher": 41
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBB3038",
    "Name": "SEKOLAH KEBANGSAAN SERI AMAN",
    "Student": 587,
    "Teacher": 45
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBB3039",
    "Name": "SEKOLAH KEBANGSAAN FATHER BARRE'S CONVENT",
    "Student": 503,
    "Teacher": 42
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBB3040",
    "Name": "SEKOLAH KEBANGSAAN IBRAHIM",
    "Student": 646,
    "Teacher": 53
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBB3041",
    "Name": "SEKOLAH KEBANGSAAN ST THERESA",
    "Student": 145,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBB4033",
    "Name": "SEKOLAH KEBANGSAAN SULTAN AHMAD TAJUDDIN",
    "Student": 1037,
    "Teacher": 88
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBB5021",
    "Name": "SEKOLAH KEBANGSAAN ST ANNE'S CONVENT",
    "Student": 473,
    "Teacher": 43
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBB5023",
    "Name": "SEKOLAH KEBANGSAAN TUNKU ABDUL MALIK",
    "Student": 1221,
    "Teacher": 96
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBB7016",
    "Name": "SEKOLAH KEBANGSAAN TUNKU ABDUL RAHMAN PUTRA",
    "Student": 677,
    "Teacher": 53
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBC0040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HWA",
    "Student": 50,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBC0041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN",
    "Student": 37,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBC0042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG LALANG",
    "Student": 32,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBC0043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA KETIL",
    "Student": 155,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBC0044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PENG MIN",
    "Student": 12,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBC0045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SENG YOK",
    "Student": 47,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBC0046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YEOK CHEE",
    "Student": 218,
    "Teacher": 23
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBC0047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POI CHEE",
    "Student": 10,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC1019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEONG CHEN",
    "Student": 82,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC1020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG HWA SKI",
    "Student": 94,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC1021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG HWA",
    "Student": 71,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC1022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POAY CHAI",
    "Student": 23,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC1023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SERDANG",
    "Student": 109,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC1024",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YEOK KHEONG",
    "Student": 66,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2109",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BOON HWA",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2112",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG YU",
    "Student": 21,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2113",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) EIK CHOON",
    "Student": 142,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2115",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEAT HWA (K)",
    "Student": 852,
    "Teacher": 46
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2116",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEAT HWA (H)",
    "Student": 621,
    "Teacher": 43
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2117",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEAT HWA (S)",
    "Student": 599,
    "Teacher": 43
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2118",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEE CHEE",
    "Student": 228,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2119",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LONG SEONG",
    "Student": 43,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2121",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LONG CHUAN",
    "Student": 208,
    "Teacher": 21
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2122",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MIN SIN",
    "Student": 85,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2123",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAN KWANG",
    "Student": 83,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2124",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PUMPONG",
    "Student": 302,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2125",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PENG MIN",
    "Student": 637,
    "Teacher": 47
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2126",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA",
    "Student": 651,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2127",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI SHIH",
    "Student": 163,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2128",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SOON JIAN",
    "Student": 61,
    "Teacher": 12
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2129",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SOON CHENG",
    "Student": 85,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2130",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN",
    "Student": 137,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2132",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAI CHONG",
    "Student": 49,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2133",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YIH MIN",
    "Student": 208,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBC2135",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YIH CHOON",
    "Student": 20,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHING CHONG",
    "Student": 121,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG CHENG",
    "Student": 44,
    "Teacher": 12
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG HWA",
    "Student": 336,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 321,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3062",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) FUH SUN",
    "Student": 88,
    "Teacher": 12
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HOON BONG",
    "Student": 27,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3064",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JENIANG",
    "Student": 192,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MIN TERK",
    "Student": 997,
    "Teacher": 57
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MAH WAH",
    "Student": 179,
    "Teacher": 23
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3067",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN KUO MIN",
    "Student": 129,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3068",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PENG MIN",
    "Student": 37,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3069",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN KWANG",
    "Student": 1150,
    "Teacher": 67
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3070",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN 'A'",
    "Student": 488,
    "Teacher": 36
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3071",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN 'B'",
    "Student": 657,
    "Teacher": 45
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3072",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HWA",
    "Student": 45,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3073",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAI TONG",
    "Student": 377,
    "Teacher": 36
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEKAN LAMA",
    "Student": 680,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC3105",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LIN KHAY",
    "Student": 310,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBC4042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 553,
    "Teacher": 42
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBC4043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG HWA",
    "Student": 83,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBC4044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE NAN",
    "Student": 11,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBC4045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA MIN",
    "Student": 153,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBC4046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LAM MIN",
    "Student": 41,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBC4047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI MIN",
    "Student": 146,
    "Teacher": 18
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBC4048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YIT MIN",
    "Student": 276,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBC4049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUH MIN",
    "Student": 64,
    "Teacher": 22
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AIK CHEE",
    "Student": 14,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIO MIN 'A'",
    "Student": 262,
    "Teacher": 24
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIO MIN 'B'",
    "Student": 629,
    "Teacher": 39
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG CHENG",
    "Student": 317,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HUA MIN",
    "Student": 94,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA MIN",
    "Student": 523,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI MIN",
    "Student": 33,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN",
    "Student": 35,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN",
    "Student": 65,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN TEONG MAHANG",
    "Student": 39,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SHANG CHENG",
    "Student": 302,
    "Teacher": 24
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBC5043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KELANG LAMA",
    "Student": 346,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBC6017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 475,
    "Teacher": 39
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBC6018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MIN NAM",
    "Student": 77,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KBC7019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KOU HUA",
    "Student": 106,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBC8019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 197,
    "Teacher": 21
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC9025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AIK MIN",
    "Student": 36,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC9026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 60,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC9027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI ENG",
    "Student": 8,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC9028",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA",
    "Student": 16,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC9029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK MIN",
    "Student": 23,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC9030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YANG KAO",
    "Student": 93,
    "Teacher": 12
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBC9031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YORK KHOON",
    "Student": 14,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBCA110",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BOON TEIK",
    "Student": 109,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBCA114",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JUNUN",
    "Student": 47,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBCA134",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YEANG CHENG",
    "Student": 140,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBCB001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 41,
    "Teacher": 12
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBCB002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TONG YUH",
    "Student": 314,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBD0048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BADENOCH",
    "Student": null,
    "Teacher": null
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBD0050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BINJOL",
    "Student": 13,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBD0052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BUKIT SEMBILAN",
    "Student": 15,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBD0053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KATUMBA",
    "Student": 26,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBD0054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KUALA KETIL",
    "Student": 15,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBD0055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BATU PEKAKA",
    "Student": 129,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBD0056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KIM SENG",
    "Student": 20,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBD0057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MALAKOFF",
    "Student": 13,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBD0058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KUPANG",
    "Student": 14,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KBD0059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PELAM",
    "Student": 16,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD1025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUNTAR",
    "Student": 20,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD1026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) GANESAR",
    "Student": 76,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBD2137",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BARATHY",
    "Student": 123,
    "Teacher": 17
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBD2138",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) THIRUVALLUVAR",
    "Student": 32,
    "Teacher": 13
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3074",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BEDONG",
    "Student": 226,
    "Teacher": 23
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3075",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) HARVARD BHG I",
    "Student": 78,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3076",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) HARVARD 2",
    "Student": 19,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3077",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG HARVARD BHG 3",
    "Student": 87,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3078",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG BATU",
    "Student": 20,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3079",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SUNGAI BONGKOK",
    "Student": 16,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3080",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SUNGAI PUNTAR",
    "Student": 36,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3081",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI TOK PAWANG",
    "Student": 213,
    "Teacher": 24
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3082",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG TUPAH",
    "Student": 21,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3083",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGKAP PARA",
    "Student": 14,
    "Teacher": 8
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3084",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) ARUMUGAM PILLAI",
    "Student": 334,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3085",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN",
    "Student": 139,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3086",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KALAIMAGAL",
    "Student": 171,
    "Teacher": 24
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3087",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MAHAJOTHI",
    "Student": 506,
    "Teacher": 42
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3088",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KUALA MUDA BHG HOME",
    "Student": 67,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3090",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PATANI PARA",
    "Student": 15,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3091",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SCARBORO BHG 2",
    "Student": 42,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3093",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SOMASUNDRAM",
    "Student": 192,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3094",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SARASWATHY",
    "Student": 505,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3095",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI GETAH",
    "Student": 155,
    "Teacher": 14
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3096",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PALANISAMY KUMARAN",
    "Student": 150,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3097",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG LUBOK SEGINTAH",
    "Student": 82,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KALAIVAANI",
    "Student": 95,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBD3107",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN KELADI",
    "Student": 324,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBD4050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) CHANGLUN",
    "Student": 60,
    "Teacher": 11
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBD4051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PAYA KAMUNTING",
    "Student": 93,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBD4052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) DARUL AMAN",
    "Student": 87,
    "Teacher": 12
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT SELARONG",
    "Student": 71,
    "Teacher": 15
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG HENRIETTA",
    "Student": 252,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PADANG MEIHA",
    "Student": 99,
    "Teacher": 12
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG VICTORIA",
    "Student": 169,
    "Teacher": 20
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG WELLESLEY",
    "Student": 433,
    "Teacher": 37
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ANAK KULIM",
    "Student": 29,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BAGAN SENA",
    "Student": 15,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT MERTAJAM",
    "Student": 200,
    "Teacher": 24
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT SIDIM",
    "Student": 50,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KULIM",
    "Student": 672,
    "Teacher": 49
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI ULAR",
    "Student": 151,
    "Teacher": 16
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI DINGIN",
    "Student": 12,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG DUBLIN BHG 5",
    "Student": 13,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG DUBLIN BHG. 7",
    "Student": 8,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SOMME",
    "Student": 28,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KBD5061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KO SARANGAPANY",
    "Student": 478,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBD6019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI RAYA",
    "Student": 108,
    "Teacher": 13
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KBDA140",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT JENUN",
    "Student": 19,
    "Teacher": 7
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KBDB001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JABI",
    "Student": 29,
    "Teacher": 10
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBE3011",
    "Name": "SEKOLAH KEBANGSAAN MERBOK (PUSAT)",
    "Student": 291,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KBE3043",
    "Name": "SEKOLAH KEBANGSAAN TUAN SYED JAN AL-JAFFRI",
    "Student": 540,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBE4023",
    "Name": "SEKOLAH KEBANGSAAN TUNKU BENDAHARA",
    "Student": 538,
    "Teacher": 45
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KBE4024",
    "Name": "SEKOLAH KEBANGSAAN TUNJANG",
    "Student": 569,
    "Teacher": 56
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBE6001",
    "Name": "SEKOLAH KEBANGSAAN PENGHULU AHMAD",
    "Student": 891,
    "Teacher": 70
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KBE6005",
    "Name": "SEKOLAH KEBANGSAAN KELIBANG",
    "Student": 625,
    "Teacher": 56
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KBE8017",
    "Name": "SEKOLAH KEBANGSAAN SERI DUSUN",
    "Student": 349,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KCA5001",
    "Name": "SEKOLAH RENDAH ISLAM NURUL HIDAYAH PENGHULU ELANG",
    "Student": 679,
    "Teacher": 44
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KCAA001",
    "Name": "SEKOLAH RENDAH ISLAM TARBIAH AL-AWLADIAH",
    "Student": 186,
    "Teacher": 23
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KCT3001",
    "Name": "SEKOLAH RENDAH AKADEMI ISLAM HJ. ABDULLAH SUBOH",
    "Student": 159,
    "Teacher": 20
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KCT3002",
    "Name": "SEKOLAH RENDAH ISLAM AL-IHSANIAH",
    "Student": 473,
    "Teacher": 34
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEA0035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BALING",
    "Student": 881,
    "Teacher": 99
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEA0036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIONG",
    "Student": 806,
    "Teacher": 66
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEA0064",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELOI KANAN",
    "Student": 360,
    "Teacher": 38
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEA0065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJONG PUTERI",
    "Student": 1154,
    "Teacher": 84
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEA0067",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JERAI",
    "Student": 757,
    "Teacher": 59
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEA0068",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PARIT PANJANG",
    "Student": 784,
    "Teacher": 64
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEA0069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SYED ABU BAKAR",
    "Student": 867,
    "Teacher": 76
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEA0070",
    "Name": "SEKOLAH KHAS BALING",
    "Student": 402,
    "Teacher": 46
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEA0071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAKAI",
    "Student": 576,
    "Teacher": 54
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEA0072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BONGOR",
    "Student": 507,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA1001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SELAMA",
    "Student": 480,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA1002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LUBUK BUNTAR",
    "Student": 472,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA1003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERDANG BARU",
    "Student": 398,
    "Teacher": 35
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2090",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTANAH BAHIYAH",
    "Student": 1015,
    "Teacher": 77
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2091",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' SYED OMAR",
    "Student": 1015,
    "Teacher": 80
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEPALA BATAS",
    "Student": 892,
    "Teacher": 69
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2094",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEBERANG PERAK",
    "Student": 362,
    "Teacher": 57
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2095",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LANGGAR",
    "Student": 504,
    "Teacher": 46
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2096",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO'WAN MOHD SAMAN",
    "Student": 559,
    "Teacher": 55
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2097",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEAT HWA 2",
    "Student": 923,
    "Teacher": 67
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2161",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAJAR",
    "Student": 477,
    "Teacher": 50
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2179",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SYED MOHAMED AL-BUKHARY",
    "Student": 705,
    "Teacher": 70
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2180",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MERGONG",
    "Student": 1121,
    "Teacher": 91
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2182",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIMPANG KUALA",
    "Student": 640,
    "Teacher": 51
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2183",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUBANG ROTAN",
    "Student": 629,
    "Teacher": 54
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2185",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PANTAI",
    "Student": 708,
    "Teacher": 60
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2186",
    "Name": "KOLEJ TINGKATAN ENAM SULTAN SALLEHUDDIN",
    "Student": 304,
    "Teacher": 81
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2187",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI AMPANG",
    "Student": 590,
    "Teacher": 57
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEA2188",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALOR MERAH",
    "Student": 515,
    "Teacher": 51
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3046",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KHIR JOHARI",
    "Student": 1202,
    "Teacher": 129
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3105",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU ISMAIL",
    "Student": 720,
    "Teacher": 71
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3106",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PASIR",
    "Student": 922,
    "Teacher": 81
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3107",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOTA KUALA MUDA",
    "Student": 1037,
    "Teacher": 80
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3108",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT SELAMBAU",
    "Student": 694,
    "Teacher": 59
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3109",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR SUNGAI PETANI",
    "Student": 1572,
    "Teacher": 115
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3111",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AMAN JAYA",
    "Student": 2196,
    "Teacher": 172
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3112",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU 5 JALAN JENIANG",
    "Student": 1112,
    "Teacher": 82
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3113",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI LAYAR",
    "Student": 1300,
    "Teacher": 119
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3114",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN RIA JAYA",
    "Student": 1304,
    "Teacher": 90
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3115",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN LEBAI MAN",
    "Student": 856,
    "Teacher": 63
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3116",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PINANG TUNGGAL",
    "Student": 825,
    "Teacher": 58
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3117",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAKAR ARANG",
    "Student": 1080,
    "Teacher": 96
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3118",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELUK BAYU",
    "Student": 527,
    "Teacher": 48
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3119",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PASIR KECIL",
    "Student": 982,
    "Teacher": 62
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3120",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN RIA",
    "Student": 739,
    "Teacher": 57
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3121",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUTERI JAYA",
    "Student": 2037,
    "Teacher": 133
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA3122",
    "Name": "SEKOLAH MENENGAH PENDIDIKAN KHAS VOKASIONAL MERBOK",
    "Student": 210,
    "Teacher": 85
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BUKIT KAYU HITAM",
    "Student": 392,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4036",
    "Name": "SEKOLAH MENENGAH SULTAN ABDUL HALIM",
    "Student": 674,
    "Teacher": 55
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SANGLANG",
    "Student": 240,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU SERI INDERA PUTERA",
    "Student": 425,
    "Teacher": 43
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHANGLUN",
    "Student": 1090,
    "Teacher": 102
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERMATANG BONGLAI",
    "Student": 444,
    "Teacher": 36
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MEGAT DEWA",
    "Student": 996,
    "Teacher": 75
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU ANUM TUNKU ABDUL RAHMAN",
    "Student": 479,
    "Teacher": 63
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HOSBA",
    "Student": 850,
    "Teacher": 75
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4063",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJUNG PAUH",
    "Student": 631,
    "Teacher": 66
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SINTOK",
    "Student": 705,
    "Teacher": 53
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4066",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU DARULAMAN",
    "Student": 1470,
    "Teacher": 118
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4067",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAYA KEMUNTING",
    "Student": 752,
    "Teacher": 70
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4068",
    "Name": "SEKOLAH MENENGAH SAINS KUBANG PASU",
    "Student": 737,
    "Teacher": 64
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DARUL AMAN HEIGHT 1",
    "Student": 614,
    "Teacher": 53
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEA4070",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JERLUN",
    "Student": 337,
    "Teacher": 36
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI KOB",
    "Student": 1040,
    "Teacher": 83
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5024",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KULIM",
    "Student": 856,
    "Teacher": 76
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5025",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO LELA PAHLAWAN",
    "Student": 1903,
    "Teacher": 122
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KELADI",
    "Student": 533,
    "Teacher": 55
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5067",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LABU BESAR",
    "Student": 834,
    "Teacher": 77
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5068",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI KARANGAN",
    "Student": 760,
    "Teacher": 51
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LUNAS",
    "Student": 1544,
    "Teacher": 119
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5070",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JUNJUNG",
    "Student": 391,
    "Teacher": 46
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SELASIH",
    "Student": 779,
    "Teacher": 64
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN KENARI",
    "Student": 951,
    "Teacher": 72
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN PAYA BESAR",
    "Student": 1079,
    "Teacher": 79
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AIR MERAH",
    "Student": 798,
    "Teacher": 67
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN HITECH",
    "Student": 812,
    "Teacher": 63
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5076",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN JELUTONG",
    "Student": 726,
    "Teacher": 63
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5077",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN MUTIARA",
    "Student": 830,
    "Teacher": 61
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEA5078",
    "Name": "KOLEJ TINGKATAN ENAM KULIM",
    "Student": 202,
    "Teacher": 54
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KEA6014",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AYER HANGAT",
    "Student": 712,
    "Teacher": 65
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KEA6015",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU PUTRA",
    "Student": 1773,
    "Teacher": 147
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KEA6016",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEDAWANG",
    "Student": 1207,
    "Teacher": 84
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KEA6017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LANGKAWI P.TUBA",
    "Student": 217,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KEA6018",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KELIBANG",
    "Student": 885,
    "Teacher": 72
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KEA7017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG TERAP",
    "Student": 479,
    "Teacher": 45
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KEA7018",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LUBOK MERBAU",
    "Student": 581,
    "Teacher": 54
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KEA7019",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NAKA",
    "Student": 913,
    "Teacher": 83
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KEA7020",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEDU",
    "Student": 604,
    "Teacher": 58
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KEA7021",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA NERANG",
    "Student": 1157,
    "Teacher": 99
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KEA8019",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JENERI",
    "Student": 527,
    "Teacher": 45
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KEA8020",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI ENGGANG",
    "Student": 653,
    "Teacher": 63
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KEA8021",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GULAU",
    "Student": 600,
    "Teacher": 57
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KEA8022",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHEPIR",
    "Student": 565,
    "Teacher": 59
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KEA8023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU LAPAN",
    "Student": 485,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA9001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI LIMAU YAN",
    "Student": 734,
    "Teacher": 66
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA9002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU 17",
    "Student": 330,
    "Teacher": 35
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA9003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DULANG",
    "Student": 621,
    "Teacher": 63
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA9004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUAR CHEMPEDAK 2",
    "Student": 404,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEA9005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BADONG",
    "Student": 770,
    "Teacher": 60
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KEAA091",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TOKAI",
    "Student": 396,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KEAA092",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUBOR PANJANG",
    "Student": 1062,
    "Teacher": 93
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KEAA164",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AYER PUTEH DALAM",
    "Student": 343,
    "Teacher": 47
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KEAA180",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANAH MERAH",
    "Student": 636,
    "Teacher": 56
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KEAA181",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SYED IBRAHIM",
    "Student": 700,
    "Teacher": 82
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KEAA182",
    "Name": "SEK MODEL KHAS BUKIT JENUN",
    "Student": 836,
    "Teacher": 75
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KEAA183",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU TEMENGGUNG",
    "Student": 686,
    "Teacher": 78
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KEAA184",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TOBIAR",
    "Student": 419,
    "Teacher": 37
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KEAA185",
    "Name": "SEKOLAH MENENGAH SAINS PENDANG",
    "Student": 188,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEAB001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JABI",
    "Student": 591,
    "Teacher": 53
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEAB002",
    "Name": "SEKOLAH MENENGAH SAINS POKOK SENA",
    "Student": 518,
    "Teacher": 52
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEAB003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT PAYONG",
    "Student": 517,
    "Teacher": 47
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEAB004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN POKOK SENA 2",
    "Student": 439,
    "Teacher": 42
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEB0037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU PUTERA",
    "Student": 1054,
    "Teacher": 94
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEB2093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DARULAMAN",
    "Student": 414,
    "Teacher": 43
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEB2094",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEAT HWA 1",
    "Student": 1726,
    "Teacher": 133
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEB2095",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTANAH ASMA",
    "Student": 674,
    "Teacher": 61
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEB2096",
    "Name": "KOLEJ SULTAN ABDUL HAMID",
    "Student": 879,
    "Teacher": 101
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEB2097",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST MICHAEL",
    "Student": 575,
    "Teacher": 53
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEB2098",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT",
    "Student": 616,
    "Teacher": 55
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEB2099",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABDUL RAHMAN",
    "Student": 748,
    "Teacher": 65
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEB2162",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU SOFIAH",
    "Student": 428,
    "Teacher": 49
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEB3047",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO BIJAYA SETIA",
    "Student": 1459,
    "Teacher": 119
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEB3048",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT FATHER BARRE",
    "Student": 400,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEB3049",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN IBRAHIM",
    "Student": 979,
    "Teacher": 100
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEB3050",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST THERESA",
    "Student": 526,
    "Teacher": 64
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEB3051",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIN MIN",
    "Student": 1706,
    "Teacher": 124
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEB5025",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHIO MIN",
    "Student": 1920,
    "Teacher": 147
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEB5026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU PANGLIMA BESAR",
    "Student": 721,
    "Teacher": 61
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEB5027",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN BADLISHAH",
    "Student": 779,
    "Teacher": 69
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEB5028",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST PATRICK",
    "Student": 175,
    "Teacher": 22
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEB5029",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. ANNE'S CONVENT",
    "Student": 320,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEE0038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA KETIL",
    "Student": 808,
    "Teacher": 87
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KEE0039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA PEGANG",
    "Student": 751,
    "Teacher": 76
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEE1017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN AHMAD TAJUDDIN",
    "Student": 754,
    "Teacher": 72
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEE1018",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERDANG",
    "Student": 617,
    "Teacher": 55
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEE2100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABDUL MALIK",
    "Student": 695,
    "Teacher": 80
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEE2101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALOR JANGGUS",
    "Student": 929,
    "Teacher": 74
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEE2102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUADZAM SHAH",
    "Student": 589,
    "Teacher": 55
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEE2104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABD. AZIZ",
    "Student": 774,
    "Teacher": 75
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEE2107",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN SHARIFAH RODZIAH",
    "Student": 628,
    "Teacher": 66
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEE2108",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU LAKSAMANA",
    "Student": 809,
    "Teacher": 77
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEE2160",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI GUNONG",
    "Student": 383,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEE3052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BEDONG",
    "Student": 1429,
    "Teacher": 117
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEE3053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHE TOM 'SRT'",
    "Student": 601,
    "Teacher": 63
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEE3054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GURUN",
    "Student": 1168,
    "Teacher": 101
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEE3055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MERBOK",
    "Student": 1460,
    "Teacher": 118
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEE3056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEKULA JAYA",
    "Student": 1001,
    "Teacher": 82
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEE3057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU SULONG",
    "Student": 946,
    "Teacher": 81
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEE3105",
    "Name": "SEKOLAH MENENGAH SAINS SULTAN MOHAMAD JIWA",
    "Student": 565,
    "Teacher": 59
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEE4037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JITRA",
    "Student": 803,
    "Teacher": 97
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEE4038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI MAHAWANGSA",
    "Student": 1133,
    "Teacher": 89
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEE4039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU BENDAHARA",
    "Student": 847,
    "Teacher": 83
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEE4040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PULAU NYIOR",
    "Student": 1204,
    "Teacher": 119
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KEE4041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AYER HITAM",
    "Student": 1003,
    "Teacher": 103
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEE5030",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAHANG",
    "Student": 363,
    "Teacher": 36
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KEE5031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG SERAI",
    "Student": 1538,
    "Teacher": 111
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KEE6016",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAHSURI",
    "Student": 1523,
    "Teacher": 128
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KEE7017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK SYED AHMAD",
    "Student": 1173,
    "Teacher": 117
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KEE8018",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIK",
    "Student": 847,
    "Teacher": 90
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEE9023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUAR CHEMPEDAK",
    "Student": 1026,
    "Teacher": 88
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KEE9024",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN YAN",
    "Student": 757,
    "Teacher": 74
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KEEA103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENDANG",
    "Student": 1427,
    "Teacher": 143
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KEEA105",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI TIANG",
    "Student": 779,
    "Teacher": 63
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KEEB001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN POKOK SENA",
    "Student": 1023,
    "Teacher": 95
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KFA8001",
    "Name": "MAAHAD DINI SULTAN ABDUL HALIM",
    "Student": 123,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KFT0010",
    "Name": "SMA ISLAHIAH",
    "Student": 260,
    "Teacher": 34
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KFT0011",
    "Name": "MAKTAB MAHMUD BALING",
    "Student": 290,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KFT0012",
    "Name": "SEKOLAH MENENGAH AGAMA (ARAB) ANNAJAH",
    "Student": 342,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KFT1001",
    "Name": "MAKTAB MAHMUD BANDAR BAHARU",
    "Student": 278,
    "Teacher": 26
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KFT2001",
    "Name": "SEKOLAH MENENGAH AGAMA DARRUSAADAH",
    "Student": 588,
    "Teacher": 61
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KFT2003",
    "Name": "SEKOLAH MENENGAH AGAMA IHYA UL-ULUM AD-DINIAH",
    "Student": 143,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KFT2004",
    "Name": "MAKTAB MAHMUD POKOK SENA",
    "Student": 549,
    "Teacher": 50
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KFT3002",
    "Name": "SMA SUNGAI PETANI",
    "Student": 373,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KFT3003",
    "Name": "SMA PEKAN GURUN",
    "Student": 409,
    "Teacher": 40
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KFT3004",
    "Name": "SEKOLAH MENENGAH AKADEMI ISLAM DARUL AMAN",
    "Student": 148,
    "Teacher": 25
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KFT3005",
    "Name": "MAKTAB MAHMUD KUALA MUDA",
    "Student": 268,
    "Teacher": 27
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KFT3006",
    "Name": "MAKTAB MAHMUD MERBOK",
    "Student": 284,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KFT4001",
    "Name": "SEKOLAH MENENGAH AGAMA HIDAYAH ISLAMIAH",
    "Student": 343,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KFT4002",
    "Name": "SMA NURUL ISLAM AYER HITAM",
    "Student": 294,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KFT5001",
    "Name": "MAKTAB MAHMUD KULIM",
    "Student": 328,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KFT6001",
    "Name": "MAKTAB MAHMUD LANGKAWI",
    "Student": 330,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KFT7001",
    "Name": "SMA DARIL IKTISAM",
    "Student": 237,
    "Teacher": 32
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KFT7002",
    "Name": "SMA MA'AHAD TAHFIZ AL-ABIDIN",
    "Student": 332,
    "Teacher": 33
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KFT7003",
    "Name": "SEKOLAH MENENGAH AGAMA AL-ISLAHIAH AL-ISLAMIAH",
    "Student": 118,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PADANG TERAP",
    "Kod": "KFT7004",
    "Name": "MAKTAB MAHMUD PADANG TERAP",
    "Student": 319,
    "Teacher": 30
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KFT8002",
    "Name": "MAKTAB MAHMUD SIK",
    "Student": 273,
    "Teacher": 29
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KFT8003",
    "Name": "SEKOLAH MENENGAH AGAMA IRSYADIAH",
    "Student": 302,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KFT9001",
    "Name": "SMA SAADAH ALDINIAH AL ISLAMIAH",
    "Student": 315,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KFT9002",
    "Name": "SMA FAUZI",
    "Student": 370,
    "Teacher": 28
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KFT9003",
    "Name": "SEKOLAH MENENGAH AGAMA NAHDZAH",
    "Student": 607,
    "Teacher": 54
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KFTA001",
    "Name": "MA'AHAD AL IMAM AL SYAFIE",
    "Student": 118,
    "Teacher": 19
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD PENDANG",
    "Kod": "KFTA002",
    "Name": "MAKTAB MAHMUD PENDANG",
    "Student": 339,
    "Teacher": 31
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KHA2001",
    "Name": "KOLEJ VOKASIONAL ALOR SETAR",
    "Student": 763,
    "Teacher": 131
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KHA3003",
    "Name": "KOLEJ VOKASIONAL SUNGAI PETANI 1",
    "Student": 522,
    "Teacher": 91
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KHA3004",
    "Name": "KOLEJ VOKASIONAL SUNGAI PETANI 2",
    "Student": 847,
    "Teacher": 126
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KULIM/BANDAR BAHARU",
    "Kod": "KHA5002",
    "Name": "KOLEJ VOKASIONAL KULIM",
    "Student": 815,
    "Teacher": 137
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD LANGKAWI",
    "Kod": "KHA6002",
    "Name": "KOLEJ VOKASIONAL PULAU LANGKAWI",
    "Student": 703,
    "Teacher": 130
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KKE2156",
    "Name": "SEKOLAH MENENGAH TEKNIK ALOR SETAR",
    "Student": 618,
    "Teacher": 59
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD BALING",
    "Kod": "KRA0001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA BALING",
    "Student": 713,
    "Teacher": 58
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KOTA SETAR",
    "Kod": "KRA2001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA KEDAH",
    "Student": 805,
    "Teacher": 142
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KRA4001",
    "Name": "SEKOLAH BERASRAMA PENUH INTEGRASI KUBANG PASU",
    "Student": 551,
    "Teacher": 53
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUBANG PASU",
    "Kod": "KRA4002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA JERLUN",
    "Student": 491,
    "Teacher": 38
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD SIK",
    "Kod": "KRA8001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SIK",
    "Student": 676,
    "Teacher": 63
  },
  {
    "NationalState": "KEDAH",
    "PPD": "PPD KUALA MUDA/YAN",
    "Kod": "KRA9001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA YAN",
    "Student": 846,
    "Teacher": 63
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0001",
    "Name": "SEKOLAH KEBANGSAAN MASJID TANAH",
    "Student": 134,
    "Teacher": 25
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0002",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG BIDARA",
    "Student": 469,
    "Teacher": 41
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0003",
    "Name": "SEKOLAH KEBANGSAAN OTHMAN SYAWAL",
    "Student": 571,
    "Teacher": 50
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0004",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BERINGIN",
    "Student": 154,
    "Teacher": 17
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0005",
    "Name": "SEKOLAH KEBANGSAAN RAMUAN CHINA BESAR",
    "Student": 239,
    "Teacher": 26
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0006",
    "Name": "SEKOLAH KEBANGSAAN RAMUAN CHINA KECHIL",
    "Student": 124,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0007",
    "Name": "SEKOLAH KEBANGSAAN AYER LIMAU",
    "Student": 158,
    "Teacher": 21
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0008",
    "Name": "SEKOLAH KEBANGSAAN KUALA LINGGI",
    "Student": 283,
    "Teacher": 37
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0009",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN BALAK",
    "Student": 567,
    "Teacher": 54
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0010",
    "Name": "SEKOLAH KEBANGSAAN DURIAN DAUN 'K'",
    "Student": 135,
    "Teacher": 18
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0011",
    "Name": "SEKOLAH KEBANGSAAN LUBOK REDAN",
    "Student": 358,
    "Teacher": 32
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0012",
    "Name": "SEKOLAH KEBANGSAAN JERAM",
    "Student": 259,
    "Teacher": 30
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0013",
    "Name": "SEKOLAH KEBANGSAAN AIR JERNIH",
    "Student": 209,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0014",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG TENGAH",
    "Student": 187,
    "Teacher": 22
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0015",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TUANG",
    "Student": 169,
    "Teacher": 19
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0016",
    "Name": "SEKOLAH KEBANGSAAN TELOK BEREMBANG",
    "Student": 358,
    "Teacher": 40
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0017",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI JERNIH",
    "Student": 32,
    "Teacher": 8
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0018",
    "Name": "SEKOLAH KEBANGSAAN RANTAU PANJANG",
    "Student": 87,
    "Teacher": 16
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0020",
    "Name": "SEKOLAH KEBANGSAAN PADANG SEBANG",
    "Student": 171,
    "Teacher": 24
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0021",
    "Name": "SEKOLAH KEBANGSAAN AYER PA' ABAS",
    "Student": 171,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0022",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG EMPAT",
    "Student": 422,
    "Teacher": 41
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0023",
    "Name": "SEKOLAH KEBANGSAAN PULAU SEBANG",
    "Student": 354,
    "Teacher": 41
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0024",
    "Name": "SEKOLAH KEBANGSAAN MELEKEK",
    "Student": 302,
    "Teacher": 32
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0025",
    "Name": "SEKOLAH KEBANGSAAN GANUN",
    "Student": 377,
    "Teacher": 33
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0026",
    "Name": "SEKOLAH KEBANGSAAN PARIT MELANA",
    "Student": 596,
    "Teacher": 49
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0027",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PETAI",
    "Student": 428,
    "Teacher": 32
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0028",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SIPUT",
    "Student": 67,
    "Teacher": 12
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0029",
    "Name": "SEKOLAH KEBANGSAAN MELAKA PINDAH",
    "Student": 245,
    "Teacher": 24
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0030",
    "Name": "SEKOLAH KEBANGSAAN PEGOH",
    "Student": 135,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0031",
    "Name": "SEKOLAH KEBANGSAAN BERISU",
    "Student": 58,
    "Teacher": 12
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0032",
    "Name": "SEKOLAH KEBANGSAAN LENDU",
    "Student": 386,
    "Teacher": 33
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0033",
    "Name": "SEKOLAH KEBANGSAAN BELIMBING DALAM",
    "Student": 545,
    "Teacher": 48
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0034",
    "Name": "SEKOLAH KEBANGSAAN RUMBIA",
    "Student": 382,
    "Teacher": 31
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0035",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BULOH",
    "Student": 66,
    "Teacher": 12
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0036",
    "Name": "SEKOLAH KEBANGSAAN CHERANA PUTEH",
    "Student": 202,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0037",
    "Name": "SEKOLAH KEBANGSAAN TEBONG",
    "Student": 106,
    "Teacher": 18
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0038",
    "Name": "SEKOLAH KEBANGSAAN KEMUNING",
    "Student": 57,
    "Teacher": 13
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0039",
    "Name": "SEKOLAH KEBANGSAAN MENGGONG",
    "Student": 119,
    "Teacher": 18
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0040",
    "Name": "SEKOLAH KEBANGSAAN HUTAN PERCHA",
    "Student": 117,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0041",
    "Name": "SEKOLAH KEBANGSAAN DURIAN TUNGGAL",
    "Student": 929,
    "Teacher": 59
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0042",
    "Name": "SEKOLAH KEBANGSAAN KEM TERENDAK II",
    "Student": 596,
    "Teacher": 71
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0043",
    "Name": "SEKOLAH KEBANGSAAN GANGSA",
    "Student": 782,
    "Teacher": 64
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0044",
    "Name": "SEKOLAH KEBANGSAAN LESONG BATU",
    "Student": 802,
    "Teacher": 62
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBA0045",
    "Name": "SEKOLAH KEBANGSAAN DEMANG TAHA",
    "Student": 517,
    "Teacher": 43
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1001",
    "Name": "SEKOLAH KEBANGSAAN JASIN",
    "Student": 388,
    "Teacher": 39
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1002",
    "Name": "SEKOLAH KEBANGSAAN KESANG TUA",
    "Student": 513,
    "Teacher": 47
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1003",
    "Name": "SEKOLAH KEBANGSAAN SELANDAR",
    "Student": 244,
    "Teacher": 32
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1004",
    "Name": "SEKOLAH KEBANGSAAN BUKIT SENGGEH",
    "Student": 113,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1005",
    "Name": "SEKOLAH KEBANGSAAN JUS",
    "Student": 50,
    "Teacher": 12
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1006",
    "Name": "SEKOLAH KEBANGSAAN BATANG MELAKA",
    "Student": 186,
    "Teacher": 28
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1007",
    "Name": "SEKOLAH KEBANGSAAN NYALAS",
    "Student": 315,
    "Teacher": 39
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1008",
    "Name": "SEKOLAH KEBANGSAAN MASJID BARU",
    "Student": 135,
    "Teacher": 22
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1009",
    "Name": "SEKOLAH KEBANGSAAN ASAHAN",
    "Student": 190,
    "Teacher": 25
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1010",
    "Name": "SEKOLAH KEBANGSAAN CHABAU",
    "Student": 178,
    "Teacher": 22
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1011",
    "Name": "SEKOLAH KEBANGSAAN CHENDERAH",
    "Student": 140,
    "Teacher": 16
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1012",
    "Name": "SEKOLAH KEBANGSAAN CHOHONG",
    "Student": 77,
    "Teacher": 13
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1013",
    "Name": "SEKOLAH KEBANGSAAN CHINCHIN",
    "Student": 180,
    "Teacher": 22
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1014",
    "Name": "SEKOLAH KEBANGSAAN TERENTANG",
    "Student": 87,
    "Teacher": 19
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1015",
    "Name": "SEKOLAH KEBANGSAAN SERI BEMBAN",
    "Student": 507,
    "Teacher": 45
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1016",
    "Name": "SEKOLAH KEBANGSAAN TEHEL",
    "Student": 356,
    "Teacher": 31
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1017",
    "Name": "SEKOLAH KEBANGSAAN KEMENDOR",
    "Student": 179,
    "Teacher": 21
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1018",
    "Name": "SEKOLAH KEBANGSAAN PULAI",
    "Student": 547,
    "Teacher": 49
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1019",
    "Name": "SEKOLAH KEBANGSAAN SERKAM",
    "Student": 238,
    "Teacher": 29
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1020",
    "Name": "SEKOLAH KEBANGSAAN TEDONG",
    "Student": 196,
    "Teacher": 24
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1021",
    "Name": "SEKOLAH KEBANGSAAN SEMPANG",
    "Student": 159,
    "Teacher": 22
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1022",
    "Name": "SEKOLAH KEBANGSAAN MERLIMAU",
    "Student": 264,
    "Teacher": 37
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1023",
    "Name": "SEKOLAH KEBANGSAAN SEBATU",
    "Student": 193,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1024",
    "Name": "SEKOLAH KEBANGSAAN SG RAMBAI",
    "Student": 271,
    "Teacher": 34
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1025",
    "Name": "SEKOLAH KEBANGSAAN BATU GAJAH",
    "Student": 292,
    "Teacher": 34
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1026",
    "Name": "SEKOLAH KEBANGSAAN PARIT PENGHULU",
    "Student": 241,
    "Teacher": 28
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1027",
    "Name": "SEKOLAH KEBANGSAAN SERI MENDAPAT",
    "Student": 195,
    "Teacher": 21
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1029",
    "Name": "SEKOLAH KEBANGSAAN SERKAM DARAT",
    "Student": 530,
    "Teacher": 50
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1071",
    "Name": "SEKOLAH KEBANGSAAN PARIT GANTONG",
    "Student": 111,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1075",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) BKT SENGGEH",
    "Student": 199,
    "Teacher": 25
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1076",
    "Name": "SEKOLAH KEBANGSAAN SERI MACHAP",
    "Student": 216,
    "Teacher": 23
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1077",
    "Name": "SEKOLAH KEBANGSAAN DATUK HJ BAGINDA",
    "Student": 375,
    "Teacher": 42
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1078",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TEMBAKAU",
    "Student": 173,
    "Teacher": 18
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBA1079",
    "Name": "SEKOLAH KEBANGSAAN AYER MERBAU",
    "Student": 448,
    "Teacher": 35
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2001",
    "Name": "SEKOLAH KEBANGSAAN BUKIT CHINA",
    "Student": 219,
    "Teacher": 30
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2002",
    "Name": "SEKOLAH KEBANGSAAN UJONG PASIR",
    "Student": 189,
    "Teacher": 29
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2003",
    "Name": "SEKOLAH KEBANGSAAN PADANG TEMU",
    "Student": 452,
    "Teacher": 52
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2004",
    "Name": "SEKOLAH KEBANGSAAN SEMABOK",
    "Student": 462,
    "Teacher": 36
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2005",
    "Name": "SEKOLAH KEBANGSAAN BENDAHARA SERI MAHARAJA",
    "Student": 429,
    "Teacher": 35
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2006",
    "Name": "SEKOLAH KEBANGSAAN BUKIT LINTANG",
    "Student": 383,
    "Teacher": 42
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2007",
    "Name": "SEKOLAH KEBANGSAAN PAYA DALAM",
    "Student": 406,
    "Teacher": 38
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2008",
    "Name": "SEKOLAH KEBANGSAAN PAYA REDAN",
    "Student": 105,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2009",
    "Name": "SEKOLAH KEBANGSAAN TAMBAK PAYA",
    "Student": 346,
    "Teacher": 33
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2010",
    "Name": "SEKOLAH KEBANGSAAN AYER MOLEK",
    "Student": 818,
    "Teacher": 65
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2011",
    "Name": "SEKOLAH KEBANGSAAN DUYONG",
    "Student": 380,
    "Teacher": 37
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2012",
    "Name": "SEKOLAH KEBANGSAAN DATO DEMANG HUSSIN",
    "Student": 1165,
    "Teacher": 81
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2013",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BERUANG",
    "Student": 922,
    "Teacher": 67
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2014",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BARU",
    "Student": 925,
    "Teacher": 60
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2015",
    "Name": "SEKOLAH KEBANGSAAN PERINGGIT",
    "Student": 589,
    "Teacher": 51
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2016",
    "Name": "SEKOLAH KEBANGSAAN BATU BERENDAM",
    "Student": 751,
    "Teacher": 61
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2019",
    "Name": "SEKOLAH KEBANGSAAN KUBU",
    "Student": 136,
    "Teacher": 23
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2020",
    "Name": "SEKOLAH KEBANGSAAN BACHANG",
    "Student": 901,
    "Teacher": 69
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2021",
    "Name": "SEKOLAH KEBANGSAAN LIMBONGAN",
    "Student": 488,
    "Teacher": 43
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2022",
    "Name": "SEKOLAH KEBANGSAAN KELEBANG BESAR",
    "Student": 590,
    "Teacher": 38
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2023",
    "Name": "SEKOLAH KEBANGSAAN BUKIT RAMBAI",
    "Student": 1129,
    "Teacher": 86
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2024",
    "Name": "SEKOLAH KEBANGSAAN LEREH",
    "Student": 963,
    "Teacher": 70
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2025",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG GELAM",
    "Student": 869,
    "Teacher": 69
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2026",
    "Name": "SEKOLAH KEBANGSAAN TANGGA BATU",
    "Student": 573,
    "Teacher": 49
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2027",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI UDANG",
    "Student": 1111,
    "Teacher": 69
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2028",
    "Name": "SEKOLAH KEBANGSAAN PANTAI KUNDOR",
    "Student": 303,
    "Teacher": 29
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2029",
    "Name": "SEKOLAH KEBANGSAAN CHENG",
    "Student": 589,
    "Teacher": 47
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2030",
    "Name": "SEKOLAH KEBANGSAAN KERUBONG",
    "Student": 1310,
    "Teacher": 73
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2031",
    "Name": "SEKOLAH KEBANGSAAN PAYA RUMPUT",
    "Student": 1315,
    "Teacher": 82
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2032",
    "Name": "SEKOLAH KEBANGSAAN BERTAM HULU",
    "Student": 832,
    "Teacher": 54
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2033",
    "Name": "SEKOLAH KEBANGSAAN ALAI",
    "Student": 543,
    "Teacher": 55
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2034",
    "Name": "SEKOLAH KEBANGSAAN TELOK MAS",
    "Student": 924,
    "Teacher": 61
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2035",
    "Name": "SEKOLAH KEBANGSAAN PERNU",
    "Student": 354,
    "Teacher": 40
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2038",
    "Name": "SEKOLAH KEBANGSAAN KEM GERAKHAS",
    "Student": 359,
    "Teacher": 39
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2039",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS MELAKA",
    "Student": 24,
    "Teacher": 26
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2040",
    "Name": "SEKOLAH KEBANGSAAN AYER KEROH",
    "Student": 869,
    "Teacher": 67
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2041",
    "Name": "SEKOLAH KEBANGSAAN SERI DUYONG",
    "Student": 1047,
    "Teacher": 71
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2042",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUKIT RAMBAI",
    "Student": 1525,
    "Teacher": 98
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2043",
    "Name": "SEKOLAH KEBANGSAAN MALIM",
    "Student": 790,
    "Teacher": 66
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2044",
    "Name": "SEKOLAH KEBANGSAAN BATU BERENDAM 2",
    "Student": 1200,
    "Teacher": 86
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2045",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG MINYAK",
    "Student": 1102,
    "Teacher": 80
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2046",
    "Name": "SEKOLAH KEBANGSAAN TUN SYED AHMAD SHAHABUDIN",
    "Student": 1353,
    "Teacher": 93
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2047",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG TUN RAZAK",
    "Student": 1288,
    "Teacher": 93
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2048",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG MINYAK 2",
    "Student": 1032,
    "Teacher": 68
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBA2049",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MERDEKA",
    "Student": 859,
    "Teacher": 52
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBB0041",
    "Name": "SEKOLAH KEBANGSAAN ALOR GAJAH 1",
    "Student": 736,
    "Teacher": 59
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBB0042",
    "Name": "SEKOLAH KEBANGSAAN DATO' NANING",
    "Student": 516,
    "Teacher": 57
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBB0043",
    "Name": "SEKOLAH KEBANGSAAN DATUK TAMBICHIK KARIM",
    "Student": 464,
    "Teacher": 44
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBB0044",
    "Name": "SEKOLAH KEBANGSAAN SRI LAKSAMANA",
    "Student": 630,
    "Teacher": 67
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBB1029",
    "Name": "SEKOLAH KEBANGSAAN AIR BARUK",
    "Student": 696,
    "Teacher": 63
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBB1030",
    "Name": "SEKOLAH KEBANGSAAN SRI LANANG",
    "Student": 259,
    "Teacher": 31
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBB1031",
    "Name": "SEKOLAH KEBANGSAAN MERLIMAU 1",
    "Student": 659,
    "Teacher": 53
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBB1074",
    "Name": "SEKOLAH KEBANGSAAN MERLIMAU DUA",
    "Student": 484,
    "Teacher": 49
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2038",
    "Name": "SEKOLAH KEBANGSAAN BANDAR HILIR",
    "Student": 214,
    "Teacher": 29
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2039",
    "Name": "SEKOLAH KEBANGSAAN SERI BANDAR",
    "Student": 412,
    "Teacher": 40
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2040",
    "Name": "SEKOLAH KEBANGSAAN TENGKERA 1",
    "Student": 340,
    "Teacher": 28
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2041",
    "Name": "SEKOLAH KEBANGSAAN TENGKERA DUA",
    "Student": 354,
    "Teacher": 30
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2042",
    "Name": "SEKOLAH KEBANGSAAN (P) DURIAN DAUN",
    "Student": 177,
    "Teacher": 17
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2043",
    "Name": "SEKOLAH KEBANGSAAN JLN DATUK PALEMBANG",
    "Student": 1206,
    "Teacher": 78
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2044",
    "Name": "SEKOLAH KEBANGSAAN METHODIST ACS",
    "Student": 95,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2045",
    "Name": "SEKOLAH KEBANGSAAN (P) METHODIST 1",
    "Student": 243,
    "Teacher": 28
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2046",
    "Name": "SEKOLAH KEBANGSAAN (P) METHODIST 2",
    "Student": 498,
    "Teacher": 39
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2047",
    "Name": "SEKOLAH KEBANGSAAN ST FRANCIS",
    "Student": 551,
    "Teacher": 42
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2048",
    "Name": "SEKOLAH KEBANGSAAN CONVENT OF THE INFANT JESUS 1",
    "Student": 326,
    "Teacher": 27
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2049",
    "Name": "SEKOLAH KEBANGSAAN CONVENT OF THE INFANT JESUS (2)",
    "Student": 457,
    "Teacher": 40
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBB2051",
    "Name": "SEKOLAH KEBANGSAAN SACRED HEART",
    "Student": 215,
    "Teacher": 30
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAY CHEE",
    "Student": 239,
    "Teacher": 25
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ALOR GAJAH",
    "Student": 219,
    "Teacher": 23
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SANN YUH",
    "Student": 33,
    "Teacher": 12
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PENG MIN",
    "Student": 65,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN WAH",
    "Student": 291,
    "Teacher": 27
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KIOW MIN",
    "Student": 148,
    "Teacher": 15
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MACHAP BARU",
    "Student": 133,
    "Teacher": 19
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MACHAP UMBOO",
    "Student": 95,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TABOH NANING",
    "Student": 48,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LENDU",
    "Student": 116,
    "Teacher": 17
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHIAK YEW",
    "Student": 57,
    "Teacher": 12
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MASJID TANAH",
    "Student": 224,
    "Teacher": 27
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOK SIN",
    "Student": 141,
    "Teacher": 15
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAYA MENGKUANG",
    "Student": 118,
    "Teacher": 15
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN",
    "Student": 43,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBC0061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHABAU",
    "Student": 587,
    "Teacher": 43
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUANG HWA",
    "Student": 67,
    "Teacher": 17
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU HSIEN",
    "Student": 169,
    "Teacher": 23
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 122,
    "Teacher": 17
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KIOW NAM",
    "Student": 49,
    "Teacher": 14
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAY MIN",
    "Student": 31,
    "Teacher": 8
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MERLIMAU",
    "Student": 192,
    "Teacher": 22
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAO CHEE",
    "Student": 54,
    "Teacher": 14
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JASIN LALANG",
    "Student": 195,
    "Teacher": 19
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAY CHIAO",
    "Student": 130,
    "Teacher": 15
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ON LOK",
    "Student": 41,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAY YAP",
    "Student": 34,
    "Teacher": 12
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAY CHUIN",
    "Student": 33,
    "Teacher": 10
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PARIT KELILING",
    "Student": 42,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PONDOK BATANG",
    "Student": 27,
    "Teacher": 10
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG BEKOH",
    "Student": 16,
    "Teacher": 8
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TOON HUA",
    "Student": 34,
    "Teacher": 12
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SHUH YEN",
    "Student": 87,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAY HSIEN",
    "Student": 30,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBC1051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEMENDOR",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAY FONG 1",
    "Student": 911,
    "Teacher": 54
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAY FONG 2",
    "Student": 997,
    "Teacher": 54
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAY FONG 3",
    "Student": 89,
    "Teacher": 23
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAY TECK",
    "Student": 266,
    "Teacher": 24
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PING MING",
    "Student": 314,
    "Teacher": 28
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOK BIN",
    "Student": 857,
    "Teacher": 51
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG KUO",
    "Student": 167,
    "Teacher": 16
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BACHANG",
    "Student": 422,
    "Teacher": 34
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KATHOLIK",
    "Student": 89,
    "Teacher": 14
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2062",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIANG LIN",
    "Student": 99,
    "Teacher": 21
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NOTRE DAME",
    "Student": 186,
    "Teacher": 17
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2064",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) EK TE",
    "Student": 107,
    "Teacher": 19
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YING CHYE",
    "Student": 172,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU YING",
    "Student": 523,
    "Teacher": 45
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2067",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TING HWA",
    "Student": 487,
    "Teacher": 38
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2068",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WEN HUA",
    "Student": 1017,
    "Teacher": 58
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2069",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEH SENG",
    "Student": 548,
    "Teacher": 41
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2070",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POH LAN",
    "Student": 162,
    "Teacher": 18
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2071",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LIH JEN",
    "Student": 145,
    "Teacher": 16
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2072",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUANG YAH",
    "Student": 61,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2073",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAY HWA",
    "Student": 230,
    "Teacher": 23
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2074",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST MARY",
    "Student": 116,
    "Teacher": 14
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2075",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT BERUANG",
    "Student": 781,
    "Teacher": 57
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2076",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG",
    "Student": 880,
    "Teacher": 58
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2077",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AYER KEROH",
    "Student": 382,
    "Teacher": 34
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2078",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TIANG DUA",
    "Student": 86,
    "Teacher": 13
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2079",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BERTAM ULU",
    "Student": 144,
    "Teacher": 12
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2080",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI UDANG",
    "Student": 199,
    "Teacher": 24
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2081",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MALIM",
    "Student": 829,
    "Teacher": 50
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBC2082",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU HWA",
    "Student": 271,
    "Teacher": 24
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBD0061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) ALOR GAJAH",
    "Student": 228,
    "Teacher": 26
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBD0062",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) DURIAN TUNGGAL",
    "Student": 211,
    "Teacher": 19
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBD0063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) RUMBIA",
    "Student": 108,
    "Teacher": 13
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBD0064",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GADEK",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBD0066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KEMUNING (H/D)",
    "Student": 25,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBD0067",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KEMUNING KRU DIVISION",
    "Student": 51,
    "Teacher": 10
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBD0068",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PULAU SEBANG",
    "Student": 109,
    "Teacher": 17
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBD0069",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG BARU (H/D)",
    "Student": 76,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBD0070",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG TEBONG",
    "Student": 35,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBD0097",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PEKAN TEBONG",
    "Student": 48,
    "Teacher": 13
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBD1051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BATANG MELAKA",
    "Student": 74,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBD1052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JASIN",
    "Student": 304,
    "Teacher": 32
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBD1054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BUKIT ASAHAN",
    "Student": 47,
    "Teacher": 14
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBD1055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BUKIT KAJANG",
    "Student": 102,
    "Teacher": 10
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBD1056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG DIAMOND JUBILEE",
    "Student": 46,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBD1057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG JASIN LALANG",
    "Student": 61,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBD1058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MERLIMAU",
    "Student": 97,
    "Teacher": 18
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MBD1059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SERKAM",
    "Student": 40,
    "Teacher": 11
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBD2083",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MELAKA (KUBU)",
    "Student": 345,
    "Teacher": 40
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBD2084",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BUKIT LINTANG",
    "Student": 96,
    "Teacher": 15
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MBD2085",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PAYA RUMPUT",
    "Student": 177,
    "Teacher": 20
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MBE0045",
    "Name": "SEKOLAH KEBANGSAAN KEM TERENDAK 1",
    "Student": 894,
    "Teacher": 77
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MCT0001",
    "Name": "SEKOLAH RENDAH ARAB (JAIM) AL-FALAH",
    "Student": 344,
    "Teacher": 34
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MCT0002",
    "Name": "SEKOLAH RENDAH ARAB JAIM AL-FAIZIN",
    "Student": 454,
    "Teacher": 34
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MCT1001",
    "Name": "SEKOLAH RENDAH ARAB JAIM SELANDAR",
    "Student": 281,
    "Teacher": 22
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MCT1002",
    "Name": "SEKOLAH RENDAH ARAB MERLIMAU PASIR",
    "Student": 526,
    "Teacher": 40
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MCT2001",
    "Name": "SEKOLAH RENDAH ARAB JAIM PERNU",
    "Student": 684,
    "Teacher": 53
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MCT2002",
    "Name": "SEKOLAH RENDAH ARAB BATANG TIGA TIMUR",
    "Student": 521,
    "Teacher": 40
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MCT2003",
    "Name": "SEKOLAH RENDAH ARAB JAIM TUN RAZAK",
    "Student": 406,
    "Teacher": 32
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEA0071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GHAFAR BABA",
    "Student": 727,
    "Teacher": 79
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEA0072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' HAJI TALIB KARIM",
    "Student": 1118,
    "Teacher": 104
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEA0073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAHMAT",
    "Student": 1172,
    "Teacher": 103
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEA0074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ADE PUTRA",
    "Student": 541,
    "Teacher": 63
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEA0075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NANING",
    "Student": 521,
    "Teacher": 48
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEA0095",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LUBOK CHINA",
    "Student": 708,
    "Teacher": 85
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEA0099",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HANG KASTURI",
    "Student": 726,
    "Teacher": 78
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEA0100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI UDANG",
    "Student": 681,
    "Teacher": 60
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEA0101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DURIAN TUNGGAL",
    "Student": 1299,
    "Teacher": 95
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEA0102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEBONG",
    "Student": 308,
    "Teacher": 42
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEA1060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ISKANDAR SHAH",
    "Student": 919,
    "Teacher": 93
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEA1061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' ABDUL RAHMAN YA'KUB",
    "Student": 967,
    "Teacher": 110
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEA1072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI MAHKOTA",
    "Student": 910,
    "Teacher": 99
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEA1076",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIMPANG BEKOH",
    "Student": 533,
    "Teacher": 61
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEA1077",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SELANDAR",
    "Student": 689,
    "Teacher": 84
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEA1078",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RAMBAI",
    "Student": 656,
    "Teacher": 77
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEA1079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NYALAS",
    "Student": 501,
    "Teacher": 65
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEA1080",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BEMBAN",
    "Student": 655,
    "Teacher": 73
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEA1081",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN SYED ZAHIRUDDIN",
    "Student": 434,
    "Teacher": 80
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2086",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN TIJAH",
    "Student": 774,
    "Teacher": 82
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2087",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUNSHI ABDULLAH",
    "Student": 1780,
    "Teacher": 144
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2088",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN MUTAHIR",
    "Student": 1333,
    "Teacher": 91
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2089",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI TANJUNG",
    "Student": 1160,
    "Teacher": 118
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2091",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AIR MOLEK",
    "Student": 1341,
    "Teacher": 112
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2092",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KLEBANG BESAR",
    "Student": 944,
    "Teacher": 83
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT BARU",
    "Student": 1576,
    "Teacher": 112
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2094",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN HAJI ABD MALEK",
    "Student": 1538,
    "Teacher": 121
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2095",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT KATIL",
    "Student": 1499,
    "Teacher": 106
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2096",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MALIM",
    "Student": 1475,
    "Teacher": 104
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2097",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG TEMU",
    "Student": 696,
    "Teacher": 80
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2098",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELOK MAS",
    "Student": 1188,
    "Teacher": 86
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2099",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT RAMBAI",
    "Student": 1643,
    "Teacher": 117
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AYER KEROH",
    "Student": 1210,
    "Teacher": 82
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG GELAM",
    "Student": 570,
    "Teacher": 60
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERNU",
    "Student": 775,
    "Teacher": 70
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAYA RUMPUT",
    "Student": 1288,
    "Teacher": 84
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEA2104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KRUBONG",
    "Student": null,
    "Teacher": 8
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEB0077",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PENGKALAN",
    "Student": 1047,
    "Teacher": 88
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEB0078",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PULAU SEBANG",
    "Student": 973,
    "Teacher": 72
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEB0079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ALAUDDIN",
    "Student": 983,
    "Teacher": 105
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEB1063",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK BENDAHARA",
    "Student": 891,
    "Teacher": 105
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEB1064",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DANG ANUM",
    "Student": 931,
    "Teacher": 91
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MEB1065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAN SRI HAJI ABDUL AZIZ TAPA",
    "Student": 656,
    "Teacher": 74
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2090",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI MELAKA",
    "Student": 831,
    "Teacher": 119
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2091",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GAJAH BERANG",
    "Student": 1085,
    "Teacher": 102
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2092",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI PEREMPUAN",
    "Student": 873,
    "Teacher": 86
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI ST DAVID",
    "Student": 1484,
    "Teacher": 82
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2094",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST (ACS) MELAKA ( M )",
    "Student": 460,
    "Teacher": 39
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2095",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN METHODIST",
    "Student": 573,
    "Teacher": 60
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2096",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST FRANCIS",
    "Student": 801,
    "Teacher": 61
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2097",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN INFANT JESUS CONVENT",
    "Student": 834,
    "Teacher": 61
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2098",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CANOSSA CONVENT ( M )",
    "Student": 382,
    "Teacher": 40
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2099",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KATHOLIK",
    "Student": 705,
    "Teacher": 61
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NOTRE DAME CONVENT",
    "Student": 995,
    "Teacher": 72
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN YOK BIN",
    "Student": 1318,
    "Teacher": 77
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN TUAH",
    "Student": 1027,
    "Teacher": 106
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2103",
    "Name": "KOLEJ TINGKATAN ENAM TUN FATIMAH",
    "Student": 258,
    "Teacher": 67
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI CINA MELAKA",
    "Student": 1223,
    "Teacher": 63
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEB2145",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI KOTA",
    "Student": 420,
    "Teacher": 58
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEE0074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN MANSOR SHAH",
    "Student": 354,
    "Teacher": 52
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEE0075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' DOL SAID",
    "Student": 1437,
    "Teacher": 123
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MEE0094",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEM TERENDAK",
    "Student": 838,
    "Teacher": 70
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MEE2141",
    "Name": "SEKOLAH MENENGAH SAINS MUZAFFAR SHAH",
    "Student": 751,
    "Teacher": 61
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MFT0001",
    "Name": "SEKOLAH MENENGAH AGAMA AL-EHYA AL-KARIM",
    "Student": 394,
    "Teacher": 37
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MFT0002",
    "Name": "SMA JAIM AL-ASYRAF",
    "Student": 437,
    "Teacher": 44
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MFT0003",
    "Name": "SEKOLAH MENENGAH ARAB (JAIM) DARUL FALAH",
    "Student": 295,
    "Teacher": 38
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MFT1001",
    "Name": "SEKOLAH MENENGAH ARAB ASSAIYIDAH KHADIJAH",
    "Student": 417,
    "Teacher": 42
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MFT1002",
    "Name": "SEKOLAH MENENGAH ARB.ASSYAKIRIN",
    "Student": 314,
    "Teacher": 29
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MFT1003",
    "Name": "SEKOLAH MENENGAH TAHFIZ AL QURAN (JAIM)",
    "Student": 206,
    "Teacher": 46
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MFT2001",
    "Name": "SEKOLAH MENENGAH AGAMA JAIM AL AHMADI",
    "Student": 405,
    "Teacher": 40
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD ALOR GAJAH",
    "Kod": "MHA0001",
    "Name": "KOLEJ VOKASIONAL DATUK SERI MOHD. ZIN",
    "Student": 701,
    "Teacher": 120
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MHA1001",
    "Name": "KOLEJ VOKASIONAL DATUK SERI ABU ZAHAR ISNIN",
    "Student": 651,
    "Teacher": 90
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MHA2002",
    "Name": "KOLEJ VOKASIONAL MELAKA TENGAH",
    "Student": 763,
    "Teacher": 114
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MKB2144",
    "Name": "SEKOLAH MENENGAH TEKNIK BUKIT PIATU",
    "Student": 568,
    "Teacher": 48
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MRA1001",
    "Name": "SEKOLAH BERASRAMA PENUH INTEGRASI SELANDAR",
    "Student": 558,
    "Teacher": 90
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD JASIN",
    "Kod": "MRA1002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN PERAK",
    "Student": 585,
    "Teacher": 55
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MRA2125",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SULTAN MUHAMMAD",
    "Student": 666,
    "Teacher": 78
  },
  {
    "NationalState": "MELAKA",
    "PPD": "PPD MELAKA TENGAH",
    "Kod": "MRA2127",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SHARIFAH RODZIAH",
    "Student": 673,
    "Teacher": 66
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0002",
    "Name": "SEKOLAH KEBANGSAAN AMAR PENGHULU",
    "Student": 99,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0003",
    "Name": "SEKOLAH KEBANGSAAN KUALA KLAWANG",
    "Student": 347,
    "Teacher": 32
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0005",
    "Name": "SEKOLAH KEBANGSAAN TERIANG",
    "Student": 325,
    "Teacher": 28
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0006",
    "Name": "SEKOLAH KEBANGSAAN SEPRI TENGAH",
    "Student": 137,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0007",
    "Name": "SEKOLAH KEBANGSAAN KAMPAI",
    "Student": 97,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0008",
    "Name": "SEKOLAH KEBANGSAAN PERADONG",
    "Student": 87,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0009",
    "Name": "SEKOLAH KEBANGSAAN GELANG TERUSAN",
    "Student": 132,
    "Teacher": 18
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0010",
    "Name": "SEKOLAH KEBANGSAAN PETASEH",
    "Student": 107,
    "Teacher": 20
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0011",
    "Name": "SEKOLAH KEBANGSAAN DATUK UNDANG ABDULLAH",
    "Student": 164,
    "Teacher": 21
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0012",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BULOH",
    "Student": 110,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0013",
    "Name": "SEKOLAH KEBANGSAAN DURIAN TIPUS",
    "Student": 91,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0014",
    "Name": "SEKOLAH KEBANGSAAN PUTRA",
    "Student": 132,
    "Teacher": 18
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0033",
    "Name": "SEKOLAH KEBANGSAAN PASOH SATU",
    "Student": 248,
    "Teacher": 27
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA0034",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PASOH 4",
    "Student": 160,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1001",
    "Name": "SEKOLAH KEBANGSAAN ULU BENDOL",
    "Student": 87,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1002",
    "Name": "SEKOLAH KEBANGSAAN YAMTUAN HITAM",
    "Student": 171,
    "Teacher": 22
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1004",
    "Name": "SEKOLAH KEBANGSAAN YAMTUAN LENGGANG",
    "Student": 90,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1005",
    "Name": "SEKOLAH KEBANGSAAN AMPANG TINGGI",
    "Student": 309,
    "Teacher": 30
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1006",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG PARIT",
    "Student": 31,
    "Teacher": 6
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1007",
    "Name": "SEKOLAH KEBANGSAAN TALANG",
    "Student": 113,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1008",
    "Name": "SEKOLAH KEBANGSAAN YAMTUAN ANTAH GUNUNG PASIR",
    "Student": 31,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1009",
    "Name": "SEKOLAH KEBANGSAAN SERI PILAH",
    "Student": 50,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1010",
    "Name": "SEKOLAH KEBANGSAAN TANAH MELINTANG",
    "Student": 99,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1011",
    "Name": "SEKOLAH KEBANGSAAN KUALA PILAH",
    "Student": 476,
    "Teacher": 43
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1012",
    "Name": "SEKOLAH KEBANGSAAN SENALING",
    "Student": 325,
    "Teacher": 31
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1013",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DUA",
    "Student": 88,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1014",
    "Name": "SEKOLAH KEBANGSAAN ULU SUNGAI DUA",
    "Student": 47,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1015",
    "Name": "SEKOLAH KEBANGSAAN DANGI",
    "Student": 93,
    "Teacher": 18
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1016",
    "Name": "SEKOLAH KEBANGSAAN DATO' INAS",
    "Student": 34,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1017",
    "Name": "SEKOLAH KEBANGSAAN INAS",
    "Student": 80,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1018",
    "Name": "SEKOLAH KEBANGSAAN UNDANG JOHOL",
    "Student": 143,
    "Teacher": 22
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1019",
    "Name": "SEKOLAH KEBANGSAAN DATO' UNDANG KAMAT",
    "Student": 40,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1020",
    "Name": "SEKOLAH KEBANGSAAN NURI",
    "Student": 232,
    "Teacher": 27
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1021",
    "Name": "SEKOLAH KEBANGSAAN SENIBAI",
    "Student": 99,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1022",
    "Name": "SEKOLAH KEBANGSAAN KEPIS",
    "Student": 102,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1023",
    "Name": "SEKOLAH KEBANGSAAN KUALA KEPIS",
    "Student": 58,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1024",
    "Name": "SEKOLAH KEBANGSAAN PELANGAI",
    "Student": 175,
    "Teacher": 20
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1025",
    "Name": "SEKOLAH KEBANGSAAN PUSAT JUASSEH",
    "Student": 340,
    "Teacher": 32
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1026",
    "Name": "SEKOLAH KEBANGSAAN JUASEH TENGAH",
    "Student": 56,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1027",
    "Name": "SEKOLAH KEBANGSAAN REMBANG PANAS",
    "Student": 125,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1028",
    "Name": "SEKOLAH KEBANGSAAN PASIR AMBOR",
    "Student": 31,
    "Teacher": 7
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1032",
    "Name": "SEKOLAH KEBANGSAAN TENGKEK",
    "Student": 96,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1033",
    "Name": "SEKOLAH KEBANGSAAN TAPAK",
    "Student": 78,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1034",
    "Name": "SEKOLAH KEBANGSAAN PADANG LEBAR",
    "Student": 127,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1110",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KEPIS",
    "Student": 217,
    "Teacher": 23
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBA1111",
    "Name": "SEKOLAH KEBANGSAAN BUKIT ROKAN BARAT",
    "Student": 121,
    "Teacher": 18
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2001",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG GELAM",
    "Student": 802,
    "Teacher": 67
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2003",
    "Name": "SEKOLAH KEBANGSAAN SI-RUSA",
    "Student": 489,
    "Teacher": 38
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2004",
    "Name": "SEKOLAH KEBANGSAAN SUNGALA",
    "Student": 232,
    "Teacher": 26
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2005",
    "Name": "SEKOLAH KEBANGSAAN TELOK KEMANG",
    "Student": 814,
    "Teacher": 55
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2006",
    "Name": "SEKOLAH KEBANGSAAN CHUAH",
    "Student": 186,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2007",
    "Name": "SEKOLAH KEBANGSAAN KG SAWAH",
    "Student": 327,
    "Teacher": 33
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2008",
    "Name": "SEKOLAH KEBANGSAAN JIMAH",
    "Student": 423,
    "Teacher": 43
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2009",
    "Name": "SEKOLAH KEBANGSAAN JIMAH BARU",
    "Student": 806,
    "Teacher": 54
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2010",
    "Name": "SEKOLAH KEBANGSAAN PASIR PANJANG",
    "Student": 417,
    "Teacher": 39
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2011",
    "Name": "SEKOLAH KEBANGSAAN TANJONG AGAS",
    "Student": 131,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2012",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG PASIR",
    "Student": 76,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2013",
    "Name": "SEKOLAH KEBANGSAAN LINGGI",
    "Student": 222,
    "Teacher": 26
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2014",
    "Name": "SEKOLAH KEBANGSAAN LUKUT",
    "Student": 1249,
    "Teacher": 82
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2015",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BARU SIRUSA",
    "Student": 758,
    "Teacher": 61
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBA2016",
    "Name": "SEKOLAH KEBANGSAAN INTAN PERDANA",
    "Student": 930,
    "Teacher": 60
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3001",
    "Name": "SEKOLAH KEBANGSAAN PEDAS",
    "Student": 609,
    "Teacher": 47
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3002",
    "Name": "SEKOLAH KEBANGSAAN KUNDOR",
    "Student": 175,
    "Teacher": 21
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3003",
    "Name": "SEKOLAH KEBANGSAAN SEPRI",
    "Student": 160,
    "Teacher": 14
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3005",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG BATU HAMPAR",
    "Student": 155,
    "Teacher": 21
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3006",
    "Name": "SEKOLAH KEBANGSAAN KAYU ARA",
    "Student": 96,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3009",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BATU",
    "Student": 114,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3010",
    "Name": "SEKOLAH KEBANGSAAN SALAK NAMA",
    "Student": 121,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3011",
    "Name": "SEKOLAH KEBANGSAAN BONGEK",
    "Student": 91,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3012",
    "Name": "SEKOLAH KEBANGSAAN PULAU BESAR",
    "Student": 89,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3013",
    "Name": "SEKOLAH KEBANGSAAN PILIN",
    "Student": 125,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3014",
    "Name": "SEKOLAH KEBANGSAAN TUN HAJI ABDUL MALEK",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3015",
    "Name": "SEKOLAH KEBANGSAAN CHENGKAU",
    "Student": 82,
    "Teacher": 9
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3016",
    "Name": "SEKOLAH KEBANGSAAN KOTA",
    "Student": 200,
    "Teacher": 25
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3017",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG TANJONG",
    "Student": 81,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3019",
    "Name": "SEKOLAH KEBANGSAAN SAWAH RAJA",
    "Student": 35,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3020",
    "Name": "SEKOLAH KEBANGSAAN SEMERBOK",
    "Student": 39,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3021",
    "Name": "SEKOLAH KEBANGSAAN LEGONG ULU",
    "Student": 59,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3022",
    "Name": "SEKOLAH KEBANGSAAN DATO SHAH BANDAR REMBAU",
    "Student": 105,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3023",
    "Name": "SEKOLAH KEBANGSAAN TITIAN BINTANGOR",
    "Student": 23,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBA3024",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SERI REMBAU",
    "Student": 550,
    "Teacher": 46
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4001",
    "Name": "SEKOLAH KEBANGSAAN DATO' KLANA PUTRA",
    "Student": 359,
    "Teacher": 34
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4002",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI MACHANG",
    "Student": 364,
    "Teacher": 27
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4003",
    "Name": "SEKOLAH KEBANGSAAN ULU BERANANG",
    "Student": 297,
    "Teacher": 29
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4004",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI JAI",
    "Student": 294,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4005",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG DACHING",
    "Student": 247,
    "Teacher": 20
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4006",
    "Name": "SEKOLAH KEBANGSAAN MENDUM",
    "Student": 83,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4009",
    "Name": "SEKOLAH KEBANGSAAN BATANG BENAR",
    "Student": 1512,
    "Teacher": 80
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4010",
    "Name": "SEKOLAH KEBANGSAAN DATO' AHMAD MANAF",
    "Student": 524,
    "Teacher": 37
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4011",
    "Name": "SEKOLAH KEBANGSAAN JIJAN",
    "Student": 357,
    "Teacher": 28
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4013",
    "Name": "SEKOLAH KEBANGSAAN LABU ULU",
    "Student": 376,
    "Teacher": 32
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4014",
    "Name": "SEKOLAH KEBANGSAAN LABU",
    "Student": 255,
    "Teacher": 29
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4015",
    "Name": "SEKOLAH KEBANGSAAN TEKIR",
    "Student": 112,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4016",
    "Name": "SEKOLAH KEBANGSAAN GADONG JAYA",
    "Student": 1024,
    "Teacher": 54
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4017",
    "Name": "SEKOLAH KEBANGSAAN L B JOHNSON",
    "Student": 559,
    "Teacher": 46
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4018",
    "Name": "SEKOLAH KEBANGSAAN TEMIANG",
    "Student": 377,
    "Teacher": 38
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4019",
    "Name": "SEKOLAH KEBANGSAAN RAHANG",
    "Student": 594,
    "Teacher": 46
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4020",
    "Name": "SEKOLAH KEBANGSAAN SERI KELANA",
    "Student": 1032,
    "Teacher": 75
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4021",
    "Name": "SEKOLAH KEBANGSAAN AMPANGAN",
    "Student": 845,
    "Teacher": 57
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4022",
    "Name": "SEKOLAH KEBANGSAAN PAROI",
    "Student": 574,
    "Teacher": 44
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4023",
    "Name": "SEKOLAH KEBANGSAAN PANTAI",
    "Student": 314,
    "Teacher": 29
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4024",
    "Name": "SEKOLAH KEBANGSAAN DATO' BANDAR RASAH",
    "Student": 467,
    "Teacher": 34
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4025",
    "Name": "SEKOLAH KEBANGSAAN MAMBAU",
    "Student": 605,
    "Teacher": 46
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4026",
    "Name": "SEKOLAH KEBANGSAAN NYATOH",
    "Student": 436,
    "Teacher": 36
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4027",
    "Name": "SEKOLAH KEBANGSAAN SEGA",
    "Student": 93,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4028",
    "Name": "SEKOLAH KEBANGSAAN DATUK AKHIR ZAMAN",
    "Student": 498,
    "Teacher": 40
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4029",
    "Name": "SEKOLAH KEBANGSAAN SELIAU",
    "Student": 135,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4030",
    "Name": "SEKOLAH KEBANGSAAN SENDAYAN",
    "Student": 760,
    "Teacher": 51
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4031",
    "Name": "SEKOLAH KEBANGSAAN SENAWANG",
    "Student": 1014,
    "Teacher": 77
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4032",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS SEREMBAN",
    "Student": 42,
    "Teacher": 31
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4033",
    "Name": "SEKOLAH KEBANGSAAN TAMAN PAROI JAYA",
    "Student": 1144,
    "Teacher": 76
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4034",
    "Name": "SEKOLAH KEBANGSAAN TAMAN RASAH JAYA",
    "Student": 800,
    "Teacher": 57
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4035",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DUSUN NYIOR",
    "Student": 501,
    "Teacher": 56
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4036",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SRI MAWAR",
    "Student": 420,
    "Teacher": 43
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4037",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TUANKU JAAFAR",
    "Student": 1076,
    "Teacher": 92
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4038",
    "Name": "SEKOLAH KEBANGSAAN KOMPLEKS KLIA",
    "Student": 1335,
    "Teacher": 94
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4039",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TASIK JAYA",
    "Student": 1018,
    "Teacher": 71
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4040",
    "Name": "SEKOLAH KEBANGSAAN DESA CEMPAKA",
    "Student": 1409,
    "Teacher": 91
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4041",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SEMARAK",
    "Student": 1234,
    "Teacher": 74
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4042",
    "Name": "SEKOLAH KEBANGSAAN DESA JASMIN",
    "Student": 1370,
    "Teacher": 86
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4043",
    "Name": "SEKOLAH KEBANGSAAN SIKAMAT",
    "Student": 1269,
    "Teacher": 91
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4044",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SERI PAGI",
    "Student": 1379,
    "Teacher": 95
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4045",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TUANKU JAAFAR 2",
    "Student": 706,
    "Teacher": 52
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4046",
    "Name": "SEKOLAH KEBANGSAAN SEREMBAN 2A",
    "Student": 1397,
    "Teacher": 89
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4047",
    "Name": "SEKOLAH KEBANGSAAN SEREMBAN 2B",
    "Student": 1166,
    "Teacher": 70
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4048",
    "Name": "SEKOLAH KEBANGSAAN PANCHOR",
    "Student": 873,
    "Teacher": 63
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4049",
    "Name": "SEKOLAH KEBANGSAAN WAWASAN",
    "Student": 1273,
    "Teacher": 74
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4050",
    "Name": "SEKOLAH KEBANGSAAN SEREMBAN JAYA",
    "Student": 792,
    "Teacher": 62
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4051",
    "Name": "SEKOLAH KEBANGSAAN SEREMBAN JAYA 2",
    "Student": 889,
    "Teacher": 62
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4052",
    "Name": "SEKOLAH KEBANGSAAN LAVENDER HEIGHTS",
    "Student": 1273,
    "Teacher": 81
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4053",
    "Name": "SEKOLAH KEBANGSAAN TAMAN WARISAN PUTERI",
    "Student": 952,
    "Teacher": 60
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4054",
    "Name": "SEKOLAH KEBANGSAAN SENAWANG 3",
    "Student": 1089,
    "Teacher": 63
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4055",
    "Name": "SEKOLAH KEBANGSAAN BANDAR ENSTEK",
    "Student": 877,
    "Teacher": 50
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4056",
    "Name": "SEKOLAH KEBANGSAAN BANDAR SRI SENDAYAN",
    "Student": 639,
    "Teacher": 40
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBA4057",
    "Name": "SEKOLAH KEBANGSAAN NILAI IMPIAN",
    "Student": null,
    "Teacher": 20
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5001",
    "Name": "SEKOLAH KEBANGSAAN TENGKU ZAINUN",
    "Student": 435,
    "Teacher": 37
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5002",
    "Name": "SEKOLAH KEBANGSAAN TUNKU SYED SYAABAN",
    "Student": 109,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5003",
    "Name": "SEKOLAH KEBANGSAAN KERU",
    "Student": 115,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5004",
    "Name": "SEKOLAH KEBANGSAAN DATUK ABDULLAH",
    "Student": 449,
    "Teacher": 41
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5005",
    "Name": "SEKOLAH KEBANGSAAN GEDOK",
    "Student": 197,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5006",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI JERNEH",
    "Student": 66,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5007",
    "Name": "SEKOLAH KEBANGSAAN ROKAN",
    "Student": 94,
    "Teacher": 14
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5008",
    "Name": "SEKOLAH KEBANGSAAN BUKIT ROKAN (LKTP)",
    "Student": 146,
    "Teacher": 20
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5009",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DUA",
    "Student": 110,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5010",
    "Name": "SEKOLAH KEBANGSAAN AIR KUNING SELATAN",
    "Student": 105,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5011",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PASIR BESAR",
    "Student": 365,
    "Teacher": 34
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5012",
    "Name": "SEKOLAH KEBANGSAAN LONDAH",
    "Student": 165,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5013",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG LADANG",
    "Student": 194,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5014",
    "Name": "SEKOLAH KEBANGSAAN GEMAS",
    "Student": 384,
    "Teacher": 41
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5015",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KELAMAH 'RKTP'",
    "Student": 229,
    "Teacher": 26
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5016",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) BUKIT JALOR",
    "Student": 118,
    "Teacher": 20
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5017",
    "Name": "SEKOLAH KEBANGSAAN JELAI 2 (FELDA)",
    "Student": 353,
    "Teacher": 31
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5050",
    "Name": "SEKOLAH KEBANGSAAN BUKIT ROKAN UTARA",
    "Student": 206,
    "Teacher": 21
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5051",
    "Name": "SEKOLAH KEBANGSAAN JELAI 1",
    "Student": 484,
    "Teacher": 35
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5052",
    "Name": "SEKOLAH KEBANGSAAN JELAI 3 (FELDA)",
    "Student": 232,
    "Teacher": 21
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5053",
    "Name": "SEKOLAH KEBANGSAAN DR. SULAIMAN",
    "Student": 473,
    "Teacher": 45
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBA5054",
    "Name": "SEKOLAH KEBANGSAAN KEM SYED SIRAJUDDIN",
    "Student": 666,
    "Teacher": 49
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6001",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PALONG 12",
    "Student": 186,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6002",
    "Name": "SEKOLAH KEBANGSAAN SERTING ULU",
    "Student": 298,
    "Teacher": 27
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6003",
    "Name": "SEKOLAH KEBANGSAAN SERTING ILIR",
    "Student": 235,
    "Teacher": 20
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6004",
    "Name": "SEKOLAH KEBANGSAAN JEMPOL",
    "Student": 297,
    "Teacher": 33
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6005",
    "Name": "SEKOLAH KEBANGSAAN ROMPIN",
    "Student": 334,
    "Teacher": 32
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6006",
    "Name": "SEKOLAH KEBANGSAAN AYER HITAM",
    "Student": 331,
    "Teacher": 33
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6007",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LUI",
    "Student": 108,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6008",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SAMPO",
    "Student": 107,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6009",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PALONG 1",
    "Student": 192,
    "Teacher": 20
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6010",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PALONG 2",
    "Student": 209,
    "Teacher": 22
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6011",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PASOH 2",
    "Student": 163,
    "Teacher": 21
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6012",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PASOH 3",
    "Student": 196,
    "Teacher": 20
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6013",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PALONG 3",
    "Student": 175,
    "Teacher": 21
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6014",
    "Name": "SEKOLAH KEBANGSAAN LUI TIMOR",
    "Student": 241,
    "Teacher": 25
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6015",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PALONG 4",
    "Student": 237,
    "Teacher": 27
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6016",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PALONG 5",
    "Student": 436,
    "Teacher": 42
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6017",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SERTING",
    "Student": 666,
    "Teacher": 47
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6018",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LUI SELATAN",
    "Student": 264,
    "Teacher": 28
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6019",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PALONG 7",
    "Student": 316,
    "Teacher": 29
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6020",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) PALONG 9",
    "Student": 325,
    "Teacher": 31
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6021",
    "Name": "SEKOLAH KEBANGSAAN PULAPAH",
    "Student": 108,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6023",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SERTING HILIR 2",
    "Student": 292,
    "Teacher": 26
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6024",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) LUI MUDA",
    "Student": 92,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6025",
    "Name": "SEKOLAH KEBANGSAAN DATO' PENGHULU LUAK JEMPOL",
    "Student": 655,
    "Teacher": 54
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6026",
    "Name": "SEKOLAH KEBANGSAAN FELDA RAJA ALIAS 3",
    "Student": 500,
    "Teacher": 39
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6027",
    "Name": "SEKOLAH KEBANGSAAN PALONG 14",
    "Student": 135,
    "Teacher": 20
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6028",
    "Name": "SEKOLAH KEBANGSAAN SERTING HILIR KOMPLEKS",
    "Student": 285,
    "Teacher": 35
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6029",
    "Name": "SEKOLAH KEBANGSAAN PALONG 8 (FELDA)",
    "Student": 365,
    "Teacher": 36
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBA6030",
    "Name": "SEKOLAH KEBANGSAAN TUNKU MUDA SERTING",
    "Student": 526,
    "Teacher": 43
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBB0015",
    "Name": "SEKOLAH KEBANGSAAN UNDANG JELEBU",
    "Student": 533,
    "Teacher": 51
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBB0016",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG DURIAN",
    "Student": 262,
    "Teacher": 30
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBB1042",
    "Name": "SEKOLAH KEBANGSAAN TUNKU MUNAWIR",
    "Student": 234,
    "Teacher": 27
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBB1043",
    "Name": "SEKOLAH KEBANGSAAN TUNKU KURSHIAH",
    "Student": 386,
    "Teacher": 38
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBB1044",
    "Name": "SEKOLAH KEBANGSAAN DATO' IDRIS",
    "Student": 190,
    "Teacher": 26
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBB2014",
    "Name": "SEKOLAH KEBANGSAAN PORT DICKSON",
    "Student": 947,
    "Teacher": 62
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBB4031",
    "Name": "SEKOLAH KEBANGSAAN KING GEORGE V",
    "Student": 1335,
    "Teacher": 82
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBB4032",
    "Name": "SEKOLAH KEBANGSAAN DATO KLANA MAAMOR",
    "Student": 211,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBB4036",
    "Name": "SEKOLAH KEBANGSAAN ST PAUL",
    "Student": 616,
    "Teacher": 51
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBB4037",
    "Name": "SEKOLAH KEBANGSAAN METHODIST (ACS) SEREMBAN",
    "Student": 293,
    "Teacher": 26
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBB4038",
    "Name": "SEKOLAH KEBANGSAAN PUTERI",
    "Student": 1029,
    "Teacher": 64
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBB4041",
    "Name": "SEKOLAH KEBANGSAAN DATO' RAJA MELANA",
    "Student": 236,
    "Teacher": 23
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBB5017",
    "Name": "SEKOLAH KEBANGSAAN TUANKU ABDUL RAHMAN",
    "Student": 322,
    "Teacher": 42
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBB5019",
    "Name": "SEKOLAH KEBANGSAAN TUNKU BESAR TAMPIN",
    "Student": 564,
    "Teacher": 46
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBB6001",
    "Name": "SEKOLAH KEBANGSAAN ST AIDAN",
    "Student": 432,
    "Teacher": 38
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC0017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HUA",
    "Student": 116,
    "Teacher": 14
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC0018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUN YIN",
    "Student": 220,
    "Teacher": 21
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC0019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG. BARU PETALING",
    "Student": 42,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC0020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU PERTANG",
    "Student": 90,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC0021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG PERTANG",
    "Student": 98,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC0022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU SUNGAI MUNTOH",
    "Student": 29,
    "Teacher": 9
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC0023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) DURIAN TIPUS",
    "Student": 66,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBC1047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA KUALA PILAH",
    "Student": 329,
    "Teacher": 30
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBC1048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SENALING",
    "Student": 68,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBC1049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TG IPOH",
    "Student": 25,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBC1051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHAI JOHOL",
    "Student": 35,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBC1052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU BKT GELUGOR",
    "Student": 61,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBC1053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHUN DANGI",
    "Student": 96,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBC1054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU KEPIS",
    "Student": 8,
    "Teacher": 7
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBC1057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE HUA AIR MAWANG",
    "Student": 31,
    "Teacher": 7
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBC1058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HUA JUASSEH",
    "Student": 55,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PORT DICKSON",
    "Student": 452,
    "Teacher": 35
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA LUKUT",
    "Student": 582,
    "Teacher": 43
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA TELOK KEMANG",
    "Student": 117,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PASIR PANJANG",
    "Student": 117,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG PIN",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YIK CHIAO",
    "Student": 240,
    "Teacher": 22
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUAH",
    "Student": 61,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YIK HWA",
    "Student": 56,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2024",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU SG NIPAH",
    "Student": 82,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU TANAH MERAH 'A'",
    "Student": 101,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TANAH MERAH SITE C",
    "Student": 88,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2028",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LADANG SUA BETONG",
    "Student": 25,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST LEONARDS",
    "Student": 155,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LADANG SILIAU",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BRADWALL",
    "Student": 287,
    "Teacher": 23
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBC2033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BANDAR SPRINGHILL",
    "Student": 183,
    "Teacher": 14
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBC3027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HUA REMBAU",
    "Student": 91,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBC3028",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA PEDAS",
    "Student": 35,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBC3029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI TECK KOTA",
    "Student": 42,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA",
    "Student": 556,
    "Teacher": 38
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI HWA",
    "Student": 326,
    "Teacher": 25
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHAN WA",
    "Student": 297,
    "Teacher": 23
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAN MIN",
    "Student": 204,
    "Teacher": 23
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HUA",
    "Student": 523,
    "Teacher": 32
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUO MIN",
    "Student": 293,
    "Teacher": 24
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SINO ENGLISH",
    "Student": 234,
    "Teacher": 22
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HUA",
    "Student": 865,
    "Teacher": 59
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MA HWA KG. BARU RASAH",
    "Student": 286,
    "Teacher": 29
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU RAHANG",
    "Student": 195,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG SERI SIKAMAT",
    "Student": 340,
    "Teacher": 29
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU MAMBAU",
    "Student": 495,
    "Teacher": 38
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HILLSIDE SEREMBAN",
    "Student": 545,
    "Teacher": 34
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU BT 8 LABU",
    "Student": 24,
    "Teacher": 9
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MIN SHING",
    "Student": 38,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUO MIN",
    "Student": 360,
    "Teacher": 32
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU PAROI",
    "Student": 248,
    "Teacher": 26
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU PANTAI",
    "Student": 22,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN",
    "Student": 66,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4062",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA MANTIN",
    "Student": 575,
    "Teacher": 40
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI CHI MANTIN",
    "Student": 186,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4064",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU PAJAM",
    "Student": 113,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU BROGA",
    "Student": 129,
    "Teacher": 14
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU CHAI ULU BERANANG",
    "Student": 89,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4067",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA RANTAU",
    "Student": 200,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4068",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) FOREST HEIGHTS SEREMBAN",
    "Student": 138,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4069",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KELPIN SEREMBAN",
    "Student": 718,
    "Teacher": 44
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4070",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG HUA",
    "Student": 1033,
    "Teacher": 61
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4071",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI SALAK",
    "Student": 631,
    "Teacher": 42
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4072",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PUTRA NILAI",
    "Student": 594,
    "Teacher": 36
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBC4073",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BANDAR SRI SENDAYAN",
    "Student": 392,
    "Teacher": 22
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBC5020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TAMPIN",
    "Student": 361,
    "Teacher": 30
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBC5021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU TAMPIN",
    "Student": 368,
    "Teacher": 30
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBC5022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUO MIN GEMAS",
    "Student": 136,
    "Teacher": 18
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBC5023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU GEMAS",
    "Student": 92,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBC5024",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN GEMENCHEH",
    "Student": 187,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBC5025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU GEDOK",
    "Student": 114,
    "Teacher": 14
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBC5026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AIR KUNING SELATAN",
    "Student": 39,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBC5027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LADANG REGENT",
    "Student": 14,
    "Teacher": 8
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC6001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI SIN",
    "Student": 54,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC6002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI WEN",
    "Student": 573,
    "Teacher": 35
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC6003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BAHAU",
    "Student": 503,
    "Teacher": 36
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC6004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA",
    "Student": 99,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC6005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG MAHSAN",
    "Student": 219,
    "Teacher": 24
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC6006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA AIR HITAM",
    "Student": 36,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC6008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MIDDLETON",
    "Student": 79,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBC6009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SAN",
    "Student": 31,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD0024",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PERTANG",
    "Student": 57,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBD1066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUALA PILAH",
    "Student": 141,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBD1067",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG JUASSEH",
    "Student": 34,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PORT DICKSON",
    "Student": 318,
    "Teacher": 26
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG ST LEONARD",
    "Student": 43,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SENDAYAN",
    "Student": 60,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KEM ASKAR MELAYU",
    "Student": 57,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SENGKANG",
    "Student": 47,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SUNGALA",
    "Student": 40,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SUA BETONG",
    "Student": 29,
    "Teacher": 8
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TANAH MERAH",
    "Student": 86,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ATHERTON",
    "Student": 16,
    "Teacher": 8
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BRADWALL",
    "Student": 21,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR SPRING HILL",
    "Student": 317,
    "Teacher": 28
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI SALAK",
    "Student": 148,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SAGGA",
    "Student": 53,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SILIAU",
    "Student": 42,
    "Teacher": 9
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LINSUM",
    "Student": 73,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TAMPIN LINGGI",
    "Student": 32,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBD2052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MUKUNDAN",
    "Student": 116,
    "Teacher": 14
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBD3031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATU HAMPAR",
    "Student": 49,
    "Teacher": 9
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBD3032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHEMBONG",
    "Student": 133,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBD3034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG BARU",
    "Student": 20,
    "Teacher": 8
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBD3035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PERHENTIAN TINGGI",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4069",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) CONVENT SEREMBAN (KOMPLEKS WAWASAN)",
    "Student": 764,
    "Teacher": 49
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4070",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LORONG JAVA",
    "Student": 672,
    "Teacher": 46
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4071",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN LOBAK",
    "Student": 451,
    "Teacher": 37
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4072",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) NILAI",
    "Student": 565,
    "Teacher": 42
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4073",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATANG BENAR",
    "Student": 48,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4074",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KIRBY",
    "Student": 29,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4075",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KUBANG",
    "Student": 24,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4076",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) DESA CEMPAKA",
    "Student": 95,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4077",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG CAIRO",
    "Student": 199,
    "Teacher": 21
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4078",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LABU BHG 1",
    "Student": 32,
    "Teacher": 11
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4079",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LABU BHG 4",
    "Student": 21,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4080",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SENAWANG",
    "Student": 514,
    "Teacher": 40
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4081",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SEREMBAN",
    "Student": 495,
    "Teacher": 41
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4083",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KOMBOK",
    "Student": 54,
    "Teacher": 14
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4084",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) RANTAU",
    "Student": 291,
    "Teacher": 27
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4085",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SHANGHAI SEREMBAN",
    "Student": 173,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4086",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN",
    "Student": 234,
    "Teacher": 25
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4087",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LENGGENG",
    "Student": 84,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBD4088",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR SRI SENDAYAN",
    "Student": 340,
    "Teacher": 26
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBD5029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMPIN",
    "Student": 289,
    "Teacher": 26
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBD5030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG REPAH",
    "Student": 34,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBD5031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG REGENT",
    "Student": 250,
    "Teacher": 25
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBD5032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) AIR KUNING SELATAN",
    "Student": 26,
    "Teacher": 12
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBD5033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT KLEDEK",
    "Student": 17,
    "Teacher": 8
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBD5034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) GEMAS",
    "Student": 122,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NBD5035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG KELAMAH",
    "Student": 18,
    "Teacher": 8
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD6001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BAHAU",
    "Student": 215,
    "Teacher": 23
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD6002",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG AIR HITAM",
    "Student": 96,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD6003",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL & TELUGU) LADANG GEDDES",
    "Student": 92,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD6004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG SEBALING",
    "Student": 15,
    "Teacher": 7
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD6005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KELPIN",
    "Student": 27,
    "Teacher": 8
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD6006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SENAMA",
    "Student": 81,
    "Teacher": 9
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD6007",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ST HELIER",
    "Student": 169,
    "Teacher": 14
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD6008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SIALANG",
    "Student": 86,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD6009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG JERAM PADANG",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD6010",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) DATO' K.PATHMANABAN",
    "Student": 90,
    "Teacher": 17
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBD6011",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MIDDLETON",
    "Student": 30,
    "Teacher": 8
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NBE1040",
    "Name": "SEKOLAH KEBANGSAAN TUNKU LAKSAMANA NASIR",
    "Student": 130,
    "Teacher": 16
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NBE2067",
    "Name": "SEKOLAH KEBANGSAAN PANGLIMA ADNAN",
    "Student": 830,
    "Teacher": 67
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBE3024",
    "Name": "SEKOLAH KEBANGSAAN CHEMBONG",
    "Student": 407,
    "Teacher": 45
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBE3042",
    "Name": "SEKOLAH KEBANGSAAN UNDANG REMBAU",
    "Student": 455,
    "Teacher": 53
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NBE3043",
    "Name": "SEKOLAH KEBANGSAAN ASTANA RAJA",
    "Student": 125,
    "Teacher": 21
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBE4136",
    "Name": "SEKOLAH KEBANGSAAN MANTIN",
    "Student": 1291,
    "Teacher": 87
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NBE4143",
    "Name": "SEKOLAH KEBANGSAAN DATO' SHAH BANDAR ABU BAKAR LABU HILIR",
    "Student": 450,
    "Teacher": 30
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NBE6001",
    "Name": "SEKOLAH KEBANGSAAN BATU KIKIR",
    "Student": 636,
    "Teacher": 47
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NCA4001",
    "Name": "SEKOLAH RENDAH TAHFIZ AL-QURAN INTEGRASI AL-IRSHAD",
    "Student": 159,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA0025",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' UNDANG MUSA AL-HAJ",
    "Student": 631,
    "Teacher": 69
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA0026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' UNDANG SYED ALI AL-JUFRI SIMPANG GELAMI",
    "Student": 383,
    "Teacher": 32
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEA1080",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ZABA",
    "Student": 333,
    "Teacher": 52
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEA1081",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DANGI",
    "Student": 64,
    "Teacher": 15
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEA1083",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JUASSEH",
    "Student": 426,
    "Teacher": 47
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEA1084",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SENALING",
    "Student": 212,
    "Teacher": 26
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NEA2052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA JUMAAT",
    "Student": 1106,
    "Teacher": 81
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NEA2053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK HAJI ABDUL SAMAD",
    "Student": 843,
    "Teacher": 84
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NEA2055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN YAM TUAN RADIN",
    "Student": 1258,
    "Teacher": 88
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NEA2056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG BARU SIRUSA",
    "Student": 573,
    "Teacher": 50
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NEA2057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN INTAN PERDANA",
    "Student": 941,
    "Teacher": 61
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NEA3035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' SEDIA RAJA",
    "Student": 682,
    "Teacher": 73
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NEA3036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' UNDANG HAJI ADNAN",
    "Student": 462,
    "Teacher": 51
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NEA3037",
    "Name": "SEKOLAH MENENGAH SAINS REMBAU",
    "Student": 870,
    "Teacher": 69
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NEA3038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEDAS",
    "Student": 415,
    "Teacher": 49
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4088",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU AMPUAN DURAH",
    "Student": 1184,
    "Teacher": 118
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4090",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT MEWAH",
    "Student": 2015,
    "Teacher": 119
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4098",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' SHAHARDIN",
    "Student": 625,
    "Teacher": 54
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4099",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAMBAU",
    "Student": 851,
    "Teacher": 79
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SENAWANG",
    "Student": 1394,
    "Teacher": 90
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' HAJI MOHD REDZA",
    "Student": 922,
    "Teacher": 77
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENDETA ZA'BA",
    "Student": 1184,
    "Teacher": 97
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS KLIA",
    "Student": 1090,
    "Teacher": 78
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DESA CEMPAKA",
    "Student": 1660,
    "Teacher": 116
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4105",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SEMARAK",
    "Student": 1334,
    "Teacher": 103
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4106",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PAGI",
    "Student": 1715,
    "Teacher": 117
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4107",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN TUANKU JAAFAR",
    "Student": 1319,
    "Teacher": 99
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4108",
    "Name": "SEKOLAH MENENGAH SAINS TUANKU MUNAWIR",
    "Student": 588,
    "Teacher": 51
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4109",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEREMBAN 2",
    "Student": 1330,
    "Teacher": 94
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4110",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANCHOR",
    "Student": 763,
    "Teacher": 67
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4111",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEREMBAN JAYA",
    "Student": 1325,
    "Teacher": 93
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4112",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT KEPAYANG",
    "Student": 1281,
    "Teacher": 89
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4113",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEREMBAN JAYA 2",
    "Student": 863,
    "Teacher": 57
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4114",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN FOREST HEIGHTS",
    "Student": 429,
    "Teacher": 100
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4115",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN WARISAN PUTERI",
    "Student": 511,
    "Teacher": 48
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4116",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' SHAMSUDIN NAIN",
    "Student": 639,
    "Teacher": 57
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4117",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEREMBAN 3",
    "Student": 512,
    "Teacher": 42
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4118",
    "Name": "SEKOLAH MENENGAH SAINS TUANKU AISHAH ROHANI",
    "Student": 601,
    "Teacher": 49
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4119",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHAN WA II",
    "Student": 1321,
    "Teacher": 80
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4120",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SRI SENDAYAN",
    "Student": 1133,
    "Teacher": 76
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4121",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR ENSTEK",
    "Student": 737,
    "Teacher": 49
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEA4122",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NILAI IMPIAN",
    "Student": 303,
    "Teacher": 22
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NEA5051",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PASIR BESAR",
    "Student": 356,
    "Teacher": 35
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NEA5052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU SYED IDRUS",
    "Student": 757,
    "Teacher": 72
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NEA5054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT ROKAN",
    "Student": 220,
    "Teacher": 28
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NEA5055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GEMAS",
    "Student": 594,
    "Teacher": 52
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NEA5056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JELAI",
    "Student": 609,
    "Teacher": 48
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NEA5057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN INDAH",
    "Student": 350,
    "Teacher": 42
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA6001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAHAU",
    "Student": 1003,
    "Teacher": 83
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA6002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) PASOH 2",
    "Student": 236,
    "Teacher": 28
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA6003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PERPATIH",
    "Student": 452,
    "Teacher": 52
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEA6004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JELAI",
    "Student": 382,
    "Teacher": 37
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA6005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) PALONG DUA",
    "Student": 210,
    "Teacher": 25
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA6006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) LUI BARAT",
    "Student": 617,
    "Teacher": 63
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA6007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) PALONG 7",
    "Student": 409,
    "Teacher": 36
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA6008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) BANDAR BARU SERTING",
    "Student": 574,
    "Teacher": 56
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA6009",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERTING HILIR KOMPLEKS",
    "Student": 468,
    "Teacher": 49
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA6010",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALAM BERAJA",
    "Student": 412,
    "Teacher": 38
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA6011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI JEMPOL",
    "Student": 422,
    "Teacher": 45
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEA6012",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAHAU 2",
    "Student": 321,
    "Teacher": 35
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEB0026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN UNDANG JELEBU",
    "Student": 546,
    "Teacher": 41
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEB0027",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERTANG",
    "Student": 248,
    "Teacher": 30
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEB1081",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUANKU MUHAMMAD",
    "Student": 669,
    "Teacher": 84
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEB1082",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU KURSHIAH",
    "Student": 335,
    "Teacher": 34
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEB1083",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG HUA",
    "Student": 475,
    "Teacher": 46
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NEB2054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI PORT DICKSON",
    "Student": 1294,
    "Teacher": 116
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NEB3036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN UNDANG REMBAU",
    "Student": 941,
    "Teacher": 84
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEB4089",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KING GEORGE V",
    "Student": 861,
    "Teacher": 72
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEB4090",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST PAUL",
    "Student": 999,
    "Teacher": 72
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEB4091",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST ACS",
    "Student": 769,
    "Teacher": 57
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEB4092",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTERI",
    "Student": 984,
    "Teacher": 77
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEB4093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI AMPANGAN",
    "Student": 1222,
    "Teacher": 83
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEB4094",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHAN WA",
    "Student": 1599,
    "Teacher": 85
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEB4095",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU AMPUAN NAJIHAH",
    "Student": 458,
    "Teacher": 46
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEB4096",
    "Name": "KOLEJ TUNKU KURSHIAH",
    "Student": 758,
    "Teacher": 77
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEB4097",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO SHEIKH AHMAD",
    "Student": 715,
    "Teacher": 74
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEB4137",
    "Name": "SEKOLAH MENENGAH DATUK ABDUL RAZAK",
    "Student": 739,
    "Teacher": 64
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEB6001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHI WEN",
    "Student": 742,
    "Teacher": 52
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEE0028",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TERIANG HILIR",
    "Student": 570,
    "Teacher": 56
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEE1086",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU BESAR BURHANUDDIN",
    "Student": 551,
    "Teacher": 51
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEE1087",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' ABDUL SAMAD",
    "Student": 521,
    "Teacher": 47
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEE1088",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK UNDANG ABDUL MANAP",
    "Student": 404,
    "Teacher": 43
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NEE1098",
    "Name": "SEKOLAH MENENGAH SAINS TUANKU JAAFAR",
    "Student": 732,
    "Teacher": 64
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NEE2057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK MUHAMMAD YUSOF",
    "Student": 278,
    "Teacher": 32
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NEE2064",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PASIR PANJANG",
    "Student": 487,
    "Teacher": 54
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NEE2065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANAH MERAH",
    "Student": 651,
    "Teacher": 56
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NEE2068",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT SAUJANA",
    "Student": 797,
    "Teacher": 69
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NEE3037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK ABDULLAH",
    "Student": 434,
    "Teacher": 44
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEE4098",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' KLANA PUTRA",
    "Student": 771,
    "Teacher": 60
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEE4099",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RANTAU",
    "Student": 721,
    "Teacher": 66
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEE4100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' MOHD SAID",
    "Student": 1514,
    "Teacher": 122
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEE4101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MANTIN",
    "Student": 1813,
    "Teacher": 129
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NEE4102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (FELDA) SERI SENDAYAN",
    "Student": 839,
    "Teacher": 55
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NEE5037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU BESAR",
    "Student": 671,
    "Teacher": 81
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NEE5038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUANKU ABDUL RAHMAN",
    "Student": 925,
    "Teacher": 81
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NEE5039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT JALOR",
    "Student": 249,
    "Teacher": 28
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NEE5040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' MOHD TAHA",
    "Student": 1137,
    "Teacher": 86
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEE6001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK MANSOR",
    "Student": 1038,
    "Teacher": 109
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NEE6002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU KIKIR",
    "Student": 981,
    "Teacher": 103
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NFT0001",
    "Name": "SEKOLAH MENENGAH AGAMA KUALA KLAWANG",
    "Student": 114,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NFT0002",
    "Name": "SEKOLAH MENENGAH AGAMA DATO' HAJI TAN AHMAD",
    "Student": 188,
    "Teacher": 38
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NFT1001",
    "Name": "SEKOLAH MENENGAH RENDAH AGAMA JOHOL",
    "Student": 51,
    "Teacher": 13
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NFT1002",
    "Name": "SEKOLAH MENENGAH AGAMA ULU JEMPOL",
    "Student": 74,
    "Teacher": 19
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NFT1003",
    "Name": "SEKOLAH MENENGAH AGAMA SAINS KUALA PILAH",
    "Student": 267,
    "Teacher": 44
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NFT2001",
    "Name": "SEKOLAH MENENGAH AGAMA CHUAH",
    "Student": 142,
    "Teacher": 24
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NFT2002",
    "Name": "SEKOLAH MENENGAH AGAMA TELOK KEMANG",
    "Student": 246,
    "Teacher": 41
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NFT3001",
    "Name": "SEKOLAH MENENGAH AGAMA HAJI MOHD.YATIM",
    "Student": 352,
    "Teacher": 63
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NFT3002",
    "Name": "SEKOLAH MENENGAH AGAMA REMBAU",
    "Student": 217,
    "Teacher": 29
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NFT4001",
    "Name": "SEKOLAH MENENGAH AGAMA RANTAU",
    "Student": 229,
    "Teacher": 29
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NFT4002",
    "Name": "SEKOLAH MENENGAH AGAMA NILAI",
    "Student": 241,
    "Teacher": 47
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NFT4003",
    "Name": "SEKOLAH MENENGAH AGAMA DATO' KLANA PETRA MAAMOR",
    "Student": 527,
    "Teacher": 49
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NFT5001",
    "Name": "SEKOLAH MENENGAH AGAMA REPAH",
    "Student": 233,
    "Teacher": 37
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD TAMPIN",
    "Kod": "NFT5002",
    "Name": "SEKOLAH MENENGAH AGAMA GEMAS",
    "Student": 140,
    "Teacher": 21
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NFT6001",
    "Name": "SEKOLAH MENENGAH AGAMA HAJI MUHAMAD",
    "Student": 295,
    "Teacher": 35
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NFT6002",
    "Name": "SEKOLAH MENENGAH AGAMA DATO' HAJI MUSTAFA",
    "Student": 355,
    "Teacher": 40
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NHA0001",
    "Name": "KOLEJ VOKASIONAL KUALA KLAWANG",
    "Student": 591,
    "Teacher": 90
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD KUALA PILAH",
    "Kod": "NHA1001",
    "Name": "KOLEJ VOKASIONAL JUASSEH",
    "Student": 786,
    "Teacher": 133
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD PORT DICKSON",
    "Kod": "NHA2002",
    "Name": "KOLEJ VOKASIONAL PORT DICKSON",
    "Student": 1012,
    "Teacher": 133
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NHA3001",
    "Name": "KOLEJ VOKASIONAL DATO' UNDANG HJ. MUHAMAD SHARIP",
    "Student": 366,
    "Teacher": 67
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NHA3002",
    "Name": "KOLEJ VOKASIONAL DATO LELA MAHARAJA",
    "Student": 571,
    "Teacher": 135
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NHA4001",
    "Name": "KOLEJ VOKASIONAL AMPANGAN",
    "Student": 718,
    "Teacher": 113
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NKE4144",
    "Name": "SMT TUANKU JAAFAR",
    "Student": 659,
    "Teacher": 53
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD REMBAU",
    "Kod": "NRA3001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA DATO' HAJI ABU HASSAN HAJI SAIL",
    "Student": 652,
    "Teacher": 108
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NRA4001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SHEIKH HJ MOHD SAID",
    "Student": 765,
    "Teacher": 63
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD SEREMBAN",
    "Kod": "NRA4002",
    "Name": "SEKOLAH MENENGAH AGAMA PERSEKUTUAN LABU",
    "Student": 750,
    "Teacher": 71
  },
  {
    "NationalState": "NEGERI SEMBILAN",
    "PPD": "PPD JEMPOL/JELEBU",
    "Kod": "NRA6001",
    "Name": "SEKOLAH BERASRAMA PENUH INTEGRASI JEMPOL",
    "Student": 588,
    "Teacher": 50
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0001",
    "Name": "SEKOLAH KEBANGSAAN ALMA",
    "Student": 396,
    "Teacher": 48
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0002",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TEH",
    "Student": 226,
    "Teacher": 28
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0003",
    "Name": "SEKOLAH KEBANGSAAN GUAR PERAHU",
    "Student": 622,
    "Teacher": 52
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0005",
    "Name": "SEKOLAH KEBANGSAAN JALAN BAHARU PERAI",
    "Student": 806,
    "Teacher": 70
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0006",
    "Name": "SEKOLAH KEBANGSAAN JUARA",
    "Student": 485,
    "Teacher": 44
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0007",
    "Name": "SEKOLAH KEBANGSAAN JURU",
    "Student": 766,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0008",
    "Name": "SEKOLAH KEBANGSAAN KEBUN SIREH",
    "Student": 534,
    "Teacher": 42
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0009",
    "Name": "SEKOLAH KEBANGSAAN KHIR JOHARI",
    "Student": 229,
    "Teacher": 28
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0010",
    "Name": "SEKOLAH KEBANGSAAN MACANG BUBOK",
    "Student": 333,
    "Teacher": 31
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0011",
    "Name": "SEKOLAH KEBANGSAAN MENGKUANG",
    "Student": 852,
    "Teacher": 60
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0012",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG PASIR",
    "Student": 644,
    "Teacher": 54
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0013",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG PAUH",
    "Student": 487,
    "Teacher": 42
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0014",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG TOK KANDU",
    "Student": 1195,
    "Teacher": 89
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0015",
    "Name": "SEKOLAH KEBANGSAAN SERI PENANTI",
    "Student": 817,
    "Teacher": 64
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0016",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RAMBAI",
    "Student": 381,
    "Teacher": 45
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0017",
    "Name": "SEKOLAH KEBANGSAAN TANAH LIAT",
    "Student": 824,
    "Teacher": 68
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0018",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG JAYA",
    "Student": 1162,
    "Teacher": 102
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0019",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG JAYA 2",
    "Student": 986,
    "Teacher": 72
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0020",
    "Name": "SEKOLAH KEBANGSAAN TAMAN INDERAWASIH",
    "Student": 278,
    "Teacher": 30
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0021",
    "Name": "SEKOLAH KEBANGSAAN BUKIT INDERA MUDA",
    "Student": 539,
    "Teacher": 44
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0022",
    "Name": "SEKOLAH KEBANGSAAN BUKIT MINYAK",
    "Student": 718,
    "Teacher": 67
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0023",
    "Name": "SEKOLAH KEBANGSAAN ALMA JAYA",
    "Student": 727,
    "Teacher": 58
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0024",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS ALMA",
    "Student": 31,
    "Teacher": 25
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0025",
    "Name": "SEKOLAH KEBANGSAAN SERI IMPIAN",
    "Student": 757,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0026",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU PERDA",
    "Student": 1072,
    "Teacher": 82
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0027",
    "Name": "SEKOLAH KEBANGSAAN PAUH JAYA",
    "Student": 1341,
    "Teacher": 90
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0028",
    "Name": "SEKOLAH KEBANGSAAN PERMAI INDAH",
    "Student": 611,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0029",
    "Name": "SEKOLAH KEBANGSAAN MACHANG BUBOK II",
    "Student": 591,
    "Teacher": 57
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBA0030",
    "Name": "SEKOLAH KEBANGSAAN TAMAN IMPIAN",
    "Student": 342,
    "Teacher": 35
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1001",
    "Name": "SEKOLAH KEBANGSAAN AYER ITAM",
    "Student": 296,
    "Teacher": 41
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1002",
    "Name": "SEKOLAH KEBANGSAAN BATU FERINGGHI",
    "Student": 137,
    "Teacher": 20
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1003",
    "Name": "SEKOLAH KEBANGSAAN DATO KRAMAT",
    "Student": 616,
    "Teacher": 43
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1005",
    "Name": "SEKOLAH KEBANGSAAN JELUTONG",
    "Student": 402,
    "Teacher": 35
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1006",
    "Name": "SEKOLAH KEBANGSAAN TAN SRI P.RAMLEE",
    "Student": 314,
    "Teacher": 37
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1008",
    "Name": "SEKOLAH KEBANGSAAN SRI TANJUNG",
    "Student": 77,
    "Teacher": 12
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1009",
    "Name": "SEKOLAH KEBANGSAAN SG GELUGOR",
    "Student": 960,
    "Teacher": 66
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA1010",
    "Name": "SEKOLAH KEBANGSAAN SG NIBONG",
    "Student": 635,
    "Teacher": 47
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1011",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG BUNGA",
    "Student": 222,
    "Teacher": 29
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1012",
    "Name": "SEKOLAH KEBANGSAAN TANJONG TOKONG",
    "Student": 310,
    "Teacher": 30
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1014",
    "Name": "SEKOLAH KEBANGSAAN MINDEN HEIGHT",
    "Student": 1248,
    "Teacher": 81
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1015",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS JALAN HUTTON",
    "Student": 47,
    "Teacher": 24
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1016",
    "Name": "SEKOLAH KEBANGSAAN JALAN HAMILTON",
    "Student": 321,
    "Teacher": 40
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1017",
    "Name": "SEKOLAH KEBANGSAAN BUKIT GAMBIR",
    "Student": 623,
    "Teacher": 59
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBA1018",
    "Name": "SEKOLAH KEBANGSAAN SERI INDAH",
    "Student": 453,
    "Teacher": 43
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2001",
    "Name": "SEKOLAH KEBANGSAAN ARA RENDANG",
    "Student": 613,
    "Teacher": 46
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2002",
    "Name": "SEKOLAH KEBANGSAAN BAGAN AJAM",
    "Student": 470,
    "Teacher": 43
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2003",
    "Name": "SEKOLAH KEBANGSAAN BAGAN JERMAL",
    "Student": 518,
    "Teacher": 58
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2004",
    "Name": "SEKOLAH KEBANGSAAN BAGAN TUAN KECHIL",
    "Student": 188,
    "Teacher": 23
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2005",
    "Name": "SEKOLAH KEBANGSAAN BUMBONG LIMA",
    "Student": 237,
    "Teacher": 29
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2006",
    "Name": "SEKOLAH KEBANGSAAN MOHD SHAH",
    "Student": 424,
    "Teacher": 35
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2007",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG TO'BEDOR",
    "Student": 618,
    "Teacher": 48
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2008",
    "Name": "SEKOLAH KEBANGSAAN KUALA MUDA",
    "Student": 273,
    "Teacher": 35
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2009",
    "Name": "SEKOLAH KEBANGSAAN KUALA PERAI",
    "Student": 228,
    "Teacher": 31
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2011",
    "Name": "SEKOLAH KEBANGSAAN LAHAR KEPAR",
    "Student": 265,
    "Teacher": 39
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2012",
    "Name": "SEKOLAH KEBANGSAAN PADANG MENORA",
    "Student": 422,
    "Teacher": 36
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2013",
    "Name": "SEKOLAH KEBANGSAAN PASIR GEBU",
    "Student": 397,
    "Teacher": 44
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2014",
    "Name": "SEKOLAH KEBANGSAAN PAYA KELADI",
    "Student": 766,
    "Teacher": 54
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2015",
    "Name": "SEKOLAH KEBANGSAAN PENAGA",
    "Student": 678,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2016",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG BERTAM",
    "Student": 539,
    "Teacher": 43
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2017",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG BINJAI",
    "Student": 173,
    "Teacher": 19
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2018",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG BULOH",
    "Student": 440,
    "Teacher": 37
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2019",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG JANGGUS",
    "Student": 548,
    "Teacher": 43
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2020",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG SINTOK",
    "Student": 353,
    "Teacher": 33
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2021",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG TO'JAYA",
    "Student": 545,
    "Teacher": 45
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2022",
    "Name": "SEKOLAH KEBANGSAAN PINANG TUNGGAL",
    "Student": 675,
    "Teacher": 61
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2023",
    "Name": "SEKOLAH KEBANGSAAN PULAU MERTAJAM",
    "Student": 213,
    "Teacher": 24
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2024",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DUA",
    "Student": 975,
    "Teacher": 91
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2025",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PUYU",
    "Student": 386,
    "Teacher": 33
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2026",
    "Name": "SEKOLAH KEBANGSAAN TASEK GELUGOR",
    "Student": 669,
    "Teacher": 51
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2027",
    "Name": "SEKOLAH KEBANGSAAN TELOK AYER TAWAR",
    "Student": 429,
    "Teacher": 37
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2028",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SELAMAT",
    "Student": 681,
    "Teacher": 48
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2029",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SENANGAN",
    "Student": 676,
    "Teacher": 53
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2030",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI NYIOR",
    "Student": 82,
    "Teacher": 18
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2031",
    "Name": "SEKOLAH KEBANGSAAN LAHAR YOOI",
    "Student": 544,
    "Teacher": 44
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2032",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN JAYA",
    "Student": 936,
    "Teacher": 81
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2033",
    "Name": "SEKOLAH KEBANGSAAN KEPALA BATAS",
    "Student": 755,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2034",
    "Name": "SEKOLAH KEBANGSAAN BERTAM INDAH",
    "Student": 920,
    "Teacher": 63
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2035",
    "Name": "SEKOLAH KEBANGSAAN POKOK SENA",
    "Student": 721,
    "Teacher": 68
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2036",
    "Name": "SEKOLAH KEBANGSAAN PENAGA JAYA",
    "Student": 342,
    "Teacher": 30
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2037",
    "Name": "SEKOLAH KEBANGSAAN SERI GEMILANG",
    "Student": 518,
    "Teacher": 40
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2038",
    "Name": "SEKOLAH KEBANGSAAN BERTAM PERDANA",
    "Student": 1708,
    "Teacher": 117
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2039",
    "Name": "SEKOLAH KEBANGSAAN DESA MURNI",
    "Student": 822,
    "Teacher": 62
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2040",
    "Name": "SEKOLAH KEBANGSAAN BERTAM PUTRA",
    "Student": 497,
    "Teacher": 36
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBA2041",
    "Name": "SEKOLAH KEBANGSAAN TASEK GELUGOR",
    "Student": 171,
    "Teacher": 21
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3001",
    "Name": "SEKOLAH KEBANGSAAN BATU MAUNG",
    "Student": 435,
    "Teacher": 41
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3002",
    "Name": "SEKOLAH KEBANGSAAN BAYAN LEPAS",
    "Student": 821,
    "Teacher": 60
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3003",
    "Name": "SEKOLAH KEBANGSAAN GENTING",
    "Student": 478,
    "Teacher": 40
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3004",
    "Name": "SEKOLAH KEBANGSAAN KONGSI",
    "Student": 550,
    "Teacher": 43
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3005",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG DAMAR LAUT",
    "Student": 542,
    "Teacher": 49
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3006",
    "Name": "SEKOLAH KEBANGSAAN TAN SRI AWANG HAD SALLEH",
    "Student": 218,
    "Teacher": 22
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3007",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ARA",
    "Student": 1464,
    "Teacher": 95
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3008",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KOROK",
    "Student": 288,
    "Teacher": 27
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3009",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PINANG",
    "Student": 313,
    "Teacher": 30
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3010",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI RUSA",
    "Student": 350,
    "Teacher": 33
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3011",
    "Name": "SEKOLAH KEBANGSAAN TELOK BAHANG",
    "Student": 486,
    "Teacher": 42
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3012",
    "Name": "SEKOLAH KEBANGSAAN TELOK KUMBAR",
    "Student": 630,
    "Teacher": 56
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3013",
    "Name": "SEKOLAH KEBANGSAAN TITI TERAS",
    "Student": 357,
    "Teacher": 38
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3014",
    "Name": "SEKOLAH KEBANGSAAN BAYAN BARU",
    "Student": 736,
    "Teacher": 75
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3015",
    "Name": "SEKOLAH KEBANGSAAN SERI RELAU",
    "Student": 771,
    "Teacher": 68
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3016",
    "Name": "SEKOLAH KEBANGSAAN SERI BAYU",
    "Student": 824,
    "Teacher": 59
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3017",
    "Name": "SEKOLAH KEBANGSAAN MUTIARA PERDANA",
    "Student": 1014,
    "Teacher": 74
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3018",
    "Name": "SEKOLAH KEBANGSAAN BALIK PULAU",
    "Student": 575,
    "Teacher": 53
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3019",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BATU",
    "Student": 791,
    "Teacher": 72
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBA3020",
    "Name": "SEKOLAH KEBANGSAAN BAYAN LEPAS 2",
    "Student": 664,
    "Teacher": 48
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4001",
    "Name": "SEKOLAH KEBANGSAAN BKT TAMBUN",
    "Student": 164,
    "Teacher": 29
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4002",
    "Name": "SEKOLAH KEBANGSAAN KELEDANG JAYA",
    "Student": 162,
    "Teacher": 21
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4003",
    "Name": "SEKOLAH KEBANGSAAN NIBONG TEBAL",
    "Student": 187,
    "Teacher": 25
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4004",
    "Name": "SEKOLAH KEBANGSAAN PERMATANG TOK MAHAT",
    "Student": 803,
    "Teacher": 69
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4005",
    "Name": "SEKOLAH KEBANGSAAN PULAU AMAN",
    "Student": 25,
    "Teacher": 7
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4006",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ACHEH",
    "Student": 419,
    "Teacher": 41
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4007",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BAKAP",
    "Student": 595,
    "Teacher": 61
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4008",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DURI",
    "Student": 1058,
    "Teacher": 78
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4009",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KECHIL",
    "Student": 474,
    "Teacher": 37
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4010",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SETAR",
    "Student": 300,
    "Teacher": 32
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4011",
    "Name": "SEKOLAH KEBANGSAAN SERI TASEK",
    "Student": 554,
    "Teacher": 52
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4012",
    "Name": "SEKOLAH KEBANGSAAN SG BAKAU",
    "Student": 210,
    "Teacher": 35
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4013",
    "Name": "SEKOLAH KEBANGSAAN JAWI",
    "Student": 744,
    "Teacher": 59
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4014",
    "Name": "SEKOLAH KEBANGSAAN SERI SENTOSA",
    "Student": 425,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4015",
    "Name": "SEKOLAH KEBANGSAAN SAUJANA INDAH",
    "Student": 735,
    "Teacher": 54
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4016",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MERAK",
    "Student": 497,
    "Teacher": 43
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4017",
    "Name": "SEKOLAH KEBANGSAAN BATU KAWAN",
    "Student": 752,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4018",
    "Name": "SEKOLAH KEBANGSAAN TASEK PERMAI",
    "Student": 481,
    "Teacher": 42
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4019",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TASEK MUTIARA",
    "Student": 1029,
    "Teacher": 87
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBA4020",
    "Name": "SEKOLAH KEBANGSAAN BAKAP INDAH",
    "Student": 287,
    "Teacher": 29
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBB0018",
    "Name": "SEKOLAH KEBANGSAAN CONVENT",
    "Student": 606,
    "Teacher": 50
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBB0019",
    "Name": "SEKOLAH KEBANGSAAN STOWELL",
    "Student": 407,
    "Teacher": 38
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBB0021",
    "Name": "SEKOLAH KEBANGSAAN KG BARU",
    "Student": 109,
    "Teacher": 18
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1013",
    "Name": "SEKOLAH KEBANGSAAN SRI AMAN",
    "Student": 418,
    "Teacher": 38
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1014",
    "Name": "SEKOLAH KEBANGSAAN BATU LANCHANG",
    "Student": 911,
    "Teacher": 66
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1015",
    "Name": "SEKOLAH KEBANGSAAN CONVENT GREEN LANE",
    "Student": 792,
    "Teacher": 52
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1017",
    "Name": "SEKOLAH KEBANGSAAN CONVENT LEBUH LIGHT",
    "Student": 76,
    "Teacher": 15
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1019",
    "Name": "SEKOLAH KEBANGSAAN CONVENT PULAU TIKUS",
    "Student": 305,
    "Teacher": 31
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1021",
    "Name": "SEKOLAH KEBANGSAAN FRANCIS LIGHT",
    "Student": 407,
    "Teacher": 37
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1023",
    "Name": "SEKOLAH KEBANGSAAN BUKIT GELUGOR",
    "Student": 672,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1024",
    "Name": "SEKOLAH KEBANGSAAN HUTCHINGS",
    "Student": 70,
    "Teacher": 20
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1025",
    "Name": "SEKOLAH KEBANGSAAN PEREMPUAN ISLAND",
    "Student": 114,
    "Teacher": 13
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1026",
    "Name": "SEKOLAH KEBANGSAAN JELUTONG BARAT",
    "Student": 371,
    "Teacher": 47
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1030",
    "Name": "SEKOLAH KEBANGSAAN JALAN RESIDENSI",
    "Student": 298,
    "Teacher": 32
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1032",
    "Name": "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST",
    "Student": 580,
    "Teacher": 41
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1034",
    "Name": "SEKOLAH KEBANGSAAN ST XAVIER",
    "Student": 483,
    "Teacher": 37
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1035",
    "Name": "SEKOLAH KEBANGSAAN ST XAVIER CAWANGAN",
    "Student": 328,
    "Teacher": 27
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1037",
    "Name": "SEKOLAH KEBANGSAAN WELLESLEY",
    "Student": 256,
    "Teacher": 28
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBB1039",
    "Name": "SEKOLAH KEBANGSAAN PADANG TEMBAK",
    "Student": 294,
    "Teacher": 33
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBB2029",
    "Name": "SEKOLAH KEBANGSAAN CONVENT 1",
    "Student": 444,
    "Teacher": 40
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBB2031",
    "Name": "SEKOLAH KEBANGSAAN HASHIM AWANG",
    "Student": 607,
    "Teacher": 77
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBB2032",
    "Name": "SEKOLAH KEBANGSAAN ST MARK",
    "Student": 192,
    "Teacher": 22
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBB2075",
    "Name": "SEKOLAH KEBANGSAAN MAK MANDIN",
    "Student": 260,
    "Teacher": 36
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBB2078",
    "Name": "SEKOLAH KEBANGSAAN ST MARK",
    "Student": 196,
    "Teacher": 21
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBB3014",
    "Name": "SEKOLAH KEBANGSAAN SERI PERMAI",
    "Student": 1382,
    "Teacher": 99
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBB4012",
    "Name": "SEKOLAH KEBANGSAAN METHODIST",
    "Student": 299,
    "Teacher": 36
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PERMATANG TINGGI",
    "Student": 762,
    "Teacher": 52
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PERKAMPUNGAN BERAPIT",
    "Student": 214,
    "Teacher": 25
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUBANG SEMANG",
    "Student": 65,
    "Teacher": 12
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0024",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KG. SG. LEMBU",
    "Student": 265,
    "Teacher": 28
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JIT SIN 'A'",
    "Student": 490,
    "Teacher": 36
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JIT SIN 'B'",
    "Student": 439,
    "Teacher": 35
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0028",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAY SIN",
    "Student": 476,
    "Teacher": 39
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEOW KUANG",
    "Student": 773,
    "Teacher": 45
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KIM SEN",
    "Student": 1281,
    "Teacher": 77
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LAY KEOW",
    "Student": 243,
    "Teacher": 25
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PENG BIN",
    "Student": 976,
    "Teacher": 62
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SENG KEOW",
    "Student": 614,
    "Teacher": 41
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN YA",
    "Student": 1460,
    "Teacher": 85
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TRUE LIGHT",
    "Student": 763,
    "Teacher": 51
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC0036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BENG TEIK (PUSAT)",
    "Student": 1120,
    "Teacher": 61
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SHANG WU",
    "Student": 1067,
    "Teacher": 62
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEREMPUAN CHINA",
    "Student": 1077,
    "Teacher": 67
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AIK HUA",
    "Student": 71,
    "Teacher": 17
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BENG TEIK CAWANGAN",
    "Student": 112,
    "Teacher": 15
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAO NAN",
    "Student": 484,
    "Teacher": 36
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JELUTONG",
    "Student": 659,
    "Teacher": 46
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA CONFUCIAN 'A'",
    "Student": 517,
    "Teacher": 36
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA CONFUCIAN 'B'",
    "Student": 375,
    "Teacher": 28
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CONVENT DATUK KERAMAT",
    "Student": 218,
    "Teacher": 22
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ENG CHUAN",
    "Student": 59,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1055",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HAN CHIANG",
    "Student": 912,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HENG EE",
    "Student": 158,
    "Teacher": 15
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HUN BIN",
    "Student": 810,
    "Teacher": 45
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEONG HOE",
    "Student": 1026,
    "Teacher": 65
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1059",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHENG TEAN",
    "Student": 584,
    "Teacher": 36
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1060",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN PUSAT",
    "Student": 241,
    "Teacher": 24
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1061",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN CAWANGAN SATU",
    "Student": 146,
    "Teacher": 15
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1062",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN CAWANGAN KEDUA",
    "Student": 586,
    "Teacher": 40
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG HWA",
    "Student": 1909,
    "Teacher": 94
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1064",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LI TEK 'A'",
    "Student": 168,
    "Teacher": 16
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LI TEK 'B'",
    "Student": 164,
    "Teacher": 15
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LI TEK CAWANGAN",
    "Student": 115,
    "Teacher": 15
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1067",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MIN SIN",
    "Student": 686,
    "Teacher": 36
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1068",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MOH GHEE (PUSAT)",
    "Student": 453,
    "Teacher": 32
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1069",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MOH GHEE CAWANGAN",
    "Student": 80,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1070",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAI CHAI",
    "Student": 106,
    "Teacher": 12
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1071",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHEI SHIN",
    "Student": 351,
    "Teacher": 24
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1072",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHOR TAY",
    "Student": 266,
    "Teacher": 25
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1073",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POAY WAH",
    "Student": 172,
    "Teacher": 17
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1074",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SHIH CHUNG PUSAT",
    "Student": 54,
    "Teacher": 17
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1075",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SHIH CHUNG CAWANGAN",
    "Student": 1107,
    "Teacher": 68
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1076",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN KANG",
    "Student": 423,
    "Teacher": 33
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1077",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUM MIN",
    "Student": 31,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1078",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUM SUN",
    "Student": 469,
    "Teacher": 32
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1079",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TONG SIAN",
    "Student": 107,
    "Teacher": 15
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1080",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) UNION",
    "Student": 842,
    "Teacher": 52
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBC1081",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HU YEW SEAH",
    "Student": 204,
    "Teacher": 23
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MAK MANDIN",
    "Student": 453,
    "Teacher": 33
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AIK KEOW",
    "Student": 46,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) AIK KEOW",
    "Student": 182,
    "Teacher": 17
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HWA",
    "Student": 95,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI YU",
    "Student": 451,
    "Teacher": 36
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA PUSAT",
    "Student": 693,
    "Teacher": 41
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 1",
    "Student": 767,
    "Teacher": 51
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2039",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 2",
    "Student": 654,
    "Teacher": 42
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2040",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAI CHEE",
    "Student": 637,
    "Teacher": 42
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUANG YU",
    "Student": 28,
    "Teacher": 8
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG HWA",
    "Student": 1202,
    "Teacher": 67
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LEE CHEE",
    "Student": 140,
    "Teacher": 16
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LI HWA",
    "Student": 1560,
    "Teacher": 81
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MAH HUA",
    "Student": 324,
    "Teacher": 28
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NUNG MIN",
    "Student": 72,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBC2047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN CHUNG",
    "Student": 86,
    "Teacher": 13
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBC2079",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 3",
    "Student": 522,
    "Teacher": 38
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HWA (PANTAI ACHEH)",
    "Student": 27,
    "Teacher": 7
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PULAU BETONG",
    "Student": 39,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG CHENG",
    "Student": 2088,
    "Teacher": 101
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG TEIK",
    "Student": 260,
    "Teacher": 25
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SHAN",
    "Student": 406,
    "Teacher": 29
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) EOK HUA",
    "Student": 62,
    "Teacher": 16
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POI ENG",
    "Student": 61,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3024",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SACRED HEART",
    "Student": 126,
    "Teacher": 16
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN",
    "Student": 35,
    "Teacher": 6
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAR THONG",
    "Student": 84,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WEN KHAI",
    "Student": 450,
    "Teacher": 32
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3028",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YANG CHENG",
    "Student": 151,
    "Teacher": 14
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YEOK HUA",
    "Student": 59,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBC3030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YU CHYE",
    "Student": 52,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBC4013",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BOON BENG",
    "Student": 1126,
    "Teacher": 64
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBC4015",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG VALDOR",
    "Student": 472,
    "Teacher": 36
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBC4016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG KUANG",
    "Student": 780,
    "Teacher": 62
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBC4017",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG JAWI",
    "Student": 180,
    "Teacher": 20
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBC4018",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KENG KOON",
    "Student": 1102,
    "Teacher": 72
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBC4019",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAI TEIK",
    "Student": 1370,
    "Teacher": 87
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBC4021",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOK ENG",
    "Student": 262,
    "Teacher": 25
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBD0034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PERMATANG TINGGI",
    "Student": 847,
    "Teacher": 58
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBD0035",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG JURU",
    "Student": 92,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBD0036",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BKT MERTAJAM",
    "Student": 328,
    "Teacher": 28
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBD0037",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG ALMA",
    "Student": 156,
    "Teacher": 17
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBD0038",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PRYE",
    "Student": 128,
    "Teacher": 17
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBD1082",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) AZAD",
    "Student": 90,
    "Teacher": 13
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBD1084",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) RAJAJI",
    "Student": 92,
    "Teacher": 10
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBD1085",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) RAMAKRISHNA",
    "Student": 279,
    "Teacher": 27
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBD1086",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) RAMATHASAR",
    "Student": 139,
    "Teacher": 19
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBD1088",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUBRAMANIYA BARATHEE",
    "Student": 336,
    "Teacher": 28
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBD2048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MALAKOFF",
    "Student": 86,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBD2049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MAYFIELD",
    "Student": 85,
    "Teacher": 15
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBD2050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PALANIANDY",
    "Student": 125,
    "Teacher": 16
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PBD2076",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) MAK MANDIN",
    "Student": 720,
    "Teacher": 56
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PBD2080",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) PERAI",
    "Student": 249,
    "Teacher": 25
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBD3031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) BAYAN LEPAS",
    "Student": 97,
    "Teacher": 16
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PBD3032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI ARA",
    "Student": 106,
    "Teacher": 13
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBD4022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATU KAWAN",
    "Student": 98,
    "Teacher": 12
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBD4023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BYRAM",
    "Student": 14,
    "Teacher": 8
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBD4024",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) NIBONG TEBAL",
    "Student": 317,
    "Teacher": 29
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBD4025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG CHANGKAT",
    "Student": 24,
    "Teacher": 10
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBD4026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JAWI",
    "Student": 149,
    "Teacher": 24
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBD4028",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KRIAN",
    "Student": 221,
    "Teacher": 23
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBD4029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SEMPAH",
    "Student": 94,
    "Teacher": 10
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBD4030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) TASEK PERMAI",
    "Student": 150,
    "Teacher": 18
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBD4031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG TRANSKRIAN",
    "Student": 66,
    "Teacher": 10
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBD4032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG VALDOR",
    "Student": 182,
    "Teacher": 16
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PBD4034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI BAKAP",
    "Student": 112,
    "Teacher": 15
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PBE1040",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS PERSEKUTUAN",
    "Student": 20,
    "Teacher": 21
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PCT0002",
    "Name": "SEKOLAH RENDAH ISLAM AL MASRIYAH",
    "Student": 343,
    "Teacher": 34
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PRAI",
    "Student": 267,
    "Teacher": 31
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BERAPIT",
    "Student": 1309,
    "Teacher": 107
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUAR PERAHU",
    "Student": 679,
    "Teacher": 66
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEBERANG JAYA",
    "Student": 948,
    "Teacher": 96
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERMATANG RAWA",
    "Student": 678,
    "Teacher": 53
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SAMA GAGAH",
    "Student": 423,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN INDERAWASIH",
    "Student": 666,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALMA",
    "Student": 919,
    "Teacher": 87
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0009",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MENGKUANG",
    "Student": 914,
    "Teacher": 77
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0010",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERMAI INDAH",
    "Student": 947,
    "Teacher": 71
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SEJAHTERA",
    "Student": 536,
    "Teacher": 57
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0012",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU PERDA",
    "Student": 748,
    "Teacher": 63
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0013",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAUH JAYA",
    "Student": 791,
    "Teacher": 54
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0014",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN HUSSEIN ONN",
    "Student": 1150,
    "Teacher": 89
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0015",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN PERWIRA",
    "Student": 1616,
    "Teacher": 110
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0016",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERMATANG PASIR",
    "Student": 693,
    "Teacher": 54
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MACHANG BUBUK",
    "Student": 1149,
    "Teacher": 98
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEA0018",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUAR PERAHU INDAH",
    "Student": 578,
    "Teacher": 49
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEA1090",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ABDULLAH MUNSHI",
    "Student": 701,
    "Teacher": 73
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEA1091",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) SRI MUTIARA",
    "Student": 371,
    "Teacher": 38
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEA1092",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HUTCHINGS",
    "Student": 62,
    "Teacher": 27
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEA1093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT GAMBIR",
    "Student": 829,
    "Teacher": 62
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEA1094",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AIR ITAM",
    "Student": 839,
    "Teacher": 62
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEA1210",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HAMID KHAN",
    "Student": 522,
    "Teacher": 46
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PEA1211",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELOK KUMBAR",
    "Student": 802,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEA1212",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT JAMBUL",
    "Student": 764,
    "Teacher": 59
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEA1213",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI NIBONG",
    "Student": 721,
    "Teacher": 69
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2051",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERMATANG TOK JAYA",
    "Student": 1118,
    "Teacher": 96
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK HAJI AHMAD SAID",
    "Student": 881,
    "Teacher": 74
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK ONN",
    "Student": 728,
    "Teacher": 66
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK HAJI AHMAD BADAWI",
    "Student": 843,
    "Teacher": 96
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI MUDA",
    "Student": 1029,
    "Teacher": 76
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG LING BUTTERWORTH",
    "Student": 1953,
    "Teacher": 141
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELOK AIR TAWAR",
    "Student": 590,
    "Teacher": 86
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' KAILAN",
    "Student": 896,
    "Teacher": 69
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2082",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAK MANDIN",
    "Student": 668,
    "Teacher": 73
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2083",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAGAN JAYA",
    "Student": 1856,
    "Teacher": 110
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2084",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN POKOK SENA",
    "Student": 854,
    "Teacher": 77
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2085",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPONG SELAMAT",
    "Student": 930,
    "Teacher": 64
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2086",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BERTAM INDAH",
    "Student": 772,
    "Teacher": 57
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2087",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEPALA BATAS",
    "Student": 707,
    "Teacher": 73
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2088",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' HAJI HASSAN NOH",
    "Student": 808,
    "Teacher": 71
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2089",
    "Name": "KOLEJ TINGKATAN ENAM DESA MURNI",
    "Student": 172,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2090",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GEMILANG",
    "Student": 524,
    "Teacher": 49
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2091",
    "Name": "SEKOLAH MENENGAH SAINS KEPALA BATAS",
    "Student": 742,
    "Teacher": 69
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2092",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BERTAM PERDANA",
    "Student": 966,
    "Teacher": 62
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEA2093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TASEK GELUGOR",
    "Student": 399,
    "Teacher": 30
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PEA3033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA TUN UDA",
    "Student": 1470,
    "Teacher": 113
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PEA3034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BALIK PULAU",
    "Student": 1158,
    "Teacher": 104
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PEA3035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU MAUNG",
    "Student": 565,
    "Teacher": 44
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PEA3036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELUK BAHANG",
    "Student": 391,
    "Teacher": 35
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PEA3037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BAYU",
    "Student": 833,
    "Teacher": 71
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PEA3038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PONDOK UPEH",
    "Student": 954,
    "Teacher": 64
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PEA3039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAYAN LEPAS",
    "Student": 841,
    "Teacher": 50
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEA4001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ACHEH",
    "Student": 852,
    "Teacher": 77
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEA4002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN VALDOR",
    "Student": 1139,
    "Teacher": 88
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEA4003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI NIBONG",
    "Student": 673,
    "Teacher": 63
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEA4004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUTIARA IMPIAN",
    "Student": 468,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEA4005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JAWI",
    "Student": 635,
    "Teacher": 62
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEA4006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN WIDURI",
    "Student": 519,
    "Teacher": 43
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEA4007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SAUJANA INDAH",
    "Student": 741,
    "Teacher": 61
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEA4008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU KAWAN",
    "Student": 587,
    "Teacher": 48
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEA4009",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR TASEK MUTIARA",
    "Student": 1044,
    "Teacher": 73
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEA4010",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TASEK",
    "Student": 698,
    "Teacher": 70
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEA4011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JIT SIN II",
    "Student": 1136,
    "Teacher": 68
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEB0042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI BUKIT MERTAJAM",
    "Student": 1237,
    "Teacher": 130
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEB0043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN DAMAI",
    "Student": 2220,
    "Teacher": 130
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEB0044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT (M) BUKIT MERTAJAM",
    "Student": 689,
    "Teacher": 57
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEB0045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JIT SIN",
    "Student": 2366,
    "Teacher": 172
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN CHINA PULAU PINANG",
    "Student": 1308,
    "Teacher": 132
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1094",
    "Name": "PENANG FREE SCHOOL",
    "Student": 914,
    "Teacher": 85
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1095",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA CONFUCIAN",
    "Student": 1818,
    "Teacher": 127
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1096",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG LING",
    "Student": 2087,
    "Teacher": 153
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1097",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT GREEN LANE",
    "Student": 908,
    "Teacher": 57
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1098",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT DATUK KERAMAT",
    "Student": 899,
    "Teacher": 72
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1099",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT LEBUH LIGHT",
    "Student": 47,
    "Teacher": 10
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT PULAU TIKUS",
    "Student": 39,
    "Teacher": 11
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GEORGETOWN",
    "Student": 350,
    "Teacher": 53
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HENG EE",
    "Student": 3326,
    "Teacher": 212
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1106",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (L) METHODIST",
    "Student": 836,
    "Teacher": 95
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1107",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) METHODIST",
    "Student": 578,
    "Teacher": 39
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1108",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PHOR TAY",
    "Student": 2161,
    "Teacher": 140
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1109",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) ST GEORGE",
    "Student": 746,
    "Teacher": 58
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1110",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST XAVIER",
    "Student": 990,
    "Teacher": 70
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1112",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU PUAN HABSAH",
    "Student": 250,
    "Teacher": 28
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1113",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN UNION",
    "Student": 805,
    "Teacher": 63
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEB1114",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN WESTLANDS",
    "Student": 214,
    "Teacher": 27
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEB2059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT BUTTERWORTH",
    "Student": 592,
    "Teacher": 57
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEB2060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPONG KASTAM",
    "Student": 871,
    "Teacher": 58
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEB2061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK HJ ABDUL KADIR",
    "Student": 1143,
    "Teacher": 82
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEB2062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST MARK",
    "Student": 1152,
    "Teacher": 71
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PEB3036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SACRED HEART",
    "Student": 689,
    "Teacher": 59
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PEB3038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ARA",
    "Student": 1138,
    "Teacher": 102
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEB4038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST",
    "Student": 429,
    "Teacher": 34
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEE0040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT MERTAJAM",
    "Student": 987,
    "Teacher": 92
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEE0041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENANTI",
    "Student": 977,
    "Teacher": 114
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PEE0051",
    "Name": "SEKOLAH MENENGAH SAINS TUN SYED SHEH SHAHABUDDIN",
    "Student": 689,
    "Teacher": 62
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEE1092",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANJONG BUNGA",
    "Student": 294,
    "Teacher": 30
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEE1101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK HJ. MOHAMED NOR AHMAD",
    "Student": 362,
    "Teacher": 46
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEE1104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JELUTONG",
    "Student": 599,
    "Teacher": 76
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEE1111",
    "Name": "KOLEJ TINGKATAN ENAM HAJI ZAINUL ABIDIN",
    "Student": 181,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEE1115",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG POLO",
    "Student": 280,
    "Teacher": 42
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PEE1118",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENDIDIKAN KHAS PERSEKUTUAN",
    "Student": 107,
    "Teacher": 48
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PEE2056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAKTI",
    "Student": 1062,
    "Teacher": 84
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEE4035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIMPANG EMPAT",
    "Student": 1183,
    "Teacher": 111
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEE4036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN SYED SHEH BARAKBAH",
    "Student": 830,
    "Teacher": 77
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PEE4037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABDUL RAHMAN",
    "Student": 1212,
    "Teacher": 110
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PFT0001",
    "Name": "SMA DAERATUL MAARIFIL WATANIAH 2",
    "Student": 191,
    "Teacher": 30
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PFT0002",
    "Name": "SEKOLAH MENENGAH AGAMA AL-AHMADIAH AL-IJTIMAIAH",
    "Student": 169,
    "Teacher": 28
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PFT0003",
    "Name": "SEKOLAH MENENGAH ISLAM AL-MASRIYAH",
    "Student": 369,
    "Teacher": 41
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PFT0004",
    "Name": "SMA FAQIAH DAIMIAH",
    "Student": 176,
    "Teacher": 32
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PFT0005",
    "Name": "SEKOLAH MENENGAH AGAMA AL MAAHADUL ISLAMI TASEK JUNJUNG",
    "Student": 232,
    "Teacher": 26
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PFT1001",
    "Name": "SMA TARBIAH ISLAMIAH",
    "Student": 151,
    "Teacher": 23
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PFT2001",
    "Name": "SMA DAERATUL MA'ARIFIL WATANIAH SATU",
    "Student": 662,
    "Teacher": 60
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PFT2002",
    "Name": "MAKTAB WAN JAH",
    "Student": 200,
    "Teacher": 29
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PFT2003",
    "Name": "MAAHAD AL-IMAM AN-NAWAWI",
    "Student": 270,
    "Teacher": 27
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PFT2004",
    "Name": "MADRASAH KHAIRIAH ISLAMIAH",
    "Student": 476,
    "Teacher": 42
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PFT3001",
    "Name": "SMA MAAHAD AL MASHOOR AL ISLAMI",
    "Student": 570,
    "Teacher": 51
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI TENGAH",
    "Kod": "PHA0001",
    "Name": "KOLEJ VOKASIONAL SEBERANG PERAI",
    "Student": 501,
    "Teacher": 77
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PHA1001",
    "Name": "KOLEJ VOKASIONAL BATU LANCHANG",
    "Student": 445,
    "Teacher": 85
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PHA2001",
    "Name": "KOLEJ VOKASIONAL BUTTERWORTH",
    "Student": 715,
    "Teacher": 101
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD BARAT DAYA",
    "Kod": "PHA3001",
    "Name": "KOLEJ VOKASIONAL BALIK PULAU",
    "Student": 664,
    "Teacher": 121
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PHA4002",
    "Name": "KOLEJ VOKASIONAL NIBONG TEBAL",
    "Student": 776,
    "Teacher": 123
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PKB1116",
    "Name": "SMT TUNKU ABD RAHMAN",
    "Student": 556,
    "Teacher": 52
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PRA1001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA (L) AL-MASHOOR",
    "Student": 590,
    "Teacher": 60
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD TIMUR LAUT",
    "Kod": "PRA1002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA (P) ALMASHOOR",
    "Student": 562,
    "Teacher": 55
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PERAI UTARA",
    "Kod": "PRA2003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA AL-IRSHAD",
    "Student": 836,
    "Teacher": 70
  },
  {
    "NationalState": "PULAU PINANG",
    "PPD": "PPD SEBERANG PRAI SELATAN",
    "Kod": "PRA4001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SYEIKH ABDULLAH FAHIM",
    "Student": 680,
    "Teacher": 114
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0001",
    "Name": "SEKOLAH KEBANGSAAN ABI",
    "Student": 273,
    "Teacher": 29
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0002",
    "Name": "SEKOLAH KEBANGSAAN TENGKU BUDRIAH",
    "Student": 904,
    "Teacher": 84
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0003",
    "Name": "SEKOLAH KEBANGSAAN BESERI",
    "Student": 398,
    "Teacher": 40
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0004",
    "Name": "SEKOLAH KEBANGSAAN BINTONG",
    "Student": 290,
    "Teacher": 29
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0005",
    "Name": "SEKOLAH KEBANGSAAN BOHOR MALI",
    "Student": 159,
    "Teacher": 18
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0006",
    "Name": "SEKOLAH KEBANGSAAN RAJA PEREMPUAN BUDRIAH",
    "Student": 134,
    "Teacher": 16
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0007",
    "Name": "SEKOLAH KEBANGSAAN CHUPING",
    "Student": 232,
    "Teacher": 32
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0009",
    "Name": "SEKOLAH KEBANGSAAN DATO KAYAMAN",
    "Student": 236,
    "Teacher": 29
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0010",
    "Name": "SEKOLAH KEBANGSAAN GUAR NANGKA",
    "Student": 550,
    "Teacher": 43
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0011",
    "Name": "SEKOLAH KEBANGSAAN JEJAWI",
    "Student": 572,
    "Teacher": 53
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0012",
    "Name": "SEKOLAH KEBANGSAAN JELEMPOK",
    "Student": 211,
    "Teacher": 24
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0013",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG SALANG",
    "Student": 249,
    "Teacher": 30
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0014",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG SERDANG",
    "Student": 408,
    "Teacher": 34
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0015",
    "Name": "SEKOLAH KEBANGSAAN DATO WAN AHMAD",
    "Student": 337,
    "Teacher": 42
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0016",
    "Name": "SEKOLAH KEBANGSAAN SERI PERLIS",
    "Student": 360,
    "Teacher": 36
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0017",
    "Name": "SEKOLAH KEBANGSAAN KAYANG",
    "Student": 687,
    "Teacher": 57
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0018",
    "Name": "SEKOLAH KEBANGSAAN KUALA PERLIS",
    "Student": 637,
    "Teacher": 72
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0019",
    "Name": "SEKOLAH KEBANGSAAN PADANG KOTA",
    "Student": 238,
    "Teacher": 33
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0020",
    "Name": "SEKOLAH KEBANGSAAN ORAN",
    "Student": 448,
    "Teacher": 41
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0021",
    "Name": "SEKOLAH KEBANGSAAN PADANG BESAR UTARA",
    "Student": 240,
    "Teacher": 37
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0022",
    "Name": "SEKOLAH KEBANGSAAN PADANG BESAR SELATAN",
    "Student": 302,
    "Teacher": 28
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0023",
    "Name": "SEKOLAH KEBANGSAAN PADANG KERIA",
    "Student": 167,
    "Teacher": 19
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0024",
    "Name": "SEKOLAH KEBANGSAAN PADANG MELANGIT",
    "Student": 175,
    "Teacher": 18
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0025",
    "Name": "SEKOLAH KEBANGSAAN PANGGAS",
    "Student": 115,
    "Teacher": 18
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0026",
    "Name": "SEKOLAH KEBANGSAAN PAUH",
    "Student": 809,
    "Teacher": 70
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0027",
    "Name": "SEKOLAH KEBANGSAAN PAYA",
    "Student": 304,
    "Teacher": 29
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0028",
    "Name": "SEKOLAH KEBANGSAAN SANGLANG",
    "Student": 291,
    "Teacher": 37
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0029",
    "Name": "SEKOLAH KEBANGSAAN SANTAN",
    "Student": 383,
    "Teacher": 37
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0030",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG AMPAT",
    "Student": 560,
    "Teacher": 60
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0031",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BARU",
    "Student": 215,
    "Teacher": 24
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0032",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BEREMBANG",
    "Student": 220,
    "Teacher": 29
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0033",
    "Name": "SEKOLAH KEBANGSAAN DATO AHMAD MUSA",
    "Student": 172,
    "Teacher": 18
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0034",
    "Name": "SEKOLAH KEBANGSAAN TAMBUN TULANG",
    "Student": 422,
    "Teacher": 39
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0035",
    "Name": "SEKOLAH KEBANGSAAN TITI TINGGI",
    "Student": 344,
    "Teacher": 30
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0036",
    "Name": "SEKOLAH KEBANGSAAN TITI TO' BANDAR",
    "Student": 156,
    "Teacher": 18
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0037",
    "Name": "SEKOLAH KEBANGSAAN SERI TUNJONG",
    "Student": 538,
    "Teacher": 55
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0038",
    "Name": "SEKOLAH KEBANGSAAN UJONG BATU",
    "Student": 149,
    "Teacher": 18
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0039",
    "Name": "SEKOLAH KEBANGSAAN UTAN AJI",
    "Student": 308,
    "Teacher": 36
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0040",
    "Name": "SEKOLAH KEBANGSAAN CHANGKAT JAWI",
    "Student": 182,
    "Teacher": 19
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0075",
    "Name": "SEKOLAH KEBANGSAAN BATU BERTANGKUP",
    "Student": 501,
    "Teacher": 44
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0076",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) CHUPING",
    "Student": 337,
    "Teacher": 30
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0077",
    "Name": "SEKOLAH KEBANGSAAN LONG BOH",
    "Student": 159,
    "Teacher": 19
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0078",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) MATA AIR",
    "Student": 188,
    "Teacher": 22
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0079",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS PERLIS",
    "Student": 86,
    "Teacher": 40
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0080",
    "Name": "SEKOLAH KEBANGSAAN LUBUK SIREH",
    "Student": 245,
    "Teacher": 29
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0081",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) RIMBA MAS",
    "Student": 233,
    "Teacher": 25
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0082",
    "Name": "SEKOLAH KEBANGSAAN ARAU",
    "Student": 419,
    "Teacher": 43
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0083",
    "Name": "SEKOLAH KEBANGSAAN JALAN RAJA SYED ALWI",
    "Student": 531,
    "Teacher": 43
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0084",
    "Name": "SEKOLAH KEBANGSAAN SENA",
    "Student": 811,
    "Teacher": 58
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0085",
    "Name": "SEKOLAH KEBANGSAAN KUBANG GAJAH",
    "Student": 320,
    "Teacher": 30
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0086",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG RAMAI",
    "Student": 465,
    "Teacher": 41
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0087",
    "Name": "SEKOLAH KEBANGSAAN GUAR JENTIK",
    "Student": 173,
    "Teacher": 19
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0088",
    "Name": "SEKOLAH KEBANGSAAN BEHOR EMPIANG",
    "Student": 361,
    "Teacher": 31
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0089",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KETERI",
    "Student": 320,
    "Teacher": 37
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0090",
    "Name": "SEKOLAH KEBANGSAAN PANGGAU",
    "Student": 166,
    "Teacher": 17
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBA0091",
    "Name": "SEKOLAH KEBANGSAAN INSTITUT PENDIDIKAN GURU MALAYSIA",
    "Student": 315,
    "Teacher": 29
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBB0040",
    "Name": "SEKOLAH KEBANGSAAN SERI INDERA",
    "Student": 780,
    "Teacher": 55
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBB0041",
    "Name": "SEKOLAH KEBANGSAAN PUTRA",
    "Student": 464,
    "Teacher": 51
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBB0042",
    "Name": "SEKOLAH KEBANGSAAN STELLA MARIS",
    "Student": 451,
    "Teacher": 39
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBC0043",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HUN",
    "Student": 48,
    "Teacher": 11
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBC0044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHOON SIEW",
    "Student": 182,
    "Teacher": 26
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBC0045",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA AIK",
    "Student": 205,
    "Teacher": 26
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBC0046",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAY BENG",
    "Student": 53,
    "Teacher": 12
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBC0047",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHOON AIK",
    "Student": 623,
    "Teacher": 47
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBC0048",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PADANG BESAR UTARA",
    "Student": 156,
    "Teacher": 17
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBC0049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KONG AIK",
    "Student": 166,
    "Teacher": 14
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBC0050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KONG HWA",
    "Student": 111,
    "Teacher": 16
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBC0051",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG EMPAT",
    "Student": 87,
    "Teacher": 13
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBC0052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN",
    "Student": 179,
    "Teacher": 15
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RBD0053",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KANGAR",
    "Student": 66,
    "Teacher": 12
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RCT0001",
    "Name": "SEKOLAH AGAMA AL-ISLAHIYAH (RENDAH)",
    "Student": 432,
    "Teacher": 32
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RCT0002",
    "Name": "SEKOLAH RENDAH ISLAM III",
    "Student": 224,
    "Teacher": 18
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RCT0003",
    "Name": "SEKOLAH RENDAH ISLAM II",
    "Student": 214,
    "Teacher": 19
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RCT0004",
    "Name": "SEKOLAH RENDAH ISLAM 1",
    "Student": 807,
    "Teacher": 51
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' SHEIKH AHMAD",
    "Student": 597,
    "Teacher": 65
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SYED AHMAD",
    "Student": 741,
    "Teacher": 73
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SYED HASSAN",
    "Student": 529,
    "Teacher": 78
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA PUAN MUDA TENGKU FAUZIAH",
    "Student": 642,
    "Teacher": 66
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0076",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA PERLIS",
    "Student": 1011,
    "Teacher": 95
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0077",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK JAAFAR HASSAN",
    "Student": 810,
    "Teacher": 77
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0078",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ARAU",
    "Student": 774,
    "Teacher": 82
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SANGLANG",
    "Student": 406,
    "Teacher": 54
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0080",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ABI",
    "Student": 761,
    "Teacher": 66
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0081",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG BESAR UTARA",
    "Student": 370,
    "Teacher": 56
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0082",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU BUDRIAH",
    "Student": 639,
    "Teacher": 53
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0083",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' ALI AHMAD",
    "Student": 404,
    "Teacher": 47
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0084",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUANKU LAILATUL SHAHREEN",
    "Student": 488,
    "Teacher": 43
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0085",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BESERI",
    "Student": 261,
    "Teacher": 39
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0086",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MATA AYER",
    "Student": 361,
    "Teacher": 35
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REA0087",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUAR NANGKA",
    "Student": 351,
    "Teacher": 38
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REB0057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRA",
    "Student": 925,
    "Teacher": 87
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REB0058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERLIS",
    "Student": 1085,
    "Teacher": 106
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REE0059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DERMA",
    "Student": 697,
    "Teacher": 75
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REE0060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SYED ALWI",
    "Student": 1253,
    "Teacher": 139
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REE0061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SYED SAFFI",
    "Student": 762,
    "Teacher": 72
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REE0062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SYED SIRAJUDDIN",
    "Student": 876,
    "Teacher": 114
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REE0063",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU SULAIMAN",
    "Student": 1142,
    "Teacher": 136
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "REE0072",
    "Name": "SEKOLAH MENENGAH SAINS TUANKU SYED PUTRA",
    "Student": 526,
    "Teacher": 57
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RFT0001",
    "Name": "SEKOLAH AGAMA AL-ISLAHIYAH (MENENGAH)",
    "Student": 470,
    "Teacher": 49
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RFT0002",
    "Name": "SMA AL MADRASAH AL ALAWIYAH AD DINIAH",
    "Student": 568,
    "Teacher": 48
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RHA0002",
    "Name": "KOLEJ VOKASIONAL KANGAR",
    "Student": 834,
    "Teacher": 165
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RHA0003",
    "Name": "KOLEJ VOKASIONAL ARAU",
    "Student": 739,
    "Teacher": 117
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RRA0001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA ARAU",
    "Student": 476,
    "Teacher": 89
  },
  {
    "NationalState": "PERLIS",
    "PPD": "JPN PERLIS",
    "Kod": "RRA0002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA (P) ALAWIYAH",
    "Student": 669,
    "Teacher": 61
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0001",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG RAJA",
    "Student": 991,
    "Teacher": 70
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0002",
    "Name": "SEKOLAH KEBANGSAAN AMER",
    "Student": 300,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0003",
    "Name": "SEKOLAH KEBANGSAAN ALOR LINTAH",
    "Student": 286,
    "Teacher": 36
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0004",
    "Name": "SEKOLAH KEBANGSAAN KERANDANG",
    "Student": 390,
    "Teacher": 34
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0005",
    "Name": "SEKOLAH KEBANGSAAN KUALA BESUT",
    "Student": 615,
    "Teacher": 57
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0006",
    "Name": "SEKOLAH KEBANGSAAN TEMBILA",
    "Student": 466,
    "Teacher": 35
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0007",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG NANGKA",
    "Student": 282,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0008",
    "Name": "SEKOLAH KEBANGSAAN PULAU PERHENTIAN",
    "Student": 197,
    "Teacher": 21
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0012",
    "Name": "SEKOLAH KEBANGSAAN BETING LINTANG",
    "Student": 248,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0013",
    "Name": "SEKOLAH KEBANGSAAN GONG BAYOR",
    "Student": 401,
    "Teacher": 39
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0014",
    "Name": "SEKOLAH KEBANGSAAN KELUANG",
    "Student": 386,
    "Teacher": 34
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0015",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TEMPURONG",
    "Student": 243,
    "Teacher": 27
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0017",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BAHARU",
    "Student": 711,
    "Teacher": 57
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0018",
    "Name": "SEKOLAH KEBANGSAAN ALOR PEROI",
    "Student": 613,
    "Teacher": 50
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0019",
    "Name": "SEKOLAH KEBANGSAAN TOK RAJA",
    "Student": 113,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0022",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN NYIREH",
    "Student": 535,
    "Teacher": 44
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0023",
    "Name": "SEKOLAH KEBANGSAAN PUSAT JERTEH",
    "Student": 750,
    "Teacher": 53
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0024",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KENAK",
    "Student": 462,
    "Teacher": 46
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0025",
    "Name": "SEKOLAH KEBANGSAAN TANAH MERAH",
    "Student": 334,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0026",
    "Name": "SEKOLAH KEBANGSAAN APAL",
    "Student": 250,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0027",
    "Name": "SEKOLAH KEBANGSAAN JABI",
    "Student": 489,
    "Teacher": 44
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0028",
    "Name": "SEKOLAH KEBANGSAAN RENEK",
    "Student": 297,
    "Teacher": 28
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0029",
    "Name": "SEKOLAH KEBANGSAAN OH",
    "Student": 135,
    "Teacher": 20
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0030",
    "Name": "SEKOLAH KEBANGSAAN TEMPINIS",
    "Student": 328,
    "Teacher": 37
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0031",
    "Name": "SEKOLAH KEBANGSAAN BUKIT JEROK",
    "Student": 202,
    "Teacher": 22
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0032",
    "Name": "SEKOLAH KEBANGSAAN TOK DOR",
    "Student": 158,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0033",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BARU TOK DOR",
    "Student": 108,
    "Teacher": 16
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0043",
    "Name": "SEKOLAH KEBANGSAAN PADANG LUAS",
    "Student": 545,
    "Teacher": 49
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0044",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PUTERI",
    "Student": 399,
    "Teacher": 36
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0045",
    "Name": "SEKOLAH KEBANGSAAN AYER TERJUN",
    "Student": 308,
    "Teacher": 31
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0046",
    "Name": "SEKOLAH KEBANGSAAN TOK MOTONG",
    "Student": 255,
    "Teacher": 26
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0048",
    "Name": "SEKOLAH KEBANGSAAN DARAU",
    "Student": 303,
    "Teacher": 28
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0049",
    "Name": "SEKOLAH KEBANGSAAN PASIR AKAR",
    "Student": 559,
    "Teacher": 45
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0050",
    "Name": "SEKOLAH KEBANGSAAN SERI PAYONG",
    "Student": 355,
    "Teacher": 37
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0051",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) TENANG",
    "Student": 167,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0052",
    "Name": "SEKOLAH KEBANGSAAN PANCHOR",
    "Student": 115,
    "Teacher": 19
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0053",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG LA",
    "Student": 125,
    "Teacher": 16
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0054",
    "Name": "SEKOLAH KEBANGSAAN KERUAK",
    "Student": 316,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0055",
    "Name": "SEKOLAH KEBANGSAAN KUALA KUBANG",
    "Student": 123,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0067",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) SELASIH",
    "Student": 113,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0068",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS BESUT",
    "Student": 29,
    "Teacher": 26
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0069",
    "Name": "SEKOLAH KEBANGSAAN KUALA BESUT 2",
    "Student": 420,
    "Teacher": 35
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0070",
    "Name": "SEKOLAH KEBANGSAAN TENGKU MAHMUD 2",
    "Student": 660,
    "Teacher": 53
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0072",
    "Name": "SEKOLAH KEBANGSAAN LUBUK KAWAH",
    "Student": 491,
    "Teacher": 43
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0073",
    "Name": "SEKOLAH KEBANGSAAN ALOR KELADI",
    "Student": 269,
    "Teacher": 34
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0074",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG NAIL",
    "Student": 352,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0075",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG JERTEH",
    "Student": 207,
    "Teacher": 27
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0076",
    "Name": "SEKOLAH KEBANGSAAN PERMAISURI NUR ZAHIRAH",
    "Student": 550,
    "Teacher": 52
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0077",
    "Name": "SEKOLAH KEBANGSAAN PELAGAT",
    "Student": 338,
    "Teacher": 32
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0078",
    "Name": "SEKOLAH KEBANGSAAN ANAK IKAN",
    "Student": 269,
    "Teacher": 32
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0079",
    "Name": "SEKOLAH KEBANGSAAN PADANG LANDAK",
    "Student": 181,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBA0080",
    "Name": "SEKOLAH KEBANGSAAN NYIUR TUJUH",
    "Student": 307,
    "Teacher": 35
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1001",
    "Name": "SEKOLAH KEBANGSAAN KUALA DUNGUN",
    "Student": 619,
    "Teacher": 53
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1002",
    "Name": "SEKOLAH KEBANGSAAN DURIAN MAS 1",
    "Student": 311,
    "Teacher": 33
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1003",
    "Name": "SEKOLAH KEBANGSAAN PUSAT DUNGUN",
    "Student": 588,
    "Teacher": 47
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1004",
    "Name": "SEKOLAH KEBANGSAAN BANDAR DUNGUN",
    "Student": 420,
    "Teacher": 32
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1005",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BESI",
    "Student": 425,
    "Teacher": 37
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1006",
    "Name": "SEKOLAH KEBANGSAAN TEBING TEMBAH",
    "Student": 399,
    "Teacher": 32
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1007",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG NYIOR",
    "Student": 698,
    "Teacher": 55
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1008",
    "Name": "SEKOLAH KEBANGSAAN PAKA",
    "Student": 1047,
    "Teacher": 71
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1009",
    "Name": "SEKOLAH KEBANGSAAN SURA",
    "Student": 1414,
    "Teacher": 99
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1010",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG DUNGUN",
    "Student": 381,
    "Teacher": 36
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1011",
    "Name": "SEKOLAH KEBANGSAAN KUALA ABANG",
    "Student": 201,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1012",
    "Name": "SEKOLAH KEBANGSAAN PULAU SERAI",
    "Student": 859,
    "Teacher": 64
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1013",
    "Name": "SEKOLAH KEBANGSAAN DELONG",
    "Student": 76,
    "Teacher": 13
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1014",
    "Name": "SEKOLAH KEBANGSAAN PADANG PULUT",
    "Student": 397,
    "Teacher": 35
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1015",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG WA",
    "Student": 100,
    "Teacher": 13
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1016",
    "Name": "SEKOLAH KEBANGSAAN JERANGAU",
    "Student": 124,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1017",
    "Name": "SEKOLAH KEBANGSAAN DENDANG",
    "Student": 233,
    "Teacher": 22
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1018",
    "Name": "SEKOLAH KEBANGSAAN TEPUS",
    "Student": 42,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1019",
    "Name": "SEKOLAH KEBANGSAAN MINDA TALONG",
    "Student": 69,
    "Teacher": 13
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1020",
    "Name": "SEKOLAH KEBANGSAAN KUALA JENGAL",
    "Student": 124,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1021",
    "Name": "SEKOLAH KEBANGSAAN JONGOK BATU",
    "Student": 61,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1022",
    "Name": "SEKOLAH KEBANGSAAN PASIR RAJA",
    "Student": 58,
    "Teacher": 15
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1023",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SHUKOR",
    "Student": 64,
    "Teacher": 12
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1024",
    "Name": "SEKOLAH KEBANGSAAN SANTONG",
    "Student": 100,
    "Teacher": 14
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1025",
    "Name": "SEKOLAH KEBANGSAAN BATU 48",
    "Student": 778,
    "Teacher": 54
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1026",
    "Name": "SEKOLAH KEBANGSAAN TOK KAH",
    "Student": 142,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1027",
    "Name": "SEKOLAH KEBANGSAAN DURIAN MENTANGAU",
    "Student": 145,
    "Teacher": 14
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1028",
    "Name": "SEKOLAH KEBANGSAAN LINTANG",
    "Student": 76,
    "Teacher": 13
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1029",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) KERTEH 1",
    "Student": 447,
    "Teacher": 40
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1030",
    "Name": "SEKOLAH KEBANGSAAN LADANG GAJAH MATI",
    "Student": 36,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1031",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BARU KUALA ABANG",
    "Student": 424,
    "Teacher": 33
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1032",
    "Name": "SEKOLAH KEBANGSAAN SERDANG",
    "Student": 123,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1033",
    "Name": "SEKOLAH KEBANGSAAN DURIAN MAS 2",
    "Student": 361,
    "Teacher": 37
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1034",
    "Name": "SEKOLAH KEBANGSAAN SERI PAKA",
    "Student": 972,
    "Teacher": 65
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1035",
    "Name": "SEKOLAH KEBANGSAAN BALAI BESAR",
    "Student": 738,
    "Teacher": 51
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1037",
    "Name": "SEKOLAH KEBANGSAAN FELDA SERI RASAU",
    "Student": 544,
    "Teacher": 45
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1038",
    "Name": "SEKOLAH KEBANGSAAN LKTP JERANGAU",
    "Student": 313,
    "Teacher": 31
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1039",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG PATI",
    "Student": 292,
    "Teacher": 33
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1040",
    "Name": "SEKOLAH KEBANGSAAN PAKA II",
    "Student": 696,
    "Teacher": 64
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1041",
    "Name": "SEKOLAH KEBANGSAAN BATU 7",
    "Student": 728,
    "Teacher": 50
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1042",
    "Name": "SEKOLAH KEBANGSAAN KETENGAH JAYA II",
    "Student": 255,
    "Teacher": 37
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1043",
    "Name": "SEKOLAH KEBANGSAAN KOMPLEKS RANTAU ABANG",
    "Student": 163,
    "Teacher": 22
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1044",
    "Name": "SEKOLAH KEBANGSAAN PAKA III",
    "Student": 562,
    "Teacher": 44
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBA1045",
    "Name": "SEKOLAH KEBANGSAAN GONG PASIR",
    "Student": 592,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2001",
    "Name": "SEKOLAH KEBANGSAAN CHUKAI",
    "Student": 981,
    "Teacher": 80
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2002",
    "Name": "SEKOLAH KEBANGSAAN PUSAT",
    "Student": 919,
    "Teacher": 67
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2003",
    "Name": "SEKOLAH KEBANGSAAN SERI GELIGA",
    "Student": 1518,
    "Teacher": 103
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2004",
    "Name": "SEKOLAH KEBANGSAAN BUKIT MENTOK",
    "Student": 598,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2005",
    "Name": "SEKOLAH KEBANGSAAN BINJAI",
    "Student": 1078,
    "Teacher": 81
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2006",
    "Name": "SEKOLAH KEBANGSAAN PASIR GAJAH",
    "Student": 181,
    "Teacher": 21
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2007",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG TAYOR",
    "Student": 172,
    "Teacher": 20
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2008",
    "Name": "SEKOLAH KEBANGSAAN RKT SEBERANG TAYOR",
    "Student": 140,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2009",
    "Name": "SEKOLAH KEBANGSAAN AYER PUTEH",
    "Student": 360,
    "Teacher": 31
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2010",
    "Name": "SEKOLAH KEBANGSAAN IBOK",
    "Student": 312,
    "Teacher": 34
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2011",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KUANG",
    "Student": 1164,
    "Teacher": 81
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2012",
    "Name": "SEKOLAH KEBANGSAAN TELOK KALONG",
    "Student": 604,
    "Teacher": 45
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2013",
    "Name": "SEKOLAH KEBANGSAAN KIJAL",
    "Student": 776,
    "Teacher": 55
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2014",
    "Name": "SEKOLAH KEBANGSAAN MERAGA BERIS",
    "Student": 619,
    "Teacher": 52
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2015",
    "Name": "SEKOLAH KEBANGSAAN KEMASEK",
    "Student": 954,
    "Teacher": 68
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2016",
    "Name": "SEKOLAH KEBANGSAAN AYER JERNEH",
    "Student": 202,
    "Teacher": 22
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2017",
    "Name": "SEKOLAH KEBANGSAAN KERTEH",
    "Student": 700,
    "Teacher": 54
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2018",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN RANGGON",
    "Student": 281,
    "Teacher": 33
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2019",
    "Name": "SEKOLAH KEBANGSAAN KG CHABANG",
    "Student": 167,
    "Teacher": 19
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2021",
    "Name": "SEKOLAH KEBANGSAAN PADANG KUBU",
    "Student": 223,
    "Teacher": 24
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2023",
    "Name": "SEKOLAH KEBANGSAAN CHENEH BARU",
    "Student": 672,
    "Teacher": 51
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2035",
    "Name": "SEKOLAH KEBANGSAAN FELDA NERAM SATU",
    "Student": 455,
    "Teacher": 38
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2037",
    "Name": "SEKOLAH KEBANGSAAN SERI BANDI",
    "Student": 292,
    "Teacher": 28
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2038",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BAHARU FELDA NERAM I",
    "Student": 192,
    "Teacher": 20
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2039",
    "Name": "SEKOLAH KEBANGSAAN LEMBAH JABOR",
    "Student": 265,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2040",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) CHERUL",
    "Student": 261,
    "Teacher": 28
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2041",
    "Name": "SEKOLAH KEBANGSAAN SERI KEMAMAN",
    "Student": 643,
    "Teacher": 46
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2042",
    "Name": "SEKOLAH KEBANGSAAN PAYOH",
    "Student": 222,
    "Teacher": 22
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2043",
    "Name": "SEKOLAH KEBANGSAAN CHENEH BARU 2",
    "Student": 218,
    "Teacher": 24
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2044",
    "Name": "SEKOLAH KEBANGSAAN SERI IMAN",
    "Student": 945,
    "Teacher": 72
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2045",
    "Name": "SEKOLAH KEBANGSAAN KUALA KEMAMAN",
    "Student": 567,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2046",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PERGAM",
    "Student": 56,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2047",
    "Name": "SEKOLAH KEBANGSAAN RANTAU PETRONAS",
    "Student": 589,
    "Teacher": 49
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2048",
    "Name": "SEKOLAH KEBANGSAAN KETENGAH JAYA",
    "Student": 437,
    "Teacher": 37
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2049",
    "Name": "SEKOLAH KEBANGSAAN KG. BARU RASAU KERTEH 5",
    "Student": 352,
    "Teacher": 31
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2050",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BAHARU",
    "Student": 939,
    "Teacher": 66
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2051",
    "Name": "SEKOLAH KEBANGSAAN MAK LAGAM",
    "Student": 1080,
    "Teacher": 71
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2052",
    "Name": "SEKOLAH KEBANGSAAN KG BARU KERTEH",
    "Student": 577,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2053",
    "Name": "SEKOLAH KEBANGSAAN BUKIT ANAK DARA",
    "Student": 325,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2054",
    "Name": "SEKOLAH KEBANGSAAN SERI BANDI 2",
    "Student": 326,
    "Teacher": 33
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2055",
    "Name": "SEKOLAH KEBANGSAAN SERI GELUGOR",
    "Student": 457,
    "Teacher": 47
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBA2056",
    "Name": "SEKOLAH KEBANGSAAN SERI JAYA",
    "Student": 749,
    "Teacher": 50
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3001",
    "Name": "SEKOLAH KEBANGSAAN TENGKU AMPUAN MARIAM",
    "Student": 532,
    "Teacher": 40
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3003",
    "Name": "SEKOLAH KEBANGSAAN PAYA BUNGA",
    "Student": 174,
    "Teacher": 19
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3004",
    "Name": "SEKOLAH KEBANGSAAN PUSAT BUKIT BESAR",
    "Student": 584,
    "Teacher": 48
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3005",
    "Name": "SEKOLAH KEBANGSAAN PUSAT KUALA IBAI",
    "Student": 936,
    "Teacher": 68
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3007",
    "Name": "SEKOLAH KEBANGSAAN LADANG",
    "Student": 388,
    "Teacher": 37
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3008",
    "Name": "SEKOLAH KEBANGSAAN GONG KAPAS",
    "Student": 450,
    "Teacher": 35
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3009",
    "Name": "SEKOLAH KEBANGSAAN PASIR PANJANG",
    "Student": 567,
    "Teacher": 51
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3010",
    "Name": "SEKOLAH KEBANGSAAN DUYONG",
    "Student": 406,
    "Teacher": 32
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3011",
    "Name": "SEKOLAH KEBANGSAAN CHENDERING",
    "Student": 372,
    "Teacher": 32
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3014",
    "Name": "SEKOLAH KEBANGSAAN GONG TOK NASEK",
    "Student": 759,
    "Teacher": 58
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3015",
    "Name": "SEKOLAH KEBANGSAAN SERI NILAM",
    "Student": 603,
    "Teacher": 46
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3016",
    "Name": "SEKOLAH KEBANGSAAN PULAU RUSA",
    "Student": 211,
    "Teacher": 23
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3017",
    "Name": "SEKOLAH KEBANGSAAN LOSONG",
    "Student": 329,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3018",
    "Name": "SEKOLAH KEBANGSAAN PUSAT CHABANG TIGA",
    "Student": 980,
    "Teacher": 67
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3019",
    "Name": "SEKOLAH KEBANGSAAN PALOH",
    "Student": 319,
    "Teacher": 28
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3020",
    "Name": "SEKOLAH KEBANGSAAN RENGAS BEKAH",
    "Student": 514,
    "Teacher": 40
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3021",
    "Name": "SEKOLAH KEBANGSAAN MANIR",
    "Student": 550,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3022",
    "Name": "SEKOLAH KEBANGSAAN TELOK PASU",
    "Student": 508,
    "Teacher": 41
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3023",
    "Name": "SEKOLAH KEBANGSAAN WAKAF MEMPELAM",
    "Student": 663,
    "Teacher": 53
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3025",
    "Name": "SEKOLAH KEBANGSAAN GELUGOR",
    "Student": 686,
    "Teacher": 53
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3026",
    "Name": "SEKOLAH KEBANGSAAN KEPONG",
    "Student": 573,
    "Teacher": 44
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3027",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BAHARU SERADA",
    "Student": 348,
    "Teacher": 32
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3028",
    "Name": "SEKOLAH KEBANGSAAN SERADA",
    "Student": 166,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3029",
    "Name": "SEKOLAH KEBANGSAAN BANGGOL KATONG",
    "Student": 414,
    "Teacher": 31
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3032",
    "Name": "SEKOLAH KEBANGSAAN PADANG MIDIN",
    "Student": 1041,
    "Teacher": 70
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3036",
    "Name": "SEKOLAH KEBANGSAAN ATAS TOL",
    "Student": 475,
    "Teacher": 36
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3037",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BUKIT CHENDERING",
    "Student": 525,
    "Teacher": 40
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3038",
    "Name": "SEKOLAH KEBANGSAAN UNDANG",
    "Student": 355,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3041",
    "Name": "SEKOLAH KEBANGSAAN TOK DIR",
    "Student": 679,
    "Teacher": 47
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3060",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG TENGAH",
    "Student": 985,
    "Teacher": 78
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3061",
    "Name": "SEKOLAH KEBANGSAAN BANGGOL PERADONG",
    "Student": 638,
    "Teacher": 55
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3062",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PETITI",
    "Student": 161,
    "Teacher": 16
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3063",
    "Name": "SEKOLAH KEBANGSAAN KEBOR BESAR",
    "Student": 385,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3064",
    "Name": "SEKOLAH KEBANGSAAN BELADAU KOLAM",
    "Student": 377,
    "Teacher": 31
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3065",
    "Name": "SEKOLAH KEBANGSAAN KESOM",
    "Student": 410,
    "Teacher": 35
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3066",
    "Name": "SEKOLAH KEBANGSAAN PULAU MANIS",
    "Student": 182,
    "Teacher": 21
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3077",
    "Name": "SEKOLAH KEBANGSAAN KEBOR AYER",
    "Student": 314,
    "Teacher": 28
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3079",
    "Name": "SEKOLAH KEBANGSAAN PULAU BAHAGIA",
    "Student": 451,
    "Teacher": 36
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3085",
    "Name": "SEKOLAH KEBANGSAAN DURIAN MAS",
    "Student": 238,
    "Teacher": 24
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3086",
    "Name": "SEKOLAH KEBANGSAAN SERI BUDIMAN",
    "Student": 1084,
    "Teacher": 74
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3087",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KUALA TERENGGANU",
    "Student": 48,
    "Teacher": 34
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3089",
    "Name": "SEKOLAH KEBANGSAAN DURIAN BURUNG",
    "Student": 475,
    "Teacher": 36
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3090",
    "Name": "SEKOLAH KEBANGSAAN KUBANG IKAN",
    "Student": 956,
    "Teacher": 73
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3092",
    "Name": "SEKOLAH KEBANGSAAN PADANG HILIRAN",
    "Student": 595,
    "Teacher": 62
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3093",
    "Name": "SEKOLAH KEBANGSAAN SERI BUDIMAN 2",
    "Student": 586,
    "Teacher": 56
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3094",
    "Name": "SEKOLAH KEBANGSAAN BUKIT LOSONG",
    "Student": 424,
    "Teacher": 39
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3095",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BAYAS",
    "Student": 315,
    "Teacher": 28
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3099",
    "Name": "SEKOLAH KEBANGSAAN TELUK MENARA",
    "Student": 402,
    "Teacher": 39
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBA3102",
    "Name": "SEKOLAH KEBANGSAAN KEDAI BULOH",
    "Student": 353,
    "Teacher": 39
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4001",
    "Name": "SEKOLAH KEBANGSAAN MARANG",
    "Student": 698,
    "Teacher": 51
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4002",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG MARANG",
    "Student": 614,
    "Teacher": 46
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4003",
    "Name": "SEKOLAH KEBANGSAAN PULAU KERENGGA",
    "Student": 406,
    "Teacher": 33
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4004",
    "Name": "SEKOLAH KEBANGSAAN MERCHANG",
    "Student": 541,
    "Teacher": 45
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4005",
    "Name": "SEKOLAH KEBANGSAAN GONG BALAI",
    "Student": 373,
    "Teacher": 31
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4006",
    "Name": "SEKOLAH KEBANGSAAN PASIR PUTEH",
    "Student": 117,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4007",
    "Name": "SEKOLAH KEBANGSAAN JAMBU BONGKOK",
    "Student": 203,
    "Teacher": 22
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4008",
    "Name": "SEKOLAH KEBANGSAAN SENTOL PATAH",
    "Student": 175,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4009",
    "Name": "SEKOLAH KEBANGSAAN BATANGAN",
    "Student": 147,
    "Teacher": 21
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4010",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN BERANGAN",
    "Student": 364,
    "Teacher": 39
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4011",
    "Name": "SEKOLAH KEBANGSAAN BUKIT JEJULONG",
    "Student": 321,
    "Teacher": 28
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4012",
    "Name": "SEKOLAH KEBANGSAAN JENANG",
    "Student": 96,
    "Teacher": 13
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4013",
    "Name": "SEKOLAH KEBANGSAAN KUBU",
    "Student": 125,
    "Teacher": 19
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4014",
    "Name": "SEKOLAH KEBANGSAAN BUKIT SAWA",
    "Student": 805,
    "Teacher": 57
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4015",
    "Name": "SEKOLAH KEBANGSAAN WAKAF TAPAI",
    "Student": 661,
    "Teacher": 53
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4016",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PAYONG",
    "Student": 1050,
    "Teacher": 71
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4017",
    "Name": "SEKOLAH KEBANGSAAN GONDANG",
    "Student": 209,
    "Teacher": 21
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4018",
    "Name": "SEKOLAH KEBANGSAAN JERONG",
    "Student": 370,
    "Teacher": 33
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4019",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG RAWAI",
    "Student": 737,
    "Teacher": 61
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4020",
    "Name": "SEKOLAH KEBANGSAAN TASEK",
    "Student": 488,
    "Teacher": 38
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4021",
    "Name": "SEKOLAH KEBANGSAAN RUSILA",
    "Student": 591,
    "Teacher": 47
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4022",
    "Name": "SEKOLAH KEBANGSAAN RU RENDANG",
    "Student": 512,
    "Teacher": 44
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4023",
    "Name": "SEKOLAH KEBANGSAAN KELULUT",
    "Student": 510,
    "Teacher": 44
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4024",
    "Name": "SEKOLAH KEBANGSAAN PADANG MENGKUANG",
    "Student": 877,
    "Teacher": 68
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4025",
    "Name": "SEKOLAH KEBANGSAAN PAYA RESAK",
    "Student": 522,
    "Teacher": 47
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4026",
    "Name": "SEKOLAH KEBANGSAAN MEDAN JAYA",
    "Student": 166,
    "Teacher": 25
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4027",
    "Name": "SEKOLAH KEBANGSAAN BUKIT GASING",
    "Student": 497,
    "Teacher": 37
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBA4028",
    "Name": "SEKOLAH KEBANGSAAN GONG NANGKA",
    "Student": 420,
    "Teacher": 41
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5001",
    "Name": "SEKOLAH KEBANGSAAN KUALA BERANG",
    "Student": 495,
    "Teacher": 50
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5002",
    "Name": "SEKOLAH KEBANGSAAN TELAGA",
    "Student": 348,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5003",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PERAH",
    "Student": 507,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5004",
    "Name": "SEKOLAH KEBANGSAAN TANGGOL",
    "Student": 179,
    "Teacher": 22
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5005",
    "Name": "SEKOLAH KEBANGSAAN BUKIT DIMAN",
    "Student": 215,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5006",
    "Name": "SEKOLAH KEBANGSAAN PAUH",
    "Student": 203,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5007",
    "Name": "SEKOLAH KEBANGSAAN BUKIT APIT",
    "Student": 172,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5008",
    "Name": "SEKOLAH KEBANGSAAN DUSUN",
    "Student": 69,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5009",
    "Name": "SEKOLAH KEBANGSAAN LANGGAR",
    "Student": 143,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5010",
    "Name": "SEKOLAH KEBANGSAAN GETANG",
    "Student": 146,
    "Teacher": 21
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5011",
    "Name": "SEKOLAH KEBANGSAAN BUKIT GEMUROH",
    "Student": 160,
    "Teacher": 25
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5012",
    "Name": "SEKOLAH KEBANGSAAN MATANG",
    "Student": 129,
    "Teacher": 19
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5013",
    "Name": "SEKOLAH KEBANGSAAN TENGKAWANG",
    "Student": 249,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5014",
    "Name": "SEKOLAH KEBANGSAAN MENERONG",
    "Student": 117,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5015",
    "Name": "SEKOLAH KEBANGSAAN SG BULOH",
    "Student": 135,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5016",
    "Name": "SEKOLAH KEBANGSAAN KUALA PING",
    "Student": 83,
    "Teacher": 16
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5017",
    "Name": "SEKOLAH KEBANGSAAN TAPU",
    "Student": 143,
    "Teacher": 15
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5018",
    "Name": "SEKOLAH KEBANGSAAN PADANG SETEBU",
    "Student": 120,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5019",
    "Name": "SEKOLAH KEBANGSAAN PADANG SETAR",
    "Student": 131,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5020",
    "Name": "SEKOLAH KEBANGSAAN KUA",
    "Student": 196,
    "Teacher": 22
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5021",
    "Name": "SEKOLAH KEBANGSAAN PASIR NERING",
    "Student": 68,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5022",
    "Name": "SEKOLAH KEBANGSAAN PEROH",
    "Student": 234,
    "Teacher": 33
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5023",
    "Name": "SEKOLAH KEBANGSAAN NIBONG",
    "Student": 39,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5024",
    "Name": "SEKOLAH KEBANGSAAN KUALA DURA",
    "Student": 151,
    "Teacher": 19
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5025",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TADOK",
    "Student": 83,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5027",
    "Name": "SEKOLAH KEBANGSAAN KEMAT",
    "Student": 61,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5028",
    "Name": "SEKOLAH KEBANGSAAN LUBUK PERIOK",
    "Student": 66,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5029",
    "Name": "SEKOLAH KEBANGSAAN LEREK",
    "Student": 48,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5030",
    "Name": "SEKOLAH KEBANGSAAN PEREH",
    "Student": 43,
    "Teacher": 12
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5031",
    "Name": "SEKOLAH KEBANGSAAN TERIS",
    "Student": 82,
    "Teacher": 13
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5032",
    "Name": "SEKOLAH KEBANGSAAN TAPAH",
    "Student": 141,
    "Teacher": 21
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5033",
    "Name": "SEKOLAH KEBANGSAAN KUALA JENDERIS",
    "Student": 82,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5034",
    "Name": "SEKOLAH KEBANGSAAN BETONG",
    "Student": 72,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5035",
    "Name": "SEKOLAH KEBANGSAAN CHETING",
    "Student": 110,
    "Teacher": 16
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5041",
    "Name": "SEKOLAH KEBANGSAAN LKTP BUKIT BADING",
    "Student": 285,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5042",
    "Name": "SEKOLAH KEBANGSAAN FELDA JERANGAU BARAT",
    "Student": 185,
    "Teacher": 23
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5043",
    "Name": "SEKOLAH KEBANGSAAN BINJAI KERTAS",
    "Student": 149,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5044",
    "Name": "SEKOLAH KEBANGSAAN FELDA MENGKAWANG",
    "Student": 163,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5045",
    "Name": "SEKOLAH KEBANGSAAN FELDA TERSAT",
    "Student": 107,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5046",
    "Name": "SEKOLAH KEBANGSAAN SG BERUA",
    "Student": 72,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5047",
    "Name": "SEKOLAH KEBANGSAAN KUALA TELEMONG",
    "Student": 326,
    "Teacher": 37
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5048",
    "Name": "SEKOLAH KEBANGSAAN PASIR TINGGI",
    "Student": 185,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5049",
    "Name": "SEKOLAH KEBANGSAAN SERI BERANG",
    "Student": 449,
    "Teacher": 34
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5050",
    "Name": "SEKOLAH KEBANGSAAN TOK RANDOK",
    "Student": 246,
    "Teacher": 26
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5051",
    "Name": "SEKOLAH KEBANGSAAN LANDAS",
    "Student": 132,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5052",
    "Name": "SEKOLAH KEBANGSAAN SERI BULUH",
    "Student": 113,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5053",
    "Name": "SEKOLAH KEBANGSAAN AJIL",
    "Student": 447,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBA5054",
    "Name": "SEKOLAH KEBANGSAAN PADANG SETENGGE",
    "Student": 186,
    "Teacher": 22
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6006",
    "Name": "SEKOLAH KEBANGSAAN LUBOK TERAS",
    "Student": 67,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6009",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG FIKRI",
    "Student": 101,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6010",
    "Name": "SEKOLAH KEBANGSAAN KUALA SETIU",
    "Student": 128,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6011",
    "Name": "SEKOLAH KEBANGSAAN PENAREK",
    "Student": 129,
    "Teacher": 21
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6016",
    "Name": "SEKOLAH KEBANGSAAN MANGKOK",
    "Student": 80,
    "Teacher": 13
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6020",
    "Name": "SEKOLAH KEBANGSAAN TELAGA PAPAN",
    "Student": 277,
    "Teacher": 32
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6021",
    "Name": "SEKOLAH KEBANGSAAN BARI",
    "Student": 176,
    "Teacher": 20
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6034",
    "Name": "SEKOLAH KEBANGSAAN BINTANG",
    "Student": 445,
    "Teacher": 46
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6035",
    "Name": "SEKOLAH KEBANGSAAN GUNTONG",
    "Student": 287,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6036",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BULOH",
    "Student": 370,
    "Teacher": 46
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6037",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BESUT",
    "Student": 110,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6038",
    "Name": "SEKOLAH KEBANGSAAN PUTERA JAYA",
    "Student": 352,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6040",
    "Name": "SEKOLAH KEBANGSAAN KG. RAHMAT",
    "Student": 322,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6041",
    "Name": "SEKOLAH KEBANGSAAN CHALOK",
    "Student": 303,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6042",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PUTERA",
    "Student": 265,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6056",
    "Name": "SEKOLAH KEBANGSAAN GUNTONG LUAR",
    "Student": 148,
    "Teacher": 19
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6058",
    "Name": "SEKOLAH KEBANGSAAN MERANG",
    "Student": 337,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6066",
    "Name": "SEKOLAH KEBANGSAAN (FELDA) CHALOK BARAT",
    "Student": 280,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6068",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TONG",
    "Student": 186,
    "Teacher": 20
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6069",
    "Name": "SEKOLAH KEBANGSAAN BATU 29",
    "Student": 160,
    "Teacher": 21
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6070",
    "Name": "SEKOLAH KEBANGSAAN LANGKAP",
    "Student": 106,
    "Teacher": 16
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6071",
    "Name": "SEKOLAH KEBANGSAAN PELONG",
    "Student": 124,
    "Teacher": 16
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6073",
    "Name": "SEKOLAH KEBANGSAAN MERBAU MENYUSUT",
    "Student": 61,
    "Teacher": 12
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6074",
    "Name": "SEKOLAH KEBANGSAAN BUKIT MUNDOK",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6075",
    "Name": "SEKOLAH KEBANGSAAN KG BUKIT ULU NERUS",
    "Student": 55,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6080",
    "Name": "SEKOLAH KEBANGSAAN PAK BA",
    "Student": 38,
    "Teacher": 12
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6082",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG FIKRI SUNGAI TONG",
    "Student": 119,
    "Teacher": 16
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6084",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LAS",
    "Student": 37,
    "Teacher": 12
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6085",
    "Name": "SEKOLAH KEBANGSAAN RHU SEPULUH",
    "Student": 264,
    "Teacher": 27
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6086",
    "Name": "SEKOLAH KEBANGSAAN BANGGOL",
    "Student": 282,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6087",
    "Name": "SEKOLAH KEBANGSAAN PANCHOR MERAH",
    "Student": 173,
    "Teacher": 16
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6088",
    "Name": "SEKOLAH KEBANGSAAN PERMAISURI",
    "Student": 272,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6089",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LEREK",
    "Student": 183,
    "Teacher": 24
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6090",
    "Name": "SEKOLAH KEBANGSAAN LEMBAH BIDONG",
    "Student": 208,
    "Teacher": 26
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6091",
    "Name": "SEKOLAH KEBANGSAAN LEMBAH JAYA",
    "Student": 274,
    "Teacher": 26
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6092",
    "Name": "SEKOLAH KEBANGSAAN SERI KASAR",
    "Student": 43,
    "Teacher": 14
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6113",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG JAYA",
    "Student": 53,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6114",
    "Name": "SEKOLAH KEBANGSAAN PAYONG BARU",
    "Student": 67,
    "Teacher": 13
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6115",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG TAYOR",
    "Student": 40,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6116",
    "Name": "SEKOLAH KEBANGSAAN BARI ( SG TONG)",
    "Student": 33,
    "Teacher": 7
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6117",
    "Name": "SEKOLAH KEBANGSAAN JELAPANG",
    "Student": 153,
    "Teacher": 21
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6118",
    "Name": "SEKOLAH KEBANGSAAN SAUJANA",
    "Student": 391,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TBA6119",
    "Name": "SEKOLAH KEBANGSAAN SERI LANGKAP",
    "Student": 259,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7001",
    "Name": "SEKOLAH KEBANGSAAN DARAT BATU RAKIT",
    "Student": 192,
    "Teacher": 20
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7002",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TUNGGAL",
    "Student": 818,
    "Teacher": 59
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7003",
    "Name": "SEKOLAH KEBANGSAAN SEBERANG TAKIR",
    "Student": 530,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7004",
    "Name": "SEKOLAH KEBANGSAAN MENGABANG TELIPOT",
    "Student": 694,
    "Teacher": 53
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7005",
    "Name": "SEKOLAH KEBANGSAAN BUKIT GUNTONG",
    "Student": 515,
    "Teacher": 43
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7006",
    "Name": "SEKOLAH KEBANGSAAN BATU RAKIT",
    "Student": 488,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7007",
    "Name": "SEKOLAH KEBANGSAAN MARAS",
    "Student": 357,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7008",
    "Name": "SEKOLAH KEBANGSAAN PAGAR BESI",
    "Student": 624,
    "Teacher": 45
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7009",
    "Name": "SEKOLAH KEBANGSAAN BUKIT CHEMPAKA",
    "Student": 378,
    "Teacher": 31
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7010",
    "Name": "SEKOLAH KEBANGSAAN BUKIT WAN",
    "Student": 327,
    "Teacher": 29
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7011",
    "Name": "SEKOLAH KEBANGSAAN BUKIT NANAS",
    "Student": 321,
    "Teacher": 35
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7012",
    "Name": "SEKOLAH KEBANGSAAN PECHAH ROTAN",
    "Student": 59,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7013",
    "Name": "SEKOLAH KEBANGSAAN TOK JEMBAL",
    "Student": 963,
    "Teacher": 65
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7014",
    "Name": "SEKOLAH KEBANGSAAN TOK JIRING",
    "Student": 953,
    "Teacher": 71
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7015",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG GEMUROH",
    "Student": 188,
    "Teacher": 22
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7016",
    "Name": "SEKOLAH KEBANGSAAN PULAU REDANG",
    "Student": 167,
    "Teacher": 21
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7017",
    "Name": "SEKOLAH KEBANGSAAN LKTP BELARA",
    "Student": 135,
    "Teacher": 18
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7018",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TOK BENG",
    "Student": 437,
    "Teacher": 34
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7019",
    "Name": "SEKOLAH KEBANGSAAN KOMPLEKS MENGABANG TELIPOT",
    "Student": 622,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7020",
    "Name": "SEKOLAH KEBANGSAAN GONG BADAK",
    "Student": 960,
    "Teacher": 76
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7021",
    "Name": "SEKOLAH KEBANGSAAN TELUK KETAPANG",
    "Student": 865,
    "Teacher": 64
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7022",
    "Name": "SEKOLAH KEBANGSAAN KOMPLEKS GONG BADAK",
    "Student": 931,
    "Teacher": 62
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7023",
    "Name": "SEKOLAH KEBANGSAAN BUKIT TUMBUH",
    "Student": 611,
    "Teacher": 44
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7024",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG GELAM",
    "Student": 396,
    "Teacher": 36
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7025",
    "Name": "SEKOLAH KEBANGSAAN PADANG AIR",
    "Student": 350,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7026",
    "Name": "SEKOLAH KEBANGSAAN KOMPLEKS SEBERANG TAKIR",
    "Student": 774,
    "Teacher": 64
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7027",
    "Name": "SEKOLAH KEBANGSAAN INSTITUT PENDIDIKAN GURU KAMPUS DATO RAZALI ISMAIL",
    "Student": 395,
    "Teacher": 35
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7028",
    "Name": "SEKOLAH KEBANGSAAN PADANG KEMUNTING",
    "Student": 359,
    "Teacher": 37
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TBA7029",
    "Name": "SEKOLAH KEBANGSAAN KOMPLEKS TEMBESU",
    "Student": 632,
    "Teacher": 46
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBB0061",
    "Name": "SEKOLAH KEBANGSAAN TENGKU MAHMUD",
    "Student": 909,
    "Teacher": 69
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBB1030",
    "Name": "SEKOLAH KEBANGSAAN SULTAN OMAR",
    "Student": 928,
    "Teacher": 81
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBB2024",
    "Name": "SEKOLAH KEBANGSAAN SULTAN ISMAIL",
    "Student": 1147,
    "Teacher": 79
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBB3088",
    "Name": "SEKOLAH KEBANGSAAN SULTAN SULAIMAN 1",
    "Student": 1299,
    "Teacher": 88
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBB3089",
    "Name": "SEKOLAH KEBANGSAAN SULTAN SULAIMAN 2",
    "Student": 904,
    "Teacher": 61
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBB3090",
    "Name": "SEKOLAH KEBANGSAAN TENGKU BARIAH",
    "Student": 514,
    "Teacher": 41
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TBB5038",
    "Name": "SEKOLAH KEBANGSAAN TENGKU AMPUAN INTAN",
    "Student": 680,
    "Teacher": 51
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TBC0063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 226,
    "Teacher": 27
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBC1032",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG HWA",
    "Student": 140,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBC1033",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN CHONE",
    "Student": 101,
    "Teacher": 15
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TBC1034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE MONG",
    "Student": 115,
    "Teacher": 13
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBC2026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK KHOON",
    "Student": 67,
    "Teacher": 11
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBC2027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUKAI",
    "Student": 380,
    "Teacher": 31
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBC2028",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA KEMAMAN",
    "Student": 127,
    "Teacher": 14
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TBC2029",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JABOR",
    "Student": 14,
    "Teacher": 8
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TBC3094",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA WEI SIN",
    "Student": 814,
    "Teacher": 66
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TBC4014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA",
    "Student": 168,
    "Teacher": 16
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA BESUT",
    "Student": 933,
    "Teacher": 88
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI NERING",
    "Student": 1288,
    "Teacher": 116
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NASIRUDDIN SHAH",
    "Student": 1141,
    "Teacher": 109
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT PAYONG",
    "Student": 964,
    "Teacher": 103
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0070",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RENEK",
    "Student": 688,
    "Teacher": 71
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PELAGAT",
    "Student": 845,
    "Teacher": 74
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU MAHMUD 2",
    "Student": 721,
    "Teacher": 66
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRA",
    "Student": 675,
    "Teacher": 76
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERMAISURI NUR ZAHIRAH",
    "Student": 869,
    "Teacher": 77
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEMBILA",
    "Student": 555,
    "Teacher": 61
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0076",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BUKIT PUTERI",
    "Student": 495,
    "Teacher": 49
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0077",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JABI",
    "Student": 556,
    "Teacher": 68
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0078",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TOK DOR",
    "Student": 445,
    "Teacher": 51
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEA0079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALOR PEROI",
    "Student": 487,
    "Teacher": 52
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1027",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG PULUT",
    "Student": 526,
    "Teacher": 44
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1029",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU INTAN ZAHARAH",
    "Student": 916,
    "Teacher": 90
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1030",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAKA",
    "Student": 1206,
    "Teacher": 92
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PULAU SERAI",
    "Student": 722,
    "Teacher": 61
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DURIAN MAS",
    "Student": 586,
    "Teacher": 73
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI DUNGUN",
    "Student": 643,
    "Teacher": 50
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SURA",
    "Student": 655,
    "Teacher": 59
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JERANGAU",
    "Student": 390,
    "Teacher": 47
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI RASAU",
    "Student": 290,
    "Teacher": 31
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KETENGAH JAYA",
    "Student": 467,
    "Teacher": 54
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1038",
    "Name": "SEKOLAH MENENGAH SAINS DUNGUN",
    "Student": 682,
    "Teacher": 63
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA JENGAL",
    "Student": 198,
    "Teacher": 26
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PAKA",
    "Student": 594,
    "Teacher": 48
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEJA PUTRA",
    "Student": 172,
    "Teacher": 24
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT BESI",
    "Student": 249,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BALAI BESAR",
    "Student": 833,
    "Teacher": 69
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS RANTAU ABANG",
    "Student": 385,
    "Teacher": 33
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' ENGKU BIJAYA SURA",
    "Student": 508,
    "Teacher": 48
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEA1046",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS PADANG JAMBU",
    "Student": 396,
    "Teacher": 41
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2019",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KERTEH",
    "Student": 770,
    "Teacher": 65
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2021",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHENEH BARU",
    "Student": 488,
    "Teacher": 65
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2022",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUKAI",
    "Student": 1017,
    "Teacher": 89
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BADRUL ALAM SHAH",
    "Student": 1023,
    "Teacher": 87
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2024",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AYER PUTEH",
    "Student": 564,
    "Teacher": 47
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KIJAL",
    "Student": 1005,
    "Teacher": 76
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2027",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ISMAIL 2",
    "Student": 812,
    "Teacher": 58
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2028",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GELIGA",
    "Student": 853,
    "Teacher": 90
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2029",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RANTAU PETRONAS",
    "Student": 338,
    "Teacher": 36
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2030",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN FELDA NERAM",
    "Student": 444,
    "Teacher": 35
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RASAU KERTEH B5",
    "Student": 229,
    "Teacher": 34
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAK LAGAM",
    "Student": 465,
    "Teacher": 56
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2033",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT MENTOK",
    "Student": 808,
    "Teacher": 68
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2034",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG BARU KERTEH",
    "Student": 403,
    "Teacher": 41
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BINJAI",
    "Student": 1103,
    "Teacher": 73
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BANDI",
    "Student": 448,
    "Teacher": 51
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHERUL",
    "Student": 128,
    "Teacher": 17
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI CHENEH",
    "Student": 157,
    "Teacher": 24
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT KUANG",
    "Student": 651,
    "Teacher": 64
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANGGOL",
    "Student": 837,
    "Teacher": 59
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEA2041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PERMAI",
    "Student": 398,
    "Teacher": 32
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3081",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MEGAT PANJI ALAM",
    "Student": 565,
    "Teacher": 69
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3083",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG MIDIN",
    "Student": 1543,
    "Teacher": 118
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3085",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BUDIMAN",
    "Student": 372,
    "Teacher": 47
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3088",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MANIR",
    "Student": 1282,
    "Teacher": 128
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3110",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BELARA",
    "Student": 1215,
    "Teacher": 115
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3112",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN AHMAD",
    "Student": 1295,
    "Teacher": 120
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3117",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHENDERING",
    "Student": 774,
    "Teacher": 75
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3118",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN MANSOR",
    "Student": 1035,
    "Teacher": 87
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3119",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT BESAR",
    "Student": 855,
    "Teacher": 92
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3120",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' RAZALI ISMAIL",
    "Student": 911,
    "Teacher": 83
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3122",
    "Name": "SEKOLAH MENENGAH SAINS KUALA TERENGGANU",
    "Student": 281,
    "Teacher": 35
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3123",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI NILAM",
    "Student": 1158,
    "Teacher": 119
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3125",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG NEGARA",
    "Student": 571,
    "Teacher": 66
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3128",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' HAJI MOHD SAID",
    "Student": 647,
    "Teacher": 66
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEA3130",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU MUHAMMAD ISMAIL",
    "Student": 509,
    "Teacher": 51
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TEA4011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MERCHANG",
    "Student": 556,
    "Teacher": 69
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TEA4012",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN TELANAI",
    "Student": 786,
    "Teacher": 92
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TEA4013",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT SAWA",
    "Student": 1244,
    "Teacher": 96
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TEA4014",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU LELA SEGARA",
    "Student": 1251,
    "Teacher": 119
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TEA4015",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN BERANGAN",
    "Student": 795,
    "Teacher": 78
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TEA4016",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RUSILA",
    "Student": 494,
    "Teacher": 57
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TEA4017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEBERANG MARANG",
    "Student": 884,
    "Teacher": 69
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TEA4018",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN WAKAF TAPAI",
    "Student": 458,
    "Teacher": 47
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TEA4019",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK AWANG JABAR",
    "Student": 172,
    "Teacher": 25
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TEA4020",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PAYONG",
    "Student": 856,
    "Teacher": 81
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TEA4021",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI SERATING",
    "Student": 457,
    "Teacher": 47
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5035",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MATANG",
    "Student": 401,
    "Teacher": 49
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AJIL",
    "Student": 859,
    "Teacher": 77
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LANDAS",
    "Student": 346,
    "Teacher": 51
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5038",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MENERONG",
    "Student": 474,
    "Teacher": 61
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5039",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BERANG",
    "Student": 651,
    "Teacher": 61
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA TELEMONG",
    "Student": 415,
    "Teacher": 57
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JENAGOR",
    "Student": 333,
    "Teacher": 46
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA JENDERIS",
    "Student": 277,
    "Teacher": 34
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN INDERA",
    "Student": 211,
    "Teacher": 28
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT DIMAN",
    "Student": 307,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5045",
    "Name": "SEKOLAH MENENGAH SAINS HULU TERENGGANU",
    "Student": 689,
    "Teacher": 64
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5046",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAPU",
    "Student": 194,
    "Teacher": 24
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEA5047",
    "Name": "KOLEJ TINGKATAN ENAM HULU TERENGGANU",
    "Student": 152,
    "Teacher": 41
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU IBRAHIM",
    "Student": 690,
    "Teacher": 82
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6080",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI TONG",
    "Student": 593,
    "Teacher": 77
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6081",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENAREK",
    "Student": 783,
    "Teacher": 69
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6082",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SAUJANA",
    "Student": 636,
    "Teacher": 69
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6083",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHALOK",
    "Student": 678,
    "Teacher": 79
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6084",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PELONG",
    "Student": 238,
    "Teacher": 39
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6085",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUNTONG",
    "Student": 416,
    "Teacher": 43
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6086",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LEMBAH BIDONG",
    "Student": 452,
    "Teacher": 57
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6087",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTERA JAYA",
    "Student": 209,
    "Teacher": 30
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6088",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LANGKAP",
    "Student": 213,
    "Teacher": 31
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6089",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT NENAS",
    "Student": 288,
    "Teacher": 42
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TEA6090",
    "Name": "SEKOLAH MENENGAH SAINS SETIU",
    "Student": 664,
    "Teacher": 57
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEA7001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN IBRAHIM FIKRI",
    "Student": 1285,
    "Teacher": 141
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEA7002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU MIZAN ZAINAL ABIDIN",
    "Student": 837,
    "Teacher": 77
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEA7003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT GUNTONG",
    "Student": 617,
    "Teacher": 71
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEA7004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT TUNGGAL",
    "Student": 1105,
    "Teacher": 105
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEA7005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS MENGABANG TELIPOT",
    "Student": 1101,
    "Teacher": 105
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEA7006",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS GONG BADAK",
    "Student": 1024,
    "Teacher": 84
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEA7007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS SEBERANG TAKIR",
    "Student": 820,
    "Teacher": 81
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEA7008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG KEMUNTING",
    "Student": 622,
    "Teacher": 73
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEA7009",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU RAKIT",
    "Student": 762,
    "Teacher": 64
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEA7010",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS TEMBESU",
    "Student": 687,
    "Teacher": 53
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEA7011",
    "Name": "SEKOLAH SUKAN MALAYSIA TERENGGANU",
    "Student": 161,
    "Teacher": 44
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TEB0062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU MAHMUD",
    "Student": 757,
    "Teacher": 100
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TEB1031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN OMAR",
    "Student": 863,
    "Teacher": 84
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TEB2025",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN ISMAIL",
    "Student": 1169,
    "Teacher": 131
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEB3091",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SULTAN SULAIMAN",
    "Student": 1162,
    "Teacher": 158
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEB3092",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU BARIAH",
    "Student": 771,
    "Teacher": 83
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TEB3093",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA WEI SIN",
    "Student": 942,
    "Teacher": 89
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TEE5036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGKU AMPUAN INTAN",
    "Student": 678,
    "Teacher": 68
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TEE7001",
    "Name": "SEKOLAH MENENGAH SAINS SULTAN MAHMUD",
    "Student": 637,
    "Teacher": 62
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TFT0001",
    "Name": "SEKOLAH MENENGAH IMTIAZ YAYASAN TERENGGANU-BESUT",
    "Student": 579,
    "Teacher": 109
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TFT0002",
    "Name": "SMA ITTIFAKIAH",
    "Student": 567,
    "Teacher": 64
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TFT0003",
    "Name": "SMA MAARIF",
    "Student": 616,
    "Teacher": 69
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TFT1001",
    "Name": "SEKOLAH MENENGAH AGAMA SULTAN ISMAIL",
    "Student": 827,
    "Teacher": 75
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TFT1002",
    "Name": "SEKOLAH MENENGAH IMTIAZ YAYASAN TERENGGANU-DUNGUN",
    "Student": 331,
    "Teacher": 71
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TFT2001",
    "Name": "SMA AL FALAH",
    "Student": 965,
    "Teacher": 83
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "TFT2002",
    "Name": "SEKOLAH MENENGAH AGAMA IMTIAZ KEMAMAN",
    "Student": 359,
    "Teacher": 69
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TFT3001",
    "Name": "SMA KHAIRIAH",
    "Student": 697,
    "Teacher": 77
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TFT3002",
    "Name": "SMA (ATAS) SULTAN ZAINAL ABIDIN",
    "Student": 649,
    "Teacher": 88
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TFT3003",
    "Name": "SMA SULTAN ZAINAL ABIDIN LADANG",
    "Student": 424,
    "Teacher": 44
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TFT3004",
    "Name": "SEKOLAH MENENGAH IMTIAZ YAYASAN TERENGGANU-KUALA TERENGGANU",
    "Student": 483,
    "Teacher": 92
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TFT4001",
    "Name": "SEKOLAH MENENGAH AGAMA MARANG",
    "Student": 700,
    "Teacher": 67
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TFT5001",
    "Name": "SEKOLAH MENENGAH IMTIAZ YAYASAN TERENGGANU-KUALA BERANG",
    "Student": 292,
    "Teacher": 58
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "TFT5002",
    "Name": "SMA MAHMUDIAH",
    "Student": 798,
    "Teacher": 77
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TFT6001",
    "Name": "SEKOLAH MENENGAH AGAMA SETIU",
    "Student": 591,
    "Teacher": 57
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD SETIU",
    "Kod": "TFT6002",
    "Name": "SEKOLAH MENENGAH IMTIAZ YAYASAN TERENGGANU SETIU",
    "Student": 299,
    "Teacher": 35
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "THA0001",
    "Name": "KOLEJ VOKASIONAL BESUT",
    "Student": 707,
    "Teacher": 129
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "THA0002",
    "Name": "KOLEJ VOKASIONAL SULTAN MIZAN",
    "Student": null,
    "Teacher": null
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "THA1001",
    "Name": "KOLEJ VOKASIONAL DUNGUN",
    "Student": 471,
    "Teacher": 81
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KEMAMAN",
    "Kod": "THA2002",
    "Name": "KOLEJ VOKASIONAL KEMAMAN",
    "Student": 745,
    "Teacher": 125
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD HULU TERENGGANU",
    "Kod": "THA5001",
    "Name": "KOLEJ VOKASIONAL KUALA BERANG",
    "Student": 176,
    "Teacher": 34
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "THA7001",
    "Name": "KOLEJ VOKASIONAL WAKAF TEMBESU",
    "Student": 737,
    "Teacher": 109
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TKE7001",
    "Name": "SEKOLAH MENENGAH TEKNIK KUALA TERENGGANU",
    "Student": 515,
    "Teacher": 55
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TRA0001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA NURUL ITTIFAQ",
    "Student": 806,
    "Teacher": 76
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD BESUT",
    "Kod": "TRA0002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA WATANIAH",
    "Student": 823,
    "Teacher": 65
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD DUNGUN",
    "Kod": "TRA1001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (AGAMA) KUALA ABANG",
    "Student": 609,
    "Teacher": 54
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA TERENGGANU",
    "Kod": "TRA3002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SHEIKH ABDUL MALEK",
    "Student": 1078,
    "Teacher": 97
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD MARANG",
    "Kod": "TRA4016",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (AGAMA) DURIAN GULING",
    "Student": 886,
    "Teacher": 83
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TRA7001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA DATO' HAJI ABBAS",
    "Student": 1188,
    "Teacher": 82
  },
  {
    "NationalState": "TERENGGANU",
    "PPD": "PPD KUALA NERUS",
    "Kod": "TRA7002",
    "Name": "SEKOLAH BERASRAMA PENUH INTEGRASI BATU RAKIT",
    "Student": 517,
    "Teacher": 96
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0002",
    "Name": "SEKOLAH KEBANGSAAN SULTAN HISAMUDDIN ALAM SHAH",
    "Student": 776,
    "Teacher": 45
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0003",
    "Name": "SEKOLAH KEBANGSAAN JALAN PASAR 1",
    "Student": 132,
    "Teacher": 18
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0005",
    "Name": "SEKOLAH KEBANGSAAN TUN HUSSEIN ONN",
    "Student": 1020,
    "Teacher": 64
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0006",
    "Name": "SEKOLAH KEBANGSAAN DATOK KERAMAT 1",
    "Student": 375,
    "Teacher": 29
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0007",
    "Name": "SEKOLAH KEBANGSAAN DATOK KERAMAT 2",
    "Student": 454,
    "Teacher": 38
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0008",
    "Name": "SEKOLAH KEBANGSAAN POLIS DEPOT",
    "Student": 632,
    "Teacher": 50
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0009",
    "Name": "SEKOLAH KEBANGSAAN SENTUL 1",
    "Student": 1163,
    "Teacher": 72
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0010",
    "Name": "SEKOLAH KEBANGSAAN SETAPAK",
    "Student": 430,
    "Teacher": 40
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0011",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BANDARAYA",
    "Student": 1294,
    "Teacher": 85
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0012",
    "Name": "SEKOLAH KEBANGSAAN BANGSAR",
    "Student": 1686,
    "Teacher": 106
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0013",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BHARU",
    "Student": 372,
    "Teacher": 29
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0014",
    "Name": "SEKOLAH KEBANGSAAN TAMAN BUKIT MALURI",
    "Student": 536,
    "Teacher": 39
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0015",
    "Name": "SEKOLAH KEBANGSAAN WANGSA JAYA",
    "Student": 921,
    "Teacher": 55
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0016",
    "Name": "SEKOLAH KEBANGSAAN WANGSA MAJU SEKSYEN 1",
    "Student": 1084,
    "Teacher": 67
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0017",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MIDAH",
    "Student": 1107,
    "Teacher": 74
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0018",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TUN DR ISMAIL 1",
    "Student": 1246,
    "Teacher": 72
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0020",
    "Name": "SEKOLAH KEBANGSAAN KG SELAYANG",
    "Student": 87,
    "Teacher": 17
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0021",
    "Name": "SEKOLAH KEBANGSAAN KG BATU",
    "Student": 522,
    "Teacher": 36
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0022",
    "Name": "SEKOLAH KEBANGSAAN SEGAMBUT",
    "Student": 458,
    "Teacher": 36
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0031",
    "Name": "SEKOLAH KEBANGSAAN SG BESI",
    "Student": 734,
    "Teacher": 45
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0032",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN TENTERA DARAT",
    "Student": 1339,
    "Teacher": 82
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0033",
    "Name": "SEKOLAH KEBANGSAAN PETALING 1",
    "Student": 646,
    "Teacher": 41
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0035",
    "Name": "SEKOLAH KEBANGSAAN SG PENCHALA",
    "Student": 682,
    "Teacher": 49
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0036",
    "Name": "SEKOLAH KEBANGSAAN SERI MEGA",
    "Student": 543,
    "Teacher": 41
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0037",
    "Name": "SEKOLAH KEBANGSAAN BKT DAMANSARA",
    "Student": 1204,
    "Teacher": 72
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0038",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PANTAI",
    "Student": 879,
    "Teacher": 55
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0039",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TUN RAZAK 1",
    "Student": 814,
    "Teacher": 50
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0040",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TUN RAZAK 2",
    "Student": 465,
    "Teacher": 32
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0041",
    "Name": "SEKOLAH KEBANGSAAN SENTUL 2",
    "Student": 600,
    "Teacher": 42
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0042",
    "Name": "SEKOLAH KEBANGSAAN PETALING 2",
    "Student": 425,
    "Teacher": 30
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0043",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU SERI PETALING",
    "Student": 577,
    "Teacher": 42
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0045",
    "Name": "SEKOLAH KEBANGSAAN SERI CHERAS",
    "Student": 802,
    "Teacher": 56
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0046",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU SENTUL",
    "Student": 1167,
    "Teacher": 71
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0047",
    "Name": "SEKOLAH KEBANGSAAN WANGSA MAJU SEKSYEN 2",
    "Student": 515,
    "Teacher": 44
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0048",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KG BAHARU",
    "Student": 30,
    "Teacher": 22
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0049",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS JLN BATU",
    "Student": 35,
    "Teacher": 21
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0050",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS JLN PEEL",
    "Student": 35,
    "Teacher": 25
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0051",
    "Name": "SEKOLAH KEBANGSAAN TAMAN DESA",
    "Student": 591,
    "Teacher": 48
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0052",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MALURI",
    "Student": 974,
    "Teacher": 83
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0053",
    "Name": "SEKOLAH KEBANGSAAN AU KERAMAT",
    "Student": 1971,
    "Teacher": 116
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0054",
    "Name": "SEKOLAH KEBANGSAAN SERI PERAK",
    "Student": 359,
    "Teacher": 27
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0055",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SEGAR",
    "Student": 738,
    "Teacher": 65
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0056",
    "Name": "SEKOLAH KEBANGSAAN TAMAN KOPERASI POLIS",
    "Student": 1115,
    "Teacher": 86
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0057",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TUN DR. ISMAIL (2)",
    "Student": 910,
    "Teacher": 80
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0058",
    "Name": "SEKOLAH KEBANGSAAN SETIAWANGSA",
    "Student": 2288,
    "Teacher": 130
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0059",
    "Name": "SEKOLAH KEBANGSAAN TAMAN KEPONG",
    "Student": 450,
    "Teacher": 42
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0060",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SERI RAMPAI",
    "Student": 1037,
    "Teacher": 62
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0061",
    "Name": "SEKOLAH KEBANGSAAN DESA TUN HUSSEIN ONN",
    "Student": 1883,
    "Teacher": 108
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0062",
    "Name": "SEKOLAH KEBANGSAAN WANGSA MELAWATI",
    "Student": 825,
    "Teacher": 51
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0063",
    "Name": "SEKOLAH KEBANGSAAN JALAN RAJA MUDA",
    "Student": 666,
    "Teacher": 47
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0064",
    "Name": "SEKOLAH KEBANGSAAN MENJALARA",
    "Student": 359,
    "Teacher": 28
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0065",
    "Name": "SEKOLAH KEBANGSAAN TAMAN SRI SINAR",
    "Student": 428,
    "Teacher": 43
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0066",
    "Name": "SEKOLAH KEBANGSAAN WANGSA MAJU ZON R10",
    "Student": 1059,
    "Teacher": 60
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0067",
    "Name": "SEKOLAH KEBANGSAAN DESA SETAPAK",
    "Student": 419,
    "Teacher": 43
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0068",
    "Name": "SEKOLAH KEBANGSAAN SETAPAK INDAH",
    "Student": 937,
    "Teacher": 58
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0069",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MIDAH 2",
    "Student": 878,
    "Teacher": 67
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0070",
    "Name": "SEKOLAH KEBANGSAAN DANAU KOTA",
    "Student": 1710,
    "Teacher": 114
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0071",
    "Name": "SEKOLAH KEBANGSAAN DESA PANDAN",
    "Student": 1116,
    "Teacher": 68
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0072",
    "Name": "SEKOLAH KEBANGSAAN SERI MURNI",
    "Student": 479,
    "Teacher": 40
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0073",
    "Name": "SEKOLAH KEBANGSAAN SERI SURIA",
    "Student": 620,
    "Teacher": 47
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0074",
    "Name": "SEKOLAH KEBANGSAAN SERI HARTAMAS",
    "Student": 791,
    "Teacher": 44
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0075",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BARU SERI PETALING 2",
    "Student": 453,
    "Teacher": 28
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0076",
    "Name": "SEKOLAH KEBANGSAAN DESA TASIK",
    "Student": 1096,
    "Teacher": 77
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0077",
    "Name": "SEKOLAH KEBANGSAAN SERI NILAM",
    "Student": 853,
    "Teacher": 60
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0078",
    "Name": "SEKOLAH KEBANGSAAN SERI INDAH",
    "Student": 219,
    "Teacher": 33
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0079",
    "Name": "SEKOLAH KEBANGSAAN SERI KEPONG",
    "Student": 148,
    "Teacher": 25
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0080",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TASIK SELATAN",
    "Student": 1600,
    "Teacher": 113
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0081",
    "Name": "SEKOLAH KEBANGSAAN DESA PETALING",
    "Student": 940,
    "Teacher": 60
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0082",
    "Name": "SEKOLAH KEBANGSAAN DANAU PERDANA",
    "Student": 262,
    "Teacher": 24
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0083",
    "Name": "SEKOLAH KEBANGSAAN BUKIT JALIL",
    "Student": 1442,
    "Teacher": 88
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0084",
    "Name": "SEKOLAH KEBANGSAAN ALAM DAMAI",
    "Student": 1118,
    "Teacher": 66
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0085",
    "Name": "SEKOLAH KEBANGSAAN SERI TASIK",
    "Student": 1804,
    "Teacher": 109
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0086",
    "Name": "SEKOLAH KEBANGSAAN INTAN BAIDURI",
    "Student": 494,
    "Teacher": 32
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0087",
    "Name": "SEKOLAH KEBANGSAAN SERI SAUJANA",
    "Student": 596,
    "Teacher": 38
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0088",
    "Name": "SEKOLAH KEBANGSAAN COCHRANE",
    "Student": 991,
    "Teacher": 58
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0089",
    "Name": "SEKOLAH KEBANGSAAN KIARAMAS",
    "Student": 1007,
    "Teacher": 62
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0090",
    "Name": "SEKOLAH KEBANGSAAN TAMAN MELATI",
    "Student": 1114,
    "Teacher": 64
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0091",
    "Name": "SEKOLAH KEBANGSAAN SEGAMBUT MAKMUR",
    "Student": 461,
    "Teacher": 33
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0092",
    "Name": "SEKOLAH KEBANGSAAN SERI ANGGERIK",
    "Student": 793,
    "Teacher": 64
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0093",
    "Name": "SEKOLAH KEBANGSAAN COCHRANE PERKASA",
    "Student": 618,
    "Teacher": 46
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0094",
    "Name": "SEKOLAH KEBANGSAAN SENTUL UTAMA",
    "Student": 461,
    "Teacher": 42
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0095",
    "Name": "SEKOLAH KEBANGSAAN BATU MUDA",
    "Student": 890,
    "Teacher": 56
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0096",
    "Name": "SEKOLAH KEBANGSAAN DANAU KOTA 2",
    "Student": 874,
    "Teacher": 54
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBA0097",
    "Name": "SEKOLAH KEBANGSAAN TIARA PERMAI",
    "Student": 550,
    "Teacher": 35
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBA0098",
    "Name": "SEKOLAH KEBANGSAAN SERI PERMAISURI",
    "Student": 926,
    "Teacher": 63
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0099",
    "Name": "SEKOLAH KEBANGSAAN KEMENTAH",
    "Student": 1077,
    "Teacher": 61
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBA0100",
    "Name": "SEKOLAH KEBANGSAAN SERI BONUS",
    "Student": 611,
    "Teacher": 39
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1001",
    "Name": "SEKOLAH KEBANGSAAN BEBULOH",
    "Student": 495,
    "Teacher": 29
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1002",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BEDAUN",
    "Student": 628,
    "Teacher": 41
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1003",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KALLAM",
    "Student": 690,
    "Teacher": 55
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1007",
    "Name": "SEKOLAH KEBANGSAAN KERUPANG",
    "Student": 582,
    "Teacher": 37
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1008",
    "Name": "SEKOLAH KEBANGSAAN PEKAN 1 WP LABUAN",
    "Student": 1056,
    "Teacher": 74
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1010",
    "Name": "SEKOLAH KEBANGSAAN PEKAN II WP LABUAN",
    "Student": 766,
    "Teacher": 51
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1012",
    "Name": "SEKOLAH KEBANGSAAN LAYANG-LAYANGAN",
    "Student": 410,
    "Teacher": 31
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1013",
    "Name": "SEKOLAH KEBANGSAAN LUBOK TEMIANG",
    "Student": 583,
    "Teacher": 39
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1016",
    "Name": "SEKOLAH KEBANGSAAN PATAU-PATAU",
    "Student": 472,
    "Teacher": 34
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1017",
    "Name": "SEKOLAH KEBANGSAAN RANCHA-RANCHA",
    "Student": 446,
    "Teacher": 36
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1023",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LADA",
    "Student": 477,
    "Teacher": 36
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1024",
    "Name": "SEKOLAH KEBANGSAAN MEMBEDAI",
    "Student": 279,
    "Teacher": 27
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1025",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG ARU",
    "Student": 513,
    "Teacher": 36
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WBA1026",
    "Name": "SEKOLAH KEBANGSAAN PANTAI",
    "Student": 695,
    "Teacher": 56
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2001",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 9(1)",
    "Student": 1287,
    "Teacher": 78
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2002",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 8(1)",
    "Student": 1179,
    "Teacher": 76
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2003",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 16(1)",
    "Student": 1211,
    "Teacher": 74
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2004",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 11(1)",
    "Student": 1319,
    "Teacher": 78
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2005",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 8(2)",
    "Student": 1550,
    "Teacher": 114
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2006",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 9 (2)",
    "Student": 1861,
    "Teacher": 131
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2007",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 16 (2)",
    "Student": 1635,
    "Teacher": 109
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2008",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 11 (3)",
    "Student": 1449,
    "Teacher": 113
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2009",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 14(1)",
    "Student": 2090,
    "Teacher": 141
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2010",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 11(2)",
    "Student": 1702,
    "Teacher": 109
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2011",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 18(1)",
    "Student": 2255,
    "Teacher": 170
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2012",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 18(2)",
    "Student": 791,
    "Teacher": 55
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2013",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 5(1)",
    "Student": 1983,
    "Teacher": 128
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2014",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 17(1)",
    "Student": 1200,
    "Teacher": 72
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2015",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 8 (3)",
    "Student": 436,
    "Teacher": 30
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WBA2016",
    "Name": "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 15 (1)",
    "Student": 555,
    "Teacher": 37
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBB0038",
    "Name": "SEKOLAH KEBANGSAAN JALAN AIR PANAS",
    "Student": 661,
    "Teacher": 52
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0039",
    "Name": "SEKOLAH KEBANGSAAN (L) JALAN BATU",
    "Student": 258,
    "Teacher": 32
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0041",
    "Name": "SEKOLAH KEBANGSAAN (P) JALAN BATU",
    "Student": 224,
    "Teacher": 26
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0043",
    "Name": "SEKOLAH KEBANGSAAN JALAN BELLAMY",
    "Student": 261,
    "Teacher": 20
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0044",
    "Name": "SEKOLAH KEBANGSAAN BRICKFIELDS 1",
    "Student": 403,
    "Teacher": 35
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0045",
    "Name": "SEKOLAH KEBANGSAAN BRICKFIELDS 2",
    "Student": 236,
    "Teacher": 26
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0046",
    "Name": "SEKOLAH KEBANGSAAN SERI BINTANG UTARA",
    "Student": 1003,
    "Teacher": 61
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0047",
    "Name": "SEKOLAH KEBANGSAAN SERI BINTANG SELATAN",
    "Student": 1084,
    "Teacher": 62
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBB0048",
    "Name": "SEKOLAH KEBANGSAAN CONVENT (1) BUKIT NANAS",
    "Student": 505,
    "Teacher": 28
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBB0049",
    "Name": "SEKOLAH KEBANGSAAN CONVENT (2) BUKIT NANAS",
    "Student": 472,
    "Teacher": 28
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0050",
    "Name": "SEKOLAH KEBANGSAAN ST. TERESA BRICKFIELDS 1",
    "Student": 82,
    "Teacher": 15
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0052",
    "Name": "SEKOLAH KEBANGSAAN CONVENT JLN PEEL",
    "Student": 592,
    "Teacher": 37
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0053",
    "Name": "SEKOLAH KEBANGSAAN CONVENT SENTUL 1",
    "Student": 369,
    "Teacher": 34
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0054",
    "Name": "SEKOLAH KEBANGSAAN CONVENT SENTUL 2",
    "Student": 293,
    "Teacher": 28
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBB0055",
    "Name": "SEKOLAH KEBANGSAAN MARIAN CONVENT SETAPAK",
    "Student": 292,
    "Teacher": 24
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0056",
    "Name": "SEKOLAH KEBANGSAAN DATO ABU BAKAR",
    "Student": 147,
    "Teacher": 30
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBB0058",
    "Name": "SEKOLAH KEBANGSAAN JALAN GURNEY 1",
    "Student": 542,
    "Teacher": 40
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBB0059",
    "Name": "SEKOLAH KEBANGSAAN JALAN GURNEY 2",
    "Student": 278,
    "Teacher": 26
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0060",
    "Name": "SEKOLAH KEBANGSAAN (P) METHODIST (1) BRICKFIELDS",
    "Student": 119,
    "Teacher": 19
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBB0062",
    "Name": "SEKOLAH KEBANGSAAN JALAN KUANTAN 2",
    "Student": 703,
    "Teacher": 42
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0063",
    "Name": "SEKOLAH KEBANGSAAN PUTERI PANDAN 1",
    "Student": 363,
    "Teacher": 28
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0064",
    "Name": "SEKOLAH KEBANGSAAN PUTERI PANDAN 2",
    "Student": 153,
    "Teacher": 16
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0065",
    "Name": "SEKOLAH KEBANGSAAN LA SALLE (1) BRICKFIELDS",
    "Student": 100,
    "Teacher": 16
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0067",
    "Name": "SEKOLAH KEBANGSAAN YAACOB LATIF 1",
    "Student": 398,
    "Teacher": 44
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0069",
    "Name": "SEKOLAH KEBANGSAAN LA SALLE (1) SENTUL",
    "Student": 322,
    "Teacher": 26
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0071",
    "Name": "SEKOLAH KEBANGSAAN METHODIST (L) JLN HANG JEBAT",
    "Student": 295,
    "Teacher": 25
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0072",
    "Name": "SEKOLAH KEBANGSAAN (L) METHODIST SENTUL",
    "Student": 41,
    "Teacher": 9
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBB0073",
    "Name": "SEKOLAH KEBANGSAAN PADANG TEMBAK 1",
    "Student": 549,
    "Teacher": 34
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBB0074",
    "Name": "SEKOLAH KEBANGSAAN PADANG TEMBAK 2",
    "Student": 222,
    "Teacher": 15
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0077",
    "Name": "SEKOLAH KEBANGSAAN JALAN PEEL",
    "Student": 247,
    "Teacher": 27
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0078",
    "Name": "SEKOLAH KEBANGSAAN (P) PUDU 1",
    "Student": 122,
    "Teacher": 16
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0082",
    "Name": "SEKOLAH KEBANGSAAN ST. GABRIEL",
    "Student": 216,
    "Teacher": 17
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBB0083",
    "Name": "SEKOLAH KEBANGSAAN ST. JOHN 1",
    "Student": 824,
    "Teacher": 48
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0085",
    "Name": "SEKOLAH KEBANGSAAN ST. MARY",
    "Student": 939,
    "Teacher": 56
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0086",
    "Name": "SEKOLAH KEBANGSAAN JALAN HANG TUAH 1",
    "Student": 427,
    "Teacher": 29
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0089",
    "Name": "SEKOLAH KEBANGSAAN JALAN SUNGEI BESI 1",
    "Student": 182,
    "Teacher": 18
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0090",
    "Name": "SEKOLAH KEBANGSAAN JALAN SUNGAI BESI 2",
    "Student": 127,
    "Teacher": 16
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0097",
    "Name": "SEKOLAH KEBANGSAAN (1) BATU 4 JALAN IPOH",
    "Student": 354,
    "Teacher": 37
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0098",
    "Name": "SEKOLAH KEBANGSAAN SERI DELIMA",
    "Student": 1016,
    "Teacher": 67
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0101",
    "Name": "SEKOLAH KEBANGSAAN SERI SETIA",
    "Student": 616,
    "Teacher": 43
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0103",
    "Name": "SEKOLAH KEBANGSAAN LA SALLE 1 JINJANG",
    "Student": 387,
    "Teacher": 24
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBB0107",
    "Name": "SEKOLAH KEBANGSAAN SALAK SOUTH",
    "Student": 395,
    "Teacher": 36
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0113",
    "Name": "SEKOLAH KEBANGSAAN KEPONG BARU",
    "Student": 310,
    "Teacher": 27
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBB0217",
    "Name": "SEKOLAH KEBANGSAAN LA SALLE 2 JINJANG",
    "Student": 374,
    "Teacher": 27
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0113",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI MAN",
    "Student": 308,
    "Teacher": 25
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0114",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAO NAN",
    "Student": 252,
    "Teacher": 21
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0115",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN WOO",
    "Student": 100,
    "Teacher": 15
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0116",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA",
    "Student": 594,
    "Teacher": 31
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBC0117",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 'P'",
    "Student": 901,
    "Teacher": 52
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0118",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG KWO",
    "Student": 486,
    "Teacher": 36
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0119",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG KWOK",
    "Student": 888,
    "Teacher": 52
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0120",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CONFUCIAN",
    "Student": 546,
    "Teacher": 33
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0121",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JALAN DAVIDSON",
    "Student": 1165,
    "Teacher": 68
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0122",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) IMBI",
    "Student": 1293,
    "Teacher": 52
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0123",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI CHEE",
    "Student": 1945,
    "Teacher": 88
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0124",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG MIN",
    "Student": 288,
    "Teacher": 26
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0125",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUEN CHENG 1",
    "Student": 1198,
    "Teacher": 58
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0126",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LA SALLE",
    "Student": 1814,
    "Teacher": 73
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0127",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LAI CHEE",
    "Student": 759,
    "Teacher": 45
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0128",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LAI MENG",
    "Student": 2092,
    "Teacher": 83
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBC0130",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MUN YEE",
    "Student": 1487,
    "Teacher": 72
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0131",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAAM KHEUNG",
    "Student": 1847,
    "Teacher": 90
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBC0132",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAN KAI",
    "Student": 131,
    "Teacher": 14
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0133",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG FAH PHIT CHEE",
    "Student": 122,
    "Teacher": 16
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0135",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAM YOKE",
    "Student": 204,
    "Teacher": 27
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0136",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SENTUL",
    "Student": 557,
    "Teacher": 32
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0137",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SENTUL PASAR",
    "Student": 249,
    "Teacher": 21
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0138",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST. TERESA BRICKFIELDS",
    "Student": 142,
    "Teacher": 15
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0139",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAI THUNG",
    "Student": 661,
    "Teacher": 40
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0140",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TSUN JIN",
    "Student": 1683,
    "Teacher": 84
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0141",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE NAM",
    "Student": 1906,
    "Teacher": 78
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0144",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG WEN",
    "Student": 2042,
    "Teacher": 79
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0146",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JINJANG TENGAH 1",
    "Student": 344,
    "Teacher": 26
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0147",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JINJANG UTARA",
    "Student": 1049,
    "Teacher": 69
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0148",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JINJANG SELATAN",
    "Student": 778,
    "Teacher": 48
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0149",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEPONG 1",
    "Student": 1784,
    "Teacher": 74
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0150",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEPONG 2",
    "Student": 1882,
    "Teacher": 74
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0153",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KWONG HON",
    "Student": 591,
    "Teacher": 44
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0154",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MUN CHOONG",
    "Student": 1665,
    "Teacher": 82
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBC0155",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAN YIK 'LEE RUBBER'",
    "Student": 1505,
    "Teacher": 77
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0158",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SALAK SOUTH",
    "Student": 963,
    "Teacher": 64
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0168",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) JINJANG TENGAH 2",
    "Student": 214,
    "Teacher": 16
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0170",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUEN CHENG 2",
    "Student": 1133,
    "Teacher": 68
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBC0171",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAMAN CONNAUGHT",
    "Student": 1913,
    "Teacher": 90
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBC0172",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEPONG 3",
    "Student": 1754,
    "Teacher": 65
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBC0173",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WANGSA MAJU",
    "Student": 1384,
    "Teacher": 60
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBD0168",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) APPAR",
    "Student": 103,
    "Teacher": 12
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBD0169",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN BANGSAR",
    "Student": 78,
    "Teacher": 12
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBD0170",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) CHERAS",
    "Student": 258,
    "Teacher": 21
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WBD0171",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) FLETCHER",
    "Student": 308,
    "Teacher": 33
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBD0172",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) KG PANDAN",
    "Student": 220,
    "Teacher": 18
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBD0174",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) JLN SAN PENG",
    "Student": 96,
    "Teacher": 14
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBD0175",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SENTUL",
    "Student": 271,
    "Teacher": 30
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBD0176",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) ST. JOSEPH",
    "Student": 174,
    "Teacher": 16
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBD0177",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) THAMBOOSAMY PILLAI",
    "Student": 244,
    "Teacher": 26
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBD0178",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) VIVEKANANDA",
    "Student": 353,
    "Teacher": 26
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBD0181",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT JALIL",
    "Student": 201,
    "Teacher": 16
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBD0184",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG EDINBURGH",
    "Student": 380,
    "Teacher": 25
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBD0191",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SARASWATHY",
    "Student": 386,
    "Teacher": 26
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WBD0192",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SEGAMBUT",
    "Student": 360,
    "Teacher": 27
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WBD0193",
    "Name": "SEKOLAH JENIS KEBANGSAAN (TAMIL) SG BESI",
    "Student": 129,
    "Teacher": 14
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WCA1020",
    "Name": "SEKOLAH KEBANGSAAN ST ANNE'S CONVENT",
    "Student": 434,
    "Teacher": 29
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WCC1005",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI WEN",
    "Student": 877,
    "Teacher": 58
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WCC1006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 522,
    "Teacher": 36
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0194",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN BUKIT MALURI",
    "Student": 1683,
    "Teacher": 104
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0195",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI BESI",
    "Student": 1378,
    "Teacher": 112
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0196",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AMINUDDIN BAKI",
    "Student": 966,
    "Teacher": 72
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0197",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTERI WILAYAH",
    "Student": 520,
    "Teacher": 55
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0198",
    "Name": "KOLEJ TINGKATAN ENAM PUDU JAYA",
    "Student": 165,
    "Teacher": 63
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0199",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG TEMBAK",
    "Student": 1314,
    "Teacher": 103
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PETALING",
    "Student": 1034,
    "Teacher": 77
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA ALI",
    "Student": 613,
    "Teacher": 64
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0206",
    "Name": "SEKOLAH MENENGAH SAINS SELANGOR",
    "Student": 629,
    "Teacher": 53
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0208",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEPONG BARU",
    "Student": 2248,
    "Teacher": 126
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0210",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN TUN DR. ISMAIL",
    "Student": 1118,
    "Teacher": 93
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0211",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENDIDIKAN KHAS SETAPAK",
    "Student": 106,
    "Teacher": 45
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0212",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA",
    "Student": 968,
    "Teacher": 95
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0213",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT BANDARAYA",
    "Student": 911,
    "Teacher": 68
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0214",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN WANGSA MAJU SEKSYEN 2",
    "Student": 684,
    "Teacher": 61
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0215",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ZON R1 WANGSA MAJU",
    "Student": 1037,
    "Teacher": 76
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0216",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN CONNAUGHT",
    "Student": 1543,
    "Teacher": 116
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0217",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SENTUL",
    "Student": 1308,
    "Teacher": 122
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0218",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SERI PETALING",
    "Student": 844,
    "Teacher": 70
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0219",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN YARL",
    "Student": 713,
    "Teacher": 61
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0220",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI HARTAMAS",
    "Student": 1109,
    "Teacher": 69
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0221",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SERI RAMPAI",
    "Student": 780,
    "Teacher": 72
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0222",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR TUN RAZAK",
    "Student": 636,
    "Teacher": 53
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0223",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DESA PERDANA",
    "Student": 970,
    "Teacher": 88
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0224",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN MELATI",
    "Student": 872,
    "Teacher": 64
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0225",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 5 WANGSA MAJU",
    "Student": 1435,
    "Teacher": 117
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0226",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DESA TUN HUSSEIN ONN",
    "Student": 956,
    "Teacher": 90
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0227",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MENJALARA",
    "Student": 1176,
    "Teacher": 98
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0228",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEGAMBUT JAYA",
    "Student": 596,
    "Teacher": 64
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0229",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN WANGSA MELAWATI",
    "Student": 823,
    "Teacher": 67
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0230",
    "Name": "SEKOLAH SUKAN BUKIT JALIL",
    "Student": 341,
    "Teacher": 103
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0231",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' IBRAHIM YAACOB",
    "Student": 999,
    "Teacher": 104
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0232",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN SETIAWANGSA",
    "Student": 1275,
    "Teacher": 116
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0233",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR TASIK SELATAN",
    "Student": 1503,
    "Teacher": 122
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0234",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU MUDA",
    "Student": 1414,
    "Teacher": 104
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0235",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SETAPAK INDAH",
    "Student": 996,
    "Teacher": 75
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0236",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SINAR BINTANG",
    "Student": 1070,
    "Teacher": 116
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0237",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI SAUJANA",
    "Student": 1419,
    "Teacher": 102
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0238",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MIHARJA",
    "Student": 674,
    "Teacher": 68
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0239",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT JALIL",
    "Student": 1062,
    "Teacher": 69
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0240",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DESA PETALING",
    "Student": 981,
    "Teacher": 99
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0241",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BINTANG SELATAN",
    "Student": 960,
    "Teacher": 84
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0242",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DANAU KOTA",
    "Student": 1483,
    "Teacher": 125
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0243",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEGAMBUT",
    "Student": 664,
    "Teacher": 67
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0244",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI MULIA",
    "Student": 536,
    "Teacher": 54
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0245",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI TASIK",
    "Student": 976,
    "Teacher": 78
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0246",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PERMAISURI",
    "Student": 1180,
    "Teacher": 90
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0247",
    "Name": "SEKOLAH MENENGAH SAINS ALAM SHAH",
    "Student": 581,
    "Teacher": 50
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0248",
    "Name": "SEKOLAH MENENGAH SAINS SERI PUTERI",
    "Student": 448,
    "Teacher": 42
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0249",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KIARAMAS",
    "Student": 749,
    "Teacher": 60
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0250",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI MUTIARA",
    "Student": 972,
    "Teacher": 79
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0251",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN COCHRANE PERKASA",
    "Student": 712,
    "Teacher": 76
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0252",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALAM DAMAI",
    "Student": 963,
    "Teacher": 78
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0253",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SENTUL UTAMA",
    "Student": 848,
    "Teacher": 75
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0255",
    "Name": "SEKOLAH BIMBINGAN JALINAN KASIH",
    "Student": 127,
    "Teacher": 14
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0256",
    "Name": "KOLEJ TINGKATAN ENAM DESA MAHKOTA",
    "Student": 268,
    "Teacher": 72
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0257",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ORKID DESA",
    "Student": 724,
    "Teacher": 62
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEA0258",
    "Name": "SEKOLAH SENI MALAYSIA KUALA LUMPUR",
    "Student": 236,
    "Teacher": 36
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEA0259",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KERAMAT WANGSA",
    "Student": 618,
    "Teacher": 47
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEA0260",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TIARA PERMAI",
    "Student": 814,
    "Teacher": 68
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WEA1001",
    "Name": "SEKOLAH MENENGAH SAINS LABUAN",
    "Student": 460,
    "Teacher": 48
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WEA1002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LAJAU",
    "Student": 640,
    "Teacher": 57
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WEA1003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN PERUMAHAN BEDAUN",
    "Student": 535,
    "Teacher": 40
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WEA1004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PANTAI",
    "Student": 1053,
    "Teacher": 78
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WEA1005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RANCHA-RANCHA",
    "Student": 486,
    "Teacher": 42
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WEA2001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 8(1)",
    "Student": 1156,
    "Teacher": 87
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WEA2002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 9(1)",
    "Student": 777,
    "Teacher": 59
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WEA2003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 16(1)",
    "Student": 1152,
    "Teacher": 79
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WEA2004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 11(1)",
    "Student": 1261,
    "Teacher": 93
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WEA2005",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 9(2)",
    "Student": 1180,
    "Teacher": 90
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WEA2006",
    "Name": "SEKOLAH SULTAN ALAM SHAH",
    "Student": 888,
    "Teacher": 71
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WEA2007",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 14(1)",
    "Student": 1346,
    "Teacher": 84
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WEA2008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 11(2)",
    "Student": 884,
    "Teacher": 63
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WEA2009",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 18(1)",
    "Student": 1257,
    "Teacher": 86
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WEA2010",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 5(1)",
    "Student": 1040,
    "Teacher": 102
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEB0206",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI AMPANG",
    "Student": 435,
    "Teacher": 54
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEB0207",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTERI AMPANG",
    "Student": 488,
    "Teacher": 54
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEB0208",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) AIR PANAS",
    "Student": 661,
    "Teacher": 71
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0209",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI BINTANG UTARA",
    "Student": 994,
    "Teacher": 82
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0210",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHERAS",
    "Student": 1081,
    "Teacher": 95
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0211",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN COCHRANE",
    "Student": 951,
    "Teacher": 70
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0212",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONFUCIAN",
    "Student": 940,
    "Teacher": 71
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEB0213",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT BUKIT NANAS",
    "Student": 869,
    "Teacher": 63
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0214",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT JALAN PEEL",
    "Student": 773,
    "Teacher": 68
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEB0215",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CONVENT SENTUL",
    "Student": 768,
    "Teacher": 56
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEB0216",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI SETAPAK",
    "Student": 732,
    "Teacher": 120
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0217",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) BANDARAYA",
    "Student": 146,
    "Teacher": 27
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEB0218",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAXWELL",
    "Student": 456,
    "Teacher": 89
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0219",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (L) METHODIST",
    "Student": 885,
    "Teacher": 100
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEB0220",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (L) METHODIST SENTUL",
    "Student": 405,
    "Teacher": 40
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0221",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) PUDU",
    "Student": 554,
    "Teacher": 62
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0222",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. GABRIEL",
    "Student": 347,
    "Teacher": 44
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEB0223",
    "Name": "ST. JOHN'S INSTITUTION",
    "Student": 812,
    "Teacher": 95
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEB0224",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. MARY",
    "Student": 1010,
    "Teacher": 65
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0225",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) METHODIST",
    "Student": 477,
    "Teacher": 43
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0226",
    "Name": "VICTORIA INSTITUTION",
    "Student": 908,
    "Teacher": 118
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0227",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATOK LOKMAN",
    "Student": 1105,
    "Teacher": 80
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEB0228",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (P) JALAN IPOH",
    "Student": 662,
    "Teacher": 72
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0229",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PANTAI",
    "Student": 1059,
    "Teacher": 77
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0230",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI SENTOSA",
    "Student": 1793,
    "Teacher": 147
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0231",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO' ONN",
    "Student": 571,
    "Teacher": 62
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0232",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN MALURI",
    "Student": 893,
    "Teacher": 63
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEB0233",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI TITIWANGSA",
    "Student": 344,
    "Teacher": 47
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WEB0234",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTERI TITIWANGSA",
    "Student": 671,
    "Teacher": 57
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0235",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN VIVEKANANDA",
    "Student": 779,
    "Teacher": 81
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0236",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LA SALLE BRICKFIELDS",
    "Student": 119,
    "Teacher": 22
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0237",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN YAACOB LATIF",
    "Student": 441,
    "Teacher": 63
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEB0238",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LA SALLE SENTUL",
    "Student": 431,
    "Teacher": 40
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0243",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DHARMA",
    "Student": 147,
    "Teacher": 17
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEB0247",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHONG HWA",
    "Student": 2124,
    "Teacher": 120
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEB0248",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RAJA ABDULLAH",
    "Student": 1203,
    "Teacher": 93
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WEB0249",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JINJANG",
    "Student": 1843,
    "Teacher": 121
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WEB0256",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANGSAR",
    "Student": 696,
    "Teacher": 65
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WEE1011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LABUAN",
    "Student": 1568,
    "Teacher": 129
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WEE1015",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUTIARA",
    "Student": 955,
    "Teacher": 83
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WFE1019",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST ANNE",
    "Student": 340,
    "Teacher": 31
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WFE1022",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST ANTHONY",
    "Student": 375,
    "Teacher": 28
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WFT0001",
    "Name": "SMA MAJLIS AGAMA ISLAM WILAYAH PERSEKUTUAN",
    "Student": 665,
    "Teacher": 66
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WFT0002",
    "Name": "SEKOLAH MENENGAH INTEGRASI SAINS TAHFIZ (SMISTA) MAIWP",
    "Student": 386,
    "Teacher": 69
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WFT1001",
    "Name": "SMA MAJLIS AGAMA ISLAM WILAYAH PERSEKUTUAN LABUAN",
    "Student": 241,
    "Teacher": 24
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WHA0001",
    "Name": "KOLEJ VOKASIONAL ERT SETAPAK",
    "Student": 518,
    "Teacher": 90
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW KERAMAT",
    "Kod": "WHA0002",
    "Name": "KOLEJ VOKASIONAL SETAPAK",
    "Student": 791,
    "Teacher": 126
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN LABUAN",
    "PPD": "JPWP LABUAN",
    "Kod": "WHA1099",
    "Name": "KOLEJ VOKASIONAL LABUAN",
    "Student": 449,
    "Teacher": 85
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW BANGSAR PUDU",
    "Kod": "WKB0001",
    "Name": "SMT CHERAS",
    "Student": 645,
    "Teacher": 57
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "PPD": "PPW SENTUL",
    "Kod": "WRA0004",
    "Name": "SMA KUALA LUMPUR",
    "Student": 688,
    "Teacher": 117
  },
  {
    "NationalState": "WILAYAH PERSEKUTUAN PUTRAJAYA",
    "PPD": "JPWP PUTRAJAYA",
    "Kod": "WRA2001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA PUTRAJAYA",
    "Student": 634,
    "Teacher": 113
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1001",
    "Name": "SEKOLAH KEBANGSAAN AMBUAL",
    "Student": 140,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1002",
    "Name": "SEKOLAH KEBANGSAAN APIN-APIN",
    "Student": 196,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1003",
    "Name": "SEKOLAH KEBANGSAAN BANJAR",
    "Student": 516,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1004",
    "Name": "SEKOLAH KEBANGSAAN BATU LUNGUYAN",
    "Student": 94,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1005",
    "Name": "SEKOLAH KEBANGSAAN RANCANGAN BIAH",
    "Student": 190,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1006",
    "Name": "SEKOLAH KEBANGSAAN BINAONG",
    "Student": 301,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1007",
    "Name": "SEKOLAH KEBANGSAAN BINGKOR",
    "Student": 434,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1008",
    "Name": "SEKOLAH KEBANGSAAN BONOR",
    "Student": 66,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1009",
    "Name": "SEKOLAH KEBANGSAAN BULU SILOU",
    "Student": 280,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1010",
    "Name": "SEKOLAH KEBANGSAAN BUNDU APIN-APIN",
    "Student": 125,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1011",
    "Name": "SEKOLAH KEBANGSAAN BUNSIT",
    "Student": 211,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1012",
    "Name": "SEKOLAH KEBANGSAAN KAWAKAAN",
    "Student": 135,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1013",
    "Name": "SEKOLAH KEBANGSAAN DALIT",
    "Student": 155,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1014",
    "Name": "SEKOLAH KEBANGSAAN DELAYAN TULID",
    "Student": 143,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1015",
    "Name": "SEKOLAH KEBANGSAAN KABATANG BARU",
    "Student": 167,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1016",
    "Name": "SEKOLAH KEBANGSAAN KALAMPUN",
    "Student": 160,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1017",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BARU",
    "Student": 214,
    "Teacher": 23
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1018",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG BIAH",
    "Student": 406,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1019",
    "Name": "SEKOLAH KEBANGSAAN KG KENINGAU",
    "Student": 440,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1020",
    "Name": "SEKOLAH KEBANGSAAN KARAMATOI",
    "Student": 185,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1021",
    "Name": "SEKOLAH KEBANGSAAN JAYA BARU",
    "Student": 296,
    "Teacher": 32
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1022",
    "Name": "SEKOLAH KEBANGSAAN PEKAN KENINGAU",
    "Student": 761,
    "Teacher": 60
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1023",
    "Name": "SEKOLAH KEBANGSAAN INANDUNG",
    "Student": 97,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1024",
    "Name": "SEKOLAH KEBANGSAAN KG BERIAWA ULU",
    "Student": 110,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1025",
    "Name": "SEKOLAH KEBANGSAAN KAPAYAN BARU",
    "Student": 146,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1026",
    "Name": "SEKOLAH KEBANGSAAN LANAS",
    "Student": 159,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1027",
    "Name": "SEKOLAH KEBANGSAAN LIAU APIN-APIN",
    "Student": 194,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1028",
    "Name": "SEKOLAH KEBANGSAAN LUAGAN",
    "Student": 755,
    "Teacher": 57
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1029",
    "Name": "SEKOLAH KEBANGSAAN MAGATANG",
    "Student": 200,
    "Teacher": 24
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1030",
    "Name": "SEKOLAH KEBANGSAAN MANSIAT",
    "Student": 119,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1031",
    "Name": "SEKOLAH KEBANGSAAN MEMBULU",
    "Student": 216,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1032",
    "Name": "SEKOLAH KEBANGSAAN MENAWO",
    "Student": 316,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1033",
    "Name": "SEKOLAH KEBANGSAAN MERAMPONG",
    "Student": 342,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1034",
    "Name": "SEKOLAH KEBANGSAAN MAMAGUN",
    "Student": 35,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1035",
    "Name": "SEKOLAH KEBANGSAAN PASIR PUTEH",
    "Student": 776,
    "Teacher": 67
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1036",
    "Name": "SEKOLAH KEBANGSAAN POHON BATU",
    "Student": 32,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1037",
    "Name": "SEKOLAH KEBANGSAAN KUALA KAHABA",
    "Student": 75,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1038",
    "Name": "SEKOLAH KEBANGSAAN SENAGANG",
    "Student": 127,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1039",
    "Name": "SEKOLAH KEBANGSAAN SINARON TENGAH",
    "Student": 114,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1040",
    "Name": "SEKOLAH KEBANGSAAN SODOMON",
    "Student": 167,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1041",
    "Name": "SEKOLAH KEBANGSAAN SOOK",
    "Student": 549,
    "Teacher": 49
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1042",
    "Name": "SEKOLAH KEBANGSAAN KEBULU",
    "Student": 157,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1047",
    "Name": "SEKOLAH KEBANGSAAN TUARID TAUD",
    "Student": 419,
    "Teacher": 42
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1048",
    "Name": "SEKOLAH KEBANGSAAN TULID",
    "Student": 127,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1051",
    "Name": "SEKOLAH KEBANGSAAN ULU LIAWAN",
    "Student": 74,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1052",
    "Name": "SEKOLAH KEBANGSAAN ANSIP",
    "Student": 236,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1054",
    "Name": "SEKOLAH KEBANGSAAN MALIMA",
    "Student": 141,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1056",
    "Name": "SEKOLAH KEBANGSAAN PENAGATAN",
    "Student": 246,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1058",
    "Name": "SEKOLAH KEBANGSAAN SINUA",
    "Student": 109,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1059",
    "Name": "SEKOLAH KEBANGSAAN MENINIPIR",
    "Student": 166,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1060",
    "Name": "SEKOLAH KEBANGSAAN MALAING",
    "Student": 36,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1061",
    "Name": "SEKOLAH KEBANGSAAN BUNGA RAYA",
    "Student": 306,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1062",
    "Name": "SEKOLAH KEBANGSAAN SINULIHAN BARU",
    "Student": 196,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1063",
    "Name": "SEKOLAH KEBANGSAAN SIMBUAN TULID",
    "Student": 131,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1064",
    "Name": "SEKOLAH KEBANGSAAN ULU SENAGANG",
    "Student": 114,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1065",
    "Name": "SEKOLAH KEBANGSAAN GAULAN",
    "Student": 104,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1066",
    "Name": "SEKOLAH KEBANGSAAN PATIKANG LAUT KENINGAU",
    "Student": 210,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1067",
    "Name": "SEKOLAH KEBANGSAAN BINUWOU TENGAH",
    "Student": 90,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1068",
    "Name": "SEKOLAH KEBANGSAAN RANCANGAN BELIA TIULON",
    "Student": 143,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1069",
    "Name": "SEKOLAH KEBANGSAAN BINAKAAN",
    "Student": 127,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1070",
    "Name": "SEKOLAH KEBANGSAAN BUNANG SOOK",
    "Student": 127,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1071",
    "Name": "SEKOLAH KEBANGSAAN LINTUHUN BARU",
    "Student": 99,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1072",
    "Name": "SEKOLAH KEBANGSAAN NANGKAWANGAN",
    "Student": 26,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1073",
    "Name": "SEKOLAH KEBANGSAAN NANDAGAN",
    "Student": 116,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1074",
    "Name": "SEKOLAH KEBANGSAAN BINANON",
    "Student": 44,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1075",
    "Name": "SEKOLAH KEBANGSAAN PANGAS",
    "Student": 26,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBA1076",
    "Name": "SEKOLAH KEBANGSAAN PEKAN KENINGAU II",
    "Student": 292,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1101",
    "Name": "SEKOLAH KEBANGSAAN SIBANGALI",
    "Student": 62,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1102",
    "Name": "SEKOLAH KEBANGSAAN LOGONGON",
    "Student": 154,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1103",
    "Name": "SEKOLAH KEBANGSAAN PANDIWAN",
    "Student": 125,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1104",
    "Name": "SEKOLAH KEBANGSAAN PEKAN PENSIANGAN",
    "Student": 192,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1105",
    "Name": "SEKOLAH KEBANGSAAN PENONTOMON",
    "Student": 54,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1106",
    "Name": "SEKOLAH KEBANGSAAN SALAROM",
    "Student": 192,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1107",
    "Name": "SEKOLAH KEBANGSAAN SALILARAN",
    "Student": 19,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1108",
    "Name": "SEKOLAH KEBANGSAAN SEPULOT",
    "Student": 203,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1109",
    "Name": "SEKOLAH KEBANGSAAN KUALA SALONG",
    "Student": 147,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1110",
    "Name": "SEKOLAH KEBANGSAAN KG BAHAGIA",
    "Student": 361,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1111",
    "Name": "SEKOLAH KEBANGSAAN LOTONG",
    "Student": 173,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1112",
    "Name": "SEKOLAH KEBANGSAAN PEKAN NABAWAN",
    "Student": 812,
    "Teacher": 62
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1113",
    "Name": "SEKOLAH KEBANGSAAN PEMENTERIAN",
    "Student": 89,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1114",
    "Name": "SEKOLAH KEBANGSAAN LABANG",
    "Student": 91,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1115",
    "Name": "SEKOLAH KEBANGSAAN ULU MOSOPOH",
    "Student": 59,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1116",
    "Name": "SEKOLAH KEBANGSAAN TAMPUSISON",
    "Student": 124,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1117",
    "Name": "SEKOLAH KEBANGSAAN KEBU BARU",
    "Student": 177,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1118",
    "Name": "SEKOLAH KEBANGSAAN TETAGAS",
    "Student": 370,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1119",
    "Name": "SEKOLAH KEBANGSAAN KG ENAM",
    "Student": 174,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1120",
    "Name": "SEKOLAH KEBANGSAAN BABALITAN",
    "Student": 100,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1121",
    "Name": "SEKOLAH KEBANGSAAN LAYON",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1122",
    "Name": "SEKOLAH KEBANGSAAN SALINATAN",
    "Student": 52,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1123",
    "Name": "SEKOLAH KEBANGSAAN PENGARAAN",
    "Student": 40,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1124",
    "Name": "SEKOLAH KEBANGSAAN SALIKU",
    "Student": 40,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1125",
    "Name": "SEKOLAH KEBANGSAAN TINADUK",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1126",
    "Name": "SEKOLAH KEBANGSAAN SASANDUKON",
    "Student": 28,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XBA1127",
    "Name": "SEKOLAH KEBANGSAAN SIMATUOH",
    "Student": 70,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1201",
    "Name": "SEKOLAH KEBANGSAAN GERAS",
    "Student": 57,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1202",
    "Name": "SEKOLAH KEBANGSAAN KAINGARAN",
    "Student": 130,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1203",
    "Name": "SEKOLAH KEBANGSAAN KEROKOT",
    "Student": 52,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1204",
    "Name": "SEKOLAH KEBANGSAAN KIAWAYAN",
    "Student": 218,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1205",
    "Name": "SEKOLAH KEBANGSAAN KUMAWANAN",
    "Student": 48,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1206",
    "Name": "SEKOLAH KEBANGSAAN KINABAAN",
    "Student": 138,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1207",
    "Name": "SEKOLAH KEBANGSAAN KUALA NAMADAN TAMBUNAN",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1208",
    "Name": "SEKOLAH KEBANGSAAN MONSOK TENGAH",
    "Student": 42,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1209",
    "Name": "SEKOLAH KEBANGSAAN NUKAKATAN",
    "Student": 23,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1210",
    "Name": "SEKOLAH KEBANGSAAN PATAU",
    "Student": 162,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1211",
    "Name": "SEKOLAH KEBANGSAAN PEGALAN KUSOB",
    "Student": 57,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1212",
    "Name": "SEKOLAH KEBANGSAAN ROMPON",
    "Student": 104,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1213",
    "Name": "SEKOLAH KEBANGSAAN BAMBANGAN",
    "Student": 46,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1214",
    "Name": "SEKOLAH KEBANGSAAN LOTONG",
    "Student": 63,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1217",
    "Name": "SEKOLAH KEBANGSAAN SUNSURON",
    "Student": 241,
    "Teacher": 25
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1218",
    "Name": "SEKOLAH KEBANGSAAN PEKAN TAMBUNAN",
    "Student": 316,
    "Teacher": 49
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1220",
    "Name": "SEKOLAH KEBANGSAAN TIKOLOD",
    "Student": 121,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1221",
    "Name": "SEKOLAH KEBANGSAAN TIMBOU",
    "Student": 142,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1223",
    "Name": "SEKOLAH KEBANGSAAN KIPAKU",
    "Student": 138,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1224",
    "Name": "SEKOLAH KEBANGSAAN TIONG WIDO",
    "Student": 59,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1225",
    "Name": "SEKOLAH KEBANGSAAN TINOMPOK LIWAN",
    "Student": 36,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1226",
    "Name": "SEKOLAH KEBANGSAAN KINALUAN",
    "Student": 64,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1227",
    "Name": "SEKOLAH KEBANGSAAN SINTUONG-TUONG",
    "Student": 43,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1228",
    "Name": "SEKOLAH KEBANGSAAN RAGKAM",
    "Student": 39,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XBA1229",
    "Name": "SEKOLAH KEBANGSAAN KITOU",
    "Student": 98,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1301",
    "Name": "SEKOLAH KEBANGSAAN BAMBAN",
    "Student": 103,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1302",
    "Name": "SEKOLAH KEBANGSAAN BATU TININGKANG",
    "Student": 209,
    "Teacher": 23
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1303",
    "Name": "SEKOLAH KEBANGSAAN TILIS",
    "Student": 42,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1304",
    "Name": "SEKOLAH KEBANGSAAN KUNGKULAR",
    "Student": 80,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1307",
    "Name": "SEKOLAH KEBANGSAAN CHINTA MATA",
    "Student": 116,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1308",
    "Name": "SEKOLAH KEBANGSAAN ENTABUAN",
    "Student": 91,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1309",
    "Name": "SEKOLAH KEBANGSAAN GUMISI",
    "Student": 135,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1310",
    "Name": "SEKOLAH KEBANGSAAN INUBAI",
    "Student": 190,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1312",
    "Name": "SEKOLAH KEBANGSAAN KALANG KANAR",
    "Student": 95,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1313",
    "Name": "SEKOLAH KEBANGSAAN KG BARU JUMPA",
    "Student": 146,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1314",
    "Name": "SEKOLAH KEBANGSAAN KEMABONG",
    "Student": 445,
    "Teacher": 40
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1317",
    "Name": "SEKOLAH KEBANGSAAN KUALA TOMANI",
    "Student": 534,
    "Teacher": 40
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1318",
    "Name": "SEKOLAH KEBANGSAAN LAGUD",
    "Student": 83,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1320",
    "Name": "SEKOLAH KEBANGSAAN MANDALOM LAMA",
    "Student": 97,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1321",
    "Name": "SEKOLAH KEBANGSAAN MASANOI",
    "Student": 142,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1322",
    "Name": "SEKOLAH KEBANGSAAN MANTAILANG",
    "Student": 99,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1323",
    "Name": "SEKOLAH KEBANGSAAN MELALAP",
    "Student": 173,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1325",
    "Name": "SEKOLAH KEBANGSAAN PANGI",
    "Student": 66,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1328",
    "Name": "SEKOLAH KEBANGSAAN PULONG",
    "Student": 121,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1330",
    "Name": "SEKOLAH KEBANGSAAN RUNDUM",
    "Student": 28,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1331",
    "Name": "SEKOLAH KEBANGSAAN SAGA",
    "Student": 149,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1332",
    "Name": "SEKOLAH KEBANGSAAN LADANG SAPONG",
    "Student": 194,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1336",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI API",
    "Student": 103,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1337",
    "Name": "SEKOLAH KEBANGSAAN PEKAN TENOM",
    "Student": 305,
    "Teacher": 39
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1340",
    "Name": "SEKOLAH KEBANGSAAN ULU TOMANI",
    "Student": 74,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1348",
    "Name": "SEKOLAH KEBANGSAAN JAWA SUMBILING",
    "Student": 122,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1349",
    "Name": "SEKOLAH KEBANGSAAN PAGUOKON",
    "Student": 164,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1350",
    "Name": "SEKOLAH KEBANGSAAN ULU ENTABUAN",
    "Student": 53,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1351",
    "Name": "SEKOLAH KEBANGSAAN KAPULU",
    "Student": 23,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1352",
    "Name": "SEKOLAH KEBANGSAAN SUMAMBU",
    "Student": 30,
    "Teacher": 7
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1353",
    "Name": "SEKOLAH KEBANGSAAN PANGALAT",
    "Student": 50,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1354",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG",
    "Student": 67,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1355",
    "Name": "SEKOLAH KEBANGSAAN BAKUKU",
    "Student": 90,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XBA1356",
    "Name": "SEKOLAH KEBANGSAAN KOLOROK",
    "Student": 57,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2001",
    "Name": "SEKOLAH KEBANGSAAN BAMBANGAN",
    "Student": 210,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2002",
    "Name": "SEKOLAH KEBANGSAAN SRI TANJUNG PAPAT 1",
    "Student": 885,
    "Teacher": 55
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2003",
    "Name": "SEKOLAH KEBANGSAAN BONGON BESAR",
    "Student": 19,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2004",
    "Name": "SEKOLAH KEBANGSAAN BANDAR",
    "Student": 612,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2005",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PADAS",
    "Student": 441,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2006",
    "Name": "SEKOLAH KEBANGSAAN RANCANGAN SUAN LAMBA",
    "Student": 245,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2007",
    "Name": "SEKOLAH KEBANGSAAN KUALA SUAN LAMBA",
    "Student": 161,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2008",
    "Name": "SEKOLAH KEBANGSAAN BATU 16 GUM-GUM",
    "Student": 575,
    "Teacher": 42
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2009",
    "Name": "SEKOLAH KEBANGSAAN GUM-GUM KECIL",
    "Student": 222,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2010",
    "Name": "SEKOLAH KEBANGSAAN GAS",
    "Student": 893,
    "Teacher": 65
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2011",
    "Name": "SEKOLAH KEBANGSAAN KARAMUNTING",
    "Student": 1250,
    "Teacher": 78
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2013",
    "Name": "SEKOLAH KEBANGSAAN SAKILAN DESA",
    "Student": 63,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2014",
    "Name": "SEKOLAH KEBANGSAAN PULAU LUBUKAN",
    "Student": 97,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2016",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BARU",
    "Student": 50,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2017",
    "Name": "SEKOLAH KEBANGSAAN MUMIANG",
    "Student": 47,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2018",
    "Name": "SEKOLAH KEBANGSAAN RANCANGAN LUBUH",
    "Student": 129,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2019",
    "Name": "SEKOLAH KEBANGSAAN NUNUYAN LAUT",
    "Student": 123,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2021",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI MADANG",
    "Student": 25,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2024",
    "Name": "SEKOLAH KEBANGSAAN PULAU LIBARAN",
    "Student": 37,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2025",
    "Name": "SEKOLAH KEBANGSAAN PULAU SANGHAI",
    "Student": 120,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2026",
    "Name": "SEKOLAH KEBANGSAAN PULAU TIMBANG",
    "Student": 67,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2029",
    "Name": "SEKOLAH KEBANGSAAN LADANG SANDAK",
    "Student": 417,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2030",
    "Name": "SEKOLAH KEBANGSAAN LABUK SUBUR",
    "Student": 130,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2033",
    "Name": "SEKOLAH KEBANGSAAN SEGALIUD",
    "Student": 264,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2034",
    "Name": "SEKOLAH KEBANGSAAN SEMAWANG",
    "Student": 86,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2036",
    "Name": "SEKOLAH KEBANGSAAN SIBUGAL BESAR",
    "Student": 464,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2048",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SUAN LAMBA",
    "Student": 176,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2049",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ANIB 1",
    "Student": 766,
    "Teacher": 54
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2050",
    "Name": "SEKOLAH KEBANGSAAN KEM TENTERA",
    "Student": 838,
    "Teacher": 66
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2051",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI MANILA",
    "Student": 1057,
    "Teacher": 56
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA2059",
    "Name": "SEKOLAH KEBANGSAAN TAMBISAN",
    "Student": 144,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2060",
    "Name": "SEKOLAH KEBANGSAAN TANJONG ARU",
    "Student": 99,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2062",
    "Name": "SEKOLAH KEBANGSAAN ULU DUSUN",
    "Student": 294,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2066",
    "Name": "SEKOLAH KEBANGSAAN TANJONG PISAU",
    "Student": 52,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2069",
    "Name": "SEKOLAH KEBANGSAAN SIPINONG",
    "Student": 82,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2073",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TIRAM",
    "Student": 101,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2076",
    "Name": "SEKOLAH KEBANGSAAN TANJONG BATU",
    "Student": 92,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2077",
    "Name": "SEKOLAH KEBANGSAAN BATU PUTIH BARU",
    "Student": 564,
    "Teacher": 40
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2079",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KAYU",
    "Student": 607,
    "Teacher": 39
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2080",
    "Name": "SEKOLAH KEBANGSAAN PAMAGUAN",
    "Student": 15,
    "Teacher": 7
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2081",
    "Name": "SEKOLAH KEBANGSAAN PITAS",
    "Student": 13,
    "Teacher": 7
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2082",
    "Name": "SEKOLAH KEBANGSAAN SG ANIB 2",
    "Student": 644,
    "Teacher": 46
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2083",
    "Name": "SEKOLAH KEBANGSAAN SRI TANJUNG PAPAT 2",
    "Student": 517,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2084",
    "Name": "SEKOLAH KEBANGSAAN MUHIBBAH",
    "Student": 977,
    "Teacher": 72
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2085",
    "Name": "SEKOLAH KEBANGSAAN TERUSAN BARU",
    "Student": 48,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2086",
    "Name": "SEKOLAH KEBANGSAAN TAMAN RIMBA",
    "Student": 861,
    "Teacher": 59
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2087",
    "Name": "SEKOLAH KEBANGSAAN SERI MANIS",
    "Student": 243,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2088",
    "Name": "SEKOLAH KEBANGSAAN KG BAHAGIA",
    "Student": 531,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2089",
    "Name": "SEKOLAH KEBANGSAAN MERPATI",
    "Student": 1425,
    "Teacher": 83
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2090",
    "Name": "SEKOLAH KEBANGSAAN KG PERTANIAN",
    "Student": 235,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2091",
    "Name": "SEKOLAH KEBANGSAAN MAWAR",
    "Student": 623,
    "Teacher": 40
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2092",
    "Name": "SEKOLAH KEBANGSAAN SRI MELANTA JAYA",
    "Student": 736,
    "Teacher": 53
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2093",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ANIB III",
    "Student": 723,
    "Teacher": 57
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBA2094",
    "Name": "SEKOLAH KEBANGSAAN TINUSA",
    "Student": 944,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2101",
    "Name": "SEKOLAH KEBANGSAAN BAWANG",
    "Student": 145,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2102",
    "Name": "SEKOLAH KEBANGSAAN PEKAN BELURAN",
    "Student": 1008,
    "Teacher": 69
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2103",
    "Name": "SEKOLAH KEBANGSAAN BERAYUNG",
    "Student": 69,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2104",
    "Name": "SEKOLAH KEBANGSAAN GOLONG",
    "Student": 92,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2105",
    "Name": "SEKOLAH KEBANGSAAN WONOD",
    "Student": 227,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2106",
    "Name": "SEKOLAH KEBANGSAAN JAMBONGAN",
    "Student": 143,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA2107",
    "Name": "SEKOLAH KEBANGSAAN KAINGARAN",
    "Student": 368,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2108",
    "Name": "SEKOLAH KEBANGSAAN KENIOGAN",
    "Student": 114,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2109",
    "Name": "SEKOLAH KEBANGSAAN KIABAU",
    "Student": 54,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2110",
    "Name": "SEKOLAH KEBANGSAAN KOLAPIS",
    "Student": 224,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2111",
    "Name": "SEKOLAH KEBANGSAAN LIDONG",
    "Student": 78,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2112",
    "Name": "SEKOLAH KEBANGSAAN LINGKABAU",
    "Student": 200,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2113",
    "Name": "SEKOLAH KEBANGSAAN LUBANG BUAYA",
    "Student": 57,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA2114",
    "Name": "SEKOLAH KEBANGSAAN MALINSAU",
    "Student": 245,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2115",
    "Name": "SEKOLAH KEBANGSAAN ULU MUANAD",
    "Student": 187,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2116",
    "Name": "SEKOLAH KEBANGSAAN PAMOL",
    "Student": 235,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2117",
    "Name": "SEKOLAH KEBANGSAAN PERANCANGAN",
    "Student": 107,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2118",
    "Name": "SEKOLAH KEBANGSAAN BASAI BARU",
    "Student": 123,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2119",
    "Name": "SEKOLAH KEBANGSAAN SIMPANGAN",
    "Student": 183,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2120",
    "Name": "SEKOLAH KEBANGSAAN MALALIN",
    "Student": 64,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2121",
    "Name": "SEKOLAH KEBANGSAAN SUALOK",
    "Student": 169,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2122",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI NANGKA",
    "Student": 189,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2123",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SAPI",
    "Student": 108,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2124",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI-SUNGAI",
    "Student": 314,
    "Teacher": 24
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2125",
    "Name": "SEKOLAH KEBANGSAAN TAGAS-TAGAS",
    "Student": 67,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2126",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG NIPIS",
    "Student": 139,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2127",
    "Name": "SEKOLAH KEBANGSAAN TELUPID",
    "Student": 173,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2128",
    "Name": "SEKOLAH KEBANGSAAN TERUSAN SUGUT",
    "Student": 92,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2129",
    "Name": "SEKOLAH KEBANGSAAN TETABUAN",
    "Student": 94,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2130",
    "Name": "SEKOLAH KEBANGSAAN BUIS",
    "Student": 92,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2131",
    "Name": "SEKOLAH KEBANGSAAN KABULUH",
    "Student": 247,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2132",
    "Name": "SEKOLAH KEBANGSAAN TAMPAT",
    "Student": 89,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2133",
    "Name": "SEKOLAH KEBANGSAAN MAIDAN",
    "Student": 63,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2134",
    "Name": "SEKOLAH KEBANGSAAN OBAH",
    "Student": 73,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA2135",
    "Name": "SEKOLAH KEBANGSAAN KAWIYAN SUGUT",
    "Student": 210,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2136",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BESI",
    "Student": 27,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2137",
    "Name": "SEKOLAH KEBANGSAAN GABUNGAN",
    "Student": 173,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2138",
    "Name": "SEKOLAH KEBANGSAAN BOTITION",
    "Student": 101,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2139",
    "Name": "SEKOLAH KEBANGSAAN TANGKUNGON",
    "Student": 204,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA2140",
    "Name": "SEKOLAH KEBANGSAAN MANGKAPOH",
    "Student": 204,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA2141",
    "Name": "SEKOLAH KEBANGSAAN KARAGASAN",
    "Student": 182,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2142",
    "Name": "SEKOLAH KEBANGSAAN NANGOH",
    "Student": 228,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2143",
    "Name": "SEKOLAH KEBANGSAAN BALABAN JAYA",
    "Student": 174,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2144",
    "Name": "SEKOLAH KEBANGSAAN ULU SAPI",
    "Student": 477,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2147",
    "Name": "SEKOLAH KEBANGSAAN ABUAN",
    "Student": 48,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2148",
    "Name": "SEKOLAH KEBANGSAAN PANTAI BORING",
    "Student": 72,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2149",
    "Name": "SEKOLAH KEBANGSAAN MOYNOD",
    "Student": 147,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2150",
    "Name": "SEKOLAH KEBANGSAAN SERI PAGI",
    "Student": 96,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2151",
    "Name": "SEKOLAH KEBANGSAAN PEKAN TELUPID",
    "Student": 697,
    "Teacher": 60
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2152",
    "Name": "SEKOLAH KEBANGSAAN BINTANG MAS",
    "Student": 120,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2153",
    "Name": "SEKOLAH KEBANGSAAN JAYA BAKTI",
    "Student": 334,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2154",
    "Name": "SEKOLAH KEBANGSAAN PINANGKAU",
    "Student": 48,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2155",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI NAFAS",
    "Student": 178,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2156",
    "Name": "SEKOLAH KEBANGSAAN TANGKARASON",
    "Student": 94,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2157",
    "Name": "SEKOLAH KEBANGSAAN LIMAU-LIMAU",
    "Student": 28,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2158",
    "Name": "SEKOLAH KEBANGSAAN BINSULUNG",
    "Student": 250,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2159",
    "Name": "SEKOLAH KEBANGSAAN MONOPOD",
    "Student": 32,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XBA2160",
    "Name": "SEKOLAH KEBANGSAAN MATANGGAL BELURAN",
    "Student": 103,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2201",
    "Name": "SEKOLAH KEBANGSAAN ABAI",
    "Student": 44,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2202",
    "Name": "SEKOLAH KEBANGSAAN BALAT",
    "Student": 49,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2203",
    "Name": "SEKOLAH KEBANGSAAN BATU PUTEH",
    "Student": 297,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2204",
    "Name": "SEKOLAH KEBANGSAAN BILIT",
    "Student": 63,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2205",
    "Name": "SEKOLAH KEBANGSAAN SRI GANDA",
    "Student": 52,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2206",
    "Name": "SEKOLAH KEBANGSAAN INARAD",
    "Student": 82,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2207",
    "Name": "SEKOLAH KEBANGSAAN KERAMUAK",
    "Student": 147,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2208",
    "Name": "SEKOLAH KEBANGSAAN KUAMUT",
    "Student": 63,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2209",
    "Name": "SEKOLAH KEBANGSAAN LANGKABONG",
    "Student": 185,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2210",
    "Name": "SEKOLAH KEBANGSAAN BUKIT GARAM",
    "Student": 417,
    "Teacher": 40
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2211",
    "Name": "SEKOLAH KEBANGSAAN MASAUM",
    "Student": 69,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2212",
    "Name": "SEKOLAH KEBANGSAAN MINUSUH",
    "Student": 171,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2213",
    "Name": "SEKOLAH KEBANGSAAN PENANGAH",
    "Student": 329,
    "Teacher": 25
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2214",
    "Name": "SEKOLAH KEBANGSAAN MENGKAWAGO",
    "Student": 102,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2215",
    "Name": "SEKOLAH KEBANGSAAN SUKAU",
    "Student": 372,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2216",
    "Name": "SEKOLAH KEBANGSAAN TAMPASAK",
    "Student": 86,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2217",
    "Name": "SEKOLAH KEBANGSAAN MENANAM",
    "Student": 124,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2218",
    "Name": "SEKOLAH KEBANGSAAN TUNDUN BOHANGIN",
    "Student": 49,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2219",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI LOKAN",
    "Student": 58,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2220",
    "Name": "SEKOLAH KEBANGSAAN KENANG-KENANGAN",
    "Student": 27,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2221",
    "Name": "SEKOLAH KEBANGSAAN ENTILIBON",
    "Student": 373,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2222",
    "Name": "SEKOLAH KEBANGSAAN SANGAU",
    "Student": 59,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2225",
    "Name": "SEKOLAH KEBANGSAAN BUANG SAYANG",
    "Student": 95,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2226",
    "Name": "SEKOLAH KEBANGSAAN DESA PERMAI",
    "Student": 7,
    "Teacher": 7
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2227",
    "Name": "SEKOLAH KEBANGSAAN SOGO-SOGO",
    "Student": 207,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2228",
    "Name": "SEKOLAH KEBANGSAAN PARIS",
    "Student": 373,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2229",
    "Name": "SEKOLAH KEBANGSAAN LINAYUKAN",
    "Student": 146,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2230",
    "Name": "SEKOLAH KEBANGSAAN MALIAU",
    "Student": 87,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2231",
    "Name": "SEKOLAH KEBANGSAAN LADANG SUNGAI BENDERA",
    "Student": 64,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2232",
    "Name": "SEKOLAH KEBANGSAAN SAGUON",
    "Student": 96,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2233",
    "Name": "SEKOLAH KEBANGSAAN SAPAGAYA",
    "Student": 40,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2234",
    "Name": "SEKOLAH KEBANGSAAN JAYA BARU",
    "Student": 228,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2236",
    "Name": "SEKOLAH KEBANGSAAN KOTA KINABATANGAN",
    "Student": 447,
    "Teacher": 38
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2237",
    "Name": "SEKOLAH KEBANGSAAN SANDAU",
    "Student": 324,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2238",
    "Name": "SEKOLAH KEBANGSAAN KIANDONGO",
    "Student": 134,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2239",
    "Name": "SEKOLAH KEBANGSAAN SINAR JAYA",
    "Student": 158,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2240",
    "Name": "SEKOLAH KEBANGSAAN LITANG",
    "Student": 41,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2241",
    "Name": "SEKOLAH KEBANGSAAN PARIS 3",
    "Student": 427,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2242",
    "Name": "SEKOLAH KEBANGSAAN SENTOSA JAYA",
    "Student": 126,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2243",
    "Name": "SEKOLAH KEBANGSAAN TIDUNG/TABIN",
    "Student": 65,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2244",
    "Name": "SEKOLAH KEBANGSAAN IMBAK",
    "Student": 123,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2245",
    "Name": "SEKOLAH KEBANGSAAN KIRONGGU",
    "Student": 44,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2246",
    "Name": "SEKOLAH KEBANGSAAN SINGGAH MANIS",
    "Student": 129,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2247",
    "Name": "SEKOLAH KEBANGSAAN ULU ANSUAN",
    "Student": 110,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XBA2248",
    "Name": "SEKOLAH KEBANGSAAN KOPURON",
    "Student": 93,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XBA2249",
    "Name": "SEKOLAH KEBANGSAAN BUKIT GARAM II",
    "Student": 786,
    "Teacher": 59
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3001",
    "Name": "SEKOLAH KEBANGSAAN BATU 22 BALUNG",
    "Student": 615,
    "Teacher": 44
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3002",
    "Name": "SEKOLAH KEBANGSAAN DESA SUBUR",
    "Student": 48,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3003",
    "Name": "SEKOLAH KEBANGSAAN ANDRASSY",
    "Student": 233,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3004",
    "Name": "SEKOLAH KEBANGSAAN BALUNG",
    "Student": 988,
    "Teacher": 69
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3005",
    "Name": "SEKOLAH KEBANGSAAN BATU PAYONG",
    "Student": 307,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3006",
    "Name": "SEKOLAH KEBANGSAAN BERGOSONG",
    "Student": 89,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3007",
    "Name": "SEKOLAH KEBANGSAAN BUKIT QUOIN",
    "Student": 244,
    "Teacher": 32
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3008",
    "Name": "SEKOLAH KEBANGSAAN BLOK 31",
    "Student": 304,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3009",
    "Name": "SEKOLAH KEBANGSAAN UMAS-UMAS",
    "Student": 988,
    "Teacher": 70
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3018",
    "Name": "SEKOLAH KEBANGSAAN INDERSABAH",
    "Student": 323,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3019",
    "Name": "SEKOLAH KEBANGSAAN KALABAKAN",
    "Student": 771,
    "Teacher": 56
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3020",
    "Name": "SEKOLAH KEBANGSAAN KEM KABOTA",
    "Student": 563,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3021",
    "Name": "SEKOLAH KEBANGSAAN KINABUTAN BESAR",
    "Student": 843,
    "Teacher": 60
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3022",
    "Name": "SEKOLAH KEBANGSAAN KUALA APAS",
    "Student": 331,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3023",
    "Name": "SEKOLAH KEBANGSAAN KUALA MEROTAI",
    "Student": 177,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3030",
    "Name": "SEKOLAH KEBANGSAAN MENTADAK BARU",
    "Student": 157,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3032",
    "Name": "SEKOLAH KEBANGSAAN MEROTAI BESAR",
    "Student": 1011,
    "Teacher": 78
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3037",
    "Name": "SEKOLAH KEBANGSAAN RANGGU",
    "Student": 661,
    "Teacher": 49
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3039",
    "Name": "SEKOLAH KEBANGSAAN SERUDONG BARU",
    "Student": 173,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3046",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI HJ MATAHIR",
    "Student": 103,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3047",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI IMAM",
    "Student": 243,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3048",
    "Name": "SEKOLAH KEBANGSAAN PANGLIMA HABIBULLAH",
    "Student": 123,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3049",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TONGKANG",
    "Student": 110,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3050",
    "Name": "SEKOLAH KEBANGSAAN TANJONG BATU",
    "Student": 751,
    "Teacher": 60
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3051",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TAWAU",
    "Student": 832,
    "Teacher": 68
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3054",
    "Name": "SEKOLAH KEBANGSAAN WAKUBA",
    "Student": 188,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3063",
    "Name": "SEKOLAH KEBANGSAAN KG JAWA",
    "Student": 292,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3065",
    "Name": "SEKOLAH KEBANGSAAN MUHIBBAH RAYA",
    "Student": 1082,
    "Teacher": 78
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3066",
    "Name": "SEKOLAH KEBANGSAAN PASIR PUTIH",
    "Student": 824,
    "Teacher": 60
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3067",
    "Name": "SEKOLAH KEBANGSAAN BATU 4 JALAN APAS",
    "Student": 1088,
    "Teacher": 83
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3068",
    "Name": "SEKOLAH KEBANGSAAN KG TITINGAN",
    "Student": 997,
    "Teacher": 78
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3069",
    "Name": "SEKOLAH KEBANGSAAN BANDAR TAWAU II",
    "Student": 745,
    "Teacher": 71
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3070",
    "Name": "SEKOLAH KEBANGSAAN TAMAN TAWAU",
    "Student": 1237,
    "Teacher": 82
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3071",
    "Name": "SEKOLAH KEBANGSAAN ULU KALABAKAN",
    "Student": 77,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3072",
    "Name": "SEKOLAH KEBANGSAAN TG BATU KERAMAT",
    "Student": 1020,
    "Teacher": 76
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3073",
    "Name": "SEKOLAH KEBANGSAAN PERDANA",
    "Student": 160,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3074",
    "Name": "SEKOLAH KEBANGSAAN MELODI",
    "Student": 180,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3075",
    "Name": "SEKOLAH KEBANGSAAN SENTOSA",
    "Student": 232,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3076",
    "Name": "SEKOLAH KEBANGSAAN BRANTIAN",
    "Student": 100,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3077",
    "Name": "SEKOLAH KEBANGSAAN BAHAGIA",
    "Student": 672,
    "Teacher": 51
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3078",
    "Name": "SEKOLAH KEBANGSAAN JAMBATAN PUTIH",
    "Student": 387,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XBA3079",
    "Name": "SEKOLAH KEBANGSAAN KUKUSAN",
    "Student": 491,
    "Teacher": 51
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3101",
    "Name": "SEKOLAH KEBANGSAAN BANGINGOD",
    "Student": 74,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3102",
    "Name": "SEKOLAH KEBANGSAAN BIKANG",
    "Student": 264,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3103",
    "Name": "SEKOLAH KEBANGSAAN BINUANG",
    "Student": 508,
    "Teacher": 35
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3104",
    "Name": "SEKOLAH KEBANGSAAN TAWAIYARI",
    "Student": 140,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3105",
    "Name": "SEKOLAH KEBANGSAAN SAHABAT 4",
    "Student": 480,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3106",
    "Name": "SEKOLAH KEBANGSAAN SABAH COCOA",
    "Student": 38,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3107",
    "Name": "SEKOLAH KEBANGSAAN AMALANIA",
    "Student": 178,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3108",
    "Name": "SEKOLAH KEBANGSAAN LAHAD DATU II",
    "Student": 1855,
    "Teacher": 104
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3115",
    "Name": "SEKOLAH KEBANGSAAN PEKAN",
    "Student": 1873,
    "Teacher": 118
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3119",
    "Name": "SEKOLAH KEBANGSAAN BATU 6 1/2 SEGAMA",
    "Student": 538,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3123",
    "Name": "SEKOLAH KEBANGSAAN SEPAGAYA",
    "Student": 688,
    "Teacher": 52
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3129",
    "Name": "SEKOLAH KEBANGSAAN TELISAI",
    "Student": 210,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3130",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG LABIAN",
    "Student": 271,
    "Teacher": 23
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3131",
    "Name": "SEKOLAH KEBANGSAAN TANJONG PARAS",
    "Student": 238,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3132",
    "Name": "SEKOLAH KEBANGSAAN TUNGKU",
    "Student": 364,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3133",
    "Name": "SEKOLAH KEBANGSAAN ULU TUNGKU",
    "Student": 96,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3135",
    "Name": "SEKOLAH KEBANGSAAN SRI DARUN",
    "Student": 148,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3136",
    "Name": "SEKOLAH KEBANGSAAN SILAM",
    "Student": 658,
    "Teacher": 44
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3137",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BALACON",
    "Student": 554,
    "Teacher": 37
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3138",
    "Name": "SEKOLAH KEBANGSAAN LOK BUANI",
    "Student": 110,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3139",
    "Name": "SEKOLAH KEBANGSAAN SRI PANTAI",
    "Student": 392,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3140",
    "Name": "SEKOLAH KEBANGSAAN SILABUKAN",
    "Student": 326,
    "Teacher": 35
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3141",
    "Name": "SEKOLAH KEBANGSAAN SAHABAT 16",
    "Student": 436,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3142",
    "Name": "SEKOLAH KEBANGSAAN TERUSAN",
    "Student": 215,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3143",
    "Name": "SEKOLAH KEBANGSAAN JEROCO",
    "Student": 154,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3144",
    "Name": "SEKOLAH KEBANGSAAN LAHAD DATU III",
    "Student": 992,
    "Teacher": 61
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3145",
    "Name": "SEKOLAH KEBANGSAAN SAHABAT II",
    "Student": 493,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3146",
    "Name": "SEKOLAH KEBANGSAAN FAJAR HARAPAN",
    "Student": 515,
    "Teacher": 42
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3147",
    "Name": "SEKOLAH KEBANGSAAN CENDERAWASIH",
    "Student": 799,
    "Teacher": 51
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3148",
    "Name": "SEKOLAH KEBANGSAAN PERMAI",
    "Student": 99,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3149",
    "Name": "SEKOLAH KEBANGSAAN PAYANG",
    "Student": 317,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3150",
    "Name": "SEKOLAH KEBANGSAAN UNICO DESA",
    "Student": 184,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XBA3151",
    "Name": "SEKOLAH KEBANGSAAN LAHAD DATU IV",
    "Student": 368,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3201",
    "Name": "SEKOLAH KEBANGSAAN TUN FUAD",
    "Student": 190,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3202",
    "Name": "SEKOLAH KEBANGSAAN KUNAK I",
    "Student": 1149,
    "Teacher": 70
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3203",
    "Name": "SEKOLAH KEBANGSAAN KUNAK 2",
    "Student": 350,
    "Teacher": 36
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3204",
    "Name": "SEKOLAH KEBANGSAAN LORMALONG",
    "Student": 267,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3205",
    "Name": "SEKOLAH KEBANGSAAN MOSTYN",
    "Student": 353,
    "Teacher": 38
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3206",
    "Name": "SEKOLAH KEBANGSAAN PANGI",
    "Student": 553,
    "Teacher": 47
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3207",
    "Name": "SEKOLAH KEBANGSAAN GADING-GADING",
    "Student": 96,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3208",
    "Name": "SEKOLAH KEBANGSAAN LADANG GIRAM",
    "Student": 134,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3209",
    "Name": "SEKOLAH KEBANGSAAN SKIM KOKOS",
    "Student": 109,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3210",
    "Name": "SEKOLAH KEBANGSAAN KUNAK JAYA",
    "Student": 499,
    "Teacher": 39
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3211",
    "Name": "SEKOLAH KEBANGSAAN MADAI",
    "Student": 440,
    "Teacher": 36
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3213",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG KERAMAT",
    "Student": 71,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3214",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SELAMAT",
    "Student": 695,
    "Teacher": 53
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XBA3215",
    "Name": "SEKOLAH KEBANGSAAN LADANG BINUANG",
    "Student": 205,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3301",
    "Name": "SEKOLAH KEBANGSAAN BALIMBANG",
    "Student": 564,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3302",
    "Name": "SEKOLAH KEBANGSAAN BUBUL",
    "Student": 1085,
    "Teacher": 72
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3303",
    "Name": "SEKOLAH KEBANGSAAN BUGAYA",
    "Student": 970,
    "Teacher": 70
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3304",
    "Name": "SEKOLAH KEBANGSAAN GELAM-GELAM",
    "Student": 275,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3305",
    "Name": "SEKOLAH KEBANGSAAN HAMPALAN",
    "Student": 159,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3306",
    "Name": "SEKOLAH KEBANGSAAN KABOGAN",
    "Student": 501,
    "Teacher": 47
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3307",
    "Name": "SEKOLAH KEBANGSAAN PULAU LARAPAN",
    "Student": 161,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3308",
    "Name": "SEKOLAH KEBANGSAAN LIHAK-LIHAK",
    "Student": 337,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3309",
    "Name": "SEKOLAH KEBANGSAAN MENAMPILIK",
    "Student": 24,
    "Teacher": 7
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3312",
    "Name": "SEKOLAH KEBANGSAAN PEGAGAU",
    "Student": 402,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3313",
    "Name": "SEKOLAH KEBANGSAAN PULAU BAIT",
    "Student": 147,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3314",
    "Name": "SEKOLAH KEBANGSAAN PULAU BUM-BUM",
    "Student": 209,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3315",
    "Name": "SEKOLAH KEBANGSAAN PULAU OMADAL",
    "Student": 14,
    "Teacher": 7
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3316",
    "Name": "SEKOLAH KEBANGSAAN SELAKAN",
    "Student": 23,
    "Teacher": 7
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3317",
    "Name": "SEKOLAH KEBANGSAAN PEKAN SEMPORNA",
    "Student": 1165,
    "Teacher": 79
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3318",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG KAPOR",
    "Student": 375,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3319",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG POKAS",
    "Student": 182,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3320",
    "Name": "SEKOLAH KEBANGSAAN SISIPAN",
    "Student": 106,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3321",
    "Name": "SEKOLAH KEBANGSAAN SULABAYAN",
    "Student": 103,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3322",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TUHOK",
    "Student": 409,
    "Teacher": 37
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3323",
    "Name": "SEKOLAH KEBANGSAAN TAGASAN",
    "Student": 464,
    "Teacher": 32
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3324",
    "Name": "SEKOLAH KEBANGSAAN TAMPI-TAMPI",
    "Student": 475,
    "Teacher": 42
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3325",
    "Name": "SEKOLAH KEBANGSAAN TERUSAN BARU",
    "Student": 189,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3326",
    "Name": "SEKOLAH KEBANGSAAN TERUSAN TENGAH",
    "Student": 474,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3327",
    "Name": "SEKOLAH KEBANGSAAN PULAU DENAWAN",
    "Student": 52,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3328",
    "Name": "SEKOLAH KEBANGSAAN EGANG-EGANG",
    "Student": 154,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3329",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SIMUNUL",
    "Student": 1084,
    "Teacher": 71
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3330",
    "Name": "SEKOLAH KEBANGSAAN PULAU PABABAG",
    "Student": 161,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3331",
    "Name": "SEKOLAH KEBANGSAAN KUBANG PINANG",
    "Student": 390,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3332",
    "Name": "SEKOLAH KEBANGSAAN KALUMPANG",
    "Student": 147,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3333",
    "Name": "SEKOLAH KEBANGSAAN PULAU MABUL",
    "Student": 143,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3334",
    "Name": "SEKOLAH KEBANGSAAN BANGAU-BANGAU",
    "Student": 1167,
    "Teacher": 67
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3335",
    "Name": "SEKOLAH KEBANGSAAN LABUAN HAJI",
    "Student": 114,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3336",
    "Name": "SEKOLAH KEBANGSAAN PULAU SUMANDI",
    "Student": 154,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3337",
    "Name": "SEKOLAH KEBANGSAAN TONGKALLOH",
    "Student": 208,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3338",
    "Name": "SEKOLAH KEBANGSAAN PEKAN SEMPORNA II",
    "Student": 917,
    "Teacher": 68
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3339",
    "Name": "SEKOLAH KEBANGSAAN BUBUL II",
    "Student": 736,
    "Teacher": 44
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XBA3340",
    "Name": "SEKOLAH KEBANGSAAN BUKIT LALANG",
    "Student": 586,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4001",
    "Name": "SEKOLAH KEBANGSAAN GANTISAN",
    "Student": 696,
    "Teacher": 47
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4003",
    "Name": "SEKOLAH KEBANGSAAN BABAGON TOKI",
    "Student": 49,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4004",
    "Name": "SEKOLAH KEBANGSAAN BANTAYAN",
    "Student": 250,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4007",
    "Name": "SEKOLAH KEBANGSAAN DARAU",
    "Student": 1285,
    "Teacher": 76
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4010",
    "Name": "SEKOLAH KEBANGSAAN GUDON MENGGATAL",
    "Student": 934,
    "Teacher": 57
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4011",
    "Name": "SEKOLAH KEBANGSAAN PENGIRAN SITI HAFSAH KARAMBUNAI",
    "Student": 224,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4012",
    "Name": "SEKOLAH KEBANGSAAN KEBAGU",
    "Student": 644,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4013",
    "Name": "SEKOLAH KEBANGSAAN KEBAYAU TELIPOK",
    "Student": 73,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4014",
    "Name": "SEKOLAH KEBANGSAAN KEPAYAN",
    "Student": 1243,
    "Teacher": 79
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4016",
    "Name": "SEKOLAH KEBANGSAAN KIONSOM INANAM",
    "Student": 178,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4017",
    "Name": "SEKOLAH KEBANGSAAN KERONGGU",
    "Student": 80,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4018",
    "Name": "SEKOLAH KEBANGSAAN KITOBU",
    "Student": 197,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4019",
    "Name": "SEKOLAH KEBANGSAAN KOKOL",
    "Student": 197,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4020",
    "Name": "SEKOLAH KEBANGSAAN KOLOMBONG KOTA KINABALU",
    "Student": 770,
    "Teacher": 61
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4023",
    "Name": "SEKOLAH KEBANGSAAN LIKAS",
    "Student": 1444,
    "Teacher": 87
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4031",
    "Name": "SEKOLAH KEBANGSAAN MALAWA",
    "Student": 1788,
    "Teacher": 108
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4033",
    "Name": "SEKOLAH KEBANGSAAN NATAI TELIPOK",
    "Student": 40,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4034",
    "Name": "SEKOLAH KEBANGSAAN PORING-PORING",
    "Student": 36,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4035",
    "Name": "SEKOLAH KEBANGSAAN PULAU GAYA",
    "Student": 1413,
    "Teacher": 84
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4036",
    "Name": "SEKOLAH KEBANGSAAN RAMPAYAN MENGGATAL",
    "Student": 578,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4037",
    "Name": "SEKOLAH KEBANGSAAN RUMINDING",
    "Student": 10,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4041",
    "Name": "SEKOLAH KEBANGSAAN PULAU SEPANGGAR",
    "Student": 231,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4042",
    "Name": "SEKOLAH KEBANGSAAN SEMBULAN",
    "Student": 1254,
    "Teacher": 85
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4059",
    "Name": "SEKOLAH KEBANGSAAN TALUNGAN TELIPOK",
    "Student": 60,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4060",
    "Name": "SEKOLAH KEBANGSAAN TAMPULAN",
    "Student": 88,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4061",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG ARU 1",
    "Student": 791,
    "Teacher": 56
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4064",
    "Name": "SEKOLAH KEBANGSAAN TOMBONGON",
    "Student": 70,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4072",
    "Name": "SEKOLAH KEBANGSAAN UNGGUN MENGGATAL",
    "Student": 963,
    "Teacher": 54
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4073",
    "Name": "SEKOLAH KEBANGSAAN POMOTODON",
    "Student": 313,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4090",
    "Name": "SEKOLAH KEBANGSAAN PANGKALAN TLDM KOTA KINABALU",
    "Student": 711,
    "Teacher": 47
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4091",
    "Name": "SEKOLAH KEBANGSAAN INANAM LAUT",
    "Student": 915,
    "Teacher": 64
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4092",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PADANG",
    "Student": 590,
    "Teacher": 56
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4093",
    "Name": "SEKOLAH KEBANGSAAN TOBOBON",
    "Student": 1557,
    "Teacher": 95
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4094",
    "Name": "SEKOLAH KEBANGSAAN LAPASAN",
    "Student": 279,
    "Teacher": 24
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4095",
    "Name": "SEKOLAH KEBANGSAAN SRI GAYA",
    "Student": 988,
    "Teacher": 59
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4096",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG ARU II",
    "Student": 688,
    "Teacher": 56
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4097",
    "Name": "SEKOLAH KEBANGSAAN INANAM II",
    "Student": 1240,
    "Teacher": 87
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4098",
    "Name": "SEKOLAH KEBANGSAAN MUTIARA KOTA KINABALU",
    "Student": 804,
    "Teacher": 53
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4099",
    "Name": "SEKOLAH KEBANGSAAN LUYANG KOTA KINABALU",
    "Student": 538,
    "Teacher": 52
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4101",
    "Name": "SEKOLAH KEBANGSAAN BABAGON",
    "Student": 409,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4102",
    "Name": "SEKOLAH KEBANGSAAN BUAYAN",
    "Student": 34,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4103",
    "Name": "SEKOLAH KEBANGSAAN BUIT HILL",
    "Student": 424,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4105",
    "Name": "SEKOLAH KEBANGSAAN KIPOVO",
    "Student": 112,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4106",
    "Name": "SEKOLAH KEBANGSAAN KEM LOK KAWI",
    "Student": 885,
    "Teacher": 72
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4107",
    "Name": "SEKOLAH KEBANGSAAN LONGKOGUNGON",
    "Student": 8,
    "Teacher": 5
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4108",
    "Name": "SEKOLAH KEBANGSAAN MOYOG",
    "Student": 162,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4109",
    "Name": "SEKOLAH KEBANGSAAN TANSAU",
    "Student": 892,
    "Teacher": 61
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4111",
    "Name": "SEKOLAH KEBANGSAAN PETAGAS",
    "Student": 881,
    "Teacher": 62
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4112",
    "Name": "SEKOLAH KEBANGSAAN PUTATON INOBONG",
    "Student": 151,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4113",
    "Name": "SEKOLAH KEBANGSAAN PUUN TUNOH",
    "Student": 216,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4120",
    "Name": "SEKOLAH KEBANGSAAN SUGUD",
    "Student": 252,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4121",
    "Name": "SEKOLAH KEBANGSAAN TERIAN",
    "Student": 15,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4122",
    "Name": "SEKOLAH KEBANGSAAN TOMBOVO",
    "Student": 891,
    "Teacher": 57
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4125",
    "Name": "SEKOLAH KEBANGSAAN TAMPASAK TOGUDON",
    "Student": 74,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4126",
    "Name": "SEKOLAH KEBANGSAAN PEKAN PUTATAN",
    "Student": 1357,
    "Teacher": 88
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4128",
    "Name": "SEKOLAH KEBANGSAAN BAHANG",
    "Student": 764,
    "Teacher": 56
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4129",
    "Name": "SEKOLAH KEBANGSAAN KIBABAIG",
    "Student": 985,
    "Teacher": 73
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4130",
    "Name": "SEKOLAH KEBANGSAAN KG CONTOH",
    "Student": 888,
    "Teacher": 70
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4131",
    "Name": "SEKOLAH KEBANGSAAN PENAMPANG",
    "Student": 997,
    "Teacher": 66
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4203",
    "Name": "SEKOLAH KEBANGSAAN BELATIK",
    "Student": 92,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4204",
    "Name": "SEKOLAH KEBANGSAAN BENONI",
    "Student": 569,
    "Teacher": 47
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4205",
    "Name": "SEKOLAH KEBANGSAAN BERINGIS",
    "Student": 608,
    "Teacher": 46
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4214",
    "Name": "SEKOLAH KEBANGSAAN DAINGIN",
    "Student": 111,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4215",
    "Name": "SEKOLAH KEBANGSAAN GANA",
    "Student": 126,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4217",
    "Name": "SEKOLAH KEBANGSAAN KAIDUAN",
    "Student": 93,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4218",
    "Name": "SEKOLAH KEBANGSAAN KELATUAN",
    "Student": 128,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4219",
    "Name": "SEKOLAH KEBANGSAAN KAWANG",
    "Student": 311,
    "Teacher": 35
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4220",
    "Name": "SEKOLAH KEBANGSAAN KAYAU",
    "Student": 90,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4221",
    "Name": "SEKOLAH KEBANGSAAN KELANAHAN",
    "Student": 315,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4222",
    "Name": "SEKOLAH KEBANGSAAN KAMBIZAAN",
    "Student": 63,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4226",
    "Name": "SEKOLAH KEBANGSAAN PEKAN KINARUT",
    "Student": 1430,
    "Teacher": 103
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4227",
    "Name": "SEKOLAH KEBANGSAAN KOGOPON",
    "Student": 86,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4228",
    "Name": "SEKOLAH KEBANGSAAN KUALA PAPAR",
    "Student": 392,
    "Teacher": 36
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4229",
    "Name": "SEKOLAH KEBANGSAAN LANGKAWIT",
    "Student": 102,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4230",
    "Name": "SEKOLAH KEBANGSAAN LIMPUTONG",
    "Student": 212,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4231",
    "Name": "SEKOLAH KEBANGSAAN LINGAN",
    "Student": 61,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4233",
    "Name": "SEKOLAH KEBANGSAAN MANDALIPAU",
    "Student": 64,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4234",
    "Name": "SEKOLAH KEBANGSAAN MOOK",
    "Student": 522,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4236",
    "Name": "SEKOLAH KEBANGSAAN PADAWAN BESAR",
    "Student": 75,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4237",
    "Name": "SEKOLAH KEBANGSAAN PEKAN PAPAR",
    "Student": 605,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4241",
    "Name": "SEKOLAH KEBANGSAAN PENGALAT BESAR",
    "Student": 597,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4242",
    "Name": "SEKOLAH KEBANGSAAN PENGALAT KECIL",
    "Student": 265,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4244",
    "Name": "SEKOLAH KEBANGSAAN RAMPAZAN",
    "Student": 176,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4245",
    "Name": "SEKOLAH KEBANGSAAN SABANDIL",
    "Student": 94,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4256",
    "Name": "SEKOLAH KEBANGSAAN TAMPASAK",
    "Student": 395,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4257",
    "Name": "SEKOLAH KEBANGSAAN TANAKI",
    "Student": 58,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4258",
    "Name": "SEKOLAH KEBANGSAAN BUANG SAYANG",
    "Student": 294,
    "Teacher": 35
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4259",
    "Name": "SEKOLAH KEBANGSAAN PANTAI MANIS PAPAR",
    "Student": 237,
    "Teacher": 23
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4260",
    "Name": "SEKOLAH KEBANGSAAN SURATI",
    "Student": 566,
    "Teacher": 50
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA4261",
    "Name": "SEKOLAH KEBANGSAAN PEKAN KIMANIS",
    "Student": 392,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4303",
    "Name": "SEKOLAH KEBANGSAAN BERUNGIS TUARAN",
    "Student": 435,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4304",
    "Name": "SEKOLAH KEBANGSAAN BOLONG",
    "Student": 286,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4306",
    "Name": "SEKOLAH KEBANGSAAN BUNDUNG",
    "Student": 124,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4308",
    "Name": "SEKOLAH KEBANGSAAN BUNGALIO",
    "Student": 78,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4309",
    "Name": "SEKOLAH KEBANGSAAN BARU-BARU",
    "Student": 258,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4310",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DAMIT",
    "Student": 242,
    "Teacher": 25
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4314",
    "Name": "SEKOLAH KEBANGSAAN GAYANG",
    "Student": 448,
    "Teacher": 35
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4317",
    "Name": "SEKOLAH KEBANGSAAN KAULUAN",
    "Student": 113,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4320",
    "Name": "SEKOLAH KEBANGSAAN KINDU",
    "Student": 155,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4321",
    "Name": "SEKOLAH KEBANGSAAN PEKAN KIULU",
    "Student": 173,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4324",
    "Name": "SEKOLAH KEBANGSAAN LOKUB",
    "Student": 103,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4327",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KOTA KINABALU",
    "Student": 59,
    "Teacher": 35
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4328",
    "Name": "SEKOLAH KEBANGSAAN MALANGANG BARU",
    "Student": 99,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4329",
    "Name": "SEKOLAH KEBANGSAAN MANTOB",
    "Student": 80,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4330",
    "Name": "SEKOLAH KEBANGSAAN MENGKABONG",
    "Student": 638,
    "Teacher": 46
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4331",
    "Name": "SEKOLAH KEBANGSAAN NONGKOLUD",
    "Student": 416,
    "Teacher": 32
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4332",
    "Name": "SEKOLAH KEBANGSAAN PAHU",
    "Student": 27,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4333",
    "Name": "SEKOLAH KEBANGSAAN PENIMBAWAN",
    "Student": 187,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4334",
    "Name": "SEKOLAH KEBANGSAAN PORING",
    "Student": 6,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4335",
    "Name": "SEKOLAH KEBANGSAAN PUKAK",
    "Student": 98,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4336",
    "Name": "SEKOLAH KEBANGSAAN RANGALAU BARU",
    "Student": 36,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4337",
    "Name": "SEKOLAH KEBANGSAAN RANGALAU LAMA",
    "Student": 43,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4341",
    "Name": "SEKOLAH KEBANGSAAN SELUPOH",
    "Student": 207,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4343",
    "Name": "SEKOLAH KEBANGSAAN SERUSUP",
    "Student": 321,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4358",
    "Name": "SEKOLAH KEBANGSAAN TAMBALANG",
    "Student": 447,
    "Teacher": 37
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4359",
    "Name": "SEKOLAH KEBANGSAAN TAMBULAONG",
    "Student": 42,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4363",
    "Name": "SEKOLAH KEBANGSAAN PEKAN TELIPOK",
    "Student": 1737,
    "Teacher": 102
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4365",
    "Name": "SEKOLAH KEBANGSAAN TERMUNONG",
    "Student": 168,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4372",
    "Name": "SEKOLAH KEBANGSAAN PEKAN TUARAN",
    "Student": 939,
    "Teacher": 80
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4375",
    "Name": "SEKOLAH KEBANGSAAN TUDAN",
    "Student": 35,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4376",
    "Name": "SEKOLAH KEBANGSAAN WASAI",
    "Student": 20,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4382",
    "Name": "SEKOLAH KEBANGSAAN RUGADING",
    "Student": 1177,
    "Teacher": 67
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4383",
    "Name": "SEKOLAH KEBANGSAAN KITAPOL",
    "Student": 22,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4384",
    "Name": "SEKOLAH KEBANGSAAN LAYA-LAYA",
    "Student": 401,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XBA4385",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS TUARAN",
    "Student": 84,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4386",
    "Name": "SEKOLAH KEBANGSAAN SUNGOI",
    "Student": 19,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4401",
    "Name": "SEKOLAH KEBANGSAAN BANTAYAN",
    "Student": 226,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4402",
    "Name": "SEKOLAH KEBANGSAAN BAWANG",
    "Student": 127,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4403",
    "Name": "SEKOLAH KEBANGSAAN BONGOL",
    "Student": 47,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4404",
    "Name": "SEKOLAH KEBANGSAAN BUNDUTAHURI",
    "Student": 48,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4405",
    "Name": "SEKOLAH KEBANGSAAN GONTUNG",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4406",
    "Name": "SEKOLAH KEBANGSAAN GAYARATAU",
    "Student": 58,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4407",
    "Name": "SEKOLAH KEBANGSAAN GIOK",
    "Student": 64,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4408",
    "Name": "SEKOLAH KEBANGSAAN KAYANGAT",
    "Student": 84,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4409",
    "Name": "SEKOLAH KEBANGSAAN KELAWAT",
    "Student": 65,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4410",
    "Name": "SEKOLAH KEBANGSAAN LAPUTONG",
    "Student": 58,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4411",
    "Name": "SEKOLAH KEBANGSAAN LINUNGKUAN",
    "Student": 54,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4412",
    "Name": "SEKOLAH KEBANGSAAN LOKOS TAMPARULI",
    "Student": 49,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4413",
    "Name": "SEKOLAH KEBANGSAAN RUNGUS",
    "Student": 104,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4414",
    "Name": "SEKOLAH KEBANGSAAN RUNGUS NAHABA",
    "Student": 27,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4415",
    "Name": "SEKOLAH KEBANGSAAN SARADAN",
    "Student": 55,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4416",
    "Name": "SEKOLAH KEBANGSAAN SAMBAH",
    "Student": 14,
    "Teacher": 7
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4417",
    "Name": "SEKOLAH KEBANGSAAN SINULIHAN",
    "Student": 25,
    "Teacher": 6
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4418",
    "Name": "SEKOLAH KEBANGSAAN TAGINAMBUR",
    "Student": 34,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4419",
    "Name": "SEKOLAH KEBANGSAAN PEKAN TAMPARULI",
    "Student": 633,
    "Teacher": 62
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4420",
    "Name": "SEKOLAH KEBANGSAAN PEKAN TENGHILAN",
    "Student": 312,
    "Teacher": 36
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4422",
    "Name": "SEKOLAH KEBANGSAAN TIONG PERUNGUSAN",
    "Student": 45,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4423",
    "Name": "SEKOLAH KEBANGSAAN TOGOP",
    "Student": 46,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4424",
    "Name": "SEKOLAH KEBANGSAAN TOMIS",
    "Student": 47,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4425",
    "Name": "SEKOLAH KEBANGSAAN TOPOKON",
    "Student": 152,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4426",
    "Name": "SEKOLAH KEBANGSAAN WANGKOD",
    "Student": 107,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4427",
    "Name": "SEKOLAH KEBANGSAAN GUAKON BARU",
    "Student": 37,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4428",
    "Name": "SEKOLAH KEBANGSAAN LINGGA BARU",
    "Student": 55,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4429",
    "Name": "SEKOLAH KEBANGSAAN KAPA",
    "Student": 62,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4431",
    "Name": "SEKOLAH KEBANGSAAN TINAMBAK",
    "Student": 83,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XBA4432",
    "Name": "SEKOLAH KEBANGSAAN KOPORINGAN",
    "Student": 70,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBA4501",
    "Name": "SEKOLAH KEBANGSAAN API API",
    "Student": 850,
    "Teacher": 49
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5002",
    "Name": "SEKOLAH KEBANGSAAN BALAMBANGAN BANGGI",
    "Student": 70,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5003",
    "Name": "SEKOLAH KEBANGSAAN LAKSIAN",
    "Student": 120,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5004",
    "Name": "SEKOLAH KEBANGSAAN DOGOTON",
    "Student": 83,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5005",
    "Name": "SEKOLAH KEBANGSAAN BANGAU KUDAT",
    "Student": 161,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5006",
    "Name": "SEKOLAH KEBANGSAAN BARAMBANGON",
    "Student": 50,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5007",
    "Name": "SEKOLAH KEBANGSAAN PEKAN KUDAT II",
    "Student": 926,
    "Teacher": 66
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5008",
    "Name": "SEKOLAH KEBANGSAAN PATA",
    "Student": 94,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5009",
    "Name": "SEKOLAH KEBANGSAAN INDARASON LAUT",
    "Student": 165,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5010",
    "Name": "SEKOLAH KEBANGSAAN GUMANDANG",
    "Student": 75,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5011",
    "Name": "SEKOLAH KEBANGSAAN SEMAYAN BANGGI",
    "Student": 34,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5013",
    "Name": "SEKOLAH KEBANGSAAN KG MINYAK",
    "Student": 70,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5014",
    "Name": "SEKOLAH KEBANGSAAN KAPITANGAN",
    "Student": 71,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5015",
    "Name": "SEKOLAH KEBANGSAAN KARAKIT BANGGI",
    "Student": 273,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5016",
    "Name": "SEKOLAH KEBANGSAAN BATU LAYAR",
    "Student": 136,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5017",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG MANAWALI",
    "Student": 27,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5018",
    "Name": "SEKOLAH KEBANGSAAN TUN DATU HJ MUSTAPHA",
    "Student": 801,
    "Teacher": 58
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5019",
    "Name": "SEKOLAH KEBANGSAAN MUHIBBAH",
    "Student": 202,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5024",
    "Name": "SEKOLAH KEBANGSAAN LIMAU-LIMAUAN",
    "Student": 55,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5025",
    "Name": "SEKOLAH KEBANGSAAN LIMBUAK",
    "Student": 79,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5028",
    "Name": "SEKOLAH KEBANGSAAN LOKOTON",
    "Student": 254,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5040",
    "Name": "SEKOLAH KEBANGSAAN MATUNGGONG",
    "Student": 493,
    "Teacher": 38
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5041",
    "Name": "SEKOLAH KEBANGSAAN NANGKA KUDAT",
    "Student": 100,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5043",
    "Name": "SEKOLAH KEBANGSAAN PADANG",
    "Student": 297,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5046",
    "Name": "SEKOLAH KEBANGSAAN PERAPAT LAUT",
    "Student": 39,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5047",
    "Name": "SEKOLAH KEBANGSAAN PINAWANTAI KUDAT",
    "Student": 488,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5055",
    "Name": "SEKOLAH KEBANGSAAN SEBAYAN",
    "Student": 123,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5061",
    "Name": "SEKOLAH KEBANGSAAN SIKUATI",
    "Student": 392,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5067",
    "Name": "SEKOLAH KEBANGSAAN SUANGPAI",
    "Student": 67,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5071",
    "Name": "SEKOLAH KEBANGSAAN PALAK",
    "Student": 55,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5074",
    "Name": "SEKOLAH KEBANGSAAN TERONGKONGAN",
    "Student": 106,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5076",
    "Name": "SEKOLAH KEBANGSAAN TIGA PAPAN KUDAT",
    "Student": 119,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5083",
    "Name": "SEKOLAH KEBANGSAAN LOKTOHOG BANGGI",
    "Student": 124,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5088",
    "Name": "SEKOLAH KEBANGSAAN DUALOG",
    "Student": 72,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5089",
    "Name": "SEKOLAH KEBANGSAAN PARAPAT DARAT",
    "Student": 134,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5091",
    "Name": "SEKOLAH KEBANGSAAN LAMPAKI KUDAT",
    "Student": 91,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5092",
    "Name": "SEKOLAH KEBANGSAAN LOTONG",
    "Student": 139,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5093",
    "Name": "SEKOLAH KEBANGSAAN PANUDAHAN",
    "Student": 208,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5094",
    "Name": "SEKOLAH KEBANGSAAN GARAU KUDAT",
    "Student": 133,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5095",
    "Name": "SEKOLAH KEBANGSAAN SABUR",
    "Student": 136,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5096",
    "Name": "SEKOLAH KEBANGSAAN BINGOLON KUDAT",
    "Student": 97,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5097",
    "Name": "SEKOLAH KEBANGSAAN PULAU TIGABU",
    "Student": 89,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5098",
    "Name": "SEKOLAH KEBANGSAAN DAMPIRIT",
    "Student": 111,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XBA5099",
    "Name": "SEKOLAH KEBANGSAAN LANDUNG AYANG",
    "Student": 362,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5101",
    "Name": "SEKOLAH KEBANGSAAN PEKAN KOTA MARUDU",
    "Student": 465,
    "Teacher": 47
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5102",
    "Name": "SEKOLAH KEBANGSAAN BENGKONGAN",
    "Student": 381,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5103",
    "Name": "SEKOLAH KEBANGSAAN LANGKON",
    "Student": 392,
    "Teacher": 38
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5104",
    "Name": "SEKOLAH KEBANGSAAN MARAK-PARAK",
    "Student": 380,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5105",
    "Name": "SEKOLAH KEBANGSAAN MASALOG",
    "Student": 261,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5106",
    "Name": "SEKOLAH KEBANGSAAN POPOK",
    "Student": 348,
    "Teacher": 37
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5107",
    "Name": "SEKOLAH KEBANGSAAN SAMPIR",
    "Student": 124,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5108",
    "Name": "SEKOLAH KEBANGSAAN SAMPARITA",
    "Student": 364,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5109",
    "Name": "SEKOLAH KEBANGSAAN SIMPANGAN",
    "Student": 388,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5110",
    "Name": "SEKOLAH KEBANGSAAN TAGAROH",
    "Student": 374,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5111",
    "Name": "SEKOLAH KEBANGSAAN TANDEK",
    "Student": 476,
    "Teacher": 44
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5112",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG BATU",
    "Student": 191,
    "Teacher": 25
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5113",
    "Name": "SEKOLAH KEBANGSAAN TARITIPAN",
    "Student": 307,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5114",
    "Name": "SEKOLAH KEBANGSAAN TIGAMAN",
    "Student": 285,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5115",
    "Name": "SEKOLAH KEBANGSAAN TIMBANG BATU",
    "Student": 357,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5116",
    "Name": "SEKOLAH KEBANGSAAN SUNSUI",
    "Student": 156,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5117",
    "Name": "SEKOLAH KEBANGSAAN ONGKILAN",
    "Student": 234,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5118",
    "Name": "SEKOLAH KEBANGSAAN TEMUNO TERINGAI DARAT",
    "Student": 250,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5119",
    "Name": "SEKOLAH KEBANGSAAN MELANGKAP",
    "Student": 180,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5120",
    "Name": "SEKOLAH KEBANGSAAN TAGIBANG",
    "Student": 88,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5121",
    "Name": "SEKOLAH KEBANGSAAN MANGARIS",
    "Student": 135,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5122",
    "Name": "SEKOLAH KEBANGSAAN PANAITAN",
    "Student": 230,
    "Teacher": 25
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5124",
    "Name": "SEKOLAH KEBANGSAAN TUMUNDA SALIMANDUT",
    "Student": 399,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5125",
    "Name": "SEKOLAH KEBANGSAAN TALANTANG",
    "Student": 474,
    "Teacher": 36
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5126",
    "Name": "SEKOLAH KEBANGSAAN MANGIN",
    "Student": 85,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5127",
    "Name": "SEKOLAH KEBANGSAAN LAMPADA",
    "Student": 239,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5128",
    "Name": "SEKOLAH KEBANGSAAN KOROMOKO",
    "Student": 65,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5129",
    "Name": "SEKOLAH KEBANGSAAN GANA",
    "Student": 305,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5130",
    "Name": "SEKOLAH KEBANGSAAN RANAU",
    "Student": 321,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5131",
    "Name": "SEKOLAH KEBANGSAAN BINTASAN",
    "Student": 128,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5132",
    "Name": "SEKOLAH KEBANGSAAN KOTA MARUDU II",
    "Student": 464,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5201",
    "Name": "SEKOLAH KEBANGSAAN BAWING",
    "Student": 74,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5202",
    "Name": "SEKOLAH KEBANGSAAN DANDUN",
    "Student": 149,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5203",
    "Name": "SEKOLAH KEBANGSAAN KANIBONGAN",
    "Student": 544,
    "Teacher": 40
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5204",
    "Name": "SEKOLAH KEBANGSAAN KUSILAD",
    "Student": 263,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5205",
    "Name": "SEKOLAH KEBANGSAAN LIU",
    "Student": 115,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5206",
    "Name": "SEKOLAH KEBANGSAAN MAPAN-MAPAN",
    "Student": 107,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5207",
    "Name": "SEKOLAH KEBANGSAAN MALUBANG",
    "Student": 105,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5208",
    "Name": "SEKOLAH KEBANGSAAN PANTAI",
    "Student": 172,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5209",
    "Name": "SEKOLAH KEBANGSAAN PINGGAN-PINGGAN",
    "Student": 558,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5210",
    "Name": "SEKOLAH KEBANGSAAN PEKAN PITAS",
    "Student": 506,
    "Teacher": 39
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5211",
    "Name": "SEKOLAH KEBANGSAAN ROSOB",
    "Student": 285,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5212",
    "Name": "SEKOLAH KEBANGSAAN TELAGA",
    "Student": 137,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5213",
    "Name": "SEKOLAH KEBANGSAAN PANDAN MANDAMAI",
    "Student": 137,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5214",
    "Name": "SEKOLAH KEBANGSAAN PINAPAK",
    "Student": 203,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5215",
    "Name": "SEKOLAH KEBANGSAAN SENAJA",
    "Student": 89,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5216",
    "Name": "SEKOLAH KEBANGSAAN SALIMPODON DARAT",
    "Student": 223,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5217",
    "Name": "SEKOLAH KEBANGSAAN MANGKAPON PITAS",
    "Student": 147,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5218",
    "Name": "SEKOLAH KEBANGSAAN BONGKOL PITAS",
    "Student": 322,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5219",
    "Name": "SEKOLAH KEBANGSAAN BAWANG PITAS",
    "Student": 161,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5220",
    "Name": "SEKOLAH KEBANGSAAN KASAGAAN",
    "Student": 137,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5221",
    "Name": "SEKOLAH KEBANGSAAN MARINGGAN PITAS",
    "Student": 84,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5222",
    "Name": "SEKOLAH KEBANGSAAN MANGGIS/MONGKUBOU",
    "Student": 132,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5223",
    "Name": "SEKOLAH KEBANGSAAN MANDURIAN",
    "Student": 152,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5224",
    "Name": "SEKOLAH KEBANGSAAN KIBUBUK / SAHABAT SATU",
    "Student": 127,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5225",
    "Name": "SEKOLAH KEBANGSAAN NIBANG",
    "Student": 55,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5226",
    "Name": "SEKOLAH KEBANGSAAN DALLAS PITAS",
    "Student": 62,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5227",
    "Name": "SEKOLAH KEBANGSAAN RUKOM",
    "Student": 193,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XBA5228",
    "Name": "SEKOLAH KEBANGSAAN MAGANDAI",
    "Student": 121,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5229",
    "Name": "SEKOLAH KEBANGSAAN DATONG",
    "Student": 173,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5230",
    "Name": "SEKOLAH KEBANGSAAN BANGKAU-BANGKAU",
    "Student": 102,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5231",
    "Name": "SEKOLAH KEBANGSAAN SOSOP",
    "Student": 122,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XBA5232",
    "Name": "SEKOLAH KEBANGSAAN PEKAN PITAS II",
    "Student": 537,
    "Teacher": 50
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5301",
    "Name": "SEKOLAH KEBANGSAAN AMBONG",
    "Student": 99,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5302",
    "Name": "SEKOLAH KEBANGSAAN ROSOK/BENGKAHAK LAMA",
    "Student": 300,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5303",
    "Name": "SEKOLAH KEBANGSAAN BANGKAHAK BARU",
    "Student": 134,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5306",
    "Name": "SEKOLAH KEBANGSAAN DALAS KOTA BELUD",
    "Student": 105,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5307",
    "Name": "SEKOLAH KEBANGSAAN DUDAR",
    "Student": 82,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5308",
    "Name": "SEKOLAH KEBANGSAAN GENSURAI",
    "Student": 81,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5309",
    "Name": "SEKOLAH KEBANGSAAN KEGURAAN",
    "Student": 150,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5310",
    "Name": "SEKOLAH KEBANGSAAN LABUAN",
    "Student": 263,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5311",
    "Name": "SEKOLAH KEBANGSAAN KAUNG",
    "Student": 79,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5312",
    "Name": "SEKOLAH KEBANGSAAN KEBAYAU",
    "Student": 79,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5313",
    "Name": "SEKOLAH KEBANGSAAN KELAWAT",
    "Student": 89,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5314",
    "Name": "SEKOLAH KEBANGSAAN KESAPANG",
    "Student": 181,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5315",
    "Name": "SEKOLAH KEBANGSAAN KIAU I",
    "Student": 188,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5316",
    "Name": "SEKOLAH KEBANGSAAN KINASARABAN KOTA BELUD",
    "Student": 274,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5317",
    "Name": "SEKOLAH KEBANGSAAN PEKAN KOTA BELUD",
    "Student": 735,
    "Teacher": 69
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5320",
    "Name": "SEKOLAH KEBANGSAAN KULAMBAI",
    "Student": 495,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5321",
    "Name": "SEKOLAH KEBANGSAAN LASAU PODI",
    "Student": 226,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5322",
    "Name": "SEKOLAH KEBANGSAAN MELANGKAP KBELUD",
    "Student": 161,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5323",
    "Name": "SEKOLAH KEBANGSAAN MENGKULAT",
    "Student": 46,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5324",
    "Name": "SEKOLAH KEBANGSAAN NAHABA",
    "Student": 192,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5325",
    "Name": "SEKOLAH KEBANGSAAN NANAMUN",
    "Student": 278,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5326",
    "Name": "SEKOLAH KEBANGSAAN NARINANG",
    "Student": 64,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5328",
    "Name": "SEKOLAH KEBANGSAAN PANDASAN",
    "Student": 169,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5329",
    "Name": "SEKOLAH KEBANGSAAN PELADOK",
    "Student": 187,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5330",
    "Name": "SEKOLAH KEBANGSAAN PIASAU",
    "Student": 301,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5331",
    "Name": "SEKOLAH KEBANGSAAN PITURU",
    "Student": 113,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5332",
    "Name": "SEKOLAH KEBANGSAAN PULAU MANTANANI",
    "Student": 105,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5333",
    "Name": "SEKOLAH KEBANGSAAN JAWI-JAWI",
    "Student": 246,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5334",
    "Name": "SEKOLAH KEBANGSAAN RANGALAU",
    "Student": 87,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5335",
    "Name": "SEKOLAH KEBANGSAAN SAYAP",
    "Student": 136,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5336",
    "Name": "SEKOLAH KEBANGSAAN SEMBIRAI",
    "Student": 503,
    "Teacher": 55
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5340",
    "Name": "SEKOLAH KEBANGSAAN SUANG PUNGGOR",
    "Student": 180,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5341",
    "Name": "SEKOLAH KEBANGSAAN TABURAN",
    "Student": 97,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5343",
    "Name": "SEKOLAH KEBANGSAAN TAMAU",
    "Student": 409,
    "Teacher": 37
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5344",
    "Name": "SEKOLAH KEBANGSAAN TAMBATUAN",
    "Student": 64,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5345",
    "Name": "SEKOLAH KEBANGSAAN TAMBULIAN",
    "Student": 263,
    "Teacher": 23
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5346",
    "Name": "SEKOLAH KEBANGSAAN TAMPASUK I",
    "Student": 380,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5347",
    "Name": "SEKOLAH KEBANGSAAN TAMU DARAT",
    "Student": 222,
    "Teacher": 23
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5348",
    "Name": "SEKOLAH KEBANGSAAN TENGKURUS",
    "Student": 142,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5349",
    "Name": "SEKOLAH KEBANGSAAN TARINTIDON",
    "Student": 71,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5350",
    "Name": "SEKOLAH KEBANGSAAN TAUN GUSI",
    "Student": 267,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5352",
    "Name": "SEKOLAH KEBANGSAAN TIMBANG",
    "Student": 207,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5353",
    "Name": "SEKOLAH KEBANGSAAN TIMBANG DAYANG",
    "Student": 331,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5354",
    "Name": "SEKOLAH KEBANGSAAN TUGUSON",
    "Student": 85,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5355",
    "Name": "SEKOLAH KEBANGSAAN TAMPASUK II",
    "Student": 231,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5356",
    "Name": "SEKOLAH KEBANGSAAN SARANG",
    "Student": 111,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5358",
    "Name": "SEKOLAH KEBANGSAAN RAMPAYAN ULU",
    "Student": 126,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5360",
    "Name": "SEKOLAH KEBANGSAAN LASAU TINTAPON",
    "Student": 65,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5361",
    "Name": "SEKOLAH KEBANGSAAN KUALA ABAI",
    "Student": 320,
    "Teacher": 36
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5362",
    "Name": "SEKOLAH KEBANGSAAN PODOS",
    "Student": 80,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5363",
    "Name": "SEKOLAH KEBANGSAAN PANGKALAN ABAI",
    "Student": 206,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5364",
    "Name": "SEKOLAH KEBANGSAAN MENUNGGUI",
    "Student": 210,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5365",
    "Name": "SEKOLAH KEBANGSAAN PINOLOBU",
    "Student": 115,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XBA5366",
    "Name": "SEKOLAH KEBANGSAAN ULU KUKUT",
    "Student": 111,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5401",
    "Name": "SEKOLAH KEBANGSAAN BADUKAN",
    "Student": 70,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5402",
    "Name": "SEKOLAH KEBANGSAAN BONGKUD",
    "Student": 187,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5403",
    "Name": "SEKOLAH KEBANGSAAN BUNDU TUHAN",
    "Student": 101,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5404",
    "Name": "SEKOLAH KEBANGSAAN GANA-GANA",
    "Student": 28,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5405",
    "Name": "SEKOLAH KEBANGSAAN KAMPONG LIBANG",
    "Student": 204,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5406",
    "Name": "SEKOLAH KEBANGSAAN KEMBURONGOH",
    "Student": 69,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5407",
    "Name": "SEKOLAH KEBANGSAAN KANANAPON",
    "Student": 102,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5408",
    "Name": "SEKOLAH KEBANGSAAN KERANAAN",
    "Student": 77,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5409",
    "Name": "SEKOLAH KEBANGSAAN KIMONDOU",
    "Student": 199,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5410",
    "Name": "SEKOLAH KEBANGSAAN KITUNTUL",
    "Student": 147,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5412",
    "Name": "SEKOLAH KEBANGSAAN KUNDASANG",
    "Student": 522,
    "Teacher": 49
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5413",
    "Name": "SEKOLAH KEBANGSAAN LONGUT",
    "Student": 20,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5414",
    "Name": "SEKOLAH KEBANGSAAN LANGSAT",
    "Student": 98,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5415",
    "Name": "SEKOLAH KEBANGSAAN LIPASU",
    "Student": 199,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5416",
    "Name": "SEKOLAH KEBANGSAAN LOHAN",
    "Student": 608,
    "Teacher": 49
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5417",
    "Name": "SEKOLAH KEBANGSAAN MATUPANG",
    "Student": 148,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5418",
    "Name": "SEKOLAH KEBANGSAAN MAUKAB",
    "Student": 54,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5419",
    "Name": "SEKOLAH KEBANGSAAN MARAKAU",
    "Student": 147,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5420",
    "Name": "SEKOLAH KEBANGSAAN MIRURU",
    "Student": 142,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5421",
    "Name": "SEKOLAH KEBANGSAAN MOHIMBOYON",
    "Student": 97,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5422",
    "Name": "SEKOLAH KEBANGSAAN NALAPAK",
    "Student": 148,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5423",
    "Name": "SEKOLAH KEBANGSAAN NAMPASAN",
    "Student": 80,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5424",
    "Name": "SEKOLAH KEBANGSAAN NAPONG",
    "Student": 129,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5425",
    "Name": "SEKOLAH KEBANGSAAN NARAWANG",
    "Student": 153,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5427",
    "Name": "SEKOLAH KEBANGSAAN PAGINATAN",
    "Student": 129,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5428",
    "Name": "SEKOLAH KEBANGSAAN PAUS",
    "Student": 93,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5430",
    "Name": "SEKOLAH KEBANGSAAN PERANCANGAN",
    "Student": 125,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5431",
    "Name": "SEKOLAH KEBANGSAAN PINAUSUK",
    "Student": 212,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5432",
    "Name": "SEKOLAH KEBANGSAAN PINAWANTAI RANAU",
    "Student": 183,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5433",
    "Name": "SEKOLAH KEBANGSAAN PORING",
    "Student": 163,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5434",
    "Name": "SEKOLAH KEBANGSAAN PEKAN RANAU",
    "Student": 604,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5437",
    "Name": "SEKOLAH KEBANGSAAN RANDAGONG",
    "Student": 91,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5438",
    "Name": "SEKOLAH KEBANGSAAN RATAU",
    "Student": 47,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5439",
    "Name": "SEKOLAH KEBANGSAAN SAGINDAI",
    "Student": 104,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5442",
    "Name": "SEKOLAH KEBANGSAAN TAGUDON LAMA",
    "Student": 48,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5443",
    "Name": "SEKOLAH KEBANGSAAN TAMPIOS",
    "Student": 101,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5444",
    "Name": "SEKOLAH KEBANGSAAN TIMBUA",
    "Student": 303,
    "Teacher": 32
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5445",
    "Name": "SEKOLAH KEBANGSAAN TINANOM",
    "Student": 74,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5446",
    "Name": "SEKOLAH KEBANGSAAN TOBOH",
    "Student": 71,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5447",
    "Name": "SEKOLAH KEBANGSAAN TOGOP DARAT",
    "Student": 75,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5448",
    "Name": "SEKOLAH KEBANGSAAN TONGOU",
    "Student": 68,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5449",
    "Name": "SEKOLAH KEBANGSAAN TUDAN",
    "Student": 39,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5450",
    "Name": "SEKOLAH KEBANGSAAN WAANG",
    "Student": 136,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5451",
    "Name": "SEKOLAH KEBANGSAAN KINIRASAN",
    "Student": 56,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5452",
    "Name": "SEKOLAH KEBANGSAAN KIROKOT",
    "Student": 79,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5453",
    "Name": "SEKOLAH KEBANGSAAN NUNUK RAGANG",
    "Student": 145,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5454",
    "Name": "SEKOLAH KEBANGSAAN TIBABAR",
    "Student": 243,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5455",
    "Name": "SEKOLAH KEBANGSAAN KANDAWAYON",
    "Student": 117,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5456",
    "Name": "SEKOLAH KEBANGSAAN TIANG",
    "Student": 21,
    "Teacher": 7
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5457",
    "Name": "SEKOLAH KEBANGSAAN PAHU HIMBAAN",
    "Student": 91,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5458",
    "Name": "SEKOLAH KEBANGSAAN KEPANGIAN",
    "Student": 89,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5460",
    "Name": "SEKOLAH KEBANGSAAN KAULUAN",
    "Student": 98,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5461",
    "Name": "SEKOLAH KEBANGSAAN SAGIBAN",
    "Student": 118,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5462",
    "Name": "SEKOLAH KEBANGSAAN NARADAN (NAWANON)",
    "Student": 132,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5463",
    "Name": "SEKOLAH KEBANGSAAN PEKAN DUA",
    "Student": 396,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5464",
    "Name": "SEKOLAH KEBANGSAAN KINAPULIDAN",
    "Student": 185,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5465",
    "Name": "SEKOLAH KEBANGSAAN TARAWAS",
    "Student": 118,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5466",
    "Name": "SEKOLAH KEBANGSAAN KILIMU",
    "Student": 513,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5467",
    "Name": "SEKOLAH KEBANGSAAN KINASARABAN",
    "Student": 137,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5468",
    "Name": "SEKOLAH KEBANGSAAN NUKAKATAN",
    "Student": 56,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5469",
    "Name": "SEKOLAH KEBANGSAAN SRI GABUNGAN",
    "Student": 147,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5470",
    "Name": "SEKOLAH KEBANGSAAN GUSI",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XBA5471",
    "Name": "SEKOLAH KEBANGSAAN MESILOU",
    "Student": 189,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6002",
    "Name": "SEKOLAH KEBANGSAAN BANGKALALAK",
    "Student": 109,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6003",
    "Name": "SEKOLAH KEBANGSAAN BATANDOK",
    "Student": 108,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6005",
    "Name": "SEKOLAH KEBANGSAAN BENTUKA",
    "Student": 69,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6007",
    "Name": "SEKOLAH KEBANGSAAN BUKAU",
    "Student": 153,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6010",
    "Name": "SEKOLAH KEBANGSAAN GADONG",
    "Student": 215,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6012",
    "Name": "SEKOLAH KEBANGSAAN GARAMA",
    "Student": 65,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6013",
    "Name": "SEKOLAH KEBANGSAAN KABAJANG",
    "Student": 91,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6014",
    "Name": "SEKOLAH KEBANGSAAN KEBATU",
    "Student": 111,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6015",
    "Name": "SEKOLAH KEBANGSAAN KEBULU",
    "Student": 75,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6017",
    "Name": "SEKOLAH KEBANGSAAN BIAH BATU 65",
    "Student": 69,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6019",
    "Name": "SEKOLAH KEBANGSAAN KEPAWA",
    "Student": 58,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6020",
    "Name": "SEKOLAH KEBANGSAAN KOTA KLIAS",
    "Student": 78,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6022",
    "Name": "SEKOLAH KEBANGSAAN KLIAS BARU",
    "Student": 462,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6023",
    "Name": "SEKOLAH KEBANGSAAN KLIAS KECIL",
    "Student": 65,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6025",
    "Name": "SEKOLAH KEBANGSAAN KUKURO",
    "Student": 18,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6028",
    "Name": "SEKOLAH KEBANGSAAN LAGO",
    "Student": 69,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6031",
    "Name": "SEKOLAH KEBANGSAAN LINGKUNGAN",
    "Student": 107,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6032",
    "Name": "SEKOLAH KEBANGSAAN LUAGAN",
    "Student": 141,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6033",
    "Name": "SEKOLAH KEBANGSAAN LUBOK",
    "Student": 82,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6034",
    "Name": "SEKOLAH KEBANGSAAN LUMAT",
    "Student": 130,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6035",
    "Name": "SEKOLAH KEBANGSAAN LADANG-LUMADAN",
    "Student": 133,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6036",
    "Name": "SEKOLAH KEBANGSAAN LUPAK",
    "Student": 166,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6037",
    "Name": "SEKOLAH KEBANGSAAN MARABA",
    "Student": 85,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6040",
    "Name": "SEKOLAH KEBANGSAAN MEMPAGAR",
    "Student": 366,
    "Teacher": 35
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6041",
    "Name": "SEKOLAH KEBANGSAAN BATU 60",
    "Student": 74,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6042",
    "Name": "SEKOLAH KEBANGSAAN NUKAHAN",
    "Student": 39,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6043",
    "Name": "SEKOLAH KEBANGSAAN PADAS DAMIT",
    "Student": 115,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6047",
    "Name": "SEKOLAH KEBANGSAAN RANCANGAN KLIAS",
    "Student": 150,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6057",
    "Name": "SEKOLAH KEBANGSAAN SUASA",
    "Student": 77,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6059",
    "Name": "SEKOLAH KEBANGSAAN TAKULI",
    "Student": 84,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6060",
    "Name": "SEKOLAH KEBANGSAAN WESTON",
    "Student": 155,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6062",
    "Name": "SEKOLAH KEBANGSAAN PEKAN BEAUFORT",
    "Student": 526,
    "Teacher": 59
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6063",
    "Name": "SEKOLAH KEBANGSAAN LAJAU",
    "Student": 176,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6064",
    "Name": "SEKOLAH KEBANGSAAN KARANGAN",
    "Student": 24,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6067",
    "Name": "SEKOLAH KEBANGSAAN JABANG",
    "Student": 43,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6068",
    "Name": "SEKOLAH KEBANGSAAN PINTAS",
    "Student": 152,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6101",
    "Name": "SEKOLAH KEBANGSAAN TIONG BARU",
    "Student": 36,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6102",
    "Name": "SEKOLAH KEBANGSAAN BINSULOK",
    "Student": 271,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6103",
    "Name": "SEKOLAH KEBANGSAAN KG BAMBANGAN",
    "Student": 92,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6104",
    "Name": "SEKOLAH KEBANGSAAN KG BRUNEI",
    "Student": 153,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6105",
    "Name": "SEKOLAH KEBANGSAAN LEMBAH PORING",
    "Student": 51,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6106",
    "Name": "SEKOLAH KEBANGSAAN PEKAN MEMBAKUT",
    "Student": 287,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6107",
    "Name": "SEKOLAH KEBANGSAAN SINOKO",
    "Student": 35,
    "Teacher": 10
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6108",
    "Name": "SEKOLAH KEBANGSAAN TAHAK",
    "Student": 56,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6109",
    "Name": "SEKOLAH KEBANGSAAN TAMALANG",
    "Student": 46,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6110",
    "Name": "SEKOLAH KEBANGSAAN PENGIRAN JAYA PIMPING",
    "Student": 137,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA6111",
    "Name": "SEKOLAH KEBANGSAAN PEKAN BONGAWAN",
    "Student": 525,
    "Teacher": 59
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA6112",
    "Name": "SEKOLAH KEBANGSAAN SUMBILING",
    "Student": 158,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA6113",
    "Name": "SEKOLAH KEBANGSAAN VIGING ULU",
    "Student": 78,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA6114",
    "Name": "SEKOLAH KEBANGSAAN ULU LUMAGAR",
    "Student": 66,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA6115",
    "Name": "SEKOLAH KEBANGSAAN KIMANIS",
    "Student": 308,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6116",
    "Name": "SEKOLAH KEBANGSAAN BANDAU",
    "Student": 111,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA6117",
    "Name": "SEKOLAH KEBANGSAAN MANDAHAN",
    "Student": 152,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XBA6118",
    "Name": "SEKOLAH KEBANGSAAN SAGA-SAGA",
    "Student": 180,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XBA6119",
    "Name": "SEKOLAH KEBANGSAAN NYARIS-NYARIS",
    "Student": 323,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6201",
    "Name": "SEKOLAH KEBANGSAAN BANTING",
    "Student": 83,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6204",
    "Name": "SEKOLAH KEBANGSAAN KEBAWANG",
    "Student": 58,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6205",
    "Name": "SEKOLAH KEBANGSAAN KAWANG",
    "Student": 84,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6206",
    "Name": "SEKOLAH KEBANGSAAN KUALA MENGALONG",
    "Student": 62,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6207",
    "Name": "SEKOLAH KEBANGSAAN LONG PASIA",
    "Student": 49,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6208",
    "Name": "SEKOLAH KEBANGSAAN LUBANG BUAYA",
    "Student": 74,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6209",
    "Name": "SEKOLAH KEBANGSAAN LUBOK DARAT",
    "Student": 71,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6210",
    "Name": "SEKOLAH KEBANGSAAN MELAMAM",
    "Student": 121,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6211",
    "Name": "SEKOLAH KEBANGSAAN MENDULONG",
    "Student": 96,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6212",
    "Name": "SEKOLAH KEBANGSAAN MELALIA",
    "Student": 114,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6213",
    "Name": "SEKOLAH KEBANGSAAN MELIGAN",
    "Student": 94,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6214",
    "Name": "SEKOLAH KEBANGSAAN MERINTAMAN",
    "Student": 171,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6215",
    "Name": "SEKOLAH KEBANGSAAN MESAPOL",
    "Student": 156,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6216",
    "Name": "SEKOLAH KEBANGSAAN PANTAI",
    "Student": 95,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6217",
    "Name": "SEKOLAH KEBANGSAAN PELAKAT",
    "Student": 177,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6219",
    "Name": "SEKOLAH KEBANGSAAN SINDUMIN",
    "Student": 186,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6220",
    "Name": "SEKOLAH KEBANGSAAN PEKAN SIPITANG",
    "Student": 772,
    "Teacher": 62
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6223",
    "Name": "SEKOLAH KEBANGSAAN ULU SIPITANG",
    "Student": 168,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6224",
    "Name": "SEKOLAH KEBANGSAAN PADANG BERAMPAH",
    "Student": 411,
    "Teacher": 42
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6225",
    "Name": "SEKOLAH KEBANGSAAN SOLOB",
    "Student": 83,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6226",
    "Name": "SEKOLAH KEBANGSAAN ULU BOLE",
    "Student": 38,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XBA6227",
    "Name": "SEKOLAH KEBANGSAAN KELANGSAT",
    "Student": 99,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6301",
    "Name": "SEKOLAH KEBANGSAAN BATU LINTING",
    "Student": 46,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6302",
    "Name": "SEKOLAH KEBANGSAAN BERANGKOK",
    "Student": 78,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6304",
    "Name": "SEKOLAH KEBANGSAAN JANANG",
    "Student": 17,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6305",
    "Name": "SEKOLAH KEBANGSAAN KERUKAN",
    "Student": 90,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6307",
    "Name": "SEKOLAH KEBANGSAAN KILUGUS",
    "Student": 90,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6308",
    "Name": "SEKOLAH KEBANGSAAN LAMBIDAN",
    "Student": 37,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6309",
    "Name": "SEKOLAH KEBANGSAAN PEKAN KUALA PENYU",
    "Student": 163,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6311",
    "Name": "SEKOLAH KEBANGSAAN MANSUD",
    "Student": 55,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6312",
    "Name": "SEKOLAH KEBANGSAAN MELIKAI",
    "Student": 139,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6313",
    "Name": "SEKOLAH KEBANGSAAN PEKAN MENUMBOK",
    "Student": 262,
    "Teacher": 35
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6315",
    "Name": "SEKOLAH KEBANGSAAN MENUMPANG",
    "Student": 48,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6316",
    "Name": "SEKOLAH KEBANGSAAN MENUNGGANG",
    "Student": 143,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6317",
    "Name": "SEKOLAH KEBANGSAAN PALU-PALU",
    "Student": 148,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6319",
    "Name": "SEKOLAH KEBANGSAAN SINAPOKAN",
    "Student": 107,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6325",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG ARU",
    "Student": 51,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6326",
    "Name": "SEKOLAH KEBANGSAAN TEMPURONG",
    "Student": 54,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6327",
    "Name": "SEKOLAH KEBANGSAAN TENAMBAK",
    "Student": 119,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6328",
    "Name": "SEKOLAH KEBANGSAAN SANGKABOK",
    "Student": 94,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6329",
    "Name": "SEKOLAH KEBANGSAAN KEKAPOR",
    "Student": 58,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XBA6330",
    "Name": "SEKOLAH KEBANGSAAN RIMBAAN",
    "Student": 69,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XBC1012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG MING",
    "Student": 194,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XBC2004",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG MIN",
    "Student": 242,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XBC4002",
    "Name": "SEKOLAH KEBANGSAAN (CINA) ANGLO-CHINESE",
    "Student": 1301,
    "Teacher": 58
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XCA1043",
    "Name": "SEKOLAH KEBANGSAAN ST.FRANCIS XAVIER KENINGAU",
    "Student": 1054,
    "Teacher": 70
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XCA1046",
    "Name": "SEKOLAH KEBANGSAAN ST JAMES APIN-APIN",
    "Student": 255,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XCA1213",
    "Name": "SEKOLAH KEBANGSAAN ST DAVID TOBOH",
    "Student": 305,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XCA1214",
    "Name": "SEKOLAH KEBANGSAAN NAMBAYAN",
    "Student": 284,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XCA1216",
    "Name": "SEKOLAH KEBANGSAAN ST THERESA",
    "Student": 397,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCA1333",
    "Name": "SEKOLAH KEBANGSAAN ST ANTHONY",
    "Student": 274,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCA1335",
    "Name": "SEKOLAH KEBANGSAAN ST PIUS PAMILAAN",
    "Student": 140,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCA2039",
    "Name": "SEKOLAH KEBANGSAAN PASIR PUTIH",
    "Student": 233,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCA2040",
    "Name": "SEKOLAH KEBANGSAAN LUNG MANIS",
    "Student": 131,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCA2041",
    "Name": "SEKOLAH KEBANGSAAN ST MARY CONVENT",
    "Student": 611,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCA2042",
    "Name": "SEKOLAH KEBANGSAAN ST MARY TOWN",
    "Student": 510,
    "Teacher": 39
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCA2043",
    "Name": "SEKOLAH KEBANGSAAN ST MARY LABUK",
    "Student": 199,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XCA2044",
    "Name": "SEKOLAH KEBANGSAAN LADANG TOMANGGONG",
    "Student": 101,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCA2047",
    "Name": "SEKOLAH KEBANGSAAN ST MONICA",
    "Student": 708,
    "Teacher": 44
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCA2053",
    "Name": "SEKOLAH KEBANGSAAN SUNG SIEW",
    "Student": 849,
    "Teacher": 54
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XCA2105",
    "Name": "SEKOLAH KEBANGSAAN HOLY CROSS",
    "Student": 109,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XCA2132",
    "Name": "SEKOLAH KEBANGSAAN LADANG SABAPALM",
    "Student": 84,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XCA2201",
    "Name": "SEKOLAH KEBANGSAAN LADANG BODE KRETAM",
    "Student": 88,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XCA2205",
    "Name": "SEKOLAH KEBANGSAAN PEKAN TONGOD",
    "Student": 461,
    "Teacher": 39
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3001",
    "Name": "SEKOLAH KEBANGSAAN ABAKA",
    "Student": 82,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3007",
    "Name": "SEKOLAH KEBANGSAAN BOMBALAI",
    "Student": 368,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3010",
    "Name": "SEKOLAH KEBANGSAAN BRUMAS",
    "Student": 232,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3011",
    "Name": "SEKOLAH KEBANGSAAN MELATI",
    "Student": 111,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3016",
    "Name": "SEKOLAH KEBANGSAAN HOLY TRINITY",
    "Student": 754,
    "Teacher": 52
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3029",
    "Name": "SEKOLAH KEBANGSAAN LUASONG",
    "Student": 152,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3031",
    "Name": "SEKOLAH KEBANGSAAN MEROTAI KECIL",
    "Student": 239,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3043",
    "Name": "SEKOLAH KEBANGSAAN ST PATRICK",
    "Student": 726,
    "Teacher": 55
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3053",
    "Name": "SEKOLAH KEBANGSAAN LADANG TIGER",
    "Student": 67,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3055",
    "Name": "SEKOLAH KEBANGSAAN WALLACE BAY",
    "Student": 107,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3056",
    "Name": "SEKOLAH RENDAH ISLAMIAH",
    "Student": 701,
    "Teacher": 50
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCA3057",
    "Name": "SEKOLAH RENDAH AGAMA KINABUTAN",
    "Student": 284,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XCA3106",
    "Name": "SEKOLAH KEBANGSAAN BAKAPIT",
    "Student": 166,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XCA3107",
    "Name": "SEKOLAH KEBANGSAAN KENNEDY BAY",
    "Student": 188,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XCA3126",
    "Name": "SEKOLAH KEBANGSAAN ST DOMINIC",
    "Student": 713,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XCA3128",
    "Name": "SEKOLAH KEBANGSAAN ST STEPHENS",
    "Student": 428,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XCA3304",
    "Name": "SEKOLAH KEBANGSAAN LADANG SEGARIA",
    "Student": 154,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCA4024",
    "Name": "SEKOLAH KEBANGSAAN LOK YUK INANAM",
    "Student": 422,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCA4026",
    "Name": "SEKOLAH KEBANGSAAN LOK YUK LIKAS",
    "Student": 807,
    "Teacher": 54
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCA4039",
    "Name": "SEKOLAH KEBANGSAAN SACRED HEART",
    "Student": 551,
    "Teacher": 40
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCA4047",
    "Name": "SEKOLAH KEBANGSAAN ST AGNES",
    "Student": 855,
    "Teacher": 58
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCA4048",
    "Name": "SEKOLAH KEBANGSAAN ST CATHERINE",
    "Student": 218,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCA4050",
    "Name": "SEKOLAH KEBANGSAAN ST FRANCIS CONVENT",
    "Student": 666,
    "Teacher": 52
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCA4057",
    "Name": "SEKOLAH KEBANGSAAN STELLA MARIS",
    "Student": 961,
    "Teacher": 50
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XCA4114",
    "Name": "SEKOLAH KEBANGSAAN ST ALOYSIUS LIMBANAK",
    "Student": 371,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XCA4115",
    "Name": "SEKOLAH KEBANGSAAN ST ANTHONY",
    "Student": 413,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XCA4116",
    "Name": "SEKOLAH KEBANGSAAN ST JOSEPH",
    "Student": 520,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XCA4118",
    "Name": "SEKOLAH KEBANGSAAN ST PAUL KOLOPIS",
    "Student": 260,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XCA4119",
    "Name": "SEKOLAH KEBANGSAAN ST THERESA INOBONG",
    "Student": 259,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCA4235",
    "Name": "SEKOLAH KEBANGSAAN OUR LADY",
    "Student": 128,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCA4246",
    "Name": "SEKOLAH KEBANGSAAN SACRED HEART KG BIAU",
    "Student": 126,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCA4252",
    "Name": "SEKOLAH KEBANGSAAN ST JOSEPH",
    "Student": 243,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCA4254",
    "Name": "SEKOLAH KEBANGSAAN ST MARY",
    "Student": 258,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XCA4301",
    "Name": "SEKOLAH AGAMA SURIA",
    "Student": 152,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XCA4351",
    "Name": "SEKOLAH KEBANGSAAN LOK YUK TELIPOK",
    "Student": 518,
    "Teacher": 37
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XCA4352",
    "Name": "SEKOLAH KEBANGSAAN ST JOHN",
    "Student": 672,
    "Teacher": 40
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XCA4369",
    "Name": "SEKOLAH KEBANGSAAN TOMBONGON",
    "Student": 102,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XCA4401",
    "Name": "SEKOLAH KEBANGSAAN ST JAMES TENGHILAN TAMPARULI",
    "Student": 193,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCA5022",
    "Name": "SEKOLAH KEBANGSAAN LAJONG",
    "Student": 139,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCA5023",
    "Name": "SEKOLAH KEBANGSAAN LOK YUK BATU 1 KUDAT.",
    "Student": 398,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCA5027",
    "Name": "SEKOLAH KEBANGSAAN LODUNG KUDAT",
    "Student": 110,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCA5029",
    "Name": "SEKOLAH KEBANGSAAN LOK YUK TAMALANG KUDAT",
    "Student": 137,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCA5030",
    "Name": "SEKOLAH KEBANGSAAN LOK YUK SIKUATI",
    "Student": 208,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCA5063",
    "Name": "SEKOLAH KEBANGSAAN ST JAMES",
    "Student": 465,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCA5064",
    "Name": "SEKOLAH KEBANGSAAN ST PETER KUDAT",
    "Student": 643,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCA5078",
    "Name": "SEKOLAH KEBANGSAAN TINANGOL",
    "Student": 328,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XCA5339",
    "Name": "SEKOLAH KEBANGSAAN ST EDMUND",
    "Student": 202,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XCA5342",
    "Name": "SEKOLAH KEBANGSAAN TAGINAMBUR",
    "Student": 229,
    "Teacher": 24
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XCA5440",
    "Name": "SEKOLAH KEBANGSAAN ST BENEDICT",
    "Student": 424,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XCA5441",
    "Name": "SEKOLAH KEBANGSAAN DON BOSCO",
    "Student": 137,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XCA6050",
    "Name": "SEKOLAH KEBANGSAAN ST.JOHN",
    "Student": 212,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XCA6055",
    "Name": "SEKOLAH KEBANGSAAN ST PAUL",
    "Student": 311,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XCA6101",
    "Name": "SEKOLAH KEBANGSAAN ST PATRICK",
    "Student": 196,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XCA6320",
    "Name": "SEKOLAH KEBANGSAAN ST. AUGUSTINE",
    "Student": 19,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XCA6321",
    "Name": "SEKOLAH KEBANGSAAN ST JOSEPH",
    "Student": 103,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XCA6322",
    "Name": "SEKOLAH KEBANGSAAN ST. PETER BUNDU",
    "Student": 216,
    "Teacher": 24
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XCA6324",
    "Name": "SEKOLAH KEBANGSAAN ST. STEPHEN",
    "Student": 64,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XCC1049",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK KONG",
    "Student": 600,
    "Teacher": 38
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XCC1050",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK YIN",
    "Student": 1382,
    "Teacher": 72
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XCC1222",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUH MIN",
    "Student": 390,
    "Teacher": 24
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCC1303",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI VUN ENTABUAN",
    "Student": 71,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCC1304",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HWA",
    "Student": 241,
    "Teacher": 23
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCC1305",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 265,
    "Teacher": 25
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCC1315",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN",
    "Student": 129,
    "Teacher": 17
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCC1324",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PADA",
    "Student": 63,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCC1327",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHUI YING",
    "Student": 70,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCC1341",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) VUN SHIN",
    "Student": 79,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCC1342",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK NAM",
    "Student": 71,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCC1343",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK SYN",
    "Student": 46,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XCC1346",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TSI SIN",
    "Student": 265,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCC2006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHI HWA",
    "Student": 728,
    "Teacher": 61
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCC2013",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YUK",
    "Student": 173,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCC2016",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MING CHUNG",
    "Student": 158,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCC2020",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEA WHA",
    "Student": 198,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCC2022",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI YING",
    "Student": 178,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCC2023",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PUI GIN",
    "Student": 783,
    "Teacher": 48
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCC2056",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SYN HUA",
    "Student": 606,
    "Teacher": 47
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCC2058",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAI TONG",
    "Student": 484,
    "Teacher": 39
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XCC2063",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHOI",
    "Student": 507,
    "Teacher": 32
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCC3012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 309,
    "Teacher": 28
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCC3014",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HING HWA",
    "Student": 738,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCC3026",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG MING",
    "Student": 362,
    "Teacher": 29
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCC3027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUOK MING",
    "Student": 271,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCC3034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PHUI YUK",
    "Student": 87,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCC3041",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HWA",
    "Student": 757,
    "Teacher": 62
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XCC3057",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHIN",
    "Student": 1106,
    "Teacher": 82
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XCC3104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE VUN",
    "Student": 401,
    "Teacher": 25
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XCC3109",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KIAU SHING",
    "Student": 88,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XCC3124",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIEW CHING",
    "Student": 549,
    "Teacher": 38
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XCC3125",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN WAH",
    "Student": 239,
    "Teacher": 23
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XCC3134",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHOI",
    "Student": 621,
    "Teacher": 36
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XCC3201",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAI SHENG",
    "Student": 140,
    "Teacher": 16
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XCC3311",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NYUK HWA",
    "Student": 251,
    "Teacher": 23
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCC4001",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA LIKAS",
    "Student": 1571,
    "Teacher": 90
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCC4006",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA KG AIR K KINABALU",
    "Student": 2101,
    "Teacher": 92
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCC4009",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) GOOD SHEPHERD MENGGATAL",
    "Student": 472,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCC4025",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YUK LIKAS",
    "Student": 735,
    "Teacher": 39
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCC4030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YUK MENGGATAL",
    "Student": 607,
    "Teacher": 35
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCC4044",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SHAN TAO",
    "Student": 1953,
    "Teacher": 78
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCC4052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST JAMES KOTA KINABALU",
    "Student": 1301,
    "Teacher": 64
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCC4054",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST PETER TELIPOK",
    "Student": 502,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCC4066",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YICK NAM",
    "Student": 940,
    "Teacher": 46
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XCC4104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA SHIONG",
    "Student": 868,
    "Teacher": 51
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XCC4124",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUE MIN",
    "Student": 1348,
    "Teacher": 70
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCC4202",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ANGLO CHINESE",
    "Student": 156,
    "Teacher": 12
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCC4211",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG HWA",
    "Student": 291,
    "Teacher": 25
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCC4212",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG MING",
    "Student": 95,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCC4216",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA YIN RAMPAZAN",
    "Student": 62,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCC4225",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KIN KIAU",
    "Student": 415,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCC4248",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SEN MING",
    "Student": 35,
    "Teacher": 8
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCC4251",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST JOSEPH",
    "Student": 376,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCC4258",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG SHAN",
    "Student": 159,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XCC4309",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEN SIN",
    "Student": 608,
    "Teacher": 37
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XCC4323",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KOK WAH TALIBONG",
    "Student": 140,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XCC4403",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST PHILLIP",
    "Student": 195,
    "Teacher": 18
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XCC4404",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA TAMPARULI",
    "Student": 378,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XCC4412",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA TENGHILAN",
    "Student": 152,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCC5012",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HWA LIAN",
    "Student": 179,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCC5031",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YUK PINANGSOO KUDAT",
    "Student": 101,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCC5034",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YUK BATU 1 KUDAT",
    "Student": 170,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCC5042",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) OUR LADY IMMACULATE KUDAT",
    "Student": 143,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCC5052",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SACRED HEART TAJAU KUDAT",
    "Student": 116,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCC5065",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST PETER",
    "Student": 197,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XCC5080",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HWA TEMALANG",
    "Student": 27,
    "Teacher": 9
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XCC5122",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KHOI MING",
    "Student": 694,
    "Teacher": 36
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XCC5305",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 547,
    "Teacher": 38
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XCC5429",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PAI WEN",
    "Student": 450,
    "Teacher": 26
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XCC6008",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHE HWA",
    "Student": 445,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XCC6027",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG MING (1)",
    "Student": 590,
    "Teacher": 37
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XCC6030",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LIAN HWA",
    "Student": 136,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XCC6101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PEI YIN",
    "Student": 192,
    "Teacher": 19
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XCC6102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BONG HWA",
    "Student": 99,
    "Teacher": 14
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XCC6202",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA SIPITANG",
    "Student": 197,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XCC6203",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA MESAPOL",
    "Student": 116,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XCC6218",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIN BOON SINDUMIN",
    "Student": 44,
    "Teacher": 11
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XCC6303",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA",
    "Student": 226,
    "Teacher": 15
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XCC6318",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PUI HWA",
    "Student": 66,
    "Teacher": 13
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XCT3301",
    "Name": "SEKOLAH AGAMA TUN SAKARAN",
    "Student": 329,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XEA1001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TULID",
    "Student": 892,
    "Teacher": 53
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XEA1057",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN APIN-APIN",
    "Student": 918,
    "Teacher": 66
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XEA1058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KENINGAU",
    "Student": 1631,
    "Teacher": 119
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XEA1059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KENINGAU II",
    "Student": 1773,
    "Teacher": 138
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XEA1060",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SOOK",
    "Student": 1391,
    "Teacher": 101
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XEA1061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUNSANAD II",
    "Student": 1176,
    "Teacher": 82
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XEA1111",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NABAWAN",
    "Student": 1417,
    "Teacher": 86
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XEA1112",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEPULOT NABAWAN",
    "Student": 802,
    "Teacher": 54
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENSIANGAN",
    "Kod": "XEA1113",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NABAWAN II",
    "Student": 802,
    "Teacher": 48
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XEA1219",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMBUNAN",
    "Student": 1100,
    "Teacher": 117
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XEA1220",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NAMBAYAN",
    "Student": 1022,
    "Teacher": 85
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XEA1221",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DESA WAWASAN",
    "Student": 227,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XEA1222",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHINTA MATA",
    "Student": 484,
    "Teacher": 38
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XEA1345",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEMABONG",
    "Student": 1524,
    "Teacher": 101
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XEA1346",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ENTABUAN",
    "Student": 960,
    "Teacher": 62
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ELOPURA",
    "Student": 1830,
    "Teacher": 128
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU SAPI",
    "Student": 1842,
    "Teacher": 104
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2073",
    "Name": "KOLEJ TINGKATAN ENAM DATUK PENGIRAN GALPAM",
    "Student": 263,
    "Teacher": 84
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUM-GUM",
    "Student": 704,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUHIBBAH",
    "Student": 1218,
    "Teacher": 95
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2076",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN FAJAR",
    "Student": 924,
    "Teacher": 54
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2077",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN",
    "Student": 769,
    "Teacher": 49
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XEA2078",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TONGOD",
    "Student": 711,
    "Teacher": 53
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XEA2079",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PARIS",
    "Student": 545,
    "Teacher": 39
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2080",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MERPATI",
    "Student": 1058,
    "Teacher": 68
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2081",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEGALIUD",
    "Student": 772,
    "Teacher": 54
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2082",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LIBARAN",
    "Student": 612,
    "Teacher": 32
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2083",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ELOPURA DUA",
    "Student": 1077,
    "Teacher": 86
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2084",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SANDAKAN DUA",
    "Student": 854,
    "Teacher": 73
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEA2085",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINUSA",
    "Student": 961,
    "Teacher": 54
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XEA2140",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ULU SUGUT (MALINSAU)",
    "Student": 890,
    "Teacher": 60
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XEA2141",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELUPID",
    "Student": 1326,
    "Teacher": 100
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XEA2142",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TERUSAN SUGUT",
    "Student": 230,
    "Teacher": 21
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XEA2143",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAMOL",
    "Student": 721,
    "Teacher": 56
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XEA2144",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIMPANGAN",
    "Student": 1049,
    "Teacher": 55
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XEA2145",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BALABAN JAYA",
    "Student": 300,
    "Teacher": 22
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XEA2146",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ULU SAPI",
    "Student": 504,
    "Teacher": 33
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XEA2147",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BELURAN II",
    "Student": 421,
    "Teacher": 35
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XEA2220",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT GARAM",
    "Student": 1272,
    "Teacher": 84
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XEA2224",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENANGAH",
    "Student": 680,
    "Teacher": 50
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XEA2225",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUKAU",
    "Student": 648,
    "Teacher": 35
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TELUPID/TONGOD",
    "Kod": "XEA2226",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ENTILIBON",
    "Student": 926,
    "Teacher": 73
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KINABATANGAN",
    "Kod": "XEA2227",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT GARAM II",
    "Student": 948,
    "Teacher": 60
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3053",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BALUNG",
    "Student": 1801,
    "Teacher": 131
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3062",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KINABUTAN",
    "Student": 1238,
    "Teacher": 101
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3064",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MEROTAI BESAR",
    "Student": 1286,
    "Teacher": 102
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3066",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN APAS",
    "Student": 753,
    "Teacher": 72
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3067",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN UMAS-UMAS",
    "Student": 842,
    "Teacher": 70
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3068",
    "Name": "KOLEJ TINGKATAN ENAM TAWAU",
    "Student": 405,
    "Teacher": 109
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3069",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KALABAKAN",
    "Student": 524,
    "Teacher": 38
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3070",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PASIR PUTIH",
    "Student": 1105,
    "Teacher": 95
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3071",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TITINGAN",
    "Student": 900,
    "Teacher": 63
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3072",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN WAKUBA",
    "Student": 495,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3073",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAWAU II",
    "Student": 645,
    "Teacher": 42
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3074",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JAMBATAN PUTIH",
    "Student": 734,
    "Teacher": 67
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEA3075",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KINABUTAN 2",
    "Student": 832,
    "Teacher": 54
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XEA3101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNGKU",
    "Student": 1159,
    "Teacher": 87
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XEA3102",
    "Name": "SEKOLAH MENENGAH SAINS LAHAD DATU",
    "Student": 529,
    "Teacher": 51
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XEA3103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SILABUKAN",
    "Student": 1017,
    "Teacher": 71
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XEA3104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEPAGAYA",
    "Student": 1908,
    "Teacher": 149
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XEA3105",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DESA KENCANA",
    "Student": 1645,
    "Teacher": 116
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XEA3106",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEGAMA",
    "Student": 979,
    "Teacher": 72
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XEA3107",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TERUSAN",
    "Student": 1352,
    "Teacher": 88
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XEA3201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUNAK JAYA",
    "Student": 1008,
    "Teacher": 72
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XEA3202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MADAI",
    "Student": 1104,
    "Teacher": 93
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XEA3329",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUGAYA",
    "Student": 1630,
    "Teacher": 127
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XEA3330",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUM-BUM",
    "Student": 1756,
    "Teacher": 140
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XEA3331",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAGASAN",
    "Student": 1283,
    "Teacher": 90
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XEA3332",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KABOGAN",
    "Student": 689,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XEA3333",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KABOGAN II",
    "Student": 673,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XEA3334",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUGAYA II",
    "Student": 1090,
    "Teacher": 69
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XEA3335",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK HAJI PANGLIMA JAKARULLAH",
    "Student": 1915,
    "Teacher": 121
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEA4039",
    "Name": "SEKOLAH MENENGAH SAINS SABAH",
    "Student": 590,
    "Teacher": 52
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEA4040",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SANZAC",
    "Student": 1420,
    "Teacher": 102
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEA4041",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN INANAM",
    "Student": 1472,
    "Teacher": 99
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEA4042",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PULAU GAYA",
    "Student": 880,
    "Teacher": 51
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEA4043",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN",
    "Student": 647,
    "Teacher": 50
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEA4044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN TUN FUAD",
    "Student": 828,
    "Teacher": 66
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEA4045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOLOMBONG",
    "Student": 2004,
    "Teacher": 122
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEA4046",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEBOBON",
    "Student": 2319,
    "Teacher": 127
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEA4047",
    "Name": "SEKOLAH SUKAN MALAYSIA SABAH",
    "Student": 215,
    "Teacher": 63
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEA4048",
    "Name": "KOLEJ TINGKATAN ENAM KOTA KINABALU",
    "Student": 319,
    "Teacher": 79
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XEA4127",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK PETER MOJUNTIN",
    "Student": 1450,
    "Teacher": 105
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XEA4128",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAHANG",
    "Student": 2038,
    "Teacher": 135
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XEA4129",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANSAU",
    "Student": 2203,
    "Teacher": 143
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XEA4130",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LIMBANAK",
    "Student": 1262,
    "Teacher": 115
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XEA4261",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KINARUT",
    "Student": 2263,
    "Teacher": 157
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XEA4262",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BENONI",
    "Student": 926,
    "Teacher": 89
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XEA4263",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAKIS",
    "Student": 1056,
    "Teacher": 99
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XEA4264",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KIMANIS",
    "Student": 581,
    "Teacher": 42
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XEA4265",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGALAT PAPAR",
    "Student": 914,
    "Teacher": 59
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XEA4301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEKAN TELIPOK",
    "Student": 1630,
    "Teacher": 79
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XEA4302",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI NANGKA",
    "Student": 1223,
    "Teacher": 75
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XEA4303",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN RIA",
    "Student": 950,
    "Teacher": 68
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XEA4401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENGHILAN",
    "Student": 878,
    "Teacher": 90
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XEA4402",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI DAMIT",
    "Student": 543,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XEA5001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUDAT",
    "Student": 624,
    "Teacher": 69
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XEA5004",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIKUATI",
    "Student": 867,
    "Teacher": 76
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XEA5021",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANGGI",
    "Student": 973,
    "Teacher": 73
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XEA5022",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PINAWANTAI",
    "Student": 995,
    "Teacher": 78
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XEA5023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUDAT II",
    "Student": 484,
    "Teacher": 65
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XEA5024",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MATUNGGONG",
    "Student": 713,
    "Teacher": 47
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XEA5025",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIKUATI II",
    "Student": 640,
    "Teacher": 52
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XEA5026",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ABD. RAHIM II",
    "Student": 625,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XEA5101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANDEK",
    "Student": 1389,
    "Teacher": 115
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XEA5102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BENGKONGAN",
    "Student": 927,
    "Teacher": 68
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XEA5103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAU",
    "Student": 1088,
    "Teacher": 71
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XEA5104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LANGKON",
    "Student": 650,
    "Teacher": 52
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XEA5105",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOTA MARUDU II",
    "Student": 1338,
    "Teacher": 104
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XEA5106",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TANDEK 2",
    "Student": 587,
    "Teacher": 38
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XEA5216",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PITAS",
    "Student": 1120,
    "Teacher": 95
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XEA5217",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PINGGAN-PINGGAN",
    "Student": 1176,
    "Teacher": 90
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XEA5218",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KANIBUNGAN",
    "Student": 596,
    "Teacher": 39
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XEA5219",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELAGA",
    "Student": 353,
    "Teacher": 36
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XEA5220",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BONGKOL",
    "Student": 335,
    "Teacher": 27
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PITAS",
    "Kod": "XEA5221",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PITAS II",
    "Student": 742,
    "Teacher": 73
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XEA5327",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NARINANG",
    "Student": 1425,
    "Teacher": 113
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XEA5351",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAUN GUSI",
    "Student": 993,
    "Teacher": 94
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XEA5352",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN USUKAN",
    "Student": 1089,
    "Teacher": 88
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XEA5353",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEKAN KOTA BELUD",
    "Student": 1057,
    "Teacher": 83
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XEA5354",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMBULION",
    "Student": 999,
    "Teacher": 61
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XEA5355",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEKAN II KOTA BELUD",
    "Student": 1040,
    "Teacher": 75
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XEA5356",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAUN GUSI II",
    "Student": 773,
    "Teacher": 53
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XEA5401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TIMBUA",
    "Student": 675,
    "Teacher": 39
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XEA5402",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUNDU TUHAN",
    "Student": 606,
    "Teacher": 47
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XEA5403",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUNDASANG",
    "Student": 900,
    "Teacher": 87
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XEA5404",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LOHAN",
    "Student": 1462,
    "Teacher": 120
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XEA5405",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MATUPANG JAYA",
    "Student": 770,
    "Teacher": 58
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XEA5406",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEMBURONGOH RANAU",
    "Student": 441,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XEA5407",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RANAU",
    "Student": 835,
    "Teacher": 66
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XEA6065",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BEAUFORT",
    "Student": 744,
    "Teacher": 86
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XEA6066",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BEAUFORT II",
    "Student": 664,
    "Teacher": 61
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XEA6067",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BEAUFORT III",
    "Student": 280,
    "Teacher": 30
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XEA6100",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MEMBAKUT II",
    "Student": 533,
    "Teacher": 50
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XEA6201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADANG BERAMPAH",
    "Student": 557,
    "Teacher": 51
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XEA6202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGIRAN OMAR II",
    "Student": 585,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XEA6203",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SINDUMIN",
    "Student": 563,
    "Teacher": 47
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XEA6314",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MENUMBOK",
    "Student": 512,
    "Teacher": 46
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XEA6315",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PEKAN KUALA PENYU",
    "Student": 543,
    "Teacher": 56
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XEE1008",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BINGKOR",
    "Student": 1169,
    "Teacher": 102
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XEE1023",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GUNSANAD",
    "Student": 1477,
    "Teacher": 152
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XEE1338",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TENOM",
    "Student": 799,
    "Teacher": 68
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XEE2031",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SANDAKAN",
    "Student": 1624,
    "Teacher": 110
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BELURAN",
    "Kod": "XEE2103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BELURAN",
    "Student": 1448,
    "Teacher": 105
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEE3024",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUHARA",
    "Student": 2009,
    "Teacher": 132
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XEE3052",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAWAU",
    "Student": 1766,
    "Teacher": 107
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XEE3117",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGASEH",
    "Student": 2432,
    "Teacher": 161
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUNAK",
    "Kod": "XEE3201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUNAK",
    "Student": 1358,
    "Teacher": 100
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XEE3318",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK PANGLIMA ABDULLAH",
    "Student": 1601,
    "Teacher": 107
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEE4020",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LIKAS",
    "Student": 1083,
    "Teacher": 80
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEE4032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDARAYA KOTA KINABALU",
    "Student": 2501,
    "Teacher": 129
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XEE4038",
    "Name": "SEKOLAH MENENGAH MAKTAB SABAH",
    "Student": 1184,
    "Teacher": 102
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XEE4110",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUTATAN",
    "Student": 1700,
    "Teacher": 136
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XEE4239",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAJAKIR",
    "Student": 1039,
    "Teacher": 91
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XEE4322",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN FUAD STEPHENS",
    "Student": 981,
    "Teacher": 90
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XEE4374",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BADIN",
    "Student": 1950,
    "Teacher": 147
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XEE4401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMPARULI",
    "Student": 1897,
    "Teacher": 143
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XEE5019",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ABDUL RAHIM",
    "Student": 871,
    "Teacher": 84
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA MARUDU",
    "Kod": "XEE5118",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOTA MARUDU",
    "Student": 1543,
    "Teacher": 135
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XEE5318",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ARSHAD",
    "Student": 1186,
    "Teacher": 111
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XEE5435",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MAT SALLEH RANAU",
    "Student": 1551,
    "Teacher": 142
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XEE6011",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GADONG",
    "Student": 948,
    "Teacher": 80
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XEE6021",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOTA KLIAS",
    "Student": 356,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XEE6061",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN WESTON",
    "Student": 323,
    "Teacher": 36
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XEE6101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MEMBAKUT",
    "Student": 595,
    "Teacher": 61
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XEE6102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BONGAWAN",
    "Student": 891,
    "Teacher": 82
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SIPITANG",
    "Kod": "XEE6221",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENGIRAN OMAR",
    "Student": 890,
    "Teacher": 105
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XEE6310",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUALA PENYU",
    "Student": 416,
    "Teacher": 51
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XFE1021",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEN HWA",
    "Student": 1448,
    "Teacher": 105
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XFE1044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. FRANCIS XAVIER",
    "Student": 883,
    "Teacher": 55
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAMBUNAN",
    "Kod": "XFE1215",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST MARTIN",
    "Student": 659,
    "Teacher": 49
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XFE1306",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA",
    "Student": 683,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TENOM",
    "Kod": "XFE1334",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST ANTHONY",
    "Student": 439,
    "Teacher": 31
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XFE2032",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TIONG HUA",
    "Student": 1298,
    "Teacher": 71
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XFE2037",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KONVEN ST CECILIA",
    "Student": 969,
    "Teacher": 65
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XFE2044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST MARY SANDAKAN",
    "Student": 1118,
    "Teacher": 62
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XFE2045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST MICHAEL",
    "Student": 819,
    "Teacher": 58
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XFE2054",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNG SIEW",
    "Student": 1339,
    "Teacher": 78
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XFE3002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ABAKA",
    "Student": 816,
    "Teacher": 84
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XFE3017",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HOLY TRINITY",
    "Student": 753,
    "Teacher": 51
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XFE3044",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST PATRICK",
    "Student": 1387,
    "Teacher": 75
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XFE3045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST URSULA",
    "Student": 488,
    "Teacher": 46
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XFE3059",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN WALLACE BAY",
    "Student": 259,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XFE3127",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST DOMINIC",
    "Student": 1125,
    "Teacher": 68
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XFE4001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ALL SAINTS",
    "Student": 1514,
    "Teacher": 87
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XFE4021",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI KOTA KINABALU",
    "Student": 2280,
    "Teacher": 116
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XFE4022",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LA SALLE TG ARU",
    "Student": 1109,
    "Teacher": 72
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XFE4027",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LOK YUK LIKAS",
    "Student": 1876,
    "Teacher": 108
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XFE4045",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SHAN TAO",
    "Student": 951,
    "Teacher": 56
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XFE4051",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST FRANCIS CONVENT",
    "Student": 776,
    "Teacher": 48
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XFE4055",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST PETER TELIPOK",
    "Student": 847,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XFE4058",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN STELLA MARIS",
    "Student": 800,
    "Teacher": 49
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PENAMPANG",
    "Kod": "XFE4117",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST MICHAEL",
    "Student": 899,
    "Teacher": 67
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XFE4253",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST JOSEPH",
    "Student": 947,
    "Teacher": 61
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XFE4255",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST MARY PAPAR",
    "Student": 905,
    "Teacher": 57
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XFE4353",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST JOHN",
    "Student": 794,
    "Teacher": 51
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TUARAN",
    "Kod": "XFE4401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST JAMES TENGHILAN",
    "Student": 245,
    "Teacher": 24
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XFE5036",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LOK YUK KUDAT",
    "Student": 651,
    "Teacher": 41
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XFE5066",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST PETER KUDAT",
    "Student": 555,
    "Teacher": 40
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XFE6051",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST JOHN",
    "Student": 355,
    "Teacher": 32
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XFE6056",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SAINT PAUL",
    "Student": 518,
    "Teacher": 38
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XFE6101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST PATRICK",
    "Student": 384,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUALA PENYU",
    "Kod": "XFE6313",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST PETER BUNDU",
    "Student": 445,
    "Teacher": 34
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XFT3001",
    "Name": "SM. ISLAMIAH TAWAU",
    "Student": 559,
    "Teacher": 40
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XFT5401",
    "Name": "SMA AL-IRSYADIAH MARAKAU RANAU",
    "Student": 183,
    "Teacher": 20
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XHA2001",
    "Name": "KOLEJ VOKASIONAL SANDAKAN",
    "Student": 278,
    "Teacher": 56
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD BEAUFORT",
    "Kod": "XHA3102",
    "Name": "KOLEJ VOKASIONAL BEAUFORT",
    "Student": 582,
    "Teacher": 108
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD LAHAD DATU",
    "Kod": "XHA3103",
    "Name": "KOLEJ VOKASIONAL LAHAD DATU",
    "Student": 592,
    "Teacher": 139
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KUDAT",
    "Kod": "XHA5002",
    "Name": "KOLEJ VOKASIONAL KUDAT",
    "Student": 734,
    "Teacher": 122
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XKA1099",
    "Name": "KOLEJ VOKASIONAL KENINGAU",
    "Student": 923,
    "Teacher": 176
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD TAWAU",
    "Kod": "XKA3099",
    "Name": "KOLEJ VOKASIONAL TAWAU",
    "Student": 985,
    "Teacher": 197
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XKB4001",
    "Name": "KOLEJ VOKASIONAL LIKAS",
    "Student": 831,
    "Teacher": 125
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KENINGAU",
    "Kod": "XRA1001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA KENINGAU",
    "Student": 648,
    "Teacher": 44
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SANDAKAN",
    "Kod": "XRA2001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN JUHAR",
    "Student": 657,
    "Teacher": 50
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD SEMPORNA",
    "Kod": "XRA3301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN SAKARAN",
    "Student": 602,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XRA4001",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA KIMANIS",
    "Student": 515,
    "Teacher": 62
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XRA4002",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN AHMADSHAH",
    "Student": 532,
    "Teacher": 45
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA KINABALU",
    "Kod": "XRA4003",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA KOTA KINABALU",
    "Student": 604,
    "Teacher": 106
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD PAPAR",
    "Kod": "XRA4201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN DATU MUSTAPHA LIMAUAN",
    "Student": 562,
    "Teacher": 49
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD KOTA BELUD",
    "Kod": "XRA5301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN SAID",
    "Student": 536,
    "Teacher": 43
  },
  {
    "NationalState": "SABAH",
    "PPD": "PPD RANAU",
    "Kod": "XRA5401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA MOHAMAD ALI RANAU",
    "Student": 575,
    "Teacher": 49
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBA1101",
    "Name": "SEKOLAH KEBANGSAAN APAR",
    "Student": 179,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBA1102",
    "Name": "SEKOLAH KEBANGSAAN PUAK",
    "Student": 68,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBA1103",
    "Name": "SEKOLAH KEBANGSAAN KG BOBAK/SEJINJANG",
    "Student": 108,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1201",
    "Name": "SEKOLAH KEBANGSAAN MERPATI JEPANG",
    "Student": 409,
    "Teacher": 39
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1202",
    "Name": "SEKOLAH KEBANGSAAN RANCANGAN PERUMAHAN RAKYAT 'RPR'",
    "Student": 818,
    "Teacher": 56
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1203",
    "Name": "SEKOLAH KEBANGSAAN SATRIA JAYA",
    "Student": 808,
    "Teacher": 48
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1204",
    "Name": "SEKOLAH KEBANGSAAN LAKSAMANA",
    "Student": 257,
    "Teacher": 34
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1205",
    "Name": "SEKOLAH KEBANGSAAN SG. MAONG HILIR KUCHING",
    "Student": 148,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1206",
    "Name": "SEKOLAH KEBANGSAAN MATANG JAYA",
    "Student": 1016,
    "Teacher": 71
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1207",
    "Name": "SEKOLAH KEBANGSAAN RPR BATU KAWA",
    "Student": 388,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1208",
    "Name": "SEKOLAH KEBANGSAAN JALAN ARANG",
    "Student": 698,
    "Teacher": 60
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1209",
    "Name": "SEKOLAH KEBANGSAAN SIOL KANAN",
    "Student": 620,
    "Teacher": 55
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1210",
    "Name": "SEKOLAH KEBANGSAAN TABUAN HILIR",
    "Student": 764,
    "Teacher": 62
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1211",
    "Name": "SEKOLAH KEBANGSAAN BANDAR SAMARIANG",
    "Student": 929,
    "Teacher": 64
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1212",
    "Name": "SEKOLAH KEBANGSAAN TAN SRI DATUK DR. HJ. SULAIMAN DAUD",
    "Student": 677,
    "Teacher": 55
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1302",
    "Name": "SEKOLAH KEBANGSAAN BERADEK",
    "Student": 180,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1304",
    "Name": "SEKOLAH KEBANGSAAN BATU KITANG",
    "Student": 198,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1307",
    "Name": "SEKOLAH KEBANGSAAN MUHIBBAH",
    "Student": 134,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1308",
    "Name": "SEKOLAH KEBANGSAAN PAYA MEBI",
    "Student": 238,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1311",
    "Name": "SEKOLAH KEBANGSAAN MATANG",
    "Student": 624,
    "Teacher": 42
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1312",
    "Name": "SEKOLAH KEBANGSAAN ENCIK BUYONG",
    "Student": 692,
    "Teacher": 50
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1313",
    "Name": "SEKOLAH KEBANGSAAN GERSIK",
    "Student": 428,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1314",
    "Name": "SEKOLAH KEBANGSAAN SANTUBONG",
    "Student": 172,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1317",
    "Name": "SEKOLAH KEBANGSAAN MUARA TEBAS",
    "Student": 312,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1319",
    "Name": "SEKOLAH KEBANGSAAN PASIR PANDAK",
    "Student": 204,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1321",
    "Name": "SEKOLAH KEBANGSAAN GITA",
    "Student": 663,
    "Teacher": 50
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1322",
    "Name": "SEKOLAH KEBANGSAAN PULO",
    "Student": 625,
    "Teacher": 50
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1324",
    "Name": "SEKOLAH KEBANGSAAN GOEBILT",
    "Student": 351,
    "Teacher": 32
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1325",
    "Name": "SEKOLAH KEBANGSAAN TABUAN",
    "Student": 470,
    "Teacher": 37
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1326",
    "Name": "SEKOLAH KEBANGSAAN SEMENGGOK",
    "Student": 887,
    "Teacher": 71
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1332",
    "Name": "SEKOLAH KEBANGSAAN ASTANA",
    "Student": 529,
    "Teacher": 54
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1333",
    "Name": "SEKOLAH KEBANGSAAN TEMENGGONG",
    "Student": 440,
    "Teacher": 33
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1334",
    "Name": "SEKOLAH KEBANGSAAN TG BAKO",
    "Student": 164,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1336",
    "Name": "SEKOLAH KEBANGSAAN TABUAN ULU",
    "Student": 233,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1337",
    "Name": "SEKOLAH KEBANGSAAN SALAK",
    "Student": 80,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1339",
    "Name": "SEKOLAH KEBANGSAAN SEMARIANG",
    "Student": 503,
    "Teacher": 47
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1341",
    "Name": "SEKOLAH KEBANGSAAN SG AUR",
    "Student": 7,
    "Teacher": 5
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1343",
    "Name": "SEKOLAH KEBANGSAAN TABUAN JAYA",
    "Student": 275,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1346",
    "Name": "SEKOLAH KEBANGSAAN RANTAU PANJANG",
    "Student": 174,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1347",
    "Name": "SEKOLAH KEBANGSAAN TAN SRI DATUK HAJI MOHAMED",
    "Student": 960,
    "Teacher": 73
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1348",
    "Name": "SEKOLAH KEBANGSAAN TELAGA AIR",
    "Student": 251,
    "Teacher": 23
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA1349",
    "Name": "SEKOLAH KEBANGSAAN WIRA JAYA",
    "Student": 997,
    "Teacher": 62
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1350",
    "Name": "SEKOLAH KEBANGSAAN SEMERAH PADI",
    "Student": 990,
    "Teacher": 79
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1351",
    "Name": "SEKOLAH KEBANGSAAN RAMPANGI",
    "Student": 469,
    "Teacher": 45
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBA1352",
    "Name": "SEKOLAH KEBANGSAAN PETRA JAYA",
    "Student": 852,
    "Teacher": 65
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBA1353",
    "Name": "SEKOLAH KEBANGSAAN MAJ GEN DATU IBRAHIM",
    "Student": 617,
    "Teacher": 51
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBA1401",
    "Name": "SEKOLAH KEBANGSAAN STUNGGANG MELAYU",
    "Student": 142,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBA1402",
    "Name": "SEKOLAH KEBANGSAAN TELOK MELANO",
    "Student": 41,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBA1403",
    "Name": "SEKOLAH KEBANGSAAN STOH",
    "Student": 46,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBA2103",
    "Name": "SEKOLAH KEBANGSAAN MUNGGU BRINGIN",
    "Student": 81,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBA2105",
    "Name": "SEKOLAH KEBANGSAAN PULAU SEDUKU",
    "Student": 33,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBA2106",
    "Name": "SEKOLAH KEBANGSAAN LELA PAHLAWAN",
    "Student": 99,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2108",
    "Name": "SEKOLAH KEBANGSAAN MALUDAM",
    "Student": 335,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBA2109",
    "Name": "SEKOLAH KEBANGSAAN ABANG AING",
    "Student": 542,
    "Teacher": 56
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBA2111",
    "Name": "SEKOLAH KEBANGSAAN BAKONG",
    "Student": 120,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBA2114",
    "Name": "SEKOLAH KEBANGSAAN KEM PAKIT",
    "Student": 100,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBA2116",
    "Name": "SEKOLAH KEBANGSAAN ENGKRANJI",
    "Student": 15,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBA2117",
    "Name": "SEKOLAH KEBANGSAAN APING",
    "Student": 79,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBA2118",
    "Name": "SEKOLAH KEBANGSAAN SRI AMAN",
    "Student": 276,
    "Teacher": 34
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2201",
    "Name": "SEKOLAH KEBANGSAAN TO' EMAN",
    "Student": 100,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2202",
    "Name": "SEKOLAH KEBANGSAAN KG EMPLAM",
    "Student": 57,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2203",
    "Name": "SEKOLAH KEBANGSAAN PERPAT",
    "Student": 134,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2204",
    "Name": "SEKOLAH KEBANGSAAN HJ JUNID",
    "Student": 161,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2205",
    "Name": "SEKOLAH KEBANGSAAN KG PALOH",
    "Student": 22,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2206",
    "Name": "SEKOLAH KEBANGSAAN ABG. MOH SESSANG",
    "Student": 128,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2207",
    "Name": "SEKOLAH KEBANGSAAN KPG ALIT",
    "Student": 99,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2208",
    "Name": "SEKOLAH KEBANGSAAN ABANG LEMAN",
    "Student": 667,
    "Teacher": 53
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2209",
    "Name": "SEKOLAH KEBANGSAAN ABANG ABDUL RAHMAN",
    "Student": 593,
    "Teacher": 56
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2210",
    "Name": "SEKOLAH KEBANGSAAN HAJI BOLLAH",
    "Student": 45,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2211",
    "Name": "SEKOLAH KEBANGSAAN KG KUPANG",
    "Student": 121,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2212",
    "Name": "SEKOLAH KEBANGSAAN SG PASIR",
    "Student": 92,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2213",
    "Name": "SEKOLAH KEBANGSAAN ULU SEBETAN",
    "Student": 44,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBA2301",
    "Name": "SEKOLAH KEBANGSAAN BATANG AI",
    "Student": 288,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBA2302",
    "Name": "SEKOLAH KEBANGSAAN NG DELOK",
    "Student": 30,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2401",
    "Name": "SEKOLAH KEBANGSAAN BALINGAN",
    "Student": 56,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2402",
    "Name": "SEKOLAH KEBANGSAAN MANGUT",
    "Student": 95,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2403",
    "Name": "SEKOLAH KEBANGSAAN SEREMBANG",
    "Student": 28,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2404",
    "Name": "SEKOLAH KEBANGSAAN KPG. BUDA",
    "Student": 59,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2405",
    "Name": "SEKOLAH KEBANGSAAN SEBEMBAN",
    "Student": 69,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2406",
    "Name": "SEKOLAH KEBANGSAAN SPINANG",
    "Student": 128,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2407",
    "Name": "SEKOLAH KEBANGSAAN SPAOH",
    "Student": 375,
    "Teacher": 45
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2408",
    "Name": "SEKOLAH KEBANGSAAN ABANG ABDUL KADIR",
    "Student": 392,
    "Teacher": 36
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2409",
    "Name": "SEKOLAH KEBANGSAAN BELADIN",
    "Student": 699,
    "Teacher": 50
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2410",
    "Name": "SEKOLAH KEBANGSAAN PUSA",
    "Student": 381,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2411",
    "Name": "SEKOLAH KEBANGSAAN DATUK BANDAR",
    "Student": 523,
    "Teacher": 41
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2412",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG ASSAM",
    "Student": 27,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2413",
    "Name": "SEKOLAH KEBANGSAAN TUI",
    "Student": 198,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2414",
    "Name": "SEKOLAH KEBANGSAAN SEMARANG",
    "Student": 146,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBA2415",
    "Name": "SEKOLAH KEBANGSAAN BATANG MARO",
    "Student": 68,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2416",
    "Name": "SEKOLAH KEBANGSAAN TAMBAK",
    "Student": 220,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2417",
    "Name": "SEKOLAH KEBANGSAAN SERABANG",
    "Student": 50,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2418",
    "Name": "SEKOLAH KEBANGSAAN SUPA",
    "Student": 226,
    "Teacher": 23
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2419",
    "Name": "SEKOLAH KEBANGSAAN BUNGIN",
    "Student": 117,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2420",
    "Name": "SEKOLAH KEBANGSAAN MEDANG",
    "Student": 114,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2421",
    "Name": "SEKOLAH KEBANGSAAN NG. SPAK",
    "Student": 49,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2422",
    "Name": "SEKOLAH KEBANGSAAN KALOK",
    "Student": 248,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBA2423",
    "Name": "SEKOLAH KEBANGSAAN NG. PASSA",
    "Student": 52,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBA3101",
    "Name": "SEKOLAH KEBANGSAAN KG BAHAGIA JAYA",
    "Student": 568,
    "Teacher": 39
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBA3102",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS SIBU",
    "Student": 17,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBA3103",
    "Name": "SEKOLAH KEBANGSAAN JALAN DESHON",
    "Student": 239,
    "Teacher": 32
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBA3104",
    "Name": "SEKOLAH KEBANGSAAN SEDC",
    "Student": 526,
    "Teacher": 43
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBA3105",
    "Name": "SEKOLAH KEBANGSAAN SENTOSA SIBU",
    "Student": 907,
    "Teacher": 67
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBA3201",
    "Name": "SEKOLAH KEBANGSAAN BATU 10 JALAN OYA",
    "Student": 663,
    "Teacher": 48
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBA3202",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG KEBUAW",
    "Student": 33,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBA3203",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG IGAN",
    "Student": 229,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBA3204",
    "Name": "SEKOLAH KEBANGSAAN SINGAT",
    "Student": 11,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBA3205",
    "Name": "SEKOLAH KEBANGSAAN SIBU JAYA",
    "Student": 1828,
    "Teacher": 100
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBA3206",
    "Name": "SEKOLAH KEBANGSAAN BUKIT ARIP",
    "Student": 86,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBA3301",
    "Name": "SEKOLAH KEBANGSAAN SENGAYAN",
    "Student": 17,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBA3400",
    "Name": "SEKOLAH KEBANGSAAN MUKAH",
    "Student": 367,
    "Teacher": 45
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBA3401",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG SAU",
    "Student": 106,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBA3402",
    "Name": "SEKOLAH KEBANGSAAN KG PETANAK",
    "Student": 205,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBA3403",
    "Name": "SEKOLAH KEBANGSAAN DUA SUNGAI",
    "Student": 165,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBA3404",
    "Name": "SEKOLAH KEBANGSAAN SG LIUK",
    "Student": 128,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBA3405",
    "Name": "SEKOLAH KEBANGSAAN SG KEMENA",
    "Student": 76,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBA3406",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI DUAN",
    "Student": 106,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBA3407",
    "Name": "SEKOLAH KEBANGSAAN SLDB LADANG 3",
    "Student": 253,
    "Teacher": 23
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBA3408",
    "Name": "SEKOLAH KEBANGSAAN DIJIH",
    "Student": 270,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBA3409",
    "Name": "SEKOLAH KEBANGSAAN PENAKUP ULU",
    "Student": 57,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBA3410",
    "Name": "SEKOLAH KEBANGSAAN SG BAYAN",
    "Student": 111,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBA3501",
    "Name": "SEKOLAH KEBANGSAAN BATU 36",
    "Student": 246,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4101",
    "Name": "SEKOLAH KEBANGSAAN JALAN BINTANG",
    "Student": 1098,
    "Teacher": 72
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4102",
    "Name": "SEKOLAH KEBANGSAAN RIAM BATU DUA",
    "Student": 387,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4103",
    "Name": "SEKOLAH KEBANGSAAN ANCHI",
    "Student": 844,
    "Teacher": 64
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4104",
    "Name": "SEKOLAH KEBANGSAAN KUALA BARAM",
    "Student": 66,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4105",
    "Name": "SEKOLAH KEBANGSAAN KG. BAKAM",
    "Student": 258,
    "Teacher": 23
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4106",
    "Name": "SEKOLAH KEBANGSAAN PUJUT CORNER",
    "Student": 841,
    "Teacher": 55
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4107",
    "Name": "SEKOLAH KEBANGSAAN KG. LUAK",
    "Student": 430,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4108",
    "Name": "SEKOLAH KEBANGSAAN PULAU MELAYU",
    "Student": 172,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4110",
    "Name": "SEKOLAH KEBANGSAAN TEMENGGONG DATUK MUIP",
    "Student": 1448,
    "Teacher": 68
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4111",
    "Name": "SEKOLAH KEBANGSAAN TUDAN",
    "Student": 1246,
    "Teacher": 73
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4112",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS MIRI",
    "Student": 34,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4113",
    "Name": "SEKOLAH KEBANGSAAN MERBAU",
    "Student": 1400,
    "Teacher": 70
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4114",
    "Name": "SEKOLAH KEBANGSAAN KUALA BARAM II",
    "Student": 471,
    "Teacher": 41
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4115",
    "Name": "SEKOLAH KEBANGSAAN SENADIN",
    "Student": 1181,
    "Teacher": 59
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4116",
    "Name": "SEKOLAH KEBANGSAAN MIRI",
    "Student": 515,
    "Teacher": 44
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4117",
    "Name": "SEKOLAH KEBANGSAAN TUDAN JAYA",
    "Student": 283,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBA4301",
    "Name": "SEKOLAH KEBANGSAAN LONG SAIT",
    "Student": 35,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBA4302",
    "Name": "SEKOLAH KEBANGSAAN LEPONG AJAI",
    "Student": 85,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBA4303",
    "Name": "SEKOLAH KEBANGSAAN LONG TERAN KANAN",
    "Student": 75,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBA4304",
    "Name": "SEKOLAH KEBANGSAAN LONG PELUTAN",
    "Student": 59,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBA4306",
    "Name": "SEKOLAH KEBANGSAAN LONG LUTENG",
    "Student": 172,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBA4307",
    "Name": "SEKOLAH KEBANGSAAN UMA BAWANG",
    "Student": 24,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBA4308",
    "Name": "SEKOLAH KEBANGSAAN LONG LAPOK",
    "Student": 345,
    "Teacher": 24
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBA4309",
    "Name": "SEKOLAH KEBANGSAAN LONG KEVOK",
    "Student": 114,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBA4310",
    "Name": "SEKOLAH KEBANGSAAN BATU BUNGAN",
    "Student": 71,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBA4311",
    "Name": "SEKOLAH KEBANGSAAN LONG JEKITAN",
    "Student": 160,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBA4401",
    "Name": "SEKOLAH KEBANGSAAN KUALA NYALAU",
    "Student": 65,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4402",
    "Name": "SEKOLAH KEBANGSAAN KELAPA SAWIT NO. 4",
    "Student": 224,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4403",
    "Name": "SEKOLAH KEBANGSAAN KPG. ANGUS",
    "Student": 46,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4404",
    "Name": "SEKOLAH KEBANGSAAN KPG. MASJID",
    "Student": 18,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4405",
    "Name": "SEKOLAH KEBANGSAAN BELIAU AHAD",
    "Student": 92,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4406",
    "Name": "SEKOLAH KEBANGSAAN KITA",
    "Student": 204,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4407",
    "Name": "SEKOLAH KEBANGSAAN BEKENU",
    "Student": 254,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4408",
    "Name": "SEKOLAH KEBANGSAAN TAWAKAL SATAP",
    "Student": 43,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4410",
    "Name": "SEKOLAH KEBANGSAAN ULU SATAP",
    "Student": 44,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4411",
    "Name": "SEKOLAH KEBANGSAAN KPG. SELANYAU",
    "Student": 93,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4412",
    "Name": "SEKOLAH KEBANGSAAN RANCANGAN SEPUPOK",
    "Student": 106,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4413",
    "Name": "SEKOLAH KEBANGSAAN RUMAH UNDI",
    "Student": 47,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4414",
    "Name": "SEKOLAH KEBANGSAAN SUAI 1",
    "Student": 290,
    "Teacher": 23
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4415",
    "Name": "SEKOLAH KEBANGSAAN RUMAH MENTALI",
    "Student": 67,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4416",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG BELIPAT",
    "Student": 85,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBA4417",
    "Name": "SEKOLAH KEBANGSAAN KELURU TENGAH",
    "Student": 117,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5101",
    "Name": "SEKOLAH KEBANGSAAN BUKIT LUBA",
    "Student": 88,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5102",
    "Name": "SEKOLAH KEBANGSAAN KUBONG",
    "Student": 39,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5103",
    "Name": "SEKOLAH KEBANGSAAN BATU EMPAT",
    "Student": 96,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5104",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG PAHLAWAN",
    "Student": 107,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5105",
    "Name": "SEKOLAH KEBANGSAAN LIMPAKU PINANG",
    "Student": 34,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5107",
    "Name": "SEKOLAH KEBANGSAAN TEDUNGAN (BAMBANGAN)",
    "Student": 96,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5108",
    "Name": "SEKOLAH KEBANGSAAN MELAYU PUSAT",
    "Student": 558,
    "Teacher": 47
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5109",
    "Name": "SEKOLAH KEBANGSAAN GADONG",
    "Student": 91,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5110",
    "Name": "SEKOLAH KEBANGSAAN BATU DANAU",
    "Student": 120,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5111",
    "Name": "SEKOLAH KEBANGSAAN LIMPAKI",
    "Student": 170,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5112",
    "Name": "SEKOLAH KEBANGSAAN TELAHAK",
    "Student": 82,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5113",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI POYAN",
    "Student": 195,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5114",
    "Name": "SEKOLAH KEBANGSAAN KUALA AWANG",
    "Student": 77,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5116",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN JAWA",
    "Student": 70,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5117",
    "Name": "SEKOLAH KEBANGSAAN MERAMBUT",
    "Student": 34,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5118",
    "Name": "SEKOLAH KEBANGSAAN TIGA KAMPUNG",
    "Student": 37,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5120",
    "Name": "SEKOLAH KEBANGSAAN TANJONG",
    "Student": 142,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5121",
    "Name": "SEKOLAH KEBANGSAAN LUBOK TEKUROK",
    "Student": 36,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5122",
    "Name": "SEKOLAH KEBANGSAAN MERITAM",
    "Student": 56,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5123",
    "Name": "SEKOLAH KEBANGSAAN BANDAR LIMBANG",
    "Student": 167,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBA5125",
    "Name": "SEKOLAH KEBANGSAAN LIMBANG",
    "Student": 378,
    "Teacher": 35
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5201",
    "Name": "SEKOLAH KEBANGSAAN KUALA LAWAS",
    "Student": 78,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5202",
    "Name": "SEKOLAH KEBANGSAAN MERAPOK",
    "Student": 119,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5203",
    "Name": "SEKOLAH KEBANGSAAN PUNANG",
    "Student": 134,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5204",
    "Name": "SEKOLAH KEBANGSAAN AWAT-AWAT",
    "Student": 151,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5205",
    "Name": "SEKOLAH KEBANGSAAN SUNDAR",
    "Student": 189,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5206",
    "Name": "SEKOLAH KEBANGSAAN TRUSAN",
    "Student": 97,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5207",
    "Name": "SEKOLAH KEBANGSAAN ULU MERAPOK",
    "Student": 185,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5208",
    "Name": "SEKOLAH KEBANGSAAN BATU LIMA",
    "Student": 58,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5209",
    "Name": "SEKOLAH KEBANGSAAN KG SEBERANG",
    "Student": 112,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5210",
    "Name": "SEKOLAH KEBANGSAAN KERANGAN",
    "Student": 75,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5211",
    "Name": "SEKOLAH KEBANGSAAN LUAGAN",
    "Student": 92,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5212",
    "Name": "SEKOLAH KEBANGSAAN ARU BARU",
    "Student": 78,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBA5213",
    "Name": "SEKOLAH KEBANGSAAN PURU SIA",
    "Student": 95,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBA6101",
    "Name": "SEKOLAH KEBANGSAAN ABANG BUYUK",
    "Student": 98,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBA6102",
    "Name": "SEKOLAH KEBANGSAAN SG SENTEBU",
    "Student": 60,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBA6103",
    "Name": "SEKOLAH KEBANGSAAN ABANG GESA",
    "Student": 288,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBA6104",
    "Name": "SEKOLAH KEBANGSAAN ORANG KAYA MUDA",
    "Student": 108,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBA6105",
    "Name": "SEKOLAH KEBANGSAAN ABANG HAJI MATAHIR",
    "Student": 388,
    "Teacher": 49
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBA6106",
    "Name": "SEKOLAH KEBANGSAAN ABANG GALAU",
    "Student": 89,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBA6107",
    "Name": "SEKOLAH KEBANGSAAN MANDOR ARIS",
    "Student": 82,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBA6109",
    "Name": "SEKOLAH KEBANGSAAN BUKIT KINYAU",
    "Student": 48,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBA6110",
    "Name": "SEKOLAH KEBANGSAAN STALON",
    "Student": 17,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBA6111",
    "Name": "SEKOLAH KEBANGSAAN SARIKEI",
    "Student": 413,
    "Teacher": 40
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBA6112",
    "Name": "SEKOLAH KEBANGSAAN TANJONG MANIS",
    "Student": 172,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBA6201",
    "Name": "SEKOLAH KEBANGSAAN ULU PEDANUM",
    "Student": 61,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBA6301",
    "Name": "SEKOLAH KEBANGSAAN KG SERDENG",
    "Student": 74,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBA6302",
    "Name": "SEKOLAH KEBANGSAAN MUPONG ULIN",
    "Student": 12,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBA6303",
    "Name": "SEKOLAH KEBANGSAAN SG SIAN",
    "Student": 39,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBA6401",
    "Name": "SEKOLAH KEBANGSAAN SALAH KECHIL",
    "Student": 49,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBA6402",
    "Name": "SEKOLAH KEBANGSAAN HIJRAH BADONG",
    "Student": 286,
    "Teacher": 37
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBA7101",
    "Name": "SEKOLAH KEBANGSAAN NG PERARAN",
    "Student": 30,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBA7102",
    "Name": "SEKOLAH KEBANGSAAN BEBANGAN",
    "Student": 14,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBA7103",
    "Name": "SEKOLAH KEBANGSAAN SG PAKU",
    "Student": 38,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBA7104",
    "Name": "SEKOLAH KEBANGSAAN OYAN TENGAH",
    "Student": 21,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBA7105",
    "Name": "SEKOLAH KEBANGSAAN RANTAU PANJAI",
    "Student": 53,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBA7107",
    "Name": "SEKOLAH KEBANGSAAN LUBOK BAYA",
    "Student": 22,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBA7108",
    "Name": "SEKOLAH KEBANGSAAN SG TUNOH",
    "Student": 63,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBA7109",
    "Name": "SEKOLAH KEBANGSAAN NG BAWAI",
    "Student": 162,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBA7110",
    "Name": "SEKOLAH KEBANGSAAN KAPIT",
    "Student": 653,
    "Teacher": 47
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBA7201",
    "Name": "SEKOLAH KEBANGSAAN NG TELAWAN",
    "Student": 23,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBA7203",
    "Name": "SEKOLAH KEBANGSAAN LONG URUN",
    "Student": 162,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBA7204",
    "Name": "SEKOLAH KEBANGSAAN LUSONG LAKU",
    "Student": 139,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBA7205",
    "Name": "SEKOLAH KEBANGSAAN TEGULANG",
    "Student": 144,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBA7206",
    "Name": "SEKOLAH KEBANGSAAN METALUN",
    "Student": 171,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8101",
    "Name": "SEKOLAH KEBANGSAAN MERANEK",
    "Student": 291,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8102",
    "Name": "SEKOLAH KEBANGSAAN MANG",
    "Student": 172,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8103",
    "Name": "SEKOLAH KEBANGSAAN SERPAN",
    "Student": 155,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8104",
    "Name": "SEKOLAH KEBANGSAAN MATA PARANG",
    "Student": 339,
    "Teacher": 37
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8105",
    "Name": "SEKOLAH KEBANGSAAN TAMBIRAT",
    "Student": 464,
    "Teacher": 40
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8106",
    "Name": "SEKOLAH KEBANGSAAN PINANG",
    "Student": 210,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8107",
    "Name": "SEKOLAH KEBANGSAAN DATO TRAOH MUARA TUANG",
    "Student": 767,
    "Teacher": 59
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8108",
    "Name": "SEKOLAH KEBANGSAAN KG BARU",
    "Student": 464,
    "Teacher": 41
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8109",
    "Name": "SEKOLAH KEBANGSAAN ENDAP",
    "Student": 233,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8110",
    "Name": "SEKOLAH KEBANGSAAN SAMBIR",
    "Student": 129,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8111",
    "Name": "SEKOLAH KEBANGSAAN TANJONG APONG",
    "Student": 160,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8112",
    "Name": "SEKOLAH KEBANGSAAN KG MELAYU",
    "Student": 246,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8113",
    "Name": "SEKOLAH KEBANGSAAN SEBANDI MATANG",
    "Student": 95,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8114",
    "Name": "SEKOLAH KEBANGSAAN TAMBAY",
    "Student": 112,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8115",
    "Name": "SEKOLAH KEBANGSAAN KG EMPILA",
    "Student": 296,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8116",
    "Name": "SEKOLAH KEBANGSAAN NIUP",
    "Student": 205,
    "Teacher": 23
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8117",
    "Name": "SEKOLAH KEBANGSAAN KG TANJUNG TUANG",
    "Student": 192,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8118",
    "Name": "SEKOLAH KEBANGSAAN MOYAN LAUT",
    "Student": 292,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8119",
    "Name": "SEKOLAH KEBANGSAAN SEBANDI ULU",
    "Student": 163,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8120",
    "Name": "SEKOLAH KEBANGSAAN DATO MOHD MUSA",
    "Student": 1099,
    "Teacher": 85
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBA8201",
    "Name": "SEKOLAH KEBANGSAAN TEPOI",
    "Student": 47,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBA8202",
    "Name": "SEKOLAH KEBANGSAAN MUBOK BERAWAN",
    "Student": 54,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBA8203",
    "Name": "SEKOLAH KEBANGSAAN TRIANG",
    "Student": 79,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8301",
    "Name": "SEKOLAH KEBANGSAAN LUBOK BUNTIN",
    "Student": 78,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8302",
    "Name": "SEKOLAH KEBANGSAAN JEMUKAN",
    "Student": 148,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8303",
    "Name": "SEKOLAH KEBANGSAAN TUNGKAH MELAYU",
    "Student": 111,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8304",
    "Name": "SEKOLAH KEBANGSAAN IBOI/PELANDUK",
    "Student": 177,
    "Teacher": 23
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8305",
    "Name": "SEKOLAH KEBANGSAAN ABANG KADIR GEDONG",
    "Student": 518,
    "Teacher": 52
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8306",
    "Name": "SEKOLAH KEBANGSAAN SG LADONG",
    "Student": 198,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8307",
    "Name": "SEKOLAH KEBANGSAAN KG GUMPEY",
    "Student": 49,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8308",
    "Name": "SEKOLAH KEBANGSAAN HJ BUJANG SEBANGAN",
    "Student": 198,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8309",
    "Name": "SEKOLAH KEBANGSAAN SG APIN",
    "Student": 82,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8310",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG PISANG SELANGKING",
    "Student": 54,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBA8311",
    "Name": "SEKOLAH KEBANGSAAN HAJI KELALI SEMERA",
    "Student": 449,
    "Teacher": 41
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8313",
    "Name": "SEKOLAH KEBANGSAAN ST ANDREW",
    "Student": 28,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8314",
    "Name": "SEKOLAH KEBANGSAAN SEGALI",
    "Student": 59,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8315",
    "Name": "SEKOLAH KEBANGSAAN SERUYUK",
    "Student": 62,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8316",
    "Name": "SEKOLAH KEBANGSAAN GAWANG EMPILI",
    "Student": 42,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8317",
    "Name": "SEKOLAH KEBANGSAAN RABA",
    "Student": 49,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8318",
    "Name": "SEKOLAH KEBANGSAAN TUANKU BAGUS",
    "Student": 451,
    "Teacher": 39
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8319",
    "Name": "SEKOLAH KEBANGSAAN KEDUMPAI",
    "Student": 76,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8320",
    "Name": "SEKOLAH KEBANGSAAN TEBELU",
    "Student": 151,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBA8321",
    "Name": "SEKOLAH KEBANGSAAN KLAIT",
    "Student": 38,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBA9101",
    "Name": "SEKOLAH KEBANGSAAN ORANG KAYA MOHAMMAD",
    "Student": 602,
    "Teacher": 48
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBA9102",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG JEPAK",
    "Student": 1430,
    "Teacher": 83
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBA9104",
    "Name": "SEKOLAH KEBANGSAAN KG BARU",
    "Student": 1151,
    "Teacher": 60
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBA9105",
    "Name": "SEKOLAH KEBANGSAAN PANDAN",
    "Student": 120,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBA9106",
    "Name": "SEKOLAH KEBANGSAAN SEBAUH",
    "Student": 421,
    "Teacher": 33
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBA9107",
    "Name": "SEKOLAH KEBANGSAAN BUKIT MAWANG",
    "Student": 22,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBA9109",
    "Name": "SEKOLAH KEBANGSAAN KIDURONG",
    "Student": 1154,
    "Teacher": 67
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBA9110",
    "Name": "SEKOLAH KEBANGSAAN BINTULU",
    "Student": 786,
    "Teacher": 49
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBA9111",
    "Name": "SEKOLAH KEBANGSAAN KIDURONG II",
    "Student": 1015,
    "Teacher": 60
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBA9112",
    "Name": "SEKOLAH KEBANGSAAN DESA LAVANG",
    "Student": 178,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBA9113",
    "Name": "SEKOLAH KEBANGSAAN ASYAKIRIN",
    "Student": 1225,
    "Teacher": 70
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBA9114",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG BATU",
    "Student": 1754,
    "Teacher": 94
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBA9201",
    "Name": "SEKOLAH KEBANGSAAN KUALA TATAU",
    "Student": 109,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBA9202",
    "Name": "SEKOLAH KEBANGSAAN NG PENYARAI",
    "Student": 71,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBA9203",
    "Name": "SEKOLAH KEBANGSAAN ULU KAKUS",
    "Student": 127,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBA9204",
    "Name": "SEKOLAH KEBANGSAAN KELAWIT",
    "Student": 291,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1101",
    "Name": "SEKOLAH KEBANGSAAN BUSO",
    "Student": 38,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1102",
    "Name": "SEKOLAH KEBANGSAAN OPAR",
    "Student": 167,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1103",
    "Name": "SEKOLAH KEBANGSAAN ATAS",
    "Student": 141,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1104",
    "Name": "SEKOLAH KEBANGSAAN SIMPANG KUDA",
    "Student": 179,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1105",
    "Name": "SEKOLAH KEBANGSAAN JAGOI",
    "Student": 174,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1106",
    "Name": "SEKOLAH KEBANGSAAN SINIAWAN",
    "Student": 94,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1107",
    "Name": "SEKOLAH KEBANGSAAN GROGO",
    "Student": 175,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1108",
    "Name": "SEKOLAH KEBANGSAAN TEMBAWANG",
    "Student": 120,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1109",
    "Name": "SEKOLAH KEBANGSAAN SERUMBU",
    "Student": 213,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1110",
    "Name": "SEKOLAH KEBANGSAAN SEGONG",
    "Student": 141,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1111",
    "Name": "SEKOLAH KEBANGSAAN GUMBANG",
    "Student": 72,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1112",
    "Name": "SEKOLAH KEBANGSAAN SERABAK",
    "Student": 71,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1113",
    "Name": "SEKOLAH KEBANGSAAN SEGUBANG",
    "Student": 54,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1114",
    "Name": "SEKOLAH KEBANGSAAN SKIBANG",
    "Student": 72,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1115",
    "Name": "SEKOLAH KEBANGSAAN BAU",
    "Student": 313,
    "Teacher": 35
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1116",
    "Name": "SEKOLAH KEBANGSAAN SEBOBOK",
    "Student": 58,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1117",
    "Name": "SEKOLAH KEBANGSAAN TRINGGUS",
    "Student": 85,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1118",
    "Name": "SEKOLAH KEBANGSAAN SUBA BUAN",
    "Student": 48,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1119",
    "Name": "SEKOLAH KEBANGSAAN PEDAUN BAWAH",
    "Student": 101,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1120",
    "Name": "SEKOLAH KEBANGSAAN SENIBONG",
    "Student": 65,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBB1121",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PINANG",
    "Student": 153,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBB1203",
    "Name": "SEKOLAH KEBANGSAAN BATU LINTANG",
    "Student": 353,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBB1204",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KOTA SAMARAHAN",
    "Student": 83,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBB1205",
    "Name": "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KUCHING",
    "Student": 89,
    "Teacher": 34
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBB1206",
    "Name": "SEKOLAH KEBANGSAAN JLN.ONG TIANG SWEE",
    "Student": 511,
    "Teacher": 34
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBB1207",
    "Name": "SEKOLAH KEBANGSAAN GREEN ROAD",
    "Student": 863,
    "Teacher": 52
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBB1301",
    "Name": "SEKOLAH KEBANGSAAN SG STUTONG",
    "Student": 254,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBB1304",
    "Name": "SEKOLAH KEBANGSAAN PELAMAN SIGANDAR",
    "Student": 92,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBB1305",
    "Name": "SEKOLAH KEBANGSAAN BENGOH",
    "Student": 80,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBB1306",
    "Name": "SEKOLAH KEBANGSAAN PURUH KARU",
    "Student": 84,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB1310",
    "Name": "SEKOLAH KEBANGSAAN SIBURAN BATU",
    "Student": 643,
    "Teacher": 44
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB1314",
    "Name": "SEKOLAH KEBANGSAAN PELAMAN SIDUNUK",
    "Student": 42,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBB1315",
    "Name": "SEKOLAH KEBANGSAAN SIBU LAUT",
    "Student": 21,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBB1316",
    "Name": "SEKOLAH KEBANGSAAN SITANG PETAG",
    "Student": 126,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB1317",
    "Name": "SEKOLAH KEBANGSAAN PATUNG",
    "Student": 124,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBB1318",
    "Name": "SEKOLAH KEBANGSAAN KAMBUG",
    "Student": 125,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBB1319",
    "Name": "SEKOLAH KEBANGSAAN PANGKALAN AMPAT",
    "Student": 76,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBB1320",
    "Name": "SEKOLAH KEBANGSAAN TABA SAIT",
    "Student": 81,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBB1322",
    "Name": "SEKOLAH KEBANGSAAN SEJIJAK",
    "Student": 200,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1401",
    "Name": "SEKOLAH KEBANGSAAN SEMATAN",
    "Student": 228,
    "Teacher": 32
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1402",
    "Name": "SEKOLAH KEBANGSAAN PUEH",
    "Student": 111,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1403",
    "Name": "SEKOLAH KEBANGSAAN SEBIRIS",
    "Student": 86,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1404",
    "Name": "SEKOLAH KEBANGSAAN SELAMPIT",
    "Student": 141,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1406",
    "Name": "SEKOLAH KEBANGSAAN LEBAI MENTALI",
    "Student": 89,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1407",
    "Name": "SEKOLAH KEBANGSAAN JANGKAR",
    "Student": 114,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1409",
    "Name": "SEKOLAH KEBANGSAAN SAMPADI",
    "Student": 154,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1410",
    "Name": "SEKOLAH KEBANGSAAN BIAWAK",
    "Student": 125,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1411",
    "Name": "SEKOLAH KEBANGSAAN PAON/TEMAGA",
    "Student": 68,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1412",
    "Name": "SEKOLAH KEBANGSAAN STUNGKOR",
    "Student": 269,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1413",
    "Name": "SEKOLAH KEBANGSAAN SEBAT",
    "Student": 100,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1414",
    "Name": "SEKOLAH KEBANGSAAN PASIR TENGAH",
    "Student": 69,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1415",
    "Name": "SEKOLAH KEBANGSAAN PUGU",
    "Student": 24,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1416",
    "Name": "SEKOLAH KEBANGSAAN RASO",
    "Student": 68,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1417",
    "Name": "SEKOLAH KEBANGSAAN SEBANDI",
    "Student": 52,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1418",
    "Name": "SEKOLAH KEBANGSAAN KENDAIE",
    "Student": 56,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1419",
    "Name": "SEKOLAH KEBANGSAAN SENIBONG SEJERIN",
    "Student": 89,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1420",
    "Name": "SEKOLAH KEBANGSAAN SERAYAN/KERANJI",
    "Student": 81,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1422",
    "Name": "SEKOLAH KEBANGSAAN BOKAH",
    "Student": 96,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1423",
    "Name": "SEKOLAH KEBANGSAAN SEBAKO",
    "Student": 100,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1424",
    "Name": "SEKOLAH KEBANGSAAN SIAR CAMPURAN",
    "Student": 77,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBB1425",
    "Name": "SEKOLAH KEBANGSAAN STUNGGANG/TEMELAN",
    "Student": 58,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2101",
    "Name": "SEKOLAH KEBANGSAAN GRAN/STUMBIN",
    "Student": 185,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2102",
    "Name": "SEKOLAH KEBANGSAAN BATU LINTANG",
    "Student": 77,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2103",
    "Name": "SEKOLAH KEBANGSAAN PANTU",
    "Student": 138,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2104",
    "Name": "SEKOLAH KEBANGSAAN MELUGU",
    "Student": 227,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2105",
    "Name": "SEKOLAH KEBANGSAAN ABOK",
    "Student": 58,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2108",
    "Name": "SEKOLAH KEBANGSAAN SELEPONG",
    "Student": 118,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2109",
    "Name": "SEKOLAH KEBANGSAAN JAONG",
    "Student": 53,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2111",
    "Name": "SEKOLAH KEBANGSAAN TG BIJAT",
    "Student": 134,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2112",
    "Name": "SEKOLAH KEBANGSAAN SELANJAN",
    "Student": 53,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2113",
    "Name": "SEKOLAH KEBANGSAAN KERANGGAS",
    "Student": 26,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2116",
    "Name": "SEKOLAH KEBANGSAAN ISSU/BANGAT",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2117",
    "Name": "SEKOLAH KEBANGSAAN NG KLASSEN",
    "Student": 75,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2120",
    "Name": "SEKOLAH KEBANGSAAN TABUT",
    "Student": 17,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2123",
    "Name": "SEKOLAH KEBANGSAAN NANGA TEBAT",
    "Student": 34,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2124",
    "Name": "SEKOLAH KEBANGSAAN NG SKRANG",
    "Student": 32,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2125",
    "Name": "SEKOLAH KEBANGSAAN NG MENJUAU",
    "Student": 29,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2128",
    "Name": "SEKOLAH KEBANGSAAN NG. ENTEBAN",
    "Student": 43,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2129",
    "Name": "SEKOLAH KEBANGSAAN NG ENTALAU",
    "Student": 30,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2131",
    "Name": "SEKOLAH KEBANGSAAN PAKU",
    "Student": 62,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2132",
    "Name": "SEKOLAH KEBANGSAAN KEM SKRANG",
    "Student": 92,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2136",
    "Name": "SEKOLAH KEBANGSAAN TEMUDOK KEM",
    "Student": 74,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YBB2139",
    "Name": "SEKOLAH KEBANGSAAN SEMBAYANG",
    "Student": 7,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2142",
    "Name": "SEKOLAH KEBANGSAAN NG. LIDONG",
    "Student": 64,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2201",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KLAMPAI",
    "Student": 55,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2202",
    "Name": "SEKOLAH KEBANGSAAN NG GRENJANG",
    "Student": 56,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2203",
    "Name": "SEKOLAH KEBANGSAAN LICHOK",
    "Student": 32,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2204",
    "Name": "SEKOLAH KEBANGSAAN LUBOK KEPAYANG",
    "Student": 35,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2205",
    "Name": "SEKOLAH KEBANGSAAN ENGKABANG",
    "Student": 71,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2206",
    "Name": "SEKOLAH KEBANGSAAN SUNGEI ANTU",
    "Student": 53,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2207",
    "Name": "SEKOLAH KEBANGSAAN ENGKUDU",
    "Student": 34,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2208",
    "Name": "SEKOLAH KEBANGSAAN NG DRAU",
    "Student": 25,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2209",
    "Name": "SEKOLAH KEBANGSAAN BABANG",
    "Student": 27,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2211",
    "Name": "SEKOLAH KEBANGSAAN NG BUDU",
    "Student": 62,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2212",
    "Name": "SEKOLAH KEBANGSAAN KABO HILIR",
    "Student": 34,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2213",
    "Name": "SEKOLAH KEBANGSAAN ORANG KAYA TEMENGGONG TANDUK",
    "Student": 86,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2214",
    "Name": "SEKOLAH KEBANGSAAN NG ATOI",
    "Student": 61,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2216",
    "Name": "SEKOLAH KEBANGSAAN ULU BUDU",
    "Student": 34,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2217",
    "Name": "SEKOLAH KEBANGSAAN ULU KABO",
    "Student": 62,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2218",
    "Name": "SEKOLAH KEBANGSAAN MENDAS",
    "Student": 53,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2219",
    "Name": "SEKOLAH KEBANGSAAN BERAYANG",
    "Student": 58,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2220",
    "Name": "SEKOLAH KEBANGSAAN MUDONG",
    "Student": 51,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2221",
    "Name": "SEKOLAH KEBANGSAAN NG ASSAM",
    "Student": 61,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2222",
    "Name": "SEKOLAH KEBANGSAAN SG ENTEBU",
    "Student": 35,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2223",
    "Name": "SEKOLAH KEBANGSAAN LEMPA",
    "Student": 35,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2224",
    "Name": "SEKOLAH KEBANGSAAN SUPOK",
    "Student": 44,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2225",
    "Name": "SEKOLAH KEBANGSAAN TEMUDOK AWIK",
    "Student": 49,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2228",
    "Name": "SEKOLAH KEBANGSAAN NG ABU",
    "Student": 36,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2229",
    "Name": "SEKOLAH KEBANGSAAN PRAJA",
    "Student": 18,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2230",
    "Name": "SEKOLAH KEBANGSAAN NG MALONG",
    "Student": 37,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2231",
    "Name": "SEKOLAH KEBANGSAAN LUBOK NIBONG",
    "Student": 37,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2232",
    "Name": "SEKOLAH KEBANGSAAN WONG BESI",
    "Student": 45,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2234",
    "Name": "SEKOLAH KEBANGSAAN ULU AWIK",
    "Student": 18,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2235",
    "Name": "SEKOLAH KEBANGSAAN SG NYIAR",
    "Student": 52,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2237",
    "Name": "SEKOLAH KEBANGSAAN KLUA",
    "Student": 40,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2238",
    "Name": "SEKOLAH KEBANGSAAN KALAKA CENTRAL",
    "Student": 104,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YBB2239",
    "Name": "SEKOLAH KEBANGSAAN ULU ROBAN",
    "Student": 48,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2301",
    "Name": "SEKOLAH KEBANGSAAN ENGKILILI NO. 1",
    "Student": 132,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2302",
    "Name": "SEKOLAH KEBANGSAAN LUBOK ANTU",
    "Student": 355,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2303",
    "Name": "SEKOLAH KEBANGSAAN RIDAN",
    "Student": 118,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2304",
    "Name": "SEKOLAH KEBANGSAAN IJOK",
    "Student": 38,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2305",
    "Name": "SEKOLAH KEBANGSAAN MELABAN",
    "Student": 82,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2306",
    "Name": "SEKOLAH KEBANGSAAN NANGA KUMPANG",
    "Student": 56,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2308",
    "Name": "SEKOLAH KEBANGSAAN NANGA TIBU",
    "Student": 70,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2310",
    "Name": "SEKOLAH KEBANGSAAN BASI",
    "Student": 33,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2311",
    "Name": "SEKOLAH KEBANGSAAN NANGA AUP",
    "Student": 16,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2312",
    "Name": "SEKOLAH KEBANGSAAN SKAROK",
    "Student": 64,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2313",
    "Name": "SEKOLAH KEBANGSAAN SBANGKI",
    "Student": 88,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2317",
    "Name": "SEKOLAH KEBANGSAAN NANGA KESIT",
    "Student": 97,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2318",
    "Name": "SEKOLAH KEBANGSAAN MERBONG",
    "Student": 38,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2319",
    "Name": "SEKOLAH KEBANGSAAN ENGKILILI NO.2",
    "Student": 117,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2320",
    "Name": "SEKOLAH KEBANGSAAN EMPLAM",
    "Student": 13,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2321",
    "Name": "SEKOLAH KEBANGSAAN NG MENYEBAT",
    "Student": 47,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2322",
    "Name": "SEKOLAH KEBANGSAAN ULU LEMANAK",
    "Student": 48,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2323",
    "Name": "SEKOLAH KEBANGSAAN SAN SEMANJU",
    "Student": 88,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2324",
    "Name": "SEKOLAH KEBANGSAAN STENGIN/SEDARAT",
    "Student": 54,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YBB2325",
    "Name": "SEKOLAH KEBANGSAAN ULU ENGKARI",
    "Student": 11,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2402",
    "Name": "SEKOLAH KEBANGSAAN NG. AJAU",
    "Student": 174,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2403",
    "Name": "SEKOLAH KEBANGSAAN DIT",
    "Student": 85,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2405",
    "Name": "SEKOLAH KEBANGSAAN SAKA",
    "Student": 47,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2406",
    "Name": "SEKOLAH KEBANGSAAN PAKU CENTRAL",
    "Student": 81,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2407",
    "Name": "SEKOLAH KEBANGSAAN SURI",
    "Student": 60,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2408",
    "Name": "SEKOLAH KEBANGSAAN PENOM",
    "Student": 16,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2409",
    "Name": "SEKOLAH KEBANGSAAN NG. GAYAU",
    "Student": 101,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2410",
    "Name": "SEKOLAH KEBANGSAAN MUTON",
    "Student": 116,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YBB2412",
    "Name": "SEKOLAH KEBANGSAAN NG. LAWIH/LESU",
    "Student": 73,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3201",
    "Name": "SEKOLAH KEBANGSAAN SG AUP",
    "Student": 190,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3202",
    "Name": "SEKOLAH KEBANGSAAN SG PASSAI",
    "Student": 88,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3203",
    "Name": "SEKOLAH KEBANGSAAN SG DURIN",
    "Student": 201,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3204",
    "Name": "SEKOLAH KEBANGSAAN SG NAMAN",
    "Student": 46,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3205",
    "Name": "SEKOLAH KEBANGSAAN BATU WONG",
    "Student": 138,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3206",
    "Name": "SEKOLAH KEBANGSAAN SG SALIM",
    "Student": 91,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3207",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG PENASU",
    "Student": 91,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3208",
    "Name": "SEKOLAH KEBANGSAAN BAWANG ASSAN",
    "Student": 149,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3209",
    "Name": "SEKOLAH KEBANGSAAN ULU SUNGAI SALIM",
    "Student": 105,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3210",
    "Name": "SEKOLAH KEBANGSAAN SG SIONG",
    "Student": 75,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3211",
    "Name": "SEKOLAH KEBANGSAAN SG LENGAN",
    "Student": 67,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBB3212",
    "Name": "SEKOLAH KEBANGSAAN SG RASAU",
    "Student": 63,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3213",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG BEKAKAP",
    "Student": 88,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB3214",
    "Name": "SEKOLAH KEBANGSAAN SUNGEI PASSIN",
    "Student": 74,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3215",
    "Name": "SEKOLAH KEBANGSAAN ULU SG SENGAN",
    "Student": 87,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3216",
    "Name": "SEKOLAH KEBANGSAAN NANGA TUTUS",
    "Student": 73,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3217",
    "Name": "SEKOLAH KEBANGSAAN NANGA ASSAN",
    "Student": 91,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3218",
    "Name": "SEKOLAH KEBANGSAAN ULU SUNGAI SIONG",
    "Student": 58,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3219",
    "Name": "SEKOLAH KEBANGSAAN NANGA BON",
    "Student": 46,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB3220",
    "Name": "SEKOLAH KEBANGSAAN BATANG LASSA",
    "Student": 60,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3221",
    "Name": "SEKOLAH KEBANGSAAN NANGA PAK",
    "Student": 25,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBB3222",
    "Name": "SEKOLAH KEBANGSAAN SG PINANG",
    "Student": 54,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3223",
    "Name": "SEKOLAH KEBANGSAAN SG. SIONG TENGAH",
    "Student": 77,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBB3224",
    "Name": "SEKOLAH KEBANGSAAN KG BUNGAN",
    "Student": 86,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3225",
    "Name": "SEKOLAH KEBANGSAAN ASSAN NUNGGANG",
    "Student": 23,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3226",
    "Name": "SEKOLAH KEBANGSAAN RANTAU PANJANG",
    "Student": 308,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3227",
    "Name": "SEKOLAH KEBANGSAAN KPG BANYOK",
    "Student": 46,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3228",
    "Name": "SEKOLAH KEBANGSAAN NG SALIM",
    "Student": 99,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBB3229",
    "Name": "SEKOLAH KEBANGSAAN SG ILAS",
    "Student": 39,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3230",
    "Name": "SEKOLAH KEBANGSAAN SG NIBONG",
    "Student": 28,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3231",
    "Name": "SEKOLAH KEBANGSAAN LUBOK PELAIE",
    "Student": 23,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB3232",
    "Name": "SEKOLAH KEBANGSAAN ULU SG NAMAN",
    "Student": 90,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3233",
    "Name": "SEKOLAH KEBANGSAAN SG SEBINTEK",
    "Student": 56,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3235",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG LATAP",
    "Student": 58,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3239",
    "Name": "SEKOLAH KEBANGSAAN ULU DURIN KIBA",
    "Student": 64,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBB3240",
    "Name": "SEKOLAH KEBANGSAAN ULU SG MERAH",
    "Student": 774,
    "Teacher": 46
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3302",
    "Name": "SEKOLAH KEBANGSAAN NANGA LIPUS",
    "Student": 40,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3303",
    "Name": "SEKOLAH KEBANGSAAN RANTAU KEMIDING",
    "Student": 43,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3304",
    "Name": "SEKOLAH KEBANGSAAN NANGA POI",
    "Student": 47,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3305",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI TUAH",
    "Student": 50,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3306",
    "Name": "SEKOLAH KEBANGSAAN NANGA JIH",
    "Student": 55,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3307",
    "Name": "SEKOLAH KEBANGSAAN ULU POI",
    "Student": 11,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3308",
    "Name": "SEKOLAH KEBANGSAAN NG JAGOI",
    "Student": 63,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3309",
    "Name": "SEKOLAH KEBANGSAAN NANGA DAP",
    "Student": 64,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3310",
    "Name": "SEKOLAH KEBANGSAAN NANGA MACHAN",
    "Student": 203,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3311",
    "Name": "SEKOLAH KEBANGSAAN NANGA NGUNGUN",
    "Student": 209,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3312",
    "Name": "SEKOLAH KEBANGSAAN RANTAU DILANG",
    "Student": 36,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3313",
    "Name": "SEKOLAH KEBANGSAAN ULU BAWAN",
    "Student": 117,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3314",
    "Name": "SEKOLAH KEBANGSAAN NG BALINGIAU",
    "Student": 88,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3315",
    "Name": "SEKOLAH KEBANGSAAN BATU LUKING",
    "Student": 67,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3316",
    "Name": "SEKOLAH KEBANGSAAN NANGA NIROK NGEMAH",
    "Student": 36,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3317",
    "Name": "SEKOLAH KEBANGSAAN NANGA TADA",
    "Student": 226,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3318",
    "Name": "SEKOLAH KEBANGSAAN ULU MACHAN",
    "Student": 12,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3319",
    "Name": "SEKOLAH KEBANGSAAN NANGA MENALUN",
    "Student": 3,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3320",
    "Name": "SEKOLAH KEBANGSAAN ULU RANAN",
    "Student": 282,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3322",
    "Name": "SEKOLAH KEBANGSAAN ULU MAJAU",
    "Student": 93,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3323",
    "Name": "SEKOLAH KEBANGSAAN NANGA PEDAI",
    "Student": 137,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3324",
    "Name": "SEKOLAH KEBANGSAAN NANGA NGEMAH",
    "Student": 15,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YBB3325",
    "Name": "SEKOLAH KEBANGSAAN NG JAGAU",
    "Student": 27,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBB3401",
    "Name": "SEKOLAH KEBANGSAAN KUALA KENYANA",
    "Student": 35,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBB3402",
    "Name": "SEKOLAH KEBANGSAAN NANGA SIKAT",
    "Student": 30,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBB3403",
    "Name": "SEKOLAH KEBANGSAAN LUBOK BEMBAN",
    "Student": 65,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBB3404",
    "Name": "SEKOLAH KEBANGSAAN SG BADENGAN",
    "Student": 158,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBB3405",
    "Name": "SEKOLAH KEBANGSAAN NANGA KUA",
    "Student": 76,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBB3406",
    "Name": "SEKOLAH KEBANGSAAN SG ARAU",
    "Student": 13,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBB3408",
    "Name": "SEKOLAH KEBANGSAAN SG BAWANG",
    "Student": 55,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBB3409",
    "Name": "SEKOLAH KEBANGSAAN SG BULOH",
    "Student": 124,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBB3410",
    "Name": "SEKOLAH KEBANGSAAN SG TAU",
    "Student": 17,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBB3411",
    "Name": "SEKOLAH KEBANGSAAN KUALA BALINGIAN",
    "Student": 122,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBB3412",
    "Name": "SEKOLAH KEBANGSAAN KUALA PELUGAU",
    "Student": 76,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBB3414",
    "Name": "SEKOLAH KEBANGSAAN PENGKALAN LOBANG",
    "Student": 43,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBB3415",
    "Name": "SEKOLAH KEBANGSAAN SG ANAK",
    "Student": 75,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBB3416",
    "Name": "SEKOLAH KEBANGSAAN SG SEPIRING/SG TEPUS",
    "Student": 205,
    "Teacher": 23
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBB3501",
    "Name": "SEKOLAH KEBANGSAAN KPG TEH",
    "Student": 38,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBB3502",
    "Name": "SEKOLAH KEBANGSAAN NANGA TAJAM",
    "Student": 97,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBB3503",
    "Name": "SEKOLAH KEBANGSAAN BAOH ULU",
    "Student": 59,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBB3505",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PAKOH",
    "Student": 27,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBB3506",
    "Name": "SEKOLAH KEBANGSAAN PENGHULU IMBAN",
    "Student": 237,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBB3507",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG BAKONG TERUS",
    "Student": 61,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4101",
    "Name": "SEKOLAH KEBANGSAAN KG. BERAYA",
    "Student": 86,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4102",
    "Name": "SEKOLAH KEBANGSAAN SOUTH",
    "Student": 410,
    "Teacher": 38
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4301",
    "Name": "SEKOLAH KEBANGSAAN PENGARAH ENTERI",
    "Student": 124,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4302",
    "Name": "SEKOLAH KEBANGSAAN BELURU CENTRAL",
    "Student": 364,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4303",
    "Name": "SEKOLAH KEBANGSAAN LONG IKANG",
    "Student": 15,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4304",
    "Name": "SEKOLAH KEBANGSAAN LUBOK NIBONG",
    "Student": 34,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4305",
    "Name": "SEKOLAH KEBANGSAAN POYUT",
    "Student": 76,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4306",
    "Name": "SEKOLAH KEBANGSAAN LONG TERU",
    "Student": 21,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4307",
    "Name": "SEKOLAH KEBANGSAAN LONG MOH",
    "Student": 52,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4308",
    "Name": "SEKOLAH KEBANGSAAN LONG ATON",
    "Student": 108,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4309",
    "Name": "SEKOLAH KEBANGSAAN LONG PANAI",
    "Student": 21,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4310",
    "Name": "SEKOLAH KEBANGSAAN LONG LAPUT",
    "Student": 83,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4311",
    "Name": "SEKOLAH KEBANGSAAN LONG LELLANG",
    "Student": 42,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4313",
    "Name": "SEKOLAH KEBANGSAAN LONG BEDIAN",
    "Student": 501,
    "Teacher": 35
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4314",
    "Name": "SEKOLAH KEBANGSAAN LONG ANAP",
    "Student": 33,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4315",
    "Name": "SEKOLAH KEBANGSAAN LIO MATO",
    "Student": 35,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4316",
    "Name": "SEKOLAH KEBANGSAAN LONG BEMANG",
    "Student": 108,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4317",
    "Name": "SEKOLAH KEBANGSAAN LONG JEGAN",
    "Student": 104,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4319",
    "Name": "SEKOLAH KEBANGSAAN LONG ATIP",
    "Student": 10,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4320",
    "Name": "SEKOLAH KEBANGSAAN MOREK",
    "Student": 64,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4321",
    "Name": "SEKOLAH KEBANGSAAN SG. ENTULANG",
    "Student": 59,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4322",
    "Name": "SEKOLAH KEBANGSAAN UBONG IMANG",
    "Student": 186,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4323",
    "Name": "SEKOLAH KEBANGSAAN SG. LIAM",
    "Student": 29,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4324",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BAIN",
    "Student": 44,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4325",
    "Name": "SEKOLAH KEBANGSAAN SG PEKING",
    "Student": 40,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4326",
    "Name": "SEKOLAH KEBANGSAAN LONG LOYANG",
    "Student": 88,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4327",
    "Name": "SEKOLAH KEBANGSAAN LONG LAMEI",
    "Student": 63,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4328",
    "Name": "SEKOLAH KEBANGSAAN LONG SERIDAN",
    "Student": 47,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4329",
    "Name": "SEKOLAH KEBANGSAAN LONG KESSEH",
    "Student": 22,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4330",
    "Name": "SEKOLAH KEBANGSAAN LONG PILLAH",
    "Student": 53,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4331",
    "Name": "SEKOLAH KEBANGSAAN BARIO",
    "Student": 89,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4332",
    "Name": "SEKOLAH KEBANGSAAN SG BONG",
    "Student": 89,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4333",
    "Name": "SEKOLAH KEBANGSAAN LONG MIRI",
    "Student": 54,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4334",
    "Name": "SEKOLAH KEBANGSAAN LONG JEEH",
    "Student": 24,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4335",
    "Name": "SEKOLAH KEBANGSAAN LONG NAAH",
    "Student": 39,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4336",
    "Name": "SEKOLAH KEBANGSAAN SG. SELEPIN",
    "Student": 49,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4337",
    "Name": "SEKOLAH KEBANGSAAN SG BRIT",
    "Student": 50,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4338",
    "Name": "SEKOLAH KEBANGSAAN LONG BANGA",
    "Student": 94,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4339",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SEBATU",
    "Student": 7,
    "Teacher": 4
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4340",
    "Name": "SEKOLAH KEBANGSAAN SG ARANG",
    "Student": 48,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4343",
    "Name": "SEKOLAH KEBANGSAAN KUALA TUTOH",
    "Student": 17,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4344",
    "Name": "SEKOLAH KEBANGSAAN SG SETAPANG",
    "Student": 29,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4345",
    "Name": "SEKOLAH KEBANGSAAN SG. SEPUTI",
    "Student": 57,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4347",
    "Name": "SEKOLAH KEBANGSAAN PA ' DALLIH",
    "Student": 19,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4348",
    "Name": "SEKOLAH KEBANGSAAN LONG PALAI",
    "Student": 28,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4349",
    "Name": "SEKOLAH KEBANGSAAN PENGHULU BAYA MALLANG",
    "Student": 12,
    "Teacher": 5
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4350",
    "Name": "SEKOLAH KEBANGSAAN SG. BIAR",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4351",
    "Name": "SEKOLAH KEBANGSAAN LONG APU",
    "Student": 28,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4352",
    "Name": "SEKOLAH KEBANGSAAN LONG SOBENG",
    "Student": 23,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4353",
    "Name": "SEKOLAH KEBANGSAAN SG. LAONG",
    "Student": 43,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4354",
    "Name": "SEKOLAH KEBANGSAAN BENAWA",
    "Student": 41,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4355",
    "Name": "SEKOLAH KEBANGSAAN SG. KELABIT",
    "Student": 11,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4356",
    "Name": "SEKOLAH KEBANGSAAN LONG TUNGAN",
    "Student": 12,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4357",
    "Name": "SEKOLAH KEBANGSAAN LONG MAKABAR",
    "Student": 28,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4358",
    "Name": "SEKOLAH KEBANGSAAN SG. BAKAS",
    "Student": 120,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4359",
    "Name": "SEKOLAH KEBANGSAAN PENGELAYAN",
    "Student": 31,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4360",
    "Name": "SEKOLAH KEBANGSAAN LONG SEPILING",
    "Student": 11,
    "Teacher": 4
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4361",
    "Name": "SEKOLAH KEBANGSAAN RUMAH GUDANG",
    "Student": 46,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4362",
    "Name": "SEKOLAH KEBANGSAAN LONG WAT",
    "Student": 19,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4364",
    "Name": "SEKOLAH KEBANGSAAN KUALA BOK",
    "Student": 37,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBB4365",
    "Name": "SEKOLAH KEBANGSAAN SG. BURI",
    "Student": 62,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBB4367",
    "Name": "SEKOLAH KEBANGSAAN LONG LENEI",
    "Student": 34,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4401",
    "Name": "SEKOLAH KEBANGSAAN BELIAU ISA",
    "Student": 117,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4403",
    "Name": "SEKOLAH KEBANGSAAN KPG. IRAN",
    "Student": 169,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4404",
    "Name": "SEKOLAH KEBANGSAAN RUMAH BARAT",
    "Student": 17,
    "Teacher": 5
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4405",
    "Name": "SEKOLAH KEBANGSAAN RUMAH TINGGI",
    "Student": 52,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4406",
    "Name": "SEKOLAH KEBANGSAAN KPG. BUNGAI",
    "Student": 61,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4407",
    "Name": "SEKOLAH KEBANGSAAN RUMAH GOYANG",
    "Student": 45,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4408",
    "Name": "SEKOLAH KEBANGSAAN BATU TELLINGAI",
    "Student": 479,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4409",
    "Name": "SEKOLAH KEBANGSAAN SG. MANONG",
    "Student": 123,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4410",
    "Name": "SEKOLAH KEBANGSAAN SG. TANGAP",
    "Student": 38,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4411",
    "Name": "SEKOLAH KEBANGSAAN KPG. TARIKAN",
    "Student": 36,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4412",
    "Name": "SEKOLAH KEBANGSAAN BATU NIAH",
    "Student": 341,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4413",
    "Name": "SEKOLAH KEBANGSAAN RUMAH RANGGONG",
    "Student": 58,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4414",
    "Name": "SEKOLAH KEBANGSAAN SG. SAEH",
    "Student": 41,
    "Teacher": 5
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4415",
    "Name": "SEKOLAH KEBANGSAAN SG. LAMAUS",
    "Student": 94,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4416",
    "Name": "SEKOLAH KEBANGSAAN KPG. KUALA SIBUTI",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4418",
    "Name": "SEKOLAH KEBANGSAAN KPG. BULAU",
    "Student": 29,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4419",
    "Name": "SEKOLAH KEBANGSAAN SG. BAKAS",
    "Student": 64,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBB4420",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG NYALAU",
    "Student": 146,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4421",
    "Name": "SEKOLAH KEBANGSAAN KPG. SUBAK",
    "Student": 78,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4422",
    "Name": "SEKOLAH KEBANGSAAN KPG. TEGAGENG",
    "Student": 78,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4423",
    "Name": "SEKOLAH KEBANGSAAN KELAPA SAWIT NO. 1",
    "Student": 154,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4425",
    "Name": "SEKOLAH KEBANGSAAN SG. SEBATU",
    "Student": 77,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4426",
    "Name": "SEKOLAH KEBANGSAAN KELAPA SAWIT NO. 2",
    "Student": 163,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4427",
    "Name": "SEKOLAH KEBANGSAAN KELAPA SAWIT NO. 3",
    "Student": 48,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4429",
    "Name": "SEKOLAH KEBANGSAAN KELAPA SAWIT NO. 5",
    "Student": 131,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YBB4430",
    "Name": "SEKOLAH KEBANGSAAN RUMAH ESSAU",
    "Student": 36,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBB5102",
    "Name": "SEKOLAH KEBANGSAAN UKONG",
    "Student": 47,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBB5103",
    "Name": "SEKOLAH KEBANGSAAN NANGA MEDAMIT",
    "Student": 118,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBB5104",
    "Name": "SEKOLAH KEBANGSAAN MENUANG",
    "Student": 243,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBB5105",
    "Name": "SEKOLAH KEBANGSAAN KUALA PENGANAN",
    "Student": 97,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBB5106",
    "Name": "SEKOLAH KEBANGSAAN LONG NAPIR",
    "Student": 57,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBB5107",
    "Name": "SEKOLAH KEBANGSAAN NANGA MERIT",
    "Student": 32,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBB5109",
    "Name": "SEKOLAH KEBANGSAAN KUALA MEDALAM",
    "Student": 69,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBB5112",
    "Name": "SEKOLAH KEBANGSAAN ULU LUBAI",
    "Student": 10,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBB5113",
    "Name": "SEKOLAH KEBANGSAAN MELABAN",
    "Student": 26,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YBB5114",
    "Name": "SEKOLAH KEBANGSAAN BUKIT BATU",
    "Student": 132,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBB5201",
    "Name": "SEKOLAH KEBANGSAAN LONG SEMADOH",
    "Student": 69,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBB5202",
    "Name": "SEKOLAH KEBANGSAAN LONG TUMA",
    "Student": 197,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBB5203",
    "Name": "SEKOLAH KEBANGSAAN LONG TUKON",
    "Student": 66,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBB5204",
    "Name": "SEKOLAH KEBANGSAAN BA KELALAN",
    "Student": 74,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBB5205",
    "Name": "SEKOLAH KEBANGSAAN LONG SUKANG",
    "Student": 98,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBB5206",
    "Name": "SEKOLAH KEBANGSAAN LONG TENGOA",
    "Student": 51,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBB5207",
    "Name": "SEKOLAH KEBANGSAAN LONG LUPING",
    "Student": 78,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBB5208",
    "Name": "SEKOLAH KEBANGSAAN KG LINTANG",
    "Student": 62,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBB5209",
    "Name": "SEKOLAH KEBANGSAAN TANG LAPADAN",
    "Student": 49,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBB5210",
    "Name": "SEKOLAH KEBANGSAAN LONG SEBANGAN",
    "Student": 136,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBB5212",
    "Name": "SEKOLAH KEBANGSAAN SIANG-SIANG",
    "Student": 155,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6101",
    "Name": "SEKOLAH KEBANGSAAN UDIN",
    "Student": 78,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6102",
    "Name": "SEKOLAH KEBANGSAAN ADIN",
    "Student": 86,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6103",
    "Name": "SEKOLAH KEBANGSAAN BALONG",
    "Student": 23,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6104",
    "Name": "SEKOLAH KEBANGSAAN PENGHULU ANDIN",
    "Student": 77,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6106",
    "Name": "SEKOLAH KEBANGSAAN ST. ANDREW",
    "Student": 80,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6107",
    "Name": "SEKOLAH KEBANGSAAN ASSUN",
    "Student": 44,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6108",
    "Name": "SEKOLAH KEBANGSAAN RENTAP",
    "Student": 55,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6110",
    "Name": "SEKOLAH KEBANGSAAN BAYANG",
    "Student": 58,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6111",
    "Name": "SEKOLAH KEBANGSAAN JAWA",
    "Student": 75,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6112",
    "Name": "SEKOLAH KEBANGSAAN SG PAOH",
    "Student": 144,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6113",
    "Name": "SEKOLAH KEBANGSAAN SG PETAI",
    "Student": 57,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6114",
    "Name": "SEKOLAH KEBANGSAAN TANAH PUTIH",
    "Student": 40,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6115",
    "Name": "SEKOLAH KEBANGSAAN SELANGAN",
    "Student": 101,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6116",
    "Name": "SEKOLAH KEBANGSAAN SEBAKO",
    "Student": 43,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBB6118",
    "Name": "SEKOLAH KEBANGSAAN MUARA PAYANG",
    "Student": 33,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6201",
    "Name": "SEKOLAH KEBANGSAAN NG SERAU",
    "Student": 36,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6202",
    "Name": "SEKOLAH KEBANGSAAN NANGA LASI",
    "Student": 124,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6203",
    "Name": "SEKOLAH KEBANGSAAN NANGA MELUAN",
    "Student": 198,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6204",
    "Name": "SEKOLAH KEBANGSAAN NANGA PAKAN",
    "Student": 225,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6205",
    "Name": "SEKOLAH KEBANGSAAN NANGA WAK",
    "Student": 129,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6206",
    "Name": "SEKOLAH KEBANGSAAN NANGA ENTAIH",
    "Student": 95,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6207",
    "Name": "SEKOLAH KEBANGSAAN ULU MANDING",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6208",
    "Name": "SEKOLAH KEBANGSAAN NANGA MERURUN",
    "Student": 57,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6209",
    "Name": "SEKOLAH KEBANGSAAN NANGA PEDANUM",
    "Student": 42,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6210",
    "Name": "SEKOLAH KEBANGSAAN NANGA BABAI",
    "Student": 132,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6211",
    "Name": "SEKOLAH KEBANGSAAN NANGA MAONG",
    "Student": 26,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6212",
    "Name": "SEKOLAH KEBANGSAAN NANGA KEDUP",
    "Student": 50,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6213",
    "Name": "SEKOLAH KEBANGSAAN NANGA ENTABAI",
    "Student": 115,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6214",
    "Name": "SEKOLAH KEBANGSAAN NANGA KOTA",
    "Student": 46,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6215",
    "Name": "SEKOLAH KEBANGSAAN NANGA LUAU",
    "Student": 90,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6216",
    "Name": "SEKOLAH KEBANGSAAN NANGA SEMAWANG",
    "Student": 36,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6217",
    "Name": "SEKOLAH KEBANGSAAN NANGA SENGAIH",
    "Student": 55,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6218",
    "Name": "SEKOLAH KEBANGSAAN TAPANG PUNGGU",
    "Student": 29,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6219",
    "Name": "SEKOLAH KEBANGSAAN NANGA KARA",
    "Student": 42,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6220",
    "Name": "SEKOLAH KEBANGSAAN LUBOK ASSAM",
    "Student": 29,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6221",
    "Name": "SEKOLAH KEBANGSAAN NG ENSIRING",
    "Student": 34,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6222",
    "Name": "SEKOLAH KEBANGSAAN ULU ENTAIH",
    "Student": 78,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6223",
    "Name": "SEKOLAH KEBANGSAAN ULU ENTABAI",
    "Student": 54,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6224",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI SUGAI",
    "Student": 151,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6225",
    "Name": "SEKOLAH KEBANGSAAN NANGA JAMBU",
    "Student": 38,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6226",
    "Name": "SEKOLAH KEBANGSAAN NANGA ENGKAMOP",
    "Student": 134,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6228",
    "Name": "SEKOLAH KEBANGSAAN NANGA BUKU",
    "Student": 55,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6229",
    "Name": "SEKOLAH KEBANGSAAN NANGA JU",
    "Student": 13,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6230",
    "Name": "SEKOLAH KEBANGSAAN NANGA DAYU",
    "Student": 55,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YBB6231",
    "Name": "SEKOLAH KEBANGSAAN NANGA KELANGAS",
    "Student": 36,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6301",
    "Name": "SEKOLAH KEBANGSAAN NANGA SEMAH",
    "Student": 30,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6302",
    "Name": "SEKOLAH KEBANGSAAN ULU BINATANG",
    "Student": 83,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6303",
    "Name": "SEKOLAH KEBANGSAAN SG MADOR",
    "Student": 103,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6304",
    "Name": "SEKOLAH KEBANGSAAN SG SELIDAP",
    "Student": 51,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6306",
    "Name": "SEKOLAH KEBANGSAAN TULAI",
    "Student": 38,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6307",
    "Name": "SEKOLAH KEBANGSAAN TANJUNG BUNDUNG",
    "Student": 44,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6309",
    "Name": "SEKOLAH KEBANGSAAN SEMOP",
    "Student": 172,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6310",
    "Name": "SEKOLAH KEBANGSAAN MUPONG",
    "Student": 23,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6311",
    "Name": "SEKOLAH KEBANGSAAN KPG PENASU DARO",
    "Student": 82,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6312",
    "Name": "SEKOLAH KEBANGSAAN ULU STRAS",
    "Student": 43,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6313",
    "Name": "SEKOLAH KEBANGSAAN BAKERKONG",
    "Student": 24,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6314",
    "Name": "SEKOLAH KEBANGSAAN ULU MERADONG",
    "Student": 52,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6315",
    "Name": "SEKOLAH KEBANGSAAN BUKIT PAPIT",
    "Student": 41,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6317",
    "Name": "SEKOLAH KEBANGSAAN NANGA STRAS",
    "Student": 205,
    "Teacher": 23
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6318",
    "Name": "SEKOLAH KEBANGSAAN SG KAWI",
    "Student": 84,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6319",
    "Name": "SEKOLAH KEBANGSAAN BANDAR BINTANGOR",
    "Student": 189,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6320",
    "Name": "SEKOLAH KEBANGSAAN GEMUAN",
    "Student": 58,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBB6321",
    "Name": "SEKOLAH KEBANGSAAN BUKIT NIBONG",
    "Student": 144,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6322",
    "Name": "SEKOLAH KEBANGSAAN TELOK GELAM",
    "Student": 15,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6323",
    "Name": "SEKOLAH KEBANGSAAN SABENA",
    "Student": 26,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6401",
    "Name": "SEKOLAH KEBANGSAAN O K SERGUNIM",
    "Student": 120,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6402",
    "Name": "SEKOLAH KEBANGSAAN MOHAMAD REDEH SAAI",
    "Student": 31,
    "Teacher": 5
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6405",
    "Name": "SEKOLAH KEBANGSAAN KG PERGAU",
    "Student": 130,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6406",
    "Name": "SEKOLAH KEBANGSAAN KG PENIPAH",
    "Student": 166,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6407",
    "Name": "SEKOLAH KEBANGSAAN KG TEKAJONG",
    "Student": 52,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6408",
    "Name": "SEKOLAH KEBANGSAAN KG KUT",
    "Student": 38,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6409",
    "Name": "SEKOLAH KEBANGSAAN BAWANG TIAN",
    "Student": 380,
    "Teacher": 38
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6410",
    "Name": "SEKOLAH KEBANGSAAN KG PANGTRAY",
    "Student": 116,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6411",
    "Name": "SEKOLAH KEBANGSAAN KG BETANAK",
    "Student": 147,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6413",
    "Name": "SEKOLAH KEBANGSAAN KG PENIBONG",
    "Student": 88,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6414",
    "Name": "SEKOLAH KEBANGSAAN BRUAN MAPAL",
    "Student": 74,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBB6415",
    "Name": "SEKOLAH KEBANGSAAN SEKAAN KECHIL",
    "Student": 59,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7101",
    "Name": "SEKOLAH KEBANGSAAN NG MERIT",
    "Student": 112,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7102",
    "Name": "SEKOLAH KEBANGSAAN NG PELAGUS",
    "Student": 25,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7103",
    "Name": "SEKOLAH KEBANGSAAN TEMENGGONG KOH",
    "Student": 25,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7104",
    "Name": "SEKOLAH KEBANGSAAN NG STAPANG",
    "Student": 17,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7105",
    "Name": "SEKOLAH KEBANGSAAN NG IBAU",
    "Student": 70,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7107",
    "Name": "SEKOLAH KEBANGSAAN NG BENA",
    "Student": 48,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7108",
    "Name": "SEKOLAH KEBANGSAAN NG OYAN",
    "Student": 60,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7109",
    "Name": "SEKOLAH KEBANGSAAN NG YONG",
    "Student": 64,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7110",
    "Name": "SEKOLAH KEBANGSAAN MUJONG TENGAH",
    "Student": 22,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7111",
    "Name": "SEKOLAH KEBANGSAAN NG IBUN",
    "Student": 18,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7112",
    "Name": "SEKOLAH KEBANGSAAN NG SEMPILI",
    "Student": 52,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7113",
    "Name": "SEKOLAH KEBANGSAAN NG SAMA",
    "Student": 38,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7114",
    "Name": "SEKOLAH KEBANGSAAN NG KEBIAW",
    "Student": 21,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7115",
    "Name": "SEKOLAH KEBANGSAAN NG TRUSA",
    "Student": 72,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7116",
    "Name": "SEKOLAH KEBANGSAAN LEPONG GAAT",
    "Student": 39,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7117",
    "Name": "SEKOLAH KEBANGSAAN NG ENCHEREMIN",
    "Student": 81,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7118",
    "Name": "SEKOLAH KEBANGSAAN NG SEGENOK",
    "Student": 15,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7119",
    "Name": "SEKOLAH KEBANGSAAN LEPONG BALLEH",
    "Student": 280,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7120",
    "Name": "SEKOLAH KEBANGSAAN LP MENUAN",
    "Student": 64,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7121",
    "Name": "SEKOLAH KEBANGSAAN NG ANTAROH",
    "Student": 91,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7122",
    "Name": "SEKOLAH KEBANGSAAN NG KAIN",
    "Student": 36,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7123",
    "Name": "SEKOLAH KEBANGSAAN KG BARU",
    "Student": 303,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7124",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KAPIT",
    "Student": 372,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7126",
    "Name": "SEKOLAH KEBANGSAAN ULU PELAGUS",
    "Student": 46,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7127",
    "Name": "SEKOLAH KEBANGSAAN NG BALANG",
    "Student": 11,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7128",
    "Name": "SEKOLAH KEBANGSAAN ULU YONG",
    "Student": 79,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YBB7129",
    "Name": "SEKOLAH KEBANGSAAN NG METAH",
    "Student": 59,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBB7202",
    "Name": "SEKOLAH KEBANGSAAN ABUN MATU",
    "Student": 374,
    "Teacher": 36
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBB7203",
    "Name": "SEKOLAH KEBANGSAAN UMA SAMBOP",
    "Student": 112,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBB7205",
    "Name": "SEKOLAH KEBANGSAAN LONG SEGAHAM",
    "Student": 73,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBB7207",
    "Name": "SEKOLAH KEBANGSAAN LONG GANG",
    "Student": 325,
    "Teacher": 32
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBB7209",
    "Name": "SEKOLAH KEBANGSAAN BATU KELING",
    "Student": 791,
    "Teacher": 58
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBB7210",
    "Name": "SEKOLAH KEBANGSAAN AIRPORT",
    "Student": 69,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBB7212",
    "Name": "SEKOLAH KEBANGSAAN LONG BUSANG",
    "Student": 92,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YBB7213",
    "Name": "SEKOLAH KEBANGSAAN PUNAN BA",
    "Student": 92,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7301",
    "Name": "SEKOLAH KEBANGSAAN NG ENGKUAH",
    "Student": 46,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7302",
    "Name": "SEKOLAH KEBANGSAAN NG BEGUANG",
    "Student": 40,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7303",
    "Name": "SEKOLAH KEBANGSAAN NG NANSANG",
    "Student": 23,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7304",
    "Name": "SEKOLAH KEBANGSAAN NG BANGKIT",
    "Student": 25,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7305",
    "Name": "SEKOLAH KEBANGSAAN NG DALAI",
    "Student": 30,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7306",
    "Name": "SEKOLAH KEBANGSAAN TAN SRI DATUK TEMENGGONG JUGAH",
    "Student": 4,
    "Teacher": 5
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7307",
    "Name": "SEKOLAH KEBANGSAAN NANGA NYIMOH",
    "Student": 61,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7308",
    "Name": "SEKOLAH KEBANGSAAN LUBOK IPOH",
    "Student": 20,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7309",
    "Name": "SEKOLAH KEBANGSAAN NG JANAN",
    "Student": 28,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7310",
    "Name": "SEKOLAH KEBANGSAAN LUBOK BEDIL",
    "Student": 33,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7311",
    "Name": "SEKOLAH KEBANGSAAN NG MUSAH",
    "Student": 20,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7312",
    "Name": "SEKOLAH KEBANGSAAN NG EMBUAU",
    "Student": 79,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7313",
    "Name": "SEKOLAH KEBANGSAAN NG TEMALAT",
    "Student": 95,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7314",
    "Name": "SEKOLAH KEBANGSAAN NG SELIBUT",
    "Student": 35,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YBB7315",
    "Name": "SEKOLAH KEBANGSAAN ULU MELIPIS",
    "Student": 33,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBB8101",
    "Name": "SEKOLAH KEBANGSAAN PLAIE D C",
    "Student": 89,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBB8102",
    "Name": "SEKOLAH KEBANGSAAN SAMPUN",
    "Student": 77,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBB8103",
    "Name": "SEKOLAH KEBANGSAAN SAMPUN TEBUN",
    "Student": 38,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBB8105",
    "Name": "SEKOLAH KEBANGSAAN JALAN MUARA TUANG",
    "Student": 1406,
    "Teacher": 87
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8201",
    "Name": "SEKOLAH KEBANGSAAN SAMARAHAN ESTATE",
    "Student": 104,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8202",
    "Name": "SEKOLAH KEBANGSAAN TEBAKANG",
    "Student": 187,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8203",
    "Name": "SEKOLAH KEBANGSAAN TEBEDU",
    "Student": 196,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8204",
    "Name": "SEKOLAH KEBANGSAAN TEMA",
    "Student": 74,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8205",
    "Name": "SEKOLAH KEBANGSAAN TANAH MERAH",
    "Student": 110,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8206",
    "Name": "SEKOLAH KEBANGSAAN KRUSEN",
    "Student": 82,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8207",
    "Name": "SEKOLAH KEBANGSAAN LEBUR / REMUN",
    "Student": 132,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8209",
    "Name": "SEKOLAH KEBANGSAAN TARAT",
    "Student": 166,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8210",
    "Name": "SEKOLAH KEBANGSAAN RAYANG",
    "Student": 154,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8211",
    "Name": "SEKOLAH KEBANGSAAN BALAI RINGIN",
    "Student": 338,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8212",
    "Name": "SEKOLAH KEBANGSAAN TIAN MURUD",
    "Student": 51,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8213",
    "Name": "SEKOLAH KEBANGSAAN TESU",
    "Student": 60,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8214",
    "Name": "SEKOLAH KEBANGSAAN SEMUKOI",
    "Student": 73,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8215",
    "Name": "SEKOLAH KEBANGSAAN MELANSAI",
    "Student": 217,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8216",
    "Name": "SEKOLAH KEBANGSAAN RETEH",
    "Student": 56,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8217",
    "Name": "SEKOLAH KEBANGSAAN SG SAMERAN",
    "Student": 132,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8218",
    "Name": "SEKOLAH KEBANGSAAN MAPU",
    "Student": 111,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8219",
    "Name": "SEKOLAH KEBANGSAAN KORAN",
    "Student": 295,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8220",
    "Name": "SEKOLAH KEBANGSAAN PARUN SUAN",
    "Student": 121,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8221",
    "Name": "SEKOLAH KEBANGSAAN TEMONG",
    "Student": 195,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8222",
    "Name": "SEKOLAH KEBANGSAAN LOBANG BATU",
    "Student": 131,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8223",
    "Name": "SEKOLAH KEBANGSAAN RITUH",
    "Student": 71,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8224",
    "Name": "SEKOLAH KEBANGSAAN PAYAU",
    "Student": 124,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8225",
    "Name": "SEKOLAH KEBANGSAAN SG RIMU",
    "Student": 37,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8226",
    "Name": "SEKOLAH KEBANGSAAN TANAH PUTEH",
    "Student": 79,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8227",
    "Name": "SEKOLAH KEBANGSAAN PATI",
    "Student": 139,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8228",
    "Name": "SEKOLAH KEBANGSAAN BEDUP",
    "Student": 139,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8229",
    "Name": "SEKOLAH KEBANGSAAN SANGAI",
    "Student": 81,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8230",
    "Name": "SEKOLAH KEBANGSAAN SG KENYAH",
    "Student": 151,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8231",
    "Name": "SEKOLAH KEBANGSAAN ENGKAROH",
    "Student": 94,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8232",
    "Name": "SEKOLAH KEBANGSAAN DAHA",
    "Student": 95,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8233",
    "Name": "SEKOLAH KEBANGSAAN GAHAT MAWANG",
    "Student": 45,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8234",
    "Name": "SEKOLAH KEBANGSAAN SEBANBAN",
    "Student": 49,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8236",
    "Name": "SEKOLAH KEBANGSAAN SEJIJAG",
    "Student": 42,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8237",
    "Name": "SEKOLAH KEBANGSAAN GEMANG",
    "Student": 99,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8238",
    "Name": "SEKOLAH KEBANGSAAN RIIH DASO",
    "Student": 172,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8239",
    "Name": "SEKOLAH KEBANGSAAN MAWANG TAUP",
    "Student": 56,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8240",
    "Name": "SEKOLAH KEBANGSAAN KRANGAN",
    "Student": 75,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8241",
    "Name": "SEKOLAH KEBANGSAAN ENTAYAN",
    "Student": 53,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8242",
    "Name": "SEKOLAH KEBANGSAAN MERBAU",
    "Student": 102,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8243",
    "Name": "SEKOLAH KEBANGSAAN KRAIT",
    "Student": 82,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8244",
    "Name": "SEKOLAH KEBANGSAAN MERAKAI",
    "Student": 36,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8245",
    "Name": "SEKOLAH KEBANGSAAN SEMADA",
    "Student": 33,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8246",
    "Name": "SEKOLAH KEBANGSAAN KUJANG SAIN",
    "Student": 39,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8247",
    "Name": "SEKOLAH KEBANGSAAN SUNGAN",
    "Student": 36,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8249",
    "Name": "SEKOLAH KEBANGSAAN KUJANG MAWANG",
    "Student": 61,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8251",
    "Name": "SEKOLAH KEBANGSAAN PANGKALAN SORAH",
    "Student": 61,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8252",
    "Name": "SEKOLAH KEBANGSAAN LUBOK ANTU REBAN",
    "Student": 53,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8253",
    "Name": "SEKOLAH KEBANGSAAN SUMPAS",
    "Student": 12,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8254",
    "Name": "SEKOLAH KEBANGSAAN ENTUBUH",
    "Student": 70,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8255",
    "Name": "SEKOLAH KEBANGSAAN PRIDAN",
    "Student": 97,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8256",
    "Name": "SEKOLAH KEBANGSAAN MENTU TAPU",
    "Student": 85,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8257",
    "Name": "SEKOLAH KEBANGSAAN PLAMAN BAKI / MENAUL",
    "Student": 177,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8301",
    "Name": "SEKOLAH KEBANGSAAN RAJAU ENSIKA",
    "Student": 17,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8302",
    "Name": "SEKOLAH KEBANGSAAN SEBUYAU/ENSENGGEI",
    "Student": 74,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8303",
    "Name": "SEKOLAH KEBANGSAAN TUNGKAH DAYAK",
    "Student": 26,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8304",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI ARUS LUMUT",
    "Student": 24,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8305",
    "Name": "SEKOLAH KEBANGSAAN TEMIANG",
    "Student": 40,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8307",
    "Name": "SEKOLAH KEBANGSAAN NYELITAK",
    "Student": 41,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8308",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI PINANG",
    "Student": 39,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8309",
    "Name": "SEKOLAH KEBANGSAAN SEMALATONG",
    "Student": 125,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8310",
    "Name": "SEKOLAH KEBANGSAAN PADANG PEDALAI",
    "Student": 130,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBB8311",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BULU/SENANGEH",
    "Student": 59,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8312",
    "Name": "SEKOLAH KEBANGSAAN KEPAYANG",
    "Student": 55,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8313",
    "Name": "SEKOLAH KEBANGSAAN KENIONG",
    "Student": 39,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8314",
    "Name": "SEKOLAH KEBANGSAAN MUNGGU LALLANG",
    "Student": 73,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8315",
    "Name": "SEKOLAH KEBANGSAAN SG LINGKAU",
    "Student": 33,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBB8316",
    "Name": "SEKOLAH KEBANGSAAN TERASI",
    "Student": 56,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8317",
    "Name": "SEKOLAH KEBANGSAAN SEKENDU",
    "Student": 41,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8319",
    "Name": "SEKOLAH KEBANGSAAN SKITONG/MERANTI",
    "Student": 33,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8320",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI BA",
    "Student": 41,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8325",
    "Name": "SEKOLAH KEBANGSAAN GUNONG NGELI",
    "Student": 62,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8326",
    "Name": "SEKOLAH KEBANGSAAN SEBANGKOI/MAWANG",
    "Student": 54,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBB8327",
    "Name": "SEKOLAH KEBANGSAAN TELAGUS/JEROK",
    "Student": 182,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8328",
    "Name": "SEKOLAH KEBANGSAAN TEGELAM",
    "Student": 22,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8329",
    "Name": "SEKOLAH KEBANGSAAN LEPONG EMPLAS",
    "Student": 20,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8330",
    "Name": "SEKOLAH KEBANGSAAN BAJONG",
    "Student": 20,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8331",
    "Name": "SEKOLAH KEBANGSAAN MENTU",
    "Student": 22,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8332",
    "Name": "SEKOLAH KEBANGSAAN MUDING",
    "Student": 38,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8333",
    "Name": "SEKOLAH KEBANGSAAN TUBA",
    "Student": 44,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8334",
    "Name": "SEKOLAH KEBANGSAAN BULAN JERAGAM",
    "Student": 52,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBB8335",
    "Name": "SEKOLAH KEBANGSAAN LUNYING",
    "Student": 50,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9101",
    "Name": "SEKOLAH KEBANGSAAN LABANG",
    "Student": 91,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9102",
    "Name": "SEKOLAH KEBANGSAAN KUALA BINYO",
    "Student": 17,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9104",
    "Name": "SEKOLAH KEBANGSAAN SG SEBUNGAN",
    "Student": 56,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9105",
    "Name": "SEKOLAH KEBANGSAAN SG GENAAN",
    "Student": 16,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9106",
    "Name": "SEKOLAH KEBANGSAAN SG. SENGIAN LABANG",
    "Student": 81,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9107",
    "Name": "SEKOLAH KEBANGSAAN HERMANUS ASSAN",
    "Student": 58,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9108",
    "Name": "SEKOLAH KEBANGSAAN KUALA KEBULU",
    "Student": 36,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBB9109",
    "Name": "SEKOLAH KEBANGSAAN SG SILAS",
    "Student": 34,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBB9110",
    "Name": "SEKOLAH KEBANGSAAN SG. TISANG",
    "Student": 409,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBB9111",
    "Name": "SEKOLAH KEBANGSAAN SG KEM BATU 18",
    "Student": 708,
    "Teacher": 35
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBB9112",
    "Name": "SEKOLAH KEBANGSAAN SG SELAD",
    "Student": 116,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBB9113",
    "Name": "SEKOLAH KEBANGSAAN ULU SEGAN",
    "Student": 309,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9114",
    "Name": "SEKOLAH KEBANGSAAN KUALA SIGU",
    "Student": 27,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9201",
    "Name": "SEKOLAH KEBANGSAAN RH TAYAI",
    "Student": 77,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9202",
    "Name": "SEKOLAH KEBANGSAAN KUALA SERUPAI",
    "Student": 68,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9203",
    "Name": "SEKOLAH KEBANGSAAN RUMAH KESENG",
    "Student": 91,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9204",
    "Name": "SEKOLAH KEBANGSAAN RUMAH BARRAU",
    "Student": 51,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9205",
    "Name": "SEKOLAH KEBANGSAAN SANGAN IBAN",
    "Student": 92,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9206",
    "Name": "SEKOLAH KEBANGSAAN KUALA ANNAU",
    "Student": 62,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9207",
    "Name": "SEKOLAH KEBANGSAAN KUALA MUPUT",
    "Student": 85,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBB9208",
    "Name": "SEKOLAH KEBANGSAAN SG SETIAM",
    "Student": 182,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9209",
    "Name": "SEKOLAH KEBANGSAAN SG. BAGIAN",
    "Student": 55,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBB9210",
    "Name": "SEKOLAH KEBANGSAAN NG TAU",
    "Student": 104,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBB9211",
    "Name": "SEKOLAH KEBANGSAAN SG. SETULAN",
    "Student": 58,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBC1201",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SG APONG",
    "Student": 389,
    "Teacher": 37
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBC1202",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BINTAWA",
    "Student": 311,
    "Teacher": 37
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBC1302",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) STAMPIN",
    "Student": 870,
    "Teacher": 46
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBC1303",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SG TENGAH",
    "Student": 137,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBC1304",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BERATOK",
    "Student": 202,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBC1305",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAPAH",
    "Student": 327,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBC3101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAMAN RAJANG",
    "Student": 802,
    "Teacher": 50
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBC3201",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SANG MING",
    "Student": 112,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBC4101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NORTH",
    "Student": 500,
    "Teacher": 34
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBC4103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TUKAU",
    "Student": 171,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBC4302",
    "Name": "SEKOLAH JENIS KEBANGSAAN SUNGAI JAONG MARUDI",
    "Student": 192,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBC4303",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HUA KWONG",
    "Student": 97,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBC6101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HIEN",
    "Student": 60,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBC6102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TIONG HO",
    "Student": 55,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBC6103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SZE LU",
    "Student": 49,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBC6104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MINAR",
    "Student": 36,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBC6105",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAM KIEW",
    "Student": 30,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBC6106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SU LOK",
    "Student": 64,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBC6107",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KIEW MANG",
    "Student": 31,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBC6108",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HUA KEE",
    "Student": 26,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YBC6109",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HUA NAM",
    "Student": 42,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBC6401",
    "Name": "SEKOLAH KEBANGSAAN CAMPORAN",
    "Student": 434,
    "Teacher": 42
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBC8201",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SG MENYAN",
    "Student": 256,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBC8202",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) PANGKALAN BEDUP",
    "Student": 86,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBC9101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SEBIEW CHINESE",
    "Student": 1051,
    "Teacher": 66
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YBC9102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAI MING",
    "Student": 339,
    "Teacher": 24
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBE1101",
    "Name": "SEKOLAH KEBANGSAAN PODAM",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBE1102",
    "Name": "SEKOLAH KEBANGSAAN STASS",
    "Student": 82,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YBE1103",
    "Name": "SEKOLAH KEBANGSAAN SERASOT",
    "Student": 93,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBE1202",
    "Name": "SEKOLAH KEBANGSAAN LUMBA KUDA",
    "Student": 289,
    "Teacher": 45
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBE1203",
    "Name": "SEKOLAH KEBANGSAAN COMBINED",
    "Student": 391,
    "Teacher": 37
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBE1301",
    "Name": "SEKOLAH KEBANGSAAN BUNTAL",
    "Student": 417,
    "Teacher": 38
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBE1304",
    "Name": "SEKOLAH KEBANGSAAN BAKO",
    "Student": 383,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBE1308",
    "Name": "SEKOLAH KEBANGSAAN LANDEH",
    "Student": 388,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBE1313",
    "Name": "SEKOLAH KEBANGSAAN PAJAR SEJINGKAT",
    "Student": 539,
    "Teacher": 52
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBE1321",
    "Name": "SEKOLAH KEBANGSAAN STAPOK",
    "Student": 606,
    "Teacher": 45
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBE1324",
    "Name": "SEKOLAH KEBANGSAAN KPG SENARI",
    "Student": 166,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBE1327",
    "Name": "SEKOLAH KEBANGSAAN BELIONG",
    "Student": 45,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBE1341",
    "Name": "SEKOLAH KEBANGSAAN BINYU",
    "Student": 80,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YBE1342",
    "Name": "SEKOLAH KEBANGSAAN JALAN HAJI BAKI",
    "Student": 291,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBE1345",
    "Name": "SEKOLAH KEBANGSAAN MATU BARU",
    "Student": 608,
    "Teacher": 54
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBE1401",
    "Name": "SEKOLAH KEBANGSAAN BUMIPUTERA",
    "Student": 388,
    "Teacher": 41
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YBE1402",
    "Name": "SEKOLAH KEBANGSAAN RAMBUNGAN",
    "Student": 61,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBE3101",
    "Name": "SEKOLAH KEBANGSAAN ABANG ALI",
    "Student": 663,
    "Teacher": 50
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBE3102",
    "Name": "SEKOLAH KEBANGSAAN SIBU BANDARAN NO 3",
    "Student": 488,
    "Teacher": 55
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBE3201",
    "Name": "SEKOLAH KEBANGSAAN SG MENYAN",
    "Student": 71,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBE3203",
    "Name": "SEKOLAH KEBANGSAAN BATU 15",
    "Student": 325,
    "Teacher": 34
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBE3205",
    "Name": "SEKOLAH KEBANGSAAN KG SAWAI",
    "Student": 55,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBE3401",
    "Name": "SEKOLAH KEBANGSAAN DATUK AWANG UDIN",
    "Student": 308,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBE3402",
    "Name": "SEKOLAH KEBANGSAAN KG TELLIAN",
    "Student": 214,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBE3403",
    "Name": "SEKOLAH KEBANGSAAN KG JEBUNGAN",
    "Student": 115,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBE3404",
    "Name": "SEKOLAH KEBANGSAAN BUKIT INKERBAI",
    "Student": 57,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBE3405",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG TEH",
    "Student": 103,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBE3408",
    "Name": "SEKOLAH KEBANGSAAN NANGA SELANGAU",
    "Student": 250,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBE3409",
    "Name": "SEKOLAH KEBANGSAAN KG SEBERANG",
    "Student": 297,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBE3410",
    "Name": "SEKOLAH KEBANGSAAN SG PENIPAH",
    "Student": 204,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBE3411",
    "Name": "SEKOLAH KEBANGSAAN PARISH",
    "Student": 179,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBE3412",
    "Name": "SEKOLAH KEBANGSAAN KUALA LEMAI",
    "Student": 12,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBE3413",
    "Name": "SEKOLAH KEBANGSAAN IBAN UNION",
    "Student": 6,
    "Teacher": 5
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YBE3414",
    "Name": "SEKOLAH KEBANGSAAN ULU SG ARIP",
    "Student": 65,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YBE3415",
    "Name": "SEKOLAH KEBANGSAAN SLDB NO. 1",
    "Student": 141,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBE3501",
    "Name": "SEKOLAH KEBANGSAAN DATU PENGIRAN MOHAMAD",
    "Student": 254,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBE3502",
    "Name": "SEKOLAH KEBANGSAAN KG TANAM",
    "Student": 137,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBE3503",
    "Name": "SEKOLAH KEBANGSAAN KG BALAN",
    "Student": 67,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBE3504",
    "Name": "SEKOLAH KEBANGSAAN SG UD",
    "Student": 262,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBE3505",
    "Name": "SEKOLAH KEBANGSAAN SUNGAI KUT TENGAH DALAT",
    "Student": 75,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBE3506",
    "Name": "SEKOLAH KEBANGSAAN KG SENAU",
    "Student": 128,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YBE3507",
    "Name": "SEKOLAH KEBANGSAAN KG KLID/PLAJAU",
    "Student": 36,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBE4104",
    "Name": "SEKOLAH KEBANGSAAN SAYED OTHMAN",
    "Student": 154,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBE4105",
    "Name": "SEKOLAH KEBANGSAAN LUTONG",
    "Student": 1209,
    "Teacher": 73
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YBE4107",
    "Name": "SEKOLAH KEBANGSAAN LAMBIR VILLAGE",
    "Student": 710,
    "Teacher": 39
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YBE4301",
    "Name": "SEKOLAH KEBANGSAAN DATO SHARIF HAMID",
    "Student": 236,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBE5203",
    "Name": "SEKOLAH KEBANGSAAN PUSAT LAWAS",
    "Student": 462,
    "Teacher": 40
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBE5210",
    "Name": "SEKOLAH KEBANGSAAN LADANG BARU",
    "Student": 130,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YBE5212",
    "Name": "SEKOLAH KEBANGSAAN BELIPAT",
    "Student": 112,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YBE6301",
    "Name": "SEKOLAH KEBANGSAAN ABANG AMIN",
    "Student": 294,
    "Teacher": 34
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBE6401",
    "Name": "SEKOLAH KEBANGSAAN SOK",
    "Student": 40,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBE6402",
    "Name": "SEKOLAH KEBANGSAAN KUALA MATU",
    "Student": 206,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBE6404",
    "Name": "SEKOLAH KEBANGSAAN ULU DARO",
    "Student": 160,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBE6405",
    "Name": "SEKOLAH KEBANGSAAN KG TEBAANG",
    "Student": 199,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBE6406",
    "Name": "SEKOLAH KEBANGSAAN NANGAR",
    "Student": 158,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBE6407",
    "Name": "SEKOLAH KEBANGSAAN KG BRUIT",
    "Student": 130,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YBE6408",
    "Name": "SEKOLAH KEBANGSAAN O K SELAIR",
    "Student": 100,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBE8107",
    "Name": "SEKOLAH KEBANGSAAN REBAK",
    "Student": 90,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBE8108",
    "Name": "SEKOLAH KEBANGSAAN ASAJAYA LAUT",
    "Student": 228,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBE8109",
    "Name": "SEKOLAH KEBANGSAAN ASAJAYA ULU",
    "Student": 194,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YBE8201",
    "Name": "SEKOLAH KEBANGSAAN SERIAN",
    "Student": 544,
    "Teacher": 45
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBE8303",
    "Name": "SEKOLAH KEBANGSAAN HAJI BUJANG RANGAWAN PUTIN",
    "Student": 233,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBE8305",
    "Name": "SEKOLAH KEBANGSAAN JA'IE",
    "Student": 145,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBE8307",
    "Name": "SEKOLAH KEBANGSAAN ABANG MAN",
    "Student": 369,
    "Teacher": 46
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBE8310",
    "Name": "SEKOLAH KEBANGSAAN LUBOK PUNGGOR",
    "Student": 95,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YBE8312",
    "Name": "SEKOLAH KEBANGSAAN SAGENG",
    "Student": 215,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YBE8313",
    "Name": "SEKOLAH KEBANGSAAN PENDAM",
    "Student": 222,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBE9108",
    "Name": "SEKOLAH KEBANGSAAN SG BUKIT BALAI",
    "Student": 236,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YBE9207",
    "Name": "SEKOLAH KEBANGSAAN TATAU",
    "Student": 698,
    "Teacher": 50
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBH1202",
    "Name": "SEKOLAH KEBANGSAAN SONG KHENG HAI",
    "Student": 276,
    "Teacher": 37
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YBH1301",
    "Name": "SEKOLAH KEBANGSAAN KENYALANG",
    "Student": 366,
    "Teacher": 38
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBH3101",
    "Name": "SEKOLAH KEBANGSAAN SIBU BANDARAN NO 2",
    "Student": 359,
    "Teacher": 43
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YBH3102",
    "Name": "SEKOLAH KEBANGSAAN PERBANDARAN SIBU NO. 4",
    "Student": 851,
    "Teacher": 73
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCA1201",
    "Name": "SEKOLAH KEBANGSAAN RAKYAT",
    "Student": 362,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCA1301",
    "Name": "SEKOLAH KEBANGSAAN RAKYAT TUPONG",
    "Student": 333,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCA1303",
    "Name": "SEKOLAH KEBANGSAAN MADRASAH DATUK HJ. ABDUL KADIR HASAN",
    "Student": 1218,
    "Teacher": 90
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCA2101",
    "Name": "SEKOLAH KEBANGSAAN AGAMA SRI AMAN",
    "Student": 341,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCA3101",
    "Name": "SEKOLAH KEBANGSAAN AGAMA SIBU",
    "Student": 504,
    "Teacher": 46
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YCA4101",
    "Name": "SEKOLAH KEBANGSAAN AGAMA",
    "Student": 635,
    "Teacher": 50
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YCA5201",
    "Name": "SEKOLAH KEBANGSAAN AGAMA (MIS) LAWAS",
    "Student": 487,
    "Teacher": 38
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCA6101",
    "Name": "SEKOLAH KEBANGSAAN AGAMA SARIKEI",
    "Student": 381,
    "Teacher": 39
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCA8101",
    "Name": "SEKOLAH KEBANGSAAN (A) IBNU KHALDUN",
    "Student": 514,
    "Teacher": 46
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YCA9101",
    "Name": "SEKOLAH KEBANGSAAN AGAMA (M.I.S) BINTULU",
    "Student": 489,
    "Teacher": 45
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YCAB101",
    "Name": "SEKOLAH KEBANGSAAN (AGAMA) MAJLIS ISLAM SARAWAK MUKAH",
    "Student": 290,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCB1101",
    "Name": "SEKOLAH KEBANGSAAN ST PATRICK",
    "Student": 144,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCB1102",
    "Name": "SEKOLAH KEBANGSAAN ST TERESA",
    "Student": 154,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCB1103",
    "Name": "SEKOLAH KEBANGSAAN ST STEPHEN ( M )",
    "Student": 198,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCB1105",
    "Name": "SEKOLAH KEBANGSAAN ST JOHN",
    "Student": 251,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCB1201",
    "Name": "SEKOLAH KEBANGSAAN ST THOMAS",
    "Student": 540,
    "Teacher": 39
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCB1202",
    "Name": "SEKOLAH KEBANGSAAN ST MARY",
    "Student": 586,
    "Teacher": 35
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCB1204",
    "Name": "SEKOLAH KEBANGSAAN ST TERESA",
    "Student": 550,
    "Teacher": 38
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCB1206",
    "Name": "SEKOLAH KEBANGSAAN CATHOLIC ENGLISH",
    "Student": 264,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCB1207",
    "Name": "SEKOLAH KEBANGSAAN ST ANDREW",
    "Student": 350,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1301",
    "Name": "SEKOLAH KEBANGSAAN EMPEROH JAMBU",
    "Student": 54,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1302",
    "Name": "SEKOLAH KEBANGSAAN ST FRANCIS XAVIER SERATAU",
    "Student": 258,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1303",
    "Name": "SEKOLAH KEBANGSAAN ST ELIZABETH",
    "Student": 383,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1304",
    "Name": "SEKOLAH KEBANGSAAN ST PETER",
    "Student": 257,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1305",
    "Name": "SEKOLAH KEBANGSAAN SACRED HEART",
    "Student": 203,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1306",
    "Name": "SEKOLAH KEBANGSAAN PANGKALAN KUUD",
    "Student": 92,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1307",
    "Name": "SEKOLAH KEBANGSAAN SUBANG",
    "Student": 58,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1308",
    "Name": "SEKOLAH KEBANGSAAN SIRA/KRIAN",
    "Student": 79,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1309",
    "Name": "SEKOLAH KEBANGSAAN PESANG BEGU",
    "Student": 104,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1311",
    "Name": "SEKOLAH KEBANGSAAN KG GAYU",
    "Student": 137,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1312",
    "Name": "SEKOLAH KEBANGSAAN ST ALBAN",
    "Student": 127,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1317",
    "Name": "SEKOLAH KEBANGSAAN ST PHILIP",
    "Student": 15,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1318",
    "Name": "SEKOLAH KEBANGSAAN ST AUGUSTINE",
    "Student": 274,
    "Teacher": 24
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1319",
    "Name": "SEKOLAH KEBANGSAAN GEORGE",
    "Student": 344,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1320",
    "Name": "SEKOLAH KEBANGSAAN ST MATTHEW",
    "Student": 103,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1321",
    "Name": "SEKOLAH KEBANGSAAN ST JAMES QUOP",
    "Student": 176,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1322",
    "Name": "SEKOLAH KEBANGSAAN ST ALBAN DURAS",
    "Student": 67,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1324",
    "Name": "SEKOLAH KEBANGSAAN ST PATRICK",
    "Student": 111,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1325",
    "Name": "SEKOLAH KEBANGSAAN ST PAUL",
    "Student": 123,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1327",
    "Name": "SEKOLAH KEBANGSAAN ST JAMES RAYANG",
    "Student": 41,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1328",
    "Name": "SEKOLAH KEBANGSAAN ST DUNSTAN",
    "Student": 74,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1329",
    "Name": "SEKOLAH KEBANGSAAN ST GREGORY",
    "Student": 60,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1330",
    "Name": "SEKOLAH KEBANGSAAN ST DAVID",
    "Student": 237,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1331",
    "Name": "SEKOLAH KEBANGSAAN ST BERNARD",
    "Student": 88,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1332",
    "Name": "SEKOLAH KEBANGSAAN ST EDWARD BELIMBING",
    "Student": 62,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1333",
    "Name": "SEKOLAH KEBANGSAAN ST GILE",
    "Student": 111,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB1334",
    "Name": "SEKOLAH KEBANGSAAN ST SWITHUN",
    "Student": 82,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCB1335",
    "Name": "SEKOLAH KEBANGSAAN GARLAND",
    "Student": 369,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YCB1401",
    "Name": "SEKOLAH KEBANGSAAN HOLY NAME",
    "Student": 125,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCB2101",
    "Name": "SEKOLAH KEBANGSAAN ST MARTIN",
    "Student": 47,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCB2102",
    "Name": "SEKOLAH KEBANGSAAN ST LAWRENCE",
    "Student": 216,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCB2103",
    "Name": "SEKOLAH KEBANGSAAN ST PAUL",
    "Student": 21,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCB2105",
    "Name": "SEKOLAH KEBANGSAAN ST DUNSTAN",
    "Student": 20,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCB2106",
    "Name": "SEKOLAH KEBANGSAAN ST LEO GAYAU",
    "Student": 28,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YCB2201",
    "Name": "SEKOLAH KEBANGSAAN ST PETER SARATOK",
    "Student": 274,
    "Teacher": 34
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YCB2202",
    "Name": "SEKOLAH KEBANGSAAN ST PAUL ROBAN",
    "Student": 151,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YCB2203",
    "Name": "SEKOLAH KEBANGSAAN ST MICHAEL PLASSU",
    "Student": 55,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCB2402",
    "Name": "SEKOLAH KEBANGSAAN ST. CHRISTOPHER DEBAK",
    "Student": 140,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCB2403",
    "Name": "SEKOLAH KEBANGSAAN ST. PETER & ST. PAUL",
    "Student": 64,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCB2405",
    "Name": "SEKOLAH KEBANGSAAN ST. BARTHOLOMEW",
    "Student": 42,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCB2407",
    "Name": "SEKOLAH KEBANGSAAN ST. BARNABAS",
    "Student": 24,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCB2408",
    "Name": "SEKOLAH KEBANGSAAN ST. JOHN",
    "Student": 125,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCB2409",
    "Name": "SEKOLAH KEBANGSAAN ST. AUGUSTINE",
    "Student": 312,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCB2410",
    "Name": "SEKOLAH KEBANGSAAN ST. MARK",
    "Student": 17,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCB3101",
    "Name": "SEKOLAH KEBANGSAAN ST RITA",
    "Student": 298,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCB3102",
    "Name": "SEKOLAH KEBANGSAAN SACRED HEART",
    "Student": 238,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCB3103",
    "Name": "SEKOLAH KEBANGSAAN ST. MARY",
    "Student": 357,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCB3105",
    "Name": "SEKOLAH KEBANGSAAN HUA HIN ENGLISH",
    "Student": 280,
    "Teacher": 24
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YCB3301",
    "Name": "SEKOLAH KEBANGSAAN ST FRANCIS XAVIER",
    "Student": 376,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YCB3401",
    "Name": "SEKOLAH KEBANGSAAN ST. PATRICK",
    "Student": 399,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YCB3501",
    "Name": "SEKOLAH KEBANGSAAN ST MATTHEW SEKUAU",
    "Student": 72,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YCB3502",
    "Name": "SEKOLAH KEBANGSAAN ST LUKE NG BAOH",
    "Student": 27,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YCB3503",
    "Name": "SEKOLAH KEBANGSAAN ST MARK",
    "Student": 48,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YCB3504",
    "Name": "SEKOLAH KEBANGSAAN ST. JUDE",
    "Student": 55,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YCB3505",
    "Name": "SEKOLAH KEBANGSAAN ST BERNARD",
    "Student": 119,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YCB3506",
    "Name": "SEKOLAH KEBANGSAAN ST JOHN KG MEDONG",
    "Student": 204,
    "Teacher": 22
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YCB3507",
    "Name": "SEKOLAH KEBANGSAAN ST KEVIN SG KUT",
    "Student": 102,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YCB4101",
    "Name": "SEKOLAH KEBANGSAAN ST JOSEPH",
    "Student": 553,
    "Teacher": 41
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YCB4102",
    "Name": "SEKOLAH KEBANGSAAN ST COLUMBA",
    "Student": 612,
    "Teacher": 40
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YCB4302",
    "Name": "SEKOLAH KEBANGSAAN ST PIUS LONG SAN",
    "Student": 263,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YCB4303",
    "Name": "SEKOLAH KEBANGSAAN GOOD SHEPHERD",
    "Student": 199,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YCB5101",
    "Name": "SEKOLAH KEBANGSAAN ST EDMUND",
    "Student": 386,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YCB5102",
    "Name": "SEKOLAH KEBANGSAAN R C KUBONG",
    "Student": 126,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCB6101",
    "Name": "SEKOLAH KEBANGSAAN ST ANNE",
    "Student": 794,
    "Teacher": 47
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YCB6201",
    "Name": "SEKOLAH KEBANGSAAN ST ALPHONSUS",
    "Student": 284,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCB6301",
    "Name": "SEKOLAH KEBANGSAAN ST AUGUSTINE",
    "Student": 188,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YCB7101",
    "Name": "SEKOLAH KEBANGSAAN METHODIST",
    "Student": 770,
    "Teacher": 40
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YCB7102",
    "Name": "SEKOLAH KEBANGSAAN NG TIAU",
    "Student": 33,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YCB7103",
    "Name": "SEKOLAH KEBANGSAAN NG MELUAN",
    "Student": 14,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YCB7104",
    "Name": "SEKOLAH KEBANGSAAN NANGA ENTULOH",
    "Student": 13,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YCB7105",
    "Name": "SEKOLAH KEBANGSAAN SG AMANG",
    "Student": 652,
    "Teacher": 37
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YCB7106",
    "Name": "SEKOLAH KEBANGSAAN NG MUJONG",
    "Student": 87,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YCB7107",
    "Name": "SEKOLAH KEBANGSAAN SG MENUAN",
    "Student": 35,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YCB7108",
    "Name": "SEKOLAH KEBANGSAAN LBK MAWANG",
    "Student": 19,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YCB7301",
    "Name": "SEKOLAH KEBANGSAAN CARDINAL VAUGHAN",
    "Student": 592,
    "Teacher": 45
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCB8110",
    "Name": "SEKOLAH KEBANGSAAN KAMPUNG REMBUS",
    "Student": 119,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCB8111",
    "Name": "SEKOLAH KEBANGSAAN ST JOHN",
    "Student": 156,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCB8112",
    "Name": "SEKOLAH KEBANGSAAN ST MICHAEL",
    "Student": 232,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCB8113",
    "Name": "SEKOLAH KEBANGSAAN MERDANG",
    "Student": 979,
    "Teacher": 49
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8202",
    "Name": "SEKOLAH KEBANGSAAN ST PHILIP BUGU",
    "Student": 59,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8203",
    "Name": "SEKOLAH KEBANGSAAN ST JOHN MANTUNG",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8204",
    "Name": "SEKOLAH KEBANGSAAN ST MICHAEL MONGKOS",
    "Student": 119,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8205",
    "Name": "SEKOLAH KEBANGSAAN ST DOMINIC PICHIN",
    "Student": 126,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8206",
    "Name": "SEKOLAH KEBANGSAAN ST ANTHONY KAWAN",
    "Student": 90,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8207",
    "Name": "SEKOLAH KEBANGSAAN ST JUDE BUNAN",
    "Student": 224,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8208",
    "Name": "SEKOLAH KEBANGSAAN ST PATRICK TANGGA",
    "Student": 246,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8209",
    "Name": "SEKOLAH KEBANGSAAN ST HENRY SLABI",
    "Student": 98,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8210",
    "Name": "SEKOLAH KEBANGSAAN ST ALBAN AMPUNGAN",
    "Student": 107,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8211",
    "Name": "SEKOLAH KEBANGSAAN ST NORBERT PAUN GAHAT",
    "Student": 47,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8212",
    "Name": "SEKOLAH KEBANGSAAN ST RAYMOND MUJAT",
    "Student": 177,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8213",
    "Name": "SEKOLAH KEBANGSAAN ST BARNABAS BARU",
    "Student": 93,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8214",
    "Name": "SEKOLAH KEBANGSAAN ST JOHN TAEE",
    "Student": 158,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8215",
    "Name": "SEKOLAH KEBANGSAAN ST MATHEW LANCHANG",
    "Student": 58,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8216",
    "Name": "SEKOLAH KEBANGSAAN ST AMBROSE PANCHOR",
    "Student": 53,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCB8217",
    "Name": "SEKOLAH KEBANGSAAN ALL SAINTS PLAMAN NYABET",
    "Student": 28,
    "Teacher": 9
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YCB9101",
    "Name": "SEKOLAH KEBANGSAAN ST. ANTHONY",
    "Student": 1184,
    "Teacher": 52
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCC1101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TAITON",
    "Student": 172,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCC1102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PAKU",
    "Student": 142,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCC1103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TONDONG",
    "Student": 108,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCC1104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEBUKU",
    "Student": 72,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCC1105",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA MUSI",
    "Student": 59,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCC1106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BUSO",
    "Student": 72,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCC1107",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SINIAWAN",
    "Student": 213,
    "Teacher": 24
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCC1108",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BAU",
    "Student": 312,
    "Teacher": 28
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YCC1109",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA KRANJI",
    "Student": 89,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCC1201",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST PAUL",
    "Student": 536,
    "Teacher": 36
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCC1202",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA 1",
    "Student": 459,
    "Teacher": 42
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCC1203",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA 2",
    "Student": 1330,
    "Teacher": 63
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCC1204",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA 3",
    "Student": 1243,
    "Teacher": 64
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCC1205",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA NO.4 KUCHING",
    "Student": 1123,
    "Teacher": 65
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCC1206",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PENDING",
    "Student": 143,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1207",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA NO.6",
    "Student": 1066,
    "Teacher": 65
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCC1301",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA NO. 5",
    "Student": 778,
    "Teacher": 64
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1302",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG TENGAH",
    "Student": 419,
    "Teacher": 30
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1303",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 8 1/2",
    "Student": 114,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1305",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG.TAPANG",
    "Student": 551,
    "Teacher": 39
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1306",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAM HAP HIN",
    "Student": 1089,
    "Teacher": 61
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1307",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 11",
    "Student": 70,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1308",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEJIJAK",
    "Student": 100,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1310",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 15",
    "Student": 175,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCC1311",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG BUDA",
    "Student": 73,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1312",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BT KITANG",
    "Student": 75,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1313",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG MOYAN",
    "Student": 250,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1314",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BT KAWA",
    "Student": 135,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1315",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG. LUBAK",
    "Student": 299,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1316",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA STAPOK",
    "Student": 264,
    "Teacher": 24
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1317",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA KIM CHOO SENG",
    "Student": 142,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1318",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA RANTAU PANJANG",
    "Student": 60,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1319",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA STAMPIN",
    "Student": 150,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YCC1321",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BUNTAL",
    "Student": 126,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1322",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 4 1/2",
    "Student": 950,
    "Teacher": 52
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCC1323",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BELIONG",
    "Student": 22,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1324",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PANGKALAN BARU",
    "Student": 136,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1327",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 10",
    "Student": 1333,
    "Teacher": 69
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1329",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SYN-SAN-TU",
    "Student": 155,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1330",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG TAPANG BT KAWA",
    "Student": 33,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YCC1331",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 7",
    "Student": 552,
    "Teacher": 43
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCC1334",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SIBURAN",
    "Student": 875,
    "Teacher": 49
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YCC1401",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEMATAN",
    "Student": 122,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YCC1402",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA LUNDU",
    "Student": 402,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YCC1404",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SERAYAN/SELARAT",
    "Student": 56,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCC2102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA MELUDAM",
    "Student": 110,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCC2103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SIMANGGANG",
    "Student": 862,
    "Teacher": 59
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCC2104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BANGKONG",
    "Student": 78,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCC2105",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA UNDOP",
    "Student": 44,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCC2106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PANTU",
    "Student": 92,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCC2107",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA LINGGA",
    "Student": 54,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YCC2201",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA ROBAN",
    "Student": 162,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YCC2202",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MIN SYN SARATOK",
    "Student": 316,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YCC2203",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA KABONG",
    "Student": 84,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YCC2301",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA ENGKILILI",
    "Student": 242,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCC2401",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BETONG",
    "Student": 342,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCC2402",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA DEBAK",
    "Student": 153,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCC2403",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SPAOH",
    "Student": 156,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YCC2404",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PUSA",
    "Student": 81,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SACRED HEART CHINESE",
    "Student": 452,
    "Teacher": 38
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG HUA",
    "Student": 746,
    "Teacher": 50
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) GUONG ANN",
    "Student": 377,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3105",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA",
    "Student": 449,
    "Teacher": 40
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) DUNG SANG",
    "Student": 460,
    "Teacher": 36
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3107",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) THAI KWANG",
    "Student": 939,
    "Teacher": 71
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3108",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) UK DAIK",
    "Student": 271,
    "Teacher": 34
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3109",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TIONG HIN",
    "Student": 350,
    "Teacher": 38
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3110",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) EK THEI",
    "Student": 276,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3111",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SING",
    "Student": 759,
    "Teacher": 53
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3112",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KIEW NANG",
    "Student": 344,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3113",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) THIAN HUA",
    "Student": 784,
    "Teacher": 49
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3201",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) THIAN CHIN",
    "Student": 775,
    "Teacher": 48
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3203",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YONG CHENG",
    "Student": 9,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3205",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) DO NANG",
    "Student": 62,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3206",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) GUONG MING",
    "Student": 103,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3208",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KENG NANG",
    "Student": 21,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3209",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG UNG",
    "Student": 23,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3210",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG KIEW",
    "Student": 31,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3211",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NANG KIANG",
    "Student": 30,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3212",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HANG KWONG",
    "Student": 18,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3213",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YONG SHING",
    "Student": 14,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3214",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAI NANG",
    "Student": 31,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3215",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SU LAI",
    "Student": 845,
    "Teacher": 51
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3217",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TING SING",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3219",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SUNG SANG",
    "Student": 128,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3220",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NANG SANG",
    "Student": 571,
    "Teacher": 33
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3221",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAM LAM",
    "Student": 15,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3222",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KWONG HUA",
    "Student": 60,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3223",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ING GUONG",
    "Student": 72,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3225",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SING MING",
    "Student": 52,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3226",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) UNG NANG",
    "Student": 40,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3228",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KWONG KOK",
    "Student": 47,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3230",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHAO SU",
    "Student": 33,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3231",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BOI ING",
    "Student": 12,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3232",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TAI TUNG 2",
    "Student": 32,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3233",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HING UNG",
    "Student": 32,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3234",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KIANG HIN",
    "Student": 239,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3235",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NENG SHING",
    "Student": 47,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3236",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHENG",
    "Student": 120,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3237",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAI DEE",
    "Student": 29,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCC3238",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHAI",
    "Student": 18,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YCC3301",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIH MONG",
    "Student": 34,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YCC3302",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SING SHING",
    "Student": 9,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YCC3303",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YEE TING",
    "Student": 251,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YCC3304",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MING WOK",
    "Student": 37,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YCC3305",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) LIANG HUA",
    "Student": 18,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YCC3306",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SHING KOK",
    "Student": 32,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YCC3307",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAM HUA",
    "Student": 43,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YCC3308",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YOK MING",
    "Student": 75,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YCC3309",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SHING HUA",
    "Student": 21,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YCC3311",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG HUA",
    "Student": 40,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YCC3402",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) EK HUA",
    "Student": 35,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YCC3403",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG BOON",
    "Student": 501,
    "Teacher": 35
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YCC3404",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BALINGIAN",
    "Student": 93,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YCC3405",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TONG AH",
    "Student": 132,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YCC3501",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YAK TEE",
    "Student": 70,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YCC3502",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HUA",
    "Student": 137,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YCC3503",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAN SAN",
    "Student": 16,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YCC3504",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) POI YUK",
    "Student": 93,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YCC4101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA MIRI",
    "Student": 1150,
    "Teacher": 66
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YCC4102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA KROKOP",
    "Student": 1102,
    "Teacher": 68
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YCC4103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA LUTONG",
    "Student": 1085,
    "Teacher": 59
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YCC4104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PUJUT",
    "Student": 1258,
    "Teacher": 56
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YCC4105",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BAKAM",
    "Student": 83,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YCC4106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SAN MIRI",
    "Student": 1552,
    "Teacher": 64
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YCC4107",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TUDAN",
    "Student": 1337,
    "Teacher": 65
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YCC4302",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA",
    "Student": 166,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YCC4303",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KEE TEE",
    "Student": 91,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YCC4305",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HUA NAM",
    "Student": 60,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YCC4401",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SIBUTI",
    "Student": 126,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YCC4402",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE MUNG",
    "Student": 310,
    "Teacher": 23
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YCC4403",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN NAM",
    "Student": 118,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YCC4404",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG LUMUT",
    "Student": 46,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YCC5101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA LIMBANG",
    "Student": 261,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YCC5102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HIN",
    "Student": 301,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YCC5201",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TRUSAN",
    "Student": 75,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YCC5202",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA LAWAS",
    "Student": 260,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YCC5203",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SOON HWA SUNDAR",
    "Student": 55,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) BULAT",
    "Student": 58,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG CHIEN",
    "Student": 340,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SU HING",
    "Student": 38,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6105",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SU KWONG",
    "Student": 127,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6106",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SU MING",
    "Student": 98,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6107",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SU TAK",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6108",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIUNG HUA",
    "Student": 91,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6110",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) WAH MAN",
    "Student": 277,
    "Teacher": 27
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6111",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SING MING",
    "Student": 37,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6112",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SU LEE",
    "Student": 100,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6113",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SU DOK",
    "Student": 43,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6114",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SAN MING",
    "Student": 52,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6115",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MING TAK",
    "Student": 7,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCC6117",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) ST MARTIN",
    "Student": 73,
    "Teacher": 18
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YCC6201",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YUK KUNG",
    "Student": 119,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6302",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG CHENG",
    "Student": 51,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6303",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MING LU",
    "Student": 35,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6304",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) YONG KWONG",
    "Student": 38,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6305",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TONG HUA",
    "Student": 77,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6306",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NAN CHIEW",
    "Student": 36,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6307",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG KWONG",
    "Student": 39,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6308",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MING CHIANG",
    "Student": 38,
    "Teacher": 10
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6309",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MING SHING",
    "Student": 14,
    "Teacher": 8
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6310",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAI CHUNG",
    "Student": 215,
    "Teacher": 29
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6311",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MING TEE",
    "Student": 123,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6313",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MIN DAIK",
    "Student": 90,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6314",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) MING CHING",
    "Student": 14,
    "Teacher": 7
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6315",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) NANG HUA",
    "Student": 7,
    "Teacher": 6
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YCC6317",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) KAI SING",
    "Student": 70,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YCC6401",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA",
    "Student": 118,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YCC7101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HOCK LAM",
    "Student": 644,
    "Teacher": 46
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YCC7301",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) HIN HUA",
    "Student": 208,
    "Teacher": 19
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCC8101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG JERNANG",
    "Student": 478,
    "Teacher": 37
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCC8102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TAMBIRAT",
    "Student": 61,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCC8103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA ASAJAYA",
    "Student": 95,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCC8104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SAMBIR",
    "Student": 65,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCC8105",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BAKO",
    "Student": 260,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCC8201",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SERIAN",
    "Student": 482,
    "Teacher": 38
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCC8202",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 29",
    "Student": 79,
    "Teacher": 14
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCC8204",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 35",
    "Student": 201,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YCC8205",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 32",
    "Student": 161,
    "Teacher": 16
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YCC8301",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SIMUNJAN",
    "Student": 173,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCC8302",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEMERA",
    "Student": 48,
    "Teacher": 12
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YCC8303",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEBANGAN",
    "Student": 34,
    "Teacher": 11
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YCC8304",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA JEMUKAN",
    "Student": 64,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YCC8306",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEBUYAU",
    "Student": 57,
    "Teacher": 13
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YCC9101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BINTULU",
    "Student": 1255,
    "Teacher": 67
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YCC9102",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SAN",
    "Student": 173,
    "Teacher": 17
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YCC9103",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) SIONG BOON",
    "Student": 1215,
    "Teacher": 64
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YCC9104",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BINTULU 2",
    "Student": 642,
    "Teacher": 48
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YCC9201",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TATAU",
    "Student": 351,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YCE2101",
    "Name": "SEKOLAH KEBANGSAAN ST LUKE",
    "Student": 398,
    "Teacher": 33
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YCH3101",
    "Name": "SEKOLAH JENIS KEBANGSAAN (CINA) METHODIST",
    "Student": 1147,
    "Teacher": 66
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YCH6101",
    "Name": "SEKOLAH KEBANGSAAN METHODIST ANGLO-CHINESE",
    "Student": 542,
    "Teacher": 41
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YEA1101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAKU (S)",
    "Student": 1489,
    "Teacher": 89
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YEA1102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SINGAI",
    "Student": 795,
    "Teacher": 55
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEA1201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN D P H ABDUL GAPOR",
    "Student": 1698,
    "Teacher": 147
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEA1202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI SETIA",
    "Student": 1092,
    "Teacher": 73
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YEA1203",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SG MAONG",
    "Student": 1135,
    "Teacher": 91
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEA1204",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TABUAN JAYA",
    "Student": 457,
    "Teacher": 50
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEA1205",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAKO",
    "Student": 1431,
    "Teacher": 97
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YEA1206",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN ARANG",
    "Student": 1701,
    "Teacher": 96
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEA1207",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DEMAK BARU",
    "Student": 1057,
    "Teacher": 70
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YEA1208",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MATANG JAYA",
    "Student": 1474,
    "Teacher": 126
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEA1209",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR SAMARIANG",
    "Student": 1128,
    "Teacher": 71
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEA1210",
    "Name": "SEKOLAH SENI MALAYSIA SARAWAK",
    "Student": 292,
    "Teacher": 54
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YEA1211",
    "Name": "SEKOLAH MENENGAH SAINS KUCHING UTARA",
    "Student": 653,
    "Teacher": 61
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEA1301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PETRA JAYA",
    "Student": 1452,
    "Teacher": 101
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YEA1302",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN WIRA PENRISSEN",
    "Student": 2213,
    "Teacher": 155
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEA1303",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABDUL RAHMAN",
    "Student": 1087,
    "Teacher": 69
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YEA1304",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU KAWA",
    "Student": 1874,
    "Teacher": 139
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEA1305",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SANTUBONG",
    "Student": 651,
    "Teacher": 43
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YEA1306",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADAWAN",
    "Student": 837,
    "Teacher": 57
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YEA1307",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MATANG HILIR",
    "Student": 1381,
    "Teacher": 101
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YEA1308",
    "Name": "SEKOLAH MENENGAH SAINS KUCHING",
    "Student": 495,
    "Teacher": 57
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEA1309",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEMERAH PADI",
    "Student": 1677,
    "Teacher": 110
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YEA1310",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI MATANG",
    "Student": 1017,
    "Teacher": 61
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YEA1401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEMATAN",
    "Student": 765,
    "Teacher": 68
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YEA1402",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SENIBONG",
    "Student": 603,
    "Teacher": 44
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YEA2101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI AMAN",
    "Student": 1102,
    "Teacher": 102
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YEA2102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LINGGA",
    "Student": 479,
    "Teacher": 42
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YEA2201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN (BM) SARATOK",
    "Student": 1020,
    "Teacher": 88
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YEA2202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KABONG",
    "Student": 1008,
    "Teacher": 76
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YEA2401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUSA",
    "Student": 1050,
    "Teacher": 81
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YEA2402",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SPAOH",
    "Student": 948,
    "Teacher": 80
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YEA2403",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ULU LAYAR",
    "Student": 363,
    "Teacher": 31
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YEA2404",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BELADIN",
    "Student": 868,
    "Teacher": 60
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEA3101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT LIMA",
    "Student": 547,
    "Teacher": 47
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEA3102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DESHON",
    "Student": 829,
    "Teacher": 66
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEA3103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIBU JAYA",
    "Student": 2284,
    "Teacher": 140
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEA3104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LANANG",
    "Student": 1157,
    "Teacher": 93
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEA3105",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNGAI MERAH",
    "Student": 1060,
    "Teacher": 96
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEA3106",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR SIBU",
    "Student": 925,
    "Teacher": 85
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEA3107",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BUKIT ASSEK",
    "Student": 952,
    "Teacher": 64
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEA3201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAWANG ASSAN",
    "Student": 358,
    "Teacher": 32
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YEA3202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATANG IGAN",
    "Student": 189,
    "Teacher": 21
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YEA3302",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KANOWIT",
    "Student": 1220,
    "Teacher": 102
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YEA3303",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN NANGA DAP",
    "Student": 317,
    "Teacher": 32
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SELANGAU",
    "Kod": "YEA3401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ULU BALINGIAN",
    "Student": 649,
    "Teacher": 46
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YEA3402",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUKAH",
    "Student": 1378,
    "Teacher": 98
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YEA3501",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN OYA",
    "Student": 927,
    "Teacher": 81
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YEA4101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATO PERMAISURI",
    "Student": 1230,
    "Teacher": 86
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YEA4102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BARU",
    "Student": 752,
    "Teacher": 71
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YEA4103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LUAK",
    "Student": 621,
    "Teacher": 48
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YEA4104",
    "Name": "SEKOLAH MENENGAH SAINS MIRI",
    "Student": 454,
    "Teacher": 50
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YEA4105",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN RIAM",
    "Student": 1164,
    "Teacher": 68
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YEA4106",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LOPENG TENGAH",
    "Student": 685,
    "Teacher": 51
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YEA4107",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MERBAU",
    "Student": 3082,
    "Teacher": 165
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YEA4108",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAMAN TUNKU",
    "Student": 1091,
    "Teacher": 64
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YEA4109",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PUJUT",
    "Student": 2583,
    "Teacher": 130
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YEA4301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEMENGGONG DATUK LAWAI JAU",
    "Student": 595,
    "Teacher": 38
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YEA4304",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUTOH APOH",
    "Student": 214,
    "Teacher": 20
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YEA4305",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TELANG USAN",
    "Student": 392,
    "Teacher": 34
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YEA4306",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINJAR",
    "Student": 902,
    "Teacher": 58
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YEA4401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUAI",
    "Student": 1169,
    "Teacher": 75
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YEA4402",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BEKENU",
    "Student": 560,
    "Teacher": 44
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YEA5101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERI PATIAMBUN LIMBANG",
    "Student": 889,
    "Teacher": 70
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YEA5102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KUBONG",
    "Student": 831,
    "Teacher": 94
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YEA5201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUNDAR",
    "Student": 461,
    "Teacher": 36
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YEA5202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MERAPOK",
    "Student": 845,
    "Teacher": 79
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YEA6101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BELAWAI",
    "Student": 563,
    "Teacher": 47
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YEA6102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SARIKEI BARU",
    "Student": 835,
    "Teacher": 62
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YEA6201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PAKAN",
    "Student": 816,
    "Teacher": 65
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YEA6202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JULAU NO.2",
    "Student": 701,
    "Teacher": 52
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YEA6301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BINTANGOR",
    "Student": 662,
    "Teacher": 63
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YEA7101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BALLEH",
    "Student": 192,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YEA7102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAKUN",
    "Student": 1367,
    "Teacher": 84
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YEA7103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAPIT NO.2",
    "Student": 1576,
    "Teacher": 124
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YEA7301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KATIBAS",
    "Student": 240,
    "Teacher": 26
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YEA7302",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SONG NO. 2",
    "Student": 485,
    "Teacher": 36
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YEA8101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ASAJAYA",
    "Student": 1189,
    "Teacher": 103
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YEA8102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KOTA SAMARAHAN",
    "Student": 2505,
    "Teacher": 154
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YEA8103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN HAJJAH LAILA TAIB",
    "Student": 1355,
    "Teacher": 83
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YEA8201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TAEE",
    "Student": 833,
    "Teacher": 74
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YEA8202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEBEDU",
    "Student": 986,
    "Teacher": 68
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YEA8301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SRI SADONG",
    "Student": 564,
    "Teacher": 40
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YEA8302",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GEDONG",
    "Student": 742,
    "Teacher": 61
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YEA8303",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SADONG HILIR (SEMERA)",
    "Student": 485,
    "Teacher": 44
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YEA8304",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEBUYAU",
    "Student": 928,
    "Teacher": 64
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YEA8401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SADONG JAYA",
    "Student": 1051,
    "Teacher": 65
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YEA9101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR BINTULU",
    "Student": 2576,
    "Teacher": 144
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YEA9102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BARU BINTULU",
    "Student": 1990,
    "Teacher": 141
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YEA9103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KIDURONG",
    "Student": 2368,
    "Teacher": 164
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YEA9104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ASYAKIRIN",
    "Student": 1516,
    "Teacher": 98
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YEA9105",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KEMENA",
    "Student": 1860,
    "Teacher": 94
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YEA9201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TATAU",
    "Student": 1700,
    "Teacher": 110
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YEAA201",
    "Name": "KOLEJ TINGKATAN ENAM SARATOK",
    "Student": 203,
    "Teacher": 43
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YEAB301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEMOP",
    "Student": 600,
    "Teacher": 40
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YEB1102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LAKE",
    "Student": 1476,
    "Teacher": 93
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEB1201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN GREEN ROAD",
    "Student": 1420,
    "Teacher": 120
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEB1202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR KUCHING NO.1",
    "Student": 1116,
    "Teacher": 76
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YEB1301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN ABDUL RAZAK",
    "Student": 1378,
    "Teacher": 108
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEB1303",
    "Name": "KOLEJ D PATINGGI ABANG HJ ABDILLAH",
    "Student": 732,
    "Teacher": 63
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YEB2301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LUBOK ANTU",
    "Student": 844,
    "Teacher": 66
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEB3201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LUAR BANDAR NO 1",
    "Student": 1076,
    "Teacher": 89
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KANOWIT",
    "Kod": "YEB3301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK HAJI ABDUL RAHMAN YAKUB",
    "Student": 751,
    "Teacher": 55
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YEB4101",
    "Name": "KOLEJ TUN DATU TUANKU HJ BUJANG",
    "Student": 852,
    "Teacher": 91
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YEB4301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MARUDI",
    "Student": 918,
    "Teacher": 80
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YEB4302",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LONG LAMA",
    "Student": 643,
    "Teacher": 56
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BARAM",
    "Kod": "YEB4303",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BARIO",
    "Student": 156,
    "Teacher": 15
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YEB4401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SUBIS",
    "Student": 1267,
    "Teacher": 64
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YEB5101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MEDAMIT",
    "Student": 676,
    "Teacher": 53
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YEB5202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TRUSAN",
    "Student": 678,
    "Teacher": 47
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YEB6101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BANDAR SARIKEI",
    "Student": 818,
    "Teacher": 86
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD JULAU",
    "Kod": "YEB6201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JULAU",
    "Student": 664,
    "Teacher": 58
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YEB8101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SG TAPANG",
    "Student": 1181,
    "Teacher": 89
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YEB8202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TEBAKANG",
    "Student": 1390,
    "Teacher": 90
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YEB8203",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TARAT",
    "Student": 1072,
    "Teacher": 84
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YEB8204",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BALAI RINGIN",
    "Student": 1460,
    "Teacher": 96
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YEB8205",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SERIAN",
    "Student": 1992,
    "Teacher": 157
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD TATAU/SEBAUH",
    "Kod": "YEB9102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SEBAUH",
    "Student": 900,
    "Teacher": 67
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BAU",
    "Kod": "YEE1101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAU",
    "Student": 1174,
    "Teacher": 140
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEE1201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENDING",
    "Student": 1049,
    "Teacher": 115
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEE1204",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BATU LINTANG",
    "Student": 1176,
    "Teacher": 111
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEE1205",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PADUNGAN",
    "Student": 482,
    "Teacher": 42
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YEE1301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUN ABANG HJ OPENG",
    "Student": 1268,
    "Teacher": 98
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SERIAN",
    "Kod": "YEE1302",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIBURAN",
    "Student": 2679,
    "Teacher": 145
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YEE1303",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PENRISSEN",
    "Student": 2710,
    "Teacher": 165
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUNDU",
    "Kod": "YEE1401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LUNDU",
    "Student": 1559,
    "Teacher": 121
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YEE2101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIMANGGANG",
    "Student": 952,
    "Teacher": 100
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YEE2102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MELUGU",
    "Student": 734,
    "Teacher": 67
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YEE2201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SARATOK",
    "Student": 853,
    "Teacher": 58
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YEE2202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KALAKA",
    "Student": 709,
    "Teacher": 52
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LUBOK ANTU",
    "Kod": "YEE2301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ENGKILILI",
    "Student": 1052,
    "Teacher": 87
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YEE2401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SARIBAS",
    "Student": 929,
    "Teacher": 81
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YEE2402",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DATUK PATINGGI KEDIT",
    "Student": 457,
    "Teacher": 51
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEE3101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ROSLI DHOBY",
    "Student": 688,
    "Teacher": 87
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEE3102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG NANGKA",
    "Student": 784,
    "Teacher": 84
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEE3201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DURIN",
    "Student": 584,
    "Teacher": 57
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YEE3202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN JALAN OYA",
    "Student": 882,
    "Teacher": 70
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YEE3401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN THREE RIVERS",
    "Student": 1251,
    "Teacher": 101
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YEE3501",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN DALAT",
    "Student": 899,
    "Teacher": 67
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YEE4101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LUTONG",
    "Student": 2286,
    "Teacher": 130
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YEE4301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BAKONG",
    "Student": 542,
    "Teacher": 38
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SUBIS",
    "Kod": "YEE4401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LUAR BANDAR MIRI",
    "Student": 1013,
    "Teacher": 71
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YEE5101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LIMBANG",
    "Student": 1223,
    "Teacher": 80
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LAWAS",
    "Kod": "YEE5201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN LAWAS",
    "Student": 935,
    "Teacher": 73
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YEE6101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SG PAOH",
    "Student": 812,
    "Teacher": 67
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YEE6301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MERADONG",
    "Student": 1135,
    "Teacher": 129
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YEE6401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MATU",
    "Student": 862,
    "Teacher": 55
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DARO",
    "Kod": "YEE6402",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TOH PUAN DATUK PATINGGI HJH NORMAH",
    "Student": 1087,
    "Teacher": 87
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YEE7101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAPIT",
    "Student": 1607,
    "Teacher": 122
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KAPIT",
    "Kod": "YEE7102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SELIRIK",
    "Student": 1436,
    "Teacher": 97
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BELAGA",
    "Kod": "YEE7201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BELAGA",
    "Student": 688,
    "Teacher": 59
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SONG",
    "Kod": "YEE7301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SONG",
    "Student": 564,
    "Teacher": 64
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YEE8101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN MUARA TUANG",
    "Student": 2757,
    "Teacher": 180
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIMUNJAN",
    "Kod": "YEE8304",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SIMUNJAN NO 1",
    "Student": 681,
    "Teacher": 67
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YEE9103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN BINTULU",
    "Student": 3362,
    "Teacher": 200
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SAMARAHAN",
    "Kod": "YFA8101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN PESANTREN ABDUL TAIB MAHMUD",
    "Student": 486,
    "Teacher": 47
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YFB1201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI KUCHING",
    "Student": 1620,
    "Teacher": 97
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YFB1202",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST JOSEPH",
    "Student": 980,
    "Teacher": 99
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YFB1203",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST TERESA",
    "Student": 594,
    "Teacher": 46
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YFB1204",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST THOMAS",
    "Student": 711,
    "Teacher": 82
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YFB1205",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST MARY",
    "Student": 595,
    "Teacher": 40
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SRI AMAN",
    "Kod": "YFB2101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST LUKE",
    "Student": 835,
    "Teacher": 62
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YFB2401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. AUGUSTINE",
    "Student": 1047,
    "Teacher": 81
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YFB3101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN METHODIST",
    "Student": 2006,
    "Teacher": 149
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YFB3102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN SACRED HEART",
    "Student": 1137,
    "Teacher": 113
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YFB3103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST ELIZABETH",
    "Student": 1047,
    "Teacher": 85
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YFB3104",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TIONG HIN",
    "Student": 654,
    "Teacher": 52
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YFB3105",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TUNG HUA",
    "Student": 1218,
    "Teacher": 102
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YFB3106",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG HUA",
    "Student": 1131,
    "Teacher": 98
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YFB3201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG CHENG",
    "Student": 162,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YFB3203",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KWONG HUA MIDDLE",
    "Student": 299,
    "Teacher": 33
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MUKAH",
    "Kod": "YFB3401",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. PATRICK",
    "Student": 802,
    "Teacher": 48
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YFB4101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN CHUNG HUA",
    "Student": 1727,
    "Teacher": 109
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YFB4102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. COLUMBA",
    "Student": 1019,
    "Teacher": 70
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YFB4103",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST. JOSEPH",
    "Student": 1039,
    "Teacher": 71
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YFB6101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TINGGI SARIKEI",
    "Student": 849,
    "Teacher": 69
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARIKEI",
    "Kod": "YFB6102",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN ST ANTHONY",
    "Student": 1469,
    "Teacher": 103
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YFB6301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN KAI CHUNG",
    "Student": 965,
    "Teacher": 68
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MARADONG",
    "Kod": "YFB6302",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN TONG HUA",
    "Student": 165,
    "Teacher": 25
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YHA1201",
    "Name": "KOLEJ VOKASIONAL KUCHING",
    "Student": 619,
    "Teacher": 114
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YHA1302",
    "Name": "KOLEJ VOKASIONAL MATANG",
    "Student": 987,
    "Teacher": 150
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BETONG",
    "Kod": "YHA2401",
    "Name": "KOLEJ VOKASIONAL BETONG",
    "Student": 696,
    "Teacher": 124
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YHA3101",
    "Name": "KOLEJ VOKASIONAL SIBU",
    "Student": 549,
    "Teacher": 94
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YHA4101",
    "Name": "KOLEJ VOKASIONAL MIRI",
    "Student": 570,
    "Teacher": 104
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YKA1202",
    "Name": "SMT SEJINGKAT",
    "Student": 416,
    "Teacher": 47
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD BINTULU",
    "Kod": "YKE9101",
    "Name": "KOLEJ VOKASIONAL BINTULU",
    "Student": 789,
    "Teacher": 134
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YRA1301",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SHEIKH HJ OTHMAN ABD WAHAB",
    "Student": 754,
    "Teacher": 99
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD PADAWAN",
    "Kod": "YRA1302",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA MATANG 2",
    "Student": 535,
    "Teacher": 41
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD KUCHING",
    "Kod": "YRA1303",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN AHMAD ZAIDI",
    "Student": 784,
    "Teacher": 69
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SARATOK",
    "Kod": "YRA2201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SARATOK",
    "Student": 418,
    "Teacher": 47
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD SIBU",
    "Kod": "YRA3101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA SIBU (S)",
    "Student": 630,
    "Teacher": 54
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD DALAT",
    "Kod": "YRA3201",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA IGAN",
    "Student": 524,
    "Teacher": 47
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD MIRI",
    "Kod": "YRA4101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA",
    "Student": 727,
    "Teacher": 65
  },
  {
    "NationalState": "SARAWAK",
    "PPD": "PPD LIMBANG",
    "Kod": "YRA5101",
    "Name": "SEKOLAH MENENGAH KEBANGSAAN AGAMA LIMBANG",
    "Student": 496,
    "Teacher": 44
  }
 ];
export default SchoolListWithDetails;