import React from 'react';
import { Redirect } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import 'firebase/database';
import {
    // osVersion, osName, browserName, fullBrowserVersion
    browserName
} from "react-device-detect";

import LoadingIndicator from './LoadingIndicator.js';
import Question from './Question.js';
import QuestionSPPA from './Question_SP_PopAnswers.js';
import QuestionSubjective from './Question_Subjective.js';  //2020.12.01

import { Locale, Lang } from '../../Localization/CustomLocalization.js';

// import './css/QuizLiveCSS.css';
import './css/StripedTableCSS.css';
import './css/SpecialStyleSCSS.scss';
import LocalizationSwitcher from '../../Localization/LocalizationSwitcher.js';
import { setPreciseInterval, clearPreciseInterval } from '../../components/preciseIntervals';

// import AppCtx, { SetContext } from '../../AppContext';

import { Delay, DelayUntil, ScrollToElement, CheckObjectNullValue, CheckNullValue, CheckStringEmpty, CheckObjectStringEmpty, CheckObjectBoolean, CheckObjectNumber, CapitalizeJsonKeys, CheckBoolean } from '../../components/GlobalFunctions';      //2023.09.11
import { GlobalSetting } from '../../components/GlobalSetting.js';

const ResultNode = {
    AppVer: 0,
    IsDone: 1,
    IsDoneOnUtc: 2,
    QResults: 3,
    ScoreRecord: 4,
    ASelects: 5,
    Root: 6,    //2022.06.14
    Questions: 7,   //2023.10.06
    SubmittedOnUTC: 8, //2023.10.09
}

let Interval_CountdownTimer = null;
let Interval_QuizStartEndTime = null;
let Interval_ResultProcessingCountdown = null;

const AtoZ = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
    'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
];

let unsubscribe_QuizRoomStateListener = null;
// let unsubscribe_BackButtonListener = null;   //2024.02.03

export default class QuizLive extends React.Component {

    constructor(props) {
        super(props);
        // this.SP_PA_ref = React.createRef();

        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        // _isMounted: false,

        redirect: false,
        redirectLink: '',
        // link: false,
        // linkUrl: "",

        // isDevMode: false,   //2021.04.06

        todayDT: '',
        roomCode: '',
        roomId: '',
        roomTitle: '',
        // roomDate: '',

        GroupList: [],  //2022.06.07

        isRoomInfoLoaded: false,
        roomInfoModal: {
            //revamped values.
            Date: '',
            DateEnd: '',
            DateStart: '',
            Duration: 0,
            EventCode: '',
            Organizer: '',
            OrganizerId: 0,     //2023.10.06
            QuestionSetUniqueId: '',
            RoomCode: 0,
            RoomId: '',
            RoomTitle: '',
            SubjectName: '',
            TimeEnd: '',
            TimeStart: '',

            //old values. keep first.
            Subject: '',
            Grade: '',
            QnQty: 0,
            QnSet: 0,
            DurationPerQuestion: 0,
            Questions: '',

            //2022.06.14
            AccessBeginTime: moment(),
            AccessEndTime: moment(),
            AccessDurationEndTime: moment(),

            //2023.10.06
            RandomQuestionMode: false,
            RestrictAccessToTimeRangeOnly: false,
            ForceRetrictedAccess: false,
        },
        isRandomQuestionMode: false,
        questionSetModal: null,

        singleQuestionDetailArray: [],
        isQuizQuestionsLoaded: false,
        // isQuizWaitingToStart: true,
        isQuizStarted: false,

        // liveQuizStatus: 'init',
        liveQuizStatus: 'started',      //2022.06.15 = obsolete = default to 'started'
        liveQuizEndTime: '',
        roomQuestions: [],

        //2023.10.06
        RoomResultRootData: {
            RoomId: '',
            IsDone: false,
            ASelects: '',
            QResults: '',
            ScoreRecord: '',
            Questions: '',
            IsDoneOnUtc: '',    //new.
            SubmittedOnUTC: '',     //new.
            ASelectList: [{
                No: 0,
                Index: 0,
                Value: 'A',
            }],
        },

        //2020.12.02
        liveQuizStartTime: '',
        isQuizStartTimeReached: false,
        isQuizEndTimeReached: false,    //2022.06.14
        isEventCertRecordExisted: false,    //2022.06.15

        //2021.04.09
        isCheckingIsDoneStatus: false,

        qsToggle: [],
        qsAnswers: [],
        qsHasAnswer: [],
        qsSelectedAnswers: [],
        qsNotActiveStat: [],

        nextQsToAnswer: 0,
        isAllAnswered: false,
        qsQtyToAnswer: 0,
        qsLeftToAnswer: [],
        isQuizDoneAnswered: false,

        //2023.11.09
        ASelects: '',
        SelectedAnswers: '',

        ctTimerActive: false,
        elapsedTime: 0.0,
        savedScore: 0,
        loadedElapsedTime: 0.0,
        lastElapsedTime: 0.0,

        toggleShowResult: false,
        toggleResultIcon: false,
        toggleResultProcessing: false,

        //2020.12.11
        ResultProcessingCountdown_Timer: 0,
        ResultProcessingCountdown_Active: false,

        extraContent: [],
        loadingInProgress: false,
        isQuizFullyLoaded: false,
        isQuizDone: false,
        isQuizDoneStateLoaded: false,
        // isQuizHasBeenStarted: false,             //2022.06.15 = obsolete
        isQuizCorrectAnswerRechecked: false,    //2021.09.02
        isScoreRecordNeedUpdate: false,         //2021.09.03

        SubmittedOnUTC: '',     //2023.10.09
        SaveOrSubmitInProgress: false,  //2023.10.18
        isQuizSubmitted: false,     //2023.10.20
        isDoneOnUtc: '',    //2023.10.20

        subjectLocale: Lang.English,

        userStartTime: '',

        //2021.03.19
        TagCategorizedResults: [],
        hasTags: false,

        //2021.04.05
        ParticipatedDate: '',

        //2022.11.09
        ParticipationOnEvent_SetDone: false,
        LoadingProgressionText: '',
    });

    componentWillUnmount() {
        // this.props.SetEventInfo('');
        // clearInterval(Interval_CountdownTimer);
        // clearInterval(Interval_QuizStartEndTime);
        // clearInterval(Interval_ResultProcessingCountdown);
        // this.ClearAllIntervals();   //2022.06.14

        //2022.06.15
        this.ClearAllIntervals();
        this.props.SetEventInfo('');
        this.props.SetTimeLeftText(null);
        this.setState(this.getInitState(), () => {
            //
        });

        //2023.10.18
        if (unsubscribe_QuizRoomStateListener !== null)
            unsubscribe_QuizRoomStateListener();

        //2024.02.03
        window.removeEventListener("popstate", this.BackButtonListener);
    }

    componentDidMount = async () => {

        this.props.SetScreen(this.props.Screen.QuizLive);   //2023.10.18

        // clearInterval(Interval_CountdownTimer);
        // clearInterval(Interval_QuizStartEndTime);
        // clearInterval(Interval_ResultProcessingCountdown);
        this.ClearAllIntervals();   //2022.06.14

        //2021.04.06
        // this.setState({
        //     isDevMode: window.location.href.includes('localhost'),
        // });

        window.scrollTo(0, 0);

        //2022.06.14
        this.setState({
            roomInfoModal: null,
        });
        //Check Quiz Start Time.
        Interval_QuizStartEndTime = setPreciseInterval(this.Interval_CheckOnQuizStartEndTime, 2000);
        //Check Result Processing time.
        Interval_ResultProcessingCountdown = setPreciseInterval(this.Interval_CheckOn_ResultProcessingCountdown, 1000);
        //Timer
        Interval_CountdownTimer = setPreciseInterval(this.Interval_CheckOn_CountdownTimer, 500);

        //#region  old interval timers.
        // //Timer
        // Interval_CountdownTimer = setInterval(() => {
        //     if (this.state.ctTimerActive) {
        //         this.setState({
        //             // elapsedTime: this.state.elapsedTime + 0.1,

        //             // elapsedTime: Number(((moment(new Date()) - moment(this.state.userStartTime)) / 1000).toFixed(3)) + Number(this.state.loadedElapsedTime.toFixed(3)),
        //             // elapsedTime: Number(((moment(new Date()) - moment(this.state.userStartTime)) / 1000)) + Number(this.state.loadedElapsedTime),
        //             // elapsedTime: Number((moment(new Date()) - moment(this.state.userStartTime)) / 1000),

        //             elapsedTime: Number((moment() - moment(this.state.userStartTime)) / 1000) + this.state.loadedElapsedTime,
        //         }, () => {
        //             this.CheckOnQuizTime();
        //         });
        //         // });
        //         // this.CheckOnQuizTime();
        //     }
        // }, 500);

        // //Check Quiz Start Time     //2020.12.02
        // Interval_QuizStartEndTime = setInterval(() => {
        //     if (!this.state.isQuizStarted) {
        //         this.CheckIfQuizStartTimeReach();
        //     }
        // }, 2000);

        // //Check Result Processing time.     //2020.12.11
        // Interval_ResultProcessingCountdown = setInterval(() => {
        //     if (this.state.ResultProcessingCountdown_Active) {
        //         if (this.state.ResultProcessingCountdown_Timer < 10) {
        //             this.setState({
        //                 ResultProcessingCountdown_Timer: this.state.ResultProcessingCountdown_Timer + 1,
        //             });
        //         }
        //         else {
        //             this.setState({
        //                 ResultProcessingCountdown_Active: false,
        //                 ResultProcessingCountdown_Timer: 0,
        //             }, async () => {

        //                 //Set IsDone.
        //                 await this.Firebase_SetIsDone_True();   //2022.06.14
        //                 // let retryAgain = false; //2021.09.13 - add retry.
        //                 // let async_action = async () => await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.IsDone))
        //                 //     .set(true)
        //                 //     .catch((error) => {
        //                 //         // await this.props.SetErrorLog(new Date(), "Interval_ResultProcessingCountdown | Set IsDone | Room " + this.state.roomCode + " | " + this.state.roomId + " : Error = " + error);
        //                 //         retryAgain = true;
        //                 //     });
        //                 // do {
        //                 //     retryAgain = false;
        //                 //     await async_action();
        //                 // } while (retryAgain)

        //                 //Set Re-enter trigger for this quiz room.
        //                 this.props.SetReEnterQuizRoom(true);

        //                 //Go back to QuizHome, & wait for trigger to re-enter this quiz room.
        //                 this.GotoHome();
        //             });
        //         }
        //     }
        // }, 1000);
        //#endregion

        //2023.12.14
        await this.GetRoomInfo_ViaAPI(this.props.RoomCode);
        // //2021.07.16
        // await this.GetRoomInfo(this.props.RoomCode);

        let todayDate = '';
        if (this.state.roomInfoModal !== null && this.state.roomInfoModal.hasOwnProperty('DateStart'))
            todayDate = moment(String(this.state.roomInfoModal.DateStart), 'YYYY-MM-DD').format('YYYYMMDD');
        else
            todayDate = moment().format('YYYYMMDD');

        this.setState({
            // _isMounted: true,

            // todayDT: this.props.viewHistoryQuiz !== null ? String(this.props.viewHistoryQuiz) : moment().format('YYYYMMDD'),
            //2021.07.14
            todayDT: String(this.props.RoomDate) !== '' ?
                moment(String(this.props.RoomDate)).format('YYYYMMDD')
                :
                this.props.viewHistoryQuiz !== null ? String(this.props.viewHistoryQuiz) : todayDate,

            roomCode: String(this.props.RoomCode),
            roomId: String(this.props.RoomId),
            loadingInProgress: true,

            // subjectLocale: this.props.Locale,
            // subjectLocale: this.GetLocale(),
        },
            () => {
                // alert(this.props.viewHistoryQuiz);
                if (this.props.isDevMode)
                    console.log('viewHistoryQuiz = ' + String(this.props.viewHistoryQuiz) + '\ntodayDT = ' + this.state.todayDT);
                if (this.props.user !== null) {
                    this.setState({ LoadingProgressionText: 'fetching room information...' });  //2022.11.16
                    setTimeout(async () => {
                        // await this.CheckOn_GroupList();
                        await this.CheckOn_GroupList_ViaAPI();  //2023.12.14

                        //2023.12.13 - moved here from later stage.
                        let isSuccess = false;
                        let counter = 0;
                        do {
                            isSuccess = await this.FetchRoomQuestions();
                            counter++;
                            await Delay(1000);
                        } while (isSuccess === false && counter < 5);
                        if (this.props.isDevMode) {
                            console.log('roomInfoModal', JSON.stringify(this.state.roomInfoModal));
                            console.log('questionSetModal', JSON.stringify(this.state.questionSetModal));
                        }

                        // await this.FetchRoomDetailFromFirebase();
                        await this.processRoomData();   //2023.12.14

                    }, 1000);
                }
                else {
                    // clearInterval(Interval_CountdownTimer);
                    // clearInterval(Interval_QuizStartEndTime);
                    // clearInterval(Interval_ResultProcessingCountdown);
                    this.ClearAllIntervals();   //2022.06.14

                    //Go back to login page.
                    this.GotoLogin();
                }
            }
        );

        // this.props.SetAlert("User", JSON.stringify(this.props.user));

        //2023.10.18
        await DelayUntil(() => this.state.isQuizFullyLoaded === true);
        setTimeout(() => {
            this.ListenToRoomState();
            window.addEventListener("popstate", this.BackButtonListener);
        }, 1000);
    }

    //2024.02.03
    BackButtonListener = (e) => {
        // console.log(e);
        if (CheckStringEmpty(e.currentTarget.location.href).includes('quiz-pos-q-'))
            return null;
        this.GotoHome();
    }

    //2023.10.18
    ListenToRoomState = () => {
        // console.log('ListenToRoomState', 'entered');
        if (this.state.roomInfoModal !== null) {
            unsubscribe_QuizRoomStateListener = this.props.firestore
                .collection("LiveQuiz_UniqueRoomCode")
                .doc(String(this.state.roomInfoModal.RoomId))
                .onSnapshot(async (doc) => {
                    // console.log('ListenToRoomState', JSON.stringify(doc.data()));
                    if (CheckObjectBoolean(doc.data(), 'ForceRetrictedAccess')) {

                        await DelayUntil(() => this.state.SaveOrSubmitInProgress === false);

                        this.props.SetAlertConfirm(Locale("quiz-room-access-restricted-label", this.props.Locale),
                            Locale("quiz-room-access-restricted-notice", this.props.Locale),
                            this.props.Feedback.Confirm, false, false, true, false, false);
                    }
                });
        }
    }

    //2023.12.14
    CheckOn_GroupList_ViaAPI = async () => {
        // if (this.state.roomInfoModal === null)
        //     return;
        // if (this.state.roomInfoModal.hasOwnProperty('AuthorId') === false)
        //     return;
        // if (Number(this.state.roomInfoModal.AuthorId) <= 0)
        //     return;
        if (this.state.GroupList.length > 0)
            return;

        let _List = [];
        let done = false;

        //fetch.
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Quiz/Group/List/'
            + 0 + '/'
            + 0 + '/'
            + 0 + '/'
            + 99999,
            // Api/LearningCentre/Quiz/Group/List/{organizerId}/{authorId}/{pageIndex}/{pageSize}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('CheckOn_GroupList_ViaApi', JSON.stringify(data));
                if (data.success)
                    _List = data.data;
                else
                    if (this.props.isDevMode)
                        console.log('Error', 'api - group list (failed)\n' + JSON.stringify(data));
                done = true;
            })
            .catch(error => {
                done = true;
                if (this.props.isDevMode)
                    console.log('Error', 'api - group list (failed)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        if (_List.length > 0) {
            //Finalize list.
            for (let i = 0; i < _List.length; i++) {
                _List[i] = CapitalizeJsonKeys(_List[i]);
            }
            //Sort.
            _List.sort((a, b) => a.Id - b.Id);
            _List.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
            //Save.
            this.setState({
                GroupList: _List,
            });
        }
    }

    //2022.06.07
    CheckOn_GroupList = async () => {
        if (this.state.roomInfoModal.hasOwnProperty('AuthorId') === false)
            return;
        if (Number(this.state.roomInfoModal.AuthorId) <= 0)
            return;
        if (this.state.GroupList.length > 0)
            return;

        let _List = [];
        //Default Groups.
        await this.props.firestore
            .collection("QuizBank")
            .doc('QuizGroup')
            .collection('QuizGroups')
            .where('AuthorId', '==', Number(1))
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                    });
                    if (dataArray.length > 0) {
                        dataArray.map((data, key) => {
                            return _List.push(data);
                        });
                    }
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });
        //Author created Groups.
        await this.props.firestore
            .collection("QuizBank")
            .doc('QuizGroup')
            .collection('QuizGroups')
            .where('AuthorId', '==', Number(this.state.roomInfoModal.AuthorId))
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                    });
                    if (dataArray.length > 0) {
                        dataArray.map((data, key) => {
                            return _List.push(data);
                        });
                    }
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(error.message);
            });
        //Sort.
        _List.sort((a, b) => a.Id - b.Id);
        _List.sort((a, b) => moment(a.DisplayOrder) - moment(b.DisplayOrder));
        //Save.
        this.setState({ GroupList: _List, });
    }

    //2023.12.14
    GetRoomInfo_ViaAPI = async (roomCode) => {

        // if (roomCode === null || roomCode === undefined || String(roomCode).trim().length === 0)
        if (CheckNullValue(roomCode) === null)
            return null;

        let isRoomFound = false;
        let _data = null;
        let done = false;

        //2023.12.07 - revamped - search via Api - main.
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Quiz/Room/Get/'
            + 0 + '/'
            + 0 + '/'
            + roomCode + '/0',
            // Api/LearningCentre/Quiz/Room/Get/{organizerId}/{authorId}/{roomCode}/{roomId}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('GetRoomInfo_ViaAPI (json)', JSON.stringify(data));
                const success = CheckObjectBoolean(data, 'success');
                if (success)
                    _data = CapitalizeJsonKeys(CheckObjectNullValue(data, 'data'));
                // else
                //     if (this.props.isDevMode)
                //         console.log('Error', 'api - room get (failed)\n' + JSON.stringify(data));
                isRoomFound = _data !== null;
                done = true;
            })
            .catch(error => {
                done = true;
                if (this.props.isDevMode)
                    console.log('Error', 'api - room get (failed)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        if (isRoomFound) {

            //2022.06.07
            if (_data.hasOwnProperty('Subject') === false && _data.hasOwnProperty('SubjectName') === true)
                _data['Subject'] = _data['SubjectName'];

            //2022.06.15
            let _timeStart = '';
            let _timeEnd = '';
            let _durationEndTime = '';
            if (_data.hasOwnProperty('DateStart') && _data.hasOwnProperty('TimeStart') && _data.hasOwnProperty('Duration')) {
                const timeStart = moment(String(_data.DateStart) + ' ' + String(_data.TimeStart), 'YYYY-MM-DD HH:mm');
                const timeAccessEnd = moment(String(_data.DateEnd) + ' ' + String(_data.TimeEnd), 'YYYY-MM-DD HH:mm');
                const durationEndTime = moment().add(Number(_data.Duration), 'seconds').add(-this.state.loadedElapsedTime, 'seconds');
                _data['AccessBeginTime'] = timeStart;
                _data['AccessEndTime'] = timeAccessEnd;
                _data['AccessDurationEndTime'] = durationEndTime;
                _timeStart = timeStart.format('YYYY-MM-DD HH:mm:ss');
                _timeEnd = timeAccessEnd.format('YYYY-MM-DD HH:mm:ss');
                _durationEndTime = durationEndTime.format('YYYY-MM-DD HH:mm:ss');
                if (this.props.isDevMode)
                    console.log('GetRoomInfo_ViaAPI = \n _timeStart = ' + _timeStart + '\n _durationEndTime = ' + _durationEndTime + '\n _timeEnd' + _timeEnd);
            }

            //2024.03.13 - default to false atm.
            _data['RandomQuestionMode'] = false;

            //2024.05.15
            const restrictAccessToTimeRangeOnly = CheckObjectBoolean(_data, 'RestrictAccessToTimeRangeOnly');

            this.setState({
                isRandomQuestionMode: false,    //CheckObjectBoolean(_data, 'RandomQuestionMode'),  //2023.10.06
                roomInfoModal: _data,
                roomTitle: _data.hasOwnProperty('RoomTitle') ? String(_data['RoomTitle']) : '',
                liveQuizStartTime: _timeStart,
                // liveQuizEndTime: _durationEndTime,
                liveQuizEndTime: restrictAccessToTimeRangeOnly ? _durationEndTime : _timeEnd,
            }, () => {
                if (this.props.isDevMode)
                    console.log('GetRoomInfo_ViaAPI = \n ' + JSON.stringify(this.state.roomInfoModal));
            });
        }
    }

    //#region //2024.02.03 - GetRoomInfo - obsolete FS api.
    // //2021.07.16
    // GetRoomInfo = async (roomCode) => {

    //     if (roomCode === null || roomCode === undefined || String(roomCode).trim().length === 0)
    //         return;

    //     let isRoomFound = false;
    //     let _data = null;

    //     //Search Room in new FS location.
    //     await this.props.firestore.collection("LiveQuiz_UniqueRoomCode")
    //         .where('RoomCode', '==', Number(roomCode))
    //         .get()
    //         .then(querySnapshot => {
    //             // isEmailNotExist = !querySnapshot.exists;
    //             let data = [];
    //             if (querySnapshot !== null) {
    //                 querySnapshot.forEach((doc) => {
    //                     data.push(doc.data());
    //                 });
    //                 if (data.length > 0)
    //                     data = data[0];
    //                 else
    //                     data = null;
    //             }
    //             // alert(this.state.quizRoomCode + "\n\n" + data.hasOwnProperty('RoomId') + "\n\n" + JSON.stringify(data));
    //             if (data !== null && data.hasOwnProperty('RoomId')) {
    //                 isRoomFound = true;
    //                 _data = data;
    //             }
    //         });
    //     // .catch(() => {
    //     //     // isRoomFound = false;
    //     //     alert('Room ' + this.state.quizRoomCode + ' - no data found');
    //     // });
    //     // console.log(JSON.stringify(_data));
    //     if (isRoomFound) {
    //         // if (_data.hasOwnProperty('DateStart')) {

    //         //2022.06.07
    //         if (_data.hasOwnProperty('Subject') === false && _data.hasOwnProperty('SubjectName') === true)
    //             _data['Subject'] = _data['SubjectName'];

    //         //2022.06.15
    //         let _timeStart = '';
    //         let _timeEnd = '';
    //         let _durationEndTime = '';
    //         if (_data.hasOwnProperty('DateStart') && _data.hasOwnProperty('TimeStart') && _data.hasOwnProperty('Duration')) {
    //             const timeStart = moment(String(_data.DateStart) + ' ' + String(_data.TimeStart), 'YYYY-MM-DD HH:mm');
    //             const timeAccessEnd = moment(String(_data.DateEnd) + ' ' + String(_data.TimeEnd), 'YYYY-MM-DD HH:mm');
    //             const durationEndTime = moment().add(Number(_data.Duration), 'seconds').add(-this.state.loadedElapsedTime, 'seconds');
    //             _data.AccessBeginTime = timeStart;
    //             _data.AccessEndTime = timeAccessEnd;
    //             _data.AccessDurationEndTime = durationEndTime;
    //             _timeStart = timeStart.format('YYYY-MM-DD HH:mm:ss');
    //             _timeEnd = timeAccessEnd.format('YYYY-MM-DD HH:mm:ss');
    //             _durationEndTime = durationEndTime.format('YYYY-MM-DD HH:mm:ss');
    //             if (this.props.isDevMode)
    //                 console.log('GetRoomInfo = \n _timeStart = ' + _timeStart + '\n _durationEndTime = ' + _durationEndTime + '\n _timeEnd' + _timeEnd);
    //         }

    //         // //2023.10.06
    //         // let _randomQuestionMode = false;
    //         // if (_data.hasOwnProperty('RandomQuestionMode') === false) {
    //         //     _data['RandomQuestionMode'] = false;
    //         // }
    //         // else {
    //         //     const get_randomQuestionMode = String(_data['RandomQuestionMode']);
    //         //     if (get_randomQuestionMode === '' || get_randomQuestionMode.toLowerCase() === 'false')
    //         //         _randomQuestionMode = false;
    //         //     else
    //         //         _randomQuestionMode = true;
    //         // }

    //         this.setState({
    //             isRandomQuestionMode: CheckObjectBoolean(_data, 'RandomQuestionMode'),  //2023.10.06
    //             roomInfoModal: _data,
    //             roomTitle: _data.hasOwnProperty('RoomTitle') ? String(_data.RoomTitle) : '',
    //             liveQuizStartTime: _timeStart,
    //             liveQuizEndTime: _durationEndTime,
    //         }, () => {
    //             if (this.props.isDevMode)
    //                 console.log('GetRoomInfo = \n ' + JSON.stringify(this.state.roomInfoModal));
    //         });
    //         // }
    //     }
    // }
    //#endregion

    //#region //2022.06.15 === obsolete === replaced by Interval_CheckOnQuizStartEndTime
    // //2020.12.02
    // CheckIfQuizStartTimeReach = () => {
    //     // if (this.state.liveQuizStartTime !== '_none') {
    //     // let _liveQuizStartTime = this.state.liveQuizStartTime !== '_none' ? this.state.liveQuizStartTime : '2020-01-01 00:00:00';
    //     // let _isTimeReached = moment() > moment(_liveQuizStartTime);

    //     //2021.07.16
    //     // console.log(
    //     //     'DateStart = ' + this.state.roomInfoModal.hasOwnProperty('DateStart') + '\nDateEnd = ' +
    //     //     this.state.roomInfoModal.hasOwnProperty('DateEnd')
    //     // );

    //     // //2021.07.16
    //     // let _liveQuizStartTime =
    //     //     this.state.roomInfoModal.hasOwnProperty('DateStart') ?
    //     //         moment(this.state.roomInfoModal.DateStart)
    //     //         :
    //     //         this.state.liveQuizStartTime !== '_none' ? moment(this.state.liveQuizStartTime) : moment('2020-01-01 00:00:00');
    //     // let _isTimeReached = moment() > _liveQuizStartTime;
    //     // // console.log('_liveQuizStartTime = ' + _liveQuizStartTime.format('YYYY-MM-DD HH:mm:ss') + '\n_isTimeReached = ' + _isTimeReached);


    //     // //2021.09.14 - to counter older firestore record format.
    //     // let roomInfo = this.state.roomInfoModal;
    //     // console.log(JSON.stringify(this.state.roomInfoModal));
    //     // if (roomInfo.hasOwnProperty('DateStart') && roomInfo.hasOwnProperty('TimeStart')) {
    //     //     let today = moment(roomInfo.Date).format('YYYY-MM-DD ');

    //     //     if (roomInfo.TimeStart.includes(today))
    //     //         roomInfo.TimeStart = roomInfo.TimeStart.replace(today, '');
    //     //     if (roomInfo.TimeEnd.includes(today))
    //     //         roomInfo.TimeEnd = roomInfo.TimeEnd.replace(today, '');

    //     //     if (roomInfo.DateStart === '')
    //     //         roomInfo.DateStart = today.trim();
    //     //     if (roomInfo.DateEnd === '')
    //     //         roomInfo.DateEnd = today.trim();
    //     // }


    //     //2021.07.19
    //     let _liveQuizStartTime =
    //         this.state.roomInfoModal.hasOwnProperty('DateStart') && this.state.roomInfoModal.hasOwnProperty('TimeStart') ?
    //             moment(this.state.roomInfoModal.DateStart + ' ' + this.state.roomInfoModal.TimeStart)
    //             :
    //             this.state.liveQuizStartTime !== '_none' ? moment(this.state.liveQuizStartTime) : moment('2020-01-01 00:00:00');
    //     let _isTimeReached = moment() > _liveQuizStartTime;
    //     if (this.props.isDevMode)
    //         console.log('_liveQuizStartTime = ' + _liveQuizStartTime.format('YYYY-MM-DD HH:mm:ss') + '\n_isTimeReached = ' + _isTimeReached);

    //     //2021.04.09
    //     // let _liveQuizEndTime = this.state.liveQuizEndTime !== '_none' ? this.state.liveQuizEndTime : moment(_liveQuizStartTime).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
    //     // let _isQuizTimeEnded = moment() > moment(_liveQuizEndTime);

    //     // //2021.07.16
    //     // let _liveQuizEndTime =
    //     //     this.state.roomInfoModal.hasOwnProperty('DateEnd') ?
    //     //         moment(this.state.roomInfoModal.DateEnd).add(1, 'day').add(-1, 'minute')
    //     //         :
    //     //         this.state.liveQuizEndTime !== '_none' ? this.state.liveQuizEndTime : moment(_liveQuizStartTime).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
    //     // let _isQuizTimeEnded = moment() > _liveQuizEndTime;
    //     // // console.log('_liveQuizEndTime = ' + _liveQuizEndTime.format('YYYY-MM-DD HH:mm:ss') + '\n_isQuizTimeEnded = ' + _isQuizTimeEnded);

    //     //2021.07.16
    //     let _liveQuizEndTime =
    //         this.state.roomInfoModal.hasOwnProperty('DateEnd') && this.state.roomInfoModal.hasOwnProperty('TimeEnd') ?
    //             moment(this.state.roomInfoModal.DateEnd + ' ' + this.state.roomInfoModal.TimeEnd)
    //             :
    //             this.state.liveQuizEndTime !== '_none' ? this.state.liveQuizEndTime : moment(_liveQuizStartTime).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
    //     let _isQuizTimeEnded = moment() > _liveQuizEndTime;

    //     if (_isTimeReached) {
    //         this.setState({
    //             isCheckingIsDoneStatus: true,
    //             isQuizStartTimeReached: true,
    //         }, async () => {

    //             //2021.04.09 - set IsDone state & start quiz
    //             // when Quiz time is reached & user not yet click start & quiz is already ended.

    //             //debug
    //             if (this.props.isDevMode)
    //                 console.log('Checking Status : \n'
    //                     + '   _isTimeReached = ' + _isTimeReached
    //                     + '\n   _isQuizTimeEnded = ' + _isQuizTimeEnded
    //                     + '\n   isQuizStarted = ' + this.state.isQuizStarted
    //                     + '\n   isQuizDone = ' + this.state.isQuizDone);

    //             if (_isQuizTimeEnded && !this.state.isQuizStarted && !this.state.isQuizDone) {

    //                 //2021.09.04
    //                 // clearInterval(Interval_CountdownTimer);
    //                 // clearInterval(Interval_QuizStartEndTime);
    //                 // clearInterval(Interval_ResultProcessingCountdown);
    //                 this.ClearAllIntervals();   //2022.06.14

    //                 //debug
    //                 if (this.props.isDevMode)
    //                     console.log('Setting IsDone state = true.');

    //                 //Set IsDone state.
    //                 await this.Firebase_SetIsDone_True();   //2022.06.14
    //                 // let retryAgain = false;
    //                 // let async_action = async () => await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.IsDone))
    //                 //     .set(true)
    //                 //     .catch((error) => {
    //                 //         // await this.props.SetErrorLog(new Date(), "CheckIfQuizStartTimeReach | Set IsDone | Error = " + error);
    //                 //         retryAgain = true;
    //                 //     });
    //                 // do {
    //                 //     retryAgain = false;
    //                 //     await async_action();
    //                 // } while (retryAgain);

    //                 //set state.
    //                 this.setState({ isCheckingIsDoneStatus: false });

    //                 //debug
    //                 if (this.props.isDevMode)
    //                     console.log('Starting Quiz.');

    //                 //Start Quiz.
    //                 this.handleStartQuiz();
    //             }
    //             else {
    //                 //set state.
    //                 this.setState({ isCheckingIsDoneStatus: false });
    //             }
    //         });
    //     }
    //     // }
    // }
    //#endregion

    //2022.06.14
    ClearAllIntervals = () => {
        // if (Interval_CountdownTimer !== null)
        //     clearInterval(Interval_CountdownTimer);
        // if (Interval_QuizStartEndTime !== null)
        //     clearInterval(Interval_QuizStartEndTime);
        // if (Interval_ResultProcessingCountdown !== null)
        //     clearInterval(Interval_ResultProcessingCountdown);

        if (Interval_CountdownTimer !== null)
            clearPreciseInterval(Interval_CountdownTimer);
        if (Interval_QuizStartEndTime !== null)
            clearPreciseInterval(Interval_QuizStartEndTime);
        if (Interval_ResultProcessingCountdown !== null)
            clearPreciseInterval(Interval_ResultProcessingCountdown);
    }

    //2022.06.14
    Interval_CheckOnQuizStartEndTime = () => {
        this.setState({
            isCheckingIsDoneStatus: true,
        });
        if (this.state.roomInfoModal !== null) {
            //Check Start Time if its reached.
            if (this.state.isQuizStartTimeReached === false) {
                if (this.state.roomInfoModal.hasOwnProperty('DateStart') && this.state.roomInfoModal.hasOwnProperty('TimeStart')) {
                    const timeOfChecking = this.state.roomInfoModal.AccessBeginTime;
                    if (String(timeOfChecking).includes('date') === false) {
                        if (moment() > timeOfChecking) {
                            this.setState({
                                isQuizStartTimeReached: true,
                            });
                        }
                    }
                }
            }
            //Check End Time if its reached.
            if (this.state.isQuizStartTimeReached === true) {
                if (this.state.roomInfoModal.hasOwnProperty('DateEnd') && this.state.roomInfoModal.hasOwnProperty('TimeEnd')) {
                    let timeOfChecking = this.state.roomInfoModal.AccessEndTime;
                    if (moment() < this.state.roomInfoModal.AccessEndTime) {
                        if (moment() > this.state.roomInfoModal.AccessDurationEndTime)
                            timeOfChecking = this.state.roomInfoModal.AccessEndTime;
                        else
                            timeOfChecking = this.state.roomInfoModal.AccessDurationEndTime;
                    }
                    if (String(timeOfChecking).includes('date') === false) {
                        if (moment() > timeOfChecking) {
                            this.setState({
                                isQuizEndTimeReached: true,
                                isCheckingIsDoneStatus: false,
                            });
                            this.Firebase_SetIsDone_True();
                            // this.Firebase_SetIsDoneOnUtc();  //2023.10.20
                            this.ClearAllIntervals();
                        }
                    }
                }
            }
        }
        this.setState({
            isCheckingIsDoneStatus: false,
        });
    }

    //2022.06.14
    Interval_CheckOn_ResultProcessingCountdown = async () => {
        if (this.state.ResultProcessingCountdown_Active) {
            if (this.state.ResultProcessingCountdown_Timer < 20) {
                this.setState({
                    ResultProcessingCountdown_Timer: this.state.ResultProcessingCountdown_Timer + 1,
                });
            }
            else {
                //Set IsDone.
                await this.Firebase_SetIsDone_True();
                // this.Firebase_SetIsDoneOnUtc();  //2023.10.20

                //Set Re-enter trigger for this quiz room.
                this.props.SetReEnterQuizRoom(true);

                //Go back to QuizHome, & wait for trigger to re-enter this quiz room.
                this.GotoHome();

                this.setState({
                    ResultProcessingCountdown_Active: false,
                    ResultProcessingCountdown_Timer: 0,
                });
            }
        }
    }

    //2022.06.14
    Interval_CheckOn_CountdownTimer = () => {

        if (this.state.roomInfoModal === null)
            return;

        if (this.state.ctTimerActive === false)
            return;

        // if (this.state.ctTimerActive) {
        // const timeStart = this.state.roomInfoModal.AccessBeginTime;
        // const timeEnd = this.state.roomInfoModal.AccessEndTime;
        const currentTime = moment();
        const userStartTime = moment(this.state.userStartTime, 'YYYY-MM-DD HH:mm:ss', true);
        const elapsedTime = Number((currentTime.valueOf() - userStartTime.valueOf()) / 1000) + Number(this.state.loadedElapsedTime);
        this.setState({
            // elapsedTime: Number((moment() - moment(this.state.userStartTime)) / 1000) + this.state.loadedElapsedTime,
            elapsedTime: elapsedTime,   //seconds.
        });
        let duration = 0;   //seconds.
        let timeLeft = 0;   //seconds.
        if (this.state.roomInfoModal.hasOwnProperty('Duration')) {
            if (Number(this.state.roomInfoModal.Duration) > 0) {
                duration = Number(this.state.roomInfoModal.Duration);
                timeLeft = duration - elapsedTime;
            }
        }
        if (duration === 0)
            return;
        const durationEndTime = this.state.roomInfoModal.AccessDurationEndTime;
        const accessEndTime = this.state.roomInfoModal.AccessEndTime;

        // if (this.state.isQuizDoneStateLoaded && this.state.isQuizHasBeenStarted) {
        if (this.state.isQuizDoneStateLoaded) {     //2022.06.15

            //Check expiration.
            if (
                elapsedTime > duration
                || currentTime >= durationEndTime
                || currentTime >= accessEndTime
            ) {
                //time limit is reached, quiz is forced ended.
                this.setState({
                    ctTimerActive: false,
                    toggleResultProcessing: true,
                    // elapsedTime: this.state.liveQuizStatus === 'ended' ? this.state.roomInfoModal.Duration : this.state.elapsedTime,

                    //2022.06.15
                    elapsedTime: Number(this.state.elapsedTime) > duration ? duration : this.state.elapsedTime,

                });
                // }, async () => {
                //     if (!this.state.isQuizDone && this.state.isQuizDoneStateLoaded)
                //         await this.handleSubmitPaper();
                // });

                //2022.06.15
                if (!this.state.isQuizDone && this.state.isQuizDoneStateLoaded)
                    this.handleSubmitPaper();
            }

            //Update Timer Ui.
            this.props.SetTimeLeftText(
                Locale('time-left', this.props.Locale) + '<p ' + (timeLeft < 16 ? 'class="blink"' : '') + '>'
                + this.ConvertTime(timeLeft)
                + '</p>'
            );
        }
        // }
    }

    //2022.06.14
    Firebase_SetIsDone_True = async () => {
        //Set IsDone state.
        let retryAgain = false;
        let done = false;
        let async_action = async () => {

            await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.IsDone))
                .set(true)
                .then(() => {
                    done = true;
                })
                .catch((error) => {
                    retryAgain = true;
                    done = true;
                });

            //2023.10.30
            await this.props.dbLiveQuizResult.ref(this.studentLiveQuizResultPath(ResultNode.IsDone))
                .set(true)
                .then(() => {
                    done = true;
                })
                .catch((error) => {
                    retryAgain = true;
                    done = true;
                });

            await DelayUntil(() => done === true);
        }
        do {
            retryAgain = false;
            done = false;
            await async_action();
            if (retryAgain) {
                await Delay(500);
            }
            else {
                this.setState({
                    isQuizDone: true,
                    isQuizDoneStateLoaded: true,
                });
            }
        } while (retryAgain);
        return true;
    }

    //2023.10.20
    Firebase_SetIsDoneOnUtc = async () => {
        //Set IsDone state.

        // //2023.10.20
        // let updateModel = {};
        // updateModel['IsDone'] = true;
        // updateModel['IsDoneOnUtc'] = moment.utc().format('YYYY-MM-DD HH:mm:ss');
        const IsDoneOnUtc = moment.utc().format('YYYY-MM-DD HH:mm:ss');

        let retryAgain = false;
        let done = false;
        let async_action = async () => {

            //2023.10.20
            await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.IsDoneOnUtc))
                .set(IsDoneOnUtc)
                .then(() => {
                    done = true;
                })
                .catch((error) => {
                    retryAgain = true;
                    done = true;
                });

            //2023.10.30
            await this.props.dbLiveQuizResult.ref(this.studentLiveQuizResultPath(ResultNode.IsDoneOnUtc))
                .set(IsDoneOnUtc)
                .then(() => {
                    done = true;
                })
                .catch((error) => {
                    retryAgain = true;
                    done = true;
                });

            await DelayUntil(() => done === true);
        }
        do {
            retryAgain = false;
            done = false;
            await async_action();
            if (retryAgain) {
                await Delay(500);
            }
            else {
                this.setState({
                    isDoneOnUtc: IsDoneOnUtc,
                });
            }
        } while (retryAgain);

        return true;
    }

    GetLocale = () => {
        let qsLocale = Lang.English;

        if (String(this.state.roomInfoModal.Subject) === 'Chinese')
            qsLocale = Lang.Chinese;
        else if (String(this.state.roomInfoModal.Subject) === 'Malay')
            qsLocale = Lang.Malay;
        // else
        //     if (this.state.subjectLocale !== Lang.English)
        //         qsLocale = this.state.subjectLocale;

        return qsLocale;
    }

    //2022.06.15 === obsolete ===
    // //=== Alert === starts.
    // SetAlert = (title, content) => {
    //     this.props.SetAlert(title, content);
    // }
    // //=== Alert === ends.


    //=== Database path === starts.
    getRoomDetailPath() {
        let path = this.props.dbLiveQuiz.ref('pkquiz/' + this.state.todayDT + '/pkquiz-room-detail/' + this.state.roomId);
        if (this.props.isDevMode)
            console.log('getRoomDetailPath =\n' + path);
        return path;
    }

    // getRoomQuestionPath() {
    //     let path = "LiveQuiz/"
    //         + this.state.roomInfoModal.Subject + "/"
    //         + this.state.roomInfoModal.Grade + "/"
    //         + this.state.roomInfoModal.QnSet;

    //     return this.props.dbQuestion.ref(path);
    //     // return this.props.dbQuestion.ref(path);
    // }

    // getRoomTitlePath1() {
    //     let path = "LiveQuiz/"
    //         + this.state.roomInfoModal.Subject + "/"
    //         + this.state.roomInfoModal.Grade + "/"
    //         + this.state.roomInfoModal.QnSet + "/RoomTitle";

    //     return this.props.dbQuestion.ref(path);
    // }

    getRoomTitlePath2() {
        let path = "pkquiz-questions/"
            + this.state.roomInfoModal.Subject + "/"
            + this.state.roomInfoModal.Grade + "/"
            + this.state.roomInfoModal.QnSet + "/RoomTitle";

        return this.props.dbLiveQuiz.ref(path);
    }

    getRoomQuestionPath_ExtraContent(node) {
        let path = "LiveQuiz/"
            + this.state.roomInfoModal.Subject + "/"
            + this.state.roomInfoModal.Grade + "/"
            + this.state.roomInfoModal.QnSet
            + "/ExtraContent/" + node;

        return this.props.dbQuestion.ref(path);
        // return this.props.dbQuestion.ref(path);
    }

    getRoomQuestionPath_Single(qsNum) {
        let path = "LiveQuiz/"
            + this.state.roomInfoModal.Subject + "/"
            + this.state.roomInfoModal.Grade + "/"
            + this.state.roomInfoModal.QnSet + "/" + qsNum;

        return this.props.dbQuestion.ref(path);
        // return this.props.dbQuestion.ref(path);
    }

    //2021.09.30
    getPath_QuestionSet_Questions(questionSetUniqueId) {
        let path = questionSetUniqueId + "/Questions";
        return this.props.dbQuizBank.ref(path);
    }

    //2021.09.30
    getPath_QuestionSet_Root(questionSetUniqueId) {
        let path = questionSetUniqueId;
        return this.props.dbQuizBank.ref(path);
    }

    // getRoomLivePath() {
    //     return this.props.dbLiveQuiz.ref('pkquiz/' + this.state.todayDT + '/pkquiz-live/' + this.state.roomId);
    // }

    // getRoomLiveStatusPath() {
    //     return this.props.dbLiveQuiz.ref('pkquiz/' + this.state.todayDT + '/pkquiz-live/' + this.state.roomId + "/QuizState");
    // }

    // getRoomLiveEndTimePath() {
    //     return this.props.dbLiveQuiz.ref('pkquiz/' + this.state.todayDT + '/pkquiz-live/' + this.state.roomId + "/QuizEndDT");
    // }

    // //2020.12.02
    // getRoomLiveStartTimePath() {
    //     return this.props.dbLiveQuiz.ref('pkquiz/' + this.state.todayDT + '/pkquiz-live/' + this.state.roomId + "/QuizStartDT");
    // }

    roomResultPath(node) {
        // let path = "";
        // if (node === typeof (ResultNode)) {
        let nodeName = "";
        switch (node) {
            case ResultNode.Root: nodeName = ''; break;     //2022.06.14
            case ResultNode.AppVer: nodeName = 'AppVer'; break;
            case ResultNode.IsDone: nodeName = 'IsDone'; break;
            case ResultNode.IsDoneOnUtc: nodeName = 'IsDoneOnUtc'; break;   //2023.10.20
            case ResultNode.QResults: nodeName = 'QResults'; break;
            case ResultNode.ScoreRecord: nodeName = 'ScoreRecord'; break;
            case ResultNode.ASelects: nodeName = 'ASelects'; break;
            case ResultNode.Questions: nodeName = 'Questions'; break;     //2023.10.06
            case ResultNode.SubmittedOnUTC: nodeName = 'SubmittedOnUTC'; break;     //2023.10.09
            default: nodeName = 'AppVer'; break;
        }

        return 'pkquiz/' + this.state.todayDT + '/pkquiz-personal-data/' + String(this.props.user.uid) + '/' + this.state.roomId
            + (CheckStringEmpty(nodeName) === '' ? '' : "/" + nodeName);
        // }
        // else {
        //     this.props.SetAlert("Path Error", "Invalid Node type.");
        // }
        // return path;
    }

    //2023.10.30
    studentLiveQuizResultPath(node) {
        let nodeName = "";
        switch (node) {
            case ResultNode.Root: nodeName = ''; break;     //2022.06.14
            case ResultNode.AppVer: nodeName = 'AppVer'; break;
            case ResultNode.IsDone: nodeName = 'IsDone'; break;
            case ResultNode.IsDoneOnUtc: nodeName = 'IsDoneOnUtc'; break;   //2023.10.20
            case ResultNode.QResults: nodeName = 'QResults'; break;
            case ResultNode.ScoreRecord: nodeName = 'ScoreRecord'; break;
            case ResultNode.ASelects: nodeName = 'ASelects'; break;
            case ResultNode.Questions: nodeName = 'Questions'; break;     //2023.10.06
            case ResultNode.SubmittedOnUTC: nodeName = 'SubmittedOnUTC'; break;     //2023.10.09
            default: nodeName = 'AppVer'; break;
        }
        //e.g. "Y2RfhtrgDxg1uKBNAxp3mifGQjC3/1695715195524" aka "{uid}/{room id}/{deeper path}"
        return this.props.user.uid + '/' + this.state.roomId + (CheckStringEmpty(nodeName) === '' ? '' : "/" + nodeName);
    }

    //2023.11.09
    liveQuizRoomResultPath(node) {
        let nodeName = "";
        switch (node) {
            case ResultNode.Root: nodeName = ''; break;
            case ResultNode.Uid: nodeName = 'Uid'; break;
            case ResultNode.ASelects: nodeName = 'ASelects'; break;
            case ResultNode.IsDoneOnUtc: nodeName = 'IsDoneOnUtc'; break;
            case ResultNode.SubmittedOnUTC: nodeName = 'SubmittedOnUTC'; break;
            case ResultNode.TimeElapsed: nodeName = 'TimeElapsed'; break;
            default: nodeName = 'AppVer'; break;
        }
        //e.g. "Y2RfhtrgDxg1uKBNAxp3mifGQjC3/1695715195524" aka "{room id}/{uid}/{deeper path}"
        return this.state.roomId + '/' + this.props.user.uid + (CheckStringEmpty(nodeName) === '' ? '' : "/" + nodeName);
    }

    roomRankingLivePath() {
        return 'pkquiz/' + this.state.todayDT + "/pkquiz-ranking-live/" + this.state.roomId + "/" + this.props.user.uid;
    }

    // postLogsPath(date) {
    //     let year = date.getFullYear();
    //     let month = date.getMonth();
    //     let day = date.getDate();
    //     return this.props.dbLogs.ref(this.props.user.uid + '/' + year + '/' + month + '/' + day + '/');
    // }

    //=== Database path === end.

    // GetConvertedDateTime(_dateTime) {
    //     let _time = _dateTime.split(" ")[0];
    //     let _date = _dateTime.split(" ")[1];

    //     let hr = _time.substring(0, 2);
    //     let min = _time.substring(2, 4);
    //     let sec = _time.substring(4, 6);
    //     let dateTime = moment(_date).format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;

    //     return moment(dateTime).format("ll");
    // }

    // GetConvertedTime(_data, _date) {
    //     let hr = _data.substring(0, 2);
    //     let min = _data.substring(2, 4);
    //     let sec = _data.substring(4, 6);
    //     let dateTime = _date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
    //     return moment(dateTime).add(8, 'hours').format('x');
    // }

    FormatedDuration = (_totalSeconds) => {
        // let min = Locale("time-min", this.props.Locale);
        // let sec = Locale("time-sec", this.props.Locale);

        // let totalSeconds = Number(_totalSeconds);

        // let minutes = (totalSeconds / 60).toFixed(3).split('.')[0];
        // let seconds = (totalSeconds % 60).toFixed(1);
        // return minutes + ' ' + min + ' ' + seconds + ' ' + sec;

        if (_totalSeconds === null || _totalSeconds === undefined || Number(_totalSeconds) <= 0)
            return '-';

        //2022.06.07
        let hr = Locale("time-hour-full", this.props.Locale);
        let hrs = Locale("time-hour-full-s", this.props.Locale);
        let min = Locale("time-min", this.props.Locale);
        let sec = Locale("time-sec", this.props.Locale);

        let totalSeconds = Number(_totalSeconds);

        let hours = (totalSeconds / 3600).toFixed(3).split('.')[0];
        let minutes = ((totalSeconds - (hours * 3600)) / 60).toFixed(3).split('.')[0];
        let seconds = (totalSeconds % 60).toFixed(3).split('.')[0];
        return (hours > 0 ? hours + ' ' + (hours > 1 ? hrs : hr) + ' ' : '') + minutes + ' ' + min + ' ' + seconds + ' ' + sec;
    }

    //=== Load Room Detail === starts.

    //#region //obsolete codes.
    // //2023.12.14 - obsolete, now all fetch from api.
    // FetchRoomDetailFromFirebase = async () => {
    //     // await this.getRoomDetailPath().once('value', snapshot => {
    //     //     this.processRoomDetailData(snapshot.val());
    //     // });
    //     let isRetryNeeded = false;

    //     for (let i = 0; i < 20; i++) {
    //         if (!this.state.isQuizFullyLoaded) {
    //             let result = await this.getRoomDetailPath()
    //                 .once('value')
    //                 .then((snapshot) => {
    //                     let isFetchSuccess = false;
    //                     if (snapshot.exists()) {
    //                         isFetchSuccess = true;
    //                         // console.log(JSON.stringify(snapshot.val()));
    //                         this.processRoomDetailData(snapshot.val());
    //                     }
    //                     return isFetchSuccess;
    //                 });
    //             isRetryNeeded = !result;
    //             if (result)
    //                 break;
    //             else
    //                 await Delay(3000);
    //         }
    //         else {
    //             break;
    //         }
    //     }

    //     //2020.12.01    //check if need to retry again after 1 minute.
    //     if (isRetryNeeded) {
    //         this.Retry_FetchRoomDetailFromFirebase();
    //     }
    // }

    // //2023.12.14 - obsolete, now all fetch from api.
    // //2020.12.01
    // Retry_FetchRoomDetailFromFirebase = async () => {
    //     if (String(this.props.isInternetReachable) !== 'null') {
    //         await this.FetchRoomDetailFromFirebase();
    //     }
    // }

    // //2023.12.14 - obsolete, now all fetch from api.
    // processRoomDetailData = async (dataSnapVal) => {

    //     if (this.props.isDevMode)
    //         console.log('dataSnapVal =\n' + JSON.stringify(dataSnapVal));

    //     let roomInfoModal = this.state.roomInfoModal;
    //     // console.log('1 == ' + JSON.stringify(this.state.roomInfoModal));

    //     let dataKeys = _(dataSnapVal).keys().value();       //key
    //     let dataValues = _(dataSnapVal).values().value();   //values

    //     // console.log('dataSnapVal (start)');
    //     dataKeys.map((data, key) => {
    //         // this.state.roomInfoModal[data] = _(dataValues[key]).value();
    //         // return roomInfoModal[data] = _(dataValues[key]).value();

    //         // //2021.09.30
    //         // if (roomInfoModal[data] === null || roomInfoModal[data] === undefined || roomInfoModal[data] === '' || roomInfoModal[data] === 0)
    //         //     roomInfoModal[data] = _(dataValues[key]).value();

    //         //2023.10.06
    //         if (CheckObjectNullValue(roomInfoModal, data) === null)
    //             roomInfoModal[data] = _(dataValues[key]).value();

    //         //2022.06.07
    //         if (String(data) === 'QnQty')
    //             if (roomInfoModal.hasOwnProperty('QnQty') === false)
    //                 roomInfoModal.QnQty = Number(dataValues[key]);
    //         let ReplaceGradeName = false;
    //         if (String(data) === 'Grade') {
    //             if (roomInfoModal.hasOwnProperty('Grade') === false)
    //                 ReplaceGradeName = true;
    //             else if (String(roomInfoModal['Grade']) === '')
    //                 ReplaceGradeName = true;
    //             else if (typeof roomInfoModal['Grade'] === 'number')
    //                 ReplaceGradeName = true;

    //             if (ReplaceGradeName && roomInfoModal.hasOwnProperty('GroupId')) {
    //                 let index = this.state.GroupList.findIndex(x => Number(x.Id) === Number(roomInfoModal['GroupId']));
    //                 if (index > -1) {
    //                     roomInfoModal.Grade = String(this.state.GroupList[index].Name);
    //                 }
    //             }
    //         }
    //         // console.log(String(key) + ' = ' + String(data));

    //         return null;
    //     });
    //     // console.log('dataSnapVal (end)');

    //     this.setState({
    //         roomInfoModal: roomInfoModal,
    //     }, async () => {
    //         await this.processRoomData();
    //     });
    // }
    //#endregion

    //2023.12.14
    processRoomData = async () => {

        this.setState({ LoadingProgressionText: 'processing room information...' });  //2022.11.16

        let roomInfoModal = this.state.roomInfoModal;

        //#region //obsolete codes.
        // // console.log('1 == ' + JSON.stringify(this.state.roomInfoModal));

        // let dataKeys = _(dataSnapVal).keys().value();       //key
        // let dataValues = _(dataSnapVal).values().value();   //values

        // // console.log('dataSnapVal (start)');
        // dataKeys.map((data, key) => {
        //     // this.state.roomInfoModal[data] = _(dataValues[key]).value();
        //     // return roomInfoModal[data] = _(dataValues[key]).value();

        //     // //2021.09.30
        //     // if (roomInfoModal[data] === null || roomInfoModal[data] === undefined || roomInfoModal[data] === '' || roomInfoModal[data] === 0)
        //     //     roomInfoModal[data] = _(dataValues[key]).value();

        //     //2023.10.06
        //     if (CheckObjectNullValue(roomInfoModal, data) === null)
        //         roomInfoModal[data] = _(dataValues[key]).value();

        //     //2022.06.07
        //     if (String(data) === 'QnQty')
        //         if (roomInfoModal.hasOwnProperty('QnQty') === false)
        //             roomInfoModal.QnQty = Number(dataValues[key]);
        //     let ReplaceGradeName = false;
        //     if (String(data) === 'Grade') {
        //         if (roomInfoModal.hasOwnProperty('Grade') === false)
        //             ReplaceGradeName = true;
        //         else if (String(roomInfoModal['Grade']) === '')
        //             ReplaceGradeName = true;
        //         else if (typeof roomInfoModal['Grade'] === 'number')
        //             ReplaceGradeName = true;

        //         if (ReplaceGradeName && roomInfoModal.hasOwnProperty('GroupId')) {
        //             let index = this.state.GroupList.findIndex(x => Number(x.Id) === Number(roomInfoModal['GroupId']));
        //             if (index > -1) {
        //                 roomInfoModal.Grade = String(this.state.GroupList[index].Name);
        //             }
        //         }
        //     }
        //     // console.log(String(key) + ' = ' + String(data));

        //     return null;
        // });
        // // console.log('dataSnapVal (end)');
        //#endregion

        //2023.12.13
        if (this.state.questionSetModal !== null) {
            if (CheckNullValue(roomInfoModal, 'QnQty') !== null && CheckNullValue(this.state.questionSetModal, 'TotalQuestion') !== null)
                if (roomInfoModal.QnQty !== this.state.questionSetModal.TotalQuestion)
                    roomInfoModal.QnQty = this.state.questionSetModal.TotalQuestion;
        }

        // console.log('2 == ' + JSON.stringify(this.state.roomInfoModal));
        if (this.props.isDevMode) {
            // console.log('dataSnapVal =\n' + JSON.stringify(dataSnapVal));
            console.log('roomInfoModal =\n' + JSON.stringify(roomInfoModal));
            console.log('questionSetModal =\n' + JSON.stringify(this.state.questionSetModal));
        }

        //Set default value.
        let qsToggle = this.state.qsToggle;
        let qsAnswers = this.state.qsAnswers;
        let qsHasAnswer = this.state.qsHasAnswer;
        let qsSelectedAnswers = this.state.qsSelectedAnswers;
        let qsNotActiveStat = this.state.qsNotActiveStat;

        [...Array(roomInfoModal.QnQty)].map(() => {
            // this.state.qsToggle.push(false);
            // this.state.qsAnswers.push(false);
            // this.state.qsHasAnswer.push(false);
            // this.state.qsSelectedAnswers.push("-1");
            // this.state.qsNotActiveStat.push(false);
            qsToggle.push(false);
            qsAnswers.push(false);
            qsHasAnswer.push(false);
            qsSelectedAnswers.push("-1");
            qsNotActiveStat.push(false);
            return null;
        });

        // //2022.06.14
        // roomInfoModal.AccessBeginTime = moment(String(roomInfoModal.DateStart) + ' ' + String(roomInfoModal.TimeStart), 'YYYY-MM-DD HH:mm', true);
        // roomInfoModal.AccessEndTime = moment(String(roomInfoModal.DateEnd) + ' ' + String(roomInfoModal.TimeEnd), 'YYYY-MM-DD HH:mm', true);
        // // const timeEnd = moment(String(roomInfoModal.DateStart) + ' ' + String(roomInfoModal.TimeEnd), 'YYYY-MM-DD HH:mm', true);
        // // roomInfoModal.AccessTimeLimit = roomInfoModal.AccessBeginTime.diff(timeEnd, 'seconds', true);

        // if (this.props.isDevMode)
        //     console.log('roomInfoModal = \n' + JSON.stringify(roomInfoModal));

        //Save states.
        this.setState({
            // qsToggle: this.state.qsToggle,
            // qsAnswers: this.state.qsAnswers,
            // qsHasAnswer: this.state.qsHasAnswer,
            // qsSelectedAnswers: this.state.qsSelectedAnswers,
            // qsQtyToAnswer: this.state.roomInfoModal.QnQty,
            // qsNotActiveStat: this.state.qsNotActiveStat,
            qsLeftToAnswer: [],     //added 2020.11.04
            qsToggle: qsToggle,
            qsAnswers: qsAnswers,
            qsHasAnswer: qsHasAnswer,
            qsSelectedAnswers: qsSelectedAnswers,
            qsQtyToAnswer: roomInfoModal.QnQty,
            qsNotActiveStat: qsNotActiveStat,

            // roomTitle: '',
            // roomTitle: roomInfoModal.hasOwnProperty('RoomTitle') ?
            //     roomInfoModal.RoomTitle !== undefined && roomInfoModal.RoomTitle !== '' ?       //2021.09.30
            //         roomInfoModal.RoomTitle
            //         : ''
            //     : '',
            roomTitle: CheckObjectStringEmpty(roomInfoModal, 'RoomTitle'),

            // roomInfoModal: this.state.roomInfoModal,
            roomInfoModal: roomInfoModal,
            isRoomInfoLoaded: true,

            // roomQuestions: [],
            // isQuizQuestionsLoaded: false,
            isQuizStarted: false,
            nextQsToAnswer: 0,

            subjectLocale: this.GetLocale(),
        });
        // },
        // async () => {

        //2020.12.01    //massive modification = simpify code view & add retry logic.
        // for (let i = 0; i < 5; i++) {

        this.setState({ LoadingProgressionText: 'fetching questions...' });  //2022.11.16
        await Delay(1000);

        await this.PopulatingRoomData();

        // await Delay(1000);     //wait estimates for PopulatingRoomData to finished saving states.

        await DelayUntil(() => this.state.isQuizFullyLoaded === true);     //2022.06.07

        // if (this.state.isQuizFullyLoaded === true)
        //     break;
        // }
        // });
    }

    //2020.12.01
    PopulatingRoomData = async () => {

        let useLegacyPattern = false;       //false = ignore old, direct run new pattern.

        if (useLegacyPattern) {

            this.props.SetAlert("Error", "You shouldn't be seeing this message.<br />Please contact Customer Service.");
            return null;

            // await this.FetchQuizRoomTitleFromFirebase();
            // await this.FetchRoomQuestions();

            // // this.props.SetAlert("", this.state.loadedElapsedTime);
            // await this.CheckIfQuizHasBeenDoneBefore();

            // await this.CheckAndLoadSavedQsResults();
            // await this.CheckAndLoadSavedQsSelectsAndSetQsToggle();
            // await this.CheckAndLoadSavedQsScoreRecord();

            // // this.props.SetAlert("", this.state.loadedElapsedTime);
            // await this.CheckIfQuizHasFullyLoaded();

            // // await this.CheckIfQuizHasBeenDonePreviously();
        }
        else {
            //edit on 2020.11.10 - improved. Retry feature.
            let isSuccess = true;
            let counter = 0;
            // console.log('isQuizCorrectAnswerRechecked = ' + this.state.isQuizCorrectAnswerRechecked);

            //2022.06.15 === obsolete ===
            // if (isSuccess) {
            //     if (this.props.isDevMode)
            //         console.log('roomTitle = ' + this.state.roomTitle);
            //     if (this.state.roomTitle === null || this.state.roomTitle === undefined || this.state.roomTitle === '') {
            //         for (i = 0; i < 10; i++) {
            //             isSuccess = await this.FetchQuizRoomTitleFromFirebase();
            //             if (isSuccess)
            //                 break;
            //             else
            //                 await Delay(500);
            //         }
            //     }
            //     else {
            //         //2021.10.14
            //         await this.props.SaveRoomInLocalHistoryList_V2({
            //             Title: this.state.roomTitle,
            //             RoomCode: this.state.roomCode,
            //             RoomId: this.state.roomId,
            //             Date: moment().format("YYYY-MM-DD HH:mm:ss"),
            //             Score: 0,
            //             RoomType: 0,
            //             SubmittedOnUTC: CheckStringEmpty(this.state.SubmittedOnUTC),  //2023.10.20
            //             IsDoneOnUtc: CheckStringEmpty(this.state.isDoneOnUtc),    //2023.10.20
            //         });
            //     }
            //     // this.props.SetAlert("FetchQuizRoomTitleFromFirebase", isSuccess + "\n" + i);
            // }

            //2023.12.13 - moved to earlier stage.
            // if (isSuccess) {
            //     isSuccess = false;
            //     counter = 0;
            //     do {
            //         isSuccess = await this.FetchRoomQuestions();
            //         counter++;
            //         await Delay(1000);
            //     } while (isSuccess === false && counter < 5);
            //     // this.props.SetAlert("FetchRoomQuestions", isSuccess);
            // }

            //2023.10.06 - revamped & simpified fetch processes.
            this.setState({ LoadingProgressionText: 'fetching previously saved data...' });
            if (isSuccess) {
                isSuccess = false;
                counter = 0;
                do {
                    isSuccess = await this.GetAndPopulate_RoomResultRootData();
                    counter++;
                    // console.log(counter);
                    await Delay(1000);
                } while (isSuccess === false && counter < 5);
                // this.props.SetAlert("GetAndPopulate_RoomResultRootData", isSuccess);
            }
            //#region old codes - before 2023.10.06
            // if (isSuccess) {
            //     isSuccess = false;
            //     for (i = 0; i < 10; i++) {
            //         isSuccess = await this.CheckIfQuizHasBeenDoneBefore();
            //         if (isSuccess)
            //             break;
            //         else
            //             await Delay(500);
            //     }
            //     // this.props.SetAlert("CheckIfQuizHasBeenDoneBefore", isSuccess + "\n" + i);
            // }

            // this.setState({ LoadingProgressionText: 'fetching previously saved data...' });  //2022.11.16
            // if (isSuccess) {
            //     isSuccess = false;
            //     for (i = 0; i < 10; i++) {
            //         isSuccess = await this.CheckAndLoadSavedQsResults();
            //         if (isSuccess)
            //             break;
            //         else
            //             await Delay(500);
            //     }
            //     // this.props.SetAlert("CheckAndLoadSavedQsResults", isSuccess + "\n" + i);
            //     isSuccess = true;   //no record will return false, so force this as true.
            // }

            // this.setState({ LoadingProgressionText: 'populating questions...' });  //2022.11.16
            // if (isSuccess) {
            //     isSuccess = false;
            //     for (i = 0; i < 10; i++) {
            //         isSuccess = await this.CheckAndLoadSavedQsSelectsAndSetQsToggle();
            //         if (isSuccess)
            //             break;
            //         else
            //             await Delay(500);
            //     }
            //     // this.props.SetAlert("CheckAndLoadSavedQsSelectsAndSetQsToggle", isSuccess + "\n" + i);
            // }

            // if (isSuccess) {
            //     isSuccess = false;
            //     for (i = 0; i < 10; i++) {
            //         isSuccess = await this.CheckAndLoadSavedQsScoreRecord();
            //         if (isSuccess)
            //             break;
            //         else
            //             await Delay(500);
            //     }
            //     // this.props.SetAlert("CheckAndLoadSavedQsScoreRecord", isSuccess + "\n" + i);
            // }

            //#endregion

            if (isSuccess) {
                // await DelayUntil(() => this.state.isQuizCorrectAnswerRechecked === true);
                // console.log('isQuizCorrectAnswerRechecked = ' + this.state.isQuizCorrectAnswerRechecked);
                await this.CheckIfQuizHasFullyLoaded();

                // // await Delay(1500);
                // setTimeout(async () => {
                //     await this.CheckIfQuizHasFullyLoaded();
                // }, 1000);

                // this.props.CloseAlert();
            }
        }

        //2023.11.17
        this.props.CloseAlert();

        if (this.props.isDevMode)
            console.log('roomInfoModal =\n' + JSON.stringify(this.state.roomInfoModal));
    }

    //=== Load Room Detail === ends.

    //=== Logs === starts.

    SetEnterRoomLog = async (quizInProgress) => {
        //Log - Enter Quiz Room - "Room 22329 | Entered | New Quiz | 1598413137876"
        let quizStat = "";
        if (this.state.isQuizDoneAnswered)
            quizStat = "Viewing previously completed Quiz";
        else
            if (quizInProgress)
                quizStat = "Continue on Quiz";
            else
                quizStat = "New Quiz";

        // await this.SetLog(new Date(), "Room " + this.state.roomCode + " | Entered | " + quizStat + " | " + this.state.roomId);
        const msg = "Room " + this.state.roomCode + " | Entered | " + quizStat + " | " + this.state.roomId;
        await this.props.SetLog(msg);
        await this.props.SetLiveQuizLog(msg, this.state.roomId);    //2023.10.30
    }

    SetQuizStartedLog = async () => {
        //Room 69482 | Quiz Started | 1595636299754
        // await this.SetLog(new Date(), "Room " + this.state.roomCode + " | Quiz Started | " + this.state.roomId);
        const msg = "Room " + this.state.roomCode + " | Quiz Started | " + this.state.roomId;
        await this.props.SetLog(msg);
        await this.props.SetLiveQuizLog(msg, this.state.roomId);    //2023.10.30
    }

    SetExitRoomLog = async () => {
        //Room 69482 | Exit | 1595636299754
        // await this.SetLog(new Date(), "Room " + this.state.roomCode + " | Exit | " + this.state.roomId);
        const msg = "Room " + this.state.roomCode + " | Exit | " + this.state.roomId;
        await this.props.SetLog(msg);
        await this.props.SetLiveQuizLog(msg, this.state.roomId);    //2023.10.30
    }

    SetQuizCompletedLog = async (timeOnUtc = '') => {
        //Room 69482 | Quiz Completed | 18750 | 258.4 | | 1595636299754
        // // await this.SetLog("Room " + this.state.roomCode + " | Quiz Completed | " + this.GetScore() + " | " + this.state.elapsedTime.toFixed(1) + " | " + this.state.roomId);
        // await this.props.SetLog("Room " + this.state.roomCode + " | Quiz Completed | " + this.GetScore() + " | " + this.state.elapsedTime.toFixed(1) + " | " + this.state.roomId);
        // await this.props.SetLog("Room " + this.state.roomCode + " | Quiz ASelects = " + this.GetSelectedAnswer() + " | " + this.state.roomId);

        //2023.11.09
        let _SelectedAnswers = this.state.SelectedAnswers;
        let _ASelects = this.state.ASelects;
        if (CheckNullValue(_SelectedAnswers) === null || CheckNullValue(_ASelects) === null) {
            const { ASelects, SelectedAnswers } = this.GetSelectedAnswer();
            _SelectedAnswers = SelectedAnswers;
            _ASelects = ASelects;
        }

        //2022.06.15
        // const [SelectedAnswers, ASelects] = this.GetSelectedAnswer();
        const { QScore, QResults, QCorrect, QTotal } = this.Get_Score_CorrectQty_QResult();
        // await this.props.SetLog("Room " + this.state.roomCode + " | Quiz Completed | " + QScore + " | " + this.state.elapsedTime.toFixed(1) + " | " + this.state.roomId);
        // await this.props.SetLog("Room " + this.state.roomCode + " | Quiz ASelects = " + SelectedAnswers + " | " + this.state.roomId);
        // await this.props.SetLog("Room " + this.state.roomCode + " | Quiz ASelects (Raw) = " + ASelects + " | " + this.state.roomId);
        // await this.props.SetLog("Room " + this.state.roomCode + " | Quiz QResults = " + QResults + " | Correct (" + QCorrect + "/" + QTotal + ") | " + this.state.roomId);

        // //2023.07.04
        // let p1 = new Promise((resolve, reject) => this.props.SetLog("Room " + this.state.roomCode + " | Quiz Completed | " + QScore + " | " + this.state.elapsedTime.toFixed(1) + " | " + this.state.roomId));
        // let p2 = new Promise((resolve, reject) => this.props.SetLog("Room " + this.state.roomCode + " | Quiz ASelects = " + SelectedAnswers + " | " + this.state.roomId));
        // let p3 = new Promise((resolve, reject) => this.props.SetLog("Room " + this.state.roomCode + " | Quiz ASelects (Raw) = " + ASelects + " | " + this.state.roomId));
        // let p4 = new Promise((resolve, reject) => this.props.SetLog("Room " + this.state.roomCode + " | Quiz QResults = " + QResults + " | Correct (" + QCorrect + "/" + QTotal + ") | " + this.state.roomId));
        // await Promise.all([p1, p2, p3, p4]);


        //2023.07.05 ==== start ===//
        // let datePath = moment.utc().format('YYYY/M/D/HHmmss');
        let utcNow = moment.utc();

        // //2023.10.09
        // if (CheckStringEmpty(ticks) !== '') {
        //     utcNow = moment(Number(ticks) * 10000);
        // }

        //2023.10.20
        let _submittedOnUTC = '';
        if (CheckStringEmpty(timeOnUtc) === '')
            _submittedOnUTC = utcNow.valueOf() + " | " + utcNow.toString();
        else
            _submittedOnUTC = moment(timeOnUtc).valueOf() + " | " + timeOnUtc;

        //2023.10.30
        let logTime1 = [];
        let logTime2 = [];
        logTime1.push(utcNow.format('HHmmss'));
        logTime2.push(utcNow.format('YYYYMMDDHHmmss'));
        for (let tt = 0; tt < 5; tt++) {
            const time = utcNow.add(tt + 1, 'second');
            logTime1.push(time.format('HHmmss'));
            logTime2.push(time.format('YYYYMMDDHHmmss'));
        }

        let updates = {};

        // updates[`${utcNow.format('HHmmss')}`] = "Room " + this.state.roomCode + " | Quiz Completed | " + QScore + " | " + this.state.elapsedTime.toFixed(1) + " | " + this.state.roomId + ' | ' + utcNow.toString();
        // updates[`${utcNow.add(1, 'second').format('HHmmss')}`] = "Room " + this.state.roomCode + " | Quiz ASelects = " + SelectedAnswers + " | " + this.state.roomId;
        // updates[`${utcNow.add(2, 'second').format('HHmmss')}`] = "Room " + this.state.roomCode + " | Quiz ASelects (Raw) = " + ASelects + " | " + this.state.roomId;
        // updates[`${utcNow.add(3, 'second').format('HHmmss')}`] = "Room " + this.state.roomCode + " | Quiz QResults = " + QResults + " | Correct (" + QCorrect + "/" + QTotal + ") | " + this.state.roomId;
        // updates[`${utcNow.add(4, 'second').format('HHmmss')}`] = "Room " + this.state.roomCode + " | Quiz Questions (Ordering) = " + CheckObjectStringEmpty(this.state.RoomResultRootData, 'Questions');
        // updates[`${utcNow.add(5, 'second').format('HHmmss')}`] = "Room " + this.state.roomCode + " | Quiz SubmittedOnUTC = " + _submittedOnUTC;

        updates[`${logTime1[0]}`] = "Room " + this.state.roomCode + " | Quiz Completed | " + QScore + " | " + this.state.elapsedTime.toFixed(1) + " | " + this.state.roomId + ' | ' + utcNow.toString();
        updates[`${logTime1[1]}`] = "Room " + this.state.roomCode + " | Quiz ASelects = " + _SelectedAnswers + " | " + this.state.roomId;
        updates[`${logTime1[2]}`] = "Room " + this.state.roomCode + " | Quiz ASelects (Raw) = " + _ASelects + " | " + this.state.roomId;
        updates[`${logTime1[3]}`] = "Room " + this.state.roomCode + " | Quiz QResults = " + QResults + " | Correct (" + QCorrect + "/" + QTotal + ") | " + this.state.roomId;
        updates[`${logTime1[4]}`] = "Room " + this.state.roomCode + " | Quiz Questions (Ordering) = " + CheckObjectStringEmpty(this.state.RoomResultRootData, 'Questions');
        updates[`${logTime1[5]}`] = "Room " + this.state.roomCode + " | Quiz SubmittedOnUTC = " + _submittedOnUTC;

        let retryAgain = false;
        do {
            retryAgain = false;
            let done = false;
            let retry = false;
            await this.props.dbLogs.ref(String(this.props.user.uid) + '/' + utcNow.format('YYYY/M/D'))
                .update(updates)
                .then(() => {
                    done = true;
                })
                .catch((error) => {
                    retry = true;
                    done = true;
                    if (this.state.isDevMode)
                        console.log(error);
                });
            await DelayUntil(() => done === true);
            retryAgain = retry;
        } while (retryAgain);
        //2023.07.05 ==== end ===//

        //2023.10.30 === start ===//
        let updates2 = {};
        updates2[`${logTime2[0]}`] = "Room " + this.state.roomCode + " | Quiz Completed | " + QScore + " | " + this.state.elapsedTime.toFixed(1) + " | " + this.state.roomId + ' | ' + utcNow.toString();
        updates2[`${logTime2[1]}`] = "Room " + this.state.roomCode + " | Quiz ASelects = " + _SelectedAnswers + " | " + this.state.roomId;
        updates2[`${logTime2[2]}`] = "Room " + this.state.roomCode + " | Quiz ASelects (Raw) = " + _ASelects + " | " + this.state.roomId;
        updates2[`${logTime2[3]}`] = "Room " + this.state.roomCode + " | Quiz QResults = " + QResults + " | Correct (" + QCorrect + "/" + QTotal + ") | " + this.state.roomId;
        updates2[`${logTime2[4]}`] = "Room " + this.state.roomCode + " | Quiz Questions (Ordering) = " + CheckObjectStringEmpty(this.state.RoomResultRootData, 'Questions');
        updates2[`${logTime2[5]}`] = "Room " + this.state.roomCode + " | Quiz SubmittedOnUTC = " + _submittedOnUTC;
        do {
            retryAgain = false;
            let done = false;
            let retry = false;
            await this.props.dbLiveQuizLogs
                .ref(String(this.props.user.uid) + '/' + this.state.roomId)
                .update(updates2)
                .then(() => {
                    done = true;
                })
                .catch((error) => {
                    retry = true;
                    done = true;
                    if (this.props.isDevMode)
                        console.log('SetQuizCompletedLog (Error)', error);
                });
            await DelayUntil(() => done === true);
            retryAgain = retry;
        } while (retryAgain);
        //2023.10.30 === end ===//

        //2022.11.16
        return true;
    }

    //=== Logs === ends.

    //#region old codes
    // CheckIfQuizHasBeenDonePreviously = async () => {
    //     let quizInProgress = false;
    //     await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.IsDone))
    //         .once('value', (snapshot) => {
    //             // let firstNoAnswer = false;

    //             // if (snapshot.exists && snapshot.val() != null) {
    //             let snapValue = "";
    //             if (snapshot.exists && snapshot.val() !== null)
    //                 snapValue = snapshot.val();
    //             else
    //                 snapValue = false;  //default

    //             quizInProgress = true;
    //             let isDone = snapValue.toString().toUpperCase() === "TRUE" ? true : false;

    //             this.SetFinalResultToggle(isDone);

    //             // let endLoop = false;
    //             // this.state.qsToggle.map((data, key) => {
    //             //     // this.state.qsToggle[key] = true;
    //             //     // // this.state.qsHasAnswer[key] = false;    //reset so that scrollToPos can work.
    //             //     // this.state.qsNotActiveStat[key] = true;
    //             //     if (!endLoop) {

    //             //         //edited 2020.10.13/14
    //             //         if (!this.state.roomQuestions[key].hasOwnProperty("SpecialMode")) {
    //             //             // if (key < this.state.nextQsToAnswer) {
    //             //             //     this.state.qsToggle[key] = isDone;
    //             //             //     this.state.qsNotActiveStat[key] = isDone;
    //             //             // }
    //             //             this.state.qsToggle[key] = true;
    //             //             this.state.qsNotActiveStat[key] = isDone;

    //             //             // if (this.state.qsSelectedAnswers[key] == '-1')
    //             //             //     this.state.nextQsToAnswer = key - 2;

    //             //             // if (this.state.qsSelectedAnswers[key] == '-1')
    //             //             //     endLoop = true;
    //             //         }
    //             //         else {
    //             //             let setting = this.state.roomQuestions[key].SpecialMode.split(";");
    //             //             if (setting[0] === "Comprehension") {
    //             //                 this.state.qsToggle[key] = true;
    //             //             }
    //             //             else {
    //             //                 let range = setting[1].split(",");
    //             //                 let qsStart = range[0];
    //             //                 let qsEnd = range[1];
    //             //                 if (key <= qsEnd - 1) {
    //             //                     // this.state.nextQsToAnswer = qsStart;
    //             //                     // if (this.state.qsHasAnswer[qsStart - 1])
    //             //                     //     this.state.qsToggle[qsStart - 1] = true;
    //             //                     // else
    //             //                     //     this.state.qsToggle[qsStart - 1] = isDone;
    //             //                     this.state.qsToggle[qsStart - 1] = true;

    //             //                     if (!this.state.qsHasAnswer[qsStart - 1] && !firstNoAnswer) {
    //             //                         this.state.nextQsToAnswer = qsStart;
    //             //                         firstNoAnswer = true;
    //             //                         // endLoop = true;
    //             //                     }
    //             //                     // this.state.nextQsToAnswer = qsEnd;
    //             //                 }
    //             //             }
    //             //             this.state.qsNotActiveStat[key] = isDone;
    //             //         }

    //             //         if (key < this.state.qsSelectedAnswers.length)
    //             //             if (this.state.qsSelectedAnswers[key] == '-1') {
    //             //                 endLoop = true;
    //             //                 this.state.nextQsToAnswer++;
    //             //             }
    //             //     }
    //             // });

    //             // this.props.SetAlert("", this.state.loadedElapsedTime);

    //             this.setState({
    //                 nextQsToAnswer: this.state.nextQsToAnswer,

    //                 isQuizDoneAnswered: isDone,
    //                 qsToggle: this.state.qsToggle,
    //                 // qsHasAnswer: this.state.qsHasAnswer,
    //                 qsNotActiveStat: this.state.qsNotActiveStat,
    //                 toggleShowResult: isDone,
    //                 // toggleResultIcon: true,
    //                 // qsQtyToAnswer: 0,
    //                 isQuizStarted: isDone,
    //                 toggleResultIcon: isDone,

    //                 loadingInProgress: false,
    //                 // isQuizDoneAnswered: true,
    //                 // isQuizStarted: false,
    //                 // isQuizQuestionsLoaded: true,
    //                 // toggleResultProcessing: false,
    //                 // isAllAnswered: false,
    //                 // ctTimerActive: !isDone,

    //                 isQuizFullyLoaded: true,
    //                 isQuizDone: isDone,

    //             }, () => {
    //                 // this.props.SetAlert("", this.state.isQuizDone + "<br/>" + this.state.elapsedTime);

    //                 // this.props.SetAlert("", JSON.stringify(this.state.qsSelectedAnswers));

    //                 // alert(this.state.isQuizStarted + " | " + this.state.toggleResultProcessing + " | " + this.state.roomQuestions.length);

    //                 // alert(this.state.qsToggle.join(", "));

    //                 // this.checkQuizComplete();
    //                 // this.SaveQsResults();
    //                 this.checkQuizComplete();

    //                 //Scroll to Result.
    //                 if (isDone) {
    //                     setTimeout(() => {
    //                         document.getElementById("quiz-pos-q-result").scrollIntoView({ behavior: 'smooth' });
    //                         // this.setState({
    //                         //     toggleResultIcon: true,
    //                         // });
    //                     }, 1500);
    //                 }
    //             });
    //             // }
    //             // else {
    //             //     this.SetResultUi();
    //             // }
    //         })
    //         .catch(error => {
    //             this.props.SetAlert("Error", error.code + "<br />" + error.message);
    //         });

    //     //Log - Enter Quiz Room - "Room 22329 | Entered | New Quiz | 1598413137876"
    //     await this.SetEnterRoomLog(quizInProgress);
    // }
    //#endregion

    //2023.10.06 - few fetch from the same root path all combined into one function.
    GetAndPopulate_RoomResultRootData = async () => {
        // if (this.state.isQuizDone && this.state.isQuizDoneStateLoaded)
        //     return true;

        const rootUrl = this.roomResultPath(ResultNode.Root);
        if (this.props.isDevMode)
            console.log('GetAndPopulate_RoomResultRootData =\n' + rootUrl);

        // console.log('GetAndPopulate_RoomResultRootData = start');
        let isSuccess = false;
        let counter = 0;
        // let done = false;

        let isDone = false;
        // let qsAnswers = [];
        // let _savedScore = 0;
        // let _elapsedTime = 0;
        // let _loadedElapsedTime = this.state.loadedElapsedTime;

        let dataSnapshot = null;
        do {
            dataSnapshot = null;
            let _dataSnapshot = null;
            let done = false;
            let _success = false;
            isSuccess = false;
            counter++;
            await this.props.dbLiveQuiz
                .ref(rootUrl)
                .once('value', async (snapshot) => {
                    if (this.props.isDevMode)
                        console.log('GetAndPopulate_RoomResultRootData, snapshot =\n' + JSON.stringify(snapshot));

                    //2024.05.23 - moved to outside fetch function.
                    if (snapshot.exists() && snapshot.val() !== null)
                        _dataSnapshot = snapshot;
                    //#region old codes.
                    // //default data - initial.
                    // const roomInfo = this.state.roomInfoModal;
                    // const qnqty = CheckObjectNumber(roomInfo, 'QnQty');

                    // //2024.03.13 - default to false atm.
                    // const randomQuestionMode = false;   // CheckObjectBoolean(roomInfo, 'RandomQuestionMode', this.state.isRandomQuestionMode);

                    // let qs_NOs = [];
                    // let qs_selects = [];
                    // let qs_results = [];
                    // [...Array(qnqty)].map((data, key) => {
                    //     qs_NOs.push(key + 1);
                    //     qs_selects.push('-1');      // -1 = answer not selected, default value.
                    //     qs_results.push('0');       // 0 = wrong, default value.
                    //     return null;
                    // });
                    // const qs_NOs_joined = qs_NOs.join(':');
                    // const qs_selects_default = qs_selects.join(':');
                    // const qs_results_default = qs_results.join(':');
                    // let scoreRecord_default = '0.00;0.000;';

                    // let dataArray = this.state.RoomResultRootData; //initial.
                    // dataArray = []; //reset empty.

                    // //populate fetched data into obj.
                    // if (snapshot.exists() && snapshot.val() !== null) {
                    //     const data = snapshot.val();
                    //     if (CheckNullValue(data) !== null) {

                    //         const snapshot_scoreRecord = CheckObjectStringEmpty(data, 'ScoreRecord').split(';');
                    //         if (Array.isArray(snapshot_scoreRecord) && snapshot_scoreRecord.length > 1) {
                    //             const _savedScore = Number(snapshot_scoreRecord[0]) > 0 ? Number(snapshot_scoreRecord[0]) : 0;
                    //             const _elapsedTime = Number(snapshot_scoreRecord[1]) > 0 ? Number(snapshot_scoreRecord[1]) : 0;
                    //             this.setState({
                    //                 savedScore: _savedScore,
                    //                 elapsedTime: _elapsedTime,
                    //                 loadedElapsedTime: _elapsedTime,
                    //             });
                    //             if (this.props.isDevMode) {
                    //                 console.log('savedScore', this.state.savedScore, _savedScore);
                    //                 console.log('elapsedTime', this.state.elapsedTime, _elapsedTime);
                    //                 console.log('loadedElapsedTime', this.state.loadedElapsedTime, _elapsedTime);
                    //             }
                    //             scoreRecord_default = _savedScore.toFixed(2) + ';' + _elapsedTime.toFixed(3) + ';';
                    //         }

                    //         let obj = {
                    //             RoomId: CheckStringEmpty(snapshot.key),
                    //             IsDone: CheckObjectBoolean(data, 'IsDone'),
                    //             IsDoneOnUtc: CheckObjectStringEmpty(data, 'IsDoneOnUtc'),   //new.
                    //             SubmittedOnUTC: CheckObjectStringEmpty(data, 'SubmittedOnUTC'),   //new.
                    //             ASelects: CheckObjectNullValue(data, 'ASelects') === null ? qs_selects_default : CheckObjectStringEmpty(data, 'ASelects'),
                    //             QResults: CheckObjectNullValue(data, 'QResults') === null ? qs_results_default : CheckObjectStringEmpty(data, 'QResults'),
                    //             ScoreRecord: CheckObjectNullValue(data, 'ScoreRecord') === null ? scoreRecord_default : CheckObjectStringEmpty(data, 'ScoreRecord'),
                    //             Questions: randomQuestionMode === false ? qs_NOs_joined : CheckObjectStringEmpty(data, 'Questions', qs_NOs_joined),     //new.
                    //         };
                    //         // if (randomQuestionMode)
                    //         //     obj.Questions = CheckObjectNullValue(data, 'Questions') === null ? '' : String(data.Questions);
                    //         dataArray.push(obj);
                    //     }
                    // }
                    // else {
                    //     let obj = {
                    //         RoomId: CheckStringEmpty(snapshot.key),
                    //         IsDone: false,
                    //         IsDoneOnUtc: '',    //new.
                    //         SubmittedOnUTC: '',     //new.
                    //         ASelects: qs_selects_default,
                    //         QResults: qs_results_default,
                    //         ScoreRecord: scoreRecord_default,
                    //         Questions: randomQuestionMode === false ? qs_NOs_joined : '',   //new.
                    //     };
                    //     // if (randomQuestionMode)
                    //     //     obj.Questions = '';
                    //     dataArray.push(obj);
                    // }
                    // if (this.props.isDevMode)
                    //     console.log('dataArray =', JSON.stringify(dataArray));

                    // //proceed.
                    // if (dataArray.length > 0) {
                    //     let data = dataArray[0];

                    //     //2023.11.16
                    //     //avoid ASelects in wrong format, shd b in raw, but in (Q:A;)*n form
                    //     let p_ASelects = CheckObjectStringEmpty(data, 'ASelects');
                    //     if (p_ASelects.includes(':') && p_ASelects.includes(';')) {
                    //         let p_ASelects_array = [];
                    //         const _tmp_p_ASelects_array = p_ASelects.split(';');
                    //         for (let p1 = 0; p1 < _tmp_p_ASelects_array.length; p1++) {
                    //             const aSelect = _tmp_p_ASelects_array[p1].split(':');
                    //             //aSelect[0] = Question No.
                    //             //aSelect[1] = Answer.
                    //             const aSelect_AnswerIndex = AtoZ.findIndex(x => x === aSelect[1]);
                    //             p_ASelects_array.push(aSelect_AnswerIndex.toString());
                    //         }
                    //         p_ASelects = p_ASelects_array.join(':');
                    //     }
                    //     data['ASelects'] = p_ASelects;
                    //     // console.log('ASelects', p_ASelects);

                    //     const { ASelects, QResults } = await this.CheckAndRandomizeQuestions(data, data.ASelects, data.QResults);
                    //     if (this.props.isDevMode) {
                    //         console.log('ASelects (After Check) = ', ASelects);
                    //         console.log('QResults (After Check) = ', QResults);
                    //     }
                    //     data.ASelects = ASelects;
                    //     data.QResults = QResults;
                    //     this.setState({
                    //         RoomResultRootData: data,
                    //         isDoneOnUtc: CheckObjectStringEmpty(data, 'IsDoneOnUtc'),
                    //         SubmittedOnUTC: CheckObjectStringEmpty(data, 'SubmittedOnUTC'),
                    //         // isQuizSubmitted: CheckObjectStringEmpty(data, 'SubmittedOnUTC') !== '' && CheckObjectNumber(data, 'SubmittedOnUTC', 0) > 0,
                    //         isQuizSubmitted: CheckObjectNullValue(data, 'SubmittedOnUTC') !== null,
                    //     });

                    //     if (this.props.isDevMode)
                    //         console.log('RoomResultRootData (final) =', JSON.stringify(data));

                    //     // //Update Root. for more complete data ref, rather than only a IsDone or etc. if data r same, write will not be counted.
                    //     // let updateDone = false;
                    //     // let updateCounter = 0;
                    //     // let obj = Object.assign({}, data);
                    //     // delete obj.RoomId;
                    //     // do {
                    //     //     await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.Root))
                    //     //         .set(obj)
                    //     //         .then(() => { updateDone = true; })
                    //     //         .catch((error) => { updateDone = true; });
                    //     //     updateCounter++;
                    //     // } while (updateDone === false || updateCounter < 5);

                    //     //IsDone.
                    //     isDone = data.IsDone;

                    //     //ASelects & QResults.
                    //     this.PopulateSavedQsSelectsAndSetQsToggle(CheckStringEmpty(ASelects, qs_selects_default), data.Questions);

                    //     //SubmittedOnUTC & Completed Logs.
                    //     if (isDone && CheckObjectStringEmpty(data, 'SubmittedOnUTC') === ''
                    //         && this.state.SubmittedOnUTC === ''
                    //         && this.state.isQuizSubmitted === false) {
                    //         //2023.10.20
                    //         // const ticks = (moment.utc().valueOf() / 10000).toString();
                    //         const timeOnUtc = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                    //         await this.SetQuizCompletedLog(timeOnUtc);
                    //         await this.SetQuizRoomResultSubmittedOnUTC(timeOnUtc);
                    //         await this.props.SaveRoomInLocalHistoryList_V2({
                    //             Title: this.state.roomTitle,
                    //             RoomCode: this.state.roomCode,
                    //             RoomId: this.state.roomId,
                    //             Date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    //             Score: this.GetScore(),
                    //             ElapsedTime: this.state.loadedElapsedTime,   //2023.11.09
                    //             RoomType: 0,
                    //             SubmittedOnUTC: CheckStringEmpty(this.state.SubmittedOnUTC),  //2023.10.20
                    //             IsDoneOnUtc: CheckStringEmpty(this.state.isDoneOnUtc),    //2023.10.20
                    //         });
                    //     }

                    //     // //QResults.
                    //     // if (CheckNullValue(data.QResults) !== null) {
                    //     //     let qsResults = CheckStringEmpty(data.QResults).split(":");
                    //     //     qsAnswers = this.state.qsAnswers;
                    //     //     this.state.qsAnswers.map((data, key) => {
                    //     //         const key_value = CheckObjectNullValue(qsResults, key) === null ? '0' : String(qsResults[key]);
                    //     //         return qsAnswers[key] = key_value === '1' ? true : false;
                    //     //     });
                    //     // }

                    //     // //ScoreRecord.
                    //     // if (CheckNullValue(data.ScoreRecord) !== null) {
                    //     //     const records = data.ScoreRecord.split(';');
                    //     //     _savedScore = Number(records[0]) > 0 ? Number(records[0]) : 0;
                    //     //     _elapsedTime = Number(records[1]) > 0 ? Number(records[1]) : 0;
                    //     // }
                    // }
                    //#endregion
                    _success = true;
                    done = true;
                })
                .catch(async (error) => {
                    // this.props.SetAlert("Error", error.code + "<br />" + error.message);
                    await this.props.SetErrorLog(new Date(), "GetAndPopulate_RoomResultRootData | Root | Error = " + error);
                    done = true;
                });
            await DelayUntil(() => done === true);
            dataSnapshot = _dataSnapshot;
            isSuccess = _success;
        }
        while (isSuccess === false && counter < 5)

        //2024.05.23 - moved here.
        // if (isSuccess && dataSnapshot !== null) {
        //default data - initial.
        const roomInfo = this.state.roomInfoModal;
        const qnqty = CheckObjectNumber(roomInfo, 'QnQty');

        //2024.03.13 - default to false atm.
        const randomQuestionMode = false;   // CheckObjectBoolean(roomInfo, 'RandomQuestionMode', this.state.isRandomQuestionMode);

        let qs_NOs = [];
        let qs_selects = [];
        let qs_results = [];
        [...Array(qnqty)].map((data, key) => {
            qs_NOs.push(key + 1);
            qs_selects.push('-1');      // -1 = answer not selected, default value.
            qs_results.push('0');       // 0 = wrong, default value.
            return null;
        });
        const qs_NOs_joined = qs_NOs.join(':');
        const qs_selects_default = qs_selects.join(':');
        const qs_results_default = qs_results.join(':');
        let scoreRecord_default = '0.00;0.000;';

        let roomResultRootData = this.state.RoomResultRootData; //initial.
        roomResultRootData = null; //reset empty.

        //populate fetched data into obj.
        if (dataSnapshot !== null) {
            const data = CapitalizeJsonKeys(dataSnapshot.val());
            if (CheckNullValue(data) !== null) {

                const snapshot_scoreRecord = CheckObjectStringEmpty(data, 'ScoreRecord').split(';');
                if (Array.isArray(snapshot_scoreRecord) && snapshot_scoreRecord.length > 1) {
                    const _savedScore = Number(snapshot_scoreRecord[0]) > 0 ? Number(snapshot_scoreRecord[0]) : 0;
                    const _elapsedTime = Number(snapshot_scoreRecord[1]) > 0 ? Number(snapshot_scoreRecord[1]) : 0;
                    this.setState({
                        savedScore: _savedScore,
                        elapsedTime: _elapsedTime,
                        loadedElapsedTime: _elapsedTime,
                    });
                    if (this.props.isDevMode) {
                        console.log('savedScore', this.state.savedScore, _savedScore);
                        console.log('elapsedTime', this.state.elapsedTime, _elapsedTime);
                        console.log('loadedElapsedTime', this.state.loadedElapsedTime, _elapsedTime);
                    }
                    scoreRecord_default = _savedScore.toFixed(2) + ';' + _elapsedTime.toFixed(3) + ';';
                }

                //set data.
                roomResultRootData = {
                    RoomId: CheckObjectStringEmpty(dataSnapshot, 'Key'),
                    IsDone: CheckObjectBoolean(data, 'IsDone'),
                    IsDoneOnUtc: CheckObjectStringEmpty(data, 'IsDoneOnUtc'),   //new.
                    SubmittedOnUTC: CheckObjectStringEmpty(data, 'SubmittedOnUTC'),   //new.
                    ASelects: CheckObjectNullValue(data, 'ASelects') === null ? qs_selects_default : CheckObjectStringEmpty(data, 'ASelects'),
                    QResults: CheckObjectNullValue(data, 'QResults') === null ? qs_results_default : CheckObjectStringEmpty(data, 'QResults'),
                    ScoreRecord: CheckObjectNullValue(data, 'ScoreRecord') === null ? scoreRecord_default : CheckObjectStringEmpty(data, 'ScoreRecord'),
                    Questions: randomQuestionMode === false ? qs_NOs_joined : CheckObjectStringEmpty(data, 'Questions', qs_NOs_joined),     //new.
                    ASelectList: [],
                };
            }
        }
        if (roomResultRootData === null) {
            //set default data.
            roomResultRootData = {
                RoomId: CheckObjectStringEmpty(dataSnapshot, 'Key'),
                IsDone: false,
                IsDoneOnUtc: '',    //new.
                SubmittedOnUTC: '',     //new.
                ASelects: qs_selects_default,
                QResults: qs_results_default,
                ScoreRecord: scoreRecord_default,
                Questions: randomQuestionMode === false ? qs_NOs_joined : '',   //new.
                ASelectList: [],
            };
        }
        if (this.props.isDevMode)
            console.log('roomResultRootData =\n' + JSON.stringify(roomResultRootData));

        //proceed.
        if (roomResultRootData != null) {
            let data = roomResultRootData;

            //2023.11.16
            //avoid ASelects in wrong format, shd b in raw (1:2:1:3:0) format, but in (Q:A;)*n format.
            let p_ASelects = CheckObjectStringEmpty(data, 'ASelects');
            if (p_ASelects.includes(':') && p_ASelects.includes(';')) {
                let p_ASelects_array = [];
                const _tmp_p_ASelects_array = p_ASelects.split(';');
                for (let p1 = 0; p1 < _tmp_p_ASelects_array.length; p1++) {
                    if (CheckStringEmpty(_tmp_p_ASelects_array) !== '') {
                        const aSelect = _tmp_p_ASelects_array[p1].split(':');
                        //aSelect[0] = Question No.
                        //aSelect[1] = Answer.
                        const aSelect_AnswerIndex = AtoZ.findIndex(x => x === aSelect[1]);
                        p_ASelects_array.push(aSelect_AnswerIndex.toString());
                    }
                }
                p_ASelects = p_ASelects_array.join(':');
                data['ASelects'] = p_ASelects;
                // console.log('ASelects', p_ASelects);
            }

            //2024.05.23 disabled - avoid randomizing questions.
            // const { ASelects, QResults } = await this.CheckAndRandomizeQuestions(data, data['ASelects'], data['QResults']);
            // if (this.props.isDevMode) {
            //     console.log('ASelects (After Check) = ', ASelects);
            //     console.log('QResults (After Check) = ', QResults);
            // }
            // data['ASelects'] = ASelects;
            // data['QResults'] = QResults;

            //2024.05.23 - populate ASelectList.
            let { ASelects, ASelectList } = data;
            if (ASelectList.length === 0 && CheckNullValue(ASelects) !== null && qnqty > 0) {
                let _ASelectList = [];
                const ASelects_array = CheckStringEmpty(ASelects).split(':').filter(x => CheckNullValue(x) !== null);
                for (let s = 0; s < qnqty; s++) {
                    if (ASelects_array.length >= qnqty) {
                        const index = Number(ASelects_array[s]);
                        _ASelectList.push({ No: s + 1, Index: index, Value: index < 0 ? '' : AtoZ[index] });
                    }
                }
                // ASelectList = _ASelectList;
                data['ASelectList'] = _ASelectList;
            }
            // const { ASelectList } = data;

            this.setState({
                RoomResultRootData: data,
                isDoneOnUtc: CheckObjectStringEmpty(data, 'IsDoneOnUtc'),
                SubmittedOnUTC: CheckObjectStringEmpty(data, 'SubmittedOnUTC'),
                isQuizSubmitted: CheckObjectNullValue(data, 'SubmittedOnUTC') !== null,
            });

            if (this.props.isDevMode)
                console.log('RoomResultRootData (final) =', JSON.stringify(data));

            // //Update Root. for more complete data ref, rather than only a IsDone or etc. if data r same, write will not be counted.
            // let updateDone = false;
            // let updateCounter = 0;
            // let obj = Object.assign({}, data);
            // delete obj.RoomId;
            // do {
            //     await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.Root))
            //         .set(obj)
            //         .then(() => { updateDone = true; })
            //         .catch((error) => { updateDone = true; });
            //     updateCounter++;
            // } while (updateDone === false || updateCounter < 5);

            //IsDone.
            isDone = CheckObjectBoolean(data, 'IsDone');

            //ASelects & QResults.
            this.PopulateSavedQsSelectsAndSetQsToggle(ASelectList, CheckStringEmpty(ASelects, qs_selects_default), data.Questions);

            //SubmittedOnUTC & Completed Logs.
            if (isDone && CheckObjectStringEmpty(data, 'SubmittedOnUTC') === ''
                && this.state.SubmittedOnUTC === ''
                && this.state.isQuizSubmitted === false) {
                //2023.10.20
                // const ticks = (moment.utc().valueOf() / 10000).toString();
                const timeOnUtc = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                await this.SetQuizCompletedLog(timeOnUtc);
                await this.SetQuizRoomResultSubmittedOnUTC(timeOnUtc);
                await this.props.SaveRoomInLocalHistoryList_V2({
                    Title: this.state.roomTitle,
                    RoomCode: this.state.roomCode,
                    RoomId: this.state.roomId,
                    Date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    Score: this.GetScore(),
                    ElapsedTime: this.state.loadedElapsedTime,   //2023.11.09
                    RoomType: 0,
                    SubmittedOnUTC: CheckStringEmpty(this.state.SubmittedOnUTC),  //2023.10.20
                    IsDoneOnUtc: CheckStringEmpty(this.state.isDoneOnUtc),    //2023.10.20
                });
            }

            // //QResults.
            // if (CheckNullValue(data.QResults) !== null) {
            //     let qsResults = CheckStringEmpty(data.QResults).split(":");
            //     qsAnswers = this.state.qsAnswers;
            //     this.state.qsAnswers.map((data, key) => {
            //         const key_value = CheckObjectNullValue(qsResults, key) === null ? '0' : String(qsResults[key]);
            //         return qsAnswers[key] = key_value === '1' ? true : false;
            //     });
            // }

            // //ScoreRecord.
            // if (CheckNullValue(data.ScoreRecord) !== null) {
            //     const records = data.ScoreRecord.split(';');
            //     _savedScore = Number(records[0]) > 0 ? Number(records[0]) : 0;
            //     _elapsedTime = Number(records[1]) > 0 ? Number(records[1]) : 0;
            // }
        }
        // }

        if (this.props.isDevMode) {
            console.log('isQuizDone = ' + isDone);
        }
        this.setState({
            isQuizDone: isDone,
            // qsAnswers: qsAnswers,
            isQuizDoneStateLoaded: true,
            // savedScore: this.state.savedScore !== _savedScore ? _savedScore : this.state.savedScore,
            // elapsedTime: this.state.elapsedTime !== _elapsedTime ? _elapsedTime : this.state.elapsedTime,
            // loadedElapsedTime: this.state.elapsedTime !== _elapsedTime ? _elapsedTime : this.state.elapsedTime,
        });

        return isSuccess;
    }

    //2023.10.06
    CheckAndRandomizeQuestions = async (roomResultRootData = null, ASelects = '', QResults = '') => {
        if (roomResultRootData === null)
            return null;

        const isDone = CheckObjectBoolean(roomResultRootData, 'IsDone');
        const roomInfo = this.state.roomInfoModal;
        const qnqty = CheckObjectNullValue(roomInfo, 'QnQty') === null ? this.state.roomQuestions.length : roomInfo.QnQty;
        const randomQuestionMode = false;   // CheckObjectBoolean(roomInfo, 'RandomQuestionMode', this.state.isRandomQuestionMode);
        if (randomQuestionMode === true) {

            //default data - initial.
            let qs_NOs = [];
            [...Array(qnqty)].map((data, key) => {
                qs_NOs.push(key + 1);
                return null;
            });
            const qs_NOs_joined = qs_NOs.join(':');

            //Randomized Questions' NO.
            let randomizedQuestionNOs = '';
            if (CheckObjectNullValue(roomResultRootData, 'Questions') === null) {
                if (isDone === false) {
                    let randomizedQuestions = this.state.roomQuestions
                        .map(value => ({ value, sort: Math.random() }))
                        .sort((a, b) => a.sort - b.sort)
                        .map(({ value }) => value);
                    this.setState({ roomQuestions: randomizedQuestions, });
                    console.log('randomizedQuestions =\n', JSON.stringify(randomizedQuestions));

                    //Update to Root > Questions.
                    randomizedQuestionNOs = randomizedQuestions.map(({ No }) => No).join(':');
                }
                else {
                    //IsDone = true.
                    randomizedQuestionNOs = qs_NOs_joined;
                }
            }
            else {
                randomizedQuestionNOs = CheckObjectStringEmpty(roomResultRootData, 'Questions');
            }

            //Update to Root > Questions, if Room's RandomQuestionMode(true).
            if (CheckObjectNullValue(roomResultRootData, 'Questions') === null
                || (CheckStringEmpty(randomizedQuestionNOs) !== '' && randomizedQuestionNOs !== CheckObjectStringEmpty(roomResultRootData, 'Questions'))) {

                let update_done = false;
                let update_counter = 0;
                do {
                    update_counter++;
                    let doneCount = 0;
                    await this.props.dbLiveQuiz
                        .ref(this.roomResultPath(ResultNode.Questions))
                        .set(randomizedQuestionNOs)
                        .then(() => {
                            doneCount++;
                        })
                        .catch((error) => {
                            if (this.props.isDevMode)
                                console.log(error);
                        });
                    //2023.10.30
                    await this.props.dbLiveQuizResult
                        .ref(this.studentLiveQuizResultPath(ResultNode.Questions))
                        .set(randomizedQuestionNOs)
                        .then(() => {
                            doneCount++;
                        })
                        .catch((error) => {
                            if (this.props.isDevMode)
                                console.log(error);
                        });
                    update_done = doneCount === 2;
                    await Delay(500);
                } while (update_done === false && update_counter < 5);
            }

            // let ASelects_newArray = [];
            // let QResults_newArray = [];
            // const ASelects_array = CheckStringEmpty(ASelects).split(':');
            // const QResults_array = CheckStringEmpty(QResults).split(':');
            const qsNOs_array = CheckObjectStringEmpty(roomResultRootData, 'Questions').split(':');
            let newQuestionArray = [];

            for (let k = 0; k < qnqty; k++) {
                const findIndex = this.state.roomQuestions.findIndex(x => CheckObjectStringEmpty(x, 'No') === String(qsNOs_array[k]));
                if (findIndex > -1)
                    newQuestionArray.push(this.state.roomQuestions[findIndex]);
            }
            // console.log('newQuestionArray =\n', JSON.stringify(newQuestionArray));

            // if (qnqty === newQuestionArray.length)
            //     this.setState({ roomQuestions: newQuestionArray, });
            // //ASelects & QResults not needed to update as adi filled with default data from previous fetch stage.

            //Jay added 2024.01.15
            if (isDone === true) {
                //sort back to linear state.
                let ASelects_newArray = [];
                let QResults_newArray = [];
                const ASelects_array = CheckStringEmpty(ASelects).split(':');
                // const QResults_array = CheckStringEmpty(QResults).split(':');
                let temp_newQuestionArray = [];
                for (let k = 0; k < qnqty; k++) {
                    const findIndex = newQuestionArray.findIndex(x => CheckObjectStringEmpty(x, 'No') === String(k + 1));
                    if (findIndex > -1) {
                        ASelects_newArray.push(ASelects_array[findIndex]);
                        const qResult = String(this.state.roomQuestions[findIndex].Answer) === String(this.state.roomQuestions[findIndex].Selection).split(';')[Number(ASelects_array[findIndex])].split(':')[0];
                        QResults_newArray.push(qResult ? '1' : '0');
                        temp_newQuestionArray.push(newQuestionArray[findIndex]);
                    }
                }
                ASelects = ASelects_newArray.join(':');
                QResults = QResults_newArray.join(':');
                this.setState({
                    roomQuestions: temp_newQuestionArray,
                });
                if (this.props.isDevMode) {
                    console.log('ASelects (sorted) \n', ASelects.toString());
                    console.log('QResults (sorted) \n', QResults.toString());
                    console.log('roomQuestions (sorted) \n', JSON.stringify(temp_newQuestionArray));
                }
            }
            else {
                this.setState({ roomQuestions: newQuestionArray, });
            }
        }
        else {
            //for  'randomQuestionMode === false' (qs still in random form)  or  'isDone === true' (qs in linear form)
            let ASelects_newArray = [];
            let QResults_newArray = [];
            const ASelects_array = CheckStringEmpty(ASelects).split(':');
            // const QResults_array = CheckStringEmpty(QResults).split(':');
            const qsNOs_array = CheckObjectStringEmpty(roomResultRootData, 'Questions').split(':');
            if (qsNOs_array.length > 0) {
                for (let k = 0; k < qnqty; k++) {
                    const findIndex = qsNOs_array.findIndex(x => x === CheckObjectStringEmpty(this.state.roomQuestions[k], 'No'));
                    if (findIndex > -1 && ASelects_array.length > 0) {
                        ASelects_newArray.push(ASelects_array[findIndex]);

                        //2023.12.15 - revamped.
                        // const answer = String(this.state.roomQuestions[k].Answer);
                        // const selectedAnswer = String(this.state.roomQuestions[k].Selection).split(';')[Number(ASelects_array[findIndex])].split(':')[0];
                        // const qResult = answer === selectedAnswer;
                        if (Number(ASelects_array[findIndex]) >= 0) {
                            const qResult = String(this.state.roomQuestions[k].Answer) === String(this.state.roomQuestions[k].Selection).split(';')[Number(ASelects_array[findIndex])].split(':')[0];
                            QResults_newArray.push(qResult ? '1' : '0');
                        }
                        // if (qResult !== QResults_array[findIndex])
                        //     QResults_newArray.push(qResult);
                        // else
                        //     QResults_newArray.push(QResults_array[findIndex]);

                        //debug.
                        // const answer = String(this.state.roomQuestions[k].Answer);
                        // let selections_splits = String(this.state.roomQuestions[k].Selection).split(';');
                        // let selections = [];
                        // selections_splits.map((data, key) => {
                        //     const splits = data.split(':');
                        //     selections.push(splits[0]);
                        // });
                        // selections.sort((x, y) => x - y);
                        // const selectedAnswer = selections[Number(ASelects_array[findIndex])];
                        // QResults_newArray.push(qResult ? '1' : '0');
                        // if (CheckObjectNumber(this.state.roomQuestions[k], 'No') === 24) {
                        //     console.log('(24) Answer =', answer);
                        //     console.log('(24) Selected Answer =', selectedAnswer, ASelects_array[findIndex], JSON.stringify(selections));
                        //     console.log('(24) Answer === Selected Answer >', String(qResult));
                        // }
                    }
                }
            }
            else {
                //Jay edited 2024.01.15
                for (let k = 0; k < qnqty; k++) {
                    ASelects_newArray.push(ASelects_array[k]);
                    const qResult = String(this.state.roomQuestions[k].Answer) === String(this.state.roomQuestions[k].Selection).split(';')[Number(ASelects_array[k])].split(':')[0];
                    QResults_newArray.push(qResult ? '1' : '0');
                }
            }
            // for (let k = 0; k < qnqty; k++) {
            //     ASelects_newArray.push(ASelects_array[k]);
            //     const qResult = String(this.state.roomQuestions[k].Answer) === String(this.state.roomQuestions[k].Selection).split(';')[Number(ASelects_array[k])].split(':')[0];
            //     QResults_newArray.push(qResult ? '1' : '0');
            // }
            ASelects = ASelects_newArray.join(':');
            QResults = QResults_newArray.join(':');
        }
        return { ASelects, QResults };
    }

    CheckIfQuizHasBeenDoneBefore = async () => {

        //2022.06.15
        if (this.state.isQuizDone && this.state.isQuizDoneStateLoaded)
            return true;

        let isSuccess = false;

        let isDone = false;
        let done = false;
        await this.props.dbLiveQuiz
            .ref(this.roomResultPath(ResultNode.IsDone))
            .once('value', (snapshot) => {
                // let firstNoAnswer = false;

                // if (snapshot.exists && snapshot.val() != null) {
                let snapValue = '';
                if (snapshot.exists() && snapshot.val() !== null)
                    snapValue = snapshot.val();
                else
                    snapValue = false;  //default

                isDone = String(snapValue).toLowerCase() === 'true' ? true : false;

                // this.setState({
                //     isQuizDone: isDone,
                //     isQuizDoneStateLoaded: true,
                // });

                isSuccess = true;
                done = true;
            })
            .catch(async (error) => {
                // this.props.SetAlert("Error", error.code + "<br />" + error.message);
                await this.props.SetErrorLog(new Date(), "CheckIfQuizHasBeenDoneBefore | Get IsDone | Error = " + error);
                done = true;
            });
        await DelayUntil(() => done === true);

        if (this.props.isDevMode)
            console.log('isQuizDone = ' + isDone);

        this.setState({
            isQuizDone: isDone,
            isQuizDoneStateLoaded: true,
        });

        return isSuccess;
    }

    CheckIfQuizHasFullyLoaded = async () => {

        this.setState({ LoadingProgressionText: 'preparing page contents...' });  //2022.11.16

        let isDone = this.state.isQuizDone;
        // alert(isDone);
        await this.SetFinalResultToggle(isDone);

        //2021.04.02
        // await DelayUntil(() => this.state.isQuizCorrectAnswerRechecked === true);
        await this.Populate_CategorizedResults();

        this.setState({
            // nextQsToAnswer: this.state.nextQsToAnswer,

            isQuizDoneAnswered: isDone,
            // qsToggle: this.state.qsToggle,
            // qsHasAnswer: this.state.qsHasAnswer,
            // qsNotActiveStat: this.state.qsNotActiveStat,
            // toggleShowResult: isDone,
            // toggleResultIcon: true,
            // qsQtyToAnswer: 0,
            isQuizStarted: isDone,
            toggleResultIcon: isDone,

            loadingInProgress: false,
            // isQuizDoneAnswered: true,
            // isQuizStarted: false,
            // isQuizQuestionsLoaded: true,
            // toggleResultProcessing: false,
            // isAllAnswered: false,
            // ctTimerActive: !isDone,

            // isQuizFullyLoaded: true,
        });
        await Delay(500);
        // }, async () => {
        await this.checkQuizComplete();
        await Delay(500);

        //2020.11.24    //Update Score in History List, if Score is empty.
        if (this.state.isQuizDone && this.state.isQuizDoneStateLoaded) {
            // let roomHistory = this.props.historyList.find(el => String(el.RoomCode) === this.state.roomCode && String(el.RoomId) === this.state.roomId);
            // // alert(roomHistory.RoomCode + '\n' + this.state.roomCode + '\n' + this.props.historyList.length + '\n' + roomHistory.Score);
            // // alert(roomHistory.RoomCode + '\n' + roomHistory.RoomId + '\n' + roomHistory.hasOwnProperty('Score') + '\n' + roomHistory.Score);
            // if (roomHistory !== null && roomHistory !== undefined) {
            //     // await this.props.UpdateScoreInRoomHistoryList({
            //     //     'RoomCode': this.state.roomCode,
            //     //     'RoomId': this.state.roomId,
            //     //     // 'Title': this.state.roomTitle,
            //     //     'Score': this.GetScore(),
            //     // });

            //     //2021.10.14
            //     await this.props.SaveRoomInLocalHistoryList_V2({
            //         Title: this.state.roomTitle,
            //         RoomCode: this.state.roomCode,
            //         RoomId: this.state.roomId,
            //         Date: moment().format("YYYY-MM-DD HH:mm:ss"),
            //         Score: this.GetScore(),
            //         SubmittedOnUTC: CheckStringEmpty(this.state.SubmittedOnUTC),  //2023.10.20
            //         IsDoneOnUtc: CheckStringEmpty(this.state.isDoneOnUtc),    //2023.10.20
            //     });
            // }
            await this.CheckAndAddRoomHistoryIfRequired();
        }

        //2021.09.03 - update Score Record in personal data if scores/results are different.
        if (this.state.isScoreRecordNeedUpdate) {
            await this.SaveQsResults();
        }

        // //Scroll to Result.
        // if (isDone) {
        //     setTimeout(() => {
        //         // document.getElementById("quiz-pos-q-result").scrollIntoView({ behavior: 'smooth' });
        //         ScrollToElement("quiz-pos-q-result");
        //     }, 1500);
        // }
        // });

        //Log - Enter Quiz Room - "Room 22329 | Entered | New Quiz | 1598413137876"
        let quizState = this.state.qsQtyToAnswer === this.state.roomInfoModal.QnQty ? false : true;
        await this.SetEnterRoomLog(quizState);

        //2022.06.07
        this.setState({ isQuizFullyLoaded: true, });

        //2022.11.07
        await Delay(1000);
        this.setState({ toggleShowResult: isDone, });
        //Scroll to Result.
        if (isDone) {
            setTimeout(() => {
                // document.getElementById("quiz-pos-q-result").scrollIntoView({ behavior: 'smooth' });
                ScrollToElement("quiz-pos-q-result");
            }, 700);
        }
    }

    //2022.06.14
    CheckAndAddRoomHistoryIfRequired = async () => {
        let roomHistoryIndex = this.props.historyList.findIndex(el => String(el.RoomCode) === this.state.roomCode && String(el.RoomId) === this.state.roomId);
        // console.log(roomHistory.RoomCode + '\n' + this.state.roomCode + '\n' + this.props.historyList.length + '\n' + roomHistory.Score);
        // console.log(roomHistory.RoomCode + '\n' + roomHistory.RoomId + '\n' + roomHistory.hasOwnProperty('Score') + '\n' + roomHistory.Score);
        if (roomHistoryIndex < 0) {
            await this.props.SaveRoomInLocalHistoryList_V2({
                Title: this.state.roomTitle,
                RoomCode: this.state.roomCode,
                RoomId: this.state.roomId,
                Date: moment().format("YYYY-MM-DD HH:mm:ss"),
                Score: this.GetScore(),
                ElapsedTime: this.state.loadedElapsedTime,  //2023.11.09
                RoomType: 0,
                SubmittedOnUTC: CheckStringEmpty(this.state.SubmittedOnUTC),  //2023.10.20
                IsDoneOnUtc: CheckStringEmpty(this.state.isDoneOnUtc),    //2023.10.20
            });
        }
    }

    SetFinalResultToggle = async (isDone = false) => {
        let firstNoAnswer = false;
        let endLoop = false;

        let qsToggle = this.state.qsToggle;
        let qsNotActiveStat = this.state.qsNotActiveStat;
        let nextQsToAnswer = this.state.nextQsToAnswer;

        this.state.qsToggle.map((data, key) => {
            // this.state.qsToggle[key] = true;
            // // this.state.qsHasAnswer[key] = false;    //reset so that scrollToPos can work.
            // this.state.qsNotActiveStat[key] = true;
            // if (!endLoop) {

            //edited 2020.10.13/14
            // if (Array(this.state.roomQuestions[key]).hasOwnProperty("SpecialMode") === false) {     //Array conversion cannot remove    //2020.11.10
            // let arrayObj = JSON.parse(JSON.stringify(this.state.roomQuestions[key]));   //2020.12.11 = change conversion method.
            // if (arrayObj.hasOwnProperty("SpecialMode") === false) {
            // if (key < this.state.nextQsToAnswer) {
            //     this.state.qsToggle[key] = isDone;
            //     this.state.qsNotActiveStat[key] = isDone;
            // }
            // this.state.qsToggle[key] = true;
            // this.state.qsNotActiveStat[key] = isDone;
            let arrayObj = Object.assign({}, this.state.roomQuestions[key]);    //2020.12.14
            if (arrayObj.hasOwnProperty("SpecialMode") === false) {
                if (!firstNoAnswer)
                    qsToggle[key] = true;

                qsNotActiveStat[key] = isDone;

                // if (this.state.qsSelectedAnswers[key] == '-1')
                //     this.state.nextQsToAnswer = key - 2;

                // if (this.state.qsSelectedAnswers[key] == '-1')
                //     endLoop = true;

                if (isDone) {
                    qsToggle[key] = true;
                }
                else {
                    if (this.state.qsSelectedAnswers[key] === '-1' && !firstNoAnswer) {
                        // this.state.nextQsToAnswer = key + 1;
                        firstNoAnswer = true;
                        nextQsToAnswer = key + 1;
                    }
                }
            }
            else {
                //SpecialMode.
                let setting = String(this.state.roomQuestions[key].SpecialMode).split(';');
                let range = setting[1].split(',');
                let qsStart = range[0];
                let qsEnd = range[1];
                if (setting[0] === 'Comprehension') {
                    // this.state.qsToggle[key] = true;
                    if (!firstNoAnswer)
                        qsToggle[key] = true;
                }
                else if (setting[0] === 'Subjective') {
                    if (isDone) {
                        qsToggle[key] = true;
                    }
                    else {

                        if (!firstNoAnswer)
                            qsToggle[qsStart - 1] = true;

                        if (!this.state.qsHasAnswer[qsStart - 1] && !firstNoAnswer) {
                            nextQsToAnswer = qsStart;
                            firstNoAnswer = true;
                        }
                    }
                }
                else {
                    if (key <= qsEnd - 1) {
                        // this.state.nextQsToAnswer = qsStart;
                        // if (this.state.qsHasAnswer[qsStart - 1])
                        //     this.state.qsToggle[qsStart - 1] = true;
                        // else
                        //     this.state.qsToggle[qsStart - 1] = isDone;
                        // this.state.qsToggle[qsStart - 1] = true;

                        if (!firstNoAnswer)
                            qsToggle[qsStart - 1] = true;

                        if (!this.state.qsHasAnswer[qsStart - 1] && !firstNoAnswer) {
                            // this.state.nextQsToAnswer = qsStart;
                            nextQsToAnswer = qsStart;
                            firstNoAnswer = true;
                            // endLoop = true;
                        }
                        // this.state.nextQsToAnswer = qsEnd;
                    }
                }
                // this.state.qsNotActiveStat[key] = isDone;
                qsNotActiveStat[key] = isDone;
            }

            if (key < this.state.qsSelectedAnswers.length && !endLoop && !firstNoAnswer)
                if (String(this.state.qsSelectedAnswers[key]) === '-1') {
                    endLoop = true;
                    // this.state.nextQsToAnswer++;
                    nextQsToAnswer++;
                }
            // }
            return null;
        });
        // alert("SetFinalResultToggle : " + this.state.nextQsToAnswer);

        this.setState({
            qsToggle: qsToggle,
            qsNotActiveStat: qsNotActiveStat,
            nextQsToAnswer: nextQsToAnswer,
        });
    }

    //2024.05.23 checked - obsolete codes
    //#region old codes
    // CheckAndLoadSavedQsResults = async () => {
    //     // if (!this.state.isQuizDoneAnswered) {
    //     let isSuccess = false;

    //     //Load QResults.
    //     await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.QResults))
    //         .once('value', snapshot => {
    //             if (snapshot !== null) {
    //                 let qsResults = String(snapshot.val()).split(":");

    //                 let qsAnswers = this.state.qsAnswers;
    //                 this.state.qsAnswers.map((data, key) => {
    //                     // this.state.qsAnswers[key] = qsResults[key] === "1" ? true : false;
    //                     return qsAnswers[key] = (qsResults[key] === "1" ? true : false);
    //                 });

    //                 //Update Saved Qs Results.
    //                 this.setState({
    //                     // qsAnswers: this.state.qsAnswers,
    //                     qsAnswers: qsAnswers,
    //                 });
    //                 // this.props.SetAlert("", JSON.stringify(this.state.qsAnswers));

    //                 isSuccess = true;
    //             }
    //         })
    //         .catch(async (error) => {
    //             // this.props.SetAlert("Error", error.code + "<br />" + error.message);
    //             await this.props.SetErrorLog(new Date(), "CheckAndLoadSavedQsResults | Get QResults | Error = " + error);
    //         });

    //     return isSuccess;
    //     // }
    // }
    // CheckAndLoadSavedQsSelectsAndSetQsToggle = async () => {
    //     let isSuccess = false;

    //     // if (!this.state.isQuizDoneAnswered) {
    //     //Load ASelects.
    //     await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.ASelects))
    //         .once('value')
    //         .then((snapshot) => {

    //             //2023.10.06
    //             if (snapshot.exists()) {
    //                 this.PopulateSavedQsSelectsAndSetQsToggle(snapshot.val());
    //             }
    //             //#region old codes - before 2023.10.06
    //             // let ansText1 = '';
    //             // let ansText2 = '';
    //             // let qsQtyAnswered = 0;
    //             // let qsASelects = [];

    //             // let qsSelectedAnswers = this.state.qsSelectedAnswers;
    //             // let qsNotActiveStat = this.state.qsNotActiveStat;
    //             // let nextQsToAnswer = this.state.nextQsToAnswer;
    //             // let qsHasAnswer = this.state.qsHasAnswer;
    //             // let qsAnswers = this.state.qsAnswers;
    //             // let qsAnswers_cloned = [...qsAnswers];  // JSON.parse(JSON.stringify(this.state.qsAnswers));
    //             // // let qsAnswers_cloned = this.state.qsAnswers;
    //             // // console.log(qsAnswers !== qsAnswers_cloned);
    //             // // console.log(JSON.stringify(qsAnswers));
    //             // // console.log(JSON.stringify(qsAnswers_cloned));

    //             // if (snapshot.exists()) {
    //             //     qsASelects = String(snapshot.val()).split(":");

    //             //     // alert(qsASelects);

    //             //     // this.state.qsSelectedAnswers.map((data, key) => {
    //             //     qsSelectedAnswers.map((data, key) => {
    //             //         // this.state.qsSelectedAnswers[key] = qsASelects[key];
    //             //         qsSelectedAnswers[key] = qsASelects[key];

    //             //         //to show all previous qs directly.
    //             //         // if (this.state.qsSelectedAnswers[key] === "-1") {
    //             //         if (qsSelectedAnswers[key] === "-1") {
    //             //             if (this.state.nextQsToAnswer <= 0) {
    //             //                 // this.state.nextQsToAnswer = key;
    //             //                 nextQsToAnswer = key;
    //             //             }
    //             //             // this.state.qsLeftToAnswer.push({    //added 2020.11.04
    //             //             //     No: key + 1,
    //             //             //     Key: key
    //             //             // });
    //             //         }
    //             //         else {
    //             //             // this.state.qsNotActiveStat[key] = true;
    //             //             // this.state.qsHasAnswer[key] = true;
    //             //             qsNotActiveStat[key] = true;
    //             //             qsHasAnswer[key] = true;
    //             //             qsQtyAnswered++;

    //             //             //2021.09.02
    //             //             let startNo = 1;
    //             //             let index = 0;
    //             //             // let _answer = String(this.state.roomQuestions[key].Answer);
    //             //             let _answer = '';
    //             //             let _selectedAnswer = '';
    //             //             if (this.state.roomQuestions[key].hasOwnProperty('SpecialMode')) {
    //             //                 if (this.state.roomQuestions[key].SpecialMode.includes('FillInTheBlanks')) {
    //             //                     let settings = String(this.state.roomQuestions[key].SpecialMode).split(';');
    //             //                     startNo = Number(settings[1].split(',')[0]) - 1;
    //             //                     index = key - startNo;
    //             //                     _answer = String(this.state.roomQuestions[startNo].Answer).split(';')[index];
    //             //                     _selectedAnswer = String(String(this.state.roomQuestions[startNo].Selection).split(';')[qsSelectedAnswers[key]]).split(':')[0];
    //             //                 }
    //             //                 else {
    //             //                     //Comprehension or Subjective.
    //             //                     _answer = String(this.state.roomQuestions[key].Answer);
    //             //                     _selectedAnswer = String(String(this.state.roomQuestions[key].Selection).split(';')[qsSelectedAnswers[key]]).split(':')[0];
    //             //                     // index = AtoZ.findIndex(x => x === _answer);
    //             //                 }
    //             //             }
    //             //             else {
    //             //                 //normal question.
    //             //                 _answer = String(this.state.roomQuestions[key].Answer);
    //             //                 _selectedAnswer = String(String(this.state.roomQuestions[key].Selection).split(';')[qsSelectedAnswers[key]]).split(':')[0];
    //             //                 // index = AtoZ.findIndex(x => x === _answer);
    //             //             }

    //             //             qsAnswers[key] = _answer === _selectedAnswer;
    //             //             // console.log('#' + (key + 1) + ' = ' + qsAnswers[key]);

    //             //             ansText1 += (key + 1) + ':' + _answer + ';';
    //             //             ansText2 += AtoZ.findIndex(x => x === _answer) + ':';
    //             //         }

    //             //         return null;
    //             //     });
    //             // }
    //             // else {
    //             //     //set default values if no previous record found.
    //             //     qsSelectedAnswers.map((data, key) => {
    //             //         // this.state.qsSelectedAnswers[key] = qsASelects[key];
    //             //         qsSelectedAnswers[key] = '-1';

    //             //         //to show all previous qs directly.
    //             //         if (qsSelectedAnswers[key] === '-1') {
    //             //             if (this.state.nextQsToAnswer <= 0) {
    //             //                 nextQsToAnswer = key;
    //             //             }
    //             //             // this.state.qsLeftToAnswer.push({    //added 2020.11.04
    //             //             //     No: key + 1,
    //             //             //     Key: key
    //             //             // });
    //             //         }
    //             //         else {
    //             //             qsNotActiveStat[key] = true;
    //             //             qsHasAnswer[key] = true;
    //             //             qsQtyAnswered++;
    //             //         }

    //             //         return null;
    //             //     });
    //             // }

    //             // //2021.09.06
    //             // let _isScoreRecordNeedUpdate = false;
    //             // for (let k = 0; k < qsAnswers_cloned.length; k++) {
    //             //     if (qsAnswers_cloned[k] !== qsAnswers[k]) {
    //             //         _isScoreRecordNeedUpdate = true;
    //             //         break;
    //             //     }
    //             // }
    //             // if (this.props.isDevMode) {
    //             //     console.log('Correct Answers = ' + ansText1);
    //             //     console.log('\n' + ansText2);
    //             // }

    //             // //Update Saved Qs Selects.
    //             // this.setState({
    //             //     // qsNotActiveStat: this.state.qsNotActiveStat,
    //             //     // qsHasAnswer: this.state.qsHasAnswer,
    //             //     // qsSelectedAnswers: this.state.qsSelectedAnswers,
    //             //     // nextQsToAnswer: this.state.nextQsToAnswer,
    //             //     qsQtyToAnswer: this.state.roomInfoModal.QnQty - qsQtyAnswered,
    //             //     // qsLeftToAnswer: this.state.qsLeftToAnswer,  //added 2020.11.04
    //             //     qsNotActiveStat: qsNotActiveStat,
    //             //     qsHasAnswer: qsHasAnswer,
    //             //     qsSelectedAnswers: qsSelectedAnswers,
    //             //     nextQsToAnswer: nextQsToAnswer,

    //             //     //2021.09.03
    //             //     // isScoreRecordNeedUpdate: qsAnswers !== qsAnswers_cloned,
    //             //     isScoreRecordNeedUpdate: _isScoreRecordNeedUpdate,

    //             //     //2021.09.02
    //             //     qsAnswers: qsAnswers,
    //             //     isQuizCorrectAnswerRechecked: true,
    //             // }, () => {
    //             //     // console.log(qsAnswers !== qsAnswers_cloned);
    //             //     // console.log(JSON.stringify(qsAnswers));
    //             //     // console.log(JSON.stringify(qsAnswers_cloned));
    //             // });
    //             // // this.props.SetAlert("", JSON.stringify(this.state.qsSelectedAnswers));
    //             //#endregion

    //             isSuccess = true;
    //         })
    //         .catch(async (error) => {
    //             // this.props.SetAlert("Error", error.code + "<br />" + error.message);
    //             await this.props.SetErrorLog(new Date(), "CheckAndLoadSavedQsSelectsAndSetQsToggle | Get ASelects | Error = " + error);
    //         });

    //     return isSuccess;

    //     //Update QsToggle stat.
    //     // let endLoop = false;
    //     // this.state.qsToggle.map((data, key) => {
    //     //     if (!endLoop) {
    //     //         if (!this.state.roomQuestions[key].hasOwnProperty("SpecialMode")) {
    //     //             if (key < this.state.nextQsToAnswer) {
    //     //                 this.state.qsToggle[key] = true;
    //     //                 endLoop = true;
    //     //             }
    //     //         }
    //     //         else {
    //     //             if (this.state.qsSelectedAnswers[key] == "-1") {
    //     //                 let setting = this.state.roomQuestions[key].SpecialMode.split(";");
    //     //                 let range = setting[1].split(",");
    //     //                 let qsStart = range[0];
    //     //                 let qsEnd = range[1];
    //     //                 if (key >= qsStart - 1 && key <= qsEnd - 1) {
    //     //                     // if (key < this.state.nextQsToAnswer - 1) {
    //     //                     this.state.qsToggle[qsStart - 1] = true;
    //     //                     this.state.nextQsToAnswer = qsStart;
    //     //                     endLoop = true;
    //     //                 }                            
    //     //             }
    //     //         }
    //     //     }
    //     // });
    //     // this.setState({
    //     //     qsToggle: this.state.qsToggle,
    //     //     nextQsToAnswer: this.state.nextQsToAnswer,
    //     // }, () => {
    //     //     // alert(this.state.nextQsToAnswer);
    //     // });
    //     // }
    // }
    //#endregion

    PopulateSavedQsSelectsAndSetQsToggle = async (ASelectList = [], snapshot_qsSelects = '', qsNoOrder = '') => {
        let ansText1 = [];
        let ansText2 = [];
        let qsQtyAnswered = 0;
        let qsASelects = [];

        const roomInfoModal = this.state.roomInfoModal;
        let qsSelectedAnswers = this.state.qsSelectedAnswers;
        let qsNotActiveStat = this.state.qsNotActiveStat;
        let nextQsToAnswer = this.state.nextQsToAnswer;
        let qsHasAnswer = this.state.qsHasAnswer;
        let qsAnswers = this.state.qsAnswers;
        // let qsAnswers_cloned = JSON.parse(JSON.stringify(this.state.qsAnswers));
        let qsAnswers_cloned = JSON.stringify(this.state.qsAnswers);
        // let qsAnswers_cloned = Object.assign({}, this.state.qsAnswers);
        // console.log(qsAnswers !== qsAnswers_cloned);
        // console.log(JSON.stringify(qsAnswers));
        // console.log(JSON.stringify(qsAnswers_cloned));

        //2024.05.23 - revamped, added ASelectList.
        if (ASelectList.length > 0) {
            for (let key = 0; key < ASelectList.length; key++) {
                const aSelect = CheckObjectStringEmpty(ASelectList[key], 'Index', '-1');
                qsSelectedAnswers[key] = aSelect;
                if (aSelect === '-1') {
                    qsAnswers[key] = false;
                    if (this.state.nextQsToAnswer <= 0)
                        nextQsToAnswer = key;
                }
                else {
                    qsNotActiveStat[key] = true;
                    qsHasAnswer[key] = true;
                    qsQtyAnswered++;
                    //Objectives.
                    const _answer = CheckObjectStringEmpty(this.state.roomQuestions[key], 'Answer');
                    // const _answerOptions = CheckObjectStringEmpty(this.state.roomQuestions[key], 'Selection');
                    // const _selectedAnswer = _answerOptions.split(';').filter(x => CheckNullValue(x) !== null)[Number(qsSelectedAnswers[key])].split(':')[0];
                    const _selectedAnswer = CheckObjectStringEmpty(ASelectList[key], 'Value');
                    qsAnswers[key] = _answer === '' ? false : _answer === _selectedAnswer;
                    ansText1.push((key + 1) + ':' + _answer);
                    // ansText2.push(AtoZ.findIndex(x => x === _answer));
                    ansText2.push(_selectedAnswer);
                }
            }
        }
        else if (CheckStringEmpty(snapshot_qsSelects) !== '') {
            qsASelects = String(snapshot_qsSelects).split(":");
            // qsSelectedAnswers.map((data, key) => {
            // for (let key = 0; key < qsSelectedAnswers.length; key++) {
            for (let key = 0; key < roomInfoModal.QnQty; key++) {

                //2023.12.13
                if (CheckNullValue(qsASelects[key]) === null) {
                    qsASelects[key] = '-1';
                    qsAnswers[key] = false;
                }
                if (CheckNullValue(qsAnswers[key]) === null) {
                    qsAnswers[key] = false;
                }

                qsSelectedAnswers[key] = qsASelects[key];

                //to show all previous qs directly.
                if (qsSelectedAnswers[key] === "-1") {
                    if (this.state.nextQsToAnswer <= 0) {
                        nextQsToAnswer = key;
                    }
                }
                else {
                    qsNotActiveStat[key] = true;
                    qsHasAnswer[key] = true;
                    qsQtyAnswered++;

                    //2021.09.02
                    let startNo = 1;
                    let index = 0;
                    // let _answer = String(this.state.roomQuestions[key].Answer);
                    let _answer = '';
                    let _selectedAnswer = '';
                    if (this.state.roomQuestions[key].hasOwnProperty('SpecialMode')) {
                        if (this.state.roomQuestions[key].SpecialMode.includes('FillInTheBlanks')) {
                            let settings = String(this.state.roomQuestions[key].SpecialMode).split(';');
                            startNo = Number(settings[1].split(',')[0]) - 1;
                            index = key - startNo;
                            _answer = String(this.state.roomQuestions[startNo].Answer).split(';')[index];
                            _selectedAnswer = String(String(this.state.roomQuestions[startNo].Selection).split(';')[Number(qsSelectedAnswers[key])]).split(':')[0];
                        }
                        else {
                            //Comprehension or Subjective.
                            _answer = String(this.state.roomQuestions[key].Answer);
                            _selectedAnswer = String(String(this.state.roomQuestions[key].Selection).split(';')[Number(qsSelectedAnswers[key])]).split(':')[0];
                            // index = AtoZ.findIndex(x => x === _answer);
                        }
                    }
                    else {
                        //normal question.
                        _answer = CheckObjectStringEmpty(this.state.roomQuestions[key], 'Answer');
                        _selectedAnswer = String(String(this.state.roomQuestions[key].Selection).split(';')[Number(qsSelectedAnswers[key])]).split(':')[0];
                        // index = AtoZ.findIndex(x => x === _answer);
                    }

                    // //2023.10.09
                    // if (CheckObjectBoolean(this.state.roomInfoModal, 'RandomQuestionMode')) {
                    //     if (CheckNullValue(qsNoOrder) !== null) {
                    //         const _qsNoOrder = String(qsNoOrder).split(":");
                    //         const _qs_index = this.state.roomQuestions.findIndex(x => String(x.No) === _qsNoOrder[key]);
                    //         if (_qs_index > -1) {
                    //             _answer = String(this.state.roomQuestions[_qs_index].Answer);
                    //             _selectedAnswer = String(String(this.state.roomQuestions[_qs_index].Selection).split(';')[qsSelectedAnswers[key]]).split(':')[0];
                    //         }
                    //     }
                    // }

                    qsAnswers[key] = _answer === _selectedAnswer;
                    // console.log('#' + (key + 1) + ' = ' + qsAnswers[key]);

                    ansText1.push((key + 1) + ':' + _answer);
                    ansText2.push(AtoZ.findIndex(x => x === _answer));
                }
            }
            // return null;
            // });
        }


        //set default values if no previous record found.
        if (ASelectList.length === 0 && CheckStringEmpty(snapshot_qsSelects) === '') {
            qsSelectedAnswers.map((data, key) => {
                // this.state.qsSelectedAnswers[key] = qsASelects[key];
                qsSelectedAnswers[key] = '-1';
                //to show all previous qs directly.
                if (qsSelectedAnswers[key] === '-1') {
                    if (this.state.nextQsToAnswer <= 0)
                        nextQsToAnswer = key;
                }
                else {
                    qsNotActiveStat[key] = true;
                    qsHasAnswer[key] = true;
                    qsQtyAnswered++;
                }
                return null;
            });
        }

        const _isScoreRecordNeedUpdate = qsAnswers_cloned !== JSON.stringify(qsAnswers);
        // //2021.09.06
        // let _isScoreRecordNeedUpdate = false;
        // for (let k = 0; k < qsAnswers_cloned.length; k++) {
        //     if (String(qsAnswers_cloned[k]) !== String(qsAnswers[k])) {
        //         _isScoreRecordNeedUpdate = true;
        //         break;
        //     }
        // }
        if (this.props.isDevMode) {
            console.log('Correct Answers = ', ansText1.join(';'));
            console.log('Selected Answers = ', ansText2.join(':'));
            console.log('Need Update ScoreRecord = ' + _isScoreRecordNeedUpdate, qsAnswers_cloned, JSON.stringify(qsAnswers));
        }

        //Update Saved Qs Selects.
        this.setState({
            qsQtyToAnswer: this.state.roomInfoModal.QnQty - qsQtyAnswered,
            qsNotActiveStat: qsNotActiveStat,
            qsHasAnswer: qsHasAnswer,
            qsSelectedAnswers: qsSelectedAnswers,
            nextQsToAnswer: nextQsToAnswer,

            //2021.09.03
            isScoreRecordNeedUpdate: _isScoreRecordNeedUpdate,

            //2021.09.02
            qsAnswers: qsAnswers,
            isQuizCorrectAnswerRechecked: true,
        });
        // this.props.SetAlert("", JSON.stringify(this.state.qsSelectedAnswers));
    }

    CheckAndLoadSavedQsScoreRecord = async () => {
        let isSuccess = false;
        // if (!this.state.isQuizDoneAnswered) {
        //Load Score & Time elapsed.
        await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.ScoreRecord))
            .once('value', snapshot => {
                let _savedScore = 0;
                let _elapsedTime = 0;

                if (snapshot.exists()) {
                    let val = String(snapshot.val());
                    let records = val.split(';');
                    // alert(snapshot.val());
                    // alert(JSON.stringify(records));
                    // _savedScore = val !== 'null' ? Number(records[0]) : 0;
                    // _elapsedTime = val !== 'null' ? Number(records[1]) : 0;
                    _savedScore = Number(records[0]) > 0 ? Number(records[0]) : 0;
                    _elapsedTime = Number(records[1]) > 0 ? Number(records[1]) : 0;

                    // alert(String(savedScore) + "\n" + String(elapsedTime));
                }

                //Update Saved Qs Selects.
                this.setState({
                    // savedScore: this.state.savedScore,
                    savedScore: _savedScore,
                    elapsedTime: _elapsedTime,
                    loadedElapsedTime: _elapsedTime,
                }, () => {
                    // alert(records[1]);
                    // alert(elapsedTime + " | " + records[1]);
                });
                isSuccess = true;
            })
            .catch(async (error) => {
                // this.props.SetAlert("Error", error.code + "<br />" + error.message);
                await this.props.SetErrorLog(new Date(), "CheckAndLoadSavedQsScoreRecord | Get ScoreRecord | Error = " + error);
            });
        // }
        return isSuccess;
    }

    handleStartQuiz = async () => {
        // this.state.qsToggle[0] = true;
        const currentTime = moment();
        let qsToggle = this.state.qsToggle;
        qsToggle[0] = true;

        //2022.06.16
        let roomInfoModal = this.state.roomInfoModal;
        roomInfoModal.AccessDurationEndTime = currentTime.clone()
            .add(Number(roomInfoModal.Duration), 'seconds')     //full length.
            .add(-this.state.loadedElapsedTime, 'seconds');     //minus back used time.
        // .format('YYYY-MM-DD HH:mm:ss');
        if (this.props.isDevMode)
            console.log('Updated Access Time = \n _timeStart = ' + roomInfoModal.AccessBeginTime.format('YYYY-MM-DD HH:mm:ss')
                + '\n _durationEndTime = ' + roomInfoModal.AccessDurationEndTime.format('YYYY-MM-DD HH:mm:ss')
                + '\n _timeEnd = ' + roomInfoModal.AccessEndTime.format('YYYY-MM-DD HH:mm:ss'));

        this.setState({
            isQuizStarted: true,
            // qsToggle: this.state.qsToggle,
            qsToggle: qsToggle,
            // elapsedTime: 0.0,
            ctTimerActive: true,
            // nextQsToAnswer: 0,

            userStartTime: currentTime.format('YYYY-MM-DD HH:mm:ss'),

            roomInfoModal: roomInfoModal,   //2022.06.16
        });
        // }, async () => {
        // let scrollToPos = document.getElementById("quiz-pos-q-0").getBoundingClientRect().top - 100;
        // window.scrollTo({ top: scrollToPos, behavior: 'smooth' })
        // window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
        // alert(this.state.nextQsToAnswer);
        await Delay(200);

        //Log - Start Quiz
        await this.SetQuizStartedLog();


        let nextNum = this.state.nextQsToAnswer - 1;
        if (nextNum < 0)
            nextNum = 0;
        // alert(nextNum);

        // if (this.state.isQuizDoneAnswered)
        //     nextNum = "result";

        // let msDelay = 1000;
        let qsQtyAnswered = this.state.roomInfoModal.QnQty - this.state.qsQtyToAnswer;
        // alert(qsQtyAnswered);
        // switch (qsQtyAnswered) {
        //     // case (qsQtyAnswered <= this.state.roomInfoModal.QnQty): msDelay = 1000; break;
        //     default: msDelay = 1000; break;
        //     case (qsQtyAnswered <= 30): msDelay = 500; break;
        //     case (qsQtyAnswered <= 20): msDelay = 700; break;
        //     case (qsQtyAnswered <= 10): msDelay = 1000; break;
        // }
        if (qsQtyAnswered <= 10)
            qsQtyAnswered = 10;
        let msDelay = Number((qsQtyAnswered / 10).toFixed(0)) * 425;

        // if (msDelay <= 1000)
        //     msDelay = 1000;

        // alert("quiz-pos-q-" + nextNum);
        // if (!this.state.isQuizDoneAnswered)
        //     nextNum = 0;

        let endLoop = false;
        this.state.extraContent.map((data, key) => {
            if (!endLoop)
                if (data.Mode !== "Comprehension") {
                    if (nextNum >= Number(data.QsStart) && nextNum < Number(data.QsEnd)) {
                        nextNum = Number(data.QsStart) - 1;
                        endLoop = true;
                    }
                }
                else {
                    // nextNum--;
                    endLoop = true;
                }
            return null;
        });
        // alert("quiz-pos-q-" + nextNum + " | delay : " + msDelay);

        // alert(this.state.todayDT + " | " + moment().format("YYYYMMDD"));


        // document.getElementById("quiz-pos-q-" + this.state.nextQsToAnswer).scrollIntoView({ behavior: 'smooth' });
        // if (this.state.todayDT >= moment().format("YYYYMMDD"))
        //     setTimeout(() => {
        //         // document.getElementById("quiz-pos-q-" + nextNum).scrollIntoView({ behavior: "smooth" });
        //         ScrollToElement("quiz-pos-q-" + nextNum);
        //     }, msDelay);

        //2021.07.16
        let dateToCheck = '';
        if (this.state.roomInfoModal.hasOwnProperty('DateEnd'))
            dateToCheck = moment(this.state.roomInfoModal.DateEnd).format("YYYYMMDD");
        else
            dateToCheck = this.state.todayDT;
        if (Number(dateToCheck) >= Number(currentTime.format("YYYYMMDD")))
            setTimeout(() => {
                // document.getElementById("quiz-pos-q-" + nextNum).scrollIntoView({ behavior: "smooth" });
                ScrollToElement("quiz-pos-q-" + nextNum);
            }, msDelay);
        // });
    }

    // handleScrollToPos = (id) => {
    //     // let scrollToPos = document.getElementById(id).getBoundingClientRect().top;
    //     // window.scrollTo({ top: scrollToPos, behavior: 'smooth' });
    //     // document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    //     // document.getElementById("quiz-pos-q-" + id).scrollIntoView({ behavior: 'smooth' });
    //     ScrollToElement("quiz-pos-q-" + id);
    // }

    // handleScrollToBottom = () => {
    //     window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    // }

    checkQuizComplete = async () => {
        let done = false;
        let _isAllAnswered = true;
        let _qsQtyToAnswer = 0;
        let _qsLeftToAnswer = [];
        // const randomQuestionMode = CheckObjectBoolean(roomInfo, 'RandomQuestionMode');
        this.state.qsHasAnswer.map((data, key) => {
            if (CheckBoolean(data) === false) {
                _isAllAnswered = false;
                _qsQtyToAnswer++;

                //2023.10.09
                // let _no = key + 1;
                // if (randomQuestionMode) {
                //     const _no_idx = this.state.roomQuestions.findIndex(x => String(x.No) === String(key + 1));
                //     if (_no_idx > -1)
                //         _no = this.state.roomQuestions[_no_idx].No;
                // }

                _qsLeftToAnswer.push({    //added 2020.11.04
                    No: key + 1,
                    // No: _no,
                    Key: key
                });
            }
            return null;
        });
        this.setState({
            qsQtyToAnswer: _qsQtyToAnswer,
            isAllAnswered: _isAllAnswered,
            qsLeftToAnswer: _qsLeftToAnswer,  //added 2020.11.04
        });
        if (_isAllAnswered) {
            console.log('Check Quiz Complete', 'Set Event Participation');
            done = await this.SetParticipationOnEvent();   //2022.06.15
            await DelayUntil(() => done === true);
        }
        return true;
    }

    handleNextQuestion = async (_index, _focus) => {
        let index = Number(_index);
        // let focusTarget = String(_focus);

        // this.state.qsToggle[index] = true;
        let qsToggle = this.state.qsToggle;
        qsToggle[index] = true;

        this.setState({
            // qsToggle: this.state.qsToggle,
            qsToggle: qsToggle,
            nextQsToAnswer: index,
        });

        // await Delay(500);
        // this.props.CloseAlert();
        // await Delay(500);

        if (this.state.isAllAnswered && !this.state.isQuizDoneAnswered) {
            this.state.qsToggle.map((data, key) => {
                let arrayObj = Object.assign({}, this.state.roomQuestions[key]);    //2020.12.14
                if (arrayObj.hasOwnProperty("Answer"))
                    qsToggle[key] = true;
                return null;
            });
            this.setState({
                qsToggle: qsToggle,
            }, () => {
                ScrollToElement("quiz-pos-q-" + this.state.roomInfoModal.QnQty);
                if (_focus !== null)
                    document.getElementById(_focus).focus();
            });
        }
        else {
            ScrollToElement("quiz-pos-q-" + index);
            if (_focus !== null)
                document.getElementById(_focus).focus();
        }

        //#region old codes
        // },
        //     () => {
        //         // alert("quiz-pos-q-" + index);

        //         setTimeout(() => {
        //             // document.getElementById("quiz-pos-q-" + index).scrollIntoView({ behavior: 'smooth' });
        //             // if (this.state.isAllAnswered && !this.state.isQuizDoneAnswered)
        //             //     document.getElementById("quiz-pos-q-" + this.state.roomInfoModal.QnQty).scrollIntoView({ behavior: 'smooth' });
        //             // else
        //             //     document.getElementById("quiz-pos-q-" + index).scrollIntoView({ behavior: 'smooth' });
        //             let qsToggle = this.state.qsToggle;
        //             if (this.state.isAllAnswered && !this.state.isQuizDoneAnswered) {
        //                 this.state.qsToggle.map((data, key) => {
        //                     // return this.state.qsToggle[key] = true;
        //                     // if (Array(this.state.roomQuestions[key]).hasOwnProperty("Answer"))
        //                     // let arrayObj = JSON.parse(JSON.stringify(this.state.roomQuestions[key]));   //2020.12.11 = change conversion method.
        //                     let arrayObj = Object.assign({}, this.state.roomQuestions[key]);    //2020.12.14
        //                     if (arrayObj.hasOwnProperty("Answer"))
        //                         qsToggle[key] = true;
        //                     return null;
        //                 });
        //                 this.setState({
        //                     // qsToggle: this.state.qsToggle,
        //                     qsToggle: qsToggle,
        //                 }, () => {
        //                     // ScrollToElement("quiz-pos-q-" + this.state.roomInfoModal.QnQty);

        //                     //2020.12.14
        //                     // if (_focus !== null) {
        //                     //     setTimeout(() => {
        //                     //         document.getElementById(focusTarget).focus();
        //                     //     }, 1000);
        //                     // }
        //                     // else {
        //                     //     ScrollToElement("quiz-pos-q-" + this.state.roomInfoModal.QnQty);
        //                     // }
        //                     ScrollToElement("quiz-pos-q-" + this.state.roomInfoModal.QnQty);
        //                     if (_focus !== null)
        //                         document.getElementById(_focus).focus();
        //                 });
        //             }
        //             else {
        //                 // ScrollToElement("quiz-pos-q-" + index);

        //                 //2020.12.14
        //                 // if (_focus !== null) {
        //                 //     setTimeout(() => {
        //                 //         document.getElementById(focusTarget).focus();
        //                 //     }, 1000);
        //                 // }
        //                 // else {
        //                 //     ScrollToElement("quiz-pos-q-" + index);
        //                 // }
        //                 ScrollToElement("quiz-pos-q-" + index);
        //                 if (_focus !== null)
        //                     document.getElementById(_focus).focus();
        //             }
        //         }, 200);

        //         // if (index < this.state.roomInfoModal.QnQty) {
        //         //     // if (this.state.qsHasAnswer[index] === false)
        //         //     setTimeout(() => {
        //         //         // document.getElementById("quiz-pos-q-" + index).scrollIntoView({ behavior: 'smooth' });
        //         //         if (this.state.isAllAnswered && !this.state.isQuizDoneAnswered)
        //         //             document.getElementById("quiz-pos-q-" + this.state.roomInfoModal.QnQty).scrollIntoView({ behavior: 'smooth' });
        //         //         else
        //         //             document.getElementById("quiz-pos-q-" + index).scrollIntoView({ behavior: 'smooth' });
        //         //     }, 200);
        //         // }
        //         // window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
        //     });
        //#endregion
    }

    handleSetAnswer = async (_result, _qidx, _ansIdx) => {
        // this.state.qsAnswers[qidx] = this.state.qsAnswers[qidx] ? true : result;
        // this.state.qsHasAnswer[qidx] = true;
        // this.state.qsSelectedAnswers[qidx] = ansIdx;

        //2023.10.18
        this.setState({
            SaveOrSubmitInProgress: true,
        });

        //2022.06.15
        const result = CheckBoolean(_result);
        const qidx = Number(_qidx);
        const ansIdx = Number(_ansIdx);

        //2024.05.23 - save a sub-copy in RoomResultRootData. - start -
        let roomResultRootData = this.state.RoomResultRootData;
        if (roomResultRootData.ASelectList.length > 0) {
            if (qidx < roomResultRootData.ASelectList.length) {
                let aSelectList = roomResultRootData.ASelectList;
                if (CheckObjectNumber(aSelectList[qidx], 'No') === CheckObjectNumber(this.state.roomQuestions[qidx], 'No')) {
                    aSelectList[qidx]['Index'] = ansIdx;
                    aSelectList[qidx]['Value'] = AtoZ[ansIdx];
                    roomResultRootData.ASelectList = aSelectList;
                    this.setState({ RoomResultRootData: roomResultRootData, });
                    if (this.props.isDevMode)
                        console.log('handleSetAnswer > aSelectList =\n' + JSON.stringify(aSelectList));
                }
            }
        }
        //2024.05.23 - save a sub-copy in RoomResultRootData. - end -

        let _qsAnswers = this.state.qsAnswers;
        let _qsHasAnswer = this.state.qsHasAnswer;
        let _qsSelectedAnswers = this.state.qsSelectedAnswers;

        //2020.12.10
        let _finalResult = result;
        if (
            !this.state.roomQuestions[qidx].hasOwnProperty('SpecialMode')
            && this.state.roomQuestions[qidx]['QuizType'] !== 'Subjective'  //2020.12.18
        ) {
            const _actualAnswer = CheckObjectStringEmpty(this.state.roomQuestions[qidx], 'Answer');
            const _providedAnswers = CheckObjectStringEmpty(this.state.roomQuestions[qidx], 'Selection').split(';');
            const _Result = _actualAnswer === _providedAnswers[ansIdx].split(':')[0];
            if (_Result !== result)
                _finalResult = _Result;
        }

        // qsAnswers[qidx] = qsAnswers[qidx] ? true : result;
        // qsAnswers[qidx] = result;    //2020.12.03
        _qsAnswers[qidx] = _finalResult;    //2020.12.10

        _qsHasAnswer[qidx] = true;
        _qsSelectedAnswers[qidx] = String(ansIdx);

        this.setState({
            // qsAnswers: this.state.qsAnswers,
            // qsHasAnswer: this.state.qsHasAnswer,
            // qsSelectedAnswers: this.state.qsSelectedAnswers,
            qsAnswers: _qsAnswers,
            qsHasAnswer: _qsHasAnswer,
            qsSelectedAnswers: _qsSelectedAnswers,
            qsLeftToAnswer: [],     //reset
        });
        // },
        //     async () => {
        //         //Update Ui.
        //         await this.checkQuizComplete();

        //         //Update QResult in Firebase.
        //         await this.SaveQsResults();
        //     });
        // this.props.SetAlert("Result for Question " + (qidx+1), (result ? "Correct" : "Wrong"));

        //2022.06.15
        this.props.SetLoading('', 'saving answer...', false);

        //Update QResult in Firebase.
        const saveDone = await this.SaveQsResults();
        await DelayUntil(() => saveDone === true);

        //Update Ui.
        const checkDone = await this.checkQuizComplete();
        await DelayUntil(() => checkDone === true);

        //2023.11.09
        await Delay(300);
        if (this.state.isAllAnswered) {
            console.log('SetAnswer', 'Set IsDone');
            //Set IsDone.
            await this.Firebase_SetIsDone_True();
            await this.Firebase_SetIsDoneOnUtc();
        }

        //disabled by Calvin request 2023.08.07
        // //2023.07.05
        // if (this.state.isAllAnswered) {
        //     let submitDone = await this.handleSubmitPaper();
        //     await DelayUntil(() => submitDone === true);
        // }

        //close loading modal.
        this.props.CloseAlert();

        //2023.10.18
        this.setState({
            SaveOrSubmitInProgress: false,
        }, () => {
            //close loading modal - backup.
            this.props.CloseAlert();
        });

        return true;
    }

    SaveQsResults = async () => {

        //2022.06.15 === minor revamped.
        // let retryAgain = false;
        // let done = false;
        const { ASelects } = this.GetSelectedAnswer();
        const { QScore, QResults } = this.Get_Score_CorrectQty_QResult();
        const elapsedTime = this.GetElapsedTime();
        if (this.props.isDevMode)
            console.log('SaveQsResults = ' + elapsedTime);

        //2024.05.23 - sub-copy of ASelectsList in RoomResultRootData - start -
        const { subCopy_ASelects, subCopy_QResults, subCopy_QScores, subCopy_isAllAnswered } = this.GetASelectsListDataFromRoomResultRootData();
        let final_ASelects = ASelects;
        let final_QResults = QResults;
        let final_QScores = QScore;
        let final_isDone = this.state.isAllAnswered;
        if (ASelects !== subCopy_ASelects) {
            final_ASelects = subCopy_ASelects;
            final_QResults = subCopy_QResults;
            final_QScores = subCopy_QScores;
            final_isDone = subCopy_isAllAnswered;
        }
        if (final_isDone) {
            this.setState({ isAllAnswered: true, });
        }
        //2024.05.23 - sub-copy in RoomResultRootData - end -

        //2023.10.09 - revamped/simplified.
        let updates = {};
        if (!this.state.isQuizDoneAnswered)
            updates[`${'/ASelects'}`] = final_ASelects;
        if (!this.state.isQuizDoneAnswered || this.state.isScoreRecordNeedUpdate)
            updates[`${'/QResults'}`] = final_QResults;
        if (this.state.isAllAnswered) {
            updates[`${'/IsDone'}`] = final_isDone;
            updates[`${'/Score'}`] = final_QScores;
            updates[`${'/ElapsedTime'}`] = elapsedTime.toFixed(3);

            //2023.10.20
            if (CheckNullValue(this.state.isDoneOnUtc) === null) {
                const IsDoneOnUtc = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                updates[`${'/IsDoneOnUtc'}`] = IsDoneOnUtc;
                this.setState({ isDoneOnUtc: IsDoneOnUtc, });
            }
        }
        if (!this.state.isQuizDoneAnswered)
            updates[`${'/ScoreRecord'}`] = QScore + ';' + elapsedTime + ';';

        let counter = 0;
        let done = false;
        do {
            counter++;
            let doneCount = 0;
            await this.props.dbLiveQuiz
                .ref(this.roomResultPath(ResultNode.Root))  //master root.
                .update(updates)
                .then(() => { doneCount++; })
                .catch((error) => { doneCount++; });
            //2023.10.30
            await this.props.dbLiveQuizResult
                .ref(this.studentLiveQuizResultPath(ResultNode.Root))   //backup root.
                .update(updates)
                .then(() => { doneCount++ })
                .catch((error) => { doneCount++ });
            if (!this.state.isQuizDoneAnswered)
                await this.UpdateRankingLiveRecord(QScore, elapsedTime);    //ranking-live.
            done = doneCount === 2;
        } while (done === false && counter < 5);

        //#region old code - before 2023.10.09
        // if (!this.state.isQuizDoneAnswered) {
        //     //Update ASelects.
        //     let async_action_1 = async () => {
        //         await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.ASelects))
        //             .set(ASelects)
        //             .then(() => {
        //                 done = true;
        //             })
        //             .catch((error) => {
        //                 retryAgain = true;
        //                 done = true;
        //             });
        //         await DelayUntil(() => done === true);
        //     }
        //     do {
        //         retryAgain = false;
        //         done = false;
        //         await async_action_1();
        //         // await DelayUntil(() => done === true);
        //         if (retryAgain)
        //             await Delay(500);
        //     } while (retryAgain);
        // }

        // if (!this.state.isQuizDoneAnswered || this.state.isScoreRecordNeedUpdate) {
        //     //Update QResults.
        //     let async_action_2 = async () => {
        //         await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.QResults))
        //             .set(QResults)
        //             .then(() => {
        //                 done = true;
        //             })
        //             .catch((error) => {
        //                 retryAgain = true;
        //                 done = true;
        //             });
        //         await DelayUntil(() => done === true);
        //     }
        //     do {
        //         retryAgain = false;
        //         done = false;
        //         await async_action_2();
        //         // await DelayUntil(() => done === true);
        //         if (retryAgain)
        //             await Delay(500);
        //     } while (retryAgain);
        // }

        // //Set IsDone = true. if all qs are answered.
        // if (this.state.isAllAnswered) {
        //     let setIsDoneSuccess = await this.Firebase_SetIsDone_True();
        //     await DelayUntil(() => setIsDoneSuccess === true);
        // }

        // //Update score & time elapsed.  //2021.04.20    //revamped 2023.09.05
        // if (!this.state.isQuizDoneAnswered) {
        //     let isUpdateScoreAndRankingSuccess = false;
        //     do {
        //         retryAgain = false;
        //         done = false;
        //         isUpdateScoreAndRankingSuccess = await this.UpdateScoreAndRankingRecord(QScore, elapsedTime);
        //         retryAgain = !isUpdateScoreAndRankingSuccess;
        //         if (retryAgain)
        //             await Delay(500);
        //     } while (retryAgain);
        //     await DelayUntil(() => isUpdateScoreAndRankingSuccess === true);
        // }
        //#endregion old code - before 2023.10.09

        //2021.10.14
        //update room history's score.
        await this.props.SaveRoomInLocalHistoryList_V2({
            Title: this.state.roomTitle,
            RoomCode: this.state.roomCode,
            RoomId: this.state.roomId,
            Date: moment().format("YYYY-MM-DD HH:mm:ss"),
            Score: QScore,
            ElapsedTime: elapsedTime,   //2023.11.09
            RoomType: 0,
            // SubmittedOnUTC: CheckStringEmpty(this.state.SubmittedOnUTC),  //2023.10.20
            // IsDoneOnUtc: CheckStringEmpty(this.state.isDoneOnUtc),    //2023.10.20

            //2023.11.09
            SubmittedOnUTC: '',
            IsDoneOnUtc: '',
        });

        // //2022.06.16
        // SetContext('IsSavingAnswer', false);

        //#region  old codes
        // let retrySetData = false;
        // if (!this.state.isQuizDoneAnswered) {
        //     //Update ASelects.
        //     let qsASelect = "";
        //     this.state.qsSelectedAnswers.map((data, key) => {
        //         qsASelect += data;
        //         if (key < this.state.qsSelectedAnswers.length - 1)
        //             qsASelect += ":";
        //         return null;
        //     });
        //     //2021.09.13 - add retry.
        //     // let retrySetData = false;
        //     let async_action_1 = async () => await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.ASelects))
        //         .set(qsASelect)
        //         .catch((error) => {
        //             // this.props.SetAlert("Error", error.code + "<br />" + error.message);
        //             // await this.props.SetErrorLog(new Date(), "SaveQsResults | Set ASelects | Error = " + error);
        //             retrySetData = true;
        //         });
        //     do {
        //         retrySetData = false;
        //         await async_action_1();
        //     } while (retrySetData);
        // }

        // if (!this.state.isQuizDoneAnswered || this.state.isScoreRecordNeedUpdate) {
        //     //Update QResults.
        //     // let qsResult = "";
        //     // this.state.qsAnswers.map((data, key) => {
        //     //     qsResult += data === true ? "1" : "0";
        //     //     if (key < this.state.qsAnswers.length - 1)
        //     //         qsResult += ":";
        //     //     return null;
        //     // });
        //     let qsResult = this.GetResult();        //2022.06.15            
        //     //2021.09.13 - add retry.
        //     // let retrySetData = false;
        //     let async_action_2 = async () => await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.QResults))
        //         .set(qsResult)
        //         .catch((error) => {
        //             // this.props.SetAlert("Error", error.code + "<br />" + error.message);
        //             // await this.props.SetErrorLog(new Date(), "SaveQsResults | Set QResults | Error = " + error);
        //             retrySetData = true;
        //         });
        //     do {
        //         retrySetData = false;
        //         await async_action_2();
        //     } while (retrySetData);
        // }

        // //Update Score in History List.
        // this.props.UpdateScoreInRoomHistoryList({
        //     'RoomCode': this.state.roomCode,
        //     'RoomId': this.state.roomId,
        //     'Title': this.state.roomTitle,
        //     'Score': this.GetScore(),
        // });
        // }

        // if (this.state.isAllAnswered) {
        //     //Set IsDone = true.
        //     await this.Firebase_SetIsDone_True();   //2022.06.14
        //     // //2021.09.13 - add retry.
        //     // // let retrySetData = false;
        //     // let async_action_3 = async () => await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.IsDone))
        //     //     .set(true)
        //     //     .catch((error) => {
        //     //         // this.props.SetAlert("Error", error.code + "<br />" + error.message);
        //     //         // await this.props.SetErrorLog(new Date(), "SaveQsResults | Set IsDone | Error = " + error);
        //     //         retrySetData = true;
        //     //     });
        //     // do {
        //     //     retrySetData = false;
        //     //     await async_action_3();
        //     // } while (retrySetData);
        //     // // //Update score & time elapsed.  //2021.04.20
        //     // // await this.UpdateScoreAndRankingRecord();
        // }
        // //Update score & time elapsed.  //2021.04.20
        // await this.UpdateScoreAndRankingRecord();

        //2021.04.20 - disabled.
        // //Update scores.
        // if (!this.state.isQuizDone && this.state.isQuizDoneStateLoaded) {
        //     let isUpdateScoreAndRankingRecordSuccess = await this.UpdateScoreAndRankingRecord();
        //     if (!isUpdateScoreAndRankingRecordSuccess) {
        //         this.props.SetAlert(Locale('update-failed', this.props.Locale),
        //             Locale('update-failed-msg', this.props.Locale));
        //     }
        // }

        //Update Score in History List.
        // await this.props.UpdateScoreInRoomHistoryList({
        //     'RoomCode': this.state.roomCode,
        //     'RoomId': this.state.roomId,
        //     // 'Title': this.state.roomTitle,
        //     'Score': this.GetScore(),
        // });

        // //2021.10.14
        // //update score.
        // await this.props.SaveRoomInLocalHistoryList_V2({
        //     Title: this.state.roomTitle,
        //     RoomCode: this.state.roomCode,
        //     RoomId: this.state.roomId,
        //     Date: moment().format("YYYY-MM-DD HH:mm:ss"),
        //     Score: this.GetScore(),
        //     RoomType: 0,
        //     SubmittedOnUTC: CheckStringEmpty(this.state.SubmittedOnUTC),  //2023.10.20
        //     IsDoneOnUtc: CheckStringEmpty(this.state.isDoneOnUtc),    //2023.10.20
        // });
        //#endregion

        return true;
    }

    //2024.05.23 - subCopy ASelectsList from RoomResultRootData.
    GetASelectsListDataFromRoomResultRootData = () => {
        let subCopy_isAllAnswered = false;
        let subCopy_ASelects = '';
        let subCopy_QResults = '';
        let subCopy_QScores = '';
        const roomResultRootData = this.state.RoomResultRootData;
        if (roomResultRootData.ASelectList.length > 0) {
            let answered = 0;
            let correct = 0;
            let subCopy_ASelects_array = [];
            let subCopy_QResults_array = [];
            let aSelectList = roomResultRootData.ASelectList;
            aSelectList.sort((a, b) => a.No - b.No);
            let questions = this.state.roomQuestions;
            questions.sort((a, b) => a.No - b.No);
            for (let s = 0; s < aSelectList.length; s++) {
                if (aSelectList[s].No === s + 1) {
                    subCopy_ASelects_array.push(aSelectList[s].Index);
                    const isCorrect = aSelectList[s].Value === CheckObjectStringEmpty(questions[s], 'Answer');
                    if (isCorrect)
                        correct++;
                    subCopy_QResults_array.push(isCorrect ? '1' : '0');
                    if (aSelectList[s].Index > -1)
                        answered++;
                }
            }
            subCopy_ASelects = subCopy_ASelects_array.join(':');
            subCopy_QResults = subCopy_QResults_array.join(':');
            subCopy_QScores = ((correct / aSelectList.length) * 100.00).toFixed(2);
            subCopy_isAllAnswered = aSelectList.length === answered;
        }
        return { subCopy_ASelects, subCopy_QResults, subCopy_QScores, subCopy_isAllAnswered };
    }

    GetElapsedTime = () => {

        let final_elapsedTime = 0;  //2023.10.20

        //2022.06.15 - minor revamped.
        const duration = Number(this.state.roomInfoModal.Duration.toFixed(3));

        // console.log('GetElapsedTime', '(duration)', duration);
        // console.log('GetElapsedTime', '(isQuizDone)', this.state.isQuizDone);
        // console.log('GetElapsedTime', '(loadedElapsedTime)', this.state.loadedElapsedTime);
        // console.log('GetElapsedTime', '(elapsedTime)', this.state.elapsedTime);
        // console.log('GetElapsedTime', '(durationEndTime)', this.state.roomInfoModal.AccessDurationEndTime.format('YYYY-MM-DD HH:mm:ss'));

        if (this.state.isQuizDone) {
            // if (Number(this.state.loadedElapsedTime) > duration)
            //     return duration;
            // else if (Number(this.state.loadedElapsedTime) <= 0)     //2023.07.05
            //     return Number(this.state.elapsedTime.toFixed(3));
            // else
            //     return Number(this.state.loadedElapsedTime.toFixed(3));
            if (this.state.loadedElapsedTime > duration)
                final_elapsedTime = duration;
            else if (this.state.loadedElapsedTime <= 0)     //2023.07.05
                final_elapsedTime = this.state.elapsedTime;
            else
                final_elapsedTime = this.state.loadedElapsedTime;
        }
        else {
            const elapsedTime = this.state.elapsedTime;
            const durationEndTime = this.state.roomInfoModal.AccessDurationEndTime;
            // const timeLeft = duration - elapsedTime;
            if (moment() >= durationEndTime) {
                final_elapsedTime = duration;
            }
            final_elapsedTime = elapsedTime;
        }

        return Number(final_elapsedTime.toFixed(3));    //2023.10.20

        //#region old codes
        // let _elapsedTime = '';
        // if (this.state.isQuizDone) {
        //     _elapsedTime = this.state.loadedElapsedTime.toFixed(3);
        // }
        // else {
        //     // let _elapsedTime = this.state.loadedElapsedTime.toFixed(3);
        //     // if (!this.state.isQuizDone) {
        //     if (this.state.liveQuizEndTime === '_none') {
        //         _elapsedTime = this.state.elapsedTime.toFixed(3);
        //     }
        //     else {
        //         let timeLeft = 0;

        //         // timeLeft = moment(this.state.liveQuizEndTime).unix() - moment().unix();
        //         // // let timeLeft = moment(this.state.liveQuizEndTime) - moment();
        //         // timeLeft = timeLeft < 0 ? 0 : timeLeft;

        //         // //2020.11.25
        //         // timeLeft = timeLeft > this.state.roomInfoModal.Duration ? (this.state.roomInfoModal.Duration - this.state.elapsedTime).toFixed(3) : timeLeft;

        //         //2021.07.16
        //         if (this.state.roomInfoModal.hasOwnProperty('DateStart')) {
        //             timeLeft = (this.state.roomInfoModal.Duration - this.state.elapsedTime).toFixed(3);
        //         }
        //         else {
        //             timeLeft = moment(this.state.liveQuizEndTime).unix() - moment().unix();
        //             timeLeft = timeLeft < 0 ? 0 : timeLeft;
        //             timeLeft = timeLeft > this.state.roomInfoModal.Duration ? (this.state.roomInfoModal.Duration - this.state.elapsedTime).toFixed(3) : timeLeft;
        //         }

        //         _elapsedTime = (this.state.roomInfoModal.Duration - timeLeft).toFixed(3);

        //         // console.log(this.state.liveQuizEndTime + '\n' + timeLeft + '\n' + _elapsedTime);
        //     }
        // }
        // return Number(_elapsedTime);
        //#endregion
    }

    UpdateScoreAndRankingRecord = async (score, elapsedTime) => {
        //#region old codes
        // let qsCorrect = 0;
        // this.state.qsAnswers.map((data, key) => {
        //     if (data === true)
        //         qsCorrect++;
        // });

        // let elapsedTime = 0;
        // if (this.state.liveQuizEndTime === '_none') {
        //     elapsedTime = Number(this.state.elapsedTime).toFixed(1);
        // }
        // else {
        //     let timeLeft = moment(this.state.liveQuizEndTime).unix() - moment().unix();
        //     elapsedTime = (this.state.roomInfoModal.Duration - timeLeft).toFixed(1);
        // }

        // const elapsedTime = this.GetElapsedTime();
        // console.log('elapsedTime = ' + String(elapsedTime));

        // let qsScoreRecord = this.GetScore() + ";" + elapsedTime + ";";
        // // let qsScoreRecord = this.GetScore() + ";" + Number(this.state.elapsedTime).toFixed(1) + ";";
        // await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.ScoreRecord))
        //     .set(qsScoreRecord)
        //     .catch(error => {
        //         this.props.SetAlert("Error", error.code + "<br />" + error.message);
        //     });

        // //Update Ranking Live records.
        // await this.UpdateRankingLiveRecord(elapsedTime);
        //#endregion

        //2020.12.14
        // await this.CheckIfQuizHasBeenDoneBefore();
        if (!this.state.isQuizDone) {
            this.setState({
                lastElapsedTime: elapsedTime,
            });
        }
        // else {
        //     elapsedTime = this.state.lastElapsedTime;
        // }
        await this.CheckIfQuizHasBeenDoneBefore();  //2020.12.16

        //edit on 2020.11.10
        let isUpdateScoreSuccess = null;
        isUpdateScoreSuccess = await this.UpdateScoreRecord(score, elapsedTime);
        await DelayUntil(() => isUpdateScoreSuccess !== null);
        let isUpdateRankingSuccess = null;
        isUpdateRankingSuccess = await this.UpdateRankingLiveRecord(score, elapsedTime);
        await DelayUntil(() => isUpdateRankingSuccess !== null);

        return (isUpdateScoreSuccess && isUpdateRankingSuccess) ? true : false;
    }

    UpdateScoreRecord = async (score, elapsedTime) => {
        let isSuccess = false;

        //2021.09.03
        // let _elapsedTime = this.state.isScoreRecordNeedUpdate ? this.state.loadedElapsedTime : elapsedTime;

        //2023.07.05
        let _elapsedTime = 0
        if (this.state.isScoreRecordNeedUpdate) {
            if (this.state.loadedElapsedTime > 0)
                _elapsedTime = this.state.loadedElapsedTime;
            else
                _elapsedTime = elapsedTime;
        }
        else {
            _elapsedTime = elapsedTime;
        }
        if (_elapsedTime <= 0)
            _elapsedTime = this.state.elapsedTime;

        let qsScoreRecord = score + ";" + Number(_elapsedTime).toFixed(3) + ";";
        if (this.props.isDevMode)
            console.log('qsScoreRecord = ' + qsScoreRecord);

        // let qsScoreRecord = this.GetScore() + ";" + Number(elapsedTime).toFixed(3) + ";";
        // let qsScoreRecord = this.GetScore() + ";" + Number(this.state.elapsedTime).toFixed(1) + ";";

        //2021.09.13 - add retry.
        let retryAgain = false;
        let done = false;
        let async_action = async () => {
            await this.props.dbLiveQuiz
                .ref(this.roomResultPath(ResultNode.ScoreRecord))
                .set(qsScoreRecord)
                .then(() => {
                    isSuccess = true;
                    done = true;
                })
                .catch((error) => {
                    // this.props.SetAlert("Error", error.code + "<br />" + error.message);
                    // await this.props.SetErrorLog(new Date(), "Room " + this.state.roomCode + " | " + this.state.roomId + " : Error = " + error);
                    retryAgain = true;
                    done = true;
                });
            //2023.10.30
            await this.props.dbLiveQuizResult
                .ref(this.studentLiveQuizResultPath(ResultNode.ScoreRecord))
                .set(qsScoreRecord)
                .then(() => {
                    isSuccess = true;
                    done = true;
                })
                .catch((error) => {
                    retryAgain = true;
                    done = true;
                });
            await DelayUntil(() => done === true);
        }
        do {
            retryAgain = false;
            done = false;
            await async_action();
            // await DelayUntil(() => done === true);
            if (retryAgain)
                await Delay(500);
        } while (retryAgain);

        return isSuccess;
    }

    UpdateRankingLiveRecord = async (score, elapsedTime) => {
        let isSuccess = false;
        //Update Ranking Live records.
        let record = score + ";"
            // + this.state.elapsedTime.toFixed(1) + ";"
            + Number(elapsedTime).toFixed(3) + ";"
            + encodeURI(String(this.props.profile.Name)) + ";"
            + String(this.state.isQuizDoneAnswered).toUpperCase() + ";"

        //2021.09.13 - add retry.
        let retryAgain = false;
        let done = false;
        let async_action = async () => {
            await this.props.dbLiveQuiz
                .ref(this.roomRankingLivePath())
                .set(record)
                .then(() => {
                    isSuccess = true;
                    done = true;
                })
                .catch((error) => {
                    // this.props.SetAlert("Error", error.code + "<br />" + error.message);
                    // await this.props.SetErrorLog(new Date(), "Room " + this.state.roomCode + " | " + this.state.roomId + " : Error = " + error);
                    retryAgain = true;
                    done = true;
                });
            await DelayUntil(() => done === true);
        }
        do {
            retryAgain = false;
            done = false;
            await async_action();
            // await DelayUntil(() => done === true);
            if (retryAgain)
                await Delay(500);
        } while (retryAgain);

        return isSuccess;
    }

    //2023.10.20
    CheckTimeOnUTC = (timeOnUtc = '') => {
        if (CheckStringEmpty(timeOnUtc) === '')
            return '';

        // const currTicks = (moment.utc().valueOf() / 10000);
        // if (currTicks > Number(timeOnUtc))
        //     return currTicks.toString();

        const currUtc = moment.utc();
        if (currUtc > moment(timeOnUtc).utc())
            return currUtc.toString();

        return timeOnUtc;
    }

    //2023.10.09
    SetQuizRoomResultSubmittedOnUTC = async (timeOnUtc = '') => {
        let done = false;
        let counter = 0;
        // let doneCount = 0;
        const submittedOnUTC = timeOnUtc === '' ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : timeOnUtc;
        do {
            counter++;
            let doneCount = 0;
            done = false;
            await this.props.dbLiveQuiz
                .ref(this.roomResultPath(ResultNode.SubmittedOnUTC))
                .set(submittedOnUTC)
                .then(() => {
                    doneCount++;
                })
                .catch((error) => {
                    doneCount++;
                });
            //2023.10.30
            await this.props.dbLiveQuizResult
                .ref(this.studentLiveQuizResultPath(ResultNode.SubmittedOnUTC))
                .set(submittedOnUTC)
                .then(() => {
                    doneCount++;
                })
                .catch((error) => {
                    doneCount++;
                });
            //2023.11.09
            const elapsedTime = this.GetElapsedTime();
            await this.props.dbLiveQuizRoomResult
                .ref(this.liveQuizRoomResultPath(ResultNode.Root))
                .set({
                    Uid: this.props.user.uid,
                    ASelects: this.state.ASelects,
                    SelectedAnswers: this.state.SelectedAnswers,
                    IsDoneOnUtc: this.state.isDoneOnUtc,
                    SubmittedOnUTC: this.state.SubmittedOnUTC,
                    // TimeElapsed: this.state.elapsedTime.toFixed(3),
                    TimeElapsed: elapsedTime.toFixed(3),
                })
                .then(() => {
                    doneCount++;
                })
                .catch((error) => {
                    doneCount++;
                });
            //done.
            done = doneCount === 3;
        } while (done === false && counter < 5);
        this.setState({
            SubmittedOnUTC: submittedOnUTC,
            isQuizSubmitted: true
        }, () => {
            // this.Firebase_Set_RoomResult();
        });
    }

    // //2023.11.09
    // Firebase_Set_RoomResult = async () => {
    //     let done = false;
    //     let counter = 0;
    //     const elapsedTime = this.GetElapsedTime();
    //     do {
    //         counter++;
    //         await this.props.dbLiveQuizRoomResult
    //             .ref(this.liveQuizRoomResultPath(ResultNode.Root))
    //             .set({
    //                 Uid: this.props.user.uid,
    //                 ASelects: this.state.ASelects,
    //                 SelectedAnswers: this.state.SelectedAnswers,
    //                 IsDoneOnUtc: this.state.isDoneOnUtc,
    //                 SubmittedOnUTC: this.state.SubmittedOnUTC,
    //                 // TimeElapsed: this.state.elapsedTime.toFixed(3),
    //                 TimeElapsed: elapsedTime.toFixed(3),
    //             })
    //             .then(() => {
    //                 done = true;
    //             })
    //             .catch((error) => {
    //                 done = true;
    //             });
    //     } while (done === false && counter < 5);
    // }

    GetScore = () => {
        // return (this.GetCorrect() * (100 / Number(this.state.roomInfoModal.QnQty))).toFixed(2);

        //2020.12.23
        // let subjectName = String(this.state.roomInfoModal.SubjectName).toLowerCase();
        // if (subjectName.includes('abacus'))
        //     return (this.GetCorrect() * 10).toFixed(2);
        // else
        // return (this.GetCorrect() * (100 / Number(this.state.roomInfoModal.QnQty))).toFixed(2);

        let qsCorrect = 0;
        this.state.qsAnswers.map((data, key) => {
            if (CheckBoolean(data) === true)
                qsCorrect++;
            return null;
        });
        return (qsCorrect * (100 / Number(this.state.roomInfoModal.QnQty))).toFixed(2);
    }

    GetCorrect = () => {
        let qsCorrect = 0;
        this.state.qsAnswers.map((data, key) => {
            if (data === true)
                qsCorrect++;
            return null;
        });
        return qsCorrect;
    }

    //2021.09.11
    GetSelectedAnswer = () => {
        let _aSelects = [];
        let _selectedAnswers = [];
        // console.log(JSON.stringify(this.state.qsSelectedAnswers));
        const qsSelectedAnswers = this.state.qsSelectedAnswers;
        qsSelectedAnswers.map((data, key) => {
            // selectedAnswers += (key + 1) + ":" + (String(data) === "-1" ? "-" : AtoZ[data]) + ";";
            // aSelects += data;   //String(data) === '-1' ? '-' : data;
            // if (key < qsSelectedAnswers.length - 1)
            //     aSelects += ":";
            _selectedAnswers.push((key + 1) + ":" + (String(data) === "-1" ? "-" : AtoZ[data]));
            _aSelects.push(data);
            return null;
        });
        let ASelects = _aSelects.join(':');
        let SelectedAnswers = _selectedAnswers.join(';');
        //2023.1109
        this.setState({
            ASelects: ASelects,
            SelectedAnswers: SelectedAnswers,
        })
        return { ASelects, SelectedAnswers };
    }

    //2022.06.15
    GetResult = () => {
        let QResults = "";
        const qsAnswers = this.state.qsAnswers;
        qsAnswers.map((data, key) => {
            QResults += data === true ? "1" : "0";
            if (key < qsAnswers.length - 1)
                QResults += ":";
            return null;
        });
        return QResults;
    }

    //2022.06.15 === [QScore, QResults, QCorrect, QTotal]
    Get_Score_CorrectQty_QResult = () => {
        const qTotal = Number(this.state.roomInfoModal.QnQty);
        let qCorrect = 0;
        let QResults = "";
        // this.state.qsAnswers.map((data, key) => {
        //     QCorrect += CheckBoolean(data) === true ? 1 : 0;
        //     QResults += CheckBoolean(data) === true ? '1' : '0';
        //     if (key < this.state.qsAnswers.length - 1) {
        //         QResults += ':';
        //     }
        //     return null;
        // });

        //2023.10.07 - revamped.
        let QResults_array = [];
        const randomizedQuestionNOs = CheckObjectBoolean(this.state.roomInfoModal, 'RandomQuestionMode', this.state.isRandomQuestionMode);
        const data = this.state.RoomResultRootData;
        // const isDone = this.state.isQuizDone;   // CheckObjectBoolean(data, 'IsDone');
        // if (randomizedQuestionNOs === true && isDone === false && data !== null) {
        if (randomizedQuestionNOs === true && data !== null) {
            if (CheckObjectNullValue(data, 'Questions') !== null) {
                const ASelects_array = CheckObjectStringEmpty(data, 'ASelects').split(':');
                const qsNOs_array = CheckObjectStringEmpty(data, 'Questions').split(':');
                for (let k = 0; k < qsNOs_array.length; k++) {
                    const aSelect = CheckStringEmpty(ASelects_array[k]) === '' ? '-1' : CheckStringEmpty(ASelects_array[k]);
                    if (aSelect === '-1') {
                        QResults_array.push('0');
                    }
                    else {
                        const findIndex = this.state.roomQuestions.findIndex(x => CheckObjectStringEmpty(x, 'No') === CheckStringEmpty(qsNOs_array[k]));
                        if (findIndex > -1) {
                            if (CheckObjectStringEmpty(this.state.roomQuestions[findIndex], 'Answer') === AtoZ[Number(aSelect)]) {
                                qCorrect++;
                                QResults_array.push('1');
                            }
                            else {
                                QResults_array.push('0');
                            }
                        }
                    }
                }
                QResults = QResults_array.join(':');
            }
        }
        else {
            this.state.qsAnswers.map((data, key) => {
                if (CheckBoolean(data) === true) {
                    qCorrect++;
                    QResults_array.push('1');
                }
                else {
                    QResults_array.push('0');
                }
                return null;
            });
            QResults = QResults_array.join(':');
        }

        let QCorrect = String(qCorrect);
        let QTotal = String(qTotal);
        let QScore = (qCorrect * (100 / qTotal)).toFixed(2);
        return { QScore, QResults, QCorrect, QTotal };
    }

    ConvertTime = (timeInSec) => {

        let hour = Locale('time-hour', this.props.Locale);  //2020.11.25
        let min = Locale('time-min', this.props.Locale);
        let sec = Locale('time-sec', this.props.Locale);

        // let decimal = "";
        // if (timeInSec.toString().includes(".")) {
        //     let temp = timeInSec.toString().split(".")[1];
        //     decimal = temp > 0 ? "." + temp : ".0";
        // }
        let _timeInSec = Number(timeInSec);
        _timeInSec = _timeInSec <= 0 ? 0 : _timeInSec;

        // alert(timeInSec);

        // let minutes = (_timeInSec / 60).toFixed(3).split('.')[0];
        // let seconds = (_timeInSec % 60).toFixed(0);
        // // return (minutes + " m " + (seconds.length < 2 ? "0" + seconds : seconds) + decimal + " s");
        // // return (minutes + " " + min + " " + (seconds.length < 2 ? "0" + seconds : seconds) + decimal + " " + sec);
        // return (minutes + ' ' + min + ' ' + (seconds.length < 2 ? '0' + seconds : seconds) + ' ' + sec);


        //2020.11.25
        // let hours = (_timeInSec / 3600) > 0 ? Number((_timeInSec / 3600).toFixed(3).split('.')[0]) : 0;
        // let minutes = (Number('0.' + ((_timeInSec / 3600).toFixed(3).split('.')[1])) * 60).toFixed(0);
        // let seconds = (_timeInSec % 60).toFixed(0);

        // //2020.12.16
        // let hours = (_timeInSec / 3600) > 0 ? String(_timeInSec / 3600).split('.')[0] : 0;
        // // let minutes = (_timeInSec / 60).toFixed(0);
        // let minutes = (Number(String(Number('0.' + (_timeInSec / 3600))).split('.')[1]) * 60).toFixed(0);
        // let seconds = (_timeInSec % 60).toFixed(0);

        //2020.12.16
        let hoursFlr = Math.floor(_timeInSec / 3600);
        let hours = hoursFlr > 0 ? hoursFlr : 0;
        let minutes = Math.floor(_timeInSec / 60) - (hoursFlr * 60);
        let seconds = Math.floor(_timeInSec % 60);

        let hourTxt =
            this.props.Locale === Lang.English ?
                hours > 0 ? hours + ' ' + hour + (hours > 1 ? 's' : '') + ' ' : ''
                :
                hours > 0 ? hours + ' ' + hour + ' ' : '';

        return (hourTxt + (minutes + ' ' + min) + ' ' + (seconds.length < 2 ? '0' + seconds : seconds) + ' ' + sec);
    }

    //=== Load Room Questions === starts.
    // FetchQuizRoomTitleFromFirebase = async () => {

    //     if (this.state.roomInfoModal !== null)
    //         if (this.state.roomInfoModal.RoomTitle !== '') {
    //             if (this.props.isDevMode)
    //                 console.log('FetchQuizRoomTitleFromFirebase = \n ' + this.state.roomInfoModal.RoomTitle);
    //             return;
    //         }

    //     let isSuccess = false;
    //     let title = '';
    //     // let titleLoc = '';

    //     //edited 2020.11.12
    //     let fetchSuccess = false;
    //     let i = 0;

    //     //=== Fetch from first primary node location.
    //     // await this.getRoomTitlePath1()
    //     //     .once('value', snapshot => {
    //     //         if (snapshot.val() != null)
    //     //             title = String(snapshot.val());
    //     //     });
    //     for (i = 0; i < 10; i++) {
    //         // await this.getRoomTitlePath1()
    //         //     .once('value', snapshot => {
    //         //         if (snapshot.exists()) {
    //         //             title = String(snapshot.val());
    //         //             fetchSuccess = true;
    //         //             titleLoc = '1';
    //         //         }
    //         //     });

    //         let result = await this.getRoomTitlePath1()
    //             .once('value')
    //             .then((snapshot) => {
    //                 let val = '';
    //                 if (snapshot.exists()) {
    //                     val = String(snapshot.val());
    //                 }
    //                 return val;
    //             })
    //             .catch(() => {
    //                 return '';
    //             });

    //         if (result.length > 0) {
    //             title = result;
    //             fetchSuccess = true;
    //             // titleLoc = '1';
    //         }

    //         if (fetchSuccess)
    //             break;
    //         else
    //             await Delay(500);
    //     }

    //     // alert(title);
    //     //=== Fetch from second backup node location. === usually will not be called.
    //     if (title.length <= 0) {
    //         // await this.getRoomTitlePath2()
    //         //     .once('value', snapshot => {
    //         //         if (snapshot.val() != null)
    //         //             title = String(snapshot.val());
    //         //     });
    //         for (i = 0; i < 5; i++) {
    //             // await this.getRoomTitlePath2()
    //             //     .once('value', snapshot => {
    //             //         if (snapshot.exists()) {
    //             //             title = String(snapshot.val());
    //             //             fetchSuccess = true;
    //             //             titleLoc = '2';
    //             //         }
    //             //     });

    //             let result = await this.getRoomTitlePath2()
    //                 .once('value')
    //                 .then((snapshot) => {
    //                     let val = '';
    //                     if (snapshot.exists()) {
    //                         val = String(snapshot.val());
    //                     }
    //                     return val;
    //                 })
    //                 .catch(() => {
    //                     return '';
    //                 });

    //             if (result.length > 0) {
    //                 title = result;
    //                 fetchSuccess = true;
    //                 // titleLoc = '2';
    //             }

    //             if (fetchSuccess)
    //                 break;
    //             else
    //                 await Delay(500);
    //         }
    //     }
    //     // alert(title);
    //     // this.props.SetAlert("Title " + titleLoc, title);

    //     this.setState({
    //         roomTitle: title,
    //     }, async () => {
    //         //2021.11.18 - SaveRoomInLocalHistoryList - behaviour changed on last few updates, so disabled whole part.
    //         // if (title.length > 0) {
    //         //     // this.props.SetAlert("", this.props.viewHistoryQuiz + "<br/>" + String(this.props.viewHistoryQuiz) );
    //         //     if (String(this.props.viewHistoryQuiz) === 'null') {
    //         //         let currentTime = moment();
    //         //         // await this.props.SaveRoomInLocalHistoryList({
    //         //         //     Title: title,
    //         //         //     RoomCode: this.state.roomCode,
    //         //         //     RoomId: this.state.roomId,
    //         //         //     // Date: moment().format("lll"),
    //         //         //     // Date: moment().format("YYYY-MM-DD HH:mm:ss"),
    //         //         //     // Date: moment().format('YYYYMMDD') > this.state.todayDT ? null : moment().format("YYYY-MM-DD HH:mm:ss"),
    //         //         //     // Date: Number(currentTime.format('YYYYMMDD')) > Number(this.state.todayDT) ? null : currentTime.format("YYYY-MM-DD HH:mm:ss"),
    //         //         //     Date: currentTime.format("YYYY-MM-DD HH:mm:ss"),
    //         //         //     Score: 0,
    //         //         // });
    //         //         //     this.props.SetAlert("", "room history is saved.");
    //         //         // this.setState({
    //         //         //     roomDate: currentTime.format("YYYY-MM-DD HH:mm:ss"),
    //         //         // });
    //         //     }
    //         // }
    //     });

    //     if (title.length > 0)
    //         isSuccess = true;
    //     else
    //         isSuccess = false;

    //     return isSuccess;
    // }

    FetchRoomQuestions = async () => {

        // console.log('FetchRoomQuestions = start');
        let isSuccess = false;
        // let fetchDone = false;

        //2021.09.30 - added new fetch method/fetch location.
        let roomQuestions = [];
        let extraContentArray = [];
        let _hasTags = false;
        // let isFetchFromQuestionSet =
        //     this.state.roomInfoModal === null ? null :
        //         this.state.roomInfoModal.hasOwnProperty('QuestionSetUniqueId') ?
        //             this.state.roomInfoModal.QuestionSetUniqueId !== ''
        //             : false;
        // console.log('isFetchFromQuestionSet = ' + String(isFetchFromQuestionSet));

        // if (isFetchFromQuestionSet) {
        //fetch from new RTDB location.
        let resultArray = [];

        //2023.12.14 - revamped to fetch root via api === start ===//
        let questionSetRoot = null;
        let success = false;
        let errorMessage = '';
        let done = false;
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/QuestionSet/Root/Get/'
            + 0 + '/'
            + 0 + '/'
            + CheckObjectStringEmpty(this.state.roomInfoModal, 'QuestionSetUniqueId'),
            // Api/LearningCentre/Quiz/QuestionSet/Root/Get/{organizerId}/{authorId}/{uniqueId}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('FetchRoomQuestions (api)', JSON.stringify(data));
                success = CheckObjectBoolean(data, 'success');
                if (success)
                    questionSetRoot = data.data;
                else
                    errorMessage = 'api-get / qSet (failed)\n' + JSON.stringify(data);
                done = true;
            })
            .catch(error => {
                errorMessage = 'api-get / qSet (failed)\n' + error.message;
                done = true;
            });
        await DelayUntil(() => done === true);
        if (errorMessage !== '')
            if (this.props.isDevMode)
                console.log('FetchRoomQuestions (api) (error)', errorMessage);

        if (questionSetRoot !== null) {
            questionSetRoot = CapitalizeJsonKeys(questionSetRoot);
            // if (CheckObjectNullValue(questionSetRoot, 'questions') !== null)
            //     resultArray = CapitalizeJsonKeys(questionSetRoot.questions);
            // else if (CheckObjectNullValue(questionSetRoot, 'Questions') !== null)
            //     resultArray = CapitalizeJsonKeys(questionSetRoot['Questions']);
            if (CheckObjectNullValue(questionSetRoot, 'Questions') !== null)
                resultArray = CapitalizeJsonKeys(questionSetRoot['Questions']);

            const qSet_Settings = CheckObjectNullValue(questionSetRoot, 'Settings');
            if (qSet_Settings !== null) {
                let roomInfoModal = this.state.roomInfoModal;
                // const settings = CapitalizeJsonKeys(questionSetRoot.settings);
                roomInfoModal.QnQty = CheckObjectNumber(qSet_Settings, 'TotalQuestion', roomInfoModal.QnQty);
                this.setState({
                    roomInfoModal: roomInfoModal,
                    questionSetModal: qSet_Settings,
                });
            }
            // else if (CheckObjectNullValue(questionSetRoot, 'Settings') !== null) {
            //     let roomInfoModal = this.state.roomInfoModal;
            //     const settings = CapitalizeJsonKeys(questionSetRoot.Settings);
            //     roomInfoModal.QnQty = CheckObjectNumber(settings, 'TotalQuestion', roomInfoModal.QnQty);
            //     this.setState({
            //         roomInfoModal: roomInfoModal,
            //         questionSetModal: settings,
            //     });
            // }
        }
        //2023.12.14 - revamped to fetch root via api === end ===//
        //#region old codes
        // // await this.getPath_QuestionSet_Questions(this.state.roomInfoModal.QuestionSetUniqueId)
        // await this.getPath_QuestionSet_Root(this.state.roomInfoModal.QuestionSetUniqueId)   //2023.12.13
        //     .once('value')
        //     .then((snapshot) => {
        //         if (snapshot.exists()) {
        //             let root = snapshot.val();
        //             // if (this.props.isDevMode)
        //             //     console.log('question set (root)', JSON.stringify(root));
        //             if (CheckObjectNullValue(root, 'Questions') !== null)
        //                 resultArray = root.Questions;
        //             if (CheckObjectNullValue(root, 'Settings') !== null) {
        //                 let roomInfoModal = this.state.roomInfoModal;
        //                 roomInfoModal.QnQty = CheckObjectNumber(root.Settings, 'TotalQuestion', roomInfoModal.QnQty);
        //                 this.setState({
        //                     roomInfoModal: roomInfoModal,
        //                     questionSetModal: root.Settings,
        //                 });
        //             }
        //         }
        //         // if (this.props.isDevMode)
        //         //     console.log('resultArray (roomQuestions)', JSON.stringify(resultArray));
        //     });
        //#endregion

        if (Array.isArray(resultArray) && resultArray.length > 0) {

            if (resultArray.hasOwnProperty('ExtraContent')) {
                extraContentArray = resultArray.ExtraContent;
                delete resultArray.ExtraContent;
            }

            resultArray = resultArray.filter((el) => { return (el !== null); });    //remove null or empty item.
            // console.log('resultArray length === QnQty \n' + String(resultArray.length === this.state.roomInfoModal.QnQty));
            if (this.props.isDevMode)
                console.log('filtered array (roomQuestions)', JSON.stringify(resultArray));

            resultArray.sort((a, b) => a.No - b.No);    //sort.
            if ((resultArray.length === this.state.roomInfoModal.QnQty))
                roomQuestions = resultArray;
            else
                roomQuestions = resultArray.slice(0, this.state.roomInfoModal.QnQty);

            //shd b can directly set as true for hasTags state.
            for (let k = 0; k < resultArray.length; k++) {
                if (_hasTags === false)
                    _hasTags = CheckObjectNullValue(resultArray[k], 'Tags') !== null;
                if (_hasTags)
                    break;
            }
            // console.log('resultArray =\n' + JSON.stringify(resultArray));

            //2023.12.06
            for (let q = 0; q < roomQuestions.length; q++) {
                if (CheckObjectNullValue(roomQuestions[q], 'Selection') === null)
                    roomQuestions[q].Selection = 'A:-;B:-;C:-;D:-;';
            }

            this.setState({
                extraContent: extraContentArray,
                roomQuestions: roomQuestions,
                isQuizQuestionsLoaded: true,
                hasTags: _hasTags,
            });

            if (this.props.isDevMode)
                console.log('roomQuestions (final) =\n' + JSON.stringify(roomQuestions));

            // }, async () => {
            //     await this.FetchQuizStartTime();
            //     await this.FetchQuizEndTime();
            //     await this.RoomLiveStatusListener();
            // });

            // await this.FetchQuizStartTime();
            // await this.FetchQuizEndTime();

            // let roomState = [];
            // await this.getRoomLivePath()
            //     .once('value')
            //     .then((snapshot) => {
            //         if (snapshot.exists())
            //             roomState = snapshot.val();
            //     });
            // if (roomState.hasOwnProperty('QuizStartDT'))
            //     this.setState({ liveQuizStartTime: roomState.QuizStartDT });
            // if (roomState.hasOwnProperty('QuizEndDT'))
            //     this.setState({ liveQuizEndTime: roomState.QuizEndDT });
            // if (this.props.isDevMode)
            //     console.log('\nQuizStartDT = ' + roomState.QuizStartDT + '\nQuizEndDT = ' + roomState.QuizEndDT);

            // //2022.06.15
            // if (this.state.roomInfoModal.hasOwnProperty('AccessBeginTime')) {
            //     const startTime = this.state.roomInfoModal.AccessBeginTime.format('YYYY-MM-DD HH:mm:ss');
            //     const endTime = this.state.roomInfoModal.AccessBeginTime.add(this.state.roomInfoModal.Duration, 'seconds').format('YYYY-MM-DD HH:mm:ss');
            //     this.setState({
            //         liveQuizStartTime: startTime,
            //         liveQuizEndTime: endTime,
            //     });
            //     if (this.props.isDevMode)
            //         console.log('\nQuizStartDT = ' + startTime + '\nQuizEndDT = ' + endTime);
            // }

            //trigger listening to node.
            // this.RoomLiveStatusListener();     //2022.06.15 === obsolete
            isSuccess = true;
        }
        //done.
        return isSuccess;
        // fetchDone = true;
        // }
        // else if (!isFetchFromQuestionSet) {
        //     //2021.09.30 === older method/questions fetch location before 2021.09.30

        //     let qsNums = String(this.state.roomInfoModal.Questions).split(';');
        //     qsNums = qsNums.filter((el) => { return (el !== ''); });    //remove null or empty item.
        //     // this.props.SetAlert("qsNums", JSON.stringify(qsNums));
        //     // return;

        //     // let roomQuestions = [];
        //     // let extraContentArray = [];
        //     let qsArray = {};
        //     // let count = 0;

        //     //edited on 2020.11.12
        //     // let fetchSuccess = false;
        //     let i = 0;

        //     // //2021.03.23
        //     // let _hasTags = false;

        //     isSuccess = Promise.all(
        //         qsNums.map(async (data, key) => {
        //             qsArray = null;
        //             // await this.getRoomQuestionPath_Single(data).once('value', snapshot => {
        //             //     qsArray = this.processSnapshotToArray(snapshot.val());
        //             // });                
        //             for (i = 0; i < 10; i++) {
        //                 // await this.getRoomQuestionPath_Single(data).once('value', snapshot => {
        //                 //     if (snapshot.val() != null) {
        //                 //         qsArray = this.processSnapshotToArray(snapshot.val());
        //                 //         fetchSuccess = true;
        //                 //     }
        //                 // });

        //                 let result = await this.getRoomQuestionPath_Single(data)
        //                     .once('value')
        //                     .then((snapshot) => {
        //                         let ssArray = null;
        //                         if (snapshot.exists()) {
        //                             ssArray = this.processSnapshotToArray(snapshot.val());
        //                         }
        //                         return ssArray;
        //                     });

        //                 // if (typeof (result) !== 'object') {
        //                 if (result !== null) {
        //                     qsArray = result;
        //                     break;
        //                 }
        //                 else {
        //                     await Delay(500);
        //                 }
        //             }

        //             // if (typeof (qsArray) !== 'object') {
        //             if (qsArray !== null) {
        //                 roomQuestions.push(qsArray);
        //                 // alert(JSON.stringify(qsArray));
        //                 // this.props.SetAlert("Question " + (key + 1), JSON.stringify(qsArray));
        //                 // await Delay(3000);

        //                 if (_hasTags === false)
        //                     if (qsArray.hasOwnProperty("Tags"))
        //                         if (qsArray.Tags !== undefined && String(qsArray.Tags) !== 'undefined')
        //                             _hasTags = true;


        //                 //Fetch Extra Content if has SpecialMode & is Comprehension.
        //                 if (qsArray.hasOwnProperty('SpecialMode')) {
        //                     let setting = String(qsArray.SpecialMode).split(';');
        //                     let specialMode = setting[0];
        //                     let qsContentId = setting[3];
        //                     if (specialMode === 'Comprehension') {
        //                         let extraContent = this.GetExtraContent(qsContentId, extraContentArray);
        //                         if (extraContent.length <= 0) {
        //                             extraContent = await this.FetchExtraContent(qsContentId, specialMode);
        //                             let options = qsContentId.split('_');
        //                             extraContentArray.push({
        //                                 Name: qsContentId,
        //                                 Content: extraContent,
        //                                 QsStart: Number(options[1]),
        //                                 QsEnd: Number(options[2]),
        //                                 Mode: specialMode,
        //                             });
        //                         }
        //                     }
        //                     else if (specialMode === 'FillInTheBlanks') {
        //                         let extraContent = this.GetExtraContent(qsContentId, extraContentArray);
        //                         if (extraContent.length <= 0) {
        //                             let options = qsContentId.split('_');
        //                             extraContentArray.push({
        //                                 Name: qsContentId,
        //                                 Content: '',
        //                                 QsStart: Number(options[1]),
        //                                 QsEnd: Number(options[2]),
        //                                 Mode: specialMode,
        //                             });
        //                         }
        //                     }
        //                 }
        //             }
        //         })
        //     )
        //         .then(() => {
        //             //2023.12.06
        //             for (let q = 0; q < roomQuestions.length; q++) {
        //                 if (CheckNullValue(roomQuestions[q].Selection) === null)
        //                     roomQuestions[q].Selection = 'A:-;B:-;C:-;D:-;';
        //             }

        //             this.setState({
        //                 extraContent: extraContentArray,
        //                 // roomQuestions: this.state.roomQuestions,
        //                 roomQuestions: roomQuestions,
        //                 isQuizQuestionsLoaded: true,

        //                 hasTags: _hasTags,      //2021.03.23

        //             });

        //             if (this.props.isDevMode)
        //                 console.log('roomQuestions', JSON.stringify(roomQuestions));

        //             // }, async () => {
        //             //     //     // alert(JSON.stringify(this.state.extraContent));
        //             //     //     // this.props.SetAlert(count, JSON.stringify(this.state.roomQuestions));

        //             //     //     await this.FetchQuizStartTime();    //2020.12.02
        //             //     //     await this.FetchQuizEndTime();
        //             //     //     await this.RoomLiveStatusListener();

        //             //     //2022.06.15
        //             //     await this.FetchQuizTimeAndListenState();
        //             // });

        //             //2022.06.15 === obsolete
        //             //2022.06.15
        //             // this.FetchQuizTimeAndListenState();
        //         })
        //         .then(() => {
        //             return true;
        //         });
        //     // fetchDone = true;
        // }
        // await DelayUntil(() => fetchDone === true);
        // return isSuccess;
    }

    //2022.06.15 === obsolete
    // //2022.06.15
    // FetchQuizTimeAndListenState = async () => {
    //     await Delay(500);
    //     await this.FetchQuizStartTime();    //2020.12.02
    //     await this.FetchQuizEndTime();
    //     // await this.RoomLiveStatusListener();     //2022.06.15 === obsolete
    // }

    processSnapshotToArray = (dataSnapVal) => {

        let dataKeys = _(dataSnapVal).keys().value();       //key
        let dataValues = _(dataSnapVal).values().value();   //values

        let detail = {};
        dataKeys.map((data, key) => {
            return detail[data] = _(dataValues[key]).value();
        });

        return detail;
    }

    //#region old codes
    /*
    // processRoomQuestionsData(dataSnapVal) {
    //     // this.props.SetAlert("Questions", JSON.stringify(dataSnapVal) + "<br /><br />" + JSON.stringify(this.state.roomQuestions));
    
    //     let dataKeys = _(dataSnapVal).keys().value();       //key
    //     let dataValues = _(dataSnapVal).values().value();   //values
    
    //     let qsArray = [];
    //     let detail = [];
    
    //     dataKeys.map((data, key) => {
    //         // let _roomCode = this.state.RoomCodeArrays.find(({ RoomId }) => RoomId === data).RoomCode;
    //         // return qsArray.push({
    //         //     Answer: _(dataValues[key]).values().value()[0],
    //         //     Content: _(dataValues[key]).values().value()[1],
    //         //     Hints: _(dataValues[key]).values().value()[2],
    //         //     PictureURL: _(dataValues[key]).values().value()[3],
    //         //     QuizType: _(dataValues[key]).values().value()[4],
    //         //     Selection: _(dataValues[key]).values().value()[5],
    //         //     // RoomTitle: '',
    //         // });
    //         // let detail = [];
    //         detail = [];
    //         for (let k = 0; k < dataValues.length; k++) {
    //             detail[data] = _(dataValues[key]).value();
    //         }
    //         detail = detail.filter((el) => { return el != null; });
    //         qsArray.push(detail);
    //     });
    
    //     // for (let p = 0; p < dataKeys.length; p++) {
    //     //     let detail = [];
    //     //     for (let k = 0; k < dataValues.length; k++) {
    //     //         detail[_(dataKeys[p]).value()] = _(dataValues[p]).value();
    //     //     }
    //     //     qsArray.push(detail);
    //     // }
    //     // qsArray.filter((el) => { return (typeof el !== undefined); });
    //     this.setState({
    //         roomQuestions: qsArray,
    //         isQuizQuestionsLoaded: true,
    //     },
    //         () => {
    //             this.RoomLiveStatusListener();
    //         });
    
    //     // this.props.SetAlert("Questions", JSON.stringify(dataSnapVal) + "<br /><br />" + JSON.stringify(this.state.roomQuestions));
    //     // this.props.SetAlert("Questions", JSON.stringify(this.state.roomQuestions));
    // }
    
    //=== Load Room Questions === ends.
    
    
    //==== Listen to Node === starts.
    
    // WaitForQuizFullyLoaded = async () => {
    //     do {
    //         if (this.state.isQuizDoneStateLoaded) {
    //             this.setState({
    //                 ctTimerActive: true,
    //             });
    //         }
    //     } while (!this.state.isQuizDoneStateLoaded);
    // }
    
    //#region //2022.06.15 === RoomLiveStatusListener === obsolete ===
    // RoomLiveStatusListener = async () => {
    //     // this.props.SetAlert("", this.getRoomLiveStatusPath());
    //     await this.getRoomLiveStatusPath().on('value', snapshot => {
    //         this.setState({
    //             liveQuizStatus: String(snapshot.val()),
    //         }, async () => {
    //             // alert(this.state.liveQuizStatus);
    //             switch (this.state.liveQuizStatus) {
    //                 // case 'init': break;
    //                 case 'started':
    //                     this.setState({
    //                         // ctTimerActive: true,
    //                         isQuizHasBeenStarted: true,
    //                     });
    //                     // await this.WaitForQuizFullyLoaded();
    //                     break;
    //                 case 'ended':
    //                     // setTimeout(() => {
    //                     //     this.setState({
    //                     //         ctTimerActive: true,
    //                     //     });
    //                     // }, 500);  
    //                     // this.setState({
    //                     //     ctTimerActive: false,
    //                     //     // isQuizStarted: true,
    //                     //     // isQuizDoneAnswered: true,
    //                     //     // isQuizStarted: false,
    //                     //     // isQuizQuestionsLoaded: true,
    //                     //     // toggleResultProcessing: false,
    //                     //     // isAllAnswered: false,
    //                     // });
    //                     // this.setState({
    //                     //     elapsedTime: this.state.roomInfoModal.Duration,
    //                     // });
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         });
    //     });
    // }
    //#endregion
    //=== Listen to Node === ends.
    
    //2022.06.15 === obsolete
    // //2020.12.02
    // FetchQuizStartTime = async () => {
    //     let fetchSuccess = false;
    //     let i = 0;
    //     for (i = 0; i < 5; i++) {
    
    //         let result = await this.getRoomLiveStartTimePath()
    //             .once('value')
    //             .then((snapshot) => {
    //                 let resultText = '';
    //                 if (snapshot.exists()) {
    //                     resultText = String(snapshot.val());
    //                 }
    //                 return resultText;
    //             });
    
    //         if (result.length > 0) {
    //             fetchSuccess = true;
    //             this.setState({
    //                 liveQuizStartTime: result,
    //             }, () => {
    //                 // alert(this.state.liveQuizEndTime);
    //                 // alert(moment(this.state.liveQuizEndTime).toString());
    //                 // this.props.SetAlert("Quiz End Time", moment(this.state.liveQuizEndTime).toString());
    //                 // this.props.SetAlert("Quiz End Time", this.state.liveQuizEndTime);
    //             });
    //         }
    
    //         if (fetchSuccess)
    //             break;
    //         else
    //             await Delay(300);
    //     }
    // }
    
    //2022.06.15 === obsolete
    // FetchQuizEndTime = async () => {
    //     // await this.getRoomLiveEndTimePath().once('value', snapshot => {
    //     //     if (snapshot.val() !== null)
    //     //         this.setState({
    //     //             liveQuizEndTime: String(snapshot.val()),
    //     //         }, () => {
    //     //             // alert(this.state.liveQuizEndTime);
    //     //             // alert(moment(this.state.liveQuizEndTime).toString());
    //     //             // this.props.SetAlert("End Time", moment(this.state.liveQuizEndTime).toString());
    //     //         });
    //     // });
    //     let fetchSuccess = false;
    //     let i = 0;
    //     for (i = 0; i < 5; i++) {
    //         // await this.getRoomLiveEndTimePath().once('value', snapshot => {
    //         //     if (snapshot.val() !== null) {
    //         //         fetchSuccess = true;
    //         //         this.setState({
    //         //             liveQuizEndTime: String(snapshot.val()),
    //         //         }, () => {
    //         //             // alert(this.state.liveQuizEndTime);
    //         //             // alert(moment(this.state.liveQuizEndTime).toString());
    //         //             // this.props.SetAlert("End Time", moment(this.state.liveQuizEndTime).toString());
    //         //         });
    //         //     }
    //         // });
    
    //         let result = await this.getRoomLiveEndTimePath()
    //             .once('value')
    //             .then((snapshot) => {
    //                 let resultText = '';
    //                 if (snapshot.exists()) {
    //                     resultText = String(snapshot.val());
    //                 }
    //                 return resultText;
    //             });
    
    //         if (result.length > 0) {
    //             fetchSuccess = true;
    //             this.setState({
    //                 liveQuizEndTime: result,
    //             }, () => {
    //                 // alert(this.state.liveQuizEndTime);
    //                 // alert(moment(this.state.liveQuizEndTime).toString());
    //                 // this.props.SetAlert("Quiz End Time", moment(this.state.liveQuizEndTime).toString());
    //                 // this.props.SetAlert("Quiz End Time", this.state.liveQuizEndTime);
    //             });
    //         }
    
    //         if (fetchSuccess)
    //             break;
    //         else
    //             await Delay(300);
    //     }
    // }
    
    //#region === 2022.06.15 === CheckOnQuizTime === obsolete ===
    // CheckOnQuizTime = () => {
    
    //     if (this.state.isQuizDoneStateLoaded && this.state.isQuizHasBeenStarted) {
    
    //         // if (this.state.ctTimerActive) {
    
    //         // //Debug.
    //         let current = moment();
    //         let endTime = null;
    //         let timeLeft = this.state.roomInfoModal.Duration - this.state.elapsedTime;  //2020.12.16
    
    //         //2021.01.19
    //         // let timeLeft = this.state.roomInfoModal.Duration - this.GetElapsedTime();
    
    
    //         if (this.state.liveQuizEndTime !== '_none')
    //             endTime = moment(this.state.liveQuizEndTime);
    //         // this.props.SetAlert("Time", "Current : " + current.toString()
    //         //     + "<br /><br />End Time : " + endTime.toString()
    //         //     + "<br /><br />Compare : " + (current > endTime)
    //         // );
    
    //         // alert((current >= endTime) + " | " + this.state.liveQuizStatus.toString() + " | " + this.state.liveQuizEndTime.toString() + " | " + this.state.roomQuestions.length);
    
    //         //2021.07.16
    //         let _liveQuizStartTime =
    //             this.state.roomInfoModal.hasOwnProperty('DateStart') ?
    //                 moment(this.state.roomInfoModal.DateStart)
    //                 :
    //                 this.state.liveQuizStartTime !== '_none' ? moment(this.state.liveQuizStartTime) : moment('2020-01-01 00:00:00');
    //         endTime =
    //             this.state.roomInfoModal.hasOwnProperty('DateEnd') ?
    //                 moment(this.state.roomInfoModal.DateEnd).add(1, 'day').add(-1, 'minute')
    //                 :
    //                 this.state.liveQuizEndTime !== '_none' ? moment(this.state.liveQuizEndTime) : moment(_liveQuizStartTime).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
    //         // console.log(
    //         //     'elapsedTime' + this.state.elapsedTime +
    //         //     '\ntimeLeft = ' + timeLeft
    //         // );
    
    
    //         //Local time > End Time.
    //         if (
    //             (endTime !== null && current >= endTime)
    //             || this.state.liveQuizStatus === 'ended'
    //             // || this.state.roomInfoModal.Duration - this.state.elapsedTime < 0
    //             || timeLeft < 0     //2020.12.16
    //             // || this.state.todayDT < current.format("YYYYMMDD")   //2021.07.16 - disabled due to new requirement.
    //         ) {
    //             this.setState({
    //                 ctTimerActive: false,
    //                 // isQuizDoneAnswered: true,
    //                 // isQuizStarted: true,
    
    //                 toggleResultProcessing: true,
    
    //                 // elapsedTime: this.state.roomInfoModal.Duration, //2020.11.04
    //                 // elapsedTime: this.state.isQuizDoneStateLoaded ? this.state.loadedElapsedTime : this.state.roomInfoModal.Duration, //2020.11.04
    //                 // elapsedTime: 0.0,
    //                 // elapsedTime: this.state.loadedElapsedTime,
    
    //                 elapsedTime: this.state.liveQuizStatus === 'ended' ? this.state.roomInfoModal.Duration : this.state.elapsedTime,
    
    //             }, async () => {
    //                 // setTimeout(async () => {
    //                 //     await this.SetResultUi();
    //                 // }, 5000);
    //                 // this.SetResultUi();
    //                 if (!this.state.isQuizDone && this.state.isQuizDoneStateLoaded)
    //                     await this.handleSubmitPaper();
    //             });
    //         }
    
    //         // if (this.state.ctTimerActive) {
    //         // this.props.SetAlert(moment().toString(), this.state.liveQuizEndTime);
    //         let timeLeftText = '';
    //         if (this.state.liveQuizEndTime === '_none') {
    //             timeLeftText = Locale('time-left', this.props.Locale) + '<p>'
    //                 // + this.ConvertTime(this.state.roomInfoModal.Duration - this.state.elapsedTime)
    //                 + this.ConvertTime(timeLeft)    //2020.12.16
    //                 + '</p>';
    //         }
    //         else {
    //             // timeLeftText = Locale('time-left', this.props.Locale) + '<p>'
    //             //     // + this.ConvertTime((moment(this.state.liveQuizEndTime).unix() / 1000) - (moment().unix() / 1000))
    //             //     + this.ConvertTime(moment(this.state.liveQuizEndTime).unix() - current.unix())
    //             //     + '</p>';
    
    //             //2020.12.16
    //             if (this.state.liveQuizStartTime !== '_none' && this.state.liveQuizEndTime !== '_none') {
    //                 // let activeTimeSpam = moment(this.state.liveQuizEndTime).unix() - moment(this.state.liveQuizStartTime).unix();
    //                 // activeTimeSpam = (activeTimeSpam / 3600)
    
    //                 // timeLeftText =
    //                 //     // 'activeTimeSpam : ' + activeTimeSpam + '<br />'
    //                 //     // + 'elapsedTime : ' + this.state.elapsedTime + '<br /><br />' +
    
    //                 //     '<font color="lavender">' + Locale('quiz-time-left', this.props.Locale) + '<p>'
    //                 //     + this.ConvertTime(endTime.unix() - current.unix())
    //                 //     + '</p></font>'
    
    //                 //     + Locale('time-left', this.props.Locale) + '<p ' + (timeLeft < 16 ? 'class="blink"' : '') + '>'
    //                 //     + this.ConvertTime(timeLeft)
    //                 //     + '</p>'
    
    //                 //     ;
    
    //                 //2020.12.18
    //                 let quizActiveTimeLeft = endTime.unix() - current.unix();
    //                 let isTimeLeftLessThanQuizActiveTimeLeft = timeLeft <= quizActiveTimeLeft;
    
    //                 if (isTimeLeftLessThanQuizActiveTimeLeft) {
    //                     //do nothing
    //                     //timeLeft = timeLeft;
    //                 }
    //                 else {
    //                     timeLeft = quizActiveTimeLeft;
    //                 }
    
    //                 timeLeftText =
    //                     Locale('time-left', this.props.Locale) + '<p ' + (timeLeft < 16 ? 'class="blink"' : '') + '>'
    //                     + this.ConvertTime(timeLeft)
    //                     + '</p>';
    
    //                 // this.props.SetAlert('Timer', 'activeTimeSpam : ' + activeTimeSpam + '<br /><br />' + timeLeftText);
    //             }
    //         }
    //         this.props.SetTimeLeftText(timeLeftText);
    
    //         // this.props.SetAlert(moment().toString(), timeLeftText);
    
    //         // this.props.SetAlert("Time", "Current : " + current.toString()
    //         //     + "<br /><br />End Time : " + endTime.toString()
    //         //     + "<br /><br />Compare : " + (current > endTime)
    //         //     + "<br />" + this.state.elapsedTime
    //         //     + "<br />" + this.state.roomInfoModal.Duration
    //         //     + "<br />" + (moment(this.state.liveQuizEndTime).unix() - moment().unix()).toString()
    //         // );
    //         // }
    
    //     }
    // }
    //#endregion
    */
    //#endregion

    SetResultUi = async () => {

        //Update Ui.
        await this.checkQuizComplete();

        //Update QResult in Firebase.
        await this.SaveQsResults();

        //#region  old codes
        //Show all hidden questions.
        // this.state.qsToggle.map((data, key) => {
        //     // this.state.qsToggle[key] = true;
        //     // // this.state.qsSelectedAnswers[key] = true;
        //     // this.state.qsNotActiveStat[key] = true;
        //     // // this.state.qsHasAnswer[key] = true; //this.state.qsSelectedAnswers[key] !== "-1";

        //     //edited 2020.10.13
        //     if (!Array(this.state.roomQuestions[key]).hasOwnProperty("SpecialMode")) {
        //         if (key < this.state.nextQsToAnswer) {
        //             this.state.qsToggle[key] = true;
        //             this.state.qsNotActiveStat[key] = true;
        //         }
        //     }
        //     else {
        //         let setting = this.state.roomQuestions[key].SpecialMode.split(";");
        //         if (setting[0] === "Comprehension") {
        //             this.state.qsToggle[key] = true;
        //         }
        //         else {
        //             let range = setting[1].split(",");
        //             let qsStart = range[0];
        //             let qsEnd = range[1];
        //             if (key <= qsEnd - 1) {
        //                 // this.state.nextQsToAnswer = qsStart;
        //                 this.state.qsToggle[qsStart - 1] = true;
        //                 // this.state.qsNotActiveStat[qsStart - 1] = true;
        //             }
        //         }
        //         this.state.qsNotActiveStat[key] = true;
        //     }
        //     // this.state.qsNotActiveStat[key] = true;
        // });

        //toggle to show all questions. //edited on 2020.11.13
        // let qsToggle = this.state.qsToggle;
        // this.state.qsToggle.map((data, key) => {
        //     // return qsToggle[key] = true;
        //     if (Array(this.state.roomQuestions[key]).hasOwnProperty("Answer"))    //2020.12.03  //must include Array conversion.
        //         qsToggle[key] = true;
        //     return null;
        // });
        // this.setState({ qsToggle: qsToggle });
        //#endregion

        await this.SetFinalResultToggle(true);

        //2021.04.02
        await this.Populate_CategorizedResults();

        //2023.11.09 - disabled as sdh b not needed since each setAnswer will check isAllAnswered & set isDone
        // //Set IsDone.
        // await this.Firebase_SetIsDone_True();   //2022.06.14
        // await this.Firebase_SetIsDoneOnUtc();  //2023.10.20

        // let retryAgain = false;
        // let async_action = async () => await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.IsDone))
        //     .set(true)
        //     .catch((error) => {
        //         // this.props.SetAlert("Error", error.code + "<br />" + error.message);
        //         // await this.props.SetErrorLog(new Date(), "SetResultUi | Set IsDone | Room " + this.state.roomCode + " | " + this.state.roomId + " : Error = " + error);
        //         retryAgain = true;
        //     });
        // do {
        //     retryAgain = false;
        //     await async_action();
        // } while (retryAgain);

        //Show Result stat.
        const _elapsedTime = this.GetElapsedTime();
        this.setState({
            qsToggle: this.state.qsToggle,
            // qsSelectedAnswers: this.state.qsSelectedAnswers,
            // qsNotActiveStat: this.state.qsNotActiveStat,
            // qsHasAnswer: this.state.qsHasAnswer,
            toggleShowResult: true,
            toggleResultIcon: true,
            ctTimerActive: false,

            toggleResultProcessing: false,
            // isQuizStarted: true,

            loadingInProgress: true,
            // isQuizDoneAnswered: true,
            // // isQuizStarted: false,
            // isQuizQuestionsLoaded: true,
            // // toggleResultProcessing: false,
            // isAllAnswered: false,

            elapsedTime: _elapsedTime,

            //2020.12.11
            ResultProcessingCountdown_Active: false,
            ResultProcessingCountdown_Timer: 0,

        }, () => {
            //reset & hide time left div.
            this.props.SetTimeLeftText(null);

            //2020.12.11    //reset re-enter feature.
            this.props.SetReEnterQuizRoom(false);

            setTimeout(() => {
                ScrollToElement("quiz-pos-q-result");
                // document.getElementById("quiz-pos-q-result").scrollIntoView({ behavior: 'smooth' });
                // this.SP_PA_ref.current.processContent();
                // this.forceUpdate();
            }, 1000);
        });

        return true;
    }

    //2021.04.06
    Populate_CategorizedResults = async () => {

        if (this.state.hasTags) {

            let _results = [];
            // let _tags = [];
            let _prevQsTag = '';
            let _nextQsTag = '';
            let _totalCorrect = 0;
            let _totalQuestion = 0;

            this.state.roomQuestions.map((data, key) => {

                _totalQuestion += 1;

                if (this.state.qsAnswers[key] === true) {
                    _totalCorrect += 1;
                    // console.log('#' + (key + 1) + ' = ' + data.Tags);
                }

                // if (String(this.state.roomQuestions[key].Answer) === String(String(this.state.roomQuestions[key].Selection).split(';')[this.state.qsSelectedAnswers[key]]).split(':')[0])
                //     _totalCorrect += 1;

                if (data.hasOwnProperty('Tags') && data.Tags !== undefined)
                    _prevQsTag = data.Tags;

                if (key + 1 < this.state.roomQuestions.length) {
                    let nextQs = this.state.roomQuestions[key + 1];
                    if (nextQs.hasOwnProperty('Tags'))
                        if (nextQs.Tags !== undefined)
                            _nextQsTag = nextQs.Tags;
                }

                if (_prevQsTag !== _nextQsTag || key === this.state.roomQuestions.length - 1) {
                    // console.log('#' + (key + 1) + ' = ' + data.Tags);
                    let _tagName = data.Tags === undefined ? _prevQsTag : data.Tags;
                    _results.push({ TagName: _tagName, TotalCorrect: _totalCorrect, TotalQty: _totalQuestion });
                    _totalQuestion = 0;
                    _totalCorrect = 0;
                }

                return null;
                // console.log('#' + (key + 1) + ' = curr (' + data.Tags + ') | prev (' + _prevQsTag + ') | next (' + _nextQsTag + ')');
            });

            // _tags.map((data, key) => {
            //     _results.push(
            //         <tr>
            //             <td>{key + 1}</td>
            //             <td>{data.TagName}</td>
            //             <td><span className="result-primary">{data.TotalCorrect}</span> / {data.TotalQty} (<span className="result-wrong">{(data.TotalQty - data.TotalCorrect) + ' ' + Locale("wrong", this.props.UiLocale)}</span>)</td>
            //         </tr>
            //     );
            // });

            this.setState({
                TagCategorizedResults: _results,
            });
        }
    }

    // FetchExtraContent = async (contentName) => {
    //     let contentText = "";
    //     let isFound = false;
    //     let foundKeyId = -1;

    //     if (this.state.extraContent.length > 0) {
    //         this.state.extraContent.map((data, key) => {
    //             isFound = false;
    //             if (data.hasOwnProperty("Name")) {
    //                 if (data.Name === contentName) {
    //                     isFound = true;
    //                     foundKeyId = key;
    //                 }
    //             }
    //         });
    //     }

    //     if (!isFound) {
    //         await this.getRoomQuestionPath_ExtraContent(contentName).once('value', snapshot => {
    //             contentText = snapshot.val().toString();
    //         })
    //             .then(() => {
    //                 this.state.extraContent.push({
    //                     Name: contentName,
    //                     Content: contentText,
    //                 });
    //                 this.setState({
    //                     extraContent: this.state.extraContent,
    //                 });
    //             });
    //     }
    //     else {
    //         if (this.state.extraContent[foundKeyId].hasOwnProperty("Content"))
    //             contentText = this.state.extraContent[foundKeyId].Content;
    //     }

    //     // alert(contentText);
    //     // alert(JSON.stringify(contentText));
    //     return contentText;
    // }

    FetchExtraContent = async (contentName, mode) => {
        let contentText = '';

        let fetchSuccess = false;

        for (let i = 0; i < 5; i++) {
            let result = await this.getRoomQuestionPath_ExtraContent(contentName)
                .once('value')
                .then((snapshot) => {
                    let ssText = '';
                    if (snapshot.exists())
                        ssText = String(snapshot.val());
                    return ssText;
                });

            if (result.length > 0) {
                contentText = result;
                fetchSuccess = true;
            }

            if (fetchSuccess)
                break;
            else
                await Delay(300);
        }


        // await this.getRoomQuestionPath_ExtraContent(contentName).once('value', snapshot => {
        //     contentText = String(snapshot.val());
        // });
        // // .then(() => {
        // //     let options = contentName.split("_");
        // //     this.state.extraContent.push({
        // //         Name: contentName,
        // //         Content: contentText,
        // //         QsStart: Number(options[1]),
        // //         QsEnd: Number(options[2]),
        // //         Mode: mode,
        // //     });
        // //     this.setState({
        // //         extraContent: this.state.extraContent,
        // //     });
        // // });

        // alert(contentText);
        // alert(JSON.stringify(contentText));
        return contentText;
    }

    GetExtraContent = (contentName, extraContentArray) => {
        let contentText = '';
        let isFound = false;
        let foundKeyId = -1;

        // let extraContentArray = Array(_extraContentArray);

        if (extraContentArray.length > 0) {
            extraContentArray.map((data, key) => {
                // isFound = false;
                if (data.hasOwnProperty('Name') && !isFound) {
                    if (String(data.Name) === String(contentName)) {
                        isFound = true;
                        foundKeyId = key;
                    }
                }
                return null;
            });
        }

        if (isFound) {
            if (extraContentArray[foundKeyId].hasOwnProperty('Content')) {
                contentText = extraContentArray[foundKeyId].Content;
            }
        }

        // alert(contentText);
        // alert(JSON.stringify(contentText));
        return contentText;
    }

    GetQuestionComponent = (data, _key) => {

        if (this.state.isQuizFullyLoaded === false)
            return <LoadingIndicator />;

        // let data = Object.assign({}, _data);
        let key = Number(_key);

        let qsTotal = this.state.roomQuestions.length;  //2020.12.14
        let qsStart = -1;
        let qsEnd = -1;
        let qsTitle = '';
        let qsContentId = '';
        let qsCount = 0;
        let specialMode = '';
        let selectedAnswers = [];
        let activeStates = [];
        // let ComprehensionContent = null;
        let ExtraContentText = '';

        if (data.hasOwnProperty('SpecialMode')) {
            // let setting = String(data.SpecialModeSetting).split(";");
            // qsContentId = setting[1];

            let setting = CheckObjectStringEmpty(data, 'SpecialMode').split(';');
            qsCount = setting.length < 3 ? '' : Number(setting[2]);
            qsContentId = setting.length < 4 ? '' : setting[3];   //e.g. Content_35_40
            specialMode = setting.length === 0 ? '' : setting[0];

            // let qsRange = setting[0];
            let qsRange = setting[1].split(',');
            qsStart = Number(qsRange[0]);
            qsEnd = Number(qsRange[1]);

            // alert(specialMode + " | " + qsContentId);

            //Mode & content.
            let modeText = '';
            if (specialMode === 'FillInTheBlanks') {
                modeText = 'Fill in the blanks';
            }
            else if (specialMode === 'Comprehension') {
                modeText = specialMode;
                // ComprehensionContent = qsContentId;
                // ExtraContentText = await this.FetchExtraContent(qsContentId);
                ExtraContentText = this.GetExtraContent(qsContentId, this.state.extraContent);
            }
            else {
                modeText = specialMode;
            }

            //Title
            qsTitle = Locale("question", this.props.Locale) + " " + qsStart + " " + Locale("question-to", this.props.Locale) + " " + qsEnd + " (" + modeText + ")";

            // await this.getRoomQuestionPath_ExtraContent(node)

            this.state.qsSelectedAnswers.map((data, key) => {
                if (key >= qsStart - 1 && key < qsEnd) {
                    selectedAnswers.push(data);
                }
                return null;
            });

            this.state.qsNotActiveStat.map((data, key) => {
                if (key >= qsStart - 1 && key < qsEnd) {
                    activeStates.push(data);
                }
                return null;
            });
        }

        // return (
        //     data !== null ?
        //         // Array(data).hasOwnProperty('SpecialMode') ?
        //         specialMode.length > 0 ?
        //             key >= qsStart - 1 && key < qsEnd ?
        //                 // data.SpecialMode === 'FillInTheBlanks' ?
        //                 // String(data.SpecialMode).includes("FillInTheBlanks") ?
        //                 specialMode === 'FillInTheBlanks' ?
        //                     <>
        //                         <div
        //                             className="card"
        //                             //id={"Q_" + qsStart + "_" + qsEnd}
        //                             key={"Card_Q" + (key + 1)}
        //                             hidden={!this.state.qsToggle[key]}
        //                         >
        //                             <article className="card-body">
        //                                 <QuestionSPPA
        //                                     Locale={this.state.subjectLocale}
        //                                     UiLocale={this.props.Locale}
        //                                     SetLocaleSetting={this.props.SetLocaleSetting}

        //                                     // ref={this.SP_PA_ref}
        //                                     id={key}
        //                                     key={"qs_sppa_" + key.toString()}
        //                                     // idStart={qsStart - 1}
        //                                     // idEnd={qsEnd - 1}
        //                                     qsStart={qsStart}
        //                                     qsEnd={qsEnd}
        //                                     qsTitle={qsTitle}
        //                                     qsCount={qsCount}

        //                                     total={this.state.roomInfoModal.QnQty}
        //                                     data={Object.assign({}, data)}
        //                                     // scrollTo={this.handleScrollToPos}
        //                                     nextQs={this.handleNextQuestion}
        //                                     setAnswer={this.handleSetAnswer}
        //                                     ASelectId={selectedAnswers}
        //                                     // ASelectId={this.state.qsSelectedAnswers}
        //                                     // Enabled={!this.state.isQuizDoneAnswered && this.state.qsSelectedAnswers[key] === "-1"}
        //                                     // InActive={this.state.qsNotActiveStat[key]}
        //                                     InActive={activeStates}
        //                                     ShowResultBtn={this.state.toggleShowResult}
        //                                     // HasAnswer={this.state.qsHasAnswer[key]}
        //                                     subject={this.state.roomInfoModal.Subject}
        //                                     ShowResultIcon={this.state.toggleResultIcon}
        //                                     IsQuizDone={this.state.isQuizDone}
        //                                 />
        //                             </article>
        //                         </div>
        //                         {/* <span id={"quiz-pos-q-" + qsEnd} >&nbsp;{qsEnd}</span> */}
        //                     </>
        //                     :
        //                     specialMode === 'Comprehension' ?
        //                         // <span>error showing question #{key + 1}</span>
        //                         <div
        //                             className="card"
        //                             // id={"Q" + (key + 1)}
        //                             key={"Card_Q" + (key + 1)}
        //                             hidden={!this.state.qsToggle[key]}
        //                         >
        //                             <article className="card-body">
        //                                 <Question
        //                                     Locale={this.state.subjectLocale}
        //                                     UiLocale={this.props.Locale}
        //                                     SetLocaleSetting={this.props.SetLocaleSetting}

        //                                     id={key}
        //                                     key={"qs_comp_" + key.toString()}
        //                                     total={this.state.roomInfoModal.QnQty}
        //                                     data={Object.assign({}, data)}
        //                                     // scrollTo={this.handleScrollToPos}
        //                                     nextQs={this.handleNextQuestion}
        //                                     // skipQs={this.handleSkipQuestion}
        //                                     setAnswer={this.handleSetAnswer}
        //                                     ASelectId={this.state.qsSelectedAnswers[key]}
        //                                     // Enabled={!this.state.isQuizDoneAnswered && this.state.qsSelectedAnswers[key] === "-1"}
        //                                     InActive={this.state.qsNotActiveStat[key]}
        //                                     ShowResultBtn={this.state.toggleShowResult}
        //                                     // HasAnswer={this.state.qsHasAnswer[key]}
        //                                     ShowResultIcon={this.state.toggleResultIcon}

        //                                     SpecialMode={specialMode}
        //                                     qsStart={qsStart}
        //                                     qsEnd={qsEnd}
        //                                     // ComprehensionContent={ComprehensionContent}
        //                                     ExtraContent={ExtraContentText}
        //                                 />
        //                             </article>
        //                         </div>
        //                         : null
        //                 : null
        //             :
        //             <>
        //                 <div
        //                     className="card"
        //                     // id={"Q" + (key + 1)}
        //                     key={"Card_Q" + (key + 1)}
        //                     hidden={!this.state.qsToggle[key]}
        //                 >
        //                     <article className="card-body">
        //                         <Question
        //                             Locale={this.state.subjectLocale}
        //                             UiLocale={this.props.Locale}
        //                             SetLocaleSetting={this.props.SetLocaleSetting}

        //                             id={key}
        //                             key={"qs_q_" + key.toString()}
        //                             total={this.state.roomInfoModal.QnQty}
        //                             data={Object.assign({}, data)}
        //                             // scrollTo={this.handleScrollToPos}
        //                             nextQs={this.handleNextQuestion}
        //                             // skipQs={this.handleSkipQuestion}
        //                             setAnswer={this.handleSetAnswer}
        //                             ASelectId={this.state.qsSelectedAnswers[key]}
        //                             // Enabled={!this.state.isQuizDoneAnswered && this.state.qsSelectedAnswers[key] === "-1"}
        //                             InActive={this.state.qsNotActiveStat[key]}
        //                             ShowResultBtn={this.state.toggleShowResult}
        //                             // HasAnswer={this.state.qsHasAnswer[key]}
        //                             ShowResultIcon={this.state.toggleResultIcon}

        //                             SpecialMode={specialMode}
        //                             qsStart={qsStart}
        //                             qsEnd={qsEnd}
        //                             // ComprehensionContent={ComprehensionContent}
        //                             ExtraContent={ExtraContentText}
        //                         />
        //                     </article>
        //                 </div>
        //                 {/* <span id={"quiz-pos-q-" + (key + 1)} >&nbsp;</span> */}
        //             </>
        //         : null
        // );


        //2020.12.01    //massive modification. to simplify code pattern.
        let questionComponent = null;

        //2020.12.18
        if (data.hasOwnProperty('QuizType')) {
            if (data.QuizType === 'Subjective') {
                specialMode = 'Subjective';
                qsStart = key + 1;
                qsEnd = key + 1;
            }
        }

        switch (specialMode) {

            //=== FillInTheBlanks ===//
            case 'FillInTheBlanks':
                questionComponent =
                    <div
                        className="card"
                        //id={"Q_" + qsStart + "_" + qsEnd}
                        key={"Card_Q" + (key + 1)}
                        hidden={!this.state.qsToggle[key]}
                    >
                        <article className="card-body">
                            <QuestionSPPA
                                Locale={this.state.subjectLocale}
                                UiLocale={this.props.Locale}
                                SetLocaleSetting={this.props.SetLocaleSetting}

                                // ref={this.SP_PA_ref}
                                id={key}
                                key={"qs_sppa_" + key.toString()}
                                // idStart={qsStart - 1}
                                // idEnd={qsEnd - 1}
                                qsStart={qsStart}
                                qsEnd={qsEnd}
                                qsTitle={qsTitle}
                                qsCount={qsCount}

                                total={this.state.roomInfoModal.QnQty}
                                data={Object.assign({}, data)}
                                // scrollTo={this.handleScrollToPos}
                                nextQs={this.handleNextQuestion}
                                setAnswer={this.handleSetAnswer}
                                ASelectId={selectedAnswers}
                                // ASelectId={this.state.qsSelectedAnswers}
                                // Enabled={!this.state.isQuizDoneAnswered && this.state.qsSelectedAnswers[key] === "-1"}
                                // InActive={this.state.qsNotActiveStat[key]}
                                // InActive={activeStates}
                                InActive={this.state.isQuizDone}
                                ShowResultBtn={this.state.toggleShowResult}
                                // HasAnswer={this.state.qsHasAnswer[key]}
                                subject={this.state.roomInfoModal.Subject}
                                ShowResultIcon={this.state.toggleResultIcon}
                                IsQuizDone={this.state.isQuizDone}
                            />
                        </article>
                    </div>;
                break;

            //=== Comprehension ===//
            case 'Comprehension':
                questionComponent =
                    <div
                        className="card"
                        // id={"Q" + (key + 1)}
                        key={"Card_Q" + (key + 1)}
                        hidden={!this.state.qsToggle[key]}
                    >
                        <article className="card-body card-qs">
                            <Question
                                Locale={this.state.subjectLocale}
                                UiLocale={this.props.Locale}
                                SetLocaleSetting={this.props.SetLocaleSetting}

                                id={key}
                                key={"qs_comp_" + key.toString()}
                                total={this.state.roomInfoModal.QnQty}
                                data={Object.assign({}, data)}
                                // scrollTo={this.handleScrollToPos}
                                nextQs={this.handleNextQuestion}
                                // skipQs={this.handleSkipQuestion}
                                setAnswer={this.handleSetAnswer}
                                ASelectId={this.state.qsSelectedAnswers[key]}
                                // Enabled={!this.state.isQuizDoneAnswered && this.state.qsSelectedAnswers[key] === "-1"}
                                InActive={this.state.qsNotActiveStat[key]}
                                ShowResultBtn={this.state.toggleShowResult}
                                // HasAnswer={this.state.qsHasAnswer[key]}
                                ShowResultIcon={this.state.toggleResultIcon}

                                SpecialMode={specialMode}
                                qsStart={qsStart}
                                qsEnd={qsEnd}
                                // ComprehensionContent={ComprehensionContent}
                                ExtraContent={ExtraContentText}

                                CloseAlert={this.props.CloseAlert}      //2023.11.11
                            />
                        </article>
                    </div>;
                break;

            //2020.12.01 - new type
            //=== Subjective ===//
            case 'Subjective':
                questionComponent =
                    <div
                        className="card"
                        // id={"Q" + (key + 1)}
                        key={"Card_Q" + (key + 1)}
                        hidden={!this.state.qsToggle[key]}
                    >
                        <article className="card-body">
                            <QuestionSubjective
                                isDevMode={this.props.isDevMode}

                                Locale={this.state.subjectLocale}
                                UiLocale={this.props.Locale}
                                SetLocaleSetting={this.props.SetLocaleSetting}

                                id={key}
                                key={"qs_q_" + key.toString()}
                                total={this.state.roomInfoModal.QnQty}
                                data={Object.assign({}, data)}

                                //2021.04.28
                                // nextQsData={
                                //     key + 1 < this.state.roomQuestions.length ?
                                //         this.state.roomQuestions[key + 1].Answer.includes('|') ?
                                //             Object.assign({}, data)
                                //             : null
                                //         : null
                                // }

                                // scrollTo={this.handleScrollToPos}
                                nextQs={this.handleNextQuestion}
                                // skipQs={this.handleSkipQuestion}
                                setAnswer={this.handleSetAnswer}
                                ASelectId={this.state.qsSelectedAnswers[key]}
                                // Enabled={!this.state.isQuizDoneAnswered && this.state.qsSelectedAnswers[key] === "-1"}
                                InActive={this.state.qsNotActiveStat[key]}
                                ShowResultBtn={this.state.toggleShowResult}
                                // HasAnswer={this.state.qsHasAnswer[key]}
                                ShowResultIcon={this.state.toggleResultIcon}

                                SpecialMode={specialMode}
                                qsStart={qsStart}
                                qsEnd={qsEnd}
                                qsTotal={qsTotal}   //2020.12.14
                            // ComprehensionContent={ComprehensionContent}
                            // ExtraContent={ExtraContentText}
                            />
                        </article>
                    </div>;
                break;

            //=== Normal Default Question ===//
            default:
                questionComponent =
                    <div
                        className="card"
                        // id={"Q" + (key + 1)}
                        key={"Card_Q" + (key + 1)}
                        hidden={!this.state.qsToggle[key]}
                    >
                        <article className="card-body">
                            <Question
                                Locale={this.state.subjectLocale}
                                UiLocale={this.props.Locale}
                                SetLocaleSetting={this.props.SetLocaleSetting}

                                id={key}
                                key={"qs_q_" + key.toString()}
                                total={this.state.roomInfoModal.QnQty}
                                data={Object.assign({}, data)}      //question.
                                // scrollTo={this.handleScrollToPos}
                                nextQs={this.handleNextQuestion}
                                // skipQs={this.handleSkipQuestion}
                                setAnswer={this.handleSetAnswer}
                                ASelectId={this.state.qsSelectedAnswers[key]}
                                // Enabled={!this.state.isQuizDoneAnswered && this.state.qsSelectedAnswers[key] === "-1"}
                                InActive={this.state.qsNotActiveStat[key]}
                                ShowResultBtn={this.state.toggleShowResult}
                                // HasAnswer={this.state.qsHasAnswer[key]}
                                ShowResultIcon={this.state.toggleResultIcon}

                                SpecialMode={specialMode}
                                qsStart={qsStart}
                                qsEnd={qsEnd}
                                // ComprehensionContent={ComprehensionContent}
                                ExtraContent={ExtraContentText}

                                CloseAlert={this.props.CloseAlert}      //2023.11.11
                            />
                        </article>
                    </div>
                break;
        }

        return questionComponent;
    }

    handleSubmitPaper = async () => {
        //Goto Top
        window.scrollTo(0, 0);

        //2023.10.18
        this.setState({
            SaveOrSubmitInProgress: true,
        });

        //2022.06.15
        this.setState({
            ctTimerActive: false,
            isQuizDoneAnswered: true,
            toggleResultProcessing: true,
            ResultProcessingCountdown_Active: true,
        });
        // await this.processSubmitPaper();

        //2022.11.16
        let done = false;
        done = await this.processSubmitPaper();
        await DelayUntil(() => done === true);
        this.props.CloseAlert();

        //2023.10.18
        this.setState({
            SaveOrSubmitInProgress: false,
        });

        //2024.06.19
        window.scrollTo(0, 0);
        const _browserName = browserName.toLowerCase();
        if (_browserName.includes('safari'))
            document.body.scrollTop = 0;
        else if (_browserName.includes('chrome')
            || _browserName.includes('firefox')
            || _browserName.includes('edge'))
            document.documentElement.scrollTop = 0;

        //#region old codes
        // this.setState({
        //     ctTimerActive: false,
        //     isQuizDoneAnswered: true,

        //     toggleResultProcessing: true,

        //     ResultProcessingCountdown_Active: true, //2020.12.11


        // }, async () => {
        //     // //Update scores.
        //     // await this.UpdateScoreAndRankingRecord();

        //     // //Set IsDone.
        //     // // await this.props.dbLiveQuiz.ref(this.roomResultPath(ResultNode.IsDone))
        //     // //     .set(true)
        //     // //     .catch(error => {
        //     // //         this.props.SetAlert("Error", error.code + "<br />" + error.message);
        //     // //     });

        //     // //Log - Quiz Completed
        //     // await this.SetQuizCompletedLog();

        //     // //Show Result.
        //     // // this.setState({
        //     // //     toggleShowResult: true,
        //     // // }, () => {
        //     // //     document.getElementById("quiz-pos-q-result").scrollIntoView({ behavior: 'smooth' });
        //     // // });


        //     // //new on 2020.10.12
        //     // await this.SetResultUi();

        //     // let isProcessSubmitAnswerSuccess = await this.processSubmitPaper();
        //     await this.processSubmitPaper();
        // });
        //#endregion
    }

    //2020.11.09
    processSubmitPaper = async () => {

        //2023.11.17
        this.props.SetLoading('', 'submitting...', false);

        //Update scores.
        // await this.UpdateScoreAndRankingRecord();
        // let isUpdateScoreAndRankingRecordSuccess = await this.UpdateScoreAndRankingRecord();
        // if (!isUpdateScoreAndRankingRecordSuccess) {
        //     this.props.SetAlert(Locale('update-failed', this.props.Locale),
        //         Locale('update-failed-msg', this.props.Locale));
        // }

        //2022.11.16
        let done = false;

        // const ticks = (moment.utc().valueOf() / 10000).toString();  //2023.10.20
        const timeOnUtc = moment.utc().format('YYYY-MM-DD HH:mm:ss');  //2023.10.20

        //Log - Quiz Completed
        done = await this.SetQuizCompletedLog(timeOnUtc);
        await DelayUntil(() => done === true);

        done = false;   //next.

        //2023.10.09
        done = await this.SetQuizRoomResultSubmittedOnUTC(timeOnUtc);
        await DelayUntil(() => done === true);

        done = false;   //next.

        //new on 2020.10.12
        done = await this.SetResultUi();
        await DelayUntil(() => done === true);

        // //2021.04.06
        // await this.SetParticipationOnEvent();

        //2023.11.17
        this.props.CloseAlert();

        //2022.11.16
        return true;
    }

    //2021.04.06
    SetParticipationOnEvent = async () => {

        //2022.11.09
        if (this.state.ParticipationOnEvent_SetDone === true)
            return true;

        // //2022.06.15
        // if (this.state.isEventCertRecordExisted === true)
        //     return;

        // if (this.state.isAllAnswered === false)
        //     return null;

        //check if this room belongs to any event.
        let isEventQuiz = { Result: false, EventCode: '', EventModal: null };
        isEventQuiz = this.props.CheckIfRoomBelongsToEvent(this.state.roomCode);
        // console.log('SetParticipationOnEvent > CheckIfRoomBelongsToEvent = ' + isEventQuiz.Result + ' | ' + isEventQuiz.EventCode);

        let done = false;

        if (isEventQuiz.Result) {
            //2021.09.14
            if (moment() <= moment(String(isEventQuiz.EventModal.DateStart)).add(7, 'days')) {

                //2021.09.08 - add retry.
                let retryAgain = false;
                // do {
                //     retryAgain = false;

                //check if collection/doc exits.
                let isEventExists = false;
                let isCounterExists = false;
                let counter = 0;
                let count = 0;

                //check for certificate of current event.
                let async_action_2 = async () => {
                    await this.props.firestore
                        .collection('LiveQuiz_Certifications')
                        .doc(String(this.props.user.uid))
                        .collection('Certificates')
                        .where('EventCode', '==', isEventQuiz.EventCode)
                        .get()
                        .then((querySnapshot) => {
                            let data = [];
                            if (querySnapshot !== null) {
                                // data = querySnapshot.docs.map(doc => doc.data())[0];

                                //Jay revamped 2021.12.17
                                querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                    data[data.length - 1].Id = doc.id;    //important
                                });
                                if (data.length > 0)
                                    data = data[0];
                                else
                                    data = undefined;
                            }
                            else {
                                data = undefined;
                            }
                            if (data !== undefined && data.hasOwnProperty('EventCode')) {
                                isEventExists = true;
                                this.setState({ isEventCertRecordExisted: true, });     //2022.06.15
                            }
                            done = true;
                            if (this.props.isDevMode)
                                console.log('Event (' + (isEventExists ? 'exist, new cert record not needed' : 'not-exist') + ') =\n' + JSON.stringify(data));
                        })
                        .catch((error) => {
                            retryAgain = true;
                            done = true;
                        });
                    await DelayUntil(() => done === true);
                }
                do {
                    retryAgain = false;
                    done = false;
                    await async_action_2();
                    // await DelayUntil(() => done === true);
                    if (retryAgain)
                        await Delay(500);
                    count++;
                } while (retryAgain && count < 5);

                // if (isEventExists === false) {
                //check on counter record, to know whether its fresh or existed.
                let async_action_1 = async () => {
                    await this.props.firestore
                        .collection('LiveQuiz_Certifications')
                        .where('Uid', '==', String(this.props.user.uid))
                        .get()
                        .then((querySnapshot) => {
                            let data = [];
                            if (querySnapshot !== null) {
                                data = querySnapshot.docs.map(doc => doc.data())[0];
                            }
                            if (data !== undefined && data.hasOwnProperty('Counter')) {
                                isCounterExists = true;
                                counter = Number(data.Counter);
                            }
                            done = true;
                            if (this.props.isDevMode)
                                console.log('Counter (' + (isCounterExists ? 'exist' : 'not-exist') + ') =\n' + JSON.stringify(data) + ' | ' + counter);
                        })
                        .catch((error) => {
                            retryAgain = true;
                            done = true;
                        });
                    await DelayUntil(() => done === true);
                }
                count = 0;
                do {
                    retryAgain = false;
                    done = false;
                    await async_action_1();
                    // await DelayUntil(() => done === true);
                    if (retryAgain)
                        await Delay(500);
                    count++;
                } while (retryAgain && count < 5);

                //if no record found.
                if (isCounterExists === false) {
                    //set initial search target.
                    let async_action_3 = async () => {
                        await this.props.firestore
                            .collection('LiveQuiz_Certifications')
                            .doc(String(this.props.user.uid))
                            .set({ Uid: String(this.props.user.uid), Counter: 1, LastUpdatedOnUtc: moment.utc().format('YYYY-MM-DD HH:mm:ss') })
                            .then(() => {
                                done = true;
                            })
                            .catch((error) => {
                                retryAgain = true;
                                done = true;
                            });
                        await DelayUntil(() => done === true);
                    }
                    count = 0;
                    do {
                        retryAgain = false;
                        done = false;
                        await async_action_3();
                        // await DelayUntil(() => done === true);
                        if (retryAgain)
                            await Delay(500);
                        count++;
                    } while (retryAgain && count < 5);
                    if (this.props.isDevMode)
                        console.log('new record created. | Counter = 1');
                }
                else if (isCounterExists && !isEventExists) {
                    //update counter
                    let newCounter = counter + 1;
                    let async_action_4 = async () => {
                        await this.props.firestore
                            .collection('LiveQuiz_Certifications')
                            .doc(String(this.props.user.uid))
                            .update({ Counter: newCounter, LastUpdatedOnUtc: moment.utc().format('YYYY-MM-DD HH:mm:ss') })
                            .then(() => {
                                done = true;
                            })
                            .catch((error) => {
                                retryAgain = true;
                                done = true;
                            });
                        await DelayUntil(() => done === true);
                    }
                    count = 0;
                    do {
                        retryAgain = false;
                        done = false;
                        await async_action_4();
                        // await DelayUntil(() => done === true);
                        if (retryAgain)
                            await Delay(500);
                        count++;
                    } while (retryAgain && count < 5);
                    if (this.props.isDevMode)
                        console.log('certification counter updated.\nCounter = ' + newCounter);
                }

                //set certificates collection.
                // if (isEventExists === false) {

                //#region changed plan - only will set serial when set score. 2021.11.12
                // //2021.11.08 === generate serial number.
                // if (isEventQuiz.EventModal.hasOwnProperty('CertSerialPrefix')) {
                //     if (String(isEventQuiz.EventModal.CertSerialPrefix).length === 0) {

                //         //2021.11.12 - revamped.
                //         // let eventCode = String(isEventQuiz.EventModal.EventCode);
                //         let eventCode = '883f207e4ab84f52af5494eac658bc8a';

                //         //Check if value exist.
                //         let isSerialCounterExist = false;
                //         let serialCounterModal = {
                //             CertSerialPrefix: '',
                //             EventCode: '',
                //             Counter: 0,
                //         };
                //         await this.props.dbLQ_SN_Counter.ref(eventCode).then(snapshot => {
                //             if (snapshot.exists) {
                //                 isSerialCounterExist = true;
                //                 serialCounterModal = snapshot.data();
                //             }
                //         }).catch(error => { console.log('SN Counter (Fetch) Error = ' + error) });

                //         //Generate a new SN for this cert.
                //         if (isSerialCounterExist) {
                //             let updates = {};
                //             updates['Counter'] = this.props.dbLQ_SN_Counter.ServerValue.increment(1);
                //             serialCounterModal.Counter = updates['Counter'];
                //             await this.props.dbLQ_SN_Counter.ref(eventCode).update(updates);
                //         }
                //         else {
                //             serialCounterModal = {
                //                 CertSerialPrefix: String(isEventQuiz.EventModal.CertSerialPrefix),
                //                 EventCode: eventCode,
                //                 Counter: 1,
                //             };
                //             await this.props.dbLQ_SN_Counter.ref(eventCode).set(serialCounterModal);
                //         }

                //         //Fill the returned SN into this cert info.
                //         let fullTextCounter = '';
                //         if (String(serialCounterModal.Counter).length < 6) {
                //             let loopCount = 6 - String(serialCounterModal.Counter).length;
                //             for (let i = 0; i < loopCount; i++)
                //                 fullTextCounter += '0';
                //             fullTextCounter += String(serialCounterModal.Counter);
                //         }
                //         else {
                //             fullTextCounter = String(serialCounterModal.Counter);
                //         }
                //         isEventQuiz.EventModal.CertSN = String(isEventQuiz.EventModal.CertSerialPrefix) + fullTextCounter;
                //     }
                // }
                //#endregion

                if (isEventExists === false) {
                    //original codes.
                    let _studentName = String(this.props.profile.Name);
                    let _current = moment.utc();    //2021.11.12
                    let async_action_5 = async () => {
                        await this.props.firestore
                            .collection('LiveQuiz_Certifications')
                            .doc(String(this.props.user.uid))
                            .collection('Certificates')
                            .doc(_current.valueOf().toString())
                            .set({
                                CertDownloadEnabled: CheckBoolean(isEventQuiz.EventModal.CertDownloadEnabled),    //2021.07.05
                                Participant: _studentName,
                                EventCode: String(isEventQuiz.EventCode),
                                CreatedOnUtc: _current.format('YYYY-MM-DD HH:mm:ss'),        //YYYY-MM-DD HH:mm:ss.SSS

                                //2021.11.08 === add serial number & school name.
                                CertSN: '', //isEventQuiz.EventModal.CertSN,
                                SchoolName: this.props.profile.hasOwnProperty('School') ?
                                    String(this.props.profile.School).length > 0 ? String(this.props.profile.School) : ''
                                    : '',
                            })
                            .then(() => {
                                done = true;
                            })
                            .catch((error) => {
                                retryAgain = true;
                                done = true;
                            });
                        await DelayUntil(() => done === true);
                    }
                    count = 0;
                    do {
                        retryAgain = false;
                        done = false;
                        await async_action_5();
                        // await DelayUntil(() => done === true);
                        if (retryAgain)
                            await Delay(500);
                        count++;
                    } while (retryAgain && count < 5);
                    if (this.props.isDevMode)
                        console.log('<' + _studentName + '> new certificate is added.');
                    // }

                    // } while (retryAgain);
                }
                //done.

                //2022.11.09
                this.setState({ ParticipationOnEvent_SetDone: true, });

                return true;
            }
        }

        return true;
    }

    GetResultUiElements = (result, key) => {

        if (CheckNullValue(this.state.roomQuestions[key]) === null)     //2023.12.13
            return null;
        // if (this.state.isQuizFullyLoaded === false)
        //     return null;
        // if (this.state.roomQuestions[key].Answer === undefined)
        //     return null;
        // console.log(JSON.stringify(this.state.roomQuestions[key]));

        //2021.09.02
        let _finalResult = result;
        // let _answer = String(this.state.roomQuestions[key].Answer);
        let _answer = '';
        // _finalResult = _answer === String(String(this.state.roomQuestions[key].Selection).split(';')[this.state.qsSelectedAnswers[key]]).split(':')[0];
        if (this.state.roomQuestions[key].hasOwnProperty('SpecialMode')) {
            if (this.state.roomQuestions[key].SpecialMode.includes('FillInTheBlanks')) {
                // console.log(this.state.roomQuestions[key].SpecialMode);
                let settings = String(this.state.roomQuestions[key].SpecialMode).split(';');
                let startNo = Number(settings[1].split(',')[0]) - 1;
                let index = key - startNo;
                _answer = String(this.state.roomQuestions[startNo].Answer).split(';')[index];
                _finalResult = _answer === String(String(this.state.roomQuestions[startNo].Selection).split(';')[Number(this.state.qsSelectedAnswers[key])]).split(':')[0];
                // console.log('#' + (key + 1) + ' = ' + index + '\ = ' + _answer);
            }
            else {
                //Comprehension or Subjective.
                _answer = String(this.state.roomQuestions[key].Answer);
                _finalResult = _answer === String(String(this.state.roomQuestions[key].Selection).split(';')[Number(this.state.qsSelectedAnswers[key])]).split(':')[0];
            }
        }
        else {
            //normal question.
            _answer = String(this.state.roomQuestions[key].Answer);
            _finalResult = _answer === String(String(this.state.roomQuestions[key].Selection).split(';')[Number(this.state.qsSelectedAnswers[key])]).split(':')[0];
        }
        // _finalResult = _answer === String(String(this.state.roomQuestions[key].Selection).split(';')[this.state.qsSelectedAnswers[key]]).split(':')[0];
        // let _providedAnswers = String(this.state.roomQuestions[key].Selection).split(';');
        // if (this.state.roomQuestions[key].Answer === _providedAnswers[this.state.qsSelectedAnswers[key]].split(':')[0])
        //     _finalResult = true;
        // else
        //     _finalResult = false;

        // console.log('#' + (key + 1) + ' = ' + _answer + '\nCorrect = ' + _finalResult);

        if (_finalResult) {
            return <>
                <i className="fa fa-check" style={{ fontSize: "24px", color: "blue" }}></i>&nbsp;
                <font color="blue">{Locale("correct", this.props.Locale)}</font>
            </>
        }
        else {
            return <>
                <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>&nbsp;
                <font color="red">
                    {
                        String(this.state.qsSelectedAnswers[key]) !== "-1" ?
                            Locale("wrong", this.props.Locale)
                            : Locale("quiz-times-up", this.props.Locale)
                    }
                </font>
            </>
        }
    }


    GotoLogin = () => {
        this.setState({
            redirectLink: "/",
            redirect: true,
        });
    }

    GotoHome = async () => {
        await this.SetExitRoomLog();
        this.props.SetTimeLeftText(null);

        let _loc = "/home";
        if (this.props.isFromParentApp)
            _loc = "/history";

        this.setState({
            redirectLink: _loc,
            redirect: true,
        }, () => {
            //2020.12.01
            const _browserName = browserName.toLowerCase();

            if (_browserName.includes('safari'))
                document.body.scrollTop = 0;
            else if (_browserName.includes('chrome')
                || _browserName.includes('firefox')
                || _browserName.includes('edge'))
                document.documentElement.scrollTop = 0;
        });
    }

    RemainingQtyText = () => {
        let text = null;

        // if (this.state.subjectLocale === Lang.Chinese) {
        if (String(this.props.Locale) === Lang.Chinese) {
            text = "还有剩下约 <b>" + this.state.qsQtyToAnswer + "</b> 题 题目等待回答。";
        }
        // else if (this.state.subjectLocale === Lang.Malay) {
        else if (String(this.props.Locale) === Lang.Malay) {
            text = "Masih terdapat kira-kira "
                + "<b>" + this.state.qsQtyToAnswer + "</b>"
                + " soalan perlu diberikan jawapan.";
        }
        else {
            text = "There " + (this.state.qsQtyToAnswer > 1 ? " are " : " is ")
                + "<b>" + this.state.qsQtyToAnswer + "</b>"
                + " question" + (this.state.qsQtyToAnswer > 1 ? "s " : " ")
                + "remaining to be answered.";
        }


        //Added 2020-11-04
        // if (this.state.qsLeftToAnswer.length > 0) {
        //     if (this.state.isQuizStarted) {
        //         text += '<p>';
        //         this.state.qsLeftToAnswer.map((data, key) => {
        //             // let componentMarkUp = <a href={'#quiz-pos-q-' + data.Key} onClick={()=>this.OpenQsToggle(data.Key)}>[ {data.No} ]</a>;
        //             // text += ReactDOMServer.renderToString(componentMarkUp);
        //             text += "<a href='#quiz-pos-q-" + data.Key + "'>[ " + data.No + " ]</a> ";
        //             if (key > 0 && (key + 1) % 5 === 0)
        //                 text += "<br />";
        //             return null;
        //         });
        //         text += '</p>';
        //     }
        //     else {
        //         text += '<p>';
        //         this.state.qsLeftToAnswer.map((data, key) => {
        //             text += "[ " + data.No + " ] ";
        //             if (key > 0 && (key + 1) % 5 === 0)
        //                 text += "<br />";
        //             return null;
        //         });
        //         text += '</p>';
        //     }
        // }

        return text;
    }

    //Added on 2020-11-10
    RemainingQsComponents = () => {
        let markUpComponent = [];
        if (this.state.qsLeftToAnswer.length > 0) {
            if (this.state.isQuizStarted) {
                this.state.qsLeftToAnswer.map((data, key) => {
                    let _specialMode = 'none';
                    if (CheckNullValue(this.state.roomQuestions[data.Key]) !== null)
                        if (this.state.roomQuestions[data.Key].hasOwnProperty("SpecialMode"))
                            _specialMode = String(this.state.roomQuestions[data.Key].SpecialMode);
                    let keyId =
                        _specialMode !== 'none' ?
                            _specialMode.split(';')[0] !== "Comprehension" ?
                                Number(_specialMode.split(';')[1].split(',')[0]) - 1
                                :
                                data.Key
                            :
                            data.Key
                        ;

                    //#region old code - before 2023.10.09
                    // // let keyId = data.Key;
                    // // markUpComponent.push(<a
                    // //     // href={'#quiz-pos-q-' + data.Key}
                    // //     // onClick={() => this.OpenQsToggle(data.Key)}
                    // //     // key={'jump_' + key}
                    // //     onClick={() => this.OpenQsToggle(keyId)}
                    // //     href={'#quiz-pos-q-' + keyId}
                    // //     key={'jump_' + keyId}
                    // // >[ {data.No} ]</a>);
                    // // if (key > 0 && (key + 1) % 5 === 0)
                    // //     markUpComponent.push(<br />);

                    // //2021.03.26
                    // if (key > 0 && (key + 1) % 5 === 0)
                    //     markUpComponent.push(<>
                    //         <a
                    //             // href={'#quiz-pos-q-' + data.Key}
                    //             // onClick={() => this.OpenQsToggle(data.Key)}
                    //             // key={'jump_' + key}
                    //             onClick={() => this.OpenQsToggle(keyId)}
                    //             href={'#quiz-pos-q-' + keyId}
                    //             key={'jump_' + keyId}
                    //         >[ {data.No} ]</a>
                    //         <br />
                    //     </>);
                    // else
                    //     markUpComponent.push(<>
                    //         <a
                    //             // href={'#quiz-pos-q-' + data.Key}
                    //             // onClick={() => this.OpenQsToggle(data.Key)}
                    //             // key={'jump_' + key}
                    //             onClick={() => this.OpenQsToggle(keyId)}
                    //             href={'#quiz-pos-q-' + keyId}
                    //             key={'jump_' + keyId}
                    //         >[ {data.No} ]</a>
                    //     </>);
                    //#endregion old code - before 2023.10.09

                    //2023.10.09
                    markUpComponent.push(<>
                        <a
                            // href={'#quiz-pos-q-' + data.Key}
                            // onClick={() => this.OpenQsToggle(data.Key)}
                            // key={'jump_' + key}
                            onClick={() => this.OpenQsToggle(keyId)}
                            href={'#quiz-pos-q-' + keyId}
                            key={'jump_' + keyId}
                        >[ {data.No} ]</a>
                        {/* <button
                            className='btn-link'
                            onClick={() => {
                                this.OpenQsToggle(keyId);
                                ScrollToElement('quiz-pos-q-' + keyId);
                            }}
                            key={'jump_' + keyId}
                        >[ {data.No} ]</button> */}
                    </>);
                    if (key > 0 && (key + 1) % 5 === 0)
                        markUpComponent.push(<br />);

                    return null;
                });
            }
            else {
                this.state.qsLeftToAnswer.map((data, key) => {
                    markUpComponent.push(<span key={'noJump_' + key}>[ {data.No} ]</span>);
                    if (key > 0 && (key + 1) % 5 === 0)
                        markUpComponent.push(<br key={'noJump_br_' + key} />);
                    return null;
                });
            }
        }
        return (markUpComponent);
    }

    OpenQsToggle = (index) => {
        // alert(index);
        let qsToggle = this.state.qsToggle;
        this.state.qsToggle.map((data, key) => {
            if (key <= index) {
                if (CheckNullValue(this.state.roomQuestions[key]) !== null)
                    if (this.state.roomQuestions[key].hasOwnProperty("Answer"))
                        qsToggle[key] = true;
            }
            return null;
        });
        this.setState({
            qsToggle: qsToggle,
        });
    }

    //2021.03.30
    FindTag = (idx = -1) => {
        if (idx > -1 && this.state.roomQuestions[idx].hasOwnProperty('Tags')) {
            if (this.state.roomQuestions[idx].Tags === undefined || this.state.roomQuestions[idx].Tags === 'undefined')
                return this.FindTag(idx - 1);
            else
                return this.state.roomQuestions[idx].Tags;
        }
        return '';
    }

    //2021.04.05
    // CheckParticipatedDate = async () => {
    //     if (this.props.viewHistoryQuiz === null) { return moment(); }
    //     else {

    //     }
    // }

    render = () => {
        // if (this.state.link) {
        //     return <Link to={this.state.linkUrl} />
        // }
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirectLink} />);
        }
        else {
            return (
                <>
                    <div style={{
                        backgroundColor: 'transparent',
                        // backgroundColor: 'lavender',
                        // height: this.state.isQuizStarted ? 'auto' : '100%',
                        height: !this.state.isQuizFullyLoaded ? '100%' : 'auto',
                        // height: 'auto',
                        width: '100%',
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 0,
                        // paddingTop: 25,
                        paddingTop: window.innerWidth < 576 ? 0 : 25,   //2020.12.29
                    }}>
                        <div className="container" style={{
                            maxWidth: '650px',
                            backgroundColor: 'lavender',
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 5
                        }}>
                            <div className="row" key='quizLive'>
                                <aside className="col-sm-12">
                                    {/* <p style={{
                                    color: 'black', fontSize: 35, textAlign: 'center', fontWeight: 'bold',
                                }}>iKEY Kidz (WEB) Live Quiz ({this.state.roomCode}) </p> */}

                                    <div className="card" style={{ backgroundColor: '#007bff' }}>
                                        <article className="card-body text-center">

                                            {/* <span style={{
                                                color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'normal',
                                            }}>iKEY Kidz (WEB)<br />{" Live Quiz (" + this.state.roomCode + ") "} </span> */}

                                            {/* <span style={{
                                                color: 'white', fontSize: 18, textAlign: 'center', fontWeight: 'bold',
                                                float: "left"
                                            }}>iKEY Kidz (WEB) {" Live Quiz (" + this.state.roomCode + ") "}</span> */}

                                            <span style={{
                                                color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                                float: "left"
                                            }}>{"Live Quiz (" + this.state.roomCode + ") "}</span>

                                            <LocalizationSwitcher
                                                Locale={this.props.Locale}
                                                SetLocaleSetting={this.props.SetLocaleSetting}
                                            />

                                        </article>
                                    </div>

                                    {/* <span>&nbsp;</span> */}

                                    {
                                        this.state.isRoomInfoLoaded && this.state.isQuizFullyLoaded ?
                                            <>
                                                <div className="card">
                                                    <article className="card-body">

                                                        <div className="row custyle">
                                                            <table className="table table-striped custab table-result">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>{Locale("room-code", this.props.Locale)} {this.state.roomCode}</th>
                                                                        <td>{this.state.roomTitle}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{Locale("subject", this.props.Locale)}</th>
                                                                        <td>{this.state.roomInfoModal.Subject}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{String(Locale("label-grade", this.props.Locale)).replace(new RegExp(' ', 'g'), '')}</th>
                                                                        <td>{this.state.roomInfoModal.Grade}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{Locale("no-of-qs", this.props.Locale)}</th>
                                                                        <td>{this.state.roomInfoModal.QnQty}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{Locale("total-duration", this.props.Locale)}</th>
                                                                        <td><span className="blink" style={{ fontWeight: 'bold', color: 'red' }}>{this.FormatedDuration(this.state.roomInfoModal.Duration)}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{Locale("held-date", this.props.Locale)}</th>
                                                                        <td>{
                                                                            // moment(this.state.todayDT).format("ll")
                                                                            //2021.07.19
                                                                            this.state.roomInfoModal.hasOwnProperty('DateStart') ?
                                                                                this.state.roomInfoModal.DateStart === '' ?
                                                                                    moment(this.state.roomInfoModal.Date).format("ll")     //2021.09.14
                                                                                    :
                                                                                    this.state.roomInfoModal.DateStart === this.state.roomInfoModal.DateEnd ?
                                                                                        moment(this.state.roomInfoModal.DateStart).format("ll")
                                                                                        : moment(this.state.roomInfoModal.DateStart).format("ll") + ' ~ ' + moment(this.state.roomInfoModal.DateEnd).format("ll")
                                                                                : moment(this.state.todayDT).format("ll")
                                                                            // this.state.roomInfoModal.Date     //2021.09.14
                                                                        }</td>
                                                                    </tr>
                                                                    {
                                                                        this.state.liveQuizStartTime !== '' && this.state.liveQuizStartTime !== '_none' ?
                                                                            <tr>
                                                                                <th>{Locale("start-time", this.props.Locale)}</th>
                                                                                <td>{moment(this.state.liveQuizStartTime, 'YYYY-MM-DD HH:mm:ss').format("LT")}</td>
                                                                            </tr>
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.state.liveQuizEndTime !== '' && this.state.liveQuizEndTime !== '_none' ?
                                                                            <tr>
                                                                                <th>{Locale("end-time", this.props.Locale)}</th>
                                                                                <td>{moment(this.state.liveQuizEndTime, 'YYYY-MM-DD HH:mm:ss').format("LT")}</td>
                                                                            </tr>
                                                                            : null
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </article>
                                                </div>

                                                {
                                                    !this.state.isQuizStarted ?
                                                        <>
                                                            <span>&nbsp;</span>
                                                            <div className="card">
                                                                <article className="card-body" style={{ textAlign: "center" }}>
                                                                    {
                                                                        // this.state.isQuizQuestionsLoaded ?
                                                                        !this.state.loadingInProgress ?
                                                                            this.state.liveQuizStatus === 'init' || !this.state.isQuizStartTimeReached || this.state.isCheckingIsDoneStatus ?
                                                                                <div dangerouslySetInnerHTML={{ __html: Locale("waiting-to-start", this.state.subjectLocale) }} />
                                                                                :
                                                                                <button type="button" className="btn btn-primary btn-block"
                                                                                    onClick={this.handleStartQuiz}
                                                                                >{Locale("begin", this.state.subjectLocale)}</button>
                                                                            :
                                                                            <LoadingIndicator />
                                                                    }
                                                                </article>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                <div className="card">
                                                    <article className="card-body">
                                                        <button type="button" className="btn btn-primary btn-block"
                                                            onClick={this.GotoHome}
                                                        >{Locale("back-to-home", this.props.Locale)}</button>
                                                    </article>
                                                </div>
                                            </>
                                            :
                                            // <div className="card">
                                            //     <article className="card-body">

                                            //         <LoadingIndicator />

                                            //     </article>
                                            // </div>
                                            <div className="card">
                                                <article className="card-body">

                                                    <div className="row">
                                                        <div className="col col-sm-12"><p>{Locale("loading-qs", this.props.Locale)}</p></div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col col-sm-12">
                                                            <ProgressBar animated now={100} />
                                                        </div>
                                                    </div>

                                                </article>
                                            </div>
                                    }

                                    <span id="quiz-pos-q-0">&nbsp;</span>

                                    {
                                        this.state.isQuizStarted && this.state.isQuizFullyLoaded ?  //2022.06.15
                                            // this.state.isQuizStarted ?  //&& !this.state.toggleResultProcessing ?
                                            !this.state.toggleResultProcessing ?
                                                this.state.roomQuestions.map((data, key) => {
                                                    // alert(JSON.stringify(data[key]));
                                                    if (data !== undefined) {
                                                        return (
                                                            <div key={'Question_' + key + '_section'}>
                                                                <div className="card card-qs"
                                                                    // id={"Q" + (key + 1)}
                                                                    // key={"Q" + (key + 1)}
                                                                    hidden={!this.state.qsToggle[key]}
                                                                >
                                                                    {/* <article className="card-body"> */}
                                                                    {
                                                                        /*
                                                                        data.hasOwnProperty('SpecialMode') ?
                                                                            data.SpecialMode === 'PopupAnswers' ?
                                                                                <>
                                                                                    <span>PopupAnswers</span>
                                                                                    <Question_SP_PA
                                                                                        id={key}
                                                                                        total={this.state.roomInfoModal.QnQty}
                                                                                        data={Object.assign({}, data)}
                                                                                        scrollTo={this.handleScrollToPos}
                                                                                        nextQs={this.handleNextQuestion}
                                                                                        setAnswer={this.handleSetAnswer}
                                                                                        ASelectId={this.state.qsSelectedAnswers[key]}
                                                                                        // Enabled={!this.state.isQuizDoneAnswered && this.state.qsSelectedAnswers[key] === "-1"}
                                                                                        InActive={this.state.qsNotActiveStat[key]}
                                                                                        ShowResultBtn={this.state.toggleShowResult}
                                                                                        // HasAnswer={this.state.qsHasAnswer[key]}
                                                                                        subject={this.state.roomInfoModal.Subject}
                                                                                    />
                                                                                </>
                                                                                : <span>error showing question #{key + 1}</span>
                                                                            :
                                                                            <Question
                                                                                id={key}
                                                                                total={this.state.roomInfoModal.QnQty}
                                                                                data={Object.assign({}, data)}
                                                                                scrollTo={this.handleScrollToPos}
                                                                                nextQs={this.handleNextQuestion}
                                                                                // skipQs={this.handleSkipQuestion}
                                                                                setAnswer={this.handleSetAnswer}
                                                                                ASelectId={this.state.qsSelectedAnswers[key]}
                                                                                // Enabled={!this.state.isQuizDoneAnswered && this.state.qsSelectedAnswers[key] === "-1"}
                                                                                InActive={this.state.qsNotActiveStat[key]}
                                                                                ShowResultBtn={this.state.toggleShowResult}
                                                                            // HasAnswer={this.state.qsHasAnswer[key]}
                                                                            />
                                                                            */

                                                                        data.hasOwnProperty('Content') ?
                                                                            this.GetQuestionComponent(data, key)
                                                                            : null
                                                                    }
                                                                    {/* </article> */}
                                                                </div>

                                                                <span
                                                                    id={
                                                                        'quiz-pos-q-'
                                                                        + (this.state.roomQuestions[key].hasOwnProperty('SpecialMode') ?
                                                                            String(this.state.roomQuestions[key].SpecialMode).split(';')[0] !== 'Comprehension' ?
                                                                                String(this.state.roomQuestions[key].SpecialMode).split(';')[1].split(',')[1]
                                                                                : key + 1
                                                                            : key + 1)
                                                                    }
                                                                    // hidden={
                                                                    //     !this.state.qsToggle[key]}>&nbsp;{
                                                                    //     (this.state.roomQuestions[key].hasOwnProperty("SpecialMode") ?
                                                                    //         this.state.roomQuestions[key].SpecialMode.split(";")[0] !== 'Comprehension' ?
                                                                    //             this.state.roomQuestions[key].SpecialMode.split(";")[1].split(",")[1]
                                                                    //             : key + 1
                                                                    //         : key + 1)
                                                                    // }</span>
                                                                    hidden={!this.state.qsToggle[key]}>&nbsp;</span>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })
                                                : null
                                            :
                                            null
                                    }

                                    {
                                        this.state.isQuizStarted && !this.state.toggleShowResult && !this.state.toggleResultProcessing ?
                                            this.state.isAllAnswered ?
                                                this.state.isQuizFullyLoaded ?
                                                    <>
                                                        <span>&nbsp;</span>
                                                        <div className="card">
                                                            <article className="card-body">
                                                                <button type="button" className="btn btn-primary btn-block"
                                                                    onClick={this.handleSubmitPaper}
                                                                    disabled={!this.state.ctTimerActive}
                                                                >{Locale("submit-and-show-result", this.state.subjectLocale)}</button>
                                                            </article>
                                                        </div>
                                                    </>
                                                    : null
                                                :
                                                //=== Quiz Room - Remaining Question (Navigation/Clickable) Ui ===//
                                                // this.state.isQuizQuestionsLoaded && !this.state.isQuizDoneAnswered ?
                                                this.state.isQuizQuestionsLoaded && !this.state.isQuizDoneAnswered && this.state.isQuizFullyLoaded ?    //2022.06.15
                                                    <>
                                                        <span>&nbsp;</span>
                                                        <div className="card">
                                                            <article className="card-body" style={{ textAlign: "center" }}>
                                                                {/* <span>
                                                                    There {this.state.qsQtyToAnswer > 1 ? " are " : " is "}
                                                                    <b>{this.state.qsQtyToAnswer}</b> {" "}
                                                                question{this.state.qsQtyToAnswer > 1 ? "s " : " "}
                                                                remaining to be answered.
                                                                </span> */}
                                                                <div dangerouslySetInnerHTML={{ __html: this.RemainingQtyText() }} />
                                                                {/* <span>{this.RemainingQtyText()}</span> */}
                                                                {this.RemainingQsComponents()}
                                                            </article>
                                                        </div>
                                                    </>
                                                    : null
                                            : null
                                    }

                                    {
                                        //=== Quiz Room - Remaining Question (Static/Non-clickable) Ui ===//
                                        !this.state.isQuizStarted && this.state.isQuizQuestionsLoaded && !this.state.toggleResultProcessing ?
                                            // !this.state.isAllAnswered && !(this.state.liveQuizStatus === 'init') && !this.state.isQuizDoneAnswered ?
                                            // this.state.liveQuizStatus !== 'ended' && !this.state.isQuizDoneAnswered ?
                                            this.state.liveQuizStatus !== 'ended' && !this.state.isQuizDoneAnswered && this.state.isQuizFullyLoaded ?   //2022.06.15
                                                // !this.state.loadingInProgress ?
                                                <>
                                                    <span>&nbsp;</span>
                                                    <div className="card">
                                                        <article className="card-body" style={{ textAlign: "center" }}>
                                                            {/* <span>
                                                                There {this.state.qsQtyToAnswer > 1 ? " are " : " is "}
                                                                <b>{this.state.qsQtyToAnswer}</b> {" "}
                                                                question{this.state.qsQtyToAnswer > 1 ? "s " : " "}
                                                                remaining to be answered.
                                                            </span> */}
                                                            <div dangerouslySetInnerHTML={{ __html: this.RemainingQtyText() }} />
                                                            {/* <span>{this.RemainingQtyText()}</span> */}
                                                            {this.RemainingQsComponents()}
                                                            {/* <div dangerouslySetInnerHTML={{ __html: this.RemainingQsComponents() }} /> */}
                                                        </article>
                                                    </div>
                                                </>
                                                : null
                                            : null
                                    }

                                    {
                                        //=== Quiz Room - Result Ui ===//
                                        // this.state.toggleShowResult && !this.state.toggleResultProcessing ?
                                        this.state.toggleShowResult && !this.state.toggleResultProcessing && this.state.isQuizFullyLoaded ?   //2022.06.15
                                            <>
                                                <div className="card">
                                                    <article className="card-body">
                                                        <button type="button" className="btn btn-primary btn-block"
                                                            onClick={this.GotoHome}
                                                        >{Locale("back-to-home", this.props.Locale)}</button>
                                                    </article>
                                                </div>
                                                <span id="quiz-pos-q-result">&nbsp;</span>

                                                <div className="card" style={{ backgroundColor: '#007bff' }}>
                                                    <article className="card-body text-center">

                                                        <span style={{
                                                            color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                                            float: "left"
                                                        }}>{Locale("quiz-result", this.props.Locale)}</span>

                                                        <LocalizationSwitcher
                                                            Locale={this.props.Locale}
                                                            SetLocaleSetting={this.props.SetLocaleSetting}
                                                        />
                                                    </article>
                                                </div>

                                                <div className="card">
                                                    <article className="card-body" style={{ textAlign: 'center' }}>

                                                        <div className="row custyle">
                                                            <table width="100%" className="table custab table-result">
                                                                <tbody>
                                                                    <tr>
                                                                        <th valign='top'>{Locale("your-name", this.props.Locale)}</th>
                                                                        <td valign='top'>{this.props.profile.Name}{
                                                                            this.props.profile.Email !== null ?
                                                                                <><br /><span style={{ fontSize: 12, color: 'gray', fontWeight: 'bold' }}>({String(this.props.profile.Email).trim()})</span></>
                                                                                : null
                                                                        }</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{Locale("your-grade", this.props.Locale)}</th>
                                                                        <td>{this.props.profile.Grade}</td>
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <th>{Locale("participated-date", this.props.Locale)}</th>
                                                                        <td>{this.state.ParticipatedDate}</td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <span height="25"></span>

                                                        <div className="row custyle">
                                                            <table width="100%" className="table custab table-result">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>{Locale("quiz-score", this.props.Locale)}</th>
                                                                        <td><span className="result-primary">{this.GetScore()}%</span></td>
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <th>{Locale("quiz-total-time-consumed", this.props.Locale)}</th>
                                                                        <td>{this.FormatedDuration(this.state.elapsedTime)} ({Number(this.state.elapsedTime).toFixed(1)} {Locale("time-sec", this.props.Locale)})</td>
                                                                    </tr> */}
                                                                    <tr>
                                                                        <th>{Locale("quiz-total-correct", this.props.Locale)}</th>
                                                                        <td><span className="result-primary">{this.GetCorrect()}</span> / {this.state.roomInfoModal.QnQty}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{Locale("quiz-total-wrong", this.props.Locale)}</th>
                                                                        <td><span className="result-wrong">{this.state.roomInfoModal.QnQty - this.GetCorrect()}</span> / {this.state.roomInfoModal.QnQty}</td>
                                                                    </tr>
                                                                    {
                                                                        // this.state.CategorizedResults_Html.length > 0 ?
                                                                        //     <div dangerouslySetInnerHTML={{ __html: this.state.CategorizedResults_Html }}></div>
                                                                        //     : null
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <span height="25"></span>

                                                        <div className="row custyle">
                                                            <table width="100%" className="table custab table-result">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>{Locale("room-code", this.props.Locale)} {this.state.roomCode}</th>
                                                                        <td>{this.state.roomTitle}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{Locale("label-grade", this.props.Locale)}</th>
                                                                        <td>{this.state.roomInfoModal.Grade}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{Locale("room-date", this.props.Locale)}</th>
                                                                        {/* <td>{moment(this.state.todayDT).format("ll")}</td> */}
                                                                        <td>
                                                                            {
                                                                                //2023.11.16
                                                                                this.state.roomInfoModal.hasOwnProperty('DateStart') ?
                                                                                    this.state.roomInfoModal.DateStart === '' ?
                                                                                        moment(this.state.roomInfoModal.Date).format("ll")
                                                                                        :
                                                                                        this.state.roomInfoModal.DateStart === this.state.roomInfoModal.DateEnd ?
                                                                                            moment(this.state.roomInfoModal.DateStart).format("ll")
                                                                                            : moment(this.state.roomInfoModal.DateStart).format("ll") + ' ~ ' + moment(this.state.roomInfoModal.DateEnd).format("ll")
                                                                                    : moment(this.state.todayDT).format("ll")
                                                                            }
                                                                            {
                                                                                //2023.11.16
                                                                                CheckObjectNumber(this.state.RoomResultRootData, 'RoomId') === 0 ? null :
                                                                                    CheckObjectNullValue(this.state.RoomResultRootData, 'SubmittedOnUTC') === null ? null :
                                                                                        <>
                                                                                            <br />
                                                                                            <span style={{ fontSize: 12, color: 'gray', fontWeight: 'bold' }}>{
                                                                                                '(' + Locale("room-submitted-date", this.props.Locale) + ': '
                                                                                                + moment.utc(CheckObjectStringEmpty(this.state.RoomResultRootData, 'SubmittedOnUTC')).local().format('lll')
                                                                                                + ')'
                                                                                            }</span>
                                                                                        </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <span height="25"></span>

                                                        {
                                                            //2021.04.06
                                                            this.state.hasTags ?
                                                                <div className="row custyle">
                                                                    <table width="100%" className="table custab">
                                                                        <thead>
                                                                            <tr>
                                                                                <th width="15%">{'#'}</th>
                                                                                <th width="35%">{Locale("category", this.props.Locale)}</th>
                                                                                <th width="50%">{Locale("result", this.props.Locale)}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                this.state.TagCategorizedResults.length > 0 ?
                                                                                    this.state.TagCategorizedResults.map((data, key) => {
                                                                                        return (
                                                                                            <tr key={'result_' + key}>
                                                                                                <td>{key + 1}</td>
                                                                                                <td>{data.TagName}</td>
                                                                                                <td><span className="result-primary">{data.TotalCorrect}</span> / {data.TotalQty} (<span className="result-wrong">{(data.TotalQty - data.TotalCorrect) + ' ' + Locale("wrong", this.props.Locale)}</span>)</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                    : null
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : null
                                                        }

                                                        <span height="25"></span>

                                                        <div className="row custyle">
                                                            <table className="table table-striped custab" border='1'>
                                                                <thead>
                                                                    <tr>
                                                                        <th width="10%">{Locale("question", this.props.Locale)}</th>
                                                                        {/* <th width="20%">{Locale("tag", this.props.Locale)}</th>
                                                                        <th width="30%">{Locale("result", this.props.Locale)}</th> */}
                                                                        {/* <th width="50%">{Locale("result", this.props.Locale)}</th> */}
                                                                        {/* <th width="50%" colSpan="2">{Locale("result", this.props.Locale)}</th> */}
                                                                        {/* <th width="50%" colSpan={this.state.roomQuestions[key].hasOwnProperty('Tags') ? "2" : "1"}>{Locale("result", this.props.Locale)}</th> */}
                                                                        {/* <th width="50%" style={{ columnSpan: this.state.hasTags ? 1 : 2 }}>{Locale("result", this.props.Locale)}</th> */}
                                                                        {
                                                                            this.state.hasTags ?
                                                                                <th width="60%" colSpan='2'>{Locale("result", this.props.Locale)}</th>
                                                                                :
                                                                                <th width="60%">{Locale("result", this.props.Locale)}</th>
                                                                        }
                                                                        <th width="30%">{Locale("goto", this.props.Locale)}</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {/* <tr style={{ height: 1 }}><td></td><td></td><td></td>{this.state.hasTags ? <td></td> : null}</tr> */}
                                                                    {
                                                                        this.state.qsAnswers.map((data, key) => {
                                                                            if (CheckNullValue(this.state.roomQuestions[key]) === null) {
                                                                                return null;
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <tr
                                                                                        key={"qs_ans_" + key}
                                                                                    >
                                                                                        <td>{key + 1}</td>
                                                                                        {/* {
                                                                                        this.state.roomQuestions[key].hasOwnProperty('Tags') ?
                                                                                            <td>{this.state.roomQuestions[key].Tags}</td>
                                                                                            : null
                                                                                    } */}
                                                                                        {/* <td><b>{data ? <font color="blue">Correct</font> : <font color="red">Wrong</font>}</b></td> */}
                                                                                        {/* <td>{this.GetResultUiElements(data, key)}</td> */}
                                                                                        {/* <td colSpan={
                                                                                        this.state.roomQuestions[key].hasOwnProperty('Tags') ?
                                                                                            (String(this.state.roomQuestions[key].Tags).length > 0 ? "1" : "2")
                                                                                            : "1"
                                                                                    }
                                                                                    >{this.GetResultUiElements(data, key)}</td> */}


                                                                                        {
                                                                                            this.state.hasTags ?
                                                                                                <>
                                                                                                    <td width="10%">{
                                                                                                        this.state.roomQuestions[key].hasOwnProperty('Tags') ?
                                                                                                            String(this.state.roomQuestions[key].Tags).length > 0 ?
                                                                                                                this.state.roomQuestions[key].Tags
                                                                                                                :
                                                                                                                ''
                                                                                                            :
                                                                                                            this.FindTag(key)
                                                                                                    }</td>
                                                                                                    <td width="50%">{this.GetResultUiElements(data, key)}</td>
                                                                                                </>
                                                                                                :
                                                                                                <td>{this.GetResultUiElements(data, key)}</td>
                                                                                        }

                                                                                        {/* <td><a href={"#quiz-pos-q-" + key} >Goto Question {key + 1}</a></td> */}
                                                                                        <td>
                                                                                            {
                                                                                                // let setting = this.state.roomQuestions[key].SpecialMode.split(";");
                                                                                                // let range = setting[1].split(",");
                                                                                                // let qsStart = range[0];
                                                                                                // let qsEnd = range[1];

                                                                                                // JSON.parse(JSON.stringify(this.state.roomQuestions[key])).hasOwnProperty("SpecialMode") ?
                                                                                                this.state.roomQuestions[key].hasOwnProperty("SpecialMode") ?
                                                                                                    String(this.state.roomQuestions[key].SpecialMode).split(";")[0] !== "Comprehension" ?
                                                                                                        // String(this.state.roomQuestions[key].SpecialMode).includes("Comprehension") === false ?
                                                                                                        <a href={"#quiz-pos-q-"
                                                                                                            + (Number(String(this.state.roomQuestions[key].SpecialMode).split(";")[1].split(",")[0]) - 1)
                                                                                                        }>{Locale("goto-qs", this.props.Locale) + " " + (key + 1)}</a>
                                                                                                        :
                                                                                                        <a href={"#quiz-pos-q-" + key}>{Locale("goto-qs", this.props.Locale) + " " + (key + 1)}</a>
                                                                                                    :
                                                                                                    <a href={"#quiz-pos-q-" + key}>{Locale("goto-qs", this.props.Locale) + " " + (key + 1)}</a>

                                                                                                // //2024.02.27
                                                                                                // this.state.roomQuestions[key].hasOwnProperty("SpecialMode") ?
                                                                                                //     String(this.state.roomQuestions[key].SpecialMode).split(";")[0] !== "Comprehension" ?
                                                                                                //         <button className='btn-link' onClick={() => ScrollToElement("quiz-pos-q-"
                                                                                                //             + (Number(String(this.state.roomQuestions[key].SpecialMode).split(";")[1].split(",")[0]) - 1))
                                                                                                //         }>{Locale("goto-qs", this.props.Locale) + " " + (key + 1)}</button>
                                                                                                //         :
                                                                                                //         <button className='btn-link' onClick={() => ScrollToElement("quiz-pos-q-" + key)}>{Locale("goto-qs", this.props.Locale) + " " + (key + 1)}</button>
                                                                                                //     :
                                                                                                //     <button className='btn-link' onClick={() => ScrollToElement("quiz-pos-q-" + key)} >{Locale("goto-qs", this.props.Locale) + " " + (key + 1)}</button>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        })
                                                                    }
                                                                </tbody>

                                                            </table>
                                                        </div>

                                                    </article>
                                                </div>

                                                {/* <span>&nbsp;</span>

                                                <div className="card">
                                                    <article className="card-body">
                                                        <button type="button" className="btn btn-primary btn-block"
                                                            onClick={this.GotoHome}
                                                        > Back to Home </button>
                                                    </article>
                                                </div>

                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span> */}
                                            </>
                                            :
                                            null
                                    }

                                    {
                                        //=== Quiz Room - Result Ui - Processing ===//
                                        this.state.toggleResultProcessing ?
                                            <div className="card">
                                                <article className="card-body">

                                                    <div className="row">
                                                        <div className="col col-sm-12"><p>{Locale("processing-result", this.state.subjectLocale)}</p></div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col col-sm-12">
                                                            <ProgressBar animated now={100} />
                                                        </div>
                                                    </div>

                                                </article>
                                            </div>
                                            : null
                                    }


                                    {
                                        // this.state.isQuizQuestionsLoaded ?
                                        this.state.isQuizFullyLoaded ?
                                            <>
                                                <span>&nbsp;</span>

                                                <div className="card">
                                                    <article className="card-body">
                                                        <button type="button" className="btn btn-primary btn-block"
                                                            onClick={this.GotoHome}
                                                        >{Locale("back-to-home", this.props.Locale)}</button>
                                                    </article>
                                                </div>
                                            </>
                                            : null
                                    }

                                    {
                                        this.state.isQuizFullyLoaded ? null
                                            :
                                            String(this.state.LoadingProgressionText).length > 0 ?
                                                <>
                                                    <span>&nbsp;</span>

                                                    <div className="card">
                                                        <article className="card-body" style={{ textAlign: 'center' }}>
                                                            {this.state.LoadingProgressionText}
                                                        </article>
                                                    </div>
                                                </>
                                                : null
                                    }

                                </aside>
                            </div>
                        </div>

                        {
                            this.state.isQuizFullyLoaded ?
                                <div style={{ height: '200px', width: '100%' }}>
                                    <span>&nbsp;</span>
                                </div>
                                : null
                        }
                    </div>

                    {/* <div className="floating-modal">
                        <article className="card-body">
                            Time Left: {this.ConvertTime(this.state.roomInfoModal.Duration - this.state.elapsedTime)}
                        </article>
                    </div> */}
                </>
            );
        }
    }
}