import React from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import RegistrationInfo from './RegistrationInfo';
import ProfileRemoval from './ProfileRemoval';
import QuizHistoryMonitor from './QuizHistoryMonitor';
import _ from 'lodash';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { GlobalSetting } from '../../components/GlobalSetting';
import { CheckBoolean, CheckObjectBoolean, Delay } from '../../components/GlobalFunctions';      //2023.09.11

let Toggle = {
    Registration: 0,
    ProfileRemoval: 1,
    QuizHistoryMonitor: 2,
    List: 3,
    Question: 4,
    Etc: 5,
    // EventCertificateStateRecovery: 6,
}

export default class QuizAdminTool extends React.Component {

    state = {
        redirect: false,
        redirectLink: '',

        toggleMenuOption: Toggle.Etc,

        // quizList_DefaultText: "",
        isFireStoreQuizExists: false,
        quizListInFireStore: [],

        //2021.01.14
        actionInProgress: false,

        //2021.04.07
        PEList_Cert_Progression: [],
        PEList_Cert_Progression_Text: '',
        PEList_Cert_Progression_Progress: '',
        EventDetails: [],

        //2021.04.20
        SearchAndReApplyCert_StudentEmail: '',
        SearchAndReCalculateScoreRecord_Progress: '',

        //2021.08.30
        SelectedEventCode: null,
        // SearchAndReApplyAllStudentCert_QuizDate: '',
        // SearchAndReApplyAllStudentCert_QuizRoomId: '',
        progressStatusText: '',

        //2022.06.11
        EventList: [],
        EventList_lastVisible: null,
        IsEventListLoaded: false,

        //2022.06.27
        CompleteApiUrlWithParams: '',

        //2022.07.29
        DataMigration_SelectedOption: -1,
        EventRegData: [
            // { loc: 'SmartNewton_RegistrationData', target: 'SMARTNEWTON', event: 'abacus_challenge_smartnewton_2021_0206' },
            // { loc: 'RegistrationData', target: 'SIM', event: 'abacus_challenge_smartnewton_2021_0206' },

            //SPOT 2020
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SIM', target: 'NEW', event: 'spot_2020_sim_1209_1211', eventName: 'SIM - SPOT 2020' },

            //Abacus Challenge
            // { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SMARTNEWTON', target: 'NEW', event: 'abacus_challenge_smartnewton_2021_0206' },

            //Secondary BM
            // { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'COLIN', target: 'NEW', event: 'colin_bm_2021_0130' },

            //全马线上考试
            // { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'PTSMC', target: 'NEW', event: 'ptsmc_primary_test_2021_0306_0307' },

            //SPOT 2021 (1ST QUARTER)
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SIM', target: 'NEW', event: 'spot_2021_sim_0417_1st_sem', eventName: 'SIM - SPOT 2021 (1ST QUARTER)' },

            //JULY ASSESSMENT (MEGA)
            // { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SIM', target: 'NEW', event: '4c3fe3deaf78485a839f6ec350a76a20' },
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'OTHER', target: 'NEW', event: '4c3fe3deaf78485a839f6ec350a76a20', eventName: 'OTHER - JULY ASSESSMENT (MEGA)' },

            //SJKC SPOT 2021
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SIM', target: 'NEW', event: 'ced0217d39cb49a4bb810e4b1abd04ae', eventName: 'SIM - SJKC SPOT 2021' },
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'OTHER', target: 'NEW', event: 'ced0217d39cb49a4bb810e4b1abd04ae', eventName: 'OTHER - SJKC SPOT 2021' },

            //Youth Chinese Test【国际汉语】
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SIM', target: 'NEW', event: '9ca16982f8b845b8bc2831bb05fe275d', eventName: 'SIM - Youth Chinese Test【国际汉语】' },
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'OTHER', target: 'NEW', event: '9ca16982f8b845b8bc2831bb05fe275d', eventName: 'OTHER - Youth Chinese Test【国际汉语】' },

            //SK&SJKT SPOT 2021
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SIM', target: 'NEW', event: 'e6c4de5a2b27406eb5d7d6e3db36fd74', eventName: 'SIM - SK&SJKT SPOT 2021' },
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'OTHER', target: 'NEW', event: 'e6c4de5a2b27406eb5d7d6e3db36fd74', eventName: 'OTHER - SK&SJKT SPOT 2021' },

            //Preschool SPOT 2021
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'LITTLEOPPY', target: 'NEW', event: '783f207e4ab84f52af5494eac658bc8a', eventName: 'LITTLEOPPY - Preschool SPOT 2021' },

            //SJKC SPOT 2021 终极场
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SIM', target: 'NEW', event: '8a99d6952ca0470481834023672e8d43', eventName: 'SIM - SJKC SPOT 2021 终极场' },

            //SPOT 2021 AI 智能作文争霸赛
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SIM', target: 'NEW', event: '7565a9729ecb4747b665b38c9652982e', eventName: 'SIM - SPOT 2021 AI 智能作文争霸赛' },

            //SPOT 2022 小学线上自我挑战比赛 (SJKC)
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SIM', target: 'NEW', event: '5707df5e48d44772ba5d694ea7658776', eventName: 'SIM - SPOT 2022 小学线上自我挑战比赛 (SJKC)' },

            //SPOT 2022 Pertandingan Cabaran Diri (SK)
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SIM', target: 'NEW', event: '51e5dfdb6fbd498ca8e89cdd6d5ad62a', eventName: 'SIM - SPOT 2022 Pertandingan Cabaran Diri (SK)' },

            //SPOT 2022 幼儿线上自我测试 (幼儿组）
            { from: 'LiveQuiz_RegData', to: 'LiveQuiz_NewUser_RegData', source: 'SIM', target: 'NEW', event: '5fc9181ff9214cebb17ab184e54b2281', eventName: 'SIM - SPOT 2022 幼儿线上自我测试 (幼儿组）' },
        ],
    }

    componentDidMount() {
        this.LoadEventList();
    }

    //2022.06.17
    CheckValue = (dataSet, value) => {
        return dataSet[value] !== null && dataSet[value] !== undefined ? dataSet[value] : null;
    }

    //2022.06.17
    DownloadTxtFile = (dataSet, filename) => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(dataSet)], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = filename + '.txt';
        document.body.appendChild(element);
        element.click();
    };

    handleGoBack = (loc) => {
        this.setState({
            redirect: true,
            redirectLink: loc,
        });
    }

    GoBack = () => {
        this.handleGoBack('/admin');
    }

    // GotoQuizManagement = () => {
    //     this.handleGoBack('/admin/quiz/management');
    // }

    handleNull = () => {
        alert("NULL");
    }

    ToggleList = (setting) => {
        this.setState({
            toggleMenuOption: setting,
        }, async () => {
            switch (setting) {
                case Toggle.Registration:
                    break;
                case Toggle.List:
                    await this.FetchQuizSetList();
                    break;
                case Toggle.ManageQuestion:
                    break;
                case Toggle.Etc:
                    break;
                default:
                    break;
            }
        });
    }

    FetchQuizSetList = async () => {
        await this.props.firestore.collection("QuizBank").doc("Bank").collection("Quiz")
            .get()
            .then(querySnapshot => {
                // isEmailNotExist = !querySnapshot.exists;
                let quizList = [];
                querySnapshot.forEach((doc) => {
                    quizList.push(doc.data());
                });

                //Save.
                this.setState({
                    quizListInFireStore: quizList,
                    isFireStoreQuizExists: querySnapshot != null,
                }, () => {
                    if (this.state.isFireStoreQuizExists)
                        this.props.SetAlert("Quiz List", JSON.stringify(this.state.quizListInFireStore));
                    else
                        this.props.SetAlert("Quiz List is Empty", "Empty List.");
                });
            })
            .catch(error => {
                this.SetAlert("Error", error.code + "<br />" + error.message);
            });
    }



    //2021.01.13    //temp only
    SaveEventListToFS = async () => {
        return;

        this.setState({ actionInProgress: true });
        // let _modalToSave = Object.assign({}, this.state.EventList);
        this.state.EventList.map(async (data, key) => {
            if (key === this.state.EventList.length - 1) {
                let _modalToSave = Object.assign({}, data);

                //2021.02.10
                _modalToSave.ZoomInfo = data.ZoomInfo !== null ? Object.assign({}, data.ZoomInfo) : null;
                _modalToSave.FacebookInfo = data.FacebookInfo !== null ? Object.assign({}, data.FacebookInfo) : null;

                //2021.07.03
                _modalToSave.Groups = data.Groups !== null ? Object.assign({}, data.Groups) : null;

                await this.props.firestore
                    .collection("LiveQuiz_UpcomingEvents")
                    .doc(moment(data.CreatedDate).valueOf().toFixed(0))
                    .set(_modalToSave)
                    .then(() => {
                        this.props.SetAlert("Done", "Upload Done.<br /><br />LiveQuizUpcomingEvents");
                    })
                    .catch(async (error) => {
                        this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
                    });
            }
        });
        // await this.props.firestore
        //     .collection("LiveQuiz_UpcomingEvents")
        //     .doc("Global")
        //     .set(_modalToSave)
        //     .then(() => {
        //         this.props.SetAlert("Done", "Upload Done.<br /><br />LiveQuizUpcomingEvents");
        //     })
        //     .catch(async (error) => {
        //         this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
        //     });
        this.setState({ actionInProgress: false });
    }

    //2021.01.13    //temp only
    RandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    //2021.01.13    //temp only
    RandomSetEvent = async () => {
        this.setState({ actionInProgress: true });
        let _modalToSave = {
            //Old Property
            EventCode: this.RandomNumber(10000, 99999),
            EventName: "Name #" + String(this.RandomNumber(100000, 200000)),
            //...other properties

            //New Property
            IsPublic: _.sample([true, false]),
            Active: _.sample([true, false]),
            Role: _.sample(['Center', 'Partner', 'Global']),
            UserId: this.RandomNumber(100000, 200000),
        };
        await this.props.firestore
            .collection("LiveQuiz_UpcomingEvents")
            // .doc("Other")
            // .collection("Events")
            // .add(_modalToSave)
            .doc(moment().valueOf().toFixed(0))
            .set(_modalToSave)
            .then(() => {
                // this.props.SetAlert("Done", "Upload Done.<br /><br />LiveQuizUpcomingEvents");
            })
            .catch(async (error) => {
                this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
            });
        this.setState({ actionInProgress: false });
    }

    //2021.01.13    //temp only
    GetRandomEventList = async () => {
        this.setState({ actionInProgress: true });
        await this.props.firestore
            .collection("LiveQuiz_UpcomingEvents")
            // .doc("Other")
            // .collection("Events")
            .where("IsPublic", "==", true)
            .where("Active", "==", true)
            .get()
            .then((querySnapshot) => {
                let profiles = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        profiles.push(doc.data());
                    });
                }
                let texts = [];
                profiles.map((data, key) => {
                    texts += "#" + (key + 1) + " : " + data.EventCode + "<br/>";
                    return null;
                });
                this.props.SetAlert("List (IsPublic = true, Active = true) " + profiles.length, texts);
            })
            .catch(async (error) => {
                this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
            });
        this.setState({ actionInProgress: false });
    }

    //edited 2022.07.29
    //2021.01.14
    DataMigration_RegistrationData = async () => {
        this.setState({ actionInProgress: true });
        let option = this.state.DataMigration_SelectedOption;
        let _array = this.state.EventRegData;
        let _from = _array[option].from;
        let _to = _array[option].to;
        // let _target = _array[option].target;
        let _source = _array[option].source;
        let _eventCode = _array[option].event;
        let _data = [];
        await this.props.firestore
            .collection(_from)
            .doc(_source)
            .collection('List')
            .orderBy('CreatedDate')
            .get()
            .then((querySnapshot) => {
                let profiles = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        profiles.push(doc.data());
                    });
                }
                _data = profiles;

                // //2021.01.14
                // if (option === 0) {  //0 = smart newton.
                //     _data.map((data, key) => {
                //         // if (data.EventCode === 'abacus_challenge_2021_0206')
                //         _data[key].EventCode = 'abacus_challenge_smartnewton_2021_0206';
                //         return null;
                //     });
                // }
                // else if (option === 1) {    //1 = sim.
                //     _data.map((data, key) => {
                //         _data[key].EventCode = 'spot_2020_sim_1209_1211';
                //         return null;
                //     });
                // }

                this.props.SetAlert("List (" + _from + ")", "Total Records : " + profiles.length);
            })
            .catch(async (error) => {
                this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
            });
        await Delay(2000);
        if (_data.length > 0) {
            let _counter = 0;
            for (let key = 0; key < _data.length; key++) {
                let data = _data[key];
                if (data.hasOwnProperty('Uid')) {
                    //move to new loc.
                    await this.props.firestore
                        .collection(_to)
                        // .doc(_target)
                        // .collection("List")
                        .doc(String(data.Uid))
                        .set(data)
                        .then(() => {
                            _counter++;
                            this.props.SetAlert("List (" + _from + ") migrating to List (" + _to + ")", "Total Records Found : " + _data.length + "<br /><hr /><br />Data Migration in progress...<br /><hr /><br />" + (key + 1) + " / " + _data.length + " (" + ((key + 1 / _data.length) * 100).toFixed(2) + "%)");
                        })
                        .catch(async (error) => {
                            this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
                        });

                    //sync with baseDB.
                    await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Registration/Profile/Sync',
                        {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                FirebaseUserId: String(data.Uid),
                                OrganizerIdentity: 'simeducationgroup',
                                EventCode: _eventCode,
                                CenterUserId: '24856532',
                                AuthorId: '6',
                                RegistrationDate: data.hasOwnProperty('CreatedDate') ? String(data.CreatedDate) : '',
                                Profile: data,  //extra, for comparison.
                                Email: String(data.Email),      //2024.05.06
                            }),
                        })
                        .then(response => response.json())
                        .then(rdata => {
                            if (!rdata.success) {
                                console.log(key + ' (Failed) (Sync) | ' + String(data.Uid) + " | " + rdata.message);
                            }
                            else {
                                console.log(key + " (Sync'ed) | " + String(data.Uid) + " | " + rdata.message);
                                console.log(key + " (Verified) | " + String(data.Uid) + " === " + (rdata.data.Id ?? rdata.data.id));
                            }
                        })
                        .catch(error => {
                            console.log(key + ' (Error) (Sync) | ' + String(data.Uid) + " | " + error);
                        });

                    await Delay(500);
                }
            }
            // await Promise.all(
            //     _data.map(async (data, key) => {
            //         if (data.hasOwnProperty('Uid')) {
            //             //move to new loc.
            //             await this.props.firestore
            //                 .collection(_to)
            //                 // .doc(_target)
            //                 // .collection("List")
            //                 .doc(String(data.Uid))
            //                 .set(data)
            //                 .then(() => {
            //                     _counter++;
            //                     this.props.SetAlert("List (" + _from + ") migrating to List (" + _to + ")", "Total Records Found : " + _data.length + "<br /><hr /><br />Data Migration in progress...<br /><hr /><br />" + _counter + " / " + _data.length + " (" + ((_counter / _data.length) * 100).toFixed(2) + "%)");
            //                 })
            //                 .catch(async (error) => {
            //                     this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
            //                 });

            //             //sync with baseDB.
            //             await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Registration/Profile/Sync',
            //                 {
            //                     method: 'POST',
            //                     headers: {
            //                         'Accept': 'application/json',
            //                         'Content-Type': 'application/json',
            //                     },
            //                     body: JSON.stringify({
            //                         FirebaseUserId: String(data.Uid),
            //                         OrganizerIdentity: 'simeducationgroup',
            //                         EventCode: _eventCode,
            //                         CenterUserId: '24856532',
            //                         AuthorId: '6',
            //                         RegistrationDate: data.hasOwnProperty('CreatedDate') ? String(data.CreatedDate) : '',
            //                     }),
            //                 })
            //                 .then(response => response.json())
            //                 .then(data => {
            //                     if (!data.success)
            //                         console.log('(Failed) (Sync) | ' + String(data.Uid) + " | " + data.message);
            //                     else
            //                         console.log("(Sync'ed) | " + String(data.Uid) + " | " + data.message);
            //                 })
            //                 .catch(error => {
            //                     console.log('(Error) (Sync) | ' + String(data.Uid) + " | " + error);
            //                 });
            //         }

            //         // //202101.14     //set joined.   //for SIM only.
            //         // if (option === 1) {
            //         //     let _joinData = {
            //         //         Uid: data.Uid,
            //         //         Date: moment(data.CreatedDate).format('YYYY-MM-DD HH:mm:ss'),
            //         //     };
            //         //     await this.props.firestore
            //         //         .collection("LiveQuizEventJoinedList")
            //         //         .doc("spot_2020_sim_1209_1211")
            //         //         .collection("List")
            //         //         .doc(data.Uid)
            //         //         .set(_joinData)
            //         //         .catch(async (error) => {
            //         //             this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
            //         //         });
            //         // }
            //         return null;
            //     })
            // );
            // await this.props.firestore
            //     .collection("RegData")
            //     .doc(_target)
            //     .collection("List")
            //     .doc()
            //     .set(_regData)
            //     .then(() => {
            //         // _counter++;
            //         // this.props.SetAlert("List (" + _loc + ")", "Total Records : " + _regData.length + "<br /><br />Data Migration Done<br /><br />" + _counter + " / " + _regData.length + " (" + ((_counter / _regData.length) * 100).toFixed(2) + "%)");
            //     })
            //     .catch(async (error) => {
            //         this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
            //     });
            this.props.SetAlert("List (" + _from + ") migrating to List (" + _to + ")", "Total Records : " + _data.length + "<br /><hr /><br /><b>Data Migration has Finished.</b><br /><br />" + _counter + " / " + _data.length + " (" + ((_counter / _data.length) * 100).toFixed(2) + "%)");
        }
        await Delay(1000);
        this.setState({ actionInProgress: false });
    }

    //2021.01.14
    DataMigration_LiveQuizEventJoinedList = async (option) => {
        this.setState({ actionInProgress: true });
        let _array = [
            { from: 'abacus_challenge_2021_0206', to: 'abacus_challenge_smartnewton_2021_0206' },
            { from: 'spot_2020_1209_1211', to: 'spot_2020_sim_1209_1211' },
            { from: 'spot_2021_bm_colin_2021_0130', to: 'colin_bm_2021_0130' },
        ];
        let _from = _array[option].from;
        let _target = _array[option].to;
        let _data = [];
        await this.props.firestore
            .collection("LiveQuizEventJoinedList")
            .doc(_from)
            .collection("List")
            .get()
            .then((querySnapshot) => {
                let profiles = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        profiles.push(doc.data());
                    });
                }
                _data = profiles;
                this.props.SetAlert("List (" + _from + ")", "Total Records : " + profiles.length);
            })
            .catch(async (error) => {
                this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
            });
        if (_data.length > 0) {
            let _counter = 0;
            await Promise.all(
                _data.map(async (data, key) => {
                    await this.props.firestore
                        .collection("LiveQuizEventJoinedList")
                        .doc(_target)
                        .collection("List")
                        .doc(String(data.Uid))
                        .set(data)
                        .then(() => {
                            _counter++;
                            this.props.SetAlert("List (" + _from + ")", "Total Records Found : " + _data.length + "<br /><br />Data Migration in progress...<br /><br />" + _counter + " / " + _data.length + " (" + ((_counter / _data.length) * 100).toFixed(2) + "%)");
                        })
                        .catch(async (error) => {
                            this.props.SetAlert("Error", error.code + "<br /><br />" + error.message);
                        });
                    return null;
                })
            );
            this.props.SetAlert("List (" + _from + ")", "Total Records : " + _data.length + "<br /><br /><b>Data Migration has Finished.</b><br /><br />" + _counter + " / " + _data.length);
        }
        this.setState({ actionInProgress: false });
    }

    //2021.01.19
    SearchAndInsert_LiveQuiz_UniqueRoomCode = async (_dateArray) => {
        this.setState({ actionInProgress: true });
        // let option = 0;
        // let _array = [
        //     { from: 'abacus_challenge_2021_0206', to: 'abacus_challenge_smartnewton_2021_0206' },
        //     { from: 'spot_2020_1209_1211', to: 'spot_2020_sim_1209_1211' },
        //     { from: 'spot_2021_bm_colin_2021_0130', to: 'colin_bm_2021_0130' },
        // ];
        // let _from = _array[option].from;
        // let _target = _array[option].to;

        for (var i = 0; i < _dateArray.length; i++) {

            let _date = _dateArray[i];

            // let _date = '20210120';
            let _roomDetails = [];
            let _roomStates = [];
            let _roomCodes = [];

            await this.props.dbLiveQuiz
                .ref('pkquiz/' + _date + '/pkquiz-room-detail')
                .once('value', snapshot => {
                    // if (snapshot.exists())
                    // alert(JSON.stringify(snapshot.val()));
                    // _roomDetails = snapshot.val();
                    // let _array = snapshot.val();
                    // _array.map((data, key) => {
                    //     return _roomDetails.push(data);
                    // })                
                    // _roomDetails = Object.entries(snapshot.val());
                    _roomDetails = _(snapshot.val()).values().value();
                    // alert(JSON.stringify(_roomDetails));
                });

            await this.props.dbLiveQuiz
                .ref('pkquiz/' + _date + '/pkquiz-live')
                .once('value', snapshot => {
                    // if (snapshot.exists())
                    // alert(JSON.stringify(snapshot.val()));
                    // _roomStates = snapshot.val();
                    // let _array = snapshot.val();
                    // _array.map((data, key) => {
                    //     return _roomStates.push(data);
                    // })
                    // _roomStates = Object.entries(snapshot.val());
                    _roomStates = _(snapshot.val()).values().value();
                });

            await this.props.dbLiveQuiz
                .ref('pkquiz/' + _date + '/pkquiz-room-code')
                .once('value', snapshot => {
                    // if (snapshot.exists())
                    // alert(JSON.stringify(snapshot.val()));

                    // _roomCodes = snapshot.val();
                    // let _array = snapshot.val();
                    // _array.map((data, key) => {
                    //     return _roomCodes.push(data);
                    // })
                    // _roomCodes = _(snapshot.val()).values().value();
                    let Keys = _(snapshot.val()).keys().value();
                    let Values = _(snapshot.val()).values().value();
                    Keys.map((data, key) => {
                        return _roomCodes.push({ key: Keys[key], value: Values[key] });
                    });
                });

            // alert(
            //     '_roomDetails : ' + _roomDetails.length + '\n' +
            //     '_roomStates : ' + _roomStates.length + '\n' +
            //     '_roomCodes : ' + _roomCodes.length + '\n'
            // );

            if (_roomDetails.length > 0 && _roomStates.length > 0) {
                // let _subjectList = [];
                // await this.props.dbLiveQuiz
                //     .ref('pkquiz-questions/_Subjects')
                //     .once('value')
                //     .then(function (dataSnapshot) {
                //         // handle read data.
                //         var subjects = dataSnapshot.val().toString();
                //         var subjectArray = subjects.split(';');
                //         subjectArray.map((data) => {
                //             if (data.length > 0) {
                //                 _subjectList.push(data);
                //             }
                //             return null;
                //         });
                //     });

                _roomDetails.map(async (data, key) => {
                    let _organizer = '';
                    let _subjectName = '';
                    let _subject = String(data.Subject).toLowerCase();
                    if (_subject.includes('abacus')) {
                        _subjectName = 'Abacus';
                        _organizer = 'Smart Newton';
                    }
                    else if (_subject.includes('colin')) {
                        _subjectName = "Colin's BM";
                        _organizer = "The Colin's Learning Centre";
                    }
                    else {
                        _subjectName = String(data.Subject);
                        _organizer = 'SIM';
                    }

                    // alert(JSON.stringify(_roomCodes));
                    // let _roomCodes_Keys = _(_roomCodes).keys().value();
                    let _roomCode = _roomCodes[key].key;
                    let _roomId = _roomCodes[key].value;

                    // alert(
                    //     "_roomCode : " + _roomCode + "\n" +
                    //     "_roomId : " + _roomId + "\n"
                    // );

                    // alert("pkquiz-questions/"
                    //     + String(data.Subject) + "/"
                    //     + String(data.Grade) + "/"
                    //     + String(data.QnSet) + "/"
                    //     + "RoomTitle");

                    let _roomTitle = '';
                    await this.props.dbLiveQuiz
                        .ref("pkquiz-questions/"
                            + data.Subject + "/"
                            + data.Grade + "/"
                            + data.QnSet + "/"
                            + "RoomTitle"
                        )
                        .once('value', snapshot => {
                            if (snapshot.exists())
                                _roomTitle = snapshot.val().toString();
                        });

                    let _modal = {
                        Date: moment(_date).format('YYYY-MM-DD 00:00:00'),
                        SubjectName: _subjectName,
                        RoomId: Number(_roomId),
                        RoomCode: Number(_roomCode),
                        RoomTitle: _roomTitle,
                        TimeStart: _roomStates[key].QuizStartDT,
                        TimeEnd: _roomStates[key].QuizEndDT,
                        Organizer: _organizer,
                        Duration: this.FormatedDuration(_roomDetails[key].Duration),   //2021.01.26
                    };
                    // alert(JSON.stringify(_modal));
                    await this.props.firestore
                        .collection("LiveQuiz_UniqueRoomCode")
                        .doc(_roomId)
                        .set(_modal)
                        .then(() => {
                            // alert('done');
                        })
                        .catch((error) => {
                            alert("Error", error.code + "\n\n" + error.message);
                        });
                    return null;
                });
            }

        }
        this.setState({ actionInProgress: false });
    }

    //2021.01.26
    FormatedDuration = (_totalSeconds) => {
        // // let min = Locale("time-min", this.props.Locale);
        // // let sec = Locale("time-sec", this.props.Locale);
        // let min = 'm';
        // let sec = 's';

        // let totalSeconds = Number(_totalSeconds);

        // var minutes = (totalSeconds / 60).toFixed(3).split('.')[0];
        // var seconds = (totalSeconds % 60).toFixed(0);
        // return minutes + ' ' + min + (seconds > 0 ? (' ' + seconds + ' ' + sec) : '');

        //2022.06.07
        let hr = 'hr';   //Locale("time-hour-full", this.props.Locale);
        let hrs = 'hrs';   //Locale("time-hour-full-s", this.props.Locale);
        let min = 'm';   //Locale("time-min", this.props.Locale);
        let sec = 's';   //Locale("time-sec", this.props.Locale);

        let totalSeconds = Number(_totalSeconds);

        let hours = (totalSeconds / 3600).toFixed(3).split('.')[0];
        let minutes = ((totalSeconds - (hours * 3600)) / 60).toFixed(3).split('.')[0];
        let seconds = (totalSeconds % 60).toFixed(3).split('.')[0];
        return (hours > 0 ? hours + ' ' + (hours > 1 ? hrs : hr) + ' ' : '') + minutes + ' ' + min + ' ' + seconds + ' ' + sec;
    }

    //2021.04.07 ==== ParticipatedEventList ======== start
    SearchAndInsert_LiveQuiz_Cert = async () => {

        this.setState({ actionInProgress: true });

        let progressText = '';
        let progressStatus = '';

        // //Fetch Event Details.
        // let eventDetails = [];
        // await this.props.firestore
        //     .collection('LiveQuiz_UpcomingEvents')
        //     .get()
        //     .then((querySnapshot) => {
        //         let dataArray = [];
        //         if (querySnapshot !== null) {
        //             dataArray = querySnapshot.docs.map(doc => doc.data())[0];
        //             eventDetails = dataArray;
        //         }
        //     });
        // this.setState({ EventDetails: eventDetails });


        // let Events = this.props.Events;

        let UserIdsByEvent = [];

        let Events = [];
        // Events.push(this.props.Events[5]);
        let event = this.props.Events.find(x => x.EventCode === this.state.SelectedEventCode);
        Events.push(event);

        // console.log(JSON.stringify(Events));
        // return null;

        for (let x = 0; x < Events.length; x++) {

            // let eventDetail = Events.find(x => x.EventCode === Events[x].EventCode);
            let event = Events[x];
            // console.log(JSON.stringify(event));

            let startDate = moment(event.DateStart + ' 00:00:00');
            let endDate = moment(event.DateEnd + ' 00:00:00');

            let DaysHeld = [];

            let JoinedDays = 1;
            if (startDate !== endDate) {
                JoinedDays = endDate.diff(startDate, 'days') + 1;
            }
            console.log(
                'DateStart = ' + event.DateStart + '\n' +
                'DateEnd = ' + event.DateEnd + '\n' +
                'JoinedDays = ' + JoinedDays
            );

            //debug status.
            progressText = 'Total Event(s) : ' + Events.length + '<br /><br />'
                + 'Event (' + (x + 1) + ') : ' + event.EventName + '<br />'
                + 'Total held Day(s) : ' + JoinedDays;

            //process per day held by the event.
            for (let i = 0; i < JoinedDays; i++) {

                let eventDate = i > 0 ?
                    moment(event.DateStart).add(i, 'days')
                    : moment(event.DateStart);

                console.log('eventDate = ' + eventDate.format('YYYYMMDD'));

                //Fetch room code(s).
                let quizRoomCodes = [];
                await this.props.dbLiveQuiz
                    .ref('pkquiz/' + eventDate.format('YYYYMMDD') + '/pkquiz-room-code')
                    .once('value', (snapshot) => {
                        if (snapshot.exists && snapshot.val() !== null) {
                            let snapArray = snapshot.val();
                            if (snapArray !== null) {
                                let dataArray = [];
                                snapshot.forEach(snap => {
                                    dataArray.push({
                                        RoomId: snap.val(),
                                        RoomCode: snap.key,
                                    });
                                    return false;
                                });
                                quizRoomCodes = dataArray;
                            }
                        }
                    });
                console.log('quizRoomCodes = ' + JSON.stringify(quizRoomCodes));

                //Fetch personal result(s).
                let participatedStudents = [];
                await this.props.dbLiveQuiz
                    .ref('pkquiz/' + eventDate.format('YYYYMMDD') + '/pkquiz-personal-data/')
                    .once('value', (snapshot) => {
                        if (snapshot.exists && snapshot.val() !== null) {

                            snapshot.forEach(rooms => {
                                // console.log('user = ' + JSON.stringify(rooms));

                                let results = [];
                                rooms.forEach(result => {
                                    // console.log('result = ' + JSON.stringify(result));
                                    let resultKey = Number(result.key);
                                    result = JSON.parse(JSON.stringify(result));
                                    let scoreRecords = String(result['ScoreRecord']).split(';');
                                    results.push({
                                        RoomId: resultKey,
                                        Score: Number(scoreRecords[0]),
                                        TimeElapsed: Number(scoreRecords[1]),
                                        ASelect: result['ASelects'],
                                        QResult: result['QResults'],
                                        IsDone: result['IsDone']
                                    });
                                });
                                participatedStudents.push({
                                    Uid: rooms.key,
                                    Rooms: results,
                                    Date: eventDate.format('YYYY-MM-DD HH:mm:ss')
                                });
                            });
                            // console.log('participatedStudents = ' + JSON.stringify(participatedStudents));
                        }
                    });
                console.log('participatedStudents = ' + participatedStudents.length);

                // for (let p = 0; p < participatedStudents.length; p++) {
                //     console.log('student = ' + participatedStudents[p].Uid
                //         + '\nRooms = ' + participatedStudents[p].Rooms.length
                //         + '\nDate = ' + eventDate.format('YYYYMMDD'));

                //     // this.setState({ PEList_Cert_Progression_Progress: progressStatus });
                // }

                DaysHeld.push(participatedStudents);
            }
            console.log('DaysHeld = ' + DaysHeld.length);

            UserIdsByEvent.push(DaysHeld);
        }

        for (let evt = 0; evt < UserIdsByEvent.length; evt++) {
            for (let day = 0; day < UserIdsByEvent[evt].length; day++) {
                for (let user = 0; user < UserIdsByEvent[evt][day].length; user++) {

                    // if (UserIdsByEvent[evt][day][user].Uid > 'vc0wWnqvdSOCUeSlbV4kpMyYRnP2') {

                    let _studentName = '';
                    let _uid = '';

                    let isEventExists = false;
                    let isCounterExists = false;
                    let counter = 0;
                    let lastUpdatedOnUtc = '';

                    console.log('checking Uid = ' + UserIdsByEvent[evt][day][user].Uid);

                    //check for certificate of current event.
                    await this.props.firestore
                        .collection('LiveQuiz_Certifications')
                        .doc(UserIdsByEvent[evt][day][user].Uid)
                        .collection('Certificates')
                        .where('EventCode', '==', Events[evt].EventCode)
                        .get()
                        .then((querySnapshot) => {
                            let data = [];
                            if (querySnapshot !== null) {
                                data = querySnapshot.docs.map(doc => doc.data())[0];
                            }
                            if (data !== undefined && data.hasOwnProperty('EventCode')) {
                                isEventExists = true;
                            }
                            console.log('Event (' + (isEventExists ? 'exist, new cert record not needed' : 'not-exist') + ') =\n' + JSON.stringify(data));
                        });

                    if (isEventExists === false) {

                        //fetch student name.
                        await this.props.firestore
                            .collection("User")
                            .where('Uid', '==', UserIdsByEvent[evt][day][user].Uid)
                            .get()
                            .then(querySnapshot => {
                                // isEmailNotExist = !querySnapshot.exists;
                                let profile = [];
                                if (querySnapshot !== null) {
                                    profile = querySnapshot.docs.map(doc => doc.data())[0];
                                }
                                if (profile !== undefined && profile.hasOwnProperty('Name')) {
                                    _studentName = profile.Name;
                                }
                                if (profile !== undefined && profile.hasOwnProperty('Uid')) {
                                    _uid = profile.Uid;
                                }
                            });
                        console.log('student name = ' + _studentName);

                        //if FS not found, fetch from RTDB.
                        if (_studentName.length <= 0) {
                            await this.props.dbCommon
                                .ref('Common/' + UserIdsByEvent[evt][day][user].Uid)
                                .once('value', snapshot => {
                                    let profile = [];
                                    if (snapshot !== null) {
                                        profile = snapshot.val();
                                    }
                                    if (profile !== null && profile.hasOwnProperty('_1Name')) {
                                        _studentName = profile._1Name;
                                    }
                                    if (profile !== null && profile.hasOwnProperty('_15Uid')) {
                                        _uid = profile._15Uid;
                                    }
                                });
                        }

                        //only proceed if student uid is proven found.
                        if (_uid.length > 0) {

                            // let isEventExists = false;
                            // let isCounterExists = false;
                            // let counter = 0;
                            // let lastUpdatedOnUtc = '';

                            //check on counter record, to know whether its fresh or existed.
                            await this.props.firestore
                                .collection('LiveQuiz_Certifications')
                                .where('Uid', '==', UserIdsByEvent[evt][day][user].Uid)
                                .get()
                                .then((querySnapshot) => {
                                    let data = [];
                                    if (querySnapshot !== null) {
                                        data = querySnapshot.docs.map(doc => doc.data())[0];
                                    }
                                    if (data !== null && data !== undefined) {
                                        if (data.hasOwnProperty('Counter')) {
                                            isCounterExists = true;
                                            counter = Number(data.Counter);
                                            lastUpdatedOnUtc = data.LastUpdatedOnUtc;
                                        }
                                    }
                                    console.log('Counter (' + (isCounterExists ? 'exist' : 'not-exist') + ') =\n' + JSON.stringify(data) + ' | ' + counter);
                                });

                            // //check for certificate of current event.
                            // await this.props.firestore
                            //     .collection('LiveQuiz_Certifications')
                            //     .doc(UserIdsByEvent[evt][day][user].Uid)
                            //     .collection('Certificates')
                            //     .where('EventCode', '==', Events[evt].EventCode)
                            //     .get()
                            //     .then((querySnapshot) => {
                            //         let data = [];
                            //         if (querySnapshot !== null) {
                            //             data = querySnapshot.docs.map(doc => doc.data())[0];
                            //         }
                            //         if (data !== undefined && data.hasOwnProperty('EventCode')) {
                            //             isEventExists = true;
                            //         }
                            //         console.log('Event (' + (isEventExists ? 'exist, new cert record not needed' : 'not-exist') + ') =\n' + JSON.stringify(data));
                            //     });

                            // let _dateOnUtc = moment(UserIdsByEvent[evt][day][user].Date).add(-8, 'hours').format('YYYY-MM-DD HH:mm:ss');

                            //compare dates.
                            let _fetched_LastUpdatedDateOnUtc = moment(lastUpdatedOnUtc);
                            let _event_LastUpdatedDateOnUtc = moment(UserIdsByEvent[evt][day][user].Date);

                            let _dateOnUtc = '';
                            if (_fetched_LastUpdatedDateOnUtc > _event_LastUpdatedDateOnUtc)
                                _dateOnUtc = _fetched_LastUpdatedDateOnUtc.format('YYYY-MM-DD HH:mm:ss');
                            else
                                _dateOnUtc = _event_LastUpdatedDateOnUtc.format('YYYY-MM-DD HH:mm:ss');

                            //if no record found.
                            if (isCounterExists === false) {
                                //set initial search target.
                                await this.props.firestore
                                    .collection('LiveQuiz_Certifications')
                                    .doc(UserIdsByEvent[evt][day][user].Uid)
                                    .set({
                                        Uid: UserIdsByEvent[evt][day][user].Uid,
                                        Counter: 1,
                                        LastUpdatedOnUtc: _dateOnUtc
                                    });
                                console.log('new record created. | Counter = 1');
                            }
                            else if (isCounterExists && !isEventExists) {
                                //update counter
                                let newCounter = counter + 1;
                                await this.props.firestore
                                    .collection('LiveQuiz_Certifications')
                                    .doc(UserIdsByEvent[evt][day][user].Uid)
                                    .update({
                                        Counter: newCounter,
                                        LastUpdatedOnUtc: _dateOnUtc
                                    });
                                console.log('certification counter updated.\nCounter = ' + newCounter);
                            }

                            //set certificates collection.
                            if (isEventExists === false) {
                                await this.props.firestore
                                    .collection('LiveQuiz_Certifications')
                                    .doc(UserIdsByEvent[evt][day][user].Uid)
                                    .collection('Certificates')
                                    .doc(moment().valueOf().toString())
                                    .set({
                                        Participant: _studentName,
                                        EventCode: Events[evt].EventCode,
                                        CreatedOnUtc: _dateOnUtc,
                                        CertDownloadEnabled: Events[evt].CertDownloadEnabled,
                                    });
                                console.log('<' + _studentName + '> new certificate is added.');
                            }

                            //end.
                        }
                    }

                    // }
                }
            }
        }

        this.setState({
            PEList_Cert_Progression_Text: progressText,
            PEList_Cert_Progression_Progress: progressStatus,
            actionInProgress: false,
        });
    }
    //2021.04.07 ==== ParticipatedEventList ======== end


    //2022.06.13 ==== ParticipatedEventList ======== start
    SearchAndInsert_LiveQuiz_Cert_V2 = async () => {

        this.setState({ actionInProgress: true });

        let Events = [];



        // ['5fc9181ff9214cebb17ab184e54b2281', '51e5dfdb6fbd498ca8e89cdd6d5ad62a', '5707df5e48d44772ba5d694ea7658776']
        // [String(this.state.SelectedEventCode)]
        // ['7f42183f6d4141a2b307ddaa3ec9651c', 'b3ff4b3d76b9418f9af582cc59979657', '5bf804027933414495aa64e0fbb2e7e7']
        //['c46511053b0846ff9d77dffbcd04ac59', 'ad054eed35524458ac518bcf8f688388']
        // ['83917750545249bda206cce80c2b6a7d', 'feba5155e6a249b1a6af25dfe601d968', 'f83d270f3554400781475c7b427d8f85']

        //2023.11.14
        let _Events = this.state.EventList.filter(x => x.checked === true);
        if (_Events === null || _Events === undefined || _Events.length === 0)
            return null;

        _Events.map((evtCode, key) => {
            const eventIdx = this.state.EventList.findIndex(x => String(x.EventCode) === evtCode);
            if (eventIdx > -1)
                Events.push(this.state.EventList[eventIdx]);
            return null;
        });

        let participatedStudents = [];
        // const listOfStudents = await import('../../components/listOfStudents').then((data) => { return data.default; });
        // if (listOfStudents !== undefined) {
        //     if (listOfStudents.length > 0) {
        //         listOfStudents.map((data, key) => {
        //             if (data.EventChecked.length > 0) {
        //                 for (let ps = 0; ps < data.EventChecked.length; ps++) {
        //                     if (String(data.EventChecked[ps]) !== String(this.state.SelectedEventCode)) {
        //                         data.Event = [];
        //                         participatedStudents.push(data);
        //                     }
        //                 }
        //             }
        //             else {
        //                 participatedStudents.push(data);
        //             }
        //             return null;
        //         });
        //         // participatedStudents = listOfStudents;
        //     }
        // }



        let EventRooms = [];
        let Dates = [];
        Events.map((data, key) => {
            if (data !== null && data !== undefined) {
                if (data.DateStart !== null && data.DateStart !== undefined) {
                    const date = String(data.DateStart);
                    const index = Dates.findIndex(x => String(x) === date);
                    if (index < 0)
                        Dates.push(date);
                }

                //2022.06.20
                let eventRooms = [];
                let evtIdx = EventRooms.findIndex(x => String(x.EventCode) === String(data.EventCode));
                if (evtIdx < 0) {
                    EventRooms.push({ EventCode: String(data.EventCode), Rooms: [], });
                    evtIdx = EventRooms.length - 1;
                }
                if (data.hasOwnProperty('QuizRooms')) {
                    if (data.QuizRooms.length > 0) {
                        data.QuizRooms.map((content, idx1) => {
                            if (content.hasOwnProperty('Rooms')) {
                                if (content.Rooms.length > 0) {
                                    content.Rooms.map((room, idx2) => {
                                        return eventRooms.push({
                                            EventCode: String(data.EventCode),
                                            RoomCode: String(room.RoomCode),
                                            RoomId: '',
                                            SubjectId: room.SubjectId,
                                            GroupId: content.GroupId,
                                            Group: content.Group,
                                        });
                                    });
                                }
                            }
                            return null;
                        });
                        EventRooms[evtIdx].Rooms = eventRooms;
                    }
                }
            }
            return null;
        });
        this.DownloadTxtFile(EventRooms, 'EventRooms_' + moment().format('YYYY-MM-DD_HHmmss'));
        console.log('EventRooms =\n' + JSON.stringify(EventRooms));
        await Delay(2000);

        // if (participatedStudents.length > 0) {

        // let participatedStudents = [];
        for (let ddd = 0; ddd < Dates.length; ddd++) {
            let eventDate = moment(String(Dates[ddd]), 'YYYY-MM-DD');

            this.setState({
                PEList_Cert_Progression_Text: 'Fetching all Personal Results on Date [' + eventDate + ']',
            });

            //Fetch personal result(s).
            await this.props.dbLiveQuiz
                .ref('pkquiz/' + eventDate.format('YYYYMMDD') + '/pkquiz-personal-data/')
                .once('value', (snapshot) => {
                    this.setState({
                        PEList_Cert_Progression_Text: 'Processing Personal Results on Date [' + eventDate + ']',
                    });
                    if (snapshot.exists && snapshot.val() !== null) {

                        const userArray = Object.entries(snapshot.val());
                        console.log('total user = ' + userArray.length + ' (current event)');

                        userArray.forEach(user => {
                            const roomArray = Object.entries(user[1]);
                            let results = [];
                            roomArray.forEach(result => {
                                const _roomId = Number(result[0]);
                                result = JSON.parse(JSON.stringify(result[1]));
                                const scoreRecords = String(result['ScoreRecord']).split(';');
                                results.push({
                                    RoomId: _roomId,
                                    Score: Number(scoreRecords[0]),
                                    TimeElapsed: Number(scoreRecords[1]),
                                    ASelect: String(this.CheckValue(result, 'ASelects')),
                                    QResult: String(this.CheckValue(result, 'QResults')),
                                    IsDone: CheckBoolean(this.CheckValue(result, 'IsDone')),
                                    ScoreRecord: String(this.CheckValue(result, 'ScoreRecord')),
                                });
                                return null;
                            });
                            if (results.length > 0) {
                                participatedStudents.push({
                                    Uid: String(user[0]),
                                    Rooms: results,
                                    // Date: eventDate.format('YYYY-MM-DD HH:mm:ss')
                                    Date: eventDate.format('YYYYMMDD'),
                                    EventChecked: [],
                                    Event: [],  //2022.11.07
                                });
                            }
                            return null;
                        });
                        // console.log('participatedStudents = ' + JSON.stringify(participatedStudents));
                    }
                });
            console.log('participatedStudents = ' + participatedStudents.length);
            this.DownloadTxtFile(participatedStudents, 'ParticipatedStudents_' + moment().format('YYYY-MM-DD_HHmmss'));
            this.setState({
                PEList_Cert_Progression_Text: 'Process Done for Personal Results on Date [' + eventDate + ']',
            });
        }
        await Delay(2000);

        // }
        let CertInfoNotExist = [];
        let RoomInfo = [];
        for (let user = 0; user < participatedStudents.length; user++) {
            if (participatedStudents[user] !== null && participatedStudents[user] !== undefined) {

                this.setState({
                    PEList_Cert_Progression_Text: 'Checking on Student CertInfo on completed Rooms. (' + String(user + 1) + '/' + participatedStudents.length + ')',
                });

                //2022.06.20 - simplified.
                let evt = -1;
                const _Uid = participatedStudents[user].Uid;
                const _Rooms = participatedStudents[user].Rooms;
                let roomRecordFoundOnThisEvent = false;

                //find room.
                if (_Rooms !== null && _Rooms.length > 0) {
                    for (let uroom = 0; uroom < _Rooms.length; uroom++) {

                        evt = -1;
                        roomRecordFoundOnThisEvent = false;

                        let roomInfo = null;
                        const roomId = String(_Rooms[uroom].RoomId);
                        let roomInfoIndex = RoomInfo.findIndex(x => String(x.RoomId) === roomId);
                        if (roomInfoIndex > -1) {
                            roomInfo = RoomInfo[roomInfoIndex];
                        }
                        else {
                            //fetch room info.
                            roomInfo = await this.GetRoomInfoByRoomId(roomId);
                            //add to array.
                            RoomInfo.push(roomInfo);
                        }
                        //find if this room belongs to an event.
                        if (roomInfo !== null) {
                            if (roomInfo.hasOwnProperty('RoomCode')) {
                                if (roomInfo.RoomCode !== null) {

                                    for (let eee = 0; eee < EventRooms.length; eee++) {
                                        const evtRoomIdx = EventRooms[eee].Rooms.findIndex(x => String(x.RoomCode) === String(roomInfo.RoomCode));

                                        //room is found & matched for this event.
                                        if (evtRoomIdx > -1) {

                                            roomRecordFoundOnThisEvent = true;

                                            const evtIdx = Events.findIndex(x => String(x.EventCode) === String(EventRooms[eee].EventCode));
                                            if (evtIdx > -1)
                                                evt = evtIdx;

                                            if (_Rooms[uroom].RoomId === '')
                                                _Rooms[uroom].RoomId = roomInfo.RoomId;

                                            if (String(EventRooms[eee].Rooms[evtRoomIdx].RoomId) === '')
                                                EventRooms[eee].Rooms[evtRoomIdx].RoomId = roomInfo.RoomId;

                                            if (CheckBoolean(_Rooms[uroom].IsDone) === false) {
                                                if (evtIdx > -1) {
                                                    await this.props.dbLiveQuiz.ref('pkquiz/' + moment(String(Events[evtIdx].DateStart), 'YYYY-MM-DD').format('YYYYMMDD')
                                                        + '/pkquiz-personal-data/' + _Uid + '/' + String(roomInfo.RoomId) + '/IsDone')
                                                        .set(true);
                                                    console.log('set IsDone for user [' + _Uid + '] room [' + roomInfo.RoomCode + '] [' + roomInfo.RoomId + ']');
                                                }
                                            }

                                            console.log('\n('
                                                + (CheckBoolean(_Rooms[uroom].IsDone) ? 'DONE' : 'NYD')
                                                + ') user [' + _Uid + '] attended this room [' + roomInfo.RoomCode + '] [' + roomInfo.RoomId + '].');

                                            break;
                                        }
                                    }
                                }
                            }
                        }

                        //only update to events tat r not yet check.
                        if (evt > -1) {
                            const evtChkIdx = participatedStudents[user].EventChecked.findIndex(x => String(x) === String(Events[evt].EventCode));
                            if (evtChkIdx < 0) {
                                if (
                                    roomRecordFoundOnThisEvent
                                    && evt > -1
                                    && Events[evt] !== null
                                    && Events[evt].hasOwnProperty('EventCode')
                                    && _Uid !== null
                                    && _Uid !== undefined
                                    && String(_Uid).toLowerCase() !== 'null'
                                    && String(_Uid).toLowerCase() !== 'undefined'
                                ) {
                                    let _schoolName = '';
                                    let _studentName = '';
                                    const _uid = String(_Uid);

                                    let isEventExists = false;
                                    let isCounterExists = false;
                                    let counter = 0;
                                    let lastUpdatedOnUtc = '';

                                    console.log('checking Uid = ' + _uid);

                                    this.setState({
                                        PEList_Cert_Progression_Progress: 'Checking on...'
                                            + '<br />Event: ' + String(Events[evt].EventName)
                                            + '<br />EventCode: ' + String(Events[evt].EventCode)
                                            + '<br />Uid: ' + _uid,
                                    });

                                    //check for certificate of current event.
                                    await this.props.firestore
                                        .collection('LiveQuiz_Certifications')
                                        .doc(_uid)
                                        .collection('Certificates')
                                        .where('EventCode', '==', String(Events[evt].EventCode))
                                        .get()
                                        .then((querySnapshot) => {
                                            let data = [];
                                            if (querySnapshot !== null) {
                                                data = querySnapshot.docs.map(doc => doc.data())[0];
                                            }
                                            if (data !== null && data !== undefined && data.hasOwnProperty('EventCode')) {
                                                isEventExists = true;
                                            }
                                            console.log('Event (' + (isEventExists ? 'exist, new cert record not needed' : 'not-exist') + ') =\n' + (isEventExists ? JSON.stringify(data) : _uid));
                                        });

                                    if (isEventExists === false) {
                                        participatedStudents[user].Event.push({ EventCode: String(Events[evt].EventCode), Exists: String(isEventExists), })
                                        CertInfoNotExist.push({ Event: String(Events[evt].EventCode), Uid: _uid });
                                    }

                                    if (isEventExists === false) {
                                        // if (isEventExists === false) {

                                        //fetch student name.
                                        await this.props.firestore
                                            .collection("User")
                                            .where('Uid', '==', _uid)
                                            .get()
                                            .then(querySnapshot => {
                                                // isEmailNotExist = !querySnapshot.exists;
                                                let profile = null;
                                                if (querySnapshot !== null) {
                                                    profile = querySnapshot.docs.map(doc => doc.data())[0];
                                                }
                                                if (profile !== null && profile !== undefined && profile.hasOwnProperty('Name')) {
                                                    _studentName = profile.Name !== null ? String(profile.Name) : '';
                                                }
                                                if (profile !== null && profile !== undefined && profile.hasOwnProperty('School')) {
                                                    _schoolName = profile.School !== null ? String(profile.School) : '';
                                                }
                                            });
                                        // console.log('student name = ' + _studentName);

                                        //if FS not found, fetch from RTDB.
                                        if (_studentName.length <= 0 || _schoolName.length <= 0) {
                                            await this.props.dbCommon
                                                .ref('Common/' + _uid)
                                                .once('value', snapshot => {
                                                    let profile = null;
                                                    if (snapshot !== null) {
                                                        profile = snapshot.val();
                                                    }
                                                    if (profile !== null && profile.hasOwnProperty('_1Name')) {
                                                        if (_studentName.length <= 0)
                                                            _studentName = profile._1Name !== null ? String(profile._1Name) : '-';
                                                    }
                                                    if (profile !== null && profile.hasOwnProperty('_10School')) {
                                                        if (_schoolName.length <= 0)
                                                            _schoolName = profile._10School !== null ? String(profile._10School) : '';
                                                    }
                                                });
                                        }

                                        console.log('student name = ' + _studentName);
                                        console.log('school name = ' + _schoolName);

                                        this.setState({
                                            PEList_Cert_Progression_Progress: '=== saving cert info. ==='
                                                + '<br />Event: ' + String(Events[evt].EventName)
                                                + '<br />EventCode: ' + String(Events[evt].EventCode)
                                                + '<br />Uid: ' + _uid
                                                + '<br />Student Name: ' + _studentName
                                                + '<br />School Name: ' + _schoolName
                                                + '<br />=== saving cert info. ===',
                                        });

                                        //check on counter record, to know whether its fresh or existed.
                                        await this.props.firestore
                                            .collection('LiveQuiz_Certifications')
                                            .where('Uid', '==', _uid)
                                            .get()
                                            .then((querySnapshot) => {
                                                let data = [];
                                                if (querySnapshot !== null) {
                                                    data = querySnapshot.docs.map(doc => doc.data())[0];
                                                }
                                                if (data !== null && data !== undefined) {
                                                    if (data.hasOwnProperty('Counter')) {
                                                        isCounterExists = true;
                                                        counter = Number(data.Counter);
                                                        lastUpdatedOnUtc = data.LastUpdatedOnUtc;
                                                    }
                                                }
                                                console.log('Counter (' + (isCounterExists ? 'exist' : 'not-exist') + ') =\n' + JSON.stringify(data) + ' | ' + counter);
                                            });

                                        //compare dates.
                                        let _fetched_LastUpdatedDateOnUtc = moment(lastUpdatedOnUtc);
                                        // let _event_LastUpdatedDateOnUtc = moment(String(UserIdsByEvent[evt][day][user].Date));
                                        let _event_LastUpdatedDateOnUtc = moment(String(Events[evt].DateStart));

                                        let _dateOnUtc = '';
                                        if (_fetched_LastUpdatedDateOnUtc > _event_LastUpdatedDateOnUtc)
                                            _dateOnUtc = _fetched_LastUpdatedDateOnUtc.format('YYYY-MM-DD HH:mm:ss');
                                        else
                                            _dateOnUtc = _event_LastUpdatedDateOnUtc.format('YYYY-MM-DD HH:mm:ss');

                                        //if no record found.
                                        if (isCounterExists === false) {
                                            //set initial search target.
                                            await this.props.firestore
                                                .collection('LiveQuiz_Certifications')
                                                .doc(_uid)
                                                .set({
                                                    Uid: _uid,
                                                    Counter: 1,
                                                    LastUpdatedOnUtc: _dateOnUtc
                                                });
                                            console.log('new record created. | Counter = 1');
                                        }
                                        else if (isCounterExists && !isEventExists) {
                                            //update counter
                                            let newCounter = counter + 1;
                                            await this.props.firestore
                                                .collection('LiveQuiz_Certifications')
                                                .doc(_uid)
                                                .update({
                                                    Counter: newCounter,
                                                    LastUpdatedOnUtc: _dateOnUtc
                                                });
                                            console.log('certification counter updated.\nCounter = ' + newCounter);
                                        }

                                        //set certificates collection.
                                        if (isEventExists === false) {
                                            await this.props.firestore
                                                .collection('LiveQuiz_Certifications')
                                                .doc(_uid)
                                                .collection('Certificates')
                                                .doc(moment().valueOf().toString())
                                                .set({
                                                    Participant: _studentName,
                                                    EventCode: String(Events[evt].EventCode),
                                                    CreatedOnUtc: _dateOnUtc,
                                                    CertDownloadEnabled: CheckBoolean(Events[evt].CertDownloadEnabled),

                                                    //2021.11.08 === add serial number & school name.
                                                    CertSN: '', //isEventQuiz.EventModal.CertSN,
                                                    SchoolName: _schoolName,
                                                });
                                            console.log('<' + _studentName + '> new certificate is added.');
                                        }

                                        //end.
                                    }
                                    //set checked event in array to avoid another loop on updates.
                                    participatedStudents[user].EventChecked.push(String(Events[evt].EventCode));
                                }
                            }
                        }
                    }
                }

                //#region old codes
                // let evt = -1;
                // const _Uid = participatedStudents[user].Uid;
                // // const index = participatedStudents[user].Rooms
                // let roomRecordFoundOnThisEvent = false;
                // for (let eee = 0; eee < Events.length; eee++) {
                //     if (Events[eee] !== null) {
                //         if (Events[eee].hasOwnProperty('QuizRooms')) {
                //             if (Events[eee].QuizRooms !== null && Events[eee].QuizRooms.length > 0) {

                //                 for (let ggg = 0; ggg < Events[eee].QuizRooms.length; ggg++) {
                //                     if (Events[eee].QuizRooms[ggg] !== null) {
                //                         if (Events[eee].QuizRooms[ggg].hasOwnProperty('Rooms')) {
                //                             if (Events[eee].QuizRooms[ggg].Rooms !== null && Events[eee].QuizRooms[ggg].Rooms.length > 0) {

                //                                 for (let rrr = 0; rrr < Events[eee].QuizRooms[ggg].Rooms.length; rrr++) {
                //                                     if (Events[eee].QuizRooms[ggg].Rooms[rrr] !== null) {
                //                                         if (Events[eee].QuizRooms[ggg].Rooms[rrr].hasOwnProperty('RoomCode')) {
                //                                             const roomCode = String(Events[eee].QuizRooms[ggg].Rooms[rrr].RoomCode);

                //                                             //find room.
                //                                             if (participatedStudents[user].Rooms !== null && participatedStudents[user].Rooms.length > 0) {
                //                                                 for (let uroom = 0; uroom < participatedStudents[user].Rooms.length; uroom++) {

                //                                                     let roomInfo = null;
                //                                                     const roomId = String(participatedStudents[user].Rooms[uroom].RoomId);
                //                                                     let roomInfoIndex = RoomInfo.findIndex(x => String(x.RoomId) === roomId);
                //                                                     if (roomInfoIndex > -1) {
                //                                                         roomInfo = RoomInfo[roomInfoIndex];
                //                                                     }
                //                                                     else {
                //                                                         //fetch room info.
                //                                                         roomInfo = await this.GetRoomInfoByRoomId(roomCode);
                //                                                         //add to array.
                //                                                         RoomInfo.push(roomInfo);
                //                                                     }
                //                                                     if (roomInfo !== null) {
                //                                                         if (roomInfo.hasOwnProperty('RoomCode')) {
                //                                                             if (roomInfo.RoomCode !== null) {
                //                                                                 if (String(roomInfo.RoomCode) === roomCode) {
                //                                                                     //room is found & matched for this event.
                //                                                                     roomRecordFoundOnThisEvent = true;
                //                                                                     evt = eee;
                //                                                                     participatedStudents[user].Rooms[uroom].RoomCode = roomCode;

                //                                                                     console.log('\n('
                //                                                                         + (CheckBoolean(participatedStudents[user].Rooms[uroom].IsDone) ? 'DONE' : 'NYD')
                //                                                                         + ') user [' + _Uid + '] attended this room [' + roomCode + '].');
                //                                                                 }
                //                                                             }
                //                                                         }
                //                                                     }
                //                                                     if (roomRecordFoundOnThisEvent)
                //                                                         break;
                //                                                 }
                //                                             }
                //                                         }
                //                                     }
                //                                     if (roomRecordFoundOnThisEvent)
                //                                         break;
                //                                 }
                //                             }
                //                         }
                //                     }
                //                     if (roomRecordFoundOnThisEvent)
                //                         break;
                //                 }
                //                 // let groupRooms = 
                //             }
                //         }
                //     }
                //     if (roomRecordFoundOnThisEvent)
                //         break;
                // }
                //=======================================================// 2022.06.13
                //#endregion

                //#region  old codes
                // if (
                //     roomRecordFoundOnThisEvent
                //     && evt > -1
                //     && Events[evt] !== null
                //     && Events[evt].hasOwnProperty('EventCode')
                //     && _Uid !== null
                //     && _Uid !== undefined
                //     && String(_Uid).toLowerCase() !== 'null'
                //     && String(_Uid).toLowerCase() !== 'undefined'
                // ) {
                //     let _schoolName = '';
                //     let _studentName = '';
                //     const _uid = String(_Uid);

                //     let isEventExists = false;
                //     let isCounterExists = false;
                //     let counter = 0;
                //     let lastUpdatedOnUtc = '';

                //     console.log('checking Uid = ' + _uid);

                //     this.setState({
                //         PEList_Cert_Progression_Progress: 'Checking on...'
                //             + '<br />Event: ' + String(Events[evt].EventName)
                //             + '<br />EventCode: ' + String(Events[evt].EventCode)
                //             + '<br />Uid: ' + _uid,
                //     });

                //     //check for certificate of current event.
                //     await this.props.firestore
                //         .collection('LiveQuiz_Certifications')
                //         .doc(_uid)
                //         .collection('Certificates')
                //         .where('EventCode', '==', String(Events[evt].EventCode))
                //         .get()
                //         .then((querySnapshot) => {
                //             let data = [];
                //             if (querySnapshot !== null) {
                //                 data = querySnapshot.docs.map(doc => doc.data())[0];
                //             }
                //             if (data !== null && data !== undefined && data.hasOwnProperty('EventCode')) {
                //                 isEventExists = true;
                //             }
                //             console.log('Event (' + (isEventExists ? 'exist, new cert record not needed' : 'not-exist') + ') =\n' + JSON.stringify(data));
                //         });

                //     if (isEventExists === false) {

                //         //fetch student name.
                //         await this.props.firestore
                //             .collection("User")
                //             .where('Uid', '==', _uid)
                //             .get()
                //             .then(querySnapshot => {
                //                 // isEmailNotExist = !querySnapshot.exists;
                //                 let profile = null;
                //                 if (querySnapshot !== null) {
                //                     profile = querySnapshot.docs.map(doc => doc.data())[0];
                //                 }
                //                 if (profile !== null && profile !== undefined && profile.hasOwnProperty('Name')) {
                //                     _studentName = profile.Name !== null ? String(profile.Name) : '';
                //                 }
                //                 if (profile !== null && profile !== undefined && profile.hasOwnProperty('School')) {
                //                     _schoolName = profile.School !== null ? String(profile.School) : '';
                //                 }
                //             });
                //         // console.log('student name = ' + _studentName);

                //         //if FS not found, fetch from RTDB.
                //         if (_studentName.length <= 0 || _schoolName.length <= 0) {
                //             await this.props.dbCommon
                //                 .ref('Common/' + _uid)
                //                 .once('value', snapshot => {
                //                     let profile = null;
                //                     if (snapshot !== null) {
                //                         profile = snapshot.val();
                //                     }
                //                     if (profile !== null && profile.hasOwnProperty('_1Name')) {
                //                         if (_studentName.length <= 0)
                //                             _studentName = profile._1Name !== null ? String(profile._1Name) : '-';
                //                     }
                //                     if (profile !== null && profile.hasOwnProperty('_10School')) {
                //                         if (_schoolName.length <= 0)
                //                             _schoolName = profile._10School !== null ? String(profile._10School) : '';
                //                     }
                //                 });
                //         }

                //         console.log('student name = ' + _studentName);
                //         console.log('school name = ' + _schoolName);

                //         this.setState({
                //             PEList_Cert_Progression_Progress: '=== saving cert info. ==='
                //                 + '<br />Event: ' + String(Events[evt].EventName)
                //                 + '<br />EventCode: ' + String(Events[evt].EventCode)
                //                 + '<br />Uid: ' + _uid
                //                 + '<br />Student Name: ' + _studentName
                //                 + '<br />School Name: ' + _schoolName
                //                 + '<br />=== saving cert info. ===',
                //         });

                //         //check on counter record, to know whether its fresh or existed.
                //         await this.props.firestore
                //             .collection('LiveQuiz_Certifications')
                //             .where('Uid', '==', _uid)
                //             .get()
                //             .then((querySnapshot) => {
                //                 let data = [];
                //                 if (querySnapshot !== null) {
                //                     data = querySnapshot.docs.map(doc => doc.data())[0];
                //                 }
                //                 if (data !== null && data !== undefined) {
                //                     if (data.hasOwnProperty('Counter')) {
                //                         isCounterExists = true;
                //                         counter = Number(data.Counter);
                //                         lastUpdatedOnUtc = data.LastUpdatedOnUtc;
                //                     }
                //                 }
                //                 console.log('Counter (' + (isCounterExists ? 'exist' : 'not-exist') + ') =\n' + JSON.stringify(data) + ' | ' + counter);
                //             });

                //         // //check for certificate of current event.
                //         // await this.props.firestore
                //         //     .collection('LiveQuiz_Certifications')
                //         //     .doc(UserIdsByEvent[evt][day][user].Uid)
                //         //     .collection('Certificates')
                //         //     .where('EventCode', '==', Events[evt].EventCode)
                //         //     .get()
                //         //     .then((querySnapshot) => {
                //         //         let data = [];
                //         //         if (querySnapshot !== null) {
                //         //             data = querySnapshot.docs.map(doc => doc.data())[0];
                //         //         }
                //         //         if (data !== undefined && data.hasOwnProperty('EventCode')) {
                //         //             isEventExists = true;
                //         //         }
                //         //         console.log('Event (' + (isEventExists ? 'exist, new cert record not needed' : 'not-exist') + ') =\n' + JSON.stringify(data));
                //         //     });

                //         // let _dateOnUtc = moment(UserIdsByEvent[evt][day][user].Date).add(-8, 'hours').format('YYYY-MM-DD HH:mm:ss');

                //         //compare dates.
                //         let _fetched_LastUpdatedDateOnUtc = moment(lastUpdatedOnUtc);
                //         // let _event_LastUpdatedDateOnUtc = moment(String(UserIdsByEvent[evt][day][user].Date));
                //         let _event_LastUpdatedDateOnUtc = moment(String(Events[evt].DateStart));

                //         let _dateOnUtc = '';
                //         if (_fetched_LastUpdatedDateOnUtc > _event_LastUpdatedDateOnUtc)
                //             _dateOnUtc = _fetched_LastUpdatedDateOnUtc.format('YYYY-MM-DD HH:mm:ss');
                //         else
                //             _dateOnUtc = _event_LastUpdatedDateOnUtc.format('YYYY-MM-DD HH:mm:ss');

                //         //if no record found.
                //         if (isCounterExists === false) {
                //             //set initial search target.
                //             await this.props.firestore
                //                 .collection('LiveQuiz_Certifications')
                //                 .doc(_uid)
                //                 .set({
                //                     Uid: _uid,
                //                     Counter: 1,
                //                     LastUpdatedOnUtc: _dateOnUtc
                //                 });
                //             console.log('new record created. | Counter = 1');
                //         }
                //         else if (isCounterExists && !isEventExists) {
                //             //update counter
                //             let newCounter = counter + 1;
                //             await this.props.firestore
                //                 .collection('LiveQuiz_Certifications')
                //                 .doc(_uid)
                //                 .update({
                //                     Counter: newCounter,
                //                     LastUpdatedOnUtc: _dateOnUtc
                //                 });
                //             console.log('certification counter updated.\nCounter = ' + newCounter);
                //         }

                //         //set certificates collection.
                //         if (isEventExists === false) {
                //             await this.props.firestore
                //                 .collection('LiveQuiz_Certifications')
                //                 .doc(_uid)
                //                 .collection('Certificates')
                //                 .doc(moment().valueOf().toString())
                //                 .set({
                //                     Participant: _studentName,
                //                     EventCode: String(Events[evt].EventCode),
                //                     CreatedOnUtc: _dateOnUtc,
                //                     CertDownloadEnabled: CheckBoolean(Events[evt].CertDownloadEnabled),

                //                     //2021.11.08 === add serial number & school name.
                //                     CertSN: '', //isEventQuiz.EventModal.CertSN,
                //                     SchoolName: _schoolName,
                //                 });
                //             console.log('<' + _studentName + '> new certificate is added.');
                //         }

                //         //end.
                //         // }
                //     }

                // }
                //#endregion
            }
        }

        this.DownloadTxtFile(CertInfoNotExist, 'CertInfoNotExist_(Checked)_' + moment().format('YYYY-MM-DD_HHmmss'));
        this.DownloadTxtFile(participatedStudents, 'ParticipatedStudents_(Checked)_' + moment().format('YYYY-MM-DD_HHmmss'));

        this.setState({
            PEList_Cert_Progression_Text: 'Completed',
            PEList_Cert_Progression_Progress: '',
            actionInProgress: false,
        });
    }
    //2022.06.13 ==== ParticipatedEventList ======== end

    //2021.04.20 === Search & re-calculate Score Record === start
    SearchAndReCalculateScoreRecord = async () => {

        this.setState({
            actionInProgress: true,
            SearchAndReCalculateScoreRecord_Progress: 'SearchAndReCalculateScoreRecord - started',
        });

        //target room id = 1618452508847
        //target room code = 62002

        // let targetRoomId = 1618452508847;
        // let targetRoomCode = 62002;

        // let targetRoomId = 1617697845647;   //std 1 - testing 0406

        // let targetRoomId = 1618452266561;   //std 1
        // let targetRoomId = 1618452359922;   //std 2
        // let targetRoomId = 1618452413631;   //std 3
        // let targetRoomId = 1618452452955;   //std 4
        // let targetRoomId = 1618452508847;   //std 5
        let targetRoomId = 1618452555231;   //std 6

        let event = this.state.EventList[4];  //spot_2021_sim_0417_1st_sem = 4, beta test = 5
        console.log(event.EventCode + ' (event code)');

        let eventDate = moment(event.DateStart);

        //Fetch room code(s).
        let quizRoom = [];
        // await this.props.dbLiveQuiz
        //     .ref('pkquiz/' + eventDate.format('YYYYMMDD') + '/pkquiz-room-code')
        //     .once('value', (snapshot) => {
        //         if (snapshot.exists && snapshot.val() !== null) {
        //             let snapArray = snapshot.val();
        //             if (snapArray !== null) {
        //                 let dataArray = [];
        //                 snapshot.forEach(snap => {
        //                     dataArray.push({
        //                         RoomId: snap.val(),
        //                         RoomCode: snap.key,
        //                     });
        //                     return false;
        //                 });
        //                 quizRoomCodes = dataArray;
        //             }
        //         }
        //     });
        await this.props.firestore
            .collection('LiveQuiz_UniqueRoomCode')
            .where('EventCode', '==', event.EventCode)
            .get()
            .then((querySnapshot) => {
                let data = [];
                if (querySnapshot !== null) {
                    // data = querySnapshot.docs.map(doc => doc.data())[0];
                    querySnapshot.forEach((doc) => {
                        data.push(doc.data());
                    });
                }
                quizRoom = data;
            });
        console.log('quizRoom = ' + JSON.stringify(quizRoom));

        //Fetch personal result(s).
        let participatedStudents = [];
        await this.props.dbLiveQuiz
            .ref('pkquiz/' + eventDate.format('YYYYMMDD') + '/pkquiz-personal-data/')
            .once('value', (snapshot) => {
                if (snapshot.exists && snapshot.val() !== null) {

                    let userArray = Object.entries(snapshot.val());

                    console.log('total user = ' + userArray.length + ' (current event)');
                    // console.log('user array = ' + JSON.stringify(userArray));
                    // let countUser = 0;
                    // Array(userArray).forEach(() => {
                    //     countUser += 1;
                    // });
                    // let testObj = Object.assign({}, userArray);
                    // console.log('total user = ' + countUser);


                    userArray.forEach(user => {
                        // for (let i = 0; i < userArray.length; i++) {
                        // console.log('user = ' + JSON.stringify(user));

                        // let userObj = Object.assign({}, user);
                        let roomArray = Object.entries(user[1]);

                        let results = [];
                        roomArray.forEach(result => {

                            let _roomId = Number(result[0]);

                            if (_roomId === targetRoomId) {
                                // console.log('result = ' + JSON.stringify(result));
                                result = result[1];

                                let scoreRecords = String(result['ScoreRecord']).split(';');
                                results.push({
                                    RoomId: _roomId,
                                    Score: Number(scoreRecords[0]),
                                    TimeElapsed: Number(scoreRecords[1]),
                                    ASelect: result['ASelects'],
                                    QResult: result['QResults'],
                                    IsDone: result['IsDone'],
                                    ScoreRecord: result['ScoreRecord']
                                });
                            }
                            // }
                            return null;
                        });
                        if (results.length > 0)
                            participatedStudents.push({
                                Uid: user[0],
                                Rooms: results,
                                // Date: eventDate.format('YYYY-MM-DD HH:mm:ss')
                                Date: eventDate.format('YYYYMMDD'),
                            });
                        // }
                        return null;
                    });

                    console.log('participatedStudents = ' + participatedStudents.length + ' (current quiz)');
                    // console.log('participatedStudents = ' + JSON.stringify(participatedStudents));

                    // navigator.clipboard.writeText(JSON.stringify(participatedStudents));

                    // this.ModifyScoreRecords(participatedStudents);
                    // await this.AsyncModifyScoreRecords(participatedStudents);
                }
            });
        // console.log('participatedStudents = ' + participatedStudents.length);
        // console.log('participatedStudents = ' + JSON.stringify(participatedStudents));

        // navigator.clipboard.writeText(JSON.stringify(participatedStudents));
        // await this.SaveInClipboard(JSON.stringify(participatedStudents));

        this.setState({
            SearchAndReCalculateScoreRecord_Progress: event.EventCode + '<br />' + targetRoomId + '<br />progression 0% (0/' + participatedStudents.length + ')',
        });

        this.AsyncModifyScoreRecords(participatedStudents, event, targetRoomId);

        return null;
    }
    // ModifyScoreRecords = async (_participatedStudents) => {
    //     await this.AsyncModifyScoreRecords(_participatedStudents);
    // }
    AsyncModifyScoreRecords = async (_participatedStudents, _event, _roomId) => {

        //Fetch Room details.
        let _roomDetails = [];
        await this.props.dbLiveQuiz.ref('pkquiz/' + moment(_event.DateStart).format('YYYYMMDD') + '/pkquiz-room-detail/' + _roomId)
            .once('value')
            .then((snapshot) => {
                if (snapshot !== null) {
                    _roomDetails = snapshot.val();
                }
            });
        console.log('roomDetails = ' + JSON.stringify(_roomDetails));

        //Fetch Questions.
        // let _roomTitle = '';

        // //Std 1
        // let _questions = ["D", "C", "A", "B", "A", "A", "D", "A", "A", "A", "B", "B", "A", "B", "A", "B", "B", "C", "E", "H", "B", "A", "A", "C", "D", "B", "A", "D", "C", "B", "A", "A", "A", "A", "B", "C", "D", "A", "B", "C", "D", "C", "A", "D", "B", "C", "D", "C", "B", "A", "B", "A", "B", "A", "D", "B", "C", "G", "F", "I", "A", "B", "A", "D", "B", "B", "C", "B", "D", "C", "B", "A", "B", "A", "B", "B", "A", "B", "A", "B", "A", "D", "C", "B", "A", "C", "A", "B", "C", "D", "C", "D", "C", "A", "D", "A", "B", "D", "A", "C"];

        // //Std 2
        // let _questions = ["B","A","B","A","D","A","A","B","B","C","E","A","A","A","A","B","C","D","A","A","A","C","B","C","B","D","C","A","C","D","A","B","A","B","A","A","C","B","A","B","B","A","D","C","B","C","C","A","A","D","B","A","A","B","C","E","B","A","G","D","D","A","B","C","D","D","C","A","A","C","D","F","B","E","A","C","A","C","B","D","C","A","C","B","B","B","B","C","A","D","D","C","A","B","D","A","C","A","B","D"];

        // //Std 3
        // let _questions = ["A", "D", "A", "B", "D", "C", "A", "A", "B", "D", "A", "E", "A", "C", "D", "C", "A", "C", "B", "D", "C", "D", "A", "C", "A", "C", "B", "B", "B", "C", "A", "A", "D", "A", "B", "B", "B", "D", "A", "C", "A", "A", "D", "B", "C", "A", "B", "D", "B", "A", "A", "B", "B", "A", "C", "B", "C", "A", "D", "A", "D", "D", "B", "D", "C", "B", "C", "B", "C", "C", "C", "A", "D", "B", "B", "A", "E", "D", "C", "F", "C", "B", "D", "A", "A", "D", "C", "B", "A", "D", "D", "C", "B", "C", "D", "A", "D", "C", "B", "D"];

        // //Std 4
        // let _questions = ["A","A","B","C","B","D","D","B","C","B","E","B","A","J","C","D","B","D","A","D","C","A","A","B","D","C","C","D","A","C","D","A","D","A","B","A","B","A","B","C","C","A","A","C","D","B","B","A","A","B","C","D","E","B","A","A","B","D","D","A","D","B","D","D","B","D","D","A","A","D","C","D","D","B","C","D","C","C","B","A","C","B","B","D","D","D","D","D","B","D","D","D","B","A","D","D","C","D","C","C"];

        // //Std 5 === ***
        // let _questions = ["D", "B", "B", "D", "B", "A", "A", "D", "C", "A", "A", "B", "B", "A", "B", "B", "B", "D", "B", "C", "D", "C", "B", "D", "A", "C", "B", "C", "F", "D", "A", "C", "A", "B", "C", "B", "B", "A", "C", "C", "D", "A", "C", "C", "D", "D", "C", "B", "C", "B", "B", "C", "B", "H", "I", "C", "D", "A", "B", "B",
        //     "A", "C", "C", "A", "B", "D", "C", "D", "B", "A",
        //     "A", "A", "D", "D", "B", "B", "C", "B", "D", "D",
        //     "C", "D", "A", "C", "A", "D", "A", "D", "B", "C",
        //     "B", "B", "C", "C", "D", "C", "B", "B", "B", "B"];

        //Std 6
        let _questions = ["A", "D", "A", "C", "A", "D", "C", "D", "B", "B", "D", "B", "G", "D", "A", "C", "D", "C", "D", "A", "D", "B", "C", "B", "C", "D", "D", "D", "C", "B", "D", "B", "D", "B", "A", "C", "A", "C", "B", "B", "B", "D", "B", "D", "C", "B", "D", "B", "A", "G", "J", "D", "E", "L", "P", "B", "C", "C", "B", "C", "C", "D", "C", "B", "A", "C", "C", "D", "D", "A", "A", "C", "A", "D", "D", "A", "D", "C", "D", "B", "C", "A", "B", "D", "C", "A", "C", "B", "A", "B", "D", "B", "B", "D", "C", "D", "D", "C", "C", "B"];

        // let path = "LiveQuiz/"
        //     + _roomDetails.Subject + "/"
        //     + _roomDetails.Grade + "/"
        //     + _roomDetails.QnSet;
        // await this.props.dbQuestion.ref(path)
        //     .once('value')
        //     .then((snapshot) => {
        //         if (snapshot !== null) {
        //             // _questions = JSON.parse(JSON.stringify(snapshot.val()));
        //             // let qsArray = Object.entries(snapshot.val());
        //             let qsArray = snapshot.val();

        //             for (let k = 0; k < _roomDetails.QnQty; k++) {
        //                 if (qsArray[k] !== undefined)
        //                     _questions.push(qsArray[k]);
        //             }
        //             // _roomTitle = qsArray[101][1];
        //         }
        //         console.log(JSON.stringify(_questions));
        //     });
        console.log('questions = ' + _questions.length);
        // console.log('room title = ' + _roomTitle);

        let counter = 0;
        let No77correct = 0;

        //Process Participant Data.
        for (let p = 0; p < _participatedStudents.length; p++) {
            if (_participatedStudents[p] !== undefined) {
                let _uid = _participatedStudents[p].Uid;
                let _date = _participatedStudents[p].Date;
                if (_participatedStudents[p].Rooms.length > 0) {
                    for (let k = 0; k < _participatedStudents[p].Rooms.length; k++) {
                        if (_participatedStudents[p].Rooms[k] !== undefined) {
                            let _ASelect = _participatedStudents[p].Rooms[k].ASelect;
                            // let _QResult = _participatedStudents[p].Rooms[k].QResult;
                            // let _ScoreRecord = _participatedStudents[p].Rooms[k].ScoreRecord;
                            let _IsDone = _participatedStudents[p].Rooms[k].IsDone;
                            let _TimeElapsed = _participatedStudents[p].Rooms[k].TimeElapsed;
                            let _RoomId = _participatedStudents[p].Rooms[k].RoomId;

                            //calculate result.
                            console.log('_ASelect = ' + _ASelect);
                            let _ASelects = String(_ASelect).split(':');
                            let _QResult = '';
                            for (let q = 0; q < _questions.length; q++) {
                                let answer = String.fromCharCode(65 + Number(_ASelects[q]));
                                // console.log(answer);
                                if (answer === String(_questions[q])) {
                                    _QResult += '1';
                                    if (q === 76)
                                        No77correct += 1;
                                }
                                else {
                                    _QResult += '0';
                                }
                                if (q < _questions.length - 1)
                                    _QResult += ':';
                            }
                            console.log('_QResult = ' + _QResult);

                            //calculate total correct.
                            let _totalCorrect = 0;
                            let _ResultArray = String(_QResult).split(':');
                            for (let k = 0; k < _ResultArray.length; k++) {
                                if (_ResultArray[k] === '1')
                                    _totalCorrect += 1;
                            }
                            let _score = ((_totalCorrect / _ResultArray.length) * 100).toFixed(2);
                            _TimeElapsed = String(_TimeElapsed) === 'NaN' ? '0.000' : _TimeElapsed;
                            let _ScoreRecord = _score + ';' + Number(_TimeElapsed).toFixed(3) + ';';

                            //get name.
                            let _profile = await this.GetStudentName(_uid);
                            _profile = _profile === undefined ? { Name: 'unknown' } : _profile;

                            //update scores in personal-record.
                            let _updates = {};
                            _updates['/' + _RoomId + '/ScoreRecord'] = _ScoreRecord;
                            _updates['/' + _RoomId + '/QResults'] = _QResult;
                            await this.props.dbLiveQuiz
                                .ref('pkquiz/' + _date + '/pkquiz-personal-data/' + _uid)
                                .update(_updates);
                            // console.log(String(_profile.Name) + '\n' + _uid + '\nOld: ' + _participatedStudents[p].Rooms[k].ScoreRecord + '\nNew: ' + _ScoreRecord);

                            //update listing in pkquiz-ranking-live list.                            
                            _IsDone = _IsDone === undefined ? 'TRUE' : String(_IsDone).toUpperCase();
                            let valueToUpdate = _ScoreRecord + escape(String(_profile.Name)) + ';' + _IsDone + ';';
                            await this.props.dbLiveQuiz
                                .ref('pkquiz/' + _date + '/pkquiz-ranking-live/' + _RoomId + '/' + _uid)
                                .set(valueToUpdate);
                            console.log(String(_profile.Name) + '\n' + _uid + '\nOld: ' + _participatedStudents[p].Rooms[k].ScoreRecord + '\nNew: ' + _ScoreRecord + '\nranking: ' + valueToUpdate);

                            //progression
                            counter += 1;
                            let percent = Math.round((counter / _participatedStudents.length) * 100);
                            this.setState({
                                SearchAndReCalculateScoreRecord_Progress: _event.EventCode + '<br />' + _roomId + '<br />progression ' + percent + '% (' + counter + '/' + _participatedStudents.length + ')' + (_roomId === 1618452508847 ? '<br />No.77 correct = ' + No77correct : ''),
                            });
                        }
                    }
                }
            }
        }

        this.setState({ actionInProgress: false });
        return null;
    }
    // GetAnswerByIndex = (_idx) => {
    //     return String.fromCharCode(97 + _idx);
    // }
    GetStudentName = async (_uid) => {
        let profile = [];
        //get from FS.
        await this.props.firestore.collection("User")
            .where('Uid', '==', _uid)
            .get()
            .then(querySnapshot => {
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        profile.push(doc.data());
                    });
                    profile = profile[0];
                }
            });
        //if FS not found, get from RTDB.
        if (profile === []) {
            await this.props.dbCommon
                .ref('Common/' + _uid + '/_1Name')
                .once('value', snapshot => {
                    profile.push({ Name: snapshot.val() });
                });
        }
        return profile;
    }
    //2021.04.20 === Search & re-calculate Score Record === end

    // SaveInClipboard = async (data) => {
    //     //2021.04.06 === fixing Copy/Paste feature on browser, where right now permission is needed to success === start
    //     let queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };
    //     let permissionStatus = await navigator.permissions.query(queryOpts);
    //     // Will be 'granted', 'denied' or 'prompt':
    //     // console.log(permissionStatus.state);
    //     if (navigator.clipboard !== undefined) {//Chrome
    //         await navigator.clipboard.writeText(data).then(function () {
    //             console.log('Room Code: Copying to clipboard was successful!');
    //         }, function (err) {
    //             console.error('Room Code: Could not copy text: ', err);
    //         });
    //     }
    //     else if (window.clipboardData) { // Internet Explorer
    //         window.clipboardData.setData("Text", data);
    //     }
    //     //2021.04.06 === end
    // }

    //2021.04.20 === Search & re-apply Cert info === start
    SetUid_SearchAndReApplyCert = (event) => {
        this.setState({ SearchAndReApplyCert_StudentEmail: event.target.value });
        // console.log('set uid = ' + this.state.SearchAndReApplyCert_StudentEmail);
    }
    SearchAndReApplyCertInfo = async (_uid = '', _email = '') => {

        let _Email = _email.length > 0 ? _email : this.state.SearchAndReApplyCert_StudentEmail;

        let event = this.state.EventList.find(x => String(x.EventCode) === String(this.state.SelectedEventCode));   //2022.06.11
        // let event = this.state.EventList[4];  //spot_2021_sim_0417_1st_sem
        console.log(event.EventCode);

        // let eventDate = moment(event.DateStart);

        if (event === undefined || event === null)
            return;

        let _Uid = _uid.length > 0 ? _uid : '';
        let _profile = [];
        let _studentName = '';
        let _schoolName = '';

        let searchParam = '';
        let searchValue = '';
        if (_Uid.length > 0) {
            searchParam = 'Uid';
            searchValue = _uid;
        }
        else if (_Email.length > 0) {
            searchParam = 'Email';
            searchValue = _Email;
        }

        //fetch student profile.
        await this.props.firestore
            .collection("User")
            .where(searchParam, '==', searchValue)
            .get()
            .then(querySnapshot => {
                // isEmailNotExist = !querySnapshot.exists;
                let profile = [];
                if (querySnapshot !== null) {
                    profile = querySnapshot.docs.map(doc => doc.data())[0];
                }
                if (profile !== undefined && profile.hasOwnProperty('Name')) {
                    _studentName = profile.Name;
                    _profile = profile;
                    _Uid = profile.Uid;
                    _schoolName = profile.School;
                }
            });
        console.log('student name = ' + _profile.Name);
        console.log('student email = ' + _profile.Email);
        console.log('student uid = ' + _profile.Uid);
        console.log('student school = ' + _profile.School);

        if (_Uid === '' || _Uid === undefined)
            return null;

        // //if FS not found, fetch from RTDB.
        // if (_studentName.length <= 0) {
        //     await this.props.dbCommon
        //         .ref('Common/' + _Uid)
        //         .once('value', snapshot => {
        //             let profile = [];
        //             if (snapshot !== null) {
        //                 profile = snapshot.val();
        //             }
        //             if (profile !== undefined && profile.hasOwnProperty('Name')) {
        //                 _studentName = profile.Name;
        //                 // _profile = profile;
        //             }
        //         });
        // }
        // console.log('student name = ' + _profile.Name);

        let isEventExists = false;
        let isCounterExists = false;
        let counter = 0;
        let lastUpdatedOnUtc = '';

        //check on counter record, to know whether its fresh or existed.
        await this.props.firestore
            .collection('LiveQuiz_Certifications')
            .where('Uid', '==', _Uid)
            .get()
            .then((querySnapshot) => {
                let data = [];
                if (querySnapshot !== null) {
                    data = querySnapshot.docs.map(doc => doc.data())[0];
                }
                if (data !== undefined && data.hasOwnProperty('Counter')) {
                    isCounterExists = true;
                    counter = Number(data.Counter);
                    lastUpdatedOnUtc = data.LastUpdatedOnUtc;
                }
                console.log('Counter (' + (isCounterExists ? 'exist' : 'not-exist') + ') =\n' + JSON.stringify(data) + ' | ' + counter);
            });

        //check for certificate of current event.
        await this.props.firestore
            .collection('LiveQuiz_Certifications')
            .doc(_Uid)
            .collection('Certificates')
            .where('EventCode', '==', String(event.EventCode))
            .get()
            .then((querySnapshot) => {
                let data = [];
                if (querySnapshot !== null) {
                    data = querySnapshot.docs.map(doc => doc.data())[0];
                }
                if (data !== undefined && data.hasOwnProperty('EventCode')) {
                    isEventExists = true;
                }
                console.log('Event (' + (isEventExists ? 'exist, new cert record not needed' : 'not-exist') + ') =\n' + JSON.stringify(data));
            });

        // let _dateOnUtc = moment(UserIdsByEvent[evt][day][user].Date).add(-8, 'hours').format('YYYY-MM-DD HH:mm:ss');

        // return null;

        //compare dates.
        let _fetched_LastUpdatedDateOnUtc = lastUpdatedOnUtc !== null && lastUpdatedOnUtc !== undefined ?
            moment(lastUpdatedOnUtc) : moment().utc().format('YYYY-MM-DD HH:mm:ss');
        let _event_LastUpdatedDateOnUtc = moment(event.DateStart);

        let _dateOnUtc = '';
        if (_fetched_LastUpdatedDateOnUtc > _event_LastUpdatedDateOnUtc)
            _dateOnUtc = _fetched_LastUpdatedDateOnUtc.format('YYYY-MM-DD HH:mm:ss');
        else
            _dateOnUtc = _event_LastUpdatedDateOnUtc.format('YYYY-MM-DD HH:mm:ss');

        //if no record found.
        if (isCounterExists === false) {
            //set initial search target.
            await this.props.firestore
                .collection('LiveQuiz_Certifications')
                .doc(_Uid)
                .set({
                    Uid: _Uid,
                    Counter: 1,
                    LastUpdatedOnUtc: _dateOnUtc
                });
            console.log('new record created. | Counter = 1');
        }
        else if (isCounterExists && !isEventExists) {
            //update counter
            let newCounter = counter + 1;
            await this.props.firestore
                .collection('LiveQuiz_Certifications')
                .doc(_Uid)
                .update({
                    Counter: newCounter,
                    LastUpdatedOnUtc: _dateOnUtc
                });
            console.log('certification counter updated.\nCounter = ' + newCounter);
        }

        //set certificates collection.
        if (isEventExists === false) {
            await this.props.firestore
                .collection('LiveQuiz_Certifications')
                .doc(_Uid)
                .collection('Certificates')
                .doc(moment().valueOf().toString())
                .set({
                    Participant: _studentName,
                    EventCode: String(event.EventCode),
                    CreatedOnUtc: _dateOnUtc,
                    CertDownloadEnabled: CheckBoolean(event.CertDownloadEnabled),
                    CertSN: '',
                    SchoolName: _schoolName,
                });
            console.log('new certificate is added.');
        }
        return { uid: _uid, pname: _profile.Name, email: _profile.Email, };
    }
    //2021.04.20 === Search & re-apply Cert info === end


    // //2021.08.30 === Search & re-apply All Students' Cert info === start
    // SearchAndReApplyAllStudentCert = async () => {
    //     let event = this.state.EventList.find(x => x.EventCode === 'ced0217d39cb49a4bb810e4b1abd04ae');
    //     console.log(event.EventCode);


    // }
    // //2021.08.30 === Search & re-apply All Students' Cert info === end

    //2021.08.30
    FetchStudentListInfo = async () => {
        let students = ['LYE XIN LE', 'Josiah Joel Charles', 'Jeslie Yee Ming Micheal', '潘玟淇', 'TAN BOON CHAI ', 'NG SHIN ICHI', 'OOI ZHI YAN', 'Lexus Kuek Chen Xian', 'KAU ZI EN', 'Ng Kee Siang', 'Jayden Lee Wei Yang', '陈秋怡', 'NUHA BINTI NAZARI', 'Karunyah Jagatheesan', 'Lai Zi Lin', 'Lim Si Ting', '黄家春', 'KHOR ZI QING', '黎熙银', 'Lee Kang Rui', 'CASPER LU JUN YI', 'JOSEPH TEE CHUN SENG', 'Reshika Kanagaraj', 'CHIN EN QIAO', 'CELESTIA DING LE XUAN', 'Boon Hong Zhe ', 'ONG ZHEN KAI', 'Howard Ooi', 'teo jing jia', 'Tay Xiao Ci Drina', 'AINAL NADZMIZAN HAQIMI BIN MORSHIDI ', 'Donny Lim Pe Hong 林柏宏', 'Tan Yee Han', 'Christiana Than Xiiao Qin',
            '林锶洁', 'Yap Jia Ee ', 'Ong Sze Chin', 'TAN LE YANG', 'Lim Li Ying', 'Tan Xin Jie', 'Hau Lanice', 'YAP JIA HONG ', 'OOI ZI XIANG', 'CHIAM ZI QIAN', 'Ryan Toh Kean Jun ', 'HOW SYNN TATT', 'Jassie Yap Shin Fen', 'Yong Kak Hey', 'Tee Sheng Yuan', 'Ong le shuen', 'Toi Xin Mei', 'Wang Yu Quan', 'JOEY LEONG', 'CAYSON CHIA JIN ONN', 'Jayne', 'Beh Zhen Li', 'CHEONG ZHI KANG', 'Lee Jun Ahn', 'Wong Zhen Xuan', 'Channery Teoh Zhi Han', 'choong', 'SUKI YAP', 'Choo Yee An', 'Oon Yu Xin', 'LIM YU QING', 'Cheah Jun Yee', 'Cheng Jee Keat', 'Danaus Wan Kai Xuan', 'Eden Cham', '陈雪熔', 'AIK KE YIN', 'Charisse Tay Qi Yi', '叶美芬', 'Nicholas Voon',
            'Lee Jia Xuan ', 'Foo fang rui', 'Teoh Tze Yi', 'Ghooi Khe Xing', 'Ng Yong Xin', 'CHUNG WEN CHANG', 'Muhammad Amirul Aiman Bin Shahrul Azman', 'Thye Liang Xuan', 'ONG KHAI TONG', 'Jeng Ying', 'NICOLE TANG YU HAN', 'LAU XIN TIAN', 'CHOW YANG SHAN ', 'Tan Hui Zheng', 'Eric Ang Boon Hao ', 'Twinkle teo bee chen', 'Kok Samuel ', 'Darren shee wen tong', 'Angelina CJ Millip ', 'LIEW JIA XU', 'LOO WEN CHIN', 'Sim Chee Mei', 'Luai KahYew', 'Olivia Lee ning', 'NURUL FARHANA AIN DAMIA BINTI FAIZAL', 'Christina Jane Cabreros', 'LEE ZI YO', 'DANIEL SIA KHOON PING', 'CLOONIE NIGHTINGALE TAN ', 'Ting Zi Qian', 'Nicholas Sio Zhe Ning ',
            'Tan Wee Loong', 'IVAN ANG ZHENG HONG', 'Lim Jhee Shen ', 'Lee Kar Jay', 'Wan Jia Xin', '叶芳钰', 'CHIN ZI XUAN', 'Sia Qi Shen', 'Ooi ming min', 'Chang Tong Jian', 'KYRA KE XIN KAVIRAJ', 'Choong Xu Zen', 'LEE CAI YAN', 'CHUA KAI XIANG', 'fsaXDAA', 'WONG ZHENG YING ', 'LIM XIN RU', 'Kok Zhi Tze ', '陈真亿', 'CHEN XIN ER', 'Tan Yu Sheng', 'Jonathan Ng bin Abdullah ', 'Pua Yu Suang', 'Natalie Sio Yee Ning ', 'DAVID SU YUE MIN', 'Yu Zen ', 'Chew Yee Jia', '林嘉悦', 'BOON SI QI', 'Mak Chu Yuan', 'Low Zhi Hann', 'JORDAN TAN YONG TENG', '谢洁宁', 'CHAI SI EN', 'YEAP YI ZHE ', 'ANDERSEN NG JIN XIAN', 'Chan Kar Jun', 'MARCUS CHONG QUAN LONG',
            'LEONG YU MIn', 'GOH GUAN JIE', 'ONG ZI WEI', 'Wong Wan Ching', 'On Zee Jun', 'Tan Zi Xin ', 'JAZVIER LIM HAO XUAN', 'YAP KAI SAM', 'law yu han ', 'Ng Kar Fai', 'GOH XUE RUI', 'Fow Yong Sheng', '郑嘉欣Teh Jia Xin', 'Lidya Hidayani Bt Lamhawari', 'Ding ZiHao ', 'Tan Ve Joy', 'HANNAH POON ZI HOON', 'CHENG TAI YIN', 'GAN YIH JIA', 'LAI JIAN YUAN', 'Tan Rui Xuan ', '沈光浩', 'August See Kai Sheng', 'Chan Eamon', '刘紫宁Janice', 'Estee Tan Yi Tyan ', 'Chan Ning', '李欣恩', 'GAN KAI ZE', '颜泋钐Gan Hui Shan', 'Ng Yean Xin', 'TAN LI YI', 'Ma Xin Rou', '李可萱', 'Ryan Lim Jin Le', 'NG SHENG ZHE', '李珮瑜 LEE PEI YU', 'YAP LU XIN', 'Damian Chong Chi Weng',
            '叶祖翔', 'KWOK WING SUM Moe', 'EUNICE CHANG YU NING ', 'Lee Zhong Run ', 'Chua Jun Cheng', 'JOSHUA EMMANUEL JEFFERY', 'Joan Ang Kim Yin', '黄榆恬', '林凯丰', '苏俊威', 'Khoo Yong Yun ', 'Jonathan Ng Zheng Zhe', 'Ivan Yeow Kai Pin', 'kerkjiayu', 'Ellena Chuah Jinn Yew', 'Iu Wei Jie', 'Too Shi Wen', 'WONG YAN KHAI', 'Heng Shin Shuen ', 'CHING HAO YANG', 'LIM ZHI EN', 'Vemalraj ', 'Lee Yee Jing ', '毛贝恩', 'GOH WEN QIAN', 'LIM TONG XUAN', 'LEO LEONG KAI QUAN', '颜晟才Gan Sheng Cai', 'LEE ZHENG YANG', 'Hoo Zhong Kent', 'ANG ZI XIANG ', 'Tan Yi Ern', 'Tan Hao Ting', 'Evan Chan', 'Tan Xi Heng ', 'LOH WEN YAN', 'Thong Zhi Ying ', 'Chen Jun Loong', '乡俊',
            'Show Jin Yi', 'Teoh Xin En', 'TAN WYN EN', '    陈星宏', 'ESTHER', 'Kong Chi Ron ', 'Justin Loo Qi Yip ', 'LEE KA YEW', 'TAN JIAN HAN', 'Liew yu xin', 'Ng Xin Yee', '李彦橦', 'Lim Ze Han', 'CHEW YU TIAN', 'EVONE LU YU THONG ', 'Chong jay jing', 'TAN WEI YANG', 'Tan Xuan Yee', 'DECLAN CHAN YIK KAI', 'Ng Shamira ', 'Olivia Yee Qiao Ting', 'YAP XIN THONG', 'JUSTIN NG JIA LE 黄家乐', 'LOW CHENG HAO', 'Wong Yan Binn', 'Lai Jing Wei', 'YAP XIN XUAN', 'Loo Sheng Horng', 'NOR FAZLY AHMAD DANIEL BIN FAIZAL', 'Teeyuxuan', 'Selene Yeap Shen Leng', 'Vincent Yeap Zhan Rui', 'Tang Hong Xuan', 'Rainee Lau', 'JAYDEN ONG WEI PING', 'Angel Lee Xuan Lin', 'HANG WEI RU',
            'Law Le Xin', 'Tan Ze Ren', 'Song Jia Rou', 'CHONG JAY JING', 'Seo Hwang ', 'YONG ZHEN YAO', 'GLINDA NGAN YEE CHEAN ', 'Tah Wan Ning', 'Chua Rui Bing', 'Chloe Chan', 'Claire Pua Wen Yin', 'CHEW ZHEN XIN', 'CHEN JIA XIN', '黄宇政', '吴启睿', 'TAN YI HENG', 'Nge Jia Zheng', 'ERIN BINTI YATIM', 'Cheng Pei Yi', 'ROGINE A/P CHANDRASEKARAN ', 'Chloe Yeo Jing Xuan ', 'Ling Yi Xiang', 'CHAN CHUI LEK', 'Tiffany Lee Rheok Szee', 'LAY ANG HAN', 'Tey Ming Hao', 'LEW SIN EE', 'LIM LE KUAN', 'Ooi Wen Zhe', 'Gan Ai Min', 'Teo Kian Giap', 'Wong Xiao Han', 'amber wong xun xuan ', 'Siew Jia hui', 'Wang Xin Wei', 'NURAISY NADIA BINTI MOHD ROZI', 'Wang Yi Bing ', 'Tan Wei Yu',
            'Lau Xin Ru Shannon ', 'Sharmeli Phi Yen Khanna', 'Annabelle Soon En Chyi', 'JANER NG ZI YANG', 'Goh Bing Jie', '尤泽竣', 'CHONG JING WEI', '林志凱 Lim Zhi Kai', 'LAY ANG ZHE', 'Glennyse Ng Wei Chi ', 'Thon Jayie', '张嘉倩', 'Oon jia chen', 'CHUA ENG MING', 'Winny un', '李家耀', 'Tan Chiew Yan ', 'Loo winnie', 'Tong xin yue', 'Wong Anne Ni', 'Leong Yi xin', '郑廣盛 Tee Guang Sheng', 'LOH YAN LING', 'Lye Meng jun', 'WONG JEZWIN', 'ANDI ERYNAA A.SAHRIL', 'CHONG CHEE HENG ', 'Hee Jun Lok', 'TAN YI JOH', '甘心恬', 'Lee Yuan Xuan', 'Chng Ka Wai', 'Wong Ern Xin', 'Pua bee ling ', 'SEE BEE TIN', 'Khoo Shi Enn', 'Giselle Ng Yann-Yuee', 'ELISE GOH ZHI YING', 'rex fong yun cheng',
            'Mak Teng Teng', 'Lee Rui Faye', 'ZHENG LIEW ZI QI', 'So Ken Lee', 'SHAMAYNE TAN JIA XUAN', 'Edward Ng Zi Teng', 'MUHAMMAD AMIR FAIZ AL AMIN BIN MOHD YUSLIN', '黄雯柔', 'Joy Chan hooi yan ', 'Loke Kai Yuan / 骆楷源', 'LIEW QIU XIANG', 'Fion Chook Xin Rou', 'Lim Hui En ', 'Aisyah Aisyikin Binti Fairuoze', 'Hew Zi Xian', 'LAU MAN HEI ', 'MOK RUI SHUEN', 'Lim En Er', 'Liam Gan', 'JALVYNE TAN KA MIN', 'Hoo Jean Yee', 'Ng Ivonne ', 'Rachel Chee', 'Wang Xuanwen ', 'Natalia Au Yong Hui Yi', 'CHONG YIN CHEN', 'Liang Yu Xin', 'Ho En Yi ', '林芯瑜', 'YEOH ZHE KAI', 'Tan hao yi', 'Wu Kai Er', 'TEE XI YING', 'Chow Ying Xuan', 'YEOH JING YI', 'WONG JUN BIN', 'LIM XIN YAN',
            'Jayden Lee Hong Zhan ', 'Lohjingxian', 'Tan Zi Yang', 'Than Zee Yu', 'Ng Kar Seng', 'QIANJO NG', 'JOEY LIN YUXI', 'Wong Si Hong', 'YAP JIAN HENG', 'Tang Qian Tong', 'diong zhi yao', 'Lau yong jun', 'Ryan Chang Bo Wei', 'Loke Yun Foon', 'KOK ZHI QI', 'Teoh Chen Kai', 'Phuah Yie Hung', 'Lee Yun Xuan ', 'Jennifer Tan', 'Alston Lim Jia Le', 'Wong Jun Wei', 'Tai Rui Le', 'Daniel Sim Yan Xuin', 'NAQSYA QALEESH ADRIAN BIN SHAHARUDIN', 'Chuah Seong Yu', 'Wong Mei Jia ', 'Ong huai yue', 'Brayden Tan Yan Heng', 'Lee Sin Yun', 'Tan Pei Chyi', 'Wong Chia Min', 'Looi zi xuan', 'WONG ZHI SHENG', 'Ling Yi Han', 'Chen Qian hua', 'James Ng Zhi Yuan', 'Tan Yu Han', 'LIM PEI JUN',
            'Hannah Yap', 'HNG YOU WEI', 'Ng Fu Yun', 'Yongjyun', 'TEOH HUI HAN 张厞菡', 'NG KE XUAN', '.赖志伟', 'Fina Hoo Hui Na', 'Jennie sim jia ni ', 'Liew Hao Xiang 廖浩翔', 'Royce Lim Hong Shen', 'Lee Shovvyn', 'Eva Ivanka Edwin', 'HII SHIENG CHENG', 'SHARIFAH MUNIRAH BINTI SYED IDRUS', 'Lee Jin Henn', 'CHEW JUN YANG', 'Yeoh Shu Ting', 'Chong Yi Tong ', 'Khoo Wen Quan', 'Anson Bong Yang Le', 'Tan Jia Hui', 'Charmaine Ng Yu Qing', 'YAP LU QI', 'Winnie Tan Hui Yee ', '陈侣彤', 'Nick ang', 'Ng Kher Rui', ' Lau Shu En', 'JARYL LIM FANG JYUN', 'LIM YEE TIEN', 'Alice Ting Jing Er', 'TAN YAN KAI', 'Lee Jia sin', 'BOON KIA HENG', 'Ng Hong Tat', 'ruixing vijayakumar', 'Paris Tan Jen Hann',
            'Terrence Lim', 'Teo Jya Le', 'LOW YING HERN', 'Tan Yu Thong ', 'Ng Zhen Zhen', 'NG ZI WEI', 'Low Guan Huong', 'CHO YU FOONG', 'YEE TZE XUAN', 'CHAN XIN NING ', 'ng wei jay', 'SAN JIE FEI', '叶佳敏', 'Wong Guan Minh', 'xing zhou', 'Fadiah Iman Ishak', 'Cashlyn wong', 'Darren Chew Hong Wei', 'CHLOE TENG WENN XUAN', '黄薇恩', 'Yong JIA RU', 'Low Phey Shueann ', 'Vellarya De Glorya Helvid', 'NG WEN WEN ', 'CHOE YONG EN', 'TAN SIOK LENG', 'KEE WEN JIE', '孙淑桦', 'CHONG KUAN JOO', 'Tee Hee Er Hebe', 'ONG JIA YING', 'CHAN MEI QI', 'Shanice Loo Xin Tung', 'Davisson Berundang Anak Carefree', 'YAP Hao Qing', 'Tan Shin Yan', 'Tavian Soo Zhen Khai ', 'CHONG YU CHEN', 'Lee Xing Nee',
            'Heng Xuan Ru', 'Khor Xun Ming ', 'JAYDEN LIM JAY EN', 'Mou Yu Zheng', 'TEE CHU XUAN ', 'Ke Xin ', 'chan sook kuan', 'KEOK FENG MING', 'CHEONG HIN YAN ', 'LIEW YUE CHENG', 'kiew sy', 'Yap Zhe Yu', 'Saw Sheau Faye', 'Alyce 吴恺滢', 'ELYN TAN YU XIN', 'SIA QI SHEN', 'LIM XIN JE', 'Shinela Letchumi Devandaran ', 'Tan Qian Ying ', 'Leong Qing Xian ', 'CHAN RUO AN ', 'Wang Rou Yee', 'Emmanuel Raj', 'ONG YI KHAI', 'CHARLES LOKE HONG AN', 'SIEW JUN HONG', 'Wong Yik Ken', 'Wong Xien Zhen', 'Cheam Zi How', '林千荻', '张楷伦', 'Tan Yu Jie', 'Chin Zi Xuan', 'Chue Qing En', 'Vong Xuan Qi', 'CHIN HUI WEN', 'Teh Zu Er', 'Regene Goh Zenxuan ', 'Putri Arissa Binti Muhamad Azrul', 'TAN KAI JIE',
            'Alvin liew yu ze', 'FOO SHI YUAN', 'ng wu xun', 'Lau You Yi', 'Khoo Jing Er', 'Andria Liew En Yu', 'Yap Hao Jing', 'Kyoren Lai Shuang Lee', 'Sam Jia Qi', 'Pua yu xuan', 'Wee En Qi', 'TAN JIA HUI', 'LAU JUN WEN', 'Shasikumar a/l Subramanian', 'Zachary Seet Li Wern', 'NG HAO YANG', '颜思成', 'Hoi Jia Enn ', 'Xavier wong le cheng ', 'CHIA MEE CHENG', 'Jaden Lee Zhen Han', 'ANGEL TOH PEI YING', 'TAN YU XUAN', 'Inthira A/P Ai Huat', 'Gan xiao tong ', 'NICOLE LEE NIAN EN', 'Kenny lim', 'Jason Gan Zhi Xiang ', '黄志岷', 'Lin Ren Yi', 'Lim Engi', 'Chia Yi Xuan', 'Lian Shiney', 'HO JIA WEI', 'Lim Zi Qing ', 'JENNIFER CHIN POH YI', '朱文轩', 'Chong Sheng', 'Clarence Chew Shin Yang',
            'HA YONG XIN', 'OOI YU XUAN', 'Goay Xuan Minn', 'Wendy Phang Xing Ying', 'Chow Hui Sheng', 'Gan Raylen', 'YONG WING TENG', 'NISRIEEN BINTI NAZARI', 'Chloe Foong ', 'Chan Zheng Tin', 'NG WEI HARN', '萧贝诺', '郑卓霆', 'Yong Khai Yin', 'Lim Jin Han', 'NICHOLE CHONG JIA XIN ', 'Ang yu en', 'ISAAC LIM HONG YAN', 'Lee Tze Ben', 'ONG QI HAO', 'Ngan Yuen Yee', 'SZE KAH TENG', 'LIM HAN YI', '杨萤盈', 'Fan  Zhi  Xuan', 'CY', '黄紫淇', 'Wong Han You', 'Aiden Tan Zhe Yi ', 'Faith Chia Yee Ling', 'OON JIA JIA', 'Thiang Seng Joon', 'CHOK CHAI FUN ', 'Chua Tze Qi', 'Cassandra low xin ru', 'Janelle Lim Zi Yan ', 'WOON FU SEN', 'Ter Cheng Huat', 'Chen Siew Wei ', 'Ang Eu Tong', 'WONG ZI LE',
            'Nicole Ngu Rou Yi', 'TAN SHENG WEI', 'CAREEN LIEW', 'CHAN ZI YING', 'Eyshwar A/L Thiruvasagam', 'Kingsley Law Zi Jun', 'Ong Zhi Hao', 'YAP XIN YAO', 'TAN TZE YAN', 'LING ZI JUN', '潘韦杰', 'Hong Sin Le', 'Low Ming Jun', 'Yeoh Yu Ying', 'NORFAZILAH BINTI AYU', '吴雨韩', 'CHIN KAR EE 陈家翊', 'lim zhen wei', 'Ng Tze Han', 'Wong Xue En', 'Ng Yan Xiang', 'Bee Rui Jie', 'Ong Zixuan', 'Naida Law', 'Ng Jue Rui', '颜靖恩', 'CHEANG QIAN LING', 'Ang yee siang', 'Loh Jia Enn', 'Kaw Yu Er', 'Kisalyasri A/P Lingarao', 'CHUA ANGEL ', 'Darren Lok Jun Aik', 'Lee Yee Jing ', 'CHONG YI', 'Edward Teo Zhi Xian', 'Muhamad Nazumi Bin Mohd Nazri', 'Ong Shi Tian', 'GOH JIAN LER', 'ANDRE KHOO HAO YIK',
            'Ng Xiang Bing', 'WONG PEI JIE', 'Choi Min Yan', '蔡佳韵', 'Ku Wei You', 'AUSTIN TOH WU SIANG', 'Joelle Chin Zi En', 'Edric Phang Yi Sheng', 'Rachel A/P CALEB JEBASEGARAN ', 'KONG ZHUO XUAN', 'DICKSON JULIO WONG TECK SHENG', '郑盛鏞 Ting Sheng Rong', '陈咿宁', 'KO YI TENG', 'CHAN KAI QUAN ', 'Yap Ke Xuen', 'Tan Je Won', 'Nicole Voo Nie Koh ', 'Wong e Ro ', '高玮汎', 'YEOH JIN ZHI', 'Chiow Boon Jie', 'Lee Hoong Sheng ', 'Voon Bok Xuan', 'Tan Ze Khai', 'Lim Shun Zhi', 'Tan Ben Yi', 'Ng Ze Yuan', 'Celine Tan Hwei Xin ', 'Low Rou En', 'Chung Zhun San', 'Ooi Zhi Ying', 'Wong Jun Ern', '黄倩怡', 'LIU XIN YU', 'Reyna Deon By Chian Han', 'Cherry Ong Ying Rou', 'Teow sing shan ',
            'CHOW ZHUO JIN', 'TAN YINN WEI', 'Kok Jackson', 'TEOH TZE HAN', 'Annson Khoo', 'Yeng Cheng Chew', 'Ashlyn Soo Qi Wei', 'Gan huei kwan', 'CHUA JIA SHIN', 'Caden Tan Jun Bin', 'Megan Tan Yu En', '谢铭谦', 'Tan Jie Yang', 'Ket Kinson', 'Nur Qaseh Nabila binti Azahari', '余建颖', 'LU PO RU', 'TAN KE QI', 'Leong Weng Hei', 'Chloe Lee Han Ee', 'Toh Zhun Xian ', 'Racheal Voon Xin Yee', 'Chai Li Yee', 'Chiu Yi Ting', 'LEE RUO ZHEN', 'Song Jing Yu', 'LEE HAO SHUEN', 'Lee Guan Hong', 'Yuki Chye Zhi Qian', 'Choo Yong Hann', 'CLIFFORD ONG MOE', 'khaw zhi yu', 'LIEW WEI HAO', 'JAVEN WONG JIN HAN ', '黄心乐', 'Chia Shao Yang', 'Ooi Jyun Yit ', '陈姿萍', 'WONG HUI XIN', 'LEE JOWIN',
            'Liew guan teng', 'Wong ming qian ', '奥利维亚', 'Pua yu han', 'GAN XIN YU', 'NG WEE QI', 'Ellie Ang Wei Xin ', 'Tey Zhi En', 'Lim Peng You ', '张詠涵', 'Ong Guo Ming ', 'TAN QIAN XIN ', 'Tan Chun Xian ', 'Doris Ang Wan Peng', 'Tan Yi Hong', 'TAN YU AN', 'Joey Lim Zi In ', 'Wong Zi Yu', 'Daniel Dheeran ', 'LOH HAO ZHIN', 'Tan Yu Hong', '杨善如', '郑婉柔', 'CHUA SIANG ANN', 'WOON WAI YEONG', 'Goh Zhen Hong', 'Matthew Chee Jia Wai 徐茄瑋', '林嘉宜', 'Chua Zeng Hei', 'BEH KEAT TOM', 'LEONG HOK JENG', 'Ng Jia Qi', 'Lee Wen Shuen', 'Cheng Cai Ni Vian', 'TEE ZI QING', 'LIM FEI QI', 'WONG SHEAU SWEN ', 'KOE JING SEANG', 'Terence Tee', 'Ooi Yee Xuan', 'Ashley Jane Chan',
            'Fathin Hani Karlysha', 'WONG YU XUAN', 'Claire Hieu Ai ker ', 'Chua Chenxun', 'Liew Li Han', 'EUNICE YONG TZI QIN ', 'LEE QIN XUAN', 'Chang Weng Kean', 'TAN JUN HONG', 'chin yun hui', 'Soon Yi Zin', 'Wong Bei Ern', 'Dylan Khor Ming Feng', 'Fun Yee Ning', 'Ellron tai kie jie', 'NEO CAI XIN', 'ko yi zhen', 'Ng Guan Yuan ', 'Eliezer Alexzandre Embers Popou Danil', 'feltylia ngui', 'Ong Kai Yuan', 'Yee hui ying', 'Ashley Wong Zhi Xuan', 'Chan Ming Jing', 'WONG HAO ZE', 'Teoh Sze Yu', 'TAN YOU HUA', 'Law Yi Xuan', 'Agnes Yong', 'chea chun rui', '王康杰', 'Aiden Neoh Wei feng', 'Liew Kaylee', 'Lim Hui Shan', 'Chananda', 'Lee Min xuan', 'Liew Jun Qi', 'Yong Ming Hui',
            'SIM PEI YI', 'Grace Hanna ', 'DECLAN TOH KY SHEN', 'Edmund', 'WONG YEE HAN', 'Chuah yi xuan ', 'YONG HAO JAY', 'Yap Jia yin', '  Chang Zi Xuan', 'chan yu cheng', 'SOO YING', 'Lee Jie Xing', 'WONG YUAN TANG', 'CHOO HONG RUI', 'Giovanni Chrisha Jaril', 'Goh Jia Jie', '吕慧恩', 'Ivan Liew', 'Chia Hann C', '徐敏亿', 'MUHAMMAD ADAM AMSYAR BIN HAMDAR', '林哲辉', 'Ha minh thu', 'Evan Chung Zhi Hong', '刘颂杰', 'Quek yuh thong ', 'ONG SU YI', 'Jayden Tan shaan zun', 'Tan Yuan Qiao', 'Auston Chan Yi Ci', 'Valdekha Jazz Entigar Anak Valentine Mekai', 'LAI KAI HONG', 'TEH KAI WEN', 'Ong Kai Qi ', 'Tan yee shan', 'HEW HONG YAN', 'Test ', 'Eng Li Fei', 'NG SHU HUEY',
            'NUR QASSRIENA LOW', 'Pang Jeng Yii', 'Leong You Yue', 'ONG YAN HAN', 'KWAN EN JEN', 'AVIANA LING ZI XIN', 'Tina Boo Xin Tian', 'Ling Teck Hoe', 'Gan QIAO Xuan ', 'Soo Zi Yie', 'LIEW DE KAI', 'Gareth Ling Ee Jian', 'Jayson Lim Zhong Ren', 'Joseph yeap Jun xian ', 'KOO QIAN RU', 'Fun Sing Ying', 'SI Soo Qing SI', 'YAP KUN HENG ', 'WEE SHI JIE', 'Jayda Kong Jude Ning', '陈仔涛', 'Lee Jun Xian', 'Ng Xin Ru', 'Issac Tan Kai Junn', 'CHAN CHEN KWAN', 'Alda Gwenn anak Mazilan', 'ng wei sheng', 'Chong Zhen Ru', 'Clarence Ong Jia Le', 'Thishan sasinthran', '紫滢', 'Nelly How', 'TAN ZI HEN', 'LIM CHAN KANG LOONG', ' Janelle low chui rou', 'ALDEN LEE YUE ZHE',
            'holidaylawjiaqi', 'Hong Jin Rue', 'WAN ATHIRAH ISNAINI WAN SAFIE', 'LIM WEN HAN', 'LEE PEI YING', 'Liang Xin Rou', 'Tejesh Karthigesu', 'Gan Wei Zhen ', '陈芷莹', 'WONG JOON CHUEN', 'Brayden Yue Hao Xuan', 'Lim Yu Tin'];
        console.log('students = ' + students.length);

        let infos = [];
        // let FS_Found = false;
        for (let i = 0; i < students.length; i++) {
            // FS_Found = false;
            await this.props.firestore
                .collection("User")
                .where('Name', '==', students[i])
                .get()
                .then(querySnapshot => {
                    // isEmailNotExist = !querySnapshot.exists;
                    let profile = undefined;
                    if (querySnapshot !== null) {
                        profile = querySnapshot.docs.map(doc => doc.data())[0];

                        // //2021.11.23
                        // let _data = [];
                        // querySnapshot.docs.map(doc => { return _data.push(doc.data()); });
                        // profile = _data[0];
                    }
                    if (profile !== undefined) {
                        if (profile.hasOwnProperty('Name')) {
                            infos.push({
                                Name: profile.Name,
                                Email: profile.Email,
                                Grade: profile.Grade,
                                Source: 'FS',
                                Uid: profile.Uid,
                            });
                            console.log('#' + (i + 1) + ' = ' + JSON.stringify(infos[i]));
                            // FS_Found = true;
                        }
                    }
                });

            // if (FS_Found === false) {
            //     await this.props.dbCommon
            //         .ref('Common/' + this.props.user.uid)
            //         .once('value', snapshot => {
            //             let profile = undefined;
            //             if (snapshot !== null) {
            //                 profile = snapshot.val();
            //             }
            //             if (profile !== undefined) {
            //                 if (profile.hasOwnProperty('_1Name')) {
            //                     infos.push({
            //                         Name: profile._1Name,
            //                         Email: profile._7Email,
            //                         Grade: profile._2Grade,
            //                         Source: 'RTDB',
            //                         Uid: profile._15Uid,
            //                     });
            //                     console.log('#' + (i + 1) + ' = ' + JSON.stringify(infos[i]));
            //                 }
            //             }
            //         });
            // }
        }
        console.log(JSON.stringify(infos));
    }

    //2021.09.06
    SearchAndMigrateRoomResults = async () => {
        this.setState({ actionInProgress: true });
        console.log('started');

        // let UserIdsByEvent = [];
        let _event = this.state.EventList.find(x => x.EventCode === this.state.SelectedEventCode);

        let level3roomid_old = '1630633817274';
        let level4roomid_old = '1630633971156';

        let level3roomid = '1630728463082';
        let level4roomid = '1630730727212';

        let _date = moment(_event.DateStart).format('YYYYMMDD');

        //Fetch Personal Records.
        let _records = [];
        await this.props.dbLiveQuiz.ref('pkquiz/' + _date + '/pkquiz-personal-data')
            .once('value')
            .then((snapshot) => {
                if (snapshot !== null) {
                    // _records = snapshot.val();
                    _records = Object.entries(snapshot.val());

                    // let Keys = _(snapshot.val()).keys().value();
                    // let Values = _(snapshot.val()).values().value();
                }
            });
        console.log('Total Records = ' + _records.length);
        // return null;

        if (_records !== null) {
            // _records.map(async (data, key) => {
            for (var i = 0; i < _records.length; i++) {
                let data = _records[i];
                let _uid = data[0];
                let _rooms = data[1];
                console.log('Uid = ' + _uid);
                // console.log('data = ' + JSON.stringify(data));

                // if (_uid === 'ZSFcQFdziLT0H8fEPT3Iu1kPpcs1') {

                //Level 3 room.
                if (_rooms.hasOwnProperty(level3roomid_old)) {
                    if (_rooms.hasOwnProperty(level3roomid) === false) {
                        await this.props.dbLiveQuiz
                            .ref('pkquiz/' + _date + '/pkquiz-personal-data/' + _uid + '/' + level3roomid)
                            .set(_rooms[level3roomid_old]);
                        // console.log('Room L3 result = ' + JSON.stringify(_rooms[level3roomid_old]));
                        console.log('Room L3 result migrated.');
                    }
                }

                //Level 4 room.
                if (_rooms.hasOwnProperty(level4roomid_old)) {
                    if (_rooms.hasOwnProperty(level4roomid) === false) {
                        await this.props.dbLiveQuiz
                            .ref('pkquiz/' + _date + '/pkquiz-personal-data/' + _uid + '/' + level4roomid)
                            .set(_rooms[level4roomid_old]);
                        // console.log('Room L4 result = ' + JSON.stringify(_rooms[level4roomid_old]));
                        console.log('Room L4 result migrated.');
                    }
                }
                // }
            }
            //     return null;
            // })
            //     .then(() => {
            //     this.props.SetAlert('', 'Done', false);
            // });
        }

        this.setState({ actionInProgress: false },
            () => {
                this.props.SetAlert('', 'Done', false);
            });
    }

    //2022.06.11
    LoadEventList = async () => {
        let eventList_lastVisible = null;
        let eventList = [];
        this.setState({ IsEventListLoaded: false, });

        //2022.06.03 edited.
        await this.props.firestore.collection("LiveQuiz_UpcomingEvents").orderBy('DateStart', 'desc')
            .limit(20)
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                        dataArray[dataArray.length - 1].Id = doc.id;    //important
                    });
                    if (dataArray.length > 0) {
                        eventList = dataArray;
                        eventList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    }
                }
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(eventList));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('load event list (error) =\n' + error);
            });
        for (let i = 0; i < eventList.length; i++) {    //2023.11.14
            eventList[i]['checked'] = false;
        }
        this.setState({
            EventList: eventList,
            EventList_lastVisible: eventList_lastVisible,
            IsEventListLoaded: true,
        });
    }

    //2022.06.13
    GetRoomInfoByRoomId = async (_roomId) => {
        let roomInfo = null;
        await this.props.firestore
            .collection("LiveQuiz_UniqueRoomCode")
            .where('RoomId', '==', Number(_roomId))
            .limit(1)
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                        dataArray[dataArray.length - 1].Id = doc.id;    //important
                    });
                    if (dataArray.length > 0) {
                        roomInfo = dataArray[0];
                    }
                }
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(roomInfo));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('load room info (error) =\n' + error);
            });
        return roomInfo;
    }

    //2022.06.27
    Set_CompleteApiUrlWithParams = (event) => {
        let value = event.target.value !== null ? String(event.target.value) : '';
        if (value.length > 0 && value.includes('http') === false) {
            value = GlobalSetting.ApiUrl + value;
        }
        this.setState({ CompleteApiUrlWithParams: value, });
    }
    RunApiFrom_CompleteApiUrlWithParams = async (_method = 'GET') => {
        await fetch(this.state.CompleteApiUrlWithParams,
            {
                method: _method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then((data) => {
                console.log('api login response =\n' + JSON.stringify(data));
            })
            .catch(error => {
                console.log('api error =\n' + error.message);
            });
    }

    //2022.11.07
    FilterListOfStudents = async () => {
        let studentList = [];
        const listOfStudents = await import('../../components/listOfStudents').then((data) => { return data.default; });
        if (listOfStudents !== undefined) {
            if (listOfStudents.length > 0) {
                listOfStudents.map((data, key) => {
                    if (data.Event.length === 0) {
                        studentList.push(data.Uid);
                    }
                    return null;
                })
            }
        }
        // this.DownloadTxtFile(studentList, 'StudentListUid_(Lack-Of-CertInfo)_' + moment().format('YYYY-MM-DD_HHmmss'));
        this.DownloadTxtFile(studentList, 'StudentListUid_(KD-SJKC)_' + moment().format('YYYY-MM-DD_HHmmss'));
    }

    //2022.11.07
    FillBackMissingCertInfo = async (event = '') => {
        this.setState({ actionInProgress: true, progressStatusText: null });
        let _progressStatusText = [];
        let listOfStudentsUid = [];
        let _selectedEventCode = '';
        switch (event) {
            case 'SK':
                // const { listOfStudentsUid_SK } = await import('../../components/listOfStudentsUid.js');
                // listOfStudentsUid = listOfStudentsUid_SK;
                _selectedEventCode = '7f42183f6d4141a2b307ddaa3ec9651c';
                break;
            case 'SJKC':
                // const { listOfStudentsUid_SJKC } = await import('../../components/listOfStudentsUid.js');
                // listOfStudentsUid = listOfStudentsUid_SJKC;
                _selectedEventCode = '5bf804027933414495aa64e0fbb2e7e7';
                break;
            case 'KD':
                // const { listOfStudentsUid_KD } = await import('../../components/listOfStudentsUid.js');
                // listOfStudentsUid = listOfStudentsUid_KD;
                _selectedEventCode = 'b3ff4b3d76b9418f9af582cc59979657';
                break;
            default: break;
        }
        this.setState({ SelectedEventCode: _selectedEventCode, });

        if (listOfStudentsUid !== undefined) {
            if (listOfStudentsUid.length > 0) {
                listOfStudentsUid.map(async (studentUid, key) => {
                    const { uid, pname, email } = await this.SearchAndReApplyCertInfo(studentUid, '');
                    _progressStatusText.push('(Done) | ' + pname + ' | ' + email + ' | ' + uid);
                    this.setState({ progressStatusText: _progressStatusText.join('<br />'), });
                    return null;
                });
            }
        }
        this.setState({ actionInProgress: false, progressStatusText: _progressStatusText.join('<br />') + '<br /><br />FillBackMissingCertInfo (Done).' });
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <div style={{
                    // backgroundColor: 'lavender',
                    backgroundColor: 'transparent',
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 15
                }}>
                    <div className="container" style={{ width: '100%' }}>

                        <div className="row">
                            <div className="col-3">
                                <p style={{
                                    color: 'white', fontSize: 25, textAlign: 'left', fontWeight: 'bold', paddingLeft: 22
                                }}>Quiz Admin Tools</p>
                            </div>
                        </div>

                        <div className="row">

                            <aside className="col-3">

                                <div className="card">
                                    <article className="card-body">

                                        <button type="button" className="btn btn-outline-secondary btn-block"
                                            onClick={this.GoBack}
                                        > back </button>

                                        <span>&nbsp;</span>

                                        <button type="button" className="btn btn-outline-primary btn-block"
                                            onClick={() => this.ToggleList(Toggle.Registration)}
                                            style={{
                                                backgroundColor: this.state.toggleMenuOption === Toggle.Registration ? "#007bff" : "white",
                                                borderColor: "#007bff",
                                            }}
                                        ><span style={{ color: this.state.toggleMenuOption === Toggle.Registration ? "white" : "#007bff" }}>Registration Info</span></button>

                                        {/* <span>&nbsp;</span> */}

                                        <button type="button" className="btn btn-outline-primary btn-block"
                                            onClick={() => this.ToggleList(Toggle.ProfileRemoval)}
                                            style={{
                                                backgroundColor: this.state.toggleMenuOption === Toggle.ProfileRemoval ? "#007bff" : "white",
                                                borderColor: "#007bff",
                                            }}
                                        ><span style={{ color: this.state.toggleMenuOption === Toggle.ProfileRemoval ? "white" : "#007bff" }}>Profile Search / Removal</span></button>

                                        {/* <span>&nbsp;</span> */}

                                        <button type="button" className="btn btn-outline-primary btn-block"
                                            onClick={() => this.ToggleList(Toggle.QuizHistoryMonitor)}
                                            style={{
                                                backgroundColor: this.state.toggleMenuOption === Toggle.QuizHistoryMonitor ? "#007bff" : "white",
                                                borderColor: "#007bff",
                                            }}
                                        ><span style={{ color: this.state.toggleMenuOption === Toggle.QuizHistoryMonitor ? "white" : "#007bff" }}>Quiz History Monitor</span></button>

                                        <span>&nbsp;</span>

                                        <button type="button" className="btn btn-outline-primary btn-block"
                                            onClick={() => this.ToggleList(Toggle.List)}
                                            style={{
                                                backgroundColor: this.state.toggleMenuOption === Toggle.List ? "#007bff" : "white",
                                                borderColor: "#007bff",
                                            }}
                                        ><span style={{ color: this.state.toggleMenuOption === Toggle.List ? "white" : "#007bff" }}>List</span></button>

                                        <button type="button" className="btn btn-outline-primary btn-block"
                                            onClick={() => this.ToggleList(Toggle.ManageQuestion)}
                                            style={{
                                                backgroundColor: this.state.toggleMenuOption === Toggle.ManageQuestion ? "#007bff" : "white",
                                                borderColor: "#007bff",
                                            }}
                                        ><span style={{ color: this.state.toggleMenuOption === Toggle.ManageQuestion ? "white" : "#007bff" }}>Question</span></button>

                                        <button type="button" className="btn btn-outline-primary btn-block"
                                            onClick={() => this.ToggleList(Toggle.Etc)}
                                            style={{
                                                backgroundColor: this.state.toggleMenuOption === Toggle.Etc ? "#007bff" : "white",
                                                borderColor: "#007bff",
                                            }}
                                        ><span style={{ color: this.state.toggleMenuOption === Toggle.Etc ? "white" : "#007bff" }}>Etc</span></button>

                                        {/* <button type="button" className="btn btn-outline-primary btn-block"
                                            onClick={() => this.ToggleList(Toggle.EventCertificateStateRecovery)}
                                            style={{
                                                backgroundColor: this.state.toggleMenuOption === Toggle.EventCertificateStateRecovery ? "#007bff" : "white",
                                                borderColor: "#007bff",
                                            }}
                                        ><span style={{ color: this.state.toggleMenuOption === Toggle.EventCertificateStateRecovery ? "white" : "#007bff" }}>Quiz Event<br />Certificate<br />State Recovery</span></button> */}

                                    </article>
                                </div>

                            </aside>

                            <div className="col-9">

                                <div className="card">
                                    <article className="card-body">

                                        {
                                            this.state.toggleMenuOption === Toggle.Registration ?
                                                <RegistrationInfo

                                                    user={this.props.user}

                                                    firestore={this.props.firestore}
                                                    dbCommon={this.props.dbCommon}
                                                    dbLogs={this.props.dbLogs}

                                                    SetAlert={this.props.SetAlert}

                                                />
                                                : null
                                        }

                                        {
                                            this.state.toggleMenuOption === Toggle.ProfileRemoval ?
                                                <ProfileRemoval
                                                    user={this.props.user}

                                                    firestore={this.props.firestore}
                                                    dbCommon={this.props.dbCommon}
                                                    dbLogs={this.props.dbLogs}

                                                    SetAlert={this.props.SetAlert}
                                                />
                                                : null
                                        }

                                        {
                                            this.state.toggleMenuOption === Toggle.QuizHistoryMonitor ?
                                                <QuizHistoryMonitor
                                                    user={this.props.user}

                                                    firestore={this.props.firestore}
                                                    dbCommon={this.props.dbCommon}
                                                    dbLogs={this.props.dbLogs}

                                                    SetAlert={this.props.SetAlert}
                                                />
                                                : null
                                        }

                                        {
                                            this.state.toggleMenuOption === Toggle.List ?
                                                <div style={{ border: '1px solid gray', width: '100%', padding: 10 }} >

                                                    {
                                                        this.state.isFireStoreQuizExists ?
                                                            <>
                                                                {
                                                                    this.state.quizListInFireStore.map((data, key) => {
                                                                        return (
                                                                            JSON.stringify(data)
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <span>list of quiz created.</span>
                                                    }
                                                </div>
                                                : null
                                        }

                                        {
                                            this.state.toggleMenuOption === Toggle.ManageQuestion ?
                                                <div style={{ border: '1px solid gray', width: '100%', padding: 10 }} >
                                                    list of questions created.
                                                </div>
                                                : null
                                        }

                                        {
                                            this.state.toggleMenuOption === Toggle.Etc ?
                                                <>
                                                    <div style={{ border: '1px solid gray', width: '100%', padding: 10 }} >Etc</div>

                                                    {/* <br />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => this.SaveEventListToFS()}
                                                        // disabled={this.state.actionInProgress}
                                                        disabled={true}
                                                    >Save Event list to FS</button> */}

                                                    {/* <br /><br />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => this.RandomSetEvent()}
                                                        disabled={this.state.actionInProgress}
                                                    >Random Set Event to FS</button> */}

                                                    {/* <br /><br />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => this.GetRandomEventList()}
                                                        // disabled={this.state.actionInProgress}
                                                        disabled
                                                    >Get List of Random Set Events</button>
                                                    &nbsp;IsPublic = true, Active = true */}

                                                    {/* <br /><br />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => this.DataMigration_RegistrationData(0)}
                                                        // disabled={this.state.actionInProgress}
                                                        disabled
                                                    >Data Migration - RegData - Smart Newton</button> */}

                                                    {/* <br /><br />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => this.DataMigration_RegistrationData(1)}
                                                        // disabled={this.state.actionInProgress}
                                                        disabled
                                                    >Data Migration - RegData - SIM (5k+)</button> */}

                                                    {/* <br /><br />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => this.DataMigration_LiveQuizEventJoinedList(0)}
                                                        disabled={this.state.actionInProgress}
                                                    // disabled
                                                    >Data Migration - LiveQuizEventJoinedList - abacus_challenge_smartnewton_2021_0206</button> */}

                                                    {/* <br /><br />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => this.DataMigration_LiveQuizEventJoinedList(1)}
                                                        disabled={this.state.actionInProgress}
                                                    // disabled
                                                    >Data Migration - LiveQuizEventJoinedList - spot_2020_sim_1209_1211</button> */}

                                                    {/* <br /><br />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => this.DataMigration_LiveQuizEventJoinedList(2)}
                                                        disabled={this.state.actionInProgress}
                                                    // disabled
                                                    >Data Migration - LiveQuizEventJoinedList - colin_bm_2021_0130</button> */}

                                                    {/* <br /><hr /><br />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => this.SearchAndInsert_LiveQuiz_UniqueRoomCode(
                                                            ['20210120', '20210121', '20210125', '20210126', '20210130', '20210206']
                                                        )}
                                                        // disabled={this.state.actionInProgress}
                                                        disabled
                                                    >LiveQuiz_UniqueRoomCode - Search and Insert</button> */}

                                                    <br />
                                                    <hr style={{ border: '2px solid' }} />
                                                    {/* <Row>
                                                        <Col>
                                                            <DropdownButton
                                                                id="admin-tool-event-list-dropdown-button"
                                                                title={this.state.SelectedEventCode !== null ? this.state.EventList.find(x => x.EventCode === this.state.SelectedEventCode).EventName : "Select Event"}
                                                                drop="down"
                                                                onSelect={option => this.setState({ SelectedEventCode: option }, () => { console.log(this.state.SelectedEventCode) })}
                                                                disable={String(!this.state.IsEventListLoaded).toLowerCase()}
                                                            >
                                                                {
                                                                    this.state.EventList.length > 0 ?
                                                                        this.state.EventList.map((data, key) => {
                                                                            return <Dropdown.Item as="button" eventKey={data.EventCode} key={'dd_evt_' + key}>{data.EventName}</Dropdown.Item>;
                                                                        })
                                                                        : null
                                                                }
                                                            </DropdownButton>
                                                        </Col>
                                                    </Row>
                                                    <span>&nbsp;</span> */}
                                                    {/* <Row>
                                                        <Col>
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={this.SearchAndInsert_LiveQuiz_Cert}
                                                                disabled={this.state.actionInProgress || this.state.SelectedEventCode === null}
                                                            // disabled
                                                            >Certificates - ParticipatedEventList - insert for all</button>
                                                        </Col>
                                                    </Row>
                                                    <hr /> */}


                                                    <button type="button" className="btn btn-primary"
                                                        onClick={this.SearchAndInsert_LiveQuiz_Cert_V2}
                                                        disabled={this.state.actionInProgress || this.state.EventList.filter(x => x.checked === true).length === 0}
                                                    >Certificates - ParticipatedEventList V2 - insert for all</button>
                                                    <br /><br />
                                                    <DropdownButton
                                                        id="admin-tool-event-list-dropdown-button-cert"
                                                        title={this.state.EventList.filter(x => x.checked === true).length > 0 ?
                                                            '(' + this.state.EventList.filter(x => x.checked === true).length + ') are selected'
                                                            : "Select Event"}
                                                        drop="down"
                                                        aria-rowcount={5}
                                                        onSelect={option => {
                                                            let eventList = this.state.EventList;
                                                            let eventIndex = eventList.findIndex(x => x.EventCode === option);
                                                            if (eventIndex > -1) {
                                                                eventList[eventIndex]['checked'] = !eventList[eventIndex]['checked'];
                                                                this.setState({
                                                                    EventList: eventList
                                                                }, () => {
                                                                    console.log(JSON.stringify(this.state.EventList));
                                                                });
                                                            }
                                                            else {
                                                                console.log('Event not found: ' + option);
                                                            }
                                                        }}
                                                        disable={!this.state.IsEventListLoaded || this.state.actionInProgress}
                                                    >
                                                        {
                                                            this.state.EventList.length > 0 ?
                                                                this.state.EventList.map((data, key) => {
                                                                    return <Dropdown.Item as="button" eventKey={data.EventCode} key={'dd_evt_cert_' + key} style={{ textAlign: 'left' }}>{CheckObjectBoolean(data, 'checked') ? '✔' : '-'} {data.EventName}</Dropdown.Item>;
                                                                })
                                                                : null
                                                        }
                                                    </DropdownButton>
                                                    {
                                                        this.state.PEList_Cert_Progression_Text.length > 0 ?
                                                            <>
                                                                <br />
                                                                <span><div dangerouslySetInnerHTML={{ __html: this.state.PEList_Cert_Progression_Text }}></div></span>
                                                                <br />
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        this.state.PEList_Cert_Progression_Progress.length > 0 ?
                                                            <>
                                                                <br />
                                                                <span><div dangerouslySetInnerHTML={{ __html: this.state.PEList_Cert_Progression_Progress }}></div></span>
                                                                <br />
                                                            </>
                                                            : null
                                                    }

                                                    <br />
                                                    <br />
                                                    <hr style={{ border: '2px solid' }} />
                                                    <DropdownButton
                                                        id="admin-tool-event-list-dropdown-button"
                                                        title={this.state.SelectedEventCode !== null ? this.state.EventList.find(x => x.EventCode === this.state.SelectedEventCode).EventName : "Select Event"}
                                                        drop="down"
                                                        onSelect={option => this.setState({ SelectedEventCode: option }, () => { console.log(this.state.SelectedEventCode) })}
                                                        disable={!this.state.IsEventListLoaded}
                                                    >
                                                        {
                                                            this.state.EventList.length > 0 ?
                                                                this.state.EventList.map((data, key) => {
                                                                    return <Dropdown.Item as="button" eventKey={data.EventCode} key={'dd_evt_' + key} style={{ textAlign: 'left' }}>{data.EventName}</Dropdown.Item>;
                                                                })
                                                                : null
                                                        }
                                                    </DropdownButton>
                                                    <br />
                                                    <input
                                                        type='text'
                                                        placeholder='email address'
                                                        onChange={this.SetUid_SearchAndReApplyCert}
                                                        value={this.state.SearchAndReApplyCert_StudentEmail}
                                                        // maxLength={40}
                                                        // size={25}
                                                        style={{ width: '100%', marginBottom: 20, }}
                                                    />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={this.SearchAndReApplyCertInfo}
                                                        disabled={this.state.actionInProgress}
                                                    // disabled
                                                    >Certificates - Search And Re-Apply Cert Info by Uid</button>


                                                    {/* <br /><hr /><br />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={async () => await this.SearchAndReCalculateScoreRecord()}
                                                        // disabled={this.state.actionInProgress}
                                                        // disabled
                                                        disabled={true}
                                                    >(Quiz) Score Record - Search And Re-Calculate Score Record & Ranking join list</button> */}
                                                    {
                                                        this.state.SearchAndReCalculateScoreRecord_Progress.length > 0 ?
                                                            <>
                                                                <br />
                                                                <span><div dangerouslySetInnerHTML={{ __html: this.state.SearchAndReCalculateScoreRecord_Progress }}></div></span>
                                                            </>
                                                            : null
                                                    }


                                                    {/* <br /><br />
                                                    <input
                                                        type='date'
                                                        onChange={event => this.setState({ SearchAndReApplyAllStudentCert_QuizDate: event.target.value })}
                                                        value={this.state.SearchAndReApplyAllStudentCert_QuizDate}
                                                        // maxLength={40}
                                                        size={25}
                                                    />&nbsp;
                                                    <input
                                                        type='text'
                                                        onChange={event => this.setState({ SearchAndReApplyAllStudentCert_QuizRoomId: event.target.value })}
                                                        value={this.state.SearchAndReApplyAllStudentCert_QuizRoomId}
                                                        // maxLength={40}
                                                        size={25}
                                                    />&nbsp;
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={this.SearchAndReApplyAllStudentCert}
                                                        disabled={this.state.actionInProgress}
                                                    // disabled
                                                    >Certificates - Search And Re-Apply All Students' Cert Info by Date & RoomId</button> */}

                                                    {/* <br /><hr /><br />
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={this.FetchStudentListInfo}
                                                        // disabled={this.state.actionInProgress}
                                                        // disabled
                                                        disabled={true}
                                                    >Custom Fetch Student List Info</button> */}

                                                    {/* <br /><hr /><br />
                                                    <Row>
                                                        <Col>
                                                            <DropdownButton
                                                                id="admin-tool-event-list-dropdown-button-1"
                                                                title={this.state.SelectedEventCode !== null ? this.state.EventList.find(x => x.EventCode === this.state.SelectedEventCode).EventName : "Select Event"}
                                                                drop="down"
                                                                onSelect={option => this.setState({ SelectedEventCode: option }, () => { console.log(this.state.SelectedEventCode) })}
                                                                // disable={!this.state.IsEventListLoaded}
                                                                disabled={true}
                                                            >
                                                                {
                                                                    this.state.EventList.length > 0 ?
                                                                        this.state.EventList.map((data, key) => {
                                                                            return <Dropdown.Item as="button" eventKey={data.EventCode} key={'evt_' + key}>{data.EventName}</Dropdown.Item>;
                                                                        })
                                                                        : null
                                                                }
                                                            </DropdownButton>
                                                        </Col>
                                                        <Col>
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={this.SearchAndMigrateRoomResults}
                                                                // disabled={this.state.actionInProgress || this.state.SelectedEventCode === null}
                                                                // disabled
                                                                disabled={true}
                                                            >Migrate Room Results to New Room</button>
                                                        </Col>
                                                    </Row> */}

                                                    {/* <br /><hr /><br />
                                                    <input
                                                        type='text'
                                                        onChange={this.Set_CompleteApiUrlWithParams}
                                                        value={this.state.SearchAndReApplyCert_StudentEmail}
                                                        // maxLength={40}
                                                        size={25}
                                                    />&nbsp;
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={this.RunApiFrom_CompleteApiUrlWithParams}
                                                        disabled={this.state.actionInProgress}
                                                    // disabled
                                                    >Run Api (GET)</button> */}

                                                    {/* <br /><hr /><br />
                                                    <Row>
                                                        <Col>
                                                            <DropdownButton
                                                                id="admin-tool-event-list-dropdown-button"
                                                                title={this.state.DataMigration_SelectedOption > -1 ? String(this.state.EventRegData[this.state.DataMigration_SelectedOption].eventName) : "Select Event"}
                                                                drop="down"
                                                                onSelect={option => this.setState({ DataMigration_SelectedOption: parseInt(option) }, () => { console.log('selected = ' + this.state.DataMigration_SelectedOption) })}
                                                                disabled={this.state.actionInProgress}
                                                            >{
                                                                    this.state.EventRegData.length > 0 ?
                                                                        this.state.EventRegData.map((data, key) => {
                                                                            return <Dropdown.Item as="button" eventKey={key} key={'dm_evt_' + key}>{data.eventName}</Dropdown.Item>;
                                                                        })
                                                                        : null
                                                                }</DropdownButton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={this.DataMigration_RegistrationData}
                                                                disabled={this.state.actionInProgress}
                                                            // disabled
                                                            >Data Migration - Execute Process</button>
                                                        </Col>
                                                    </Row> */}

                                                    {/* <br /><br /><br />
                                                    <Row>
                                                        <Col>
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={this.FilterListOfStudents}
                                                                disabled={this.state.actionInProgress}
                                                            // disabled
                                                            >Filter - List of Students</button>
                                                        </Col>
                                                    </Row> */}
                                                    {/* <br /><br />
                                                    <Row>
                                                        <Col>
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={() => this.FillBackMissingCertInfo('SK')}
                                                                disabled={this.state.actionInProgress}
                                                            // disabled
                                                            >Fill Back Missing Cert Info</button>
                                                        </Col>
                                                    </Row>
                                                    <br /><div dangerouslySetInnerHTML={{ __html: this.state.progressStatusText }}></div> */}
                                                </>
                                                : null
                                        }

                                    </article>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div style={{ height: '200px', width: '100%' }}>
                        <span>&nbsp;</span>
                    </div>
                </div>
            );
        }
    }
}