import React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import { Locale } from '../Localization/CustomLocalization.js';
import './AlertModalCSS.scss';
import { CheckBoolean } from './GlobalFunctions.js';

//2020.11.30
export let Btn = {
    None: 0,
    Close: 1,
    Yes: 2,
    No: 3,
    Confirm: 4,
    Cancel: 5,
};

//2020.11.30
export let Feedback = {
    None: 0,
    InternetDown: 1,

    //2021.04.02
    LogOut: 2,
    Edit: 3,
    New: 4,
    Delete: 5,
    // Notification: 6,
    // Message: 7,
    Loading: 6,
    Confirm: 7,
};

export default class Alert extends React.Component {

    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.mdRef = React.createRef();
    }

    state = {
        modalTitle: '',
        modalMessage: '',
        showAlertModal: false,
        hideProgressBar: true,

        //2020.11.30
        feedbackType: Feedback.None,
        showYes: false,
        showNo: false,
        showConfirm: false,
        showCancel: false,
        showClose: true,

        //202104.14
        showTitle: true,

        //2022.10.03
        extraComponent: null,
    }

    //==== Alert Modal === starts.

    CloseAlert = () => {
        this.setState({ showAlertModal: false });
    }

    ToggleAlertModal = (btnType) => {
        if (this.state.feedbackType === Feedback.Loading)
            return null;

        if (this.state.hideProgressBar === false)
            return null;

        btnType = btnType === undefined ? Btn.None : btnType;
        this.setState({
            showAlertModal: !this.state.showAlertModal,
            hideProgressBar: true,
        }, () => {
            if (this.state.feedbackType === Feedback.InternetDown) {
                setTimeout(() => {
                    this.props.Callback_AlertConfirm(btnType, Feedback.InternetDown);
                }, 2000);
            }
            else {
                this.props.Callback_AlertConfirm(btnType, Feedback.None);
            }
        });
    }

    HandleButtonClick = (btnType) => {
        this.ToggleAlertModal(btnType);
        switch (btnType) {
            case Btn.Close:
                break;
            // case Btn.Yes:
            //     this.props.Callback_AlertConfirm(btnType, this.state.feedbackType);
            //     break;
            // case Btn.No:
            //     this.props.Callback_AlertConfirm(btnType, this.state.feedbackType);
            //     break;
            // case Btn.Confirm:
            //     this.props.Callback_AlertConfirm(btnType, this.state.feedbackType);
            //     break;
            case Btn.Cancel:
                break;
            default:
                this.props.Callback_AlertConfirm(btnType, this.state.feedbackType);
                break;
        }
    }

    //2021.04.02
    SetLoading = (title, content, showTitle = true, extraComponent = null) => {
        this.setState({
            modalTitle: String(title),
            modalMessage: String(content),
            showAlertModal: true,
            hideProgressBar: false,

            feedbackType: Feedback.Loading,
            showYes: false,
            showNo: false,
            showConfirm: false,
            showCancel_Left: false,
            showCancel_Right: false,
            showClose: false,

            showTitle: showTitle,
            extraComponent: extraComponent,
        });
    }

    SetAlert = (title, content, showTitle = true, extraComponent = null) => {
        // let _content = String(content).replace(new RegExp('<icon alert>', 'g'), )
        this.setState({
            modalTitle: String(title),
            modalMessage: content,
            showAlertModal: true,
            hideProgressBar: true,

            //2020.11.30
            feedbackType: Feedback.None,
            showYes: false,
            showNo: false,
            showConfirm: false,
            showCancel: false,
            showClose: true,

            showTitle: showTitle,
            extraComponent: extraComponent,
        });
    }

    //2021.04.23
    SetAlertWithComponent = (title, content, showTitle = true) => {
        // let _content = String(content).replace(new RegExp('<icon alert>', 'g'), )
        this.setState({
            modalTitle: title,
            modalMessage: content,
            showAlertModal: true,
            hideProgressBar: true,

            //2020.11.30
            feedbackType: Feedback.None,
            showYes: false,
            showNo: false,
            showConfirm: false,
            showCancel: false,
            showClose: true,

            showTitle: showTitle,
        });
    }

    SetAlertWithProgressBar = (title, content, showBar) => {
        this.setState({
            modalTitle: String(title),
            modalMessage: String(content),
            showAlertModal: true,
            hideProgressBar: !CheckBoolean(showBar),

            //2020.11.30
            feedbackType: Feedback.None,
            showYes: false,
            showNo: false,
            showConfirm: false,
            showCancel: false,
            showClose: true,

            //2021.09.27
            showTitle: title.length > 0,
        });
    }

    //2020.11.30
    SetAlertConfirm = (title, content, feedbackType, showYes, showNo, showConfirm, showCancel, showClose) => {
        this.setState({
            modalTitle: String(title),
            modalMessage: String(content),
            showAlertModal: true,
            hideProgressBar: true,

            feedbackType: feedbackType === null ? Feedback.None : feedbackType,
            showYes: showYes,
            showNo: showNo,
            showConfirm: showConfirm,
            showCancel: showCancel,
            showClose: showClose,
        });
    }

    // getAlertMessage = () => {
    //     return { __html: this.state.modalMessage };
    // }
    //=== Alert Modal === ends.

    render = () => {
        return (
            <>
                <Modal
                    ref={this.mdRef}
                    show={this.state.showAlertModal}
                    onHide={this.ToggleAlertModal}
                    centered
                // dialogClassName='alert-dialog-bordered'
                >
                    {
                        //2021.04.14
                        // this.state.showTitle ?
                        // <Modal.Header
                        //     closeButton={
                        //         this.state.feedbackType !== Feedback.Loading
                        //             ? this.state.hideProgressBar === false : false
                        //     }
                        //     hidden={this.state.hideProgressBar === false || this.state.showTitle === false}
                        // >
                        //     <Modal.Title>
                        //         <span
                        //             style={{ fontSize: 16 }}
                        //         >
                        //             <b>
                        //                 <div dangerouslySetInnerHTML={{ __html: this.state.modalTitle }}></div>
                        //             </b>
                        //         </span>
                        //     </Modal.Title>
                        // </Modal.Header>
                        // : null
                    }
                    <Modal.Header
                        closeButton={
                            this.state.feedbackType !== Feedback.Loading
                                ? this.state.hideProgressBar === false : false
                        }
                        hidden={this.state.hideProgressBar === false || this.state.showTitle === false}
                        backdrop='static'
                        keyboard='false'
                    >
                        <Modal.Title>
                            <span
                                style={{ fontSize: 16 }}
                            >
                                <b>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.modalTitle }}></div>
                                </b>
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ fontSize: 14 }} >
                            {/* {this.state.modalMessage} */}
                            {/* <div dangerouslySetInnerHTML={this.getAlertMessage()}></div> */}
                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.modalMessage }}></div> */}
                            {
                                typeof (this.state.modalMessage) === 'object' ?
                                    this.state.modalMessage
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: this.state.modalMessage }}></div>
                            }
                            {this.state.extraComponent}
                        </div>
                        <br hidden={this.state.hideProgressBar} />
                        <ProgressBar animated now={100} hidden={this.state.hideProgressBar} />
                    </Modal.Body>
                    <Modal.Footer
                        hidden={this.state.hideProgressBar === false}
                    >
                        <div style={{
                            width: '100%',
                            textAlign: this.state.feedbackType !== Feedback.InternetDown ? 'right' : 'center',
                            // textAlign: 'right',
                        }}>
                            {
                                this.state.showYes ?
                                    // <Button
                                    //     className='button'
                                    //     variant='primary'
                                    //     onClick={() => this.HandleButtonClick(Btn.Yes)}>
                                    //     {Locale("yes", this.props.Locale)}
                                    // </Button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.HandleButtonClick(Btn.Yes)}
                                    >{Locale("yes", this.props.Locale)}</button>
                                    : null
                            }

                            {
                                this.state.showNo ?
                                    // <Button
                                    //     className='button'
                                    //     variant='secondary'
                                    //     onClick={() => this.HandleButtonClick(Btn.No)}>
                                    //     {Locale("no", this.props.Locale)}
                                    // </Button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => this.HandleButtonClick(Btn.No)}
                                    >{Locale("no", this.props.Locale)}</button>
                                    : null
                            }

                            {
                                this.state.showClose ?
                                    // <Button
                                    //     className='button'
                                    //     variant='secondary'
                                    //     onClick={() => this.HandleButtonClick(Btn.Close)}>
                                    //     {Locale("close", this.props.Locale)}
                                    // </Button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => this.HandleButtonClick(Btn.Close)}
                                    >{Locale("close", this.props.Locale)}</button>
                                    : null
                            }

                            {
                                this.state.showConfirm ?
                                    // <Button
                                    //     className='btn'
                                    //     variant='primary'
                                    //     onClick={() => this.HandleButtonClick(Btn.Confirm)}>
                                    //     {Locale("confirm", this.props.Locale)}
                                    // </Button>
                                    // <button
                                    //     type="button"
                                    //     className="btn btn-primary"
                                    //     onClick={() => this.HandleButtonClick(Btn.Confirm)}
                                    // >{Locale("confirm", this.props.Locale)}</button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.HandleButtonClick(Btn.Confirm)}
                                    >{Locale("confirm-type-2", this.props.Locale)}</button>
                                    : null
                            }

                            {
                                this.state.showCancel ?
                                    // <Button
                                    //     className='button'
                                    //     variant='secondary'
                                    //     onClick={() => this.HandleButtonClick(Btn.Cancel)}>
                                    //     {Locale("cancel", this.props.Locale)}
                                    // </Button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => this.HandleButtonClick(Btn.Cancel)}
                                    >{Locale("cancel", this.props.Locale)}</button>
                                    : null
                            }
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

