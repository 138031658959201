

// import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
// import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';

// import { authState } from 'rxfire/auth';
// import { collectionData } from 'rxfire/firestore';
// import { filter } from 'rxjs/operators';

const firebaseConfig = {
    apiKey: "AIzaSyCrcClX5GAEmH4ro4tdrx22VCBs85kuNWw",
    authDomain: "ikeyedutech-student.firebaseapp.com",
    databaseURL: "https://ikeyedutech-student.firebaseio.com",
    projectId: "ikeyedutech-student",
    storageBucket: "ikeyedutech-student.appspot.com",
    messagingSenderId: "838670185333",
    appId: "1:838670185333:web:2a892db22266ceef"

    // apiKey: "AIzaSyCrcClX5GAEmH4ro4tdrx22VCBs85kuNWw",
    //   authDomain: "ikeyedutech-student.firebaseapp.com",
    //   databaseURL: "https://ikeyedutech-student.firebaseio.com",
    //   projectId: "ikeyedutech-student",
    //   storageBucket: "ikeyedutech-student.appspot.com",
    //   messagingSenderId: "838670185333",
    //   appId: "1:838670185333:web:794fd3a5dec95ada"
};

const app = firebase.initializeApp(firebaseConfig);

//2021.04.06
const functions = firebase.functions();

// init
// let app1 = firebase.initializeApp(firebaseConfig);

// let firebaseConfig_PkQuiz = firebaseConfig;
// firebaseConfig_PkQuiz.databaseURL = "https://ikeyedutech-student-5ea3c-pkquiz.firebaseio.com/";
// let app2 = firebase.initializeApp(firebaseConfig_PkQuiz, 'app2');

// let firebaseConfig_Question = firebaseConfig;
// firebaseConfig_Question.databaseURL = "https://ikeyedutech-student-5ea3c-quizquestions.firebaseio.com/";
// let app3 = firebase.initializeApp(firebaseConfig_Question, 'app3');

// let firebaseConfig_Logs = firebaseConfig;
// firebaseConfig_Logs.databaseURL = "https://ikeyedutech-student-a28c5-logs.firebaseio.com/";
// let app4 = firebase.initializeApp(firebaseConfig_Logs, 'app4');


// let dbCommon = firebase.database();
// let dbLiveQuiz = firebase.database(app2);
// let dbQuestion = firebase.database(app3);
// let dbLogs = firebase.database(app4);


const firestore = firebase.firestore(app);
const auth = firebase.auth(app);
// let loggedIn$ = authState(auth).pipe(filter(user => !!user));


export {
    // collectionData, loggedIn$, 
    app, auth, firestore, firebaseConfig, functions
    // app2, app3, app4
    // dbCommon, dbLiveQuiz, dbQuestion, dbLogs
};

export default firebase;