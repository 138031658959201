export const NationalState = [
    "PERAK",
    "SELANGOR",
    "PAHANG",
    "KELANTAN",
    "JOHOR",

    "KEDAH",
    "MELAKA",
    "NEGERI SEMBILAN",
    "PULAU PINANG",
    "PERLIS",

    "TERENGGANU",
    "SABAH",
    "SARAWAK",

    "WILAYAH PERSEKUTUAN KUALA LUMPUR",
    "WILAYAH PERSEKUTUAN LABUAN",
    "WILAYAH PERSEKUTUAN PUTRAJAYA"
];

//list frm gov, 2022 June (2022.10.05)
export const SchoolList = [
    "OTHER",
    "SEKOLAH KEBANGSAAN TOH TANDEWA SAKTI - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN PENDITA ZA'BA - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN BANIR - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN TEMOH - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN CHENDERIANG - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN BIDOR - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN KAMPONG POH - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN BATU TIGA - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN BATU MELINTANG - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN HAJI HASAN - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN SRI KINJANG - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN KAMPONG PAHANG - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN BATU TUJUH - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN JERAM MENGKUANG - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN SUNGAI LESONG - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN AYER KUNING - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN BATU MASJID - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN SUNGKAI - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN PERLOK - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN BIKAM - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN CHANGKAT SULAIMAN - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN FELDA SUNGAI KELAH - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN BATU 14 - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN BESOUT 3 - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN TROLAK UTARA - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN TROLAK SELATAN - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN SERI BESOUT - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN POS GEDONG - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN KAMPUNG SENTA - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN POS MUSUH LZ - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN JERNANG - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN TAPAH - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN BIDOR 2 - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN DENDANG - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN BERUAS - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN KAMPUNG KOTA - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN GELONG GAJAH - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN CHANGKAT CHERMIN - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN AYER TAWAR - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN PANCHOR - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN PENGKALAN BAHARU - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SUNGAI BATU - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN LADANG HUNTLY - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SEGARI - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN NAKHODA MUHAMMAD TAIB - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SUNGAI RAMAI - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SUNGAI WANGI - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SERI SELAMAT - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SITIAWAN - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN LEKIR - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN MUHAMMAD SAMAN - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN BATU SEPULUH - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SUNGAI TIRAM - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN DATO' ISHAK - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN LUMUT - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SERI PANGKOR - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN TELAGA NENAS - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN KAMPUNG BAHARU - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN KAYAN - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN PANGKALAN TLDM - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SERI MANJUNG - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN PANTAI REMIS - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN GUGUSAN LEKIR - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN PANGKALAN TLDM II - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN KPG. DATO' SERI KAMARUDDIN - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SERI SITIAWAN - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SERI BAYU - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SERI SAMUDERA - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SUNGAI RAYA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SUNGAI RAPAT - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SUNGAI ROKAM - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN TAMBUN - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN TANJONG RAMBUTAN - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN TASEK - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN CHEPOR - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN HAJI MAHMUD - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SYED IDRUS - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN DATO' PANGLIMA KINTA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN JALAN PANGLIMA BUKIT GANTANG - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SERI KEPAYANG - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN KAMPUNG PASIR PUTEH - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN KUALA PARI - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN PENGKALAN PEGOH - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN PENGKALAN BAHARU - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN TOH INDERA WANGSA AHMAD - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN SIPUTEH - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN TRONOH - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN KG. SERI RAHMAT - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN TANJUNG TUALANG - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN AYER PAPAN - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN CHANGKAT TIN - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN BAKAP - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN PASUKAN POLIS HUTAN - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN AYER DENAK - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN MANJOI (SATU) - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN GURU KALGIDHAR - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN MANJOI (DUA) - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS IPOH - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN JELAPANG - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN DATO' AHMAD SAID TAMBAHAN - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN POS RAYA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SERI TANJUNG - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SERI AMPANG - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN JALAN PEGOH - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SERI KELEBANG - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN PERPADUAN - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN RAJA CHULAN - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN TAMAN BERSATU - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN PENGKALAN - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN JATI - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN BANDAR BARU PUTERA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SILIBIN - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN TASEK DERMAWAN - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SIMPANG PULAI - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN RAPAT JAYA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN PINJI - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN JELAPANG JAYA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN BUNTONG - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SERI JAYA - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN RAPAT SETIA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN WIRA JAYA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SERI MUTIARA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN TASIK DAMAI - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN PAKATAN JAYA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN KELEBANG JAYA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN TANAH HITAM - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN MERU RAYA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN ALOR PONGSU - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN BAGAN SERAI - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN BUKIT MERAH - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN CHANGKAT LOBAK - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN DATO' MAS'UD - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN KUALA KURAU - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN MATANG GERDU - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN MATANG JELUTONG - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN PARIT HAJI TAIB - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN PARIT HAJI AMAN - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN SUNGAI BOGAK - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN SRI SIAKAP - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN TEBUK PANCUR - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN TELUK MEDAN - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN TELOK PIAL - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN JALAN BAHARU - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN HAJI RAMLI - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN KAMPONG KEDAH - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN SRI KERIAN - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN PARIT HJ WAHAB - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN PARIT TOK NGAH - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN SUNGAI BAHARU - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN SUNGAI BURONG - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN SUNGAI STAR - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN TANAH KEBUN - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN TANJONG PIANDANG - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN TITI SERONG - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN SUNGAI MEGAT ARIS - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN GUNONG SEMANGGOL - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN KAMPONG DEW - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN DATO' ALAUDIN - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN SAMA GAGAH - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN SUNGAI KEPAR - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN SERI PINANG - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN KAMPONG TUA - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN KAMPONG SELAMAT - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN HAJI DAHLAN - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN KUALA GULA - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN KUALA KURAU (BARU) - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN BARU PARIT BUNTAR - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN WAWASAN - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN SAYONG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN KOTA LAMA KANAN - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN PADANG AMPANG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN BELURU - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN BENDANG KERING - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN MENORA - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN BEKOR - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN KUALA KENAS - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN SENGGANG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN RAMBAI TUJUH - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN SULTAN IDRIS II - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN RAJA PEREMPUAN MUZWIN - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN TANAH MERAH - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN SYEIKH MOHD. IDRIS AL-MARBAWI - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN KATI - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN CHEH - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN SAUK - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN SULTAN ABDUL AZIZ - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN ULU KENAS - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN LEMPOR - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN JELIANG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN ARA PANJANG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN MANONG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN ULU PIOL - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN TALANG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN PADANG ASAM - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN TUN DR ISMAIL - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN PAYA SALAK - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN LANEH - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN PEREMPUAN - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN KARAI - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN SUNGAI SIPUT (U) - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN TROSOR - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN LASAH - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN RKT LASAH - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN JAWANG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN PERIANG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN TEMIN - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN JALONG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN TEMONG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN CHEGAR GALAH - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN LINTANG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN KAMPUNG MAAMOR - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN ULU RIBU - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN SEROLI - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN JENALIK - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN CHENEIN - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN PERLOP 1 - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN POS POI - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN POS PERWOR - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN POS LEGAP - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN POS PIAH - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN KAMPUNG KENANG - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN POS KUALA MU - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN POS SUNGAI PELANTOK - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN RAJA MUDA MUSA - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN KAMPUNG MUHIBAH - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN KAMPUNG NASIB - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN KUMPULAN GANDA - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SULTAN IDRIS 2 - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SERI INTAN - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SELABAK - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN CHANGKAT JONG - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN AYER HITAM - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN TEBOK BANJAR - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SUNGAI DURIAN - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN DATO' LAKSAMANA RAJA MAHKOTA - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN TELUK BIRAH - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SUNGAI RUSA - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SUNGAI JEJAWI - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN DATO' YAHYA SUBBAN - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SUNGAI TUNGKU - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN CHIKUS - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN PERMATANG - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SG LAMPAM - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SUNGAI TUKANG SIDIN - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SERI LANGKAP - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN PEKAN RABU - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN PERMATANG PELANDOK - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN DEGONG - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN PENGKALAN ARA - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SUNGAI KERAWAI - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN KAMPUNG BAHARU REDANG PONGGOR - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN KAMPUNG BAHAGIA - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN SERI SETIA - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN RAJA MUDA MUSA - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN PERWIRA - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN ST.ANTHONY - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN LONG JAAFAR - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN ASAM KUMBANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN LAKSAMANA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN MATANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN NGAH IBRAHIM - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN MATANG GELUGUR - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SIMPANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SULTAN YUSSUF AMDR - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN TAIPING - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SERI AMAN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN CHANGKAT LARUT - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN BENDANG SIAM - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN TOH JOHAN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN TEMELOK - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN PADANG GAJAH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SUNGAI TINGGI - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN BATU HAMPAR - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN CHANGKAT JERING - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN JELUTONG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN DATO' PANGLIMA BKT GANTANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN CHANGKAT IBUL - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SULTAN ABDULLAH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN TOH SAJAK - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN KAMPONG PERAK - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN KAMPUNG GUDANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN KAMPUNG REPOH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN KAMPUNG YAMAN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN KAMPUNG RELANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN JELAI - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN PANTAI BESAR - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN PONDOK TANJONG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN ULU SEPETANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN HAJI WAN JAAFAR - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SUNGAI JERNIH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SRI KURAU - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SRI SELAMA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN TEBING TINGGI - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SUNGAI DENDANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN BAGAN BAHARU - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN RANTAU PANJANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN ULU MENGKUANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN BANGGOL JAS - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SUNGAI BAYOR - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN TOH RAKNA SAKTI - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SUNGAI SIPUTEH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SULTAN IDRIS II - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN BUKIT BERTAM - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SUNGAI ARA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN (RTBK) PONDOK TANJONG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN KAMUNTING - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS TAIPING - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN PENGKALAN AUR - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN AYER PUTEH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN BUKIT JANA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN KAMPUNG JAMBU - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN PERMATANG RAJA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN TAMAN PANGLIMA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN TAMAN JANA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN KAMPUNG BOYAN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN TAMAN PALMA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN KEROH - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN KUAK HULU - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN KUAK LUAR - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN BEKUAI - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN TASEK - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN KLIAN INTAN - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN AYER PANAS - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN LENGGONG - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN GELOK - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN SUMPITAN - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN BUKIT SAPI - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN TEMELONG - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN CHEPOR - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN BANGGOL BELIMBING - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN LUBOK KAWAH - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN LUAT - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN BENG - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN RABAN - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN SRI ADIKA RAJA - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN TAN SRI GHAZALI JAWI - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN SERI TAWAI - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN KENAYAT - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN KENERING - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN KERUNAI - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN PLANG - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN KAMPONG LALANG - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN RKT BERSIA - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN BASIA LAMA - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN BONGOR - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN GANDA TEMENGOR - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN (FELDA) PAPULUT - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN (FELDA) LAWIN UTARA - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN (FELDA) LAWIN SELATAN - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN PAHIT - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN (FELDA) LEPANG NENERING - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN RPS POS KEMAR - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN RPS BANUN - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN RPS DALA - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN SUNGAI TIANG - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN BATU 4 - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN SUNGAI GALAH - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN BALUN BIDAI - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN TEPUS - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN TANJONG BELANJA - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN SIMPANG TIGA - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN BELANJA - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN PASIR GAJAH - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN LAYANG-LAYANG KIRI - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN PULAU PADANG - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN BOTA KIRI - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN SERIKAYA - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN BAKONG - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN LAMBOR KIRI - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN CHANGKAT BANJAR - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN TELUK KEPAYANG - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN PARIT - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN LENGKUAS - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN TELOK PERANG - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN CHOPIN - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN BUKIT CHUPAK - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN LAYANG-LAYANG KANAN - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN CIKGU MIOR SHAHARUDDIN - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN BOTA KANAN - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN PADANG CHANGKAT - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN LAMBOR KANAN - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN TELOK BAKONG - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN SUNGAI RANGGAM - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN DATO' SAGOR - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN SELAT PULAU - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN TANJONG BIDARA - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN RAJA CHULAN - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN PULAU TIGA KIRI - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN TOH PADUKA RAJA - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN TOK PELITA - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN PULAU JUAR - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN BANDAR TUA - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN PASIR PANJANG ULU - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN SUAK PADI - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN PASIR KUBU - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN BANDAR - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN SUNGAI BESAR - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN KOTA SETIA - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN SEBERANG PERAK - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN SRI CHANGKAT - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN SERI CEMPAKA - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN CHENDERONG KELUBI - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN SUNGAI PERAH - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN CHANGKAT LADA 2 - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN TITI GANTONG - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN SERI ISKANDAR - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN FELCRA NASARUDDIN - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN CHANGKAT LADA 3 - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN ISKANDAR PERDANA - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN GOPENG - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN GUNUNG PANJANG - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN KOTA BAHARU - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN TUALANG SEKAH - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN MALIM NAWAR - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN KUALA DIPANG - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN SAHOM - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN KAMPAR - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN BUKIT PEKAN - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN AIR HITAM LABU - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN SUNGAI ITEK - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN ULU GERUNTUM - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN POS DIPANG - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN SENTOSA - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN CHANGKAT TUALANG - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN AMINUDIN BAKI - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN BALUN - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN DATUK NAN KAYA - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN ULU SLIM - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN KUALA SLIM - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN TANJONG MALIM - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN BEHRANG - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN SLIM VILLAGE - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN DATO KAMARUDDIN - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN SUNGAI BEHRANG - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN POS BERSIH - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN POS TENAU - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN PROTON CITY - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN BANDAR BEHRANG 2020 - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN TROLAK TIMUR - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN BESOUT 5 - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN BESOUT 4 - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN TAPAK SEMENANG - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SERI BARU - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI BETUL - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI BULOH - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN BAGAN PASIR - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI SUMUN - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI PULAU - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN TANJONG BAYAN - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN HUTAN MELINTANG - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN KAMPUNG BAHARU - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN TANAH LALANG - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI DULANG DALAM - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN TEBING REBAK - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN TELUK BULOH - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI KELI - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI SAMAK - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN ULU BERNAM - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN BAGAN DATOH - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI NIPAH - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN MATANG KUNDA - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI PERGAM - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SIMPANG TIGA - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI BALAI - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN RUNGKUP - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI TIANG BAROH - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI TIANG DARAT - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SELEKOH - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI BATANG - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SUNGAI HAJI MUHAMMAD - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SIMPANG EMPAT - PERAK - BAGAN DATUK",
    "SEKOLAH KEBANGSAAN SATU - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN DATUK KELANA - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN SERI BIDOR - PERAK - BATANG PADANG",
    "SEKOLAH KEBANGSAAN METHODIST (ACS) - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN METHODIST - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN CONVENT - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SIMPANG EMPAT - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN ST. FRANCIS - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN GANGGA - PERAK - MANJUNG",
    "SEKOLAH KEBANGSAAN SRI KINTA - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN CATOR AVENUE - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN RAJA DIHILIR EKRAM - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN METHODIST (ACS) - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN HO SENG ONG METHODIST - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN ST. MICHAEL - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN LA SALLE - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN RAJA PEREMPUAN - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN CONVENT - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN MARIAN CONVENT - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN TARCISIAN CONVENT - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN CORONATION PARK - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SULTAN YUSSUF - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN ST. BERNADETTE'S CONVENT - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN PUSING - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN SERI TRONOH - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN ST. PAUL - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN METHODIST - PERAK - KINTA UTARA",
    "SEKOLAH KEBANGSAAN METHODIST PARIT BUNTAR - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN JALAN MATANG BULUH - PERAK - KRIAN",
    "SEKOLAH KEBANGSAAN CLIFFORD - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN DATIN KHADIJAH - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN METHODIST - PERAK - KUALA KANGSAR",
    "SEKOLAH KEBANGSAAN SULTAN ABDUL AZIZ - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN HORLEY METHODIST - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN ST ANTHONY - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN CONVENT - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST - PERAK - HILIR PERAK",
    "SEKOLAH KEBANGSAAN KING EDWARD VII (1) - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN KING EDWARD VII (2) - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN ST GEORGE 1 - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN (P) TREACHER METHODIST - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN KLIAN PAUH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN CONVENT KOTA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN CONVENT AULONG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN ALL SAINTS' - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN SELAMA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN BATU KURAU - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN METHODIST - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH KEBANGSAAN MAHKOTA SARI - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN BUKIT BALAI - PERAK - HULU PERAK",
    "SEKOLAH KEBANGSAAN ISKANDAR SHAH - PERAK - PERAK TENGAH",
    "SEKOLAH KEBANGSAAN METHODIST (ACS) - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN DE LA SALLE - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN METHODIST - PERAK - KINTA SELATAN",
    "SEKOLAH KEBANGSAAN SLIM RIVER - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN METHODIST - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN KHIR JOHARI - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHENG LOK - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA MIN - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI KROH - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHENDERIANG - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHEUNG WA - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT PAGAR - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEKAN GETAH - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHUI CHEN - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPONG PAHANG - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA LIAN - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG HUA (1) - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG HUA (2) - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PIN MIN - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG COLDSTREAM - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TANAH MAS - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEKAN PASIR - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA BIKAM - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI MENG - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA LIAN (1) - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA LIAN (2) - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ENG LING - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PING MIN - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUR SENG - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHENG - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) UK ING - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHINESE NATIONAL - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) UK DIH - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIEN HUA - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEKAN GURNEY - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG LIMA - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KOK MING - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AYER TAWAR - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MIN TE - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG JERING - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI MIN - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPONG MERBAU - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHUEN HEAN - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI BATU - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TIT BIN - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHING - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YEN MIN - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHOY - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PERAK - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAM TET - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AVE MARIA CONVENT - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUH HUA - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POI LAM - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MIN TET - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SHAN - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAM CHAI - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PASIR PINJI (1) - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PASIR PINJI (2) - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) GUNTONG - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WAN HWA (1) - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WAN HWA (2) - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG TACK - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BERCHAM - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MIN SIN - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HING HWA - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAT CHOI - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHANGKAT KINDING - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST MICHAEL & ALL ANGELS - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG TAWAS - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHENG - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA KUANG - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TANAH HITAM - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JELAPANG - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT MERAH - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LAHAT - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAPAN - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YIT CHEE - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) GUNONG HIJAU - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIPUTEH - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SUN - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG BALI - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG NALLA - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK KWAN - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BEMBAN - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) THUNG HON - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI DURIAN - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPONG TIMAH - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) GUNONG RAPAT - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHUI YING - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BANDAR SERI BOTANI - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HWA - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BIN SIN - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAM HUA - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WAI SIN - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU CHAI - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HWA - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG WAH - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TONG WAH - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ALOR PONGSU - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AIK HWA - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAUK - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LIMAN - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI CHEE - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHIU MIN - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TSUNG WAH - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JERLUN - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MANONG - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SEY WAH - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SALAK BAHRU - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HING WA - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI BULOH - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SHING CHUNG - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG JALONG - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LINTANG - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LASAH - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) RIMBA PANJANG - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WAH KEOW - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PELAWAN - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BATU DUABELAS - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUI CHAK - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAN MIN 1 - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAN MIN 2 - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHOOI YONG - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG MIN - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HUA LIAN (1) - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HUA LIAN (2) - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HUA LIAN (3) - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POKOK ASSAM - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AULONG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHUI CHOI - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KWONG MAN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHOOI WAH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHEA WAH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POAY ENG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POAY CHEE - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHUEN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIU SIN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NGAI SENG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI ROTAN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAY HWA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HUA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AIK HUA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI SHENG (1) - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI SHENG (2) - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) REDANG PANJANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI TERAP - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) EOK KWAN - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG LI - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG. BAHARU BATU DUA - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA RUI - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG WA - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AYER KALA - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SELAT PAGAR - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PADANG GRUS - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YEONG HWA - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KOTA TAMPAN - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAY BENG - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - PERAK - PERAK TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NEW KOPISAN - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MAN MING - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LAWAN KUDA BARU - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JERAM - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YING SING - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI YUAN - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MAMBANG DIAWAN - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPAR GIRLS - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUAH - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU YING - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HO PIN - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHIN MIN - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HUA - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BEHRANG ULU - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SIN - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YEONG SENG - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POOI SENG - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POOI AING - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEOW MIN - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG SAN - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA NAN - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HUA HSIA - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YEE HWA - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG TIGA - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BAGAN PASIR LAUT - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAPAH - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KHIR JOHARI - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BHARATHY - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGKAI - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TONG WAH - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BIDOR TAHAN - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BIKAM - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI KRUIT - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGKAI - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KELAPA BALI - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BANOPDANE - PERAK - BATANG PADANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MAHA GANESA VIDDYASALAI - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PANGKOR - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PENGKALAN BARU - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG HUNTLY - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SOGOMANA - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG AYER TAWAR - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMPONG TUN SAMBANTHAN - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CASHWOOD - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMPUNG COLUMBIA - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG WALBROOK - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG WANGI II - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MUKIM PUNDUT - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMPUNG KAYAN - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) AYER TAWAR - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BERUAS - PERAK - MANJUNG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TANJONG RAMBUTAN - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KERAJAAN - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) ST PHILOMENA CONVENT - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PERAK SANGEETHA SABAH - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) METHODIST - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) CHETTIARS - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KG.SIMEE - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) GUNONG RAPAT - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MENGLEMBU - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) CHANGKAT - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TRONOH - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHEMOR - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHANGKAT KINDING - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KLEBANG - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG STRATHISLA - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KINTA VALLEY - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN DESA PINJI - PERAK - KINTA UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SELINSING - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG YAM SENG - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUALA KURAU - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SIMPANG LIMA - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BAGAN SERAI - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SAINT MARY'S - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI BOGAK - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GULA - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHERSONESE - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JIN SENG - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SOON LEE - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) ARUMUGAM PILLAI - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KALUMPONG - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GEDONG - PERAK - KRIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) GANDHI MEMORIAL - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MAHATHMA GANDHI KALASALAI - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI BIONG - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KATI - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GAPIS - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PERAK RIVER VALLEY - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) ENGGOR - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHANGKAT SALAK - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ELPHIL - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI REYLA - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG DOVENBY - PERAK - KUALA KANGSAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) THIRUVALLUVAR - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SITHAMBARAM PILLAY - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SELABA - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) DATO SITHAMBARAM PILLAY - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUSSEX - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) NATESA PILLAY - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI TIMAH - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SABRANG - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATAK RABIT - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NOVA SCOTIA 1 - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NOVA SCOTIA (2) - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG RUBANA 1 - PERAK - HILIR PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMUNTING - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) YMHA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) ST TERESA'S CONVENT - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) ULU SEPETANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SELAMA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PONDOK TANJUNG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG HOLYROOD - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MALAYA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SIN WAH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LAUDERDALE - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MATANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KG JEBONG LAMA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMPONG BARU BATU MATANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GETAH TAIPING - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ALLAGAR - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TEMERLOH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG STOUGHTON - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KERUH - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) GERIK - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KOTA LIMA - PERAK - HULU PERAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GLENEALY - PERAK - PERAK TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SERAPOH - PERAK - PERAK TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BULOH AKAR - PERAK - PERAK TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) GOPENG - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KOTA BAHROE - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MAMBANG DIAWAN - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAMPAR - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KAMPAR - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) METHODIST - PERAK - KINTA SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SLIM RIVER - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SLIM VILLAGE - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAN SRI DATO' MANICKAVASAGAM - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TROLAK - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CLUNY - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KATOYANG - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BEHRANG RIVER - PERAK - MUALLIM",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BARATHI - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JENDARATA 1 - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JENDARATA BHG 2 - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JENDARATA BHG 3 - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TELUK BULOH - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JENDARATA BAHAGIAN ALPHA BERNAM - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG FLEMINGTON - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TELUK BHARU - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KUALA BERNAM - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL & TELUGU) BAGAN DATOH - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG STRATHMASHIE - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NEW COCONUT - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ULU BERNAM 2 - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI SAMAK - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KAMATCHY - PERAK - BAGAN DATUK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN - PERAK - BAGAN DATUK",
    "SEKOLAH RENDAH ISLAM AL-EHYA ASSHARIFF - PERAK - KRIAN",
    "SRA AR-RIDHWANIAH - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN HAMID KHAN - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN TROLAK SELATAN - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) BESOUT - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI KERUIT - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN AIR KUNING - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN CHENDERIANG - PERAK - BATANG PADANG",
    "KOLEJ TINGKATAN ENAM TAPAH - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN BIDOR - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH SAINS TAPAH - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI MANJUNG - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN AHMAD BOESTAMAM - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN PANGKALAN TLDM - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN BATU SEPULUH - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN PANTAI REMIS - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI SAMUDERA - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN CHANGKAT BERUAS - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN KG. DATO' SERI KAMARUDDIN - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA CHULAN - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN PASIR PUTEH - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN GUNUNG RAPAT - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA PERMAISURI BAINUN - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' HJ. MOHD TAIB - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN SERI KELEDANG - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN KG. DATO' AHMAD SAID - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN KG PASIR PUTEH - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN SERI AMPANG - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN TASEK - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN SIMPANG PULAI - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN LAHAT - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN TASEK DAMAI - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN TANJONG RAMBUTAN - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN JELAPANG JAYA - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN BERCHAM - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN PUSING - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN BUNTONG - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN WIRA JAYA - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN JATI - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN PINJI - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN RAPAT SETIA - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN TAMBUN - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU PUTRA - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN ULU KINTA - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN PANGLIMA BUKIT GANTANG - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN SRI PERAK - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN ALANG ISKANDAR - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN ALOR PONGSU - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA LOPE NOR RASHID - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA KURAU - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN ABU BAKAR AL-BAQIR - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN GUNUNG SEMANGGOL - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN PEKAN BARU - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN SELINSING - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT MERCHU - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN TEMENGGONG - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN TOH MUDA ABDUL AZIZ SUNGAI SIPUT (U) - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN SIMPANG BELURU - PERAK - KUALA KANGSAR",
    "SEKOLAH SENI MALAYSIA PERAK - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN RLKT LASAH - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN SAYONG - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN BAWONG - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI MANIK - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PERAK - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDULLAH - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN ABDUL RAHMAN TALIB - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI SETIA - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI KANDI - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG BAHAGIA - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN DOKTOR BURHANUDDIN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI BAYOR - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH SAINS RAJA TUN AZLAN SHAH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SIMPANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN KAMUNTING - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' WAN AHMAD RASDI - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN JELAI - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN TASIK - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT JANA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG JAMBU - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN AUR - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN PANGLIMA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN KUBU GAJAH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN MATANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN KLIAN PAUH - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN TOH JOHAN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN REDANG PANJANG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPONG PERAK - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN KAMUNTING BAKTI - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN TAN SRI ABDUL AZIZ - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN BERSIA (FELDA) - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN AZLAN SHAH - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN DATO SERI WAN MOHAMED - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN KENERING - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN GERIK - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN HULU - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN BATU 4 - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BUDIMAN - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN MUHAMMAD SHAH - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN MUZAFAR SHAH 1 - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL JALIL SHAH - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN LAYANG-LAYANG KIRI - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN CHANGKAT LADA - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN LAMBOR KIRI - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RANGGAM - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SERI ISKANDAR - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SERI LONDANG - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SRI KAMPAR - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN SENTOSA - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI TEJA - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN KHIR JOHARI - PERAK - MUALLIM",
    "SEKOLAH MENENGAH KEBANGSAAN SLIM - PERAK - MUALLIM",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BEHRANG 2020 - PERAK - MUALLIM",
    "SEKOLAH MENENGAH KEBANGSAAN PROTON CITY - PERAK - MUALLIM",
    "SEKOLAH MENENGAH KEBANGSAAN ULU BERNAM - PERAK - BAGAN DATUK",
    "SEKOLAH MENENGAH KEBANGSAAN HUTAN MELINTANG - PERAK - BAGAN DATUK",
    "SEKOLAH MENENGAH SAINS TELOK INTAN - PERAK - BAGAN DATUK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PERKASA - PERAK - BAGAN DATUK",
    "SEKOLAH MENENGAH KEBANGSAAN RUNGKUP - PERAK - BAGAN DATUK",
    "SEKOLAH MENENGAH SAINS BAGAN DATOH - PERAK - BAGAN DATUK",
    "SEKOLAH MENENGAH KEBANGSAAN BUYONG ADIL - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN SRI TAPAH - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN CHOONG HUA - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN NAN HWA - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN DINDINGS - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN AYER TAWAR - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN ANDERSON - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN ST. MICHAEL - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST (ACS) - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA PEREMPUAN - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN METHODIST - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN TARCISIAN CONVENT - PERAK - KINTA UTARA",
    "SEKOLAH TUANKU ABDUL RAHMAN - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN PERAK - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN AVE MARIA CONVENT - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN SAM TET - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN POI LAM - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN YUK CHOY - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN SERI INTAN - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN SRI PUTERI - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PARI - PERAK - KINTA UTARA",
    "KOLEJ TINGKATAN ENAM SERI PUTERA - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN DR MEGAT KHAS - PERAK - KINTA UTARA",
    "KOLEJ TINGKATAN ENAM SERI IPOH - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN MENGLEMBU - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN YUSSUF - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN ST. BERNADETTE'S CONVENT - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN YUK KWAN - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN KRIAN - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN SRI KURAU - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN BAGAN SERAI - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA MUDA MUSA - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN TSUNG WAH - PERAK - KUALA KANGSAR",
    "KOLEJ MELAYU KUALA KANGSAR - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN SHING CHUNG - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK HJ ABD WAHAB - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL AZIZ - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA MUDA MUSA - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN ST. ANTHONY - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN HORLEY METHODIST - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN SAN MIN - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN ST. GEORGE - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN (P) TREACHER METHODIST - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN HUA LIAN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SRI KOTA - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN DARUL RIDWAN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPAR - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN PEI YUAN - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST - PERAK - MUALLIM",
    "SEKOLAH MENENGAH KEBANGSAAN KATHOLIK - PERAK - MUALLIM",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' PANGLIMA PERANG KIRI - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN SYEIKH ABDUL GHANI - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGKAI - PERAK - BATANG PADANG",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST (ACS) - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN TOK PERDANA - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA SHAHRIMAN - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN AMBROSE - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN PANGKOR - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' IDRIS - PERAK - MANJUNG",
    "SEKOLAH MENENGAH KEBANGSAAN TOH INDERA WANGSA AHMAD - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN AMINUDDIN BAKI - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' BENDAHARA C M YUSUF - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN TRONOH - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN MUDZAFFAR SHAH - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN CLIFFORD - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA PEREMPUAN KELSOM - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN TAJUL ARIFFIN - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN TUN PERAK - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' SAGOR - PERAK - HILIR PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN KING EDWARD VII - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN DATO KAMARUDDIN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' HJ HUSSEIN - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN TAT BENG - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU MENTERI - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN DATO AHMAD - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN IDRIS SHAH II - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN TUN SABAN - PERAK - HULU PERAK",
    "SEKOLAH MENENGAH KEBANGSAAN ISKANDAR SHAH - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' ABDUL RAHMAN YAAKUB - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' SERI MAHARAJA LELA - PERAK - PERAK TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN IDRIS SHAH - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN MALIM NAWAR - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST (ACS) - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO ZULKIFLI MUHAMMAD - PERAK - MUALLIM",
    "SEKOLAH MENENGAH KEBANGSAAN KHIR JOHARI - PERAK - BAGAN DATUK",
    "SEKOLAH MENENGAH KEBANGSAAN TUN ABD RAZAK - PERAK - BAGAN DATUK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI MUARA - PERAK - BAGAN DATUK",
    "MAAHAD TAHFIZ AL-QURAN & AKADEMIK BAKIP (MATAQAB) - PERAK - KINTA UTARA",
    "SEKOLAH IZZUDDIN SHAH - PERAK - KINTA UTARA",
    "SEKOLAH RAJA PEREMPUAN TAAYAH - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH RAJA DR. NAZRIN SHAH - PERAK - PERAK TENGAH",
    "SMA DAERAH BATANG PADANG - PERAK - BATANG PADANG",
    "MAAHAD ISLAHIAH ADDINIAH - PERAK - MANJUNG",
    "MAAHAD AL-IMAM ASY SYAFIEE - PERAK - KINTA UTARA",
    "SABK MAAHAD AL-UMMAH - PERAK - KINTA UTARA",
    "SEKOLAH MENENGAH AGAMA AL-HIDAYAH - PERAK - KRIAN",
    "SMA SHAMSUL MAARIF AL WATANIAH - PERAK - KRIAN",
    "SEKOLAH MENENGAH AGAMA AL FALAH - PERAK - KRIAN",
    "MAAHAD AL EHYA ASSHARIFF - PERAK - KRIAN",
    "MA'AHAD AR-RIDHWAN - PERAK - KRIAN",
    "SEKOLAH MENENGAH AGAMA AD-DINIAH AL-ISLAMIAH - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH AGAMA MAAHAD AL-MIZAN - PERAK - KUALA KANGSAR",
    "SMA MAAHAD NURUL FADZLIAH - PERAK - KUALA KANGSAR",
    "MAAHAD AL YAHYAWIAH - PERAK - KUALA KANGSAR",
    "MADRASAH IDRISIAH - PERAK - KUALA KANGSAR",
    "SEKOLAH MENENGAH ADDINIAH - PERAK - LARUT/MATANG/SELAMA",
    "MAAHAD AL MAARIF AL ISLAMIAH BATU 9 PERAK - PERAK - LARUT/MATANG/SELAMA",
    "SABK MAAHAD AL-KHAIR LIL BANAT - PERAK - LARUT/MATANG/SELAMA",
    "MAAHAD AL TARBIAH AL ISLAMIAH - PERAK - LARUT/MATANG/SELAMA",
    "SABK SYUBBANIAH - PERAK - LARUT/MATANG/SELAMA",
    "SMA TAN SRI GHAZALI JAWI - PERAK - HULU PERAK",
    "SMA MAAHAD AL AZIZ - PERAK - PERAK TENGAH",
    "MAAHAD EHYAK DINIAH ISLAMIAH - PERAK - PERAK TENGAH",
    "SMA AL-ULUM AL-SYAR'IYYAH - PERAK - BAGAN DATUK",
    "KOLEJ VOKASIONAL SERI MANJUNG - PERAK - MANJUNG",
    "KOLEJ VOKASIONAL LEBUH CATOR - PERAK - KINTA UTARA",
    "KOLEJ VOKASIONAL IPOH - PERAK - KINTA UTARA",
    "KOLEJ VOKASIONAL KERIAN - PERAK - KRIAN",
    "KOLEJ VOKASIONAL KUALA KANGSAR - PERAK - KUALA KANGSAR",
    "KOLEJ VOKASIONAL (PERTANIAN) TELUK INTAN - PERAK - HILIR PERAK",
    "KOLEJ VOKASIONAL GERIK - PERAK - HULU PERAK",
    "KOLEJ VOKASIONAL SERI ISKANDAR - PERAK - PERAK TENGAH",
    "KOLEJ VOKASIONAL SLIM RIVER - PERAK - MUALLIM",
    "KOLEJ VOKASIONAL TAIPING - PERAK - LARUT/MATANG/SELAMA",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA KERIAN - PERAK - KRIAN",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SULTAN AZLAN SHAH - PERAK - PERAK TENGAH",
    "SEKOLAH BERASRAMA PENUH INTEGRASI GOPENG - PERAK - KINTA SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SLIM RIVER - PERAK - MUALLIM",
    "SEKOLAH KEBANGSAAN KLANG - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN TELOK GADONG - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN PELABUHAN KELANG - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN TELOK MENEGON - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN BUKIT NAGA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN JALAN KEBUN - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN BATU BELAH - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN RANTAU PANJANG - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN SEMENTA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN KAPAR - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN BUKIT KAPAR - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN SUNGAI BINJAI - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN PULAU INDAH - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN TOK MUDA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN BUKIT KUDA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN KG RAJA UDA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN PANDAMARAN JAYA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN KG JOHAN SETIA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN SUNGAI SERDANG - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN KG PENDAMAR - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN TELOK GONG - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN TAMAN KLANG JAYA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN TAMAN SRI MUDA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TELOK PULAI - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN SUNGAI UDANG - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN PELABUHAN UTARA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN ABDUL SAMAT - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN MERU - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN KAMPUNG IDAMAN - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN TAMAN KLANG UTAMA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN KAMPUNG JAWA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN TAMAN SRI ANDALAS - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN TAMAN GEMBIRA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN BUKIT TINGGI - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN BUKIT KEMUNING 2 - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN MERU (2) - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN SUNGAI KAPAR INDAH - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN KOTA KEMUNING - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN KAMPUNG JAWA 2 - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN PULAU INDAH (2) - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN BUKIT RIMAU - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN BATU UNJUR - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN SUNGAI BUAYA - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN BANDAR - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN SUNGAI MANGGIS - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN OLAK LEMPIT - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN LABOHAN DAGANG - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN BKT CHANGGANG - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN JENJAROM - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN TELOK PANGLIMA GARANG - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN KEBUN BAHARU - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN SIJANGKANG - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN SG BUMBUN (A) - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN SUNGAI JUDAH - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN BUKIT TADOM - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN SERI CHEDING - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN (ASLI) BKT CHEDING - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN TG SEPAT - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN BATU LAUT - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN KAMPONG ENDAH - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN MORIB - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN KANCHONG DARAT - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN SG LANG - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN SERI LANANG - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN PERMATANG PASIR - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN SG KELAMBU - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN KAMPUNG MEDAN - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN JALAN TANJUNG - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN KANCHONG TENGAH - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN SIJANGKANG JAYA - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN RTB BUKIT CHANGGANG - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN JUGRA - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN BANDAR BANTING - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN (ASLI) BUKIT KEMANDOL - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN ASSAM JAWA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN BKT BADONG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN BKT KERAYONG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN DATO' MAHARAJA LELA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN TAMBAK JAWA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN JERAM - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN JERAM BT 20 - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN JAYA SETIA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN PASANGAN - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN RANTAU PANJANG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN IJOK - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN TANJUNG KERAMAT - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN BKT BELIMBING - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN KUALA SELANGOR - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN TANJONG KARANG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN SUNGAI BURONG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN SRI TIRAM - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN DATO' MANAN - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN SERI GAMBUT - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN SUNGAI SIREH - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN PARIT EMPAT - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN PARIT MAHANG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN JALAN RAJA MUSA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN SUNGAI TERAP - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN PARIT SERONG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN SAWAH SEMPADAN - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN BERJAYA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN BUKIT CERAKA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN BANDAR BARU - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN BESTARI JAYA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN PUNCAK ALAM - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN SAUJANA UTAMA IJOK - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN PUNCAK ALAM 2 - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN SERI PRISTANA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN PUNCAK ALAM 3 - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN DESA AMAN - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN SUNGAI SERAI - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN JALAN ENAM - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN AMPANG CAMPURAN - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN SERI SEKAMAT - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BATU SEMBILAN - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TUN ABD AZIZ MAJID - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN SG TEKALI - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN KUALA POMSON - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN DUSUN TUA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN LUBOK KELUBI - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN SUNGAI LUI - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN LEFTENAN ADNAN - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN SUNGAI RAMAL DALAM - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TAMAN NIRWANA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN KAJANG - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BERANANG - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN KG KUALA PAJAM - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BANGI - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN KG CHERAS BAHARU - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN LEMBAH JAYA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN ULU SEMENYIH - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN KG RINCING - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN RINCHING HILIR - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN AMPANG - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BANDAR BARU BANGI - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TAMAN TASIK - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN SUNGAI KANTAN - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TAMAN MUDA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TAMAN CUEPACS - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TAMAN KOSAS - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TAMING JAYA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN JALAN 3 - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN PANDAN INDAH - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BANDAR TUN HUSSEIN ONN - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN PANDAN JAYA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN SAUJANA IMPIAN - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN KAJANG UTAMA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TAMAN RAKAN - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN SRI JELOK - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN CHERAS INDAH - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BUKIT RAYA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TASEK PERMAI - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN DESA BAIDURI - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TAMAN JASMIN - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN JALAN EMPAT - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN KANTAN PERMAI - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN SEKSYEN 7 - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN PANDAN PERDANA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN SAUJANA IMPIAN (2) - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN CHERAS JAYA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BANDAR TASIK KESUMA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BANDAR RINCHING - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BANDAR TEKNOLOGI KAJANG - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN DUSUN NANDING - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BANDAR TUN HUSSEIN ONN 2 - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TAMAN BUKIT TERATAI - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN TAMAN PUTERI - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BANDAR SERI PUTRA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BANDAR BUKIT MAHKOTA - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BANDAR SUNWAY SEMENYIH - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN BATANG KALI - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN RASA - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN SG CHOH - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN SG SELISEK - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN ULU YAM BHARU - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN ULU YAM LAMA - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN HULU BERNAM - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN KUALA KUBU BHARU - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN KERLING - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN KALUMPANG - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN KG KUANTAN - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN GEDANGSA - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN SUNGAI BUAYA - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN KAMPUNG SOEHARTO - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN TUN ABDUL RAZAK - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN GESIR TENGAH - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN SG TENGI - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN SRI KELEDANG - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN SERENDAH - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN BUKIT BERUNTUNG - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN AMPANG PECAH - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN BANDAR BARU BATANG KALI - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN BANDAR SUNGAI BUAYA - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN SERI FAJAR - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN TAMAN BUNGA RAYA (1) - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN LEMBAH BERINGIN - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN BUKIT SENTOSA - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN ANTARA GAPI - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN BUKIT BERUNTUNG 2 - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN TAMAN BUNGA RAYA 2 - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN SERI MAKMUR - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SG HJ DORANI - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SIMPANG LIMA - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN PANCHANG PEDENA - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SG LIMAU - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN PARIT 4 - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN PARIT 6 - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SERI BAHAGIA - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN TOK KHALIFAH - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN BAGAN TERAP - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN PARIT 13 - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN LOBOK PUSING - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN DOKTOR ABDUL LATIFF - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN BATU 38 - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN KG BAHARU - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN TUN DOKTOR ISMAIL - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SEKENDI - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SIMPANG 4 BNO - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN PARIT BAHARU - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SUNGAI TENGAR - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SERI MAWAR - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN TEBOK JAWA - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SAPINTAS - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN KAMPONG BANTING - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN TANJONG JAWA - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN TELUK RU - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SERI CEMPAKA - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SG LEMAN - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN PARIT 9 - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN PASIR PANJANG - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SUNGAI NIBONG - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN BINJAI JAYA - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SELAYANG BARU (2) - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN (2) TAMAN SELAYANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN (2) TAMAN KERAMAT - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN HULU KELANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN KLANG GATE - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN GOMBAK SETIA - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN GOMBAK UTARA - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN SG TUA BAHARU - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN KEPONG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN RAWANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN KUANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN SG SERAI - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN SG PELONG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN KERAMAT (1) - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN SG BULUH - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN EHSAN - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN MELAWATI - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN (1) TAMAN SELAYANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN SERI GOMBAK - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN LEMBAH KERAMAT - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN SELAYANG JAYA - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN BANDAR BARU SELAYANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN SELASIH - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN PERMATA - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN MELAWATI 2 - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN SELAYANG UTAMA - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN SUNGAI KERTAS - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN SAMUDRA - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN SUNGAI PUSU - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN SETIA - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN DESA - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN BUKIT RAWANG JAYA - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN BUKIT INDAH - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN BUKIT RAHMAN PUTRA - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN SERI KUNDANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN SERI GOMBAK 2 - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN DESA 2 - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN BANDAR BARU RAWANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN BANDAR TASIK PUTERI - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN DESA AMANPURI - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN PRIMA SELAYANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN BANDAR BARU SELAYANG (2) - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TASIK PUTERI 2 - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN TUN TEJA - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN TAMAN DATO HARUN (2) - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN KELANA JAYA (2) - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN DAMANSARA JAYA (2) - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SS 19 SUBANG JAYA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 24 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TAMAN MEGAH - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN PETALING JAYA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SUBANG - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN PADANG JAWA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN PUCHONG - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SERDANG - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN BUKIT LANJAN - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN MERBAU SEMPAK - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN DAMANSARA UTAMA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SRI DAMAI - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN TAMAN DATO' HARUN (SATU) - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN KELANA JAYA (1) - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SUBANG JAYA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN RAJA MUDA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TAMAN SEA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN DAMANSARA JAYA (1) - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SEKSYEN ENAM - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SRI SUBANG JAYA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SRI SERDANG - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 17 SHAH ALAM - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TAMAN MEDAN - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SRI KELANA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SEKSYEN 19 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN HICOM - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 18 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 20 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN BANDAR BARU SUNGAI BULOH - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN SEAFIELD - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TAMAN UNIVERSITI - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN PUCHONG JAYA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TAMAN ALAM MEGAH - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TAMAN SRI MUDA 2 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN PERDANA JAYA SS19 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TUDM SUBANG - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN BANDAR SUNWAY - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN PUCHONG PERDANA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 7 KOTA DAMANSARA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SERI SELANGOR - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN BANDAR ANGGERIK - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN BUKIT KUCHAI - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TAMAN TUN DR ISMAIL JAYA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN BANDAR BARU SRI DAMANSARA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN ALAM MEGAH DUA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEAFIELD 3 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN BANDAR UTAMA DAMANSARA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS SELANGOR - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 9 SHAH ALAM - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN USJ 2 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN BANDAR UTAMA DAMANSARA 2 - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN PUCHONG UTAMA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 6 KOTA DAMANSARA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN USJ 20 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN USJ 12 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN BANDAR BARU SRI DAMANSARA 2 - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN PUCHONG UTAMA 2 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 1 BANDAR KINRARA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 13 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN PUCHONG INDAH - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN PUSAT BANDAR PUCHONG (1) - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN BANDAR SRI DAMANSARA 3 - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN BANDAR UTAMA DAMANSARA (4) - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN ALAM MEGAH (3) - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 2 BANDAR KINRARA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TROPICANA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN BUKIT JELUTONG - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN BANDAR SRI DAMANSARA 1 - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN DATO' ONN JAAFAR - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 27(1) - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SUBANG BESTARI - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN PUSAT BANDAR PUCHONG 2 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 9 KOTA DAMANSARA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN DAMANSARA DAMAI 1 - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SEKSYEN 7 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SEKSYEN 27 (2) - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN PUCHONG JAYA 2 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TAMAN BUKIT SUBANG - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN LEMBAH SUBANG - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SEKSYEN 11 KOTA DAMANSARA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN PUCHONG UTAMA 3 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TAMAN SUNGAI BESI INDAH - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN KAMPUNG LINDUNGAN - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN JALAN U3 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SETIA ALAM - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN TAMAN DESAMINIUM - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN DAMANSARA DAMAI 2 - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SAUJANA PUCHONG - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SUBANG BESTARI DUA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN PUNCAK PERDANA - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN PUTRA HEIGHTS 2 - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SUNGAI RAWANG - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN BKT BANGKONG - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN SG PELEK - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN SALAK - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN KG BAHARU LANJUT - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN KAMPONG BUSUT ASLI - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN JENDERAM - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN JENDERAM HILIR - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN DENGKIL - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN SG MERAB LUAR - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN DESA PUTRA - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN DATO' ABU BAKAR BAGINDA - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN PULAU MERANTI - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN SG MELUT (ASLI) - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN BKT TAMPOI (ASLI) - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN BANDAR BARU SALAK TINGGI - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN TAMAN SEROJA - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN TAMAN PANTAI SEPANG PUTRA - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN TAMAN PUTRA PERDANA - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN KOTA WARISAN - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN CYBERJAYA - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN KLIA - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN TAMAN PUTRA PERDANA 2 - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN DESA PINGGIRAN PUTRA - SELANGOR - SEPANG",
    "SEKOLAH KEBANGSAAN METHODIST ACS - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN (1) JALAN BATU TIGA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN CONVENT (1) - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN CONVENT (2) - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN LA SALLE - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN (1) JALAN MERU - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN (2) JALAN MERU - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN METHODIST - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN TENGKU BENDAHARA AZMAN (1) - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN TENGKU BENDAHARA AZMAN (2) - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN PEREMPUAN BUKIT KUDA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN (1) SIMPANG LIMA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN (2) SIMPANG LIMA - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN ST ANNE'S CONVENT - SELANGOR - KLANG",
    "SEKOLAH KEBANGSAAN METHODIST - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN SRI LANGAT - SELANGOR - KUALA LANGAT",
    "SEKOLAH KEBANGSAAN SULTAN ABDUL AZIZ - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN BATU 9 JALAN BOMBA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH KEBANGSAAN CONVENT - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN JALAN BUKIT SATU - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN (2) JALAN BUKIT - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN JALAN SEMENYIH '1' - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN JALAN SEMENYIH '2' - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN SEMENYIH - SELANGOR - HULU LANGAT",
    "SEKOLAH KEBANGSAAN KUALA KUBU BARU SATU - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN KUALA KUBU BHARU DUA - SELANGOR - HULU SELANGOR",
    "SEKOLAH KEBANGSAAN KHIR JOHARI - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SERI UTAMA - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN SERI SEKINCHAN - SELANGOR - SABAK BERNAM",
    "SEKOLAH KEBANGSAAN (1) GOMBAK - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN (2) GOMBAK - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN (1) KUALA AMPANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN (2) KUALA AMPANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN (1) SELAYANG BARU - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN SINARAN BUDI - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN BATU ARANG - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN ASSUNTA SATU - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN ASSUNTA (2) - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN (L) BUKIT BINTANG (1) - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN KAMPUNG TUNKU - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN LA SALLE PJ - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN METHODIST PJ - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN (1) PETALING JAYA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN (2) PETALING JAYA - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN JALAN SELANGOR (SATU) - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN (2) JALAN SELANGOR - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SATU SULTAN ALAM SHAH - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN (2) SULTAN ALAM SHAH - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SRI PETALING - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN SHAH ALAM - SELANGOR - PETALING PERDANA",
    "SEKOLAH KEBANGSAAN SUNGEI WAY - SELANGOR - PETALING UTAMA",
    "SEKOLAH KEBANGSAAN METHODIST - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEREMPUAN - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUEN MIN - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HIN HUA - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA LIEN - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KENG CHEE - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHE BENG - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KONG HOE - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LEE MIN - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PANDAMARAN 'A' - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PANDAMARAN 'B' - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PIN HWA (1) - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PUI YING - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN BIN - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SOO JIN - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TIONG HUA KOK BIN - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TSHING NIAN - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WU TECK - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YING WAH - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PIN HWA (2) - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAMAN RASHNA - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG HUA - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JENJAROM - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAH WAH - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PENG MING - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG MORIB - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TANJONG SEPAT - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TIONG NAM - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT FRASER - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AIK THEE - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BAGAN PASIR - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG WAH - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) IJOK - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI TEE - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LIAT CHOON - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MING TEE - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PUI CHI - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PUI TEH - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TANJONG KARANG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YIT KHWAN - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHIH - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BALAKONG - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BATU 9 CHERAS - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BATU 11 - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHOON HWA - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU SEMENYIH - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MING - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI CHUA - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TON FAH - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BATU 14 - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE MIN - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU HUA - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU AMPANG - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ON PONG - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CONNAUGHT (2) - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ON PONG 2 - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BANDAR SUNGAI LONG - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BANDAR KAJANG 2 - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BATANG KALI - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BKT TANGGA - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG CHEE - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KALUMPANG - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG GURNEY - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KERLING - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHING MING - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) RASA - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SERENDAH - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEONG HUA - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HAI PING - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LUM HUA - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MOON TECK - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHOOI TEE - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POAY CHNEH - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHOOI MIN - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE KUAN - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KIAN SIT - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHAP KHUAN - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHEOW BIN - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUANG - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUNDANG - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAN YUK - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SELAYANG BARU - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) DESA JAYA - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KOTA EMERALD - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEN MOH - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) DAMANSARA - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG MAN - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HAN MING - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SERDANG BARU (1) - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SERDANG BARU (2) - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUBANG - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI WAY - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI BULOH - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YAK CHEE - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHAI - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHYUN - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PUAY CHAI - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LICK HUNG - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PUAY CHAI 2 - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TUN TAN CHENG LOCK - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE WEN - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) DESA JAYA 2 - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SHIN CHENG (HARCROFT) - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA DAMANSARA - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TUN TAN SIEW SIN - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHENG CHEE - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT SERDANG SERI KEMBANGAN - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIO CHIAO - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) DENGKIL - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SEPANG - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MING - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TCHE MIN - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WAH LIAN - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) UNION - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATU AMPAT - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BRAFFERTON - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT RAJAH - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG EMERALD - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG HIGHLANDS - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JALAN ACOB - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN TEPI SUNGAI - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PERSIARAN RAJA MUDA MUSA - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN MERU - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) METHODIST - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SIMPANG LIMA - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG VALLAMBROSA - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN SENTOSA - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SG MANGGIS - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG GADONG - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG JUGRA - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JENJAROM - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SIMPANG MORIB - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI SEDU - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI BUAYA - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TELOK PANGLIMA GARANG - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PUSAT TELOK DATOK - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TUMBUK - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PULAU CAREY SELATAN - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PULAU CAREY BARAT - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PULAU CAREY TIMUR - SELANGOR - KUALA LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BESTARI JAYA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BRAUNSTON - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BUKIT CHERAKA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BKT IJOK - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG COALFIELDS - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) GHANDIJI - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG HOPEFUL - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KG BARU - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI TERAP - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KUALA SELANGOR - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG RAJA MUSA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BUKIT ROTAN BARU - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG RIVERSIDE - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SELANGOR RIVER - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG BULOH - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG RAMBAI - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TUAN MEE - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) VAGEESAR - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANGI - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG DOMINION - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAJANG - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG RINCHING - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SEMENYIH - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG WEST COUNTRY 'TIMUR' - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) AMPANG - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR MAHKOTA CHERAS - SELANGOR - HULU LANGAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATANG KALI - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG CHANGKAT ASA - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG ESCOT - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUALA KUBU BHARU - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KERLING - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KALUMPANG - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG LIMA BELAS - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MINYAK - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG NIGEL GARDNER - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG CHOH - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BUKIT BERUNTUNG - SELANGOR - HULU SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MARY - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG TINGGI - SELANGOR - KUALA SELANGOR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI BERNAM - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SABAK BERNAM - SELANGOR - SABAK BERNAM",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BATU CAVES - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BATU ARANG - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUANG - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BUKIT DARAH - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) RAWANG - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN MELAWATI - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) CASTLEFIELD - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG EFFINGHAM - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) F E S SERDANG - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PUCHONG - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KINRARA - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) RRI SUNGAI BULOH - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SARASWATHY - SELANGOR - GOMBAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) VIVEKANANDA - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) HICOM - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG EBOR - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG GLENMARIE - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MIDLANDS - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NORTH HUMMOCK - SELANGOR - KLANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG RASAK - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SEAPORT - SELANGOR - PETALING UTAMA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SG RENGGAM - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SEAFIELD - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN - SELANGOR - PETALING PERDANA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG AMPAR TENANG - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUTE - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR BARU SALAK TINGGI - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) DENGKIL - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN PERMATA - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SEPANG - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TELUK MERBAU - SELANGOR - SEPANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG WEST COUNTRY 'BARAT' - SELANGOR - SEPANG",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA MAHADI - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA LUMU - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU AMPUAN RAHIMAH - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU AMPUAN JEMAAH - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU IDRIS SHAH - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL SAMAD - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN PULAU INDAH - SELANGOR - KLANG",
    "KOLEJ TINGKATAN ENAM SRI ISTANA - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN RANTAU PANJANG - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN (P) KAPAR - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN PENDAMARAN JAYA - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN TELOK GADONG - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN SRI ANDALAS - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN KEBUN - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG JAWA - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN KLANG UTAMA - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN KOTA KEMUNING - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SULTAN SULEIMAN - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI KAPAR INDAH - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN SHAHBANDARAYA - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN BATU UNJUR - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT TINGGI KLANG - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT KAPAR - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN JENJAROM - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN JUGRA - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN TELOK DATOK - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BATU LAUT - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN TELOK PANGLIMA GARANG - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT CHANGGANG - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANTING - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN SIJANGKANG JAYA - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BANTING - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH SAINS BANTING - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR SAUJANA PUTRA - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN SERI TANJUNG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN DATO HARUN - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA MUDA MUSA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN JERAM - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN SG. BURONG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN TIRAM JAYA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN RANTAU PANJANG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN PERMATANG - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH SAINS KUALA SELANGOR - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN SAUJANA UTAMA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN PUNCAK ALAM - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA SELANGOR - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT KUCHING TENGAH - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN PUNCAK ALAM 3 - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN TASIK - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU BANGI - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL AZIZ SHAH - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN KHIR JOHARI - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN ABDUL JALIL - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN ENGKU HUSAIN - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN KOSAS - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SERAYA - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN PERIMBUN - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN TASEK PERMAI - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN REKO - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN TIGA - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN PANDAN JAYA - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN PANDAN INDAH - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN CHERAS PERDANA - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' AHMAD RAZALI - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT INDAH - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN CHERAS JAYA - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN SAUJANA IMPIAN - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU AMPANG - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SUNGAI LONG - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN EMPAT - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN KAJANG UTAMA - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN JASMIN 2 - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN DUSUN NANDING - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR TASIK KESUMA - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR DAMAI PERDANA - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR TUN HUSSEIN ONN 2 - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN PANDAN MEWAH - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR RINCHING - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR SERI PUTRA - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN DESA SERDANG - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RAMAL - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA KUBU BHARU - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPONG SOEHARTO - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI CHOH - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN KALUMPANG - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN GEDANGSA - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT SENTOSA - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH SAINS HULU SELANGOR - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN AMPANG PECAH - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU BATANG KALI - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR SUNGAI BUAYA - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN BUNGA RAYA (1) - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN SERENDAH - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT SENTOSA 2 - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN UNGKU AZIZ - SELANGOR - SABAK BERNAM",
    "SEKOLAH MENENGAH KEBANGSAAN MUNSHI ABDULLAH - SELANGOR - SABAK BERNAM",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI BESAR - SELANGOR - SABAK BERNAM",
    "SEKOLAH MENENGAH KEBANGSAAN BAGAN TERAP - SELANGOR - SABAK BERNAM",
    "SEKOLAH MENENGAH KEBANGSAAN DATO MUSTAFFA - SELANGOR - SABAK BERNAM",
    "SEKOLAH MENENGAH KEBANGSAAN GOMBAK SETIA - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SELAYANG - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN LEMBAH KERAMAT - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN EHSAN - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN RAWANG - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN KERAMAT - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI GOMBAK - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN HILLCREST - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN IDEAL HEIGHTS - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN MELAWATI - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI SELAYANG - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI KERTAS - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI KERAMAT - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN DARUL EHSAN - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PUSU - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR TASIK PUTERI - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT RAHMAN PUTRA - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI KUNDANG - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA 2 - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN SIERRAMAS - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN TUN PERAK - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN PUCHONG - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SRI PERMATA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN SALAHUDDIN ABDUL AZIZ SHAH - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SUBANG JAYA - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT GADING - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI SERDANG - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN DATO' HARUN - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN DAMANSARA JAYA - SELANGOR - PETALING UTAMA",
    "KOLEJ TINGKATAN ENAM PUCHONG - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 16 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN DAMANSARA UTAMA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH PENDIDIKAN KHAS VOKASIONAL SHAH ALAM - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN MEDAN - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SUBANG UTAMA - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN KELANA JAYA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN SEBELAS - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 18 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SEAFIELD - SELANGOR - PETALING PERDANA",
    "KOLEJ TINGKATAN ENAM SHAH ALAM - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SUNGAI BULOH - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 19 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN ALAM MEGAH - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SRI MUDA - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SRI UTAMA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SS 17 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR SUNWAY - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SERI INDAH - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN PUCHONG PERDANA - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 9 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SUBANG - SELANGOR - PETALING PERDANA",
    "KOLEJ TINGKATAN ENAM BANDAR UTAMA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN USJ 4 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN USJ 13 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN USJ 8 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN USJ 12 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN PUCHONG UTAMA (1) - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN TTDI JAYA - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 10 KOTA DAMANSARA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 1 BANDAR KINRARA - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN TROPICANA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR SRI DAMANSARA 2 - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN USJ 23 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR UTAMA DAMANSARA 3 - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR UTAMA DAMANSARA (4) - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 3 BANDAR KINRARA - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 4 BANDAR KINRARA - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 7 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN PUSAT BANDAR PUCHONG 1 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT JELUTONG - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN DAMANSARA DAMAI 1 - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN ALAM MEGAH 2 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 24 (2) - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 4 KOTA DAMANSARA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUCHONG JAYA (A) - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUCHONG JAYA (B) - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR UTAMA DAMANSARA (2) - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR SRI DAMANSARA 1 - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 27 - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN LEMBAH SUBANG - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SUBANG BESTARI - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN PUCHONG PERMAI - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESAMINIUM - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SETIA ALAM - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 8 KOTA DAMANSARA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SHAH ALAM - SELANGOR - PETALING PERDANA",
    "KOLEJ TINGKATAN ENAM PETALING JAYA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUNCAK JALIL - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RAWANG - SELANGOR - SEPANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI SEPANG - SELANGOR - SEPANG",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SALAK TINGGI - SELANGOR - SEPANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PUTERI - SELANGOR - SEPANG",
    "SEKOLAH MENENGAH KEBANGSAAN CYBERJAYA - SELANGOR - SEPANG",
    "SEKOLAH MENENGAH KEBANGSAAN PANTAI SEPANG PUTRA - SELANGOR - SEPANG",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRA PERDANA - SELANGOR - SEPANG",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' ABU BAKAR BAGINDA - SELANGOR - SEPANG",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST (ACS) - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI KLANG - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN KWANG HUA - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN LA SALLE - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN (P) METHODIST - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN (P) BUKIT KUDA - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN DATO HAMZAH - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN (P) RAJA ZARINA - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN MERU - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN PULAU KETAM - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST - SELANGOR - KUALA LANGAT",
    "KOLEJ VOKASIONAL SULTAN ABDUL SAMAD - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN TANJUNG SEPAT - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI MANGGIS - SELANGOR - KUALA LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN PULAU CAREY - SELANGOR - KUALA LANGAT",
    "KOLEJ TINGKATAN ENAM SULTAN ABDUL AZIZ - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN SULAIMAN SHAH - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN SERI DESA - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI KAJANG - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN YU HUA - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN BUKIT - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH KEBANGSAAN SYED MASHOR - SELANGOR - HULU SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' HJ KAMARUDDIN - SELANGOR - HULU SELANGOR",
    "KOLEJ TINGKATAN ENAM TUNKU ABDUL RAHMAN PUTRA - SELANGOR - SABAK BERNAM",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BEDENA - SELANGOR - SABAK BERNAM",
    "SEKOLAH MENENGAH KEBANGSAAN YOKE KUAN - SELANGOR - SABAK BERNAM",
    "SEKOLAH MENENGAH KEBANGSAAN KEPONG - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN HULU KELANG - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN SELAYANG BHARU - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI GARING - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN TUANKU ABDUL RAHMAN - SELANGOR - GOMBAK",
    "SEKOLAH MENENGAH KEBANGSAAN ASSUNTA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN (L) BUKIT BINTANG - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN KATHOLIK - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN LA SALLE PJ - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN (P) SRI AMAN - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SEA - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL SAMAD - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN (P) TMN PETALING - SELANGOR - PETALING UTAMA",
    "SEKOLAH MENENGAH KEBANGSAAN SERI KEMBANGAN - SELANGOR - PETALING PERDANA",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PELEK - SELANGOR - SEPANG",
    "SEKOLAH MENENGAH KEBANGSAAN DENGKIL - SELANGOR - SEPANG",
    "SAMT SULTAN HISAMUDDIN - SELANGOR - KLANG",
    "SAM HISAMUDDIN - SELANGOR - KLANG",
    "SAM NURUL IMAN - SELANGOR - KLANG",
    "SAM UNWANUS SAADAH - SELANGOR - KUALA LANGAT",
    "SAMT TENGKU AMPUAN RAHIMAH - SELANGOR - KUALA LANGAT",
    "SAM JERAM - SELANGOR - KUALA SELANGOR",
    "SAM TANJONG KARANG - SELANGOR - KUALA SELANGOR",
    "SAM HULU LANGAT - SELANGOR - HULU LANGAT",
    "SAM SUNGAI SELISEK - SELANGOR - HULU SELANGOR",
    "SAMT KUALA KUBU BHARU - SELANGOR - HULU SELANGOR",
    "SAM PARIT BARU - SELANGOR - SABAK BERNAM",
    "SAM BAGAN TERAP - SELANGOR - SABAK BERNAM",
    "SAM SUNGAI HAJI DORANI - SELANGOR - SABAK BERNAM",
    "SAM PASIR PANJANG - SELANGOR - SABAK BERNAM",
    "SAM MUHAMMADIAH - SELANGOR - SABAK BERNAM",
    "SAM TENGKU AMPUAN JEMAAH - SELANGOR - SABAK BERNAM",
    "SAMT SULTAN SALAHUDDIN ABD AZIZ SHAH - SELANGOR - SABAK BERNAM",
    "SAM RAWANG - SELANGOR - GOMBAK",
    "SAM PAYA JARAS - SELANGOR - GOMBAK",
    "SAM BESTARI - SELANGOR - PETALING PERDANA",
    "SAMT TENGKU AMPUAN JEMAAH - SELANGOR - PETALING PERDANA",
    "SAM SUNGAI MERAB LUAR - SELANGOR - SEPANG",
    "SAM BANDAR BARU SALAK TINGGI - SELANGOR - SEPANG",
    "KOLEJ VOKASIONAL KUALA SELANGOR - SELANGOR - KUALA SELANGOR",
    "KOLEJ VOKASIONAL GOMBAK - SELANGOR - GOMBAK",
    "KOLEJ VOKASIONAL SUNGAI BULOH - SELANGOR - GOMBAK",
    "KOLEJ VOKASIONAL SHAH ALAM - SELANGOR - PETALING PERDANA",
    "KOLEJ VOKASIONAL SEPANG - SELANGOR - SEPANG",
    "KOLEJ VOKASIONAL KAJANG - SELANGOR - HULU LANGAT",
    "KOLEJ VOKASIONAL KLANG - SELANGOR - KLANG",
    "KOLEJ ISLAM SULTAN ALAM SHAH - SELANGOR - KLANG",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA KUALA SELANGOR - SELANGOR - KUALA SELANGOR",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA MAAHAD HAMIDIAH - SELANGOR - HULU LANGAT",
    "SEKOLAH MENENGAH AGAMA PERSEKUTUAN KAJANG - SELANGOR - HULU LANGAT",
    "SEKOLAH BERASRAMA PENUH INTEGRASI SABAK BERNAM - SELANGOR - SABAK BERNAM",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN RAHAH - SELANGOR - SABAK BERNAM",
    "SEKOLAH BERASRAMA PENUH INTEGRASI RAWANG - SELANGOR - GOMBAK",
    "SEKOLAH BERASRAMA PENUH INTEGRASI GOMBAK - SELANGOR - GOMBAK",
    "SEKOLAH KEBANGSAAN FELDA LURAH BILUT - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN LEBU - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN SRI LAYANG - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN TUANKU FATIMAH - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN FELDA KG. SERTIK - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN SUNGAI DUA - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN KARAK - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN JAMBU RIAS - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN PELANGAI - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN SIMPANG PELANGAI - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN KG SHAFIE - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN JANDA BAIK - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN (FELDA) MEMPAGA 1 - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN (FELDA) KEMASUL - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN (FELDA) MEMPAGA 2 - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN LENTANG - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN BUKIT PIATU - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN (FELDA) CHEMOMOI - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN (FELDA) SG KEMAHAL - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN TANAH RATA - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH KEBANGSAAN TELANOK (JHEOA) - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH KEBANGSAAN LEMOI (JHEOA) - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH KEBANGSAAN MENSON (JHEOA) - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH KEBANGSAAN RPS TERISU (JHEOA) - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH KEBANGSAAN BRINCHANG - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH KEBANGSAAN RINGLET - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH KEBANGSAAN KAMPUNG RAJA - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH KEBANGSAAN BANDAR JERANTUT - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN KG BAHARU - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN DAMAK - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN PAWANG NONG - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN PEDAH - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN TANAH ROM - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN TEBING TINGGI - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN BUKIT DINDING - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN GINTONG - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 24 - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN DURIAN HIJAU - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN BUKIT NIKMAT - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN PULAU TAWAR - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN PERIAN - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN (FELDA) SG TEKAM - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN PULAU MANSOK - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN TEH - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN KUALA TEMBELING - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN PASIR DURIAN - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN CHEBONG - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN MERTING - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN KUALA TAHAN - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN KG PAGI - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN KUALA SAT - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN ULU TEMBELING - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN BUKIT MAT DALING - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN GUSAI - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN (FELDA) PADANG PIOL - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN LKTP TEKAM UTARA - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN (FELDA) KOTA GELANGGI 1 - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN (FELDA) SG RETANG - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN (FELDA) KOTA GELANGGI 2 - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN (LKTP) KOTA GELANGGI 3 - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN P. PENYELIDIKAN PERTANIAN TUN RAZAK - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN BATU BALAI - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN SG. KIOL (JHEOA) - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN TEMIN - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN LKTP JENGKA 25 - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN JERANTUT JAYA - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN INDERAPURA - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN (FELDA) LEPAR UTARA 8 - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN MELA - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN KUALA TELANG - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN BATU YON - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN KG KELEDEK - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN AUR GADING - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN MERAPOH - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN PENJOM - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN (FELDA) KECAU 1 - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN BENTA - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN BUDU - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN CHAT - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN KEMAHANG - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN TANJUNG BESAR - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN JERANSANG - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN PAGAR SASAK - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN TANJUNG GAHAI - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN KUALA MEDANG - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN WAN IBRAHIM - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN RELONG - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN BERCHANG - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN LUBOK KULIT - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN PADANG TENGKU - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN KECHOR TUI - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN GUA - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN KUALA LANAR - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN JERAM BUNGOR - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN JERAM LANDAK - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN KUALA KENONG - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN KERAMBIT - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN (FELDA) SG KOYAN - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN CHEGAR PERAH - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN (FELDA) CHEGAR PERAH - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN BATU 13 - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN KUALA KOYAN (JHEOA) - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN TITOM (JHEOA) - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN LENJANG(JHEOA) - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN RPS BETAU (JHEOA) - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN SENDERUT(JHEOA) - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN LANAI (JHEOA) - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN BATU 9 - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN TEMPOYANG - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN TELUK GUNUNG - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN LIMAU PURUT - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN TERUNTUM - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN GALING - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN BESERAH - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SUNGAI KARANG - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SUNGAI ULAR - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN BALOK - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN CHERATING - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN KAMPONG PADANG - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN PANCHING - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SUNGAI LEMBING - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN BATU TIGA - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN PERMATANG BADAK - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN FAKEH ABD. SAMAD - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN TANJUNG LUMPUR - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN KEMPADANG - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN KUALA PENOR - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN CHEROK PALOH - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN UBAI - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN JAYA GADING - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN NADAK - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN (LKTP) BUKIT GOH - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN (LKTP) BUKIT SAGU 1 - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN (FELDA) LEPAR HILIR 2 - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN (FELDA) LEPAR UTARA 1 - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN (FELDA) SUNGAI PANCHING UTARA - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN (FELDA) SG PANCHING SELATAN - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN JABOR - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN (FELDA) BT KUANTAN - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN (FELDA) SG PANCHING TIMUR - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN BUKIT SEKILAU - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN BUKIT KUIN - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN (LKTP) LEPAR HILIR 1 - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN TANAH PUTIH BARU - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN (FELDA) LEPAR HILIR 3 - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN GAMBANG - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KUANTAN - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN (FELDA) BUKIT SAGU 2 - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN CENDERAWASIH - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN INDERA MAHKOTA - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SEMAMBU - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SG. MAS (JHEOA) - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN BALOK BARU - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SUNGAI ISAP - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN WIRA - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN PANDAN - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN TOK SERA - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN BUKIT RANGIN - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN INDERA MAHKOTA UTAMA - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SERI MAHKOTA - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SUNGAI TALAM - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN AIR PUTIH - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN PELABUHAN - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN MAT KILAU - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN RTP BUKIT GOH - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN BALOK MAKMUR - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN BUKIT SETONGKOL - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SUNGAI BAGING - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SUNGAI SOI - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN TUNKU AZIZAH - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN BUNUT RENDANG - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN PELINDUNG - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN KAMPUNG BELUKAR - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SUNGAI ISAP MURNI - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN PADANG GARUDA - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN PAYA BUNGOR - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SERI MELATI - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN CHENGAL LEMPONG - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN BELIMBING - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN SERI TERENTANG - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN NENASI - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN TANJUNG BATU - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN SRI MAULANA - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN SINAR MUTIARA - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN INDERA SHAHBANDAR - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN (FELDA) CHINI TIMUR 1 - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN LANGGAR - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN PAHANG TUA - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN PULAU RUSA - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN GANCHONG - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN PULAU MANIS - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN PERMATANG KELEDANG - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN SERI BIRAM - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN PASIR PANJANG - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN TANJUNG AGAS - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN TANJUNG MEDANG - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN KG ACHEH - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN TEMAI - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN PADANG RUMBIA - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN SERAMBI - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN PALOH HINAI - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN PELAK - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN MAMBANG - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN KINCHIR - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN TANAH PUTEH - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN SERANDU - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN MERCHONG JAYA - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN SERI MAKMUR - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN LEPAR - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN (FELDA) CHINI 1 & 4 - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN KOTA PERDANA - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN (FELDA) CHINI 2 - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN (FELDA) CHINI 3 & 5 - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN RUNCHANG - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN PERAMU JAYA - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN (LKTP) CHINI TIMUR 2 - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN TASIK CINI PEKAN (JHEOA) - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN BANDAR 2 - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN PEKAN JAYA - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN PULAU SERAI - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN SIMPAI - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN BANDAR RAUB - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN ULU GALI - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN GALI - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN TANJUNG PUTUS - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN PAMAH KULAT - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN ULU DONG - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN MUHAMMAD JABAR - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN TEMAU - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN KUALA DONG - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN TANJUNG RAJA - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN CHENUA - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN SEGA - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN KUALA ATOK - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN ULU ATOK - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN SEMANTAN ULU - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN KUNDANG PATAH - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN BATU TALAM - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN TERSANG - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN ULU SUNGAI - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN (FELDA) LEMBAH KLAU - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN LKTP TERSANG SATU - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN (FELDA) KRAU 1 - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN (FELDA) TERSANG 3 - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN BUKIT FRASER - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN SATAK - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN RAUB INDAH - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN RAUB JAYA - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN BATU KAPOR - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN RANTAU PANJANG - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN LUBOK TERUA - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN BOLOK - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN KUALA KAUNG - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN PAYA ARA - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN SANGGANG - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN SANGGANG SEBERANG - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN BINTANG - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN TELOK SENTANG - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN PAYA TARAM - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN KERDAU - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN LIPAT KAJANG - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN KUALA TEKAL - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN PULAU PASIR MANDI - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN BATU SAWAR - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN JENDERAK - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN GUNONG SENYUM - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN KUALA KRAU - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN PAYA LUAS - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN KUALA MAI BHARU - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN BANGAU - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN BANDAR MENTAKAB - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN BANDAR TEMERLOH - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN TANJUNG LALANG - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN KAMPUNG TENGAH - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN BELENGU - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN LEBAK - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN PAYA PULAI - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN TELOK IRA - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN DESA BAKTI - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN SERI SONGSANG - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN (FELDA) JENDERAK UTARA - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN (FELDA) JENDERAK SELATAN - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN (FELDA) BUKIT DAMAR - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 23 - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN PENDERAS(JHEOA) - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN SERI TUALANG - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN SUNGAI GAU - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN TEMERLOH JAYA - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN MENTAKAB ( CHATIN ) - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN TANJUNG BATU - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN BUKIT CERMIN - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN LANCHANG - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN MENTAKAB JAYA - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN (FELDA) LAKUM - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN ROMPIN - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN PONTIAN - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN TANJONG GEMOK - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN PIANGGU - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN SARANG TIONG - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN LEBAN CHONDONG - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN KAMPONG AUR - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN BUKIT IBAM - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN TEKEK - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN PERANTAU DAMAI - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN (FELDA) SELENDANG 01 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN KEDAIK - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN BT SEROK - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN LADANG KOTA BAHAGIA - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN (FELDA) KERATONG 1 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN (LKTP) KERATONG 4 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN (FELDA) KERATONG 2 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN LKTP SELANCAR 1 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN (FELDA) KERATONG 6 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN (LKTP) KERATONG 3 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN (LKTP) KERATONG 8 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN SUNGAI PUTERI - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN (LKTP) SELANCAR 3 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN MELATI - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN BULUH NIPIS - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN DENAI - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN SG. MOK (JHEOA) - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN MUKUT - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN JUARA - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN BANDAR TUN RAZAK - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN (LKTP) KERATONG 7 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN KAMPONG KOLAM - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN MUADZAM JAYA - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN KERATONG 10 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN JANGLAU - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN (LKTP) SELENDANG 4 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN BUKIT RIDAN - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN CHENDERAWASIH - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN (FELDA) SELANCAR 2 - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN MUADZAM SHAH - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN MARAN - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN ULU LUIT - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (LKTP) KG NEW ZEALAND - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) BT TAJAU - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN LKTP ULU JEMPOL - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 1 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 2 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN KUALA SENTOL - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN SENGGORA - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN KUALA WAU - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN SERENGKAM - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN PAYA PASIR - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN PESAGI - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN CHENOR - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN KERTAU - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN TANJUNG PERANG - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 15 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN BUKIT SEGUMPAL - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN BUKIT LADA - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN BUKIT BOTA - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 21 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (LKTP) KG AWAH - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN SEKARA - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) SG NEREK - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 3 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 4 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN LKTP JENGKA 5 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 6 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (LKTP) JENGKA 7 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 8 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN BANDAR PUSAT - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 9 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 10 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 11 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (LKTP) JENGKA 12 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (LKTP) JENGKA 14 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 13 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 16 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 17 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 18 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 19 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN SENTOSA - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 20 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN JENGKA BATU 13 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN (FELDA) JENGKA 22 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN JENGKA PUSAT 2 - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN SERI KERAMAT - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN CHEDONG JAYA - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN DESA JAYA - PAHANG - MARAN",
    "SEKOLAH KEBANGSAAN BUKIT GEMURUH (JHEOA) - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN ISKANDAR - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN (LKTP) TEMBANGAU 1 - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN LKTP TEMBANGAU 3 - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN LKTP TEMBANGAU 6 - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN DURIAN TAWAR - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN KERAYONG - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN KUALA TRIANG - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN BATU PAPAN - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN KUALA BERA - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN BT ROK - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN (FELDA) TRIANG 3 - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN (FELDA) BT MENDI - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN (FELDA) BT PUCHONG - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN (FELDA) BT KEPAYANG - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN (FELDA) MAYAM - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN BOHOR BAHARU - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN (FELDA) PURUN - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN (FELDA) TEMENTI - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN (FELDA) SEBERTAK - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN (FELDA) RENTAM - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN KEMAYAN - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN (FELDA) KUMAI - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN LKTP TRIANG 2 - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN SRI BUNTAR - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN MENGKUANG - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN KERAYONG JAYA - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN BATU BOR - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN GUAI - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN CHARUK PUTING - PAHANG - BERA",
    "SEKOLAH KEBANGSAAN SULAIMAN - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN SG MARONG - PAHANG - BENTONG",
    "SEKOLAH KEBANGSAAN CONVENT - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH KEBANGSAAN JERANTUT - PAHANG - JERANTUT",
    "SEKOLAH KEBANGSAAN CLIFFORD - PAHANG - LIPIS",
    "SEKOLAH KEBANGSAAN SULTAN ABDULLAH - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN ST THOMAS - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN ASSUNTA CONVENT - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN PENGKALAN TENTERA - PAHANG - KUANTAN",
    "SEKOLAH KEBANGSAAN SULTAN AHMAD - PAHANG - PEKAN",
    "SEKOLAH KEBANGSAAN MAHMUD - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST - PAHANG - RAUB",
    "SEKOLAH KEBANGSAAN JALAN BAHAGIA - PAHANG - TEMERLOH",
    "SEKOLAH KEBANGSAAN ABU BAKAR - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI MUN PAGI - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI MUN REPAS - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI MUN CHAMANG - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KETARI - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PERTING - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI DUA - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KARAK - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TELEMONG - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MANCHIS - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT TINGGI - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LURAH BILUT - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CAMERON - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TANAH RATA - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BRINCHANG - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG RAJA - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BERTAM VALLEY - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TRINGKAP - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEA FARM - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SG JAN - PAHANG - JERANTUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BATU BALAI - PAHANG - JERANTUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JERANSONG - PAHANG - JERANTUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) DAMAK - PAHANG - JERANTUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MELA - PAHANG - LIPIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - PAHANG - LIPIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PADANG TENGKU - PAHANG - LIPIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JERKOH - PAHANG - LIPIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PENJOM - PAHANG - LIPIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BENTA - PAHANG - LIPIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SEMAMBU - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHING - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUANG HWA - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHAI - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) GAMBANG - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE SHIAN - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LEMBING - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PANCHING - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POOI MING - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAMAN TAS - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POAY CHAI - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHING 2 - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEKAN - PAHANG - PEKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEE WHA - PAHANG - PEKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE HWA - PAHANG - PEKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHING - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SEMPALIT - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI RUAN - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUH HWA - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SG LUI - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEROH - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TRAS - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SG CHETANG - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SANG LEE - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SG KLAU - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHEE CHEE - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MENTAKAB (1) - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MENTAKAB (2) - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LANCHANG - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YEOW CHENG LUAN - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KERDAU - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA KRAU - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI KAWANG - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ROMPIN - PAHANG - ROMPIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI MIN - PAHANG - MARAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MARAN - PAHANG - MARAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TRIANG (1) - PAHANG - BERA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TRIANG (2) - PAHANG - BERA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KERAYONG - PAHANG - BERA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MENGKUANG - PAHANG - BERA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MENGKARAK - PAHANG - BERA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEMASUL - PAHANG - BERA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MENTERI - PAHANG - BERA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BENTONG - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SRI TELEMONG - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG RENJOK - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KARAK - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LURAH BILUT - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TANAH RATA - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BOH (1) - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BOH (2) - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG PALAS - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BLUE VALLEY - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SHUM YIP LEONG - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) RINGLET - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUALA TERLA - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JERANTUT - PAHANG - JERANTUT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUALA LIPIS - PAHANG - LIPIS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUDU - PAHANG - LIPIS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BENTA - PAHANG - LIPIS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SELBORNE - PAHANG - LIPIS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR INDERA MAHKOTA - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JERAM - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KUALA REMAN - PAHANG - KUANTAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) RAUB - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHEROH - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BUKIT FRASER - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GALI - PAHANG - RAUB",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MENTAKAB - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG EDENSOR - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MENTAKAB - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG TEKAL - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BEE YONG - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SEMANTAN - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG YEOW CHENG LUAN - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG LANCHANG - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI KAWANG - PAHANG - TEMERLOH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MENTERI - PAHANG - BERA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KARMEN - PAHANG - BERA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KEMAYAN - PAHANG - BERA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SG PENJURING - PAHANG - BENTONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA TERLA - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JERIK - PAHANG - MARAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEMAYAN - PAHANG - BERA",
    "SEKOLAH RENDAH AGAMA ISLAHIAH - PAHANG - ROMPIN",
    "SRA ISLAM AT TAKWA - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN BENTONG - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN (LKTP) KG SERTIK - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) LURAH BILUT - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PELANGAI - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BENTONG - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT TINGGI - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA REPAS - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN KARAK SETIA - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) CHEMOMOI - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN SRI LAYANG - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG RAJA - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH MENENGAH KEBANGSAAN RINGLET - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH MENENGAH KEBANGSAAN PULAU TAWAR - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA TEMBELING - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG PIOL - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG SAUJANA - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN DAMAK - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN TEMIN - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN KOTA GELANGGI 2 - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN JUBLI PERAK SULTAN HAJI AHMAD SHAH - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN P. PENYELIDIKAN PERTANIAN TUN RAZAK - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RETANG - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN INDERAPURA - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN JENGKA 24 - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN ORANG KAYA HAJI - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG TENGKU - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN BENTA - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) SG KOYAN - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN MERAPOH - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN SERI LIPIS - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN KERAMBIT - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA LANAR - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN ABDUL RAHMAN TALIB - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN ALOR AKAR - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN BESERAH - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN PAYA BESAR - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT GOH - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PANCHING - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN TANJONG LUMPUR - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN TG PANGLIMA PERANG TG MUHAMMAD - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN LEPAR HILIR - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN LEPAR UTARA - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN PANDAN - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN TOK SERA - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN INDERA MAHKOTA 2 - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT RANGIN - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN GUDANG RASAU - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN PELABUHAN - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN SEMAMBU - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI DAMAI - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN SULTANAH HAJJAH KALSOM - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ISAP - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN ASTANA - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI SOI - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN CENDERAWASIH - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI BAGING - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN MAT KILAU - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN TELUK CHEMPEDAK - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN PELINDUNG - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN GAMBANG - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG GARUDA - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT SAGU - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ISAP MURNI - PAHANG - KUANTAN",
    "SEKOLAH SUKAN MALAYSIA PAHANG - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHENGAL LEMPONG - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' MAHMUD MAT - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU ABDULLAH - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN PALOH HINAI - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN (LKTP) CHINI - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN NENASI - PAHANG - PEKAN",
    "SEKOLAH SULTAN HAJI AHMAD SHAH - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHINI TIMUR - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PEKAN - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN INDERA SHAHBANDAR - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN PEKAN - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN UBAI - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN LEPAR - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN PERAMU JAYA - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHINI 2 - PAHANG - PEKAN",
    "SEKOLAH MENENGAH SAINS TENGKU ABDULLAH - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU KUDIN - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN DONG - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN LKTP TERSANG - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN SERI RAUB - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN GALI - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN SEGA - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN KERDAU - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN TEMERLOH - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA KRAU - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK BAHAMAN - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN SERI SEMANTAN - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN SERI TUALANG - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT DAMAR - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN TEMERLOH JAYA - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN PAYA PULAI - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN TELUK SENTANG - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT CERMIN - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN LANCHANG - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN SEBERANG TEMERLOH - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN ROMPIN - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN KERATONG - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN TANJUNG GEMOK - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN PERWIRA JAYA - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN PERANTAU DAMAI - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN TEKEK - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN CHANIS - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR TUN RAZAK - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI ROMPIN - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN MUADZAM JAYA - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN SELANCAR - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PUTERI - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN SELENDANG - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN PONTIAN JAYA - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN ROMPIN PERMAI - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN MUADZAM SHAH - PAHANG - ROMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN MARAN - PAHANG - MARAN",
    "SEKOLAH MENENGAH TENGKU AMPUAN AFZAN - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 2 - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN (LKTP) KAMPUNG AWAH - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 6 - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN JENGKA PUSAT - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 10 - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 18 - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 21 - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN JENGKA PUSAT 2 - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI JENGKA - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN MARAN 2 - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN (LKTP) JENGKA 16 - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN JENGKA 12 - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN SRI JAYA - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN DESA JAYA - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN CEDUNG JAYA - PAHANG - MARAN",
    "SEKOLAH MENENGAH KEBANGSAAN BERA - PAHANG - BERA",
    "SEKOLAH MENENGAH KEBANGSAAN MENGKARAK - PAHANG - BERA",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT MENDI - PAHANG - BERA",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) PURUN - PAHANG - BERA",
    "SEKOLAH MENENGAH KEBANGSAAN KEMAYAN - PAHANG - BERA",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR KERAYONG - PAHANG - BERA",
    "SEKOLAH MENENGAH KEBANGSAAN TEMBANGAU - PAHANG - BERA",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BERA - PAHANG - BERA",
    "SEKOLAH MENENGAH KEBANGSAAN TRIANG 3 - PAHANG - BERA",
    "SEKOLAH MENENGAH KEBANGSAAN TELEMONG - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN KETARI - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN KATHOLIK - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN KHAI MUN - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN AHMAD SHAH - PAHANG - CAMERON HIGHLANDS",
    "SEKOLAH MENENGAH KEBANGSAAN SETIA WANGSA - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABU BAKAR - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN ST THOMAS - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN (P) METHODIST - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN AIR PUTIH - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN TANAH PUTEH - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI MAHKOTA - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU AFZAN - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN MAHMUD - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN DATO SHAHBANDAR HUSSAIN - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN (P) METHODIST - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG CHING - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RUAN - PAHANG - RAUB",
    "SEKOLAH MENENGAH KEBANGSAAN ABU BAKAR - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BAHAGIA - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN HWA LIAN - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN TRIANG - PAHANG - BERA",
    "SEKOLAH MENENGAH KEBANGSAAN KARAK - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN SULAIMAN - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN JERANTUT - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN PANGLIMA GARANG ABDUL SAMAD - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN CLIFFORD - PAHANG - LIPIS",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI LEMBING - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH SAINS SULTAN HAJI AHMAD SHAH - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN AHMAD - PAHANG - PEKAN",
    "SEKOLAH MENENGAH KEBANGSAAN MENTAKAB - PAHANG - TEMERLOH",
    "SMA KOTA GELANGGI 3 - PAHANG - JERANTUT",
    "SEKOLAH AGAMA MAAHAD TAHFIZ WA TA'LIMIL AL-QURAN - PAHANG - JERANTUT",
    "SMA AL-ITTIHADIAH - PAHANG - LIPIS",
    "SEKOLAH MENENGAH AGAMA AL MAARIF - PAHANG - KUANTAN",
    "MAAHAD TAHFIZ DARUL ULUM AL-BASRIYAH - PAHANG - KUANTAN",
    "MADRASAH TARBIAH ISLAMIAH - PAHANG - TEMERLOH",
    "SMA AL-HASANAH - PAHANG - ROMPIN",
    "SMA AL MAHMUDI - PAHANG - MARAN",
    "KOLEJ VOKASIONAL TENGKU AMPUAN AFZAN - PAHANG - BENTONG",
    "KOLEJ VOKASIONAL SULTAN HAJI AHMAD SHAH AL-MUSTAIN BILLAH - PAHANG - LIPIS",
    "KOLEJ VOKASIONAL KUANTAN - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH PENDIDIKAN KHAS VOKASIONAL KUANTAN - PAHANG - KUANTAN",
    "KOLEJ VOKASIONAL PUTERI - PAHANG - TEMERLOH",
    "KOLEJ VOKASIONAL TEMERLOH - PAHANG - TEMERLOH",
    "KOLEJ VOKASIONAL SULTAN AHMAD SHAH - PAHANG - ROMPIN",
    "KOLEJ VOKASIONAL MUADZAM SHAH - PAHANG - ROMPIN",
    "KOLEJ VOKASIONAL (PERTANIAN) CHENOR - PAHANG - MARAN",
    "SEKOLAH MENENGAH TEKNIK KUANTAN - PAHANG - KUANTAN",
    "SEKOLAH MENENGAH AGAMA PERSEKUTUAN BENTONG - PAHANG - BENTONG",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA TENGKU AMPUAN HAJJAH AFZAN PAHANG - PAHANG - JERANTUT",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA KUALA LIPIS - PAHANG - LIPIS",
    "SEKOLAH BERASRAMA PENUH INTEGRASI KUANTAN - PAHANG - KUANTAN",
    "SEKOLAH BERASRAMA PENUH INTEGRASI TUN ABDUL RAZAK - PAHANG - PEKAN",
    "SEKOLAH BERASRAMA PENUH INTEGRASI TEMERLOH - PAHANG - TEMERLOH",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA PAHANG - PAHANG - ROMPIN",
    "SEKOLAH KEBANGSAAN ALOR BAKAT - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN BACHOK - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN BADAK - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN BAKONG - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN BEKELAM - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN SRI KEMUNTING - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN BERIS KUBOR BESAR - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN BERIS PANCHOR - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN BUKIT MARAK - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN CHANTUM - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN GUNONG - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN JELAWAT - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN KAMPONG CHAP - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN KANDIS - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN KETING - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN KOLAM - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN KUBANG GOLOK - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN KUBANG TELAGA - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN KUCHELONG - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN PAK BADOL - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN PA' PURA - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN PANTAI SENAK - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN PAUH SEMBILAN - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN PENGKALAN CHENGAL - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN PERUPOK - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN SENENG - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN SUNGAI DUA - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN TANJONG JENERA - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN TAWANG - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN TELAGA ARA - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN BERIS LALANG - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN TANGOK - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN JELAWAT 2 - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN ABDUL HADI - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KEM - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN BANGGOL SAMAN - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN BANGGU - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN BECHAH KERANJI - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN BETA HULU - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN BULOH POH - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN BUNUT PAYONG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN CHE DERIS - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN CHE LATIFF - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN DEMIT - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN DEWAN BETA - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN GONDANG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN GONG DERMIN - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KADOK - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KAMPONG CHENGAL - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KAMPONG KELING - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KAMPUNG SIREH - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KEDAI BULOH 1 - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KOTA JEMBAL - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KEDAI PIAH - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KEMUMIN - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KETEREH - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN LONG GAFAR - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KUBANG KERIAN (1) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KUBANG KIAT - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KOK LANAS - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KOTA - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN LANGGAR - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN LUNDANG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN MELOR - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN MENTERA - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN MENTUAN - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN MERBAU - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN MULONG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PADANG GARONG 2 - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN RAJA ABDULLAH - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PADANG KALA - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PADANG MOKAN - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PALOH PINTU GANG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PANGKAL KALONG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PANJI - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PARANG PUTTING - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PASIR HOR - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PAUH LIMA - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SRI BEMBAN - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PENAMBANG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PENDEK - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN DATU' HASHIM - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PENGKALAN KUBOR SALOR - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PERINGAT - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PEROL - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PULAU GAJAH - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PULAU KUNDOR - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SERI CHEMPAKA - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SABAK - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SALOR - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SEBERANG PASIR MAS - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SEMUT API - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SERIBONG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SERING - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN TAPANG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN TEGAYONG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN TIONG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN WAKAF STAN - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN CHICHA MENYABONG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KOR - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN DESA PAHLAWAN - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PENGKALAN CHEPA - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KUBANG KERIAN (2) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN PADANG GARONG 1 - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN DATO' HASHIM (2) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KEDAI BULOH (2) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN ISMAIL PETRA (2) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN MULONG (2) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SULTAN ISMAIL (4) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN KUBANG KERIAN 3 - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN TANJONG MAS - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN DEMIT (2) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN AYER MERAH - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN BANDAR - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN BELUKAR - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN BUKIT TIU - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN DEWAN BESAR - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN JAMBU LAWAR - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN KAMPONG PEK - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN KAMPUNG KERILLA - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN KELAWEH - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN LABOK - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN MACHANG (1) - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN MATA AYER - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN PAK ROMAN - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN PALOH RAWA - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN PANGKAL JENEREH - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN PANGKAL MAK WAN - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN PANGKAL MELERET - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN PANGKAL NERING - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN PULAI CHONDONG - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN SUNGAI BAGAN - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN TEMANGAN - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN PANGKAL GONG - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN HAMZAH (2) - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN MACHANG (2) - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN TOK BOK - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN BAKONG - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN BANGGOL CHICHA - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN BANGGOL PETAI - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN BAROH PIAL - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN BAYU LALANG - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN BECHAH DURIAN - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN BENDANG PAUH - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN BUKIT PERAH - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN BUNUT SUSU - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN CHABANG TIGA CHETOK - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN CHETOK - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN CHICHA TINGGI - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN GELANG MAS - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN GUAL PERIOK - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN GUAL SITOK - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN GUAL TO' DEH - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN KAMPONG BARU - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN KANGKONG - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN KEDAI TANJONG - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN KEDONDONG - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN KELAR - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN BECHAH KELUBI - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN KEPAS - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN KOK PAUH - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN KUBANG CHENOK - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN KUBANG KUAU - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN LATI - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN LEMAL - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN LUBOK SETOL - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN MEKASAR - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN MERANTI - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN PANGKAL KALA - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN DATO' ABDUL HAMID (1) - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN PENGGU - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN RANTAU PANJANG - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN TANJONG BUNGA - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN TANJONG CHENAK - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN OTHMAN TALIB (1) - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN SRI KIAMBANG - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN TOK SANGKUT - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN TO' UBAN - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN RAHMAT - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN DATO' ABDUL HAMID (2) - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN OTHMAN TALIB (2) - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN SULTAN IBRAHIM (3) - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN SULTAN IBRAHIM (4) - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN SRI RANTAU PANJANG (2) - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KELANTAN - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN GUAL TINGGI - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN BUKIT JARUM - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN BANGGOL PA' ESAH - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN BUKIT ABAL - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN BUKIT AWANG - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN BUKIT JAWA - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN BUKIT MERBAU - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN BUKIT TANAH - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN CHANGGAI - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN CHERANG RUKU - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN DANAN - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN DALAM RU - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN GAAL - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN GONG GARU - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN GONG MANAK - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN JELOR - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN JERAM - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN KAMPONG BERANGAN - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN KAMPONG KANDIS - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN KAMPONG RAJA - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN KAMPONG TASEK - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN KULIM - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN KAMPONG NARA - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN PADANG PAK AMAT - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN PANGGONG - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN PASIR PUTEH (L) - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN PASIR PUTEH (P) - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN SELIGI - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN KG SEPULAU - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN SUNGAI PETAI - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN TASEK PAUH - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN TELOSAN - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN TO'BALI - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN TUALANG TINGGI - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN WAKAF RAJA - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN BUKIT JAWA (2) - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN KAMIL (3) - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN SERI AMAN - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN LADANG KERILLA - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN ALOR PASIR - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN BATANG MERBAU - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN BATU GAJAH - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN BECHAH LAUT - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN BELIMBING - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN BENDANG NYIOR - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN BUKIT DURIAN - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN BUKIT GADING - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN BUKIT PANAU - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN NIK DAUD - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN GUAL JEDOK - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN IPOH - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN KAMPONG PANJANG - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN SRI KELEWEK - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN KUALA TIGA - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN KULIM - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN LALANG PEPUYU - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN LAWANG - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN SOKOR - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN TANAH MERAH (1) - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN TEBING TINGGI - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN TOK CHE DOL - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN ULU KUSIAL - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN KEMAHANG 1 - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN AYER CHANAL - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN PERALLA - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN SRI SURIA (2) - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN KEMAHANG 3 - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN KEMAHANG 2 - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN TANAH MERAH (2) - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN SRI SURIA (3) - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN BENDANG PA' YONG - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN BERANGAN (1) - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN BUNOHAN - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN CHABANG EMPAT - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN CHENDERONG BATU - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN GETING (1) - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN KAMPONG DELIMA - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN KAMPONG LAUT - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN KEBAKAT JAYA - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN KOK KELI - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN KOK PASIR - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN KUBANG BATANG - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN KUTAN - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN MORAK - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN PADANG MANDOL - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN PADANG POHON TANJUNG - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN PALEKBANG - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN PASIR PEKAN - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN PENGKALAN KUBOR (1) - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN PULAU BELURU - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN SUNGAI PINANG - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN SUNGAI TAPANG - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN TELUK JERING - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN KELABORAN - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN TUMPAT - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN WAKAF BHARU - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN SIMPANGAN - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN SRI NETING - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN PENGKALAN KUBOR (2) - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN BERANGAN (2) - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN SRI TUMPAT (2) - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN LADANG TAKU - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN SLOW TEMIANG - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN SUNGAI SOK - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN LELA JASA - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN BANGGOL GUCHIL - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN BATU JONG - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN BATU MENGKEBANG - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN LATA REK - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN MANEK URAI BARU - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN CHENULANG - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN DABONG - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN PASIR KELANG - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN KAMPONG BEDAL - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN KAMPONG KARANGAN - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN KEMUBU - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN KUALA GERIS - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN KUALA KRAI - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN KUALA NAL - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN LALOH - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN MANEK URAI - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN PAHI - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN PASIR GAJAH - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN PEMBERIAN - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN PERIA - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN TELKONG - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN TEMALIR - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN KAMPONG TENGAH - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN SUNGAI EMBAK - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN SUNGAI PAS - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN CHUCHOH PUTERI - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN BAHAGIA - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN SUNGAI MENGKUANG - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN SG SAM - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN MEMPELAM JELAWANG - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN SULTAN YAHYA PETRA (2) - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN BIAK - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN SERI MAHLIGAI - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN BANDAR KUALA KRAI - KELANTAN - KUALA KRAI",
    "SEKOLAH KEBANGSAAN BERTAM - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN GUA MUSANG - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN JERAM TEKOH - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN LIMAU KASTURI 1 - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN PALOH 1 & 2 - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN RENOK BARU - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN STAR - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN SUNGAI TERAH - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN CHALIL - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN JEREK - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN LEBIR - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN PALOH TIGA - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN PASIR TUMBUH - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN SRI CHIKU - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN PERASU - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN CIKU TIGA - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN ARING - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN KUALA SUNGAI - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN CHIKU 7 - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN LIMAU KASTURI 2 - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN SRI WANGI - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN SRI CHIKU (2) - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN TENGKU MUHAMMAD FAKHRY PETRA - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN TOHOI - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN PULAT - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN SRI PERMAI (JHEOA) - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN KUALA LAH - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN BIHAI - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN MERANTO - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN LEPAN JAYA - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN SRI WANGI (2) - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN PASIR LINGGI - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN AYER LANAS - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN BATU MELINTANG - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN BUKIT JERING - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN GEMANG - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN JELI (1) - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN KALAI - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN KUALA BALAH - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN KUBOR DATU - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN LEGEH - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN LUBOK BONGOR - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN PENDOK - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN SUNGAI LONG - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN JELI (2) - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN SUNGAI RUAL - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN PASIR DUSUN - KELANTAN - JELI",
    "SEKOLAH KEBANGSAAN KUALA BETIS - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN BALAR - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN HENDROP - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN POS BROOKE - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN BLAU - KELANTAN - GUA MUSANG",
    "SEKOLAH KEBANGSAAN SERI KEMUDI - KELANTAN - BACHOK",
    "SEKOLAH KEBANGSAAN ISLAH - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN ISMAIL PETRA (1) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN RAJA BAHAR - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SERI KETEREH - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SERI KOTA - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SRI MELOR - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN TENGKU INDERA PETRA - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN REDANG - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SULTAN ISMAIL (1) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SULTAN ISMAIL (2) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN ZAINAB (1) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN ZAINAB 2 - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN SULTAN ISMAIL (3) - KELANTAN - KOTA BHARU",
    "SEKOLAH KEBANGSAAN HAMZAH (1) - KELANTAN - MACHANG",
    "SEKOLAH KEBANGSAAN SRI RANTAU PANJANG (1) - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN SULTAN IBRAHIM (1) - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN SULTAN IBRAHIM (2) - KELANTAN - PASIR MAS",
    "SEKOLAH KEBANGSAAN KAMIL (1) - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN KAMIL (2) - KELANTAN - PASIR PUTIH",
    "SEKOLAH KEBANGSAAN SRI SURIA (1) - KELANTAN - TANAH MERAH",
    "SEKOLAH KEBANGSAAN SERI KAMPONG LAUT - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN TUMPAT (1) - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN SERI WAKAF BAHARU - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN SULTAN YAHYA PETRA(1) - KELANTAN - KUALA KRAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHENG - KELANTAN - KOTA BHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - KELANTAN - KOTA BHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEIR CHIH - KELANTAN - KOTA BHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - KELANTAN - MACHANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA - KELANTAN - MACHANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POEY SIT - KELANTAN - MACHANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAY BOON - KELANTAN - PASIR MAS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOK ENG - KELANTAN - PASIR MAS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POY HWA - KELANTAN - PASIR MAS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAI CHIH - KELANTAN - PASIR PUTIH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHENG - KELANTAN - TANAH MERAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK TZE - KELANTAN - TUMPAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHAI - KELANTAN - KUALA KRAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) GUA MUSANG - KELANTAN - GUA MUSANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG PULAI - KELANTAN - GUA MUSANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PASIR GAJAH - KELANTAN - KUALA KRAI",
    "SEKOLAH RENDAH AGAMA TENGKU AMALIN A'ISHAH PUTRI - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN BERIS PANCHOR - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN NIPAH - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN BADAK - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN LONG YUNUS - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN PUTERI SAADONG - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN JELAWAT - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN PAK BADOL - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN SRI GUNUNG - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN KANDIS - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' PERDANA - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN KUBANG TELAGA - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN TERATAK PULAI - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN KUBANG GOLOK - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN BERIS PANCHOR 2 - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN KEDAI BULOH - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN ISMAIL PETRA - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KADOK - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA SAKTI - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' AHMAD MAHER - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN LONG GAFAR - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KUBANG KERIAN - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN MULONG - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PANGKAL KALONG - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PUTERA - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PUTERI - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN SALOR - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PANJI - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN DEWAN BETA - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PINTU GENG - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KEMUMIN - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KUBANG KERIAN 2 - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KOK LANAS - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN TANJUNG MAS - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN ZAINAB 2 - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PENAMBANG - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN CHEPA 2 - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN SERING - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KG CHENGAL - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN TANJONG CHAT - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG KALA - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG ENGGANG - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN BADANG - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KUBANG KERIAN 3 - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN LONG GHAFAR 2 - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PANCHOR PERDANA - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PANGKAL MELERET - KELANTAN - MACHANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI INTAN - KELANTAN - MACHANG",
    "SEKOLAH MENENGAH KEBANGSAAN MACHANG - KELANTAN - MACHANG",
    "SEKOLAH MENENGAH KEBANGSAAN ABDUL SAMAD - KELANTAN - MACHANG",
    "SEKOLAH MENENGAH SAINS MACHANG - KELANTAN - MACHANG",
    "SEKOLAH MENENGAH KEBANGSAAN TEMANGAN - KELANTAN - MACHANG",
    "SEKOLAH MENENGAH KEBANGSAAN HAMZAH (2) - KELANTAN - MACHANG",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR MACHANG - KELANTAN - MACHANG",
    "SEKOLAH MENENGAH KEBANGSAAN CHETOK - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN BUNUT SUSU - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN KANGKONG - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU PANGLIMA RAJA - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN MERANTI - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN PASIR MAS - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN TENDONG - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN TO' UBAN - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN SRI KIAMBANG - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN IBRAHIM (2) - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN GUAL PERIOK - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN KEDONDONG - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN KUBANG BEMBAN - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN BAROH PIAL - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG DANGAR - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN TANJONG BUNGA - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT JAWA - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN TOK JANGGUT - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN (AGAMA) DATO' ISMAIL - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN GAAL - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN JERAM - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN SRI MAHARAJA - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN CHERANG RUKU - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG PAK AMAT - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH SAINS PASIR PUTEH - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN SERI AMAN - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN SELISING - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PETAI - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN IPOH - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH KEBANGSAAN TAN SRI MOHAMED YAACOB - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH KEBANGSAAN TANAH MERAH (1) - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH KEBANGSAAN LADANG KERILLA - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH KEBANGSAAN ALOR PASIR - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH KEBANGSAAN KEMAHANG - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH KEBANGSAAN TANAH MERAH (2) - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT BUNGA - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' MAHMUD PADUKA RAJA (2) - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH KEBANGSAAN BELIMBING - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH KEBANGSAAN CHABANG EMPAT - KELANTAN - TUMPAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATO BIJI WANGSA - KELANTAN - TUMPAT",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPONG LAUT - KELANTAN - TUMPAT",
    "SEKOLAH MENENGAH KEBANGSAAN MAHMUD MAHYIDIN - KELANTAN - TUMPAT",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PINANG - KELANTAN - TUMPAT",
    "SEKOLAH MENENGAH KEBANGSAAN WAKAF BHARU - KELANTAN - TUMPAT",
    "SEKOLAH MENENGAH KEBANGSAAN GETING - KELANTAN - TUMPAT",
    "SEKOLAH MENENGAH KEBANGSAAN BERANGAN - KELANTAN - TUMPAT",
    "SEKOLAH MENENGAH KEBANGSAAN KUTAN - KELANTAN - TUMPAT",
    "SEKOLAH MENENGAH KEBANGSAAN KEROH - KELANTAN - KUALA KRAI",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA KRAI - KELANTAN - KUALA KRAI",
    "SEKOLAH MENENGAH KEBANGSAAN MANEK URAI - KELANTAN - KUALA KRAI",
    "SEKOLAH MENENGAH KEBANGSAAN DABONG - KELANTAN - KUALA KRAI",
    "SEKOLAH MENENGAH KEBANGSAAN PAHI - KELANTAN - KUALA KRAI",
    "SEKOLAH MENENGAH KEBANGSAAN LALOH - KELANTAN - KUALA KRAI",
    "SEKOLAH MENENGAH KEBANGSAAN MENGKEBANG - KELANTAN - KUALA KRAI",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN YAHYA PETRA 2 - KELANTAN - KUALA KRAI",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR KUALA KRAI - KELANTAN - KUALA KRAI",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU INDRA PETRA - KELANTAN - GUA MUSANG",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR CHIKU - KELANTAN - GUA MUSANG",
    "SEKOLAH MENENGAH KEBANGSAAN PALOH - KELANTAN - GUA MUSANG",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ASAP - KELANTAN - GUA MUSANG",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU BENDAHARA - KELANTAN - GUA MUSANG",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU INDRA PETRA 2 - KELANTAN - GUA MUSANG",
    "SEKOLAH MENENGAH KEBANGSAAN CHIKU 2 - KELANTAN - GUA MUSANG",
    "SEKOLAH MENENGAH KEBANGSAAN SRI WANGI - KELANTAN - GUA MUSANG",
    "SEKOLAH MENENGAH SAINS GUA MUSANG - KELANTAN - GUA MUSANG",
    "SEKOLAH MENENGAH KEBANGSAAN PANGGUNG JAYA - KELANTAN - GUA MUSANG",
    "SEKOLAH MENENGAH KEBANGSAAN AYER LANAS - KELANTAN - JELI",
    "SEKOLAH MENENGAH KEBANGSAAN JELI - KELANTAN - JELI",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA BALAH - KELANTAN - JELI",
    "SEKOLAH MENENGAH KEBANGSAAN BATU MELINTANG - KELANTAN - JELI",
    "SEKOLAH MENENGAH KEBANGSAAN JELI 2 - KELANTAN - JELI",
    "SEKOLAH MENENGAH SAINS JELI - KELANTAN - JELI",
    "MAKTAB SULTAN ISMAIL - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG CHENG - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN ZAINAB (1) - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN BACHOK - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN KETEREH - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KOTA - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN MELOR - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN CHEPA - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KOTA BHARU - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ISMAIL - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH SAINS TENGKU MUHAMMAD FARIS PETRA - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN HAMZAH - KELANTAN - MACHANG",
    "SEKOLAH MENENGAH KEBANGSAAN RANTAU PANJANG - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN IBRAHIM (1) - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN KAMIL - KELANTAN - PASIR PUTIH",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' MAHMUD PADUKA RAJA (1) - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH KEBANGSAAN TUMPAT - KELANTAN - TUMPAT",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN YAHYA PETRA 1 - KELANTAN - KUALA KRAI",
    "SMU(A) DARUL ULUM ALMUHAMMADIAH (SABK) - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH UGAMA (ARAB) DARUL IMAN - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH AGAMA TENGKU AMALIN A'ISHAH PUTRI - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH UGAMA (ARAB) AL-YUNUSIAH - KELANTAN - KOTA BHARU",
    "SMU(A) AL-KAUTHAR - KELANTAN - KOTA BHARU",
    "SMU(A)AL-FALAH SIRAM - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH UGAMA (ARAB) AL-ULUM - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH UGAMA (ARAB) AL-BALAGHUL MUBIN - KELANTAN - PASIR MAS",
    "SMU(A) DINIAH - KELANTAN - PASIR MAS",
    "SMU(A)MARDZIAH JABO - KELANTAN - PASIR MAS",
    "SMU(A) NURUL ITTIFAK - KELANTAN - PASIR PUTIH",
    "SMU (A) TARBIAH ISLAMIAH - KELANTAN - PASIR PUTIH",
    "SMU(A) ITTIFAKIAH - KELANTAN - PASIR PUTIH",
    "SMU (ARAB) AL-FITRAH - KELANTAN - TANAH MERAH",
    "SMU (ARAB) DARUL ULUM - KELANTAN - TANAH MERAH",
    "SMU (ARAB) TARBIAH ISLAMIAH - KELANTAN - TANAH MERAH",
    "SMU (ARAB) ARABIAH - KELANTAN - TANAH MERAH",
    "SEKOLAH MENENGAH UGAMA (ARAB) MARDZIAH - KELANTAN - TUMPAT",
    "SMU (A) NURUL HUDA KAJANG SEBIDANG - KELANTAN - TUMPAT",
    "SMU(A) DARUS SALAM - KELANTAN - KUALA KRAI",
    "SMU (A) SAADATUL QURA - KELANTAN - KUALA KRAI",
    "KOLEJ VOKASIONAL BACHOK - KELANTAN - BACHOK",
    "KOLEJ VOKASIONAL PENGKALAN CHEPA - KELANTAN - KOTA BHARU",
    "KOLEJ VOKASIONAL KOTA BHARU - KELANTAN - KOTA BHARU",
    "KOLEJ VOKASIONAL PASIR MAS - KELANTAN - PASIR MAS",
    "KOLEJ VOKASIONAL PASIR PUTEH - KELANTAN - PASIR PUTIH",
    "KOLEJ VOKASIONAL TANAH MERAH - KELANTAN - TANAH MERAH",
    "KOLEJ VOKASIONAL KUALA KRAI - KELANTAN - KUALA KRAI",
    "SEKOLAH MENENGAH KEBANGSAAN (AGAMA) TOK BACHOK - KELANTAN - BACHOK",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA MELOR - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA NAIM LIL-BANAT - KELANTAN - KOTA BHARU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA WATANIAH - KELANTAN - MACHANG",
    "SEKOLAH MENENGAH KEBANGSAAN (A) LATI - KELANTAN - PASIR MAS",
    "SEKOLAH MENENGAH KEBANGSAAN (AGAMA) FALAHIAH - KELANTAN - TUMPAT",
    "SEKOLAH KEBANGSAAN LUBOK - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI MAAMOR - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI PENGKALAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI UTAMA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN BAGAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI SEKAWAN DESA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN GAMBUT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SG KAJANG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PESERAI - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI CHANTEK - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI MAIMON - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI LAKSANA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN KAMPUNG JAWA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI PDG SARI - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI COMEL - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI TANJONG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI JASA SEPAKAT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PENGHULU SALLEH - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI SEPARAP - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PARIT JU - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN BANDAR - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN TEMENGGONG IBRAHIM PENGGARAM - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN BUKIT SOGA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI BEROLEH - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI GADING - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PARIT LAPIS - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PARIT BILAL - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI TELOK - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN TENAGA SEPAKAT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI BENGKAL - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN BINDU - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN BUKIT RAHMAT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI BINJAI - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN TG SEMBERONG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI MENDAPAT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI NASIB BAIK - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI MANGGIS - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI IDAMAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI BUNUT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PINTAS PUDING - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PINTAS RAYA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI SABAK UNI - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SETIA JAYA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI AMAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN JELOTONG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI KEMAJUAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PARIT BINGAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI PANDAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI PULEH - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN MINYAK BEKU - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SG SULOH - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI BANANG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI MOLEK - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN KORIS - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SENGGARANG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN KG BAHRU - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PARIT KEMANG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI REJO SARI - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PARIT KADIR - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI TIMBUL - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SUNGAI TONGKANG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI SAMPURNA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI PERPAT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI LADANG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN RENGIT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI MERLONG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN MUTIARA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SRI BELAHAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PARIT SULONG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI MEDAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI PASIR - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN TENAGA SETIA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI DAYONG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI TIGA SERANGKAI - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI SEJATI - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI PEMATANG RENGAS - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN AIR HITAM - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI YONG PENG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN BUKIT KUARI - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI TENGAH - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI BANDAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN KOTA DALAM - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI PASIR PUTEH - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI PAYA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN LADANG YONG PENG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SEPAKAT JAYA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI BERTAM - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI BULAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS BATU PAHAT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI MULIA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SEJAGONG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN AIR PUTIH - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SETIA BUDI - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN TAMAN SERI KOTA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN BANANG JAYA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PARIT RAJA - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN AYER MOLEK - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN PENGKALAN RINTING - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TANJUNG PUTERI - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN (P) BANDAR TINGGI - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN MOHD KHIR JOHARI - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN ANGKATAN TENTERA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN MAJIDI BARU - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SENIBONG - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN KOPOK - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN CAHAYA BARU - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN MASAI - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN PASIR PUTEH - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN KONGKONG LAUT - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN PASIR GUDANG (1) - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TANJONG LANGSAT - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN SUNGAI TIRAM - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN ULU TIRAM - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN KG. MAJU JAYA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TEBRAU BAKAR BATU - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN (FELDA ) ULU TEBRAU - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN KANGKA TEBRAU - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN GELANG PATAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SEKUDAI BT 10 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SUNGAI MELAYU - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SIKIJANG - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN LADANG PENDAS - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN PENDAS LAUT - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN KG PASIR - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SRI SKUDAI - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SERI KENANGA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN PASIR GUDANG (2) - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN PASIR GUDANG 3 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TIRAM DUKU - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TANJONG ADANG - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN MORNI POK - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TG KUPANG - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN KEMPAS - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SUNGAI DANGA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN KAMPUNG PULAI - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SRI TEBRAU - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN KOMPLEKS UDA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SRI AMAR - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN NONG CHIK - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN PELANGI - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN TUN AMINAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SERI MELATI - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS JOHOR BAHRU - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN PERMAS JAYA 1 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN PERLING - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN BANDAR UDA 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN RINTING 1 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN JOHOR JAYA 1 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN SURIA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SERI PERLING 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN PERUMAHAN TAMPOI 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN UNIVERSITI 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN SELESA JAYA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN PERMAS JAYA 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN TUN AMINAH 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN JOHOR JAYA 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN DAMAI JAYA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN DAYA - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN UNIVERSITI 1 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN DESA CEMERLANG - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN RINTING 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN DESA JAYA - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN CEMPAKA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN PASIR GUDANG 4 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN SKUDAI BARU - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN SERI KOTA PUTERI - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN KOMPLEKS SULTAN ABU BAKAR - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN PERIGI ACHEH - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN MOLEK - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TMN JOHOR JAYA 3 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN PUTERI WANGSA - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN MEGAH RIA - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN PERMAS JAYA 3 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN MUTIARA RINI - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN BUKIT KEMPAS - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN ANGGERIK - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN UNIVERSITI 4 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN DAYA 3 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN JOHOR JAYA 5 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN UNIVERSITI 3 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN PERMAS JAYA 5 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN BUKIT MEWAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN BANDAR SERI ALAM - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN DAYA 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN PASIR PUTIH - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN SERI ALAM 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN CENDANA - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN SERI KOTA PUTERI 4 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN SUTERA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN PERMAS JAYA 4 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN NUSA PERINTIS - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN DESA SKUDAI - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN IMPIAN EMAS - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN RINTING 3 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN BUKIT INDAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN BUKIT MUTIARA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN KOTA MASAI - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN SKUDAI BARU 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TASEK UTARA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN PERLING 3 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN BUKIT TIRAM - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN MOUNT AUSTIN - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN TAMPOI UTAMA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN PELANGI INDAH - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TG PUTERI RESORT - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN MUTIARA RINI 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN NUSANTARA - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN KOTA MASAI 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN NUSA DAMAI - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN DAMANSARA ALIFF - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TAMAN CAHAYA MASAI - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN TAMAN BUKIT DAHLIA - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN MEDINI - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN KANGKAR PULAI - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN TAMAN SRI PULAI - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN KANGKAR PULAI 2 - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN SRI PULAI PERDANA - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN TAMAN SCIENTEX - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN KOTA MASAI 3 - JOHOR - PASIR GUDANG",
    "SEKOLAH KEBANGSAAN (FELDA) ULU PENGGELI - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SIMPANG RENGAM - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SERI MACAP - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN BUKIT PALOH - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN BUKIT KENANGAN - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SERI KENCANA - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN (L) BANDAR KLUANG - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN KAMPONG MELAYU - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN KAHANG - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN BANDAR RENGAM - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN (P) BANDAR KLUANG - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SERI LALANG - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SERI KG TENGAH - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SERI KG RENGAM - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN PEKAN LAYANG-LAYANG - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN KAMPONG CHAMEK - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN LADANG PAMOL - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN LADANG BUKIT BENUT - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN LADANG TUN DR ISMAIL - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN LADANG ULU REMIS - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SULTAN SIR IBRAHIM - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN BANDAR PALOH - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN LKTP AYER HITAM - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN KG MELAYU NIYOR - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN (FELDA) KAHANG TIMUR - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SUNGAI LINAU - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN KG DATO IBRAHIM MAJID - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN (FELDA) KAHANG BARAT - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN LADANG TEREH - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN LKTP BELITONG - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN (FELDA) LAYANG-LAYANG - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN LKTP ULU DENGAR - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SERI INTAN - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN DATO' ONN JAAFAR - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SERI SEDOHOK - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN PENGKALAN TEREH - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN DATO' SYED ZAIN ALSHAHAB - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN ABDUL RAHMAN YASSIN - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SERI MAJU JAYA - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN LADANG MUTIARA - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN BANDAR T6 - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN BUKIT TONGKAT - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN SERI TAMAN KLUANG BARAT - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN BANDAR - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN (FELDA) BUKIT WAHA - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN BUKIT LINTANG - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SEDILI BESAR - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN MAWAI - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN JOHOR KAMPONG - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN PANCHOR - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SEDILI KECIL - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SEMPANG - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN TELOK HUTAN LESONG - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SERI SETIA JAYA - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN NAM HENG - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SEMANGAR - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN TELOK SENGAT - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN PENGERANG - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SUNGAI KAPAL - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SUNGAI RENGIT - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN TELOK RAMUNIA - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN LEPAU - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN KG.BARU PASIR GOGOK - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN TANJUNG SURAT - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN TANJONG BUAI - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SUNGAI PAPAN - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN TUN DR SYED NASIR - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN BANDAR PENAWAR - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN (FELDA) AIR TAWAR 1 - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN DATUK ISMAIL SA'ADON - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN MUZAFFAR SYAH - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN (FELDA) AIR TAWAR 4 - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN (FELDA) AIR TAWAR 5 - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SERI AMAN - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN BUKIT MAHKOTA - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN (FELDA) SEMENCHU - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN LKTP SUNGAI MAS - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN BANDAR MAS - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SUNGAI TELOR - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN (FELDA) ADELA - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN LKTP KLEDANG - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN BANDAR EASTER - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN (FELDA) LINGGIU - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN (FELDA) LOK HENG - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN FELCRA SUNGAI ARA - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN GEMBUT - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN (FELDA) LOK HENG SELATAN - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN PUNGGAI - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN TUNJUK LAUT - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN LKTP TENGGAROH SELATAN - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN KAMPONG LAYAU - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN DATUK USMAN AWANG - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SRI PERANI - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN (FELDA) SENING - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN PETRI JAYA - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN TAMAN KOTA JAYA - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN TANJUNG PENGELIH - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN BANDAR PENAWAR 2 - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SERI SAUJANA - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN FELDA PENGELI TIMUR - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN FELDA SUNGAI SIBOL - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN SAYONG PINANG - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN BANDAR TENGGARA 2 - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN LKTP PASIR RAJA - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN LKTP SUNGAI SAYONG - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN LKTP BUKIT BESAR - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN LKTP BUKIT RAMUN - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN BANDAR TENGGARA 1 - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN BANDAR MERSING - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN LKTP NITAR 1 - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 2 - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN SERI PANTAI - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN SAWAH DATO' - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN TENGLU - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN AIR PAPAN - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN TERIANG - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN LEMBAGA ENDAU - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN TANJUNG RESANG - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN BANDAR ENDAU - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 4 - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 6 - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN PUSAT AIR TAWAR - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN TELOK LIPAT - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN PULAU AUR - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN PULAU PEMANGGIL - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN PULAU SIBU - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN PULAU TINGGI - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 1 - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN PENYABONG - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN (LKTP) NITAR 2 - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 5 - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 3 - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN MERSING KANAN - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN (FELDA) TENGGAROH 7 - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN TANAH ABANG - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN PETA - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN LABUNG - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN KEM ISKANDAR - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN PUNAN - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN BUKIT NANING - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN AYER HITAM BATU 18 - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN BANDAR MAHARANI - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN BAKRI BATU 2 - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN BAKRI BATU 5 - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN BUKIT KEPONG - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN DUMPAR - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN GOMBANG - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN JALAN YUSUF - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN JORAK - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN KAMPONG BAHRU - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN LKTP BATU 27 - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN KAMPONG RAJA - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN LENGA - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN LIANG BATU - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT KADZI - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT KEROMA - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT KEROMA DARAT - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT RAJA - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT PINANG SERIBU - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT SETONGKAT - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT BAKAR TENGAH - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT BAKAR DARAT - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT ZAIN - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT JAWA - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT SAMSU - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT PECHAH - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT NAWI - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT LATIFF - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PANCHOR - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PANJANG SARI - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PAYA REDAN - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PAYA PANJANG - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PEKAN PAGOH - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PEKAN BAHARU - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT BULAT - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN RENCHONG - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SIMPANG JERAM - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SERI JONG - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SERI BUKIT BATU - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PARIT NO. 5 - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SERI MENANTI - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SIMPANG 4 - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SUNGAI RAYA - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN ORANGKAYA ALI - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SG BALANG DARAT - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SEMASEH - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN TEMIANG - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN TANJONG TOHOR - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS MUAR - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SUNGAI ABONG - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN FELCRA BUKIT KEPONG - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN RIMBA TERJUN - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PARIT RAMBAI - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PARIT SULAIMAN - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN RAMBAH - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI BUNIAN - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SUNGAI BUNYI - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN TELOK KERANG - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN KAMPONG BAHARU - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SUNGAI BOH - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN BELOKOK - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERKAT - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PERMAS KELAPA - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI SINARAN CHOKOH - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SUNGAI DURIAN - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN JERAM BATU - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PENGKALAN RAJA - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN KAMPONG SAWAH - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PEKAN NANAS - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PENEROK - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN BANDAR PONTIAN - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PARIT KUDUS - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PARIT PATAH - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN TG AYER HITAM - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PARIT SELANGOR - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PARIT HJ ADNAN - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI BUKIT PANJANG - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PONTIAN BESAR - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN MELAYU RAYA - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN API API - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN KAYU ARA PASONG - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PARIT SAPRAN - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PULAI SEBATANG - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN KAMPONG JAWA - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN AYER BALOI - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI PULAI - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SANGLANG - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI SEKAWAN DESA - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI ANOM - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN JAYA MULIA - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN BENUT - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI SINARAN - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN BUGIS BENUT - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI SEMANGAT - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI JAYA - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI SETIA - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI BAHAGIA - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI KEMBAR - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI AL ULUM - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SERI SENANG HATI - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN PARIT MARKOM - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN BATU 24 - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN TEMENGGONG ABDUL RAHMAN - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN KAMPONG TENGAH - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN KAMPONG JAWA - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN LKTP MEDOI - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN DATO SERI MAHARAJA LELA - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN GELANG CHINCHIN - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN BULOH KASAP - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN KUALA PAYA - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN SPANG LOI - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN SEPINANG - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN BATU ANAM - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN KAMPONG AWAT - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN MENSUDUT PINDAH - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN TAMBANG - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN GEMEREH - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN JEMENTAH - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN LADANG WELCH - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN TEBING TINGGI - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN BUKIT TUNGGAL - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN PAYA JAKAS - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN POGOH - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN MELAYU RAYA - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN LABIS - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN PEKAN AIR PANAS - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN KAMPONG TENANG - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN SAWAH BAHRU - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN LKTP TENANG - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN SERI BALI - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN SERI BEKOK - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN (FELDA) CHEMPLAK - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN (FELDA) KEMELAH - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN MENSUDUT LAMA - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN BALAI BADANG - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN (FELDA) REDONG - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN LKTP PEMANIS 1 - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN TENANG STESEN - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN (FELDA) PEMANIS 2 - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN LKTP PALONG TIMUR - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN LKTP MAOKIL 1 - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN DESA TEMU JODOH - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN SEGAMAT KECIL - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN KAMPONG KUDUNG - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN KAMPUNG PAYA BESAR - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN LKTP PALONG TIMUR 2 - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN LKTP MAOKIL 2 - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN SEGAMAT BARU - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN KEMEDAK - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN BANDAR PUTRA - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN SINAR BAHAGIA - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN KULAI - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN AYER MANIS - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN LKTP BUKIT BATU - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN LKTP BUKIT PERMAI - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN SEDENAK - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN POLIS KEM - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN SENAI - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN FELDA INAS - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN MURNI JAYA - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN SRI GUNUNG PULAI - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN TAMAN KOTA KULAI - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN SENAI UTAMA - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN TAMAN MUHIBBAH - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN TAMAN KOTA KULAI 2 - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN TAMAN INDAHPURA 1 - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN TAMAN INDAHPURA 2 - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN BANDAR PUTRA - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN PUTRA UTAMA - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN TAMAN PULAI EMAS - JOHOR - KULAI",
    "SEKOLAH KEBANGSAAN SEROM 8 - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SAGIL KAMPONG - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SAWAH RING - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SERI MA'AMOR - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SEROM 3 - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SIMPANG 5 PEKAN - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SIMPANG 5 DARAT - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SEROM 4 - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SRI TANGKAK - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SEROM 6 - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN PENCHU - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SENGKANG - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SIALANG - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SERI JAYA - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN TANJONG GADING - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN TELOK RIMBA - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN ABDULLAH - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN LEDANG - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SEROM 5 - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN KESANG - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN BANDAR TANGKAK - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN BUKIT KANGKAR - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN BUKIT BANJAR - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN BUKIT GAMBIR - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN BELEMANG - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN BEKOH - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN BUKIT RAHMAT - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN RKT SRI LEDANG - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN PUTERI LEDANG - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN GERSEK - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN RAWANG - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN KESANG TASEK - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN KUNDANG ULU - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN PARIT BUNGA - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN PARIT KASAN - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN PANTAI LAYANG - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN PAYA MAS - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN PENGKALAN BESAR - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN DURIAN CHONDONG - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN SRI RENGIT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN LIMPOON 1 - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN SERI PUTERI - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN TENGKU MARIAM - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN CONVENT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN MONTFORT - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN PESERIAN - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN YONG PENG - JOHOR - BATU PAHAT",
    "SEKOLAH KEBANGSAAN TEMENGGONG ABDUL RAHMAN 1 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN TEMENGGONG ABDUL RAHMAN 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN LARKIN 1 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN LARKIN 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN ST. JOSEPH - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN NGEE HENG - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN (P) SULTAN IBRAHIM - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN (P) JLN. YAHYA AWAL - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN INFANT JESUS CONVENT - JOHOR - JOHOR BAHRU",
    "SEKOLAH KEBANGSAAN PALOH (2) - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN TUNKU MAHMOOD 1 - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN TUNKU MAHMOOD 2 - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN CANOSSIAN CONVENT - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN BATU 3 - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN JUBLI INTAN - JOHOR - KLUANG",
    "SEKOLAH KEBANGSAAN LAKSAMANA - JOHOR - KOTA TINGGI",
    "SEKOLAH KEBANGSAAN SRI MERSING - JOHOR - MERSING",
    "SEKOLAH KEBANGSAAN ISMAIL SATU - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN ISMAIL (2) - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN ST ANDREW - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SULTAN ABU BAKAR (1) - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SULTAN ABU BAKAR 2 - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN CONVENT - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SRI JAMIL - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN SRI BUKIT PASIR - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN KOTA RAJA - JOHOR - MUAR",
    "SEKOLAH KEBANGSAAN ANDEK MORI - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN TENGKU MAHMOOD ISKANDAR 1 - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN TENGKU MAHMOOD ISKANDAR 2 - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN SRI BENUT - JOHOR - PONTIAN",
    "SEKOLAH KEBANGSAAN BUKIT HAMPAR - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN DATUK WAN IDRIS - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN CANOSSIAN CONVENT - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN SRI LABIS - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN KULAI 1 - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AI CHUN 1 - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AI CHUN 2 - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BULOH - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG SIU 1 - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG SIU 2 - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHERN HWA BUKIT PASIR - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA SRI GADING - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA SEGENTING - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA SG.AYAM - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA SENGGARANG - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA PT KADIR - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA SRI MEDAN - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA RENGIT - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA KANGKAR SENANGGAR - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHENG - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HUA MIN - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA JIN - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA NAN - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KONG NAN - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KANGKAR BAHRU - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LAM LEE - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LI CHUN - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MALAYAN - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PANCHOR - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHUN - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PING MING - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HWA - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SULONG - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TONGKANG - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG CHENG - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YANI - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YEONG CHANG - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YONG PENG 1 - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YONG PENG 2 - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YORK CHAI - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) FOON YEW 1 - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) FOON YEW 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) FOON YEW 3 - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) FOON YEW 4 - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) FOON YEW 5 - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUO KUANG - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST JOSEPH - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAMPOI - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MING TERK - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PING MING - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PU SZE - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BAN FOO - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIEN CHI - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE TONG - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MASAI - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PANDAN - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TIRAM - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEMPAS BARU - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUO KUANG 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAM HENG BARU - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JOHOR JAYA - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHIH - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WOON HWA - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) THORBURN - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) THAI HONG - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAI TZE - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MING CHIH - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHING YEH - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PULAI - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LADANG GRISEK - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEAH FAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SENTRAL PALOH - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAN BEE - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAU MIN - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN CHIANG - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG ENG - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA I - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA 2 - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA 3 - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG. GAJAH - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAHANG - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LAYANG - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LIT TERK - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MACHAP - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PALOH - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PA YAI - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PING MING - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAYONG - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SOUTH MALAYA - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TUAN POON - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU MING - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SRI LALANG - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) GOGOK - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MAWAI - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NEW KOTA - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAN YA - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEAY MIN - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAI SIN - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU HWA - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOK POON - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAO CHING - JOHOR - MERSING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG HUBONG - JOHOR - MERSING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JEMALUANG - JOHOR - MERSING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAI CHEE - JOHOR - MERSING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AI HWA - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AIK MING - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BOON LIM - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI SIN - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN KWANG WAHYU - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN TERH - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 1 A - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 1 B - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 2A - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 2B - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA KETIGA - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA PRESBYTERIAN - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SUN - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HAU LIM - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LIMBONG - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA MING - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEPONG - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KIM KEE - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LENGA - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHAI - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI YANG - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PENGKALAN BUKIT - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PU NAN - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) RENCHONG - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAN CHAI - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SING HWA - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MING - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SOON CHENG - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SOON MONG - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WEE SIN - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YIH CHIAU - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOK ENG - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU ENG - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU JERN - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU MING - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BIN CHONG - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEOW MIN - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI CHIH - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI MANG - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIEN HWA - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) GAU SAN - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEN BOON - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LEE MING - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YORK - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YU 1 - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YU(2) - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YU 3 - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YU 4 - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YU 6 - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAN MAH - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHIAO - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHUN 1 - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHUN (2) - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIU WOON - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAH TONG - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU MING - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU MING 1 - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU MING 2 - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AI CHUN - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BEKOK - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA NAN - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHA'AH - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CENTRAL SITE - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAH KANG - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JEMENTAH 1 - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JABI - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JAGOH - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LI CHI - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KASAP - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KARAS - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LABIS - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SEG HWA - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT SIPUT - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG TENGAH - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TENANG - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAMBANG - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TUA OOH - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JEMENTAH (2) - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BATU - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BEMBAN - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAWIT - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SEDENAK - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SENGKANG - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KULAI 1 - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KULAI 2 - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SALENG - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SEELONG - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SENAI - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KULAI BESAR - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHENG - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BEKOH - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAN KUO - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI MING 1 - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG YU - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN JU - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KOK BENG - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI MING (2) - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAN HWA - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI ENG - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PUI NAN - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAN YU - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE CHUIN - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LIAN HWA - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEBUN BAHRU - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAY HIAN - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SRI GADING - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SERI PELANGI - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG YONG PENG - JOHOR - BATU PAHAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN YAHYA AWAL - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TEBRAU - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MOUNT AUSTIN - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PERMAS JAYA - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG PLENTONG - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MASAI - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PASIR GUDANG - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) DESA CEMERLANG - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ULU TIRAM - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MADOS - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG RINI - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) GELANG PATAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN TUN AMINAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KANGKAR PULAI - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT SERAMPANG - JOHOR - JOHOR BAHRU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR SERI ALAM - JOHOR - PASIR GUDANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LAYANG - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ULU REMIS - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TUN DR ISMAIL - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SEMBRONG - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN BUKIT RENGAM - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SIMPANG RENGAM - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SOUTHERN MALAY - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT BENUT - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LAMBAK - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ELAEIS - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN HAJI MANAN - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MENGKIBOL - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PAMOL - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KAHANG BATU 24 - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NIYOR - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) CEP.NIYOR KLUANG - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN SETESYEN PALOH - JOHOR - KLUANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG REM - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN TAJUL - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PASAK - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PELEPAH - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG. NAM HENG - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG TELUK SENGAT - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SUNGAI PAPAN - JOHOR - KOTA TINGGI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MERSING - JOHOR - MERSING",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN KHALIDI - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LANADRON - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BAN HENG - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TEMIANG RENCHONG - JOHOR - MUAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN PARIT IBRAHIM - JOHOR - PONTIAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BEKOK - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LABIS - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG VOULES - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SEGAMAT - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR SEGAMAT - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG MUAR - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG SENARUT - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BATU ANAM - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GOMALI - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG FORTROSE - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NAGAPPA - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) CANTUMAN CHAAH - JOHOR - SEGAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KULAI BESAR - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KELAN - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KULAI OIL PALM - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SEDENAK - JOHOR - KULAI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN SIALANG - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BEKOH - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG NORDANAL - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SAGIL - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TANAH MERAH - JOHOR - TANGKAK",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TANGKAH - JOHOR - TANGKAK",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS PRINCESS ELIZABETH - JOHOR - JOHOR BAHRU",
    "SEKOLAH RENDAH AGAMA BERSEPADU BATU PAHAT - JOHOR - BATU PAHAT",
    "SEKOLAH RENDAH AGAMA BERSEPADU JOHOR BAHRU - JOHOR - JOHOR BAHRU",
    "SEKOLAH RENDAH AGAMA BERSEPADU KLUANG - JOHOR - KLUANG",
    "SEKOLAH RENDAH AGAMA BERSEPADU KOTA TINGGI - JOHOR - KOTA TINGGI",
    "SEKOLAH RENDAH AGAMA BERSEPADU MERSING - JOHOR - MERSING",
    "SEKOLAH RENDAH AGAMA BERSEPADU MUAR - JOHOR - MUAR",
    "SEKOLAH RENDAH AGAMA BERSEPADU PONTIAN - JOHOR - PONTIAN",
    "SEKOLAH RENDAH AGAMA BERSEPADU SEGAMAT - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' BENTARA LUAR - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN TUN AMINAH - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU MAHMOOD ISKANDAR - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN SENGGARANG - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN SERI MEDAN - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATO SULAIMAN - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATO ONN - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN PERMATA JAYA - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN SERI GADING - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATIN ONN JAFFAR - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATO SETH - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN PENGHULU SAAT - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANANG JAYA - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH SAINS BATU PAHAT - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN SURIA PERDANA - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ISMAIL - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TASEK UTARA - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TUN SYED NASIR ISMAIL - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN SRI TEBRAU - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN SRI RAHMAT - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN SKUDAI - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN PASIR GUDANG - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) ULU TEBRAU - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TANJUNG ADANG - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN PELANGI - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN JOHOR JAYA (1) - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN TUN AMINAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN PASIR GUDANG 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU UDA - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN UNIVERSITI - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PERLING - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN JOHOR JAYA 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN PERMAS JAYA - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN DAYA - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN DAMAI JAYA - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAN SRI MOHAMED RAHMAT - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS SULTAN ABU BAKAR - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA JAYA - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' USMAN AWANG - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN MUTIARA RINI - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI KOTA PUTERI - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN UNIVERSITI 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SELESA JAYA - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR SERI ALAM - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN RINTING 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN DESA CEMERLANG - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN PASIR PUTIH - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN MOLEK - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN MOUNT AUSTIN - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI ALAM 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN PUTERI WANGSA - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI KOTA PUTERI 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN IMPIAN EMAS - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN MEGAH RIA - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN DAYA 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN PASIR GUDANG 3 - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN PERMAS JAYA 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA SKUDAI - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN KOTA MASAI - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TASEK UTARA 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA TEBRAU - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN PELANGI INDAH - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TUN FATIMAH HASHIM - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN PERMAS JAYA 3 - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SELESA JAYA 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN MUTIARA RINI 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN NUSAJAYA - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN BUKIT INDAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SUTERA - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TG PUTERI RESORT - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN MOHD KHALID - JOHOR - JOHOR BAHRU",
    "SEKOLAH SENI MALAYSIA JOHOR - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN KOTA MASAI 2 - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN NUSA DAMAI - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI TIRAM - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN DAMANSARA ALIFF - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR UDA UTAMA - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN MAJIDI BARU 2 - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN MEDINI - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PULAI PERDANA - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN KANGKAR PULAI - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SCIENTEX - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN LAYANG-LAYANG - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN JLN MENGKIBOL - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN TUN HUSSEIN ONN - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN KAHANG - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ABDUL JALIL - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) KAHANG TIMUR - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN LKTP BELITONG - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN KLUANG BARAT - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN SIMPANG RENGAM - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI INTAN - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR T6 - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN KOTA TINGGI - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI LALANG - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SERI KLUANG - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI SEMBRONG - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI KOTA PALOH - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' IBRAHIM MAJID - JOHOR - KLUANG",
    "SEKOLAH MENENGAH SAINS SEMBRONG - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PERDANA - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI MACHAP - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR EASTER - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN TUN HABAB - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR KOTA TINGGI - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN TAN SRI JAAFAR ALBAR - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN AIR TAWAR - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR MAS - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN ADELA - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN SEMENCHU - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN LOKMAN HAKIM - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN LINGGIU - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR PENAWAR - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH SAINS KOTA TINGGI - JOHOR - KOTA TINGGI",
    "SEKOLAH SUKAN TUNKU MAHKOTA ISMAIL - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN SERI AMAN - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN KOTA JAYA - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN TANJUNG PENGELIH - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SERI SAUJANA - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ALAUDDIN - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR TENGGARA - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR TENGGARA 2 - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PINANG - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN PENGERANG UTAMA - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) TENGGAROH - JOHOR - MERSING",
    "SEKOLAH MENENGAH KEBANGSAAN MERSING - JOHOR - MERSING",
    "SEKOLAH MENENGAH KEBANGSAAN NITAR - JOHOR - MERSING",
    "SEKOLAH MENENGAH KEBANGSAAN TAN SRI ABDUL KADIR - JOHOR - MERSING",
    "SEKOLAH MENENGAH KEBANGSAAN ANJUNG BATU - JOHOR - MERSING",
    "SEKOLAH MENENGAH KEBANGSAAN SERI MENANTI - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' SRI AMAR DI RAJA - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN TUN PERAK - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA MUDA - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT PASIR - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN PEKAN BARU MUAR - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN TUN DR ISMAIL (STUDI) - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT NANING - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR MAHARANI - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ABONG - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN FELCRA BUKIT KEPONG - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN SRI TANJUNG - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO` PENGGAWA BARAT - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN SRI KUKUP - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN AYER BALOI - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN PARIT BETAK - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' MOHD. YUNOS SULAIMAN - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN KAYU ARA PASONG - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAN SRI OSMAN MOHD SA'AT - JOHOR - PONTIAN",
    "KOLEJ TINGKATAN ENAM PONTIAN - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN BULUH KASAP - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' BENTARA DALAM - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN TENANG STESEN - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN BEKOK - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN PALONG TIMUR - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN LKTP PEMANIS - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN (LKTP) MAOKIL - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN GEMEREH - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN KAMARUL ARIFFIN - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN SERI JEMENTAH - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUTRA - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN SERI KENANGAN - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BALI - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN SENAI - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABDUL RAHMAN PUTRA - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN KELAPA SAWIT - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN KULAI BESAR - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN KOTA KULAI - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN PUTRI - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUTRA - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN INDAHPURA 1 - JOHOR - KULAI",
    "SEKOLAH MENENGAH PENDIDIKAN KHAS VOKASIONAL INDAHPURA - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT BATU - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUTRA 2 - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU MAHMUD ISKANDAR - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU TEMENGGONG AHMAD - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH KEBANGSAAN TUN MAMAT - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH SAINS MUAR - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH KEBANGSAAN PARIT BUNGA - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH KEBANGSAAN SAGIL TANGKAK - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI TANGKAK - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH KEBANGSAAN PESISIRAN PERDANA - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH KEBANGSAAN LEDANG - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT GAMBIR - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH KEBANGSAAN TUN SRI LANANG - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH KEBANGSAAN PEI HWA - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI BATU PAHAT - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN (P) TEMENGGONG IBRAHIM - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN MUNSHI SULAIMAN - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' SYED ESA - JOHOR - BATU PAHAT",
    "MAKTAB SULTAN ABU BAKAR - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN DATO JAAFAR - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN AMINUDDIN BAKI - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN SAINT JOSEPH - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN (P) SULTAN IBRAHIM - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN INFANT JESUS CONVENT - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN SULTANAH ENGKU TUN AMINAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH TUN FATIMAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI KLUANG - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU ARIS BENDAHARA - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN CANOSSIAN CONVENT - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN LAKSAMANA - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH KEBANGSAAN SRI MERSING - JOHOR - MERSING",
    "SEKOLAH MENENGAH KEBANGSAAN PAI CHEE - JOHOR - MERSING",
    "SEKOLAH TINGGI MUAR - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN ST ANDREW - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN SRI MUAR - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN (PEREMPUAN) SULTAN ABU BAKAR - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN SRI PERHENTIAN - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO` ALI HJ AHMAD - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI SEGAMAT - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN SEG HWA - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN CANOSSIAN CONVENT - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN LABIS - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN PADUKA TUAN - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN MUNSHI ABDULLAH - JOHOR - KULAI",
    "SEKOLAH MENENGAH KEBANGSAAN TUN SARDON - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN TUN ISMAIL - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN SEMERAH - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK MENTERI - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN YONG PENG - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU PUTRA - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH KEBANGSAAN GELANG PATAH - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN DATO PENGGAWA TIMUR - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN DATO ABDUL RAHMAN YASSIN - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN ULU TIRAM - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN DATO HJ HASSAN YUNOS - JOHOR - KLUANG",
    "SEKOLAH MENENGAH SAINS JOHOR - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN PALOH - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' ABD RAHMAN ANDAK - JOHOR - KLUANG",
    "SEKOLAH MENENGAH KEBANGSAAN UNGKU HUSIN - JOHOR - MERSING",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU MAHKOTA - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ALAUDDIN RIAYAT SHAH 1 - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN LENGA - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN TUN SULAIMAN NINAM SHAH - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN TELOK KERANG - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN PEKAN NENAS - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN BENUT - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH KEBANGSAAN MUNSHI IBRAHIM - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' AHMAD ARSHAD - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN JEMENTAH - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN CHAAH - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN IBRAHIM - JOHOR - KULAI",
    "SEKOLAH MENENGAH AGAMA PARIT RAJA - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH AGAMA JAWAHIR AL-ULUM - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH AGAMA PARIT SULONG - JOHOR - BATU PAHAT",
    "SEKOLAH MENENGAH AGAMA ATTARBIAH AL ISLAMIAH - JOHOR - BATU PAHAT",
    "MADRASAH ALATTAS ALARABIAH JOHOR - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH AGAMA AL QURAN WADDIN - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH AGAMA ARABIAH KLUANG - JOHOR - KLUANG",
    "SEKOLAH MENENGAH AGAMA KOTA TINGGI - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH AGAMA BANDAR TENGGARA - JOHOR - KULAI",
    "SEKOLAH MENENGAH AGAMA AL-KHAIRIAH AL-ISLAMIAH - JOHOR - MERSING",
    "SEKOLAH MENENGAH AGAMA AD-DINIAH - JOHOR - MUAR",
    "SEKOLAH MENENGAH AGAMA AT-TANWIRIAH - JOHOR - MUAR",
    "SEKOLAH MENENGAH AGAMA (ARAB) AN-NUR - JOHOR - PONTIAN",
    "SEKOLAH MAAHAD PONTIAN - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH AGAMA BUGISIAH - JOHOR - PONTIAN",
    "SEKOLAH MENENGAH AGAMA AL-KHAIRIAH SEGAMAT - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH AGAMA LUGHATIL ARABIAH - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH AGAMA LUGHATUL QURAN - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH AGAMA SHAMSUDDINIAH - JOHOR - TANGKAK",
    "SEKOLAH MENENGAH AGAMA DARUL ULUM - JOHOR - TANGKAK",
    "KOLEJ VOKASIONAL BATU PAHAT - JOHOR - BATU PAHAT",
    "KOLEJ VOKASIONAL TUN HUSSEIN ONN - JOHOR - BATU PAHAT",
    "KOLEJ VOKASIONAL TANJUNG PUTERI - JOHOR - JOHOR BAHRU",
    "KOLEJ VOKASIONAL (ERT) AZIZAH - JOHOR - JOHOR BAHRU",
    "KOLEJ VOKASIONAL PERDAGANGAN - JOHOR - JOHOR BAHRU",
    "KOLEJ VOKASIONAL PASIR GUDANG - JOHOR - PASIR GUDANG",
    "KOLEJ VOKASIONAL KLUANG - JOHOR - KLUANG",
    "KOLEJ VOKASIONAL KOTA TINGGI - JOHOR - KOTA TINGGI",
    "KOLEJ VOKASIONAL MUAR - JOHOR - MUAR",
    "KOLEJ VOKASIONAL PAGOH - JOHOR - MUAR",
    "KOLEJ VOKASIONAL TANJUNG PIAI - JOHOR - PONTIAN",
    "KOLEJ VOKASIONAL SEGAMAT - JOHOR - SEGAMAT",
    "SEKOLAH MENENGAH TEKNIK JOHOR BAHRU - JOHOR - JOHOR BAHRU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA JOHOR BAHRU - JOHOR - PASIR GUDANG",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA BANDAR PENAWAR - JOHOR - KOTA TINGGI",
    "SEKOLAH MENENGAH SAINS SULTAN ISKANDAR - JOHOR - MERSING",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA MAAHAD MUAR - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA PAGOH - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA HAJI MOHD YASSIN - JOHOR - MUAR",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SEGAMAT - JOHOR - SEGAMAT",
    "SEKOLAH KEBANGSAAN MOHD ARIFF ABDULLAH - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN TELOI KANAN - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN TEMBAK - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN BADANG - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN ASAM JAWA/CAROK KELIAN - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN SERI INAS - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN PENGHULU ABU BAKAR - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN BALING - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN DALAM WANG - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN TUNKU PUAN HABSAH - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN IBOI - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN SYED SHEH - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KAMPUNG SADEK - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KAMPUNG PADANG - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KAMPUNG SERA - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KUALA KUPANG - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KUALA PEGANG - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KUALA KUANG - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KUALA MERAH - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KUALA SAMAK - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN LANAI - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN PULAI - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN SIONG - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN TANJUNG PARI - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN TAWAR - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN TIAK - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN TITI GANTONG - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN RAMBONG PULAI - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN SERI BAYU - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KUALA KETIL - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN SERI JEMERLI - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KAMPUNG SELARONG - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN TELOK TEDURI - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN MALAU - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN PAYA BESAR - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN BINJUL - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KAMPUNG BANDAR - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN ULU TAWAR - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN ULU BAKAI - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN SEBERANG KETIL - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN BINJUL DALAM - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN KAMPONG KEDA - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN AYER PUTEH - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN BANDAR BAHARU - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SUNGAI SALLEH - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN LUBOK BUNTAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN PERMATANG KERAT TELUNJUK - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN RELAU - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SELAMA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN DATO' HAJI ZAINUDDIN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SUNGAI BATU - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SG KECHIL ILIR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SUNGAI KECHIL ULU - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SUNGAI TAKA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SG TENGAS - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN ALOR JANGGUS - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SERI AMAR DIRAJA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN GUNONG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN KOTA RENTANG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN WAN SULAIMAN SIDIQ - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN HJ ABDULLAH SADUN - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN DATO' SHAARI - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN LENGKUAS - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN MERGONG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN PERMATANG GUNUNG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SIMPANG TIGA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SUKA MENANTI - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SUNGAI BARU - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TITI GAJAH - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN KUBANG TEDUH - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN ALOR MELINTANG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN ALOR MENGKUDU - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TUNKU RAUDZAH - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN HUTAN KAMPONG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN KAMPONG GELAM - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN GERIGIS - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SRI TAMAN - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN KUALA KEDAH - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN LANGGAR - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN HAJI ABU BAKAR - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SEBERANG PERAK BARU - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SEBERANG PERAK - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN HAJI ISMAIL - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN JALAN PEGAWAI - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SG KOROK LAMA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN HJ IDRIS TAJAR - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN DATO' WAN MOHD SAMAN - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TELOK KECHAI - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN BULUH LIMA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN KANGKONG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN LAHAR BUDI - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN HJ HASSAN ITAM - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TEBENGAU - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN HAJI WAHAB - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN DARUL HIKMAH - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN KAMPONG JAWA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SRI MAHAWANGSA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN BUKIT PINANG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TAMAN UDA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS ALOR SETAR - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TAMAN RAKYAT - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SERI PANTAI - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SERI PERDANA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN PEREMBA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN JALAN DATUK KUMBAR - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN KEBUN PINANG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TAMAN AMAN - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TAMAN BERSATU - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN HAJI SALLEH MASRI - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TAMAN AWANA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN GURUN (PUSAT) - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN JENIANG (PUSAT) - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BATU HAMPAR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SERI WANGSA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SULTAN MUDZAFFAR SHAH - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN AMAN JAYA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BKT SELAMBAU - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BEDONG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN LADANG HARVARD - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN DATARAN MUDA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SRI JERAI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN PANTAI PRAI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN HJ. OMAR TAHIR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN PATANI PARA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SEMELING - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SUNGAI LALANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SUNGKAP PARA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SUNGAI TOK PAWANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TANJONG DAWAI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BUKIT KECHIL - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN DARUL AMAN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BUKIT MERIAM - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN HAJI SULAIMAN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SRI GEDONG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN KOTA KUALA MUDA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SERI KUALA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN KAMPUNG KUALA SIN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SERI PINANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN PINANG TUNGGAL - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN RANTAU PANJANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SIDAM KIRI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SIMPOR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SUNGAI PASIR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN KHIR JOHARI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SUNGAI PETANI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TIKAM BATU - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BATU EMPAT - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BERAPIT - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN KEM LAPANGAN TERBANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN PETANI JAYA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS SUNGAI PETANI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TUNKU ISMAIL - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TAMAN RIA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SUNGAI LAYAR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN KAMPONG PASIR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BANDAR BARU SUNGAI LALANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TAMAN SERI WANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TASEK APONG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BAKAR ARANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TAMAN INTAN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SUNGAI PASIR KECHIL - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TELUK WANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN AMBANGAN HEIGHTS - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BUKIT KIARA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BANDAR PUTERI JAYA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BANDAR LAGUNA MERBOK - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN AYER HITAM - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN HAJI WAN YAHYA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN BATU 8 CHANGLUN - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN DATO' SYED NAHAR - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN BINJAL - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN GUAR NAPAI - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN DATO' WAN KEMARA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN GELONG - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN JITRA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN KODIANG - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN DATO' SRI SYED AHMAD - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN HOSBA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN MEGAT DEWA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN MANGGOL BONGOR - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN MATANG PAKU - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN PADANG PERAHU - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN TUNKU LAKSAMANA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN PIDA TIGA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN HAKIM TEH - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN SANGLANG - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN SIPUTEH - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN TOK KEPAK - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN BANDAR BUKIT KAYU HITAM - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN (FELDA) LAKA SELATAN - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN ANAK-ANAK ANGKATAN TENTERA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN JERLUN - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN SERI BANAI - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN PENGHULU HJ DARUS - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN MALAU - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN TELOK MALEK - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN KEPALA BATAS - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN SERI MUDA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN JITRA 2 - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN BANDAR BARU DARULAMAN - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN PULAU CHAPA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN (FELDA) BKT TANGGA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN BUKIT TINGGI - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN BANDAR BARU SINTOK - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN PULAU NYIOR - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN JITRA 3 - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN PADANG PEKAN - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN PUTAT - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN PAYA KEMUNTING - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN DARULAMAN HEIGHTS - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN BUKIT SELARONG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN LDG BUKIT SIDIM - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN LADANG DUBLIN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN GUAR LOBAK - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN JUNJONG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN KELADI - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN KULIM - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SRI KULIM - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN LABU BESAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SRI LIMAU - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN LUNAS JAYA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN MERBAU PULAS - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN MAHANG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SUNGAI KARANGAN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN PERMATANG TOK DIK - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SUNGAI KOB - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SUNGAI SELUANG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SUNGAI ULAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SERI LINDUNGAN RAJA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN TERAP - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN GUNUNG BONGSU - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN PAGAR MUSIH - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN KULIM BANDAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN PERMATANG TIONG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN TAMAN SELASIH - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN TAMAN KENARI - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN TAMAN RIA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN JALAN PAYA BESAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN TAMAN HI-TECH - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN AIR MERAH - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN TAMAN JELUTONG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN TAMAN MUTIARA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN TAMAN MAHSURI - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN LUBUK CHEMPEDAK - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN BAYAS - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN EWA - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN KEDAWANG - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN SERI NEGERI - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN KUALA TERIANG - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN PADANG MATSIRAT - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN PULAU TUBA - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN SERI LAGENDA - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN TEMONYONG - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN ULU MELAKA - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN LANGKAWI - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN SLT BAGAN NYIOR - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN NYIOR CHABANG - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN KILIM - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN SG MENGHULU - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN BUKIT TEMBAGA - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN KUALA NERANG - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN KURONG HITAM - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN TOH PUAN SHARIFAH HANIFAH - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN NAKA - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN PANGLIMA AWANG - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN NAMI - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN PADANG SANAI - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN PADANG TERAP - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN TANDOP BESAR - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN DATIN FATIMAH - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN PEDU - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN KAMPONG BELUKAR - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN TUALAK - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN LUBUK MERBAU - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN PERIK - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN LAMDIN - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN KUBANG PALAS - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN SERI BAKTI - KEDAH - PADANG TERAP",
    "SEKOLAH KEBANGSAAN BATU LIMA - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN CHEPIR - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN GULAU - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN HUJONG BANDAR - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN JENERI - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN KG BETONG - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN HJ HUSSAIN - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN KG KOTA BUKIT - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN SERI AMPANG MUDA - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN CHEMARA - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN KALAI - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN PAYA TERENDAM - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN PADANG CHICHAK - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN SIK - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN BANDAR BARU BERIS JAYA - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN TELOI TUA - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN DANGLAU - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN SIK DALAM - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN KOTA AUR - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN BATU 8 - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN TELOI TIMUR - KEDAH - SIK",
    "SEKOLAH KEBANGSAAN GUAR CHEMPEDAK - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN PADANG LUMAT - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SUNGAI KERING - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SUNGAI LIMAU - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN HAJI KASSIM JASIN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN ULU SEDAKA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN DULANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN KAMPONG PAUH - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN KAMPONG SETAR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN LANGKASUKA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TOK MAT SALLEH - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TEROI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN YAN KECHIL - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN HJ NYAK GAM - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN BUKIT BESAR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN PERMATANG BULOH - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SUNGAI DEDAP - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SIMPANG TIGA SG DAUN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SUNGAI DAUN TENGAH - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SINGKIR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN HAJI HUSSIN DOL - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN KUBOR PANJANG - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN PADANG DURIAN - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN POKOK ASAM - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN PAYA RAWA - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN PENGHULU JUSOH - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN ALOR BESAR - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN BKT CHORAS - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN BKT RAYA DALAM - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN HAJI ABDUL RAHMAN - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN CHAROK KUDONG - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN GUAR KEPAYANG - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN KG RAMBAI - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN KG CHEGAR - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN PENDANG - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN KAMPONG BARU - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN SUNGAI TIANG - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN TANAH MERAH - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN AYER PUTEH - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN HJ MOHAMAD ARIFF - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN BUKIT JENUN - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN BUKIT JAMBUL - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN BUKIT GENTING - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN KUALA RIMAU - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN POKOK TAI - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN HAJI MAT DAHAN - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN SYED IBRAHIM - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN BENDANG RAJA - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN KAMPUNG PULAI TOKAI - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN PAYA MENGKUANG - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN HUJUNG KETON - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN PAYA MAK INSUN - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN TUNKU INTAN SAFINAZ - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN SUNGAI SIPUT - KEDAH - PENDANG",
    "SEKOLAH KEBANGSAAN WAN ABDUL SAMAD - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN KAMPONG BUKIT - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN KUALA LANJUT - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN BUKIT TAMPOI - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN JABI - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN NAWA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TELAGA MAS - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TELUK JAMAT - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TUALANG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN BUKIT PAYONG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN BUKIT HIJAU - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN KG PAYA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN KUBANG LERET - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN POKOK SENA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN BUKIT PAK KIAU - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN BATU PEKAKA - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN TUNKU PUTERA - KEDAH - BALING",
    "SEKOLAH KEBANGSAAN PERMATANG PASIR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN JALAN SELAMA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN SRI GUNONG - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN BOHOR - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN ISKANDAR - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN HJ MOHD SHARIFF - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN TUNKU ABDUL HALIM - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN ALOR SETAR - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SULTANAH ASMA - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN CONVENT - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN SERI AMAN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN FATHER BARRE'S CONVENT - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN IBRAHIM - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN ST THERESA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN SULTAN AHMAD TAJUDDIN - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN ST ANNE'S CONVENT - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN TUNKU ABDUL MALIK - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH KEBANGSAAN TUNKU ABDUL RAHMAN PUTRA - KEDAH - PADANG TERAP",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HWA - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG LALANG - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA KETIL - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PENG MIN - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SENG YOK - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YEOK CHEE - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POI CHEE - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEONG CHEN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG HWA SKI - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG HWA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POAY CHAI - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SERDANG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YEOK KHEONG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BOON HWA - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG YU - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) EIK CHOON - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEAT HWA (K) - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEAT HWA (H) - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEAT HWA (S) - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEE CHEE - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LONG SEONG - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LONG CHUAN - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MIN SIN - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAN KWANG - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PUMPONG - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PENG MIN - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI SHIH - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SOON JIAN - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SOON CHENG - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAI CHONG - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YIH MIN - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YIH CHOON - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHING CHONG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG CHENG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG HWA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) FUH SUN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HOON BONG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JENIANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MIN TERK - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MAH WAH - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN KUO MIN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PENG MIN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN KWANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN 'A' - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN 'B' - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HWA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAI TONG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEKAN LAMA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LIN KHAY - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - KEDAH - KUBANG PASU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG HWA - KEDAH - KUBANG PASU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE NAN - KEDAH - KUBANG PASU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA MIN - KEDAH - KUBANG PASU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LAM MIN - KEDAH - KUBANG PASU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI MIN - KEDAH - KUBANG PASU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YIT MIN - KEDAH - KUBANG PASU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUH MIN - KEDAH - KUBANG PASU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AIK CHEE - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIO MIN 'A' - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIO MIN 'B' - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG CHENG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HUA MIN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA MIN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI MIN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN TEONG MAHANG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SHANG CHENG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KELANG LAMA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - KEDAH - LANGKAWI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MIN NAM - KEDAH - LANGKAWI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KOU HUA - KEDAH - PADANG TERAP",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - KEDAH - SIK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AIK MIN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI ENG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK MIN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YANG KAO - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YORK KHOON - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BOON TEIK - KEDAH - PENDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JUNUN - KEDAH - PENDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YEANG CHENG - KEDAH - PENDANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TONG YUH - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BADENOCH - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BINJOL - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BUKIT SEMBILAN - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KATUMBA - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KUALA KETIL - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BATU PEKAKA - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KIM SENG - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG MALAKOFF - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KUPANG - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PELAM - KEDAH - BALING",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUNTAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) GANESAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BARATHY - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) THIRUVALLUVAR - KEDAH - KOTA SETAR",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BEDONG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) HARVARD BHG I - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) HARVARD 2 - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG HARVARD BHG 3 - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG BATU - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SUNGAI BONGKOK - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SUNGAI PUNTAR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI TOK PAWANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG TUPAH - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGKAP PARA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) ARUMUGAM PILLAI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KALAIMAGAL - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MAHAJOTHI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KUALA MUDA BHG HOME - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PATANI PARA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SCARBORO BHG 2 - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SOMASUNDRAM - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SARASWATHY - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI GETAH - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PALANISAMY KUMARAN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG LUBOK SEGINTAH - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KALAIVAANI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMAN KELADI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) CHANGLUN - KEDAH - KUBANG PASU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PAYA KAMUNTING - KEDAH - KUBANG PASU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) DARUL AMAN - KEDAH - KUBANG PASU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT SELARONG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG HENRIETTA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PADANG MEIHA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG VICTORIA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG WELLESLEY - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ANAK KULIM - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BAGAN SENA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT MERTAJAM - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT SIDIM - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KULIM - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI ULAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI DINGIN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG DUBLIN BHG 5 - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG DUBLIN BHG. 7 - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SOMME - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KO SARANGAPANY - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SUNGAI RAYA - KEDAH - LANGKAWI",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT JENUN - KEDAH - PENDANG",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JABI - KEDAH - KOTA SETAR",
    "SEKOLAH KEBANGSAAN MERBOK (PUSAT) - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TUAN SYED JAN AL-JAFFRI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN TUNKU BENDAHARA - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN TUNJANG - KEDAH - KUBANG PASU",
    "SEKOLAH KEBANGSAAN PENGHULU AHMAD - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN KELIBANG - KEDAH - LANGKAWI",
    "SEKOLAH KEBANGSAAN SERI DUSUN - KEDAH - SIK",
    "SEKOLAH RENDAH ISLAM NURUL HIDAYAH PENGHULU ELANG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH RENDAH ISLAM TARBIAH AL-AWLADIAH - KEDAH - PENDANG",
    "SEKOLAH RENDAH AKADEMI ISLAM HJ. ABDULLAH SUBOH - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH RENDAH ISLAM AL-IHSANIAH - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN BALING - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN SIONG - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN TELOI KANAN - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN TANJONG PUTERI - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN JERAI - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN PARIT PANJANG - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN SYED ABU BAKAR - KEDAH - BALING",
    "SEKOLAH KHAS BALING - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN BAKAI - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN BONGOR - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN SELAMA - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN LUBUK BUNTAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN SERDANG BARU - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN SULTANAH BAHIYAH - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' SYED OMAR - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN KEPALA BATAS - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN SEBERANG PERAK - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN LANGGAR - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN DATO'WAN MOHD SAMAN - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN KEAT HWA 2 - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN TAJAR - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN SYED MOHAMED AL-BUKHARY - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN MERGONG - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN SIMPANG KUALA - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN KUBANG ROTAN - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PANTAI - KEDAH - KOTA SETAR",
    "KOLEJ TINGKATAN ENAM SULTAN SALLEHUDDIN - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN SERI AMPANG - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN ALOR MERAH - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN KHIR JOHARI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU ISMAIL - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PASIR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN KOTA KUALA MUDA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT SELAMBAU - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR SUNGAI PETANI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN AMAN JAYA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN BATU 5 JALAN JENIANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI LAYAR - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN RIA JAYA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN LEBAI MAN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN PINANG TUNGGAL - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN BAKAR ARANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN TELUK BAYU - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI PASIR KECIL - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN RIA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR PUTERI JAYA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH PENDIDIKAN KHAS VOKASIONAL MERBOK - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BUKIT KAYU HITAM - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH SULTAN ABDUL HALIM - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN SANGLANG - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU SERI INDERA PUTERA - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN CHANGLUN - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN PERMATANG BONGLAI - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN MEGAT DEWA - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU ANUM TUNKU ABDUL RAHMAN - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN HOSBA - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN TANJUNG PAUH - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SINTOK - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU DARULAMAN - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN PAYA KEMUNTING - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH SAINS KUBANG PASU - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN DARUL AMAN HEIGHT 1 - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN JERLUN - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI KOB - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KULIM - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN DATO LELA PAHLAWAN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KELADI - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN LABU BESAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI KARANGAN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN LUNAS - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN JUNJUNG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SELASIH - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN KENARI - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN PAYA BESAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN AIR MERAH - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN HITECH - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN JELUTONG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN MUTIARA - KEDAH - KULIM/BANDAR BAHARU",
    "KOLEJ TINGKATAN ENAM KULIM - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN AYER HANGAT - KEDAH - LANGKAWI",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU PUTRA - KEDAH - LANGKAWI",
    "SEKOLAH MENENGAH KEBANGSAAN KEDAWANG - KEDAH - LANGKAWI",
    "SEKOLAH MENENGAH KEBANGSAAN LANGKAWI P.TUBA - KEDAH - LANGKAWI",
    "SEKOLAH MENENGAH KEBANGSAAN KELIBANG - KEDAH - LANGKAWI",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG TERAP - KEDAH - PADANG TERAP",
    "SEKOLAH MENENGAH KEBANGSAAN LUBOK MERBAU - KEDAH - PADANG TERAP",
    "SEKOLAH MENENGAH KEBANGSAAN NAKA - KEDAH - PADANG TERAP",
    "SEKOLAH MENENGAH KEBANGSAAN PEDU - KEDAH - PADANG TERAP",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA NERANG - KEDAH - PADANG TERAP",
    "SEKOLAH MENENGAH KEBANGSAAN JENERI - KEDAH - SIK",
    "SEKOLAH MENENGAH KEBANGSAAN SERI ENGGANG - KEDAH - SIK",
    "SEKOLAH MENENGAH KEBANGSAAN GULAU - KEDAH - SIK",
    "SEKOLAH MENENGAH KEBANGSAAN CHEPIR - KEDAH - SIK",
    "SEKOLAH MENENGAH KEBANGSAAN BATU LAPAN - KEDAH - SIK",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI LIMAU YAN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN BATU 17 - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN DULANG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN GUAR CHEMPEDAK 2 - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BADONG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN TOKAI - KEDAH - PENDANG",
    "SEKOLAH MENENGAH KEBANGSAAN KUBOR PANJANG - KEDAH - PENDANG",
    "SEKOLAH MENENGAH KEBANGSAAN AYER PUTEH DALAM - KEDAH - PENDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TANAH MERAH - KEDAH - PENDANG",
    "SEKOLAH MENENGAH KEBANGSAAN SYED IBRAHIM - KEDAH - PENDANG",
    "SEK MODEL KHAS BUKIT JENUN - KEDAH - PENDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU TEMENGGUNG - KEDAH - PENDANG",
    "SEKOLAH MENENGAH KEBANGSAAN TOBIAR - KEDAH - PENDANG",
    "SEKOLAH MENENGAH SAINS PENDANG - KEDAH - PENDANG",
    "SEKOLAH MENENGAH KEBANGSAAN JABI - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH SAINS POKOK SENA - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT PAYONG - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN POKOK SENA 2 - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU PUTERA - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN DARULAMAN - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN KEAT HWA 1 - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN SULTANAH ASMA - KEDAH - KOTA SETAR",
    "KOLEJ SULTAN ABDUL HAMID - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN ST MICHAEL - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABDUL RAHMAN - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU SOFIAH - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN DATO BIJAYA SETIA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT FATHER BARRE - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN IBRAHIM - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN ST THERESA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN SIN MIN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHIO MIN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU PANGLIMA BESAR - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN BADLISHAH - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN ST PATRICK - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN ST. ANNE'S CONVENT - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA KETIL - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA PEGANG - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN AHMAD TAJUDDIN - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN SERDANG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABDUL MALIK - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN ALOR JANGGUS - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN MUADZAM SHAH - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABD. AZIZ - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN TUN SHARIFAH RODZIAH - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU LAKSAMANA - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN SERI GUNONG - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN BEDONG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHE TOM 'SRT' - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN GURUN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN MERBOK - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN PEKULA JAYA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU SULONG - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH SAINS SULTAN MOHAMAD JIWA - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN JITRA - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI MAHAWANGSA - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU BENDAHARA - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN PULAU NYIOR - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN AYER HITAM - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN MAHANG - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG SERAI - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH KEBANGSAAN MAHSURI - KEDAH - LANGKAWI",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK SYED AHMAD - KEDAH - PADANG TERAP",
    "SEKOLAH MENENGAH KEBANGSAAN SIK - KEDAH - SIK",
    "SEKOLAH MENENGAH KEBANGSAAN GUAR CHEMPEDAK - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN YAN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH KEBANGSAAN PENDANG - KEDAH - PENDANG",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI TIANG - KEDAH - PENDANG",
    "SEKOLAH MENENGAH KEBANGSAAN POKOK SENA - KEDAH - KOTA SETAR",
    "MAAHAD DINI SULTAN ABDUL HALIM - KEDAH - SIK",
    "SMA ISLAHIAH - KEDAH - KULIM/BANDAR BAHARU",
    "MAKTAB MAHMUD BALING - KEDAH - BALING",
    "SEKOLAH MENENGAH AGAMA (ARAB) ANNAJAH - KEDAH - BALING",
    "MAKTAB MAHMUD BANDAR BAHARU - KEDAH - KULIM/BANDAR BAHARU",
    "SEKOLAH MENENGAH AGAMA DARRUSAADAH - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH AGAMA IHYA UL-ULUM AD-DINIAH - KEDAH - KOTA SETAR",
    "MAKTAB MAHMUD POKOK SENA - KEDAH - KOTA SETAR",
    "SMA SUNGAI PETANI - KEDAH - KUALA MUDA/YAN",
    "SMA PEKAN GURUN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH AKADEMI ISLAM DARUL AMAN - KEDAH - KUALA MUDA/YAN",
    "MAKTAB MAHMUD KUALA MUDA - KEDAH - KUALA MUDA/YAN",
    "MAKTAB MAHMUD MERBOK - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH AGAMA HIDAYAH ISLAMIAH - KEDAH - KUBANG PASU",
    "SMA NURUL ISLAM AYER HITAM - KEDAH - KUBANG PASU",
    "MAKTAB MAHMUD KULIM - KEDAH - KULIM/BANDAR BAHARU",
    "MAKTAB MAHMUD LANGKAWI - KEDAH - LANGKAWI",
    "SMA DARIL IKTISAM - KEDAH - PADANG TERAP",
    "SMA MA'AHAD TAHFIZ AL-ABIDIN - KEDAH - PENDANG",
    "SEKOLAH MENENGAH AGAMA AL-ISLAHIAH AL-ISLAMIAH - KEDAH - PADANG TERAP",
    "MAKTAB MAHMUD PADANG TERAP - KEDAH - PADANG TERAP",
    "MAKTAB MAHMUD SIK - KEDAH - SIK",
    "SEKOLAH MENENGAH AGAMA IRSYADIAH - KEDAH - SIK",
    "SMA SAADAH ALDINIAH AL ISLAMIAH - KEDAH - KUALA MUDA/YAN",
    "SMA FAUZI - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH MENENGAH AGAMA NAHDZAH - KEDAH - KUALA MUDA/YAN",
    "MA'AHAD AL IMAM AL SYAFIE - KEDAH - PENDANG",
    "MAKTAB MAHMUD PENDANG - KEDAH - PENDANG",
    "KOLEJ VOKASIONAL ALOR SETAR - KEDAH - KOTA SETAR",
    "KOLEJ VOKASIONAL SUNGAI PETANI 1 - KEDAH - KUALA MUDA/YAN",
    "KOLEJ VOKASIONAL SUNGAI PETANI 2 - KEDAH - KUALA MUDA/YAN",
    "KOLEJ VOKASIONAL KULIM - KEDAH - KULIM/BANDAR BAHARU",
    "KOLEJ VOKASIONAL PULAU LANGKAWI - KEDAH - LANGKAWI",
    "SEKOLAH MENENGAH TEKNIK ALOR SETAR - KEDAH - KOTA SETAR",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA BALING - KEDAH - BALING",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA KEDAH - KEDAH - KOTA SETAR",
    "SEKOLAH BERASRAMA PENUH INTEGRASI KUBANG PASU - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA JERLUN - KEDAH - KUBANG PASU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SIK - KEDAH - SIK",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA YAN - KEDAH - KUALA MUDA/YAN",
    "SEKOLAH KEBANGSAAN MASJID TANAH - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN TANJUNG BIDARA - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN OTHMAN SYAWAL - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN BUKIT BERINGIN - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN RAMUAN CHINA BESAR - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN RAMUAN CHINA KECHIL - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN AYER LIMAU - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN KUALA LINGGI - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN PENGKALAN BALAK - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN DURIAN DAUN 'K' - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN LUBOK REDAN - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN JERAM - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN AIR JERNIH - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN KAMPUNG TENGAH - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN SUNGAI TUANG - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN TELOK BEREMBANG - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN SUNGAI JERNIH - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN RANTAU PANJANG - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN PADANG SEBANG - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN AYER PA' ABAS - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN SIMPANG EMPAT - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN PULAU SEBANG - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN MELEKEK - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN GANUN - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN PARIT MELANA - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN SUNGAI PETAI - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN SUNGAI SIPUT - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN MELAKA PINDAH - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN PEGOH - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN BERISU - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN LENDU - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN BELIMBING DALAM - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN RUMBIA - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN SUNGAI BULOH - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN CHERANA PUTEH - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN TEBONG - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN KEMUNING - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN MENGGONG - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN HUTAN PERCHA - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN DURIAN TUNGGAL - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN KEM TERENDAK II - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN GANGSA - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN LESONG BATU - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN DEMANG TAHA - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN JASIN - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN KESANG TUA - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN SELANDAR - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN BUKIT SENGGEH - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN JUS - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN BATANG MELAKA - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN NYALAS - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN MASJID BARU - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN ASAHAN - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN CHABAU - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN CHENDERAH - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN CHOHONG - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN CHINCHIN - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN TERENTANG - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN SERI BEMBAN - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN TEHEL - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN KEMENDOR - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN PULAI - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN SERKAM - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN TEDONG - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN SEMPANG - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN MERLIMAU - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN SEBATU - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN SG RAMBAI - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN BATU GAJAH - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN PARIT PENGHULU - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN SERI MENDAPAT - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN SERKAM DARAT - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN PARIT GANTONG - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN (FELDA) BKT SENGGEH - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN SERI MACHAP - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN DATUK HJ BAGINDA - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN BUKIT TEMBAKAU - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN AYER MERBAU - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN BUKIT CHINA - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN UJONG PASIR - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN PADANG TEMU - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN SEMABOK - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN BENDAHARA SERI MAHARAJA - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN BUKIT LINTANG - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN PAYA DALAM - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN PAYA REDAN - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN TAMBAK PAYA - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN AYER MOLEK - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN DUYONG - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN DATO DEMANG HUSSIN - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN BUKIT BERUANG - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN BUKIT BARU - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN PERINGGIT - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN BATU BERENDAM - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN KUBU - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN BACHANG - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN LIMBONGAN - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN KELEBANG BESAR - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN BUKIT RAMBAI - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN LEREH - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN KAMPONG GELAM - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN TANGGA BATU - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN SUNGAI UDANG - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN PANTAI KUNDOR - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN CHENG - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN KERUBONG - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN PAYA RUMPUT - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN BERTAM HULU - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN ALAI - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN TELOK MAS - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN PERNU - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN KEM GERAKHAS - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS MELAKA - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN AYER KEROH - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN SERI DUYONG - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN TAMAN BUKIT RAMBAI - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN MALIM - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN BATU BERENDAM 2 - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN TANJUNG MINYAK - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN TUN SYED AHMAD SHAHABUDIN - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN KAMPUNG TUN RAZAK - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN TANJUNG MINYAK 2 - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN TAMAN MERDEKA - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN ALOR GAJAH 1 - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN DATO' NANING - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN DATUK TAMBICHIK KARIM - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN SRI LAKSAMANA - MELAKA - ALOR GAJAH",
    "SEKOLAH KEBANGSAAN AIR BARUK - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN SRI LANANG - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN MERLIMAU 1 - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN MERLIMAU DUA - MELAKA - JASIN",
    "SEKOLAH KEBANGSAAN BANDAR HILIR - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN SERI BANDAR - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN TENGKERA 1 - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN TENGKERA DUA - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN (P) DURIAN DAUN - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN JLN DATUK PALEMBANG - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN METHODIST ACS - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN (P) METHODIST 1 - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN (P) METHODIST 2 - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN ST FRANCIS - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN CONVENT OF THE INFANT JESUS 1 - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN CONVENT OF THE INFANT JESUS (2) - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN SACRED HEART - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAY CHEE - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ALOR GAJAH - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SANN YUH - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PENG MIN - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN WAH - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KIOW MIN - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MACHAP BARU - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MACHAP UMBOO - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TABOH NANING - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LENDU - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHIAK YEW - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MASJID TANAH - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOK SIN - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAYA MENGKUANG - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHABAU - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUANG HWA - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU HSIEN - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KIOW NAM - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAY MIN - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MERLIMAU - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAO CHEE - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JASIN LALANG - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAY CHIAO - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ON LOK - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAY YAP - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAY CHUIN - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PARIT KELILING - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PONDOK BATANG - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG BEKOH - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TOON HUA - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SHUH YEN - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAY HSIEN - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEMENDOR - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAY FONG 1 - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAY FONG 2 - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAY FONG 3 - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAY TECK - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PING MING - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOK BIN - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG KUO - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BACHANG - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KATHOLIK - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIANG LIN - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NOTRE DAME - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) EK TE - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YING CHYE - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU YING - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TING HWA - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WEN HUA - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEH SENG - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POH LAN - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LIH JEN - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUANG YAH - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAY HWA - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST MARY - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BUKIT BERUANG - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AYER KEROH - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TIANG DUA - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BERTAM ULU - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI UDANG - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MALIM - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU HWA - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) ALOR GAJAH - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) DURIAN TUNGGAL - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) RUMBIA - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG GADEK - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KEMUNING (H/D) - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KEMUNING KRU DIVISION - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PULAU SEBANG - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SG BARU (H/D) - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG TEBONG - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PEKAN TEBONG - MELAKA - ALOR GAJAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BATANG MELAKA - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JASIN - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BUKIT ASAHAN - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BUKIT KAJANG - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG DIAMOND JUBILEE - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG JASIN LALANG - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MERLIMAU - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SERKAM - MELAKA - JASIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MELAKA (KUBU) - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BUKIT LINTANG - MELAKA - MELAKA TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PAYA RUMPUT - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN KEM TERENDAK 1 - MELAKA - ALOR GAJAH",
    "SEKOLAH RENDAH ARAB (JAIM) AL-FALAH - MELAKA - ALOR GAJAH",
    "SEKOLAH RENDAH ARAB JAIM AL-FAIZIN - MELAKA - ALOR GAJAH",
    "SEKOLAH RENDAH ARAB JAIM SELANDAR - MELAKA - JASIN",
    "SEKOLAH RENDAH ARAB MERLIMAU PASIR - MELAKA - JASIN",
    "SEKOLAH RENDAH ARAB JAIM PERNU - MELAKA - MELAKA TENGAH",
    "SEKOLAH RENDAH ARAB BATANG TIGA TIMUR - MELAKA - MELAKA TENGAH",
    "SEKOLAH RENDAH ARAB JAIM TUN RAZAK - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN GHAFAR BABA - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' HAJI TALIB KARIM - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN RAHMAT - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN ADE PUTRA - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN NANING - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN LUBOK CHINA - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN HANG KASTURI - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI UDANG - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN DURIAN TUNGGAL - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN TEBONG - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN ISKANDAR SHAH - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' ABDUL RAHMAN YA'KUB - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN SRI MAHKOTA - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN SIMPANG BEKOH - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN SELANDAR - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI RAMBAI - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN NYALAS - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BEMBAN - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN TUN SYED ZAHIRUDDIN - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN TUN TIJAH - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN MUNSHI ABDULLAH - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TUN MUTAHIR - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SERI TANJUNG - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN AIR MOLEK - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN KLEBANG BESAR - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT BARU - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TUN HAJI ABD MALEK - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT KATIL - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN MALIM - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG TEMU - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TELOK MAS - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT RAMBAI - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN AYER KEROH - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG GELAM - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN PERNU - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN PAYA RUMPUT - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN KRUBONG - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PENGKALAN - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN PULAU SEBANG - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ALAUDDIN - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK BENDAHARA - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN DANG ANUM - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN TAN SRI HAJI ABDUL AZIZ TAPA - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI MELAKA - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN GAJAH BERANG - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI PEREMPUAN - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI ST DAVID - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST (ACS) MELAKA ( M ) - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN METHODIST - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN ST FRANCIS - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN INFANT JESUS CONVENT - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN CANOSSA CONVENT ( M ) - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN KATHOLIK - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN NOTRE DAME CONVENT - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN YOK BIN - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TUN TUAH - MELAKA - MELAKA TENGAH",
    "KOLEJ TINGKATAN ENAM TUN FATIMAH - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI CINA MELAKA - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SERI KOTA - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN MANSOR SHAH - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' DOL SAID - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH KEBANGSAAN KEM TERENDAK - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH SAINS MUZAFFAR SHAH - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH AGAMA AL-EHYA AL-KARIM - MELAKA - ALOR GAJAH",
    "SMA JAIM AL-ASYRAF - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH ARAB (JAIM) DARUL FALAH - MELAKA - ALOR GAJAH",
    "SEKOLAH MENENGAH ARAB ASSAIYIDAH KHADIJAH - MELAKA - JASIN",
    "SEKOLAH MENENGAH ARB.ASSYAKIRIN - MELAKA - JASIN",
    "SEKOLAH MENENGAH TAHFIZ AL QURAN (JAIM) - MELAKA - JASIN",
    "SEKOLAH MENENGAH AGAMA JAIM AL AHMADI - MELAKA - MELAKA TENGAH",
    "KOLEJ VOKASIONAL DATUK SERI MOHD. ZIN - MELAKA - ALOR GAJAH",
    "KOLEJ VOKASIONAL DATUK SERI ABU ZAHAR ISNIN - MELAKA - JASIN",
    "KOLEJ VOKASIONAL MELAKA TENGAH - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH TEKNIK BUKIT PIATU - MELAKA - MELAKA TENGAH",
    "SEKOLAH BERASRAMA PENUH INTEGRASI SELANDAR - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN PERAK - MELAKA - JASIN",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SULTAN MUHAMMAD - MELAKA - MELAKA TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SHARIFAH RODZIAH - MELAKA - MELAKA TENGAH",
    "SEKOLAH KEBANGSAAN AMAR PENGHULU - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN KUALA KLAWANG - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN TERIANG - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN SEPRI TENGAH - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN KAMPAI - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN PERADONG - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN GELANG TERUSAN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN PETASEH - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN DATUK UNDANG ABDULLAH - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN SUNGAI BULOH - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN DURIAN TIPUS - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN PUTRA - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN PASOH SATU - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) PASOH 4 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN ULU BENDOL - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN YAMTUAN HITAM - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN YAMTUAN LENGGANG - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN AMPANG TINGGI - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN KAMPONG PARIT - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN TALANG - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN YAMTUAN ANTAH GUNUNG PASIR - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN SERI PILAH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN TANAH MELINTANG - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN KUALA PILAH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN SENALING - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN SUNGAI DUA - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN ULU SUNGAI DUA - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN DANGI - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN DATO' INAS - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN INAS - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN UNDANG JOHOL - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN DATO' UNDANG KAMAT - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN NURI - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN SENIBAI - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN KEPIS - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN KUALA KEPIS - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN PELANGAI - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN PUSAT JUASSEH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN JUASEH TENGAH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN REMBANG PANAS - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN PASIR AMBOR - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN TENGKEK - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN TAPAK - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN PADANG LEBAR - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN (FELDA) KEPIS - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN BUKIT ROKAN BARAT - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN KAMPONG GELAM - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN SI-RUSA - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN SUNGALA - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN TELOK KEMANG - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN CHUAH - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN KG SAWAH - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN JIMAH - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN JIMAH BARU - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN PASIR PANJANG - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN TANJONG AGAS - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN PERMATANG PASIR - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN LINGGI - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN LUKUT - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN KAMPUNG BARU SIRUSA - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN INTAN PERDANA - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN PEDAS - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN KUNDOR - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN SEPRI - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN SEBERANG BATU HAMPAR - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN KAYU ARA - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN KAMPONG BATU - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN SALAK NAMA - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN BONGEK - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN PULAU BESAR - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN PILIN - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN TUN HAJI ABDUL MALEK - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN CHENGKAU - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN KOTA - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN KAMPONG TANJONG - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN SAWAH RAJA - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN SEMERBOK - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN LEGONG ULU - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN DATO SHAH BANDAR REMBAU - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN TITIAN BINTANGOR - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN TAMAN SERI REMBAU - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN DATO' KLANA PUTRA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SUNGAI MACHANG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN ULU BERANANG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SUNGAI JAI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN KAMPONG DACHING - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN MENDUM - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN BATANG BENAR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN DATO' AHMAD MANAF - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN JIJAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN LABU ULU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN LABU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TEKIR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN GADONG JAYA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN L B JOHNSON - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TEMIANG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN RAHANG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SERI KELANA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN AMPANGAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN PAROI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN PANTAI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN DATO' BANDAR RASAH - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN MAMBAU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN NYATOH - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SEGA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN DATUK AKHIR ZAMAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SELIAU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SENDAYAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SENAWANG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS SEREMBAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TAMAN PAROI JAYA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TAMAN RASAH JAYA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TAMAN DUSUN NYIOR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TAMAN SRI MAWAR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TAMAN TUANKU JAAFAR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN KOMPLEKS KLIA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TAMAN TASIK JAYA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN DESA CEMPAKA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TAMAN SEMARAK - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN DESA JASMIN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SIKAMAT - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TAMAN SERI PAGI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TAMAN TUANKU JAAFAR 2 - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SEREMBAN 2A - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SEREMBAN 2B - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN PANCHOR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN WAWASAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SEREMBAN JAYA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SEREMBAN JAYA 2 - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN LAVENDER HEIGHTS - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TAMAN WARISAN PUTERI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN SENAWANG 3 - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN BANDAR ENSTEK - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN BANDAR SRI SENDAYAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN NILAI IMPIAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TENGKU ZAINUN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN TUNKU SYED SYAABAN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN KERU - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN DATUK ABDULLAH - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN GEDOK - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN SUNGAI JERNEH - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN ROKAN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN BUKIT ROKAN (LKTP) - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN SUNGAI DUA - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN AIR KUNING SELATAN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN (FELDA) PASIR BESAR - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN LONDAH - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN KAMPONG LADANG - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN GEMAS - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN SUNGAI KELAMAH 'RKTP' - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN (FELDA) BUKIT JALOR - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN JELAI 2 (FELDA) - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN BUKIT ROKAN UTARA - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN JELAI 1 - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN JELAI 3 (FELDA) - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN DR. SULAIMAN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN KEM SYED SIRAJUDDIN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN (FELDA) PALONG 12 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN SERTING ULU - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN SERTING ILIR - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN JEMPOL - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN ROMPIN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN AYER HITAM - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN SUNGAI LUI - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN SUNGAI SAMPO - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) PALONG 1 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) PALONG 2 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) PASOH 2 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) PASOH 3 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) PALONG 3 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN LUI TIMOR - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) PALONG 4 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) PALONG 5 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) SERTING - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) LUI SELATAN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) PALONG 7 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) PALONG 9 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN PULAPAH - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) SERTING HILIR 2 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN (FELDA) LUI MUDA - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN DATO' PENGHULU LUAK JEMPOL - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN FELDA RAJA ALIAS 3 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN PALONG 14 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN SERTING HILIR KOMPLEKS - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN PALONG 8 (FELDA) - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN TUNKU MUDA SERTING - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN UNDANG JELEBU - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN SIMPANG DURIAN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN TUNKU MUNAWIR - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN TUNKU KURSHIAH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN DATO' IDRIS - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN PORT DICKSON - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN KING GEORGE V - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN DATO KLANA MAAMOR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN ST PAUL - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN METHODIST (ACS) SEREMBAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN PUTERI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN DATO' RAJA MELANA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN TUANKU ABDUL RAHMAN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN TUNKU BESAR TAMPIN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH KEBANGSAAN ST AIDAN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HUA - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUN YIN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG. BARU PETALING - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU PERTANG - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG PERTANG - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU SUNGAI MUNTOH - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) DURIAN TIPUS - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA KUALA PILAH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SENALING - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TG IPOH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHAI JOHOL - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU BKT GELUGOR - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI CHUN DANGI - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU KEPIS - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE HUA AIR MAWANG - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HUA JUASSEH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PORT DICKSON - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA LUKUT - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA TELOK KEMANG - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PASIR PANJANG - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG PIN - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YIK CHIAO - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUAH - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YIK HWA - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU SG NIPAH - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU TANAH MERAH 'A' - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TANAH MERAH SITE C - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LADANG SUA BETONG - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST LEONARDS - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LADANG SILIAU - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BRADWALL - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BANDAR SPRINGHILL - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HUA REMBAU - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HWA PEDAS - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI TECK KOTA - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI HWA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHAN WA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAN MIN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI HUA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUO MIN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SINO ENGLISH - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HUA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MA HWA KG. BARU RASAH - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU RAHANG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG SERI SIKAMAT - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU MAMBAU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HILLSIDE SEREMBAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU BT 8 LABU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MIN SHING - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU PAROI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU PANTAI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA MANTIN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI CHI MANTIN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU PAJAM - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU BROGA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU CHAI ULU BERANANG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA RANTAU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) FOREST HEIGHTS SEREMBAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KELPIN SEREMBAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG HUA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNGAI SALAK - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PUTRA NILAI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BANDAR SRI SENDAYAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TAMPIN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU TAMPIN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUO MIN GEMAS - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU GEMAS - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN GEMENCHEH - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG BARU GEDOK - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AIR KUNING SELATAN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LADANG REGENT - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI SIN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI WEN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BAHAU - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG MAHSAN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA AIR HITAM - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MIDDLETON - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SAN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PERTANG - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KUALA PILAH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG JUASSEH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PORT DICKSON - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG ST LEONARD - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SENDAYAN - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KEM ASKAR MELAYU - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SENGKANG - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SUNGALA - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SUA BETONG - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TANAH MERAH - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ATHERTON - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BRADWALL - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR SPRING HILL - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI SALAK - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SAGGA - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SILIAU - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LINSUM - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG TAMPIN LINGGI - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MUKUNDAN - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATU HAMPAR - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG CHEMBONG - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG BARU - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG PERHENTIAN TINGGI - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) CONVENT SEREMBAN (KOMPLEKS WAWASAN) - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LORONG JAVA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN LOBAK - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) NILAI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATANG BENAR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KIRBY - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KUBANG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) DESA CEMPAKA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG CAIRO - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LABU BHG 1 - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LABU BHG 4 - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SENAWANG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SEREMBAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG KOMBOK - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) RANTAU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SHANGHAI SEREMBAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TUN SAMBANTHAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG LENGGENG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BANDAR SRI SENDAYAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TAMPIN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG REPAH - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG REGENT - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) AIR KUNING SELATAN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT KLEDEK - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) GEMAS - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG KELAMAH - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BAHAU - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG AIR HITAM - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL & TELUGU) LADANG GEDDES - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG SG SEBALING - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KELPIN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SENAMA - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG ST HELIER - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SIALANG - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG JERAM PADANG - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) DATO' K.PATHMANABAN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MIDDLETON - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN TUNKU LAKSAMANA NASIR - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH KEBANGSAAN PANGLIMA ADNAN - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH KEBANGSAAN CHEMBONG - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN UNDANG REMBAU - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN ASTANA RAJA - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH KEBANGSAAN MANTIN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN DATO' SHAH BANDAR ABU BAKAR LABU HILIR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH KEBANGSAAN BATU KIKIR - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH RENDAH TAHFIZ AL-QURAN INTEGRASI AL-IRSHAD - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' UNDANG MUSA AL-HAJ - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' UNDANG SYED ALI AL-JUFRI SIMPANG GELAMI - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN ZABA - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH KEBANGSAAN DANGI - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH KEBANGSAAN JUASSEH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH KEBANGSAAN SENALING - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA JUMAAT - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK HAJI ABDUL SAMAD - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH KEBANGSAAN YAM TUAN RADIN - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG BARU SIRUSA - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH KEBANGSAAN INTAN PERDANA - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' SEDIA RAJA - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' UNDANG HAJI ADNAN - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH MENENGAH SAINS REMBAU - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH MENENGAH KEBANGSAAN PEDAS - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU AMPUAN DURAH - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT MEWAH - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' SHAHARDIN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN MAMBAU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN SENAWANG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' HAJI MOHD REDZA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN PENDETA ZA'BA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS KLIA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN DESA CEMPAKA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SEMARAK - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PAGI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN TUANKU JAAFAR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH SAINS TUANKU MUNAWIR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN SEREMBAN 2 - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN PANCHOR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN SEREMBAN JAYA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT KEPAYANG - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN SEREMBAN JAYA 2 - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN FOREST HEIGHTS - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN WARISAN PUTERI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' SHAMSUDIN NAIN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN SEREMBAN 3 - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH SAINS TUANKU AISHAH ROHANI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHAN WA II - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SRI SENDAYAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR ENSTEK - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN NILAI IMPIAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN PASIR BESAR - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU SYED IDRUS - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT ROKAN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN GEMAS - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN JELAI - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN INDAH - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN BAHAU - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) PASOH 2 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PERPATIH - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN JELAI - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) PALONG DUA - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) LUI BARAT - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) PALONG 7 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) BANDAR BARU SERTING - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN SERTING HILIR KOMPLEKS - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN ALAM BERAJA - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI JEMPOL - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN BAHAU 2 - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN UNDANG JELEBU - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN PERTANG - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN TUANKU MUHAMMAD - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU KURSHIAH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG HUA - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI PORT DICKSON - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH KEBANGSAAN UNDANG REMBAU - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH MENENGAH KEBANGSAAN KING GEORGE V - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN ST PAUL - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST ACS - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN PUTERI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI AMPANGAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHAN WA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU AMPUAN NAJIHAH - NEGERI SEMBILAN - SEREMBAN",
    "KOLEJ TUNKU KURSHIAH - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO SHEIKH AHMAD - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH DATUK ABDUL RAZAK - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHI WEN - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN TERIANG HILIR - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU BESAR BURHANUDDIN - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' ABDUL SAMAD - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK UNDANG ABDUL MANAP - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH SAINS TUANKU JAAFAR - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK MUHAMMAD YUSOF - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH KEBANGSAAN PASIR PANJANG - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH KEBANGSAAN TANAH MERAH - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT SAUJANA - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK ABDULLAH - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' KLANA PUTRA - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN RANTAU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' MOHD SAID - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN MANTIN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN (FELDA) SERI SENDAYAN - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU BESAR - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN TUANKU ABDUL RAHMAN - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT JALOR - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' MOHD TAHA - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK MANSOR - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH KEBANGSAAN BATU KIKIR - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH AGAMA KUALA KLAWANG - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH AGAMA DATO' HAJI TAN AHMAD - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH RENDAH AGAMA JOHOL - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH AGAMA ULU JEMPOL - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH AGAMA SAINS KUALA PILAH - NEGERI SEMBILAN - KUALA PILAH",
    "SEKOLAH MENENGAH AGAMA CHUAH - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH AGAMA TELOK KEMANG - NEGERI SEMBILAN - PORT DICKSON",
    "SEKOLAH MENENGAH AGAMA HAJI MOHD.YATIM - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH MENENGAH AGAMA REMBAU - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH MENENGAH AGAMA RANTAU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH AGAMA NILAI - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH AGAMA DATO' KLANA PETRA MAAMOR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH AGAMA REPAH - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH AGAMA GEMAS - NEGERI SEMBILAN - TAMPIN",
    "SEKOLAH MENENGAH AGAMA HAJI MUHAMAD - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH MENENGAH AGAMA DATO' HAJI MUSTAFA - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "KOLEJ VOKASIONAL KUALA KLAWANG - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "KOLEJ VOKASIONAL JUASSEH - NEGERI SEMBILAN - KUALA PILAH",
    "KOLEJ VOKASIONAL PORT DICKSON - NEGERI SEMBILAN - PORT DICKSON",
    "KOLEJ VOKASIONAL DATO' UNDANG HJ. MUHAMAD SHARIP - NEGERI SEMBILAN - REMBAU",
    "KOLEJ VOKASIONAL DATO LELA MAHARAJA - NEGERI SEMBILAN - REMBAU",
    "KOLEJ VOKASIONAL AMPANGAN - NEGERI SEMBILAN - SEREMBAN",
    "SMT TUANKU JAAFAR - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA DATO' HAJI ABU HASSAN HAJI SAIL - NEGERI SEMBILAN - REMBAU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SHEIKH HJ MOHD SAID - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH MENENGAH AGAMA PERSEKUTUAN LABU - NEGERI SEMBILAN - SEREMBAN",
    "SEKOLAH BERASRAMA PENUH INTEGRASI JEMPOL - NEGERI SEMBILAN - JEMPOL/JELEBU",
    "SEKOLAH KEBANGSAAN ALMA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN BUKIT TEH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN GUAR PERAHU - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN JALAN BAHARU PERAI - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN JUARA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN JURU - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN KEBUN SIREH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN KHIR JOHARI - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN MACANG BUBOK - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN MENGKUANG - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN PERMATANG PASIR - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN PERMATANG PAUH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN PERMATANG TOK KANDU - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN SERI PENANTI - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN SUNGAI RAMBAI - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN TANAH LIAT - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN SEBERANG JAYA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN SEBERANG JAYA 2 - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN TAMAN INDERAWASIH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN BUKIT INDERA MUDA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN BUKIT MINYAK - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN ALMA JAYA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS ALMA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN SERI IMPIAN - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN BANDAR BARU PERDA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN PAUH JAYA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN PERMAI INDAH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN MACHANG BUBOK II - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN TAMAN IMPIAN - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN AYER ITAM - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN BATU FERINGGHI - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN DATO KRAMAT - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN JELUTONG - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN TAN SRI P.RAMLEE - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN SRI TANJUNG - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN SG GELUGOR - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN SG NIBONG - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN TANJUNG BUNGA - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN TANJONG TOKONG - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN MINDEN HEIGHT - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS JALAN HUTTON - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN JALAN HAMILTON - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN BUKIT GAMBIR - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN SERI INDAH - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN ARA RENDANG - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN BAGAN AJAM - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN BAGAN JERMAL - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN BAGAN TUAN KECHIL - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN BUMBONG LIMA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN MOHD SHAH - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN KAMPONG TO'BEDOR - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN KUALA MUDA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN KUALA PERAI - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN LAHAR KEPAR - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PADANG MENORA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PASIR GEBU - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PAYA KELADI - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PENAGA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PERMATANG BERTAM - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PERMATANG BINJAI - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PERMATANG BULOH - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PERMATANG JANGGUS - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PERMATANG SINTOK - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PERMATANG TO'JAYA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PINANG TUNGGAL - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PULAU MERTAJAM - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN SUNGAI DUA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN SUNGAI PUYU - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN TASEK GELUGOR - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN TELOK AYER TAWAR - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN KAMPUNG SELAMAT - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN TAMAN SENANGAN - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN SUNGAI NYIOR - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN LAHAR YOOI - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PENGKALAN JAYA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN KEPALA BATAS - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN BERTAM INDAH - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN POKOK SENA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN PENAGA JAYA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN SERI GEMILANG - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN BERTAM PERDANA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN DESA MURNI - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN BERTAM PUTRA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN BATU MAUNG - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN BAYAN LEPAS - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN GENTING - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN KONGSI - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN PERMATANG DAMAR LAUT - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN TAN SRI AWANG HAD SALLEH - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN SUNGAI ARA - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN SUNGAI KOROK - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN SUNGAI PINANG - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN SUNGAI RUSA - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN TELOK BAHANG - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN TELOK KUMBAR - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN TITI TERAS - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN BAYAN BARU - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN SERI RELAU - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN SERI BAYU - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN MUTIARA PERDANA - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN BALIK PULAU - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN SUNGAI BATU - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN BAYAN LEPAS 2 - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN BKT TAMBUN - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN KELEDANG JAYA - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN NIBONG TEBAL - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN PERMATANG TOK MAHAT - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN PULAU AMAN - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN SUNGAI ACHEH - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN SUNGAI BAKAP - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN SUNGAI DURI - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN SUNGAI KECHIL - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN SUNGAI SETAR - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN SERI TASEK - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN SG BAKAU - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN JAWI - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN SERI SENTOSA - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN SAUJANA INDAH - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN TAMAN MERAK - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN BATU KAWAN - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN TASEK PERMAI - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN BANDAR TASEK MUTIARA - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN BAKAP INDAH - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN CONVENT - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN STOWELL - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN KG BARU - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN SRI AMAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN BATU LANCHANG - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN CONVENT GREEN LANE - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN CONVENT LEBUH LIGHT - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN CONVENT PULAU TIKUS - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN FRANCIS LIGHT - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN BUKIT GELUGOR - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN HUTCHINGS - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN PEREMPUAN ISLAND - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN JELUTONG BARAT - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN JALAN RESIDENSI - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN PEREMPUAN METHODIST - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN ST XAVIER - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN ST XAVIER CAWANGAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN WELLESLEY - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN PADANG TEMBAK - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH KEBANGSAAN CONVENT 1 - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN HASHIM AWANG - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN ST MARK - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN MAK MANDIN - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH KEBANGSAAN ST MARK - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH KEBANGSAAN SERI PERMAI - PULAU PINANG - BARAT DAYA",
    "SEKOLAH KEBANGSAAN METHODIST - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PERMATANG TINGGI - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PERKAMPUNGAN BERAPIT - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUBANG SEMANG - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KG. SG. LEMBU - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JIT SIN 'A' - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JIT SIN 'B' - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAY SIN - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEOW KUANG - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KIM SEN - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LAY KEOW - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PENG BIN - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SENG KEOW - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN YA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TRUE LIGHT - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BENG TEIK (PUSAT) - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SHANG WU - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEREMPUAN CHINA - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AIK HUA - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BENG TEIK CAWANGAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAO NAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JELUTONG - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA CONFUCIAN 'A' - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA CONFUCIAN 'B' - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CONVENT DATUK KERAMAT - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ENG CHUAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HAN CHIANG - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HENG EE - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HUN BIN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEONG HOE - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHENG TEAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN PUSAT - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN CAWANGAN SATU - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN CAWANGAN KEDUA - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG HWA - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LI TEK 'A' - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LI TEK 'B' - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LI TEK CAWANGAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MIN SIN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MOH GHEE (PUSAT) - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MOH GHEE CAWANGAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAI CHAI - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHEI SHIN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHOR TAY - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POAY WAH - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SHIH CHUNG PUSAT - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SHIH CHUNG CAWANGAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN KANG - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUM MIN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUM SUN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TONG SIAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) UNION - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HU YEW SEAH - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MAK MANDIN - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) AIK KEOW - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HWA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI YU - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA PUSAT - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 1 - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 2 - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAI CHEE - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUANG YU - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG HWA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LEE CHEE - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LI HWA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MAH HUA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NUNG MIN - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN CHUNG - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 3 - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HWA (PANTAI ACHEH) - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PULAU BETONG - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG CHENG - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG TEIK - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SHAN - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) EOK HUA - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POI ENG - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SACRED HEART - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAR THONG - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WEN KHAI - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YANG CHENG - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YEOK HUA - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YU CHYE - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BOON BENG - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG VALDOR - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG KUANG - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAMPUNG JAWI - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KENG KOON - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAI TEIK - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOK ENG - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PERMATANG TINGGI - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG JURU - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BKT MERTAJAM - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG ALMA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG PRYE - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) AZAD - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) RAJAJI - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) RAMAKRISHNA - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) RAMATHASAR - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUBRAMANIYA BARATHEE - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MALAKOFF - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG MAYFIELD - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PALANIANDY - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) MAK MANDIN - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) PERAI - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) BAYAN LEPAS - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI ARA - PULAU PINANG - BARAT DAYA",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BATU KAWAN - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG BYRAM - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) NIBONG TEBAL - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG CHANGKAT - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG JAWI - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG KRIAN - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG SEMPAH - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) TASEK PERMAI - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LDG TRANSKRIAN - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG VALDOR - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SUNGAI BAKAP - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS PERSEKUTUAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH RENDAH ISLAM AL MASRIYAH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN PRAI - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN BERAPIT - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN GUAR PERAHU - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SEBERANG JAYA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN PERMATANG RAWA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN SAMA GAGAH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN INDERAWASIH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN ALMA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN MENGKUANG - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN PERMAI INDAH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SEJAHTERA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU PERDA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN PAUH JAYA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TUN HUSSEIN ONN - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN PERWIRA - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN PERMATANG PASIR - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN MACHANG BUBUK - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN GUAR PERAHU INDAH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN ABDULLAH MUNSHI - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN (P) SRI MUTIARA - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN HUTCHINGS - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT GAMBIR - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN AIR ITAM - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN HAMID KHAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN TELOK KUMBAR - PULAU PINANG - BARAT DAYA",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT JAMBUL - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI NIBONG - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN PERMATANG TOK JAYA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK HAJI AHMAD SAID - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK ONN - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK HAJI AHMAD BADAWI - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN SRI MUDA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG LING BUTTERWORTH - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN TELOK AIR TAWAR - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' KAILAN - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN MAK MANDIN - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN BAGAN JAYA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN POKOK SENA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPONG SELAMAT - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN BERTAM INDAH - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN KEPALA BATAS - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' HAJI HASSAN NOH - PULAU PINANG - SEBERANG PERAI UTARA",
    "KOLEJ TINGKATAN ENAM DESA MURNI - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN GEMILANG - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH SAINS KEPALA BATAS - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN BERTAM PERDANA - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN TASEK GELUGOR - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA TUN UDA - PULAU PINANG - BARAT DAYA",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BALIK PULAU - PULAU PINANG - BARAT DAYA",
    "SEKOLAH MENENGAH KEBANGSAAN BATU MAUNG - PULAU PINANG - BARAT DAYA",
    "SEKOLAH MENENGAH KEBANGSAAN TELUK BAHANG - PULAU PINANG - BARAT DAYA",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BAYU - PULAU PINANG - BARAT DAYA",
    "SEKOLAH MENENGAH KEBANGSAAN PONDOK UPEH - PULAU PINANG - BARAT DAYA",
    "SEKOLAH MENENGAH KEBANGSAAN BAYAN LEPAS - PULAU PINANG - BARAT DAYA",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ACHEH - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN VALDOR - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI NIBONG - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN MUTIARA IMPIAN - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN JAWI - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN WIDURI - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN SAUJANA INDAH - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN BATU KAWAN - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR TASEK MUTIARA - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN TASEK - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN JIT SIN II - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI BUKIT MERTAJAM - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN DAMAI - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT (M) BUKIT MERTAJAM - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN JIT SIN - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN CHINA PULAU PINANG - PULAU PINANG - TIMUR LAUT",
    "PENANG FREE SCHOOL - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA CONFUCIAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG LING - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT GREEN LANE - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT DATUK KERAMAT - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT LEBUH LIGHT - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT PULAU TIKUS - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN GEORGETOWN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN HENG EE - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN (L) METHODIST - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN (P) METHODIST - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN PHOR TAY - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN (P) ST GEORGE - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN ST XAVIER - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU PUAN HABSAH - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN UNION - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN WESTLANDS - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT BUTTERWORTH - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPONG KASTAM - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK HJ ABDUL KADIR - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN ST MARK - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN SACRED HEART - PULAU PINANG - BARAT DAYA",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI ARA - PULAU PINANG - BARAT DAYA",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT MERTAJAM - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN PENANTI - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH SAINS TUN SYED SHEH SHAHABUDDIN - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH KEBANGSAAN TANJONG BUNGA - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK HJ. MOHAMED NOR AHMAD - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN JELUTONG - PULAU PINANG - TIMUR LAUT",
    "KOLEJ TINGKATAN ENAM HAJI ZAINUL ABIDIN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG POLO - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN PENDIDIKAN KHAS PERSEKUTUAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN BAKTI - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN SIMPANG EMPAT - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN TUN SYED SHEH BARAKBAH - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABDUL RAHMAN - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SMA DAERATUL MAARIFIL WATANIAH 2 - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH AGAMA AL-AHMADIAH AL-IJTIMAIAH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH ISLAM AL-MASRIYAH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SMA FAQIAH DAIMIAH - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SEKOLAH MENENGAH AGAMA AL MAAHADUL ISLAMI TASEK JUNJUNG - PULAU PINANG - SEBERANG PRAI TENGAH",
    "SMA TARBIAH ISLAMIAH - PULAU PINANG - TIMUR LAUT",
    "SMA DAERATUL MA'ARIFIL WATANIAH SATU - PULAU PINANG - SEBERANG PERAI UTARA",
    "MAKTAB WAN JAH - PULAU PINANG - SEBERANG PERAI UTARA",
    "MAAHAD AL-IMAM AN-NAWAWI - PULAU PINANG - SEBERANG PERAI UTARA",
    "MADRASAH KHAIRIAH ISLAMIAH - PULAU PINANG - SEBERANG PERAI UTARA",
    "SMA MAAHAD AL MASHOOR AL ISLAMI - PULAU PINANG - BARAT DAYA",
    "KOLEJ VOKASIONAL SEBERANG PERAI - PULAU PINANG - SEBERANG PRAI TENGAH",
    "KOLEJ VOKASIONAL BATU LANCHANG - PULAU PINANG - TIMUR LAUT",
    "KOLEJ VOKASIONAL BUTTERWORTH - PULAU PINANG - SEBERANG PERAI UTARA",
    "KOLEJ VOKASIONAL BALIK PULAU - PULAU PINANG - BARAT DAYA",
    "KOLEJ VOKASIONAL NIBONG TEBAL - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SMT TUNKU ABD RAHMAN - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA (L) AL-MASHOOR - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA (P) ALMASHOOR - PULAU PINANG - TIMUR LAUT",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA AL-IRSHAD - PULAU PINANG - SEBERANG PERAI UTARA",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SYEIKH ABDULLAH FAHIM - PULAU PINANG - SEBERANG PRAI SELATAN",
    "SEKOLAH KEBANGSAAN ABI - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN TENGKU BUDRIAH - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN BESERI - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN BINTONG - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN BOHOR MALI - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN RAJA PEREMPUAN BUDRIAH - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN CHUPING - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN DATO KAYAMAN - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN GUAR NANGKA - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN JEJAWI - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN JELEMPOK - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN KAMPONG SALANG - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN KAMPONG SERDANG - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN DATO WAN AHMAD - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN SERI PERLIS - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN KAYANG - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN KUALA PERLIS - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN PADANG KOTA - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN ORAN - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN PADANG BESAR UTARA - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN PADANG BESAR SELATAN - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN PADANG KERIA - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN PADANG MELANGIT - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN PANGGAS - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN PAUH - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN PAYA - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN SANGLANG - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN SANTAN - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN SIMPANG AMPAT - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN SUNGAI BARU - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN SUNGAI BEREMBANG - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN DATO AHMAD MUSA - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN TAMBUN TULANG - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN TITI TINGGI - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN TITI TO' BANDAR - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN SERI TUNJONG - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN UJONG BATU - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN UTAN AJI - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN CHANGKAT JAWI - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN BATU BERTANGKUP - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN (FELDA) CHUPING - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN LONG BOH - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN (FELDA) MATA AIR - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS PERLIS - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN LUBUK SIREH - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN (FELDA) RIMBA MAS - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN ARAU - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN JALAN RAJA SYED ALWI - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN SENA - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN KUBANG GAJAH - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN SEBERANG RAMAI - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN GUAR JENTIK - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN BEHOR EMPIANG - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN BUKIT KETERI - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN PANGGAU - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN INSTITUT PENDIDIKAN GURU MALAYSIA - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN SERI INDERA - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN PUTRA - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN STELLA MARIS - PERLIS - PERLIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HUN - PERLIS - PERLIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHOON SIEW - PERLIS - PERLIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA AIK - PERLIS - PERLIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAY BENG - PERLIS - PERLIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHOON AIK - PERLIS - PERLIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PADANG BESAR UTARA - PERLIS - PERLIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KONG AIK - PERLIS - PERLIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KONG HWA - PERLIS - PERLIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIMPANG EMPAT - PERLIS - PERLIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN MIN - PERLIS - PERLIS",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KANGAR - PERLIS - PERLIS",
    "SEKOLAH AGAMA AL-ISLAHIYAH (RENDAH) - PERLIS - PERLIS",
    "SEKOLAH RENDAH ISLAM III - PERLIS - PERLIS",
    "SEKOLAH RENDAH ISLAM II - PERLIS - PERLIS",
    "SEKOLAH RENDAH ISLAM 1 - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' SHEIKH AHMAD - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN SYED AHMAD - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN SYED HASSAN - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA PUAN MUDA TENGKU FAUZIAH - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA PERLIS - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK JAAFAR HASSAN - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN ARAU - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN SANGLANG - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN ABI - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG BESAR UTARA - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU BUDRIAH - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' ALI AHMAD - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN TUANKU LAILATUL SHAHREEN - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN BESERI - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN MATA AYER - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN GUAR NANGKA - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRA - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN PERLIS - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN DERMA - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN SYED ALWI - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN SYED SAFFI - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN SYED SIRAJUDDIN - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU SULAIMAN - PERLIS - PERLIS",
    "SEKOLAH MENENGAH SAINS TUANKU SYED PUTRA - PERLIS - PERLIS",
    "SEKOLAH AGAMA AL-ISLAHIYAH (MENENGAH) - PERLIS - PERLIS",
    "SMA AL MADRASAH AL ALAWIYAH AD DINIAH - PERLIS - PERLIS",
    "KOLEJ VOKASIONAL KANGAR - PERLIS - PERLIS",
    "KOLEJ VOKASIONAL ARAU - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA ARAU - PERLIS - PERLIS",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA (P) ALAWIYAH - PERLIS - PERLIS",
    "SEKOLAH KEBANGSAAN KAMPONG RAJA - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN AMER - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN ALOR LINTAH - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KERANDANG - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KUALA BESUT - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN TEMBILA - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KAMPUNG NANGKA - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN PULAU PERHENTIAN - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN BETING LINTANG - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN GONG BAYOR - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KELUANG - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN BUKIT TEMPURONG - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KAMPUNG BAHARU - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN ALOR PEROI - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN TOK RAJA - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN PENGKALAN NYIREH - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN PUSAT JERTEH - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN BUKIT KENAK - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN TANAH MERAH - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN APAL - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN JABI - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN RENEK - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN OH - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN TEMPINIS - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN BUKIT JEROK - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN TOK DOR - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KAMPUNG BARU TOK DOR - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN PADANG LUAS - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN BUKIT PUTERI - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN AYER TERJUN - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN TOK MOTONG - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN DARAU - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN PASIR AKAR - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN SERI PAYONG - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN (FELDA) TENANG - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN PANCHOR - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KAMPUNG LA - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KERUAK - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KUALA KUBANG - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN (FELDA) SELASIH - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS BESUT - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KUALA BESUT 2 - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN TENGKU MAHMUD 2 - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN LUBUK KAWAH - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN ALOR KELADI - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KAMPUNG NAIL - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN SEBERANG JERTEH - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN PERMAISURI NUR ZAHIRAH - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN PELAGAT - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN ANAK IKAN - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN PADANG LANDAK - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN NYIUR TUJUH - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN KUALA DUNGUN - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN DURIAN MAS 1 - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN PUSAT DUNGUN - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN BANDAR DUNGUN - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN BUKIT BESI - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN TEBING TEMBAH - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN KAMPUNG NYIOR - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN PAKA - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN SURA - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN SEBERANG DUNGUN - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN KUALA ABANG - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN PULAU SERAI - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN DELONG - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN PADANG PULUT - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN KAMPUNG WA - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN JERANGAU - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN DENDANG - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN TEPUS - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN MINDA TALONG - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN KUALA JENGAL - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN JONGOK BATU - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN PASIR RAJA - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN KAMPUNG SHUKOR - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN SANTONG - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN BATU 48 - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN TOK KAH - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN DURIAN MENTANGAU - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN LINTANG - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN (FELDA) KERTEH 1 - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN LADANG GAJAH MATI - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN KAMPUNG BARU KUALA ABANG - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN SERDANG - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN DURIAN MAS 2 - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN SERI PAKA - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN BALAI BESAR - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN FELDA SERI RASAU - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN LKTP JERANGAU - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN TANJUNG PATI - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN PAKA II - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN BATU 7 - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN KETENGAH JAYA II - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN KOMPLEKS RANTAU ABANG - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN PAKA III - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN GONG PASIR - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN CHUKAI - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN PUSAT - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN SERI GELIGA - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN BUKIT MENTOK - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN BINJAI - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN PASIR GAJAH - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN SEBERANG TAYOR - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN RKT SEBERANG TAYOR - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN AYER PUTEH - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN IBOK - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN BUKIT KUANG - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN TELOK KALONG - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN KIJAL - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN MERAGA BERIS - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN KEMASEK - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN AYER JERNEH - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN KERTEH - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN PENGKALAN RANGGON - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN KG CHABANG - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN PADANG KUBU - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN CHENEH BARU - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN FELDA NERAM SATU - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN SERI BANDI - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN KAMPONG BAHARU FELDA NERAM I - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN LEMBAH JABOR - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN (FELDA) CHERUL - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN SERI KEMAMAN - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN PAYOH - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN CHENEH BARU 2 - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN SERI IMAN - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN KUALA KEMAMAN - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN SUNGAI PERGAM - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN RANTAU PETRONAS - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN KETENGAH JAYA - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN KG. BARU RASAU KERTEH 5 - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN BANDAR BAHARU - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN MAK LAGAM - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN KG BARU KERTEH - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN BUKIT ANAK DARA - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN SERI BANDI 2 - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN SERI GELUGOR - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN SERI JAYA - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN TENGKU AMPUAN MARIAM - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PAYA BUNGA - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PUSAT BUKIT BESAR - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PUSAT KUALA IBAI - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN LADANG - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN GONG KAPAS - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PASIR PANJANG - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN DUYONG - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN CHENDERING - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN GONG TOK NASEK - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN SERI NILAM - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PULAU RUSA - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN LOSONG - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PUSAT CHABANG TIGA - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PALOH - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN RENGAS BEKAH - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN MANIR - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN TELOK PASU - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN WAKAF MEMPELAM - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN GELUGOR - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN KEPONG - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN KAMPUNG BAHARU SERADA - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN SERADA - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN BANGGOL KATONG - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PADANG MIDIN - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN ATAS TOL - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN KAMPONG BUKIT CHENDERING - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN UNDANG - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN TOK DIR - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN KAMPONG TENGAH - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN BANGGOL PERADONG - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN BUKIT PETITI - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN KEBOR BESAR - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN BELADAU KOLAM - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN KESOM - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PULAU MANIS - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN KEBOR AYER - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PULAU BAHAGIA - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN DURIAN MAS - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN SERI BUDIMAN - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KUALA TERENGGANU - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN DURIAN BURUNG - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN KUBANG IKAN - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN PADANG HILIRAN - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN SERI BUDIMAN 2 - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN BUKIT LOSONG - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN BUKIT BAYAS - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN TELUK MENARA - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN KEDAI BULOH - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN MARANG - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN SEBERANG MARANG - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN PULAU KERENGGA - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN MERCHANG - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN GONG BALAI - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN PASIR PUTEH - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN JAMBU BONGKOK - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN SENTOL PATAH - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN BATANGAN - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN PENGKALAN BERANGAN - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN BUKIT JEJULONG - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN JENANG - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN KUBU - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN BUKIT SAWA - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN WAKAF TAPAI - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN BUKIT PAYONG - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN GONDANG - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN JERONG - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN SIMPANG RAWAI - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN TASEK - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN RUSILA - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN RU RENDANG - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN KELULUT - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN PADANG MENGKUANG - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN PAYA RESAK - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN MEDAN JAYA - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN BUKIT GASING - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN GONG NANGKA - TERENGGANU - MARANG",
    "SEKOLAH KEBANGSAAN KUALA BERANG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN TELAGA - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN BUKIT PERAH - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN TANGGOL - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN BUKIT DIMAN - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN PAUH - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN BUKIT APIT - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN DUSUN - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN LANGGAR - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN GETANG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN BUKIT GEMUROH - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN MATANG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN TENGKAWANG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN MENERONG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN SG BULOH - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN KUALA PING - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN TAPU - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN PADANG SETEBU - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN PADANG SETAR - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN KUA - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN PASIR NERING - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN PEROH - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN NIBONG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN KUALA DURA - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN BUKIT TADOK - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN KEMAT - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN LUBUK PERIOK - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN LEREK - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN PEREH - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN TERIS - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN TAPAH - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN KUALA JENDERIS - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN BETONG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN CHETING - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN LKTP BUKIT BADING - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN FELDA JERANGAU BARAT - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN BINJAI KERTAS - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN FELDA MENGKAWANG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN FELDA TERSAT - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN SG BERUA - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN KUALA TELEMONG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN PASIR TINGGI - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN SERI BERANG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN TOK RANDOK - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN LANDAS - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN SERI BULUH - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN AJIL - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN PADANG SETENGGE - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH KEBANGSAAN LUBOK TERAS - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN KAMPUNG FIKRI - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN KUALA SETIU - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN PENAREK - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN MANGKOK - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN TELAGA PAPAN - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN BARI - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN BINTANG - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN GUNTONG - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN KAMPUNG BULOH - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN KAMPONG BESUT - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN PUTERA JAYA - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN KG. RAHMAT - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN CHALOK - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN BUKIT PUTERA - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN GUNTONG LUAR - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN MERANG - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN (FELDA) CHALOK BARAT - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN SUNGAI TONG - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN BATU 29 - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN LANGKAP - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN PELONG - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN MERBAU MENYUSUT - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN BUKIT MUNDOK - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN KG BUKIT ULU NERUS - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN PAK BA - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN KAMPUNG FIKRI SUNGAI TONG - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN SUNGAI LAS - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN RHU SEPULUH - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN BANGGOL - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN PANCHOR MERAH - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN PERMAISURI - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN SUNGAI LEREK - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN LEMBAH BIDONG - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN LEMBAH JAYA - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN SERI KASAR - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN KAMPONG JAYA - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN PAYONG BARU - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN KAMPONG TAYOR - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN BARI ( SG TONG) - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN JELAPANG - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN SAUJANA - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN SERI LANGKAP - TERENGGANU - SETIU",
    "SEKOLAH KEBANGSAAN DARAT BATU RAKIT - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN BUKIT TUNGGAL - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN SEBERANG TAKIR - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN MENGABANG TELIPOT - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN BUKIT GUNTONG - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN BATU RAKIT - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN MARAS - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN PAGAR BESI - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN BUKIT CHEMPAKA - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN BUKIT WAN - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN BUKIT NANAS - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN PECHAH ROTAN - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN TOK JEMBAL - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN TOK JIRING - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN KAMPUNG GEMUROH - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN PULAU REDANG - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN LKTP BELARA - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN BUKIT TOK BENG - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN KOMPLEKS MENGABANG TELIPOT - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN GONG BADAK - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN TELUK KETAPANG - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN KOMPLEKS GONG BADAK - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN BUKIT TUMBUH - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN TANJUNG GELAM - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN PADANG AIR - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN KOMPLEKS SEBERANG TAKIR - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN INSTITUT PENDIDIKAN GURU KAMPUS DATO RAZALI ISMAIL - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN PADANG KEMUNTING - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN KOMPLEKS TEMBESU - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN TENGKU MAHMUD - TERENGGANU - BESUT",
    "SEKOLAH KEBANGSAAN SULTAN OMAR - TERENGGANU - DUNGUN",
    "SEKOLAH KEBANGSAAN SULTAN ISMAIL - TERENGGANU - KEMAMAN",
    "SEKOLAH KEBANGSAAN SULTAN SULAIMAN 1 - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN SULTAN SULAIMAN 2 - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN TENGKU BARIAH - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH KEBANGSAAN TENGKU AMPUAN INTAN - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - TERENGGANU - BESUT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG HWA - TERENGGANU - DUNGUN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN CHONE - TERENGGANU - DUNGUN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE MONG - TERENGGANU - DUNGUN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK KHOON - TERENGGANU - KEMAMAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUKAI - TERENGGANU - KEMAMAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUALA KEMAMAN - TERENGGANU - KEMAMAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JABOR - TERENGGANU - KEMAMAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA WEI SIN - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA BESUT - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN SERI NERING - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN NASIRUDDIN SHAH - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT PAYONG - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN RENEK - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN PELAGAT - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU MAHMUD 2 - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRA - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN PERMAISURI NUR ZAHIRAH - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN TEMBILA - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BUKIT PUTERI - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN JABI - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN TOK DOR - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN ALOR PEROI - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG PULUT - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU INTAN ZAHARAH - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN PAKA - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN PULAU SERAI - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN DURIAN MAS - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI DUNGUN - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN SURA - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN JERANGAU - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI RASAU - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN KETENGAH JAYA - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH SAINS DUNGUN - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA JENGAL - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PAKA - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN TEJA PUTRA - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT BESI - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN BALAI BESAR - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS RANTAU ABANG - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' ENGKU BIJAYA SURA - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS PADANG JAMBU - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN KERTEH - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHENEH BARU - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHUKAI - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN BADRUL ALAM SHAH - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN AYER PUTEH - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN KIJAL - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ISMAIL 2 - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN GELIGA - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN RANTAU PETRONAS - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN FELDA NERAM - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN RASAU KERTEH B5 - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN MAK LAGAM - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT MENTOK - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG BARU KERTEH - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN BINJAI - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BANDI - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHERUL - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERI CHENEH - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT KUANG - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN BANGGOL - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN PERMAI - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN MEGAT PANJI ALAM - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG MIDIN - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BUDIMAN - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN MANIR - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN BELARA - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN AHMAD - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN CHENDERING - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN MANSOR - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT BESAR - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' RAZALI ISMAIL - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH SAINS KUALA TERENGGANU - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI NILAM - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG NEGARA - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' HAJI MOHD SAID - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU MUHAMMAD ISMAIL - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN MERCHANG - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN TUN TELANAI - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT SAWA - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU LELA SEGARA - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN PENGKALAN BERANGAN - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN RUSILA - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN SEBERANG MARANG - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN WAKAF TAPAI - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK AWANG JABAR - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PAYONG - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN SERI SERATING - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN MATANG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN AJIL - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN LANDAS - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN MENERONG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BERANG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA TELEMONG - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN JENAGOR - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA JENDERIS - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN TUN INDERA - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT DIMAN - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH SAINS HULU TERENGGANU - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN TAPU - TERENGGANU - HULU TERENGGANU",
    "KOLEJ TINGKATAN ENAM HULU TERENGGANU - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU IBRAHIM - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI TONG - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH KEBANGSAAN PENAREK - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH KEBANGSAAN SAUJANA - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH KEBANGSAAN CHALOK - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH KEBANGSAAN PELONG - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH KEBANGSAAN GUNTONG - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH KEBANGSAAN LEMBAH BIDONG - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH KEBANGSAAN PUTERA JAYA - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH KEBANGSAAN LANGKAP - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT NENAS - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH SAINS SETIU - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH KEBANGSAAN IBRAHIM FIKRI - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU MIZAN ZAINAL ABIDIN - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT GUNTONG - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT TUNGGAL - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS MENGABANG TELIPOT - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS GONG BADAK - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS SEBERANG TAKIR - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG KEMUNTING - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH KEBANGSAAN BATU RAKIT - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH KEBANGSAAN KOMPLEKS TEMBESU - TERENGGANU - KUALA NERUS",
    "SEKOLAH SUKAN MALAYSIA TERENGGANU - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU MAHMUD - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN OMAR - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN ISMAIL - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH KEBANGSAAN SULTAN SULAIMAN - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU BARIAH - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA WEI SIN - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN TENGKU AMPUAN INTAN - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH SAINS SULTAN MAHMUD - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH IMTIAZ YAYASAN TERENGGANU-BESUT - TERENGGANU - BESUT",
    "SMA ITTIFAKIAH - TERENGGANU - BESUT",
    "SMA MAARIF - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH AGAMA SULTAN ISMAIL - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH IMTIAZ YAYASAN TERENGGANU-DUNGUN - TERENGGANU - DUNGUN",
    "SMA AL FALAH - TERENGGANU - KEMAMAN",
    "SEKOLAH MENENGAH AGAMA IMTIAZ KEMAMAN - TERENGGANU - KEMAMAN",
    "SMA KHAIRIAH - TERENGGANU - KUALA TERENGGANU",
    "SMA (ATAS) SULTAN ZAINAL ABIDIN - TERENGGANU - KUALA TERENGGANU",
    "SMA SULTAN ZAINAL ABIDIN LADANG - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH IMTIAZ YAYASAN TERENGGANU-KUALA TERENGGANU - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH AGAMA MARANG - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH IMTIAZ YAYASAN TERENGGANU-KUALA BERANG - TERENGGANU - HULU TERENGGANU",
    "SMA MAHMUDIAH - TERENGGANU - HULU TERENGGANU",
    "SEKOLAH MENENGAH AGAMA SETIU - TERENGGANU - SETIU",
    "SEKOLAH MENENGAH IMTIAZ YAYASAN TERENGGANU SETIU - TERENGGANU - SETIU",
    "KOLEJ VOKASIONAL BESUT - TERENGGANU - BESUT",
    "KOLEJ VOKASIONAL SULTAN MIZAN - TERENGGANU - BESUT",
    "KOLEJ VOKASIONAL DUNGUN - TERENGGANU - DUNGUN",
    "KOLEJ VOKASIONAL KEMAMAN - TERENGGANU - KEMAMAN",
    "KOLEJ VOKASIONAL KUALA BERANG - TERENGGANU - HULU TERENGGANU",
    "KOLEJ VOKASIONAL WAKAF TEMBESU - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH TEKNIK KUALA TERENGGANU - TERENGGANU - KUALA NERUS",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA NURUL ITTIFAQ - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA WATANIAH - TERENGGANU - BESUT",
    "SEKOLAH MENENGAH KEBANGSAAN (AGAMA) KUALA ABANG - TERENGGANU - DUNGUN",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SHEIKH ABDUL MALEK - TERENGGANU - KUALA TERENGGANU",
    "SEKOLAH MENENGAH KEBANGSAAN (AGAMA) DURIAN GULING - TERENGGANU - MARANG",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA DATO' HAJI ABBAS - TERENGGANU - KUALA NERUS",
    "SEKOLAH BERASRAMA PENUH INTEGRASI BATU RAKIT - TERENGGANU - KUALA NERUS",
    "SEKOLAH KEBANGSAAN SULTAN HISAMUDDIN ALAM SHAH - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN JALAN PASAR 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN TUN HUSSEIN ONN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN DATOK KERAMAT 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN DATOK KERAMAT 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN POLIS DEPOT - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN SENTUL 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN SETAPAK - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN BUKIT BANDARAYA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN BANGSAR - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN KAMPUNG BHARU - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN TAMAN BUKIT MALURI - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN WANGSA JAYA - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN WANGSA MAJU SEKSYEN 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN TAMAN MIDAH - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN TAMAN TUN DR ISMAIL 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN KG SELAYANG - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN KG BATU - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN SEGAMBUT - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN SG BESI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN PENGKALAN TENTERA DARAT - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN PETALING 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SG PENCHALA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SERI MEGA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN BKT DAMANSARA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN BUKIT PANTAI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN BANDAR TUN RAZAK 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN BANDAR TUN RAZAK 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SENTUL 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN PETALING 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN BANDAR BARU SERI PETALING - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SERI CHERAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN BANDAR BARU SENTUL - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN WANGSA MAJU SEKSYEN 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KG BAHARU - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS JLN BATU - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS JLN PEEL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN TAMAN DESA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN TAMAN MALURI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN AU KERAMAT - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN SERI PERAK - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN TAMAN SEGAR - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN TAMAN KOPERASI POLIS - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN TAMAN TUN DR. ISMAIL (2) - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SETIAWANGSA - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN TAMAN KEPONG - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN TAMAN SERI RAMPAI - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN DESA TUN HUSSEIN ONN - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN WANGSA MELAWATI - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN JALAN RAJA MUDA - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN MENJALARA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN TAMAN SRI SINAR - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN WANGSA MAJU ZON R10 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN DESA SETAPAK - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN SETAPAK INDAH - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN TAMAN MIDAH 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN DANAU KOTA - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN DESA PANDAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SERI MURNI - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN SERI SURIA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SERI HARTAMAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN BANDAR BARU SERI PETALING 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN DESA TASIK - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SERI NILAM - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN SERI INDAH - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SERI KEPONG - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN BANDAR TASIK SELATAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN DESA PETALING - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN DANAU PERDANA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN BUKIT JALIL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN ALAM DAMAI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SERI TASIK - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN INTAN BAIDURI - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN SERI SAUJANA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN COCHRANE - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN KIARAMAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN TAMAN MELATI - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN SEGAMBUT MAKMUR - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN SERI ANGGERIK - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN COCHRANE PERKASA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SENTUL UTAMA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN BATU MUDA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN DANAU KOTA 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN TIARA PERMAI - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN SERI PERMAISURI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN KEMENTAH - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN SERI BONUS - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN BEBULOH - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN SUNGAI BEDAUN - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN BUKIT KALLAM - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN KERUPANG - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN PEKAN 1 WP LABUAN - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN PEKAN II WP LABUAN - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN LAYANG-LAYANGAN - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN LUBOK TEMIANG - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN PATAU-PATAU - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN RANCHA-RANCHA - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN SUNGAI LADA - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN MEMBEDAI - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN TANJUNG ARU - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN PANTAI - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 9(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 8(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 16(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 11(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 8(2) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 9 (2) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 16 (2) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 11 (3) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 14(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 11(2) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 18(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 18(2) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 5(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 17(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 8 (3) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN PUTRAJAYA PRESINT 15 (1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN JALAN AIR PANAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN (L) JALAN BATU - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN (P) JALAN BATU - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN JALAN BELLAMY - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN BRICKFIELDS 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN BRICKFIELDS 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SERI BINTANG UTARA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN SERI BINTANG SELATAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN CONVENT (1) BUKIT NANAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN CONVENT (2) BUKIT NANAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN ST. TERESA BRICKFIELDS 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN CONVENT JLN PEEL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN CONVENT SENTUL 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN CONVENT SENTUL 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN MARIAN CONVENT SETAPAK - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN DATO ABU BAKAR - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN JALAN GURNEY 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN JALAN GURNEY 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN (P) METHODIST (1) BRICKFIELDS - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN JALAN KUANTAN 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN PUTERI PANDAN 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN PUTERI PANDAN 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN LA SALLE (1) BRICKFIELDS - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN YAACOB LATIF 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN LA SALLE (1) SENTUL - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN METHODIST (L) JLN HANG JEBAT - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN (L) METHODIST SENTUL - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN PADANG TEMBAK 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN PADANG TEMBAK 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN JALAN PEEL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN (P) PUDU 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN ST. GABRIEL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN ST. JOHN 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH KEBANGSAAN ST. MARY - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN JALAN HANG TUAH 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN JALAN SUNGEI BESI 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN JALAN SUNGAI BESI 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN (1) BATU 4 JALAN IPOH - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN SERI DELIMA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN SERI SETIA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN LA SALLE 1 JINJANG - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN SALAK SOUTH - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN KEPONG BARU - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH KEBANGSAAN LA SALLE 2 JINJANG - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI MAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIAO NAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN WOO - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG HWA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA 'P' - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG KWO - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG KWOK - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CONFUCIAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JALAN DAVIDSON - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) IMBI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHAI CHEE - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG MIN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUEN CHENG 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LA SALLE - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LAI CHEE - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LAI MENG - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MUN YEE - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAAM KHEUNG - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAN KAI - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG FAH PHIT CHEE - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAM YOKE - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SENTUL - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SENTUL PASAR - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST. TERESA BRICKFIELDS - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAI THUNG - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TSUN JIN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOKE NAM - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHOONG WEN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JINJANG TENGAH 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JINJANG UTARA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JINJANG SELATAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEPONG 1 - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEPONG 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KWONG HON - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MUN CHOONG - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAN YIK 'LEE RUBBER' - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SALAK SOUTH - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) JINJANG TENGAH 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUEN CHENG 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAMAN CONNAUGHT - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEPONG 3 - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WANGSA MAJU - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) APPAR - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JALAN BANGSAR - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) CHERAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) FLETCHER - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) KG PANDAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) JLN SAN PENG - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SENTUL - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) ST. JOSEPH - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) THAMBOOSAMY PILLAI - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) VIVEKANANDA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG BUKIT JALIL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) LADANG EDINBURGH - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SARASWATHY - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SEGAMBUT - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH JENIS KEBANGSAAN (TAMIL) SG BESI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH KEBANGSAAN ST ANNE'S CONVENT - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI WEN - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN BUKIT MALURI - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI BESI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN AMINUDDIN BAKI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN PUTERI WILAYAH - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "KOLEJ TINGKATAN ENAM PUDU JAYA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG TEMBAK - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN PETALING - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA ALI - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH SAINS SELANGOR - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN KEPONG BARU - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN TUN DR. ISMAIL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN PENDIDIKAN KHAS SETAPAK - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN DESA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT BANDARAYA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN WANGSA MAJU SEKSYEN 2 - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN ZON R1 WANGSA MAJU - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN CONNAUGHT - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SENTUL - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BARU SERI PETALING - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN YARL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI HARTAMAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SERI RAMPAI - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR TUN RAZAK - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN DESA PERDANA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN MELATI - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN SEKSYEN 5 WANGSA MAJU - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN DESA TUN HUSSEIN ONN - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN MENJALARA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN SEGAMBUT JAYA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN WANGSA MELAWATI - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH SUKAN BUKIT JALIL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' IBRAHIM YAACOB - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN SETIAWANGSA - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR TASIK SELATAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN BATU MUDA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN SETAPAK INDAH - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN SINAR BINTANG - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN SERI SAUJANA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN MIHARJA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT JALIL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN DESA PETALING - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BINTANG SELATAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN DANAU KOTA - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN SEGAMBUT - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN SERI MULIA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI TASIK - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PERMAISURI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH SAINS ALAM SHAH - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH SAINS SERI PUTERI - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN KIARAMAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN SERI MUTIARA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN COCHRANE PERKASA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN ALAM DAMAI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN SENTUL UTAMA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH BIMBINGAN JALINAN KASIH - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "KOLEJ TINGKATAN ENAM DESA MAHKOTA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN ORKID DESA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH SENI MALAYSIA KUALA LUMPUR - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN KERAMAT WANGSA - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN TIARA PERMAI - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH SAINS LABUAN - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH MENENGAH KEBANGSAAN LAJAU - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN PERUMAHAN BEDAUN - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH MENENGAH KEBANGSAAN PANTAI - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH MENENGAH KEBANGSAAN RANCHA-RANCHA - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 8(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 9(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 16(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 11(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 9(2) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH SULTAN ALAM SHAH - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 14(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 11(2) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 18(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH MENENGAH KEBANGSAAN PUTRAJAYA PRESINT 5(1) - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH MENENGAH KEBANGSAAN SERI AMPANG - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN PUTERI AMPANG - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN (P) AIR PANAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN SERI BINTANG UTARA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN CHERAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN COCHRANE - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN CONFUCIAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT BUKIT NANAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT JALAN PEEL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN CONVENT SENTUL - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI SETAPAK - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN (P) BANDARAYA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN MAXWELL - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN (L) METHODIST - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN (L) METHODIST SENTUL - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN (P) PUDU - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN ST. GABRIEL - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "ST. JOHN'S INSTITUTION - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN ST. MARY - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN (P) METHODIST - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "VICTORIA INSTITUTION - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN DATOK LOKMAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN (P) JALAN IPOH - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PANTAI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI SENTOSA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN DATO' ONN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN MALURI - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN SERI TITIWANGSA - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN PUTERI TITIWANGSA - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "SEKOLAH MENENGAH KEBANGSAAN VIVEKANANDA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN LA SALLE BRICKFIELDS - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN YAACOB LATIF - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN LA SALLE SENTUL - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN DHARMA - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN CHONG HWA - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN RAJA ABDULLAH - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN JINJANG - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN BANGSAR - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH KEBANGSAAN LABUAN - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH MENENGAH KEBANGSAAN MUTIARA - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH MENENGAH KEBANGSAAN ST ANNE - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SEKOLAH MENENGAH KEBANGSAAN ST ANTHONY - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SMA MAJLIS AGAMA ISLAM WILAYAH PERSEKUTUAN - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SEKOLAH MENENGAH INTEGRASI SAINS TAHFIZ (SMISTA) MAIWP - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SMA MAJLIS AGAMA ISLAM WILAYAH PERSEKUTUAN LABUAN - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "KOLEJ VOKASIONAL ERT SETAPAK - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "KOLEJ VOKASIONAL SETAPAK - WILAYAH PERSEKUTUAN KUALA LUMPUR - KERAMAT",
    "KOLEJ VOKASIONAL LABUAN - WILAYAH PERSEKUTUAN LABUAN - LABUAN",
    "SMT CHERAS - WILAYAH PERSEKUTUAN KUALA LUMPUR - BANGSAR PUDU",
    "SMA KUALA LUMPUR - WILAYAH PERSEKUTUAN KUALA LUMPUR - SENTUL",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA PUTRAJAYA - WILAYAH PERSEKUTUAN PUTRAJAYA - PUTRAJAYA",
    "SEKOLAH KEBANGSAAN AMBUAL - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN APIN-APIN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BANJAR - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BATU LUNGUYAN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN RANCANGAN BIAH - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BINAONG - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BINGKOR - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BONOR - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BULU SILOU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BUNDU APIN-APIN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BUNSIT - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN KAWAKAAN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN DALIT - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN DELAYAN TULID - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN KABATANG BARU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN KALAMPUN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN KAMPONG BARU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN KAMPONG BIAH - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN KG KENINGAU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN KARAMATOI - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN JAYA BARU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN PEKAN KENINGAU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN INANDUNG - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN KG BERIAWA ULU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN KAPAYAN BARU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN LANAS - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN LIAU APIN-APIN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN LUAGAN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN MAGATANG - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN MANSIAT - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN MEMBULU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN MENAWO - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN MERAMPONG - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN MAMAGUN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN PASIR PUTEH - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN POHON BATU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN KUALA KAHABA - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN SENAGANG - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN SINARON TENGAH - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN SODOMON - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN SOOK - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN KEBULU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN TUARID TAUD - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN TULID - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN ULU LIAWAN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN ANSIP - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN MALIMA - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN PENAGATAN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN SINUA - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN MENINIPIR - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN MALAING - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BUNGA RAYA - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN SINULIHAN BARU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN SIMBUAN TULID - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN ULU SENAGANG - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN GAULAN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN PATIKANG LAUT KENINGAU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BINUWOU TENGAH - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN RANCANGAN BELIA TIULON - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BINAKAAN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BUNANG SOOK - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN LINTUHUN BARU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN NANGKAWANGAN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN NANDAGAN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN BINANON - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN PANGAS - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN PEKAN KENINGAU II - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN SIBANGALI - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN LOGONGON - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN PANDIWAN - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN PEKAN PENSIANGAN - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN PENONTOMON - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN SALAROM - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN SALILARAN - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN SEPULOT - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN KUALA SALONG - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN KG BAHAGIA - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN LOTONG - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN PEKAN NABAWAN - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN PEMENTERIAN - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN LABANG - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN ULU MOSOPOH - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN TAMPUSISON - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN KEBU BARU - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN TETAGAS - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN KG ENAM - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN BABALITAN - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN LAYON - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN SALINATAN - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN PENGARAAN - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN SALIKU - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN TINADUK - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN SASANDUKON - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN SIMATUOH - SABAH - PENSIANGAN",
    "SEKOLAH KEBANGSAAN GERAS - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN KAINGARAN - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN KEROKOT - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN KIAWAYAN - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN KUMAWANAN - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN KINABAAN - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN KUALA NAMADAN TAMBUNAN - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN MONSOK TENGAH - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN NUKAKATAN - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN PATAU - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN PEGALAN KUSOB - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN ROMPON - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN BAMBANGAN - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN LOTONG - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN SUNSURON - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN PEKAN TAMBUNAN - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN TIKOLOD - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN TIMBOU - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN KIPAKU - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN TIONG WIDO - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN TINOMPOK LIWAN - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN KINALUAN - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN SINTUONG-TUONG - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN RAGKAM - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN KITOU - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN BAMBAN - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN BATU TININGKANG - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN TILIS - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN KUNGKULAR - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN CHINTA MATA - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN ENTABUAN - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN GUMISI - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN INUBAI - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN KALANG KANAR - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN KG BARU JUMPA - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN KEMABONG - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN KUALA TOMANI - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN LAGUD - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN MANDALOM LAMA - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN MASANOI - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN MANTAILANG - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN MELALAP - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN PANGI - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN PULONG - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN RUNDUM - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN SAGA - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN LADANG SAPONG - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN SUNGAI API - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN PEKAN TENOM - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN ULU TOMANI - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN JAWA SUMBILING - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN PAGUOKON - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN ULU ENTABUAN - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN KAPULU - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN SUMAMBU - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN PANGALAT - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN SIMPANG - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN BAKUKU - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN KOLOROK - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN BAMBANGAN - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SRI TANJUNG PAPAT 1 - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN BONGON BESAR - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN BANDAR - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SUNGAI PADAS - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN RANCANGAN SUAN LAMBA - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN KUALA SUAN LAMBA - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN BATU 16 GUM-GUM - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN GUM-GUM KECIL - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN GAS - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN KARAMUNTING - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SAKILAN DESA - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN PULAU LUBUKAN - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN KAMPUNG BARU - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN MUMIANG - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN RANCANGAN LUBUH - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN NUNUYAN LAUT - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SUNGAI MADANG - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN PULAU LIBARAN - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN PULAU SANGHAI - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN PULAU TIMBANG - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN LADANG SANDAK - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN LABUK SUBUR - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SEGALIUD - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SEMAWANG - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN SIBUGAL BESAR - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN KAMPUNG SUAN LAMBA - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN SUNGAI ANIB 1 - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN KEM TENTERA - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SUNGAI MANILA - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN TAMBISAN - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN TANJONG ARU - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN ULU DUSUN - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN TANJONG PISAU - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SIPINONG - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SUNGAI TIRAM - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN TANJONG BATU - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN BATU PUTIH BARU - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SUNGAI KAYU - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN PAMAGUAN - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN PITAS - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SG ANIB 2 - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SRI TANJUNG PAPAT 2 - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN MUHIBBAH - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN TERUSAN BARU - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN TAMAN RIMBA - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SERI MANIS - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN KG BAHAGIA - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN MERPATI - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN KG PERTANIAN - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN MAWAR - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SRI MELANTA JAYA - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SUNGAI ANIB III - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN TINUSA - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN BAWANG - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN PEKAN BELURAN - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN BERAYUNG - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN GOLONG - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN WONOD - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN JAMBONGAN - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN KAINGARAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KENIOGAN - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN KIABAU - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN KOLAPIS - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN LIDONG - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN LINGKABAU - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN LUBANG BUAYA - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN MALINSAU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN ULU MUANAD - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN PAMOL - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN PERANCANGAN - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN BASAI BARU - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN SIMPANGAN - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN MALALIN - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN SUALOK - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN SUNGAI NANGKA - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN SUNGAI SAPI - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN SUNGAI-SUNGAI - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN TAGAS-TAGAS - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN TANJUNG NIPIS - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN TELUPID - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN TERUSAN SUGUT - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN TETABUAN - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN BUIS - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN KABULUH - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN TAMPAT - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN MAIDAN - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN OBAH - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN KAWIYAN SUGUT - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN BUKIT BESI - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN GABUNGAN - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN BOTITION - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN TANGKUNGON - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN MANGKAPOH - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KARAGASAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN NANGOH - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN BALABAN JAYA - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN ULU SAPI - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN ABUAN - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN PANTAI BORING - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN MOYNOD - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN SERI PAGI - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN PEKAN TELUPID - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN BINTANG MAS - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN JAYA BAKTI - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN PINANGKAU - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN SUNGAI NAFAS - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN TANGKARASON - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN LIMAU-LIMAU - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN BINSULUNG - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN MONOPOD - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN MATANGGAL BELURAN - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN ABAI - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN BALAT - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN BATU PUTEH - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN BILIT - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN SRI GANDA - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN INARAD - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN KERAMUAK - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN KUAMUT - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN LANGKABONG - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN BUKIT GARAM - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN MASAUM - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN MINUSUH - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN PENANGAH - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN MENGKAWAGO - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN SUKAU - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN TAMPASAK - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN MENANAM - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN TUNDUN BOHANGIN - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN SUNGAI LOKAN - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN KENANG-KENANGAN - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN ENTILIBON - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN SANGAU - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN BUANG SAYANG - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN DESA PERMAI - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN SOGO-SOGO - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN PARIS - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN LINAYUKAN - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN MALIAU - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN LADANG SUNGAI BENDERA - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN SAGUON - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN SAPAGAYA - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN JAYA BARU - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN KOTA KINABATANGAN - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN SANDAU - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN KIANDONGO - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN SINAR JAYA - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN LITANG - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN PARIS 3 - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN SENTOSA JAYA - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN TIDUNG/TABIN - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN IMBAK - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN KIRONGGU - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN SINGGAH MANIS - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN ULU ANSUAN - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN KOPURON - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN BUKIT GARAM II - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN BATU 22 BALUNG - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN DESA SUBUR - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN ANDRASSY - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BALUNG - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BATU PAYONG - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BERGOSONG - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BUKIT QUOIN - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BLOK 31 - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN UMAS-UMAS - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN INDERSABAH - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN KALABAKAN - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN KEM KABOTA - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN KINABUTAN BESAR - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN KUALA APAS - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN KUALA MEROTAI - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN MENTADAK BARU - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN MEROTAI BESAR - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN RANGGU - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN SERUDONG BARU - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN SUNGAI HJ MATAHIR - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN SUNGAI IMAM - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN PANGLIMA HABIBULLAH - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN SUNGAI TONGKANG - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN TANJONG BATU - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BANDAR TAWAU - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN WAKUBA - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN KG JAWA - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN MUHIBBAH RAYA - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN PASIR PUTIH - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BATU 4 JALAN APAS - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN KG TITINGAN - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BANDAR TAWAU II - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN TAMAN TAWAU - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN ULU KALABAKAN - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN TG BATU KERAMAT - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN PERDANA - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN MELODI - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN SENTOSA - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BRANTIAN - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BAHAGIA - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN JAMBATAN PUTIH - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN KUKUSAN - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BANGINGOD - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN BIKANG - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN BINUANG - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN TAWAIYARI - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN SAHABAT 4 - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN SABAH COCOA - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN AMALANIA - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN LAHAD DATU II - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN PEKAN - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN BATU 6 1/2 SEGAMA - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN SEPAGAYA - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN TELISAI - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN TANJUNG LABIAN - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN TANJONG PARAS - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN TUNGKU - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN ULU TUNGKU - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN SRI DARUN - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN SILAM - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN BUKIT BALACON - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN LOK BUANI - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN SRI PANTAI - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN SILABUKAN - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN SAHABAT 16 - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN TERUSAN - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN JEROCO - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN LAHAD DATU III - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN SAHABAT II - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN FAJAR HARAPAN - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN CENDERAWASIH - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN PERMAI - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN PAYANG - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN UNICO DESA - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN LAHAD DATU IV - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN TUN FUAD - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN KUNAK I - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN KUNAK 2 - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN LORMALONG - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN MOSTYN - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN PANGI - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN GADING-GADING - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN LADANG GIRAM - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN SKIM KOKOS - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN KUNAK JAYA - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN MADAI - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN TANJUNG KERAMAT - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN KAMPUNG SELAMAT - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN LADANG BINUANG - SABAH - KUNAK",
    "SEKOLAH KEBANGSAAN BALIMBANG - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN BUBUL - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN BUGAYA - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN GELAM-GELAM - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN HAMPALAN - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN KABOGAN - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN PULAU LARAPAN - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN LIHAK-LIHAK - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN MENAMPILIK - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN PEGAGAU - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN PULAU BAIT - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN PULAU BUM-BUM - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN PULAU OMADAL - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN SELAKAN - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN PEKAN SEMPORNA - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN TANJUNG KAPOR - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN KAMPUNG POKAS - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN SISIPAN - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN SULABAYAN - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN SUNGAI TUHOK - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN TAGASAN - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN TAMPI-TAMPI - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN TERUSAN BARU - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN TERUSAN TENGAH - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN PULAU DENAWAN - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN EGANG-EGANG - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN KAMPUNG SIMUNUL - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN PULAU PABABAG - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN KUBANG PINANG - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN KALUMPANG - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN PULAU MABUL - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN BANGAU-BANGAU - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN LABUAN HAJI - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN PULAU SUMANDI - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN TONGKALLOH - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN PEKAN SEMPORNA II - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN BUBUL II - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN BUKIT LALANG - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN GANTISAN - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN BABAGON TOKI - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN BANTAYAN - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN DARAU - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN GUDON MENGGATAL - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN PENGIRAN SITI HAFSAH KARAMBUNAI - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN KEBAGU - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN KEBAYAU TELIPOK - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN KEPAYAN - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN KIONSOM INANAM - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN KERONGGU - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN KITOBU - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN KOKOL - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN KOLOMBONG KOTA KINABALU - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN LIKAS - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN MALAWA - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN NATAI TELIPOK - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN PORING-PORING - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN PULAU GAYA - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN RAMPAYAN MENGGATAL - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN RUMINDING - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN PULAU SEPANGGAR - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN SEMBULAN - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN TALUNGAN TELIPOK - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN TAMPULAN - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN TANJUNG ARU 1 - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN TOMBONGON - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN UNGGUN MENGGATAL - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN POMOTODON - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN PANGKALAN TLDM KOTA KINABALU - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN INANAM LAUT - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN BUKIT PADANG - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN TOBOBON - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN LAPASAN - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN SRI GAYA - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN TANJUNG ARU II - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN INANAM II - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN MUTIARA KOTA KINABALU - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN LUYANG KOTA KINABALU - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN BABAGON - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN BUAYAN - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN BUIT HILL - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN KIPOVO - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN KEM LOK KAWI - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN LONGKOGUNGON - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN MOYOG - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN TANSAU - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN PETAGAS - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN PUTATON INOBONG - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN PUUN TUNOH - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN SUGUD - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN TERIAN - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN TOMBOVO - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN TAMPASAK TOGUDON - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN PEKAN PUTATAN - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN BAHANG - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN KIBABAIG - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN KG CONTOH - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN PENAMPANG - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN BELATIK - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN BENONI - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN BERINGIS - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN DAINGIN - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN GANA - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN KAIDUAN - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN KELATUAN - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN KAWANG - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN KAYAU - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN KELANAHAN - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN KAMBIZAAN - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN PEKAN KINARUT - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN KOGOPON - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN KUALA PAPAR - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN LANGKAWIT - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN LIMPUTONG - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN LINGAN - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN MANDALIPAU - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN MOOK - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN PADAWAN BESAR - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN PEKAN PAPAR - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN PENGALAT BESAR - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN PENGALAT KECIL - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN RAMPAZAN - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN SABANDIL - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN TAMPASAK - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN TANAKI - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN BUANG SAYANG - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN PANTAI MANIS PAPAR - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN SURATI - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN PEKAN KIMANIS - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN BERUNGIS TUARAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN BOLONG - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN BUNDUNG - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN BUNGALIO - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN BARU-BARU - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN SUNGAI DAMIT - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN GAYANG - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN KAULUAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN KINDU - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN PEKAN KIULU - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN LOKUB - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KOTA KINABALU - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN MALANGANG BARU - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN MANTOB - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN MENGKABONG - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN NONGKOLUD - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN PAHU - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN PENIMBAWAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN PORING - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN PUKAK - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN RANGALAU BARU - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN RANGALAU LAMA - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN SELUPOH - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN SERUSUP - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN TAMBALANG - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN TAMBULAONG - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN PEKAN TELIPOK - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN TERMUNONG - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN PEKAN TUARAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN TUDAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN WASAI - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN RUGADING - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN KITAPOL - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN LAYA-LAYA - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS TUARAN - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN SUNGOI - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN BANTAYAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN BAWANG - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN BONGOL - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN BUNDUTAHURI - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN GONTUNG - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN GAYARATAU - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN GIOK - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN KAYANGAT - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN KELAWAT - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN LAPUTONG - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN LINUNGKUAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN LOKOS TAMPARULI - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN RUNGUS - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN RUNGUS NAHABA - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN SARADAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN SAMBAH - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN SINULIHAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN TAGINAMBUR - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN PEKAN TAMPARULI - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN PEKAN TENGHILAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN TIONG PERUNGUSAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN TOGOP - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN TOMIS - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN TOPOKON - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN WANGKOD - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN GUAKON BARU - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN LINGGA BARU - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN KAPA - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN TINAMBAK - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN KOPORINGAN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN API API - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN BALAMBANGAN BANGGI - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LAKSIAN - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN DOGOTON - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN BANGAU KUDAT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN BARAMBANGON - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN PEKAN KUDAT II - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN PATA - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN INDARASON LAUT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN GUMANDANG - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN SEMAYAN BANGGI - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN KG MINYAK - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN KAPITANGAN - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN KARAKIT BANGGI - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN BATU LAYAR - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN TANJUNG MANAWALI - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN TUN DATU HJ MUSTAPHA - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN MUHIBBAH - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LIMAU-LIMAUAN - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LIMBUAK - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LOKOTON - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN MATUNGGONG - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN NANGKA KUDAT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN PADANG - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN PERAPAT LAUT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN PINAWANTAI KUDAT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN SEBAYAN - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN SIKUATI - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN SUANGPAI - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN PALAK - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN TERONGKONGAN - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN TIGA PAPAN KUDAT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LOKTOHOG BANGGI - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN DUALOG - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN PARAPAT DARAT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LAMPAKI KUDAT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LOTONG - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN PANUDAHAN - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN GARAU KUDAT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN SABUR - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN BINGOLON KUDAT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN PULAU TIGABU - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN DAMPIRIT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LANDUNG AYANG - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN PEKAN KOTA MARUDU - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN BENGKONGAN - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN LANGKON - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN MARAK-PARAK - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN MASALOG - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN POPOK - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN SAMPIR - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN SAMPARITA - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN SIMPANGAN - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN TAGAROH - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN TANDEK - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN TANJUNG BATU - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN TARITIPAN - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN TIGAMAN - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN TIMBANG BATU - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN SUNSUI - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN ONGKILAN - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN TEMUNO TERINGAI DARAT - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN MELANGKAP - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN TAGIBANG - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN MANGARIS - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN PANAITAN - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN TUMUNDA SALIMANDUT - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN TALANTANG - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN MANGIN - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN LAMPADA - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN KOROMOKO - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN GANA - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN RANAU - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN BINTASAN - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN KOTA MARUDU II - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN BAWING - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN DANDUN - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN KANIBONGAN - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN KUSILAD - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN LIU - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN MAPAN-MAPAN - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN MALUBANG - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN PANTAI - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN PINGGAN-PINGGAN - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN PEKAN PITAS - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN ROSOB - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN TELAGA - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN PANDAN MANDAMAI - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN PINAPAK - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN SENAJA - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN SALIMPODON DARAT - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN MANGKAPON PITAS - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN BONGKOL PITAS - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN BAWANG PITAS - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN KASAGAAN - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN MARINGGAN PITAS - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN MANGGIS/MONGKUBOU - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN MANDURIAN - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN KIBUBUK / SAHABAT SATU - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN NIBANG - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN DALLAS PITAS - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN RUKOM - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN MAGANDAI - SABAH - KOTA MARUDU",
    "SEKOLAH KEBANGSAAN DATONG - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN BANGKAU-BANGKAU - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN SOSOP - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN PEKAN PITAS II - SABAH - PITAS",
    "SEKOLAH KEBANGSAAN AMBONG - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN ROSOK/BENGKAHAK LAMA - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN BANGKAHAK BARU - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN DALAS KOTA BELUD - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN DUDAR - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN GENSURAI - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN KEGURAAN - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN LABUAN - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN KAUNG - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN KEBAYAU - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN KELAWAT - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN KESAPANG - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN KIAU I - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN KINASARABAN KOTA BELUD - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN PEKAN KOTA BELUD - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN KULAMBAI - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN LASAU PODI - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN MELANGKAP KBELUD - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN MENGKULAT - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN NAHABA - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN NANAMUN - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN NARINANG - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN PANDASAN - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN PELADOK - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN PIASAU - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN PITURU - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN PULAU MANTANANI - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN JAWI-JAWI - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN RANGALAU - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN SAYAP - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN SEMBIRAI - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN SUANG PUNGGOR - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TABURAN - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TAMAU - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TAMBATUAN - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TAMBULIAN - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TAMPASUK I - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TAMU DARAT - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TENGKURUS - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TARINTIDON - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TAUN GUSI - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TIMBANG - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TIMBANG DAYANG - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TUGUSON - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TAMPASUK II - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN SARANG - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN RAMPAYAN ULU - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN LASAU TINTAPON - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN KUALA ABAI - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN PODOS - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN PANGKALAN ABAI - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN MENUNGGUI - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN PINOLOBU - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN ULU KUKUT - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN BADUKAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN BONGKUD - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN BUNDU TUHAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN GANA-GANA - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KAMPONG LIBANG - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KEMBURONGOH - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KANANAPON - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KERANAAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KIMONDOU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KITUNTUL - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KUNDASANG - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN LONGUT - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN LANGSAT - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN LIPASU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN LOHAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN MATUPANG - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN MAUKAB - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN MARAKAU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN MIRURU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN MOHIMBOYON - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN NALAPAK - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN NAMPASAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN NAPONG - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN NARAWANG - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN PAGINATAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN PAUS - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN PERANCANGAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN PINAUSUK - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN PINAWANTAI RANAU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN PORING - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN PEKAN RANAU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN RANDAGONG - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN RATAU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN SAGINDAI - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN TAGUDON LAMA - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN TAMPIOS - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN TIMBUA - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN TINANOM - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN TOBOH - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN TOGOP DARAT - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN TONGOU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN TUDAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN WAANG - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KINIRASAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KIROKOT - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN NUNUK RAGANG - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN TIBABAR - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KANDAWAYON - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN TIANG - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN PAHU HIMBAAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KEPANGIAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KAULUAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN SAGIBAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN NARADAN (NAWANON) - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN PEKAN DUA - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KINAPULIDAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN TARAWAS - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KILIMU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN KINASARABAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN NUKAKATAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN SRI GABUNGAN - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN GUSI - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN MESILOU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN BANGKALALAK - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN BATANDOK - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN BENTUKA - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN BUKAU - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN GADONG - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN GARAMA - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN KABAJANG - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN KEBATU - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN KEBULU - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN BIAH BATU 65 - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN KEPAWA - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN KOTA KLIAS - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN KLIAS BARU - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN KLIAS KECIL - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN KUKURO - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN LAGO - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN LINGKUNGAN - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN LUAGAN - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN LUBOK - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN LUMAT - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN LADANG-LUMADAN - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN LUPAK - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN MARABA - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN MEMPAGAR - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN BATU 60 - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN NUKAHAN - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN PADAS DAMIT - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN RANCANGAN KLIAS - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN SUASA - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN TAKULI - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN WESTON - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN PEKAN BEAUFORT - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN LAJAU - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN KARANGAN - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN JABANG - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN PINTAS - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN TIONG BARU - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN BINSULOK - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN KG BAMBANGAN - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN KG BRUNEI - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN LEMBAH PORING - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN PEKAN MEMBAKUT - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN SINOKO - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN TAHAK - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN TAMALANG - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN PENGIRAN JAYA PIMPING - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN PEKAN BONGAWAN - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN SUMBILING - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN VIGING ULU - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN ULU LUMAGAR - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN KIMANIS - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN BANDAU - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN MANDAHAN - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN SAGA-SAGA - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN NYARIS-NYARIS - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN BANTING - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN KEBAWANG - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN KAWANG - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN KUALA MENGALONG - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN LONG PASIA - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN LUBANG BUAYA - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN LUBOK DARAT - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN MELAMAM - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN MENDULONG - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN MELALIA - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN MELIGAN - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN MERINTAMAN - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN MESAPOL - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN PANTAI - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN PELAKAT - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN SINDUMIN - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN PEKAN SIPITANG - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN ULU SIPITANG - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN PADANG BERAMPAH - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN SOLOB - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN ULU BOLE - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN KELANGSAT - SABAH - SIPITANG",
    "SEKOLAH KEBANGSAAN BATU LINTING - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN BERANGKOK - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN JANANG - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN KERUKAN - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN KILUGUS - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN LAMBIDAN - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN PEKAN KUALA PENYU - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN MANSUD - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN MELIKAI - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN PEKAN MENUMBOK - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN MENUMPANG - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN MENUNGGANG - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN PALU-PALU - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN SINAPOKAN - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN TANJUNG ARU - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN TEMPURONG - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN TENAMBAK - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN SANGKABOK - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN KEKAPOR - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN RIMBAAN - SABAH - KUALA PENYU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG MING - SABAH - KENINGAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG MIN - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN (CINA) ANGLO-CHINESE - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN ST.FRANCIS XAVIER KENINGAU - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN ST JAMES APIN-APIN - SABAH - KENINGAU",
    "SEKOLAH KEBANGSAAN ST DAVID TOBOH - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN NAMBAYAN - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN ST THERESA - SABAH - TAMBUNAN",
    "SEKOLAH KEBANGSAAN ST ANTHONY - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN ST PIUS PAMILAAN - SABAH - TENOM",
    "SEKOLAH KEBANGSAAN PASIR PUTIH - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN LUNG MANIS - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN ST MARY CONVENT - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN ST MARY TOWN - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN ST MARY LABUK - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN LADANG TOMANGGONG - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN ST MONICA - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN SUNG SIEW - SABAH - SANDAKAN",
    "SEKOLAH KEBANGSAAN HOLY CROSS - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN LADANG SABAPALM - SABAH - BELURAN",
    "SEKOLAH KEBANGSAAN LADANG BODE KRETAM - SABAH - KINABATANGAN",
    "SEKOLAH KEBANGSAAN PEKAN TONGOD - SABAH - TELUPID/TONGOD",
    "SEKOLAH KEBANGSAAN ABAKA - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BOMBALAI - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BRUMAS - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN MELATI - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN HOLY TRINITY - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN LUASONG - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN MEROTAI KECIL - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN ST PATRICK - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN LADANG TIGER - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN WALLACE BAY - SABAH - TAWAU",
    "SEKOLAH RENDAH ISLAMIAH - SABAH - TAWAU",
    "SEKOLAH RENDAH AGAMA KINABUTAN - SABAH - TAWAU",
    "SEKOLAH KEBANGSAAN BAKAPIT - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN KENNEDY BAY - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN ST DOMINIC - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN ST STEPHENS - SABAH - LAHAD DATU",
    "SEKOLAH KEBANGSAAN LADANG SEGARIA - SABAH - SEMPORNA",
    "SEKOLAH KEBANGSAAN LOK YUK INANAM - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN LOK YUK LIKAS - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN SACRED HEART - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN ST AGNES - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN ST CATHERINE - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN ST FRANCIS CONVENT - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN STELLA MARIS - SABAH - KOTA KINABALU",
    "SEKOLAH KEBANGSAAN ST ALOYSIUS LIMBANAK - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN ST ANTHONY - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN ST JOSEPH - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN ST PAUL KOLOPIS - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN ST THERESA INOBONG - SABAH - PENAMPANG",
    "SEKOLAH KEBANGSAAN OUR LADY - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN SACRED HEART KG BIAU - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN ST JOSEPH - SABAH - PAPAR",
    "SEKOLAH KEBANGSAAN ST MARY - SABAH - PAPAR",
    "SEKOLAH AGAMA SURIA - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN LOK YUK TELIPOK - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN ST JOHN - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN TOMBONGON - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN ST JAMES TENGHILAN TAMPARULI - SABAH - TUARAN",
    "SEKOLAH KEBANGSAAN LAJONG - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LOK YUK BATU 1 KUDAT. - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LODUNG KUDAT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LOK YUK TAMALANG KUDAT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN LOK YUK SIKUATI - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN ST JAMES - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN ST PETER KUDAT - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN TINANGOL - SABAH - KUDAT",
    "SEKOLAH KEBANGSAAN ST EDMUND - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN TAGINAMBUR - SABAH - KOTA BELUD",
    "SEKOLAH KEBANGSAAN ST BENEDICT - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN DON BOSCO - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN ST.JOHN - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN ST PAUL - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN ST PATRICK - SABAH - BEAUFORT",
    "SEKOLAH KEBANGSAAN ST. AUGUSTINE - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN ST JOSEPH - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN ST. PETER BUNDU - SABAH - KUALA PENYU",
    "SEKOLAH KEBANGSAAN ST. STEPHEN - SABAH - KUALA PENYU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK KONG - SABAH - KENINGAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK YIN - SABAH - KENINGAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUH MIN - SABAH - TAMBUNAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI VUN ENTABUAN - SABAH - TENOM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HWA - SABAH - TENOM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - SABAH - TENOM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KONG MIN - SABAH - TENOM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PADA - SABAH - TENOM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHUI YING - SABAH - TENOM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) VUN SHIN - SABAH - TENOM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK NAM - SABAH - TENOM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK SYN - SABAH - TENOM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TSI SIN - SABAH - TENOM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHI HWA - SABAH - SANDAKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YUK - SABAH - SANDAKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MING CHUNG - SABAH - SANDAKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEA WHA - SABAH - SANDAKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI YING - SABAH - SANDAKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PUI GIN - SABAH - SANDAKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SYN HUA - SABAH - SANDAKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAI TONG - SABAH - SANDAKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHOI - SABAH - SANDAKAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - SABAH - TAWAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HING HWA - SABAH - TAWAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG MING - SABAH - TAWAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUOK MING - SABAH - TAWAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PHUI YUK - SABAH - TAWAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN HWA - SABAH - TAWAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHIN - SABAH - TAWAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE VUN - SABAH - LAHAD DATU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KIAU SHING - SABAH - LAHAD DATU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIEW CHING - SABAH - LAHAD DATU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN WAH - SABAH - LAHAD DATU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHOI - SABAH - LAHAD DATU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAI SHENG - SABAH - KUNAK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NYUK HWA - SABAH - SEMPORNA",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA LIKAS - SABAH - KOTA KINABALU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA KG AIR K KINABALU - SABAH - KOTA KINABALU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) GOOD SHEPHERD MENGGATAL - SABAH - KOTA KINABALU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YUK LIKAS - SABAH - KOTA KINABALU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YUK MENGGATAL - SABAH - KOTA KINABALU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SHAN TAO - SABAH - KOTA KINABALU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST JAMES KOTA KINABALU - SABAH - KOTA KINABALU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST PETER TELIPOK - SABAH - KOTA KINABALU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YICK NAM - SABAH - KOTA KINABALU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA SHIONG - SABAH - PENAMPANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUE MIN - SABAH - PENAMPANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ANGLO CHINESE - SABAH - PAPAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG HWA - SABAH - PAPAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG MING - SABAH - PAPAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA YIN RAMPAZAN - SABAH - PAPAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KIN KIAU - SABAH - PAPAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SEN MING - SABAH - PAPAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST JOSEPH - SABAH - PAPAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG SHAN - SABAH - PAPAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEN SIN - SABAH - TUARAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KOK WAH TALIBONG - SABAH - TUARAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST PHILLIP - SABAH - TUARAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA TAMPARULI - SABAH - TUARAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA TENGHILAN - SABAH - TUARAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HWA LIAN - SABAH - KUDAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YUK PINANGSOO KUDAT - SABAH - KUDAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LOK YUK BATU 1 KUDAT - SABAH - KUDAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) OUR LADY IMMACULATE KUDAT - SABAH - KUDAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SACRED HEART TAJAU KUDAT - SABAH - KUDAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST PETER - SABAH - KUDAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HWA TEMALANG - SABAH - KUDAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KHOI MING - SABAH - KOTA MARUDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - SABAH - KOTA BELUD",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PAI WEN - SABAH - RANAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHE HWA - SABAH - KOTA KINABALU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG MING (1) - SABAH - BEAUFORT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LIAN HWA - SABAH - BEAUFORT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PEI YIN - SABAH - BEAUFORT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BONG HWA - SABAH - PAPAR",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA SIPITANG - SABAH - SIPITANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA MESAPOL - SABAH - SIPITANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIN BOON SINDUMIN - SABAH - SIPITANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA - SABAH - KUALA PENYU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PUI HWA - SABAH - KUALA PENYU",
    "SEKOLAH AGAMA TUN SAKARAN - SABAH - SEMPORNA",
    "SEKOLAH MENENGAH KEBANGSAAN TULID - SABAH - KENINGAU",
    "SEKOLAH MENENGAH KEBANGSAAN APIN-APIN - SABAH - KENINGAU",
    "SEKOLAH MENENGAH KEBANGSAAN KENINGAU - SABAH - KENINGAU",
    "SEKOLAH MENENGAH KEBANGSAAN KENINGAU II - SABAH - KENINGAU",
    "SEKOLAH MENENGAH KEBANGSAAN SOOK - SABAH - KENINGAU",
    "SEKOLAH MENENGAH KEBANGSAAN GUNSANAD II - SABAH - KENINGAU",
    "SEKOLAH MENENGAH KEBANGSAAN NABAWAN - SABAH - PENSIANGAN",
    "SEKOLAH MENENGAH KEBANGSAAN SEPULOT NABAWAN - SABAH - PENSIANGAN",
    "SEKOLAH MENENGAH KEBANGSAAN NABAWAN II - SABAH - PENSIANGAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMBUNAN - SABAH - TAMBUNAN",
    "SEKOLAH MENENGAH KEBANGSAAN NAMBAYAN - SABAH - TAMBUNAN",
    "SEKOLAH MENENGAH KEBANGSAAN DESA WAWASAN - SABAH - TAMBUNAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHINTA MATA - SABAH - TENOM",
    "SEKOLAH MENENGAH KEBANGSAAN KEMABONG - SABAH - TENOM",
    "SEKOLAH MENENGAH KEBANGSAAN ENTABUAN - SABAH - TENOM",
    "SEKOLAH MENENGAH KEBANGSAAN ELOPURA - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN BATU SAPI - SABAH - SANDAKAN",
    "KOLEJ TINGKATAN ENAM DATUK PENGIRAN GALPAM - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN GUM-GUM - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN MUHIBBAH - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN FAJAR - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN TONGOD - SABAH - TELUPID/TONGOD",
    "SEKOLAH MENENGAH KEBANGSAAN PARIS - SABAH - KINABATANGAN",
    "SEKOLAH MENENGAH KEBANGSAAN MERPATI - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN SEGALIUD - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN LIBARAN - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN ELOPURA DUA - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN SANDAKAN DUA - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN TINUSA - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN ULU SUGUT (MALINSAU) - SABAH - RANAU",
    "SEKOLAH MENENGAH KEBANGSAAN TELUPID - SABAH - TELUPID/TONGOD",
    "SEKOLAH MENENGAH KEBANGSAAN TERUSAN SUGUT - SABAH - BELURAN",
    "SEKOLAH MENENGAH KEBANGSAAN PAMOL - SABAH - BELURAN",
    "SEKOLAH MENENGAH KEBANGSAAN SIMPANGAN - SABAH - BELURAN",
    "SEKOLAH MENENGAH KEBANGSAAN BALABAN JAYA - SABAH - BELURAN",
    "SEKOLAH MENENGAH KEBANGSAAN ULU SAPI - SABAH - TELUPID/TONGOD",
    "SEKOLAH MENENGAH KEBANGSAAN BELURAN II - SABAH - BELURAN",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT GARAM - SABAH - KINABATANGAN",
    "SEKOLAH MENENGAH KEBANGSAAN PENANGAH - SABAH - TELUPID/TONGOD",
    "SEKOLAH MENENGAH KEBANGSAAN SUKAU - SABAH - KINABATANGAN",
    "SEKOLAH MENENGAH KEBANGSAAN ENTILIBON - SABAH - TELUPID/TONGOD",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT GARAM II - SABAH - KINABATANGAN",
    "SEKOLAH MENENGAH KEBANGSAAN BALUNG - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN KINABUTAN - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN MEROTAI BESAR - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN APAS - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN UMAS-UMAS - SABAH - TAWAU",
    "KOLEJ TINGKATAN ENAM TAWAU - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN KALABAKAN - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN PASIR PUTIH - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN TITINGAN - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN WAKUBA - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN TAWAU II - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN JAMBATAN PUTIH - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN KINABUTAN 2 - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN TUNGKU - SABAH - LAHAD DATU",
    "SEKOLAH MENENGAH SAINS LAHAD DATU - SABAH - LAHAD DATU",
    "SEKOLAH MENENGAH KEBANGSAAN SILABUKAN - SABAH - LAHAD DATU",
    "SEKOLAH MENENGAH KEBANGSAAN SEPAGAYA - SABAH - LAHAD DATU",
    "SEKOLAH MENENGAH KEBANGSAAN DESA KENCANA - SABAH - LAHAD DATU",
    "SEKOLAH MENENGAH KEBANGSAAN SEGAMA - SABAH - LAHAD DATU",
    "SEKOLAH MENENGAH KEBANGSAAN TERUSAN - SABAH - LAHAD DATU",
    "SEKOLAH MENENGAH KEBANGSAAN KUNAK JAYA - SABAH - KUNAK",
    "SEKOLAH MENENGAH KEBANGSAAN MADAI - SABAH - KUNAK",
    "SEKOLAH MENENGAH KEBANGSAAN BUGAYA - SABAH - SEMPORNA",
    "SEKOLAH MENENGAH KEBANGSAAN BUM-BUM - SABAH - SEMPORNA",
    "SEKOLAH MENENGAH KEBANGSAAN TAGASAN - SABAH - SEMPORNA",
    "SEKOLAH MENENGAH KEBANGSAAN KABOGAN - SABAH - SEMPORNA",
    "SEKOLAH MENENGAH KEBANGSAAN KABOGAN II - SABAH - SEMPORNA",
    "SEKOLAH MENENGAH KEBANGSAAN BUGAYA II - SABAH - SEMPORNA",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK HAJI PANGLIMA JAKARULLAH - SABAH - SEMPORNA",
    "SEKOLAH MENENGAH SAINS SABAH - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN SANZAC - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN INANAM - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN PULAU GAYA - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN PEREMPUAN - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN TUN FUAD - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN KOLOMBONG - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN TEBOBON - SABAH - KOTA KINABALU",
    "SEKOLAH SUKAN MALAYSIA SABAH - SABAH - KOTA KINABALU",
    "KOLEJ TINGKATAN ENAM KOTA KINABALU - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK PETER MOJUNTIN - SABAH - PENAMPANG",
    "SEKOLAH MENENGAH KEBANGSAAN BAHANG - SABAH - PENAMPANG",
    "SEKOLAH MENENGAH KEBANGSAAN TANSAU - SABAH - PENAMPANG",
    "SEKOLAH MENENGAH KEBANGSAAN LIMBANAK - SABAH - PENAMPANG",
    "SEKOLAH MENENGAH KEBANGSAAN KINARUT - SABAH - PAPAR",
    "SEKOLAH MENENGAH KEBANGSAAN BENONI - SABAH - PAPAR",
    "SEKOLAH MENENGAH KEBANGSAAN TAKIS - SABAH - PAPAR",
    "SEKOLAH MENENGAH KEBANGSAAN KIMANIS - SABAH - PAPAR",
    "SEKOLAH MENENGAH KEBANGSAAN PENGALAT PAPAR - SABAH - PAPAR",
    "SEKOLAH MENENGAH KEBANGSAAN PEKAN TELIPOK - SABAH - TUARAN",
    "SEKOLAH MENENGAH KEBANGSAAN SRI NANGKA - SABAH - TUARAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN RIA - SABAH - TUARAN",
    "SEKOLAH MENENGAH KEBANGSAAN TENGHILAN - SABAH - TUARAN",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI DAMIT - SABAH - TUARAN",
    "SEKOLAH MENENGAH KEBANGSAAN KUDAT - SABAH - KUDAT",
    "SEKOLAH MENENGAH KEBANGSAAN SIKUATI - SABAH - KUDAT",
    "SEKOLAH MENENGAH KEBANGSAAN BANGGI - SABAH - KUDAT",
    "SEKOLAH MENENGAH KEBANGSAAN PINAWANTAI - SABAH - KUDAT",
    "SEKOLAH MENENGAH KEBANGSAAN KUDAT II - SABAH - KUDAT",
    "SEKOLAH MENENGAH KEBANGSAAN MATUNGGONG - SABAH - KUDAT",
    "SEKOLAH MENENGAH KEBANGSAAN SIKUATI II - SABAH - KUDAT",
    "SEKOLAH MENENGAH KEBANGSAAN ABD. RAHIM II - SABAH - KUDAT",
    "SEKOLAH MENENGAH KEBANGSAAN TANDEK - SABAH - KOTA MARUDU",
    "SEKOLAH MENENGAH KEBANGSAAN BENGKONGAN - SABAH - KOTA MARUDU",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAU - SABAH - KOTA MARUDU",
    "SEKOLAH MENENGAH KEBANGSAAN LANGKON - SABAH - KOTA MARUDU",
    "SEKOLAH MENENGAH KEBANGSAAN KOTA MARUDU II - SABAH - KOTA MARUDU",
    "SEKOLAH MENENGAH KEBANGSAAN TANDEK 2 - SABAH - KOTA MARUDU",
    "SEKOLAH MENENGAH KEBANGSAAN PITAS - SABAH - PITAS",
    "SEKOLAH MENENGAH KEBANGSAAN PINGGAN-PINGGAN - SABAH - PITAS",
    "SEKOLAH MENENGAH KEBANGSAAN KANIBUNGAN - SABAH - PITAS",
    "SEKOLAH MENENGAH KEBANGSAAN TELAGA - SABAH - PITAS",
    "SEKOLAH MENENGAH KEBANGSAAN BONGKOL - SABAH - PITAS",
    "SEKOLAH MENENGAH KEBANGSAAN PITAS II - SABAH - PITAS",
    "SEKOLAH MENENGAH KEBANGSAAN NARINANG - SABAH - KOTA BELUD",
    "SEKOLAH MENENGAH KEBANGSAAN TAUN GUSI - SABAH - KOTA BELUD",
    "SEKOLAH MENENGAH KEBANGSAAN USUKAN - SABAH - KOTA BELUD",
    "SEKOLAH MENENGAH KEBANGSAAN PEKAN KOTA BELUD - SABAH - KOTA BELUD",
    "SEKOLAH MENENGAH KEBANGSAAN TAMBULION - SABAH - KOTA BELUD",
    "SEKOLAH MENENGAH KEBANGSAAN PEKAN II KOTA BELUD - SABAH - KOTA BELUD",
    "SEKOLAH MENENGAH KEBANGSAAN TAUN GUSI II - SABAH - KOTA BELUD",
    "SEKOLAH MENENGAH KEBANGSAAN TIMBUA - SABAH - RANAU",
    "SEKOLAH MENENGAH KEBANGSAAN BUNDU TUHAN - SABAH - RANAU",
    "SEKOLAH MENENGAH KEBANGSAAN KUNDASANG - SABAH - RANAU",
    "SEKOLAH MENENGAH KEBANGSAAN LOHAN - SABAH - RANAU",
    "SEKOLAH MENENGAH KEBANGSAAN MATUPANG JAYA - SABAH - RANAU",
    "SEKOLAH MENENGAH KEBANGSAAN KEMBURONGOH RANAU - SABAH - RANAU",
    "SEKOLAH MENENGAH KEBANGSAAN RANAU - SABAH - RANAU",
    "SEKOLAH MENENGAH KEBANGSAAN BEAUFORT - SABAH - BEAUFORT",
    "SEKOLAH MENENGAH KEBANGSAAN BEAUFORT II - SABAH - BEAUFORT",
    "SEKOLAH MENENGAH KEBANGSAAN BEAUFORT III - SABAH - BEAUFORT",
    "SEKOLAH MENENGAH KEBANGSAAN MEMBAKUT II - SABAH - BEAUFORT",
    "SEKOLAH MENENGAH KEBANGSAAN PADANG BERAMPAH - SABAH - SIPITANG",
    "SEKOLAH MENENGAH KEBANGSAAN PENGIRAN OMAR II - SABAH - SIPITANG",
    "SEKOLAH MENENGAH KEBANGSAAN SINDUMIN - SABAH - SIPITANG",
    "SEKOLAH MENENGAH KEBANGSAAN MENUMBOK - SABAH - KUALA PENYU",
    "SEKOLAH MENENGAH KEBANGSAAN PEKAN KUALA PENYU - SABAH - KUALA PENYU",
    "SEKOLAH MENENGAH KEBANGSAAN BINGKOR - SABAH - KENINGAU",
    "SEKOLAH MENENGAH KEBANGSAAN GUNSANAD - SABAH - KENINGAU",
    "SEKOLAH MENENGAH KEBANGSAAN TENOM - SABAH - TENOM",
    "SEKOLAH MENENGAH KEBANGSAAN SANDAKAN - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN BELURAN - SABAH - BELURAN",
    "SEKOLAH MENENGAH KEBANGSAAN KUHARA - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN TAWAU - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN AGASEH - SABAH - LAHAD DATU",
    "SEKOLAH MENENGAH KEBANGSAAN KUNAK - SABAH - KUNAK",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK PANGLIMA ABDULLAH - SABAH - SEMPORNA",
    "SEKOLAH MENENGAH KEBANGSAAN LIKAS - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN BANDARAYA KOTA KINABALU - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH MAKTAB SABAH - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN PUTATAN - SABAH - PENAMPANG",
    "SEKOLAH MENENGAH KEBANGSAAN MAJAKIR - SABAH - PAPAR",
    "SEKOLAH MENENGAH KEBANGSAAN TUN FUAD STEPHENS - SABAH - TUARAN",
    "SEKOLAH MENENGAH KEBANGSAAN BADIN - SABAH - TUARAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAMPARULI - SABAH - TUARAN",
    "SEKOLAH MENENGAH KEBANGSAAN ABDUL RAHIM - SABAH - KUDAT",
    "SEKOLAH MENENGAH KEBANGSAAN KOTA MARUDU - SABAH - KOTA MARUDU",
    "SEKOLAH MENENGAH KEBANGSAAN ARSHAD - SABAH - KOTA BELUD",
    "SEKOLAH MENENGAH KEBANGSAAN MAT SALLEH RANAU - SABAH - RANAU",
    "SEKOLAH MENENGAH KEBANGSAAN GADONG - SABAH - BEAUFORT",
    "SEKOLAH MENENGAH KEBANGSAAN KOTA KLIAS - SABAH - BEAUFORT",
    "SEKOLAH MENENGAH KEBANGSAAN WESTON - SABAH - BEAUFORT",
    "SEKOLAH MENENGAH KEBANGSAAN MEMBAKUT - SABAH - BEAUFORT",
    "SEKOLAH MENENGAH KEBANGSAAN BONGAWAN - SABAH - PAPAR",
    "SEKOLAH MENENGAH KEBANGSAAN PENGIRAN OMAR - SABAH - SIPITANG",
    "SEKOLAH MENENGAH KEBANGSAAN KUALA PENYU - SABAH - KUALA PENYU",
    "SEKOLAH MENENGAH KEBANGSAAN KEN HWA - SABAH - KENINGAU",
    "SEKOLAH MENENGAH KEBANGSAAN ST. FRANCIS XAVIER - SABAH - KENINGAU",
    "SEKOLAH MENENGAH KEBANGSAAN ST MARTIN - SABAH - TAMBUNAN",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG HWA - SABAH - TENOM",
    "SEKOLAH MENENGAH KEBANGSAAN ST ANTHONY - SABAH - TENOM",
    "SEKOLAH MENENGAH KEBANGSAAN TIONG HUA - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN KONVEN ST CECILIA - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN ST MARY SANDAKAN - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN ST MICHAEL - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN SUNG SIEW - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN ABAKA - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN HOLY TRINITY - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN ST PATRICK - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN ST URSULA - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN WALLACE BAY - SABAH - TAWAU",
    "SEKOLAH MENENGAH KEBANGSAAN ST DOMINIC - SABAH - LAHAD DATU",
    "SEKOLAH MENENGAH KEBANGSAAN ALL SAINTS - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI KOTA KINABALU - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN LA SALLE TG ARU - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN LOK YUK LIKAS - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN SHAN TAO - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN ST FRANCIS CONVENT - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN ST PETER TELIPOK - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN STELLA MARIS - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN ST MICHAEL - SABAH - PENAMPANG",
    "SEKOLAH MENENGAH KEBANGSAAN ST JOSEPH - SABAH - PAPAR",
    "SEKOLAH MENENGAH KEBANGSAAN ST MARY PAPAR - SABAH - PAPAR",
    "SEKOLAH MENENGAH KEBANGSAAN ST JOHN - SABAH - TUARAN",
    "SEKOLAH MENENGAH KEBANGSAAN ST JAMES TENGHILAN - SABAH - TUARAN",
    "SEKOLAH MENENGAH KEBANGSAAN LOK YUK KUDAT - SABAH - KUDAT",
    "SEKOLAH MENENGAH KEBANGSAAN ST PETER KUDAT - SABAH - KUDAT",
    "SEKOLAH MENENGAH KEBANGSAAN ST JOHN - SABAH - BEAUFORT",
    "SEKOLAH MENENGAH KEBANGSAAN SAINT PAUL - SABAH - BEAUFORT",
    "SEKOLAH MENENGAH KEBANGSAAN ST PATRICK - SABAH - BEAUFORT",
    "SEKOLAH MENENGAH KEBANGSAAN ST PETER BUNDU - SABAH - KUALA PENYU",
    "SM. ISLAMIAH TAWAU - SABAH - TAWAU",
    "SMA AL-IRSYADIAH MARAKAU RANAU - SABAH - RANAU",
    "KOLEJ VOKASIONAL SANDAKAN - SABAH - SANDAKAN",
    "KOLEJ VOKASIONAL BEAUFORT - SABAH - BEAUFORT",
    "KOLEJ VOKASIONAL LAHAD DATU - SABAH - LAHAD DATU",
    "KOLEJ VOKASIONAL KUDAT - SABAH - KUDAT",
    "KOLEJ VOKASIONAL KENINGAU - SABAH - KENINGAU",
    "KOLEJ VOKASIONAL TAWAU - SABAH - TAWAU",
    "KOLEJ VOKASIONAL LIKAS - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA KENINGAU - SABAH - KENINGAU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN JUHAR - SABAH - SANDAKAN",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN SAKARAN - SABAH - SEMPORNA",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA KIMANIS - SABAH - PAPAR",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN AHMADSHAH - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA KOTA KINABALU - SABAH - KOTA KINABALU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN DATU MUSTAPHA LIMAUAN - SABAH - PAPAR",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN SAID - SABAH - KOTA BELUD",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA MOHAMAD ALI RANAU - SABAH - RANAU",
    "SEKOLAH KEBANGSAAN APAR - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN PUAK - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN KG BOBAK/SEJINJANG - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN MERPATI JEPANG - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN RANCANGAN PERUMAHAN RAKYAT 'RPR' - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN SATRIA JAYA - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN LAKSAMANA - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN SG. MAONG HILIR KUCHING - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN MATANG JAYA - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN RPR BATU KAWA - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN JALAN ARANG - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN SIOL KANAN - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN TABUAN HILIR - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN BANDAR SAMARIANG - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN TAN SRI DATUK DR. HJ. SULAIMAN DAUD - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN BERADEK - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN BATU KITANG - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN MUHIBBAH - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN PAYA MEBI - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN MATANG - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ENCIK BUYONG - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN GERSIK - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN SANTUBONG - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN MUARA TEBAS - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN PASIR PANDAK - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN GITA - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN PULO - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN GOEBILT - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN TABUAN - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN SEMENGGOK - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ASTANA - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN TEMENGGONG - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN TG BAKO - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN TABUAN ULU - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN SALAK - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN SEMARIANG - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN SG AUR - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN TABUAN JAYA - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN RANTAU PANJANG - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN TAN SRI DATUK HAJI MOHAMED - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN TELAGA AIR - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN WIRA JAYA - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN SEMERAH PADI - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN RAMPANGI - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN PETRA JAYA - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN MAJ GEN DATU IBRAHIM - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN STUNGGANG MELAYU - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN TELOK MELANO - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN STOH - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN MUNGGU BRINGIN - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN PULAU SEDUKU - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN LELA PAHLAWAN - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN MALUDAM - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN ABANG AING - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN BAKONG - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN KEM PAKIT - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN ENGKRANJI - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN APING - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN SRI AMAN - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN TO' EMAN - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN KG EMPLAM - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN PERPAT - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN HJ JUNID - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN KG PALOH - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ABG. MOH SESSANG - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN KPG ALIT - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ABANG LEMAN - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ABANG ABDUL RAHMAN - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN HAJI BOLLAH - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN KG KUPANG - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN SG PASIR - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ULU SEBETAN - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN BATANG AI - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN NG DELOK - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN BALINGAN - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN MANGUT - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN SEREMBANG - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN KPG. BUDA - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN SEBEMBAN - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN SPINANG - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN SPAOH - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN ABANG ABDUL KADIR - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN BELADIN - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN PUSA - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN DATUK BANDAR - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN TANJUNG ASSAM - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN TUI - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN SEMARANG - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN BATANG MARO - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN TAMBAK - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN SERABANG - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN SUPA - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN BUNGIN - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN MEDANG - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN NG. SPAK - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN KALOK - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN NG. PASSA - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN KG BAHAGIA JAYA - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS SIBU - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN JALAN DESHON - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SEDC - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SENTOSA SIBU - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN BATU 10 JALAN OYA - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN KAMPUNG KEBUAW - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN KAMPUNG IGAN - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN SINGAT - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SIBU JAYA - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN BUKIT ARIP - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN SENGAYAN - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN MUKAH - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN KAMPUNG SAU - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN KG PETANAK - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN DUA SUNGAI - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN SG LIUK - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN SG KEMENA - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN SUNGAI DUAN - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN SLDB LADANG 3 - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN DIJIH - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN PENAKUP ULU - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN SG BAYAN - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN BATU 36 - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN JALAN BINTANG - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN RIAM BATU DUA - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN ANCHI - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN KUALA BARAM - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN KG. BAKAM - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN PUJUT CORNER - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN KG. LUAK - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN PULAU MELAYU - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN TEMENGGONG DATUK MUIP - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN TUDAN - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS MIRI - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN MERBAU - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN KUALA BARAM II - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN SENADIN - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN MIRI - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN TUDAN JAYA - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN LONG SAIT - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LEPONG AJAI - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN LONG TERAN KANAN - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG PELUTAN - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG LUTENG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN UMA BAWANG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG LAPOK - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG KEVOK - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN BATU BUNGAN - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG JEKITAN - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN KUALA NYALAU - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN KELAPA SAWIT NO. 4 - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KPG. ANGUS - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KPG. MASJID - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN BELIAU AHAD - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KITA - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN BEKENU - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN TAWAKAL SATAP - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN ULU SATAP - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KPG. SELANYAU - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN RANCANGAN SEPUPOK - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN RUMAH UNDI - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN SUAI 1 - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN RUMAH MENTALI - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN TANJUNG BELIPAT - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KELURU TENGAH - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN BUKIT LUBA - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN KUBONG - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN BATU EMPAT - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN KAMPUNG PAHLAWAN - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN LIMPAKU PINANG - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN TEDUNGAN (BAMBANGAN) - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN MELAYU PUSAT - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN GADONG - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN BATU DANAU - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN LIMPAKI - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN TELAHAK - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN SUNGAI POYAN - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN KUALA AWANG - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN PENGKALAN JAWA - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN MERAMBUT - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN TIGA KAMPUNG - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN TANJONG - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN LUBOK TEKUROK - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN MERITAM - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN BANDAR LIMBANG - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN LIMBANG - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN KUALA LAWAS - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN MERAPOK - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN PUNANG - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN AWAT-AWAT - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN SUNDAR - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN TRUSAN - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN ULU MERAPOK - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN BATU LIMA - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN KG SEBERANG - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN KERANGAN - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN LUAGAN - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN ARU BARU - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN PURU SIA - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN ABANG BUYUK - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN SG SENTEBU - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN ABANG GESA - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN ORANG KAYA MUDA - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN ABANG HAJI MATAHIR - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN ABANG GALAU - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN MANDOR ARIS - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN BUKIT KINYAU - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN STALON - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN SARIKEI - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN TANJONG MANIS - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN ULU PEDANUM - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN KG SERDENG - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN MUPONG ULIN - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN SG SIAN - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN SALAH KECHIL - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN HIJRAH BADONG - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN NG PERARAN - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN BEBANGAN - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN SG PAKU - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN OYAN TENGAH - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN RANTAU PANJAI - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN LUBOK BAYA - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN SG TUNOH - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG BAWAI - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN KAPIT - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG TELAWAN - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN LONG URUN - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN LUSONG LAKU - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN TEGULANG - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN METALUN - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN MERANEK - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN MANG - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN SERPAN - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN MATA PARANG - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN TAMBIRAT - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN PINANG - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN DATO TRAOH MUARA TUANG - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN KG BARU - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN ENDAP - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN SAMBIR - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN TANJONG APONG - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN KG MELAYU - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN SEBANDI MATANG - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN TAMBAY - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN KG EMPILA - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN NIUP - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN KG TANJUNG TUANG - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN MOYAN LAUT - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN SEBANDI ULU - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN DATO MOHD MUSA - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN TEPOI - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN MUBOK BERAWAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN TRIANG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN LUBOK BUNTIN - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN JEMUKAN - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN TUNGKAH MELAYU - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN IBOI/PELANDUK - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN ABANG KADIR GEDONG - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SG LADONG - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN KG GUMPEY - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN HJ BUJANG SEBANGAN - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SG APIN - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN TANJUNG PISANG SELANGKING - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN HAJI KELALI SEMERA - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN ST ANDREW - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SEGALI - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SERUYUK - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN GAWANG EMPILI - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN RABA - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN TUANKU BAGUS - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN KEDUMPAI - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN TEBELU - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN KLAIT - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN ORANG KAYA MOHAMMAD - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN KAMPUNG JEPAK - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN KG BARU - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN PANDAN - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN SEBAUH - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN BUKIT MAWANG - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN KIDURONG - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN BINTULU - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN KIDURONG II - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN DESA LAVANG - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN ASYAKIRIN - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN TANJUNG BATU - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN KUALA TATAU - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN NG PENYARAI - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN ULU KAKUS - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN KELAWIT - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN BUSO - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN OPAR - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN ATAS - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SIMPANG KUDA - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN JAGOI - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SINIAWAN - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN GROGO - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN TEMBAWANG - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SERUMBU - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SEGONG - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN GUMBANG - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SERABAK - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SEGUBANG - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SKIBANG - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN BAU - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SEBOBOK - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN TRINGGUS - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SUBA BUAN - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN PEDAUN BAWAH - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SENIBONG - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SUNGAI PINANG - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN BATU LINTANG - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KOTA SAMARAHAN - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN PENDIDIKAN KHAS KUCHING - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN JLN.ONG TIANG SWEE - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN GREEN ROAD - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN SG STUTONG - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN PELAMAN SIGANDAR - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN BENGOH - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN PURUH KARU - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN SIBURAN BATU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN PELAMAN SIDUNUK - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SIBU LAUT - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN SITANG PETAG - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN PATUNG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN KAMBUG - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN PANGKALAN AMPAT - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN TABA SAIT - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN SEJIJAK - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN SEMATAN - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN PUEH - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN SEBIRIS - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN SELAMPIT - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN LEBAI MENTALI - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN JANGKAR - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN SAMPADI - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN BIAWAK - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN PAON/TEMAGA - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN STUNGKOR - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN SEBAT - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN PASIR TENGAH - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN PUGU - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN RASO - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN SEBANDI - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN KENDAIE - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN SENIBONG SEJERIN - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN SERAYAN/KERANJI - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN BOKAH - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN SEBAKO - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN SIAR CAMPURAN - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN STUNGGANG/TEMELAN - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN GRAN/STUMBIN - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN BATU LINTANG - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN PANTU - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN MELUGU - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN ABOK - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN SELEPONG - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN JAONG - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN TG BIJAT - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN SELANJAN - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN KERANGGAS - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN ISSU/BANGAT - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN NG KLASSEN - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN TABUT - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN NANGA TEBAT - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN NG SKRANG - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN NG MENJUAU - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN NG. ENTEBAN - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN NG ENTALAU - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN PAKU - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN KEM SKRANG - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN TEMUDOK KEM - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN SEMBAYANG - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN NG. LIDONG - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN SUNGAI KLAMPAI - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN NG GRENJANG - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN LICHOK - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN LUBOK KEPAYANG - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ENGKABANG - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN SUNGEI ANTU - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ENGKUDU - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN NG DRAU - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN BABANG - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN NG BUDU - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN KABO HILIR - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ORANG KAYA TEMENGGONG TANDUK - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN NG ATOI - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ULU BUDU - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ULU KABO - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN MENDAS - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN BERAYANG - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN MUDONG - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN NG ASSAM - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN SG ENTEBU - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN LEMPA - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN SUPOK - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN TEMUDOK AWIK - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN NG ABU - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN PRAJA - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN NG MALONG - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN LUBOK NIBONG - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN WONG BESI - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ULU AWIK - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN SG NYIAR - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN KLUA - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN KALAKA CENTRAL - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ULU ROBAN - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ENGKILILI NO. 1 - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN LUBOK ANTU - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN RIDAN - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN IJOK - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN MELABAN - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN NANGA KUMPANG - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN NANGA TIBU - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN BASI - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN NANGA AUP - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN SKAROK - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN SBANGKI - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN NANGA KESIT - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN MERBONG - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN ENGKILILI NO.2 - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN EMPLAM - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN NG MENYEBAT - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN ULU LEMANAK - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN SAN SEMANJU - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN STENGIN/SEDARAT - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN ULU ENGKARI - SARAWAK - LUBOK ANTU",
    "SEKOLAH KEBANGSAAN NG. AJAU - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN DIT - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN SAKA - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN PAKU CENTRAL - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN SURI - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN PENOM - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN NG. GAYAU - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN MUTON - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN NG. LAWIH/LESU - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN SG AUP - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SG PASSAI - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SG DURIN - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SG NAMAN - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN BATU WONG - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SG SALIM - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN TANJUNG PENASU - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN BAWANG ASSAN - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN ULU SUNGAI SALIM - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SG SIONG - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SG LENGAN - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SG RASAU - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN TANJUNG BEKAKAP - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SUNGEI PASSIN - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN ULU SG SENGAN - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN NANGA TUTUS - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN NANGA ASSAN - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN ULU SUNGAI SIONG - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN NANGA BON - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN BATANG LASSA - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN NANGA PAK - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SG PINANG - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN SG. SIONG TENGAH - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN KG BUNGAN - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN ASSAN NUNGGANG - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN RANTAU PANJANG - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN KPG BANYOK - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN NG SALIM - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SG ILAS - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN SG NIBONG - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN LUBOK PELAIE - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN ULU SG NAMAN - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN SG SEBINTEK - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN TANJUNG LATAP - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN ULU DURIN KIBA - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN ULU SG MERAH - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN NANGA LIPUS - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN RANTAU KEMIDING - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NANGA POI - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN SUNGAI TUAH - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NANGA JIH - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN ULU POI - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NG JAGOI - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NANGA DAP - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NANGA MACHAN - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NANGA NGUNGUN - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN RANTAU DILANG - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN ULU BAWAN - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NG BALINGIAU - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN BATU LUKING - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NANGA NIROK NGEMAH - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NANGA TADA - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN ULU MACHAN - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NANGA MENALUN - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN ULU RANAN - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN ULU MAJAU - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NANGA PEDAI - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NANGA NGEMAH - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN NG JAGAU - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN KUALA KENYANA - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN NANGA SIKAT - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN LUBOK BEMBAN - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN SG BADENGAN - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN NANGA KUA - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN SG ARAU - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN SG BAWANG - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN SG BULOH - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN SG TAU - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN KUALA BALINGIAN - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN KUALA PELUGAU - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN PENGKALAN LOBANG - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN SG ANAK - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN SG SEPIRING/SG TEPUS - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN KPG TEH - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN NANGA TAJAM - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN BAOH ULU - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN SUNGAI PAKOH - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN PENGHULU IMBAN - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN KAMPUNG BAKONG TERUS - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN KG. BERAYA - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN SOUTH - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN PENGARAH ENTERI - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN BELURU CENTRAL - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN LONG IKANG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LUBOK NIBONG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN POYUT - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG TERU - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG MOH - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG ATON - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG PANAI - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG LAPUT - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG LELLANG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG BEDIAN - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG ANAP - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LIO MATO - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG BEMANG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG JEGAN - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG ATIP - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN MOREK - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG. ENTULANG - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN UBONG IMANG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG. LIAM - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN SUNGAI BAIN - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG PEKING - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG LOYANG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG LAMEI - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG SERIDAN - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG KESSEH - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG PILLAH - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN BARIO - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG BONG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG MIRI - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG JEEH - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG NAAH - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG. SELEPIN - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN SG BRIT - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG BANGA - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SUNGAI SEBATU - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG ARANG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN KUALA TUTOH - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG SETAPANG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG. SEPUTI - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN PA ' DALLIH - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG PALAI - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN PENGHULU BAYA MALLANG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG. BIAR - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN LONG APU - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG SOBENG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG. LAONG - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN BENAWA - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG. KELABIT - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN LONG TUNGAN - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG MAKABAR - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG. BAKAS - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN PENGELAYAN - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG SEPILING - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN RUMAH GUDANG - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN LONG WAT - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN KUALA BOK - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN SG. BURI - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN LONG LENEI - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN BELIAU ISA - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KPG. IRAN - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN RUMAH BARAT - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN RUMAH TINGGI - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KPG. BUNGAI - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN RUMAH GOYANG - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN BATU TELLINGAI - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN SG. MANONG - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN SG. TANGAP - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KPG. TARIKAN - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN BATU NIAH - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN RUMAH RANGGONG - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN SG. SAEH - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN SG. LAMAUS - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KPG. KUALA SIBUTI - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KPG. BULAU - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN SG. BAKAS - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KAMPUNG NYALAU - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN KPG. SUBAK - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KPG. TEGAGENG - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KELAPA SAWIT NO. 1 - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN SG. SEBATU - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KELAPA SAWIT NO. 2 - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KELAPA SAWIT NO. 3 - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN KELAPA SAWIT NO. 5 - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN RUMAH ESSAU - SARAWAK - SUBIS",
    "SEKOLAH KEBANGSAAN UKONG - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN NANGA MEDAMIT - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN MENUANG - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN KUALA PENGANAN - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN LONG NAPIR - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN NANGA MERIT - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN KUALA MEDALAM - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN ULU LUBAI - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN MELABAN - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN BUKIT BATU - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN LONG SEMADOH - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN LONG TUMA - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN LONG TUKON - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN BA KELALAN - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN LONG SUKANG - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN LONG TENGOA - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN LONG LUPING - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN KG LINTANG - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN TANG LAPADAN - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN LONG SEBANGAN - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN SIANG-SIANG - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN UDIN - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN ADIN - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN BALONG - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN PENGHULU ANDIN - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN ST. ANDREW - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN ASSUN - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN RENTAP - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN BAYANG - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN JAWA - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN SG PAOH - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN SG PETAI - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN TANAH PUTIH - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN SELANGAN - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN SEBAKO - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN MUARA PAYANG - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN NG SERAU - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA LASI - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA MELUAN - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA PAKAN - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA WAK - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA ENTAIH - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN ULU MANDING - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA MERURUN - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA PEDANUM - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA BABAI - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA MAONG - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA KEDUP - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA ENTABAI - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA KOTA - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA LUAU - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA SEMAWANG - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA SENGAIH - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN TAPANG PUNGGU - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA KARA - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN LUBOK ASSAM - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NG ENSIRING - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN ULU ENTAIH - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN ULU ENTABAI - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN SUNGAI SUGAI - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA JAMBU - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA ENGKAMOP - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA BUKU - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA JU - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA DAYU - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA KELANGAS - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN NANGA SEMAH - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN ULU BINATANG - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN SG MADOR - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN SG SELIDAP - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN TULAI - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN TANJUNG BUNDUNG - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN SEMOP - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN MUPONG - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN KPG PENASU DARO - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN ULU STRAS - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN BAKERKONG - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN ULU MERADONG - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN BUKIT PAPIT - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN NANGA STRAS - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN SG KAWI - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN BANDAR BINTANGOR - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN GEMUAN - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN BUKIT NIBONG - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN TELOK GELAM - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN SABENA - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN O K SERGUNIM - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN MOHAMAD REDEH SAAI - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN KG PERGAU - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN KG PENIPAH - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN KG TEKAJONG - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN KG KUT - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN BAWANG TIAN - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN KG PANGTRAY - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN KG BETANAK - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN KG PENIBONG - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN BRUAN MAPAL - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN SEKAAN KECHIL - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN NG MERIT - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG PELAGUS - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN TEMENGGONG KOH - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG STAPANG - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG IBAU - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG BENA - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG OYAN - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG YONG - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN MUJONG TENGAH - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG IBUN - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG SEMPILI - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG SAMA - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG KEBIAW - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG TRUSA - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN LEPONG GAAT - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG ENCHEREMIN - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG SEGENOK - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN LEPONG BALLEH - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN LP MENUAN - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG ANTAROH - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG KAIN - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN KG BARU - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN SUNGAI KAPIT - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN ULU PELAGUS - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG BALANG - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN ULU YONG - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG METAH - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN ABUN MATU - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN UMA SAMBOP - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN LONG SEGAHAM - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN LONG GANG - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN BATU KELING - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN AIRPORT - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN LONG BUSANG - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN PUNAN BA - SARAWAK - BELAGA",
    "SEKOLAH KEBANGSAAN NG ENGKUAH - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN NG BEGUANG - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN NG NANSANG - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN NG BANGKIT - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN NG DALAI - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN TAN SRI DATUK TEMENGGONG JUGAH - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN NANGA NYIMOH - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN LUBOK IPOH - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN NG JANAN - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN LUBOK BEDIL - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN NG MUSAH - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN NG EMBUAU - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN NG TEMALAT - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN NG SELIBUT - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN ULU MELIPIS - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN PLAIE D C - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN SAMPUN - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN SAMPUN TEBUN - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN JALAN MUARA TUANG - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN SAMARAHAN ESTATE - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN TEBAKANG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN TEBEDU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN TEMA - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN TANAH MERAH - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN KRUSEN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN LEBUR / REMUN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN TARAT - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN RAYANG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN BALAI RINGIN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN TIAN MURUD - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN TESU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SEMUKOI - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN MELANSAI - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN RETEH - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SG SAMERAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN MAPU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN KORAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN PARUN SUAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN TEMONG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN LOBANG BATU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN RITUH - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN PAYAU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SG RIMU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN TANAH PUTEH - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN PATI - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN BEDUP - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SANGAI - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SG KENYAH - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ENGKAROH - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN DAHA - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN GAHAT MAWANG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SEBANBAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SEJIJAG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN GEMANG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN RIIH DASO - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN MAWANG TAUP - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN KRANGAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ENTAYAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN MERBAU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN KRAIT - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN MERAKAI - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SEMADA - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN KUJANG SAIN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SUNGAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN KUJANG MAWANG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN PANGKALAN SORAH - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN LUBOK ANTU REBAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SUMPAS - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ENTUBUH - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN PRIDAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN MENTU TAPU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN PLAMAN BAKI / MENAUL - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN RAJAU ENSIKA - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SEBUYAU/ENSENGGEI - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN TUNGKAH DAYAK - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SUNGAI ARUS LUMUT - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN TEMIANG - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN NYELITAK - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SUNGAI PINANG - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SEMALATONG - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN PADANG PEDALAI - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SUNGAI BULU/SENANGEH - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN KEPAYANG - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN KENIONG - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN MUNGGU LALLANG - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SG LINGKAU - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN TERASI - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN SEKENDU - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SKITONG/MERANTI - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SUNGAI BA - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN GUNONG NGELI - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SEBANGKOI/MAWANG - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN TELAGUS/JEROK - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN TEGELAM - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN LEPONG EMPLAS - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN BAJONG - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN MENTU - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN MUDING - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN TUBA - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN BULAN JERAGAM - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN LUNYING - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN LABANG - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN KUALA BINYO - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN SG SEBUNGAN - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN SG GENAAN - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN SG. SENGIAN LABANG - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN HERMANUS ASSAN - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN KUALA KEBULU - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN SG SILAS - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN SG. TISANG - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN SG KEM BATU 18 - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN SG SELAD - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN ULU SEGAN - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN KUALA SIGU - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN RH TAYAI - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN KUALA SERUPAI - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN RUMAH KESENG - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN RUMAH BARRAU - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN SANGAN IBAN - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN KUALA ANNAU - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN KUALA MUPUT - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN SG SETIAM - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN SG. BAGIAN - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN NG TAU - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN SG. SETULAN - SARAWAK - BINTULU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SG APONG - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BINTAWA - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) STAMPIN - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SG TENGAH - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BERATOK - SARAWAK - SERIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAPAH - SARAWAK - SERIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAMAN RAJANG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SANG MING - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NORTH - SARAWAK - MIRI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TUKAU - SARAWAK - MIRI",
    "SEKOLAH JENIS KEBANGSAAN SUNGAI JAONG MARUDI - SARAWAK - BARAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HUA KWONG - SARAWAK - MIRI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HIEN - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TIONG HO - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SZE LU - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MINAR - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAM KIEW - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SU LOK - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KIEW MANG - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HUA KEE - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HUA NAM - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN CAMPORAN - SARAWAK - DARO",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SG MENYAN - SARAWAK - SERIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) PANGKALAN BEDUP - SARAWAK - SERIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SEBIEW CHINESE - SARAWAK - BINTULU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAI MING - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN PODAM - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN STASS - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN SERASOT - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN LUMBA KUDA - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN COMBINED - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN BUNTAL - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN BAKO - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN LANDEH - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN PAJAR SEJINGKAT - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN STAPOK - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN KPG SENARI - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN BELIONG - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN BINYU - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN JALAN HAJI BAKI - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN MATU BARU - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN BUMIPUTERA - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN RAMBUNGAN - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN ABANG ALI - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SIBU BANDARAN NO 3 - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SG MENYAN - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN BATU 15 - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN KG SAWAI - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN DATUK AWANG UDIN - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN KG TELLIAN - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN KG JEBUNGAN - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN BUKIT INKERBAI - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN KAMPUNG TEH - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN NANGA SELANGAU - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN KG SEBERANG - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN SG PENIPAH - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN PARISH - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN KUALA LEMAI - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN IBAN UNION - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN ULU SG ARIP - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN SLDB NO. 1 - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN DATU PENGIRAN MOHAMAD - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN KG TANAM - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN KG BALAN - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN SG UD - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN SUNGAI KUT TENGAH DALAT - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN KG SENAU - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN KG KLID/PLAJAU - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN SAYED OTHMAN - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN LUTONG - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN LAMBIR VILLAGE - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN DATO SHARIF HAMID - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN PUSAT LAWAS - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN LADANG BARU - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN BELIPAT - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN ABANG AMIN - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN SOK - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN KUALA MATU - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN ULU DARO - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN KG TEBAANG - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN NANGAR - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN KG BRUIT - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN O K SELAIR - SARAWAK - DARO",
    "SEKOLAH KEBANGSAAN REBAK - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN ASAJAYA LAUT - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN ASAJAYA ULU - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN SERIAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN HAJI BUJANG RANGAWAN PUTIN - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN JA'IE - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN ABANG MAN - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN LUBOK PUNGGOR - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN SAGENG - SARAWAK - SIMUNJAN",
    "SEKOLAH KEBANGSAAN PENDAM - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN SG BUKIT BALAI - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN TATAU - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN SONG KHENG HAI - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN KENYALANG - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN SIBU BANDARAN NO 2 - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN PERBANDARAN SIBU NO. 4 - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN RAKYAT - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN RAKYAT TUPONG - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN MADRASAH DATUK HJ. ABDUL KADIR HASAN - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN AGAMA SRI AMAN - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN AGAMA SIBU - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN AGAMA - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN AGAMA (MIS) LAWAS - SARAWAK - LAWAS",
    "SEKOLAH KEBANGSAAN AGAMA SARIKEI - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN (A) IBNU KHALDUN - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN AGAMA (M.I.S) BINTULU - SARAWAK - BINTULU",
    "SEKOLAH KEBANGSAAN (AGAMA) MAJLIS ISLAM SARAWAK MUKAH - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN ST PATRICK - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN ST TERESA - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN ST STEPHEN ( M ) - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN ST JOHN - SARAWAK - BAU",
    "SEKOLAH KEBANGSAAN ST THOMAS - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN ST MARY - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN ST TERESA - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN CATHOLIC ENGLISH - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN ST ANDREW - SARAWAK - KUCHING",
    "SEKOLAH KEBANGSAAN EMPEROH JAMBU - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST FRANCIS XAVIER SERATAU - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST ELIZABETH - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST PETER - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SACRED HEART - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN PANGKALAN KUUD - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SUBANG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN SIRA/KRIAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN PESANG BEGU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN KG GAYU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST ALBAN - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST PHILIP - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST AUGUSTINE - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN GEORGE - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST MATTHEW - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST JAMES QUOP - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST ALBAN DURAS - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST PATRICK - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST PAUL - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST JAMES RAYANG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST DUNSTAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST GREGORY - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST DAVID - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST BERNARD - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST EDWARD BELIMBING - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST GILE - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN ST SWITHUN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN GARLAND - SARAWAK - PADAWAN",
    "SEKOLAH KEBANGSAAN HOLY NAME - SARAWAK - LUNDU",
    "SEKOLAH KEBANGSAAN ST MARTIN - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN ST LAWRENCE - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN ST PAUL - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN ST DUNSTAN - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN ST LEO GAYAU - SARAWAK - SRI AMAN",
    "SEKOLAH KEBANGSAAN ST PETER SARATOK - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ST PAUL ROBAN - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ST MICHAEL PLASSU - SARAWAK - SARATOK",
    "SEKOLAH KEBANGSAAN ST. CHRISTOPHER DEBAK - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN ST. PETER & ST. PAUL - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN ST. BARTHOLOMEW - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN ST. BARNABAS - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN ST. JOHN - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN ST. AUGUSTINE - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN ST. MARK - SARAWAK - BETONG",
    "SEKOLAH KEBANGSAAN ST RITA - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN SACRED HEART - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN ST. MARY - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN HUA HIN ENGLISH - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN ST FRANCIS XAVIER - SARAWAK - KANOWIT",
    "SEKOLAH KEBANGSAAN ST. PATRICK - SARAWAK - MUKAH",
    "SEKOLAH KEBANGSAAN ST MATTHEW SEKUAU - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN ST LUKE NG BAOH - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN ST MARK - SARAWAK - SELANGAU",
    "SEKOLAH KEBANGSAAN ST. JUDE - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN ST BERNARD - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN ST JOHN KG MEDONG - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN ST KEVIN SG KUT - SARAWAK - DALAT",
    "SEKOLAH KEBANGSAAN ST JOSEPH - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN ST COLUMBA - SARAWAK - MIRI",
    "SEKOLAH KEBANGSAAN ST PIUS LONG SAN - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN GOOD SHEPHERD - SARAWAK - BARAM",
    "SEKOLAH KEBANGSAAN ST EDMUND - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN R C KUBONG - SARAWAK - LIMBANG",
    "SEKOLAH KEBANGSAAN ST ANNE - SARAWAK - SARIKEI",
    "SEKOLAH KEBANGSAAN ST ALPHONSUS - SARAWAK - JULAU",
    "SEKOLAH KEBANGSAAN ST AUGUSTINE - SARAWAK - MARADONG",
    "SEKOLAH KEBANGSAAN METHODIST - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG TIAU - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG MELUAN - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NANGA ENTULOH - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN SG AMANG - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN NG MUJONG - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN SG MENUAN - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN LBK MAWANG - SARAWAK - KAPIT",
    "SEKOLAH KEBANGSAAN CARDINAL VAUGHAN - SARAWAK - SONG",
    "SEKOLAH KEBANGSAAN KAMPUNG REMBUS - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN ST JOHN - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN ST MICHAEL - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN MERDANG - SARAWAK - SAMARAHAN",
    "SEKOLAH KEBANGSAAN ST PHILIP BUGU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST JOHN MANTUNG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST MICHAEL MONGKOS - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST DOMINIC PICHIN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST ANTHONY KAWAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST JUDE BUNAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST PATRICK TANGGA - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST HENRY SLABI - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST ALBAN AMPUNGAN - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST NORBERT PAUN GAHAT - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST RAYMOND MUJAT - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST BARNABAS BARU - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST JOHN TAEE - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST MATHEW LANCHANG - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST AMBROSE PANCHOR - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ALL SAINTS PLAMAN NYABET - SARAWAK - SERIAN",
    "SEKOLAH KEBANGSAAN ST. ANTHONY - SARAWAK - BINTULU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TAITON - SARAWAK - BAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PAKU - SARAWAK - BAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TONDONG - SARAWAK - BAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEBUKU - SARAWAK - BAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA MUSI - SARAWAK - BAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BUSO - SARAWAK - BAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SINIAWAN - SARAWAK - BAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BAU - SARAWAK - BAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA KRANJI - SARAWAK - BAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST PAUL - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA 1 - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA 2 - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA 3 - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA NO.4 KUCHING - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PENDING - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA NO.6 - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA NO. 5 - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG TENGAH - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 8 1/2 - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG.TAPANG - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAM HAP HIN - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 11 - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEJIJAK - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 15 - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG BUDA - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BT KITANG - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG MOYAN - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BT KAWA - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG. LUBAK - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA STAPOK - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA KIM CHOO SENG - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA RANTAU PANJANG - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA STAMPIN - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BUNTAL - SARAWAK - KUCHING",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 4 1/2 - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BELIONG - SARAWAK - SAMARAHAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PANGKALAN BARU - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 10 - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SYN-SAN-TU - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG TAPANG BT KAWA - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 7 - SARAWAK - PADAWAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SIBURAN - SARAWAK - SERIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEMATAN - SARAWAK - LUNDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA LUNDU - SARAWAK - LUNDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SERAYAN/SELARAT - SARAWAK - LUNDU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA MELUDAM - SARAWAK - BETONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SIMANGGANG - SARAWAK - SRI AMAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BANGKONG - SARAWAK - SRI AMAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA UNDOP - SARAWAK - SRI AMAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PANTU - SARAWAK - SRI AMAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA LINGGA - SARAWAK - SRI AMAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA ROBAN - SARAWAK - SARATOK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MIN SYN SARATOK - SARAWAK - SARATOK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA KABONG - SARAWAK - SARATOK",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA ENGKILILI - SARAWAK - LUBOK ANTU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BETONG - SARAWAK - BETONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA DEBAK - SARAWAK - BETONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SPAOH - SARAWAK - BETONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PUSA - SARAWAK - BETONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SACRED HEART CHINESE - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG HUA - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) GUONG ANN - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) DUNG SANG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) THAI KWANG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) UK DAIK - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TIONG HIN - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) EK THEI - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SING - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KIEW NANG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) THIAN HUA - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) THIAN CHIN - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YONG CHENG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) DO NANG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) GUONG MING - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KENG NANG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG UNG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG KIEW - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NANG KIANG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HANG KWONG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YONG SHING - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAI NANG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SU LAI - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TING SING - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SUNG SANG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NANG SANG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAM LAM - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KWONG HUA - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ING GUONG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SING MING - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) UNG NANG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KWONG KOK - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHAO SU - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BOI ING - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TAI TUNG 2 - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HING UNG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KIANG HIN - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NENG SHING - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG CHENG - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAI DEE - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK CHAI - SARAWAK - SIBU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIH MONG - SARAWAK - KANOWIT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SING SHING - SARAWAK - KANOWIT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YEE TING - SARAWAK - KANOWIT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MING WOK - SARAWAK - KANOWIT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) LIANG HUA - SARAWAK - KANOWIT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SHING KOK - SARAWAK - KANOWIT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAM HUA - SARAWAK - KANOWIT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YOK MING - SARAWAK - KANOWIT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SHING HUA - SARAWAK - KANOWIT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHENG HUA - SARAWAK - KANOWIT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) EK HUA - SARAWAK - MUKAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHONG BOON - SARAWAK - MUKAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BALINGIAN - SARAWAK - MUKAH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TONG AH - SARAWAK - SELANGAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YAK TEE - SARAWAK - DALAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN HUA - SARAWAK - DALAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAN SAN - SARAWAK - DALAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) POI YUK - SARAWAK - DALAT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA MIRI - SARAWAK - MIRI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA KROKOP - SARAWAK - MIRI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA LUTONG - SARAWAK - MIRI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA PUJUT - SARAWAK - MIRI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BAKAM - SARAWAK - MIRI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SAN MIRI - SARAWAK - MIRI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TUDAN - SARAWAK - MIRI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA - SARAWAK - BARAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KEE TEE - SARAWAK - BARAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HUA NAM - SARAWAK - BARAM",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SIBUTI - SARAWAK - SUBIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHEE MUNG - SARAWAK - SUBIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHIN NAM - SARAWAK - SUBIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG LUMUT - SARAWAK - SUBIS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA LIMBANG - SARAWAK - LIMBANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK HIN - SARAWAK - LIMBANG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TRUSAN - SARAWAK - LAWAS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HWA LAWAS - SARAWAK - LAWAS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SOON HWA SUNDAR - SARAWAK - LAWAS",
    "SEKOLAH JENIS KEBANGSAAN (CINA) BULAT - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KWANG CHIEN - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SU HING - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SU KWONG - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SU MING - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SU TAK - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIUNG HUA - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) WAH MAN - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SING MING - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SU LEE - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SU DOK - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SAN MING - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MING TAK - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) ST MARTIN - SARAWAK - SARIKEI",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YUK KUNG - SARAWAK - JULAU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KUNG CHENG - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MING LU - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) YONG KWONG - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TONG HUA - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NAN CHIEW - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) TUNG KWONG - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MING CHIANG - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MING SHING - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAI CHUNG - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MING TEE - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MIN DAIK - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) MING CHING - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) NANG HUA - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) KAI SING - SARAWAK - MARADONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA - SARAWAK - DARO",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HOCK LAM - SARAWAK - KAPIT",
    "SEKOLAH JENIS KEBANGSAAN (CINA) HIN HUA - SARAWAK - SONG",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SG JERNANG - SARAWAK - SAMARAHAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TAMBIRAT - SARAWAK - SAMARAHAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA ASAJAYA - SARAWAK - SAMARAHAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SAMBIR - SARAWAK - SAMARAHAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BAKO - SARAWAK - SAMARAHAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SERIAN - SARAWAK - SERIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 29 - SARAWAK - SERIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 35 - SARAWAK - SERIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BATU 32 - SARAWAK - SERIAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SIMUNJAN - SARAWAK - SIMUNJAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEMERA - SARAWAK - SAMARAHAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEBANGAN - SARAWAK - SIMUNJAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA JEMUKAN - SARAWAK - SAMARAHAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA SEBUYAU - SARAWAK - SIMUNJAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BINTULU - SARAWAK - BINTULU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG SAN - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH JENIS KEBANGSAAN (CINA) SIONG BOON - SARAWAK - BINTULU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA BINTULU 2 - SARAWAK - BINTULU",
    "SEKOLAH JENIS KEBANGSAAN (CINA) CHUNG HUA TATAU - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH KEBANGSAAN ST LUKE - SARAWAK - SRI AMAN",
    "SEKOLAH JENIS KEBANGSAAN (CINA) METHODIST - SARAWAK - SIBU",
    "SEKOLAH KEBANGSAAN METHODIST ANGLO-CHINESE - SARAWAK - SARIKEI",
    "SEKOLAH MENENGAH KEBANGSAAN PAKU (S) - SARAWAK - BAU",
    "SEKOLAH MENENGAH KEBANGSAAN SINGAI - SARAWAK - BAU",
    "SEKOLAH MENENGAH KEBANGSAAN D P H ABDUL GAPOR - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN SERI SETIA - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN SG MAONG - SARAWAK - PADAWAN",
    "SEKOLAH MENENGAH KEBANGSAAN TABUAN JAYA - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN BAKO - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN ARANG - SARAWAK - PADAWAN",
    "SEKOLAH MENENGAH KEBANGSAAN DEMAK BARU - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN MATANG JAYA - SARAWAK - PADAWAN",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR SAMARIANG - SARAWAK - KUCHING",
    "SEKOLAH SENI MALAYSIA SARAWAK - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH SAINS KUCHING UTARA - SARAWAK - PADAWAN",
    "SEKOLAH MENENGAH KEBANGSAAN PETRA JAYA - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN WIRA PENRISSEN - SARAWAK - SAMARAHAN",
    "SEKOLAH MENENGAH KEBANGSAAN TUNKU ABDUL RAHMAN - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN BATU KAWA - SARAWAK - PADAWAN",
    "SEKOLAH MENENGAH KEBANGSAAN SANTUBONG - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN PADAWAN - SARAWAK - SERIAN",
    "SEKOLAH MENENGAH KEBANGSAAN MATANG HILIR - SARAWAK - PADAWAN",
    "SEKOLAH MENENGAH SAINS KUCHING - SARAWAK - PADAWAN",
    "SEKOLAH MENENGAH KEBANGSAAN SEMERAH PADI - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN SRI MATANG - SARAWAK - PADAWAN",
    "SEKOLAH MENENGAH KEBANGSAAN SEMATAN - SARAWAK - LUNDU",
    "SEKOLAH MENENGAH KEBANGSAAN SENIBONG - SARAWAK - LUNDU",
    "SEKOLAH MENENGAH KEBANGSAAN SRI AMAN - SARAWAK - SRI AMAN",
    "SEKOLAH MENENGAH KEBANGSAAN LINGGA - SARAWAK - SRI AMAN",
    "SEKOLAH MENENGAH KEBANGSAAN (BM) SARATOK - SARAWAK - SARATOK",
    "SEKOLAH MENENGAH KEBANGSAAN KABONG - SARAWAK - SARATOK",
    "SEKOLAH MENENGAH KEBANGSAAN PUSA - SARAWAK - BETONG",
    "SEKOLAH MENENGAH KEBANGSAAN SPAOH - SARAWAK - BETONG",
    "SEKOLAH MENENGAH KEBANGSAAN ULU LAYAR - SARAWAK - BETONG",
    "SEKOLAH MENENGAH KEBANGSAAN BELADIN - SARAWAK - BETONG",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT LIMA - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN DESHON - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN SIBU JAYA - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN LANANG - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN SUNGAI MERAH - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR SIBU - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN BUKIT ASSEK - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN BAWANG ASSAN - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN BATANG IGAN - SARAWAK - DALAT",
    "SEKOLAH MENENGAH KEBANGSAAN KANOWIT - SARAWAK - KANOWIT",
    "SEKOLAH MENENGAH KEBANGSAAN NANGA DAP - SARAWAK - KANOWIT",
    "SEKOLAH MENENGAH KEBANGSAAN ULU BALINGIAN - SARAWAK - SELANGAU",
    "SEKOLAH MENENGAH KEBANGSAAN MUKAH - SARAWAK - MUKAH",
    "SEKOLAH MENENGAH KEBANGSAAN OYA - SARAWAK - DALAT",
    "SEKOLAH MENENGAH KEBANGSAAN DATO PERMAISURI - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN BARU - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN LUAK - SARAWAK - MIRI",
    "SEKOLAH MENENGAH SAINS MIRI - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN RIAM - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN LOPENG TENGAH - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN MERBAU - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN TAMAN TUNKU - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN PUJUT - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN TEMENGGONG DATUK LAWAI JAU - SARAWAK - BARAM",
    "SEKOLAH MENENGAH KEBANGSAAN TUTOH APOH - SARAWAK - BARAM",
    "SEKOLAH MENENGAH KEBANGSAAN TELANG USAN - SARAWAK - BARAM",
    "SEKOLAH MENENGAH KEBANGSAAN TINJAR - SARAWAK - BARAM",
    "SEKOLAH MENENGAH KEBANGSAAN SUAI - SARAWAK - SUBIS",
    "SEKOLAH MENENGAH KEBANGSAAN BEKENU - SARAWAK - SUBIS",
    "SEKOLAH MENENGAH KEBANGSAAN SERI PATIAMBUN LIMBANG - SARAWAK - LIMBANG",
    "SEKOLAH MENENGAH KEBANGSAAN KUBONG - SARAWAK - LIMBANG",
    "SEKOLAH MENENGAH KEBANGSAAN SUNDAR - SARAWAK - LAWAS",
    "SEKOLAH MENENGAH KEBANGSAAN MERAPOK - SARAWAK - LAWAS",
    "SEKOLAH MENENGAH KEBANGSAAN BELAWAI - SARAWAK - DARO",
    "SEKOLAH MENENGAH KEBANGSAAN SARIKEI BARU - SARAWAK - SARIKEI",
    "SEKOLAH MENENGAH KEBANGSAAN PAKAN - SARAWAK - JULAU",
    "SEKOLAH MENENGAH KEBANGSAAN JULAU NO.2 - SARAWAK - JULAU",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BINTANGOR - SARAWAK - MARADONG",
    "SEKOLAH MENENGAH KEBANGSAAN BALLEH - SARAWAK - KAPIT",
    "SEKOLAH MENENGAH KEBANGSAAN BAKUN - SARAWAK - BELAGA",
    "SEKOLAH MENENGAH KEBANGSAAN KAPIT NO.2 - SARAWAK - KAPIT",
    "SEKOLAH MENENGAH KEBANGSAAN KATIBAS - SARAWAK - SONG",
    "SEKOLAH MENENGAH KEBANGSAAN SONG NO. 2 - SARAWAK - SONG",
    "SEKOLAH MENENGAH KEBANGSAAN ASAJAYA - SARAWAK - SAMARAHAN",
    "SEKOLAH MENENGAH KEBANGSAAN KOTA SAMARAHAN - SARAWAK - SAMARAHAN",
    "SEKOLAH MENENGAH KEBANGSAAN HAJJAH LAILA TAIB - SARAWAK - SAMARAHAN",
    "SEKOLAH MENENGAH KEBANGSAAN TAEE - SARAWAK - SERIAN",
    "SEKOLAH MENENGAH KEBANGSAAN TEBEDU - SARAWAK - SERIAN",
    "SEKOLAH MENENGAH KEBANGSAAN SRI SADONG - SARAWAK - SIMUNJAN",
    "SEKOLAH MENENGAH KEBANGSAAN GEDONG - SARAWAK - SIMUNJAN",
    "SEKOLAH MENENGAH KEBANGSAAN SADONG HILIR (SEMERA) - SARAWAK - SAMARAHAN",
    "SEKOLAH MENENGAH KEBANGSAAN SEBUYAU - SARAWAK - SIMUNJAN",
    "SEKOLAH MENENGAH KEBANGSAAN SADONG JAYA - SARAWAK - SAMARAHAN",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR BINTULU - SARAWAK - BINTULU",
    "SEKOLAH MENENGAH KEBANGSAAN BARU BINTULU - SARAWAK - BINTULU",
    "SEKOLAH MENENGAH KEBANGSAAN KIDURONG - SARAWAK - BINTULU",
    "SEKOLAH MENENGAH KEBANGSAAN ASYAKIRIN - SARAWAK - BINTULU",
    "SEKOLAH MENENGAH KEBANGSAAN KEMENA - SARAWAK - BINTULU",
    "SEKOLAH MENENGAH KEBANGSAAN TATAU - SARAWAK - TATAU/SEBAUH",
    "KOLEJ TINGKATAN ENAM SARATOK - SARAWAK - SARATOK",
    "SEKOLAH MENENGAH KEBANGSAAN SEMOP - SARAWAK - DARO",
    "SEKOLAH MENENGAH KEBANGSAAN LAKE - SARAWAK - BAU",
    "SEKOLAH MENENGAH KEBANGSAAN GREEN ROAD - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR KUCHING NO.1 - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN TUN ABDUL RAZAK - SARAWAK - SERIAN",
    "KOLEJ D PATINGGI ABANG HJ ABDILLAH - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN LUBOK ANTU - SARAWAK - LUBOK ANTU",
    "SEKOLAH MENENGAH KEBANGSAAN LUAR BANDAR NO 1 - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK HAJI ABDUL RAHMAN YAKUB - SARAWAK - KANOWIT",
    "KOLEJ TUN DATU TUANKU HJ BUJANG - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN MARUDI - SARAWAK - BARAM",
    "SEKOLAH MENENGAH KEBANGSAAN LONG LAMA - SARAWAK - BARAM",
    "SEKOLAH MENENGAH KEBANGSAAN BARIO - SARAWAK - BARAM",
    "SEKOLAH MENENGAH KEBANGSAAN SUBIS - SARAWAK - SUBIS",
    "SEKOLAH MENENGAH KEBANGSAAN MEDAMIT - SARAWAK - LIMBANG",
    "SEKOLAH MENENGAH KEBANGSAAN TRUSAN - SARAWAK - LAWAS",
    "SEKOLAH MENENGAH KEBANGSAAN BANDAR SARIKEI - SARAWAK - SARIKEI",
    "SEKOLAH MENENGAH KEBANGSAAN JULAU - SARAWAK - JULAU",
    "SEKOLAH MENENGAH KEBANGSAAN SG TAPANG - SARAWAK - SAMARAHAN",
    "SEKOLAH MENENGAH KEBANGSAAN TEBAKANG - SARAWAK - SERIAN",
    "SEKOLAH MENENGAH KEBANGSAAN TARAT - SARAWAK - SERIAN",
    "SEKOLAH MENENGAH KEBANGSAAN BALAI RINGIN - SARAWAK - SERIAN",
    "SEKOLAH MENENGAH KEBANGSAAN SERIAN - SARAWAK - SERIAN",
    "SEKOLAH MENENGAH KEBANGSAAN SEBAUH - SARAWAK - TATAU/SEBAUH",
    "SEKOLAH MENENGAH KEBANGSAAN BAU - SARAWAK - BAU",
    "SEKOLAH MENENGAH KEBANGSAAN PENDING - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN BATU LINTANG - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN PADUNGAN - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN TUN ABANG HJ OPENG - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN SIBURAN - SARAWAK - SERIAN",
    "SEKOLAH MENENGAH KEBANGSAAN PENRISSEN - SARAWAK - PADAWAN",
    "SEKOLAH MENENGAH KEBANGSAAN LUNDU - SARAWAK - LUNDU",
    "SEKOLAH MENENGAH KEBANGSAAN SIMANGGANG - SARAWAK - SRI AMAN",
    "SEKOLAH MENENGAH KEBANGSAAN MELUGU - SARAWAK - SRI AMAN",
    "SEKOLAH MENENGAH KEBANGSAAN SARATOK - SARAWAK - SARATOK",
    "SEKOLAH MENENGAH KEBANGSAAN KALAKA - SARAWAK - SARATOK",
    "SEKOLAH MENENGAH KEBANGSAAN ENGKILILI - SARAWAK - LUBOK ANTU",
    "SEKOLAH MENENGAH KEBANGSAAN SARIBAS - SARAWAK - BETONG",
    "SEKOLAH MENENGAH KEBANGSAAN DATUK PATINGGI KEDIT - SARAWAK - BETONG",
    "SEKOLAH MENENGAH KEBANGSAAN ROSLI DHOBY - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN KAMPUNG NANGKA - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN DURIN - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN JALAN OYA - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN THREE RIVERS - SARAWAK - MUKAH",
    "SEKOLAH MENENGAH KEBANGSAAN DALAT - SARAWAK - DALAT",
    "SEKOLAH MENENGAH KEBANGSAAN LUTONG - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN BAKONG - SARAWAK - SUBIS",
    "SEKOLAH MENENGAH KEBANGSAAN LUAR BANDAR MIRI - SARAWAK - SUBIS",
    "SEKOLAH MENENGAH KEBANGSAAN LIMBANG - SARAWAK - LIMBANG",
    "SEKOLAH MENENGAH KEBANGSAAN LAWAS - SARAWAK - LAWAS",
    "SEKOLAH MENENGAH KEBANGSAAN SG PAOH - SARAWAK - SARIKEI",
    "SEKOLAH MENENGAH KEBANGSAAN MERADONG - SARAWAK - MARADONG",
    "SEKOLAH MENENGAH KEBANGSAAN MATU - SARAWAK - DARO",
    "SEKOLAH MENENGAH KEBANGSAAN TOH PUAN DATUK PATINGGI HJH NORMAH - SARAWAK - DARO",
    "SEKOLAH MENENGAH KEBANGSAAN KAPIT - SARAWAK - KAPIT",
    "SEKOLAH MENENGAH KEBANGSAAN SELIRIK - SARAWAK - KAPIT",
    "SEKOLAH MENENGAH KEBANGSAAN BELAGA - SARAWAK - BELAGA",
    "SEKOLAH MENENGAH KEBANGSAAN SONG - SARAWAK - SONG",
    "SEKOLAH MENENGAH KEBANGSAAN MUARA TUANG - SARAWAK - SAMARAHAN",
    "SEKOLAH MENENGAH KEBANGSAAN SIMUNJAN NO 1 - SARAWAK - SIMUNJAN",
    "SEKOLAH MENENGAH KEBANGSAAN BINTULU - SARAWAK - BINTULU",
    "SEKOLAH MENENGAH KEBANGSAAN PESANTREN ABDUL TAIB MAHMUD - SARAWAK - SAMARAHAN",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI KUCHING - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN ST JOSEPH - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN ST TERESA - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN ST THOMAS - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN ST MARY - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN ST LUKE - SARAWAK - SRI AMAN",
    "SEKOLAH MENENGAH KEBANGSAAN ST. AUGUSTINE - SARAWAK - BETONG",
    "SEKOLAH MENENGAH KEBANGSAAN METHODIST - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN SACRED HEART - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN ST ELIZABETH - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN TIONG HIN - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN TUNG HUA - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG HUA - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG CHENG - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN KWONG HUA MIDDLE - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN ST. PATRICK - SARAWAK - MUKAH",
    "SEKOLAH MENENGAH KEBANGSAAN CHUNG HUA - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN ST. COLUMBA - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN ST. JOSEPH - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN TINGGI SARIKEI - SARAWAK - SARIKEI",
    "SEKOLAH MENENGAH KEBANGSAAN ST ANTHONY - SARAWAK - SARIKEI",
    "SEKOLAH MENENGAH KEBANGSAAN KAI CHUNG - SARAWAK - MARADONG",
    "SEKOLAH MENENGAH KEBANGSAAN TONG HUA - SARAWAK - MARADONG",
    "KOLEJ VOKASIONAL KUCHING - SARAWAK - KUCHING",
    "KOLEJ VOKASIONAL MATANG - SARAWAK - PADAWAN",
    "KOLEJ VOKASIONAL BETONG - SARAWAK - BETONG",
    "KOLEJ VOKASIONAL SIBU - SARAWAK - SIBU",
    "KOLEJ VOKASIONAL MIRI - SARAWAK - MIRI",
    "SMT SEJINGKAT - SARAWAK - KUCHING",
    "KOLEJ VOKASIONAL BINTULU - SARAWAK - BINTULU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SHEIKH HJ OTHMAN ABD WAHAB - SARAWAK - PADAWAN",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA MATANG 2 - SARAWAK - PADAWAN",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA TUN AHMAD ZAIDI - SARAWAK - KUCHING",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SARATOK - SARAWAK - SARATOK",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA SIBU (S) - SARAWAK - SIBU",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA IGAN - SARAWAK - DALAT",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA - SARAWAK - MIRI",
    "SEKOLAH MENENGAH KEBANGSAAN AGAMA LIMBANG - SARAWAK - LIMBANG"
];

//added 2021.10.12
export const District = [
    //WEST MALAYSIA.
    {
        State: "PERAK",
        Area: [
            "PPD BATANG PADANG",
            "PPD MANJUNG",
            "PPD KINTA UTARA",
            "PPD KINTA SELATAN",
            "PPD KRIAN",
            "PPD KUALA KANGSAR",
            "PPD HILIR PERAK",
            "PPD LARUT/MATANG/SELAMA",
            "PPD HULU PERAK",
            "PPD PERAK TENGAH",
            "PPD BAGAN DATUK",
        ]
    },
    {
        State: "SELANGOR",
        Area: [
            "PPD KLANG",
            "PPD PETALING PERDANA",
            "PPD KUALA LANGAT",
            "PPD KUALA SELANGOR",
            "PPD HULU LANGAT",
            "PPD HULU SELANGOR",
            "PPD SABAK BERNAM",
            "PPD GOMBAK",
            "PPD PETALING UTAMA",
            "PPD SEPANG"
        ]
    },
    {
        State: "PAHANG",
        Area: [
            "PPD BENTONG",
            "PPD CAMERON HIGHLANDS",
            "PPD JERANTUT",
            "PPD LIPIS",
            "PPD KUANTAN",
            "PPD PEKAN",
            "PPD RAUB",
            "PPD TEMERLOH",
            "PPD ROMPIN",
            "PPD MARAN",
            "PPD BERA"
        ]
    },
    {
        State: "KELANTAN",
        Area: [
            "PPD BACHOK",
            "PPD KOTA BHARU",
            "PPD MACHANG",
            "PPD PASIR MAS",
            "PPD PASIR PUTIH",
            "PPD TANAH MERAH",
            "PPD TUMPAT",
            "PPD KUALA KRAI",
            "PPD GUA MUSANG",
            "PPD JELI"
        ]
    },
    {
        State: "JOHOR",
        Area: [
            "PPD BATU PAHAT",
            "PPD JOHOR BAHRU",
            "PPD PASIR GUDANG",
            "PPD KULAI",
            "PPD KLUANG",
            "PPD KOTA TINGGI",
            "PPD MERSING",
            "PPD MUAR",
            "PPD PONTIAN",
            "PPD SEGAMAT",
            "PPD TANGKAK"
        ]
    },
    {
        State: "KEDAH",
        Area: [
            "PPD BALING",
            "PPD KULIM/BANDAR BAHARU",
            "PPD KOTA SETAR",
            "PPD KUALA MUDA/YAN",
            "PPD KUBANG PASU",
            "PPD LANGKAWI",
            "PPD PADANG TERAP",
            "PPD SIK",
            "PPD PENDANG"
        ]
    },
    {
        State: "MELAKA",
        Area: [
            "PPD ALOR GAJAH",
            "PPD JASIN",
            "PPD MELAKA TENGAH"
        ]
    },
    {
        State: "NEGERI SEMBILAN",
        Area: [
            "PPD JEMPOL/JELEBU",
            "PPD KUALA PILAH",
            "PPD PORT DICKSON",
            "PPD REMBAU",
            "PPD SEREMBAN",
            "PPD TAMPIN"
        ]
    },
    {
        State: "PULAU PINANG",
        Area: [
            "PPD SEBERANG PRAI TENGAH",
            "PPD TIMUR LAUT",
            "PPD BARAT DAYA",
            "PPD SEBERANG PERAI UTARA",
            "PPD SEBERANG PRAI SELATAN"
        ]
    },
    {
        State: "PERLIS",
        Area: [
            "JPN PERLIS"
        ]
    },
    {
        State: "TERENGGANU",
        Area: [
            "PPD BESUT",
            "PPD DUNGUN",
            "PPD KEMAMAN",
            "PPD KUALA TERENGGANU",
            "PPD MARANG",
            "PPD HULU TERENGGANU",
            "PPD SETIU",
            "PPD KUALA NERUS"
        ]
    },

    //EAST MALAYSIA.
    {
        State: "SABAH",
        Area: [
            "PPD KENINGAU",
            "PPD PENSIANGAN",
            "PPD TAMBUNAN",
            "PPD TENOM",
            "PPD SANDAKAN",
            "PPD KINABATANGAN",
            "PPD LABUK & SUGUT",
            "PPD LAHAD DATU",
            "PPD TONGOD",
            "PPD RANAU",
            "PPD TAWAU",
            "PPD KUNAK",
            "PPD SEMPORNA",
            "PPD KOTA KINABALU",
            "PPD PENAMPANG",
            "PPD PAPAR",
            "PPD TUARAN",
            "PPD KUDAT",
            "PPD KOTA MARUDU",
            "PPD PITAS",
            "PPD KOTA BELUD",
            "PPD BEAUFORT",
            "PPD SIPITANG",
            "PPD KUALA PENYU"
        ]
    },
    {
        State: "SARAWAK",
        Area: [
            "PPD BAU",
            "PPD KUCHING",
            "PPD PADAWAN",
            "PPD SAMARAHAN",
            "PPD LUNDU",
            "PPD SRI AMAN",
            "PPD BETONG",
            "PPD SARATOK",
            "PPD LUBOK ANTU",
            "PPD SIBU",
            "PPD DALAT",
            "PPD SELANGAU",
            "PPD KANOWIT",
            "PPD MUKAH",
            "PPD MIRI",
            "PPD BARAM",
            "PPD BINTULU",
            "PPD SUBIS",
            "PPD LIMBANG",
            "PPD LAWAS",
            "PPD DARO",
            "PPD SARIKEI",
            "PPD JULAU",
            "PPD MARADONG",
            "PPD KAPIT",
            "PPD BELAGA",
            "PPD SERIAN",
            "PPD SIMUNJAN",
            "PPD TATAU/SEBAUH",
            "PPD SONG"
        ]
    },

    //WILAYAH PERSEKUTUAN.
    {
        State: "WILAYAH PERSEKUTUAN KUALA LUMPUR",
        Area: [
            "PPW BANGSAR PUDU",
            "PPW KERAMAT",
            "PPW SENTUL"
        ]
    },
    {
        State: "WILAYAH PERSEKUTUAN LABUAN",
        Area: [
            "JPWP LABUAN"
        ]
    },
    {
        State: "WILAYAH PERSEKUTUAN PUTRAJAYA",
        Area: [
            "JPWP PUTRAJAYA"
        ]
    },
];