import React from 'react';
import {
    // osVersion, osName, browserName, fullBrowserVersion
    browserName
} from "react-device-detect";
import { ProgressBar } from 'react-bootstrap';
import { CommonState, UploadState, InputType, PermissionAccessType } from './GlobalSetting';
import Select from 'react-select';
import { Locale, Lang } from '../Localization/CustomLocalization';
import SchoolListWithDetails from './SchoolListWithDetails';
import moment from 'moment';

let tempTarget = null;

export function DoNothing() { }

export function Delay(ms) { return new Promise(res => setTimeout(res, ms)); }

export function DelayUntil(conditionFunction) {
    const poll = resolve => {
        if (conditionFunction()) resolve();
        else setTimeout(_ => poll(resolve), 500);
    }
    return new Promise(poll);
}

export function ScrollToElement(_elementName, delay = 300) {
    const elementName = CheckStringEmpty(_elementName);
    if (elementName === '')
        return null;
    var element = document.getElementById(elementName);
    if (element !== null) {
        setTimeout(() => {
            // element.parentNode.scrollTop = element.offsetTop;
            // window.scrollY(element.target.outerHeight);
            if (browserName.toLowerCase().includes('chrome'))
                element.scrollIntoView({ behavior: "smooth" });
            else
                element.scrollIntoView();
        }, delay);
    }
}

export function CheckNullValue(val) {
    if (val === undefined || val === null || String(val).trim() === '' || String(val) === 'null' || String(val) === 'undefined')
        return null;
    return val;
}

export function CheckObjectNullValue(obj, field) {
    // if (obj === undefined || obj === null || String(obj) === 'null' || String(obj) === 'undefined' || String(obj) === ''
    //     || field === undefined || field === null || String(field) === 'null' || String(field) === 'undefined' || String(field) === '')
    if (CheckNullValue(obj) === null || CheckNullValue(field) === null)
        return null;
    let val = obj[field];
    // if (val === undefined || val === null || val === '' || String(val) === 'null' || String(val) === 'undefined')
    if (CheckNullValue(val) === null)
        return null;
    return val;
}

export function CheckValueNA(val) {
    return CheckNullValue(val) === null ? '-n/a-' : val;
}

export function CheckStringEmpty(val, def = null) {
    return CheckNullValue(val) === null ? (def === null ? '' : String(def).trim()) : String(val).trim();
}

export function CheckBoolean(val) {
    //2023.10.26
    if (CheckNullValue(val) === null)
        return false;
    return CheckStringEmpty(val).trim().toLowerCase() === 'true' ? true : false;
}

export function CheckObjectStringEmpty(obj, val, def = null) {
    const defaultText = def === null ? '' : String(def).trim();
    return CheckObjectNullValue(obj, val) === null ? defaultText : CheckStringEmpty(obj[val], defaultText);
}

export function CheckObjectBoolean(obj, val, def = null) {
    return CheckObjectStringEmpty(obj, val).trim().toLowerCase() === 'true' ? true : (def === null ? false : CheckBoolean(def));
}

export function CheckObjectNumber(obj, val, def = null) {
    const value = CheckObjectStringEmpty(obj, val).trim();
    if (Number(value).toString() === 'NaN')
        return def === null ? 0 : Number(def);
    return Number(value);
}

//if not equal/not same = return true, if equal/same = return false.
export function CompareObjectValue_NotEqual(objA, objB, val) {
    // const _objA = CheckObjectNullValue(objA, val) === null ? '' :
    //     (typeof (objA[val]) === 'number' ? CheckObjectNumber(objA, val) :
    //         (typeof (objA[val]) === 'boolean' ? CheckObjectBoolean(objA, val) :
    //             CheckObjectStringEmpty(objA, val)));
    // const _objB = CheckObjectNullValue(objB, val) === null ? '' :
    //     (typeof (objB[val]) === 'number' ? CheckObjectNumber(objB, val) :
    //         (typeof (objB[val]) === 'boolean' ? CheckObjectBoolean(objB, val) :
    //             CheckObjectStringEmpty(objB, val)));
    let _objA = null;
    switch (typeof (objA[val])) {
        case 'number': _objA = CheckObjectNumber(objA, val); break;
        case 'boolean': _objA = CheckObjectBoolean(objA, val); break;
        default: _objA = CheckObjectStringEmpty(objA, val); break;
    }
    let _objB = null;
    switch (typeof (objB[val])) {
        case 'number': _objB = CheckObjectNumber(objB, val); break;
        case 'boolean': _objB = CheckObjectBoolean(objB, val); break;
        default: _objB = CheckObjectStringEmpty(objB, val); break;
    }
    if (_objA === null && _objB === null)
        return false;
    // console.log('CompareObjectValue_NotEqual', typeof (objA[val]), typeof (objB[val]), _objA, _objB, _objA !== _objB);
    return _objA !== _objB;
}

export function CompareValue_NotEqual(valA, valB) {
    const _valA = CheckNullValue(valA) === null ? '' :
        (typeof (valA) === 'number' ? Number(valA) :
            (typeof (valA) === 'boolean' ? (String(valA).toLowerCase() === 'true' ? true : false) :
                CheckStringEmpty(valA)));
    const _valB = CheckNullValue(valB) === null ? '' :
        (typeof (valB) === 'number' ? Number(valB) :
            (typeof (valB) === 'boolean' ? (String(valB).toLowerCase() === 'true' ? true : false) :
                CheckStringEmpty(valB)));
    return _valA !== _valB;
}

export function CheckUpdateValueDiffer(updateObj, editedObj, cachedObj, val) {
    // console.log('CheckUpdateValueDiffer ', val, editedObj[val], cachedObj[val], CompareObjectValue_NotEqual(editedObj, cachedObj, val));
    if (CompareObjectValue_NotEqual(editedObj, cachedObj, val)) {
        //value differ.
        if (typeof (editedObj[val]) === 'number')
            updateObj[val] = CheckObjectNumber(editedObj, val);
        else if (typeof (editedObj[val]) === 'boolean')
            updateObj[val] = CheckObjectBoolean(editedObj, val);
        else if (typeof (editedObj[val]) === 'string')
            updateObj[val] = CheckObjectStringEmpty(editedObj, val);
        else
            updateObj[val] = editedObj[val];
    }
    else {
        //same value.
        updateObj[val] = cachedObj[val];
    }
    // console.log('CheckUpdateValueDiffer ', updateObj[val], val, editedObj[val], cachedObj[val], CompareObjectValue_NotEqual(editedObj, cachedObj, val));
    return updateObj;
}

export function GetPropIds(props) {
    let centerUserId = 0;
    let authorId = 0;
    let authorRoleId = 0;
    let organizerId = 0;
    let organizerDiplayName = '';
    let organizerIdentity = '';
    let organizerName = '';
    let uid = '';
    let email = '';
    if (CheckObjectNullValue(props, 'user') !== null) {
        if (CheckObjectNullValue(props.user, 'uid') !== null)
            uid = String(props.user.uid);
        if (CheckObjectNullValue(props.user, 'email') !== null)
            email = String(props.user.email);
        if (CheckObjectNullValue(props.user, 'CenterUserId') !== null)
            centerUserId = Number(props.user.CenterUserId);
        if (CheckObjectNullValue(props.user, 'AuthorId') !== null)
            authorId = Number(props.user.AuthorId);
        if (CheckObjectNullValue(props.user, 'AuthorRoleId') !== null)
            authorRoleId = Number(props.user.AuthorRoleId);
    }
    if (CheckObjectBoolean(props, 'OrganizerInfoIsLoaded') === true)
        if (CheckObjectNullValue(props, 'OrganizerInfo') !== null) {
            if (CheckObjectNullValue(props.OrganizerInfo, 'OrganizerId') !== null)
                organizerId = Number(props.OrganizerInfo.OrganizerId);
            if (CheckObjectNullValue(props.OrganizerInfo, 'DisplayName') !== null)
                organizerDiplayName = String(props.OrganizerInfo.DisplayName);
            if (CheckObjectNullValue(props.OrganizerInfo, 'Identity') !== null)
                organizerIdentity = String(props.OrganizerInfo.Identity);
            if (CheckObjectNullValue(props.OrganizerInfo, 'Name') !== null)
                organizerName = String(props.OrganizerInfo.Name);
        }
    return { uid, centerUserId, authorId, authorRoleId, organizerId, organizerDiplayName, organizerIdentity, organizerName, email };
}

//Panel Menu.
export function BlockInvalidPermissionFeatureAccess(props, section = '', action = PermissionAccessType.None) {

    //return true = block access.
    //return false = allow access.

    if (CheckNullValue(props) === null || CheckNullValue(props.user) === null)
        return true;

    // const viewerAccess = !CheckBoolean(props.user.IsViewer) || props.OrganizerInfo === null;

    // const organizerAccess = !CheckBoolean(props.user.IsOrganizer) || props.OrganizerInfo === null;

    const isAdminAccess = CheckNullValue(props.isAdmin) === null ? false : CheckBoolean(props.isAdmin);
    if (isAdminAccess)
        return false;

    const permissions = CheckNullValue(props.user.CustomPermissions) === null ? null
        : props.user.CustomPermissions;

    // console.log('BlockInvalidPermissionFeatureAccess', Array.isArray(permissions), section, action, JSON.stringify(permissions));
    // console.log(permissions === undefined, permissions === null, Array.isArray(permissions), section === '', action === '');
    if (CheckNullValue(permissions) === null || !Array.isArray(permissions) || CheckNullValue(section) === null || CheckNullValue(action) === null)
        return true;

    const entries = Object.values(permissions);
    let keyIndex = -1;
    for (let i = 0; i < entries.length; i++) {
        // console.log(JSON.stringify(Object.keys(entries[i])[0]));
        // console.log(JSON.stringify(Object.values(entries[i])[0]));
        const _section = Object.keys(entries[i])[0];
        // const _entry = Object.values(entries[i])[0];
        if (_section === section) {
            keyIndex = i;
            break;
        }
    }
    // console.log('BlockInvalidPermissionFeatureAccess', keyIndex, JSON.stringify(permissions[keyIndex][section][action]));
    if (keyIndex > -1) {
        if (CheckNullValue(permissions[keyIndex]) === null)
            return true;
        if (CheckNullValue(permissions[keyIndex][section]) === null)
            return true;
        if (CheckNullValue(permissions[keyIndex][section][action]) === null)
            return true;
        // console.log('BlockInvalidPermissionFeatureAccess', !CheckBoolean(permissions[keyIndex][section][action]), keyIndex, section, action);
        return !CheckBoolean(permissions[keyIndex][section][action]);
    }
    return true;
}

export function UploadStatusMessage(uploadState = UploadState.None, uploadStatus = '', uniqueId = '') {
    if (uploadState !== UploadState.None) {
        switch (uploadState) {
            // case UploadState.Converting:
            //     return (<ProgressBar animated now={100} className='progressbar1' />);
            case UploadState.Validation:
                return (<>
                    <span>Processing File Conversion & Validation...</span>
                    <br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                </>);
            case UploadState.Processing:
                return (<>
                    {uploadStatus === '' ? null : <div dangerouslySetInnerHTML={{ __html: uploadStatus }} />}
                    <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                </>);
            case UploadState.Saving:
                return (<>
                    <span>Now Saving...</span>
                    <br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                </>);
            case UploadState.ConvertFailed:
                return (<>
                    <span>File Conversion & Validation Failed.</span>
                    {CheckNullValue(uploadStatus) === null ? null : <div dangerouslySetInnerHTML={{ __html: uploadStatus }} />}
                </>);
            case UploadState.Success:
                return (<>
                    {CheckNullValue(uniqueId) === null ? null : <><span style={{ fontSize: 12, color: 'gray' }}>{uniqueId}</span><br /></>}
                    {/* <span>File Upload Success.</span> */}
                    {CheckNullValue(uploadStatus) === null ? null : <div dangerouslySetInnerHTML={{ __html: uploadStatus }} />}
                </>);
            case UploadState.Failed:
                return (<>
                    <span>File Upload Failed.</span>
                    {CheckNullValue(uploadStatus) === null ? null : <div dangerouslySetInnerHTML={{ __html: uploadStatus }} />}
                </>);
            default:
                return null;
        }
    }
    return null;
}

export function CommonStatusMessage(commonState = CommonState.None, commonStatus = null) {
    if (commonState !== CommonState.None) {
        switch (commonState) {

            case UploadState.Processing:
                if (typeof (commonStatus) === 'object')
                    return (commonStatus);
                else if (typeof (commonStatus) === 'string')
                    return (<div dangerouslySetInnerHTML={{ __html: commonStatus }} />);
                else
                    return (<>{'Processing...'}</>);

            case UploadState.Success:
                if (typeof (commonStatus) === 'object')
                    return (commonStatus);
                else if (typeof (commonStatus) === 'string')
                    return (<div dangerouslySetInnerHTML={{ __html: commonStatus }} />);
                else
                    return (<>{'Done.'}</>);

            case UploadState.Failed:
                if (typeof (commonStatus) === 'object')
                    return (commonStatus);
                else if (typeof (commonStatus) === 'string')
                    return (<div dangerouslySetInnerHTML={{ __html: commonStatus }} />);
                else
                    return (<>{'Failed.'}</>);

            default:
                return null;
        }
    }
    return null;
}

export function DownloadTxtFile(dataSet, filename) {
    let element = document.createElement("a");
    const file = new Blob([JSON.stringify(dataSet)], {
        type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = filename + '.txt';
    document.body.appendChild(element);
    element.click();
    element = null;
};

//2023.09.15
export function GenderOptions(locale) {
    if (CheckNullValue(locale) === null)
        return null;

    return [
        { value: Locale("label-gender-male", Lang.English), label: Locale("label-gender-male", locale) },
        { value: Locale("label-gender-female", Lang.English), label: Locale("label-gender-female", locale) },
        { value: Locale("label-gender-other", Lang.English), label: Locale("label-gender-other", locale) },
    ]
};
export function RaceOptions(locale) {
    if (CheckNullValue(locale) === null)
        return null;

    return [
        { value: Locale("label-race-1", Lang.English), label: Locale("label-race-1", locale) },
        { value: Locale("label-race-2", Lang.English), label: Locale("label-race-2", locale) },
        { value: Locale("label-race-3", Lang.English), label: Locale("label-race-3", locale) },
        { value: Locale("label-race-0", Lang.English), label: Locale("label-race-0", locale) },
    ]
};
export function GradeOptions(locale) {
    if (CheckNullValue(locale) === null)
        locale = Lang.English;

    const GetStandard = (idx) => {
        let text = "";
        if (locale === Lang.Chinese)
            text = idx + " " + Locale("standard", locale);
        else
            text = Locale("standard", locale) + " " + idx;
        return text;
    }
    const GetForm = (idx) => {
        let text = "";
        if (locale === Lang.Chinese) {
            if (idx > 0 && idx < 4)
                text = Locale("form-123", locale) + " " + idx + " (" + Locale("form", locale) + idx + ")";
            else if (idx > 3 && idx < 6)
                text = Locale("form-45", locale) + " " + (idx - 3) + " (" + Locale("form", locale) + idx + ")";
            else if (idx === 6)
                text = Locale("form-6", locale) + " (" + Locale("form", locale) + idx + ")";
        }
        else {
            text = Locale("form", locale) + " " + idx;
        }
        return text;
    }

    let gradeOptions = [];

    [1, 2, 3, 4, 5, 6].map((stdIdx) => (
        gradeOptions.push({ value: 'Standard ' + stdIdx, label: GetStandard(stdIdx), id: stdIdx, })
    ));

    [1, 2, 3, 4, 5, 6].map((stdIdx) => (
        gradeOptions.push({ value: 'Form ' + stdIdx, label: GetForm(stdIdx), id: stdIdx + 10, })
    ));

    gradeOptions.push({ value: 'Other', label: Locale("other", locale), id: 17, });

    gradeOptions.push({ value: 'Pre-School', label: Locale("pre-school", locale), id: 31, });

    //2022.05.12
    [1, 2, 3].map((stdIdx) => (
        gradeOptions.push({ value: 'KD ' + (stdIdx + 3), label: Locale("kindergarden", locale) + ' ' + (stdIdx + 3) + ' (' + (stdIdx + 3) + Locale("years-old", locale) + ')', id: stdIdx + 27, })
    ));

    return gradeOptions;
}
export function GetOptionsLabel(options, value) {
    let label = '';
    if (options.length > 0 && value !== '') {
        let index = options.findIndex(x => x.value === value);
        if (index > -1)
            label = options[index].label;
        // console.log('index : ' + index + '\nlabel : ' + label + '\nvalue : ' + value + '\n' + JSON.stringify(options[index]));
    }
    return label;
}
export function GetTempTarget(field = null) {
    if (tempTarget !== null && field !== undefined && field !== null)
        return CheckNullValue(tempTarget[field]);
    return tempTarget;
}
export function SetTempTarget(target) {
    tempTarget = target;
}
export function GetInputComponent(
    inputType = InputType.None, options = [],
    target = null, fieldName = '', preConditionField = '', defaultPlaceholderLocale = '',
    locale = Lang.English,
    callbackSaveTarget = null,
    otherCallback = null,
    readOnly = false,
    styleObj = null,
    nestedKey = -1
) {
    if (inputType === InputType.None || CheckNullValue(target) === null || CheckNullValue(fieldName) === null || CheckNullValue(locale) === null)
        return null;

    switch (inputType) {
        case InputType.Text:
            const value_Text = readOnly ? CheckObjectNullValue(target, fieldName) : CheckObjectNullValue(tempTarget, fieldName);
            return (<input
                name={fieldName}
                className={"form-control" + (value_Text === null ? " highlight" : "")}
                type="text"
                onChange={(val) => {
                    target[fieldName] = String(val.target.value);
                    tempTarget = target;
                    // console.log('Text', fieldName, tempTarget[fieldName]);
                    if (callbackSaveTarget !== undefined && callbackSaveTarget !== null)
                        callbackSaveTarget(tempTarget);
                    // if (otherCallback !== undefined && otherCallback !== null)
                    //     otherCallback();
                }}
                value={value_Text === null ? '' : value_Text}
                placeholder={value_Text === null ? Locale(defaultPlaceholderLocale, locale) : value_Text}
                disabled={readOnly}
                style={styleObj === null ? {} : styleObj}
            />);

        case InputType.Number:
            const value_Number = readOnly ? CheckObjectNumber(target, fieldName) : CheckObjectNumber(tempTarget, fieldName);
            return (<input
                name={fieldName}
                className={"form-control" + (value_Number === null ? " highlight" : "")}
                type="number"
                onChange={(val) => {
                    target[fieldName] = Number(val.target.value);
                    tempTarget = target;
                    // console.log('Text', fieldName, tempTarget[fieldName]);
                    if (callbackSaveTarget !== undefined && callbackSaveTarget !== null)
                        callbackSaveTarget(tempTarget);
                }}
                value={value_Number}
                // value={value_Number === 0 ? 0 : value_Number}
                // placeholder={value_Number === 0 ? Locale(defaultPlaceholderLocale, locale) : value_Number}
                disabled={readOnly}
                style={styleObj === null ? {} : styleObj}
            />);

        case InputType.Select:
            const value_Select = readOnly ? CheckObjectNullValue(target, fieldName) : CheckObjectNullValue(tempTarget, fieldName);
            return (<Select
                classNamePrefix={'r-select'}
                className={value_Select === null ? "select-highlight" : ""}
                options={options}
                onChange={(val) => {
                    target[fieldName] = String(val.value);
                    tempTarget = target;
                    // console.log('Select', fieldName, tempTarget[fieldName]);
                    if (callbackSaveTarget !== undefined && callbackSaveTarget !== null)
                        callbackSaveTarget(tempTarget);
                    if (otherCallback !== undefined && otherCallback !== null)
                        if (fieldName === 'nationalState')
                            otherCallback(false);
                }}
                value={value_Select === null ? '' : value_Select}
                placeholder={value_Select === null ? Locale(defaultPlaceholderLocale, locale) : value_Select}
                isDisabled={readOnly || (CheckNullValue(preConditionField) === null ? false : CheckObjectNullValue(tempTarget, preConditionField) === null)}
                style={styleObj === null ? {} : styleObj}
            />);

        case InputType.Checkbox:
            let value_Checkbox_Text = null;
            if (nestedKey < 0)
                value_Checkbox_Text = readOnly ? CheckObjectNullValue(target, fieldName) : CheckObjectNullValue(tempTarget, fieldName);
            else
                value_Checkbox_Text = readOnly ?
                    (CheckObjectNullValue(target, fieldName) === null ? 'false' : CheckNullValue(target[fieldName][nestedKey]))
                    :
                    (CheckObjectNullValue(tempTarget, fieldName) === null ? 'false' : CheckNullValue(tempTarget[fieldName][nestedKey]));

            let value_Checkbox = false;
            if (value_Checkbox_Text !== null || CheckObjectNullValue(value_Checkbox_Text, 'selected') !== null) {
                if (CheckObjectNullValue(value_Checkbox_Text, 'selected') !== null)
                    value_Checkbox = CheckObjectBoolean(value_Checkbox_Text, 'selected');
                else
                    value_Checkbox = String(value_Checkbox_Text).toLowerCase() === 'true' ? true : false;
            }

            return (<input
                name={fieldName}
                className={"form-control" + (value_Checkbox === null ? " highlight" : "")}
                type="checkbox"
                onClick={(val) => {
                    if (readOnly === false) {

                        if (nestedKey < 0)
                            target[fieldName] = val.currentTarget.checked;
                        else
                            target[fieldName][nestedKey].selected = val.currentTarget.checked;

                        tempTarget = target;
                        console.log('Checkbox', fieldName, JSON.stringify(tempTarget[fieldName]));

                        if (callbackSaveTarget !== undefined && callbackSaveTarget !== null)
                            callbackSaveTarget(tempTarget);
                    }
                }}
                checked={value_Checkbox
                    // nestedKey < 0 ?
                    //     CheckObjectNullValue(tempTarget, fieldName) === null ? false : (String(tempTarget[fieldName]).toLowerCase() === 'true' ? true : false)
                    //     :
                    //     CheckObjectNullValue(tempTarget, fieldName) === null ? false
                    //         : CheckNullValue(tempTarget[fieldName][nestedKey].selected) === null ? false
                    //             : CheckBoolean(tempTarget[fieldName][nestedKey].selected)
                }
                readOnly={true}
                style={styleObj === null ? { width: 20, height: 20 } : styleObj}
                disabled={readOnly}
            />);

        default: return null;
    }
}

// //2023.11.15
// export function Profile_CompareUpdateProperties(base, fetched) {
//     const original = JSON.stringify(base);





//     const dataJson = JSON.stringify(base);
//     const isUpdated = dataJson !== original;
//     if (isUpdated)
//         base['LastUpdate'] = moment.utc().format('YYYY-MM-DD HH:mm:ss');

//     const profile = isUpdated ? base : JSON.parse(original);
//     return { profile, isUpdated };
// }

//2023.11.15
export function ApiProfileToProfileModal(fetched_profile) {
    let profile = {};

    profile['LastUpdate'] = CheckObjectStringEmpty(fetched_profile, 'lastUpdateOnUtc', CheckObjectStringEmpty(fetched_profile, 'LastUpdateOnUtc'));
    profile['Uid'] = CheckObjectStringEmpty(fetched_profile, 'firebaseUserId', CheckObjectStringEmpty(fetched_profile, 'lastUpdateOnUtc'));
    profile['Email'] = CheckObjectStringEmpty(fetched_profile, 'email', CheckObjectStringEmpty(fetched_profile, 'Email'));
    profile['Name'] = CheckObjectStringEmpty(fetched_profile, 'name', CheckObjectStringEmpty(fetched_profile, 'Name'));
    profile['Center'] = CheckObjectStringEmpty(fetched_profile, 'center', CheckObjectStringEmpty(fetched_profile, 'Center'));
    profile['Gender'] = CheckObjectStringEmpty(fetched_profile, 'gender', CheckObjectStringEmpty(fetched_profile, 'Gender', 'Other'));
    profile['Guardian'] = CheckObjectStringEmpty(fetched_profile, 'guardian', CheckObjectStringEmpty(fetched_profile, 'Guardian'));
    profile['Classroom'] = CheckObjectStringEmpty(fetched_profile, 'classroom', CheckObjectStringEmpty(fetched_profile, 'Classroom'));

    profile['NationalState'] = CheckObjectStringEmpty(fetched_profile, 'nationalState', CheckObjectStringEmpty(fetched_profile, 'NationalState'));
    profile['DistrictArea'] = CheckObjectStringEmpty(fetched_profile, 'districtArea', CheckObjectStringEmpty(fetched_profile, 'DistrictArea'));

    profile['PolicyTncAgree'] = CheckObjectBoolean(fetched_profile, 'policyTncAgree', CheckObjectBoolean(fetched_profile, 'PolicyTncAgree'));
    profile['PolicyTncAgreeDateTime'] = CheckObjectStringEmpty(fetched_profile, 'policyTncAgreeDateTime', CheckObjectStringEmpty(fetched_profile, 'PolicyTncAgreeDateTime'));

    profile['Race'] = CheckObjectStringEmpty(fetched_profile, 'race', CheckObjectStringEmpty(fetched_profile, 'Race', 'Other'));
    profile['School'] = CheckObjectStringEmpty(fetched_profile, 'school', CheckObjectStringEmpty(fetched_profile, 'School', 'OTHER'));
    profile['Score'] = CheckObjectNumber(fetched_profile, 'score', CheckObjectNumber(fetched_profile, 'Score'));
    profile['Coin'] = CheckObjectNumber(fetched_profile, 'coin', CheckObjectNumber(fetched_profile, 'Coin'));
    profile['AchievementPoint'] = CheckObjectNumber(fetched_profile, 'achievementPoint', CheckObjectNumber(fetched_profile, 'AchievementPoint'));

    profile['ICNo'] = CheckObjectStringEmpty(fetched_profile, 'iCNo', CheckObjectStringEmpty(fetched_profile, 'ICNo'));
    profile['Mode'] = CheckObjectStringEmpty(fetched_profile, 'mode', CheckObjectStringEmpty(fetched_profile, 'Mode'));

    profile['Grade'] = CheckObjectStringEmpty(fetched_profile, 'grade', CheckObjectStringEmpty(fetched_profile, 'Grade', 'Other'));
    profile['Below13'] = CheckObjectBoolean(fetched_profile, 'below13') ? 'true' : 'false';

    profile['Contact'] = CheckObjectStringEmpty(fetched_profile, 'contact', CheckObjectStringEmpty(fetched_profile, 'Contact'));
    if (
        CheckObjectNullValue(fetched_profile, 'ContactNumber') !== null
        || CheckObjectNullValue(fetched_profile, 'contactNumber') !== null
    ) {
        profile['Contact'] = CheckObjectStringEmpty(fetched_profile, 'contactNumber', CheckObjectStringEmpty(fetched_profile, 'ContactNumber'));
    }

    return profile;
}

//2023.11.15 - revamped.
//2023.10.19/20
export function Profile_ValidateProperties(user, data) {
    if (data === null)
        data = {};
    const original = JSON.stringify(data);

    data['LastUpdate'] = CheckObjectStringEmpty(data, 'LastUpdate');
    data['Uid'] = CheckObjectStringEmpty(data, 'Uid', CheckObjectStringEmpty(data, 'uid', CheckObjectStringEmpty(user, 'uid', CheckObjectStringEmpty(user, 'Uid'))));
    data['Email'] = CheckObjectStringEmpty(data, 'Email', CheckObjectStringEmpty(data, 'email', CheckObjectStringEmpty(user, 'email', CheckObjectStringEmpty(user, 'Email'))));
    data['Name'] = CheckObjectStringEmpty(data, 'Name', CheckObjectStringEmpty(data, 'name', CheckObjectStringEmpty(user, 'Name', CheckObjectStringEmpty(user, 'name'))));
    data['Center'] = CheckObjectStringEmpty(data, 'Center', CheckObjectStringEmpty(data, 'center', CheckObjectStringEmpty(user, 'Center', CheckObjectStringEmpty(user, 'center'))));
    data['Gender'] = CheckObjectStringEmpty(data, 'Gender', CheckObjectStringEmpty(data, 'gender', CheckObjectStringEmpty(user, 'Gender', CheckObjectStringEmpty(user, 'gender', 'Other'))));
    data['Guardian'] = CheckObjectStringEmpty(data, 'Guardian', CheckObjectStringEmpty(data, 'guardian', CheckObjectStringEmpty(user, 'Guardian', CheckObjectStringEmpty(user, 'guardian'))));
    data['Classroom'] = CheckObjectStringEmpty(data, 'Classroom', CheckObjectStringEmpty(data, 'classroom', CheckObjectStringEmpty(user, 'Classroom', CheckObjectStringEmpty(user, 'classroom'))));

    data['NationalState'] = CheckObjectStringEmpty(data, 'NationalState', CheckObjectStringEmpty(data, 'nationalState', CheckObjectStringEmpty(user, 'NationalState', CheckObjectStringEmpty(user, 'nationalState'))));
    data['DistrictArea'] = CheckObjectStringEmpty(data, 'DistrictArea', CheckObjectStringEmpty(data, 'districtArea', CheckObjectStringEmpty(user, 'DistrictArea', CheckObjectStringEmpty(user, 'districtArea'))));

    data['PolicyTncAgree'] = CheckObjectBoolean(data, 'PolicyTncAgree', CheckObjectBoolean(data, 'policyTncAgree', CheckObjectBoolean(user, 'PolicyTncAgree', CheckObjectBoolean(user, 'policyTncAgree'))));
    data['PolicyTncAgreeDateTime'] = CheckObjectStringEmpty(data, 'PolicyTncAgreeDateTime', CheckObjectStringEmpty(data, 'PolicyTncAgreeDateTime', CheckObjectStringEmpty(user, 'PolicyTncAgreeDateTime', CheckObjectStringEmpty(user, 'policyTncAgreeDateTime'))));

    data['Race'] = CheckObjectStringEmpty(data, 'Race', CheckObjectStringEmpty(data, 'race', CheckObjectStringEmpty(user, 'Race', CheckObjectBoolean(user, 'race', 'Other'))));
    data['School'] = CheckObjectStringEmpty(data, 'School', CheckObjectStringEmpty(data, 'school', CheckObjectStringEmpty(user, 'School', CheckObjectBoolean(user, 'school', 'OTHER'))));
    data['Score'] = CheckObjectNumber(data, 'Score', CheckObjectStringEmpty(data, 'score', CheckObjectNumber(user, 'Score', CheckObjectNumber(user, 'score'))));
    data['Coin'] = CheckObjectNumber(data, 'Coin', CheckObjectStringEmpty(data, 'coin', CheckObjectNumber(user, 'Coin', CheckObjectNumber(user, 'coin'))));
    data['AchievementPoint'] = CheckObjectNumber(data, 'AchievementPoint', CheckObjectNumber(data, 'achievementPoint', CheckObjectNumber(user, 'AchievementPoint', CheckObjectNumber(user, 'achievementPoint'))));

    data['ICNo'] = CheckObjectStringEmpty(data, 'ICNo', CheckObjectStringEmpty(data, 'iCNo', CheckObjectStringEmpty(user, 'ICNo', CheckObjectBoolean(user, 'iCNo'))));
    data['Mode'] = CheckObjectStringEmpty(data, 'Mode', CheckObjectStringEmpty(data, 'mode', CheckObjectStringEmpty(user, 'Mode', CheckObjectBoolean(user, 'mode'))));

    data['Grade'] = CheckObjectStringEmpty(data, 'Grade', CheckObjectStringEmpty(data, 'grade', CheckObjectStringEmpty(user, 'Grade', CheckObjectStringEmpty(user, 'grade', 'Other'))));
    data['Below13'] = CheckObjectBoolean(data, 'Below13', CheckObjectBoolean(data, 'below13', CheckObjectBoolean(user, 'Below13', CheckObjectBoolean(user, 'below13'))));  //? 'true' : 'false';

    data['Contact'] = CheckObjectStringEmpty(data, 'Contact', CheckObjectStringEmpty(data, 'contact', CheckObjectStringEmpty(user, 'Contact', CheckObjectStringEmpty(user, 'contact'))));
    if (
        CheckObjectNullValue(data, 'ContactNumber') !== null || CheckObjectNullValue(data, 'contactNumber') !== null
        || CheckObjectNullValue(user, 'ContactNumber') !== null || CheckObjectNullValue(user, 'contactNumber') !== null
    ) {
        if (CheckObjectNullValue(data, 'Contact') === null)
            data['Contact'] = CheckObjectStringEmpty(data, 'ContactNumber', CheckObjectStringEmpty(data, 'contactNumber', CheckObjectStringEmpty(user, 'ContactNumber', CheckObjectStringEmpty(user, 'contactNumber'))));
    }

    //2023.12.06
    if (CheckObjectNullValue(data, 'LastUpdatedOnUtc') !== null)
        data['LastUpdate'] = moment(data.LastUpdatedOnUtc).local().format('lll');
    else if (CheckObjectNullValue(data, 'lastUpdatedOnUtc') !== null)
        data['LastUpdate'] = moment(data.lastUpdatedOnUtc).local().format('lll');

    //2023.12.06 - backup script.
    let organizerMappingsText = 'OrganizerMappings';
    if (CheckObjectNullValue(data, 'organizerMappings') !== null)
        organizerMappingsText = 'organizerMappings';
    // console.log('organizerMappingsText', organizerMappingsText);
    if (CheckNullValue(organizerMappingsText) !== null) {
        let organizerMappings = data[organizerMappingsText];
        // console.log('organizerMappings', JSON.stringify(organizerMappings));
        if (Array.isArray(organizerMappings)) {
            for (let om = 0; om < organizerMappings.length; om++) {
                organizerMappings[om] = CapitalizeJsonKeys(organizerMappings[om]);
            }
            data['OrganizerMappings'] = organizerMappings;
        }
        if (organizerMappingsText === 'organizerMappings')
            delete data.organizerMappings;
    }
    if (CheckObjectNullValue(data, 'OrganizerMappings') === null)
        data['OrganizerMappings'] = [];

    //remove.
    data = RemoveUnwantedProperties(data);

    //2023.10.31 - check on School format.
    const schoolName = CheckObjectStringEmpty(data, 'School');
    if (schoolName !== 'OTHER' && schoolName !== '' && schoolName.includes(' - ') === false) {
        let index = -1;
        const schools = SchoolListWithDetails.filter(x => x.Name === schoolName);
        if (schools.length > 0) {
            if (schools.length === 1) {
                index = 0;
            }
            else {
                index = schools.findIndex(x => x.NationalState === CheckObjectStringEmpty(data, 'NationalState'));
                if (index < 0)
                    index = schools.findIndex(x => x.PPD === CheckObjectStringEmpty(data, 'PPD'));
            }
        }
        if (index > -1) {
            let ppd = schools[index].PPD.split(' ');
            ppd.shift();
            data['School'] = schools[index].Name + ' - ' + schools[index].NationalState + ' - ' + ppd.join(' ');
        }
    }

    //finalize.
    const dataJson = JSON.stringify(data);
    const profile = JSON.parse(dataJson);
    const isUpdateToFirestoreNeeded = dataJson !== original;
    return { profile, isUpdateToFirestoreNeeded };
}

function RemoveUnwantedProperties(data) {

    delete data.ContactNumber;
    delete data.contactNumber;

    delete data.CSR;
    delete data.cSR;

    delete data.Pemulihan;
    delete data.pemulihan;

    delete data.Tuition;
    delete data.tuition;

    delete data.RawPassword;
    delete data.rawPassword;

    delete data.EventCode;
    delete data.eventCode;

    delete data.CreatedDate;
    delete data.createdDate;

    delete data.IsOrganizerStudent;
    delete data.isOrganizerStudent;

    delete data.CustomGroup;
    delete data.customGroup;

    delete data.FirebaseUserId;
    delete data.firebaseUserId;

    //2023.12.06
    delete data.MarkAsDeleted;
    delete data.CreatedOnUtc;
    delete data.CreatedByUserId;
    delete data.LastUpdatedOnUtc;
    delete data.UpdatedByUserId;
    delete data.DeletedOnUtc;
    delete data.DeletedByUserId;

    // if (CheckObjectNullValue(data, 'CSR') !== null)
    //     delete data.CSR;
    // if (CheckObjectNullValue(data, 'Pemulihan') !== null)
    //     delete data.Pemulihan;
    // if (CheckObjectNullValue(data, 'Tuition') !== null)
    //     delete data.Tuition;
    // if (CheckObjectNullValue(data, 'RawPassword') !== null)
    //     delete data.RawPassword;
    // if (CheckObjectNullValue(data, 'EventCode') !== null)
    //     delete data.EventCode;
    // if (CheckObjectNullValue(data, 'CreatedDate') !== null)
    //     delete data.CreatedDate;
    // if (CheckObjectNullValue(data, 'IsOrganizerStudent') !== null)
    //     delete data.IsOrganizerStudent;
    // if (CheckObjectNullValue(data, 'RawPassword') !== null)
    //     delete data.RawPassword;
    // if (CheckObjectNullValue(data, 'CustomGroup') !== null)
    //     delete data.CustomGroup;
    // if (CheckObjectNullValue(data, 'FirebaseUserId') !== null)
    //     delete data.FirebaseUserId;

    return data;
}

//2023.11.22
export function CapitalizeJsonKeys(obj) {
    // for (var key in obj) {
    //     delete Object.assign(obj, { [key.charAt(0).toUpperCase() + key.slice(1)]: obj[key] })[key];
    //     // if (obj.hasOwnProperty(key)) {
    //     //     obj[key.charAt(0).toUpperCase() + key.slice(1)] = obj[key];
    //     //     delete obj[key];
    //     // }
    // }

    //2023.12.06
    if (Array.isArray(obj)) {
        return obj.map(CapitalizeJsonKeys);
    } else if (typeof obj === 'object') {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                obj[key.charAt(0).toUpperCase() + key.slice(1)] = CapitalizeJsonKeys(obj[key]);
                if (key.charAt(0).toUpperCase() + key.slice(1) !== key) {
                    delete obj[key];
                }
            }
        }
    }
    return obj;
}

//2023.11.27
export function FormatList_QuestionSet(props, _List = []) {
    if (CheckNullValue(props) === null || CheckNullValue(_List) === null)
        return null;

    if (_List.length > 0) {
        _List.map((data, key) => {
            if (CheckNullValue(data) !== null) {

                //2023.11.23
                if (data.hasOwnProperty('SubjectId')) {
                    const findIndex = props.SubjectOptions.findIndex(x => Number(x.id) === CheckObjectNumber(data, 'SubjectId'));
                    if (findIndex > -1)
                        data['Subject'] = { Id: props.SubjectOptions[findIndex].id, Name: props.SubjectOptions[findIndex].value, Label: props.SubjectOptions[findIndex].label };     //default
                }
                if (data.hasOwnProperty('Subject') === false)
                    data['Subject'] = { Id: props.SubjectOptions[0].id, Name: props.SubjectOptions[0].value, Label: props.SubjectOptions[0].label };     //default
                else
                    if (CheckObjectNumber(data.Subject, 'Id') === 0)
                        data['Subject'] = { Id: props.SubjectOptions[0].id, Name: props.SubjectOptions[0].value, Label: props.SubjectOptions[0].label };     //default

                //2023.11.23
                if (data.hasOwnProperty('GroupId')) {
                    const findIndex = props.GroupOptions.findIndex(x => Number(x.id) === CheckObjectNumber(data, 'GroupId'));
                    if (findIndex > -1)
                        data['Group'] = { Id: props.GroupOptions[findIndex].id, Name: props.GroupOptions[findIndex].value };     //default
                }
            }
            return data;
        });
    }
    return _List;
}

// //2023.12.14
// export function Populate_GroupOptions(groupList = [], props = null) {
//     if (groupList.length === 0)
//         return null;

//     let options = [];
//     groupList.map((data, key) => {
//         const _remark = CheckObjectNullValue(data, 'Remark') === null ? '' : ' ' + CheckObjectStringEmpty(data, 'remark');
//         const _name = CheckObjectStringEmpty(data, 'Name', CheckObjectStringEmpty(data, 'name'));
//         const _id = CheckObjectNumber(data, 'Id', CheckObjectNumber(data, 'id'));
//         return options.push({ value: _name, label: _name + _remark, id: _id });
//     });
//     if (props !== null)
//         if (props.isDevMode)
//             console.log('Populate_GroupOptions', JSON.stringify(options));
//     return options;
// }

//2023.11.29
export async function TriggerDownloadFile(props = null, url = '', fileName = '', fileExt = '', locale = null) {
    //download file.
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName + fileExt;
    // a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    await Delay(200);
    //reset.
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    //alert.
    if (props !== null) {
        props.SetAlert(
            Locale("label-file-download-success", (locale === null ? props.Locale : 'en')),
            Locale("notice-file-download-success", (locale === null ? props.Locale : 'en'))
        );
    }
}

//#region === Paging Components
function NavigatePage(totalRows = 0, pageIndex = 0, pageSize = 0, action = '', cbFnPageIndex = null) {
    if (action === 'prev') {
        pageIndex = pageIndex - pageSize;
        if (pageIndex < 0)
            pageIndex = 0;
    }
    else if (action === 'next') {
        pageIndex = pageIndex + pageSize;
        if (pageIndex >= totalRows)
            pageIndex = Math.round(totalRows / pageSize) * pageSize;
    }
    else if (action === 'first') {
        pageIndex = 0;
    }
    else if (action === 'last') {
        pageIndex = Number((totalRows / pageSize).toFixed(0)) * pageSize;
        if (pageIndex >= totalRows)
            pageIndex = (Number((totalRows / pageSize).toFixed(0)) - 1) * pageSize;
    }
    else {
        pageIndex = Number(action);
    }
    if (window.location.href.includes('localhost'))
        console.log('NavigatePage', pageIndex);
    if (cbFnPageIndex !== null)
        cbFnPageIndex(pageIndex);
}
function PagingNavigationComponents(totalRows = 0, pageIndex = 0, pageSize = 0, cbFnPageIndex = null) {
    let components = [];
    let temp = [];

    let totalPage = Number((totalRows / pageSize).toFixed(0));
    const maxSize = totalPage * pageSize;
    let currentPage = 1;
    if (totalRows > maxSize || totalPage === 0)
        totalPage += 1;
    for (let i = 0; i < totalPage; i++) {
        const _pageIndex = i * pageSize;
        temp.push({ page: (i + 1), pageIndex: _pageIndex });
        if (pageIndex === _pageIndex)
            currentPage = (i + 1);
    }
    const findCurrentPageIndex = temp.findIndex(x => x.page === currentPage);
    let page_StartIndex = 0;
    let page_EndtIndex = pageSize;
    if (findCurrentPageIndex > -1) {
        const page = temp[findCurrentPageIndex].page;
        page_StartIndex = page - 5;
        page_EndtIndex = page + 5;
        if (page_StartIndex < 0) {
            page_StartIndex = 0;
            page_EndtIndex = 10;
        }
        if (page_EndtIndex > totalPage) {
            page_EndtIndex = totalPage;
            page_StartIndex = totalPage - 10;
        }
    }
    // console.log(totalPage, currentPage, page_StartIndex, page_EndtIndex);
    if (totalPage === 1) {
        currentPage = 0;
        page_StartIndex = 0;
        page_EndtIndex = 1;
    }
    // console.log(totalPage, currentPage, page_StartIndex, page_EndtIndex);

    if (totalPage > 1) {
        components.push(<button type='button' className='btn-link' key='k_f' onClick={() => NavigatePage(totalRows, pageIndex, pageSize, 'first', cbFnPageIndex)}><i className='fa fa-fast-backward'></i> First</button>);
        components.push(<>&nbsp;&nbsp;</>);
        components.push(<button type='button' className='btn-link' key='k_p' onClick={() => NavigatePage(totalRows, pageIndex, pageSize, 'prev', cbFnPageIndex)}>Prev <i className='fa fa-angle-double-left'></i></button>);
    }
    for (let k = page_StartIndex; k < page_EndtIndex; k++) {
        if (CheckNullValue(temp[k]) !== null) {
            const firstFigure = temp[k].pageIndex + 1;
            let lastFigure = temp[k].page * pageSize;
            if (lastFigure > totalRows)
                lastFigure = totalRows;

            if (temp[k].page === currentPage) {
                components.push(<span className='btn-link' key={'k_' + k}
                    style={{ width: 35, display: 'inline-block', fontWeight: 'bold' }}
                    title={firstFigure + '~' + lastFigure + ' (' + (lastFigure - firstFigure + 1) + ')'}><u>{k + 1}</u></span>);
            }
            else {
                components.push(<button type='button' className='btn-link' key={'k_' + k}
                    style={{ width: 35 }}
                    title={firstFigure + '~' + lastFigure + ' (' + (lastFigure - firstFigure + 1) + ')'}
                    onClick={() => NavigatePage(totalRows, pageIndex, pageSize, temp[k].pageIndex, cbFnPageIndex)}>{k + 1}</button>);
            }
        }
    }
    if (totalPage > 1) {
        components.push(<button type='button' className='btn-link' key='k_n' onClick={() => NavigatePage(totalRows, pageIndex, pageSize, 'next', cbFnPageIndex)}><i className='fa fa-angle-double-right'></i> Next</button>);
        components.push(<>&nbsp;&nbsp;</>);
        components.push(<button type='button' className='btn-link' key='k_l' onClick={() => NavigatePage(totalRows, pageIndex, pageSize, 'last', cbFnPageIndex)}>Last <i className='fa fa-fast-forward'></i></button>);
    }

    return (components);
}
export function PagingComponents(colspan = 0, totalRows = 0, pageIndex = 0, pageSize = 0, cbFnPageSize = null, cbFnPageIndex = null) {
    return (<tr>
        <td colSpan={colspan} align='center' height={57}>
            <div style={{ position: 'absolute', left: 29, display: 'flex', alignItems: 'center', height: 33 }}>
                Showing {pageIndex + 1}~{pageIndex + pageSize > totalRows ? totalRows : pageIndex + pageSize} of {totalRows}.
            </div>
            <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>{PagingNavigationComponents(totalRows, pageIndex, pageSize, cbFnPageIndex)}</div>
            <div style={{ position: 'absolute', right: 29, bottom: 70 }}>
                Page Size&nbsp;
                <input type='number'
                    placeholder={pageSize}
                    onBlur={e => {
                        let _pageSize = Number(e.currentTarget.value);
                        _pageSize = _pageSize < 5 ? 5 : _pageSize;
                        if (cbFnPageSize !== null)
                            cbFnPageSize(_pageSize);
                    }}
                    style={{ height: 38, width: 60, textAlign: 'center', fontSize: 20, }}
                ></input>
            </div>
        </td>
    </tr>);
}
//#endregion === Paging Components