import React from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Row, Modal, Button, ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import './PageStyle.scss';
import './LiveQuizAdminPortal.scss';
// import { Locale, Lang } from '../../Localization/CustomLocalization.js';
import { GlobalSetting, InputType, SecretKey } from '../../components/GlobalSetting';
import { CheckBoolean, CheckNullValue, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, Delay, DelayUntil, GetInputComponent, GetPropIds, SetTempTarget } from '../../components/GlobalFunctions';      //2023.09.11

//2023.09.07
export default class AdminManageAuthor extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        redirect: false,
        redirectLink: '',

        SecretKey: SecretKey.Admin,
        TableList: [],
        CachedTableList: [],
        IsTableListLoaded: false,
        IsLoading: false,

        NewAuthorModalUiToggle: false,
        IsNewAuthorCreated: false,
        IsAuthorDetailUpdated: false,
        // IsAuthorPermissionUpdated: false,

        SelectedTableItemIndex: -1,
        OrganizerList: [],
        IsOrganizerListLoaded: false,
        OrganizerListModal_Toggle: false,

        //2023.11.30
        AuthorDetailsModal_Toggle: false,
        SelectedAuthorDetails: null,
    });

    componentDidMount = async () => {
        if (this.props.user === null) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }
        window.scrollTo(0, 0);

        // await this.DelayUntil(() => this.props.OrganizerInfoIsLoaded === true);
        await DelayUntil(() => this.props.OrganizerInfoIsLoaded === true);
        if (this.props.isDevMode)
            console.log('Admin_ManageAuthor', 'wait done');

        this.LoadAuthorList_ViaApi();
    }

    LoadAuthorList_ViaApi = async () => {
        this.setState({
            IsLoading: true,
            TableList: [],
            IsTableListLoaded: false,
        });

        const { centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log('LoadAuthorList_ViaApi', centerUserId, authorId, authorRoleId, organizerId);

        let done = false;
        let _List = [];
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/User/Author/List?secret=' + this.state.SecretKey + '&authorId=' + authorId,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('LoadAuthorList_ViaApi', JSON.stringify(data));
                // success = data.success;
                if (data.success) {
                    if (CheckObjectNullValue(data, 'data') !== null)
                        if (Array.isArray(data.data) && Array(data.data).length > 0)
                            _List = data.data;
                        else
                            if (this.props.isDevMode)
                                console.log('Author list is empty.');
                }
                else {
                    this.props.SetAlert('Load Failed', 'Failed to retrive author list.'
                        + (data.message === null || data.message === '' ? null : '<br /><br />Error:<br />' + data.message));
                    if (this.props.isDevMode)
                        console.log('Error', 'api-author-load-list (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                this.props.SetAlert('Api Load Failed', 'Failed to load author list.'
                    + (error.message === null || error.message === '' ? null : '<br /><br />Error:<br />' + error.message));
                if (this.props.isDevMode)
                    console.log('Error', 'api-author-load-list (failed)\n' + error.message);
                done = true;
            });
        await DelayUntil(() => done === true);

        //populate list.
        if (_List.length > 0) {
            let _newList = JSON.parse(JSON.stringify(_List));
            for (let i = 0; i < _newList.length; i++) {
                _newList[i].showPermissionDetail = false;
                _newList[i].isDirty = false;

                // //customPermissions.
                // const customPermissions_NotFound = CheckObjectNullValue(_newList[i], 'customPermissions') === null;
                // // let customPermissions_NotFound = false
                // // if (_newList[i].hasOwnProperty('customPermissions') === false || _newList[i]['customPermissions'] === null || _newList[i]['customPermissions'] === '')
                // //     customPermissions_NotFound = true;

                // console.log('customPermissions', i, 'start');

                let customPermissions = null;
                if (CheckObjectNullValue(_newList[i], 'customPermissions') === null)
                    customPermissions = JSON.parse(JSON.stringify(this.props.defaultCustomPermissions));
                else
                    customPermissions = await this.CheckAndUpdateCustomPermissionsModel(JSON.parse(_newList[i]['customPermissions']));
                await DelayUntil(() => customPermissions !== null);
                _newList[i]['customPermissions'] = customPermissions;

                // console.log('customPermissions', i, 'end', JSON.stringify(_newList[i]['customPermissions']));
            }
            // console.log(JSON.stringify(_newList));
            _newList.sort((x, y) => y.isAdmin - x.isAdmin || y.organizers.length - x.organizers.length || x.id - y.id);
            _List = _newList;
            // console.log(JSON.stringify(_List));
        }
        if (this.props.isDevMode)
            console.log('LoadAuthorList_ViaApi (final)', JSON.stringify(_List));

        this.setState({
            IsLoading: false,
            TableList: _List,
            CachedTableList: JSON.parse(JSON.stringify(_List)),
            IsTableListLoaded: true,
        });
    }

    //#region Custom Permiissions related.

    //2023.09.11
    CheckAndUpdateCustomPermissionsModel = async (model = null) => {
        // console.log('CheckAndUpdateCustomPermissionsModel', JSON.stringify(model));
        let customPermissions = JSON.parse(JSON.stringify(this.props.defaultCustomPermissions));
        if (model === null)
            return customPermissions;

        const entries = Object.values(customPermissions);
        const entries_model = Object.values(model);
        // console.log('customPermissions\n', JSON.stringify(customPermissions));
        // console.log('model\n', JSON.stringify(model));

        for (let i = 0; i < entries.length; i++) {
            const section = Object.keys(entries[i])[0];
            const section_action = Object.values(entries[i])[0];
            // console.log('section = ', JSON.stringify(section));
            // console.log('section_action = ', JSON.stringify(section_action));
            // console.log('section\n', JSON.stringify(customPermissions[i][section]));

            let modelSectionIndex = -1;
            try {
                for (let m = 0; i < entries_model.length; m++) {
                    const section_model = Object.keys(entries_model[m])[0];
                    if (CheckNullValue(section_model) !== null) {
                        if (section === section_model) {
                            modelSectionIndex = m;
                            break;
                        }
                    }
                }
            }
            catch (e) {
                // console.log('modelSectionIndex (error)', e);
            }
            // console.log('modelSectionIndex = ', modelSectionIndex);

            const section_action_keys = Object.keys(section_action);
            if (modelSectionIndex > -1) {
                // console.log('section_action_keys = ', JSON.stringify(section_action_keys));
                for (let action = 0; action < section_action_keys.length; action++) {
                    if (CheckNullValue(model[modelSectionIndex][section][section_action_keys[action]]) !== null)
                        customPermissions[i][section][section_action_keys[action]] = CheckBoolean(model[modelSectionIndex][section][section_action_keys[action]]);
                    else
                        customPermissions[i][section][section_action_keys[action]] = false;
                    // console.log(section_action_keys[action], '(model) = ', model[modelSectionIndex][section][section_action_keys[action]]);
                    // console.log(section_action_keys[action], ' = ', customPermissions[i][section][section_action_keys[action]]);
                }
            }
            else {
                for (let action = 0; action < section_action_keys.length; action++) {
                    customPermissions[i][section][section_action_keys[action]] = false;
                }
            }
            // console.log(section, i, JSON.stringify(customPermissions[i][section]));
        }
        return customPermissions;
    }

    HandleEditPermission = (e, selectAll = false) => {
        const ele = e.currentTarget;
        if (this.props.isDevMode)
            console.log('HandleEditPermission', ele.id, ele.name, ele.value, ele.checked);

        const splits = String(ele.id).split('-');
        const authorId = Number(splits[0]);
        const category = splits[1];     //aka. title
        const entry = selectAll ? 'select-all' : splits[2];    //aka. Read/Download/Upload/etc
        let _List = JSON.parse(JSON.stringify(this.state.TableList));    //[...this.state.TableList];
        const authorIndex = _List.findIndex(x => Number(x.id) === authorId);
        if (authorIndex > -1) {
            let categoryIndex = -1;
            let entryIndex = -1;
            const permissions = _List[authorIndex].customPermissions;
            for (let p = 0; p < permissions.length; p++) {
                const permission = Object.entries(permissions[p]);
                const title = String(permission[0][0]);
                if (title === category) {
                    // console.log('permission', JSON.stringify(permission));
                    // console.log('title', title);
                    categoryIndex = p;
                    const subCategories = Object.entries(permission[0][1]);
                    for (let s = 0; s < subCategories.length; s++) {
                        const skey = String(subCategories[s][0]);
                        if (skey === entry) {
                            entryIndex = s;
                            // console.log('skey', skey);
                            break;
                        }
                    }
                    break;
                }
            }
            // console.log(authorIndex, categoryIndex, entryIndex);
            // console.log(JSON.stringify(_List[authorIndex].customPermissions[categoryIndex]));
            // console.log(JSON.stringify(_List[authorIndex].customPermissions[categoryIndex][category]));
            // console.log(JSON.stringify(_List[authorIndex].customPermissions[categoryIndex][category][entry]));
            if (categoryIndex > -1) {
                if (selectAll === false) {
                    if (entryIndex > -1) {
                        _List[authorIndex].customPermissions[categoryIndex][category][entry] = ele.checked;
                    }
                }
                else {
                    const subCategories = Object.entries(_List[authorIndex].customPermissions[categoryIndex][category]);
                    for (let s = 0; s < subCategories.length; s++) {
                        const skey = String(subCategories[s][0]);
                        _List[authorIndex].customPermissions[categoryIndex][category][skey] = ele.checked;
                    }
                }
                _List[authorIndex].isDirty = JSON.stringify(this.state.CachedTableList[authorIndex].customPermissions)
                    !== JSON.stringify(_List[authorIndex].customPermissions);
            }
            this.setState({
                TableList: _List,
            }, () => {
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(this.state.TableList));
            });
        }
    }

    PermissionCheckboxesComponent = (author) => {
        if (this.props.user === null || author === null)
            return null;

        const array = author['customPermissions'];
        if (array === null || Array.isArray(array) === false)
            return null;

        let components = [];
        const permissions = array; //Object.entries(array);
        // console.log(JSON.stringify(permissions));

        for (let p = 0; p < permissions.length; p++) {
            const permission = Object.entries(permissions[p]);
            // console.log(JSON.stringify(permission));

            const title = String(permission[0][0]);
            const subCategories = Object.entries(permission[0][1]);
            // console.log(JSON.stringify(subCategories));

            let totalChecked = 0;
            let subComponents = [];
            for (let s = 0; s < subCategories.length; s++) {
                // const entry = Object.entries(subCategories[s]);
                const skey = String(subCategories[s][0]);
                const checked = CheckBoolean(subCategories[s][1]) ? true : false;
                const id = author.id + '-' + title + '-' + skey;
                subComponents.push(<dd style={{ display: 'inline-grid', padding: 5 }}>
                    <input type='checkbox' id={id} name={id}
                        checked={checked}
                        style={{ cursor: 'pointer' }}
                        onClick={this.HandleEditPermission} readOnly={true} />
                    <label htmlFor={id} style={{ cursor: 'pointer' }}>{skey.charAt(0).toUpperCase() + skey.slice(1)}</label>
                </dd>);
                if (checked)
                    totalChecked += 1;
            }
            const selectAllId = author.id + '-' + title + '-select-all';
            subComponents.push(<dd style={{ display: 'inline-grid', }}>
                <input type='checkbox' id={selectAllId} name={selectAllId}
                    checked={totalChecked === subCategories.length}
                    style={{ cursor: 'pointer' }}
                    onClick={e => this.HandleEditPermission(e, true)} readOnly={true} />
                <label htmlFor={selectAllId} style={{ cursor: 'pointer' }}>Select All</label>
            </dd>);

            components.push(<>
                <dt>{title.charAt(0).toUpperCase() + title.slice(1)}</dt>
                {subComponents}
            </>);
        }
        return (<dl style={{ margin: 0, marginBottom: -18 }}>{components}</dl>);
    }

    ShowPermissionDetail = (index) => {
        let _List = this.state.TableList;
        _List.map((data, key) => {
            if (key === index)
                data['showPermissionDetail'] = !data['showPermissionDetail'];
            return null;
        });
        this.setState({
            TableList: _List,
        });
    }

    //2023.10.30
    ResetPermissions = (index) => {

        let confirm = window.confirm('Continue to reset all permissions ?');
        if (confirm === false)
            return null;

        let _List = this.state.TableList;
        _List[index]['customPermissions'] = JSON.parse(JSON.stringify(this.props.defaultCustomPermissions));
        this.setState({
            TableList: _List,
        }, async () => {
            await this.UpdateAuthor_ViaAPI(index);
        });
    }

    //#endregion Custom Permiissions related.

    OrganizersComponent = (array) => {
        if (array === null || Array.isArray(array) === false)
            return null;

        let text = '';
        array.map((o, oKey) => {
            text += '(' + (oKey + 1) + ') ' + o.displayName;
            if (oKey < array.length - 1)
                text += '<br />';
            return null;
        });
        return text;
    }

    TableListComponent = () => {
        return (<table className='table table-bordered tb-row-hover' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
            <thead>
                <tr>
                    <th style={{ textAlign: 'center' }}>#</th>
                    <th style={{ width: '1%' }}>Name</th>
                    <th style={{}}>Organizer(s)</th>
                    {/* <th style={{ width: 425, }}>Permissions</th> */}
                    <th style={{ textAlign: 'center', width: 155, }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.TableList.length === 0 ?
                        <tr><td colSpan={4} align='center'>- list is empty -</td></tr>
                        :
                        this.state.TableList.map((data, itemKey) => {
                            return (<>
                                <tr key={'row_' + itemKey} id={'row_' + itemKey}>
                                    <td onClick={() => this.ShowPermissionDetail(itemKey)} style={{ cursor: 'pointer' }} align="center" >
                                        {itemKey + 1}
                                    </td>
                                    <td onClick={() => this.ShowPermissionDetail(itemKey)} style={{ cursor: 'pointer' }}>
                                        {data['name']}<br /><span style={{ color: 'gray' }}>{data['email']}</span>
                                        {CheckBoolean(data['isAdmin']) ? <><br /><span style={{ color: 'blue', fontWeight: 'bold' }}>(Admin)</span></> : null}
                                        {CheckBoolean(data['isViewer']) ? <><br /><span style={{ color: 'green', fontWeight: 'bold' }}>(Viewer)</span></> : null}
                                    </td>
                                    <td onClick={() => this.ShowPermissionDetail(itemKey)} style={{ cursor: 'pointer' }}>
                                        <div dangerouslySetInnerHTML={{ __html: this.OrganizersComponent(data['organizers']) }}></div>
                                    </td>
                                    {/* <td style={{ display: 'flex', justifyContent: 'center' }}>{this.PermissionCheckboxesComponent(data)}</td> */}
                                    <td align="center" style={{ verticalAlign: 'top', padding: 0 }}>
                                        <div className='row' style={{ padding: 5 }}>
                                            <div className='col'>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => this.ToggleEditAuthorDetailsModal(itemKey)}
                                                    style={{ width: '100%' }}
                                                    disabled={this.state.IsLoading}
                                                >Edit Detail</button>
                                            </div>
                                        </div>
                                        <div className='row' style={{ padding: 5, paddingTop: 0 }}>
                                            <div className='col'>
                                                <button
                                                    type="button"
                                                    className="btn btn-info"
                                                    onClick={() => this.LoadOrganizers(itemKey)}
                                                    style={{ width: '100%' }}
                                                    disabled={this.state.IsLoading}
                                                >Edit Organizers</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {
                                    CheckObjectBoolean(data, 'showPermissionDetail') === false ? null :
                                        <>
                                            <tr>
                                                <td rowSpan={2} style={{ backgroundColor: 'white' }}></td>
                                                <td colSpan={3} style={{ backgroundColor: 'white' }}>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <button
                                                                type="button"
                                                                className="btn btn-info"
                                                                onClick={() => this.ResetPermissions(itemKey)}
                                                                style={{ width: '100%' }}
                                                                disabled={this.state.IsLoading}
                                                            >Reset to Default Permissions</button>
                                                        </div>
                                                        <div className='col'>
                                                            <button
                                                                type="button"
                                                                className="btn btn-info"
                                                                onClick={() => this.ResetTableList()}
                                                                style={{ width: '100%' }}
                                                                disabled={this.state.IsLoading}
                                                            >Reset Permissions</button>
                                                        </div>
                                                        <div className='col'>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => this.UpdateAuthor_ViaAPI(itemKey, true)}
                                                                style={{ width: '100%' }}
                                                                disabled={this.state.IsLoading}
                                                            >Save Permission</button>
                                                        </div>
                                                    </div>
                                                </td></tr>

                                            <tr><td colSpan={3} style={{ backgroundColor: 'white' }}>{this.PermissionCheckboxesComponent(data)}</td></tr>
                                        </>
                                }
                            </>);
                        })
                }
            </tbody>
        </table>);
    }

    //2023.11.17
    ResetTableList = () => {
        let _List = this.state.CachedTableList;
        for (let i = 0; i < _List.length; i++) {
            _List[i]['showPermissionDetail'] = CheckObjectBoolean(this.state.TableList[i], 'showPermissionDetail');
        }
        this.setState({
            TableList: _List,
            CachedTableList: JSON.parse(JSON.stringify(_List)),
        });
    }

    UpdateAuthor_ViaAPI = async (index = -1, direct = false) => {

        if (index < 0)
            return null;

        if (direct) {
            let confirm = window.confirm('Continue to save ?');
            if (confirm === false)
                return null;
        }

        this.props.SetLoading('', 'Updating author...', false);
        this.setState({
            IsLoading: true,
            // IsAuthorPermissionUpdated: false,
        });

        let _List = [...this.state.TableList];
        const author = _List[index];

        const { centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(this.props);
        if (this.props.isDevMode)
            console.log('UpdateAuthor_ViaAPI (Id)', centerUserId, authorId, authorRoleId, organizerId);

        const jsonData = JSON.stringify({
            secret: this.state.SecretKey,
            authorId: authorId,
            model: {
                id: CheckObjectNumber(author, 'id'),
                userId: CheckObjectNumber(author, 'userId'),
                firebaseUserId: CheckObjectStringEmpty(author, 'firebaseUserId'),

                //author.
                isViewer: CheckObjectBoolean(author, 'isViewer'),
                isAdmin: CheckObjectBoolean(author, 'isAdmin'),
                customPermissions: author.customPermissions,    //array.
                forceResetPassword: CheckObjectBoolean(author, 'forceResetPassword'),
                isActive: CheckObjectBoolean(author, 'isActive'),

                //user.
                name: CheckObjectStringEmpty(author, 'name'),
                email: CheckObjectStringEmpty(author, 'email'),
                dialingCode: CheckObjectStringEmpty(author, 'dialingCode'),
                phoneNumber: CheckObjectStringEmpty(author, 'phoneNumber'),

                //organizer mappings.
                organizers: author.organizers,      //array.
            },
        });
        if (this.props.isDevMode)
            console.log('UpdateAuthor_ViaAPI (json)', jsonData);

        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/User/Author/Update',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonData,
            })
            .then(res => res.json())
            .then(data => {
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(data));
                if (data.success) {
                    _List[index].isDirty = false;
                    if (this.props.isDevMode)
                        console.log('Author has been updated.');
                }
                else {
                    this.props.SetAlert('Update Failed', 'Author update is unsuccessful.'
                        + (CheckObjectNullValue(data, 'message') === null ? '' : '<br /><br />Error:<br />' + data.message));
                    if (this.props.isDevMode)
                        console.log('Error', 'api-author-update (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                this.props.SetAlert('Api Update Failed', 'Failed to update author detail.'
                    + (CheckObjectNullValue(error, 'message') === null ? '' : '<br /><br />Error:<br />' + error.message));
                if (this.props.isDevMode)
                    console.log('Error', 'api-author-update (failed)\n' + error.message);
            });

        const updateSuccess = _List[index].isDirty === false;
        this.setState({
            TableList: updateSuccess ? _List : this.state.TableList,
            CachedTableList: updateSuccess ? JSON.parse(JSON.stringify(_List)) : this.state.CachedTableList,
            IsLoading: false,
            // IsAuthorPermissionUpdated: true,
        }, () => {
            if (updateSuccess)
                this.props.SetAlert('', 'Author has been updated.', false);
        });
    }

    //#region Author Details related.

    //2023.11.30
    ToggleEditAuthorDetailsModal = async (index = -1) => {
        // this.setState({
        //     AuthorDetailsModal_Toggle: !this.state.AuthorDetailsModal_Toggle,
        //     SelectedTableItemIndex: index,
        //     // SelectedAuthorDetails: null,
        //     // IsLoading: false,
        // }, async () => {
        //     if (this.state.SelectedTableItemIndex > -1) {
        //         this.ResetAuthorDetails();
        //         await Delay(500);
        //         SetTempTarget(this.state.SelectedAuthorDetails);
        //         // this.SaveSelectedAuthorDetails(this.state.SelectedAuthorDetails);
        //     }
        //     else {
        //         this.setState({
        //             SelectedAuthorDetails: null,
        //             IsLoading: false,
        //         });
        //     }
        // });
        this.setState({
            SelectedTableItemIndex: index,
        }, () => {
            if (this.state.SelectedTableItemIndex > -1) {
                this.ResetAuthorDetails();
                // this.SaveSelectedAuthorDetails(this.state.SelectedAuthorDetails);
            }
            else {
                this.setState({
                    SelectedAuthorDetails: null,
                });
            }
            this.setState({
                IsLoading: false,
                AuthorDetailsModal_Toggle: !this.state.AuthorDetailsModal_Toggle,
            });
        });
    }

    //2023.11.30
    ResetAuthorDetails = () => {
        this.setState({
            SelectedAuthorDetails: JSON.parse(JSON.stringify(this.state.TableList[this.state.SelectedTableItemIndex])),
        }, () => {
            SetTempTarget(this.state.SelectedAuthorDetails);
            this.SaveSelectedAuthorDetails(this.state.SelectedAuthorDetails);
        });
    }

    //2023.11.30
    AuthorDetailsComponent = () => {
        let components = [];

        const authorDetails = this.state.SelectedAuthorDetails;
        if (authorDetails !== null) {

            components.push(<div className="form-group">
                <label>Name</label>
                {
                    GetInputComponent(InputType.Text, null,
                        authorDetails, 'name', null, '', this.props.Locale,
                        this.SaveSelectedAuthorDetails, null, this.state.IsLoading)
                }
            </div>);

            // components.push(<div className="form-group">
            //     <label>Email</label>
            //     {
            //         GetInputComponent(InputType.Text, null,
            //             authorDetails, 'email', null, '', this.props.Locale,
            //             this.SaveSelectedAuthorDetails, null, !this.state.IsLoading)
            //     }
            // </div>);

            components.push(<div className="form-group">
                <label>Dialing Code</label>
                {
                    GetInputComponent(InputType.Number, null,
                        authorDetails, 'dialingCode', null, '', this.props.Locale,
                        this.SaveSelectedAuthorDetails, null, this.state.IsLoading)
                }
            </div>);

            components.push(<div className="form-group">
                <label>Phone Number</label>
                {
                    GetInputComponent(InputType.Number, null,
                        authorDetails, 'phoneNumber', null, '', this.props.Locale,
                        this.SaveSelectedAuthorDetails, null, this.state.IsLoading)
                }
            </div>);

            // components.push(<div className="form-group">
            //     <label>Contact Number</label>
            //     {
            //         GetInputComponent(InputType.Text, null,
            //             authorDetails, 'contactNumber', null, '', this.props.Locale,
            //             this.SaveSelectedAuthorDetails, null, this.state.IsLoading)
            //     }
            // </div>);

            components.push(<div className="form-group">
                <label>Force Reset Password on Next Login</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                authorDetails, 'forceResetPassword', null, '', this.props.Locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                    {
                        CheckObjectNullValue(this.state.SelectedAuthorDetails, 'lastPasswordUpdateOnUtc') === null ? null :
                            <div className='col' style={{ flex: 20, padding: 0 }}>
                                <span style={{ fontSize: 14, color: 'gray' }}>(Last Password Update: {moment.utc(CheckObjectStringEmpty(this.state.SelectedAuthorDetails, 'lastPasswordUpdateOnUtc'), 'YYYY-MM-DD HH:mm:ss.sss').local().format('lll')})</span>
                            </div>
                    }
                </div>
            </div>);

            //2024.01.31
            components.push(<div className="form-group">
                <label>Admin</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                authorDetails, 'isAdmin', null, '', this.props.Locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                </div>
            </div>);
            components.push(<div className="form-group">
                <label>Viewer</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                authorDetails, 'isViewer', null, '', this.props.Locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                </div>
            </div>);
            components.push(<div className="form-group">
                <label>Active</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                authorDetails, 'isActive', null, '', this.props.Locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                </div>
            </div>);
        }

        return components;
    }

    //2023.11.30
    SaveSelectedAuthorDetails = (obj = null) => {
        if (obj !== undefined && obj !== null) {
            obj['contactNumber'] = CheckObjectStringEmpty(obj, 'dialingCode') + CheckObjectStringEmpty(obj, 'phoneNumber');
            this.setState({
                SelectedAuthorDetails: obj,
            });
        }
    }

    //2023.11.30
    ConfirmUpdateAuthorDetails = () => {
        let tableList = this.state.TableList;
        tableList[this.state.SelectedTableItemIndex] = this.state.SelectedAuthorDetails;
        this.setState({
            TableList: tableList,
        }, () => {
            this.UpdateAuthor_ViaAPI(this.state.SelectedTableItemIndex, true);
        });
    }

    //#endregion

    //#region Organizer List related.

    ToggleOrganizerListModal = (cancel = false) => {
        this.setState({
            OrganizerListModal_Toggle: !this.state.OrganizerListModal_Toggle,
        }, () => {
            if (this.state.OrganizerListModal_Toggle === false) {
                //reset.
                this.setState({
                    SelectedTableItemIndex: -1,
                });

                if (cancel)
                    this.ResetTableList();
            }
        });
    }

    //2023.11.17
    LoadOrganizers = async (index) => {

        this.setState({
            SelectedTableItemIndex: index,
        });
        await DelayUntil(() => this.state.SelectedTableItemIndex > -1);

        //load organizer list if empty.
        this.props.SetLoading('', 'fetching organizers...', false);
        this.setState({
            IsLoading: true,
            OrganizerList: [],
            IsOrganizerListLoaded: false,
        });
        await Delay(1000);

        let _List = [];
        const authorId = CheckObjectNumber(this.state.TableList[this.state.SelectedTableItemIndex], 'id');
        if (this.props.isDevMode)
            console.log('authorId', authorId);

        if (authorId > 0) {
            await fetch(GlobalSetting.ApiUrl
                + 'Api/LearningCentre/Organizer/List?secret=' + this.state.SecretKey + '&authorId=' + authorId,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                .then(res => res.json())
                .then(data => {
                    // if (this.props.isDevMode)
                    //     console.log(JSON.stringify(data));
                    // success = data.success;
                    if (data.success) {
                        if (CheckObjectNullValue(data, 'data') !== null)
                            if (Array.isArray(data.data) && Array(data.data).length > 0)
                                _List = data.data;
                            else
                                if (this.props.isDevMode)
                                    console.log('Organizer list is empty.');
                    }
                    else {
                        this.props.SetAlert('Load Failed', 'Failed to retrive organizer list.'
                            + (data.message === null || data.message === '' ? null : '<br /><br />Error:<br />' + data.message));
                        if (this.props.isDevMode)
                            console.log('Error', 'api-organizer-load-list (failed)\n' + JSON.stringify(data));
                    }
                })
                .catch(error => {
                    this.props.SetAlert('Api Load Failed', 'Failed to load organizer list.'
                        + (error.message === null || error.message === '' ? null : '<br /><br />Error:<br />' + error.message));
                    if (this.props.isDevMode)
                        console.log('Error', 'api-organizer-load-list (failed)\n' + error.message);
                });
        }
        this.setState({
            IsLoading: false,
            OrganizerList: _List,
            IsOrganizerListLoaded: true,
        }, () => {
            if (_List.length > 0)
                this.props.CloseAlert();
            else
                this.props.SetAlert('Failed', 'Failed to fetch organizer list.', true);
        });

        //show organizer list modal.
        if (this.state.IsOrganizerListLoaded)
            if (this.state.OrganizerList.length > 0)
                this.ToggleOrganizerListModal();
    }

    //2023.11.17
    SetOrganizerByTableItem = (index) => {
        if (this.state.OrganizerList.length > 0) {
            let _List = this.state.TableList;
            if (CheckObjectNullValue(_List[this.state.SelectedTableItemIndex], 'organizers') === null)
                _List[this.state.SelectedTableItemIndex]['organizers'] = [];

            const organizer = this.state.OrganizerList[index];
            let author_organizers = _List[this.state.SelectedTableItemIndex]['organizers'];
            if (author_organizers.findIndex(x => x.id === organizer.id) < 0) {
                //add to author's organizer list.
                author_organizers.push(organizer);
            }
            else {
                //remove from author's organizer list.
                author_organizers = author_organizers.filter(x => x.id !== organizer.id);
            }
            _List[this.state.SelectedTableItemIndex]['organizers'] = author_organizers;
            this.setState({
                TableList: _List,
            });
        }
    }

    //2023.11.17
    OrganizerListComponent = () => {
        return (<table className='table table-bordered tb-row-hover' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
            <thead>
                <tr>
                    <th style={{ textAlign: 'center' }}>#</th>
                    <th style={{ textAlign: 'left' }} colSpan={2}>Display Name</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.OrganizerList.length === 0 ?
                        <tr><td colSpan={3} align='center'>- list is empty -</td></tr>
                        :
                        this.state.OrganizerList.map((data, itemKey) => {
                            let _checked = false;
                            if (CheckObjectNullValue(this.state.TableList[this.state.SelectedTableItemIndex], 'organizers') !== null)
                                _checked = this.state.TableList[this.state.SelectedTableItemIndex].organizers.findIndex(x => Number(x.id) === Number(data.id)) > -1;

                            return (<>
                                <tr
                                    key={'k_row_' + itemKey}
                                    id={'id_row_' + itemKey}
                                    onClick={() => this.SetOrganizerByTableItem(itemKey)}
                                    style={{ cursor: 'pointer' }}
                                    title={CheckObjectStringEmpty(data, 'schoolAddress')}
                                >
                                    <td>{itemKey + 1}</td>
                                    <td>
                                        <input type='checkbox' checked={_checked} readOnly={true}></input>
                                    </td>
                                    <td align='left' style={_checked ? { fontWeight: 'bold' } : {}}>{CheckObjectStringEmpty(data, 'displayName', '-')}</td>
                                </tr>
                            </>);
                        })
                }
            </tbody>
        </table>);
    }

    //#endregion Organizer List related.

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <>
                    <Row className='rowStyle'>
                        <Col>
                            <span style={{ fontSize: 20, fontWeight: 'bold' }}>Manage Author</span>
                        </Col>
                        <Col className='colBtn'>
                            <button type="button" className="btn btn-outline-primary"
                                disabled={true}
                                onClick={this.ToggleCreateNewAuthorModal}
                            >New Author</button>
                        </Col>
                    </Row>
                    <p />
                    {
                        this.state.IsLoading && !this.state.IsTableListLoaded ?
                            <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10, }} />
                            :
                            this.TableListComponent()
                    }

                    {/* Author Organizer List - Manage Author Organizer - Modal */}
                    <Modal show={this.state.OrganizerListModal_Toggle}
                        size='lg'
                        onHide={() => this.ToggleOrganizerListModal(true)}
                        centered
                    >
                        <Modal.Header closeButton={true}>
                            <Modal.Title style={{ fontSize: 20 }}>Manage Author's Organizer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center' }}>
                            <div className='row'><div className='col'><b>{CheckObjectStringEmpty(this.state.TableList[this.state.SelectedTableItemIndex], 'name')}</b></div></div>
                            <div className='row'><div className='col'><b>{CheckObjectStringEmpty(this.state.TableList[this.state.SelectedTableItemIndex], 'email')}</b></div></div>
                            <br />
                            {this.OrganizerListComponent()}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.ToggleOrganizerListModal(true)}>Cancel</Button>
                            <Button variant="secondary" onClick={() => this.ResetTableList()}>Reset</Button>
                            <Button variant="primary" onClick={() => this.UpdateAuthor_ViaAPI(this.state.SelectedTableItemIndex, true)}>Save</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Author Details - Manage Author Details - Modal */}
                    <Modal show={this.state.AuthorDetailsModal_Toggle}
                        onHide={() => this.ToggleEditAuthorDetailsModal()}
                        centered
                    >
                        <Modal.Header closeButton={true}>
                            <Modal.Title style={{ fontSize: 20 }}>Manage Author Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'left' }}>
                            {/* <div className='row'><div className='col'><b>{CheckObjectStringEmpty(this.state.SelectedAuthorDetails, 'name')}</b></div></div> */}
                            <div className='row'><div className='col'><b>{CheckObjectStringEmpty(this.state.SelectedAuthorDetails, 'email')}</b></div></div>
                            <br />
                            {this.AuthorDetailsComponent()}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.ToggleEditAuthorDetailsModal()} disabled={this.state.IsLoading}>Cancel</Button>
                            <Button variant="secondary" onClick={() => this.ResetAuthorDetails()} disabled={this.state.IsLoading}>Reset</Button>
                            <Button variant="primary" onClick={() => this.ConfirmUpdateAuthorDetails()} disabled={this.state.IsLoading}>Save</Button>
                        </Modal.Footer>
                    </Modal >
                </>
            );
        }
    }
}