import React from 'react';
// import { Spinner } from 'react-bootstrap';
// import _ from 'lodash';
import { Button, Modal } from 'react-bootstrap';

import { Locale } from '../../Localization/CustomLocalization.js';

import '../../Screens/Quiz/css/QuestionCSS.css';
import '../../Screens/Quiz/css/QuestionSCSS.scss';

let checkIsDoneInterval = null;

export default class QuestionSPPA extends React.Component {

    state = {
        ToggleNext: false,
        ToggleSkip: true,
        // Answers: [],
        Selections: [],
        PictureURL: "",
        // isPictureLoaded: false,
        imageBlob: null,
        showResultIcon: null,

        Answers: [],        //this.props.Answer into Array.
        PickedAnswers: [],
        showAnswerOptionModal: false,
        selectedQsId: -1,
        answerOptionsHtml: "",
        contentHtml: "",

        // subjectLocale: Lang.English,

        selectedQsNo: '',   //2020.12.02

        //2021.01.18
        hasHints: false,
        hasInstruction: false,
    }

    componentDidMount = () => {
        // let answers = Object.assign("", this.props.data.Selection);
        // alert(this.props.data.Selection);
        clearInterval(checkIsDoneInterval);

        let _Answers = [];
        let _PickedAnswers = [];
        let _Selections = [];

        let answers = String(this.props.data.Answer).split(";");
        answers = answers.filter((el) => { return (el !== ''); });  //clear null
        answers.map((data, key) => {
            if (data.length > 0) {
                _Answers.push(data)
                _PickedAnswers.push("-1");
            }
            return null;
        });

        let selections = String(this.props.data.Selection).split(";");
        selections = selections.filter((el) => { return (el !== ''); });    //clear null
        selections.map((data, key) => {
            if (data.length > 0)
                _Selections.push(data);
            return null;
        });

        // alert(this.props.ASelectId);
        // let loadedPickedAnswers = [];
        // if (this.props.ASelectId.length > 0) {
        //     this.props.ASelectId.map((data, key) => {
        //         let value = "-1";
        //         if (data !== "-1")
        //             value = String(selections[data]).split(":")[0];
        //         loadedPickedAnswers.push(value);
        //     });
        // }

        // answers.map((data, key)=> {
        //     selections.map((data1, key1) => {
        //         if (loadedPickedAnswers[key] === data1.split(":")[0])
        //             this.state.PickedAnswer[key] = loadedPickedAnswers[key];
        //     });
        // })

        if (Array(this.props.ASelectId).length > 0) {
            this.props.ASelectId.map((data, key) => {
                _PickedAnswers[key] = data;
                return null;
            });
        }

        this.setState({

            PictureURL: String(this.props.data.PictureUrl),
            Selections: _Selections,
            Answers: _Answers,
            PickedAnswers: _PickedAnswers,

            // subjectLocale: this.GetLocale(),

            //2021.01.18
            hasHints: String(this.props.data.Hints).length > 0 ? true : false,
            hasInstruction: this.props.data.hasOwnProperty('Instruction') ?
                String(this.props.data.Instruction).length > 0 ? true : false
                : false,

        }, async () => {
            // this.getPicture(this.state.PictureUrl);

            // setTimeout(() => {
            //     this.setState({
            //         // showResultIcon: this.props.ASelectId !== "-1",
            //         showResultIcon: this.props.showResultIcon,
            //     });
            // }, 1000);

            // alert(JSON.stringify(this.state.Answers) + "\n\n" + JSON.stringify(this.state.Selections));

            // alert(JSON.stringify(this.state.Answers) + "\n\n"
            //     + JSON.stringify(this.state.PickedAnswers) + "\n\n"
            //     + JSON.stringify(this.props.ASelectId));

            // await this.processContent();

            // checkIsDoneInterval = setInterval(() => {
            //     if (this.props.InActive) {
            //         if (this.props.ASelectId.length > 0) {
            //             this.callProcessContent();
            //             clearInterval(checkIsDoneInterval);
            //         }
            //     }
            // }, 500);

            // setTimeout(() => {
            //     this.callProcessContent();
            // }, 5000);
            this.callProcessContent();
        });
    }

    callProcessContent = async () => {
        // console.log('callProcessContent\n' + JSON.stringify(this.state.PickedAnswers));
        await this.processContent();
    }

    // GetLocale = () => {
    //     let qsLocale = Lang.English;

    //     if (String(this.props.data.Subject) === 'Chinese')
    //         qsLocale = Lang.Chinese;
    //     else if (String(this.props.data.Subject) === 'Malay')
    //         qsLocale = Lang.Malay;
    //     // else
    //     //     if (this.props.Locale !== Lang.English)
    //     //         qsLocale = this.props.Locale;

    //     return qsLocale;
    // }

    // handleClickAnswer = (event) => {
    //     // let keyId = event.target.id;
    //     // let answer = event.target.value;
    //     // let result = answer === this.props.data.Answer;
    //     // // alert(result + " " + answer + " " + this.props.id);
    //     // this.setState({
    //     //     ToggleNext: true,
    //     //     // showResultIcon: true,        //dun show correct/wrong immediately.
    //     // }, () => {
    //     //     this.props.setAnswer(result, this.props.id, keyId);
    //     //     this.props.nextQs(this.props.id + 1, null);
    //     // });
    // }

    processContent = async () => {
        // var temp = String(this.props.data.Content);
        // alert(this.state.contentHtml);

        var temp = this.state.contentHtml.length > 0 ?
            this.state.contentHtml
            : String(this.props.data.Content);

        var content = "";

        // if (String(temp).includes('color')) {
        //     if (String(temp).includes('green'))
        //         content = temp.replaceAll('<color=green>', '<span style="color:lightgreen;font-weight:bold;">&nbsp;');
        //     else if (String(temp).includes('blue'))
        //         content = temp.replaceAll('<color=blue>', '<span style="color:lightgreen;font-weight:bold;">&nbsp;');
        //     else if (String(temp).includes('red'))
        //         content = temp.replaceAll('<color=red>', '<span style="color:lightgreen;font-weight:bold;">&nbsp;');

        //     content = content.replaceAll('</color>', '&nbsp;</span>');
        // }
        // else {
        //     content = temp;
        // }

        // if (String(content).includes('\n')) {
        //     content = String(content).replaceAll('\n', '<br />');
        // }

        if (temp.includes('color')) {
            if (temp.includes('green'))
                content = temp.replace(new RegExp('<color=green>', 'g'), '<span style="color:lightgreen;font-weight:bold;">&nbsp;');
            else if (temp.includes('blue'))
                content = temp.replace(new RegExp('<color=blue>', 'g'), '<span style="color:lightgreen;font-weight:bold;">&nbsp;');
            else if (temp.includes('red'))
                content = temp.replace(new RegExp('<color=red>', 'g'), '<span style="color:lightgreen;font-weight:bold;">&nbsp;');

            content = content.replace(new RegExp('</color>', 'g'), '&nbsp;</span>');
        }
        else {
            content = temp;
        }

        if (content.includes('\n')) {
            content = String(content).replace(new RegExp('\\n', 'g'), '<br />');
        }

        //added on 2020-11-19
        if (content.includes('\t')) {
            content = String(content).replace(new RegExp('\\t', 'g'), '&#9;');
        }

        //2020.11.25
        if (content.includes('<tab/>')) {
            content = String(content).replace(new RegExp('<tab/>', 'g'), '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
        }

        //2020.12.02
        if (content.includes('<b>')) {
            content = String(content).replace(new RegExp('<b>', 'g'), '<span style="font-weight:bold;">');
            content = String(content).replace(new RegExp('</b>', 'g'), '</span>');
        }

        //2021.08.25
        if (content.includes('<i>')) {
            content = String(content).replace(new RegExp('<i>', 'g'), '<span style="font-style:italic;">');
            content = String(content).replace(new RegExp('</i>', 'g'), '</span>');
        }

        // alert(content + "\n\n" + temp);
        // if (Number(this.props.qsEnd) > Number(this.props.qsStart))
        //     content += "<br /><br />";


        //2021.03.25
        if (content.includes('<img>')) {
            content = String(content).replace(new RegExp('<img>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
            content = String(content).replace(new RegExp('</img>', 'g'), '.jpg" alt="" width="100%">');
        }

        //2021.07.12
        if (content.includes('<jpg>')) {
            content = String(content).replace(new RegExp('<jpg>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
            content = String(content).replace(new RegExp('</jpg>', 'g'), '.jpg" alt="" width="100%">');
        }
        if (content.includes('<png>')) {
            content = String(content).replace(new RegExp('<png>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
            content = String(content).replace(new RegExp('</png>', 'g'), '.png" alt="" width="100%">');
        }

        // content = '<div>' + content;

        let splitFront = [];
        // let splitVar = [];
        let front = "";
        let variable = "";
        let rear = "";
        let result = "";
        let pickedAnswer = "";
        let btnStyle = "";
        let disabledTxt = "";

        // let test = "";
        // alert(content);
        // alert(this.state.contentHtml);
        // alert(this.state.selectedQsId + " | " + this.state.Selections[this.state.PickedAnswers[this.state.selectedQsId]].split(":")[1]);

        this.state.PickedAnswers.map((data, key) => {

            //2020.12.02
            front = '';
            rear = '';
            variable = '';
            pickedAnswer = '';
            btnStyle = '';
            disabledTxt = '';
            result = '';

            //2021.04.07
            // hasAnswer = '';

            splitFront = [];
            let id = Number(this.props.qsStart) + key;

            // test += id + " | ";

            if (String(content).includes("<button id='" + id + "'>")) {
                splitFront = content.split("<button id='" + id + "'>");

                front = splitFront[0];
                variable = splitFront[1].split("</button>")[0];
                rear = splitFront[1].replace(variable, "");

                pickedAnswer = data !== "-1" ? "(Q" + id + ") " + String(this.state.Selections[data]).split(":")[1] : "-1";
                variable = pickedAnswer !== "-1" ? pickedAnswer : "_____(Q" + id + ")_____";

                // variable = "_____(Q" + id + ")_____";

                // btnStyle = data !== "-1" ? "" : "outline-";
                //2021.04.03 - flip button style as requested by May.
                btnStyle = data !== "-1" ? "outline-" : "";
                // hasAnswer = data !== "-1" ? " hasAnswer" : "";

                // disabledTxt = Boolean(this.props.InActive[key]) ? " disabled" : "";
                disabledTxt = this.props.InActive ? " disabled" : "";

                // result = front + "<button id='Qs" + id + "' class='btn btn-" + btnStyle + "light'>" + variable + rear;

                // result = front + "<button id='Qs" + id + "' class='btn btn-" + btnStyle + "light'" + disabledTxt + ">" + variable + rear;
                // result = front + "<button id='Qs" + id + "' class='btn btn-" + btnStyle + "light" + hasAnswer + "'" + disabledTxt + ">" + variable + rear;
                result = front + "<button id='Qs" + id + "' class='btnAns btn-" + btnStyle + "answer'" + disabledTxt + ">" + variable + rear;

                // if (this.props.InActive)
                //     result = front + "<button id='Qs" + id + "' class='btn btn-" + btnStyle + "light' " + disabledTxt + ">" + variable + rear;
                // else
                //     result = front + "<button id='Qs" + id + "' class='btn btn-" + btnStyle + "light' >" + variable + rear;
                content = result;
            }

            if (String(content).includes("<button id='Qs" + id + "' class='btnAns btn-")) {
                // if (this.props.InActive) {
                //     // disabledTxt = this.props.InActive ? " disabled" : "";

                //     if (content.includes("<button id='Qs" + id + "' class='btn btn-outline-light' disabled>"))
                //         splitFront = content.split("<button id='Qs" + id + "' class='btn btn-outline-light' disabled>");
                //     else
                //         splitFront = content.split("<button id='Qs" + id + "' class='btn btn-light' disabled>");
                // }
                // else {
                //     if (content.includes("<button id='Qs" + id + "' class='btn btn-outline-light'>"))
                //         splitFront = content.split("<button id='Qs" + id + "' class='btn btn-outline-light'>");
                //     else
                //         splitFront = content.split("<button id='Qs" + id + "' class='btn btn-light'>");
                // }

                // if (content.includes("<button id='Qs" + id + "' class='btn btn-outline-light'>"))
                //     splitFront = content.split("<button id='Qs" + id + "' class='btn btn-outline-light'>");
                // else
                //     splitFront = content.split("<button id='Qs" + id + "' class='btn btn-light'>");

                // disabledTxt = Boolean(this.props.InActive[key]) ? " disabled" : "";
                disabledTxt = this.props.InActive ? " disabled" : "";

                // splitFront = content.split("<button id='Qs" + id + "' class='btn btn-outline-light'" + disabledTxt + ">");

                // if (String(content).includes("<button id='Qs" + id + "' class='btn btn-outline-light'" + disabledTxt + ">"))
                //     splitFront = content.split("<button id='Qs" + id + "' class='btn btn-outline-light'" + disabledTxt + ">");
                // else
                //     splitFront = content.split("<button id='Qs" + id + "' class='btn btn-light'" + disabledTxt + ">");

                // //2021.04.03 - flip button style as requested by May.
                // if (String(content).includes("<button id='Qs" + id + "' class='btn btn-light'" + disabledTxt + ">"))
                //     splitFront = content.split("<button id='Qs" + id + "' class='btn btn-light'" + disabledTxt + ">");
                // else
                //     splitFront = content.split("<button id='Qs" + id + "' class='btn btn-outline-light'" + disabledTxt + ">");

                // if (String(content).includes("<button id='Qs" + id + "' class='btn btn-primary'" + disabledTxt + ">"))
                //     splitFront = content.split("<button id='Qs" + id + "' class='btn btn-primary'" + disabledTxt + ">");
                // else
                //     splitFront = content.split("<button id='Qs" + id + "' class='btn btn-outline-primary'" + disabledTxt + ">");

                // //2021.04.08 - hover color, flip button style as requested by May.
                if (String(content).includes("<button id='Qs" + id + "' class='btnAns btn-answer'" + disabledTxt + ">"))
                    splitFront = content.split("<button id='Qs" + id + "' class='btnAns btn-answer'" + disabledTxt + ">");
                else
                    splitFront = content.split("<button id='Qs" + id + "' class='btnAns btn-outline-answer'" + disabledTxt + ">");

                front = splitFront[0];

                // alert(splitFront[1]);

                variable = splitFront[1].split("</button>")[0];
                rear = splitFront[1].replace(variable, "");


                pickedAnswer = String(data) !== "-1" ? "(Q" + id + ") " + String(this.state.Selections[Number(data)]).split(":")[1] : "-1";

                // pickedAnswer = data !== "-1" ? "(Q" + id + ") " + this.state.Selections[data].split(":")[1] : "-1";
                variable = pickedAnswer !== "-1" ? pickedAnswer : "_____(Q" + id + ")_____";

                // btnStyle = String(data) !== "-1" ? "" : "outline-";
                //2021.04.03 - flip button style as requested by May.
                btnStyle = String(data) !== "-1" ? "outline-" : "";

                // disabledTxt = this.props.InActive[key] ? " disabled" : "";

                // result = front + "<button id='Qs" + id + "' class='btn btn-" + btnStyle + "light'>" + variable + rear;

                // result = front + "<button id='Qs" + id + "' class='btn btn-" + btnStyle + "light'" + disabledTxt + ">" + variable + rear;
                //2021.04.03 - flip button style as requested by May.
                // result = front + "<button id='Qs" + id + "' class='btn btn-" + btnStyle + "outline-light'" + disabledTxt + ">" + variable + rear;
                // result = front + "<button id='Qs" + id + "' class='btn btn-" + btnStyle + "primary'" + disabledTxt + ">" + variable + rear;
                //2021.04.08
                result = front + "<button id='Qs" + id + "' class='btnAns btn-" + btnStyle + "answer'" + disabledTxt + ">" + variable + rear;

                // if (this.props.InActive)
                //     result = front + "<button id='Qs" + id + "' class='btn btn-" + btnStyle + "light' " + disabledTxt + ">" + variable + rear;
                // else
                //     result = front + "<button id='Qs" + id + "' class='btn btn-" + btnStyle + "light' >" + variable + rear;
                content = result;
            }

            // return content = result;
            return null;
        });

        // alert(test);
        // alert(this.state.PickedAnswers.length);

        // alert(this.props.qsTitle + "\n\n" + content + "\n\n" + this.props.data.Content + "\n\n" + this.props.qsStart + "\n\n" + this.props.qsEnd);
        // alert(content);

        //added on 2020-11-19
        // let instructionMsg = "<div style='text-align: right'><font style='font-size: 14px;color: yellow;'><b>"
        //     + Locale('FITB-instruction-1', this.props.UiLocale)
        //     + '<b>Q' + String(Number(this.props.id) + 1) + '</b>'
        //     + Locale('FITB-instruction-2', this.props.UiLocale)
        //     + '</b></font></div>';
        // content = content + instructionMsg;

        // content += '<br />';

        // return { __html: content }
        this.setState({
            contentHtml: content,
        });
    }

    getPicture = (pictureUrl) => {
        let _pictureUrl = String(pictureUrl);

        if (_pictureUrl.length > 0)
            if (_pictureUrl === 'undefined')
                return "";
            else
                return 'https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + _pictureUrl + ".jpg";
        else
            return "";

        // // let imageBlob;
        // if (pictureUrl.length > 0)
        //     this.setState({
        //         imageBlob: null,
        //     }, async () => {
        //         await fetch('https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + pictureUrl + ".jpg")
        //             .then(response => response.blob())
        //             .then(images => {
        //                 // imageBlob = response;
        //                 this.setState({
        //                     imageBlob: URL.createObjectURL(images),
        //                 });
        //                 // alert(response);
        //             })
        //             .catch(error => {
        //                 alert("Unable to load picture.\n\n" + error.code + "\n\n" + error.message);
        //             });
        //     });

        // // return imageBlob;
    }

    // getAnswerTextOrPictureURL = (data) => {
    //     let answer = String(data).split(":")[1];

    //     if (answer != null && String(answer).includes("_pk_")) {
    //         return <img src={this.getPicture(answer)} alt={''} width={'80%'} />;
    //     }
    //     else {
    //         return <span>{answer}</span>;
    //     }
    // }

    // getSelectedAnswerInText = () => {
    //     // alert(this.props.ASelectId);s
    //     let answer = "";
    //     this.props.ASelectId.map((data, key) => {
    //         if (String(data) !== "-1" || Number(data) >= 0) {
    //             return answer += String(this.state.Selections[Number(data)]).split(":")[0] + ";";
    //         }
    //         else {
    //             return answer += "-1;";
    //         }
    //     })
    //     // if (String(this.props.ASelectId) !== "-1" || Number(this.props.ASelectId) >= 0) {
    //     //     answer = String(this.state.Selections[Number(this.props.ASelectId)]).split(":")[0];
    //     // }
    //     return answer;
    // }

    CheckOnClickEvent = (e) => {
        // alert(e.target.id);
        let id = String(e.target.id).replace("Qs", "");
        let _id = Number(id) - 1;

        if (_id !== null && _id >= 0) {

            // let givenAnswer = this.state.Selections[_id].split(":")[0];
            // let isCorrect = this.state.Answers[_id] === givenAnswer;

            // alert("Q " + (_id + 1) + " | " + this.state.Answers[_id] + " vs " + givenAnswer + " | " + (isCorrect ? "Correct" : "Wrong"));

            this.ToggleAnswerOptionModal(_id);
        }
    }


    //==== Quiz Modal === starts.

    ToggleAnswerOptionModal = (_id) => {

        if (!this.state.showAnswerOptionModal) {
            let _answerOptionsHtml = "";
            let isOptionPicked = false;
            this.state.Selections.map((data, key) => {
                isOptionPicked = false;

                //edit 2020.10.20   
                //disabled to allow to show all buttons as active.
                // this.state.PickedAnswers.map((data1, key1) => {
                //     if (data1 == key)
                //         isOptionPicked = true;
                // });

                return _answerOptionsHtml += "<button id='Ans_" + key + "_' class='btn btn-primary btnAnswerOption' " + (isOptionPicked ? "disabled" : "") + ">"
                    + "<font style='font-size:clamp(16px, 4vw, 20px)' id='Ans_" + key + "_'>" + String(data).split(":")[1] + "</font></button>";

                // _answerOptionsHtml += "<button id='Ans_" + key + "_" + (isOptionPicked ? "disabled" : "") + "' class='btn btn-primary btnAnswerOption' " + (isOptionPicked ? "disabled" : "") + ">"
                //     + "<font style='font-size:20px' id='Ans_" + key + "_" + (isOptionPicked ? "disabled" : "") + "'>" + data.split(":")[1] + "</font></button>";
            });

            // let _answerOptionsHtml = "<div class='row'>";
            // this.state.Selections.map((data, key) => {
            //     _answerOptionsHtml += "<div class='col col-sm-6'>";
            //     _answerOptionsHtml += "<button id='" + key + "' class='btn btn-primary'>" + data.split(":")[1] + "</button>";
            //     _answerOptionsHtml += "</div>";
            // });
            // _answerOptionsHtml = "</div>";

            // alert(_id);

            this.setState({
                selectedQsId: Number(_id) - Number(this.props.qsStart) + 1,  //Number(_id.replace("Qs", "")),
                // selectedQsId: _id,
                selectedQsNo: Number(_id) + 1,  //2020.12.02
                answerOptionsHtml: _answerOptionsHtml,
            }, () => {
                // alert(this.state.selectedQsId);

                // alert("Qs Id : " + this.state.selectedQsId + "\nQs No : " + this.state.selectedQsNo);
            });
        }

        this.setState({
            showAnswerOptionModal: !this.state.showAnswerOptionModal,
        });
    }

    AnswerClickEventValidation = async (e) => {
        let _id = String(e.target.id);
        let _disabled = false;
        // alert(_id);
        if (_id.includes("Ans_")) {

            let options = String(_id).split("_");
            _id = Number(options[1]);

            // alert(_id);
            // _id = _id.replace('Ans_', '');

            //edit 2020.10.20   
            //disabled to allow to show all buttons as active.
            // _disabled = options[2] === "disabled" ? true : false;

            // _id = Number(_id.replace("Ans_", ""));

            if (_id !== null && Number(_id) >= 0) {

                if (!_disabled) {
                    // alert(this.state.selectedQsId + " | " + _id);
                    // this.state.PickedAnswers[this.state.selectedQsId] = _id;
                    // this.setState({
                    //     PickedAnswer: this.state.PickedAnswers,
                    // });

                    //edited 2020.11.04
                    var _PickedAnswers = this.state.PickedAnswers;
                    _PickedAnswers[this.state.selectedQsId] = _id;
                    this.setState({
                        PickedAnswer: _PickedAnswers,
                    });

                    this.ToggleAnswerOptionModal(this.state.selectedQsId);

                    let givenAnswer = String(this.state.Selections[_id]).split(":")[0];
                    let isCorrect = this.state.Answers[this.state.selectedQsId] === givenAnswer;

                    //Save answered answer.
                    // await this.props.setAnswer(isCorrect, this.state.selectedQsId + Number(this.props.qsStart) - 1, _id);
                    await this.props.setAnswer(isCorrect, Number(this.state.selectedQsNo) - 1, Number(_id));

                    // alert("Q " + (this.state.selectedQsId + 1) + " | (Answer) " + this.state.Answers[_id] + " vs " + givenAnswer + " (Select) | " + (isCorrect ? "Correct" : "Wrong"));

                    // alert(_id + " | Q " + (this.state.selectedQsId + this.props.qsStart)
                    //     // + "\n(Answer) " + this.state.Answers[this.state.selectedQsId] + " vs " + givenAnswer + " (Select)"
                    //     + "\n(Answer) " + this.state.Selections.filter(x => x.includes(this.state.Answers[this.state.selectedQsId] + ':')) + " vs " + String(this.state.Selections[_id]) + " (Select)"
                    //     + "\n" + (isCorrect ? "Correct" : "Wrong"));

                    //Refresh Content.
                    await this.processContent();
                }
            }
        }
    }

    //=== Quiz Modal === ends.

    getQuestionTitle = () => {
        let title = "";

        if (this.props.qsStart === this.props.qsEnd)
            title = "<h5><b>" + Locale("question", this.props.UiLocale) + " " + this.props.qsStart + "</b></h5>";
        else
            title = "<h5><b>" + Locale("question", this.props.UiLocale) + " " + this.props.qsStart + " " + Locale("quiz-qs-comp-to", this.props.UiLocale) + " " + this.props.qsEnd + "</b></h5>";

        //2021.04.03 - disabled by request from May.
        // title += "<h6>(" + Locale("fill-in-the-blanks", this.props.UiLocale) + ")</h6>";

        return title;   // + " = " + this.props.ASelectId;
    }

    //2021.01.18
    getHints = () => {
        return '<p> Hints: ' + this.convertTagOfColorAndNextline(String(this.props.data.Hints), 'light') + '</p>';
    }

    //2021.01.18
    getInstruction = () => {
        return '<p>' + this.convertTagOfColorAndNextline(String(this.props.data.Instruction), 'light') + '</p>';
    }

    //2021.01.18
    convertTagOfColorAndNextline = (_content, extra) => {

        let content = String(_content);

        if (content.length > 0) {

            if (content.includes('color')) {
                if (content.includes('green'))
                    content = content.replace(new RegExp('<color=green>', 'g'), '<span style="color:' + String(extra) + 'green;font-weight:bold;">&nbsp;');
                else if (content.includes('blue'))
                    content = content.replace(new RegExp('<color=blue>', 'g'), '<span style="color:blue;font-weight:bold;">&nbsp;');
                else if (content.includes('red'))
                    content = content.replace(new RegExp('<color=red>', 'g'), '<span style="color:red;font-weight:bold;">&nbsp;');

                content = content.replace(new RegExp('</color>', 'g'), '&nbsp;</span>');
            }

            if (content.includes('\n')) {
                content = content.replace(new RegExp('\\n', 'g'), '<br />');
            }

            //added on 2020-11-20
            if (content.includes('\t')) {
                content = String(content).replace(new RegExp('\\t', 'g'), '&#9;');
            }

            //2020.11.24
            if (content.includes('<b>')) {
                content = String(content).replace(new RegExp('<b>', 'g'), '<span style="font-weight:bold;">');
                content = String(content).replace(new RegExp('</b>', 'g'), '</span>');
            }

            //2021.08.25
            if (content.includes('<i>')) {
                content = String(content).replace(new RegExp('<i>', 'g'), '<span style="font-style:italic;">');
                content = String(content).replace(new RegExp('</i>', 'g'), '</span>');
            }

            //2020.11.25
            if (content.includes('<tab/>')) {
                content = String(content).replace(new RegExp('<tab/>', 'g'), '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
            }

            //2020.12.18
            if (content.includes('<subjective-instruction>')) {
                let instructionText = Locale("subjective-instruction", this.props.UiLocale);
                content = String(content).replace(new RegExp('<subjective-instruction>', 'g'), instructionText);
            }

            //2021.03.25
            if (content.includes('<img>')) {
                content = String(content).replace(new RegExp('<img>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
                content = String(content).replace(new RegExp('</img>', 'g'), '.jpg" alt="" width="100%">');
            }

            //2021.07.12
            if (content.includes('<jpg>')) {
                content = String(content).replace(new RegExp('<jpg>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
                content = String(content).replace(new RegExp('</jpg>', 'g'), '.jpg" alt="" width="100%">');
            }
            if (content.includes('<png>')) {
                content = String(content).replace(new RegExp('<png>', 'g'), '<img src="https://ikeynew.blob.core.windows.net/ikeykidz/JPG/');
                content = String(content).replace(new RegExp('</png>', 'g'), '.png" alt="" width="100%">');
            }
        }

        return content;
    }

    render = () => {
        return (
            <>
                <div className="row">
                    <div className="col col-sm-5">
                        {/* <div className="row">
                            <div className="col">
                                <h5><b>{Locale("question", this.props.UiLocale) + " " + this.props.qsStart + " " + Locale("quiz-qs-comp-to", this.props.UiLocale) + " " + this.props.qsEnd}</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h6>({Locale("fill-in-the-blanks", this.props.UiLocale)})</h6>
                            </div>
                        </div> */}

                        {/* <h5>{"Question " + (this.props.id + 1)} {String(this.props.InActive)} {String(this.props.HasAnswer)}</h5> */}

                        <div dangerouslySetInnerHTML={{ __html: this.getQuestionTitle() }} />
                    </div>
                    <div className="col col-sm-7 text-right">
                        {/* {
                            this.props.InActive && this.props.ASelectId === "-1" ?
                                // this.props.ASelectId
                                <>
                                    <span>Time's Up, No Answer was Given</span>&nbsp;
                                    <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                </>
                                // this.props.data.Answer === this.getSelectedAnswerInText() ?
                                //     <i className="fa fa-check" style={{ fontSize: "24px", color: "blue" }}></i>
                                //     :
                                //     <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>s                                
                                :
                                this.props.ShowResultIcon ?
                                    this.props.data.Answer == this.getSelectedAnswerInText() ?
                                        // this.props.data.Answer == this.props.ASelectId ?
                                        <i className="fa fa-check" style={{ fontSize: "24px", color: "green" }}></i>
                                        :
                                        <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                    : null
                        } */}
                    </div>
                </div>

                <div className="questionsBox">

                    <div className="questions">
                        {
                            //2021.01.18
                            this.state.hasInstruction ?
                                <div dangerouslySetInnerHTML={{ __html: this.getInstruction() }} />
                                : null
                        }

                        {
                            !this.props.IsQuizDone ?
                                <div style={{ textAlign: 'right', marginBottom: 10 }}>
                                    <span style={{ fontSize: 18, color: 'yellow', fontWeight: 'bold' }}>
                                        {Locale('FITB-instruction-1', this.props.UiLocale)}
                                        {'Q' + String(this.props.qsStart)}
                                        {
                                            Number(this.props.qsEnd) > Number(this.props.qsStart) ?
                                                // this.props.UiLocale === Lang.Chinese ?
                                                //     Locale('question-to', this.props.UiLocale)
                                                //     + 'Q' + String(this.props.qsEnd)
                                                //     :
                                                ' ' + Locale('question-to', this.props.UiLocale)
                                                + ' Q' + String(this.props.qsEnd)
                                                : null
                                        }
                                        {Locale('FITB-instruction-2', this.props.UiLocale)}
                                    </span>
                                </div>
                                :
                                null
                        }

                        <div
                            onClick={this.CheckOnClickEvent}
                            dangerouslySetInnerHTML={{ __html: this.state.contentHtml }}
                            style={{ lineHeight: '42px', marginBottom: 30 }}
                        />
                        {/* {
                            !this.props.IsQuizDone ?
                                <div style={{ textAlign: 'right', marginTop: 10 }}>
                                    <span style={{ fontSize: 14, color: 'yellow', fontWeight: 'bold' }}>
                                        {Locale('FITB-instruction-1', this.props.UiLocale)}
                                        {'Q' + String(this.props.qsStart)}
                                        {
                                            Number(this.props.qsEnd) > Number(this.props.qsStart) ?
                                                // this.props.UiLocale === Lang.Chinese ?
                                                //     Locale('question-to', this.props.UiLocale)
                                                //     + 'Q' + String(this.props.qsEnd)
                                                //     :
                                                ' ' + Locale('question-to', this.props.UiLocale)
                                                + ' Q' + String(this.props.qsEnd)
                                                : null
                                        }
                                        {Locale('FITB-instruction-2', this.props.UiLocale)}
                                    </span>
                                </div>
                                :
                                <span>&nbsp;</span>
                        } */}

                        {
                            //2021.01.18
                            this.state.hasHints ?
                                <div dangerouslySetInnerHTML={{ __html: this.getHints() }} />
                                : null
                        }

                    </div>
                    {/* {this.getPicture(this.props.data.PictureUrl)} */}

                    {/* {
                        JSON.stringify(this.state.Answers) + "<br />"
                        + JSON.stringify(this.state.Selections) + "<br />"
                        + JSON.stringify(this.state.PickedAnswers)
                    } */}

                    {
                        this.state.PictureURL.length > 0 ?
                            <div className="questionPicture">
                                <img src={this.getPicture(this.state.PictureURL)} alt={''} width={'100%'} />
                                {/* {
                                    this.state.imageBlob != null ?
                                        <img src={this.state.imageBlob} alt="Picture" width={'100%'} />
                                        :
                                        <Spinner animation="border" variant="primary" />
                                } */}
                            </div>
                            : null
                    }


                    {
                        this.props.ShowResultIcon ?
                            <>
                                {/* <ul className="answerList">
                                    <li> */}
                                <div className="row custyle">
                                    <table className="table table-striped custab">
                                        <thead>
                                            <tr>
                                                <th width="15%">#</th>
                                                <th width="35%">{Locale("your-answer", this.props.UiLocale)}</th>
                                                <th width="15%">{Locale("result", this.props.UiLocale)}</th>
                                                <th width="35%">{Locale("correct-answer", this.props.UiLocale)}</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                this.state.PickedAnswers.map((data, key) => {
                                                    return (
                                                        <tr key={'PA_Result_' + key}>
                                                            <td>{Number(this.props.qsStart) + key}</td>
                                                            <td>{data !== "-1" ? String(this.state.Selections[data]).split(":")[1] : Locale("times-up", this.props.UiLocale)}</td>
                                                            <td>
                                                                {
                                                                    this.state.Answers[key] === String(this.state.Selections[data]).split(":")[0] ?
                                                                        <i className="fa fa-check" style={{ fontSize: "24px", color: "green" }}></i>
                                                                        :
                                                                        <i className="fa fa-remove" style={{ fontSize: "24px", color: "red" }}></i>
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    this.state.Selections.map((data, key1) => {
                                                                        if (String(data).split(":")[0] === String(this.state.Answers[key]))
                                                                            return String(data).split(":")[1];
                                                                        else
                                                                            return null;
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>

                                    </table>
                                </div>
                                {/* </li>
                                </ul> */}
                            </>
                            : null
                    }

                    <div className="questionsRow">
                        <div className="row">
                            <div className="col col-sm-3">
                                <button
                                    className="btn btn-secondary btn-block" id="skipquestions"
                                    onClick={() => {
                                        // alert(this.props.qsEnd);
                                        this.props.nextQs(Number(this.props.qsEnd), null);
                                        // this.props.nextQs(this.props.id + 1, null);
                                        // this.setState({ ToggleSkip: false });
                                    }}
                                    hidden={this.props.total > this.props.qsEnd ? !this.state.ToggleSkip : true}
                                >{Locale("next", this.props.UiLocale)}</button>
                            </div>
                            <div className="col col-sm-6 text-center">
                                {
                                    this.props.qsStart === this.props.qsEnd ?
                                        <span>{this.props.qsStart + " / " + this.props.total}</span>
                                        :
                                        <span>{this.props.qsStart + "~" + this.props.qsEnd + " / " + this.props.total}</span>
                                }
                            </div>
                            <div className="col col-sm-3">
                                {/* <button
                                    className="btn btn-primary btn-block" id="nextquestions"
                                    onClick={() => {
                                        this.props.nextQs(this.props.id + 1, null);
                                        this.setState({ ToggleSkip: false, ToggleNext: false, });
                                    }}
                                    hidden={this.props.total > this.props.id + 1 ? !this.state.ToggleNext : true}
                                >Next</button> */}
                                <a href="#quiz-pos-q-result" hidden={!this.props.ShowResultBtn}>
                                    <button className="btn btn-primary btn-block" >{Locale("goto-result", this.props.UiLocale)}</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.showAnswerOptionModal} onHide={this.ToggleAnswerOptionModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {/* Options for Question #{this.state.selectedQsId + 1} */}
                            {/* {Locale("options-for-qs", this.props.UiLocale) + (Number(this.props.id) + 1)} */}
                            {Locale("options-for-qs", this.props.UiLocale) + this.state.selectedQsNo}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div dangerouslySetInnerHTML={this.getAnswerOptions()}></div> */}
                        {/* <div className="form-group">
                            <label>Room Code</label>
                            <input name="roomcode" className="form-control" placeholder="Please enter room code here." type="email"
                                onChange={this.handleSetRoomCode}
                                value={this.state.quizRoomCode}
                            />
                        </div> */}

                        {/* <div className="container" style={{ width: '550px' }}> */}
                        <div
                            onClick={this.AnswerClickEventValidation}
                            dangerouslySetInnerHTML={{ __html: this.state.answerOptionsHtml }}
                            className="answerOptionDiv"
                        />
                        {/* </div> */}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.ToggleAnswerOptionModal}
                            disabled={this.state.isCheckingRoom}
                        >{Locale("close", this.props.Locale)}</Button>
                        {/* <Button variant="secondary" onClick={() => this.setState({ quizRoomCode: "" })}
                            disabled={this.state.isCheckingRoom}
                        > Clear </Button>
                        <Button variant="primary" onClick={this.RoomCodeValidation}
                            disabled={this.state.isCheckingRoom}
                        > Enter </Button> */}
                    </Modal.Footer>
                </Modal>

                {/* <span id={"quiz-pos-q-" + (this.props.id + 1)} >&nbsp;</span> */}
            </>
        );
    }
}